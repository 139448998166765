import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors, ZERO_TIME } from '../../../constants'
import Moment from 'moment-timezone'

class ChefCalendarPopup extends Component {
  state = {
    order: {},
  }

  componentWillMount() {
    const { order } = this.props
    this.setState({ order })
  }

  componentWillReceiveProps(nextProps) {
    const { order } = nextProps
    this.setState({ order })
  }

  render() {
    const { order } = this.state
    const isGroupOrder = order.orderNumber.includes('GRP')

    return (
      <div className="calendar-hover__inner">
        <div className="quick-view">Quick View</div>
        <div className="">
          <div id="calendar-popup" className="calendar-popup">
            <div className="w-full">
              <div className="">
                <div className="flex align-center">
                  {order && (
                    <div className="popup-chef-details">
                      <p className="popup-chef-name" id="chef-name">
                        {(order.mealType && order.mealType) ||
                          (order.mealType && order.mealType) ||
                          'Custom Menu'}{' '}
                        by Sales Rep {order.salesRep.name}
                      </p>
                      <p id="setup-time" className="">
                        {Moment(order.date).format('MM/DD/YY')}{' '}
                        {order.orderType}{' '}
                        {Moment(order.clientSetUpTime).format('h:mm a')}
                        <span id="headcount-and-total">
                          {' '}
                          | Headcount {order.headCount} | {order.orderNumber}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <table className="w-full">
                  <tr className="">
                    <th className="text-left">Description</th>
                    <th className="text-right pl-30">Price</th>
                    <th className="text-center pl-30">Qty</th>
                    <th className="text-right nowrap pl-30">Total</th>
                    <th />
                  </tr>
                  {order &&
                    order.items &&
                    order.items.map((o, i) => (
                      <tbody key={i}>
                        <tr key={i}>
                          <td className="text-left vertical-align-middle">
                            <div>
                              <p id="menu-item-name" className="popup-name">
                                {o.item}
                              </p>
                            </div>
                          </td>
                          <td className="text-right pl-30 vertical-align-middle">
                            $
                            {Number(
                              o.quantity ? o.chefTotal / o.quantity : 0,
                            ).toFixed(2)}
                          </td>
                          <td className="text-center pl-30 vertical-align-middle">
                            {o.quantity}
                          </td>
                          <td
                            id="line-total"
                            className="pl-30 text-right vertical-align-middle"
                          >
                            ${Number(o.chefTotal).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  {order &&
                    order.serviceItems.map((o, i) => {
                      return (
                        <tbody key={i}>
                          <tr key={i}>
                            <td>
                              <div>
                                <p id="menu-item-name">{o.name}</p>
                              </div>
                            </td>
                            <td className="text-right">
                              $
                              {Number(
                                o.quantity ? o.chefTotal / o.quantity : 0,
                              ).toFixed(2)}
                            </td>
                            <td className="text-right">{o.quantity}</td>
                            <td id="line-total" className="text-right">
                              ${Number(o.chefTotal).toFixed(2)}
                            </td>
                            <td />
                          </tr>
                        </tbody>
                      )
                    })}
                  <tr>
                    <td colSpan="2" className="text-right bold pr-30">
                      Total Payout
                    </td>
                    <td id="total" colSpan="2" className="text-right">
                      {order && `$${Number(order.totalPayout).toFixed(2)}`}
                    </td>
                  </tr>
                </table>
              </div>
              {isGroupOrder && (
                <Fragment>
                  <div style={{ height: '1rem' }}></div>
                  <ChefEmailNotifContainer>
                    <ChefEmailNotifText>
                      Group Order Chef Email Sent:{' '}
                    </ChefEmailNotifText>
                    {!order.weeklyChefEmailSentDateMap[this.props.chefId] ? (
                      <ChefEmailNotifText>
                        Email Not sent yet
                      </ChefEmailNotifText>
                    ) : (
                      <ChefEmailNotifText>
                        {Moment(
                          order.weeklyChefEmailSentDateMap[this.props.chefId],
                        ).format('MM/DD/YY h:mma')}
                      </ChefEmailNotifText>
                    )}
                  </ChefEmailNotifContainer>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const ChefEmailNotifContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ChefEmailNotifText = styled.p`
  color: ${colors.blue300};
`

ChefCalendarPopup.propTypes = {
  chefId: PropTypes.string,
  order: PropTypes.object,
}

export default ChefCalendarPopup
