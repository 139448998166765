import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class SurveyService extends BaseService {
  setDashboardSurveys = (surveys) => {
    this.dispatch(actions.setDashboardSurveys(surveys))
  }

  setSurveys = (surveyInfo) => {
    this.dispatch(actions.setSurveys(surveyInfo))
  }
}
