export const TOGGLE_EDIT_DELIVERY_ZONE_MODAL = 'TOGGLE_EDIT_DELIVERY_ZONE_MODAL'
export const TOGGLE_EDIT_HQ_MODAL = 'TOGGLE_EDIT_HQ_MODAL'
export const TOGGLE_EDIT_SERVICE_DEPOT_MODAL = 'TOGGLE_EDIT_SERVICE_DEPOT_MODAL'

export const toggleEditDeliveryZoneModal = (show) => ({
  type: TOGGLE_EDIT_DELIVERY_ZONE_MODAL,
  show,
})
export const toggleEditHQModal = (show) => ({
  type: TOGGLE_EDIT_HQ_MODAL,
  show,
})
export const toggleEditServiceDepotModal = (show) => ({
  type: TOGGLE_EDIT_SERVICE_DEPOT_MODAL,
  show,
})
