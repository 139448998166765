import React from 'react'
import PropTypes from 'prop-types'

import { MasterDetail } from '@components/common'

import { AccountDetail, AccountMaster } from '@containers/account'

const AccountPage = (props) => {
  const { newAccount } = props

  return (
    <MasterDetail
      detail={<AccountDetail />}
      master={<AccountMaster />}
      onNew={newAccount}
      onNewAuthRoles={['master admin', 'sales rep', 'sales lead']}
      onNewLabel="Client"
      title="Clients"
    />
  )
}

AccountPage.propTypes = {
  accountName: PropTypes.string,

  newAccount: PropTypes.func,
}

export default AccountPage
