import React, { useState, useMemo, useEffect, useCallback } from 'react'
import Moment from 'moment-timezone'

import { FlexContainer, LoadingIndicator } from '@components/common'
import Table from '@components/common/Table'
import { AutocompleteInput, DateInput, Dropdown } from '@components/common/form'

import {
  IndexHeadquarter,
  Chef,
  ChefConfirmation as ChefConfirmationType,
} from '@types'

interface ChefConfirmationsProps {
  headquarterId: number
  headquarters: IndexHeadquarter[]
  loadChefConfirmations: ({
    hqId,
    chefId,
    date,
  }: {
    hqId: number
    chefId?: string
    date?: string
  }) => Promise<ChefConfirmationType[]>
  loadChefs: ({
    search,
    status,
  }: {
    search?: string
    status: 'active'
  }) => Promise<Chef[]>
}

const ChefConfirmations: React.FC<ChefConfirmationsProps> = ({
  headquarterId,
  headquarters,
  loadChefConfirmations,
  loadChefs,
}) => {
  const [chefConfirmations, setChefConfirmations] = useState<
    ChefConfirmationType[]
  >([])
  const [selectedHeadquarter, setSelectedHeadquarter] =
    useState<number>(headquarterId)
  const [selectedChef, setSelectedChef] = useState<
    { id: string; name: string } | undefined
  >(undefined)
  const [selectedDate, setSelectedDate] = useState<string>(
    Moment().add(1, 'days').format('YYYY-MM-DD'),
  )
  const [sortAttribute, setSortAttribute] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const loadChefConfs = useCallback(async () => {
    setLoading(true)
    const confirmations = await loadChefConfirmations({
      hqId: selectedHeadquarter,
      chefId: selectedChef?.id,
      date: selectedDate,
    })
    setSortAttribute('')
    setChefConfirmations(confirmations)
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHeadquarter, selectedChef, selectedDate])

  useEffect(() => {
    loadChefConfs()
  }, [loadChefConfs])

  const onChangeSortAttribute = (attribute: string) => {
    if (sortAttribute === attribute) {
      setSortAttribute(`-${attribute}`)
    } else {
      setSortAttribute(attribute)
    }
  }

  const sortedConfirmations = useMemo(() => {
    if (sortAttribute === '') {
      return chefConfirmations
    }
    let dir = 1
    let key = sortAttribute
    if (key[0] === '-') {
      dir = -1
      key = key.slice(1)
    }

    return chefConfirmations.sort((a, b) => {
      const attrib = key as keyof ChefConfirmationType

      return a[attrib]! < b[attrib]! ? -1 * dir : 1 * dir
    })
  }, [chefConfirmations, sortAttribute])

  const onSelectChef = (chef: Chef) => {
    setSelectedChef(chef)
    setSortAttribute('')
  }

  return (
    <div>
      <FlexContainer
        flexDirection="row"
        justifyContent="space-between"
        width="75%"
      >
        <Dropdown
          width="25%"
          marginBottom="20px"
          label="Headquarter"
          value={selectedHeadquarter}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setSelectedHeadquarter(Number(e.target.value))
          }
        >
          {headquarters.map((hq) => (
            <option key={hq.id} value={hq.id}>
              {hq.name}
            </option>
          ))}
        </Dropdown>
        <AutocompleteInput
          width="25%"
          value={selectedChef ? selectedChef.name : ''}
          label="Search Chef"
          displayAttribute="name"
          loaderFunction={(search: any) =>
            loadChefs({ ...search, status: 'active' })
          }
          onSelect={onSelectChef}
          onClear={() => {
            setSelectedChef(undefined)
            setSortAttribute('')
          }}
        />
        <DateInput
          width="25%"
          label="Select Date"
          date={Moment(selectedDate)}
          hideClearDate={false}
          onChange={(value: Moment.Moment) =>
            setSelectedDate(value.format('YYYY-MM-DD'))
          }
          clearDate={() =>
            setSelectedDate(Moment().add(1, 'days').format('YYYY-MM-DD'))
          }
        />
      </FlexContainer>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Table>
          <tr>
            <th
              className="cursor-pointer"
              onClick={() => onChangeSortAttribute('chef.name')}
            >
              Chef Name
            </th>
            <th
              className="cursor-pointer"
              onClick={() => onChangeSortAttribute('order.orderNumber')}
            >
              Order Number
            </th>
            <th
              className="cursor-pointer"
              onClick={() => onChangeSortAttribute('order.pickupTime')}
            >
              Pickup Time
            </th>
            <th
              className="cursor-pointer"
              onClick={() => onChangeSortAttribute('status.confirmed')}
            >
              Confirmation Status
            </th>
          </tr>
          {sortedConfirmations.map((confirmation, idx) => (
            <ChefConfirmation key={idx} confirmation={confirmation} />
          ))}
        </Table>
      )}
    </div>
  )
}

interface ChefConfirmationProps {
  confirmation: ChefConfirmationType
}

const ChefConfirmation: React.FC<ChefConfirmationProps> = ({
  confirmation,
}) => {
  const {
    chef: { id, name },
    order: { orderNumber, pickupTime },
    status: { confirmed, denyReason },
  } = confirmation

  const renderStatus = () => {
    switch (confirmed) {
      case 1:
        return <p>Confirmed</p>
      case 0:
        return (
          <div>
            <p>Denied</p>
            <p>Deny Reason: {denyReason ? denyReason : 'N/A'}</p>
          </div>
        )
      default:
        return <p>Undecided</p>
    }
  }

  return (
    <tr key={`${id}-${orderNumber}`}>
      <td>{name}</td>
      <td>{orderNumber}</td>
      <td>{Moment(pickupTime).format('h:mm a')}</td>
      <td>{renderStatus()}</td>
    </tr>
  )
}

export default ChefConfirmations
