import * as accountCoordinators from './account'
import * as dinerProfilesCoordinators from './dinerProfiles'
import * as editCoordinators from './edit'
import * as promoCodesCoordinators from './promoCodes'

export default Object.freeze({
  ...editCoordinators,
  ...accountCoordinators,
  ...dinerProfilesCoordinators,
  ...promoCodesCoordinators,
})
