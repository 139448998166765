import { connect } from 'react-redux'

import { ServiceItems } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { chef } = state

  const serviceItems = chef && chef.serviceItems
  const chefId = chef && chef.id

  return {
    chefId,
    serviceItems,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, UIService } = services
  const { pDefaultServiceFields } = presenters
  const { pResponseServiceItem, pResponseGeneric } = presenters.Api
  const {
    DelayedUpdateServiceItem,
    EditServiceItem,
    LoadChefServiceItems,
    NewServiceItem,
  } = coordinators

  const editServiceItem = EditServiceItem({
    ChefService,
    RestService,
    UIService,
    pResponseServiceItem,
  })
  const loadChefServiceItems = LoadChefServiceItems({
    ChefService,
    RestService,
    pResponseServiceItems: pResponseGeneric,
  })
  const newServiceItem = NewServiceItem({
    ChefService,
    UIService,
    pDefaultServiceFields,
  })
  const delayedUpdateServiceItem = DelayedUpdateServiceItem({
    ChefService,
    UIService,
  })

  return {
    delayedUpdateServiceItem,
    editServiceItem,
    loadChefServiceItems,
    newServiceItem,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceItems)
