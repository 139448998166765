import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class EditAccount extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditAccountModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleEditAccountModal(true))
  }

  showSearchViaHubSpot = () => {
    this.dispatch(actions.toggleSearchViaHubSpotModal(true))
  }
  closeSearchViaHubSpot = () => {
    this.dispatch(actions.toggleSearchViaHubSpotModal(false))
  }
}

export class EditAccountContact extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditAccountContactModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleEditAccountContactModal(true))
  }
}

export class EditDinerProfile extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditDinerProfileModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleEditDinerProfileModal(true))
  }
}
