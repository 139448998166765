import { SET_SHOW_CALC_CHEF_PROC_FEE_MODAL } from '@actions/ui/calcChefProcFeeModal'

export const calcChefFeesModal = (state = false, action) => {
  switch (action.type) {
    case SET_SHOW_CALC_CHEF_PROC_FEE_MODAL: {
      const { show } = action

      return { show }
    }
    default:
      return state
  }
}
