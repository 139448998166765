export type ScheduleUpdateType =
  | 'create'
  | 'update'
  | 'delete'
  | 'deleteDayInRecur'

export const ScheduleUpdateTypes: { [key: string]: ScheduleUpdateType } = {
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  DeleteDayInRecur: 'deleteDayInRecur',
}

export const ScheduleUpdateStatus = {
  Pending: -1,
  Approved: 1,
  Rejected: 0,
}

export type TimeSlotReq = {
  week: string
  month: string
  day: number
  start: number
  end: number
  recurring: boolean
  recurringEnds: boolean
  recurringDaysOfWeek: number[]
  endDate: string
}

export type RemoveTimeSlotReq = {
  slotId: string
  month: string
  week: string
  day: number
}

export type RemoveDayInRecurReq = {
  slotId: string
  month: string
  week: string
  day: number
}

export type ChefTimeSlotReq =
  | TimeSlotReq
  | RemoveTimeSlotReq
  | RemoveDayInRecurReq

export type ChefScheduleUpdate = {
  id: string
  chefId: string
  chefName: string
  slotId: string
  chefTimeSlotReq: ChefTimeSlotReq
  type: ScheduleUpdateType
  status: number
  createdAt: string
  updatedAt: string
}
