import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class HubspotCompanyService extends BaseService {
  setList = (companies) => {
    this.dispatch(actions.setHubspotCompanies(companies))
  }

  setSingle = (company) => {
    this.dispatch(actions.setHubspotCompany(company))
  }

  setCompaniesInCompanies = (companies) => {
    companies.forEach((c) => this.setCompanyInCompanies(c))
  }

  setCompanyInCompanies = (company) => {
    this.dispatch(actions.setCompanyInCompanies(company))
  }
}

export class HubspotContactService extends BaseService {
  setList = (contacts) => {
    this.dispatch(actions.setHubspotContacts(contacts))
  }

  setSingle = (contact) => {
    this.dispatch(actions.setHubspotContact(contact))
  }
}

export class HubspotUserService extends BaseService {
  setList = (users) => {
    this.dispatch(actions.setHubspotUsers(users))
  }

  setSingle = (user) => {
    this.dispatch(actions.setHubspotUser(user))
  }
}

export class HubspotDealService extends BaseService {
  // Possibly need to make a new service for proposals exclusively
  setList = (deals) => {
    this.dispatch(actions.setHubspotDeals(deals))
  }
  setSingle = (deal) => {
    this.dispatch(actions.setHubspotDeal(deal))
  }
}
