import { BaseService } from 'hungry-core2'

import actions from '@actions'

import { SECTION1 } from '../../../constants'

export default class EditProposal extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditProposalModal({ show: false }))
  }

  getMode = () => {
    return this.getState().editProposalModal.mode
  }

  show = (mode, section = SECTION1) => {
    this.dispatch(
      actions.toggleEditProposalModal({ show: true, mode, section }),
    )
  }
}
