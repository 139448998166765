import { connect } from 'react-redux'

import { AccountExecutiveSection } from '@components/order/edit/sections'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'

const mapDispatchToProps = () => {
  const { RestService } = services
  const { LoadAccountExecutives } = coordinators
  const { pResponseAccountExecutives } = presenters.Api

  const loadAccountExecutives = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })

  return { loadAccountExecutives }
}

export default connect(undefined, mapDispatchToProps)(AccountExecutiveSection)
