import { connect } from 'react-redux'

import { VXOrdersPage } from '@components/virtualExperiences'

import coordinators from '@coordinators'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { lastLoggedInHq } = state.user

  return {
    headquarter: lastLoggedInHq,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService, VXInvoiceService } = services
  const {
    GetVXOrder,
    VXOrderSync,
    SearchVXOrders,
    SaveVXInvoice,
    GetAccountingVXOrder,
  } = coordinators
  const { newInvoice, newOrderPayment, newBuyer } = VXInvoiceService
  const getVXOrder = GetVXOrder({
    RestService,
    HandleError,
  })
  const searchVXOrders = SearchVXOrders({
    RestService,
    HandleError,
  })

  const saveVXInvoice = SaveVXInvoice({
    RestService,
    UIService,
    HandleError,
  })

  const getAccountingVXOrder = GetAccountingVXOrder({
    RestService,
    HandleError,
  })

  const vxOrderSync = VXOrderSync({
    RestService,
    UIService,
    HandleError,
  })

  const { displayWarningMessage } = UIService.FlashMessage
  const flashSuccess = (message) =>
    UIService.FlashMessage.displaySuccessMessage(message)
  const showConfirmationModal = UIService.ConfirmationModal.show

  return {
    getVXOrder,
    searchVXOrders,
    newInvoice,
    newOrderPayment,
    newBuyer,
    saveVXInvoice,
    getAccountingVXOrder,
    displayWarningMessage,
    flashSuccess,
    showConfirmationModal,
    vxOrderSync,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VXOrdersPage)
