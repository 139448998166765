import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { NumberInput } from '@components/common/form'
import { AuthorizedInteractable } from '@containers/common/auth'
import { moneyString } from '~/utils'

export class Inventories extends Component {
  constructor(props) {
    super(props)
    const trackedInventories =
      props.inventories && props.inventories.filter((i) => i.tracked)
    const untrackedInventories =
      props.inventories && props.inventories.filter((i) => !i.tracked)

    this.state = {
      trackedInventories,
      untrackedInventories,
      newInventory: null,
      editInventory: null,
      sortAsc: true,
    }
  }

  componentWillMount() {
    this.props.loadInventories()
  }

  componentWillReceiveProps(nextProps) {
    const { inventories } = nextProps
    if (inventories !== this.state.inventories) {
      const trackedInventories =
        inventories && inventories.filter((i) => i.tracked)
      const untrackedInventories =
        inventories && inventories.filter((i) => !i.tracked)
      this.setState({ trackedInventories, untrackedInventories }, () =>
        this.sortByNumber('trackedInventories', 'count', true),
      )
    }
  }

  sortByString = (tableName, columnName) => {
    const { sortAsc, [tableName]: inventories } = this.state
    const getColumn = (item) =>
      item[columnName] ? item[columnName].toLowerCase() : ''
    const sorted = inventories.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ [tableName]: sorted, sortAsc: !sortAsc })
  }

  sortByBoolean = (tableName, columnName) => {
    const { [tableName]: inventories, sortAsc } = this.state
    const getColumn = (item) => (item[columnName] === true ? 1 : 0)
    const sorted = inventories.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ [tableName]: sorted, sortAsc: !sortAsc })
  }

  sortByNumber = (tableName, columnName, forceSortAsc = null) => {
    const { [tableName]: inventories, sortAsc } = this.state
    const sorted = inventories.sort((a, b) => {
      if (forceSortAsc || sortAsc) {
        return a[columnName] - b[columnName]
      } else {
        return b[columnName] - a[columnName]
      }
    })
    this.setState({ [tableName]: sorted, sortAsc: !sortAsc })
  }

  sortDailyCountByDate = (index) => {
    const { trackedInventories, sortAsc } = this.state
    const sorted = trackedInventories.sort((a, b) => {
      if (sortAsc) {
        return a.dailyCounts[index] - b.dailyCounts[index]
      } else {
        return b.dailyCounts[index] - a.dailyCounts[index]
      }
    })
    this.setState({ trackedInventories: sorted, sortAsc: !sortAsc })
  }

  renderRow = (inventory, i) => {
    const { editInventory } = this.state
    const id = editInventory && editInventory.id
    if (inventory.id == id) {
      return this.renderEditRow(editInventory, i)
    } else {
      return this.renderStaticRow(inventory, i)
    }
  }

  renderStaticRow = (inventory, i) => {
    const {
      supplyName,
      count,
      lastCount,
      lastTotalValue,
      unitCost,
      totalValue,
      alertThreshold,
      alertEnabled,
      dailyCounts,
      tracked,
      orderSupplyId,
    } = inventory
    const { loadInventoryTransactions, dates } = this.props
    const { editInventory } = this.state

    return (
      <tr
        key={i}
        className="clickable"
        onClick={(e) => {
          e.preventDefault()
          this.setState({ editInventory: { ...inventory } })
        }}
      >
        <td>{supplyName}</td>
        <td>{unitCost}</td>
        {tracked && (
          <td className={count <= alertThreshold && 'orange-highlight'}>
            {' '}
            {count}{' '}
          </td>
        )}
        {tracked && <td> {'$' + moneyString(totalValue)} </td>}
        {tracked && <td> {lastCount == null ? 'N/A' : lastCount} </td>}
        {tracked && (
          <td>
            {' '}
            {lastTotalValue == null
              ? 'N/A'
              : '$' + moneyString(lastTotalValue)}{' '}
          </td>
        )}
        {tracked && <td> {alertThreshold} </td>}
        {tracked && (
          <td>
            {alertEnabled ? (
              <span className="approved-check">✔</span>
            ) : (
              <span className="not-approved-x">✖</span>
            )}
          </td>
        )}
        {editInventory && <td />}
        {dailyCounts &&
          dailyCounts.map((count, i) => {
            return (
              <td
                key={i}
                className={count <= alertThreshold && 'orange-highlight'}
                onClick={() => {
                  loadInventoryTransactions(orderSupplyId, dates[i])
                }}
              >
                {' '}
                {count}{' '}
              </td>
            )
          })}
      </tr>
    )
  }

  renderEditRow = (inventory, i) => {
    const {
      supplyName,
      tracked,
      unitCost,
      lastCount,
      lastTotalValue,
      totalValue,
      count,
      alertThreshold,
      alertEnabled,
      dailyCounts,
      orderSupplyId,
    } = inventory
    const { editInventory } = this.state
    const { loadInventoryTransactions, dates } = this.props

    return (
      <tr key={i} className="clickable">
        <td>{supplyName}</td>
        <td>
          <NumberInput
            value={unitCost}
            onInput={(newUnitcost) => {
              this.setState({
                editInventory: {
                  ...inventory,
                  unitCost: newUnitcost,
                },
              })
            }}
          />
        </td>
        {tracked && (
          <td>
            <NumberInput
              value={count}
              onInput={(newCount) => {
                this.setState({
                  editInventory: {
                    ...inventory,
                    count: newCount,
                  },
                })
              }}
            />
          </td>
        )}
        {tracked && <td> {'$' + moneyString(totalValue)} </td>}
        {tracked && <td> {lastCount == null ? 'N/A' : lastCount} </td>}
        {tracked && (
          <td>
            {' '}
            {lastTotalValue == null
              ? 'N/A'
              : '$' + moneyString(lastTotalValue)}{' '}
          </td>
        )}
        {tracked && (
          <td>
            <NumberInput
              value={alertThreshold}
              onInput={(newAlertThreshold) => {
                this.setState({
                  editInventory: {
                    ...inventory,
                    alertThreshold: newAlertThreshold,
                  },
                })
              }}
            />
          </td>
        )}
        {tracked && (
          <td>
            <input
              type="checkbox"
              checked={alertEnabled}
              onChange={(e) =>
                this.setState({
                  editInventory: {
                    ...inventory,
                    alertEnabled: e.target.checked,
                  },
                })
              }
            />
          </td>
        )}
        {
          <td>
            {editInventory && (
              <center>
                <button
                  key="clearEditInventory"
                  onClick={(event) => {
                    event.preventDefault()
                    this.setState({ editInventory: null })
                  }}
                >
                  Clear
                </button>
                <AuthorizedInteractable
                  roles={['master admin', 'chef lead', 'captain lead']}
                >
                  <button
                    key="editInventory"
                    onClick={async (event) => {
                      event.preventDefault()
                      if (await this.props.saveInventory(editInventory)) {
                        this.setState({
                          editInventory: null,
                          newInventory: null,
                        })
                      }
                    }}
                  >
                    Save
                  </button>
                </AuthorizedInteractable>
              </center>
            )}
          </td>
        }
        {dailyCounts &&
          dailyCounts.map((count, i) => {
            return (
              <td
                key={i}
                className={count <= alertThreshold && 'orange-highlight'}
                onClick={() => {
                  loadInventoryTransactions(orderSupplyId, dates[i])
                }}
              >
                {' '}
                {count}{' '}
              </td>
            )
          })}
      </tr>
    )
  }

  renderButtons = () => {
    const { editInventory } = this.state

    return (
      <div className="page">
        {editInventory && (
          <center>
            <button
              className="button-neutral"
              key="clearEditInventory"
              onClick={(event) => {
                event.preventDefault()
                this.setState({ editInventory: null })
              }}
            >
              Clear
            </button>
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="editInventory"
                onClick={async (event) => {
                  event.preventDefault()
                  if (await this.props.saveInventory(editInventory)) {
                    this.setState({ editInventory: null, newInventory: null })
                  }
                }}
              >
                Save
              </button>
            </AuthorizedInteractable>
          </center>
        )}
      </div>
    )
  }

  render() {
    const { dates } = this.props
    const { trackedInventories, untrackedInventories, editInventory } =
      this.state

    return (
      <div>
        <AuthorizedInteractable
          roles={['master admin', 'chef lead', 'captain lead']}
        >
          <button
            className="button-neutral"
            onClick={() => this.props.processTransactions()}
          >
            Process Transactions
          </button>
        </AuthorizedInteractable>
        <br />

        <table className="table table-hover page">
          <thead>
            <tr>
              <th
                onClick={() =>
                  this.sortByString('trackedInventories', 'supplyName')
                }
              >
                Tracked Supply Name
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('trackedInventories', 'unitCost')
                }
              >
                Unit Cost
              </th>
              <th
                onClick={() => this.sortByNumber('trackedInventories', 'count')}
              >
                Current Count
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('trackedInventories', 'totalValue')
                }
              >
                Current $
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('trackedInventories', 'lastCount')
                }
              >
                Last Friday Count
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('trackedInventories', 'lastTotalValue')
                }
              >
                Last Friday $
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('trackedInventories', 'alertThreshold')
                }
              >
                Alert Threshold
              </th>
              <th
                onClick={() =>
                  this.sortByBoolean('trackedInventories', 'alertEnabled')
                }
              >
                Alert Enabled
              </th>
              {editInventory && <th> Changes </th>}
              {dates.map((date, i) => (
                <th key={i} onClick={() => this.sortDailyCountByDate(i)}>
                  {' '}
                  {date}{' '}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {trackedInventories.length === 0 && (
              <tr>
                <td>
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '1.2em',
                      color: 'rgba(0,0,0,0.2)',
                      padding: '20px',
                      backgroundColor: 'rgba(0,0,0,0.01)',
                    }}
                  >
                    No tracked inventories.
                  </div>
                </td>
              </tr>
            )}
            {trackedInventories.length !== 0 &&
              trackedInventories.map((inventory, i) =>
                this.renderRow(inventory, i),
              )}
          </tbody>
        </table>

        <table className="table table-hover page">
          <thead>
            <tr>
              <th
                onClick={() =>
                  this.sortByString('trackedInventories', 'supplyName')
                }
              >
                Untracked Supply Name
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('trackedInventories', 'unitCost')
                }
              >
                Unit Cost
              </th>
            </tr>
          </thead>
          <tbody>
            {untrackedInventories.length === 0 && (
              <tr>
                <td>
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '1.2em',
                      color: 'rgba(0,0,0,0.2)',
                      padding: '20px',
                      backgroundColor: 'rgba(0,0,0,0.01)',
                    }}
                  >
                    No untracked inventories.
                  </div>
                </td>
              </tr>
            )}
            {untrackedInventories.length !== 0 &&
              untrackedInventories.map((inventory, i) =>
                this.renderRow(inventory, i),
              )}
          </tbody>
        </table>
      </div>
    )
  }
}

Inventories.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  inventories: PropTypes.arrayOf(PropTypes.object),

  loadInventories: PropTypes.func,
  loadInventoryTransactions: PropTypes.func,
  processTransactions: PropTypes.func,
  saveInventory: PropTypes.func,
}

export default Inventories
