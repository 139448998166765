import { camelCaseify, sentenceCaseify } from '~/utils'

export const pResponseBraintreeErrorMessage = (e) => {
  const errors = {}
  let errorMessage = 'Unknown error'

  if (e.details && e.details.originalError) {
    const { originalError } = e.details
    if (originalError.error && originalError.error.message) {
      errorMessage = originalError.error.message
    }
    if (originalError.fieldErrors) {
      const creditCardErrors = originalError.fieldErrors.find(
        (e) => e.field === 'creditCard',
      )
      if (creditCardErrors && creditCardErrors.fieldErrors) {
        creditCardErrors.fieldErrors.forEach(
          (e) => (errors[e['field']] = e['message']),
        )
      }
    }
  }

  return {
    errors,
    errorMessage,
  }
}

export const pResponseOrderGetTaxRate = (e) => {
  const errors = {}
  let errorMessage = 'Unknown error'

  if (e.response && e.response.data && e.response.data.error) {
    errorMessage =
      'There was a problem calculating tax with the selected dropoff address. Please check the dropoff address zip code or try re-selecting the address again.'
  }

  return {
    errors,
    errorMessage,
  }
}

export const responseErrorMessage = (e) => {
  let errors = {}
  let errorMessage = 'Unknown error'
  if (e.response) {
    if (e.response.data) {
      if (e.response.data.message) {
        let errors_
        try {
          const message = JSON.parse(e.response.data.message)
          errors_ = message.errors || message
        } catch (ee) {
          errors_ = e.response.data.message
        }
        if (Array.isArray(errors_)) {
          errorMessage = errors_[0]
        } else if (typeof errors_ === 'object') {
          for (const k in errors_) {
            if (Array.isArray(errors_[k])) {
              errors[k] = errors_[k][0]
              if (k === 'message') {
                errorMessage = errors_[k]
              }
            } else {
              errors[k] = errors_[k]
            }
          }
          errorMessage = Object.values(errors).join(' ')
        } else if (typeof errors_ === 'string') {
          errorMessage = errors_
        }
      } else if (e.response.data.errors) {
        const { errors: errors_ } = camelCaseify(e.response.data)
        if (Array.isArray(errors_)) {
          errorMessage = errors_[0]
        } else if (typeof errors_ === 'object') {
          for (const k in errors_) {
            if (Array.isArray(errors_[k])) {
              errors[k] = errors_[k][0]
              if (k === 'message') {
                errorMessage = errors_[k]
              } else if (errorMessage == 'Unknown error') {
                errorMessage =
                  sentenceCaseify(k).toLowerCase() + ' ' + errors_[k]
              } else {
                errorMessage +=
                  ', ' + sentenceCaseify(k).toLowerCase() + ' ' + errors_[k]
              }
            } else {
              errors[k] = errors_[k]
            }
          }
          if (errorMessage == 'Unknown error') {
            errorMessage = 'Please check the errors below'
          }
        } else if (typeof errors_ === 'string') {
          errorMessage = errors_
        }
      } else if (e.response.data.error) {
        errorMessage = camelCaseify(e.response.data.error)
        errors = errorMessage
      } else {
        const { data: errors_ } = camelCaseify(e.response)
        if (Array.isArray(errors_)) {
          errorMessage = errors_[0]
        } else if (typeof errors_ === 'object') {
          for (const k in errors_) {
            if (Array.isArray(errors_[k])) {
              errors[k] = errors_[k][0]
              if (k === 'message') {
                errorMessage = errors_[k]
              }
            } else {
              errors[k] = errors_[k]
            }
          }
          if (errorMessage == 'Unkown error') {
            errorMessage = 'Please check the errors below'
          }
        } else if (typeof errors_ === 'string') {
          errorMessage = errors_
        }
      }
    } else if (e.response.statusText) {
      errorMessage = `${e.response.status} ${e.response.statusText}`
    }
  } else if (e.errors) {
    const { errors: errors_ } = camelCaseify(e)
    if (Array.isArray(errors_)) {
      errorMessage = errors_[0]
    } else if (typeof errors_ === 'object') {
      for (const k in errors_) {
        if (Array.isArray(errors_[k])) {
          errors[k] = errors_[k][0]
        } else {
          errors[k] = errors_[k]
        }
      }
      errorMessage = 'Please check the errors below'
    } else if (typeof errors_ === 'string') {
      errorMessage = errors_
    }
  } else if (e.message) {
    errorMessage = e.message
    if (/timeout/.test(errorMessage)) {
      errorMessage = 'Request timed out. Please try again'
    }
  } else {
    errors = e
  }

  return {
    errors,
    errorMessage,
  }
}

export const pResponseErrorsArray = (e) => {
  const errorMessage =
    (e.response &&
      e.response.data &&
      e.response.data.errors &&
      Object.values(e.response.data.errors)) ||
    []
  if (e.response && e.response.data && e.response.data.error) {
    errorMessage.push(e.response.data.error)
  }

  return {
    errorMessage,
  }
}
