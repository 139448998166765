import React, { useState, ChangeEvent } from 'react'
import Modal from '@components/common/modal/Modal'
import Table from '@components/common/Table'

import { IndexHeadquarter } from '@types'

interface MarketSelectionModalProps {
  toReconcile: {
    hubspotId: string
    name: string
    markets: IndexHeadquarter[]
  }[]
  onConfirm: (reconiledMap: { [key: string]: string }) => void
  hideModal: () => void
}

const MarketSelectionModal: React.FC<MarketSelectionModalProps> = ({
  toReconcile,
  onConfirm,
  hideModal,
}) => {
  const [reconiledMap, setReconciledMap] = useState<{ [key: string]: string }>(
    {},
  )
  const [didConfirm, setDidConfirm] = useState<boolean>(false)

  const onSelectMetro =
    (hubspotId: string) => (e: ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target
      reconiledMap[hubspotId] = value
      setReconciledMap({ ...reconiledMap })
    }

  return (
    <Modal
      hideModal={!didConfirm ? () => undefined : hideModal} // cannot leave without reconciling
    >
      <div className="flex flex-col items-center">
        <h3 className="font-semibold mb-3">
          Please confirm metros for the following accounts:
        </h3>
        <Table headings={['Hubspot ID', 'Company Name', 'Metros']}>
          {toReconcile.map((company) => (
            <tr key={company.hubspotId}>
              <td>{company.hubspotId}</td>
              <td>{company.name}</td>
              <td>
                <select
                  value={reconiledMap[company.hubspotId] || ''}
                  onChange={onSelectMetro(company.hubspotId)}
                >
                  <option value="">Select a metro</option>
                  {company.markets.map((hq) => (
                    <option key={hq.id} value={hq.prefix}>
                      {hq.prefix}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          ))}
        </Table>
        <button
          style={{
            padding: '8px 15px',
            borderRadius: '5px',
          }}
          className="font-semibold bg-blue-500 text-white hover:bg-blue-700 mt-3"
          onClick={() => {
            setDidConfirm(true)
            onConfirm(reconiledMap)
          }}
        >
          Confirm
        </button>
      </div>
    </Modal>
  )
}

export default MarketSelectionModal
