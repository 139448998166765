import Moment from 'moment-timezone'

export const SaveVXInvoice =
  ({ RestService, UIService, HandleError }) =>
  async (req, generate = false) => {
    const { invoice } = req
    try {
      if (invoice.id) {
        await RestService.put(`/virtual-xp/invoice/${invoice.id}`, req)
      } else if (generate) {
        await RestService.post('/virtual-xp/invoice/generate', req)
      } else {
        await RestService.post('/virtual-xp/invoice', req)
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      `Invoice successfully ${invoice.id ? 'updated' : 'created'}`,
    )

    return true
  }

export const GetVXInvoice =
  ({ RestService, HandleError }) =>
  async (id) => {
    try {
      return await RestService.get(`/virtual-xp/invoice/${id}`)
    } catch (error) {
      HandleError({ error })
    }
  }

export const SearchVXInvoices =
  ({ RestService, HandleError }) =>
  async ({ from, to }) => {
    try {
      const params = {}
      if (from != null) {
        params.from = Moment(from).format()
      }

      if (to != null) {
        params.to = Moment(to).format()
      }

      return await RestService.get('/virtual-xp/invoices', params)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }
