export const SaveDateMenu =
  ({ RestService, UIService, HandleError, pRequestSaveDateMenu }) =>
  async ({ dateMenu, changedDateMenuMap, dateStr }) => {
    let result
    try {
      if (dateMenu.id) {
        result = await RestService.put(
          `/group-orders/date-menus/${dateMenu.id}`,
          pRequestSaveDateMenu({ dateMenu, changedDateMenuMap }),
        )
      } else {
        result = await RestService.post('/group-orders/date-menus', dateMenu)
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(`Menus set for ${dateStr}`)

    return result
  }

export const DeleteDateMenus =
  ({ RestService, UIService, HandleError }) =>
  async (id, dateStr) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete Menus for "${dateStr}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/group-orders/date-menus/${id}`)
      } catch (error) {
        HandleError({ error })

        return false
      }

      return true
    }

    return false
  }

export const SearchDateMenus =
  ({ RestService, HandleError }) =>
  async ({ settingsId, start, end, limit = 0 }) => {
    try {
      return await RestService.get('/group-orders/date-menus', {
        settingsId,
        start: start.format(),
        end: end.format(),
        limit,
      })
    } catch (error) {
      HandleError({ error })

      return []
    }
  }
