export const EmailTypes = [
  {
    value: 'cateringReminderEmails',
    label: 'Catering Reminder Emails (Day of Menus)',
  },
  {
    value: 'invoiceEmails',
    label: 'Invoice Emails',
  },
  {
    value: 'receiptEmails',
    label: 'Receipt Emails',
  },
]
