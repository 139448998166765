import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { FormInputContainer } from '@res/styledComponents/index'
import NumberFormat from 'react-number-format'

const CurrencyInput = (props) => {
  const {
    disabled,
    className,
    delay,
    decimals,
    label,
    paddingTop,
    prefix,
    suffix,
    testId,
    width,
    margin,
    marginBottom,
    onChange,
    ...numberFormatProps
  } = props

  let { value } = props

  const onChangeRef = useRef(_wrapOnChange(onChange))
  const debouncedOnChangeRef = useRef(null)
  value = value ? parseFloat(value) : ''

  // Keep the onChangeRef with the latest onChange prop
  useEffect(() => {
    onChangeRef.current = _wrapOnChange(onChange)
  }, [onChange])

  // If we are debouncing the onChange function cancel it on unmount
  useEffect(
    () => () => {
      debouncedOnChangeRef.current && debouncedOnChangeRef.current.cancel()
    },
    [value],
  )

  // If we have a delay prop we want to debounce the onChange function
  function getOnChangeCallback() {
    if (!delay) {
      return onChangeRef.current
    }

    if (!debouncedOnChangeRef.current) {
      debouncedOnChangeRef.current = _debounce((values, sourceInfo) => {
        onChangeRef.current(values, sourceInfo)
      }, delay)
    }

    return debouncedOnChangeRef.current
  }

  return (
    <FormInputContainer
      width={width}
      marginBottom={marginBottom}
      margin={margin}
      paddingTop={paddingTop}
    >
      {label && <label>{label}</label>}
      <NumberFormat
        disabled={disabled}
        className={className}
        type="text"
        inputMode="numeric"
        id={testId}
        width="100%"
        style={{ textAlign: 'right' }}
        value={value}
        placeholder={_getPlaceHolder(decimals, prefix, suffix)}
        decimalScale={decimals}
        prefix={prefix}
        suffix={suffix}
        onValueChange={getOnChangeCallback()}
        {...numberFormatProps}
      />
    </FormInputContainer>
  )
}

CurrencyInput.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  delay: PropTypes.number,
  decimals: PropTypes.number,
  label: PropTypes.string,
  paddingTop: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  testId: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  marginBottom: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  allowNegative: PropTypes.bool,

  onChange: PropTypes.func,
}

CurrencyInput.defaultProps = {
  decimals: 2,
  prefix: '$',
  suffix: '',
  allowNegative: false,
}

// Wrap to make component backwards compatible
function _wrapOnChange(onChange) {
  return (values) => onChange && onChange(values.floatValue || 0)
}

function _debounce(fn, delay) {
  let timer
  const debounced = (values, sourceInfo) => {
    debounced.cancel()
    timer = setTimeout(() => fn(values, sourceInfo), delay)
  }

  debounced.cancel = () => {
    timer && clearTimeout(timer)
  }

  return debounced
}

function _getPlaceHolder(decimals, prefix, suffix) {
  const numStr = decimals ? `0.${'0'.repeat(decimals)}` : '0'

  return `${prefix}${numStr}${suffix}`
}

export default CurrencyInput
