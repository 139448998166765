export const CLEAR_ACCOUNT = 'CLEAR_ACCOUNT'
export const CLEAR_EDIT_ACCOUNT_CONTACT = 'CLEAR_EDIT_ACCOUNT_CONTACT'
export const CLEAR_EDIT_ACCOUNT = 'CLEAR_EDIT_ACCOUNT'
export const REMOVE_ACCOUNT_FROM_ACCOUNTS = 'REMOVE_ACCOUNT_FROM_ACCOUNTS'
export const SET_ACCOUNT = 'SET_ACCOUNT'
export const SET_ACCOUNT_CONTACTS = 'SET_ACCOUNT_CONTACTS'
export const SET_ACCOUNTS = 'SET_ACCOUNTS'
export const SET_ACCOUNTS_IN_ACCOUNTS = 'SET_ACCOUNTS_IN_ACCOUNTS'
export const SET_ACCOUNTS_SEARCH = 'SET_ACCOUNTS_SEARCH'
export const SET_EDIT_ACCOUNT = 'SET_EDIT_ACCOUNT'
export const SET_EDIT_ACCOUNT_CONTACT = 'SET_EDIT_ACCOUNT_CONTACT'
export const UPDATE_EDIT_ACCOUNT = 'UPDATE_EDIT_ACCOUNT'
export const UPDATE_EDIT_ACCOUNT_CONTACT = 'UPDATE_EDIT_ACCOUNT_CONTACT'
export const SET_EDIT_DINER_PROFILE = 'SET_EDIT_DINER_PROFILE'
export const CLEAR_EDIT_DINER_PROFILE = 'CLEAR_EDIT_DINER_PROFILE'

export const clearAccount = () => ({ type: CLEAR_ACCOUNT })
export const clearEditAccount = () => ({ type: CLEAR_EDIT_ACCOUNT })
export const clearEditAccountContact = () => ({
  type: CLEAR_EDIT_ACCOUNT_CONTACT,
})
export const removeAccountFromAccounts = (accountId) => ({
  type: REMOVE_ACCOUNT_FROM_ACCOUNTS,
  accountId,
})

export const setAccount = (account) => ({ type: SET_ACCOUNT, account })
export const setAccountContacts = (accountContacts) => ({
  type: SET_ACCOUNT_CONTACTS,
  accountContacts,
})
export const setAccounts = (accounts) => ({ type: SET_ACCOUNTS, accounts })
export const setAccountsInAccounts = (accounts) => ({
  type: SET_ACCOUNTS_IN_ACCOUNTS,
  accounts,
})

// TODO: send individual search params
export const setAccountsSearch = (searchParams) => ({
  type: SET_ACCOUNTS_SEARCH,
  searchParams,
})

export const setEditAccount = (account) => ({ type: SET_EDIT_ACCOUNT, account })
export const setEditAccountContact = (contact) => ({
  type: SET_EDIT_ACCOUNT_CONTACT,
  contact,
})
export const updateEditAccount = (account) => ({
  type: UPDATE_EDIT_ACCOUNT,
  account,
})
export const updateEditAccountContact = (contact) => ({
  type: UPDATE_EDIT_ACCOUNT_CONTACT,
  contact,
})

export const setEditDinerProfile = (dinerProfile) => ({
  type: SET_EDIT_DINER_PROFILE,
  dinerProfile,
})
export const clearEditDinerProfile = () => ({ type: CLEAR_EDIT_DINER_PROFILE })
