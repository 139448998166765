import Moment from 'moment-timezone'

export const FakeSignIn =
  ({ FakeService, SessionService, UIService }) =>
  ({ email, password }) => {
    const user = FakeService.fakeUser({
      firstName: 'Goku',
      lastName: 'Son',
      email,
      password,
    })
    SessionService.setUserSession(user)
    UIService.LoginForm.toggleVisible(false)
  }

export const FakeLoginError =
  ({ FakeService, HandleError }) =>
  () => {
    const error = FakeService.fakeErrors({
      firstName: 'Please enter your first name',
      lastName: 'Please enter your last name',
      email: 'Please enter a valid email',
      password: 'Please enter a password',
      passwordConfirm: 'Passwords do not match!',
    })
    HandleError({ error, namespace: 'loginForm' })
  }

export const ChangeHeadquarter =
  ({
    CoreService,
    RestService,
    RouterService,
    SessionService,
    pResponseUser,
    HandleError,
  }) =>
  async ({ headquarterId, userId }) => {
    try {
      const refreshToken = window.localStorage.getItem('refreshToken')
      let user = await RestService.post('/v2/users/admin/token', {
        uid: userId,
        token: refreshToken,
        hqId: parseInt(headquarterId),
      })

      user = pResponseUser({ user, headquarterId })
      Moment.tz.setDefault(user.locale)
      SessionService.setUserSession(user)
      window.localStorage.setItem('lastLoggedInHq', user.lastLoggedInHq)
      window.location.reload()
    } catch (error) {
      CoreService.clearStore()
      RouterService.push('/signin')
      setTimeout(() => HandleError({ error }), 500)
    }
  }

export const SignIn =
  ({
    RestService,
    RouterService,
    SessionService,
    pResponseUser,
    HandleError,
  }) =>
  async ({ email, password, headquarter }) => {
    try {
      let user = await RestService.post('/v2/users/admin/login', {
        email,
        password,
        headquarterId: headquarter.id,
        headquarter_id: headquarter.id,
        application: 'Admin Panel',
      })
      user = pResponseUser({ user, headquarterId: headquarter.id })
      Moment.tz.setDefault(user.locale)
      SessionService.setUserSession(user)
      window.localStorage.setItem('lastLoggedInHq', user.lastLoggedInHq)
      RouterService.replace('/')
    } catch (error) {
      HandleError({ error })
    }
  }

export const SignOut =
  ({ CoreService, RestService, RouterService, HandleError }) =>
  async () => {
    try {
      await RestService.get('/api/v1/users/sign_out')
      CoreService.clearStore()
      RouterService.push('/signin')
    } catch (error) {
      HandleError({ error })
    }
  }

export const SendResetPassEmail =
  ({ RestService, UIService, HandleError }) =>
  async ({ email }) => {
    try {
      await RestService.post('v2/users/admin/forgotpassword', {
        email,
        app: 'admin',
      })
      UIService.FlashMessage.displaySuccessMessage(
        `If "${email}" is associated with an account, you will receive an email from us to reset your password`,
      )
    } catch (error) {
      HandleError({ error })
    }
  }

export const ResetPass =
  ({ CoreService, RestService, UIService, RouterService, HandleError }) =>
  async ({ password, token }) => {
    try {
      //Handle cornercase with user logged in, make sure we don't pass a regular auth token in the header
      window.localStorage.removeItem('accessToken')
      CoreService.clearStore()

      await RestService.put(
        `v2/users/admin/resetpassword?accessToken=${token}`,
        { password },
      )
      RouterService.replace('/signin')
      UIService.FlashMessage.displaySuccessMessage(
        'Password changed, you can now sign in with your new password',
      )
    } catch (error) {
      HandleError({ error })
    }
  }

export const LoadHeadquarters =
  ({ HeadquarterService, RestService, pResponseHeadquarters, HandleError }) =>
  async () => {
    try {
      let headquarters = await RestService.get('/api/admin/headquarters')
      headquarters = pResponseHeadquarters(headquarters)
      HeadquarterService.setHeadquarters(headquarters)

      return headquarters
    } catch (error) {
      HandleError({ error })
    }
  }

export const LoadUserHeadquarters =
  ({ HeadquarterService, RestService, pResponseHeadquarters, HandleError }) =>
  async (userId) => {
    try {
      const permissions = await RestService.get(
        `/v2/users/admin/${userId}/permissions`,
      )
      const headquarters = pResponseHeadquarters(
        permissions.map((p) => p.headquarter),
      )
      HeadquarterService.setUserHeadquarters(headquarters)
    } catch (error) {
      HandleError({ error })
    }
  }
