export const SET_HEADQUARTERS = 'SET_HEADQUARTERS'
export const SET_USER_HEADQUARTERS = 'SET_USER_HEADQUARTERS'

export const setHeadquarters = (headquarters) => ({
  type: SET_HEADQUARTERS,
  headquarters,
})
export const setUserHeadquarters = (headquarters) => ({
  type: SET_USER_HEADQUARTERS,
  headquarters,
})
