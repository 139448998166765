import { connect } from 'react-redux'

import { PopUpCustomersPage } from '@components/popUps'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { accounts } = state
  const { lastLoggedInHq } = state.user

  return {
    accounts,
    headquarter: lastLoggedInHq,
  }
}

const mapDispatchToProps = () => {
  const { RestService, AccountService } = services
  const { SearchPopUpCustomers, GetIndexAccountsByIds } = coordinators
  const { pRequestSearchPopUpCustomers, pResponseAccounts } = presenters.Api
  const searchPopUpCustomers = SearchPopUpCustomers({
    RestService,
    HandleError,
    pRequestSearchPopUpCustomers,
  })
  const getIndexAccountsByIds = GetIndexAccountsByIds({
    AccountService,
    RestService,
    pResponseAccounts,
  })

  return {
    getIndexAccountsByIds,
    searchPopUpCustomers,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpCustomersPage)
