export const SET_REFERRED_BY = 'SET_REFERRED_BY'
export const SET_REFERRAL_PARTNERS = 'SET_REFERRAL_PARTNERS'

export const setReferredBy = (referredBy) => ({
  type: SET_REFERRED_BY,
  referredBy,
})
export const setReferralPartners = (referralPartners) => ({
  type: SET_REFERRAL_PARTNERS,
  referralPartners,
})
