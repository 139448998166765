import {
  CLEAR_ERROR,
  CLEAR_ERRORS,
  CLEAR_NAMESPACE,
  UPDATE_ERRORS,
} from '@actions/ui/errors'

const initialState = {}

export const errors = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ERROR: {
      const { key, namespace } = action
      const newState = { ...state }
      let removed = false
      // check if there are even errors present to begin with
      if (newState[namespace] && newState[namespace][key]) {
        removed = true
        newState[namespace][key] = undefined
      }

      // some errors are not keyed, and just strings
      // ex. 'Concept is not approved', 'Menu Item is not approved'
      if (
        !removed &&
        newState[namespace] &&
        typeof newState[namespace] === 'string'
      ) {
        newState[namespace] = undefined
      }

      return newState
    }
    case CLEAR_ERRORS:
      return {}
    case CLEAR_NAMESPACE: {
      const { namespace } = action
      const newState = { ...state }
      if (newState[namespace]) {
        newState[namespace] = undefined
      }

      return newState
    }
    case UPDATE_ERRORS: {
      const { errors: errors_, namespace } = action
      const newErrors = { ...(state[namespace] || {}) }
      const newState = { ...state }
      if (typeof errors_ !== 'string') {
        for (const k in errors_) {
          if (errors_[k] === undefined) {
            delete newErrors[k]
          } else {
            newErrors[k] = errors_[k]
          }
        }
        newState[namespace] = newErrors
      } else {
        newState[namespace] = errors_
      }

      return newState
    }
    default:
      return state
  }
}
