import { connect } from 'react-redux'

import { ChefDirectory } from '@components/chefNetwork'
import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = () => undefined

const mapDispatchToProps = () => {
  const { HeadquarterService, RestService } = services
  const { LoadHeadquarters } = coordinators
  const { pBuildChefReportCSVRequest, pResponseGeneric } = presenters.Api

  const buildGetRequest = pBuildChefReportCSVRequest

  const loadHeadquarters = LoadHeadquarters({
    HeadquarterService,
    RestService,
    pResponseHeadquarters: pResponseGeneric,
    HandleError,
  })

  return {
    buildGetRequest,
    loadHeadquarters,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChefDirectory)
