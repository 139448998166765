import { connect } from 'react-redux'

import { ScheduleEditModal } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { locale } = state.user

  return {
    locale,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, AdminUserService, UIService } = services
  const {
    pGenerateRecurring,
    pRequestTimeSlot,
    pRequestTimeWeeks,
    pResponseAllTimeSlots,
  } = presenters.Api
  const {
    DeleteChefSchedule,
    DeleteDayFromRecurringSchedule,
    UpdateChefSchedule,
  } = coordinators

  const updateChefSchedule = UpdateChefSchedule({
    ChefService,
    RestService,
    AdminUserService,
    pRequestTimeSlot,
    pRequestTimeWeeks,
    pGenerateRecurring,
    pResponseAllTimeSlots,
    UIService,
    HandleError,
  })

  const deleteChefSchedule = DeleteChefSchedule({
    ChefService,
    RestService,
    AdminUserService,
    pGenerateRecurring,
    pRequestTimeWeeks,
    pResponseAllTimeSlots,
    UIService,
    HandleError,
  })

  const deleteDayFromRecurringSched = DeleteDayFromRecurringSchedule({
    ChefService,
    RestService,
    AdminUserService,
    pGenerateRecurring,
    pRequestTimeWeeks,
    pResponseAllTimeSlots,
    UIService,
    HandleError,
  })

  const { displayFailureMessage } = UIService.FlashMessage

  return {
    deleteChefSchedule,
    deleteDayFromRecurringSched,
    updateChefSchedule,
    displayFailureMessage,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleEditModal)
