import { connect } from 'react-redux'

import { PopUpDiscountModal } from '@components/popUps'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { user = {}, headquarter = {} } = state
  const { lastLoggedInHq } = user
  const { userHeadquarters = [] } = headquarter
  const userHeadquarter =
    userHeadquarters.find((hq) => hq.id === lastLoggedInHq) || {}

  return {
    locale: userHeadquarter.locale,
    headquarter: userHeadquarter.id,
  }
}

const mapDispatchToProps = () => {
  const {
    pResponseAccountExecutives,
    pResponseChefs,
    pResponseHeadquarters,
    pResponsePopUpAddOnMenuItems,
    pResponsePopUpServiceItems,
    pResponsePopUpConcepts,
    pResponsePopUpAccount,
    pResponsePopUpAccounts,
  } = presenters.Api
  const { RestService, UIService, PopUpDiscountService } = services
  const {
    DeletePopUp,
    GetDetailAccountsByIds,
    LoadAccount,
    LoadAccountExecutives,
    LoadMarkets,
    LoadPopUpMenuItems,
    LoadPopUpServiceItems,
    SaveDiscountLedger,
    SearchPopUpAddOnChefs,
    SearchDetailAccounts,
    SearchPopUpConcepts,
    SearchPopUpCustomers,
    SearchPopUps,
  } = coordinators
  const { newDiscountLedger } = PopUpDiscountService

  const getPopUpClient = LoadAccount({
    RestService,
    pResponseAccount: pResponsePopUpAccount,
  })
  const saveDiscountLedger = SaveDiscountLedger({
    RestService,
    UIService,
    HandleError,
  })
  const searchPopUpConcepts = SearchPopUpConcepts({
    RestService,
    pResponsePopUpConcepts,
    HandleError,
  })
  const loadMarkets = LoadMarkets({
    RestService,
    pResponseHeadquarters,
  })
  const loadPopUpMenuItems = LoadPopUpMenuItems({
    RestService,
    pResponseMenuItems: pResponsePopUpAddOnMenuItems,
  })
  const loadPopUpServiceItems = LoadPopUpServiceItems({
    RestService,
    pResponsePopUpServiceItems,
  })
  const searchPopUpClients = SearchDetailAccounts({
    RestService,
    pResponseAccounts: pResponsePopUpAccounts,
  })
  const getDetailAccountsByIds = GetDetailAccountsByIds({
    RestService,
    pResponseAccounts: pResponsePopUpAccounts,
  })
  const searchSalesReps = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })(false) // is not vcx
  const searchPopUpAddOnChefs = SearchPopUpAddOnChefs({
    RestService,
    pResponseChefs,
  })
  const searchPopUpCustomers = SearchPopUpCustomers({
    RestService,
    HandleError,
  })
  const deletePopUp = DeletePopUp({
    RestService,
    UIService,
    HandleError,
  })

  const searchPopUps = SearchPopUps({
    RestService,
    HandleError,
  })

  const showConfirmationModal = UIService.ConfirmationModal.show

  return {
    deletePopUp,
    getDetailAccountsByIds,
    getPopUpClient,
    loadMarkets,
    loadPopUpMenuItems,
    loadPopUpServiceItems,
    newDiscountLedger,
    saveDiscountLedger,
    searchPopUpAddOnChefs,
    searchPopUpClients,
    searchPopUpConcepts,
    searchPopUpCustomers,
    searchSalesReps,
    showConfirmationModal,
    searchPopUps,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpDiscountModal)
