import { camelCaseify } from '~/utils'

export const pResponseHeadquarters = (json) => {
  const markets = json

  return markets
}

export const pResponseServiceDepots = (depots) => {
  return camelCaseify(depots)
}

export const pResponseMarket = (json) => {
  const camelJson = camelCaseify(json)
  const result = {
    ...camelJson,
    zips: camelJson.zip,
  }
  delete result.zip

  return result
}

export const pResponseLastChangeToGroupOrderMinimumItems = (json) => {
  const camelJson = camelCaseify(json)

  return camelJson
}
export const pRequestUpdateHeadquarter = (data) => {
  data.address_attributes = data.address
  data.order_prefix = data.orderPrefix
  data.delivery_fee = parseFloat(data.deliveryFee)
  data.cart_minimum = parseFloat(data.cartMinimum)

  delete data.address
  delete data.orderPrefix
  delete data.deliveryFee
  delete data.cartMinimum

  return { headquarter: data }
}

export const pRequestUpdateDeliveryZone = (dZone) => {
  const { headquarter, zipcode } = dZone

  return {
    delivery_zone: {
      headquarter_id: headquarter.id,
      zipcode: zipcode,
    },
  }
}

export const pRequestUpdateServiceDepot = (serviceDepot) => {
  const { name, headquarter, isDefault } = serviceDepot

  return {
    service_depot: {
      name: name,
      headquarter_id: headquarter.id,
      address_attributes: serviceDepot.address,
      default: isDefault,
    },
  }
}
