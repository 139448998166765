import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import Select from 'react-select'
import { dayMap, rStatuses } from './constants'
import styled from '@emotion/styled'
import { colors } from '../../../constants'

import { AutocompleteInput } from '@containers/common/form'
import {
  Button,
  Checkbox,
  DateTextInput,
  Dropdown,
  LinkText,
  RadioButton,
  Input,
  TextArea,
} from '@components/common/form'
import Modal from '@components/common/modal/Modal'
import Table from '@components/common/Table'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import DividerLine from '@components/common/DividerLine'
import { ModalSectionTitle } from '@res/styledComponents/index'

import { deepCopy } from '~/utils'
import trash from '@res/images/trash-icon.png'

class RecurringPopUpModal extends Component {
  state = {
    rPopUp: undefined,
    onSiteVisibility: false,
    selectedMenu: undefined,
    selectedTimeSlot: undefined,
    addOnChef: undefined,
    onSiteChef: undefined,
    availableServiceItems: [],
    addOns: [],
    onSiteItems: [],
    timeSlotVisibility: {},
    loadingAddOnChef: false,
    loadingOnSiteChef: false,
    popUpConcepts: [],
  }
  saving = false
  taxTimer = undefined

  componentWillMount() {
    const { headquarter, newRecurringPopUp, rPopUp } = this.props
    const newState = {}
    if (!rPopUp) {
      newState.rPopUp = newRecurringPopUp({ headquarter })
    } else {
      newState.rPopUp = rPopUp
    }
    this.setState(newState)
    if (newState.rPopUp.client) {
      this.loadClient(newState.rPopUp.client)
    }
  }

  loadClient = async (rPopUpClient) => {
    const client = await this.props.getPopUpClient(rPopUpClient.id)
    if (client) {
      const { rPopUp } = this.state
      const existClient = rPopUp.client || {}
      this.setState({
        rPopUp: { ...rPopUp, client: { ...existClient, ...client } },
      })
    }
  }

  onChangeRecurringPopUp = (key, value) => {
    const { rPopUp } = this.state
    this.setState({ rPopUp: { ...rPopUp, [key]: value } })
  }

  onChangePrePaymentAmt = async (e) => {
    const { rPopUp, selectedTimeSlot: i } = this.state
    if (!rPopUp.location) {
      this.props.displayWarningMessage(
        'Must set location before entering a subsidy amount',
      )

      return
    }

    const timeSlot = { ...rPopUp.timeSlots[i] }
    timeSlot.prePaymentAmount = parseFloat(e.target.value)
    if (Number.isNaN(timeSlot.prePaymentAmount)) {
      timeSlot.prePaymentAmount = 0.0
    }

    this.setState({
      rPopUp: {
        ...rPopUp,
        timeSlots: [
          ...rPopUp.timeSlots.slice(0, i),
          timeSlot,
          ...rPopUp.timeSlots.slice(i + 1),
        ],
      },
    })

    const { location } = rPopUp
    this.calcTaxAfterTimeout(
      timeSlot.prePaymentAmount,
      location.line1,
      location.city,
      location.state,
      location.zip,
    )
  }

  onCalcTax = async (prePaymentAmount, line1, city, state, zip) => {
    const { rPopUp } = this.state
    const prePaymentTax = await this.props.getPopUpPrePaymentTax(
      rPopUp.client.id,
      prePaymentAmount,
      line1,
      city,
      state,
      zip,
    )
    if (prePaymentTax != null) {
      const { selectedTimeSlot: i } = this.state
      const timeSlot = { ...rPopUp.timeSlots[i] }
      timeSlot.prePaymentTax = prePaymentTax

      this.setState({
        rPopUp: {
          ...rPopUp,
          timeSlots: [
            ...rPopUp.timeSlots.slice(0, i),
            timeSlot,
            ...rPopUp.timeSlots.slice(i + 1),
          ],
        },
      })
    }
  }

  calcTaxAfterTimeout = (prePaymentAmount, line1, city, state, zip) => {
    if (this.taxTimer) {
      clearTimeout(this.taxTimer)
    }
    this.taxTimer = undefined
    this.taxTimer = setTimeout(() => {
      this.onCalcTax(prePaymentAmount, line1, city, state, zip)
    }, 1000)
  }

  onSelectClient = (client) => {
    const { rPopUp } = this.state
    this.setState({ rPopUp: { ...rPopUp, client } })
  }

  onSelectSalesRep = (salesRep) => {
    const { rPopUp } = this.state
    this.setState({ rPopUp: { ...rPopUp, salesRep } })
  }

  onSelectContact = (id) => {
    const { rPopUp } = this.state
    const contact = rPopUp.client.contacts.find((ct) => ct.id === id)
    this.setState({
      rPopUp: { ...rPopUp, client: { ...rPopUp.client, contact } },
    })
  }

  onChangeLocation = (id) => {
    const { rPopUp } = this.state
    const location = rPopUp.client.locations.find((loc) => loc.id === id)
    const start = rPopUp.start || Moment.tz(this.props.locale)
    this.setState({ rPopUp: { ...rPopUp, location, start } })
  }

  onChangeStatus = async (status) => {
    const doChange = await this.props.showConfirmationModal({
      text: `Are you sure you want to change status to ${status}?`,
    })
    if (!doChange) {
      return
    }
    const { rPopUp } = this.state
    this.setState({ rPopUp: { ...rPopUp, status } })
  }

  onSaveRecurringPopUp = async () => {
    const { saveRecurringPopUp, searchRecurringPopUps, hideModal } = this.props
    const { rPopUp } = this.state
    if (this.saving) {
      return
    }
    this.saving = true
    if (await saveRecurringPopUp(rPopUp)) {
      hideModal()
      searchRecurringPopUps()
    }
    this.saving = false
  }

  // Time Slots
  onCopySelected = () => {
    const { rPopUp, selectedTimeSlot: i } = this.state
    if (i == null) {
      this.props.displayWarningMessage('Must select a time slot to copy')

      return
    }
    const timeSlot = { ...deepCopy(rPopUp.timeSlots[i]), id: undefined }
    this.setState({
      rPopUp: { ...rPopUp, timeSlots: [...rPopUp.timeSlots, timeSlot] },
    })
    this.props.displaySuccessMessage('Copied selected time slot')
  }

  onDeleteTimeSlot = async (i) => {
    const doChange = await this.props.showConfirmationModal({
      text: 'Are you sure you want to delete this time slot?',
    })
    if (!doChange) {
      return
    }

    const { rPopUp, timeSlotVisibility } = this.state
    const newVisibility = rPopUp.timeSlots.reduce((map, ts, index) => {
      if (i !== index) {
        map[index < i ? index : index - 1] = timeSlotVisibility[index]
      }

      return map
    }, {})
    this.setState({
      timeSlotVisibility: newVisibility,
      selectedTimeSlot: undefined,
      rPopUp: {
        ...rPopUp,
        timeSlots: [
          ...rPopUp.timeSlots.slice(0, i),
          ...rPopUp.timeSlots.slice(i + 1),
        ],
      },
    })
  }

  onAddNewTimeSlot = () => {
    const { newTimeSlot } = this.props
    const { rPopUp } = this.state
    this.setState({
      rPopUp: { ...rPopUp, timeSlots: [...rPopUp.timeSlots, newTimeSlot()] },
    })
    this.props.displaySuccessMessage('Added new time slot')
  }

  onSelectTimeSlot = async (index) => {
    const { selectedTimeSlot: i } = this.state

    if (i != index) {
      this.setState(
        {
          selectedTimeSlot: index,
          selectedMenu: undefined,
          availableServiceItems: undefined,
          addOnChef: undefined,
          addOns: undefined,
        },
        this.reloadTimeSlotMenu,
      )
    }
  }

  reloadTimeSlotMenu = async () => {
    const { searchPopUpConcepts } = this.props
    const { rPopUp, selectedTimeSlot: i } = this.state
    const timeSlot = rPopUp.timeSlots[i]

    const menus = await searchPopUpConcepts()
    const selectedMenu = timeSlot.menu
      ? menus.find((m) => m.id === timeSlot.menu.id)
      : undefined
    const loadServiceItems = selectedMenu
      ? () => this.loadServiceItems(selectedMenu.chef.id)
      : null

    this.setState(
      {
        selectedMenu: selectedMenu,
        popUpConcepts: menus,
      },
      loadServiceItems,
    )
  }

  onToggleTimeSlotVisibility = (i) => {
    const { timeSlotVisibility } = this.state
    this.setState({
      timeSlotVisibility: {
        ...timeSlotVisibility,
        [i]: !timeSlotVisibility[i],
      },
    })
  }

  onChangeTimeSlot = (key, value) => {
    const { rPopUp, selectedTimeSlot: i } = this.state
    const timeSlot = { ...rPopUp.timeSlots[i], [key]: value }
    this.setState({
      rPopUp: {
        ...rPopUp,
        timeSlots: [
          ...rPopUp.timeSlots.slice(0, i),
          timeSlot,
          ...rPopUp.timeSlots.slice(i + 1),
        ],
      },
    })
  }

  onChangeTime = (time, isStart) => {
    const { rPopUp, selectedTimeSlot: i } = this.state
    const moment = Moment(time)
    const value = {
      hour: parseFloat(moment.hours()),
      minute: parseFloat(moment.minutes()),
    }
    const timeSlot = {
      ...rPopUp.timeSlots[i],
      [isStart ? 'start' : 'end']: value,
    }
    this.setState({
      rPopUp: {
        ...rPopUp,
        timeSlots: [
          ...rPopUp.timeSlots.slice(0, i),
          timeSlot,
          ...rPopUp.timeSlots.slice(i + 1),
        ],
      },
    })
  }

  // Menu
  onSelectMenu = (selectedMenu) => {
    const { rPopUp, selectedTimeSlot: i } = this.state
    const timeSlot = { ...rPopUp.timeSlots[i] }
    let { menu } = timeSlot
    if (!menu) {
      menu = {
        ...deepCopy(selectedMenu),
        entrees: [],
        sides: [],
        drinks: [],
        desserts: [],
        serviceItems: [],
      }
    } else if (menu.id !== selectedMenu.id) {
      menu = {
        ...deepCopy(selectedMenu),
        entrees: menu.entrees.filter((mi) => mi.supplierType === 'Add On'),
        sides: menu.sides.filter((mi) => mi.supplierType === 'Add On'),
        drinks: menu.drinks.filter((mi) => mi.supplierType === 'Add On'),
        desserts: menu.desserts.filter((mi) => mi.supplierType === 'Add On'),
        serviceItems: [],
      }
    }
    timeSlot.menu = menu
    this.setState(
      {
        selectedMenu,
        availableServiceItems: [],
        rPopUp: {
          ...rPopUp,
          timeSlots: [
            ...rPopUp.timeSlots.slice(0, i),
            timeSlot,
            ...rPopUp.timeSlots.slice(i + 1),
          ],
        },
      },
      this.calculateBalances,
    )
    this.loadServiceItems(menu.chef.id)
  }

  loadServiceItems = async (chefId) => {
    const availableServiceItems = await this.props.loadPopUpServiceItems(chefId)
    if (availableServiceItems) {
      this.setState({ availableServiceItems })
    }
  }

  onSelectAddOnChef = async (addOnChef) => {
    this.setState({ addOnChef, addOns: [], loadingAddOnChef: true })
    const addOns = await this.props.loadPopUpMenuItems(addOnChef.id)
    if (addOns) {
      this.setState({ addOns, loadingAddOnChef: false })
    }
  }
  // Service Items
  onAddServiceItem = (serviceItem) => {
    const { rPopUp, selectedTimeSlot: i } = this.state
    const timeSlot = { ...rPopUp.timeSlots[i] }
    const items = timeSlot.menu.serviceItems
    if (items.find((it) => it.id === serviceItem.id)) {
      return
    }
    timeSlot.menu.serviceItems = [...items, deepCopy(serviceItem)]
    this.setState({
      rPopUp: {
        ...rPopUp,
        timeSlots: [
          ...rPopUp.timeSlots.slice(0, i),
          timeSlot,
          ...rPopUp.timeSlots.slice(i + 1),
        ],
      },
    })
  }

  onChangeServiceItem = ({ idx, key, value }) => {
    const { rPopUp, selectedTimeSlot: i } = this.state
    const timeSlot = { ...rPopUp.timeSlots[i] }
    timeSlot.menu.serviceItems[idx][key] = value
    this.setState(
      {
        rPopUp: {
          ...rPopUp,
          timeSlots: [
            ...rPopUp.timeSlots.slice(0, i),
            timeSlot,
            ...rPopUp.timeSlots.slice(i + 1),
          ],
        },
      },
      this.calculateBalances,
    )
  }

  onDeleteServiceItem = (idx) => () => {
    const { rPopUp, selectedTimeSlot: i } = this.state
    const timeSlot = { ...rPopUp.timeSlots[i] }
    const items = timeSlot.menu.serviceItems
    timeSlot.menu.serviceItems = [
      ...items.slice(0, idx),
      ...items.slice(idx + 1, items.length),
    ]
    this.setState(
      {
        rPopUp: {
          ...rPopUp,
          timeSlots: [
            ...rPopUp.timeSlots.slice(0, i),
            timeSlot,
            ...rPopUp.timeSlots.slice(i + 1),
          ],
        },
      },
      this.calculateBalances,
    )
  }
  // On Site Items
  onSelectOnSiteChef = async (onSiteChef) => {
    this.setState({ onSiteChef, onSiteItems: [], loadingOnSiteChef: true })
    const onSiteItems = await this.props.loadPopUpOnSiteItems(onSiteChef.id)
    if (onSiteItems) {
      this.setState({ onSiteItems, loadingOnSiteChef: false })
    }
  }

  onSelectAllOnSite = () => {
    const { rPopUp, onSiteItems } = this.state
    const copiedItems = { entrees: [], sides: [], drinks: [], desserts: [] }
    onSiteItems.forEach((onSite) => {
      const { menuType } = onSite
      const onSiteCopy = { ...deepCopy(onSite), quantity: 500 }
      if (onSiteCopy.childItems.length > 0) {
        onSiteCopy.childItems.forEach((ci, i) => {
          onSiteCopy.childItems[i].quantity = 500
        })
      }
      copiedItems[menuType].push(onSiteCopy)
    })
    this.setState({ rPopUp: { ...rPopUp, onSiteItems: copiedItems } })
    this.props.displaySuccessMessage('Copied all on site items')
  }
  // Menu Items
  onAddMenuItem = ({ menuItem, menuType, supplierType }) => {
    const { rPopUp, selectedTimeSlot: i } = this.state
    if (supplierType === 'On Site') {
      const currentItems = rPopUp.onSiteItems[menuType]
      const index = currentItems.findIndex((it) => it.id === menuItem.id)
      if (index > -1) {
        const { price, quantity, quantitySold, quantityAdjusted, childItems } =
          currentItems[index]
        this.setState({
          rPopUp: {
            ...rPopUp,
            onSiteItems: {
              ...rPopUp.onSiteItems,
              [menuType]: [
                ...currentItems.slice(0, index),
                {
                  ...deepCopy(menuItem),
                  price,
                  quantity,
                  quantityAdjusted,
                  quantitySold,
                  childItems: childItems.map(
                    (
                      { price, quantity, quantitySold, quantityAdjusted },
                      childIndex,
                    ) => {
                      return {
                        ...deepCopy(menuItem.childItems[childIndex]),
                        price,
                        quantity,
                        quantitySold,
                        quantityAdjusted,
                      }
                    },
                  ),
                },
                ...currentItems.slice(index + 1),
              ],
            },
          },
        })
      } else {
        this.setState({
          rPopUp: {
            ...rPopUp,
            onSiteItems: {
              ...rPopUp.onSiteItems,
              [menuType]: [...currentItems, deepCopy(menuItem)],
            },
          },
        })
      }
    } else {
      const timeSlot = { ...rPopUp.timeSlots[i] }
      const currentItems = timeSlot.menu[menuType]
      const index = currentItems.findIndex((it) => it.id === menuItem.id)
      if (index > -1) {
        const { price, quantity, quantitySold, quantityAdjusted, childItems } =
          currentItems[index]
        timeSlot.menu[menuType] = [
          ...currentItems.slice(0, index),
          {
            ...deepCopy(menuItem),
            price,
            quantity,
            quantityAdjusted,
            quantitySold,
            childItems: childItems.map(
              (
                { price, quantity, quantitySold, quantityAdjusted },
                childIndex,
              ) => {
                return {
                  ...deepCopy(menuItem.childItems[childIndex]),
                  price,
                  quantity,
                  quantitySold,
                  quantityAdjusted,
                }
              },
            ),
          },
          ...currentItems.slice(index + 1),
        ]
      } else {
        timeSlot.menu[menuType] = [...currentItems, deepCopy(menuItem)]
      }
      this.setState({
        rPopUp: {
          ...rPopUp,
          timeSlots: [
            ...rPopUp.timeSlots.slice(0, i),
            timeSlot,
            ...rPopUp.timeSlots.slice(i + 1),
          ],
        },
      })
    }
  }

  onChangeMenuItem = ({ idx, key, value, menuType, supplierType }) => {
    const { rPopUp, selectedTimeSlot: i } = this.state
    if (supplierType === 'On Site') {
      rPopUp.onSiteItems[menuType][idx][key] = value
      this.setState({ rPopUp: { ...rPopUp } })
    } else {
      const timeSlot = { ...rPopUp.timeSlots[i] }
      timeSlot.menu[menuType][idx][key] = value
      this.setState(
        {
          rPopUp: {
            ...rPopUp,
            timeSlots: [
              ...rPopUp.timeSlots.slice(0, i),
              timeSlot,
              ...rPopUp.timeSlots.slice(i + 1),
            ],
          },
        },
        this.calculateBalances,
      )
    }
  }

  onDeleteMenuItem =
    ({ idx, menuType, supplierType }) =>
    () => {
      const { rPopUp, selectedTimeSlot: i } = this.state
      if (supplierType === 'On Site') {
        const items = rPopUp.onSiteItems[menuType]
        rPopUp.onSiteItems[menuType] = [
          ...items.slice(0, idx),
          ...items.slice(idx + 1, items.length),
        ]
        this.setState({ rPopUp: { ...rPopUp } })
      } else {
        const timeSlot = { ...rPopUp.timeSlots[i] }
        const items = timeSlot.menu[menuType]
        timeSlot.menu[menuType] = [
          ...items.slice(0, idx),
          ...items.slice(idx + 1, items.length),
        ]
        this.setState(
          {
            rPopUp: {
              ...rPopUp,
              timeSlots: [
                ...rPopUp.timeSlots.slice(0, i),
                timeSlot,
                ...rPopUp.timeSlots.slice(i + 1),
              ],
            },
          },
          this.calculateBalances,
        )
      }
    }

  onChangeChildItem = ({
    idx,
    childIdx,
    key,
    value,
    menuType,
    supplierType,
  }) => {
    const { rPopUp, selectedTimeSlot: i } = this.state
    if (supplierType === 'On Site') {
      rPopUp.onSiteItems[menuType][idx].childItems[childIdx][key] = value
      this.setState({ rPopUp: { ...rPopUp } })
    } else {
      const timeSlot = { ...rPopUp.timeSlots[i] }
      timeSlot.menu[menuType][idx].childItems[childIdx][key] = value
      this.setState(
        {
          rPopUp: {
            ...rPopUp,
            timeSlots: [
              ...rPopUp.timeSlots.slice(0, i),
              timeSlot,
              ...rPopUp.timeSlots.slice(i + 1),
            ],
          },
        },
        this.calculateBalances,
      )
    }
  }

  onDeleteChildItem =
    ({ idx, childIdx, menuType, supplierType }) =>
    () => {
      const { rPopUp, selectedTimeSlot: i } = this.state
      if (supplierType === 'On Site') {
        const items = rPopUp.onSiteItems[menuType]
        rPopUp.onSiteItems[menuType] = [
          ...items.slice(0, idx),
          {
            ...items[idx],
            childItems: [
              ...items[idx].childItems.slice(0, childIdx),
              ...items[idx].childItems.slice(
                childIdx + 1,
                items[idx].childItems.length,
              ),
            ],
          },
          ...items.slice(idx + 1, items.length),
        ]
        this.setState({ rPopUp: { ...rPopUp } })
      } else {
        const timeSlot = { ...rPopUp.timeSlots[i] }
        const items = timeSlot.menu[menuType]
        timeSlot.menu[menuType] = [
          ...items.slice(0, idx),
          {
            ...items[idx],
            childItems: [
              ...items[idx].childItems.slice(0, childIdx),
              ...items[idx].childItems.slice(
                childIdx + 1,
                items[idx].childItems.length,
              ),
            ],
          },
          ...items.slice(idx + 1, items.length),
        ]
        this.setState(
          {
            rPopUp: {
              ...rPopUp,
              timeSlots: [
                ...rPopUp.timeSlots.slice(0, i),
                timeSlot,
                ...rPopUp.timeSlots.slice(i + 1),
              ],
            },
          },
          this.calculateBalances,
        )
      }
    }

  calculateBalances = () => {
    const { rPopUp, selectedTimeSlot: i } = this.state
    const timeSlot = { ...rPopUp.timeSlots[i] }
    const { entrees, sides, drinks, desserts, serviceItems } = timeSlot.menu
    const allItems = [
      ...entrees,
      ...sides,
      ...drinks,
      ...desserts,
      ...serviceItems,
    ]
    const marketSubtotal = allItems.reduce(
      (ttl, it) => ttl + parseFloat(it.price) * it.quantity,
      0.0,
    )
    const chefSubtotal = allItems.reduce(
      (ttl, it) => ttl + parseFloat(it.chefPrice) * it.quantity,
      0.0,
    )

    timeSlot.marketSubtotal = marketSubtotal
    timeSlot.chefSubtotal = chefSubtotal
    this.setState({
      rPopUp: {
        ...rPopUp,
        timeSlots: [
          ...rPopUp.timeSlots.slice(0, i),
          timeSlot,
          ...rPopUp.timeSlots.slice(i + 1),
        ],
      },
    })
  }

  renderMenuItem = (
    { name, supplierType, quantity, price, chefPrice, childItems },
    menuType,
    idx,
  ) => {
    return (
      <tbody>
        <tr>
          <td>
            <Input
              label="" // QTY
              marginBottom="0"
              width="100px"
              type="number"
              value={quantity}
              onChange={(e) =>
                this.onChangeMenuItem({
                  idx,
                  key: 'quantity',
                  value: Math.trunc(parseFloat(e.target.value)),
                  menuType,
                  supplierType,
                })
              }
            />
          </td>
          <td>
            {name} {supplierType === 'Add On' && '(Add On)'}{' '}
            {supplierType === 'On Site' && '(On Site)'}
          </td>
          <td>${parseFloat(chefPrice).toFixed(2)}</td>
          <td>
            <Input
              label="" // Price
              marginBottom="0"
              width="100px"
              type="number"
              value={price}
              onChange={(e) =>
                this.onChangeMenuItem({
                  idx,
                  key: 'price',
                  value: parseFloat(e.target.value),
                  menuType,
                  supplierType,
                })
              }
            />
          </td>
          <td>
            <img
              onClick={this.onDeleteMenuItem({ idx, menuType, supplierType })}
              className="table-trash"
              src={trash}
            />
          </td>
        </tr>
        {(childItems || []).map((child, childIdx) => (
          <tr key={childIdx}>
            <td>
              <FlexContainer>
                <XSpacing width="30px" />
                <Input
                  label="Toppings" // QTY
                  marginBottom="0"
                  width="70px"
                  type="number"
                  value={child.quantity}
                  onChange={(e) =>
                    this.onChangeChildItem({
                      idx,
                      childIdx,
                      key: 'quantity',
                      value: Math.trunc(parseFloat(e.target.value)),
                      menuType,
                      supplierType,
                    })
                  }
                />
              </FlexContainer>
            </td>
            <td>{child.name}</td>
            <td>${parseFloat(child.chefPrice).toFixed(2)}</td>
            <td>
              <Input
                label="" // price
                marginBottom="0"
                width="100px"
                type="number"
                value={child.price}
                onChange={(e) =>
                  this.onChangeChildItem({
                    idx,
                    childIdx,
                    key: 'price',
                    value: parseFloat(e.target.value),
                    menuType,
                    supplierType,
                  })
                }
              />
            </td>
            <td>
              <img
                onClick={this.onDeleteChildItem({
                  idx,
                  childIdx,
                  menuType,
                  supplierType,
                })}
                className="table-trash"
                src={trash}
              />
            </td>
          </tr>
        ))}
      </tbody>
    )
  }

  renderServiceItem = ({ id, name, quantity, price, chefPrice }, idx) => {
    return (
      <tbody key={id}>
        <tr>
          <td>
            <Input
              label="" // QTY
              marginBottom="0"
              width="100px"
              type="number"
              value={quantity}
              onChange={(e) =>
                this.onChangeServiceItem({
                  idx,
                  key: 'quantity',
                  value: Math.trunc(parseFloat(e.target.value)),
                })
              }
            />
          </td>
          <td>{name}</td>
          <td>${parseFloat(chefPrice).toFixed(2)}</td>
          <td>
            <Input
              label="" // price
              marginBottom="0"
              width="100px"
              type="number"
              value={price}
              onChange={(e) =>
                this.onChangeServiceItem({
                  idx,
                  key: 'price',
                  value: parseFloat(e.target.value),
                })
              }
            />
          </td>
          <td>
            <img
              onClick={this.onDeleteServiceItem(idx)}
              className="table-trash"
              src={trash}
            />
          </td>
        </tr>
      </tbody>
    )
  }

  render() {
    const {
      addOns,
      addOnChef,
      onSiteChef,
      onSiteItems,
      availableServiceItems,
      selectedTimeSlot,
      rPopUp,
      selectedMenu,
      loadingAddOnChef,
      loadingOnSiteChef,
      timeSlotVisibility,
      popUpConcepts,
      onSiteVisibility,
    } = this.state

    if (!rPopUp) {
      return
    }

    const {
      id,
      client,
      headCount,
      name,
      startDay,
      location,
      salesRep,
      status,
    } = rPopUp
    const {
      headquarter,
      hideModal,
      locale,
      theme,
      searchPopUpAddOnChefs,
      searchPopUpClients,
      searchSalesReps,
    } = this.props
    const title = id ? 'Edit Recurring Pop Up' : 'New Recurring Pop Up'
    const menuHeadings = ['QTY', 'Name', 'Chef Price', 'Price', '']

    return (
      <Modal theme={theme} title={title} hideModal={hideModal} color="#001940">
        {id && (
          <PopupTitle>
            <h2>{name ? name : 'No Name'}</h2>
          </PopupTitle>
        )}
        <FlexContainer justifyContent="space-between">
          <Input
            label="Recurring Pop Up Name"
            marginBottom="0"
            width="48%"
            type="text"
            value={name}
            onChange={(e) =>
              this.onChangeRecurringPopUp('name', e.target.value)
            }
          />
          <Dropdown
            label="Status"
            width="48%"
            value={status}
            onChange={(e) => this.onChangeStatus(e.target.value)}
          >
            {(rStatuses || []).map((ele) => (
              <option key={ele} value={ele}>
                {ele}
              </option>
            ))}
          </Dropdown>
        </FlexContainer>
        <FlexContainer justifyContent="space-between">
          <Dropdown
            label="Start Day"
            width="48%"
            value={startDay}
            onChange={(e) =>
              this.onChangeRecurringPopUp(
                'startDay',
                parseFloat(e.target.value),
              )
            }
          >
            <option />
            {Object.entries(dayMap).map(([int, day]) => (
              <option key={day} value={int}>
                {day}
              </option>
            ))}
          </Dropdown>
          <Input
            label="Head Count"
            marginBottom="0"
            width="48%"
            type="text"
            value={headCount}
            onChange={(e) =>
              this.onChangeRecurringPopUp(
                'headCount',
                Math.trunc(parseFloat(e.target.value)),
              )
            }
          />
        </FlexContainer>
        {/* right column */}
        <FlexContainer justifyContent="space-between">
          <AutocompleteInput
            width="48%"
            label="Sales Rep"
            displayAttribute="firstName"
            loaderFunction={(search) => searchSalesReps({ ...search })}
            placeholder={'Search Sales Reps'}
            value={salesRep && salesRep.firstName}
            onSelect={this.onSelectSalesRep}
          />
          <AutocompleteInput
            width="48%"
            label="Client"
            displayAttribute="name"
            loaderFunction={(search) =>
              searchPopUpClients({ ...search, headquarter })
            }
            placeholder={'Search Clients'}
            value={client && client.name}
            onSelect={this.onSelectClient}
          />
        </FlexContainer>
        <YSpacing height="20px" />
        {!client && (
          <div>
            <Hint>*Please select client before entering logistic details</Hint>
            <YSpacing height="20px" />
          </div>
        )}
        {client && (
          <FlexContainer justifyContent="space-between">
            <Dropdown
              label="Location"
              width="48%"
              value={location && location.id}
              onChange={(e) => this.onChangeLocation(e.target.value)}
            >
              <option />
              {(client.locations || []).map((loc) => (
                <option key={loc.id} value={loc.id}>
                  {loc.name}
                </option>
              ))}
            </Dropdown>
            <Dropdown
              label="Contact"
              width="48%"
              value={client.contact && client.contact.id}
              onChange={(e) => this.onSelectContact(e.target.value)}
            >
              <option />
              {(client.contacts || []).map((ct) => (
                <option key={ct.id} value={ct.id}>
                  {ct.name}
                </option>
              ))}
            </Dropdown>
          </FlexContainer>
        )}
        {/* on site items */}
        <FlexContainer flexDirection="column">
          <AutocompleteInput
            width="100%"
            label="On Site Items"
            displayAttribute="name"
            loaderFunction={searchPopUpAddOnChefs}
            placeholder={'Search On Site Chefs'}
            value={onSiteChef ? onSiteChef.name : ''}
            onSelect={this.onSelectOnSiteChef}
          />
          {loadingOnSiteChef && <p>Loading On Site Chef...</p>}
          {!loadingOnSiteChef && onSiteChef && onSiteItems.length == 0 && (
            <p>Chef Has No On Site Items</p>
          )}
          {onSiteItems && onSiteItems.length > 0 && <YSpacing height="20px" />}
          {onSiteItems && onSiteItems.length > 0 && (
            <FlexContainer flexDirection="column">
              <Select
                className="mlb-select"
                placeholder="Include On Site Item"
                options={onSiteItems}
                labelKey="name"
                onChange={(menuItem) =>
                  this.onAddMenuItem({
                    menuItem,
                    menuType: menuItem.menuType,
                    supplierType: menuItem.supplierType,
                  })
                }
              />
              <YSpacing height="20px" />
              <Button label="Select All" onClick={this.onSelectAllOnSite} />
            </FlexContainer>
          )}
          <YSpacing height="20px" />
          <FlexContainer>
            <LinkText
              label="Show/Hide On Site Items"
              onClick={() =>
                this.setState({ onSiteVisibility: !onSiteVisibility })
              }
            />
          </FlexContainer>
          {onSiteVisibility && (
            <div>
              <YSpacing height="30px" />
              <MenuTitle>
                Entrees (
                {(rPopUp.onSiteItems &&
                  rPopUp.onSiteItems.entrees &&
                  rPopUp.onSiteItems.entrees.length) ||
                  0}
                )
              </MenuTitle>
              <Table headings={menuHeadings}>
                {((rPopUp.onSiteItems && rPopUp.onSiteItems.entrees) || []).map(
                  (menuItem, idx) =>
                    this.renderMenuItem(menuItem, 'entrees', idx),
                )}
              </Table>
              <YSpacing height="30px" />
              <MenuTitle>
                Sides (
                {(rPopUp.onSiteItems &&
                  rPopUp.onSiteItems.sides &&
                  rPopUp.onSiteItems.sides.length) ||
                  0}
                )
              </MenuTitle>
              <Table headings={menuHeadings}>
                {((rPopUp.onSiteItems && rPopUp.onSiteItems.sides) || []).map(
                  (menuItem, idx) =>
                    this.renderMenuItem(menuItem, 'sides', idx),
                )}
              </Table>
              <YSpacing height="30px" />
              <MenuTitle>
                Drinks (
                {(rPopUp.onSiteItems &&
                  rPopUp.onSiteItems.drinks &&
                  rPopUp.onSiteItems.drinks.length) ||
                  0}
                )
              </MenuTitle>
              <Table headings={menuHeadings}>
                {((rPopUp.onSiteItems && rPopUp.onSiteItems.drinks) || []).map(
                  (menuItem, idx) =>
                    this.renderMenuItem(menuItem, 'drinks', idx),
                )}
              </Table>
              <YSpacing height="30px" />
              <MenuTitle>
                Desserts (
                {(rPopUp.onSiteItems &&
                  rPopUp.onSiteItems.desserts &&
                  rPopUp.onSiteItems.desserts.length) ||
                  0}
                )
              </MenuTitle>
              <Table headings={menuHeadings}>
                {(
                  (rPopUp.onSiteItems && rPopUp.onSiteItems.desserts) ||
                  []
                ).map((menuItem, idx) =>
                  this.renderMenuItem(menuItem, 'desserts', idx),
                )}
              </Table>
            </div>
          )}
        </FlexContainer>
        {/* time slots */}
        <DividerLine margin="30px 0" />
        <ModalSectionTitle>
          Create Pop ups for designated times
        </ModalSectionTitle>
        <YSpacing height="20px" />
        <Button label="Add Time Slot" onClick={this.onAddNewTimeSlot} />
        <YSpacing height="20px" />
        {rPopUp && rPopUp.timeSlots.length > 0 && (
          <FlexContainer>
            <p style={{ marginLeft: '5px' }}>▼ Select to copy</p>
            <XSpacing width="10px" />
            {selectedTimeSlot >= 0 && (
              <LinkText
                label="Copy Selected Time Slot"
                onClick={this.onCopySelected}
              />
            )}
          </FlexContainer>
        )}
        <YSpacing height="10px" />
        {rPopUp &&
          rPopUp.timeSlots.length > 0 &&
          rPopUp.timeSlots.map((ts, i) => {
            //not ideal to use index as key
            const id = ts.id ? ts.id : i

            return (
              <FlexContainer key={id} alignItems="flex-start">
                <div style={{ marginTop: '7px' }}>
                  <RadioButton
                    label=""
                    value=""
                    name="time-slot"
                    checked={selectedTimeSlot === i}
                    onChange={() => this.onSelectTimeSlot(i)}
                  />
                </div>
                <Collapsible onClick={() => this.onSelectTimeSlot(i)}>
                  <CollapsibleHeading
                    onClick={() => this.onToggleTimeSlotVisibility(i)}
                  >
                    <FlexContainer justifyContent="space-between">
                      <p>
                        {ts.name ? ts.name : 'No Name'}
                        {ts.day != null
                          ? ` [ ${dayMap[ts.day]} ]`
                          : ' [ No Day ]'}
                        {ts.start != null
                          ? ` [ ${Moment(
                              ts.start.hour.toString() +
                                '.' +
                                ts.start.minute.toString(),
                              'LT',
                            ).format('h:mm a')} ]`
                          : ' [ No Time ]'}
                      </p>
                    </FlexContainer>
                    <LinkText
                      label={
                        timeSlotVisibility[i] ? 'Hide Details' : 'Show Details'
                      }
                    />
                  </CollapsibleHeading>
                  {timeSlotVisibility[i] && <DividerLine />}
                  {timeSlotVisibility[i] && (
                    <CollapsibleBody>
                      <FlexContainer justifyContent="space-between">
                        <Input
                          label="Pop Up Name"
                          marginBottom="0"
                          width="48%"
                          type="text"
                          value={ts.name}
                          onChange={(e) =>
                            this.onChangeTimeSlot('name', e.target.value)
                          }
                        />
                        <Dropdown
                          label="Day"
                          width="48%"
                          value={ts.day}
                          onChange={(e) =>
                            this.onChangeTimeSlot(
                              'day',
                              parseFloat(e.target.value),
                            )
                          }
                        >
                          {Object.entries(dayMap).map(([int, day]) => (
                            <option key={day} value={int}>
                              {day}
                            </option>
                          ))}
                        </Dropdown>
                      </FlexContainer>
                      <FlexContainer justifyContent="space-between">
                        <DateTextInput
                          className="w-full"
                          date={
                            ts.start &&
                            Moment(
                              ts.start.hour.toString() +
                                '.' +
                                ts.start.minute.toString(),
                              'LT',
                            )
                          }
                          label="start time"
                          width="48%"
                          onChange={(time) => this.onChangeTime(time, true)}
                          dateFormat={'h:mm a'}
                          timeZone={locale}
                        />
                        <DateTextInput
                          className="w-full"
                          date={
                            ts.end &&
                            Moment(
                              ts.end.hour.toString() +
                                '.' +
                                ts.end.minute.toString(),
                              'LT',
                            )
                          }
                          label="End time"
                          width="48%"
                          onChange={(time) => this.onChangeTime(time, false)}
                          dateFormat={'h:mm a'}
                          timeZone={locale}
                        />
                      </FlexContainer>
                      <YSpacing height="20px" />
                      <FlexContainer
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Dropdown
                          label="Set Up Style"
                          width="48%"
                          value={ts.setUpStyle}
                          onChange={(e) =>
                            this.onChangeTimeSlot('setUpStyle', e.target.value)
                          }
                        >
                          <option />
                          {['Pop-Up Boxes', 'Pop-Up Bowls'].map((style) => (
                            <option key={style} value={style}>
                              {style}
                            </option>
                          ))}
                        </Dropdown>
                        <FlexContainer justifyContent="flex-start" width="48%">
                          <Checkbox
                            label="Private?"
                            value={ts.isPrivate}
                            checked={ts.isPrivate}
                            onChange={(e) =>
                              this.onChangeTimeSlot(
                                'isPrivate',
                                e.target.checked,
                              )
                            }
                          />
                          <XSpacing width="20px" />
                          <Checkbox
                            label="Hidden?"
                            value={ts.isHidden}
                            checked={ts.isHidden}
                            onChange={(e) =>
                              this.onChangeTimeSlot(
                                'isHidden',
                                e.target.checked,
                              )
                            }
                          />
                        </FlexContainer>
                      </FlexContainer>
                      <FlexContainer justifyContent="space-between">
                        <TextArea
                          label="Services Notes"
                          width="48%"
                          type="number"
                          value={ts.servicesNotes}
                          onChange={(e) =>
                            this.onChangeTimeSlot(
                              'servicesNotes',
                              e.target.value,
                            )
                          }
                        />
                        <TextArea
                          label="Chef Notes"
                          width="48%"
                          type="number"
                          value={ts.chefNotes}
                          onChange={(e) =>
                            this.onChangeTimeSlot('chefNotes', e.target.value)
                          }
                        />
                      </FlexContainer>
                      <FlexContainer justifyContent="space-between">
                        <AutocompleteInput
                          label="Menu"
                          width="48%"
                          displayAttribute="autocompleteKey"
                          loaderFunction={() => popUpConcepts}
                          placeholder={'Search Concepts'}
                          value={
                            ts.menu && `${ts.menu.chef.name} - ${ts.menu.name}`
                          }
                          onSelect={this.onSelectMenu}
                        />
                        {ts.menu && (
                          <AutocompleteInput
                            label="Add On"
                            width="48%"
                            displayAttribute="name"
                            loaderFunction={searchPopUpAddOnChefs}
                            placeholder={'Search Add On Chefs'}
                            value={
                              selectedTimeSlot === i && addOnChef
                                ? addOnChef.name
                                : ''
                            }
                            onSelect={this.onSelectAddOnChef}
                          />
                        )}
                      </FlexContainer>
                      <YSpacing height="20px" />
                      {selectedTimeSlot === i && loadingAddOnChef && (
                        <p>Loading Add On Chef...</p>
                      )}
                      {selectedTimeSlot === i &&
                        !loadingAddOnChef &&
                        addOnChef &&
                        addOns.length == 0 && <p>Chef Has No Add Ons</p>}
                      {selectedTimeSlot === i &&
                        addOns &&
                        addOns.length > 0 && (
                          <Select
                            placeholder="Include Add On"
                            options={addOns}
                            labelKey="name"
                            onChange={(menuItem) =>
                              this.onAddMenuItem({
                                menuItem,
                                menuType: menuItem.menuType,
                              })
                            }
                          />
                        )}
                      <YSpacing height="30px" />
                      <MenuTitle>
                        Entrees (
                        {(ts.menu &&
                          ts.menu.entrees &&
                          ts.menu.entrees.length) ||
                          0}
                        )
                      </MenuTitle>
                      <Table headings={menuHeadings}>
                        {((ts.menu && ts.menu.entrees) || []).map(
                          (menuItem, idx) =>
                            this.renderMenuItem(menuItem, 'entrees', idx),
                        )}
                      </Table>
                      {selectedTimeSlot === i &&
                        selectedMenu &&
                        selectedMenu.entrees.length > 0 && (
                          <Select
                            className="mlb-select"
                            placeholder="Add Entree"
                            options={selectedMenu.entrees}
                            labelKey="name"
                            onChange={(menuItem) =>
                              this.onAddMenuItem({
                                menuItem,
                                menuType: 'entrees',
                              })
                            }
                          />
                        )}
                      <YSpacing height="30px" />
                      <MenuTitle>
                        Sides (
                        {(ts.menu && ts.menu.sides && ts.menu.sides.length) ||
                          0}
                        )
                      </MenuTitle>
                      <Table headings={menuHeadings}>
                        {((ts.menu && ts.menu.sides) || []).map(
                          (menuItem, idx) =>
                            this.renderMenuItem(menuItem, 'sides', idx),
                        )}
                      </Table>
                      {selectedTimeSlot === i &&
                        selectedMenu &&
                        selectedMenu.sides.length > 0 && (
                          <Select
                            className="mlb-select"
                            placeholder="Add Side"
                            options={selectedMenu.sides}
                            labelKey="name"
                            onChange={(menuItem) =>
                              this.onAddMenuItem({
                                menuItem,
                                menuType: 'sides',
                              })
                            }
                          />
                        )}
                      <YSpacing height="30px" />
                      <MenuTitle>
                        Drinks (
                        {(ts.menu && ts.menu.drinks && ts.menu.drinks.length) ||
                          0}
                        )
                      </MenuTitle>
                      <Table headings={menuHeadings}>
                        {((ts.menu && ts.menu.drinks) || []).map(
                          (menuItem, idx) =>
                            this.renderMenuItem(menuItem, 'drinks', idx),
                        )}
                      </Table>
                      {selectedTimeSlot === i &&
                        selectedMenu &&
                        selectedMenu.drinks.length > 0 && (
                          <Select
                            className="mlb-select"
                            placeholder="Add Drink"
                            options={selectedMenu.drinks}
                            labelKey="name"
                            onChange={(menuItem) =>
                              this.onAddMenuItem({
                                menuItem,
                                menuType: 'drinks',
                              })
                            }
                          />
                        )}
                      <YSpacing height="30px" />
                      <MenuTitle>
                        Desserts (
                        {(ts.menu &&
                          ts.menu.desserts &&
                          ts.menu.desserts.length) ||
                          0}
                        )
                      </MenuTitle>
                      <Table headings={menuHeadings}>
                        {((ts.menu && ts.menu.desserts) || []).map(
                          (menuItem, idx) =>
                            this.renderMenuItem(menuItem, 'desserts', idx),
                        )}
                      </Table>
                      {selectedTimeSlot === i &&
                        selectedMenu &&
                        selectedMenu.desserts.length > 0 && (
                          <Select
                            className="mlb-select"
                            placeholder="Add Dessert"
                            options={selectedMenu.desserts}
                            labelKey="name"
                            onChange={(menuItem) =>
                              this.onAddMenuItem({
                                menuItem,
                                menuType: 'desserts',
                              })
                            }
                          />
                        )}
                      <YSpacing height="30px" />
                      <MenuTitle>
                        Service Items (
                        {(ts.menu &&
                          ts.menu.serviceItems &&
                          ts.menu.serviceItems.length) ||
                          0}
                        )
                      </MenuTitle>
                      <Table headings={menuHeadings}>
                        {((ts.menu && ts.menu.serviceItems) || []).map(
                          (serviceItem, idx) =>
                            this.renderServiceItem(serviceItem, idx),
                        )}
                      </Table>
                      {selectedTimeSlot === i &&
                        availableServiceItems &&
                        availableServiceItems.length > 0 && (
                          <Select
                            className="mlb-select"
                            placeholder="Add Service Item"
                            options={availableServiceItems}
                            labelKey="name"
                            onChange={(serviceItem) =>
                              this.onAddServiceItem(serviceItem)
                            }
                          />
                        )}
                      <YSpacing height="30px" />
                      <Table
                        headings={[
                          'Chef Subtotal',
                          'Market Subtotal',
                          'Subsidy Amount',
                          'Subsidy Tax',
                        ]}
                      >
                        <tr>
                          <td> ${parseFloat(ts.chefSubtotal).toFixed(2)} </td>
                          <td> ${parseFloat(ts.marketSubtotal).toFixed(2)} </td>
                          <td>
                            <FlexContainer alignItems="center">
                              $
                              <Input
                                label="" // QTY
                                marginBottom="0"
                                width="100px"
                                type="number"
                                value={ts.prePaymentAmount}
                                onChange={this.onChangePrePaymentAmt}
                              />
                            </FlexContainer>
                          </td>
                          <td>
                            {' '}
                            $
                            {ts.prePaymentTax != null &&
                              parseFloat(ts.prePaymentTax).toFixed(2)}{' '}
                          </td>
                        </tr>
                      </Table>
                      <FlexContainer justifyContent="flex-end">
                        <Button
                          label="Delete Time Slot"
                          onClick={() => this.onDeleteTimeSlot(i)}
                          backgroundColor={colors.violet}
                        />
                      </FlexContainer>
                    </CollapsibleBody>
                  )}
                </Collapsible>
              </FlexContainer>
            )
          })}
        <YSpacing height="20px" />
        <FlexContainer justifyContent="flex-end">
          <Button
            label="Save Recurring Pop Up"
            onClick={this.onSaveRecurringPopUp}
          />
        </FlexContainer>
      </Modal>
    )
  }
}

const PopupTitle = styled.div`
  margin-bottom: 20px;
  h2 {
    color: ${colors.gray400};
    font-family: 'extrabold';
    font-size: 20px;
  }
}
`

const MenuTitle = styled.h2`
  font-family: 'extrabold';
  text-transform: uppercase;
  color: ${colors.orange};
`

const Collapsible = styled.div`
  border: 1px solid gray;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 8px;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
`

const CollapsibleHeading = styled.div`
  padding: 8px;
  display: flex;
  font-family: 'regular';
  p {
    font-size: 16px;
  }
`

const CollapsibleBody = styled.div`
  padding: 20px;
`

const Hint = styled.p`
  font-family: 'bold';
  color: ${colors.violet};
`

RecurringPopUpModal.propTypes = {
  headquarter: PropTypes.number,
  locale: PropTypes.string,
  rPopUp: PropTypes.object,
  theme: PropTypes.object,
  invoiceUrl: PropTypes.string,
  rPopUpUrl: PropTypes.string,

  displayWarningMessage: PropTypes.func,
  displaySuccessMessage: PropTypes.func,
  getPopUpClient: PropTypes.func,
  getPopUpPrePaymentTax: PropTypes.func,
  hideModal: PropTypes.func,
  loadPopUpMenuItems: PropTypes.func,
  loadPopUpOnSiteItems: PropTypes.func,
  loadPopUpServiceItems: PropTypes.func,
  newRecurringPopUp: PropTypes.func,
  newTimeSlot: PropTypes.func,
  newMenu: PropTypes.func,
  notifyPopUpChef: PropTypes.func,
  saveRecurringPopUp: PropTypes.func,
  searchPopUpAddOnChefs: PropTypes.func,
  searchRecurringPopUps: PropTypes.func,
  searchPopUpConcepts: PropTypes.func,
  searchPopUpClients: PropTypes.func,
  searchSalesReps: PropTypes.func,
  showConfirmationModal: PropTypes.func,
}

export default RecurringPopUpModal
