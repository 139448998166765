export const TOGGLE_EDIT_PROPOSAL_MODAL = 'TOGGLE_EDIT_PROPOSAL_MODAL'
export const TOGGLE_EMAIL_PROPOSAL_BUTTON_ENABLED =
  'TOGGLE_EMAIL_PROPOSAL_BUTTON_ENABLED'
export const TOGGLE_PROPOSAL_LOADING = 'TOGGLE_PROPOSAL_LOADING'
export const SET_LAST_LOAD_PROPOSALS_PARAMS = 'SET_LAST_LOAD_PROPOSALS_PARAMS'

export const setLastLoadProposalsParams = ({
  page,
  search,
  status,
  fromDate,
  toDate,
}) => ({
  type: SET_LAST_LOAD_PROPOSALS_PARAMS,
  page,
  search,
  status,
  fromDate,
  toDate,
})

export const toggleEditProposalModal = ({ show, mode, section }) => ({
  type: TOGGLE_EDIT_PROPOSAL_MODAL,
  show,
  mode,
  section,
})

export const toggleEmailProposalButtonEnabled = (on) => ({
  type: TOGGLE_EMAIL_PROPOSAL_BUTTON_ENABLED,
  on,
})

export const toggleProposalLoading = (on) => ({
  type: TOGGLE_PROPOSAL_LOADING,
  on,
})
