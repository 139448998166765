import React from 'react'
import PropTypes from 'prop-types'

class CheckboxCell extends React.Component {
  render() {
    const {
      order,
      attribute,
      className,
      key,
      orderUpdates,
      updateOrder,
      columnsLookup,
    } = this.props
    const orderUpdate = orderUpdates[order.id]
    const value = orderUpdate ? orderUpdate[attribute] : false
    const label = columnsLookup[attribute].header

    return (
      <td key={key} className={`${className}`}>
        <div>
          <input
            id={attribute + 'Checkbox'}
            type="checkbox"
            checked={value}
            onChange={(e) => updateOrder(order.id, attribute, e.target.checked)}
          />
          <label htmlFor={attribute + 'Checkbox'}>{label}</label>
        </div>
      </td>
    )
  }
}

CheckboxCell.propTypes = {
  order: PropTypes.object,
  attribute: PropTypes.string,
  className: PropTypes.string,
  columnsLookup: PropTypes.object,
  key: PropTypes.string,
  orderUpdates: PropTypes.arrayOf(PropTypes.object),

  updateOrder: PropTypes.func,
}

export default CheckboxCell
