import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Moment from 'moment-timezone'
import Modal from '@components/common/modal/Modal'
import PropTypes from 'prop-types'
import { colors } from '@constants'
import Checkbox from '@components/common/form/Checkbox'
import { Button } from '@components/common/form'

import { getWeeksInAMonth } from '~/utils'

const ChefNotifs = (props) => {
  const groupOrders = {}
  const [selectedOrders, setSelectedOrders] = useState({})

  useEffect(() => {
    if (props.autoStagedMenus) {
      const newSelectedOrders = {}
      props.autoStagedMenus.forEach((m) => {
        for (const [dateMenuId, chefId] of Object.entries(m)) {
          newSelectedOrders[dateMenuId][chefId] = true
        }
      })
      setSelectedOrders(newSelectedOrders)
    }
  }, [props.autoStagedMenus])

  getWeeksInAMonth(
    Moment(props.date).format('YYYY'),
    Moment(props.date).format('MM') - 1,
  ).forEach((element) => {
    const newGroupOrders = groupOrders
    element.forEach((day) => {
      newGroupOrders[day] =
        props.dateMenus &&
        props.dateMenus.filter((o) => {
          return Moment(o.date).format('M D') === day.format('M D')
        })
    })
  })

  const onNotifyChef = async () => {
    const { notifyChefs, setAutoStagedMenus, onHide } = props

    if (Object.keys(selectedOrders).length <= 0) {
      return
    }
    const ids = []
    Object.keys(selectedOrders).forEach((dateMenuId) => {
      Object.keys(selectedOrders[dateMenuId]).forEach((chefId) => {
        if (selectedOrders[dateMenuId][chefId]) {
          ids.push({ dateMenuId: dateMenuId, chefId: chefId })
        }
      })
    })

    if (ids.length <= 0) {
      return
    }

    const success = notifyChefs({ orders: { ids } })
    if (success) {
      setAutoStagedMenus({ autoStagedMenus: [] })
      onHide()
    }

    return success
  }

  return (
    <Modal hideModal={props.onHide} width="550px" height="600px">
      <Title>Select Chef To Notify of Updates</Title>
      <SubTitle>
        Select Chefs with menu changes to notify them of menu updates via email.
      </SubTitle>
      <SubSubTitle>
        (Chefs with no orders placed by customers yet will be skipped.)
      </SubSubTitle>
      <hr />
      {getWeeksInAMonth(
        Moment(props.date).format('YYYY'),
        Moment(props.date).format('MM') - 1,
      ).map((week, i) => (
        <div key={week[i].valueOf()}>
          <Week>
            {' '}
            {Moment(week[0]).format('MMM. D (ddd)')} to{' '}
            {Moment(week[6]).format('MMM. D (ddd)')} Orders
          </Week>

          {week.map((day) => {
            return (
              groupOrders[day] &&
              groupOrders[day].map((orders, j) => {
                return orders.menus.map((menu, k) => {
                  const selOrders = selectedOrders[orders.id]
                  const selChef = selOrders && selOrders[menu.chef.id]
                  if (!selOrders) {
                    selectedOrders[orders.id] = {}
                  }

                  return (
                    <p key={`${i}${j}${k}`} style={{ display: 'flex' }}>
                      <div>
                        <Checkbox
                          label={[
                            menu.chef.name,
                            ' - ',
                            menu.name,
                            ' - ',
                            Moment(orders.date).format('MMM. D (ddd)'),
                          ]}
                          checked={!!selChef}
                          onChange={(e) => {
                            if (e.target.checked) {
                              selectedOrders[orders.id][menu.chef.id] = true
                              setSelectedOrders({ ...selectedOrders })
                            } else {
                              selectedOrders[orders.id][menu.chef.id] = false
                              setSelectedOrders({ ...selectedOrders })
                            }
                          }}
                        />
                      </div>
                      <hr />
                    </p>
                  )
                })
              })
            )
          })}
        </div>
      ))}
      <Button margin="5% 30%" label="SEND CHEF EMAILS" onClick={onNotifyChef} />
    </Modal>
  )
}

const Title = styled.h1`
  color: ${colors.gray400};
  font-family: 'bold';
  font-size: 32px;
`
const SubTitle = styled.p`
  font-family: 'bold';
  font-size: 18px;
  padding: 20px 0;
`
const SubSubTitle = styled.p`
  font-family: 'bold';
  font-size: 14px;
  padding: 0px 0;
`
const Week = styled.h1`
  font-family: 'bold';
  font-size: 15px;
  padding: 20px 0;
`

ChefNotifs.propTypes = {
  onHide: PropTypes.func,
  date: PropTypes.object,
  dateMenus: PropTypes.arrayOf(PropTypes.object),
  autoStagedMenus: PropTypes.func,
  notifyChefs: PropTypes.func,
  setAutoStagedMenus: PropTypes.func,
}

export default ChefNotifs
