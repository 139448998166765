import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { OrderablePage } from '@components/orderable'

import { OrderDetail, OrderMaster } from '@containers/order'

class OrderPage extends Component {
  render() {
    const { checkQueryOrder, filters, orderableType, orderNumber, newOrder } =
      this.props

    return (
      <OrderablePage
        initialFilter={'open'}
        filters={filters}
        orderableDetail={<OrderDetail />}
        orderableMaster={(props) => <OrderMaster {...props} />}
        orderableNumber={orderNumber}
        orderableType={orderableType}
        renderDashboard={true}
        checkQueryOrderable={checkQueryOrder}
        newOrderable={newOrder}
      />
    )
  }
}

OrderPage.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string),
  orderableType: PropTypes.string,
  accountName: PropTypes.string,
  orderNumber: PropTypes.string,

  checkQueryOrder: PropTypes.func,
  newOrder: PropTypes.func,
}

export default OrderPage
