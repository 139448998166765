import React, { Component } from 'react'
import Moment from 'moment-timezone'
import Button from '@components/common/form/Button'
import YSpacing from '@components/common/YSpacing'
import Panel from '@components/common/Panel'

import { MarketDropdown } from '@components/common/form'

import { DateTextInput, LinkText } from '@components/common/form'
import { AutocompleteInput } from '@containers/common/form'
import LoadingIndicator from '@components/common/LoadingIndicator'

const DateFormat = 'MM/DD/YYYY h:mm a'

const REPORT_TYPE = {
  ORDERS: 'orders',
  ITEMS_ORDERED: 'itemsOrdered',
}

type SearchGroupOrdersParams = {
  start: Moment.Moment
  end: Moment.Moment
  clientId: string | null
  email?: string
  hqIds?: number[]
}

type Client = {
  id: string | null
}

type GroupOrderClient = {
  name: string
  location: string
}

type Contact = {
  email: string
}

type SalesRep = {
  firstName: string
  lastName: string
  email: string
}

type SubsidySettings = {
  subsidyType: string
}

type CostUpdates = {
  changeSvcReason: string
  previousSvcFee: number
  adjustedTipFee: number
  previousTipFee: number
  changeTipReason: string
}

type GroupOrder = {
  number: string
  date: string
  client: GroupOrderClient
  contact: Contact
  salesRep: SalesRep
  status: string
  subsidySettings: SubsidySettings | null
  perOrderSubsidy: number
  totalSubsidy: number
  totalWalletAmount: number
  serviceFee: number
  tax: number
  tip: number
  total: number
  costUpdates: CostUpdates
}

type SubOrder = {
  groupOrderChefs: Chef[]
  customer: Customer
  groupOrderNumber: string
  groupOrderCreatedAt: string
  createdAt: string
  menus: Menu[]
  date: string
  client: GroupOrderClient
  status: string
  groupOrderTotal: number
  clientAmount: number
}

type Chef = {
  id: string
  name: string
}

type Customer = {
  id: string
  firstName: string
  lastName: string
  email: string
}

type OrderItem = {
  chefId: string
  name: string
  description: string
  quantity: number
  cost: number
  price: number
  itemType: string
  menuName: string
  groupOrderNumber: string
  groupOrderCreatedAt: string
  createdAt: string
  date: string
  status: string
  client: Client
  customer: Customer
  groupOrderTotal: number
  clientAmount: number
}

type Menu = {
  menuName: string
  orderItems: OrderItem[]
}

type CurrentUser = {
  email: string
}

type Headquarter = {
  id: number
  name: string
}

type Props = {
  locale: string
  headquarter: number
  currentUser: CurrentUser
  searchGroupOrdersByDateRange: (
    params: SearchGroupOrdersParams,
  ) => Promise<GroupOrder[] | null>
  searchGroupOrderSubOrders: (
    params: SearchGroupOrdersParams,
  ) => Promise<SubOrder[] | null>
  searchGroupOrderSubOrdersCount: (
    params: SearchGroupOrdersParams,
  ) => Promise<number | null>
  searchGroupOrdersCount: (
    params: SearchGroupOrdersParams,
  ) => Promise<number | null>
  requestSendGroupOrderItemsReport: (
    params: SearchGroupOrdersParams,
  ) => Promise<string | null>
  requestSendGroupOrdersReport: (
    params: SearchGroupOrdersParams,
  ) => Promise<string | null>
  searchGroupOrdersClients: (params: any) => any
  loadHeadquarters: () => Promise<Headquarter[] | null>
}

type State = {
  ordersCSVStart: Moment.Moment
  ordersCSVEnd: Moment.Moment
  ordersInDateRange: Record<string, string>[]
  itemsOrderedInDateRange: Record<string, string>[]
  numberOfOrders: number
  client: Client
  isLoading: boolean
  reportType: string
  headquarters: Headquarter[]
  selectedHeadquarters: number[]
}

class GroupOrderReportsPage extends Component<Props, State> {
  state: State = {
    ordersCSVStart: Moment.tz(this.props.locale),
    ordersCSVEnd: Moment.tz(this.props.locale),
    ordersInDateRange: [],
    itemsOrderedInDateRange: [],
    numberOfOrders: 0,
    client: { id: null },
    isLoading: false,
    reportType: REPORT_TYPE.ORDERS,
    headquarters: [],
    selectedHeadquarters: [],
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      prevState.ordersCSVStart !== this.state.ordersCSVStart ||
      prevState.ordersCSVEnd !== this.state.ordersCSVEnd ||
      prevState.client.id !== this.state.client.id ||
      prevState.reportType !== this.state.reportType ||
      prevState.selectedHeadquarters !== this.state.selectedHeadquarters
    ) {
      this.searchGroupOrders()
    }
  }

  componentDidMount = async () => {
    const { loadHeadquarters } = this.props
    const headquarters = await loadHeadquarters()
    if (headquarters) {
      this.setState({
        headquarters: [{ id: 0, name: 'All' }, ...headquarters],
      })
    }
  }

  searchGroupOrders = async () => {
    this.setState({ isLoading: true })
    const {
      ordersCSVStart,
      ordersCSVEnd,
      client,
      reportType,
      selectedHeadquarters,
    } = this.state
    const params = {
      start: ordersCSVStart,
      end: ordersCSVEnd,
      clientId: client.id,
      hqIds: selectedHeadquarters,
    }
    if (reportType === REPORT_TYPE.ORDERS) {
      const count = await this.props.searchGroupOrdersCount(params)
      if (typeof count === 'number') {
        this.setState({ numberOfOrders: count })
      }
    } else if (reportType === REPORT_TYPE.ITEMS_ORDERED) {
      const count = await this.props.searchGroupOrderSubOrdersCount(params)
      if (typeof count === 'number') {
        this.setState({ numberOfOrders: count })
      }
    }
    this.setState({ isLoading: false })
  }

  handleRequestSendReport = async () => {
    const { currentUser } = this.props
    const {
      ordersCSVStart,
      ordersCSVEnd,
      client,
      reportType,
      selectedHeadquarters,
    } = this.state

    const params = {
      start: ordersCSVStart,
      end: ordersCSVEnd,
      clientId: client.id,
      email: currentUser.email,
      hqIds: selectedHeadquarters,
    }

    if (reportType === REPORT_TYPE.ORDERS) {
      await this.props.requestSendGroupOrdersReport(params)
    } else if (reportType === REPORT_TYPE.ITEMS_ORDERED) {
      await this.props.requestSendGroupOrderItemsReport(params)
    }
  }

  onSelectClient = (client: Client) => {
    this.setState({ client })
  }

  onSelectHq = (hqId: number) => {
    const { headquarters, selectedHeadquarters } = this.state
    if (hqId === 0) {
      this.setState({
        selectedHeadquarters: headquarters
          .filter((hq) => hq.id !== 0)
          .map((hq) => hq.id),
      })
    } else if (!selectedHeadquarters.includes(hqId)) {
      this.setState({
        selectedHeadquarters: [...selectedHeadquarters, hqId],
      })
    }
  }

  onDeselectHq = (hqId: number) => {
    const { selectedHeadquarters } = this.state
    if (hqId === 0) {
      this.setState({
        selectedHeadquarters: [],
      })
    } else {
      this.setState({
        selectedHeadquarters: selectedHeadquarters.filter((id) => id !== hqId),
      })
    }
  }

  render() {
    const { locale, headquarter } = this.props
    const {
      ordersCSVStart,
      ordersCSVEnd,
      numberOfOrders,
      headquarters,
      selectedHeadquarters,
    } = this.state

    return (
      <Panel width="100%" maxWidth="500px" heading="Orders Report">
        <select onChange={(e) => this.setState({ reportType: e.target.value })}>
          <option value={REPORT_TYPE.ORDERS}>Orders</option>
          <option value={REPORT_TYPE.ITEMS_ORDERED}>Items ordered</option>
        </select>
        <YSpacing height="20px" />
        <MarketDropdown
          label="Market(s)"
          onSelectHq={this.onSelectHq}
          onDeselectHq={this.onDeselectHq}
          options={headquarters}
          selectedOptions={selectedHeadquarters}
        />
        <YSpacing height="20px" />
        <DateTextInput
          label="Start Time"
          date={ordersCSVStart}
          onChange={(date: Moment.Moment) =>
            this.setState({ ordersCSVStart: date })
          }
          dateFormat={DateFormat}
          timeZone={locale}
        />
        <YSpacing height="20px" />
        <DateTextInput
          label="End Time"
          date={ordersCSVEnd}
          onChange={(date: Moment.Moment) =>
            this.setState({ ordersCSVEnd: date })
          }
          dateFormat={DateFormat}
          timeZone={locale}
        />
        <YSpacing height="20px" />
        <AutocompleteInput
          displayAttribute="name"
          loaderFunction={(search) =>
            this.props.searchGroupOrdersClients({ ...search, headquarter })
          }
          placeholder={'Search Clients'}
          onSelect={this.onSelectClient}
        />

        {this.state.client.id && (
          <div>
            <YSpacing height="20px" />
            <Button
              onClick={() => this.setState({ client: { id: null } })}
              label="Clear client"
            />
          </div>
        )}

        <YSpacing height="20px" />
        <span className="mr-4">
          Found&nbsp;
          {`${numberOfOrders} order(s)`}&nbsp;
        </span>
        <YSpacing height="20px" />
        {this.state.isLoading ? <LoadingIndicator /> : null}
        {!this.state.isLoading && this.state.numberOfOrders > 0 && (
          <LinkText
            label={`Download CSV`}
            onClick={this.handleRequestSendReport}
          />
        )}
      </Panel>
    )
  }
}

export default GroupOrderReportsPage
