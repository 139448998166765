import { connect } from 'react-redux'

import SurveyDashboardList from '@components/survey/SurveyDashboardList'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'

const mapStateToProps = (state) => {
  const { surveysDashboard: surveys } = state

  return { surveys }
}

const mapDispatchToProps = () => {
  const { RestService, SurveyService } = services
  const { LoadDashboardSurveys } = coordinators
  const { pRequestDashboardSurveys, pResponseDashboardSurveys } = presenters.Api

  const loadDashboardSurveys = LoadDashboardSurveys({
    RestService,
    SurveyService,
    pRequestDashboardSurveys,
    pResponseDashboardSurveys,
  })

  return { loadDashboardSurveys }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDashboardList)
