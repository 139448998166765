import { connect } from 'react-redux'

import { OrderDetail } from '@components/order'

import services from '@services'

const mapStateToProps = (state) => {
  const { order, hubspotDeal } = state
  const { OrderService } = services
  const clearOrder = OrderService.clearOrderable

  return { order, hubspotDeal, clearOrder }
}

export default connect(mapStateToProps)(OrderDetail)
