import {
  APPEND_BATCH_ORDERS,
  CLEAR_EDIT_BATCH_ORDER,
  CLEAR_NEW_BATCH_ORDER,
  CLEAR_BATCH_ORDER,
  CLEAR_BATCH_ORDERS,
  REMOVE_BATCH_ORDER_FROM_ORDERS,
  SET_EDIT_BATCH_ORDER,
  SET_NEW_BATCH_ORDER,
  SET_BATCH_ORDER,
  SET_BATCH_ORDERS,
  SET_CHILD_ORDERS,
  UPDATE_EDIT_BATCH_ORDER,
  UPDATE_NEW_BATCH_ORDER,
} from '@actions/batchOrder'

const initialEditBatchOrderState = {}
const initialNewBatchOrderState = {}
const initialBatchOrderState = null
const initialBatchOrdersState = []
const initialChildOrdersState = []

export function batchOrder(state = initialBatchOrderState, action) {
  const { type, batchOrder } = action

  switch (type) {
    case CLEAR_BATCH_ORDER:
      return initialBatchOrderState
    case SET_BATCH_ORDER:
      return { ...batchOrder }
    default:
      return state
  }
}

export function batchOrders(state = initialBatchOrdersState, action) {
  const { type, batchOrders } = action

  switch (type) {
    case APPEND_BATCH_ORDERS:
      return [...state, ...batchOrders]
    case CLEAR_BATCH_ORDERS:
      return initialBatchOrdersState.slice()
    case SET_BATCH_ORDERS:
      return batchOrders.slice()
    case REMOVE_BATCH_ORDER_FROM_ORDERS: {
      const newBatchOrders = state.slice()
      const idx = newBatchOrders.findIndex((o) => o.id === action.orderId)
      if (idx !== -1) {
        newBatchOrders.splice(idx, 1)
      }

      return newBatchOrders
    }
    default:
      return state
  }
}

export function childOrders(state = initialChildOrdersState, action) {
  const { type, childOrders } = action

  switch (type) {
    case SET_CHILD_ORDERS:
      return childOrders.slice()
    default:
      return state
  }
}

export function editBatchOrder(state = initialEditBatchOrderState, action) {
  const { type, batchOrder } = action

  switch (type) {
    case SET_EDIT_BATCH_ORDER:
      return { ...batchOrder }
    case UPDATE_EDIT_BATCH_ORDER:
      return { ...state, ...batchOrder }
    case CLEAR_EDIT_BATCH_ORDER:
      return { ...initialEditBatchOrderState }
    default:
      return state
  }
}

export function newBatchOrder(state = initialNewBatchOrderState, action) {
  const { type, batchOrder } = action

  switch (type) {
    case SET_NEW_BATCH_ORDER:
      return { ...batchOrder }
    case UPDATE_NEW_BATCH_ORDER:
      return { ...state, ...batchOrder }
    case CLEAR_NEW_BATCH_ORDER:
      return { ...initialEditBatchOrderState }
    default:
      return state
  }
}
