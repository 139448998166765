import { BaseService } from 'hungry-core2'

import actions from '@actions'

export default class BatchOrderPage extends BaseService {
  toggleInvoiceButtonEnabled = (on) => {
    this.dispatch(actions.toggleInvoiceButtonEnabled(on))
  }

  setLastLoadBatchOrdersParams = ({ page, search, status }) => {
    this.dispatch(
      actions.setLastLoadBatchOrdersParams({ page, search, status }),
    )
  }

  setBatchPaymentProcessing = (processing) => {
    this.dispatch(actions.setBatchPaymentProcessing(processing))
  }

  getLastLoadBatchOrdersParams = () => {
    const { page, search, status } = this.getState().batchOrderPage

    return { page, search, status }
  }
}
