export const LoadMLBPartner =
  ({ RestService, pResponseMLBPartner, HandleError }) =>
  async ({ mlbPartnerName }) => {
    let data
    try {
      data = await RestService.get(`mlb/${mlbPartnerName}`)
    } catch (error) {
      HandleError({ error })

      return null
    }

    return pResponseMLBPartner(data)
  }

export const LoadMLBMenu =
  ({ RestService, HandleError }) =>
  async ({ mlbPartnerName, mDate, zipCode }) => {
    let menu
    try {
      menu = await RestService.get(`mlb/${mlbPartnerName}/menu`, {
        date: mDate.format(),
        zipCode,
      })
    } catch (error) {
      HandleError({ error })

      return null
    }

    return menu
  }

export const LoadAllMLBItems =
  ({ RestService, HandleError }) =>
  async ({ mlbPartnerName }) => {
    let menu
    try {
      menu = await RestService.get(`mlb/${mlbPartnerName}/all_items`)
    } catch (error) {
      HandleError({ error })

      return null
    }

    return menu
  }

export const LoadTaxCodes =
  ({ RestService, HandleError }) =>
  async () => {
    let taxCodes = []
    try {
      taxCodes = await RestService.get('mlb/list_tax_codes')
    } catch (error) {
      HandleError({ error })

      return []
    }

    return taxCodes
  }

export const LoadMLBOrders =
  ({ RestService, HandleError }) =>
  async ({ partnerId, date, mailOrder, delivered, search }) => {
    try {
      const allOrders = []
      let offset = 0
      const limit = 100
      while (true) {
        const params = {
          mlbPartnerId: partnerId,
          status: 'Created',
          offset,
          limit,
        }
        if (mailOrder != null) {
          params.mailOrder = mailOrder
        }
        if (date != null) {
          params.gameday = date.format('YYYY-MM-DD')
        }
        if (delivered != null) {
          params.status = delivered ? 'Delivered' : 'Created'
        }
        if (search != null) {
          params.search = search
        }
        const orders = await RestService.get(`/mlb/${partnerId}/orders`, params)
        if (!orders || orders.length === 0) {
          break
        }
        allOrders.push(...orders)
        offset += limit
      }

      return allOrders
    } catch (error) {
      HandleError({ error })

      return []
    }
  }
