import React, { Component } from 'react'
import PropTypes from 'prop-types'

import OrderServiceItem from '@containers/order/edit/sections/OrderServiceItem'

class ServiceItemsSection extends Component {
  render() {
    const {
      onEditOrderServiceItem,
      onEditCustomOrderServiceItem,
      onRemoveOrderServiceItem,
      onRemoveCustomOrderServiceItem,
      onReorderCustomServiceItem,
      onReorderServiceItem,
    } = this.props
    const orderServiceItems = this.props.orderServiceItems || []
    const customOrderServiceItems = this.props.customOrderServiceItems || []

    if (orderServiceItems.length + customOrderServiceItems.length === 0) {
      return null
    }

    return (
      <div className="mt-5">
        <table className="w-100 border-separate order-table">
          <tr>
            <td />
            <td className="w-55 bold uppercase"> Service Item Name </td>
            <td className="bold uppercase text-right"> QTY </td>
            <td className="bold uppercase text-right"> Rate QTY </td>
            <td className="bold uppercase text-right"> Vendor Price </td>
            <td className="bold uppercase text-right"> Client Price </td>
            <td className="bold uppercase text-right"> Total </td>
          </tr>
          {orderServiceItems.map((i, index) => (
            <OrderServiceItem
              key={i.displayOrder}
              serviceItem={i}
              onEdit={onEditOrderServiceItem}
              onRemove={onRemoveOrderServiceItem}
              onReorder={onReorderServiceItem}
              isCustom={false}
              isFirst={index == 0}
              isLast={index == orderServiceItems.length - 1}
            />
          ))}
          {customOrderServiceItems.map((i, index) => (
            <OrderServiceItem
              key={i.displayOrder}
              serviceItem={i}
              onEdit={onEditCustomOrderServiceItem}
              onRemove={onRemoveCustomOrderServiceItem}
              onReorder={onReorderCustomServiceItem}
              isCustom={true}
              isFirst={index == 0}
              isLast={index == customOrderServiceItems.length - 1}
            />
          ))}
        </table>
      </div>
    )
  }
}

ServiceItemsSection.propTypes = {
  orderServiceItems: PropTypes.arrayOf(PropTypes.object),
  customOrderServiceItems: PropTypes.arrayOf(PropTypes.object),

  onEditOrderServiceItem: PropTypes.func,
  onEditCustomOrderServiceItem: PropTypes.func,
  onRemoveOrderServiceItem: PropTypes.func,
  onRemoveCustomOrderServiceItem: PropTypes.func,
  onReorderCustomServiceItem: PropTypes.func,
  onReorderServiceItem: PropTypes.func,
}

export default ServiceItemsSection
