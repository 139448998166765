import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/common/modal/Modal'
import { Button, Input, LinkText, Checkbox } from '@components/common/form'

const infoCopy =
  'All Group Order accounts will be defaulted to the Standard Fee ( 1-9 meals = $125; 10-24 meals = $65; >24 meals = $1.25/meal). *For multi-location accounts, if the locations have the same address, they will be treated as one location. If they have different addresses, they will be treated separately.'

const initialFormState = {
  flatFeeEnabled: false,
  percentFeeEnabled: false,
  perOrderFeeEnabled: false,
  waiveAllFees: false,
  flatFee: '',
  flatFeeWaiveStandard: false,
  percentFee: '',
  percentFeeWaiveStandard: false,
  perOrderFee: '',
  perOrderFeeWaiveStandard: false,
  perOrderFeeNoApplyAboveStandard: false,
}

const initialErrorsState = {
  flatFee: '',
  percentFee: '',
  perOrderFee: '',
}

const initialState = {
  form: { ...initialFormState },
  errors: { ...initialErrorsState },
  saveReqLoading: false,
}

class EditGroupOrderDeliveryFeeSettingsModal extends Component {
  state = { ...initialState }

  componentDidMount() {
    const { clientGlobalSettings } = this.props
    if (clientGlobalSettings && clientGlobalSettings.deliveryFeeSettings) {
      this.setForm(clientGlobalSettings.deliveryFeeSettings)
    }
  }

  componentDidUpdate(prevProps) {
    const { clientGlobalSettings } = this.props
    const prevId = prevProps.clientGlobalSettings
      ? prevProps.clientGlobalSettings.id
      : null
    if (
      clientGlobalSettings &&
      clientGlobalSettings.deliveryFeeSettings &&
      clientGlobalSettings.id !== prevId
    ) {
      this.setForm(clientGlobalSettings.deliveryFeeSettings)
    }
  }

  setForm = (deliveryFeeSettings) => {
    const flatFee = deliveryFeeSettings.flatFee
      ? parseFloat(deliveryFeeSettings.flatFee)
      : ''
    const percentFee = deliveryFeeSettings.percentFee
      ? parseFloat(deliveryFeeSettings.percentFee)
      : ''
    const perOrderFee = deliveryFeeSettings.perOrderFee
      ? parseFloat(deliveryFeeSettings.perOrderFee)
      : ''
    const nextState = {
      ...this.state,
      form: {
        flatFeeEnabled: Boolean(flatFee),
        percentFeeEnabled: Boolean(percentFee),
        perOrderFeeEnabled: Boolean(perOrderFee),
        waiveAllFees: deliveryFeeSettings.waiveAllFees,
        flatFee,
        flatFeeWaiveStandard: deliveryFeeSettings.flatFeeWaiveStandard,
        percentFee,
        percentFeeWaiveStandard: deliveryFeeSettings.percentFeeWaiveStandard,
        perOrderFee,
        perOrderFeeWaiveStandard: deliveryFeeSettings.perOrderFeeWaiveStandard,
        perOrderFeeNoApplyAboveStandard:
          deliveryFeeSettings.perOrderFeeNoApplyAboveStandard,
      },
    }

    this.setState(nextState)
  }

  onHide = () => this.props.closeModal()

  onChangeWaiveAllFees = (value) => {
    const form = {
      ...initialFormState,
      waiveAllFees: value,
    }
    this.setState({ form })
  }

  onChangeFlatFeeEnabled = (value) => {
    const form = {
      ...initialFormState,
      flatFeeEnabled: value,
    }
    this.setState({ form })
  }

  onChangePercentFeeEnabled = (value) => {
    const form = {
      ...initialFormState,
      percentFeeEnabled: value,
    }
    this.setState({ form })
  }

  onChangePerOrderFeeEnabled = (value) => {
    const form = {
      ...initialFormState,
      perOrderFeeEnabled: value,
    }
    this.setState({ form })
  }

  onChangeFlatFee = (value) => {
    const form = {
      ...this.state.form,
      flatFee: value,
    }
    this.setState({ form })
  }

  onChangeFlatFeeWaiveStandard = (value) => {
    const form = {
      ...this.state.form,
      flatFeeWaiveStandard: value,
    }
    this.setState({ form })
  }

  onChangePercentFee = (value) => {
    const form = {
      ...this.state.form,
      percentFee: value,
    }
    this.setState({ form })
  }

  onChangePercentFeeWaiveStandard = (value) => {
    const form = {
      ...this.state.form,
      percentFeeWaiveStandard: value,
    }
    this.setState({ form })
  }

  onChangePerOrderFee = (value) => {
    const form = {
      ...this.state.form,
      perOrderFee: value,
    }
    this.setState({ form })
  }

  onChangePerOrderFeeWaiveStandard = (value) => {
    const form = {
      ...this.state.form,
      perOrderFeeWaiveStandard: value,
    }
    this.setState({ form })
  }

  onChangePerOrderFeeNoApplyAboveStandard = (value) => {
    const form = {
      ...this.state.form,
      perOrderFeeNoApplyAboveStandard: value,
    }
    this.setState({ form })
  }

  save = async () => {
    const { flashError, clientGlobalSettings } = this.props

    if (!clientGlobalSettings) {
      flashError('Cannot save because client global settings not availablle')

      return
    }

    const {
      waiveAllFees,
      flatFeeEnabled,
      percentFeeEnabled,
      perOrderFeeEnabled,
      flatFeeWaiveStandard,
      percentFeeWaiveStandard,
      perOrderFeeWaiveStandard,
      perOrderFeeNoApplyAboveStandard,
    } = this.state.form

    const flatFee = flatFeeEnabled ? parseFloat(this.state.form.flatFee) : null
    if (
      (flatFeeEnabled && typeof flatFee !== 'number') ||
      (typeof flatFee === 'number' && isNaN(flatFee))
    ) {
      flashError('Please enter a valid flat fee value')

      return
    }

    const percentFee = percentFeeEnabled
      ? parseFloat(this.state.form.percentFee)
      : null
    if (
      (percentFeeEnabled && typeof percentFee !== 'number') ||
      (typeof percentFee === 'number' && isNaN(percentFee))
    ) {
      flashError('Please enter a valid percent fee value')

      return
    }

    const perOrderFee = perOrderFeeEnabled
      ? parseFloat(this.state.form.perOrderFee)
      : null
    if (
      (perOrderFeeEnabled && typeof perOrderFee !== 'number') ||
      (typeof perOrderFee === 'number' && isNaN(perOrderFee))
    ) {
      flashError('Please enter a valid per order fee value')

      return
    }

    const nextDeliveryFeeSettings = {
      waiveAllFees,
      flatFee,
      flatFeeWaiveStandard,
      percentFee,
      percentFeeWaiveStandard,
      perOrderFee,
      perOrderFeeWaiveStandard,
      perOrderFeeNoApplyAboveStandard,
    }

    const data = {
      ...clientGlobalSettings,
      deliveryFeeSettings: nextDeliveryFeeSettings,
    }

    this.setState({ saveReqLoading: true })
    const result = await this.props.onSaveClientGlobalSettings(data)
    this.setState({ saveReqLoading: false })

    if (result) {
      this.onHide()
    }
  }

  renderNoData() {
    return <div>Client global settings not available</div>
  }

  renderContent() {
    const {
      flatFeeEnabled,
      percentFeeEnabled,
      perOrderFeeEnabled,
      waiveAllFees,
      flatFee,
      flatFeeWaiveStandard,
      percentFee,
      percentFeeWaiveStandard,
      perOrderFee,
      perOrderFeeWaiveStandard,
      perOrderFeeNoApplyAboveStandard,
    } = this.state.form

    return (
      <div className="edit-group-order-delivery-fee-settings-modal">
        <div className="info-container">
          <p>{infoCopy}</p>
        </div>
        <div>
          <Checkbox
            checked={waiveAllFees}
            label="Waive all delivery & service fees for all locations"
            marginBottom="20px"
            value="waiveAllFees"
            onChange={(e) => this.onChangeWaiveAllFees(e.target.checked)}
          />
        </div>
        <div>
          <Checkbox
            checked={flatFeeEnabled}
            label="Add a flat delivery fee for all locations"
            marginBottom="20px"
            value="flatFeeEnabled"
            onChange={(e) => this.onChangeFlatFeeEnabled(e.target.checked)}
          />
          {flatFeeEnabled && (
            <div className="fee-inputs-container">
              <div className="fee-value">
                <Input
                  label="Value ($)"
                  value={flatFee}
                  onChange={(e) => this.onChangeFlatFee(e.target.value)}
                  maxWidth="100px"
                />
              </div>
              <div>
                <Checkbox
                  checked={flatFeeWaiveStandard}
                  label="Waive standard fee structure on top of flat fee"
                  marginBottom="20px"
                  value="flatFeeWaiveStandard"
                  onChange={(e) =>
                    this.onChangeFlatFeeWaiveStandard(e.target.checked)
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div>
          <Checkbox
            checked={percentFeeEnabled}
            label="Add a custom percentage delivery fee for all locations"
            marginBottom="20px"
            value="percentFeeEnabled"
            onChange={(e) => this.onChangePercentFeeEnabled(e.target.checked)}
          />
          {percentFeeEnabled && (
            <div className="fee-inputs-container">
              <div className="fee-value">
                <Input
                  label="Value (%)"
                  value={percentFee}
                  placeholder="12.5"
                  onChange={(e) => this.onChangePercentFee(e.target.value)}
                  maxWidth="100px"
                />
              </div>
              <div>
                <Checkbox
                  checked={percentFeeWaiveStandard}
                  label="Waive standard fee structure on top of percent fee"
                  marginBottom="20px"
                  value="percentFeeWaiveStandard"
                  onChange={(e) =>
                    this.onChangePercentFeeWaiveStandard(e.target.checked)
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div>
          <Checkbox
            checked={perOrderFeeEnabled}
            label="Add custom per order fee for all locations"
            marginBottom="20px"
            value="perOrderFeeEnabled"
            onChange={(e) => this.onChangePerOrderFeeEnabled(e.target.checked)}
          />
          {perOrderFeeEnabled && (
            <div className="fee-inputs-container">
              <div className="fee-value">
                <Input
                  label="Value ($)"
                  value={perOrderFee}
                  onChange={(e) => this.onChangePerOrderFee(e.target.value)}
                  maxWidth="100px"
                />
              </div>
              <div>
                <Checkbox
                  checked={perOrderFeeWaiveStandard}
                  label="Override standard tiered structure on top of custom per order fee"
                  marginBottom="20px"
                  value="perOrderFeeWaiveStandard"
                  onChange={(e) =>
                    this.onChangePerOrderFeeWaiveStandard(e.target.checked)
                  }
                />
              </div>
              <div>
                <Checkbox
                  checked={perOrderFeeNoApplyAboveStandard}
                  label="Do not apply per order fee in addition to standard fee"
                  marginBottom="20px"
                  value="perOrderFeeNoApplyAboveStandard"
                  onChange={(e) =>
                    this.onChangePerOrderFeeNoApplyAboveStandard(
                      e.target.checked,
                    )
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div className="buttons-container">
          <LinkText label="Cancel" onClick={this.onHide} color="#b30303" />
          <Button
            label="Save"
            onClick={this.save}
            disabled={this.state.saveReqLoading}
          />
        </div>
      </div>
    )
  }

  render() {
    const { clientGlobalSettings } = this.props

    const title = 'Client Global Delivery Fee Settings'

    return (
      <Modal
        title={title}
        hideModal={this.onHide}
        color="#001940"
        maxWidth="400px"
        width="400px"
        height="600px"
      >
        {clientGlobalSettings ? this.renderContent() : this.renderNoData()}
      </Modal>
    )
  }
}

EditGroupOrderDeliveryFeeSettingsModal.propTypes = {
  onSaveClientGlobalSettings: PropTypes.func,
  flashError: PropTypes.func,
  closeModal: PropTypes.func,
  clientGlobalSettings: PropTypes.object,
  clientName: PropTypes.string,
}

export default EditGroupOrderDeliveryFeeSettingsModal
