import { camelCaseify, camelCaseifySpace, formAdd, snakeCaseify } from '~/utils'
import { pResponseRole } from './user'

export const pRequestUsersFilter = (data) => {
  return snakeCaseify(data)
}

export const pResponseUsers = (data) => {
  return camelCaseify(data)
}

export const pRequestUpdateUser = (data) => {
  const req = {}
  formAdd(data, req, 'email', 'email')
  formAdd(data, req, 'firstName', 'firstName')
  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'lastName', 'lastName')
  if (data.password) {
    formAdd(data, req, 'password', 'password')
  }
  formAdd(data, req, 'isDisabled', 'isDisabled')
  formAdd(data, req, 'phoneNumber', 'phoneNumber')
  const permissions = {}
  data.roles &&
    data.roles
      .filter((r) => !r._destroy)
      .forEach((r) => {
        const hqId = parseInt(r.headquarterId)
        if (!permissions[hqId]) {
          permissions[hqId] = []
        }
        permissions[hqId].push(pRequestUpdateRole(r.name))
      })
  req.permissions = permissions

  return req
}

export const pRequestUpdateRole = (role) => {
  if (role === 'sales lead') {
    return 'masterSalesRep'
  } else if (role === 'chef lead') {
    return 'chefRecruiting'
  } else if (role === 'master admin') {
    return 'admin'
  } else {
    return camelCaseifySpace(role)
  }
}

export const pResponseAdminUsers = (json) => {
  const users = json.map((j) => ({
    id: j.id,
    name: [j.first_name, j.last_name].filter((n) => n).join(' '),
  }))

  users.sort(function (a, b) {
    const x = a.name && a.name.toLowerCase()
    const y = b.name && b.name.toLowerCase()

    return x < y ? -1 : x > y ? 1 : 0
  })

  return users
}

export const pResponseAdminUser = (user) => {
  user.name = [user.lastName, user.firstName].join(' ')
  user.roles = []
  user.permissions &&
    Object.keys(user.permissions).forEach((hq) => {
      user.permissions[hq].forEach((role) => {
        const headquarterId = parseInt(hq)
        user.roles.push({
          headquarterId,
          name: pResponseRole(role),
        })
      })
    })
  user.email = user.email || ''
  user.phoneNumber = user.phoneNumber || ''
  user.isDisabled = user.isDisabled || false

  return user
}
