import React, { Component } from 'react'
import PropTypes from 'prop-types'
import braintree from 'braintree-web'
import { Modal } from 'react-bootstrap'

import { config } from 'hungry-core2'

export class EditSubscriberModal extends Component {
  state = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    instructions: '',
    billingName: '',
  }
  didInitBraintree = false
  hostedFields = undefined
  updatePaymentMethod = false
  paymentProcessing = false

  componentWillReceiveProps(props) {
    if (props.member && !this.didInitBraintree) {
      this.initBraintree()
      this.didInitBraintree = true
    }
  }

  async getPaymentInfo() {
    return new Promise((resolve, reject) => {
      const { billingName } = this.state
      if (!(billingName.length > 0 && this.updatePaymentMethod)) {
        const errors = this.buildErrorResponse()
        reject({
          errors,
          message: 'Some fields are incomplete!\n\nPlease try again.',
        })

        return
      }

      if (this.hostedFields) {
        this.hostedFields.tokenize((err, payload) => {
          if (
            err &&
            err.code === 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE'
          ) {
            this.hostedFields.clear('number')
            this.hostedFields.clear('cvv')
            this.hostedFields.clear('expirationDate')
            reject({
              errorMessage:
                'Cannot use duplicate nonce. Please enter credit card information again.',
            })
          }

          if (err) {
            const errors = this.buildErrorResponse()
            reject({ errors, errorMessage: 'Some fields are invalid!' })

            return
          }

          if (!payload || !payload.nonce || !(payload.nonce.length > 0)) {
            reject({ errorMessage: 'Error connecting to Payment Gateway' })

            return
          } else {
            resolve({
              nonce: payload.nonce,
              billingName,
              errorMessage: undefined,
            })
          }
        })
      } else {
        reject({ errorMessage: 'Please enable Javascript!' })
      }
    })
  }

  async initBraintree() {
    try {
      const authorization = config.braintree_tokenization_key
      const client = await braintree.client.create({ authorization })
      let hostedFields
      try {
        hostedFields = await braintree.hostedFields.create({
          client,
          styles: {
            input: { 'font-size': '16px' },
            'input.invalid': { color: 'red' },
            'input.valid': { color: 'green' },
          },
          fields: {
            number: {
              selector: '#edit-card-number',
              placeholder: 'xxxx xxxx xxxx xxxx',
            },
            expirationDate: {
              selector: '#edit-expiration-date',
              placeholder: 'mm/yy',
            },
            cvv: {
              selector: '#edit-cvv',
              placeholder: 'xxx',
            },
          },
        })
      } catch (e) {
        return
      }
      if (hostedFields == null) {
        return
      }

      this.hostedFields = hostedFields

      const checkFields = (event) => {
        const fieldKeys = Object.keys(event.fields)
        this.updatePaymentMethod = fieldKeys.some(
          (key) => event.fields[key].isEmpty !== true,
        )

        if (event.cards.length === 1) {
          const cardType = event.cards[0].niceType
          switch (cardType) {
            case 'Visa':
              this.setState({ creditCardClass: 'fa-cc-visa' })
              break
            case 'MasterCard':
              this.setState({ creditCardClass: 'fa-cc-mastercard' })
              break
            case 'American Express':
              this.setState({ creditCardClass: 'fa-cc-amex' })
              break
            case 'Discover':
              this.setState({ creditCardClass: 'fa-cc-discover' })
              break
            default:
              this.setState({ creditCardClass: 'fa-cc-credit-card' })
          }
        } else {
          this.setState({ creditCardClass: 'fa-cc-credit-card' })
        }
      }

      hostedFields.on('empty', checkFields)
      hostedFields.on('notEmpty', checkFields)
      hostedFields.on('validityChange', checkFields)
      hostedFields.on('cardTypeChange', checkFields)
    } catch (err) {
      // console.log( err ) // Remove on production
    }
  }

  processPayment = async () => {
    this.paymentProcessing = true
    let paymentTokenizationResult
    let paymentError
    try {
      paymentTokenizationResult = await this.getPaymentInfo()
    } catch (e) {
      paymentError = e
    }
    if (paymentTokenizationResult && paymentTokenizationResult.errorMessage) {
      paymentError = paymentTokenizationResult.errorMessage
    }
    this.paymentProcessing = false

    return {
      paymentTokenizationResult,
      paymentError,
    }
  }

  onChangeField = (field) => (e) => {
    this.setState({ [field]: e.target.value })
  }

  onSave = async () => {
    const { updateMember, flashError, hide, refreshMembers } = this.props
    const { paymentTokenizationResult, paymentError } =
      await this.processPayment()
    if (paymentError || !paymentTokenizationResult) {
      this.setState({ paymentError })
      flashError(
        `Please check your payment details. ${paymentError.errorMessage}`,
      )
      window.scrollTo(0, 0)

      return
    }
    const data = { ...this.state, nonce: paymentTokenizationResult.nonce }
    if (await updateMember(data)) {
      hide()
      refreshMembers()
    }
  }

  render() {
    const { hide, member } = this.props
    const {
      email,
      password,
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      phone,
      instructions,
      billingName,
      paymentError = {},
    } = this.state
    const { errors = {} } = paymentError

    return (
      <Modal
        backdrop="static"
        show={!!member}
        onHide={hide}
        dialogClassName="small-modal w-1/2"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            Edit Home Subscriber
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-8">
            <div className="mb-8">
              <div className="flex">
                <div className="flex-1 mr-3">
                  <div className="mb-2">
                    <label>Email</label>
                    <input
                      type="text"
                      className="border w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                      value={email}
                      onChange={this.onChangeField('email')}
                    />
                  </div>
                  <div className="mb-2">
                    <label>Password (not required for linking)</label>
                    <input
                      type="text"
                      className="border w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                      value={password}
                      onChange={this.onChangeField('password')}
                    />
                  </div>
                  <div className="mb-2">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="border w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                      value={firstName}
                      onChange={this.onChangeField('firstName')}
                    />
                  </div>
                  <div className="mb-2">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="border w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                      value={lastName}
                      onChange={this.onChangeField('lastName')}
                    />
                  </div>
                  <div className="mb-2">
                    <label>Cell</label>
                    <input
                      type="text"
                      name="phone"
                      className="border w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                      value={phone}
                      onChange={this.onChangeField('phone')}
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <div className="mb-2">
                    <label>Address Line 1</label>
                    <input
                      type="text"
                      className="border w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                      value={addressLine1}
                      onChange={this.onChangeField('addressLine1')}
                    />
                  </div>
                  <div className="mb-2">
                    <label>Address Line 2</label>
                    <input
                      type="text"
                      className="border w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                      value={addressLine2}
                      onChange={this.onChangeField('addressLine2')}
                    />
                  </div>
                  <div className="mb-2">
                    <label>City</label>
                    <input
                      type="text"
                      className="border w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                      value={city}
                      onChange={this.onChangeField('city')}
                    />
                  </div>
                  <div className="flex mb-2">
                    <div className="flex-1 mr-3">
                      <label>State</label>
                      <input
                        type="text"
                        className="border w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                        value={state}
                        onChange={this.onChangeField('state')}
                      />
                    </div>
                    <div className="flex-1">
                      <label>Zip</label>
                      <input
                        type="text"
                        className="border w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                        value={zip}
                        onChange={this.onChangeField('zip')}
                      />
                    </div>
                  </div>
                  <div className="mb-2">
                    <label>Instructions</label>
                    <input
                      type="text"
                      className="border w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                      value={instructions}
                      onChange={this.onChangeField('instructions')}
                    />
                  </div>
                </div>
              </div>
              <div className="flex mb-2">
                <div className="flex-1 mr-3">
                  <label>
                    Card Number
                    <span className="text-red-600 font-bold ml-2">
                      {errors['card-number']}
                    </span>
                  </label>
                  <div
                    id="edit-card-number"
                    className="border border-solid h-10 w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                  />
                </div>
                <div className="flex-1 flex">
                  <div className="flex-1 mr-3">
                    <label>
                      Expiration
                      <span className="text-red-600 font-bold ml-2">
                        {errors['expiration-date']}
                      </span>
                    </label>
                    <div
                      id="edit-expiration-date"
                      className="border border-solid h-10 w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                    />
                  </div>
                  <div className="flex-1">
                    <label>
                      CVV
                      <span className="text-red-600 font-bold ml-2">
                        {errors['cvv']}
                      </span>
                    </label>
                    <div
                      id="edit-cvv"
                      className="border border-solid h-10 w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                    />
                  </div>
                </div>
              </div>
              <label>
                Name on Card
                <span className="text-red-600 font-bold ml-2">
                  {errors['billingName']}
                </span>
              </label>
              <div className="flex items-center">
                <div className="flex-1 mr-3">
                  <input
                    type="text"
                    className="border w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                    value={billingName}
                    onChange={this.onChangeField('billingName')}
                  />
                </div>
                <div className="flex-1">
                  <input type="checkbox" />
                  <span className="ml-1">
                    Billing address same as delivery?
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={this.onSave}
            className="button-primary focus:outline-none mr-1"
            id="save"
          >
            {' '}
            Save{' '}
          </button>
          <button
            onClick={hide}
            className="button-neutral focus:outline-none"
            id="cancel"
          >
            {' '}
            Cancel{' '}
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

EditSubscriberModal.propTypes = {
  member: PropTypes.object,

  flashError: PropTypes.func,
  hide: PropTypes.func,
  refreshMembers: PropTypes.func,
  updateMember: PropTypes.func,
}

export default EditSubscriberModal
