import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class OrderSupplyBreakdownTable extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  onToggleTable = (id) => async () => {
    if (this.state[id]) {
      this.setState({ [id]: undefined })
    } else {
      this.setState({ [id]: true })
    }
  }

  renderOrderItem = (orderItem, i) => {
    const {
      name,
      packagingAddOns,
      packaging,
      servingUtensils,
      cateringSupplies,
      diningSupplies,
    } = orderItem

    const allSupplies = [
      ...packaging,
      ...packagingAddOns,
      ...servingUtensils,
      ...cateringSupplies,
      ...diningSupplies,
    ]

    const showOrderItems = this.state[i]
    const totals = allSupplies.reduce((accumulator, supplyTotal) => {
      const { cost, count } = supplyTotal

      return accumulator + cost * count
    }, 0)
    const header = name.concat(` - $${totals.toFixed(2)}`)

    return (
      <div
        key={i}
        className={`collapsible-table-container ${
          showOrderItems ? 'open' : 'closed'
        }`}
      >
        <div
          className="collapsible-table-headers-container"
          onClick={this.onToggleTable(i)}
        >
          <div className="center-vertical">
            <div
              className={`table-toggle ${showOrderItems ? 'open' : 'closed'}`}
            >
              ▶
            </div>
            {header}
          </div>
        </div>
        <table className="collapsible-table">
          <thead>
            <tr>
              <th>Supply Name</th>
              <th className="align-right">Count</th>
              <th className="align-right">Cost</th>
              <th className="align-right">Item Total</th>
            </tr>
          </thead>
          <tbody>
            {allSupplies.map((supply, index) => {
              const { name, cost, count } = supply

              return (
                <tr key={index}>
                  <td>{name}</td>
                  <td className="align-right">{count}</td>
                  <td className="align-right">{cost}</td>
                  <td className="align-right">${(cost * count).toFixed(2)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  renderSupplyTotals = (key, supplyTotals, i) => {
    const showSupplies = this.state[key]
    const text = key.replace(/([A-Z])/g, ' $1')
    let header = text.charAt(0).toUpperCase() + text.slice(1)

    if (Array.isArray(supplyTotals)) {
      const totals = supplyTotals.reduce((accumulator, supplyTotal) => {
        const { cost, count } = supplyTotal

        return accumulator + cost * count
      }, 0.0)
      header = header.concat(` - $${totals.toFixed(2)}`)

      return (
        <div
          key={i}
          className={`collapsible-table-container ${
            showSupplies ? 'open' : 'closed'
          }`}
        >
          <div
            className="collapsible-table-headers-container"
            onClick={this.onToggleTable(key)}
          >
            <div className="center-vertical">
              <div
                className={`table-toggle ${showSupplies ? 'open' : 'closed'}`}
              >
                ▶
              </div>
              {header}
            </div>
          </div>
          <table className="collapsible-table">
            <thead>
              <tr>
                <th>Supply Name</th>
                <th className="align-right">Count</th>
                <th className="align-right">Cost</th>
                <th className="align-right">Item Total</th>
              </tr>
            </thead>
            <tbody>
              {supplyTotals.map((supplyTotal, j) => {
                const { name, cost, count } = supplyTotal

                return (
                  <tr key={j}>
                    <td>{name}</td>
                    <td className="align-right">{count}</td>
                    <td className="align-right">{cost}</td>
                    <td className="align-right">
                      ${(cost * count).toFixed(2)}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )
    } else {
      return (
        <h3>
          {key}: {supplyTotals}
        </h3>
      )
    }
  }

  render() {
    const { supplyBreakdown } = this.props
    if (!supplyBreakdown) {
      return
    }
    const { orderItems, total } = supplyBreakdown

    return (
      <div className="supply-breakdown">
        <div>
          <h3>Supply Totals</h3>
          <div className="collapsible-table">
            {Object.entries(total).map(([key, supplyTotals], i) =>
              this.renderSupplyTotals(key, supplyTotals, i),
            )}
          </div>
          <div className="large-divider" />
          <h3>Supplies by Order Items</h3>
          <div className="collapsible-table">
            {Object.values(orderItems).map((orderItem, i) =>
              this.renderOrderItem(orderItem, i),
            )}
          </div>
        </div>
      </div>
    )
  }
}

OrderSupplyBreakdownTable.propTypes = {
  supplyBreakdown: PropTypes.object,
}

export default OrderSupplyBreakdownTable
