import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

import loadGif from '@res/images/loading.gif'

class CalendarPopup extends Component {
  state = {
    menuItems: [],
    proposal: {},
  }

  componentWillMount() {
    const { proposal } = this.props
    this.setState({ proposal })
  }

  componentWillReceiveProps(nextProps) {
    const { proposal } = nextProps
    this.setState({ proposal })
  }

  loadMenuItems = async () => {
    const { menuItems, proposal } = this.state
    if (!menuItems.length) {
      const { getProposalMenuItems } = this.props
      const menuItems = await getProposalMenuItems(proposal)
      this.setState({ menuItems })
    }
  }

  render() {
    const { isLoadingProposal } = this.props
    const { proposal, showLoader } = this.state

    return (
      <div className="calendar-hover__inner">
        <div className="quick-view">Quick View</div>
        <div className="">
          <div id="calendar-popup" className="calendar-popup">
            <div className="w-full">
              <div className="">
                <div className="flex align-center">
                  {proposal && (
                    <div className="popup-chef-details">
                      <p className="popup-chef-name" id="chef-name">
                        {' '}
                        {(proposal.concept && proposal.concept.name) ||
                          (proposal.cuisineType && proposal.cuisineType) ||
                          'Custom Menu'}{' '}
                        by Chef {proposal.chefsString}
                      </p>
                      <p id="setup-time" className="">
                        {Moment(proposal.clientSetUpTime).format('MM/DD/YY')}{' '}
                        {proposal.orderType}{' '}
                        {Moment(proposal.clientSetUpTime).format('h:mma')}
                        <span id="headcount-and-total">
                          {' '}
                          | Headcount {proposal.headCount} | ${proposal.total}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {showLoader && isLoadingProposal ? (
                <div className="loading-gif">
                  <img src={loadGif} alt="loading image" />
                </div>
              ) : (
                <div>
                  <table className="w-full">
                    <tr className="">
                      <th className="text-left">Description</th>
                      <th className="text-right pl-30">Price</th>
                      <th className="text-center pl-30">Qty</th>
                      <th className="text-right nowrap pl-30">Total</th>
                      <th />
                    </tr>
                    {proposal &&
                      proposal.proposalMenuItems &&
                      proposal.proposalMenuItems.map((p, i) =>
                        !p._destroy ? (
                          <tbody key={i}>
                            {p.displayOrder % 1 === 0 && (
                              <tr key={i}>
                                <td className="text-left vertical-align-middle">
                                  <div>
                                    <p
                                      id="menu-item-name"
                                      className="popup-name"
                                    >
                                      {p.menuItem.name}
                                    </p>
                                    <div className="toppings">
                                      {p.childItems &&
                                        p.childItems.length > 0 && (
                                          <span>Toppings: </span>
                                        )}
                                      {p.childItems &&
                                        p.childItems.map((c, i) => (
                                          <span
                                            className="regular text-left"
                                            key={c.id}
                                          >
                                            {c.name}
                                            {p.childItems.length === i + 1
                                              ? ''
                                              : ', '}
                                          </span>
                                        ))}
                                    </div>
                                  </div>
                                </td>
                                <td className="text-right pl-30 vertical-align-middle">
                                  ${Number(p.menuItem.price).toFixed(2)}
                                </td>
                                <td className="text-center pl-30 vertical-align-middle">
                                  {p.quantity}
                                </td>
                                <td
                                  id="line-total"
                                  className="pl-30 text-right vertical-align-middle"
                                >
                                  $
                                  {Number(
                                    p.menuItem.price * p.quantity,
                                  ).toFixed(2)}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        ) : null,
                      )}
                    {/* hasServiceItems &&
                        <tr>
                          <td colSpan="5">Service Items</td>
                        </tr>
                    */}
                    {proposal &&
                      proposal.proposalServiceItems.map((p, i) => {
                        return (
                          <tbody key={i}>
                            <tr key={i}>
                              <td>
                                <div>
                                  <p id="menu-item-name">{p.name}</p>
                                </div>
                              </td>
                              <td className="text-right">
                                ${Number(p.price).toFixed(2)}
                              </td>
                              <td className="text-right">{p.quantity}</td>
                              <td id="line-total" className="text-right">
                                ${Number(p.price * p.quantity).toFixed(2)}
                              </td>
                              <td />
                            </tr>
                          </tbody>
                        )
                      })}
                    {proposal && proposal.allChefInstructions && (
                      <tr>
                        <td colSpan="4" className="pt-10 text-left">
                          <p className="bold">
                            Notes for your Account Manager and Chef.
                          </p>
                          <p>{proposal.allChefInstructions}</p>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td
                        colSpan="2"
                        className="text-right bold pr-30 total-divider"
                      >
                        Subtotal
                      </td>
                      <td
                        id="subtotal"
                        className="text-right total-divider"
                        colSpan="2"
                      >
                        {proposal && `$${Number(proposal.subtotal).toFixed(2)}`}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="text-right bold pr-30">
                        Buffet Set-Up & Delivery
                      </td>
                      <td id="delivery-fee" className="text-right" colSpan="2">
                        {proposal &&
                          `$${Number(proposal.serviceFee).toFixed(2)}`}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="text-right bold pr-30">
                        Tax
                      </td>
                      <td id="tax" className="text-right" colSpan="2">
                        {proposal && `$${Number(proposal.tax).toFixed(2)}`}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="text-right bold pr-30">
                        Gratuity
                      </td>
                      <td id="tax" colSpan="2" className="text-right">
                        {proposal && `$${Number(proposal.tip).toFixed(2)}`}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="text-right bold pr-30">
                        Total
                      </td>
                      <td id="total" colSpan="2" className="text-right">
                        {proposal && `$${Number(proposal.total).toFixed(2)}`}
                      </td>
                    </tr>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CalendarPopup.propTypes = {
  checkedProposals: PropTypes.arrayOf(PropTypes.object),
  isLoadingProposal: PropTypes.bool,
  proposal: PropTypes.object,
  proposalPdfUrlPrefix: PropTypes.string,

  getProposalMenuItems: PropTypes.func,
  goToReceipt: PropTypes.func,
  loadProposal: PropTypes.func,
  onSelectProposal: PropTypes.func,
}

export default CalendarPopup
