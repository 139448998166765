import Moment from 'moment-timezone'
import {
  formAdd,
  removedFromArrayByAttribute,
  replacedInArrayByAttribute,
} from '~/utils'
import services from '@services'
import { combineDateTime } from '@presenters/api/order'
import { LegacyLocalesToStandardLocales } from '~/utils/timezone'

export const pMapOptionAttributes = (stringArray) => {
  stringArray.sort()
  if (Array.isArray(stringArray)) {
    return stringArray.map((s) => ({ label: s, value: s }))
  }

  return []
}

export const pStateToReduxOrder = (state) => {
  state = { ...state }
  delete state.newAccount
  delete state.newCateringContact
  delete state.newContact
  delete state.newDropoffAddress
  delete state.newInvoiceContact
  delete state.newPaymentMethod
  delete state.newReceiptContact

  return state
}

export const pRemovedItemFromArray = (array, element) => {
  array = removedFromArrayByAttribute(array, element, [
    'id',
    'displayOrder',
    'menuItemId',
    'serviceItemId',
    'virtualItemId',
  ])
  array.forEach((e, i) => (e.displayOrder = i + 1))

  return array
}

export const pReplacedItemInArray = (array, element) => {
  return replacedInArrayByAttribute(array, element, [
    'id',
    'displayOrder',
    'menuItemId',
    'serviceItemId',
    'virtualItemId',
  ])
}

export const pDefaultAccountFields = (account) => {
  const {
    addresses = [],
    contacts = [],
    accountContacts = [],
    isTaxExempt,
    netPaymentSettings,
  } = account || {}
  account.setUpCompleteByTime =
    account.setUpCompleteByTime &&
    combineDateTime(Moment(), Moment(account.setUpCompleteByTime))
  account.doNotArriveBeforeTime =
    account.doNotArriveBeforeTime &&
    combineDateTime(Moment(), Moment(account.doNotArriveBeforeTime))
  account.lastUpdatedAtClientNotes =
    account.lastUpdatedAtClientNotes &&
    Moment(account.lastUpdatedAtClientNotes).format('YYYY-MM-DD')
  account.lastUpdatedAtCuisinePreferences =
    account.lastUpdatedAtCuisinePreferences &&
    Moment(account.lastUpdatedAtCuisinePreferences).format('YYYY-MM-DD')
  account.lastUpdatedAtClientSuccessMetrics =
    account.lastUpdatedAtClientSuccessMetrics &&
    Moment(account.lastUpdatedAtClientSuccessMetrics).format('YYYY-MM-DD')
  account.clientNotes = account.clientNotes || ''
  account.cuisinePreferences = account.cuisinePreferences || ''
  account.clientSuccessMetrics = account.clientSuccessMetrics || ''

  const fields = { account, isTaxExempt }

  if (addresses.length === 1) {
    fields.dropoffAddress = addresses[0]
    fields.deliveryInstructions = addresses[0].buildingInstructions
  } else if (addresses.filter((a) => a.isDefault).length === 1) {
    const address = addresses.find((a) => a.isDefault)
    fields.dropoffAddress = address
    fields.deliveryInstructions = address.buildingInstructions
  } else {
    fields.dropoffAddress = null
  }
  if (contacts.length === 1) {
    fields.cateringContact = contacts[0]
    fields.invoiceContact = contacts[0]
    fields.receiptContact = contacts[0]
  } else if (accountContacts.filter((c) => c.isDefault).length === 1) {
    const accountContact = accountContacts.find((c) => c.isDefault)
    const contact = contacts.find((c) => c.id === accountContact.contactId)
    fields.cateringContact = contact
    fields.invoiceContact = contact
    fields.receiptContact = contact
  } else {
    fields.cateringContact = null
    fields.invoiceContact = null
    fields.receiptContact = null
  }
  fields.paymentMethod = {}
  if (netPaymentSettings) {
    fields.hasNetPaymentSettings = true
  } else {
    fields.hasNetPaymentSettings = false
  }

  return fields
}

export const pOrderAttributesFromDinerProfile = (
  hqLocaleMap,
  dinerProfile,
  contacts,
  addresses,
  clientSetUpTime = null,
) => {
  const { id, mealType, headcount, eventTime, addressId, contactId, hqId } =
    dinerProfile

  const dropoffAddress = addresses.find((a) => a.id === addressId)
  const orderContact = contacts.find((c) => c.id === contactId)

  const setUpHour = eventTime / 100
  const setUpMinute = eventTime % 100
  const loc = hqLocaleMap[hqId]
  let clientSetUp = Moment()
    .tz(LegacyLocalesToStandardLocales[loc])
    .set({ hour: setUpHour, minute: setUpMinute })
  if (clientSetUpTime) {
    clientSetUp = Moment(clientSetUp)
      .tz(LegacyLocalesToStandardLocales[loc])
      .clone()
      .set({ hour: setUpHour, minute: setUpMinute })
  }

  return {
    dinerProfileId: id,
    dinerProfile,
    headCount: headcount,
    orderType: mealType,
    dropoffAddress,
    deliveryInstructions: dropoffAddress.buildingInstructions,
    invoiceContact: orderContact,
    cateringContact: orderContact,
    receiptContact: orderContact,
    clientSetUpTime: clientSetUp,
  }
}

export const pBuildAddress = (data) => {
  const { line1, line2, city, state, zip, allowTolls, buildingInstructions } =
    data

  const address = {
    line1,
    line2,
    city,
    state,
    zip,
    allowTolls,
    buildingInstructions,
    fullAddress:
      [line1, line2, city, state].filter((a) => a).join(', ') + ` ${zip}`,
  }
  formAdd(data, address, 'id', 'id')

  return address
}

export const pBuildContact = (data) => {
  const {
    title,
    firstName,
    lastName,
    phoneNumber,
    email,
    purchaserTaxStatus,
    isReferralPartner,
  } = data

  const contact = {
    title,
    firstName,
    lastName,
    phoneNumber,
    email,
    purchaserTaxStatus,
    isReferralPartner,
    name: [firstName, lastName].filter((n) => n).join(' '),
  }
  formAdd(data, contact, 'id', 'id')

  return contact
}

export const pBuildPaymentMethod = (data) => {
  const {
    billingAddress,
    cardholderName,
    cardNumber,
    cvv,
    expirationDate,
    last4,
    nonce,
    shortName,
  } = data

  const paymentMethod = {
    cardholderName,
    cardNumber,
    cvv,
    expirationDate,
    nonce,
    shortName: shortName
      ? shortName
      : [cardholderName, cardNumber].filter((c) => c).join(', '),
    last4: last4 ? last4 : cardNumber.substring(cardNumber.length - 4),
    billingAddress,
  }
  formAdd(data, paymentMethod, 'id', 'id')

  return paymentMethod
}

export const pContactKeys = (contactType) => {
  const key = `${contactType}Contact`
  const newKey = `new${key.charAt(0).toUpperCase() + key.substring(1)}`

  return { key, newKey }
}

export const pOrderSettings = (settings) => {
  settings = { ...settings }
  settings.allSetUpStyles = settings.allSetUpStyles.slice(1)

  return settings
}

export const pFindIdentical = (collection, element) => {
  if (!collection || !element) {
    return
  }

  let found = undefined
  for (const el of collection) {
    let perfectMatch = true
    for (const k in element) {
      if (element[k] !== el[k]) {
        perfectMatch = false
        break
      }
    }
    if (perfectMatch) {
      found = el
      break
    }
  }

  return found
}

export const pFindIdenticalPaymentMethod = (paymentMethods, paymentMethod) => {
  const paymentMethodNonce = { nonce: paymentMethod.nonce }

  return pFindIdentical(paymentMethods, paymentMethodNonce)
}

export const pColorHashString = (string = '') => {
  const hexLetters = '0123456789abc'
  string = string.slice(0, 6) + '000000'
  let hex = '#'
  for (let i = 0; i < 6; i++) {
    hex += hexLetters[string.charCodeAt(i) % hexLetters.length]
  }

  return hex
}

/*
  pAccountSettingsFromAccount - Note: Default delivery fee structure is set into account settings
  if delivery fee structure is not defined on account so that fee structure
  can be locked on order.
*/
export const pAccountSettingsFromAccount = (account) => {
  const { OrderService } = services

  const {
    gratuity,
    gratuityType,
    deliveryAndServiceFeePercentWeekend,
    deliveryAndServiceFeePercentWeekday,
    deliveryAndServiceFeePercentHoliday,
    deliveryAndServiceFeeCapWeekend,
    deliveryAndServiceFeeCapWeekday,
    deliveryAndServiceFeeCapHoliday,
  } = account || {}

  const deliveryFeeStructure = OrderService.getDeliveryFeesOrDefault({
    deliveryAndServiceFeePercentWeekend,
    deliveryAndServiceFeePercentWeekday,
    deliveryAndServiceFeePercentHoliday,
    deliveryAndServiceFeeCapWeekend,
    deliveryAndServiceFeeCapWeekday,
    deliveryAndServiceFeeCapHoliday,
  })

  const accountSettings = {
    gratuity,
    gratuityType,
    ...deliveryFeeStructure,
  }

  return accountSettings
}

export const pAccountSettingsFromOrderable = (orderable, account) => {
  let {
    clientDoNotArriveBeforeTime,
    clientSetUpTime,
    numberOfStaff,
    staffingHours,
    staffingRate,
    needsStaffing,
  } = orderable

  if (account.doNotArriveBeforeTime && clientDoNotArriveBeforeTime) {
    clientDoNotArriveBeforeTime = combineDateTime(
      Moment(clientDoNotArriveBeforeTime),
      Moment(account.doNotArriveBeforeTime),
    )
  }
  if (account.setUpCompleteByTime && clientSetUpTime) {
    clientSetUpTime = combineDateTime(
      Moment(clientSetUpTime),
      Moment(account.setUpCompleteByTime),
    )
  }

  if (account.cateringStaffCount) {
    numberOfStaff = account.cateringStaffCount
  }
  if (account.cateringStaffHours) {
    staffingHours = account.cateringStaffHours
  }
  if (account.cateringStaffRate) {
    staffingRate = account.cateringStaffRate
  }
  if (account.needStaffAndServe) {
    needsStaffing = account.needStaffAndServe
  }

  return {
    clientDoNotArriveBeforeTime,
    clientSetUpTime,
    numberOfStaff,
    staffingHours,
    staffingRate,
    needsStaffing,
  }
}

export const pCopyOrder = (editOrder) => {
  const newOrder = { ...editOrder }
  delete newOrder.id
  delete newOrder.orderNumber
  delete newOrder.audits
  delete newOrder.orderDiscounts
  delete newOrder.orderStatuses
  delete newOrder.pickupAddress
  delete newOrder.chargeDate
  delete newOrder.referredBy
  delete newOrder.orderServiceCost
  delete newOrder.createdAt
  delete newOrder.updatedAt
  // only reset tax exempt on orders that are exempt on the order level
  if (newOrder.isTaxExempt && newOrder.orderTaxExempt) {
    newOrder.isTaxExempt = false
  }
  newOrder.orderTaxExempt = false
  delete newOrder.orderTaxExemptReason
  const { UIService } = services

  let itemOrder = 0
  let childItemOrder = 0
  const cleanOrderItem =
    (sourceItemKey, isChild = false) =>
    (i) => {
      delete i.id
      delete i.orderId
      i.price = parseFloat(i[sourceItemKey].price)
      i.cost = parseFloat(i[sourceItemKey].cost)
      i.chefPrice = parseFloat(i[sourceItemKey].cost)
      if (i.menuItem && (!i.menuItem.isApproved || !i.menuItem.isEnabled)) {
        UIService.FlashMessage.displayFailureMessage(
          `Menu Item ${i.name} is not Approved or Enabled. Please reach out to your CN manager to approve the item in the Chef's Menu Item database.`,
        )
      }
      if (
        i.serviceItem &&
        (!i.serviceItem.isApproved || !i.serviceItem.isEnabled)
      ) {
        UIService.FlashMessage.displayFailureMessage(
          `Service Item ${i.name} is not Approved or Enabled`,
        )
      }
      if (!isChild) {
        itemOrder += 1
        childItemOrder = 0
        i.displayOrder = itemOrder
      } else {
        childItemOrder += 0.01
        i.displayOrder = itemOrder + childItemOrder
      }
      if (i.childItems && i.childItems.length > 0) {
        i.childItems.forEach(cleanOrderItem(sourceItemKey, true))
      }
    }

  newOrder.chefs.forEach((c) => {
    c.orderMenuItems.forEach(cleanOrderItem('menuItem'))
    c.orderServiceItems.forEach(cleanOrderItem('serviceItem'))
    c.orderVirtualItems.forEach(cleanOrderItem('virtualItem'))
    c.orderVirtualKits.forEach(cleanOrderItem('virtualKit'))
    c.orderSnackPacks.forEach(cleanOrderItem('snackPack'))
    c.customOrderMenuItems.forEach(cleanOrderItem('menuItem'))
    c.customOrderServiceItems.forEach(cleanOrderItem('serviceItem'))
    c.customOrderVirtualItems.forEach(cleanOrderItem('virtualItem'))
    c.chefNote = undefined
  })

  if (
    newOrder.concept &&
    (!newOrder.concept.isEnabled || !newOrder.concept.isApproved)
  ) {
    UIService.FlashMessage.displayFailureMessage(
      `Menu Concept: ${newOrder.concept.name} is not enabled or not approved.`,
    )
  }

  newOrder.chefOrderInstructions = []

  if (newOrder.discount) {
    delete newOrder.discount.id
  }
  newOrder.originalMenuItemIds = []
  newOrder.originalServiceItemIds = []
  newOrder.originalVirtualItemIds = []
  newOrder.originalVirtualKitIds = []
  newOrder.originalSnackPackIds = []
  newOrder.transactions = []
  newOrder.requiredActions = []
  newOrder.isCopy = true

  const { account } = newOrder
  const accountSettings = pAccountSettingsFromAccount(account)
  if (accountSettings) {
    newOrder.accountSettings = accountSettings
  }

  const settings = pAccountSettingsFromOrderable(newOrder, account)

  return { ...newOrder, ...settings }
}
