export const CLEAR_EDIT_ADMIN_USER = 'CLEAR_EDIT_ADMIN_USER'
export const CLEAR_ADMIN_USER = 'CLEAR_ADMIN_USER'
export const REMOVE_USER_FROM_USERS = 'REMOVE_USER_FROM_USERS'
export const SET_EDIT_ADMIN_USER = 'SET_EDIT_ADMIN_USER'
export const SET_ADMIN_USER = 'SET_ADMIN_USER'
export const SET_ADMIN_USERS = 'SET_ADMIN_USERS'
export const UPDATE_EDIT_ADMIN_USER = 'UPDATE_EDIT_ADMIN_USER'

export const clearEditAdminUser = () => ({ type: CLEAR_EDIT_ADMIN_USER })
export const clearAdminUser = () => ({ type: CLEAR_ADMIN_USER })
export const removeUserFromUsers = (userId) => ({
  type: REMOVE_USER_FROM_USERS,
  userId,
})

export const setAdminUser = (user) => ({ type: SET_ADMIN_USER, user })
export const setAdminUsers = (users) => ({ type: SET_ADMIN_USERS, users })
export const setEditAdminUser = (user) => ({ type: SET_EDIT_ADMIN_USER, user })

export const updateEditAdminUser = (user) => ({
  type: UPDATE_EDIT_ADMIN_USER,
  user,
})
