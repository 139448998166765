export const MarkOrderPaid =
  ({ RestService, UIService, HandleError }) =>
  async ({ id, orderNumber, alias, paymentType }) => {
    const markPaid = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to mark order "${orderNumber}" as paid?`,
    })
    if (markPaid) {
      try {
        if (orderNumber.startsWith('GRP')) {
          await RestService.put('/group-orders/mark_paid', {
            id,
            paymentType,
            alias,
          })
        } else if (orderNumber.startsWith('POP')) {
          await RestService.put('/pop-ups/mark_paid', {
            id,
            paymentType,
            alias,
          })
        } else {
          const payment_source = paymentType.toLowerCase()
          // We may want to send receipt in future
          // Currently marking GRP/POP orders paid does not send receipt, followed format here
          await RestService.post('/api/admin/payments', {
            order_id: id,
            is_refund: false,
            payment_source,
            send_receipt: false,
          })
        }
      } catch (error) {
        HandleError({ error })

        return false
      }
      UIService.FlashMessage.displaySuccessMessage(
        `Order "${orderNumber}" successfully marked as paid.`,
      )

      return true
    }
  }
