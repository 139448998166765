export const DelayedUpdateBatchOrder =
  ({ BatchOrderService, UIService, pStateToReduxOrder }) =>
  (data) => {
    const mode = UIService.EditBatchOrder.getMode()
    const updateBatchOrder =
      mode === 'edit'
        ? BatchOrderService.setEditBatchOrder
        : BatchOrderService.setNewBatchOrder
    const call = () => updateBatchOrder(pStateToReduxOrder(data))
    UIService.Timer.callAfterTimeout(call)
  }

export const DeleteBatchOrder =
  ({ BatchOrderService, RestService, UIService, HandleError }) =>
  async () => {
    const { id: batchOrderId, orderNumber } =
      BatchOrderService.getState().batchOrder
    const text = `Are you sure you want to delete Multi Order #${orderNumber}?`
    const doDelete = await UIService.ConfirmationModal.show({ text })
    if (doDelete) {
      try {
        await RestService.delete(`/api/admin/order_batches/${batchOrderId}`)
        BatchOrderService.clearEditBatchOrder()
        BatchOrderService.clearBatchOrder()
        BatchOrderService.removeBatchOrderFromOrders(batchOrderId)
      } catch (error) {
        HandleError({ error })
      }
    }
  }

export const EditBatchOrder =
  ({ BatchOrderService, RestService, UIService, pResponseEditBatchOrder }) =>
  async () => {
    const { id: batchOrderId } = BatchOrderService.getState().batchOrder
    let batchOrder = await RestService.get(
      `/api/admin/order_batches/${batchOrderId}`,
    )
    batchOrder = pResponseEditBatchOrder(batchOrder)
    BatchOrderService.setEditBatchOrder(batchOrder)
    UIService.EditBatchOrder.show('edit')
  }

export const SaveBatchOrder =
  ({
    BatchOrderService,
    LoadBatchOrders,
    SelectBatchOrder,
    RestService,
    RouterService,
    SessionService,
    UIService,
    pRequestUpdateBatchOrder,
    pResponseBatchOrder,
    pResponseBatchOrderError,
    HandleError,
  }) =>
  async (data) => {
    const batchOrderData = pRequestUpdateBatchOrder(data)
    let batchOrder
    try {
      if (batchOrderData.id) {
        // update
        batchOrder = await RestService.put(
          `/api/admin/order_batches/${batchOrderData.id}`,
          {
            order_batch: batchOrderData,
          },
        )
      } else {
        // create
        batchOrder = await RestService.post(
          '/api/admin/order_batches',
          {
            order_batch: batchOrderData,
          },
          { timeout: 30000 },
        )
      }
    } catch (error) {
      HandleError({
        UIService,
        RouterService,
        SessionService,
        pResponseError: pResponseBatchOrderError,
      })({ error, namespace: 'editBatchOrderModal' })

      return
    }

    batchOrder = pResponseBatchOrder(batchOrder)
    BatchOrderService.setBatchOrder(batchOrder)
    const editMode = UIService.EditBatchOrder.getMode() === 'edit'
    editMode
      ? BatchOrderService.clearEditBatchOrder()
      : BatchOrderService.clearNewBatchOrder()
    UIService.EditBatchOrder.close()
    SelectBatchOrder(batchOrder.id)
    LoadBatchOrders()
  }

export const UpdateBatchOrderPaymentMethod =
  ({
    BatchOrderService,
    RestService,
    pRequestUpdateBatchOrderPaymentMethod,
    pResponseBatchOrder,
    HandleError,
  }) =>
  async (data) => {
    const batchOrderData = pRequestUpdateBatchOrderPaymentMethod(data)
    let batchOrder

    try {
      if (!batchOrderData.id) {
        return
      }
      batchOrder = await RestService.put(
        `/api/admin/order_batches/${batchOrderData.id}`,
        {
          order_batch: batchOrderData,
        },
        { timeout: 30000 },
      )
    } catch (error) {
      HandleError({ error, namespace: 'manualCharge' })

      return
    }

    batchOrder = pResponseBatchOrder(batchOrder)
    BatchOrderService.setBatchOrder(batchOrder)
  }
