import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Boxes extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="boxes" style={{ background: `${this.props.color}` }}>
        <p className="box_names">{this.props.name}</p>
      </div>
    )
  }
}

Boxes.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
}

export default Boxes
