import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

class SubscriptionLockedIn extends Component {
  render() {
    const { subscription, onNotifyChef, onSendSurveys } = this.props

    return (
      <div
        key={subscription.id}
        className="subscription-card relative p-2 w-1/4 border-solid border border-gray-400 rounded"
      >
        <h3 className="font-bold text-hungryGray mt-1 mb-2">
          {subscription.number}
        </h3>
        <div>LOCKED IN</div>
        <div>
          {Moment(subscription.dateStr, 'MM/DD/YYYY').format('ddd MMM D, YYYY')}
        </div>
        <div>Head Count {subscription.headcount}</div>
        <h3 className="font-bold text-hungryGray mt-1 mb-2">
          Chef {subscription.menu.chef.name + "'"}s {subscription.menu.name}
        </h3>
        <div>
          <label className="font-bold text-hungryGray w-16">Mains</label>
          {subscription.mainItems
            .map((item) => `${item.quantity}x ${item.name}`)
            .join(', ')}
        </div>
        <div>
          <label className="font-bold text-hungryGray w-16">Sides</label>
          {subscription.sideItems
            .map((item) => `${item.quantity}x ${item.name}`)
            .join(', ')}
        </div>
        <div className="flex justify-center flex-col">
          <button
            className="button-neutral focus:outline-none"
            onClick={onNotifyChef}
          >
            Email Chef
          </button>
          <button
            className="button-neutral focus:outline-none my-1"
            onClick={() => onSendSurveys('customer')}
          >
            Email Customer Survey
          </button>
          <button
            className="button-neutral focus:outline-none my-1"
            onClick={() => onSendSurveys('chef')}
          >
            Email Chef Survey
          </button>
          <button
            className="button-neutral focus:outline-none my-1"
            onClick={() => onSendSurveys('captain')}
          >
            Email Captain Survey
          </button>
        </div>
      </div>
    )
  }
}

SubscriptionLockedIn.propTypes = {
  subscription: PropTypes.object,

  onNotifyChef: PropTypes.func,
  onSendSurveys: PropTypes.func,
}

export default SubscriptionLockedIn
