import { connect } from 'react-redux'

import { PopUpModal } from '@components/popUps'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'
import { config } from 'hungry-core2'
import { checkOrderIsEditableNow, orderBalancesEditCutoffDate } from '@utils'

const mapStateToProps = (state) => {
  const { user = {}, headquarter = {}, router } = state
  const { lastLoggedInHq, permissions } = user
  const { userHeadquarters = [] } = headquarter
  const userPermissions = permissions[lastLoggedInHq]
  const userHeadquarter =
    userHeadquarters.find((hq) => hq.id === lastLoggedInHq) || {}
  const port = router.location.port === 3000 ? 3001 : 3000
  const uri =
    config.marketplace_url +
    (config.marketplace_url === 'http://localhost' ? `:${port}` : '')
  const invoiceUrl = `${uri}/pop-up-invoices`
  const popUpUrl = `${config.api_host}/pop-ups`

  return {
    invoiceUrl,
    popUpUrl,
    locale: userHeadquarter.locale,
    headquarter: userHeadquarter.id,
    userPermissions,
  }
}

const mapDispatchToProps = () => {
  const {
    pResponseAccountExecutives,
    pResponseChefs,
    pResponsePopUpAddOnMenuItems,
    pResponsePopUpServiceItems,
    pResponsePopUpConcepts,
    pResponsePopUpAccount,
    pResponsePopUpAccounts,
  } = presenters.Api
  const { PopUpService, RestService, UIService } = services
  const {
    DeletePopUp,
    GetPopUpPrePaymentTax,
    LoadAccount,
    LoadAccountExecutives,
    LoadPopUpMenuItems,
    LoadPopUpServiceItems,
    SearchPopUpAddOnChefs,
    SavePopUp,
    SearchDetailAccounts,
    SearchPopUpConcepts,
    SearchRecurringPopUps,
  } = coordinators
  const { newPopUp, newMenu } = PopUpService

  const getPopUpClient = LoadAccount({
    RestService,
    pResponseAccount: pResponsePopUpAccount,
  })
  const getPopUpPrePaymentTax = GetPopUpPrePaymentTax({
    RestService,
    HandleError,
  })
  const savePopUp = SavePopUp({
    RestService,
    UIService,
    HandleError,
  })
  const searchPopUpConcepts = SearchPopUpConcepts({
    RestService,
    pResponsePopUpConcepts,
    HandleError,
  })
  const loadPopUpMenuItems = LoadPopUpMenuItems({
    RestService,
    pResponseMenuItems: pResponsePopUpAddOnMenuItems,
  })
  const loadPopUpServiceItems = LoadPopUpServiceItems({
    RestService,
    pResponsePopUpServiceItems,
  })
  const searchPopUpClients = SearchDetailAccounts({
    RestService,
    pResponseAccounts: pResponsePopUpAccounts,
  })
  const searchSalesReps = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })(false) // is not vcx
  const searchPopUpAddOnChefs = SearchPopUpAddOnChefs({
    RestService,
    pResponseChefs,
  })
  const deletePopUp = DeletePopUp({
    RestService,
    UIService,
    HandleError,
  })
  const searchRecurringPopUps = SearchRecurringPopUps({
    RestService,
    HandleError,
  })
  const showConfirmationModal = UIService.ConfirmationModal.show
  const { displayFailureMessage, displayWarningMessage } =
    UIService.FlashMessage

  return {
    checkOrderIsEditableNow,
    deletePopUp,
    displayFailureMessage,
    displayWarningMessage,
    getPopUpPrePaymentTax,
    getPopUpClient,
    loadPopUpMenuItems,
    loadPopUpServiceItems,
    newPopUp,
    newMenu,
    orderBalancesEditCutoffDate,
    savePopUp,
    searchPopUpAddOnChefs,
    searchPopUpClients,
    searchPopUpConcepts,
    searchSalesReps,
    showConfirmationModal,
    searchRecurringPopUps,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpModal)
