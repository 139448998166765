import { TOGGLE_EDIT_USER_MODAL } from '@actions/ui/userPage'

const initialEditUserModalState = { show: false }

export const editUserModal = (state = initialEditUserModalState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_USER_MODAL:
      return {
        ...state,
        show: action.show,
      }
    default:
      return state
  }
}
