import {
  CLOSE_CONFIRMATION_MODAL,
  SHOW_CONFIRMATION_MODAL,
} from '@actions/ui/confirmation'

const initialState = {
  show: false,
}

export const confirmationModal = (state = initialState, action) => {
  const { type, title, text } = action

  switch (type) {
    case SHOW_CONFIRMATION_MODAL:
      return { show: true, text, title }
    case CLOSE_CONFIRMATION_MODAL:
      return { show: false }
    default:
      return state
  }
}
