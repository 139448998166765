import { camelCaseify, formAdd } from '~/utils'

export const pResponseSnackPacks = (json) => {
  const snackPacks = json.map((j) => {
    const res = camelCaseify(j)
    res.snackPackId = res.id
    res.cost = parseFloat(res.cost)
    res.price = parseFloat(res.price)
    res.itemType = 'SnackPack'

    return res
  })

  return snackPacks
}

export const pResponseSnackPack = (json) => {
  const snackPack = camelCaseify(json)

  return snackPack
}

export const pRequestUpdateSnackPack = (data) => {
  const req = {}

  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'chefId', 'chef_id')
  formAdd(data, req, 'name', 'name')
  formAdd(data, req, 'isEnabled', 'is_enabled')
  formAdd(data, req, 'isApproved', 'is_approved')
  formAdd(data, req, 'description', 'description')
  formAdd(data, req, 'price', 'price')
  formAdd(data, req, 'cost', 'cost')

  return { snack_pack: req }
}

export const pDefaultSnackPackFields = () => {
  return {
    name: '',
    description: '',
    marketPrice: 0.0,
    price: 0.0,
    quantity: 0,
  }
}
