import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors } from '../../../../constants'

class RadioButton extends Component {
  render() {
    const { checked, label, name, testId, value, onChange, marginBottom } =
      this.props

    return (
      <RadioButtonContainer key={value} marginBottom={marginBottom}>
        <input
          type="radio"
          value={value}
          id={testId ? `${testId}-${value}` : value}
          checked={checked}
          name={name}
          // onChange={ onChange }
          onClick={onChange}
        />
        <label htmlFor={value}>{label || value}</label>
      </RadioButtonContainer>
    )
  }
}

const RadioButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.marginBottom || 0};
  label {
    padding-left: 5px;
    font-family: 'regular';
  }
  input[type="radio"] {
    margin-top: 0;
    appearance: none;
    cursor: pointer;
    background-color: #fafbff;
    border: 1px solid gray;
    height: 20px;
    width: 20px;
    min-width: 20px;
    border-radius: 25px;
    display: inline-block;
  }
  input[type="radio"]:checked {
    background-color: #fff;
    border-color: ${colors.orange};
    border: 5px solid ${colors.orange};
    position: relative;
  }
}
`

RadioButton.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  testId: PropTypes.string,
  value: PropTypes.string,
  marginBottom: PropTypes.string,

  onChange: PropTypes.func,
}

export default RadioButton
