import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors } from '../../../../constants'

class Checkbox extends Component {
  state = { checked: false }

  componentDidMount() {
    this.setState({ checked: this.props.checked })
  }

  componentWillReceiveProps(nextProps) {
    const { checked } = nextProps
    if (checked !== this.props.checked) {
      this.setState({ checked })
    }
  }

  render() {
    const { checked } = this.state
    const {
      disabled,
      inputClass,
      label,
      onChange,
      onClick,
      marginBottom,
      marginTop,
      testId,
      value,
      width,
    } = this.props

    return (
      <CheckboxContainer
        marginBottom={marginBottom}
        marginTop={marginTop}
        width={width}
      >
        <input
          type="checkbox"
          value={value}
          id={value}
          checked={checked}
          data-testid={testId}
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
          className={`${inputClass || 'checkbox-input'}`}
        />
        <label htmlFor={value}>{label}</label>
      </CheckboxContainer>
    )
  }
}

const CheckboxContainer = styled.div`
  display: inline-flex;
  width: ${(props) => props.width || '100%'};
  margin-top: ${(props) => props.marginTop || 0};
  margin-bottom: ${(props) => props.marginBottom || 0};
  label {
    padding-left: 5px;
    font-family: 'regular';
  }
  input {
    appearance: none;
    display: none;
    border-radius: 3px;
    margin-top: 0;
    cursor: pointer;
    background-color: #fafbff;
    border: 1px solid gray;
    height: 20px;
    width: 20px !important;
    min-width: 20px;
    align-self: center;
    display: inline-block;
    margin: 0 !important;
  }
  input:checked {
    background-color: ${colors.orange};
    border-color: darken(${colors.orange}, 10%);
    border: 1px solid darken(${colors.orange}, 10%);
    position: relative;
    &:before {
      content: '✔';
      color: #fff;
      position: absolute;
      top: 0;
      left: 3px;
      font-size: 16px;
    }
  }
`

Checkbox.propTypes = {
  inputClass: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  marginBottom: PropTypes.string,
  marginTop: PropTypes.string,
  testId: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,

  onChange: PropTypes.func,
  onClick: PropTypes.func,
}

export default Checkbox
