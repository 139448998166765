import { BaseService } from 'hungry-core2'

import actions from '@actions'

export default class EditMarket extends BaseService {
  closeDeliveryZone = () =>
    this.dispatch(actions.toggleEditDeliveryZoneModal(false))

  closeHQ = () => this.dispatch(actions.toggleEditHQModal(false))

  closeServiceDepot = () =>
    this.dispatch(actions.toggleEditServiceDepotModal(false))

  showDeliveryZone = () =>
    this.dispatch(actions.toggleEditDeliveryZoneModal(true))

  showHQ = () => this.dispatch(actions.toggleEditHQModal(true))

  showServiceDepot = () =>
    this.dispatch(actions.toggleEditServiceDepotModal(true))
}
