import { connect } from 'react-redux'

import { ClientOrderReminder } from '@components/order/orderSections/panel'

import { HandleError } from '@coordinators/composed'
import coordinators from '@coordinators'
import services from '@services'

const mapDispatchToProps = () => {
  const { OrderService, RestService, UIService } = services
  const { DisableAutoEmailClientOrderReminder, EmailClientOrderReminder } =
    coordinators
  const disableAutoEmailClientOrderReminder =
    DisableAutoEmailClientOrderReminder({
      OrderService,
      RestService,
      UIService,
      HandleError,
    })
  const emailClientOrderReminder = EmailClientOrderReminder({
    OrderService,
    RestService,
    UIService,
    HandleError,
  })

  return {
    disableAutoEmailClientOrderReminder,
    emailClientOrderReminder,
  }
}

export default connect(undefined, mapDispatchToProps)(ClientOrderReminder)
