import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import Modal from './Modal.js'
import mlbLogo from '@res/images/mlb.png'
import Select from 'react-select'
import AuthorizedDisplay from '@containers/common/auth/AuthorizedDisplay'

class MLBManageOrdersPage extends Component {
  state = {
    search: '',
    mlbPartner: undefined,
    orders: [],
    menu: undefined,
  }

  componentDidMount() {
    this.onChangePartner(this.props.mlbPartnerName)
  }

  loadMLBOrders = async () => {
    const { loadMLBOrders } = this.props
    const { mlbPartner, search } = this.state
    if (search == '') {
      return
    }
    const orders = await loadMLBOrders({ partnerId: mlbPartner.id, search })
    if (orders) {
      this.setState({ orders })
    }
  }

  loadMLBMenu = async () => {
    const { loadMLBMenu } = this.props
    const { selectedOrder: order, mlbPartner } = this.state
    if (
      order &&
      order.date &&
      order.dropoffAddress &&
      order.dropoffAddress.zip
    ) {
      const menu = await loadMLBMenu({
        mlbPartnerName: mlbPartner.name,
        mDate: Moment(order.date),
        zipCode: order.dropoffAddress.zip,
      })
      if (menu) {
        this.setState({ menu })
      }
    }
  }

  calcOrderBalance = async () => {
    const { selectedOrder } = this.state
    const balances = await this.props.calcMLBOrderBalance(selectedOrder)
    if (balances) {
      const { serviceFee, subtotal, tax, tip, total } = balances
      this.setState({
        selectedOrder: {
          ...selectedOrder,
          serviceFee,
          subtotal,
          tax,
          tip,
          total,
        },
      })
    }
  }

  onChangePartner = async (mlbPartnerName) => {
    const { loadMLBPartner } = this.props
    const mlbPartner = await loadMLBPartner({ mlbPartnerName })
    if (mlbPartner) {
      Moment.tz.setDefault(mlbPartner.locale)
      mlbPartner.calendar = mlbPartner.calendar.map((sched) => {
        return {
          date: sched.date,
          gameDay: Moment(sched.date).format('YYYY-MM-DD'),
        }
      })
      this.setState({ mlbPartner }, this.loadMLBOrders)
    }
  }

  onChangeOrder = (key) => (value) => {
    const { selectedOrder } = this.state
    selectedOrder[key] = value
    this.setState({ selectedOrder: { ...selectedOrder } })
  }

  onChangeDate = (e) => {
    const { selectedOrder, mlbPartner } = this.state
    const { date, gameDay } = mlbPartner.calendar.find(
      (cal) => cal.gameDay === e.target.value,
    )
    this.setState({ selectedOrder: { ...selectedOrder, date, gameDay } })
  }

  onChangeFreeOrder = (e) => {
    const { selectedOrder } = this.state
    selectedOrder.freeOrder = e.target.checked
    if (selectedOrder.freeOrder) {
      selectedOrder.subtotal = '0.0'
      selectedOrder.serviceFee = '0.0'
      selectedOrder.tax = '0.0'
      selectedOrder.tip = '0.0'
      selectedOrder.total = '0.0'
      this.setState({ selectedOrder: { ...selectedOrder } })
    } else {
      this.setState(
        { selectedOrder: { ...selectedOrder } },
        this.calcOrderBalance,
      )
    }
  }

  onChangeAddress = (key) => (e) => {
    const { selectedOrder } = this.state
    const val = e.target.value
    selectedOrder.dropoffAddress[key] = val
    let callback
    if (key === 'zip' && val.length === 5) {
      callback = this.loadMLBMenu
    }
    this.setState({ selectedOrder: { ...selectedOrder } }, callback)
  }

  onCancel = async () => {
    const { cancelMLBOrder } = this.props
    const { selectedOrder } = this.state
    if (
      await cancelMLBOrder({
        partnerId: selectedOrder.partnerId,
        orderId: selectedOrder.id,
        orderNumber: selectedOrder.number,
      })
    ) {
      this.setState(
        {
          selectedOrder: null,
          showEditModal: false,
        },
        this.loadMLBOrders,
      )
    }
  }

  onCopyOrder = (order) => () => {
    const copied = this.props.copyOrder(order)
    const freeOrder = parseFloat(copied.subtotal) === 0.0
    this.setState(
      {
        selectedOrder: { ...copied, freeOrder },
        showEditModal: true,
      },
      this.loadMLBMenu,
    )
  }

  onSelectOrder = (order) => () => {
    const freeOrder = parseFloat(order.subtotal) === 0.0
    this.setState(
      {
        showEditModal: true,
        selectedOrder: { ...order, freeOrder },
      },
      this.loadMLBMenu,
    )
  }

  onSave = async () => {
    const { selectedOrder: order } = this.state
    if (order.dateOfBirth) {
      const legalDate = Moment().subtract(21, 'years')
      const dob = Moment(order.dateOfBirth, 'MM-DD-YYYY')
      if (!dob.isSameOrBefore(legalDate)) {
        this.props.displayFailureMessage('Date of birth is under 21 years old')

        return
      }
    }
    if (await this.props.saveMLBOrder({ order, freeOrder: order.freeOrder })) {
      this.setState(
        {
          selectedOrder: null,
          showEditModal: false,
          editIdx: undefined,
        },
        this.loadMLBOrders,
      )
    }
  }

  sendMLBOrderReceipt =
    ({ orderId, orderNumber }) =>
    () => {
      const { mlbPartner } = this.state
      this.props.sendMLBOrderReceipt({
        orderId,
        orderNumber,
        partnerId: mlbPartner.id,
      })
    }

  // Edit Kits

  onChangeOrderKitQuantity = (idx) => (e) => {
    const { selectedOrder } = this.state
    const quantity = Math.trunc(parseFloat(e.target.value))
    const kit = selectedOrder.kits[idx]
    selectedOrder.kits = [
      ...selectedOrder.kits.slice(0, idx),
      {
        ...kit,
        kitItems: kit.kitItems.map((it) => ({
          ...it,
          quantity: (it.quantity / kit.quantity) * quantity,
          supplies: (it.supplies || []).map((s) => ({
            ...s,
            count:
              (s.count / it.quantity) * (it.quantity / kit.quantity) * quantity,
          })),
        })),
        quantity,
        supplies: (kit.supplies || []).map((s) => ({
          ...s,
          count: (s.count / kit.quantity) * quantity,
        })),
      },
      ...selectedOrder.kits.slice(idx + 1, selectedOrder.kits.length),
    ]
    this.setState({ selectedOrder })
  }

  onAddKitToOrder = () => {
    const { selectedOrder } = this.state
    const kitChoice = this.props.createKitChoice()
    this.setState({
      selectedOrder: {
        ...selectedOrder,
        kits: [...selectedOrder.kits, kitChoice],
      },
      editIdx: ['kits', selectedOrder.kits.length],
    })
  }

  onReplaceOrderKit = (idx) => (kit, quantity) => {
    const { selectedOrder } = this.state
    const kits = [...selectedOrder.kits.slice(0, idx)]

    if (kit) {
      const kitChoice = this.props.createKitChoice({ kit, quantity })
      kits.push(kitChoice)
    } else {
      this.setState({ editIdx: undefined })
    }

    selectedOrder.kits = [
      ...kits,
      ...selectedOrder.kits.slice(idx + 1, selectedOrder.kits.length),
    ]
    this.setState({ selectedOrder })
  }

  // Edit Kit Items

  onChangeOrderKitItemQuantity = (kitIdx, itIdx) => (e) => {
    const { selectedOrder } = this.state
    const quantity = Math.trunc(parseFloat(e.target.value))
    const { kitItems } = selectedOrder.kits[kitIdx]
    const kitItem = kitItems[itIdx]
    selectedOrder.kits = [
      ...selectedOrder.kits.slice(0, kitIdx),
      {
        ...selectedOrder.kits[kitIdx],
        kitItems: [
          ...kitItems.slice(0, itIdx),
          {
            ...kitItem,
            quantity,
            supplies: (kitItem.supplies || []).map((s) => ({
              ...s,
              count: (s.count / kitItem.quantity) * quantity,
            })),
          },
          ...kitItems.slice(itIdx + 1, kitItems.length),
        ],
      },
      ...selectedOrder.kits.slice(kitIdx + 1, selectedOrder.kits.length),
    ]
    this.setState({ selectedOrder })
  }

  onAddOrderKitItem = (kitIdx) => () => {
    const { selectedOrder } = this.state
    const kit = selectedOrder.kits[kitIdx]
    const kitItem = this.props.createAddonChoice()
    selectedOrder.kits = [
      ...selectedOrder.kits.slice(0, kitIdx),
      {
        ...kit,
        kitItems: [...kit.kitItems, kitItem],
      },
      ...selectedOrder.kits.slice(kitIdx + 1, selectedOrder.kits.length),
    ]
    this.setState({ selectedOrder })
  }

  onReplaceOrderKitItem = (kitIdx, itIdx) => (item, quantity) => {
    const { selectedOrder } = this.state
    const kit = selectedOrder.kits[kitIdx]
    const kitItems = [...kit.kitItems.slice(0, itIdx)]

    if (item) {
      const kitItem = this.props.createAddonChoice({ item, quantity })
      kitItems.push(kitItem)
    }

    selectedOrder.kits = [
      ...selectedOrder.kits.slice(0, kitIdx),
      {
        ...kit,
        kitItems: [
          ...kitItems,
          ...kit.kitItems.slice(itIdx + 1, kit.kitItems.length),
        ],
      },
      ...selectedOrder.kits.slice(kitIdx + 1, selectedOrder.kits.length),
    ]
    this.setState({ selectedOrder })
  }

  // Edit Add Ons

  onChangeOrderAddonQuantity = (idx) => (e) => {
    const { selectedOrder } = this.state
    const quantity = Math.trunc(parseFloat(e.target.value))
    const addon = selectedOrder.addons[idx]
    selectedOrder.addons = [
      ...selectedOrder.addons.slice(0, idx),
      {
        ...addon,
        quantity,
        supplies: (addon.supplies || []).map((s) => ({
          ...s,
          count: (s.count / addon.quantity) * quantity,
        })),
      },
      ...selectedOrder.addons.slice(idx + 1, selectedOrder.addons.length),
    ]
    this.setState({ selectedOrder })
  }

  onAddAddonToOrder = () => {
    const { selectedOrder } = this.state
    const addonChoice = this.props.createAddonChoice()
    this.setState({
      selectedOrder: {
        ...selectedOrder,
        addons: [...selectedOrder.addons, addonChoice],
      },
      editIdx: ['addons', selectedOrder.addons.length],
    })
  }

  onReplaceOrderAddon = (idx) => (addon, quantity) => {
    const { selectedOrder } = this.state
    const addons = [...selectedOrder.addons.slice(0, idx)]

    if (addon) {
      const addonChoice = this.props.createAddonChoice({
        item: addon,
        quantity,
      })
      addons.push(addonChoice)
    } else {
      this.setState({ editIdx: undefined })
    }

    selectedOrder.addons = [
      ...addons,
      ...selectedOrder.addons.slice(idx + 1, selectedOrder.addons.length),
    ]
    this.setState({ selectedOrder })
  }

  isEditIdx = (key, idx) => {
    return (
      this.state.editIdx &&
      this.state.editIdx[0] === key &&
      this.state.editIdx[1] === idx
    )
  }

  renderEditModal = () => {
    const { selectedOrder, mlbPartner, menu, editIdx } = this.state
    const { date, gameDay } = selectedOrder
    const mDate = Moment(date)
    let deliverable = 'Needs Zip & Date'
    if (menu) {
      deliverable = menu.deliverable ? 'Yes' : 'No'
    }
    const title = selectedOrder.id
      ? `Edit Order ${selectedOrder.number}`
      : 'New Order'

    return (
      <Modal
        title={title}
        hideModal={() =>
          this.setState({
            selectedOrder: null,
            showEditModal: false,
            editIdx: undefined,
          })
        }
        color="#001940"
      >
        <div className="mlb-modal__inner flex flex-col">
          <h2 className="mlb-modal-subtitle mb-2">Delivery Date</h2>
          <div className="flex justify-between mb-2">
            <div className="mlb-input-container">
              <select
                className="mlb-dropdown"
                value={gameDay}
                onChange={this.onChangeDate}
              >
                <option />
                {mlbPartner.calendar.map((cal) => (
                  <option key={cal.gameDay} value={cal.gameDay}>
                    {cal.gameDay}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="w-full h-4" />

          <h2 className="mlb-modal-subtitle mb-2">Game Time</h2>
          <div className="flex justify-between mb-2">
            <label>{mDate.format('MM/DD/YYYY hh:mm a zz')}</label>
          </div>

          <div className="w-full h-4" />
          <h2 className="mlb-modal-subtitle mb-2">Payment Method</h2>
          <div className="flex justify-between">
            {selectedOrder.paymentMethod && (
              <div className="mlb-input-container w-48%">
                <label>Card Type</label>
                <p>{selectedOrder.paymentMethod.cardType}</p>
                <div className="w-full h-2" />
                <label>Exp Date</label>
                <p>{selectedOrder.paymentMethod.expiration}</p>
              </div>
            )}
            {!selectedOrder.paymentMethod && (
              <div className="mlb-input-container w-48%">
                <label>No payment method</label>
              </div>
            )}
          </div>

          <div className="w-full h-6" />
          <h2 className="mlb-modal-subtitle mb-2">Contact Information</h2>
          <div className="w-full h-2" />
          <div className="flex justify-between">
            <div className="mlb-input-container w-48%">
              <label>First Name</label>
              <input
                value={selectedOrder.firstName}
                type="text"
                onChange={(e) =>
                  this.onChangeOrder('firstName')(e.target.value)
                }
              />
            </div>
            <div className="w-full h-5" />
            <div className="mlb-input-container w-48%">
              <label>last Name</label>
              <input
                value={selectedOrder.lastName}
                type="text"
                onChange={(e) => this.onChangeOrder('lastName')(e.target.value)}
              />
            </div>
          </div>

          <div className="w-full h-5" />
          <div className="flex justify-between">
            <div className="mlb-input-container w-48%">
              <label>Phone</label>
              <input
                value={selectedOrder.phone}
                type="text"
                onChange={(e) => this.onChangeOrder('phone')(e.target.value)}
              />
            </div>
            <div className="w-full h-5" />
            <div className="mlb-input-container w-48%">
              <label>Email</label>
              <input
                value={selectedOrder.clientEmail}
                type="text"
                onChange={(e) =>
                  this.onChangeOrder('clientEmail')(e.target.value)
                }
              />
            </div>
          </div>

          <div className="w-full h-8" />
          <h2 className="mlb-modal-subtitle mb-2">
            Legal Information (required for alcohol)
          </h2>
          <div className="flex justify-between">
            <div className="mlb-input-container w-48%">
              <label>DOB (MM-DD-YYYY)</label>
              <input
                value={selectedOrder.dateOfBirth}
                type="text"
                onChange={(e) =>
                  this.onChangeOrder('dateOfBirth')(e.target.value)
                }
              />
            </div>
            <div className="w-full h-5" />
            <div className="mlb-input-container w-48%">
              <label>Legal Name</label>
              <input
                value={selectedOrder.legalName}
                type="text"
                onChange={(e) =>
                  this.onChangeOrder('legalName')(e.target.value)
                }
              />
            </div>
          </div>

          {/* address section */}
          <div className="h-8" />
          <h2 className="mlb-modal-subtitle mb-2">Address</h2>
          <div className="w-full h-2" />
          <div className="mlb-input-container">
            <label>Line 1</label>
            <input
              value={selectedOrder.dropoffAddress.line1}
              type="text"
              onChange={this.onChangeAddress('line1')}
            />
          </div>
          <div className="w-full h-5" />
          <div className="mlb-input-container">
            <label>Line 2</label>
            <input
              value={selectedOrder.dropoffAddress.line2}
              type="text"
              onChange={this.onChangeAddress('line2')}
            />
          </div>
          <div className="w-full h-5" />
          <div className="flex justify-between">
            <div className="mlb-input-container w-48%">
              <label>City</label>
              <input
                value={selectedOrder.dropoffAddress.city}
                type="text"
                onChange={this.onChangeAddress('city')}
              />
            </div>
            <div className="w-full h-5" />
            <div className="mlb-input-container w-48%">
              <label>State</label>
              <input
                value={selectedOrder.dropoffAddress.state}
                type="text"
                onChange={this.onChangeAddress('state')}
              />
            </div>
          </div>
          <div className="w-full h-5" />
          <div className="mlb-input-container w-48%">
            <label>Zip</label>
            <input
              value={selectedOrder.dropoffAddress.zip}
              type="number"
              onChange={this.onChangeAddress('zip')}
            />
          </div>
          <div className="w-full h-5" />
          <h2 className="mlb-modal-subtitle mb-2">
            Deliverable: <span>{deliverable}</span>
          </h2>

          <div className="w-full h-5" />
          <div className="mlb-input-container">
            <label>Instructions</label>
            <input
              value={selectedOrder.instructions}
              type="text"
              onChange={(e) =>
                this.onChangeOrder('instructions')(e.target.value)
              }
            />
          </div>

          <div className="w-full h-8" />
          <h2 className="mlb-modal-subtitle mb-2">Order Items</h2>
          <div className="mlb-input-container">
            {(selectedOrder.kits || []).every((kit) => kit.id) && (
              <div className="mb-2">
                <button
                  className="mlb-primary-button"
                  onClick={this.onAddKitToOrder}
                >
                  Add Pack
                </button>
              </div>
            )}
            {(selectedOrder.kits || []).map((kit, j) =>
              this.isEditIdx('kits', j) ? (
                <div key={j} className="mb-5 flex flex-col">
                  <div className="mb-1 flex flex-row">
                    <input
                      type="number"
                      className="mr-1 w-8"
                      value={kit.quantity}
                      onChange={this.onChangeOrderKitQuantity(j)}
                    />
                    <Select
                      className="mlb-select"
                      placeholder="Choose Pack"
                      options={menu.kits}
                      labelKey="name"
                      value={kit}
                      onChange={(selKit) =>
                        this.onReplaceOrderKit(j)(selKit, kit.quantity)
                      }
                    />
                  </div>
                  {(kit.kitItems || []).map((kitItem, k) => (
                    <div className="mb-1 flex flex-row" key={k}>
                      &emsp;
                      <input
                        type="number"
                        className="mr-1 w-8"
                        value={kitItem.quantity}
                        onChange={this.onChangeOrderKitItemQuantity(j, k)}
                      />
                      <Select
                        className="mlb-select"
                        placeholder="Choose Item"
                        options={menu.items.filter((it) => !it.addOn)}
                        labelKey="name"
                        value={kitItem}
                        onChange={(selItem) =>
                          this.onReplaceOrderKitItem(j, k)(
                            selItem,
                            kitItem.quantity,
                          )
                        }
                      />
                    </div>
                  ))}
                  <div className="flex items-end justify-end mt-2">
                    <button
                      className="mlb-edit-item-button"
                      style={{
                        backgroundColor: '#fff',
                        color: '#001940',
                        margin: '0 10px',
                      }}
                      onClick={this.onAddOrderKitItem(j)}
                    >
                      Add Kit Item
                    </button>
                    <button
                      className="mlb-primary-button"
                      onClick={() => this.setState({ editIdx: undefined })}
                    >
                      Done
                    </button>
                  </div>
                </div>
              ) : (
                <div key={j} className="mb-5">
                  {menu && !editIdx && (
                    <button
                      className="mr-1 p-1 rounded bg-blue-200 w-10"
                      onClick={() => this.setState({ editIdx: ['kits', j] })}
                    >
                      Edit
                    </button>
                  )}
                  {kit.quantity}x {kit.name}
                  {(kit.kitItems || []).map((kitItem, k) => (
                    <div key={k}>
                      &emsp;{`- ${kitItem.quantity}x ${kitItem.name}`}
                    </div>
                  ))}
                </div>
              ),
            )}
          </div>

          <div className="w-full h-5" />
          <div className="mlb-input-container">
            {(selectedOrder.addons || []).every((addon) => addon.id) && (
              <div className="mb-2">
                <button
                  className="mlb-primary-button"
                  onClick={this.onAddAddonToOrder}
                >
                  Add Add-On
                </button>
              </div>
            )}
            {(selectedOrder.addons || []).map((addon, j) =>
              this.isEditIdx('addons', j) ? (
                <div key={j} className="mb-5 flex flex-row">
                  <input
                    type="number"
                    className="mr-1 w-8"
                    value={addon.quantity}
                    onChange={this.onChangeOrderAddonQuantity(j)}
                  />
                  <Select
                    className="mlb-select"
                    placeholder="Choose Item"
                    options={menu.items.filter((it) => it.addOn)}
                    labelKey="name"
                    value={addon}
                    onChange={(selAddon) =>
                      this.onReplaceOrderAddon(j)(selAddon, addon.quantity)
                    }
                  />
                  <button
                    className="mlb-edit-item-button"
                    style={{
                      backgroundColor: '#001940',
                      color: '#fff',
                      margin: '0 10px',
                    }}
                    onClick={() => this.setState({ editIdx: undefined })}
                  >
                    Done
                  </button>
                </div>
              ) : (
                <div key={j} className="mb-5 flex flex-row">
                  {menu && !editIdx && (
                    <button
                      className="mr-1 p-1 rounded bg-blue-200 w-10"
                      onClick={() => this.setState({ editIdx: ['addons', j] })}
                    >
                      Edit
                    </button>
                  )}
                  <div key={j}>
                    {addon.quantity}x {addon.name}
                  </div>
                </div>
              ),
            )}
          </div>

          <div className="w-full h-5" />
          <div className="flex flex-col">
            <div className="w-40 flex justify-between mb-2">
              <p className="text-lg bold">Subtotal</p>
              <p className="text-base">${selectedOrder.subtotal}</p>
            </div>
            <div className="w-40 flex justify-between mb-2">
              <p className="text-lg bold">Service Fee</p>
              <p className="text-base">${selectedOrder.serviceFee}</p>
            </div>
            <div className="w-40 flex justify-between mb-2">
              <p className="text-lg bold">Tax</p>
              <p className="text-base">${selectedOrder.tax}</p>
            </div>
            <div className="w-40 flex justify-between mb-2">
              <p className="text-lg bold">Tip</p>
              <p className="text-base">${selectedOrder.tip}</p>
            </div>
            <div className="w-40 flex justify-between">
              <p className="text-lg bold">Total</p>
              <p className="text-base">${selectedOrder.total}</p>
            </div>

            <div className="flex items-center mb-3">
              <input
                type="checkbox"
                className="mr-3 mt-0"
                checked={selectedOrder.freeOrder}
                onChange={this.onChangeFreeOrder}
              />
              <p className="bold mt-1 uppercase ml-2">Free Order?</p>
            </div>
          </div>
          <button
            className="mlb-primary-button self-start"
            onClick={this.calcOrderBalance}
          >
            Calculate Balances
          </button>

          <div className="w-full h-5" />
          <div className="flex justify-end">
            {selectedOrder.id && (
              <button
                onClick={this.onCancel}
                className="mt-8 mlb-modal-delete self-end"
              >
                Cancel Order
              </button>
            )}
            <button
              onClick={this.onSave}
              style={{ backgroundColor: '#001940' }}
              className="mt-5 mlb-modal-save self-end"
            >
              Save Changes
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  renderOrdersTable = () => {
    const { orders } = this.state
    const headings = ['Order #', 'Date', 'Contact', 'Address', '']

    return (
      <table className="mlb-menu-table mt-3">
        <tr>
          {headings.map((h) => (
            <th key={h}>{h}</th>
          ))}
        </tr>
        {orders.map((o) => (
          <tr key={o.id}>
            <td>
              <p>{o.number}</p>
            </td>
            <td>
              <p>{o.gameDay}</p>
            </td>
            <td>
              <p>
                {o.firstName} {o.lastName}
              </p>
              <p>Phone: {o.phone}</p>
            </td>
            <td>
              <p>{o.dropoffAddress.line1}</p>
              <p>{o.dropoffAddress.line2}</p>
              <p>
                {o.dropoffAddress.city} {o.dropoffAddress.state},{' '}
                {o.dropoffAddress.zip}
              </p>
            </td>
            <td>
              <div className="flex">
                <button
                  onClick={this.onCopyOrder(o)}
                  className="mlb-edit-item-button"
                  style={{ color: '#001940', borderColor: '#001940' }}
                >
                  Copy Order
                </button>
                <button
                  onClick={this.sendMLBOrderReceipt({
                    orderId: o.id,
                    orderNumber: o.number,
                  })}
                  className="mlb-edit-item-button"
                  style={{ color: '#001940', borderColor: '#001940' }}
                >
                  Send Receipt
                </button>
                <button
                  onClick={this.onSelectOrder(o)}
                  className="mlb-edit-item-button"
                  style={{
                    backgroundColor: '#001940',
                    color: '#fff',
                    margin: '0 10px',
                  }}
                >
                  edit
                </button>
              </div>
            </td>
          </tr>
        ))}
      </table>
    )
  }

  render() {
    const { mlbPartner, showEditModal, search } = this.state

    if (!mlbPartner) {
      return null
    }

    return (
      <div className="mlb-container">
        {showEditModal && this.renderEditModal()}
        {/* SIDEBAR */}
        <div className="mlb-sidebar" style={{ background: '#001940' }}>
          <div className="w-full my-8">
            <img className="w-32 m-auto" src={mlbLogo} />
          </div>
          <AuthorizedDisplay roles={['master admin', 'nationals admin']}>
            <a
              className={`${mlbPartner.id === 'Nationals' && 'active'}`}
              style={{ color: mlbPartner.id === 'Nationals' && '#001940' }}
              onClick={() => this.onChangePartner('Nationals')}
            >
              Nationals
            </a>
          </AuthorizedDisplay>
          <AuthorizedDisplay roles={['master admin', 'rangers admin']}>
            <a
              className={`${mlbPartner.id === 'Rangers' && 'active'}`}
              style={{ color: mlbPartner.id === 'Rangers' && '#001940' }}
              onClick={() => this.onChangePartner('Rangers')}
            >
              Rangers
            </a>
          </AuthorizedDisplay>
        </div>
        {/* PANEL */}
        <div className="mlb-content">
          <p className="mb-2">
            Search order # or type {mlbPartner.prefix} to see all
          </p>
          <div className="flex">
            <div className="mr-2">
              <input
                type="text"
                className="mlb-search"
                value={search}
                onChange={(e) => this.setState({ search: e.target.value })}
              />
            </div>
            <button onClick={this.loadMLBOrders} className="mlb-primary-button">
              Search
            </button>
          </div>

          {this.renderOrdersTable()}
        </div>
      </div>
    )
  }
}

MLBManageOrdersPage.propTypes = {
  mlbPartnerName: PropTypes.string,

  calcMLBOrderBalance: PropTypes.func,
  cancelMLBOrder: PropTypes.func,
  createKitChoice: PropTypes.func,
  createAddonChoice: PropTypes.func,
  copyOrder: PropTypes.func,
  displayFailureMessage: PropTypes.func,
  loadMLBMenu: PropTypes.func,
  loadMLBOrders: PropTypes.func,
  loadMLBPartner: PropTypes.func,
  saveMLBOrder: PropTypes.func,
  sendMLBOrderReceipt: PropTypes.func,
}

export default MLBManageOrdersPage
