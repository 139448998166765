import {
  TOGGLE_EDIT_DELIVERY_ZONE_MODAL,
  TOGGLE_EDIT_HQ_MODAL,
  TOGGLE_EDIT_SERVICE_DEPOT_MODAL,
} from '@actions/ui/marketPage'

const initialEditModalState = { show: false }

export const editDeliveryZoneModal = (
  state = initialEditModalState,
  action,
) => {
  switch (action.type) {
    case TOGGLE_EDIT_DELIVERY_ZONE_MODAL:
      return {
        ...state,
        show: action.show,
      }
    default:
      return state
  }
}

export const editHQModal = (state = initialEditModalState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_HQ_MODAL:
      return {
        ...state,
        show: action.show,
      }
    default:
      return state
  }
}

export const editServiceDepotModal = (
  state = initialEditModalState,
  action,
) => {
  switch (action.type) {
    case TOGGLE_EDIT_SERVICE_DEPOT_MODAL:
      return {
        ...state,
        show: action.show,
      }
    default:
      return state
  }
}
