import { connect } from 'react-redux'

import AuthorizedDisplay from '@components/common/auth/AuthorizedDisplay'

import presenters from '@presenters'

function mapStateToProps(state, ownProps) {
  const { roles, excludeRoles } = ownProps
  const authRoles = roles || excludeRoles || []

  const hasRole = presenters.isAuthorized(state.user, authRoles)

  return { visible: excludeRoles ? !hasRole : hasRole }
}

export default connect(mapStateToProps)(AuthorizedDisplay)
