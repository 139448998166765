import Moment from 'moment-timezone'
import { camelCaseify, formAdd } from '~/utils'

export const pMenuItemMargin = (item) => {
  if (Number(item.chefPrice) === 0 && Number(item.marketPrice) === 0) {
    return '0.00'
  } else if (Number(item.cost) === 0 && Number(item.price) === 0) {
    return '0.00'
  } else if (item.cost && !Number(item.cost)) {
    return 'Invalid Cost'
  } else if (item.price && !Number(item.price)) {
    return 'Invalid Price'
  } else if (Number(item.chefPrice)) {
    return (
      ((Number(item.marketPrice) - Number(item.chefPrice)) /
        Number(item.marketPrice)) *
      100
    ).toFixed(2)
  } else {
    return (
      ((Number(item.price) - Number(item.cost)) / Number(item.price)) *
      100
    ).toFixed(2)
  }
}

export const pResponseMenuItemsSearch = (json) => {
  const menuItems = camelCaseify(json)

  return menuItems.map((item) => {
    return {
      Name: item.name,
      Chef: item.chefName,
      'Dish Type': item.mealType,
      Allergens: item.tagsDietaryPreferenceList.split(',').join(', '),
      Packaging: item.packaging && item.packaging.name,
      'Chef Price': Number(item.cost).toFixed(2),
      'Market Price': Number(item.price).toFixed(2),
      'Retail Price': Number(item.retailPrice).toFixed(2),
      'Menus It’s On': item.concepts.map((c) => c.name),
      Margin: pMenuItemMargin(item),
      'Item Pickup City': item.city,
      Description: item.description,
      '_Last Updated': item.lastEditor
        ? Moment(item.lastEditor.updatedAt)
        : null,
      _Id: item.id,
      Approved: item.isApproved ? 'Yes' : 'No',
    }
  })
}

export const pResponseGroupOrderMenuItemsSearch = (json) => {
  const menuItems = camelCaseify(json)

  return menuItems.map((item) => {
    return {
      id: item.id,
      name: item.name, // autocomplete input doesn't show the name using the 'Name' key
      Name: item.name,
      Chef: item.chefName,
      'Meal Type': item.mealType,
      Allergens: item.tagsDietaryPreferenceList.split(',').join(', '),
      Packaging: item.packaging && item.packaging.name,
      'Chef Price': Number(item.cost).toFixed(2),
      'Market Price': Number(item.price).toFixed(2),
      'Retail Price': Number(item.retailPrice).toFixed(2),
      'Menus It’s On': item.concepts.map((c) => c.name),
      'Item Pickup City': item.city,
      Description: item.description,
      '_Last Updated': item.lastEditor
        ? Moment(item.lastEditor.updatedAt)
        : null,
      _Id: item.id,
      Approved: item.isApproved ? 'Yes' : 'No',
    }
  })
}

export const pRequestBulkUpdateMenuItems = (data) => {
  const req = []

  data.forEach((menuItem) => {
    const updateAttributes = {}
    formAdd(menuItem, updateAttributes, 'Retail Price', 'retail_price')
    formAdd(menuItem, updateAttributes, 'Market Price', 'price')
    formAdd(menuItem, updateAttributes, 'Description', 'description')
    formAdd(
      menuItem,
      updateAttributes,
      'Allergens',
      'tags_dietary_preference_list',
    )

    const bulkMenuItem = {}
    formAdd(menuItem, bulkMenuItem, '_Id', 'id')
    formAdd(
      { updateAttributes },
      bulkMenuItem,
      'updateAttributes',
      'update_attributes',
    )

    req.push(bulkMenuItem)
  })

  return { menu_items: req }
}
