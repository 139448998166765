import { BaseService } from 'hungry-core2'
import Moment from 'moment-timezone'

import actions from '@actions'

export class BatchOrderService extends BaseService {
  appendBatchOrders = (batchOrders) => {
    this.dispatch(actions.appendBatchOrders(batchOrders))
  }

  clearEditBatchOrder = () => {
    this.dispatch(actions.clearEditBatchOrder())
  }

  clearNewBatchOrder = () => {
    this.dispatch(actions.clearNewBatchOrder())
  }

  clearBatchOrder = () => {
    this.dispatch(actions.clearBatchOrder())
  }

  clearBatchOrders = () => {
    this.dispatch(actions.clearBatchOrders())
  }

  removeBatchOrderFromOrders = (batchOrderId) => {
    this.dispatch(actions.removeBatchOrderFromOrders(batchOrderId))
  }

  setEditBatchOrder = (batchOrder) => {
    this.dispatch(actions.setEditBatchOrder(batchOrder))
  }

  setNewBatchOrder = (batchOrder) => {
    this.dispatch(actions.setNewBatchOrder(batchOrder))
  }

  setBatchOrder = (batchOrder) => {
    this.dispatch(actions.setBatchOrder(batchOrder))
  }

  setBatchOrders = (batchOrders) => {
    this.dispatch(actions.setBatchOrders(batchOrders))
  }

  setChildOrders = (childOrders) => {
    this.dispatch(actions.setchildOrders(childOrders))
  }

  updateEditBatchOrder = (batchOrder) => {
    this.dispatch(actions.updateEditBatchOrder(batchOrder))
  }

  updateNewBatchOrder = (batchOrder) => {
    this.dispatch(actions.updateNewBatchOrder(batchOrder))
  }

  clearedFinalizedInvoiceSentOrder = () => {
    let { batchOrder } = this.getState()
    batchOrder = {
      ...batchOrder,
      isInvoiceFinalized: true,
    }

    return batchOrder
  }

  _sortChildOrdersById = (childOrders) => {
    return childOrders.sort((a, b) => {
      const compVal = a.id > b.id ? 1 : -1

      return compVal
    })
  }

  addOrderToChildOrders = ({ order, childOrders }) => {
    // trying to re-add the same order?
    if (childOrders.find((o) => order.id === o.id)) {
      return childOrders
    }
    childOrders = this._sortChildOrdersById(childOrders)

    return [...childOrders, order]
  }

  removeChildOrderFromOrders = ({ order, childOrders }) => {
    const newArray = childOrders.slice()
    const idx = newArray.findIndex((e) => e.id === order.id)
    if (idx !== -1) {
      newArray.splice(idx, 1)
    }
    childOrders = this._sortChildOrdersById(childOrders)

    return newArray
  }

  recalculateContactAndAccountExec = (childOrders) => {
    if (!childOrders || childOrders.length < 1) {
      return { contact: undefined, accountExecutive: undefined }
    }
    const { invoiceContact, accountExecutive } = childOrders && childOrders[0]

    return { contact: invoiceContact, accountExecutive }
  }

  calculateAllTotals = ({
    orders,
    didEditOrders,
    subtotal = 0,
    total = 0,
    serviceFee = 0,
    tax = 0,
    tip = 0,
  }) => {
    if (!didEditOrders) {
      return { subtotal, total, serviceFee, tax, tip }
    }

    orders.forEach((order) => {
      subtotal += parseFloat(order.subtotal)
      total += parseFloat(order.total)
      serviceFee += parseFloat(order.serviceFee)
      tax += parseFloat(order.tax)
      tip += parseFloat(order.tip)
    })

    return { subtotal, total, serviceFee, tax, tip }
  }

  filterAccountOrdersByOrderNumber = ({
    accountOrders,
    searchString,
    startDate,
    endDate,
  }) => {
    return accountOrders.filter((i) => {
      const searchRegEx = new RegExp(searchString, 'i')
      const { contact } = i

      const isOrderNumber = searchRegEx.test(i.orderNumber)
      const isContactEmail = searchRegEx.test(contact && contact.email)
      const isContactName = searchRegEx.test(contact && contact.name)
      const isPONumber = searchRegEx.test(i.purchaseOrderNumber)
      const isMatch =
        isOrderNumber || isContactName || isContactEmail || isPONumber

      if (startDate || endDate) {
        const withinStart = startDate
          ? Moment(i.clientSetUpTime).isAfter(startDate) ||
            Moment(i.clientSetUpTime).isSame(startDate)
          : true
        const withinEnd = endDate
          ? Moment(i.clientSetUpTime).isBefore(endDate) ||
            Moment(i.clientSetUpTime).isSame(endDate)
          : true

        return isMatch && withinStart && withinEnd
      }

      return isMatch
    })
  }
}
