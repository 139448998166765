import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { AutocompleteInput } from '@containers/common/form'

class CateringCaptainDropdown extends Component {
  state = {
    cateringCaptainName: '',
  }

  onSelectCateringCaptain = (cateringCaptain) => {
    const { attribute, order, updateOrder } = this.props
    this.setState({ cateringCaptainName: cateringCaptain.name })

    updateOrder(order.id, attribute, cateringCaptain.id)
  }

  render() {
    const { attribute, key, loadCateringCaptains, order, orderUpdates } =
      this.props
    let { className } = this.props
    const orderUpdate = orderUpdates[order.id]
    const revisionOrder = orderUpdate && orderUpdate[attribute]
    let { cateringCaptainName } = this.state
    cateringCaptainName = cateringCaptainName || order.cateringCaptain
    if (revisionOrder) {
      className = 'updated-input dashboard-small-field'
    }

    return (
      <td key={key}>
        <div className="form-field-container">
          <div className={`dropdown ${className}`}>
            <AutocompleteInput
              value={cateringCaptainName}
              loaderFunction={loadCateringCaptains}
              onSelect={this.onSelectCateringCaptain}
            />
          </div>
        </div>
      </td>
    )
  }
}

CateringCaptainDropdown.propTypes = {
  attribute: PropTypes.string,
  className: PropTypes.string,
  key: PropTypes.string,
  loadCateringCaptains: PropTypes.func,
  order: PropTypes.object,
  orderUpdates: PropTypes.arrayOf(PropTypes.object),

  updateOrder: PropTypes.func,
}

export default CateringCaptainDropdown
