import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import LoadingIndicator from '@components/common/LoadingIndicator'
import { AuthorizedInteractable } from '@containers/common/auth'
import { ButtonsBar } from '@res/styledComponents/index'
import { BiPencil } from 'react-icons/bi'

export class SnackPacks extends Component {
  constructor(props) {
    super(props)

    this.state = {
      snackPacks: props.snackPacks,
      snackPack: null,
      editModalVisible: false,
      sortAsc: true,
    }
  }

  componentDidMount() {
    const { loadChefSnackPacks, chefId, snackPacks } = this.props
    if (!snackPacks) {
      loadChefSnackPacks(chefId)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { snackPacks } = nextProps
    if (snackPacks !== this.state.snackPacks) {
      this.setState({ snackPacks })
    }
  }

  newSnackPack = () => {
    const { newSnackPack } = this.props
    newSnackPack()
  }

  editSnackPack = (snackPack) => {
    const { editSnackPack } = this.props
    editSnackPack(snackPack)
  }

  sortSnackPacksByString = (columnName) => {
    const { snackPacks, sortAsc } = this.state
    const getColumn = (snackPack) =>
      snackPack[columnName] ? snackPack[columnName].toLowerCase() : ''
    const sortedSnackPacks = snackPacks.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ snackPacks: sortedSnackPacks, sortAsc: !sortAsc })
  }

  sortSnackPacksByNumber = (columnName) => {
    const { snackPacks, sortAsc } = this.state
    const getColumn = (item) =>
      item[columnName] ? parseFloat(item[columnName].replace(/[$,]/g, '')) : 0.0
    const sortedSnackPacks = snackPacks.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ snackPacks: sortedSnackPacks, sortAsc: !sortAsc })
  }

  sortSnackPacksByBoolean = (columnName) => {
    const { snackPacks, sortAsc } = this.state
    const getColumn = (item) => (item[columnName] === true ? 1 : 0)
    const sortedSnackPacks = snackPacks.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ snackPacks: sortedSnackPacks, sortAsc: !sortAsc })
  }

  render() {
    const { snackPacks } = this.state
    const areSnackPacksLoaded = !!snackPacks
    const areSnackPacksEmpty = snackPacks && snackPacks.length === 0

    return (
      <div>
        <ButtonsBar>
          <AuthorizedInteractable
            roles={['master admin', 'sales lead', 'sales rep', 'chef lead']}
          >
            <button
              onClick={(event) => {
                event.preventDefault()
                this.newSnackPack()
              }}
            >
              <BiPencil />
              New Snack Pack
            </button>
          </AuthorizedInteractable>
        </ButtonsBar>
        <Panel>
          <Table>
            <thead>
              <tr>
                <th onClick={() => this.sortSnackPacksByString('name')}>
                  Item Name
                </th>
                <th onClick={() => this.sortSnackPacksByNumber('cost')}>
                  Vendor Price
                </th>
                <th onClick={() => this.sortSnackPacksByNumber('price')}>
                  Price
                </th>
                <th onClick={() => this.sortSnackPacksByBoolean('isEnabled')}>
                  Live
                </th>
                <th onClick={() => this.sortSnackPacksByBoolean('isApproved')}>
                  Approved
                </th>
              </tr>
            </thead>
            <tbody>
              {!areSnackPacksLoaded && (
                <tr>
                  <td colSpan="4">
                    <LoadingIndicator />
                  </td>
                </tr>
              )}

              {areSnackPacksLoaded && areSnackPacksEmpty && (
                <tr>
                  <td colSpan="5">
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '1.2em',
                        color: 'rgba(0,0,0,0.2)',
                        padding: '20px',
                        backgroundColor: 'rgba(0,0,0,0.01)',
                      }}
                    >
                      No snack packs yet.
                    </div>
                  </td>
                </tr>
              )}

              {areSnackPacksLoaded &&
                snackPacks.map((snackPack, i) => (
                  <tr
                    key={i}
                    className="clickable"
                    onClick={(e) => {
                      e.preventDefault()
                      this.editSnackPack(snackPack.id)
                    }}
                  >
                    <td>{snackPack.name}</td>
                    <td>${parseFloat(snackPack.cost).toFixed(2)}</td>
                    <td>${parseFloat(snackPack.price).toFixed(2)}</td>
                    <td>
                      {snackPack.isEnabled ? (
                        <span className="approved-check">✔</span>
                      ) : (
                        <span className="not-approved-x">✖</span>
                      )}
                    </td>
                    <td>
                      {snackPack.isApproved ? (
                        <span className="approved-check">✔</span>
                      ) : (
                        <span className="not-approved-x">✖</span>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Panel>
      </div>
    )
  }
}

SnackPacks.propTypes = {
  chefId: PropTypes.string,
  snackPacks: PropTypes.arrayOf(PropTypes.object),

  delayedUpdateSnackPack: PropTypes.func,
  editSnackPack: PropTypes.func,
  loadChefSnackPacks: PropTypes.func,
  newSnackPack: PropTypes.func,
}

export default SnackPacks
