import React from 'react'
import PropTypes from 'prop-types'
import { BiPencil } from 'react-icons/bi'

const infoCopy =
  'All Group Order accounts will be defaulted to the Standard Fee ( 1-9 meals = $125; 10-24 meals = $65; >24 meals = $1.25/meal ). *For multi-location accounts, if the locations have the same address, they will be treated as one location. If they have different addresses, they will be treated separately.'

const parseNumber = (rawValue) => {
  const value = parseFloat(rawValue)
  if (
    typeof value !== 'number' ||
    (typeof value === 'number' && isNaN(value))
  ) {
    return null
  }

  return value
}

const getIsStandardFee = (clientGlobalSettings) => {
  const settings = clientGlobalSettings
    ? clientGlobalSettings.deliveryFeeSettings
    : null
  if (!settings) {
    return true
  }
  const flatFee = parseNumber(settings.flatFee)
  const perOrderFee = parseNumber(settings.perOrderFee)

  return !settings.waiveAllFees && !flatFee && !perOrderFee
}

const getDescription = (clientGlobalSettings) => {
  const settings = clientGlobalSettings
    ? clientGlobalSettings.deliveryFeeSettings
    : null
  if (!settings) {
    return 'Standard Fee'
  }
  if (settings.waiveAllFees) {
    return 'Waive all fees'
  }
  const flatFee = parseNumber(settings.flatFee)
  // TODO: currently an empty flatFee value from backend will be returned
  // as "0", string representation of 0. May need to adjust this so it is
  // set to null
  if (typeof flatFee === 'number' && flatFee > 0) {
    if (settings.flatFeeWaiveStandard) {
      return `Flat fee $${flatFee.toFixed(2)}, Standard fee waived`
    } else {
      return `Flat fee $${flatFee.toFixed(2)}, Standard fee also applied`
    }
  }

  const percentFee = parseNumber(settings.percentFee)
  // TODO: currently an empty percentFee value from backend will be returned
  // as "0", string representation of 0. May need to adjust this so it is
  // set to null
  if (typeof percentFee === 'number' && percentFee > 0) {
    if (settings.percentFeeWaiveStandard) {
      return `Percent fee ${percentFee.toFixed(2)}%, Standard fee waived`
    } else {
      return `Percent fee ${percentFee.toFixed(2)}%, Standard fee also applied`
    }
  }

  const perOrderFee = parseNumber(settings.perOrderFee)
  if (typeof perOrderFee === 'number' && perOrderFee > 0) {
    if (settings.perOrderFeeWaiveStandard) {
      return `Custom fee $${perOrderFee.toFixed(2)}/meal, Standard fee waived`
    } else if (settings.perOrderFeeNoApplyAboveStandard) {
      return `Standard fee applied, if > 24 items custom fee of $${perOrderFee.toFixed(
        2,
      )}/meal applied`
    } else {
      return `Custom fee $${perOrderFee.toFixed(
        2,
      )}/meal, Standard fee also applied`
    }
  }

  return 'Standard Fee'
}

export const DeliveryFeeSettingsSummary = (props) => {
  const { clientGlobalSettings, onRequestEdit } = props
  const description = getDescription(clientGlobalSettings)
  const isStandardFee = getIsStandardFee(clientGlobalSettings)
  const title = 'Client Global Delivery Fee Settings'

  return (
    <div className="delivery-settings-summary">
      <div className="summary-header">
        <h3>{title}</h3>
        <div>
          <button onClick={onRequestEdit} className="edit-button">
            <div className="button-content">
              <BiPencil />
              <div>Edit</div>
            </div>
          </button>
        </div>
      </div>
      <div className="summary-description">
        <p>{description}</p>
        {isStandardFee && <p className="standard-fee-info">{infoCopy}</p>}
      </div>
    </div>
  )
}

DeliveryFeeSettingsSummary.propTypes = {
  onRequestEdit: PropTypes.func,
  clientGlobalSettings: PropTypes.object,
}

export default DeliveryFeeSettingsSummary
