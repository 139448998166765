import { connect } from 'react-redux'

import { PopUpDiscountsPage } from '@components/popUps'

import coordinators from '@coordinators'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { lastLoggedInHq } = state.user

  return {
    headquarter: lastLoggedInHq,
  }
}

const mapDispatchToProps = () => {
  const { RestService } = services
  const { SearchPopUpDiscounts } = coordinators
  const searchPopUpDiscounts = SearchPopUpDiscounts({
    RestService,
    HandleError,
  })

  return {
    searchPopUpDiscounts,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpDiscountsPage)
