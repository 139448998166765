export const SET_DATE_MENUS = 'SET_DATE_MENUS'
export const UPDATE_DATE_MENUS = 'UPDATE_DATE_MENUS'
export const SET_CHANGED_DATE_MENU_MAP = 'SET_CHANGED_DATE_MENU_MAP'
export const CLEAR_CHANGED_DATE_MENU_MAP = 'CLEAR_CHANGED_DATE_MENU_MAP'
export const SET_AUTO_STAGED_MENUS = 'SET_AUTO_STAGED_MENUS'

export const setDateMenus = ({ dateMenus }) => ({
  type: SET_DATE_MENUS,
  dateMenus,
})
export const updateDateMenus = ({ dateMenus, changedDateMenuMap }) => ({
  type: UPDATE_DATE_MENUS,
  dateMenus,
  changedDateMenuMap,
})
export const setChangedDateMenuMap = ({ changedDateMenuMap }) => ({
  type: SET_CHANGED_DATE_MENU_MAP,
  changedDateMenuMap,
})
export const clearChangedDateMenuMap = () => ({
  type: CLEAR_CHANGED_DATE_MENU_MAP,
})
export const setAutoStagedMenus = ({ autoStagedMenus }) => ({
  type: SET_AUTO_STAGED_MENUS,
  autoStagedMenus,
})
