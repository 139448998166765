import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Panel from '@components/common/Panel'
import ChefCalendar from '@containers/chef/ChefCalendar'
import Moment from 'moment-timezone'

export class Orders extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      orders: [],
      calendarStartDate: Moment().startOf('month'),
      calendarEndDate: Moment().endOf('month'),
    }
  }

  async fetchChefOrders({ start, end }) {
    this.setState({ isLoading: true })
    const { chefId, loadChefOrders } = this.props
    // Fetch Chef Orders without items to avoid slow request
    const orders = []
    const orderRequestMap = {}
    let startDate = Moment(start)
    // Iterate until start date is after the end date
    while (startDate.isBefore(Moment(end))) {
      const endDate = startDate.clone().add(7, 'days')
      orderRequestMap[startDate.format('MM/DD/YYYY')] = loadChefOrders({
        chefId,
        calendarStartDate: startDate.format('MM/DD/YYYY'),
        calendarEndDate: endDate.format('MM/DD/YYYY'),
        withoutItems: true,
      })
      // Assign start date to be previous end date plus one day
      // Need to add one day so there are no duplicate orders
      startDate = endDate.add(1, 'days')
    }

    await Promise.all(Object.values(orderRequestMap)).then((ordersRespArr) => {
      ordersRespArr.forEach((ordersResp) => orders.push(...ordersResp))
    })

    this.setState({ orders: orders, isLoading: false })
  }

  loadOrder = async ({ chefId, orderId, orderType }) => {
    const { loadChefOrder } = this.props
    const order = await loadChefOrder({ chefId, orderId, orderType })
    const orders = this.state.orders.map((o) => {
      if (o.id === order.id) {
        return order
      }

      return o
    })
    this.setState({ orders })
  }

  async prevMonth(date) {
    const start = Moment(date).subtract(1, 'months').startOf('month')
    const end = Moment(date).subtract(1, 'months').endOf('month')
    this.setState({
      calendarStartDate: start,
      calendarEndDate: end,
    })
    await this.fetchChefOrders({
      start: start.format('MM/DD/YYYY'),
      end: end.format('MM/DD/YYYY'),
    })
  }

  async nextMonth(date) {
    const start = Moment(date).add(1, 'months').startOf('month')
    const end = Moment(date).add(1, 'months').endOf('month')
    this.setState({
      calendarStartDate: start,
      calendarEndDate: end,
    })
    await this.fetchChefOrders({
      start: start.format('MM/DD/YYYY'),
      end: end.format('MM/DD/YYYY'),
    })
  }

  componentWillMount() {
    ;(async () => {
      await this.fetchChefOrders({
        start: this.state.calendarStartDate.utc().format('MM/DD/YYYY'),
        end: this.state.calendarEndDate.utc().format('MM/DD/YYYY'),
      })
    })()
  }

  render() {
    const { calendarStartDate, orders, isLoading } = this.state
    const date = calendarStartDate.clone()

    return (
      <Panel>
        <ChefCalendar
          isLoading={isLoading}
          prevMonth={() => this.prevMonth(date)}
          nextMonth={() => this.nextMonth(date)}
          loadOrder={this.loadOrder}
          month={date.month()}
          year={date.year()}
          orders={orders}
          chefId={this.props.chefId}
        />
      </Panel>
    )
  }
}

Orders.propTypes = {
  chefId: PropTypes.string,
  loadChefOrders: PropTypes.func,
  loadChefOrder: PropTypes.func,
}

export default Orders
