import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { AutocompleteInput } from '@containers/common/form'

class BatchAccountExecutiveSection extends Component {
  onSelectAccountExecutive = (accountExecutive) => {
    this.props.onChange({ accountExecutive })
  }

  render() {
    const { accountExecutive, loadAccountExecutives, width } = this.props

    return (
      <AutocompleteInput
        width={width}
        label="Sales Rep"
        value={accountExecutive && accountExecutive.name}
        loaderFunction={loadAccountExecutives}
        onSelect={this.onSelectAccountExecutive}
      />
    )
  }
}

BatchAccountExecutiveSection.propTypes = {
  accountExecutive: PropTypes.object,
  width: PropTypes.string,
  loadAccountExecutives: PropTypes.func,
  onChange: PropTypes.func,
}

export default BatchAccountExecutiveSection
