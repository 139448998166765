import { BaseService } from 'hungry-core2'

import actions from '@actions'
import Moment from 'moment-timezone'

export class DashboardService extends BaseService {
  clearOrders = () => {
    this.dispatch(actions.clearDashboardOrders())
  }

  setOrders = (orders) => {
    this.dispatch(actions.setDashboardOrders(orders))
  }

  suggestServiceTime = (type, order, orderUpdate) => {
    const { headCount } = order
    const clientSetUpTime =
      (orderUpdate && orderUpdate.clientSetUpTime) || order.clientSetUpTime
    const clientArrivalTime =
      (orderUpdate && orderUpdate.clientArrivalTime) || order.clientArrivalTime
    const kitchenDepartureTime =
      (orderUpdate && orderUpdate.kitchenDepartureTime) ||
      order.kitchenDepartureTime
    const kitchenArrivalTime =
      (orderUpdate && orderUpdate.kitchenArrivalTime) ||
      order.kitchenArrivalTime
    const hqDepartureTime =
      (orderUpdate && orderUpdate.hqDepartureTime) || order.hqDepartureTime

    let nextTime
    switch (type) {
      case 'clientArrivalTime':
        nextTime = clientSetUpTime
        break
      case 'kitchenDepartureTime':
        nextTime = clientArrivalTime
        break
      case 'kitchenArrivalTime':
        nextTime = kitchenDepartureTime
        break
      case 'hqDepartureTime':
        nextTime = kitchenArrivalTime
        break
      case 'hqArrivalTime':
        nextTime = hqDepartureTime
        break
      default:
        return null
    }
    if (!headCount || !clientSetUpTime || !nextTime) {
      return null
    }

    let offset = 0
    /* Setup style offset */
    if (type === 'clientArrivalTime') {
      if (order.set_up_style && order.set_up_style === 'Luxury Buffet') {
        if (headCount <= 80) {
          offset += 15
        } else if (headCount > 80) {
          offset += 30
        }
      } else if (
        order.set_up_style &&
        order.set_up_style === 'Individual Boxed Lunches'
      ) {
        offset += -15
      }
    }

    /* headcount offset */
    switch (type) {
      case 'clientArrivalTime':
        if (headCount <= 40) {
          offset += 30
        } else if (headCount <= 120) {
          offset += 45
        } else if (headCount > 120) {
          offset += 60
        }
        break
      case 'kitchenDepartureTime':
        offset += 75
        break
      case 'kitchenArrivalTime':
        if (headCount <= 80) {
          offset += 30
        } else if (headCount > 80) {
          offset += 45
        }
        break
      case 'hqDepartureTime':
        offset += 45
        break
      case 'hqArrivalTime':
        offset += 30
        break
    }

    return Moment(nextTime).subtract(offset, 'minutes')
  }
}
