export const LoadHomeMenus =
  ({ RestService, HandleError }) =>
  async (hqId) => {
    const zip = {
      1: '20750',
      2: '19022',
      3: '30122',
      4: '02447',
      5: '11782',
      6: '78704',
      7: '75220',
    }[hqId]
    let concepts
    try {
      concepts = await RestService.put(
        '/smart_catering_engine/suggested_concepts',
        { zip_code: zip, market_type: 'Home' },
      )
      concepts = concepts.concepts.map((c) => ({
        id: c.id,
        name: `Chef ${c.chef.name}'s ${c.name}`,
      }))
    } catch (error) {
      HandleError({ error })

      return undefined
    }

    return concepts
  }

export const LoadHomeMenu =
  ({ RestService, pResponseHomeMenu, HandleError }) =>
  async (menuId) => {
    let menu
    try {
      const concepts = await RestService.get(
        `/smart_catering_engine/find?concept_ids=${menuId}`,
      )
      menu = pResponseHomeMenu(concepts.concepts[0])
    } catch (error) {
      HandleError({ error })
    }

    return menu
  }

export const SkipWeek =
  ({ RestService, SubscriptionService, pResponseSubscriber, HandleError }) =>
  async (memberId, week) => {
    let member
    try {
      const member = await RestService.post(
        `/subscriptions/members/${memberId}/skip`,
        { week },
      )
      SubscriptionService.replaceMember(pResponseSubscriber(member))
    } catch (error) {
      HandleError({ error })
    }

    return member
  }

export const UnskipWeek =
  ({ RestService, SubscriptionService, pResponseSubscriber, HandleError }) =>
  async (memberId, week) => {
    let member
    try {
      const member = await RestService.post(
        `/subscriptions/members/${memberId}/unskip`,
        { week },
      )
      SubscriptionService.replaceMember(pResponseSubscriber(member))
    } catch (error) {
      HandleError({ error })
    }

    return member
  }

export const LoadSubscriptions =
  ({
    SubscriptionService,
    RestService,
    pResponseSubscription,
    pSubscriptionCutoffs,
    HandleError,
  }) =>
  async ({ hqId, from, to, searchTerm, repId }) => {
    try {
      let allSubs = []
      let offset = 0
      const limit = 100
      while (true) {
        const params = {
          hq: hqId,
          search: searchTerm,
          limit,
          offset,
        }
        if (to) {
          params.to = to.format()
        }
        if (from) {
          params.from = from.format()
        }
        if (repId) {
          params.repId = repId
        }
        const subs = (await RestService.get('/subscriptions', params)) || []
        if (subs.length === 0) {
          break
        }
        allSubs.push(...subs)
        offset += limit
      }
      const [lockedInCutoff] = pSubscriptionCutoffs()
      allSubs = allSubs.map((s) => pResponseSubscription(lockedInCutoff)(s))
      SubscriptionService.setSubscriptions(allSubs)
    } catch (error) {
      HandleError({ error })
    }
  }

export const SaveSubscription =
  ({ RestService, pRequestSubscription, HandleError }) =>
  async (subscription) => {
    try {
      const req = pRequestSubscription(subscription)
      await RestService.put(
        `/subscriptions/members/${subscription.memberId}/choice/${subscription.id}`,
        req,
      )

      return true
    } catch (error) {
      HandleError({ error })
    }

    return false
  }

export const ChooseSubsSalesRep =
  ({ RestService, pRequestChooseSalesRep, HandleError }) =>
  async (subscription, salesRep) => {
    try {
      const req = pRequestChooseSalesRep(salesRep)
      await RestService.put(
        `/subscriptions/members/${subscription.memberId}/choose_rep/${subscription.id}`,
        req,
      )

      return true
    } catch (error) {
      HandleError({ error })
    }

    return false
  }

export const SaveMemberSubscription =
  ({
    RestService,
    SubscriptionService,
    pRequestSubscription,
    pResponseSubscriber,
    HandleError,
  }) =>
  async (member, subscription) => {
    try {
      let newMember
      const req = pRequestSubscription(subscription)
      if (subscription.isNew) {
        newMember = await RestService.post(
          `/subscriptions/members/${member.id}/choice`,
          { choices: [req] },
        )
      } else {
        const subscriptions = await RestService.put(
          `/subscriptions/members/${member.id}/choice/${subscription.id}`,
          req,
        )
        newMember = { ...member, subscriptions }
      }

      newMember = pResponseSubscriber(newMember)
      SubscriptionService.replaceMember(newMember)

      return true
    } catch (error) {
      HandleError({ error })
    }

    return false
  }

export const DeleteSubscription =
  ({ RestService, UIService, HandleError }) =>
  async (subscription) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: 'Are you sure you want to delete this subscription?',
    })
    if (doDelete) {
      try {
        await RestService.delete(
          `/subscriptions/members/${subscription.memberId}/choice/${subscription.id}`,
        )

        return true
      } catch (error) {
        HandleError({ error })
      }
    }

    return false
  }

export const DeleteMemberSubscription =
  ({
    RestService,
    SubscriptionService,
    UIService,
    pResponseSubscriber,
    HandleError,
  }) =>
  async (member, subscriptionId) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: 'Are you sure you want to delete this subscription?',
    })
    if (doDelete) {
      try {
        const subscriptions = await RestService.delete(
          `/subscriptions/members/${member.id}/choice/${subscriptionId}`,
        )
        let newMember = { ...member, subscriptions }
        newMember = pResponseSubscriber(newMember)
        SubscriptionService.replaceMember(newMember)

        return true
      } catch (error) {
        HandleError({ error })
      }
    }

    return false
  }

export const NotifySubscriptionChef =
  ({ RestService, UIService, HandleError }) =>
  async (subscriptionId, chefId) => {
    try {
      await RestService.post('/subscriptions/email_chef', {
        subscriptionId,
        chefId,
        force: true,
      })
      UIService.FlashMessage.displaySuccessMessage(
        'Subscription chefs notified!',
      )
    } catch (error) {
      HandleError({ error })
    }
  }

export const SendSubscriptionSurveys =
  ({ RestService, UIService, HandleError }) =>
  async (memberId, subscriptionId, surveyType) => {
    try {
      const type = {
        customer: 'hungry_home_customer_post',
        chef: 'chef_post',
        captain: 'captain_post',
      }[surveyType]
      await RestService.post('/subscriptions/email_surveys', {
        memberId,
        subscriptionId,
        type,
      })
      UIService.FlashMessage.displaySuccessMessage('Subscription surveys sent!')
    } catch (error) {
      HandleError({ error })
    }
  }
