import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Input, LinkText } from '@components/common/form'
import { Label } from '@res/styledComponents/index'
import XSpacing from '@components/common/XSpacing'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { colors } from '../../../../../constants'
import { AutocompleteInput } from '@containers/common/form'
import { TaxStatuses } from '@constants'
import Contact from '@containers/order/edit/sections/Contact'

const InitialState = {
  title: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  purchaserTaxStatus: '',
}

class ContactsSection extends Component {
  state = {
    cateringContact: null,
    invoiceContact: null,
    receiptContact: null,
    ...InitialState,
  }

  onCancel = (contactType) => (contact) => {
    const { key, newKey } = this.props.pContactKeys(contactType)

    this.setState({ [key]: contact, ...InitialState })
    this.props.onChange({ [key]: contact, [newKey]: {} })
  }

  onChangeContact = (contactType) => (contact) => {
    const { cateringContact, invoiceContact, receiptContact } = this.props
    const { key } = this.props.pContactKeys(contactType)

    if (!cateringContact && !invoiceContact && !receiptContact) {
      this.props.onChange({
        cateringContact: contact,
        invoiceContact: contact,
        receiptContact: contact,
      })
    } else {
      this.props.onChange({ [key]: contact })
    }
  }

  onSelectExistingContact = (contactType) => (contact) => {
    const { cateringContact, invoiceContact, receiptContact } = this.props
    const { newKey } = this.props.pContactKeys(contactType)

    if (!cateringContact && !invoiceContact && !receiptContact) {
      this.props.onChange({
        newContact: contact,
      })
    } else {
      this.props.onChange({ [newKey]: contact })
    }
  }

  onInput = (field) => (e) => {
    const { clearError, pBuildContact } = this.props
    this.setState({ [field]: e.target.value }, () => {
      clearError(field)
      const newContact = pBuildContact(this.state)
      this.props.onChange({ newContact })
    })
  }

  onInputTaxStatus = (e) => {
    const { clearError, onChange, pBuildContact } = this.props
    let taxStatus
    switch (e.target.value) {
      case 'true':
        taxStatus = true
        break
      case 'false':
        taxStatus = false
        break
      default:
        taxStatus = null
    }
    this.setState({ purchaserTaxStatus: taxStatus }, () => {
      clearError('purchaserTaxStatus')
      const contact = pBuildContact(this.state)
      onChange && onChange(contact)
    })
  }

  onInputTypedContact = (contactType) => (contact) => {
    const { newKey } = this.props.pContactKeys(contactType)

    this.props.onChange({ [newKey]: contact })
  }

  onSave = async () => {
    const isValid = await this.props.onSave('')
    if (isValid) {
      this.setState({ isNewContact: false, ...InitialState })
    }
  }

  onSaveTypedContact = (contactType) => async () => {
    return await this.props.onSave(contactType)
  }

  renderNewContact() {
    const { errors, loadContacts } = this.props
    const {
      title,
      firstName,
      lastName,
      phoneNumber,
      email,
      purchaserTaxStatus,
    } = this.state
    const contactName = firstName && lastName ? firstName + '' + lastName : ''

    return (
      <FlexContainer width="31%" flexDirection="column">
        <Label>New Point of contact</Label>
        <YSpacing height="3px" />
        <div className="p-3 bg-indigo-100">
          {errors.contact && (
            <p className="text-red-700 bold text-base">{errors.contact}</p>
          )}
          <AutocompleteInput
            label="Select existing contact (optional)"
            value={contactName}
            error={errors.contact}
            loaderFunction={loadContacts}
            onSelect={this.onSelectExistingContact()}
          />
          <YSpacing height="20px" />
          <Input label="Title" value={title} onChange={this.onInput('title')} />
          <Input
            label="First Name"
            value={firstName}
            error={errors.firstName}
            onChange={this.onInput('firstName')}
          />
          <Input
            label="Last Name"
            value={lastName}
            error={errors.lastName}
            onChange={this.onInput('lastName')}
          />
          <Input
            label="Phone #"
            value={phoneNumber}
            error={errors.phoneNumber}
            onChange={this.onInput('phoneNumber')}
          />
          <Input
            label="Email"
            value={email}
            error={errors.email}
            onChange={this.onInput('email')}
          />
          <Dropdown
            label="Purchaser Tax Status"
            width="100%"
            value={purchaserTaxStatus}
            onChange={this.onInputTaxStatus}
          >
            {TaxStatuses.map((status) => (
              <option key={status.value} value={status.value}>
                {status.name}
              </option>
            ))}
          </Dropdown>
          <FlexContainer alignItems="center" justifyContent="flex-end">
            <LinkText
              label="Cancel"
              onClick={this.onCancel}
              color={colors.violet}
            />
            <XSpacing width="20px" />
            <Button label="Save" onClick={this.onSave} />
          </FlexContainer>
        </div>
      </FlexContainer>
    )
  }

  renderContactDropdowns() {
    const {
      clearError,
      errors,
      hasAccount,
      loadContacts,
      newCateringContact,
      newInvoiceContact,
      newReceiptContact,

      invoiceContact,
      receiptContact,
      cateringContact,
    } = this.props

    const contacts = this.props.contacts || []
    // use contact error if present from form submit, else pass specific field errors from adhoc save
    const cateringContactError = errors.cateringContact
      ? { contact: errors.cateringContact }
      : errors
    const invoiceContactError = errors.invoiceContact
      ? { contact: errors.invoiceContact }
      : errors
    const receiptContactError = errors.receiptContact
      ? { contact: errors.receiptContact }
      : errors

    const filterContactsErrors = (key) =>
      key.startsWith('contacts') && !key.startsWith('contacts.accountContacts')
    const mapContactsErrors = (key) => 'contact ' + errors[key]
    const generalContactErrors = Object.keys(errors)
      .filter(filterContactsErrors)
      .map(mapContactsErrors)
      .join(', ')

    return (
      <div>
        {generalContactErrors && (
          <div className="error-message">{generalContactErrors}</div>
        )}
        <FlexContainer width="100%" justifyContent="space-between">
          <Contact
            contactTitle="Catering Point of Contact"
            contact={cateringContact}
            contacts={contacts}
            errors={cateringContactError}
            hasAccount={hasAccount}
            newContact={newCateringContact}
            clearError={clearError}
            onCancel={this.onCancel('catering')}
            onChange={this.onChangeContact('catering')}
            onInputNew={this.onInputTypedContact('catering')}
            onSave={this.onSaveTypedContact('catering')}
            loadContacts={loadContacts}
            width="31%"
          />
          <Contact
            contactTitle="Invoice Point of Contact"
            contact={invoiceContact}
            contacts={contacts}
            errors={invoiceContactError}
            hasAccount={hasAccount}
            newContact={newInvoiceContact}
            clearError={clearError}
            onCancel={this.onCancel('invoice')}
            onChange={this.onChangeContact('invoice')}
            onInputNew={this.onInputTypedContact('invoice')}
            onSave={this.onSaveTypedContact('invoice')}
            loadContacts={loadContacts}
            width="31%"
          />
          <Contact
            contactTitle="Receipt Point of Contact"
            contact={receiptContact}
            contacts={contacts}
            errors={receiptContactError}
            hasAccount={hasAccount}
            newContact={newReceiptContact}
            clearError={clearError}
            onCancel={this.onCancel('receipt')}
            onChange={this.onChangeContact('receipt')}
            onInputNew={this.onInputTypedContact('receipt')}
            onSave={this.onSaveTypedContact('receipt')}
            loadContacts={loadContacts}
            width="31%"
          />
        </FlexContainer>
      </div>
    )
  }

  render() {
    const { contacts } = this.props
    const needsNewContact = contacts && contacts.length === 0

    return (
      <div>
        {needsNewContact
          ? this.renderNewContact()
          : this.renderContactDropdowns()}
      </div>
    )
  }
}

ContactsSection.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object),

  invoiceContact: PropTypes.object,
  receiptContact: PropTypes.object,
  cateringContact: PropTypes.object,

  hasAccount: PropTypes.bool,
  isVCX: PropTypes.bool,

  newInvoiceContact: PropTypes.object,
  newReceiptContact: PropTypes.object,
  newCateringContact: PropTypes.object,

  errors: PropTypes.object,

  clearError: PropTypes.func,
  loadContacts: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  pContactKeys: PropTypes.func,
  pBuildContact: PropTypes.func,
}

ContactsSection.defaultProps = {
  errors: {},
}

export default ContactsSection
