import { Icon } from 'leaflet'

export const blackHQIcon = new Icon({
  iconUrl: 'https://img.icons8.com/?size=100&id=7880&format=png&color=000000',
  iconSize: [35, 35],
})

export const blueChefIcon = new Icon({
  iconUrl: 'https://img.icons8.com/?size=100&id=20441&format=png&color=5C7CFA',
  iconSize: [30, 30],
})

export const cateringChefIcon = new Icon({
  iconUrl: 'https://img.icons8.com/?size=100&id=20441&format=png&color=FF8965',
  iconSize: [30, 30],
})

export const groupOrderChefIcon = new Icon({
  iconUrl: 'https://img.icons8.com/?size=100&id=20441&format=png&color=3FD696',
  iconSize: [30, 30],
})

export const cateringAndGroupOrderChefIcon = new Icon({
  iconUrl: 'https://img.icons8.com/?size=100&id=20441&format=png&color=FC5390',
  iconSize: [30, 30],
})

export const redClientIcon = new Icon({
  iconUrl: 'https://img.icons8.com/?size=100&id=23265&format=png&color=FB1B1B',
  iconSize: [30, 30],
})

export const cateringClientIcon = new Icon({
  iconUrl: 'https://img.icons8.com/?size=100&id=23265&format=png&color=FF8C00',
  iconSize: [30, 30],
})

export const groupOrderClientIcon = new Icon({
  iconUrl: 'https://img.icons8.com/?size=100&id=23265&format=png&color=C71585',
  iconSize: [30, 30],
})

export const cateringAndGroupOrderClientIcon = new Icon({
  iconUrl: 'https://img.icons8.com/?size=100&id=23265&format=png&color=8B0000',
  iconSize: [30, 30],
})
