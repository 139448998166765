import { connect } from 'react-redux'

import Home from '@components/Home'

import services from '@services'
import coordinators from '@coordinators'

const mapStateToProps = (state) => {
  return {
    currentUser: state.user,
  }
}

const mapDispatchToProps = () => {
  const { BraintreeService, RestService, RouterService } = services
  const { InitBraintree, IdentifyUser } = coordinators

  const initBraintree = InitBraintree({ BraintreeService, RestService })
  const redirect = () => RouterService.replace({ pathname: '/signin' })

  return { initBraintree, redirect, identifyUser: IdentifyUser }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
