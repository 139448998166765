import { connect } from 'react-redux'
import { VXLookupsPage } from '@components/virtualExperiences'
import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { accounts } = state
  const { lastLoggedInHq } = state.user

  return {
    headquarter: lastLoggedInHq,
    accounts,
  }
}

const mapDispatchToProps = () => {
  const { pResponseAccountExecutives, pResponseChefs, pResponseAccounts } =
    presenters.Api
  const { RestService, UIService, AccountService } = services
  const {
    SearchVXLookups,
    SaveVXLookup,
    LoadAccountExecutives,
    LoadAccounts,
    SearchVXTalent,
  } = coordinators

  const searchVXLookups = SearchVXLookups({
    RestService,
    HandleError,
  })

  const saveVXLookup = SaveVXLookup({
    RestService,
    UIService,
    HandleError,
  })

  const searchSalesReps = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })(true)

  const searchClients = LoadAccounts({
    AccountService,
    RestService,
    pResponseAccounts,
  })

  const searchTalents = SearchVXTalent({
    RestService,
    pResponseChefs,
  })

  const { displayWarningMessage } = UIService.FlashMessage
  const flashSuccess = (message) =>
    UIService.FlashMessage.displaySuccessMessage(message)
  const showConfirmationModal = UIService.ConfirmationModal.show

  return {
    searchVXLookups,
    saveVXLookup,
    searchSalesReps,
    searchClients,
    searchTalents,
    displayWarningMessage,
    flashSuccess,
    showConfirmationModal,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VXLookupsPage)
