import { connect } from 'react-redux'

import Packaging from '@components/supply/Packaging'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { packaging } = state

  return {
    packaging,
  }
}

const mapDispatchToProps = () => {
  const {
    RestService,
    RouterService,
    SessionService,
    SupplyService,
    UIService,
  } = services
  const { pResponseGeneric, pRequestUpdatePackaging } = presenters.Api
  const { pResponseErrorsArray } = presenters.Errors
  const {
    DeletePackaging,
    LoadPackaging,
    SavePackaging,
    HandleError: HandleError_,
  } = coordinators
  const HandleError = HandleError_({
    RouterService,
    SessionService,
    UIService,
    pResponseError: pResponseErrorsArray,
  })

  const deletePackaging = DeletePackaging({
    LoadPackaging,
    RestService,
    SupplyService,
    UIService,
    pResponsePackaging: pResponseGeneric,
    HandleError,
  })
  const loadPackaging = LoadPackaging({
    RestService,
    SupplyService,
    pResponsePackaging: pResponseGeneric,
    HandleError,
  })
  const savePackaging = SavePackaging({
    LoadPackaging,
    RestService,
    SupplyService,
    pRequestUpdatePackaging,
    pResponsePackaging: pResponseGeneric,
    HandleError,
  })

  return {
    deletePackaging,
    loadPackaging,
    savePackaging,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Packaging)
