export default class PresentValidator {
  type = 'present'

  constructor(key, { map, message } = {}) {
    this.key = key
    this.map = map
    this.message = message
  }

  validate = (data) => {
    if (!data[this.key]) {
      return this.message ? this.message : `${this.key} missing`
    }
  }
}
