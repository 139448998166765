import { TOGGLE_AR_DASHBOARD_LOADING } from '@actions/ui/accountsReceivable'

const accountsReceivableDashboardPageInitialState = {
  isLoading: false,
}

export function accountsReceivableDashboardPage(
  state = accountsReceivableDashboardPageInitialState,
  action,
) {
  const { on, type } = action
  switch (type) {
    case TOGGLE_AR_DASHBOARD_LOADING: {
      return { ...state, isLoading: on }
    }
    default:
      return state
  }
}
