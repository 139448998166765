import { connect } from 'react-redux'

import GOScheduleFilters from '@components/groupOrders/SchedulePlanner/GOSchedulerFilters'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state: any) => {
  const { headquarter, markets, user } = state
  const { userHeadquarters: headquarters } = headquarter

  return {
    markets,
    headquarters,
    userId: user && user.id,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, HeadquarterService, RestService, UIService } = services
  const { AsyncLoadAccounts, LoadChefs, LoadCuisineTags, SearchGOMenus } =
    coordinators
  const { pResponseChefs, pResponseGeneric, pRequestSearchGOMenusParams } =
    presenters.Api
  const { displayFailureMessage } = UIService.FlashMessage

  const loadHeadquarters = coordinators.LoadUserHeadquarters({
    HeadquarterService,
    RestService,
    pResponseHeadquarters: pResponseGeneric,
    HandleError,
  })
  const loadAccounts = AsyncLoadAccounts({ RestService, pResponseGeneric })
  const loadChefs = LoadChefs({ ChefService, RestService, pResponseChefs })

  const loadCuisines = LoadCuisineTags({ RestService, HandleError })

  const searchGOMenus = SearchGOMenus({
    RestService,
    pRequestSearchGOMenusParams,
    HandleError,
  })

  return {
    displayFailureMessage,
    loadHeadquarters,
    loadAccounts,
    loadChefs,
    loadCuisines,
    searchGOMenus,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GOScheduleFilters)
