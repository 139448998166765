import { connect } from 'react-redux'

import ChefCalendar from '@components/chef/ChefCalendar'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'
import { history } from '@store'

const mapStateToProps = (state) => {
  const {
    chef = {},
    user: { locale },
  } = state

  return {
    history,
    chef,
    locale,
  }
}

const mapDispatchToProps = () => {
  const {
    RestService,
    OrderService,
    GroupOrdersService: { setGroupOrderPage },
    UIService,
  } = services
  const { pResponseAccount, pResponseOrder } = presenters.Api
  const {
    SelectOrder,
    SendChefSchedEmailReminder,
    GetGroupOrderById,
    LoadAccount,
  } = coordinators
  const selectOrder = SelectOrder({
    OrderService,
    RestService,
    pResponseOrder,
  })

  const sendChefSchedEmailReminder = SendChefSchedEmailReminder({
    RestService,
    UIService,
    HandleError,
  })

  const loadGroupOrder = GetGroupOrderById({
    RestService,
    HandleError,
  })

  const loadAccount = LoadAccount({ RestService, pResponseAccount })

  return {
    selectOrder,
    sendChefSchedEmailReminder,
    loadGroupOrder,
    loadAccount,
    setGroupOrderPage,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChefCalendar)
