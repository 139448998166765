import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

import { getTheme } from './theme'
import { MLBCalendarView } from '@components/mlbPartners'
import { MLBInventoryPage } from '@containers/mlbPartners'
import { MLBEditMenuPage } from '@containers/mlbPartners'
import { MLBOrdersPage, MLBMailOrdersPage } from '@components/mlbPartners'
import AuthorizedDisplay from '@containers/common/auth/AuthorizedDisplay'

class MLBMaster extends Component {
  state = {
    gameDay: undefined,
    mlbPartner: undefined,
    orders: [],
    mailOrders: [],
    view: 'Orders',
    hideSidebar: false,
  }

  componentDidMount() {
    ;(async () => {
      const { loadMLBPartner, mlbPartnerName } = this.props
      const mlbPartner = await loadMLBPartner({ mlbPartnerName })
      if (mlbPartner) {
        const nextGameDay = mlbPartner.calendar.find((c) =>
          c.date.isAfter(Moment()),
        )
        this.setState(
          { mlbPartner, gameDay: nextGameDay && nextGameDay.date },
          this.loadMLBOrders,
        )
      }
    })()
  }

  loadMLBOrders = async () => {
    const { loadMLBOrders } = this.props
    const { mlbPartner, gameDay, view } = this.state
    const mailOrder = view === 'Pending Mail Orders'
    const deliveredMailOrder = view === 'Delivered Mail Orders'

    if (mailOrder) {
      const mailOrders = await loadMLBOrders({
        partnerId: mlbPartner.id,
        mailOrder: true,
        delivered: false,
      })
      if (mailOrders) {
        this.setState({ mailOrders })
      }
    } else if (deliveredMailOrder) {
      const mailOrders = await loadMLBOrders({
        partnerId: mlbPartner.id,
        mailOrder: true,
        delivered: true,
      })
      if (mailOrders) {
        this.setState({ mailOrders })
      }
    } else {
      const orders = await loadMLBOrders({
        partnerId: mlbPartner.id,
        date: gameDay,
        mailOrder,
      })
      if (orders) {
        this.setState({ orders })
      }
    }
  }

  setShowSidebar = (show) => this.setState({ hideSidebar: !show })

  render() {
    const { gameDay, view, mlbPartner, orders, mailOrders, hideSidebar } =
      this.state
    const { adminRoleName, defaultZip, markMLBOrderDelivered } = this.props

    if (!mlbPartner) {
      return null
    }

    const { id, name, depot } = mlbPartner
    const theme = getTheme(name)
    const isOrderView = view === 'Orders'
    const isMailOrderView = view === 'Pending Mail Orders'
    const isDeliveredMailOrderView = view === 'Delivered Mail Orders'
    const isMailView = isMailOrderView || isDeliveredMailOrderView
    const isEditMenu = view === 'EditMenu'
    const isCalendarView = view === 'Calendar'
    const isInventoryView = view === 'Inventory'

    return (
      <div className="mlb-container">
        {/* SIDEBAR */}
        {!hideSidebar && (
          <div
            className="mlb-sidebar"
            style={{ background: theme.primaryColor }}
          >
            <div className="mlb-logo">
              <img src={theme.logo} />
            </div>
            <a
              className={`${isOrderView && 'active'}`}
              style={{ color: isOrderView && theme.primaryColor }}
              onClick={() =>
                this.setState({ view: 'Orders' }, this.loadMLBOrders)
              }
            >
              Order(s)
            </a>
            <a
              className={`${isMailView && 'active'}`}
              style={{ color: isMailView && theme.primaryColor }}
              onClick={() =>
                this.setState(
                  { view: 'Pending Mail Orders' },
                  this.loadMLBOrders,
                )
              }
            >
              Mail Orders
            </a>
            <AuthorizedDisplay roles={['master admin', adminRoleName]}>
              <a
                className={`${isEditMenu && 'active'}`}
                style={{ color: isEditMenu && theme.primaryColor }}
                onClick={() => this.setState({ view: 'EditMenu' })}
              >
                Edit Menu
              </a>
            </AuthorizedDisplay>
            <a
              className={`${isCalendarView && 'active'}`}
              style={{ color: isCalendarView && theme.primaryColor }}
              onClick={() => this.setState({ view: 'Calendar' })}
            >
              Cut-off Calendar
            </a>
            <a
              className={isInventoryView && 'active'}
              style={{ color: isInventoryView && theme.primaryColor }}
              onClick={() => this.setState({ view: 'Inventory' })}
            >
              Inventory
            </a>
          </div>
        )}
        {/* PANEL */}
        <div className="mlb-content">
          {isOrderView && (
            <MLBOrdersPage
              mlbPartner={mlbPartner}
              orders={orders}
              setGameDay={(gameDay) =>
                this.setState({ gameDay }, this.loadMLBOrders)
              }
              gameDay={gameDay}
              setShowSidebar={this.setShowSidebar}
            />
          )}
          {(isMailOrderView || isDeliveredMailOrderView) && (
            <MLBMailOrdersPage
              adminRoleName={adminRoleName}
              mlbPartnerId={id}
              mlbPartner={mlbPartner}
              orders={mailOrders}
              loadMLBOrders={this.loadMLBOrders}
              markMLBOrderDelivered={markMLBOrderDelivered}
              view={view}
              changeView={(view) => this.setState({ view }, this.loadMLBOrders)}
              setShowSidebar={this.setShowSidebar}
            />
          )}
          {isEditMenu && (
            <MLBEditMenuPage
              theme={theme}
              defaultDate={gameDay}
              mlbPartnerName={id}
              mlbPartner={mlbPartner}
            />
          )}
          {isCalendarView && <MLBCalendarView mlbPartner={mlbPartner} />}
          {isInventoryView && (
            <MLBInventoryPage
              depot={depot}
              mlbPartnerName={id}
              defaultDate={gameDay}
              defaultZip={defaultZip}
              date={gameDay}
              setGameDay={(gameDay) =>
                this.setState({ gameDay }, this.loadMLBOrders)
              }
              gameDay={gameDay}
            />
          )}
        </div>
      </div>
    )
  }
}

MLBMaster.propTypes = {
  adminRoleName: PropTypes.string,
  defaultZip: PropTypes.string,
  mlbPartnerName: PropTypes.string,

  loadMLBMailOrders: PropTypes.func,
  markMLBOrderDelivered: PropTypes.func,
  loadMLBOrders: PropTypes.func,
  loadMLBPartner: PropTypes.func,
}

export default MLBMaster
