export const APPEND_BATCH_ORDERS = 'APPEND_BATCH_ORDERS'
export const CLEAR_EDIT_BATCH_ORDER = 'CLEAR_EDIT_BATCH_ORDER'
export const CLEAR_NEW_BATCH_ORDER = 'CLEAR_NEW_BATCH_ORDER'
export const CLEAR_BATCH_ORDER = 'CLEAR_BATCH_ORDER'
export const CLEAR_BATCH_ORDERS = 'CLEAR_BATCH_ORDERS'
export const REMOVE_BATCH_ORDER_FROM_ORDERS = 'REMOVE_BATCH_ORDER_FROM_ORDERS'
export const SET_EDIT_BATCH_ORDER = 'SET_EDIT_BATCH_ORDER'
export const SET_NEW_BATCH_ORDER = 'SET_NEW_BATCH_ORDER'
export const SET_BATCH_ORDER = 'SET_BATCH_ORDER'
export const SET_BATCH_ORDERS = 'SET_BATCH_ORDERS'
export const SET_CHILD_ORDERS = 'SET_CHILD_ORDERS'
export const UPDATE_EDIT_BATCH_ORDER = 'UPDATE_EDIT_BATCH_ORDER'
export const UPDATE_NEW_BATCH_ORDER = 'UPDATE_NEW_BATCH_ORDER'

export const clearEditBatchOrder = () => ({ type: CLEAR_EDIT_BATCH_ORDER })
export const clearNewBatchOrder = () => ({ type: CLEAR_NEW_BATCH_ORDER })
export const clearBatchOrder = () => ({ type: CLEAR_BATCH_ORDER })
export const clearBatchOrders = () => ({ type: CLEAR_BATCH_ORDERS })

export const appendBatchOrders = (batchOrders) => ({
  type: APPEND_BATCH_ORDERS,
  batchOrders,
})
export const removeBatchOrderFromOrders = (orderId) => ({
  type: REMOVE_BATCH_ORDER_FROM_ORDERS,
  orderId,
})
export const setEditBatchOrder = (batchOrder) => ({
  type: SET_EDIT_BATCH_ORDER,
  batchOrder,
})
export const setNewBatchOrder = (batchOrder) => ({
  type: SET_NEW_BATCH_ORDER,
  batchOrder,
})
export const setBatchOrder = (batchOrder) => ({
  type: SET_BATCH_ORDER,
  batchOrder,
})
export const setBatchOrders = (batchOrders) => ({
  type: SET_BATCH_ORDERS,
  batchOrders,
})
export const setChildOrders = (childOrders) => ({
  type: SET_CHILD_ORDERS,
  childOrders,
})

export const updateEditBatchOrder = (batchOrder) => ({
  type: UPDATE_EDIT_BATCH_ORDER,
  batchOrder,
})
export const updateNewBatchOrder = (batchOrder) => ({
  type: UPDATE_NEW_BATCH_ORDER,
  batchOrder,
})
