import React, { useState } from 'react'

import { MasterDetail } from '@components/common'

import { ChefDetail, ChefMaster, ChefDashboard } from '@containers/chef'

type Props = {
  chefName: string
  newChef: () => void
}

const ChefPage = (props: Props) => {
  const [filter, setFilter] = useState('active')

  const { chefName, newChef } = props

  return (
    <MasterDetail
      detail={<ChefDetail />}
      setFilter={setFilter}
      dashboard={!chefName && <ChefDashboard filter={filter} />}
      showDashboard={!chefName}
      master={(props: any) => <ChefMaster {...props} />}
      filters={[
        '',
        'active',
        'inactive',
        'vcx',
        'group orders',
        'pop-ups',
        'catering',
      ]}
      initialFilter="active"
      onNew={newChef}
      onNewAuthRoles={['master admin', 'chef lead']}
      onNewLabel="New Chef"
      title={chefName ? `Chef ${chefName}` : 'Chefs'}
    />
  )
}

export default ChefPage
