import React from 'react'
import PropTypes from 'prop-types'
import loadGif from '@res/images/spinner.svg'

const Loader = (props) => {
  const { width = '100px', isCenter } = props

  return (
    <img style={{ width, margin: isCenter ? 'auto' : '0' }} src={loadGif} />
  )
}

Loader.propTypes = {
  width: PropTypes.string,
  isCenter: PropTypes.bool,
}

export default Loader
