import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CustomOrderItem from '@containers/order/edit/sections/CustomOrderItem'
import OrderItem from '@containers/order/edit/sections/OrderItem'
import { MAIN, PROTEIN } from '../../../../../constants'

class OrderItemsSection extends Component {
  state = {
    width: 0,
    syncOrderItemsWithFirstMap: {}, // { [menuItemId]: boolean }
  }

  handleResize = () =>
    this.setState({
      width: window.innerWidth,
    })

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  constructor(props) {
    super(props)
    this.toggleOrderItemIdToSync = this.toggleOrderItemIdToSync.bind(this)
    this.editOrderItem = this.editOrderItem.bind(this)
  }

  isSameChildItems(childItems1, childItems2) {
    if (
      typeof childItems1 != 'object' ||
      typeof childItems2 != 'object' ||
      childItems1.length != childItems2.length
    ) {
      return false
    }

    const nameMap1 = {}
    childItems1.forEach((childItem) => {
      const category = childItem.category || ''
      if (!category.includes(MAIN) && !category.includes(PROTEIN)) {
        nameMap1[childItem.name] = true
      }
    })

    for (let index = 0; index < childItems2.length; index++) {
      const childItem = childItems2[index]
      const category = childItem.category || ''
      if (
        !category.includes(MAIN) &&
        !category.includes(PROTEIN) &&
        !nameMap1[childItem.name]
      ) {
        return false
      }
    }

    return true
  }

  toggleOrderItemIdToSync(menuItemId, value) {
    const { syncOrderItemsWithFirstMap } = this.state

    this.setState({
      syncOrderItemsWithFirstMap: {
        ...syncOrderItemsWithFirstMap,
        [menuItemId]: value,
      },
    })
  }

  editOrderItem(orderItem, isFirst) {
    const { onEditOrderMenuItem, orderItems, syncChildItems } = this.props
    const { syncOrderItemsWithFirstMap } = this.state

    onEditOrderMenuItem(orderItem)
    if (isFirst) {
      const orderItemsToBeSynced = []
      for (const menuItemId in syncOrderItemsWithFirstMap) {
        if (syncOrderItemsWithFirstMap[menuItemId]) {
          const index = orderItems.findIndex(
            (item) => item.menuItemId === menuItemId,
          )
          if (index > -1) {
            orderItemsToBeSynced.push(orderItems[index])
          }
        }
      }

      orderItemsToBeSynced.forEach((item) => {
        const { orderItem2 } = syncChildItems(orderItem, item)

        onEditOrderMenuItem(orderItem2)
      })
    }
  }

  render() {
    const { width, syncOrderItemsWithFirstMap } = this.state

    const {
      onEditCustomOrderMenuItem,
      onRemoveCustomOrderMenuItem,
      onRemoveOrderMenuItem,
      onReorderCustomMenuItem,
      onReorderMenuItem,
      orderType,
    } = this.props
    const customOrderItems = this.props.customOrderItems || []
    const orderItems = this.props.orderItems || []
    if (orderItems.length + customOrderItems.length === 0) {
      return null
    }
    const firstOrderItem = orderItems[0] || {}
    const firstEntreeChildItems = firstOrderItem.isBYOConcept
      ? firstOrderItem.childItems
      : []

    return (
      <div
        className={`${
          width > 1400 ? 'w-full' : 'w-full max-w-xl overflow-scroll'
        }`}
      >
        <table className="w-full border-separate order-table">
          <tr>
            <th />
            <th className="w-55 bold uppercase">Menu Item Name</th>
            <th />
            <th className="bold uppercase text-right">Qty</th>
            <th className="bold uppercase text-right">Chef Price</th>
            <th className="bold uppercase text-right">Client Price</th>
            <th className="bold uppercase text-right">Margin</th>
            <th className="bold uppercase text-right">Total</th>
            <th className="bold uppercase text-right">Inv?</th>
          </tr>
          {orderItems.map((i, index) => {
            const isFirst = index == 0
            const isLast = index == orderItems.length - 1
            const isSame =
              !isFirst &&
              this.isSameChildItems(firstEntreeChildItems, i.childItems)
            const isSync = isSame && syncOrderItemsWithFirstMap[i.id]

            return (
              <OrderItem
                key={i.id || index}
                orderItem={i}
                onEdit={this.editOrderItem}
                onRemove={onRemoveOrderMenuItem}
                onReorder={onReorderMenuItem}
                toggleOrderItemIdToSync={this.toggleOrderItemIdToSync}
                orderType={orderType}
                isFirst={isFirst}
                isLast={isLast}
                isSame={isSame}
                isSync={isSync}
              />
            )
          })}

          {customOrderItems.map((i, index) => (
            <CustomOrderItem
              key={i.displayOrder}
              orderItem={i}
              onEdit={onEditCustomOrderMenuItem}
              onRemove={onRemoveCustomOrderMenuItem}
              onReorder={onReorderCustomMenuItem}
              isFirst={index == 0}
              isLast={index == customOrderItems.length - 1}
            />
          ))}
        </table>
      </div>
    )
  }
}

OrderItemsSection.propTypes = {
  onAddOrRemoveConcept: PropTypes.func,
  chefName: PropTypes.string,
  customOrderItems: PropTypes.arrayOf(PropTypes.object),
  orderItems: PropTypes.arrayOf(PropTypes.object),
  orderType: PropTypes.string,
  orderConceptId: PropTypes.string,

  onChange: PropTypes.func,
  onEditCustomOrderMenuItem: PropTypes.func,
  onEditOrderMenuItem: PropTypes.func,
  onRemoveCustomOrderMenuItem: PropTypes.func,
  onRemoveOrderMenuItem: PropTypes.func,
  onReorderCustomMenuItem: PropTypes.func,
  onReorderMenuItem: PropTypes.func,
  syncChildItems: PropTypes.func,
}

export default OrderItemsSection
