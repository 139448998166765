import React, { useEffect, useState } from 'react'
import { config } from 'hungry-core2'
import Panel from '@components/common/Panel'
import Checkbox from '@components/common/form/Checkbox'
import LabelInfo from '@components/common/form/LabelInfo'
import Input from '@components/common/form/Input'
import { Button } from '@components/common/form'
import YSpacing from '@components/common/YSpacing'
import {
  ClientSettings,
  SlackConfig,
  NotificationsConfig,
  SlackAuthData,
  PopUpCustomer,
} from '@types'
import SlackAuthDataModal, { SlackAuthDataOption } from './SlackAuthDataModal'

interface Props {
  clientSettings: ClientSettings
  displayFailureMessage: (message: string) => void
  saveClientSettings: (
    cs: Partial<ClientSettings>,
    clientName: string,
  ) => Promise<ClientSettings>
  onClientSettingsUpdateSuccess: (cs: ClientSettings) => void
  searchSlackAuthData: (params: {
    customerIds: string[]
    teamId: string
  }) => Promise<[SlackAuthData[], string]>
  searchPopUpCustomers: (params: {
    ids: string[]
    limit: number
    reqMethod: string
  }) => Promise<PopUpCustomer[]>
}

type FormState = {
  teamId: string
  channelId: string
  active: boolean
  slackAuthCustomerId: string
}

const getInitialFormState = (cs: ClientSettings): FormState => {
  const slackConfig = cs?.notificationsConfig?.slackConfig
  if (!slackConfig) {
    return {
      teamId: '',
      channelId: '',
      active: false,
      slackAuthCustomerId: '',
    }
  }

  return {
    teamId: slackConfig.teamId,
    channelId: slackConfig.channelId,
    active: slackConfig.active,
    slackAuthCustomerId: slackConfig.slackAuthCustomerId,
  }
}

const getSlackConfigFromForm = (formState: FormState): SlackConfig => {
  return {
    active: formState.active,
    notifyOnGroupOrderDelivered: formState.active,
    teamId: formState.teamId.trim(),
    channelId: formState.channelId.trim(),
    slackAuthCustomerId: formState.slackAuthCustomerId,
  }
}

const customersToMap = (
  customers: PopUpCustomer[],
): Record<string, PopUpCustomer> => {
  const result: Record<string, PopUpCustomer> = {}
  customers.forEach((c) => {
    result[c.id] = c
  })

  return result
}

const getSlackAppUrl = (): string => {
  return config.slack_app_url || ''
}

const NotificationsSettingsView: React.FC<Props> = (props) => {
  const {
    clientSettings,
    saveClientSettings,
    onClientSettingsUpdateSuccess,
    displayFailureMessage,
  } = props

  const [formState, setFormState] = useState<FormState>(
    getInitialFormState(clientSettings),
  )

  const [slackAuthDataOptions, setSlackAuthDataOptions] = useState<
    SlackAuthDataOption[]
  >([])

  const [customersMap, setCustomersMap] = useState<
    Record<string, PopUpCustomer>
  >({})

  const [loading, setLoading] = useState(false)

  const [loadingCustomers, setLoadingCustomers] = useState(false)

  const [slackAuthDataModalOpen, setSlackAuthDataModalOpen] = useState(false)

  useEffect(() => {
    setFormState(getInitialFormState(clientSettings))
    getSlackAuthDataOptions(
      clientSettings,
      clientSettings.notificationsConfig?.slackConfig?.teamId || '',
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSettings])

  const getSlackAuthDataOptions = async (
    cs: ClientSettings,
    teamId: string,
  ): Promise<SlackAuthDataOption[]> => {
    const options: SlackAuthDataOption[] = []

    if (!teamId) {
      return options
    }

    setLoadingCustomers(true)

    const customers = await props.searchPopUpCustomers({
      ids: cs.employeeIds,
      limit: 200,
      reqMethod: 'post',
    })

    // Keep a record of all customers fetched so we can display selected
    // customer even when slack auth data options have changed and may not include
    // the selected customer
    setCustomersMap({ ...customersMap, ...customersToMap(customers) })

    if (customers.length) {
      const customerIds = customers.map((c) => c.id)
      const [authDataResults, _err] = await props.searchSlackAuthData({
        customerIds,
        teamId,
      })
      if (authDataResults.length) {
        authDataResults.forEach((authData) => {
          const customer = customers.find((c) => c.id === authData.customerId)
          if (customer) {
            options.push({
              authData,
              customer,
            })
          }
        })
      }
    }

    setLoadingCustomers(false)

    setSlackAuthDataOptions(options)

    return options
  }

  const handleChangeTeamId = (teamId: string) => {
    setFormState({ ...formState, teamId })
  }

  const handleChangeChannelId = (channelId: string) => {
    setFormState({ ...formState, channelId })
  }

  const handleChangeActive = (active: boolean) => {
    setFormState({ ...formState, active })
  }

  const handleChangeSlackAuthCustomerId = (slackAuthCustomerId: string) => {
    setFormState({ ...formState, slackAuthCustomerId })
  }

  const handleSaveSlackAuthCustomerId = (customerId: string) => {
    handleChangeSlackAuthCustomerId(customerId)
    setSlackAuthDataModalOpen(false)
  }

  const validateForm = (formState: FormState): string[] => {
    const errors: string[] = []
    const teamId = formState.teamId.trim()
    const channelId = formState.channelId.trim()
    if (formState.active) {
      if (!teamId) {
        errors.push('Team ID is required')
      }
      if (!channelId) {
        errors.push('Channel ID is required')
      }
      if (!formState.slackAuthCustomerId) {
        errors.push('Slack Auth Customer is required')
      }
    }

    return errors
  }

  const handleSave = async () => {
    const errors = validateForm(formState)

    if (errors.length) {
      const message = errors.join('\n')
      displayFailureMessage(message)

      return
    }

    setLoading(true)

    const slackConfig = getSlackConfigFromForm(formState)
    const notificationsConfig: NotificationsConfig = { slackConfig }
    // Partial update
    const nextSettings = {
      id: clientSettings.id,
      notificationsConfig,
    }
    const result = await saveClientSettings(
      nextSettings,
      clientSettings.client.name,
    )

    if (result) {
      onClientSettingsUpdateSuccess(result)
    }
    setLoading(false)
  }

  const handleClickSelectUser = () => {
    if (!formState.teamId) {
      alert('Please enter a Team ID first.')

      return
    }

    getSlackAuthDataOptions(clientSettings, formState.teamId)

    setSlackAuthDataModalOpen(true)
  }

  const selectedSlackAuthDataCustomer =
    customersMap[formState.slackAuthCustomerId]

  return (
    <Panel width="100%" maxWidth="800px" heading="Notification Settings">
      <LabelInfo label="Slack" />
      <YSpacing height="20px" />
      <Checkbox
        label="Active"
        value={'checkbox'}
        checked={formState.active}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeActive(e.target.checked)
        }
      />
      <YSpacing height="20px" />
      <Input
        label="Team ID"
        placeholder={'T6N23A45A'}
        value={formState.teamId}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeTeamId(e.target.value)
        }
      />
      <Input
        label="Channel ID"
        placeholder={'C07GAAK87HX'}
        value={formState.channelId}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeChannelId(e.target.value)
        }
      />
      <YSpacing height="10px" />
      <div className="slack-auth-data-customer-section">
        <div className="slack-auth-data-customer-label">
          Employee for Slack Authentication
        </div>
        {selectedSlackAuthDataCustomer && (
          <div className="slack-auth-data-customer-data">
            {selectedSlackAuthDataCustomer.email}
          </div>
        )}
        <div>
          <button
            onClick={handleClickSelectUser}
            className="slack-auth-data-customer-button"
          >
            {selectedSlackAuthDataCustomer
              ? 'Change Employee'
              : 'Select Employee'}
          </button>
        </div>
      </div>
      <YSpacing height="10px" />
      <div className="slack-auth-data-link-section">
        <div className="link-label">
          Installation link (to be shared with client POC)
        </div>
        <a
          className="slack-auth-data-link"
          href={getSlackAppUrl()}
          target="_blank"
          rel="noreferrer"
        >
          {getSlackAppUrl()}
        </a>
      </div>
      <YSpacing height="40px" />
      <div className="slack-auth-data-buttons-container">
        <Button
          label="Save Changes"
          onClick={() => handleSave()}
          disabled={loading}
        />
      </div>
      {slackAuthDataModalOpen && formState.teamId && (
        <SlackAuthDataModal
          options={slackAuthDataOptions}
          teamId={formState.teamId}
          isLoadingCustomers={loadingCustomers}
          onSave={handleSaveSlackAuthCustomerId}
          onClose={() => setSlackAuthDataModalOpen(false)}
        />
      )}
    </Panel>
  )
}

export default NotificationsSettingsView
