import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import Datetime from 'react-datetime'
import { Button } from 'react-bootstrap'

class EditDateCell extends React.Component {
  render() {
    const {
      order,
      attribute,
      className,
      key,
      orderUpdates,
      updateOrder,
      suggestServiceTime,
    } = this.props
    const orderUpdate = orderUpdates[order.id]
    const originalOrder = order[attribute]
    const revisionOrder = orderUpdate && orderUpdate[attribute]
    let didSuggestTime = false
    let newValue
    let inputProps = { className: 'form-control dashboard-date-time-picker' }

    if (revisionOrder) {
      inputProps = {
        className: 'form-control dashboard-date-time-picker updated-input',
      }
      newValue = Moment(revisionOrder, 'MM-DD-YYYY h:mm a').toDate()
    } else if (originalOrder) {
      newValue = Moment(originalOrder, 'MM-DD-YYYY h:mm a').toDate()
    } else {
      inputProps = {
        className: 'form-control dashboard-date-time-picker suggested-time',
      }
      const suggestedTime = suggestServiceTime(attribute, order, orderUpdate)
      if (suggestedTime) {
        didSuggestTime = true
        newValue = suggestedTime.toDate()
      } else {
        newValue = ''
      }
    }

    const dateParser = (value) => {
      return Moment(value).format('MM-DD-YYYY h:mm a')
    }

    return (
      <td key={key} className={`${className}`}>
        <Datetime
          value={newValue}
          onChange={(editValue) =>
            updateOrder(order.id, attribute, editValue, dateParser)
          }
          inputProps={inputProps}
          timeFormat="h:mm a"
          dateFormat={false}
          timeConstraints={{ minutes: { step: 5 } }}
        />
        {didSuggestTime && (
          <Button
            bsStyle="primary"
            onClick={() =>
              updateOrder(order.id, attribute, newValue, dateParser)
            }
          >
            Accept Suggested Time
          </Button>
        )}
      </td>
    )
  }
}

EditDateCell.propTypes = {
  order: PropTypes.object,
  attribute: PropTypes.string,
  className: PropTypes.string,
  columnsLookup: PropTypes.object,
  key: PropTypes.string,
  orderUpdates: PropTypes.arrayOf(PropTypes.object),

  suggestServiceTime: PropTypes.func,
  updateOrder: PropTypes.func,
}

export default EditDateCell
