import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BiPencil } from 'react-icons/bi'
import { ButtonsBar } from '@res/styledComponents/index'
import { AuthorizedInteractable } from '@containers/common/auth'
import { AccountListGroup, FlexContainer } from '@components/common'

export class UserDetail extends Component {
  componentWillReceiveProps(nextProps) {
    const { user } = nextProps
    if (user && user !== this.props.user && user.id) {
      this.props.loadHubspotUser(user.id)
    }
  }

  onEditUser = (e) => {
    e.preventDefault()
    e.stopPropagation()

    this.props.editUser()
  }

  render() {
    const { currentUser, headquarters, hubspotUser, user } = this.props
    if (!user) {
      return null
    }
    const { lastLoggedInHq } = currentUser
    const currentHeadquarter = headquarters.find((h) => h.id === lastLoggedInHq)

    return (
      <FlexContainer
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <AccountListGroup
          user={user}
          hubspotUser={hubspotUser}
          currentHeadquarter={currentHeadquarter}
        />
        <ButtonsBar>
          <AuthorizedInteractable
            roles={[
              'master admin',
              'sales rep',
              'sales lead',
              'captain lead',
              'chef lead',
            ]}
          >
            <button onClick={this.onEditUser}>
              <BiPencil />
              Edit
            </button>
          </AuthorizedInteractable>
        </ButtonsBar>
      </FlexContainer>
    )
  }
}

UserDetail.propTypes = {
  currentHeadquarter: PropTypes.object,
  currentUser: PropTypes.object,
  headquarters: PropTypes.arrayOf(PropTypes.object),
  hubspotUser: PropTypes.object,
  user: PropTypes.object,

  editUser: PropTypes.func,
  loadHubspotUser: PropTypes.func,
}

export default UserDetail
