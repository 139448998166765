import Moment from 'moment-timezone'

export const GetAccountingDashboard =
  ({
    DashboardService,
    RestService,
    UIService,
    pRequestCateringOrdersDashboardParams,
    pResponseCateringOrderDashboardItems,
    pRequestMLBOrdersDashboardParams,
    pResponseMLBOrderDashboardItems,
    pRequestPopUpsDashboardParams,
    pResponsePopUpsDashboardItems,
    pRequestGroupOrdersDashboardParams,
    pResponseGroupOrderDashboardItems,
    HandleError,
  }) =>
  async ({ fromDate, toDate, hq }) => {
    DashboardService.clearOrders()
    UIService.Dashboard.toggleLoading(true)

    if (toDate) {
      toDate = Moment(toDate).endOf('day')
    }

    const getOrders = GetCateringOrdersAccounting({
      DashboardService,
      RestService,
      pRequestCateringOrdersDashboardParams,
      pResponseCateringOrderDashboardItems,
      HandleError,
    })
    const getMLBOrders = GetMLBOrdersAccounting({
      RestService,
      pRequestMLBOrdersDashboardParams,
      pResponseMLBOrderDashboardItems,
      HandleError,
    })
    const getPopUps = GetPopUpsAccounting({
      RestService,
      pRequestPopUpsDashboardParams,
      pResponsePopUpsDashboardItems,
      HandleError,
    })
    const getGroupOrders = GetGroupOrdersAccounting({
      RestService,
      pRequestGroupOrdersDashboardParams,
      pResponseGroupOrderDashboardItems,
      HandleError,
    })

    let orders = []
    let mlbOrders = []
    let popUps = []
    let groupOrders = []
    await Promise.all([
      getOrders({ fromDate, toDate }),
      getMLBOrders({ fromDate, toDate, hq }),
      getPopUps({ fromDate, toDate, hq }),
      getGroupOrders({ fromDate, toDate }),
    ]).then(([ordersResp, mlbOrdersResp, popUpsResp, groupOrdersResp]) => {
      orders = ordersResp
      mlbOrders = mlbOrdersResp
      popUps = popUpsResp
      groupOrders = groupOrdersResp
    })
    let orderables = [...orders, ...mlbOrders, ...popUps, ...groupOrders]
    orderables = orderables.sort((a, b) => a.unixTimestamp - b.unixTimestamp)

    DashboardService.setOrders(orderables)
    UIService.Dashboard.toggleLoading(false)
  }

export const GetMLBOrdersAccounting =
  ({
    RestService,
    pRequestMLBOrdersDashboardParams,
    pResponseMLBOrderDashboardItems,
    HandleError,
  }) =>
  async ({ fromDate, toDate, hq }) => {
    const orders = []
    const params = pRequestMLBOrdersDashboardParams({
      fromDate,
      toDate,
      headquarter: hq,
    })
    const limit = 100
    let offset = 0
    try {
      while (true) {
        const response = await RestService.get('accounting/mlb/orders', {
          ...params,
          limit,
          offset,
        })
        if (response.orders.length === 0) {
          break
        }
        orders.push(...pResponseMLBOrderDashboardItems(response.orders))
        offset += limit
      }
    } catch (error) {
      HandleError({ error })
    }

    return orders
  }

export const GetSubsAccounting =
  ({
    RestService,
    pRequestSubsDashboardParams,
    pResponseSubsDashboardItems,
    HandleError,
  }) =>
  async ({ fromDate, toDate, hq }) => {
    const subs = []
    const params = pRequestSubsDashboardParams({
      fromDate,
      toDate,
      headquarter: hq,
    })
    const limit = 100
    let offset = 0
    try {
      while (true) {
        const response = await RestService.get('/accounting/subscriptions', {
          ...params,
          limit,
          offset,
        })
        if (response.subscriptions.length === 0) {
          break
        }
        subs.push(...pResponseSubsDashboardItems(response.subscriptions))
        offset += limit
      }
    } catch (error) {
      HandleError({ error })
    }

    return subs
  }

export const GetPopUpsAccounting =
  ({
    RestService,
    pRequestPopUpsDashboardParams,
    pResponsePopUpsDashboardItems,
    HandleError,
  }) =>
  async ({ fromDate, toDate, hq }) => {
    let popups = []
    const params = pRequestPopUpsDashboardParams({ fromDate, toDate, hq })
    try {
      const response = await RestService.get('/pop-ups/accounting', params)
      popups = pResponsePopUpsDashboardItems(response)
    } catch (error) {
      HandleError({ error })
    }

    return popups
  }

export const GetVXOrdersAccounting =
  ({
    RestService,
    pRequestVXOrdersDashboardParams,
    pResponseVXOrderDashboardItems,
    HandleError,
  }) =>
  async ({ fromDate, toDate }) => {
    const orders = []
    const params = pRequestVXOrdersDashboardParams({ fromDate, toDate })
    const limit = 100
    let offset = 0
    try {
      while (true) {
        const response = await RestService.get('/virtual-xp/aggregate-orders', {
          ...params,
          limit,
          offset,
        })
        if (response.length === 0) {
          break
        }
        orders.push(...pResponseVXOrderDashboardItems(response))
        offset += limit
      }
    } catch (error) {
      HandleError({ error })
    }

    return orders
  }

export const GetGroupOrdersAccounting =
  ({
    RestService,
    pRequestGroupOrdersDashboardParams,
    pResponseGroupOrderDashboardItems,
    HandleError,
  }) =>
  async ({ fromDate, toDate }) => {
    let orders = []
    const params = pRequestGroupOrdersDashboardParams({ fromDate, toDate })
    try {
      const response = await RestService.get('/group-orders/accounting', params)
      orders = pResponseGroupOrderDashboardItems(response)
    } catch (error) {
      console.log('err', error)
      HandleError({ error })
    }

    return orders
  }

export const GetCateringOrdersAccounting =
  ({
    RestService,
    pRequestCateringOrdersDashboardParams,
    pResponseCateringOrderDashboardItems,
    HandleError,
  }) =>
  async ({ fromDate, toDate }) => {
    let orders = []
    const params = pRequestCateringOrdersDashboardParams({ fromDate, toDate })
    try {
      const response = await RestService.get(
        '/catering-orders/accounting',
        params,
      )
      orders = pResponseCateringOrderDashboardItems(response)
    } catch (error) {
      console.log('err', error)
      HandleError({ error })
    }

    return orders
  }

export const UpdatePaymentReceived =
  ({ RestService, HandleError, pRequestPayOrderWithExternal }) =>
  async (orderId, paymentType) => {
    try {
      await RestService.post(
        '/api/admin/payments',
        pRequestPayOrderWithExternal(orderId, paymentType),
      )
    } catch (error) {
      HandleError({ error })
    }
  }

export const DashboardEditOrder =
  ({
    DashboardService,
    RestService,
    UIService,
    pOrderDashboardUpdate,
    HandleError,
  }) =>
  async ({ deleteOrderUpdate, orderUpdate, orderDate }) => {
    const { id } = orderUpdate

    DashboardService.clearOrders()
    UIService.Dashboard.toggleLoading(true)
    try {
      const request = pOrderDashboardUpdate({ orderUpdate, orderDate })
      await RestService.put(`/api/admin/orders/${id}`, request)
      deleteOrderUpdate(id)
      UIService.FlashMessage.displaySuccessMessage('Order update saved')
    } catch (error) {
      UIService.Dashboard.toggleLoading(false)
      HandleError({ error })

      return
    }

    // Send chef alerts if checkbox was selected
    if (orderUpdate.sendChefAlert) {
      try {
        await RestService.post(`/api/admin/orders/${id}/email_chefs`)
        UIService.FlashMessage.displaySuccessMessage('Order update saved')
      } catch (error) {
        UIService.Dashboard.toggleLoading(false)
        HandleError({ error })

        return
      }
    }

    // Send services alerts if checkbox was selected
    if (orderUpdate.sendServicesAlert) {
      try {
        await RestService.post(`/api/admin/orders/${id}/email_services`)
        UIService.FlashMessage.displaySuccessMessage('Service alert sent')
      } catch (error) {
        HandleError({ error })
      }
    }

    UIService.Dashboard.toggleLoading(false)
  }

export const DashboardEditOrders =
  ({ DashboardService, RestService, UIService, pOrderDashboardUpdates }) =>
  async ({ deleteOrderUpdate, orderUpdates, orderDates }) => {
    DashboardService.clearOrders()
    UIService.Dashboard.toggleLoading(true)
    try {
      const request = pOrderDashboardUpdates({ orderUpdates, orderDates })
      await RestService.post('/api/admin/order_dashboard', request)
      Object.values(orderUpdates)
        .map((u) => u.id)
        .forEach((id) => deleteOrderUpdate(id))
      UIService.FlashMessage.displaySuccessMessage('Order updates made')
    } catch (error) {
      UIService.FlashMessage.displayFailureMessage(
        'Error making order updates: ',
        error,
      )
    }
    UIService.Dashboard.toggleLoading(false)
  }

export const DashboardLoadCateringCaptains =
  ({ RestService, pResponseCateringCaptains, HandleError }) =>
  async () => {
    try {
      const cateringCaptains = await RestService.get('/api/admin/users', {
        role: 'catering_captain',
      })

      return { cateringCaptains: pResponseCateringCaptains(cateringCaptains) }
    } catch (error) {
      HandleError({ error })
    }
  }

// Inifinte Loader

export const TimeoutInfiniteLoadData =
  ({ DashboardService, UIService, GetDashboardOrderItems }) =>
  ({ fromDate, toDate, initOrdersDashboardParams }) => {
    const infiniteLoadStatus = {
      ordersDashboardDataBuffer: [],
      dataRequestTimedOut: false,
      dataRequestCompleted: false,
    }
    infiniteLoadData({
      DashboardService,
      UIService,
      GetDashboardOrderItems,
      fromDate,
      toDate,
      infiniteLoadStatus,
      initOrdersDashboardParams,
    })
    setTimeout(() => {
      if (!infiniteLoadStatus.dataRequestCompleted) {
        _toggleInfiniteLoadTimeOut(infiniteLoadStatus)
        DashboardService.clearOrders()
        UIService.Dashboard.toggleLoading(false)
      }
    }, 120000) // Dashboard timeout set here (in milliseconds)
  }

const infiniteLoadData = async ({
  DashboardService,
  UIService,
  GetDashboardOrderItems,
  fromDate,
  toDate,
  infiniteLoadStatus,
  initOrdersDashboardParams,
  ordersDashboardParams,
}) => {
  // If first request set the loading graphic, initial params, clear search data
  if (!ordersDashboardParams) {
    DashboardService.clearOrders()
    UIService.Dashboard.toggleLoading(true)
    ordersDashboardParams = { ...initOrdersDashboardParams }
    _resetInfinteLoadStatus(infiniteLoadStatus)
  }

  // If request is timedout, return and dont write to dashboard
  if (infiniteLoadStatus.dataRequestCompleted) {
    _resetInfinteLoadStatus(infiniteLoadStatus, true)
    _toggleInfiniteLoadTimeOut(infiniteLoadStatus)
    UIService.FlashMessage.displayFailureMessage('Dashboard request timed out')

    return
  }

  // Search for orders
  const orders = await GetDashboardOrderItems({
    fromDate,
    toDate,
    ordersDashboardParams,
  })
  if (orders) {
    // Add newly loaded orders to buffer
    const { ordersDashboardDataBuffer } = infiniteLoadStatus
    const updatedBuffer = [...ordersDashboardDataBuffer, ...orders]
    infiniteLoadStatus.ordersDashboardDataBuffer = updatedBuffer

    // Load more data if there are still results
    if (orders.length === ordersDashboardParams['results_per_page']) {
      const newOrdersDashboardParams = { ...ordersDashboardParams }
      newOrdersDashboardParams.page += 1
      infiniteLoadData({
        DashboardService,
        UIService,
        GetDashboardOrderItems,
        fromDate,
        toDate,
        infiniteLoadStatus,
        initOrdersDashboardParams,
        ordersDashboardParams: newOrdersDashboardParams,
      })
    } else {
      // All data loaded now load into redux
      UIService.Dashboard.toggleLoading(false)
      DashboardService.setOrders(infiniteLoadStatus.ordersDashboardDataBuffer)
      _resetInfinteLoadStatus(infiniteLoadStatus, true)
    }
  } else {
    DashboardService.setOrders(infiniteLoadStatus.ordersDashboardDataBuffer)
    _resetInfinteLoadStatus(infiniteLoadStatus, true)
  }
}

const _resetInfinteLoadStatus = (
  infiniteLoadStatus,
  dataRequestCompleted = false,
) => {
  infiniteLoadStatus.ordersDashboardDataBuffer = []
  infiniteLoadStatus.dataRequestTimedOut = false
  infiniteLoadStatus.dataRequestCompleted = dataRequestCompleted
}

const _toggleInfiniteLoadTimeOut = (infiniteLoadStatus) => {
  infiniteLoadStatus.dataRequestTimedOut =
    !infiniteLoadStatus.dataRequestTimedOut
  infiniteLoadStatus.dataRequestCompleted = true
}
