import { connect } from 'react-redux'

import CustomOrderItem from '@components/order/edit/sections/CustomOrderItem'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapDispatchToProps = () => {
  const { calculateChefPrice, calculatePrice } = services.OrderService

  const { RestService, SettingsService } = services

  const { pRequestSettings, pResponseSettings } = presenters.Api

  const { LoadSettings, RetryOnTimeout } = coordinators

  const loadMenuItemSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('menuItems')

  return {
    calculateChefPrice,
    calculatePrice,
    loadMenuItemSettings,
  }
}

export default connect(undefined, mapDispatchToProps)(CustomOrderItem)
