import { connect } from 'react-redux'

import { EditChildChefsModal } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state: any) => {
  const { chef } = state

  return {
    chef,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, MarketService, UIService } = services
  const { LoadChefs, CloseEditChildChefs, SaveChef, LoadMarkets } = coordinators
  const {
    pResponseChefs,
    pResponseChef,
    pRequestUpdateChef,
    pResponseHeadquarters,
  } = presenters.Api

  const closeModal = CloseEditChildChefs({ ChefService, UIService })
  const loadChefs = LoadChefs({ ChefService, RestService, pResponseChefs })
  const saveChef = SaveChef({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateChef,
    pResponseChef,
    HandleError,
  })
  const loadHeadquarters = LoadMarkets({
    MarketService,
    RestService,
    pResponseHeadquarters,
  })

  return {
    closeModal,
    loadChefs,
    saveChef,
    loadHeadquarters,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditChildChefsModal)
