export const LoadUsers =
  ({ AdminUserService, RestService, pResponseAdminUsers }) =>
  async (filters) => {
    let users = await RestService.get('/api/admin/users', {
      page: 1,
      results_per_page: 5000,
      ...filters,
    })
    users = pResponseAdminUsers(users)
    AdminUserService.setUsers(users)
  }

export const LoadChefRecruiters =
  ({ RestService, pResponseAccountExecutives }) =>
  async (settings) => {
    const chefLeads = await RestService.get('/api/admin/users', {
      ...settings,
      role: 'chef_recruiting',
    })

    return pResponseAccountExecutives(chefLeads)
  }

export const SelectUser =
  ({ AdminUserService, RestService, pResponseAdminUser, HandleError }) =>
  async (userId) => {
    try {
      let user = await RestService.get(`/v2/users/admin/${userId}`)
      user = pResponseAdminUser(user)
      AdminUserService.setUser(user)
    } catch (error) {
      HandleError({ error })
    }
  }

export const GetAdminUser =
  ({ RestService, pResponseAdminUser, HandleError }) =>
  async (userId) => {
    try {
      let user = await RestService.get(`/v2/users/admin/${userId}`)
      user = pResponseAdminUser(user)

      return user
    } catch (error) {
      HandleError({ error })

      return
    }
  }

export const SearchUsers =
  ({ RestService, pRequestUsersFilter, pResponseUsers, HandleError }) =>
  async (filters) => {
    try {
      let users = await RestService.get('/api/admin/users', {
        page: 1,
        results_per_page: 100,
        serializer: 'user_search',
        ...pRequestUsersFilter(filters),
      })
      users = pResponseUsers(users)

      return users
    } catch (error) {
      HandleError({ error })

      return
    }
  }
