import React, { useState } from 'react'
import Modal from '@components/common/modal/Modal'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import {
  Button,
  Error,
  Input,
  FileUpload,
  LinkText,
  TextArea,
} from '@components/common/form'
import { colors } from '../../../../constants'
import { AuthorizedInteractable } from '@containers/common/auth'
import { Recipe } from '@types'

interface Props {
  recipe: Recipe
  show: boolean
  close: () => void
  deleteRecipe: ({
    id,
    chefId,
    name,
  }: {
    id: string
    chefId: string
    name: string
  }) => void
  saveRecipe: (recipe: Recipe) => void
}

const EditRecipeModal = (props: Props) => {
  const { recipe: currentRecipe, show, close, deleteRecipe, saveRecipe } = props
  const [recipe, setRecipe] = useState(currentRecipe)
  const [errors, setErrors] = useState<string[]>([])
  const [imgPreview, setImgPreview] = useState<null | string>(null)

  const onChange =
    <K extends keyof Recipe, V extends Recipe[K]>(key: K) =>
    (e: { target: { value: V } }) => {
      const { value } = e.target
      const updatedRecipe: Recipe = { ...recipe }
      updatedRecipe[key] = value
      setRecipe(updatedRecipe)
    }

  const onAddImage = (image: Blob) => {
    const updatedRecipe = { ...recipe }
    updatedRecipe['imageUrl'] = image
    setRecipe(updatedRecipe)
    setImgPreview(URL.createObjectURL(image))
  }

  const onDelete = () => {
    deleteRecipe({
      id: recipe.id,
      chefId: recipe.chefId,
      name: recipe.name,
    })
    close()
  }

  const onSave = () => {
    const newErrors = []
    Object.entries(recipe).map((item) => {
      const key = item[0]
      const value = item[1]
      if (value.length === 0 && key !== 'id') {
        newErrors.push(key)
      }
    })
    if (recipe.prepHours === 0 && recipe.prepMinutes === 0) {
      newErrors.push('Prep. time')
    }
    if (recipe.cookHours === 0 && recipe.cookMinutes === 0) {
      newErrors.push('Cook time')
    }
    if (newErrors.length > 0) {
      setErrors(newErrors)

      return
    } else {
      setErrors([])
    }
    saveRecipe(recipe)
  }

  return (
    <Modal
      show={show}
      title={recipe.id ? 'Edit Recipe' : 'New Recipe'}
      hideModal={close}
      color="#001940"
      width="800px"
    >
      <FlexContainer justifyContent="space-between" flexWrap="wrap">
        <Input
          width="70%"
          label="Recipe Name"
          value={recipe.name}
          onChange={onChange('name')}
        />
        <Input
          width="27%"
          label="Servings"
          value={recipe.servings}
          onChange={onChange('servings')}
        />
        <TextArea
          width="100%"
          height="100px"
          label="Description"
          value={recipe.description}
          onChange={onChange('description')}
        />
        <TextArea
          width="100%"
          height="100px"
          label="Instructions"
          value={recipe.instructions}
          onChange={onChange('instructions')}
        />
        <TextArea
          width="100%"
          height="100px"
          label="Ingredients (Separate with commas)"
          value={recipe.ingredients}
          onChange={onChange('ingredients')}
        />
        <Input
          width="23%"
          type="number"
          placeholder="0"
          label="Prep Time (Hours)"
          value={recipe.prepHours.toString()}
          onChange={onChange('prepHours')}
        />
        <Input
          width="23%"
          type="number"
          placeholder="0"
          label="Prep Time (Minutes)"
          value={recipe.prepMinutes.toString()}
          onChange={onChange('prepMinutes')}
        />
        <Input
          width="23%"
          type="number"
          placeholder="0"
          label="Cook Time (Hours)"
          value={recipe.cookHours.toString()}
          onChange={onChange('cookHours')}
        />
        <Input
          width="23%"
          type="number"
          placeholder="0"
          label="Cook Time (Minutes)"
          value={recipe.cookMinutes.toString()}
          onChange={onChange('cookMinutes')}
        />
      </FlexContainer>
      {recipe.imageUrl ? (
        <div>
          <FileUpload
            width="31%"
            label="Upload Different Image"
            onChange={(e) => onAddImage(e.target.files[0])}
          />
          <img
            style={{ maxWidth: '350px', width: '100%' }}
            src={imgPreview || (recipe.imageUrl as string)}
            alt="recipe"
          />
        </div>
      ) : (
        <div>
          <FileUpload
            width="31%"
            label="Upload Image"
            onChange={(e) => onAddImage(e.target.files[0])}
          />
          {imgPreview && (
            <img
              style={{ maxWidth: '350px', width: '100%' }}
              src={imgPreview}
              alt="recipe"
            />
          )}
        </div>
      )}
      <FlexContainer alignItems="center" justifyContent="flex-end">
        <LinkText
          margin="0 20px 0 0"
          color={colors.gray300}
          onClick={close}
          label="Cancel"
        />
        {recipe.id && (
          <AuthorizedInteractable
            roles={['master admin', 'sales lead', 'chef lead']}
          >
            <LinkText color={colors.violet} onClick={onDelete} label="Delete" />
          </AuthorizedInteractable>
        )}
        <XSpacing width="20px" />
        <AuthorizedInteractable
          roles={['master admin', 'sales lead', 'chef lead']}
        >
          <Button onClick={onSave} label="Save" />
        </AuthorizedInteractable>
      </FlexContainer>
      {errors.length > 0 && (
        <Error
          error={`The following fields can’t be blank: ${errors.join(', ')}`}
        />
      )}
    </Modal>
  )
}

export default EditRecipeModal
