import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import LoadingIndicator from '@components/common/LoadingIndicator'
import { AuthorizedInteractable } from '@containers/common/auth'
import { ButtonsBar } from '@res/styledComponents/index'
import { BiPencil } from 'react-icons/bi'

export class VirtualItems extends Component {
  constructor(props) {
    super(props)

    this.state = {
      virtualItems: props.virtualItems,
      virtualItem: null,
      editModalVisible: false,
      sortAsc: true,
    }
  }

  componentDidMount() {
    const { loadChefVirtualItems, chefId, virtualItems } = this.props
    if (!virtualItems) {
      loadChefVirtualItems({ chefId })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { virtualItems } = nextProps
    if (virtualItems !== this.state.virtualItems) {
      this.setState({ virtualItems })
    }
  }

  newVirtualItem = () => {
    const { newVirtualItem } = this.props
    newVirtualItem()
  }

  editVirtualItem = (virtualItem) => {
    const { editVirtualItem } = this.props
    editVirtualItem(virtualItem)
  }

  sortVirtualItemsByString = (columnName) => {
    const { virtualItems, sortAsc } = this.state
    const getColumn = (item) =>
      item[columnName] ? item[columnName].toLowerCase() : ''
    const sortedVirtualItems = virtualItems.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ virtualItems: sortedVirtualItems, sortAsc: !sortAsc })
  }

  sortVirtualItemsByNumber = (columnName) => {
    const { virtualItems, sortAsc } = this.state
    const getColumn = (item) =>
      item[columnName] ? parseFloat(item[columnName].replace(/[$,]/g, '')) : 0.0
    const sortedVirtualItems = virtualItems.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ virtualItems: sortedVirtualItems, sortAsc: !sortAsc })
  }

  sortVirtualItemsByBoolean = (columnName) => {
    const { virtualItems, sortAsc } = this.state
    const getColumn = (item) => (item[columnName] === true ? 1 : 0)
    const sortedVirtualItems = virtualItems.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ virtualItems: sortedVirtualItems, sortAsc: !sortAsc })
  }

  render() {
    const { virtualItems } = this.state
    const areVirtualItemsLoaded = !!virtualItems
    const areVirtualItemsEmpty = virtualItems && virtualItems.length === 0

    return (
      <div>
        <ButtonsBar>
          <AuthorizedInteractable
            roles={['master admin', 'sales lead', 'sales rep', 'chef lead']}
          >
            <button
              onClick={(event) => {
                event.preventDefault()
                this.newVirtualItem()
              }}
            >
              <BiPencil />
              New Virtual Item
            </button>
          </AuthorizedInteractable>
        </ButtonsBar>
        <Panel>
          <Table>
            <thead>
              <tr>
                <th onClick={() => this.sortVirtualItemsByString('name')}>
                  Item Name
                </th>
                <th onClick={() => this.sortVirtualItemsByNumber('cost')}>
                  Vendor Price
                </th>
                <th onClick={() => this.sortVirtualItemsByNumber('price')}>
                  Price
                </th>
                <th
                  onClick={() =>
                    this.sortVirtualItemsByString('virtualItemType')
                  }
                >
                  Item Type
                </th>
                <th onClick={() => this.sortVirtualItemsByBoolean('isEnabled')}>
                  Live
                </th>
                <th
                  onClick={() => this.sortVirtualItemsByBoolean('isApproved')}
                >
                  Approved
                </th>
              </tr>
            </thead>
            <tbody>
              {!areVirtualItemsLoaded && (
                <tr>
                  <td colSpan="4">
                    <LoadingIndicator />
                  </td>
                </tr>
              )}

              {areVirtualItemsLoaded && areVirtualItemsEmpty && (
                <tr>
                  <td colSpan="5">
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '1.2em',
                        color: 'rgba(0,0,0,0.2)',
                        padding: '20px',
                        backgroundColor: 'rgba(0,0,0,0.01)',
                      }}
                    >
                      No virtual items yet.
                    </div>
                  </td>
                </tr>
              )}

              {areVirtualItemsLoaded &&
                virtualItems.map((virtualItem, i) => (
                  <tr
                    key={i}
                    className="clickable"
                    onClick={(e) => {
                      e.preventDefault()
                      this.editVirtualItem(virtualItem.id)
                    }}
                  >
                    <td>{virtualItem.name}</td>
                    <td>${parseFloat(virtualItem.cost).toFixed(2)}</td>
                    <td>${parseFloat(virtualItem.price).toFixed(2)}</td>
                    <td>{virtualItem.virtualItemType}</td>
                    <td>
                      {virtualItem.isEnabled ? (
                        <span className="approved-check">✔</span>
                      ) : (
                        <span className="not-approved-x">✖</span>
                      )}
                    </td>
                    <td>
                      {virtualItem.isApproved ? (
                        <span className="approved-check">✔</span>
                      ) : (
                        <span className="not-approved-x">✖</span>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Panel>
      </div>
    )
  }
}

VirtualItems.propTypes = {
  chefId: PropTypes.string,
  virtualItems: PropTypes.arrayOf(PropTypes.object),

  delayedUpdateVirtualItem: PropTypes.func,
  editVirtualItem: PropTypes.func,
  loadChefVirtualItems: PropTypes.func,
  newVirtualItem: PropTypes.func,
}

export default VirtualItems
