import React, { Component } from 'react'
import PropTypes from 'prop-types'

import close from '@res/images/x-icon.png'

class Modal extends Component {
  render() {
    const { color, title } = this.props

    return (
      <div className="modal-outer">
        <div className="mlb-modal">
          <div className="modal-heading" style={{ backgroundColor: color }}>
            <p>{title}</p>
            <img
              className="modal-close"
              src={close}
              onClick={() => this.props.hideModal()}
            />
          </div>
          {this.props.children}
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.object,
  color: PropTypes.string,

  hideModal: PropTypes.func,
}

export default Modal
