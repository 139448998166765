import Moment from 'moment-timezone'

export const LoadChefsForCNMap =
  ({ RestService, HandleError, pResponseGeneric }) =>
  async (hq_id, availableOn) => {
    try {
      const params = {
        hq_id,
        from: Moment().subtract(6, 'months').format(),
      }
      if (availableOn) {
        params.available_on = availableOn.format()
      }
      const chefs = await RestService.get(
        `/api/admin/chefs/chef_network_map`,
        params,
      )

      return pResponseGeneric(chefs)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const LoadClientsForCNMap =
  ({ RestService, HandleError, pResponseGeneric }) =>
  async (hq_id) => {
    try {
      const params = {
        hq_id,
        from: Moment().subtract(6, 'months').format(),
      }
      const clients = await RestService.get(
        `/api/admin/accounts/chef_network_map`,
        params,
      )

      return pResponseGeneric(clients)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }
