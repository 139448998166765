import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

import { AutocompleteInput, DateInput } from '@components/common/form'
import AuthorizedDisplay from '@containers/common/auth/AuthorizedDisplay'
import NewSubscriberModal from '@containers/subscription/edit/NewSubscriberModal'
import EditSubscriberModal from '@containers/subscription/edit/EditSubscriberModal'

import { DietaryRestrictions } from './constants'
import SubscriptionLockedIn from './SubscriptionLockedIn'
import SubscriptionEditable from './SubscriptionEditable'

class SubscriberPage extends Component {
  state = {
    filteredRep: undefined,
    activeSubscriberId: undefined,
    editDietaryRestrictions: undefined,
    editMealSchedule: undefined,
    editMember: undefined,
    editSubscription: undefined,
    searchTerm: '',
    showNewModal: false,
  }

  componentDidMount() {
    const { userRep } = this.props
    this.setState({ filteredRep: userRep }, this.loadSubscribers)
  }

  loadSubscribers = () => {
    const { callAfterTimeout, hqId, loadSubscribers } = this.props
    const { searchTerm, filteredRep } = this.state
    let repId
    if (filteredRep) {
      repId = filteredRep.id
    }
    callAfterTimeout(() => loadSubscribers({ hqId, searchTerm, repId }))
  }

  buildEditSubscription = (subscription) => {
    subscription = { ...subscription }
    subscription.mainItemMap = {}
    subscription.sideItemMap = {}
    subscription.menu.mainMenuItems.forEach((item) => {
      item.chosen = false
      subscription.mainItemMap[item.id] = item
    })
    subscription.menu.sideMenuItems.forEach((item) => {
      item.chosen = false
      subscription.sideItemMap[item.id] = item
    })
    subscription.items.forEach((item) => {
      const mainItem = subscription.mainItemMap[item.id]
      const sideItem = subscription.sideItemMap[item.id]
      if (mainItem) {
        mainItem.chosen = true
        mainItem.quantity = item.quantity
      } else if (sideItem) {
        sideItem.chosen = true
        sideItem.quantity = item.quantity
      }
    })
    delete subscription.date
    delete subscription.items
    delete subscription.mainItems
    delete subscription.sideItems

    return subscription
  }

  onChangeDietaryRestrictions = (restriction) => () => {
    const restrictions = { ...this.state.editDietaryRestrictions }
    restrictions[restriction] = !restrictions[restriction]
    this.setState({ editDietaryRestrictions: restrictions })
  }

  onChangeMealSchedule = (weekday) => () => {
    const schedule = { ...this.state.editMealSchedule }
    schedule[weekday] = !schedule[weekday]
    this.setState({ editMealSchedule: schedule })
  }

  onChangeSubscriptionDate = (date) => {
    const editSubscription = { ...this.state.editSubscription }
    editSubscription.dateStr = date.format('MM/DD/YYYY')
    this.setState({ editSubscription })
  }

  onChangeSubscriptionHeadcount = (e) => {
    const editSubscription = {
      ...this.state.editSubscription,
      headcount: parseFloat(e.target.value),
    }
    this.setState({ editSubscription })
  }

  onChooseMainItem = (itemId) => () => {
    const editSubscription = { ...this.state.editSubscription }
    editSubscription.mainItemMap[itemId].chosen =
      !editSubscription.mainItemMap[itemId].chosen
    if (!editSubscription.mainItemMap[itemId].chosen) {
      editSubscription.mainItemMap[itemId].quantity = undefined
    }
    this.setState({ editSubscription })
  }

  onChangeMainItemQty = (itemId) => (e) => {
    const editSubscription = { ...this.state.editSubscription }
    editSubscription.mainItemMap[itemId].quantity = parseFloat(e.target.value)
    this.setState({ editSubscription })
  }

  onChooseSideItem = (itemId) => () => {
    const editSubscription = { ...this.state.editSubscription }
    editSubscription.sideItemMap[itemId].chosen =
      !editSubscription.sideItemMap[itemId].chosen
    if (!editSubscription.sideItemMap[itemId].chosen) {
      editSubscription.sideItemMap[itemId].quantity = undefined
    }
    this.setState({ editSubscription })
  }

  onChangeSideItemQty = (itemId) => (e) => {
    const editSubscription = { ...this.state.editSubscription }
    editSubscription.sideItemMap[itemId].quantity = parseFloat(e.target.value)
    this.setState({ editSubscription })
  }

  onChooseMenu = async (menu) => {
    menu = await this.props.loadHomeMenu(menu.id)
    // set menu and clear items
    let subscription = { ...this.state.editSubscription }
    subscription.menu = menu
    subscription.items = []
    subscription = this.buildEditSubscription(subscription)
    this.setState({ editSubscription: subscription })
  }

  onDeleteSubscription = (member) => (subscription) => {
    this.props.deleteSubscription(member, subscription.id)
  }

  onEditDietaryRestrictions = (restrictions) => () => {
    if (restrictions === undefined) {
      this.setState({ editDietaryRestrictions: undefined })

      return
    }
    const restrictMap = {}
    restrictions.forEach((r) => (restrictMap[r] = true))
    this.setState({
      editDietaryRestrictions: restrictMap,
    })
  }

  onEditMealSchedule = (weekdays) => () => {
    if (weekdays === undefined) {
      this.setState({ editMealSchedule: undefined })

      return
    }
    const weekdayMap = {}
    weekdays.forEach((w) => (weekdayMap[w] = true))
    this.setState({
      editMealSchedule: weekdayMap,
    })
  }

  onEditSubscription = (subscription) => () => {
    if (subscription === undefined) {
      this.setState({ editSubscription: undefined })
    } else {
      this.setState({
        editSubscription: this.buildEditSubscription(subscription),
      })
    }
  }

  onNewSubscription = () => {
    this.setState({
      editSubscription: {
        isNew: true,
        mainItemMap: {},
        sideItemMap: {},
      },
    })
  }

  onNotifyChef = (subscriptionId, chefId) => () => {
    this.props.notifySubscriptionChef(subscriptionId, chefId)
  }

  onSaveDietaryRestrictions = (member) => async () => {
    const { updateMember } = this.props
    const { editDietaryRestrictions } = this.state
    const dietaryRestrictions = Object.entries(editDietaryRestrictions)
      .filter((r) => r[1])
      .map((r) => r[0])

    if (await updateMember(member.id, { dietaryRestrictions })) {
      this.setState({ editDietaryRestrictions: undefined })
    }
  }

  onSaveMealSchedule = (member) => async () => {
    const { saveMealSchedule } = this.props
    const { editMealSchedule } = this.state
    const weekdays = Object.entries(editMealSchedule)
      .filter((s) => s[1])
      .map((s) => Number(s[0]))

    if (await saveMealSchedule(member.id, weekdays)) {
      this.setState({ editMealSchedule: undefined })
    }
  }

  onSaveSubscription = (member) => async (editSubscription) => {
    if (await this.props.saveSubscription(member, editSubscription)) {
      this.setState({ editSubscription: undefined })

      return true
    }

    return false
  }

  onSearch = (e) => {
    this.setState({ searchTerm: e.target.value }, this.loadSubscribers)
  }

  onSendSurveys = (memberId, subscriptionId) => (surveyType) => {
    this.props.sendSurveys(memberId, subscriptionId, surveyType)
  }

  onShowSubscriber = (id) => () => {
    this.setState({ activeSubscriberId: id })
  }

  onToggleActive = (member) => () => {
    const { updateMember } = this.props
    if (member.active) {
      updateMember(member.id, { active: false })
    } else {
      updateMember(member.id, { active: true })
    }
  }

  onToggleRecurring = (member) => () => {
    const { updateMember } = this.props
    if (member.recurring) {
      updateMember(member.id, { recurring: false })
    } else {
      updateMember(member.id, { recurring: true })
    }
  }

  onToggleSkip = (member) => () => {
    const week = Moment().startOf('isoWeek').add(1, 'week').format('MM/DD/YYYY')

    const { skipWeek, unskipWeek } = this.props
    if (member.skipped) {
      unskipWeek(member.id, week)
    } else {
      skipWeek(member.id, week)
    }
  }

  onChooseMemberSalesRep = async (member, salesRep) => {
    const { chooseMemberSalesRep, flashSuccess } = this.props
    if (await chooseMemberSalesRep(member, salesRep)) {
      flashSuccess('Changes saved')
      this.loadSubscribers()
    }
  }

  renderEditNew(m, editSubscription) {
    if (!editSubscription || !editSubscription.isNew) {
      return
    }
    const { hqId, loadHomeMenus } = this.props

    return (
      <div>
        <label className="font-bold text-hungryGray">Date</label>
        <DateInput
          className="w-full"
          date={
            editSubscription.dateStr &&
            Moment(editSubscription.dateStr, 'MM/DD/YYYY')
          }
          onChange={this.onChangeSubscriptionDate}
        />
        <label className="font-bold text-hungryGray">Head Count</label>
        <select
          className="w-full font-bold text-hungryGray"
          value={editSubscription.headcount}
          onChange={this.onChangeSubscriptionHeadcount}
        >
          {[null, 4, 6, 8].map((headcount) => (
            <option key={headcount} value={headcount}>
              {headcount}
            </option>
          ))}
        </select>
        <label className="font-bold text-hungryGray">Menu</label>
        <AutocompleteInput
          value={
            editSubscription.menu &&
            `Chef ${editSubscription.menu.chef.name}'s ${editSubscription.menu.name}`
          }
          loaderFunction={() => loadHomeMenus(hqId)}
          placeholder={'Select a Menu'}
          onSelect={this.onChooseMenu}
        />
        <div className="mb-2">
          <label className="font-bold text-hungryGray w-16">Mains</label>
          {Object.values(editSubscription.mainItemMap).map((item) => (
            <div key={item.id}>
              <div className="flex items-center mb-1">
                <input
                  className="checkbox-input"
                  type="checkbox"
                  checked={item.chosen}
                  onChange={this.onChooseMainItem(item.id)}
                />
                <p className="ml-2">{item.name}</p>
              </div>
              {item.chosen && (
                <div className="flex items-center mb-1">
                  Serving Quantity&nbsp;
                  <select
                    className="w-full font-bold text-hungryGray"
                    value={item.quantity}
                    onChange={this.onChangeMainItemQty(item.id)}
                  >
                    {[null, 4, 8].map((headcount) => (
                      <option key={headcount} value={headcount}>
                        {headcount}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="mb-2">
          <label className="font-bold text-hungryGray w-16">Sides</label>
          {Object.values(editSubscription.sideItemMap).map((item) => (
            <div key={item.id}>
              <div className="flex items-center mb-1">
                <input
                  className="checkbox-input"
                  type="checkbox"
                  checked={item.chosen}
                  onChange={this.onChooseSideItem(item.id)}
                />
                <p className="ml-2">{item.name}</p>
              </div>
              {item.chosen && (
                <div className="flex items-center mb-1">
                  Serving Quantity&nbsp;
                  <select
                    className="w-full font-bold text-hungryGray"
                    value={item.quantity}
                    onChange={this.onChangeSideItemQty(item.id)}
                  >
                    {[null, 4, 8, 16].map((headcount) => (
                      <option key={headcount} value={headcount}>
                        {headcount}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <button
            className="button-primary focus:outline-none mr-1"
            onClick={() => this.onSaveSubscription(m)(editSubscription)}
          >
            Save
          </button>
          <button
            className="button-neutral focus:outline-none"
            onClick={this.onEditSubscription(undefined)}
          >
            Cancel
          </button>
        </div>
      </div>
    )
  }

  render() {
    const { hqId, loadSalesReps, triggerChargeOrders, triggerCutoff } =
      this.props
    const { members = [] } = this.props
    const {
      activeSubscriberId,
      editDietaryRestrictions,
      editMealSchedule,
      editMember,
      editSubscription,
      filteredRep: rep,
      searchTerm,
      showNewModal,
    } = this.state
    // if ( searchTerm ) {
    //   members = presenters.pSearchSubscribers( members, searchTerm )
    // }
    const repName = rep ? [rep.firstName, rep.lastName].join(' ') : ''

    return (
      <div>
        <EditSubscriberModal
          member={editMember}
          hide={() => this.setState({ editMember: undefined })}
        />
        <NewSubscriberModal
          hqId={hqId}
          show={showNewModal}
          hide={() => this.setState({ showNewModal: false })}
        />

        <div className="flex flex-col m-8">
          <AuthorizedDisplay roles={['developer']}>
            <div className="flex flex-col font-bold mb-4 px-1">
              <div className="mb-4">
                <button
                  className="button-primary mr-2 w-64 self-start mb-0 ml-0 focus:outline-none"
                  onClick={triggerChargeOrders}
                >
                  Dev: Trigger Charge Orders
                </button>
                <button
                  className="button-primary w-64 self-start mb-0 ml-0 focus:outline-none"
                  onClick={triggerCutoff}
                >
                  Dev: Trigger Cutoff
                </button>
              </div>
            </div>
          </AuthorizedDisplay>

          <div className="flex mb-6 justify-between">
            <button
              className="add-new-button w-64 self-start mb-0 ml-0 focus:outline-none"
              onClick={() => this.setState({ showNewModal: true })}
            >
              Create New Home Subscriber
            </button>

            <div className="flex flex-row">
              <div>
                <p> Sales Rep </p>
                <div className="dropdown">
                  <AutocompleteInput
                    value={repName}
                    loaderFunction={loadSalesReps}
                    onSelect={(filteredRep) =>
                      this.setState({ filteredRep }, this.loadSubscribers)
                    }
                  />
                </div>
              </div>
              {rep && (
                <button
                  className="text-red-600 bold ml-2 mr-4"
                  onClick={() =>
                    this.setState(
                      { filteredRep: undefined },
                      this.loadSubscribers,
                    )
                  }
                >
                  X
                </button>
              )}
            </div>

            <input
              type="text"
              className="border regular text-lg text-hungryGray ml-2 px-3 bg-gray-100 rounded border-gray-400"
              placeholder="search..."
              value={searchTerm}
              onChange={this.onSearch}
            />
          </div>
          <div className="flex items-center mb-4">
            <div className="bg-gray-300 rounded-full w-2 h-2 mr-1" />
            <span className="mr-4">inactive</span>
            <div className="bg-green-400 rounded-full w-2 h-2 mr-1" />
            <span className="mr-4">chosen</span>
            <div className="bg-red-400 rounded-full w-2 h-2 mr-1" />
            <span className="mr-4">not yet chosen</span>
            <div className="bg-purple-300 rounded-full w-2 h-2 mr-1" />
            <span className="mr-4">skip this week</span>
            <div className="bg-blue-300 rounded-full w-2 h-2 mr-1" />
            <span className="mr-4">single order</span>
          </div>

          <div className="flex font-bold mb-4 px-1">
            <div className="flex-1">Name</div>
            <div className="flex-1 pl-3">Email</div>
            <div className="flex-1">Phone</div>
            <div className="flex-1">Dietary Restrictions</div>
            <div className="flex-1">Rep</div>
            <div className="flex-1">Referrer</div>
            <div className="flex-1">Date Joined</div>
          </div>

          {members.map((m) => {
            let bgColor
            if (!m.recurring) {
              bgColor = 'bg-blue-300'
            } else if (!m.active) {
              bgColor = 'bg-gray-300'
            } else if (m.skipped) {
              bgColor = 'bg-purple-300'
            } else if (m.needsSelect) {
              bgColor = 'bg-red-400'
            } else {
              bgColor = 'bg-green-400'
            }
            const repName = m.salesRep
              ? `${m.salesRep.firstName} ${m.salesRep.lastName}`
              : 'None'

            return (
              <div key={m.id} className="mb-2">
                <div className="subscriber-row relative flex items-center px-1">
                  {activeSubscriberId === m.id ? (
                    <button
                      className="show-subscriber-button absolute bg-gray-200 px-1 border-gray-400 border border-solid mr-1 outline-none focus:outline-none"
                      onClick={this.onShowSubscriber(undefined)}
                    >
                      ▲
                    </button>
                  ) : (
                    <button
                      className="show-subscriber-button absolute bg-gray-200 px-1 border-gray-400 border border-solid mr-1 outline-none focus:outline-none"
                      onClick={this.onShowSubscriber(m.id)}
                    >
                      ▼
                    </button>
                  )}
                  <div className={`${bgColor} rounded-full w-2 h-2 mr-1`} />
                  <div className="flex-1">
                    {m.firstName} {m.lastName}
                  </div>
                  <div className="flex-1">{m.email}</div>
                  <div className="flex-1">{m.phone}</div>
                  <div className="flex-1">
                    {m.dietaryRestrictions.join(', ')}
                  </div>
                  <div className="flex-1">{repName}</div>
                  <div className="flex-1">{m.referrer}</div>
                  <div className="flex-1">
                    {Moment(m.createdAt).format('MMM D, YYYY h:mma')}
                  </div>
                </div>
                {activeSubscriberId === m.id && (
                  <div className="px-8 mt-2 mb-6">
                    <div className="py-2">
                      <p> Sales Rep </p>
                      <div className="dropdown">
                        <AutocompleteInput
                          value={repName}
                          loaderFunction={loadSalesReps}
                          onSelect={(salesRep) =>
                            this.onChooseMemberSalesRep(m, salesRep)
                          }
                        />
                      </div>
                    </div>
                    <div className="py-2">
                      {/* <button className="button-neutral focus:outline-none mr-2" onClick={ () => this.setState({ editMember: m }) }>Edit Member Info</button> */}
                      {m.recurring && (
                        <button
                          className="button-neutral focus:outline-none mr-2"
                          onClick={this.onToggleActive(m)}
                        >
                          {m.active ? 'Set Inactive' : 'Set Active'}
                        </button>
                      )}
                      {m.recurring && (
                        <button
                          className="button-neutral focus:outline-none mr-2"
                          onClick={this.onToggleSkip(m)}
                        >
                          {m.skipped ? 'Unskip Week' : 'Skip Week'}
                        </button>
                      )}
                      <button
                        className="button-neutral focus:outline-none"
                        onClick={this.onToggleRecurring(m)}
                      >
                        {m.recurring ? 'Disable Recurring' : 'Enable Recurring'}
                      </button>
                    </div>
                    <div className="member-info-row relative mb-2">
                      {editMealSchedule ? (
                        <button
                          className="subscription-close-edit-button text-xl px-2 py-1 text-hungryGray focus:outline-none"
                          onClick={this.onEditMealSchedule(undefined)}
                        >
                          ❌
                        </button>
                      ) : (
                        <button
                          className="subscription-edit-button text-xl px-2 py-1 text-hungryGray focus:outline-none"
                          onClick={this.onEditMealSchedule(m.weekdays)}
                        >
                          ✎
                        </button>
                      )}
                      {m.recurring && (
                        <div>
                          <label className="font-bold text-hungryGray w-40">
                            Meal Schedule
                          </label>
                          {editMealSchedule ? (
                            <div className="flex items-center">
                              <div>
                                <div className="flex">
                                  <div className="w-12 text-center">Mon</div>
                                  <div className="w-12 text-center">Tues</div>
                                  <div className="w-12 text-center">Wed</div>
                                  <div className="w-12 text-center">Thurs</div>
                                  <div className="w-12 text-center">Fri</div>
                                  <div className="w-12 text-center">Sat</div>
                                  <div className="w-12 text-center">Sun</div>
                                </div>
                                <div className="flex">
                                  <div className="w-12 text-center">
                                    <input
                                      className="checkbox-input"
                                      type="checkbox"
                                      checked={editMealSchedule[1]}
                                      onChange={this.onChangeMealSchedule(1)}
                                    />
                                  </div>
                                  <div className="w-12 text-center">
                                    <input
                                      className="checkbox-input"
                                      type="checkbox"
                                      checked={editMealSchedule[2]}
                                      onChange={this.onChangeMealSchedule(2)}
                                    />
                                  </div>
                                  <div className="w-12 text-center">
                                    <input
                                      className="checkbox-input"
                                      type="checkbox"
                                      checked={editMealSchedule[3]}
                                      onChange={this.onChangeMealSchedule(3)}
                                    />
                                  </div>
                                  <div className="w-12 text-center">
                                    <input
                                      className="checkbox-input"
                                      type="checkbox"
                                      checked={editMealSchedule[4]}
                                      onChange={this.onChangeMealSchedule(4)}
                                    />
                                  </div>
                                  <div className="w-12 text-center">
                                    <input
                                      className="checkbox-input"
                                      type="checkbox"
                                      checked={editMealSchedule[5]}
                                      onChange={this.onChangeMealSchedule(5)}
                                    />
                                  </div>
                                  <div className="w-12 text-center">
                                    <input
                                      className="checkbox-input"
                                      type="checkbox"
                                      checked={editMealSchedule[6]}
                                      onChange={this.onChangeMealSchedule(6)}
                                    />
                                  </div>
                                  <div className="w-12 text-center">
                                    <input
                                      className="checkbox-input"
                                      type="checkbox"
                                      checked={editMealSchedule[7]}
                                      onChange={this.onChangeMealSchedule(7)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <button
                                className="button-primary ml-8 font-bold  focus:outline-none"
                                onClick={this.onSaveMealSchedule(m)}
                              >
                                Save
                              </button>
                            </div>
                          ) : (
                            <span>
                              {(m.weekdays || [])
                                .map(
                                  (d) =>
                                    ({
                                      1: 'Mon',
                                      2: 'Tues',
                                      3: 'Wed',
                                      4: 'Thur',
                                      5: 'Fri',
                                      6: 'Sat',
                                      7: 'Sun',
                                    })[d],
                                )
                                .join(', ')}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="member-info-row relative mb-3">
                      {editDietaryRestrictions ? (
                        <button
                          className="subscription-close-edit-button text-xl px-2 py-1 text-hungryGray focus:outline-none"
                          onClick={this.onEditDietaryRestrictions(undefined)}
                        >
                          ❌
                        </button>
                      ) : (
                        <button
                          className="subscription-edit-button text-xl px-2 py-1 text-hungryGray focus:outline-none"
                          onClick={this.onEditDietaryRestrictions(
                            m.dietaryRestrictions,
                          )}
                        >
                          ✎
                        </button>
                      )}
                      <label className="font-bold text-hungryGray w-40">
                        Dietary Restrictions
                      </label>
                      {editDietaryRestrictions ? (
                        <div className="flex items-center">
                          <div>
                            <div className="flex">
                              {DietaryRestrictions.map((d) => (
                                <div key={d} className="w-12 text-center">
                                  {d}
                                </div>
                              ))}
                            </div>
                            <div className="flex">
                              {DietaryRestrictions.map((d) => (
                                <div key={d} className="w-12 text-center">
                                  <input
                                    className="checkbox-input"
                                    type="checkbox"
                                    checked={editDietaryRestrictions[d]}
                                    onChange={this.onChangeDietaryRestrictions(
                                      d,
                                    )}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                          <button
                            className="button-primary ml-8  focus:outline-none"
                            onClick={this.onSaveDietaryRestrictions(m)}
                          >
                            Save
                          </button>
                        </div>
                      ) : (
                        <span>{m.dietaryRestrictions.join(', ')}</span>
                      )}
                    </div>
                    {m.recurring && (
                      <div>
                        <h2 className="font-bold text-hungryGray mb-2">
                          Subscriptions
                        </h2>
                        <div className="flex flex-wrap">
                          {m.lockedIn.map((s, i) => (
                            <SubscriptionLockedIn
                              key={i}
                              memberId={m.id}
                              subscription={s}
                              onDeleteSubscription={this.onDeleteSubscription(
                                m,
                              )}
                              onNotifyChef={this.onNotifyChef(
                                s.id,
                                s.menu.chef.id,
                              )}
                              onSendSurveys={this.onSendSurveys(m.id, s.id)}
                            />
                          ))}
                          {m.editable.map((s) => (
                            <SubscriptionEditable
                              key={s.id}
                              className="w-1/4"
                              hqId={hqId}
                              memberId={m.id}
                              subscription={s}
                              buildEditSubscription={this.buildEditSubscription}
                              flashError={this.props.flashError}
                              loadHomeMenu={this.props.loadHomeMenu}
                              loadHomeMenus={this.props.loadHomeMenus}
                              onDeleteSubscription={this.onDeleteSubscription(
                                m,
                              )}
                              onNotifyChef={this.onNotifyChef(
                                s.id,
                                s.menu.chef.id,
                              )}
                              onSaveSubscription={this.onSaveSubscription(m)}
                              onSendSurveys={this.onSendSurveys(m.id, s.id)}
                            />
                          ))}
                          <div className="subscription-card relative p-2 w-1/4 border-solid border border-gray-400 rounded">
                            {this.renderEditNew(m, editSubscription)}
                            {(!editSubscription || !editSubscription.isNew) && (
                              <button
                                className="w-full h-full text-3xl focus:outline-none"
                                onClick={this.onNewSubscription}
                              >
                                ＋
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

SubscriberPage.propTypes = {
  userRep: PropTypes.object,
  members: PropTypes.array,
  hqId: PropTypes.number,

  callAfterTimeout: PropTypes.func,
  chooseMemberSalesRep: PropTypes.func,
  deleteSubscription: PropTypes.func,
  flashError: PropTypes.func,
  flashSuccess: PropTypes.func,
  loadHomeMenu: PropTypes.func,
  loadHomeMenus: PropTypes.func,
  loadSalesReps: PropTypes.func,
  loadSubscribers: PropTypes.func,
  notifySubscriptionChef: PropTypes.func,
  saveDietaryRestrictions: PropTypes.func,
  saveMealSchedule: PropTypes.func,
  saveSubscription: PropTypes.func,
  sendSurveys: PropTypes.func,
  skipWeek: PropTypes.func,
  triggerChargeOrders: PropTypes.func,
  triggerCutoff: PropTypes.func,
  unskipWeek: PropTypes.func,
  updateMember: PropTypes.func,
}

export default SubscriberPage
