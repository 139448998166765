import braintree from 'braintree-web'

import { BaseService } from 'hungry-core2'

export class BraintreeService extends BaseService {
  client = undefined

  initBraintree = async (authorization) => {
    if (!this.client) {
      this.client = await braintree.client.create({ authorization })
    }

    return this.client
  }

  updateNonce = async (cardData) => {
    const client = this.client || (await this.initBraintree())

    return client.request({
      endpoint: 'payment_methods/credit_cards',
      method: 'post',
      data: cardData,
    })
  }

  validate = (data) => {
    const { cardNumber, expirationDate, cvv } = data

    // check at least all fields filled out
    return (
      cardNumber &&
      expirationDate &&
      cvv &&
      (cvv.length === 3 || cvv.length == 4)
    )
  }
}
