import { connect } from 'react-redux'

import { PopUpsPage } from '@components/popUps'

import coordinators from '@coordinators'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { lastLoggedInHq, email, router } = state.user

  return {
    headquarter: lastLoggedInHq,
    email,
    ...router,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService } = services
  const {
    CopyPopUp,
    GetPopUp,
    GetPopUpAndOrders,
    NotifyPopUpChef,
    SearchPopUps,
    MarkPopUpsPaid,
  } = coordinators
  const copyPopUp = CopyPopUp({
    RestService,
    HandleError,
  })
  const getPopUp = GetPopUp({
    RestService,
    HandleError,
  })
  const getPopUpAndOrders = GetPopUpAndOrders({
    RestService,
    HandleError,
  })
  const notifyPopUpChef = NotifyPopUpChef({
    RestService,
    UIService,
    HandleError,
  })
  const searchPopUps = SearchPopUps({
    RestService,
    HandleError,
  })
  const markPopUpsPaid = MarkPopUpsPaid({
    RestService,
    UIService,
    HandleError,
  })

  return {
    copyPopUp,
    getPopUp,
    getPopUpAndOrders,
    notifyPopUpChef,
    searchPopUps,
    markPopUpsPaid,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpsPage)
