export const GroupOrderOrdersColumns = [
  { key: 'Order Number' },
  { key: 'Location' },
  { key: 'Date' },
  { key: 'Customer Name' },
  { key: 'Menu Name' },
  { key: 'Subtotal' },
  { key: 'Tax' },
  { key: 'Tip' },
  { key: 'Total' },
]

export const GroupOrderItemsColumns = [
  { key: 'Item Name' },
  { key: 'Item Type' },
  { key: 'Quantity' },
  { key: 'Cost' },
  { key: 'Total Cost' },
  { key: 'Price' },
  { key: 'Total Price' },
  { key: 'Location' },
]

export const GroupOrderStatus = {
  DELIVERED: 'Delivered',
  EMPTY: 'Empty',
  PAID: 'Paid',
  PENDING: 'Pending',
}

export const GroupOrderDeliveryFeeType = {
  STANDARD: 'Standard',
  FIXED: 'Fixed',
  PERCENT: 'Percent',
  PER_ORDER: 'PerOrder',
  WAIVE_FEE: 'WaiveFee',
  UNDER_LIMIT: 'UnderLimit',
}

export const GroupOrderDeliveryFee = [
  'Standard',
  'WaiveFee',
  'Fixed',
  'Percent',
  'PerOrder',
  'UnderLimit',
]

// Subsidy Types
export const GROUP_ORDER_SUBSIDY_TYPE_FIXED = 'FixedSubsidy'
export const GROUP_ORDER_SUBSIDY_TYPE_PERCENT = 'Percent'
export const GROUP_ORDER_SUBSIDY_TYPE_REVERSE = 'Reverse'
export const GROUP_ORDER_SUBSIDY_TYPE_HUNGRY_WALLET = 'HUNGRYWallet'
export const GROUP_ORDER_SUBSIDY_TYPE_FLAT_PRICE = 'FlatPriceSubsidy'

export const GroupOrderSubsidyTypes = [
  GROUP_ORDER_SUBSIDY_TYPE_FIXED,
  GROUP_ORDER_SUBSIDY_TYPE_PERCENT,
  GROUP_ORDER_SUBSIDY_TYPE_REVERSE,
  GROUP_ORDER_SUBSIDY_TYPE_HUNGRY_WALLET,
  GROUP_ORDER_SUBSIDY_TYPE_FLAT_PRICE,
]

export const GROUP_ORDER_ITEM_TYPE_ENTREE = 'Entree'
export const GROUP_ORDER_ITEM_TYPE_SIDE = 'Side'
export const GROUP_ORDER_ITEM_TYPE_ALL = 'All'
export const GROUP_ORDER_ITEM_TYPE_EMPTY = ''

export const GroupOrderItemPriceSettingsType = [
  GROUP_ORDER_ITEM_TYPE_EMPTY,
  GROUP_ORDER_ITEM_TYPE_ENTREE,
  GROUP_ORDER_ITEM_TYPE_SIDE,
  GROUP_ORDER_ITEM_TYPE_ALL,
]

export const GROUP_ORDER_HUNGRY_WALLET_TYPE_DAILY = 'Daily'
export const GROUP_ORDER_HUNGRY_WALLET_TYPE_WEEKLY = 'Weekly'
export const GROUP_ORDER_HUNGRY_WALLET_TYPE_MONTHLY = 'Monthly'
export const GROUP_ORDER_HUNGRY_WALLET_TYPE_EMPTY = ''

export const GroupOrderHungryWalletTypes = [
  GROUP_ORDER_HUNGRY_WALLET_TYPE_EMPTY,
  GROUP_ORDER_HUNGRY_WALLET_TYPE_DAILY,
  GROUP_ORDER_HUNGRY_WALLET_TYPE_WEEKLY,
  GROUP_ORDER_HUNGRY_WALLET_TYPE_MONTHLY,
]
