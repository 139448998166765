export const allOrderTypes = [
  { value: 'CateringOrder', text: 'Catering' },
  { value: 'GroupOrder', text: 'Group Order' },
  { value: 'PopUp', text: 'Pop Up' },
  { value: 'CateringOrder.VCX', text: 'VCX' },
]

export const AccountingOrderTypes = {
  POP_UP: 'PopUp',
  GROUP_ORDER: 'GroupOrder',
  CATERING_ORDER: 'CateringOrder',
  CATERING_ORDER_VX: 'CateringOrder.VCX',
}
