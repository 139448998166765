import React, { useEffect, useState } from 'react'
import { Moment } from 'moment'
import styled from '@emotion/styled'
import { FlexContainer, Panel, YSpacing } from '@components/common'
import {
  Button,
  DateTimeInput,
  Dropdown,
  TimeInput,
  DropdownWithCustomInputs,
} from '@components/common/form'
import { AutocompleteInput } from '@containers/common/form'
import { MultiSelectOption } from '@res/styledComponents/index'
import {
  Account,
  Address,
  FilterConfig,
  Cuisine,
  FilterOption,
  Option,
} from '@types'
import TooltipModal from '@components/common/modal/TooltipModal'
import { removeLtGtPrefix } from '@utils'
import { GREATER_THAN_CUSTOM, LESS_THAN_CUSTOM } from '../../../../constants'

interface GOScheduleFilterProps {
  initialDisabledFilters: Record<string, boolean>
  filterConfigs: FilterConfig[]
  userId: string
  displayFailureMessage: (mssg: string) => void
  loadAccounts: (val: { search: string }) => Promise<Account[]>
  loadCuisines: () => Promise<Cuisine[]>
  onSearch: () => void
  onChange: (
    filters: Record<
      string,
      string | Moment | boolean | number | string[] | null
    >,
    customValues: Record<string, string | undefined>,
  ) => void
  setClientGeo: (val: { longitude: string; latitude: string }) => void
}

const GOScheduleFilters = ({
  displayFailureMessage,
  initialDisabledFilters,
  filterConfigs,
  loadAccounts,
  loadCuisines,
  onChange,
  onSearch,
  setClientGeo,
}: GOScheduleFilterProps) => {
  const [filters, setFilters] = useState<Record<string, FilterOption>>({})
  const [multiSelectOptions, setMultiSelectOptions] = useState<
    Record<string, Option[]>
  >({})
  const [customValues, setCustomValues] = useState<
    Record<string, string | undefined>
  >({})
  const [disabledFilters, setDisabledFilters] = useState<
    Record<string, boolean>
  >(initialDisabledFilters)
  const [account, setAccount] = useState<Account | undefined>()
  const [address, setAddress] = useState<Address | undefined>()

  useEffect(() => {
    onLoadCuisines()
  }, [])

  useEffect(() => {
    onLoadConfigs(filterConfigs)
  }, [filterConfigs])

  useEffect(() => {
    if (address) {
      setClientGeo({
        latitude: address.latitude,
        longitude: address.longitude,
      })
    }
  }, [address])

  useEffect(() => {
    if (address && filters['distance']) {
      filters['longitude'] = Number(address.longitude)
      filters['latitude'] = Number(address.latitude)
    }
    onChange && onChange(filters, customValues)
  }, [filters, customValues, onChange])

  const onLoadCuisines = async () => {
    const cuisineTags = await loadCuisines()
    const cuisines = cuisineTags.map((c: Cuisine) => {
      return {
        label: c.name,
        value: c.name,
      }
    })
    setMultiSelectOptions({
      cuisines: cuisines,
    })
  }

  const handleSelectClientAddress = (addressId: string) => {
    if (account) {
      const address = account.addresses.find((addr) => addr.id == addressId)
      if (address) {
        if (!Number(address.longitude) || !Number(address.latitude)) {
          displayFailureMessage(
            'Address has invalid geo coordinates, please select another address',
          )

          return
        }
        setAddress(address)
      }
    }
  }

  const onLoadConfigs = (filterConfigs: FilterConfig[]) => {
    const initFilters = filterConfigs
      .filter((c) => c.default)
      .reduce((obj: Record<string, string>, c) => {
        obj[c.key] = c.default

        return obj
      }, {})
    setFilters(initFilters)
  }

  const onChangeFilter = (key: string, value: string) => {
    const newFilters = { ...filters, [key]: value }
    setFilters(newFilters)
    if (customValues[key]) {
      const newCustomValues = { ...customValues, [key]: undefined }
      setCustomValues(newCustomValues)
    }
  }

  const onChangeBooleanFilter = (key: string, value: string) => {
    let newFilters = {}
    if (value === '') {
      // handles removing selection
      newFilters = { ...filters, [key]: undefined }
    } else {
      newFilters = { ...filters, [key]: value === 'true' }
    }
    setFilters(newFilters)
  }

  const onChangeNumberFilter = (key: string, value: string) => {
    let newFilters = {}
    if (value === '') {
      // handles removing selection
      newFilters = { ...filters, [key]: undefined }
    } else {
      newFilters = { ...filters, [key]: Number(value) }
    }
    setFilters(newFilters)
  }

  const onChangeTimeFilter = (key: string, value: Moment | null) => {
    const newFilters = { ...filters, [key]: value }
    setFilters(newFilters)
    if (value) {
      const newDisabledFilters = { ...disabledFilters, [key]: false }
      setDisabledFilters(newDisabledFilters)
    } else {
      const newDisabledFilters = { ...disabledFilters, [key]: true }
      setDisabledFilters(newDisabledFilters)
    }
  }

  const onSelectClientFilter = (
    key: string,
    account: Account,
    name: string,
  ) => {
    const newFilters = {
      ...filters,
      [key]: account.id,
      [`${key}_name`]: name,
    }
    setAccount(account)
    setFilters(newFilters)
  }

  const clientFilter = (filterKey: string) => {
    const value = filters[`${filterKey}_name`]
      ? filters[`${filterKey}_name`]
      : ''
    if (typeof value == 'string') {
      return (
        <>
          <AutocompleteInput
            label="Client"
            loaderFunction={(search: { search: string }) =>
              loadAccounts({ ...search })
            }
            value={value}
            onSelect={(value) =>
              onSelectClientFilter(filterKey, value, value.name)
            }
          />
          {account && (
            <Dropdown
              label="Select Client Address"
              width="100%"
              marginBottom="0"
              onChange={(e) => handleSelectClientAddress(e.target.value)}
            >
              <option value="">- Select -</option>
              {account.addresses.map((o) => (
                <option key={o.id} value={o.id}>
                  {`${o.line1} ${o.city}, ${o.state}`}
                </option>
              ))}
            </Dropdown>
          )}
        </>
      )
    }
  }

  const dateTimeFilter = (filterKey: string, label = '') => {
    return (
      <FlexContainer flexDirection="row">
        <DateTimeInput
          width="100%"
          label={label ? label : filterKey}
          date={filters[filterKey]}
          onChange={(date: Moment) => onChangeTimeFilter(filterKey, date)}
          clearDate={() => onChangeTimeFilter(filterKey, null)}
        />
      </FlexContainer>
    )
  }

  const timeFilter = (
    filterKey: string,
    label = '',
    disabledKey = '',
    information = '',
  ) => {
    return (
      <FlexContainer>
        <TimeInput
          label={label}
          width="90%"
          time={filters[filterKey]}
          onChange={(val: Moment) => onChangeTimeFilter(filterKey, val)}
          disabled={disabledFilters[disabledKey]}
        />
        {information.length > 0 && (
          <TooltipModal
            unicode="&#9432;"
            width="400px"
            information={information}
            marginTop="40px"
          />
        )}
      </FlexContainer>
    )
  }

  const onRemoveMultiSelectOption = (key: string, index: number) => {
    const newFilters = { ...filters }
    const val = newFilters[key]
    if (val && Array.isArray(val)) {
      val.splice(index, 1)
      // delete name key saved for filter
      const nameVal = newFilters[`name_${key}`]
      if (nameVal && Array.isArray(nameVal)) {
        nameVal.splice(index, 1)
      }
      setFilters(newFilters)
    }
  }

  const onChangeMultiSelectFilter = (key: string, value: string) => {
    const newFilters = { ...filters }
    const val = filters[key]
    if (val && Array.isArray(val)) {
      val.push(value)
    } else {
      newFilters[key] = [value]
    }
    setFilters(newFilters)
  }

  const multiSelectObjsFilter = (filterKey: string, label: string) => {
    const options = multiSelectOptions[filterKey]
    let notSelectedOptions: Option[] = []
    const val = filters[filterKey]
    if (options && val && Array.isArray(val)) {
      notSelectedOptions = options.filter((x) => {
        return !val.includes(x.value.toString())
      })
    } else if (options) {
      notSelectedOptions = options
    }

    return (
      <div>
        <Dropdown
          label={label}
          width="100%"
          value=""
          marginBottom="0"
          onChange={(e) => onChangeMultiSelectFilter(filterKey, e.target.value)}
        >
          <option value="" disabled hidden>
            - Select -
          </option>
          {notSelectedOptions.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </Dropdown>
        <YSpacing height="5px" />
        <FlexContainer flexWrap="wrap">
          {val &&
            Array.isArray(val) &&
            val.map((filterVal, i) => {
              const option = options.find(
                (o) => o.value.toString() === filterVal,
              )
              if (option) {
                return (
                  <MultiSelectOption
                    key={filterVal}
                    onClick={() => onRemoveMultiSelectOption(filterKey, i)}
                  >
                    {option.label}
                  </MultiSelectOption>
                )
              }
            })}
        </FlexContainer>
      </div>
    )
  }

  const handleCustomValueChange = (key: string, value: any) => {
    const selectedValue = filters[key]
    const formattedValue =
      selectedValue == LESS_THAN_CUSTOM ? `lt_${value}` : `gt_${value}`
    setCustomValues((prevValues) => ({
      ...prevValues,
      [key]: formattedValue,
    }))
  }

  const dropdownCustomFilter = (config: FilterConfig, information = '') => {
    const value = filters[config.key]
    if (typeof value === 'string' || value == undefined) {
      return (
        <FlexContainer>
          <DropdownWithCustomInputs
            customValue={removeLtGtPrefix(customValues[config.key])}
            filter={config}
            hasCustomNumberInput={true}
            selectedValue={value || ''}
            onValueChange={onChangeFilter}
            onCustomValueChange={handleCustomValueChange}
            customKey="custom"
            width={information.length > 0 ? '90%' : '100%'}
            marginBottom="0"
          />
          {information.length > 0 && (
            <TooltipModal
              unicode="&#9432;"
              width="400px"
              information={information}
              marginTop="40px"
            />
          )}
        </FlexContainer>
      )
    }
  }

  const dropdownBooleanFilter = (
    filterKey: string,
    label: string,
    options: Option[],
  ) => {
    const val = filters[filterKey]
    if (typeof val === 'boolean' || val == undefined) {
      return (
        <Dropdown
          label={label}
          width="100%"
          marginBottom="0"
          value={val}
          onChange={(e) => onChangeBooleanFilter(filterKey, e.target.value)}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Dropdown>
      )
    }
  }

  const dropdownNumberFilter = (
    filterKey: string,
    label: string,
    options: Option[],
    information = '',
  ) => {
    const val = filters[filterKey]
    if (typeof val === 'number' || val == undefined) {
      return (
        <FlexContainer>
          <Dropdown
            label={label}
            width={information.length > 0 ? '90%' : '100%'}
            marginBottom="0"
            value={val}
            onChange={(e) => onChangeNumberFilter(filterKey, e.target.value)}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Dropdown>
          {information.length > 0 && (
            <TooltipModal
              unicode="&#9432;"
              width="400px"
              information={information}
              marginTop="40px"
            />
          )}
        </FlexContainer>
      )
    }
  }

  const renderFilter = (config: FilterConfig) => {
    let filter
    switch (config.name) {
      case 'multiSelectObj':
        filter = multiSelectObjsFilter(config.key, config.label)
        break
      case 'client':
        filter = clientFilter(config.key)
        break
      case 'dateTime':
        filter = dateTimeFilter(config.key, config.label)
        break
      case 'time':
        filter = timeFilter(
          config.key,
          config.label,
          config.disabledKey,
          config.information,
        )
        break
      case 'dropdown':
        filter = dropdownCustomFilter(config, config.information)
        break
      case 'dropdown-boolean':
        filter = dropdownBooleanFilter(config.key, config.label, config.options)
        break
      case 'dropdown-number':
        filter = dropdownNumberFilter(
          config.key,
          config.label,
          config.options,
          config.information,
        )
        break
    }

    return (
      <div>
        {filter}
        {filter && <YSpacing height="10px" />}
      </div>
    )
  }

  return (
    <Panel width={'100%'} minWidth="300px" maxWidth={'350px'}>
      <Button label="Search" width="100%" onClick={() => onSearch()} />
      <YSpacing height="10px" />
      {filterConfigs.map((config) => renderFilter(config))}
      <Disclaimer>
        Inputs with an <span>*</span> must have a client selected to return
        accurate results
      </Disclaimer>
    </Panel>
  )
}
const Disclaimer = styled.h2`
  color: red;
  span {
    color: black;
    font-size: 16px;
  }
`

export default GOScheduleFilters
