import { camelCaseify } from '~/utils'
import axios from 'axios'

export const AsyncLoadConcept =
  ({ RestService, pResponseConcept, HandleError }) =>
  async (conceptId) => {
    try {
      const concept = await RestService.get('/api/admin/concepts/' + conceptId)

      return pResponseConcept(concept)
    } catch (error) {
      HandleError({ error })
    }
  }

export const AsyncLoadCachedConcepts =
  ({ RestService, pResponseGeneric, HandleError }) =>
  async (conceptIds) => {
    try {
      const concepts = await RestService.get('/api/admin/concepts', {
        concept_ids: conceptIds.join(','),
        cache: '1',
      })

      return pResponseGeneric(concepts)
    } catch (error) {
      HandleError({ error })
    }
  }

export const AsyncLoadCachedFilteredConcepts =
  ({
    RestService,
    pRequestLoadCachedFilteredConcepts,
    pResponseFilteredConcepts,
    HandleError,
  }) =>
  async ({
    clientSetUpTime,
    chefId,
    headquarterId,
    search = '',
    orderType = undefined,
    filters,
    offset,
    limit,
    sortBy,
    sortOrder,
  }) => {
    try {
      const request = pRequestLoadCachedFilteredConcepts({
        clientSetUpTime,
        headquarterId,
        search,
        orderType,
        chefId,
        filters,
        offset,
        limit,
        sortBy,
        sortOrder,
      })

      const response = await RestService.get(
        '/api/admin/concepts/filtered_concepts',
        {
          ...request,
        },
      )

      return pResponseFilteredConcepts(response)
    } catch (error) {
      HandleError({ error })
    }
  }

export const AsyncSearchCachedConcepts =
  ({ RestService, pResponseGeneric, HandleError }) =>
  async (search) => {
    try {
      const concepts = await RestService.get('/api/admin/concepts', {
        ...search,
        cache: '1',
      })

      return pResponseGeneric(concepts)
    } catch (error) {
      HandleError({ error })
    }
  }

export const SearchPopUpConcepts =
  ({ RestService, pResponsePopUpConcepts, HandleError }) =>
  async (search) => {
    try {
      const concepts = await RestService.get('/api/admin/concepts', {
        ...search,
        cache: '1',
        is_approved: '1',
        is_enabled: '1',
        market_type: 'Pop-Up',
      })

      return pResponsePopUpConcepts(concepts)
    } catch (error) {
      HandleError({ error })
    }
  }

export const SearchGroupOrderConcepts =
  ({ RestService, pResponseGroupOrderConcepts, HandleError }) =>
  async ({ clientSetUpTime, headquarterId, search }) => {
    try {
      const params = {
        date: clientSetUpTime.toString(),
        hqId: headquarterId,
        day: clientSetUpTime.isoWeekday(),
      }
      const cParams = camelCaseify(params)
      const availChefs = await RestService.get(
        '/chefs/schedules/avail',
        cParams,
      )

      const availChefsSlices = []
      const sliceLimit = 50
      for (let i = 0; i < availChefs.length; i += sliceLimit) {
        availChefsSlices.push(availChefs.slice(i, i + sliceLimit))
      }

      const data = await axios.all(
        availChefsSlices.map((chefs) =>
          RestService.get('/api/admin/concepts', {
            ...search,
            chef_ids: [...chefs].map((chef) => chef.id).join(','),
            cache: '1',
            is_approved: '1',
            is_enabled: '1',
            market_type: 'Group Order',
          }),
        ),
      )

      const concepts = data.reduce((a, b) => a.concat(b), [])

      return pResponseGroupOrderConcepts(concepts)
    } catch (error) {
      HandleError({ error })
    }
  }

export const GetGroupOrderConcept =
  ({ RestService, pResponseGroupOrderConcept, HandleError }) =>
  async (id) => {
    try {
      const concepts = await RestService.get(`/api/admin/concepts/${id}`, {
        cached: '1',
      })

      return pResponseGroupOrderConcept(concepts)
    } catch (error) {
      HandleError({ error })
    }
  }

export const AsyncLoadSuggestedConcepts =
  ({ NodeApiService, pResponseGeneric, HandleError }) =>
  async (accountId, headquarterId) => {
    try {
      const params = {
        hide_metrics: '0',
        account_id: accountId,
        headquarter_id: headquarterId,
      }
      const data = await NodeApiService.get(
        'smart_catering_engine/suggestions',
        params,
      )
      const { suggested } = data

      return pResponseGeneric(suggested)
    } catch (error) {
      HandleError({ error })
    }
  }

export const LoadMenuConcepts =
  ({ ChefService, RestService, pResponseConcepts, HandleError }) =>
  async () => {
    const { id: chef_id } = ChefService.getState().chef
    try {
      let concepts = await RestService.get(
        '/api/admin/concepts',
        { chef_id },
        { timeout: 30000 },
      )
      concepts = pResponseConcepts(concepts)
      ChefService.setConcepts(concepts)
    } catch (error) {
      HandleError({ error })
    }
  }

export const DelayedUpdateConcept =
  ({ ChefService, UIService, pStateToReduxConcept }) =>
  (concept) => {
    const call = () => ChefService.setEditConcept(pStateToReduxConcept(concept))
    UIService.Timer.callAfterTimeout(call)
  }

export const EditMenuConcept =
  ({ ChefService, RestService, UIService, pResponseConcept, HandleError }) =>
  async (conceptId) => {
    try {
      const concept = await RestService.get(`/api/admin/concepts/${conceptId}`)
      ChefService.setEditConcept(pResponseConcept(concept))
      UIService.EditMenuConcept.show()
    } catch (error) {
      HandleError({ error })
    }
  }

export const NewMenuConcept =
  ({ ChefService, UIService }) =>
  () => {
    const { chef, editMenuConcept } = ChefService.getState()
    if (editMenuConcept.id) {
      ChefService.clearEditConcept()
    }

    if (chef) {
      ChefService.setEditConcept({ chefId: chef.id })
      UIService.EditMenuConcept.show()
    }
  }

export const DeleteMenuConcept =
  ({ ChefService, RestService, UIService, pResponseConcepts, HandleError }) =>
  async (id, name) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete concept "${name}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/api/admin/concepts/${id}`)
        ChefService.clearEditConcept()
        LoadMenuConcepts({ ChefService, RestService, pResponseConcepts })()
        UIService.EditMenuConcept.close()
      } catch (error) {
        HandleError({ error })
      }
    }
  }

export const BulkDeleteConcepts =
  ({ ChefService, RestService, UIService, pResponseConcepts, HandleError }) =>
  async (bulkDeleteMap) => {
    try {
      //Validate Concepts are not on any Future Active Group Order
      const { idMap } = await RestService.post(
        `group-orders/date-menus/validate/delete-concepts`,
        { bulkDeleteMap },
      )
      const ids = Object.keys(idMap)
      const gOrderValidationErrs = Object.values(idMap)
        .filter((reason) => reason)
        .join(' & ')
      if (gOrderValidationErrs) {
        UIService.FlashMessage.displayFailureMessage(gOrderValidationErrs)

        return
      } else {
        //Bulk Delete in Rails - rails has validation if concept is on future Catering Order
        const { deletedConcepts, invalidConcepts } = await RestService.delete(
          `/api/admin/concepts/bulk-delete`,
          { ids: ids },
        )
        if (invalidConcepts.length) {
          const invalidConceptReasons = invalidConcepts
            .map((concept) => concept.message.join(', '))
            .join(' & ')
          UIService.FlashMessage.displayFailureMessage(invalidConceptReasons)
        }
        if (deletedConcepts.length) {
          const deletedConceptsNames = deletedConcepts.join(' & ')
          UIService.FlashMessage.displaySuccessMessage(
            `Successfully deleted ${deletedConceptsNames}`,
          )
        }
      }
    } catch (error) {
      HandleError({ error })
    }
    LoadMenuConcepts({ ChefService, RestService, pResponseConcepts })()
  }

export const SaveMenuConcept =
  ({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateMenuConcept,
    pResponseConcepts,
    pRequestUpdateConceptImages,
    HandleError,
  }) =>
  async (data) => {
    UIService.Errors.clear()
    const req = pRequestUpdateMenuConcept(data)
    const headers = { 'Content-Type': 'multipart/form-data' }

    try {
      let concept = undefined
      if (data.id) {
        concept = await RestService.put(
          `/api/admin/concepts/${data.id}`,
          req,
          headers,
        )
      } else {
        concept = await RestService.post('/api/admin/concepts', req, headers)
      }

      const imageUpdateReq = pRequestUpdateConceptImages(data)

      if (concept && concept.id && imageUpdateReq) {
        concept = await RestService.put(
          `/api/admin/concepts/${concept.id}`,
          imageUpdateReq,
          headers,
        )
      }

      ChefService.clearEditConcept()
      LoadMenuConcepts({ ChefService, RestService, pResponseConcepts })()
      UIService.EditMenuConcept.close()
    } catch (error) {
      HandleError({ error, namespace: 'menuConceptModal' })
    }
  }

export const GetConceptAvgPricePerPerson =
  ({ RestService, HandleError }) =>
  async (isHappyHour, menuItemIds) => {
    try {
      const avgPPP = await RestService.post(
        '/api/admin/concepts/avg_price_per_person',
        {
          is_happy_hour: isHappyHour,
          menu_item_ids: menuItemIds,
        },
      )

      return camelCaseify(avgPPP).avgPricePerPerson
    } catch (error) {
      HandleError({ error, namespace: 'menuConceptModal' })

      return null
    }
  }

export const GetConceptEstPricePerPerson =
  ({ RestService, HandleError }) =>
  async (mainItemIds, sideItemIds) => {
    try {
      const out = await RestService.post(
        '/api/admin/concepts/estimated_price_per_person',
        {
          main_item_ids: mainItemIds,
          side_item_ids: sideItemIds,
        },
      )

      return camelCaseify(out)
    } catch (error) {
      HandleError({ error, namespace: 'menuConceptModal' })

      return null
    }
  }

export const LoadCuisineTags =
  ({ RestService, HandleError }) =>
  async () => {
    try {
      const resp = await RestService.get('/api/admin/concepts/cuisine_tags')

      return camelCaseify(resp)
    } catch (error) {
      HandleError({ error })
    }
  }
