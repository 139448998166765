export const EmailChef =
  ({ OrderService, RestService, UIService, HandleError }) =>
  async (chefId) => {
    const orderId = RestService.getState().order.id
    try {
      await RestService.post(`/api/admin/orders/${orderId}/email_chef`, {
        chef_id: chefId,
      })
      UIService.Alert.showPopup({
        title: 'Success!',
        message: 'Chef Email sent successfully!',
      })

      // refresh need chef alert status
      const order = OrderService.updatedChefAlertOrder(chefId)
      OrderService.setOrderable(order)
    } catch (error) {
      HandleError({ error })
    }
  }

export const EmailChefs =
  ({ OrderService, RestService, UIService, HandleError }) =>
  async () => {
    const orderId = RestService.getState().order.id
    try {
      await RestService.post(`/api/admin/orders/${orderId}/email_chefs`)
      UIService.Alert.showPopup({
        title: 'Success!',
        message: 'Chef Emails sent successfully!',
      })

      // clear chef alert status
      const order = OrderService.clearedChefAlertOrder()
      OrderService.setOrderable(order)
    } catch (error) {
      HandleError({ error })
    }
  }

export const EmailServicesSurvey =
  ({ RestService, UIService }) =>
  async () => {
    const orderId = RestService.getState().order.id
    await RestService.post(`/api/admin/orders/${orderId}/email_services_survey`)
    UIService.Alert.showPopup({
      title: 'Success!',
      message: 'Services Survey Email sent successfully!',
    })
  }

export const EmailSurvey =
  ({ RestService, UIService }) =>
  async () => {
    const orderId = RestService.getState().order.id
    await RestService.post(`/api/admin/orders/${orderId}/email_survey`)
    UIService.Alert.showPopup({
      title: 'Success!',
      message: 'Order Survey Email sent successfully!',
    })
  }

export const EmailServicesTeam =
  ({ OrderService, RestService, UIService, HandleError }) =>
  async () => {
    const orderId = RestService.getState().order.id
    try {
      await RestService.post(`/api/admin/orders/${orderId}/email_services`)
      UIService.Alert.showPopup({
        title: 'Success!',
        message: 'Services Email sent successfully!',
      })

      const order = OrderService.clearedServiceAlertOrder()
      OrderService.setOrderable(order)
    } catch (error) {
      HandleError({ error })
    }
  }

export const EmailClientOrderReminder =
  ({ OrderService, RestService, UIService, HandleError }) =>
  async (orderId, recipients, markSent = true) => {
    try {
      await RestService.post(
        `/api/admin/orders/${orderId}/email_client_order_reminder`,
        { recipients },
      )
      UIService.Alert.showPopup({
        title: 'Success!',
        message: 'Client Order Reminder Email sent successfully!',
      })

      // refresh need chef alert status
      if (markSent) {
        const order = OrderService.clientOrderReminderSent()
        OrderService.setOrderable(order)
      }
    } catch (error) {
      HandleError({ error })
    }
  }

export const DisableAutoEmailClientOrderReminder =
  ({ OrderService, RestService, UIService, HandleError }) =>
  async (orderId, enabled) => {
    try {
      const params = { auto_client_order_reminder: enabled }
      await RestService.put(`/api/admin/orders/${orderId}`, params)
      UIService.Alert.showPopup({
        title: 'Success!',
        message: `Automatic Client Order Reminder - ${
          enabled ? 'Enabled' : 'Disabled'
        }`,
      })

      // refresh need chef alert status
      const order = OrderService.autoClientOrderReminderSet(enabled)
      OrderService.setOrderable(order)
    } catch (error) {
      HandleError({ error })
    }
  }
