import { connect } from 'react-redux'

import { ChildOrdersSection } from '@components/batchOrder/edit/sections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapDispatchToProps = () => {
  const { BatchOrderService, RestService } = services
  const { AsyncLoadAccountOrders } = coordinators
  const { pResponseOrders } = presenters.Api
  const { filterAccountOrdersByOrderNumber } = BatchOrderService

  const loadAccountOrders = AsyncLoadAccountOrders({
    RestService,
    pResponseOrders,
  })

  return {
    filterAccountOrdersByOrderNumber,
    loadAccountOrders,
  }
}

export default connect(null, mapDispatchToProps)(ChildOrdersSection)
