import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/common/modal/Modal'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import {
  Checkbox,
  Dropdown,
  Input,
  LinkText,
  Button,
} from '@components/common/form'
import FlexContainer from '@components/common/FlexContainer'
import { AutocompleteInput } from '@containers/common/form'
import { AuthorizedInteractable } from '@containers/common/auth'
import { Label } from '@res/styledComponents/index'
import { colors } from '../../../../constants'
const intialAssignedRolesState = {
  'master admin': false,
  'sales rep': false,
  'sales lead': false,
  chef: false,
  'chef lead': false,
  'catering captain': false,
  'captain lead': false,
  finance: false,
  'accounting dashboard read only': false,
  'nationals read only': false,
  'rangers read only': false,
  'nationals admin': false,
  'rangers admin': false,
}

const initialState = {
  firstName: '',
  id: '',
  lastName: '',
  name: '',
  password: '',
  roles: [],
  headquarters: [],
  isDisabled: false,
  currentHeadquarterId: undefined,
  assignedRoles: { ...intialAssignedRolesState },
}

export class EditUserModal extends Component {
  state = { ...initialState }

  componentWillMount() {
    const { clearErrors, currentHeadquarterId, headquarters, user } = this.props
    clearErrors()
    this.setState(
      { ...user, headquarters, currentHeadquarterId },
      this.updateAssignedRoles,
    )
  }

  componentWillReceiveProps(nextProps) {
    const { headquarters, user } = nextProps
    if (user !== this.props.user || headquarters !== nextProps.headquarters) {
      this.setState({ ...user, headquarters }, this.updateAssignedRoles)
    }
  }

  updateAssignedRoles = () => {
    const { roles, currentHeadquarterId } = this.state
    const assignedRoles = { ...intialAssignedRolesState }
    const hqRoles = roles.filter(
      (r) => r.headquarterId === currentHeadquarterId,
    )
    const activeRoles = hqRoles.filter((r) => !r._destroy)
    activeRoles.forEach((r) => (assignedRoles[r.name] = true))
    this.setState({ assignedRoles })
  }

  // delete = () => {
  //   this.props.deleteUser( this.state )
  // }

  onChangeRoles = (e) => {
    const { checked, value: key } = e.target
    let { roles } = this.state
    const { currentHeadquarterId } = this.state
    const roleIndex = roles.findIndex(
      (r) => r.name === key && r.headquarterId === currentHeadquarterId,
    )
    // TODO - refactor roles to remove destroy attribute by remembering prior roles and comparing in presenter
    if (roleIndex >= 0) {
      roles = roles.map((r, i) => {
        if (i === roleIndex) {
          r._destroy = !checked
        }

        return r
      })
    } else {
      roles = [
        ...roles,
        { name: key, headquarterId: currentHeadquarterId, _destroy: !checked },
      ]
    }
    this.setState({ roles }, this.updateAssignedRoles)
  }

  onChangeHq = (e) => {
    this.setState(
      { currentHeadquarterId: parseInt(e.target.value) },
      this.updateAssignedRoles,
    )
  }

  onChangeDisabled = (e) => {
    const { checked } = e.target
    this.setState({ isDisabled: checked })
  }

  onInput = (field) => (value) => {
    if (value.target) {
      ;({ value } = value.target)
    }
    this.setState({ [field]: value })
    this.props.clearError(field)
  }

  onInputPhoneNumber = (e) => {
    let phoneNumber = e.target.value
    phoneNumber = phoneNumber.replace(/[^0-9#()\- ]/g, '')
    this.setState({ phoneNumber })
    this.props.clearError('phoneNumber')
  }

  onHide = () => {
    this.props.close()
  }

  onSelectHubspotUser = (c) => {
    this.setState({ hubspotUser: c }, () =>
      this.props.updateHubspotUserRef(this.state.id, c.id, c.name),
    )
  }

  onSave = () => {
    this.props.saveUser(this.state)
  }

  render() {
    const { errors, hubspotUser, loadHubspotUsers } = this.props
    const {
      currentHeadquarterId,
      email,
      firstName,
      headquarters,
      id,
      lastName,
      name,
      password,
      phoneNumber,
      isDisabled,
      assignedRoles,
    } = this.state
    const rolesList = Object.keys(assignedRoles).sort()

    return (
      <Modal
        title={id ? `Edit ${name}` : 'New User'}
        hideModal={this.onHide}
        color="#001940"
        width="400px"
      >
        <Input
          onChange={this.onInput('firstName')}
          value={firstName}
          width="100%"
          label="First Name"
          error={errors.firstName}
          testId="first-name"
        />
        <Input
          onChange={this.onInput('lastName')}
          value={lastName}
          width="100%"
          label="Last Name"
          error={errors.lastName}
          testId="last-name"
        />
        <Input
          onChange={this.onInput('email')}
          value={email}
          width="100%"
          label="Email"
          error={errors.email}
          testId="email"
        />
        <Input
          onChange={this.onInputPhoneNumber}
          value={phoneNumber}
          width="100%"
          label="Phone Number"
          error={errors.phoneNumber}
          testId="phone-number"
        />
        <Input
          onChange={this.onInput('password')}
          value={password}
          width="100%"
          label="Temporary Password"
          error={errors.password}
          testId="password"
        />
        <AutocompleteInput
          label="Hubspot Rep"
          marginBottom="20px"
          value={hubspotUser && hubspotUser.name}
          placeholder={'Must use full email address'}
          loaderFunction={loadHubspotUsers}
          onSelect={this.onSelectHubspotUser}
        />
        <YSpacing height="20px" />
        <Label>User Disabled/Inactive</Label>
        <AuthorizedInteractable roles={['master admin']}>
          <Checkbox
            value={isDisabled}
            marginBottom="5px"
            checked={isDisabled}
            onChange={this.onChangeDisabled}
            testId="role-list"
          />
        </AuthorizedInteractable>
        <YSpacing height="20px" />
        <Dropdown
          label="Headquarter"
          width="100%"
          testId="headquarter"
          value={currentHeadquarterId}
          onChange={this.onChangeHq}
        >
          {headquarters.map((h) => (
            <option key={h.id} value={h.id}>
              {h.name}
            </option>
          ))}
        </Dropdown>
        <Label>Roles</Label>
        <YSpacing height="10px" />
        {rolesList.map((role) => {
          switch (role) {
            case 'catering captain':
              return (
                <AuthorizedInteractable
                  key={role}
                  roles={['master admin', 'sales lead', 'captain lead']}
                >
                  <Checkbox
                    label={role}
                    value={role}
                    key={role}
                    marginBottom="5px"
                    checked={assignedRoles[role]}
                    onChange={this.onChangeRoles}
                    testId="role-list"
                  />
                </AuthorizedInteractable>
              )
            case 'chef':
              return (
                <div>
                  <AuthorizedInteractable
                    key={role}
                    roles={['master admin', 'sales lead', 'chef lead']}
                  >
                    <Checkbox
                      label={role}
                      value={role}
                      key={role}
                      marginBottom="5px"
                      checked={assignedRoles[role]}
                      onChange={this.onChangeRoles}
                      testId="role-list"
                      disabled={!assignedRoles[role]}
                    />
                    <div style={{ fontSize: 10, marginBottom: 8 }}>
                      * chef role must be added through the chefs tab
                    </div>
                  </AuthorizedInteractable>
                </div>
              )
            case 'chef lead':
              return (
                <div>
                  <AuthorizedInteractable
                    key={role}
                    roles={['master admin', 'sales lead', 'chef lead']}
                  >
                    <Checkbox
                      label={role}
                      value={role}
                      key={role}
                      marginBottom="5px"
                      checked={assignedRoles[role]}
                      onChange={this.onChangeRoles}
                      testId="role-list"
                    />
                  </AuthorizedInteractable>
                </div>
              )
            case 'master admin':
              return (
                <div>
                  <AuthorizedInteractable key={role} roles={['master admin']}>
                    <Checkbox
                      label={role}
                      value={role}
                      key={role}
                      marginBottom="5px"
                      checked={assignedRoles[role]}
                      onChange={this.onChangeRoles}
                      testId="role-list"
                    />
                  </AuthorizedInteractable>
                </div>
              )
            default:
              return (
                <AuthorizedInteractable
                  key={role}
                  roles={['master admin', 'sales lead']}
                >
                  <Checkbox
                    label={role}
                    value={role}
                    key={role}
                    marginBottom="5px"
                    checked={assignedRoles[role]}
                    onChange={this.onChangeRoles}
                    testId="role-list"
                  />
                </AuthorizedInteractable>
              )
          }
        })}

        {errors.role && <div className="error-message"> {errors.role}</div>}
        <YSpacing height="20px" />
        <FlexContainer alignItems="center" justifyContent="flex-end">
          {/* { id &&
            <AuthorizedInteractable roles={ [ 'master admin', 'sales lead' ] }>
              <LinkText
                testId="delete"
                label="Delete"
                onClick={ this.delete }
                color={ colors.violet }
              />
            </AuthorizedInteractable>
          } */}
          <XSpacing width="20px" />
          <LinkText
            testId="cancel"
            label="Cancel"
            onClick={this.onHide}
            color={colors.gray400}
          />
          <XSpacing width="20px" />
          <Button testId="save-order" label="Save" onClick={this.onSave} />
        </FlexContainer>
      </Modal>
    )
  }
}

EditUserModal.propTypes = {
  currentHeadquarterId: PropTypes.string,
  errors: PropTypes.object,
  headquarters: PropTypes.arrayOf(PropTypes.object),
  hubspotUser: PropTypes.object,
  user: PropTypes.object,
  show: PropTypes.bool,

  clearError: PropTypes.func,
  clearErrors: PropTypes.func,
  close: PropTypes.func,
  delayedUpdateUser: PropTypes.func,
  // deleteUser: PropTypes.func,
  loadUserOptions: PropTypes.func,
  saveUser: PropTypes.func,
  loadHubspotUser: PropTypes.func,
  loadHubspotUsers: PropTypes.func,
  updateHubspotUserRef: PropTypes.func,
}

export default EditUserModal
