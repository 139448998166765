import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { AutocompleteInput } from '@containers/common/form'
import { CurrencyInput } from '@components/common/form'
import { AuthorizedInteractable } from '@containers/common/auth'

export class PreferenceProfile extends Component {
  state = {
    allDietaryPreferences: [],
    allCuisines: [],
    filters: {},
    preferences: {},
    conceptMap: {},
    favorites: [],
    handPickedRecommendations: [],
    recentlyRejectedConcepts: [],
    suggestedConcepts: [],
  }

  componentWillMount() {
    this.loadPreferenceProfile()
    this.loadSettings()
  }

  loadConcepts = async () => {
    const {
      conceptMap,
      favorites,
      handPickedRecommendations,
      recentlyRejectedConcepts,
      suggestedConcepts,
    } = this.state
    const missing = []
    const all = [
      ...favorites,
      ...handPickedRecommendations,
      ...recentlyRejectedConcepts,
      ...suggestedConcepts,
    ]
    all.forEach((id) => {
      if (!conceptMap[id]) {
        missing.push(id)
      }
    })

    if (missing.length > 0) {
      const concepts = await this.props.loadCachedConcepts(missing)
      concepts.forEach((concept) => {
        conceptMap[concept['Id']] = concept
      })
      this.setState({ conceptMap })
    }
  }

  loadSettings = async () => {
    const { allDietaryAllergens } = await this.props.loadMenuItemSettings()
    const { allCuisines } = await this.props.loadConceptsSettings()
    this.setState({
      allDietaryPreferences: allDietaryAllergens.map((t) => {
        return { label: t, value: t }
      }),
      allCuisines: allCuisines.map((t) => {
        return { label: t, value: t }
      }),
    })
  }

  loadPreferenceProfile = async () => {
    const { preferenceProfileId, loadPreferenceProfile } = this.props
    const profile = await loadPreferenceProfile(preferenceProfileId)
    if (profile) {
      this.setState({ ...profile }, () => {
        this.loadConcepts()
        this.loadSuggestions()
      })
    }
  }

  loadSuggestions = async () => {
    const { accountId, headquarterId, loadSuggestedConcepts } = this.props
    const { conceptMap } = this.state
    let suggestedConcepts =
      (await loadSuggestedConcepts(accountId, headquarterId)) || []
    if (suggestedConcepts.length > 0) {
      suggestedConcepts.forEach((c) => {
        conceptMap[c['Id']] = c
      })
      suggestedConcepts = suggestedConcepts.map((c) => c['Id'])
      this.setState({ conceptMap, suggestedConcepts })
    }
  }

  onSave = async () => {
    const { accountId, savePreferenceProfile } = this.props
    const profile = await savePreferenceProfile(accountId, this.state)
    if (profile) {
      this.setState({ ...profile })
      this.loadConcepts()
      this.loadSuggestions()
    }
  }

  onToggleTable = (id) => async () => {
    if (this.state[id]) {
      this.setState({ [id]: undefined })
    } else {
      this.setState({ [id]: true })
    }
  }

  renderConcept = (key, concept, id, index, editable) => {
    const {
      chefName,
      name,
      suggestionScore,
      tagsCuisine,
      tagsDietaryPreference,
      tagsDayPartList,
      metrics: {
        cuisines,
        foodGroups,
        popularity: { suggestionScore: popScore },
        rating: { suggestionScore: ratingScore },
      },
    } = concept
    const { suggestionScore: cuisineScore } = cuisines
    const { suggestionScore: foodGroupsScore } = foodGroups

    const showDetail = this.state[`${key}${index}Show`]

    return (
      <div
        key={index}
        className={`collapsible-table-container min-h-50 w-100 min-w-auto ${
          showDetail ? 'open' : 'closed'
        }`}
      >
        <div
          className="collapsible-table-headers-container"
          onClick={this.onToggleTable(`${key}${index}Show`)}
        >
          <div className="center-vertical">
            <div className={`table-toggle ${showDetail ? 'open' : 'closed'}`}>
              ▶
            </div>
            {index + 1}. {chefName} - {name}
            {editable && (
              <button
                className="remove-button"
                onClick={() => {
                  const { [key]: concepts } = this.state
                  this.setState({ [key]: concepts.filter((c) => c != id) })
                }}
              >
                ×
              </button>
            )}
          </div>
        </div>
        <table className="collapsible-table">
          <thead>
            <tr></tr>
          </thead>
          <tbody>
            <tr>
              <td> Attributes </td>
              <td> Day Part </td>
              <td> {tagsDayPartList} </td>
            </tr>
            <tr>
              <td />
              <td> Cuisine(s) </td>
              <td> {tagsCuisine.join(', ')} </td>
            </tr>
            <tr>
              <td />
              <td> Dietary Prefs(s) </td>
              <td> {tagsDietaryPreference.join(', ')} </td>
            </tr>
          </tbody>
          {suggestionScore && (
            <tbody>
              <tr>
                <td> Suggestion Score </td>
                <td> {suggestionScore.toFixed(2)} </td>
                <td />
              </tr>
              <tr>
                <td colSpan="3"> Metrics </td>
              </tr>
              <tr>
                <td colSpan="2"> Popularity Score </td>
                <td> {popScore.toFixed(2)} </td>
              </tr>
              <tr>
                <td colSpan="2"> Rating Score </td>
                <td> {ratingScore.toFixed(2)} </td>
              </tr>
              <tr>
                <td colSpan="2"> Cuisine Score </td>
                <td> {cuisineScore.toFixed(2)} </td>
              </tr>
              <tr>
                <td colSpan="2"> Food Groups Score </td>
                <td> {foodGroupsScore.toFixed(2)} </td>
              </tr>
              {Object.entries(foodGroups).map(([key, foodGroup], i) => {
                if (!(typeof foodGroup == 'object')) {
                  return null
                }
                const { score } = foodGroup

                return (
                  <tr key={i}>
                    <td />
                    <td> {key} entrees </td>
                    <td> {score.toFixed(2)} </td>
                  </tr>
                )
              })}
            </tbody>
          )}
        </table>
      </div>
    )
  }

  renderEditableConcepts = (stateKey, conceptIds) => {
    const { conceptMap } = this.state
    const { searchCachedConcepts } = this.props

    return (
      <div className="hand-picked-input">
        <AutocompleteInput
          displayAttribute="name"
          loaderFunction={searchCachedConcepts}
          placeholder={'Find concept by name or chef'}
          onSelect={(concept) => {
            const { [stateKey]: concepts, conceptMap } = this.state
            this.setState({
              [stateKey]: [...concepts, concept['Id']],
              conceptMap: { ...conceptMap, [concept['Id']]: concept },
            })
          }}
        />
        <p className="mt-20">{conceptIds.length == 0 && 'None Selected'}</p>
        {conceptIds.map((id, i) => {
          const concept = conceptMap[id]
          if (!concept) {
            return null
          }

          return this.renderConcept(stateKey, concept, id, i, true)
        })}
      </div>
    )
  }

  renderConcepts = (key, concepts) => {
    const { conceptMap } = this.state

    return (
      <div>
        {concepts.length == 0 && 'None'}
        {concepts.map((id, i) => {
          const concept = conceptMap[id]
          if (!concept) {
            return null
          }

          return this.renderConcept(key, concept, id, i, false)
        })}
      </div>
    )
  }

  renderPreferences = () => {
    const { preferences } = this.state

    return (
      <div>
        <h2> Preferences </h2>
        {Object.entries(preferences).map((preference, i) => {
          return this.renderDetail(preference, i)
        })}
      </div>
    )
  }

  renderFilters = () => {
    const { filters } = this.state

    return (
      <div className="mb-40">
        <h2> Filters </h2>
        <p> Suggested concepts must include the following dietary filters: </p>
        {Object.entries(filters).map((filter, i) => {
          return this.renderDetail(filter, i)
        })}
      </div>
    )
  }

  renderDetail = ([type, subType], i) => {
    let overallWeight
    if ('weight' in subType) {
      overallWeight = subType.weight
    }
    const showDetail = this.state[`${type}${i}Show`]
    let metrics = Object.entries(subType) || []
    metrics = metrics.filter(([key, value]) => key !== 'weight' && value !== 0)
    metrics = metrics.sort(([_A, metricA], [_B, metricB]) => {
      if (typeof metricA === 'object') {
        return metricB['score'] - metricA['score']
      } else {
        return metricB - metricA
      }
    })
    const count = metrics.length
    const half = Array.from(Array(Math.ceil(count / 2)).keys())
    const title = type
      .split('')
      .map((chr) => (/[A-Z]/.test(chr) ? ` ${chr.toLowerCase()}` : chr))
      .join('')

    return (
      <div
        key={i}
        className={`collapsible-table-container min-h-50 min-w-auto ${
          showDetail ? 'open' : 'closed'
        }`}
      >
        <div
          className="collapsible-table-headers-container"
          onClick={this.onToggleTable(`${type}${i}Show`)}
        >
          <div className="center-vertical">
            <div className={`table-toggle ${showDetail ? 'open' : 'closed'}`}>
              ▶
            </div>
            {title} {overallWeight !== undefined && ' - overall weight:'}
            {overallWeight !== undefined && (
              <div className="overall-weight">
                <CurrencyInput
                  testId={`pref-weight-${i}`}
                  value={overallWeight}
                  prefix={''}
                  onChange={(value) => {
                    const { preferences } = this.state
                    preferences[type]['weight'] = value
                    this.setState({ preferences: { ...preferences } })
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {count > 0 && (
          <table className="collapsible-table" key={i}>
            <thead>
              {overallWeight !== undefined ? (
                <tr>
                  <th> Type </th>
                  <th> Value </th>
                  <th> Type </th>
                  <th> Value </th>
                </tr>
              ) : (
                <tr>
                  <th> Type </th>
                  <th> Employees </th>
                  <th> Type </th>
                  <th> Employees </th>
                </tr>
              )}
            </thead>
            <tbody>
              {half.map((i) => {
                const [keyA, metricA] = metrics[i * 2] || [null, {}]
                const [keyB, metricB] = metrics[i * 2 + 1] || [null, {}]
                const valueA =
                  typeof metricA === 'object' ? metricA['value'] : metricA
                const valueB =
                  typeof metricB === 'object' ? metricB['value'] : metricB

                return (
                  <tr key={i}>
                    <td>{keyA || ''}</td>
                    <td>{valueA}</td>
                    <td>{keyB || ''}</td>
                    <td>{keyB && valueB}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  render() {
    const {
      favorites,
      handPickedRecommendations,
      recentlyRejectedConcepts,
      suggestedConcepts,
    } = this.state

    return (
      <div className="preferences-page">
        <div className="columns-container mt-20">
          <div className="column-single">
            <div className="mb-40">
              <h2> Suggested </h2>
              {this.renderConcepts('suggestedConcepts', suggestedConcepts)}
            </div>
            <div className="mb-40">
              <h2> Favorites </h2>
              {this.renderConcepts('favorites', favorites)}
            </div>
            <div className="mb-40">
              <h2> Recently Rejected </h2>
              {this.renderConcepts(
                'recentlyRejectedConcepts',
                recentlyRejectedConcepts,
              )}
            </div>
          </div>
          <div className="column-single">
            <div className="mb-40">
              <h2> Hand Picked </h2>
              {this.renderEditableConcepts(
                'handPickedRecommendations',
                handPickedRecommendations,
              )}
            </div>
            {this.renderFilters()}
            {this.renderPreferences()}
          </div>
        </div>
        <div className="flex-center mt-20">
          <AuthorizedInteractable
            roles={['master admin', 'sales rep', 'sales lead']}
          >
            <button className="button-primary" onClick={this.onSave}>
              Save
            </button>
          </AuthorizedInteractable>
        </div>
      </div>
    )
  }
}

PreferenceProfile.propTypes = {
  accountId: PropTypes.string,
  preferenceProfileId: PropTypes.string,
  headquarterId: PropTypes.number,

  loadCachedConcepts: PropTypes.func,
  loadConceptsSettings: PropTypes.func,
  loadMenuItemSettings: PropTypes.func,
  loadPreferenceProfile: PropTypes.func,
  loadSuggestedConcepts: PropTypes.func,
  savePreferenceProfile: PropTypes.func,
  searchCachedConcepts: PropTypes.func,
}

export default PreferenceProfile
