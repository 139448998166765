import { BaseService } from 'hungry-core2'

import actions from '@actions'

export default class EditUser extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditUserModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleEditUserModal(true))
  }
}
