export const SaveMLBMenuItem =
  ({ RestService, pRequestRemoveEmptyFields, UIService, HandleError }) =>
  async (item) => {
    item = pRequestRemoveEmptyFields(item)
    try {
      if (item.id) {
        await RestService.put(
          `/mlb/${item.mlbPartnerId}/items/${item.id}`,
          item,
        )
      } else {
        await RestService.post(`/mlb/${item.mlbPartnerId}/items`, item)
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      `Item ${item.name} successfully updated`,
    )

    return true
  }

export const SaveMLBKit =
  ({ RestService, pRequestRemoveEmptyFields, UIService, HandleError }) =>
  async (kit) => {
    kit = pRequestRemoveEmptyFields(kit)
    try {
      if (kit.id) {
        await RestService.put(`/mlb/${kit.mlbPartnerId}/kits/${kit.id}`, kit)
      } else {
        await RestService.post(`/mlb/${kit.mlbPartnerId}/kits`, kit)
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      `Pack ${kit.name} successfully updated`,
    )

    return true
  }

export const UploadMLBMenuItemImg =
  ({ RestService, UIService, HandleError }) =>
  async ({ mlbPartnerName, id, name, file }) => {
    const data = new FormData()
    data.append('file', file)

    let item
    try {
      item = await RestService.put(
        `/mlb/${mlbPartnerName}/items/${id}/upload_image`,
        data,
      )
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      `Image for ${name} successfully uploaded`,
    )

    return item
  }

export const UploadMLBMenuKitImg =
  ({ RestService, UIService, HandleError }) =>
  async ({ mlbPartnerName, id, name, file }) => {
    const data = new FormData()
    data.append('file', file)

    let kit
    try {
      kit = await RestService.put(
        `/mlb/${mlbPartnerName}/kits/${id}/upload_image`,
        data,
      )
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      `Image for ${name} successfully uploaded`,
    )

    return kit
  }

export const DeleteMLBMenuItem =
  ({ RestService, UIService, HandleError }) =>
  async ({ id, mlbPartnerId, name }) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete item "${name}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/mlb/${mlbPartnerId}/items/${id}`)
      } catch (error) {
        HandleError({ error })

        return false
      }

      return true
    }

    return false
  }

export const DeleteMLBKit =
  ({ RestService, UIService, HandleError }) =>
  async ({ id, mlbPartnerId, name }) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete kit "${name}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/mlb/${mlbPartnerId}/kits/${id}`)
      } catch (error) {
        HandleError({ error })

        return false
      }

      return true
    }

    return false
  }
