import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, {
  FilterContainer,
  PageSizeContainer,
  SortableHeader,
} from 'sematable'
import { Button } from 'react-bootstrap'

import {
  AccountingColumns,
  AccountingSections,
  Configs,
  InitVisibleSections,
} from './constants'

import {
  CheckboxCell,
  ChefPayouts,
  DateFilter,
  EditDateCell,
  EditTextCell,
  GenericCell,
  LoadingIndicator,
  PaymentReceivedButton,
  SaveButton,
  StatusDropdown,
} from '.'

import { CateringCaptainDropdown, ExportCSVButton } from '@containers/dashboard'

class AccountingTab extends Component {
  constructor(props) {
    super(props)

    const headers = { ...props.headers }
    const { select } = headers
    delete headers.select

    // build dictionary to lookup columns metadata
    this.columnsLookup = {}
    AccountingColumns.forEach((c) => (this.columnsLookup[c.key] = c))

    // calculate columns per section count for rendering purposes
    this.columnsCount = {}
    AccountingColumns.forEach((c) => {
      if (!/^hidden/.test(c.key)) {
        const group = c.sectionGroup
        this.columnsCount[group] = (this.columnsCount[group] || 0) + 1
      }
    })

    this.state = {
      dataRequestTimedOut: false,
      dataRequestCompleted: false,
      orderUpdates: {},
      pendingPaymentTypes: {},
      visibleColumns: this.calculateVisibleColumns(InitVisibleSections),
      visibleSections: InitVisibleSections,
      fromDate: '',
      toDate: '',
      ordersDashboardParams: '',
      headers,
      select,
    }
  }

  calculateVisibleColumns = (visibleSections) => {
    const { headers } = this.props
    const { columnsLookup } = this

    if (headers && visibleSections) {
      const visibleColumns = []
      Object.entries(headers).forEach((h) => {
        if (columnsLookup[h[0]]) {
          const isShown = visibleSections[columnsLookup[h[0]].sectionGroup]
          const isFiller = /^hidden/.test(columnsLookup[h[0]].key)
          if (isShown && !isFiller) {
            visibleColumns.push(h[1])
          } else if (!isShown && isFiller) {
            visibleColumns.push(h[1])
          }
        }
      })

      return [...visibleColumns]
    } else {
      return []
    }
  }

  timeoutInfiniteLoadData = async ({ fromDate, toDate }) => {
    const { timeoutInfiniteLoadData, hq } = this.props
    this.setState({ fromDate, toDate })
    timeoutInfiniteLoadData({
      fromDate,
      toDate,
      hq,
    })
  }

  updateOrder = (id, attribute, value, parser) => {
    let { orderUpdates } = this.state
    const orderUpdate = orderUpdates[id] || { id }
    orderUpdate[attribute] = (parser && parser(value)) || value
    orderUpdates = { ...orderUpdates, [id]: orderUpdate }
    this.setState({ orderUpdates })
  }

  onClearOrderUpdates = () => {
    this.setState({ orderUpdates: {} })
  }

  onDeleteOrderUpdate = (orderId) => {
    const { orderUpdates } = this.state
    delete orderUpdates[orderId]
    this.setState({ orderUpdates: { ...orderUpdates } })
  }

  onSaveOrderUpdate = async ({ orderId, orderDate }) => {
    const { toDate, fromDate, orderUpdates } = this.state
    const { dashboardEditOrder } = this.props
    const orderUpdate = orderUpdates[orderId]
    await dashboardEditOrder({
      deleteOrderUpdate: this.onDeleteOrderUpdate,
      orderUpdate,
      orderDate,
    })
    this.timeoutInfiniteLoadData({ toDate, fromDate })
  }

  onSaveAllOrderUpdates = async () => {
    const { toDate, fromDate, orderUpdates } = this.state
    const { data, dashboardEditOrders } = this.props
    const updatedIDs = Object.keys(orderUpdates)
    const orderDates = []

    updatedIDs.map((id) => {
      const matchingData = data.find((d) => d.id === id)
      orderDates.push({
        id: matchingData.id,
        date: matchingData.clientSetUpTime,
      })
    })

    await dashboardEditOrders({
      deleteOrderUpdate: this.onDeleteOrderUpdate,
      orderUpdates,
      orderDates,
    })
    this.timeoutInfiniteLoadData({ toDate, fromDate })
  }

  onToggleVisibleSection = (section) => () => {
    const visibleSections = { ...this.state.visibleSections }
    visibleSections[section] = !visibleSections[section]

    this.setState({
      visibleSections,
      visibleColumns: this.calculateVisibleColumns(visibleSections),
    })
  }

  renderCellMapper = (type, order, attribute, className, key) => {
    const { orderUpdates } = this.state

    switch (type) {
      case 'editDate': {
        return (
          <EditDateCell
            order={order}
            attribute={attribute}
            className={className}
            key={key}
            orderUpdates={orderUpdates}
            updateOrder={this.updateOrder}
          />
        )
      }
      case 'editText':
        return (
          <EditTextCell
            order={order}
            attribute={attribute}
            className={className}
            key={key}
            orderUpdates={orderUpdates}
            updateOrder={this.updateOrder}
            columnsLookup={this.columnsLookup}
          />
        )
      case 'editCheckbox':
        return (
          <CheckboxCell
            order={order}
            attribute={attribute}
            className={className}
            key={key}
            orderUpdates={orderUpdates}
            updateOrder={this.updateOrder}
            columnsLookup={this.columnsLookup}
          />
        )
      case 'editPayment': {
        const { updatePaymentReceived } = this.props
        if (order.disableEditPayment) {
          return
        }

        return (
          <PaymentReceivedButton
            order={order}
            attribute={attribute}
            className={className}
            key={key}
            orderUpdates={orderUpdates}
            updateOrder={this.updateOrder}
            updatePaymentReceived={updatePaymentReceived}
          />
        )
      }
      case 'editStatus':
        if (order.disableEditStatus) {
          return (
            <GenericCell
              order={order}
              attribute={attribute}
              className={className}
              key={key}
            />
          )
        }

        return (
          <StatusDropdown
            order={order}
            attribute={attribute}
            className={className}
            key={key}
            orderUpdates={orderUpdates}
            updateOrder={this.updateOrder}
          />
        )
      case 'editCateringCaptain': {
        return (
          <CateringCaptainDropdown
            order={order}
            attribute={attribute}
            key={key}
            className={className}
            orderUpdates={orderUpdates}
            updateOrder={this.updateOrder}
          />
        )
      }
      case 'renderEachChef': {
        return <ChefPayouts order={order} attribute={attribute} key={key} />
      }
      default:
        return (
          <GenericCell
            order={order}
            attribute={attribute}
            className={className}
            key={key}
          />
        )
    }
  }

  renderRow = (order) => {
    const { orderUpdates, visibleColumns } = this.state
    const { columnsLookup } = this
    const visibleHeaders = visibleColumns.map((c) => columnsLookup[c.sortKey])
    const orderUpdate = orderUpdates[order.id]

    return (
      <tr key={order.id} className="order-number-container">
        <td className="float-order-items">
          <p className="bold">{order.orderNumber}</p>
          <p>{order.eventDate}</p>
          <p>{order.client}</p>
        </td>
        {visibleHeaders.map((c, i) =>
          this.renderCellMapper(
            c.render,
            order,
            c.key,
            c.className,
            `${order.id}${i}`,
          ),
        )}
        <td>
          {orderUpdate && (
            <SaveButton
              onDeleteOrderUpdate={this.onDeleteOrderUpdate}
              onSaveOrderUpdate={this.onSaveOrderUpdate}
              orderDate={order.clientSetUpTime}
              orderId={order.id}
            />
          )}
        </td>
      </tr>
    )
  }

  renderSectionButton = (section) => {
    return (
      <Button
        bsStyle="primary"
        className="dashboard-section-button"
        onClick={this.onToggleVisibleSection(section)}
      >
        {`${section} Information`}
      </Button>
    )
  }

  render() {
    const { data, isLoading, pCSVOrderDashboardItems } = this.props

    const { orderUpdates, visibleColumns, visibleSections } = this.state
    const { columnsCount } = this
    const orderUpdatesExist = Object.values(orderUpdates).length > 0

    return (
      <div>
        {/* Search Header */}
        <div className="table-filters">
          <div className="table-filters__inner">
            <DateFilter
              isDisabled={isLoading}
              reloadData={({ fromDate, toDate }) =>
                this.timeoutInfiniteLoadData({ fromDate, toDate })
              }
            />
            <FilterContainer className="filter" tableName="AccountingTab" />
            <div className="page-container">
              <PageSizeContainer
                className="page-selector"
                tableName="AccountingTab"
              />
            </div>
            <ExportCSVButton
              data={data}
              columns={AccountingColumns}
              filename="hungry-dashboard-extract.csv"
              pCSVItems={(orders) => pCSVOrderDashboardItems(orders)}
            />
            {orderUpdatesExist && (
              <div className="table-filters__inner">
                <Button
                  bsStyle="primary"
                  className="dashboard-clear-updates-button"
                  onClick={this.onSaveAllOrderUpdates}
                >
                  Save All
                </Button>
                <Button
                  bsStyle="warning"
                  className="dashboard-clear-updates-button"
                  onClick={this.onClearOrderUpdates}
                >
                  Clear All
                </Button>
              </div>
            )}
          </div>
        </div>

        {/* Dashboard Body */}
        <div className="table-responsive">
          <table className="table table-sm table-striped table-hover">
            <thead>
              <tr>
                <th></th>
                {AccountingSections.map((s) => (
                  <th
                    className="section-button-container"
                    key={s}
                    colSpan={visibleSections[s] ? columnsCount[s] : 1}
                  >
                    {this.renderSectionButton(s)}
                  </th>
                ))}
              </tr>
              <tr className="table-headers">
                <th className="float-order">Order Number</th>
                {visibleColumns.map((column, i) =>
                  column.name ? (
                    <SortableHeader key={i} {...column} />
                  ) : (
                    <th key={i} style={{ 'background-color': 'lightblue' }} />
                  ),
                )}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="table-content">
              {isLoading && (
                <tr>
                  <td colSpan="25">
                    <LoadingIndicator />
                  </td>
                </tr>
              )}

              {!isLoading && data.length === 0 ? (
                <tr>
                  <td colSpan="8">
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '1.2em',
                        color: 'rgba(0,0,0,0.2)',
                        padding: '20px',
                        backgroundColor: 'rgba(0,0,0,0.01)',
                      }}
                    >
                      No data found.
                    </div>
                  </td>
                </tr>
              ) : (
                data.map((order) => this.renderRow(order))
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

AccountingTab.propTypes = {
  data: PropTypes.object,
  headers: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  hq: PropTypes.number,

  dashboardEditOrder: PropTypes.func,
  dashboardEditOrders: PropTypes.func,
  timeoutInfiniteLoadData: PropTypes.func,
  updatePaymentReceived: PropTypes.func,
  pCSVOrderDashboardItems: PropTypes.func,
}

export default sematable(
  'AccountingTab',
  AccountingTab,
  AccountingColumns,
  Configs,
)
