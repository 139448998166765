import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

class SaveButton extends React.Component {
  render() {
    const { onDeleteOrderUpdate, onSaveOrderUpdate, orderDate, orderId } =
      this.props

    return (
      <div className="save-single-field-buttons">
        <Button
          bsSize="small"
          bsStyle="primary"
          className="dashboard-save-button"
          onClick={() => onSaveOrderUpdate({ orderId, orderDate })}
        >
          Save Changes
        </Button>
        <Button
          bsSize="small"
          className="dashboard-save-button"
          onClick={() => onDeleteOrderUpdate(orderId)}
        >
          Discard Changes
        </Button>
      </div>
    )
  }
}

SaveButton.propTypes = {
  orderId: PropTypes.string,
  orderDate: PropTypes.string,

  onDeleteOrderUpdate: PropTypes.func,
  onSaveOrderUpdate: PropTypes.func,
}

export default SaveButton
