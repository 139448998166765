import React, { Component } from 'react'
import PropTypes from 'prop-types'
import services from '@services'
const { UIService } = services
import { CurrencyInput, QuantityInput } from '@components/common/form'
import { DietaryPrefSelectInput } from '@containers/common/form'
import FlexContainer from '@components/common/FlexContainer'
import { CloseX } from '@res/styledComponents/index'

const RefreshAttributes = [
  'id',
  'chefId',
  'chefPrice',
  'description',
  'internalNotes',
  'ingredients',
  'name',
  'packagingId',
  'packagingName',
  'price',
  'quantity',
  'servingsPerPkg',
  'servingUtensilId',
  'servingUtensilName',
  'tagsDietaryPreferenceList',
  'total',
  'itemType',
]

class CustomOrderItem extends Component {
  state = {
    chefPrice: 0,
    description: '',
    internalNotes: '',
    ingredients: '',
    name: '',
    packagingId: '',
    packagingName: '',
    price: 0,
    quantity: 0,
    servingsPerPkg: '',
    servingUtensilId: '',
    servingUtensilName: '',
    tagsDietaryPreferenceList: '',
    addedDietaryTag: false,
    total: undefined,

    isExpanded: true,

    // unmutated just to keep track
    itemType: 'MenuItem',
    id: undefined,
    chefId: undefined,
  }

  componentDidMount() {
    ;(async () => {
      const { loadMenuItemSettings, orderItem } = this.props
      const { allDefaultPackagings = [], allServingUtensils = [] } =
        await loadMenuItemSettings()

      const newState = {
        allDefaultPackagings,
        allServingUtensils,
      }
      RefreshAttributes.forEach((k) => {
        newState[k] = orderItem[k]
      })

      if (allDefaultPackagings[0]) {
        newState.packagingId = allDefaultPackagings[0].id
      }
      if (allServingUtensils[0]) {
        newState.servingUtensilId = allServingUtensils[0].id
      }

      this.didEditChefPrice = false
      this.didEditPrice = false

      this.setState(newState)
    })()
  }

  componentWillReceiveProps(nextProps) {
    const { orderItem } = nextProps
    if (orderItem !== this.props.orderItem) {
      const newState = {}
      RefreshAttributes.forEach((k) => {
        newState[k] = orderItem[k]
      })
      this.setState(newState)
    }
  }

  updateItem = () => {
    const orderItem = {
      ...this.state,
      displayOrder: this.props.orderItem.displayOrder,
    }

    this.props.onEdit(orderItem)
  }

  onChange = (attribute) => (e) => {
    const { value } = e.target
    this.setState({ [attribute]: value }, this.updateItem)
  }

  onInputChefPrice = (chefPrice) => {
    this.didEditChefPrice = true
    if (this.didEditPrice) {
      this.setState({ chefPrice }, this.updateItem)
    } else {
      this.setState(
        {
          chefPrice,
          price: this.props.calculatePrice(chefPrice),
        },
        this.updateItem,
      )
    }
  }

  onInputPrice = (price) => {
    this.didEditPrice = true
    if (this.didEditChefPrice) {
      this.setState({ price }, this.updateItem)
    } else {
      this.setState(
        {
          price,
          chefPrice: this.props.calculateChefPrice(price),
        },
        this.updateItem,
      )
    }
  }

  onInputDietaryTags = (tagsDietaryPreferenceList) => {
    if (
      !this.state.tagsDietaryPreferenceList ||
      tagsDietaryPreferenceList.length >
        this.state.tagsDietaryPreferenceList.length
    ) {
      UIService.FlashMessage.displayWarningMessage(
        'Double check with your Chef Network Manager if the correct dietary tags are selected',
      )
    }
    this.setState({ tagsDietaryPreferenceList }, this.updateItem)
  }

  onInputQuantity = (quantity) => {
    this.setState({ quantity }, this.updateItem)
  }

  onToggleExpand = () => {
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  renderReOrder = (change) => {
    const { isFirst, isLast, orderItem, onReorder } = this.props

    let symbol
    if (change > 0 && !isLast) {
      symbol = '▼'
    } else if (change < 0 && !isFirst) {
      symbol = '▲'
    }

    if (symbol) {
      return (
        <span
          className="pointer w-2"
          onClick={() => onReorder(orderItem, change)}
        >
          {' '}
          {symbol}{' '}
        </span>
      )
    }
  }

  render() {
    const { orderItem, onRemove } = this.props
    const {
      allDefaultPackagings,
      allServingUtensils,
      chefPrice,
      description,
      ingredients,
      isExpanded,
      name,
      packagingId,
      price,
      quantity,
      servingUtensilId,
      servingsPerPkg,
      tagsDietaryPreferenceList,
      internalNotes,
    } = this.state
    let { total } = this.state
    total = total || quantity * price
    const margin =
      price === 0.0 ? 0 : Math.trunc(((price - chefPrice) / price) * 100)

    return (
      <tbody>
        <tr className="">
          <td className="flex">
            <FlexContainer flexDirection="row" width="10px">
              <CloseX onClick={() => onRemove(orderItem)}>✕</CloseX>

              <div className="flex flex-col mr-5 justify-center">
                {this.renderReOrder(-1)}
                {this.renderReOrder(1)}
              </div>
            </FlexContainer>
          </td>
          <td className="align-middle text-right">
            <input
              className="border h-8 regular text-base text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
              type="text"
              style={{ width: '130px' }}
              value={name}
              placeholder="Custom Name"
              onInput={this.onChange('name')}
            />
          </td>
          <td />
          <td className="align-middle text-right">
            <QuantityInput
              className="text-right border w-20 h-8 regular text-base text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
              value={quantity}
              onChange={this.onInputQuantity}
            />
          </td>
          <td className="align-middle text-right">
            <CurrencyInput
              className="text-right border w-20 h-8 regular text-base text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
              value={chefPrice}
              onChange={this.onInputChefPrice}
            />
          </td>
          <td className="align-middle text-right">
            <CurrencyInput
              className="text-right border w-20 h-8 regular text-base text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
              value={price}
              onChange={this.onInputPrice}
            />
          </td>
          <td className="align-middle text-right regular text-base text-hungryGray">
            {margin}%
          </td>
          <td className="align-middle text-right">
            <CurrencyInput
              className="text-right border w-20 h-8 regular text-base text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
              disabled
              value={total}
            />
          </td>
        </tr>

        {isExpanded ? (
          <tr className="bg-indigo-100">
            <td colSpan="8">
              <div className="flex">
                <div className="mr-6">
                  <div className="flex flex-col mt-3 w-64">
                    <p className="mb-1 text-hungryGray bold uppercase text-sm">
                      {' '}
                      Internal Notes{' '}
                    </p>
                    <textarea
                      className="h-20 border w-100 regular text-lg text-hungryGray p-2 bg-white rounded border-gray-400"
                      value={internalNotes}
                      onInput={this.onChange('internalNotes')}
                    />
                  </div>
                  <div className="flex flex-col mt-3 w-64">
                    <p className="mb-1 text-hungryGray bold uppercase text-sm">
                      {' '}
                      Menu Description{' '}
                    </p>
                    <textarea
                      className="h-20 border w-100 regular text-lg text-hungryGray p-2 bg-white rounded border-gray-400"
                      value={description}
                      onInput={this.onChange('description')}
                    />
                  </div>
                  <div className="flex flex-col mt-3 w-64">
                    <p className="mb-1 text-hungryGray bold uppercase text-sm">
                      {' '}
                      Ingredients{' '}
                    </p>
                    <textarea
                      className="h-20 border w-100 regular text-lg text-hungryGray p-2 bg-white rounded border-gray-400"
                      value={ingredients}
                      onInput={this.onChange('ingredients')}
                    />
                  </div>
                  <button
                    className="add-button my-3"
                    onClick={this.onToggleExpand}
                  >
                    {' '}
                    Collapse New Item Details{' '}
                  </button>
                </div>
                <div className="mr-6">
                  <DietaryPrefSelectInput
                    tagsDietaryPreferenceList={tagsDietaryPreferenceList}
                    onChange={this.onInputDietaryTags}
                  />
                  <div className="mt-3">
                    <p className="mb-1 text-hungryGray bold uppercase text-sm">
                      Select Packaging
                    </p>
                    <select
                      className="border w-64 regular text-lg text-hungryGray p-2 bg-white rounded border-gray-400"
                      value={packagingId}
                      onBlur={this.onBlur}
                      onChange={this.onChange('packagingId')}
                      onFocus={this.onFocus}
                    >
                      {allDefaultPackagings &&
                        allDefaultPackagings.map((p) => {
                          const { id, name } = p

                          return (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                  <div className="flex flex-col mt-3 w-64">
                    <p className="mb-1 text-hungryGray bold uppercase text-sm">
                      {' '}
                      Servings per package{' '}
                    </p>
                    <input
                      className="text-right border h-8 regular text-lg text-hungryGray p-2 bg-white rounded border-gray-400"
                      type="number"
                      value={servingsPerPkg}
                      placeholder="10"
                      onInput={this.onChange('servingsPerPkg')}
                    />
                  </div>
                  <div className="flex flex-col mt-3 w-64">
                    <p className="mb-1 text-hungryGray bold uppercase text-sm">
                      {' '}
                      Serving Utensil{' '}
                    </p>
                    <select
                      className="border w-64 regular text-lg text-hungryGray p-2 bg-white rounded border-gray-400"
                      value={servingUtensilId}
                      onBlur={this.onBlur}
                      onChange={this.onChange('servingUtensilId')}
                      onFocus={this.onFocus}
                    >
                      {allServingUtensils &&
                        allServingUtensils.map((s) => {
                          const { id, name } = s

                          return (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        ) : (
          <tr>
            <td />
            <td colSpan="7">
              <button className="add-button" onClick={this.onToggleExpand}>
                {' '}
                Expand Custom Item Details
              </button>
            </td>
          </tr>
        )}
      </tbody>
    )
  }
}

CustomOrderItem.propTypes = {
  isFirst: PropTypes.boolean,
  isLast: PropTypes.boolean,
  orderItem: PropTypes.object,

  calculateChefPrice: PropTypes.func,
  calculatePrice: PropTypes.func,
  loadMenuItemSettings: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onReorder: PropTypes.func,
}

export default CustomOrderItem
