import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import SidebarSearchAndInfo from '@components/sidebar/SidebarSearchAndInfo'
import SidebarListItem from '@components/sidebar/SidebarListItem'
import FlexContainer from '@components/common/FlexContainer'
import SidebarDateFilter from '@components/sidebar/SidebarDateFilter'
import SidebarMealTypeDropdown from '@components/sidebar/SidebarMealTypeDropdown'
import SidebarSalesRepFilter from '@components/sidebar/SidebarSalesRepFilter'
import { LoadingIndicator } from '@components/common'
import Moment from 'moment-timezone'

export class OrderableMaster extends Component {
  state = {
    page: 1,
    search: '',
    show: false,
    orderType: '',
    dateRange: {
      fromDate: undefined,
      toDate: undefined,
    },
    accountExecutiveId: undefined,
    salesReps: [],
    isLoading: false,
  }
  searchTimer = undefined

  componentDidMount() {
    this.props.clearOrderables()
    this.loadOrderables()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filter !== this.props.filter) {
      this.setState({ page: 1, show: false }, () => {
        this.props.clearOrderables()
        this.loadOrderables()
      })
    }
  }

  doAfterTimeout = (func, timeout = 750) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer)
    }
    this.searchTimer = undefined
    this.searchTimer = setTimeout(func, timeout)
  }

  loadOrderables = async () => {
    const { filter, loadOrderables, setLastLoadOrderablesParams } = this.props
    const { page, search, dateRange, accountExecutiveId, orderType } =
      this.state
    const { fromDate, toDate } = dateRange
    const params = {
      page,
      search,
      status: filter,
      fromDate,
      toDate,
      accountExecutiveId,
      orderType,
    }

    setLastLoadOrderablesParams(params)
    this.setState({ isLoading: true })
    await loadOrderables(params)
    this.setState({ show: true, isLoading: false })
  }

  onSelectOrderable = (orderableId) => {
    this.setState({ activeOrderableId: orderableId })
    this.props.selectOrderable(orderableId)
  }

  onSearch = (e) => {
    const search = e.target.value

    this.props.clearOrderables()
    this.setState({ page: 1, search, show: false })
    this.doAfterTimeout(this.loadOrderables)
  }

  onSearchMealType = (e) => {
    const orderType = e.target.value
    this.props.clearOrderables()
    this.setState({ page: 1, orderType, show: false })
    this.doAfterTimeout(this.loadOrderables)
  }

  onSelectSalesRep = (e) => {
    const accountExecutiveId = e
    this.props.clearOrderables()
    this.setState({ page: 1, accountExecutiveId, show: false })
    this.doAfterTimeout(this.loadOrderables)
  }

  showOrders = () => {
    this.setState({
      page: 1,
      show: true,
    })
  }

  setDateRangeFilter = (newFilters) => {
    const { dateRange } = this.state
    this.setState({
      page: 1,
      show: false,
      dateRange: { ...dateRange, ...newFilters },
    })
    this.doAfterTimeout(this.loadOrderables)
  }

  render() {
    const { orderables, loadAccountExecutives } = this.props
    const { activeOrderableId, show, isLoading } = this.state
    const { page } = this.state

    return (
      <div>
        <SidebarSearchAndInfo
          label="Search Orders"
          placeholder="Order #, Client, Rep"
          onChange={this.onSearch}
        />
        <SidebarDateFilter
          label="From Date"
          onChange={(e) => {
            this.setDateRangeFilter({
              fromDate: Moment(e.target.value).startOf('day'),
            })
          }}
        />
        <SidebarDateFilter
          label="To Date"
          onChange={(e) => {
            this.setDateRangeFilter({
              toDate: Moment(e.target.value).endOf('day'),
            })
          }}
        />
        <SidebarMealTypeDropdown
          label="Filter By Meal Type"
          onChange={this.onSearchMealType}
        />
        <SidebarSalesRepFilter
          label="Filter by Sales Rep"
          loaderFunction={loadAccountExecutives()}
          onSelect={this.onSelectSalesRep}
        />
        <SearchButton onClick={this.showOrders} id="sidebar-search-button">
          Search
        </SearchButton>

        {isLoading && (
          <SidebarListItem>
            <LoadingIndicator />
          </SidebarListItem>
        )}
        {show &&
          orderables.map((o) => {
            const selected = o.id === activeOrderableId

            return (
              <SidebarListItem
                key={o.id}
                isSelected={selected}
                onClick={() => this.onSelectOrderable(o.id)}
              >
                <ItemDetails>
                  <FlexContainer justifyContent="space-between">
                    <p className="extrabold">{o.orderNumber}</p>
                    <p className="extrabold">${o.total}</p>
                  </FlexContainer>
                  <p>
                    <span className="bold">Date:</span> {o.dateStr}
                  </p>
                  <p>
                    <span className="bold">Chef:</span> {o.chefName}
                  </p>
                  <p>
                    <span className="bold">Account:</span> {o.accountName}
                  </p>
                </ItemDetails>
              </SidebarListItem>
            )
          })}
        {show && orderables.length === 0 && (
          <SidebarListItem>
            <ItemDetails>No Orders Found</ItemDetails>
          </SidebarListItem>
        )}
        {show && (
          <div className="flex">
            {orderables && page > 1 && (
              <ArrowButton
                className="page-arrow"
                onClick={() =>
                  this.setState(
                    { page: this.state.page - 1 },
                    this.loadOrderables,
                  )
                }
              >
                ◄
              </ArrowButton>
            )}
            <Page className="pre page-number">Page {page}</Page>
            {orderables && orderables.length > 0 && (
              <ArrowButton
                className="page-arrow"
                onClick={() =>
                  this.setState(
                    { page: this.state.page + 1 },
                    this.loadOrderables,
                  )
                }
              >
                ►
              </ArrowButton>
            )}
          </div>
        )}
      </div>
    )
  }
}

export const ItemDetails = styled.div`
  color: white;
  font-size: 14px;
  p {
    color: white;
  }
  .extrabold {
    font-family: 'extrabold';
  }
`
const SearchButton = styled.button`
  background: #fff;
  color: #ff624d;
  font-family: 'bold';
  text-transform: uppercase;
  font-size: 14px;
  margin: 0 20px;
  padding: 10px;
  border-radius: 4px;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: 0.2s ease-in-out all;
  &:hover {
    box-shadow:
      0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
`
const Page = styled.div`
  padding: 0 20px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  padding: 6px 10px;
  color: white;
  // background: #fff;
  //border: 1px solid #fff;
  border-radius: 4px;
  font-size: 15px;
  ::placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-family: 'regular';
  }
`

const ArrowButton = styled.button`
  padding: 0 20px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  padding: 6px 10px;
  color: white;
  // background: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 15px;
  ::placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-family: 'regular';
  }
`

OrderableMaster.propTypes = {
  filter: PropTypes.string,
  orderables: PropTypes.arrayOf(PropTypes.object),
  accountExecutiveId: PropTypes.object,

  OrderableItem: PropTypes.func,

  clearOrderables: PropTypes.func,
  loadOrderables: PropTypes.func,
  selectOrderable: PropTypes.func,
  loadAccountExecutives: PropTypes.func,
  setLastLoadOrderablesParams: PropTypes.func,
}

export default OrderableMaster
