import { BaseService } from 'hungry-core2'
import actions from '@actions'

export class MenuCardService extends BaseService {
  setMenuCards(json) {
    this.dispatch(actions.setMenuCards(json))
  }

  loadFilters(filters) {
    this.dispatch(actions.loadMenuCardFilters(filters))
  }

  setFilters({ average_price_per_person, chef_rating, cuisine_types }) {
    this.dispatch(
      actions.setMenuCardFilters({
        average_price_per_person,
        chef_rating,
        cuisine_types,
      }),
    )
  }

  setSort({ sortProp, sortOrder }) {
    this.dispatch(actions.setMenuCardSort({ sortProp, sortOrder }))
  }

  setSearch({ date, headCount, zipcode }) {
    this.dispatch(actions.setMenuCardSearch({ date, headCount, zipcode }))
  }

  setSearchToCartSearch() {
    const { search } = this.getState().cart
    this.dispatch(actions.setMenuCardSearch(search))
  }

  clearSearch() {
    this.dispatch(actions.clearMenuCardSearch())
  }
}
