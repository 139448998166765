import React from 'react'
import PropTypes from 'prop-types'

const ButtonWithLink = (props) => {
  const { label, link, width, padding } = props

  return (
    <a
      className="primary-button"
      href={link}
      style={{ width, paddingLeft: padding, paddingRight: padding }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {label}
    </a>
  )
}

ButtonWithLink.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
  padding: PropTypes.string,
  width: PropTypes.string,

  onClick: PropTypes.func,
}

ButtonWithLink.defaultProps = {
  padding: '30px',
}

export default ButtonWithLink
