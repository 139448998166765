export default class RegexValidator {
  type = 'regex'

  constructor(key, pattern, { map, message } = {}) {
    this.key = key
    this.pattern = pattern
    this.map = map
    this.message = message
  }

  validate = (data) => {
    const value = data[this.key]
    if (value && !this.pattern.test(value)) {
      return this.message ? this.message : 'invalid format'
    }

    return null
  }
}
