import creditCardType from 'credit-card-type'

String.prototype.splice = function (start, delCount = 0, subStr = '') {
  return this.slice(0, start) + subStr + this.slice(start + delCount)
}

export const pFormatCreditCard = (cardNumber) => {
  cardNumber = cardNumber.replace(/[^0-9]/g, '')
  const cards = creditCardType(cardNumber)
  if (cards.length === 0) {
    return {
      cardNumber,
      formattedCardNumber: cardNumber,
    }
  }

  const card = cards[0]
  const cardNumberMinLength = card.lengths[0]
  const maxLength = card.lengths[card.lengths.length - 1]
  cardNumber = cardNumber.slice(0, maxLength)
  let formattedCardNumber = cardNumber

  card.gaps.reverse().forEach((gap) => {
    if (formattedCardNumber.length > gap) {
      formattedCardNumber = formattedCardNumber.splice(gap, 0, ' ')
    }
  })

  return {
    cardNumber,
    cardNumberMinLength,
    cardType: card.type,
    cvvLength: card.code.size,
    formattedCardNumber,
  }
}

export const pFormatMMYY = (date = '') => {
  date = date.replace(/[^0-9]/g, '')
  if (date.length === 0) {
    return ''
  }

  if (date[0] !== '0' && date[0] !== '1') {
    date = '0' + date
  }
  date = date.slice(0, 4)
  if (date.length > 2) {
    date = date.splice(2, 0, '/')
  }

  return date
}
