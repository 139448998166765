import { TOGGLE_LOADING_INDICATOR } from '@actions/ui/loadingIndicator'

const initialState = {
  on: false,
}

export const loadingIndicator = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOADING_INDICATOR:
      return {
        on: action.on,
      }
    default:
      return state
  }
}
