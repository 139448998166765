import { createStore as createCoreStore } from 'hungry-core2'
import { applyMiddleware } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import createHistory from 'history/createBrowserHistory'
import { autoRehydrate, persistStore } from 'redux-persist'

import reducers from '@reducers'

export const history = createHistory()

const store = createCoreStore({
  reducers,
  middleware: [autoRehydrate(), applyMiddleware(routerMiddleware(history))],
})
persistStore(store, { whitelist: ['user'] })

export default store

export const createStore = (
  { initialState, middleware, throwaway } = ({}, [], false),
) => {
  return createCoreStore({ reducers, initialState, middleware, throwaway })
}
