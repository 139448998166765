import Moment from 'moment-timezone'
import { timeSlotTimesFromWeek } from '../../../utils'

export const pRequestTimeSlotWeek = (date) => {
  return Moment(date).startOf('isoWeek').format('MM/DD/YYYY')
}

export const pRequestTimeSlotMonth = (date) => {
  return Moment(date).format('MM/YYYY')
}

export const pRequestTimeWeeks = (data) => {
  const weeks = []
  const d = Moment(data)
  const month = d.startOf('month')
  const startOfWeek = month.startOf('isoWeek')
  weeks.push(startOfWeek.format('MM/DD/YYYY'))

  for (let i = 1; i <= 5; i++) {
    const nextWeek = startOfWeek.add(7, 'days')
    weeks.push(nextWeek.format('MM/DD/YYYY'))
  }

  return weeks
}

export const pRequestUpdateTimeSlot = ({ chefId, start, end }) => {
  // update time slot
  const startDate = Moment(start)
  const endDate = Moment(end)
  const day = startDate.isoWeekday()
  start = startDate.hour() + startDate.minute() / 60.0
  end = endDate.hour() + endDate.minute() / 60.0
  const month = startDate.format('MM/YYYY')
  const week = startDate.startOf('isoWeek').format('MM/DD/YYYY')

  return { time_slot: { chef_id: chefId, month, week, day, start, end } }
}

export const pResponseAllTimeSlots = (weeks, json, locale) => {
  const mapOfRemoved = {}
  const mapOfCustomSlots = {}
  const timeSlots = []
  const recurringSlotInfo = []
  const recurringSlots = []
  for (const t of json) {
    if (t === null) {
      continue
    }
    if (t.isRecurring) {
      recurringSlots.push(t)
    } else if (t.isRemovedSlot) {
      if (!mapOfRemoved[t.week]) {
        mapOfRemoved[t.week] = {}
      }
      mapOfRemoved[t.week][t.day] = true
    } else {
      timeSlots.push(_parseTimeSlot(t, locale))
      if (!mapOfCustomSlots[t.week]) {
        mapOfCustomSlots[t.week] = {}
      }
      mapOfCustomSlots[t.week][t.day] = true
    }
  }

  for (const week of weeks) {
    const weekTime = Moment(week)
    for (const slot of recurringSlots) {
      const endDate = slot.endDate && Moment(slot.endDate)
      for (const day of slot.recurringDaysOfWeek) {
        //continue if day in week has been removed OR Custom timeslot
        if (
          (mapOfRemoved[week] && mapOfRemoved[week][day]) ||
          (mapOfCustomSlots[week] && mapOfCustomSlots[week][day])
        ) {
          continue
        }
        const t = {}
        const __ = Object.assign(t, weekTime)
        const dayInWeek = Moment(t).add(day - 1, 'days')
        //break if after the endDate in recurring slot
        if (
          endDate &&
          endDate.format('MM/DD/YYYY') !== '12/31/0000' &&
          dayInWeek.isAfter(endDate)
        ) {
          break
        }
        const newSlot = {}
        const _ = Object.assign(newSlot, slot)
        newSlot.week = week
        newSlot.day = day
        recurringSlotInfo.push(newSlot)
      }
    }
  }

  return { timeSlots, recurringSlotInfo }
}

export const pRequestTimeSlot = (timeSlot) => {
  timeSlot.startDate = Moment(timeSlot.startDate).format('MM/DD/YY')
  // timeSlot.endDate = timeSlot.endDate ? Moment( timeSlot.endDate ).format( 'MM/DD/YY' ) : null
  timeSlot.startTime = Moment(timeSlot.startTime).format('hh:mma')
  timeSlot.endTime = Moment(timeSlot.endTime).format('hh:mma')

  return timeSlot
}

export const pResponseTimeSlots = (json) => {
  return json.map(_parseTimeSlot)
}

export const pResponseTimeSlot = (json) => {
  return _parseTimeSlot(json)
}

export const pGenerateRecurring = ({
  date,
  recurringSlotInfo,
  timeSlots,
  view,
  locale,
}) => {
  return view === 'month'
    ? _recurringMonthDays({ date, recurringSlotInfo, timeSlots, locale })
    : _recurringDaysOfWeek({ date, recurringSlotInfo, timeSlots, locale })
}

const _slotTitle = (start, end) => {
  return `${Moment(start).format('h:mma')} - ${Moment(end).format('h:mma')}`
}

const _parseTimeSlot = (t, locale) => {
  const { start, end } = timeSlotTimesFromWeek(t.week, t, locale)

  return {
    title: _slotTitle(start, end),
    start,
    end,
    id: t.id,
    week: t.week,
  }
}

const _parseRecurringTimeSlot = (t, week, locale) => {
  const { start, end } = timeSlotTimesFromWeek(week, t, locale)

  return {
    title: _slotTitle(start, end),
    start,
    end,
    week: week,
    dayOfWeek: t.day,
    recurring: true,
    id: t.id,
    endDate: t.recurringEnds ? t.endDate : null,
    recurringDaysOfWeek: t.recurringDaysOfWeek,
    recurringEnds: t.recurringEnds,
  }
}

const _recurringMonthDays = ({
  date,
  recurringSlotInfo,
  timeSlots,
  locale,
}) => {
  let week = Moment(date).startOf('month').startOf('week')
  const endWeek = Moment(date).endOf('month').startOf('week')

  let weeks = []
  while (week <= endWeek) {
    weeks.push(week.format('MM/DD/YYYY'))
    week = week.add(1, 'week')
  }

  weeks = weeks.filter((week) => !timeSlots.some((t) => t.week == week))

  const recurringSlots = []
  for (const t of recurringSlotInfo) {
    recurringSlots.push(_parseRecurringTimeSlot(t, t.week, locale))
  }

  return recurringSlots
}

const _recurringDaysOfWeek = ({
  date,
  disabledWeeks,
  recurringSlotInfo,
  timeSlots,
  locale,
}) => {
  const week = Moment(date).startOf('week').format('MM/DD/YYYY')

  const recurringSlots = []
  if (
    !timeSlots.some((t) => t.week == week) &&
    !disabledWeeks.some((w) => w == week)
  ) {
    for (const t of recurringSlotInfo) {
      recurringSlots.push(_parseRecurringTimeSlot(t, week, locale))
    }
  }

  return recurringSlots
}

export const pResponseChefScheduleUpdates = (json) => {
  return json.map((scheduleUpdate) => {
    const { timeSlotReq, removeRecurDayReq, removeTimeSlot, ...updateMeta } =
      scheduleUpdate

    const out = { ...updateMeta }
    if (timeSlotReq) {
      out.chefTimeSlotReq = timeSlotReq
    } else if (removeRecurDayReq) {
      out.chefTimeSlotReq = removeRecurDayReq
    } else {
      out.chefTimeSlotReq = removeTimeSlot
    }

    return out
  })
}
