import { BaseService } from 'hungry-core2'

export default class Timer extends BaseService {
  callAfterTimeout = (func, delay = 1000) => {
    if (!this.promise) {
      this.setPromise(func, delay)
    } else {
      clearTimeout(this.timer)
      // rejections are not being handled correctly and is throwing errors throughout the codebase - disabling for now
      // this.reject && this.reject()
      this.setPromise(func, delay)
    }

    return this.promise
  }

  setPromise = (func, delay) => {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.timer = setTimeout(() => {
        this.timer = undefined
        this.promise = undefined
        resolve(func())
      }, delay)
    })
  }
}
