import { connect } from 'react-redux'

import { EditAccountContactModal } from '@components/account/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { editAccountContact, editAccountContactModal, account } = state
  const { show } = editAccountContactModal
  const errors = state.errors.accountContactModal || {}

  return {
    accountId: account.id,
    editAccountContact,
    errors,
    show,
  }
}

const mapDispatchToProps = () => {
  const {
    AccountService,
    RestService,

    UIService,
  } = services
  const { pStateToReduxAccountContact } = presenters
  const {
    pRequestUpdateAccountContact,
    pRequestAccountContacts,
    pResponseAccountContacts,
    pResponseAccount,
    pResponseEditAccount,
    pResponseEditClientAccounting,
  } = presenters.Api

  const {
    DelayedUpdateAccountContact,
    DeleteAccountContact,
    LoadAccountContacts,
    SaveAccountContact,
  } = coordinators
  const loadAccountContacts = LoadAccountContacts({
    AccountService,
    RestService,
    pRequestAccountContacts,
    pResponseAccountContacts,
    HandleError,
  })

  const { displayFailureMessage } = UIService.FlashMessage
  const { close } = UIService.EditAccountContact
  const clearErrors = UIService.Errors.clear
  const delayedUpdateAccountContact = DelayedUpdateAccountContact({
    AccountService,
    UIService,
    pStateToReduxAccountContact,
  })
  const deleteAccountContact = DeleteAccountContact({
    RestService,
    UIService,
    AccountService,
    pResponseAccount,
    pResponseEditAccount,
    pResponseEditClientAccounting,
    HandleError,
  })
  const saveAccountContact = SaveAccountContact({
    AccountService,
    RestService,
    UIService,
    pRequestUpdateAccountContact,
    pResponseAccount,
    pResponseEditAccount,
    pResponseEditClientAccounting,
    HandleError,
  })

  return {
    clearErrors,
    close,
    delayedUpdateAccountContact,
    deleteAccountContact,
    displayFailureMessage,
    loadAccountContacts,
    saveAccountContact,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAccountContactModal)
