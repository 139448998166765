import {
  APPEND_SCHEDULE_TIME_SLOTS,
  CLEAR_CHEF,
  CLEAR_EDIT_CHEF,
  CLEAR_EDIT_CHEF_CONTACT,
  CLEAR_EDIT_CONCEPT,
  CLEAR_EDIT_MENU_ITEM,
  CLEAR_EDIT_RECIPE,
  CLEAR_EDIT_SERVICE_ITEM,
  CLEAR_EDIT_SNACK_PACK,
  CLEAR_EDIT_VIRTUAL_ITEM,
  CLEAR_EDIT_VIRTUAL_KIT,
  REMOVE_SCHEDULE_DISABLED_WEEK,
  SET_CHEF,
  SET_CHEFS,
  SET_CHEF_MENU_ITEMS,
  SET_CHEF_SERVICE_ITEMS,
  SET_CHEF_SNACK_PACKS,
  SET_CHEF_VIRTUAL_ITEMS,
  SET_CHEF_VIRTUAL_KITS,
  SET_CONCEPTS,
  SET_EDIT_CHEF,
  SET_EDIT_CHEF_CONTACT,
  SET_EDIT_CONCEPT,
  SET_EDIT_CHILD_MENU_ITEM,
  SET_EDIT_MENU_ITEM,
  SET_EDIT_RECIPE,
  SET_EDIT_SERVICE_ITEM,
  SET_EDIT_SNACK_PACK,
  SET_EDIT_VIRTUAL_ITEM,
  SET_EDIT_VIRTUAL_KIT,
  SET_RECIPES,
  SET_SCHEDULE_TIME_SLOTS,
  UPDATE_EDIT_CHEF,
  UPDATE_EDIT_CHEF_CONTACT,
  UPDATE_EDIT_CONCEPT,
  UPDATE_EDIT_MENU_ITEM,
  UPDATE_EDIT_SERVICE_ITEM,
  UPDATE_EDIT_SNACK_PACK,
  UPDATE_EDIT_RECIPE,
  UPDATE_EDIT_VIRTUAL_ITEM,
  UPDATE_EDIT_VIRTUAL_KIT,
  UPDATE_SCHEDULE_TIME_SLOT,
} from '@actions/chef'

const initialChefState = null
const initialChefsState = []
const initialChefScheduleState = {
  disabledWeeks: [],
  recurringSlotInfo: [],
  recurringSlots: [],
  timeSlots: [],
}
const initialConceptsState = []
const initialEditChefState = {}
const initialEditChefContactState = {}
const initialEditConceptState = {}
const initialEditMenuItemState = {}
const initialEditChildMenuItemState = {}
const initialEditServiceItemState = {}
const initialEditSnackPackState = {}
const initialEditVirtualItemState = {}
const initialEditVirtualKitState = {}
const initialRecipeState = {}

export function chef(state = initialChefState, action) {
  const { type } = action

  switch (type) {
    case SET_CHEF:
      return { ...action.chef }
    case SET_CHEF_MENU_ITEMS:
      return {
        ...state,
        ...action.menuItems,
      }
    case SET_CHEF_SERVICE_ITEMS:
      return {
        ...state,
        serviceItems: [...action.serviceItems],
      }
    case SET_CHEF_SNACK_PACKS:
      return {
        ...state,
        snackPacks: [...action.snackPacks],
      }
    case SET_CHEF_VIRTUAL_ITEMS:
      return {
        ...state,
        virtualItems: [...action.virtualItems],
      }
    case SET_CHEF_VIRTUAL_KITS:
      return {
        ...state,
        virtualKits: [...action.virtualKits],
      }
    case SET_RECIPES:
      return {
        ...state,
        recipes: [...action.recipes],
      }
    case CLEAR_CHEF:
      return { ...initialChefState }
    default:
      return state
  }
}

export function chefs(state = initialChefsState, action) {
  const { type, chefs } = action

  switch (type) {
    case SET_CHEFS:
      return chefs.slice()
    default:
      return state
  }
}

export function chefSchedule(state = initialChefScheduleState, action) {
  const { type, disabledWeeks, recurringSlotInfo, recurringSlots, timeSlots } =
    action

  switch (type) {
    case APPEND_SCHEDULE_TIME_SLOTS: {
      const newState = { ...state }
      if (disabledWeeks) {
        newState.disabledWeeks = [...state.disabledWeeks, ...disabledWeeks]
      }
      if (recurringSlotInfo) {
        newState.recurringSlotInfo = [
          ...state.recurringSlotInfo,
          ...recurringSlotInfo,
        ]
      }
      if (recurringSlots) {
        newState.recurringSlots = [...state.recurringSlots, ...recurringSlots]
      }
      if (timeSlots) {
        newState.timeSlots = [...state.timeSlots, ...timeSlots]
      }

      return newState
    }
    case REMOVE_SCHEDULE_DISABLED_WEEK: {
      const disabledWeeks = state.disabledWeeks.slice()
      const enabledIdx = disabledWeeks.indexOf(action.week)
      if (enabledIdx !== -1) {
        disabledWeeks.splice(enabledIdx, 1)
      }

      return { ...state, disabledWeeks }
    }
    case SET_SCHEDULE_TIME_SLOTS:
      return {
        disabledWeeks: disabledWeeks || state.disabledWeeks,
        recurringSlotInfo: recurringSlotInfo || state.recurringSlotInfo,
        recurringSlots: recurringSlots || state.recurringSlots,
        timeSlots: timeSlots || state.timeSlots,
      }
    case UPDATE_SCHEDULE_TIME_SLOT: {
      const timeSlots = state.timeSlots.slice()
      const { timeSlot } = action

      const slot = timeSlots.find((s) => s.id == timeSlot.id)
      slot.start = timeSlot.start
      slot.end = timeSlot.end

      return {
        ...state,
        timeSlots,
      }
    }
    default:
      return state
  }
}

export function menuConcepts(state = initialConceptsState, action) {
  const { type, concepts } = action

  switch (type) {
    case SET_CONCEPTS:
      return concepts.slice()
    default:
      return state
  }
}

export function editChef(state = initialEditChefState, action) {
  const { type, chef } = action

  switch (type) {
    case SET_EDIT_CHEF:
      return { ...chef }
    case UPDATE_EDIT_CHEF:
      return { ...state, ...chef }
    case CLEAR_EDIT_CHEF:
      return { ...initialEditChefState }
    default:
      return state
  }
}

export function editChefContact(state = initialEditChefContactState, action) {
  const { type, contact } = action

  switch (type) {
    case SET_EDIT_CHEF_CONTACT:
      return { ...contact }
    case UPDATE_EDIT_CHEF_CONTACT:
      return { ...state, ...contact }
    case CLEAR_EDIT_CHEF_CONTACT:
      return { ...initialEditChefContactState }
    default:
      return state
  }
}

export function editChildMenuItem(
  state = initialEditChildMenuItemState,
  action,
) {
  const { type, menuItem } = action

  switch (type) {
    case SET_EDIT_CHILD_MENU_ITEM:
      return { ...menuItem }
    default:
      return state
  }
}

export function editMenuItem(state = initialEditMenuItemState, action) {
  const { type, menuItem } = action

  switch (type) {
    case SET_EDIT_MENU_ITEM:
      return { ...menuItem }
    case UPDATE_EDIT_MENU_ITEM:
      return { ...state, ...menuItem }
    case CLEAR_EDIT_MENU_ITEM:
      return { ...initialEditMenuItemState }
    default:
      return state
  }
}

export function editServiceItem(state = initialEditServiceItemState, action) {
  const { type, serviceItem } = action

  switch (type) {
    case SET_EDIT_SERVICE_ITEM:
      return { ...serviceItem }
    case UPDATE_EDIT_SERVICE_ITEM:
      return { ...state, ...serviceItem }
    case CLEAR_EDIT_SERVICE_ITEM:
      return { ...initialEditServiceItemState }
    default:
      return state
  }
}

export function editRecipe(state = initialRecipeState, action) {
  const { type, recipe } = action

  switch (type) {
    case SET_EDIT_RECIPE:
      return { ...recipe }
    case UPDATE_EDIT_RECIPE:
      return { ...state, ...recipe }
    case CLEAR_EDIT_RECIPE:
      return { ...initialRecipeState }
    default:
      return state
  }
}

export function editSnackPack(state = initialEditSnackPackState, action) {
  const { type, snackPack } = action

  switch (type) {
    case SET_EDIT_SNACK_PACK:
      return { ...snackPack }
    case UPDATE_EDIT_SNACK_PACK:
      return { ...state, ...snackPack }
    case CLEAR_EDIT_SNACK_PACK:
      return { ...initialEditSnackPackState }
    default:
      return state
  }
}

export function editVirtualItem(state = initialEditVirtualItemState, action) {
  const { type, virtualItem } = action

  switch (type) {
    case SET_EDIT_VIRTUAL_ITEM:
      return { ...virtualItem }
    case UPDATE_EDIT_VIRTUAL_ITEM:
      return { ...state, ...virtualItem }
    case CLEAR_EDIT_VIRTUAL_ITEM:
      return { ...initialEditVirtualItemState }
    default:
      return state
  }
}

export function editVirtualKit(state = initialEditVirtualKitState, action) {
  const { type, virtualKit } = action

  switch (type) {
    case SET_EDIT_VIRTUAL_KIT:
      return { ...virtualKit }
    case UPDATE_EDIT_VIRTUAL_KIT:
      return { ...state, ...virtualKit }
    case CLEAR_EDIT_VIRTUAL_KIT:
      return { ...initialEditVirtualKitState }
    default:
      return state
  }
}

export function editMenuConcept(state = initialEditConceptState, action) {
  const { type, concept } = action

  switch (type) {
    case SET_EDIT_CONCEPT:
      return { ...concept }
    case UPDATE_EDIT_CONCEPT:
      return { ...state, ...concept }
    case CLEAR_EDIT_CONCEPT:
      return { ...initialEditConceptState }
    default:
      return state
  }
}
