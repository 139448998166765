import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class ServiceVendorService extends BaseService {
  setServiceItems = (serviceItems) => {
    this.dispatch(actions.setServiceItems(serviceItems))
  }

  setEditServiceItem = (serviceItem) => {
    this.dispatch(actions.setEditServiceItem(serviceItem))
  }

  clearEditServiceItem = () => {
    this.dispatch(actions.clearEditServiceItem())
  }
}
