import React, { useState, useEffect, useMemo } from 'react'

import { NonFoodItem } from '@types'

export interface LoaderMenuItemGroupProps {
  name: string
  searchText: string
  count?: number
  chefId: string
  selectedIds: Set<string>
  addCustomText?: string
  hideNonCustom?: boolean
  onCreateCustom?: (customItem: any) => void
  loaderFunc: (chefId: string) => Promise<NonFoodItem[]>
  onSelect: (item: NonFoodItem) => void
}

const LoaderMenuItemGroup: React.FC<LoaderMenuItemGroupProps> = ({
  name,
  searchText,
  count,
  chefId,
  selectedIds,
  addCustomText,
  hideNonCustom,
  onCreateCustom,
  loaderFunc,
  onSelect,
}) => {
  const [items, setItems] = useState<NonFoodItem[]>([])
  const [itemsLoaded, setItemsLoaded] = useState(false)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (itemsLoaded || !expanded) {
      return
    }

    ;(async () => {
      const items = await loaderFunc(chefId)
      setItems(items)
      // checking length of items is unreliable, some chefs have 0 non-food items
      setItemsLoaded(true)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded])

  const renderItem = (item: NonFoodItem) => (
    <div
      key={item.id}
      className="hover:bg-blue-100 cursor-pointer flex justify-between py-2"
      onClick={() => onSelect(item)}
    >
      <p className="text-base">{item.name}</p>
      {item.price && <div> ${item.price} </div>}
    </div>
  )

  const visibleItems = useMemo(() => {
    const regex = new RegExp(searchText, 'gi')

    return items
      .filter((item) => item.name.match(regex))
      .filter((item) => !selectedIds.has(item.id))
      .map((item) => renderItem(item))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, searchText])

  const customItem = {
    name: addCustomText,
    tagsDietaryPreferenceList: [],
  }

  const fullGroupName =
    name +
    ` (${itemsLoaded ? visibleItems.length : count})` +
    (hideNonCustom ? ' (For Shipping Charges)' : '')

  return (
    <div className="mb-4">
      <div className="flex justify-between items-center">
        <p
          className="bold text-lg pb-1 relative items"
          onClick={() => setExpanded(!expanded)}
        >
          {fullGroupName}
          {!hideNonCustom && (
            <span className={expanded ? 'down-arrow' : 'side-arrow'} />
          )}
        </p>
        {onCreateCustom && (
          <p
            className="cursor-pointer rounded py-1 px-1 border border-green-500 border-solid bold text-base text-green-500"
            onClick={() => onCreateCustom(customItem)}
          >
            {customItem.name}
          </p>
        )}
      </div>
      {expanded && <ul>{!hideNonCustom ? visibleItems : null}</ul>}
    </div>
  )
}

export default LoaderMenuItemGroup
