import { connect } from 'react-redux'

import { EmailNotifications } from '@components/account/accountSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { account, accountContacts } = state

  return {
    accountId: account.id,
    accountContacts,
  }
}

const mapDispatchToProps = () => {
  const { AccountService, RestService, UIService } = services
  const {
    pRequestAccountContacts,
    pResponseAccountContacts,
    pResponseGeneric,
  } = presenters.Api
  const {
    AsyncLoadAccountContacts,
    EditAccountContact,
    LoadAccountContacts,
    NewAccountContact,
  } = coordinators

  const loadAccountContacts = LoadAccountContacts({
    AccountService,
    RestService,
    pRequestAccountContacts,
    pResponseAccountContacts,
    HandleError,
  })
  const asyncLoadAccountContacts = AsyncLoadAccountContacts({
    AccountService,
    RestService,
    pResponseGeneric,
  })

  const newAccountContact = NewAccountContact({ AccountService, UIService })
  const editAccountContact = EditAccountContact({ AccountService, UIService })

  return {
    asyncLoadAccountContacts,
    editAccountContact,
    loadAccountContacts,
    newAccountContact,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailNotifications)
