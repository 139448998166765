import { sortByAttribute } from '../../../utils'

export const LoadMarkets =
  ({ MarketService, RestService, pResponseHeadquarters }) =>
  async (filters) => {
    let markets = await RestService.get('/headquarters', {
      page: 1,
      results_per_page: 5000,
      ...filters,
    })
    markets = pResponseHeadquarters(markets)
    Array.isArray(markets) && sortByAttribute(markets, 'name')
    if (MarketService) {
      MarketService.setMarkets(markets)
    }

    return markets
  }

export const SelectMarket =
  ({ MarketService, RestService, pResponseMarket, HandleError }) =>
  async (id) => {
    try {
      const hq = await RestService.get(`/headquarters/${id}`)
      const captRates = await RestService.get(
        `/headquarters/${id}/captain_rates`,
      )

      const market = { ...pResponseMarket(hq), captRates }

      MarketService.setHQ(market)
      MarketService.setDeliveryZones(market.zips)
    } catch (err) {
      HandleError({ error: err, namespace: 'editMarketModal' })
    }
  }

export const DelayedUpdateHeadquarter =
  ({ MarketService, UIService, pStateToReduxMarket }) =>
  (market) => {
    const call = () => MarketService.setMarket(pStateToReduxMarket(market))
    UIService.Timer.callAfterTimeout(call)
  }

export const NewMarket =
  ({ MarketService, UIService }) =>
  async () => {
    MarketService.clearEditHQ()
    UIService.EditMarket.showHQ()
  }

export const EditHeadquarter =
  ({ MarketService, UIService }) =>
  async () => {
    const { hqDetails } = MarketService.getState()
    MarketService.setEditHQ(hqDetails)
    UIService.EditMarket.showHQ()
  }

export const SaveHeadquarter =
  ({ RestService, UIService, MarketService, pResponseMarket, HandleError }) =>
  async (data) => {
    UIService.Errors.clear()
    try {
      let hq
      if (data.id) {
        hq = await RestService.put(`/headquarters/${data.id}`, data)
      } else {
        hq = await RestService.post('/headquarters', data)
      }
      const market = pResponseMarket(hq)
      MarketService.setHQ(market)
      MarketService.setDeliveryZones(market.zips)
      UIService.EditMarket.closeHQ()
    } catch (err) {
      HandleError({ error: err, namespace: 'editHQModal' })
    }
  }

export const GetLastChangeToGroupOrderMinimumItems =
  ({ RestService, HandleError, pResponseLastChangeToGroupOrderMinimumItems }) =>
  async (data) => {
    try {
      const result = await RestService.get(
        `/api/admin/headquarters/${data.id}/last_change_to_group_order_minimum_items`,
      )

      return pResponseLastChangeToGroupOrderMinimumItems(result)
    } catch (err) {
      HandleError({
        error: err,
        namespace: 'GetLastChangeToGroupOrderMinimumItems',
      })
    }
  }

export const LoadHeadquarter =
  ({ RestService, pResponseMarket, HandleError }) =>
  async (hqId) => {
    try {
      const hq = await RestService.get(`/headquarters/${hqId}`)

      return pResponseMarket(hq)
    } catch (error) {
      HandleError({ error })

      return false
    }
  }
