import React from 'react'
import Modal from '@components/common/modal/Modal'
import FlexContainer from '@components/common/FlexContainer'
import YSpacing from '@components/common/YSpacing'

interface ResultsMessageModalProps {
  errors: string[]
  successes: string[]
  close: () => void
}

const ResultsMessageModal = ({
  errors,
  successes,
  close,
}: ResultsMessageModalProps) => {
  return (
    <Modal title="Results" hideModal={close} color="#001940" width="600px">
      <FlexContainer
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <FlexContainer
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <h2 style={{ color: 'green', fontSize: '18px' }}>
            Successfully Updated:
          </h2>
          {successes.map((s, i) => {
            return (
              <p style={{ color: 'green' }} key={i}>
                {s}
              </p>
            )
          })}
        </FlexContainer>
        <YSpacing height="10px" />
        <FlexContainer
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          {errors.length > 0 && (
            <>
              <h2 style={{ color: 'red', fontSize: '18px' }}>
                Unsuccessfully Updated:
              </h2>
              {errors.map((e, i) => {
                return (
                  <p style={{ color: 'red' }} key={i}>
                    {e}
                  </p>
                )
              })}
            </>
          )}
        </FlexContainer>
      </FlexContainer>
    </Modal>
  )
}

export default ResultsMessageModal
