import React, { FunctionComponent } from 'react'
import Modal from '@components/common/modal/Modal'
import Button from '@components/common/form/Button'
import LinkText from '@components/common/form/LinkText'

type Props = {
  title?: string
  onClose: () => void
  onSave: () => void
  validationErrors: string[]
  validationWarnings: string[]
}

const ValidationsModal: FunctionComponent<Props> = ({
  title,
  onClose,
  onSave,
  validationErrors,
  validationWarnings,
}) => {
  return (
    <Modal
      title={title || 'Validations'}
      hideModal={onClose}
      color="#001940"
      maxWidth="400px"
      width="400px"
      height="600px"
    >
      <div className="group-orders-master-warnings-modal-content">
        {validationErrors.length > 0 && (
          <div className="validation-section">
            <div className="validation-errors-title">
              Please correct the errors below before proceeding:
            </div>
            <div className="validation-errors-container">
              {validationErrors.map((message, index) => (
                <p key={index} className="validation-message is-error">
                  {message}
                </p>
              ))}
            </div>
          </div>
        )}
        {validationWarnings.length > 0 && (
          <div className="validation-section">
            <div className="validation-errors-title">
              Please review the warnings below before proceeding:
            </div>
            <div className="validation-errors-container">
              {validationWarnings.map((message, index) => (
                <p key={index} className="validation-message is-warning">
                  {message}
                </p>
              ))}
            </div>
          </div>
        )}
        <div className="buttons-container">
          {validationErrors.length > 0 ? (
            <Button label="OK" onClick={onClose} />
          ) : (
            <>
              <LinkText
                label="Cancel"
                onClick={onClose}
                color="#b30303"
                className="cancel-button"
              />
              <Button label="Save Anyway" onClick={onSave} />
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ValidationsModal
