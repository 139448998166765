import { BaseService } from 'hungry-core2'
import { rStatuses } from '../../view/components/popUps/constants'

export class RecurringPopUpService extends BaseService {
  newRecurringPopUp = ({ headquarter }) => ({
    id: undefined,
    name: '',
    startDay: undefined, //int sunday = 0
    status: rStatuses[0],
    onSiteItems: {
      entrees: [],
      sides: [],
      drinks: [],
      desserts: [],
    },
    timeSlots: [],
    headquarter,
    headCount: undefined,
    location: undefined,
    client: undefined,
    salesRep: undefined,
  })
  newTimeSlot = () => ({
    id: undefined,
    day: undefined, //int sunday = 0
    start: { hour: 12, minute: 0 }, //{hour: int 0 to 23, minute: int 0 to 59}
    end: { hour: 13, minute: 0 }, //{hour: int 0 to 23, minute: int 0 to 59}
    isPrivate: false,
    isHidden: false,
    setUpStyle: undefined,
    menu: undefined,
    name: '',
    servicesNotes: '',
    chefNotes: '',
    prePaymentAmount: undefined,
    prePaymentTax: undefined,
    marketSubtotal: 0.0,
    chefSubtotal: 0.0,
  })
}
