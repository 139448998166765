export const LoadChefConfirmations =
  ({ RestService, HandleError }) =>
  async (req) => {
    const params = {}
    params.hqId = req.hqId
    if (req.chefId) {
      params.chefIds = req.chefId
    }
    if (req.date) {
      params.date = req.date
    }

    try {
      const confirmations = await RestService.get(
        '/chef-dashboard/confirmations',
        params,
      )

      return confirmations
    } catch (error) {
      HandleError({ error })

      return []
    }
  }
