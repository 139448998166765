import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

const ChildOrder = (props) => {
  const { order, onSelect } = props
  const { contact } = order

  return (
    <div
      className={'selectable-item child-order'}
      id="add-bundled-order"
      onClick={() => onSelect(order)}
    >
      <span className="plus-sign"> + </span>
      <div id="selectable-order">
        {order.orderNumber} {Moment(order.clientSetUpTime).format('MM/DD')}{' '}
        {contact && contact.name} {`$${order.total}`}
      </div>
    </div>
  )
}

ChildOrder.propTypes = {
  order: PropTypes.object,

  onSelect: PropTypes.func,
}

ChildOrder.defaultProps = {
  onSelect: () => undefined,
}

export default ChildOrder
