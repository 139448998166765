import React, { Component } from 'react'
import PropTypes from 'prop-types'

const overwrittenSynthEvents = {
  disabled: true,
  href: null,
  onBlur: null,
  onChange: null,
  onClick: null,
  onFocus: null,
  onInput: null,
  onKeyDown: null,
  onKeyPress: null,
  onKeyUp: null,
  onMouseDown: null,
  onMouseEnter: null,
  onMouseLeave: null,
  onMouseOver: null,
  onMouseUp: null,
  onSelect: null,
  onSubmit: null,
}

class AuthorizedInteractable extends Component {
  renderSuppressedChildren() {
    const { key, children, customStyle } = this.props

    if (!Array.isArray(children)) {
      return (
        <div key={key} style={customStyle}>
          {React.cloneElement(children, overwrittenSynthEvents)}
        </div>
      )
    }

    return (
      <div key={key} style={customStyle}>
        {children &&
          children.map((child) =>
            React.cloneElement(child, overwrittenSynthEvents),
          )}
      </div>
    )
  }

  render() {
    const { children, interactable } = this.props

    return interactable ? children : this.renderSuppressedChildren()
  }
}

AuthorizedInteractable.propTypes = {
  children: PropTypes.node,
  customStyle: PropTypes.object, //useful for passing your own style, e.g. { 'display': 'inline' }

  key: PropTypes.string,
  interactable: PropTypes.bool,
}

export default AuthorizedInteractable
