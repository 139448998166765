import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import { Route } from 'react-router'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { config } from 'hungry-core2'
import analytics from '@analytics'
import { ActivateBuildValidation } from './utils'
import configJSON from './config.json'

import store, { history } from '@store'

import App from '@components/App'
import axios from '@services/nodeApi/axios'

import 'react-dates/initialize'

import 'react-dates/lib/css/_datepicker.css'
import 'react-datepicker/dist/react-datepicker.css'
import '@res/stylesheets/main.css'
import '@res/stylesheets/tailwind.css'

ActivateBuildValidation({
  currentVersion: window.H_BUILD_VERSION,
})

config.setConfig(configJSON)
axios.defaults.baseURL = config.hungry_node_api_host

analytics.init()

const queryClient = new QueryClient()

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <QueryClientProvider client={queryClient}>
        <Route component={App} />
      </QueryClientProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app'),
)
