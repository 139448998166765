import * as vxCoordinators from './vx'
import * as vxOrderCoordinators from './vxOrders'
import * as vxInvoiceCoordinators from './invoice'
import * as vxLookupCoordinators from './vxLookups'

export default Object.freeze({
  ...vxCoordinators,
  ...vxOrderCoordinators,
  ...vxInvoiceCoordinators,
  ...vxLookupCoordinators,
})
