export const EditVirtualKit =
  ({ ChefService, RestService, UIService, pResponseVirtualKit }) =>
  async (virtualKitId) => {
    const virtualKit = await RestService.get(
      `/api/admin/virtual_kits/${virtualKitId}`,
    )
    ChefService.setEditVirtualKit(pResponseVirtualKit(virtualKit))
    UIService.EditVirtualKit.show()
  }

export const NewVirtualKit =
  ({ ChefService, UIService, pDefaultVirtualKitFields }) =>
  () => {
    const { chef, editVirtualKit } = ChefService.getState()
    if (editVirtualKit && editVirtualKit.id) {
      ChefService.clearEditVirtualKit()
    }

    if (chef) {
      ChefService.setEditVirtualKit(pDefaultVirtualKitFields(chef))
      UIService.EditVirtualKit.show()
    }
  }

export const LoadChefVirtualKits =
  ({ ChefService, RestService, pResponseVirtualKits }) =>
  async (chefId) => {
    const params = {
      include_all: true,
      chef_ids: [chefId],
    }
    const response = await RestService.get('/api/admin/virtual_kits', params, {
      timeout: 20000,
    })
    const virtualKits = pResponseVirtualKits(response)
    ChefService.setChefVirtualKits(virtualKits)
  }

export const DelayedUpdateVirtualKit =
  ({ ChefService, UIService }) =>
  (virtualKit) => {
    const call = () => ChefService.setEditVirtualKit(virtualKit)

    UIService.Timer.callAfterTimeout(call)
  }

export const SaveVirtualKit =
  ({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateVirtualKit,
    pResponseVirtualKits,
    HandleError,
  }) =>
  async (data) => {
    try {
      UIService.Errors.clear()
      const req = pRequestUpdateVirtualKit(data)
      const headers = { 'Content-Type': 'multipart/form-data' }

      let virtualKit = undefined
      if (data.id) {
        virtualKit = await RestService.put(
          `/api/admin/virtual_kits/${data.id}`,
          req,
          headers,
        )
      } else {
        virtualKit = await RestService.post(
          '/api/admin/virtual_kits',
          req,
          headers,
        )
      }

      // const imageUpdateReq = pRequestUpdateItemImages( data, 'virtual_item' )
      // if ( virtualItem && virtualItem.id && imageUpdateReq ) {
      //   virtualItem = await RestService.put( `/api/admin/virtual_items/${ virtualItem.id }`, imageUpdateReq, headers )
      // }

      UIService.EditVirtualKit.close()
      ChefService.clearEditVirtualKit()

      LoadChefVirtualKits({ ChefService, RestService, pResponseVirtualKits })(
        virtualKit.chef_id,
      )
    } catch (error) {
      HandleError({ error, namespace: 'virtualKitModal' })
    }
  }

export const DeleteVirtualKit =
  ({
    ChefService,
    RestService,
    UIService,
    pResponseChef,
    HandleError,
    SelectChef,
  }) =>
  async ({ id, chefId, name }) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete virtual kit "${name}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/api/admin/virtual_kits/${id}`)
        ChefService.clearEditVirtualItem()
        SelectChef({ ChefService, RestService, pResponseChef })(chefId)
        UIService.EditVirtualItem.close()
      } catch (error) {
        HandleError({ error })
      }
    }
  }
