import { connect } from 'react-redux'

import { Transactions } from '@components/order/orderSections'

import services from '@services'
import coordinators from '@coordinators'
import { HandleError } from '@coordinators/composed'

const mapDispatchToProps = () => {
  const { GetAccountingOrder } = coordinators
  const { RestService } = services
  const getAccountingOrder = GetAccountingOrder({ RestService, HandleError })

  return {
    getAccountingOrder,
  }
}

export default connect(undefined, mapDispatchToProps)(Transactions)
