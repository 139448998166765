import { BaseService } from 'hungry-core2'

import actions from '@actions'

export default class OrderPage extends BaseService {
  toggleInvoiceButtonEnabled = (on) => {
    this.dispatch(actions.toggleInvoiceButtonEnabled(on))
  }

  setLastLoadOrdersParams = ({ page, search, status }) => {
    this.dispatch(actions.setLastLoadOrdersParams({ page, search, status }))
  }

  setPaymentProcessing = (processing) => {
    this.dispatch(actions.setPaymentProcessing(processing))
  }

  getLastLoadOrdersParams = () => {
    const { page, search, status } = this.getState().orderPage

    return { page, search, status }
  }
}
