import { Moment } from 'moment-timezone'

export interface ClientSettings {
  id: string
  name: string
  client: Client
  hasLoadedDateMenus: boolean
  scheduleSettings: ScheduleSettings | null
  subsidySettings: SubsidySettings | null
  location: Location
  hidePrices: boolean
  active: boolean
  useChefNotes: boolean
  deliveryFee: string
  deliveryFeeType: string
  employeeIds: string[]
  notificationsConfig: NotificationsConfig | null
}

export type SlackConfig = {
  teamId: string
  channelId: string
  slackAuthCustomerId: string
  active: boolean
  notifyOnGroupOrderDelivered: boolean
}

export type NotificationsConfig = {
  slackConfig: SlackConfig | null
}

export interface ScheduleSettings {
  cutoffTimeSettings: CutoffTimeSettings | null
  dropoffTimeSettings: DropoffTimeSettings | null
  isActive: boolean
}

export interface CutoffTimeSettings {
  dayOffset: number
  dayOffsetType: CutoffTimeDayOffSetType
  time: Moment
}

export interface DropoffTimeSettings {
  time: Moment
}

export enum CutoffTimeDayOffSetType {
  BUSINESS_DAYS = 'businessDays',
  DAYS = 'days',
}

export interface Client {
  name: string
  id: string
}

export type SubsidySettings = {
  subsidies: Subsidy[]
}

export type FixedItemPrice = {
  itemType: string
  price: number
}

export type ItemPriceSettingsPrices = Record<
  GroupOrderMenuItemType,
  FixedItemPrice
>

export type FixedItemBudget = {
  itemType: string
  budget: number
}

export type ItemPriceSettingsBudgets = Record<
  GroupOrderMenuItemType,
  FixedItemBudget
>

export type ItemPriceSettings = {
  budgets: ItemPriceSettingsBudgets
  prices: ItemPriceSettingsPrices
}

export type Subsidy = {
  itemPriceSettings: ItemPriceSettings | null
  fullySubsidized: boolean
  isActive: boolean
  perOrderSubsidy: number | null
  subsidyType: string
  subsidyPercentLimitAmt: number | null
  isSubsidyPercentLimit: boolean
  limitPurchase: boolean
  days: number[] | null
}

export type Location = {
  id: string
  name: string
  line1: string
  line2: string
  city: string
  state: string
  zip: string
  spot: string
  buildingInstructions: string
  locale: string
}

export enum SubsidyType {
  FIXED = 'FixedSubsidy',
  PERCENT = 'Percent',
  REVERSE = 'Reverse',
  HUNGRY_WALLET = 'HUNGRYWallet',
  FLAT_PRICE = 'FlatPriceSubsidy',
}

export enum GroupOrderMenuItemType {
  ENTREE = 'Entree',
  SIDE = 'Side',
  ALL = 'All',
}
