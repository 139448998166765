import { connect } from 'react-redux'

import SubscriberPage from '@components/subscription/SubscriberPage'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { subscribers, user } = state
  const { pUserHasCurrentRoles } = presenters.Api
  const userRep = pUserHasCurrentRoles(user, ['sales rep']) ? user : undefined

  return {
    members: subscribers,
    hqId: user.lastLoggedInHq,
    userRep,
  }
}

const mapDispatchToProps = () => {
  const { RestService, SubscriptionService, UIService } = services
  const {
    pResponseAccountExecutives,
    pRequestChooseSalesRep,
    pRequestUpdateSubscriber,
    pRequestSubscription,
    pResponseSubscriber,
    pResponseHomeMenu,
  } = presenters.Api
  const {
    ChooseMemberSalesRep,
    DeleteMemberSubscription,
    LoadAccountExecutives,
    LoadSubscribers,
    LoadHomeMenus,
    LoadHomeMenu,
    NotifySubscriptionChef,
    SaveMemberSubscription,
    SendSubscriptionSurveys,
    SkipWeek,
    TriggerChargeOrders,
    TriggerCutoff,
    UnskipWeek,
    UpdateSubscriber,
    UpdateSubscriberWeekdays,
  } = coordinators

  const { callAfterTimeout } = UIService.Timer
  const chooseMemberSalesRep = ChooseMemberSalesRep({
    RestService,
    pRequestChooseSalesRep,
    HandleError,
  })
  const deleteSubscription = DeleteMemberSubscription({
    RestService,
    SubscriptionService,
    UIService,
    pResponseSubscriber,
    HandleError,
  })
  const flashError = (message) =>
    UIService.FlashMessage.displayFailureMessage(message)
  const flashSuccess = (message) =>
    UIService.FlashMessage.displaySuccessMessage(message)
  const loadHomeMenu = LoadHomeMenu({
    RestService,
    pResponseHomeMenu,
    HandleError,
  })
  const loadHomeMenus = LoadHomeMenus({ RestService, HandleError })
  const loadSalesReps = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })(false) // is vcx
  const loadSubscribers = LoadSubscribers({
    RestService,
    SubscriptionService,
    pResponseSubscriber,
    HandleError,
  })
  const notifySubscriptionChef = NotifySubscriptionChef({
    RestService,
    UIService,
    HandleError,
  })
  const saveMealSchedule = UpdateSubscriberWeekdays({
    RestService,
    SubscriptionService,
    pResponseSubscriber,
    HandleError,
  })
  const saveSubscription = SaveMemberSubscription({
    RestService,
    SubscriptionService,
    pRequestSubscription,
    pResponseSubscriber,
    HandleError,
  })
  const sendSurveys = SendSubscriptionSurveys({
    RestService,
    UIService,
    HandleError,
  })
  const skipWeek = SkipWeek({
    RestService,
    SubscriptionService,
    pResponseSubscriber,
    HandleError,
  })
  const triggerChargeOrders = TriggerChargeOrders({ RestService, HandleError })
  const triggerCutoff = TriggerCutoff({ RestService, HandleError })
  const unskipWeek = UnskipWeek({
    RestService,
    SubscriptionService,
    pResponseSubscriber,
    HandleError,
  })
  const updateMember = UpdateSubscriber({
    RestService,
    SubscriptionService,
    pRequestUpdateSubscriber,
    pResponseSubscriber,
    HandleError,
  })

  return {
    callAfterTimeout,
    chooseMemberSalesRep,
    deleteSubscription,
    flashError,
    flashSuccess,
    loadHomeMenu,
    loadHomeMenus,
    loadSalesReps,
    loadSubscribers,
    notifySubscriptionChef,
    saveMealSchedule,
    saveSubscription,
    sendSurveys,
    skipWeek,
    triggerChargeOrders,
    triggerCutoff,
    unskipWeek,
    updateMember,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriberPage)
