import { connect } from 'react-redux'

import MLBMaster from '@components/mlbPartners/MLBMaster'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = () => {
  return {
    mlbPartnerName: 'Nationals',
    adminRoleName: 'nationals admin',
    defaultZip: '22207',
  }
}

const mapDispatchToProps = () => {
  const { RestService } = services
  const { pResponseMLBPartner } = presenters.Api
  const { LoadMLBOrders, LoadMLBPartner, MarkMLBOrderDelivered } = coordinators

  const loadMLBPartner = LoadMLBPartner({
    RestService,
    pResponseMLBPartner,
    HandleError,
  })
  const loadMLBOrders = LoadMLBOrders({ RestService, HandleError })
  const markMLBOrderDelivered = MarkMLBOrderDelivered({
    RestService,
    HandleError,
  })

  return {
    loadMLBOrders,
    loadMLBPartner,
    markMLBOrderDelivered,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MLBMaster)
