import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class SalesStatsService extends BaseService {
  // accountStats actions
  setAccountAvgMargin = (num) => this.dispatch(actions.setAccountAvgMargin(num))

  setCompareToAccountAvgMargin = (num) =>
    this.dispatch(actions.setCompareToAccountAvgMargin(num))

  setAccountAvgOrderTotal = (num) =>
    this.dispatch(actions.setAccountAvgOrderTotal(num))

  setCompareToAccountAvgOrderTotal = (num) =>
    this.dispatch(actions.setCompareToAccountAvgOrderTotal(num))

  setTop3MenusForAccount = (arr) =>
    this.dispatch(actions.setTop3MenusForAccount(arr))

  setCompareToTop3MenusForAccount = (arr) =>
    this.dispatch(actions.setCompareToTop3MenusForAccount(arr))

  setAccountTotalOrders = (num) =>
    this.dispatch(actions.setAccountTotalOrders(num))

  setCompareToAccountTotalOrders = (num) =>
    this.dispatch(actions.setCompareToAccountTotalOrders(num))

  setAccountTotalSales = (num) =>
    this.dispatch(actions.setAccountTotalSales(num))

  setCompareToAccountTotalSales = (num) =>
    this.dispatch(actions.setCompareToAccountTotalSales(num))

  setAccountTotalSalesChartData = (arr) =>
    this.dispatch(actions.setAccountTotalSalesChartData(arr))

  setAccountTotalOrdersChartData = (arr) =>
    this.dispatch(actions.setAccountTotalOrdersChartData(arr))

  setAccountAvgOrderTotalsChartData = (arr) =>
    this.dispatch(actions.setAccountAvgOrderTotalsChartData(arr))

  setAccountAvgMarginsChartData = (arr) =>
    this.dispatch(actions.setAccountAvgMarginsChartData(arr))

  // national stats actions
  setMarketOverviewStats = (arr) =>
    this.dispatch(actions.setMarketOverviewStats(arr))

  setNationalAvgMargin = (num) =>
    this.dispatch(actions.setNationalAvgMargin(num))

  setNationalAvgOrderTotal = (num) =>
    this.dispatch(actions.setNationalAvgOrderTotal(num))

  setTopAccountsPerMarket = (arr) =>
    this.dispatch(actions.setTopAccountsPerMarket(arr))

  setTopMenusPerMarket = (arr) =>
    this.dispatch(actions.setTopMenusPerMarket(arr))

  setTopRepsPerMarket = (arr) => this.dispatch(actions.setTopRepsPerMarket(arr))

  setNationalTotalOrders = (num) =>
    this.dispatch(actions.setNationalTotalOrders(num))

  setNationalTotalSales = (num) =>
    this.dispatch(actions.setNationalTotalSales(num))

  setNationalTotalSalesChartData = (arr) =>
    this.dispatch(actions.setNationalTotalSalesChartData(arr))

  setNationalTotalOrdersChartData = (arr) =>
    this.dispatch(actions.setNationalTotalOrdersChartData(arr))

  setNationalAvgOrderTotalsChartData = (arr) =>
    this.dispatch(actions.setNationalAvgOrderTotalsChartData(arr))

  setNationalAvgMarginsChartData = (arr) =>
    this.dispatch(actions.setNationalAvgMarginsChartData(arr))

  // marketStats actions
  setSalesRepDetails = (arr) => this.dispatch(actions.setSalesRepDetails(arr))

  setMarketAvgMargin = (num) => this.dispatch(actions.setMarketAvgMargin(num))

  setMarketAvgOrderTotal = (num) =>
    this.dispatch(actions.setMarketAvgOrderTotal(num))

  setTop3AccountsInMarket = (arr) =>
    this.dispatch(actions.setTop3AccountsInMarket(arr))

  setTop3MenusInMarket = (arr) =>
    this.dispatch(actions.setTop3MenusInMarket(arr))

  setTop3RepsInMarket = (arr) => this.dispatch(actions.setTop3RepsInMarket(arr))

  setMarketTotalOrders = (num) =>
    this.dispatch(actions.setMarketTotalOrders(num))

  setMarketTotalSales = (num) => this.dispatch(actions.setMarketTotalSales(num))

  setMarketTotalSalesChartData = (arr) =>
    this.dispatch(actions.setMarketTotalSalesChartData(arr))

  setMarketTotalOrdersChartData = (arr) =>
    this.dispatch(actions.setMarketTotalOrdersChartData(arr))

  setMarketAvgOrderTotalsChartData = (arr) =>
    this.dispatch(actions.setMarketAvgOrderTotalsChartData(arr))

  setMarketAvgMarginsChartData = (arr) =>
    this.dispatch(actions.setMarketAvgMarginsChartData(arr))

  // salesRepStats actions
  setAccountDetails = (arr) => this.dispatch(actions.setAccountDetails(arr))

  setRepAvgMargin = (num) => this.dispatch(actions.setRepAvgMargin(num))

  setCompareToRepAvgMargin = (num) =>
    this.dispatch(actions.setCompareToRepAvgMargin(num))

  setRepAvgOrderTotal = (num) => this.dispatch(actions.setRepAvgOrderTotal(num))

  setCompareToRepAvgOrderTotal = (num) =>
    this.dispatch(actions.setCompareToRepAvgOrderTotal(num))

  setTop3AccountsForRep = (arr) =>
    this.dispatch(actions.setTop3AccountsForRep(arr))

  setTop3MenusForRep = (arr) => this.dispatch(actions.setTop3MenusForRep(arr))

  setRepTotalOrders = (num) => this.dispatch(actions.setRepTotalOrders(num))

  setCompareToRepTotalOrders = (num) =>
    this.dispatch(actions.setCompareToRepTotalOrders(num))

  setRepTotalSales = (num) => this.dispatch(actions.setRepTotalSales(num))

  setCompareToRepTotalSales = (num) =>
    this.dispatch(actions.setCompareToRepTotalSales(num))

  setRepTotalSalesChartData = (arr) =>
    this.dispatch(actions.setRepTotalSalesChartData(arr))

  setRepTotalOrdersChartData = (arr) =>
    this.dispatch(actions.setRepTotalOrdersChartData(arr))

  setRepAvgOrderTotalsChartData = (arr) =>
    this.dispatch(actions.setRepAvgOrderTotalsChartData(arr))

  setRepAvgMarginsChartData = (arr) =>
    this.dispatch(actions.setRepAvgMarginsChartData(arr))
}
