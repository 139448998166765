import { connect } from 'react-redux'

import MLBInventoryPage from '@components/mlbPartners/MLBInventoryPage'

import coordinators from '@coordinators'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapDispatchToProps = () => {
  const { RestService } = services
  const { LoadMLBMenu } = coordinators

  const loadMLBMenu = LoadMLBMenu({ RestService, HandleError })

  return {
    loadMLBMenu,
  }
}

export default connect(undefined, mapDispatchToProps)(MLBInventoryPage)
