import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import { DateInput } from '@components/common/form'
import Modal from './Modal.js'

import camera from '@res/images/camera.svg'
import trash from '@res/images/trash-icon.png'

class MLBEditMenuPage extends Component {
  state = {
    menu: {
      kits: [],
      items: [],
    },
  }
  categories = null
  taxCodes = []

  componentDidMount() {
    this.loadMenu()
  }

  loadMenu = async () => {
    const { loadAllMLBItems, loadTaxCodes, mlbPartnerName } = this.props
    const menu = await loadAllMLBItems({ mlbPartnerName })
    const taxCodes = await loadTaxCodes({ mlbPartnerName })
    this.taxCodes = taxCodes
    const menuItemsCategorized = this.categorizeItems(menu.items, 'itemType')
    const itemsMap = menu.items.reduce((obj, item) => {
      obj[item.id] = item

      return obj
    }, {})
    this.setState({ menu, menuItemsCategorized, itemsMap })
  }

  categorizeItems = (items, property) => {
    let categories = items.reduce((acc, obj) => {
      const key = obj[property]
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)

      return acc
    }, {})
    categories = Object.entries(categories).sort((a, b) => {
      if (a[0] < b[0]) {
        return -1
      }
      if (a[0] > b[0]) {
        return 1
      }

      return 0
    })

    return categories
  }

  onSaveAddon = async () => {
    const { saveMLBMenuItem } = this.props
    const { selectedAddon } = this.state

    if (await saveMLBMenuItem(selectedAddon)) {
      this.loadMenu()
      this.setState({ showItemModal: false, selectedAddon: undefined })
    }
  }

  onSaveKitItem = async () => {
    const { saveMLBKit } = this.props
    const { selectedKit } = this.state

    if (await saveMLBKit(selectedKit)) {
      this.loadMenu()
      this.setState({ showKitModal: false, selectedKit: undefined })
    }
  }

  onDeleteAddon = async () => {
    const { deleteMLBMenuItem } = this.props
    const { selectedAddon } = this.state
    const { id, mlbPartnerId, name } = selectedAddon
    if (await deleteMLBMenuItem({ id, mlbPartnerId, name })) {
      this.loadMenu()
      this.setState({ showItemModal: false, selectedAddon: undefined })
    }
  }

  onDeleteKit = async () => {
    const { deleteMLBKit } = this.props
    const { selectedKit } = this.state
    const { id, mlbPartnerId, name } = selectedKit
    if (await deleteMLBKit({ id, mlbPartnerId, name })) {
      this.loadMenu()
      this.setState({ showKitModal: false, selectedKit: undefined })
    }
  }

  onAddAvailabilityWindow = (selectedKey) => () => {
    const selected = { ...this.state[selectedKey] }
    selected.availabilitySchedule = [
      ...selected.availabilitySchedule,
      {
        end: null,
        orderLimit: null,
        start: null,
      },
    ]
    this.setState({ [selectedKey]: selected })
  }

  onChangeAvailableWindow = ({ selectedKey, index, key, value }) => {
    const selected = { ...this.state[selectedKey] }
    selected['availabilitySchedule'][index][key] = value
    this.setState({ [selectedKey]: selected })
  }

  onDeleteAvailabilityWindow = (selectedKey, index) => () => {
    const selected = { ...this.state[selectedKey] }
    selected.availabilitySchedule.splice(index, 1)
    this.setState({ [selectedKey]: selected })
  }

  onAddSupply = (selectedKey) => () => {
    const selected = { ...this.state[selectedKey] }
    const supplies = selected.supplies || []
    selected.supplies = [
      ...supplies,
      {
        name: null,
        count: null,
      },
    ]
    this.setState({ [selectedKey]: selected })
  }

  onChangeSupply = ({ selectedKey, index, key, value }) => {
    const selected = { ...this.state[selectedKey] }
    selected['supplies'][index][key] = value
    this.setState({ [selectedKey]: { ...selected } })
  }

  onDeleteSupply = (selectedKey, index) => () => {
    const selected = { ...this.state[selectedKey] }
    selected.supplies.splice(index, 1)
    this.setState({ [selectedKey]: selected })
  }

  onChangeMenuItem = ({ key, value }) => {
    const { selectedAddon } = this.state
    selectedAddon[key] = value
    this.setState({ selectedAddon: { ...selectedAddon } })
  }

  onChangeKitData = ({ key, value }) => {
    const { selectedKit } = this.state
    selectedKit[key] = value
    this.setState({ selectedKit: { ...selectedKit } })
  }

  onChangeKitIncludedItem = ({ index, itemId }) => {
    const { selectedKit, itemsMap } = this.state
    const item = itemsMap[itemId]
    const inc = selectedKit.includedItems || []
    selectedKit.includedItems = [
      ...inc.slice(0, index),
      {
        ...inc[index],
        description: item.description,
        itemId: item.id,
        name: item.name,
      },
      ...inc.slice(index + 1, inc.length),
    ]
    this.setState({ selectedKit })
  }

  onChangeKitIncItemCount = ({ index, count }) => {
    const { selectedKit } = this.state
    const inc = selectedKit.includedItems || []
    selectedKit.includedItems = [
      ...inc.slice(0, index),
      {
        ...inc[index],
        count,
      },
      ...inc.slice(index + 1, inc.length),
    ]
    this.setState({ selectedKit })
  }

  onAddKitIncItem = () => {
    const { selectedKit } = this.state
    const inc = selectedKit.includedItems || []
    selectedKit.includedItems = [
      ...inc,
      {
        id: inc.length + 1,
        count: 0,
      },
    ]
    this.setState({ selectedKit })
  }

  onRemoveKitIncItem = (index) => {
    const { selectedKit } = this.state
    selectedKit.includedItems = [
      ...selectedKit.includedItems.slice(0, index),
      ...selectedKit.includedItems
        .slice(index + 1, selectedKit.includedItems.length)
        .map((it) => {
          return { ...it, id: it.id - 1 }
        }),
    ]
    this.setState({ selectedKit })
  }

  onChangeKitOption = ({ index, key, value }) => {
    const { selectedKit } = this.state
    const opts = selectedKit.itemOptions || []
    selectedKit.itemOptions = [
      ...opts.slice(0, index),
      {
        ...opts[index],
        [key]: value,
      },
      ...opts.slice(index + 1, opts.length),
    ]
    this.setState({ selectedKit })
  }

  onAddKitOption = () => {
    const { selectedKit } = this.state
    const opts = selectedKit.itemOptions || []
    selectedKit.itemOptions = [
      ...opts,
      {
        id: opts.length + 1,
        count: 0,
      },
    ]
    this.setState({ selectedKit })
  }

  onRemoveKitOption = (index) => {
    const { selectedKit } = this.state
    selectedKit.itemOptions = [
      ...selectedKit.itemOptions.slice(0, index),
      ...selectedKit.itemOptions
        .slice(index + 1, selectedKit.itemOptions.length)
        .map((it) => {
          return { ...it, id: it.id - 1 }
        }),
    ]
    this.setState({ selectedKit })
  }

  onChangeAddonImage = async (event) => {
    const { selectedAddon } = this.state
    if (!selectedAddon.image) {
      selectedAddon.image = {}
    }
    if (event.target.files && event.target.files[0]) {
      const { mlbPartnerName, uploadMLBMenuItemImg } = this.props
      const img = event.target.files[0]
      const preview = URL.createObjectURL(img)

      const item = await uploadMLBMenuItemImg({
        mlbPartnerName,
        id: selectedAddon.id,
        name: selectedAddon.name,
        file: img,
      })
      if (item) {
        this.setState({
          selectedAddon: item,
          addOnPreviewImage: preview,
        })
        this.loadMenu()
      }
    }
  }

  onChangeKitImage = async (event) => {
    const { selectedKit } = this.state
    if (!selectedKit.image) {
      selectedKit.image = {
        url: null,
      }
    }
    if (event.target.files && event.target.files[0]) {
      const { mlbPartnerName, uploadMLBMenuKitImg } = this.props
      const img = event.target.files[0]
      const preview = URL.createObjectURL(img)

      const kit = await uploadMLBMenuKitImg({
        mlbPartnerName,
        id: selectedKit.id,
        name: selectedKit.name,
        file: img,
      })
      if (kit) {
        this.setState({
          selectedKit: kit,
          kitPreviewImage: preview,
        })
        this.loadMenu()
      }
    }
  }

  renderIncludedKitItems = (kit) => {
    const { theme } = this.props
    let {
      menu: { items },
    } = this.state
    items = items.sort((a, b) => {
      if ((a.name || '') > (b.name || '')) {
        return 1
      }

      return -1
    })

    return (
      <div>
        {(kit.includedItems || []).map((item, index) => (
          <div key={index}>
            <div className="flex flex-col justify-between w-full mb-3">
              <div className="mlb-input-container mr-2">
                <select
                  className="mlb-dropdown"
                  value={item.itemId}
                  onChange={(e) =>
                    this.onChangeKitIncludedItem({
                      index,
                      itemId: e.target.value,
                    })
                  }
                >
                  <option />
                  {items.map((it) => (
                    <option key={it.id} value={it.id}>
                      {it.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex items-center mt-2">
                <div className="mlb-input-container w-24">
                  <label style={{ color: theme.textColor }}>QTY</label>
                  <input
                    type="number"
                    value={item.count || null}
                    onChange={(e) =>
                      this.onChangeKitIncItemCount({
                        index,
                        count: Math.trunc(parseFloat(e.target.value)),
                      })
                    }
                  />
                </div>
                <img
                  onClick={() => this.onRemoveKitIncItem(index)}
                  className="ml-2 table-trash"
                  src={trash}
                />
              </div>
            </div>
            <div className="h-5 w-full" />
          </div>
        ))}
        <button
          style={{ borderColor: theme.primaryColor, color: theme.primaryColor }}
          className="mb-5 mlb-modal-button self-start"
          onClick={this.onAddKitIncItem}
        >
          Add Another Pack Item
        </button>
      </div>
    )
  }

  renderKitOptions = (kit) => {
    const { theme } = this.props
    const { menuItemsCategorized } = this.state
    const itemTypes = menuItemsCategorized
      .map((c) => c[0])
      .sort((a, b) => {
        if ((a.name || '') > (b.name || '')) {
          return 1
        }

        return -1
      })

    return (
      <div>
        {(kit.itemOptions || []).map((option, index) => (
          <div key={index}>
            <div className="flex flex-col justify-between w-full mb-3">
              <div className="mlb-input-container mr-2">
                <select
                  className="mlb-dropdown"
                  value={option.itemType}
                  onChange={(e) =>
                    this.onChangeKitOption({
                      index,
                      key: 'itemType',
                      value: e.target.value,
                    })
                  }
                >
                  <option />
                  {itemTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex items-center mt-2">
                <div className="mlb-input-container w-24">
                  <label style={{ color: theme.textColor }}>QTY</label>
                  <input
                    type="number"
                    value={option.count || null}
                    onChange={(e) =>
                      this.onChangeKitOption({
                        index,
                        key: 'count',
                        value: Math.trunc(parseFloat(e.target.value)),
                      })
                    }
                  />
                </div>
                <img
                  onClick={() => this.onRemoveKitOption(index)}
                  className="ml-2 table-trash"
                  src={trash}
                />
              </div>
            </div>
            <div className="h-5 w-full" />
          </div>
        ))}
        <button
          style={{ borderColor: theme.primaryColor, color: theme.primaryColor }}
          className="mb-5 mlb-modal-button self-start"
          onClick={this.onAddKitOption}
        >
          Add Another Pack Option
        </button>
      </div>
    )
  }

  renderAvailabilityWindow = ({ avail, index, selectedKey }) => {
    const { theme } = this.props

    return (
      <div key={index} className="flex justify-between mb-5 items-center">
        <div className="mlb-input-container w-3/10">
          <label style={{ color: theme.textColor }}>Available From</label>
          <DateInput
            className="w-full bg-white"
            style={{ borderColor: theme.primaryColor }}
            date={avail.start ? Moment(avail.start) : ''}
            dateFormat="default"
            isStatic={false}
            onChange={(date) =>
              this.onChangeAvailableWindow({
                selectedKey,
                index,
                key: 'start',
                value: date,
              })
            }
          />
        </div>

        <div className="mlb-input-container w-3/10">
          <label style={{ color: theme.textColor }}>To (Optional)</label>
          <DateInput
            className="w-full bg-white"
            date={avail.end ? Moment(avail.end) : ''}
            dateFormat="default"
            isStatic={false}
            onChange={(date) =>
              this.onChangeAvailableWindow({
                selectedKey,
                index,
                key: 'end',
                value: date,
              })
            }
          />
        </div>
        <div className="mlb-input-container w-3/10">
          <label style={{ color: theme.textColor }}>QTY. Available</label>
          <input
            type="number"
            value={avail.orderLimit}
            onChange={(e) => {
              const value = parseFloat(e.target.value)
              this.onChangeAvailableWindow({
                selectedKey,
                index,
                key: 'orderLimit',
                value,
              })
            }}
          />
        </div>
        <img
          onClick={this.onDeleteAvailabilityWindow(selectedKey, index)}
          className="table-trash"
          src={trash}
        />
      </div>
    )
  }

  renderSupply = ({ supply, index, selectedKey }) => {
    const { theme } = this.props

    return (
      <div key={index} className="flex justify-between mb-5 items-center">
        <div className="mlb-input-container w-2/5">
          <label style={{ color: theme.textColor }}>Count</label>
          <input
            type="number"
            className="w-full"
            value={supply.count}
            onChange={(e) => {
              const value = parseFloat(e.target.value)
              this.onChangeSupply({
                selectedKey,
                index,
                key: 'count',
                value,
              })
            }}
          />
        </div>

        <div className="mlb-input-container w-2/5">
          <label style={{ color: theme.textColor }}>Name</label>
          <input
            type="text"
            className="w-full"
            value={supply.name}
            onChange={(e) => {
              this.onChangeSupply({
                selectedKey,
                index,
                key: 'name',
                value: e.target.value,
              })
            }}
          />
        </div>
        <img
          onClick={this.onDeleteSupply(selectedKey, index)}
          className="table-trash"
          src={trash}
        />
      </div>
    )
  }

  renderIsAddOnToggle = () => {
    const { theme } = this.props
    const { selectedAddon } = this.state

    return (
      <div>
        <label className="mlb-radio-button">
          This is an add on item
          <input
            type="radio"
            name="radio"
            checked={selectedAddon.addOn}
            onClick={() =>
              this.onChangeMenuItem({
                key: 'addOn',
                value: true,
              })
            }
          />
          <span
            style={{
              background: selectedAddon.addOn ? theme.primaryColor : '',
            }}
            className="checkmark"
          />
        </label>
        <label className="mb-5 mlb-radio-button">
          This item is part of a pack
          <input
            type="radio"
            name="radio"
            checked={!selectedAddon.addOn}
            onClick={() =>
              this.onChangeMenuItem({
                key: 'addOn',
                value: false,
              })
            }
          />
          <span
            style={{
              background: !selectedAddon.addOn ? theme.primaryColor : '',
            }}
            className="checkmark"
          />
        </label>
      </div>
    )
  }

  renderItemModal = () => {
    const { theme } = this.props
    const { selectedAddon, addOnPreviewImage } = this.state

    return (
      <Modal
        title={selectedAddon.id ? 'Edit Item' : 'Add New Item'}
        hideModal={() =>
          this.setState({
            showItemModal: false,
            selectedAddon: null,
            addOnPreviewImage: null,
          })
        }
        color={theme.primaryColor}
      >
        <div className="mlb-modal__inner flex flex-col">
          {this.renderIsAddOnToggle()}
          <div className="mlb-input-container">
            <label style={{ color: theme.textColor }}>Item Name</label>
            <textarea
              type="text"
              value={selectedAddon.name}
              onChange={(e) =>
                this.onChangeMenuItem({
                  key: 'name',
                  value: e.target.value,
                })
              }
            />
          </div>
          <div className="w-full h-5" />
          <div className="mlb-input-container">
            <label style={{ color: theme.textColor }}>
              Category (ex: Desserts, Alcoholic Beverages, Snacks)
            </label>
            <label
              style={{
                color: theme.textColor,
              }}
            >
              <p className="mb-5 text-md">
                Categories determine how add ons are grouped and categorized in
                the marketplace as well as what groups of items are available as
                selectable Pack Options
              </p>
            </label>
            <textarea
              type="text"
              value={selectedAddon.itemType}
              onChange={(e) =>
                this.onChangeMenuItem({
                  key: 'itemType',
                  value: e.target.value,
                })
              }
            />
          </div>
          <div className="w-full h-5" />
          <div className="mlb-input-container">
            <label style={{ color: theme.textColor }}>Description</label>
            <textarea
              type="text"
              style={{ height: '100px' }}
              value={selectedAddon.description}
              onChange={(e) =>
                this.onChangeMenuItem({
                  key: 'description',
                  value: e.target.value,
                })
              }
            />
          </div>

          <div className="w-full h-5" />
          <div className="flex justify-between">
            <div className="mlb-input-container w-48%">
              <label style={{ color: theme.textColor }}>Price ($)</label>
              <input
                type="number"
                value={selectedAddon.price}
                onChange={(e) => {
                  const value = parseFloat(e.target.value)
                  this.onChangeMenuItem({
                    key: 'price',
                    value,
                  })
                }}
              />
            </div>

            <div className="mlb-input-container w-48%">
              <label style={{ color: theme.textColor }}>
                Serves (Ex: 2 to 2, 3 to 4)
              </label>
              <div className="flex items-center">
                <input
                  type="number"
                  value={selectedAddon.minServings}
                  className="w-48%"
                  onChange={(e) => {
                    const value = Math.trunc(parseFloat(e.target.value))
                    this.onChangeMenuItem({
                      key: 'minServings',
                      value,
                    })
                  }}
                />
                <p style={{ color: theme.textColor }} className="bold px-3">
                  TO
                </p>
                <input
                  type="number"
                  value={selectedAddon.maxServings}
                  className="w-48%"
                  onChange={(e) => {
                    const value = Math.trunc(parseFloat(e.target.value))
                    this.onChangeMenuItem({
                      key: 'maxServings',
                      value,
                    })
                  }}
                />
              </div>
            </div>
          </div>

          <div className="w-full h-5" />
          <div className="flex justify-between">
            <div className="mlb-input-container w-48%">
              <label style={{ color: theme.textColor }}>Item Tax Code</label>
              <select
                className="mlb-dropdown"
                value={selectedAddon.taxCode}
                onChange={(e) =>
                  this.onChangeMenuItem({
                    key: 'taxCode',
                    value: e.target.value,
                  })
                }
              >
                <option />
                {Object.entries(this.taxCodes).map(([desc, code]) => (
                  <option key={code} value={code}>
                    {desc}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-48% flex items-center mt-4">
              <input
                type="checkbox"
                className="mr-3 mt-0"
                checked={selectedAddon.hasAlcohol}
                onChange={(e) =>
                  this.onChangeMenuItem({
                    key: 'hasAlcohol',
                    value: e.target.checked,
                  })
                }
              />
              <p
                className="bold mt-1 uppercase ml-2"
                style={{ color: theme.textColor }}
              >
                Has Alcohol?
              </p>
            </div>
          </div>

          <div className="w-full h-5" />
          <div className="flex justify-between">
            <div className="mlb-input-container w-48%">
              <label style={{ color: theme.textColor }}>
                Display Order (ex: 1, 2, 3)
              </label>
              <p className="mb-5 text-md">
                Determines the order that items are displayed in their category
                in the Marketplace.
              </p>
              <input
                type="number"
                value={selectedAddon.displayOrder}
                className="w-48%"
                onChange={(e) => {
                  const value = Math.trunc(parseFloat(e.target.value))
                  this.onChangeMenuItem({
                    key: 'displayOrder',
                    value,
                  })
                }}
              />
            </div>

            <div className="mlb-input-container w-48%">
              <input
                type="checkbox"
                className="mr-3 mt-0"
                checked={selectedAddon.mailable}
                onChange={(e) =>
                  this.onChangeMenuItem({
                    key: 'mailable',
                    value: e.target.checked,
                  })
                }
              />
              <p
                className="bold mt-1 uppercase ml-2"
                style={{ color: theme.textColor }}
              >
                Mailable?
              </p>

              <input
                type="checkbox"
                className="mr-3 mt-0"
                checked={selectedAddon.isEnabled}
                onChange={(e) =>
                  this.onChangeMenuItem({
                    key: 'isEnabled',
                    value: e.target.checked,
                  })
                }
              />
              <p
                className="bold mt-1 uppercase ml-2"
                style={{ color: theme.textColor }}
              >
                Enabled?
              </p>
            </div>
          </div>

          <div className="w-full h-5" />
          <h2
            style={{ color: theme.textColor }}
            className="mlb-modal-subtitle mt-8 mb-1"
          >
            Select Item Availability Window
          </h2>
          <p className="mb-5 text-md">
            Leave the “To” field blank if item is always available. Also enter
            quantity available during each period.
          </p>
          {selectedAddon.availabilitySchedule.map((avail, index) =>
            this.renderAvailabilityWindow({
              avail,
              index,
              selectedKey: 'selectedAddon',
            }),
          )}
          <button
            onClick={this.onAddAvailabilityWindow('selectedAddon')}
            style={{
              borderColor: theme.primaryColor,
              color: theme.primaryColor,
            }}
            className="mb-5 mlb-modal-button self-start"
          >
            Add Availability Window
          </button>

          <h2
            style={{ color: theme.textColor }}
            className="mlb-modal-subtitle mb-1"
          >
            Item Supplies
          </h2>
          {(selectedAddon.supplies || []).map((supply, index) =>
            this.renderSupply({ supply, index, selectedKey: 'selectedAddon' }),
          )}
          <button
            onClick={this.onAddSupply('selectedAddon')}
            style={{
              borderColor: theme.primaryColor,
              color: theme.primaryColor,
            }}
            className="mb-5 mlb-modal-button self-start"
          >
            Add Supply
          </button>

          {selectedAddon.id && (
            <div className="mb-5">
              <div className="w-full h-5" />
              <p className="bold uppercase" style={{ color: theme.textColor }}>
                Upload Image (Click to Change)
              </p>
              <p className="mb-5 text-md">Images must be less than 1MB</p>
              <div className="mlb-item-image">
                <img className="camera" src={camera} />
                {selectedAddon.image && selectedAddon.image && (
                  <img src={addOnPreviewImage || selectedAddon.image} />
                )}
                <input
                  className="mlb-image-uploader"
                  type="file"
                  onChange={this.onChangeAddonImage}
                />
              </div>
            </div>
          )}

          <div className="flex justify-end">
            {selectedAddon.id && (
              <button
                onClick={this.onDeleteAddon}
                className="mt-8 mlb-modal-delete self-end"
              >
                Delete Item
              </button>
            )}
            <button
              onClick={this.onSaveAddon}
              style={{ backgroundColor: theme.primaryColor }}
              className="mt-5 mlb-modal-save self-end"
            >
              Save Changes
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  renderKitModal = () => {
    const { theme } = this.props
    const { selectedKit, kitPreviewImage } = this.state

    return (
      <Modal
        title={selectedKit.id ? 'Edit Pack' : 'Add New Pack'}
        hideModal={() =>
          this.setState({
            showKitModal: false,
            selectedKit: null,
            kitPreviewImage,
          })
        }
        color={theme.primaryColor}
      >
        <div className="mlb-modal__inner flex flex-col">
          <div className="mlb-input-container">
            <label style={{ color: theme.textColor }}>Pack Name</label>
            <textarea
              type="text"
              value={selectedKit.name}
              onChange={(e) =>
                this.onChangeKitData({
                  key: 'name',
                  value: e.target.value,
                })
              }
            />
          </div>
          <div className="w-full h-5" />
          <div className="mlb-input-container">
            <label style={{ color: theme.textColor }}>Description</label>
            <textarea
              type="text"
              style={{ height: '100px' }}
              value={selectedKit.description}
              onChange={(e) =>
                this.onChangeKitData({
                  key: 'description',
                  value: e.target.value,
                })
              }
            />
          </div>
          <div className="w-full h-5" />
          <div className="flex justify-between">
            <div className="mlb-input-container w-48%">
              <label style={{ color: theme.textColor }}>Price ($)</label>
              <input
                type="number"
                value={selectedKit.price}
                onChange={(e) => {
                  const value = parseFloat(e.target.value)
                  this.onChangeKitData({
                    key: 'price',
                    value,
                  })
                }}
              />
            </div>

            <div className="mlb-input-container w-48%">
              <label style={{ color: theme.textColor }}>
                Serves (Ex: 2 to 2, 3 to 4)
              </label>
              <div className="flex items-center">
                <input
                  type="number"
                  value={selectedKit.minServings}
                  className="w-48%"
                  onChange={(e) => {
                    const value = Math.trunc(parseFloat(e.target.value))
                    this.onChangeKitData({
                      key: 'minServings',
                      value,
                    })
                  }}
                />
                <p style={{ color: theme.textColor }} className="bold px-3">
                  TO
                </p>
                <input
                  type="number"
                  value={selectedKit.maxServings}
                  className="w-48%"
                  onChange={(e) => {
                    const value = Math.trunc(parseFloat(e.target.value))
                    this.onChangeKitData({
                      key: 'maxServings',
                      value,
                    })
                  }}
                />
              </div>
            </div>
          </div>

          <div className="w-full h-5" />
          <div className="flex justify-between">
            <div className="mlb-input-container w-48% justify-between">
              <label style={{ color: theme.textColor }}>Item Tax Code</label>
              <select
                className="mlb-dropdown"
                value={selectedKit.taxCode}
                onChange={(e) =>
                  this.onChangeKitData({
                    key: 'taxCode',
                    value: e.target.value,
                  })
                }
              >
                <option />
                {Object.entries(this.taxCodes).map(([desc, code]) => (
                  <option key={code} value={code}>
                    {desc}
                  </option>
                ))}
              </select>

              <div className="w-full h-5" />
              <label style={{ color: theme.textColor }}>
                Display Order (ex: 1, 2, 3)
              </label>
              <p className="mb-1 text-md">
                Determines the order that items are displayed in their category
                in the Marketplace.
              </p>
              <input
                type="number"
                value={selectedKit.displayOrder}
                className="w-48%"
                onChange={(e) => {
                  const value = Math.trunc(parseFloat(e.target.value))
                  this.onChangeKitData({
                    key: 'displayOrder',
                    value,
                  })
                }}
              />
            </div>
            <div className="w-48% flex flex-col mt-4">
              <input
                type="checkbox"
                className="mr-3 mt-0"
                checked={selectedKit.hasAlcohol}
                onChange={(e) =>
                  this.onChangeKitData({
                    key: 'hasAlcohol',
                    value: e.target.checked,
                  })
                }
              />
              <p
                className="bold mt-1 uppercase ml-2"
                style={{ color: theme.textColor }}
              >
                Has Alcohol?
              </p>

              <input
                type="checkbox"
                className="mr-3 mt-0"
                checked={selectedKit.mailable}
                onChange={(e) =>
                  this.onChangeKitData({
                    key: 'mailable',
                    value: e.target.checked,
                  })
                }
              />
              <p
                className="bold mt-1 uppercase ml-2"
                style={{ color: theme.textColor }}
              >
                Mailable?
              </p>

              <input
                type="checkbox"
                className="mr-3 mt-0"
                checked={selectedKit.isEnabled}
                onChange={(e) =>
                  this.onChangeKitData({
                    key: 'isEnabled',
                    value: e.target.checked,
                  })
                }
              />
              <p
                className="bold mt-1 uppercase ml-2"
                style={{ color: theme.textColor }}
              >
                Enabled?
              </p>
            </div>
          </div>

          {selectedKit.id && (
            <div className="mb-3">
              <div className="w-full h-5" />
              <p className="bold uppercase" style={{ color: theme.textColor }}>
                Upload Image (Click to Change)
              </p>
              <p className="mb-5 text-md">Images must be less than 1MB</p>
              <div className="mlb-item-image">
                <img className="camera" src={camera} />
                {selectedKit.image && selectedKit.image.url && (
                  <img src={kitPreviewImage || selectedKit.image.url} />
                )}
                <input
                  className="mlb-image-uploader"
                  type="file"
                  onChange={this.onChangeKitImage}
                />
              </div>
            </div>
          )}

          <h2
            style={{ color: theme.textColor }}
            className="mlb-modal-subtitle mb-3"
          >
            Included Items
          </h2>
          {this.renderIncludedKitItems(selectedKit)}

          <div className="w-full h-5" />
          <h2
            style={{ color: theme.textColor }}
            className="mlb-modal-subtitle mb-3"
          >
            Item Choices
          </h2>
          {this.renderKitOptions(selectedKit)}

          <h2
            style={{ color: theme.textColor }}
            className="mlb-modal-subtitle mb-1"
          >
            Select Pack Availability Window
          </h2>
          <p className="mb-5 text-md">
            Leave the “To” field blank if item is always available and enter
            quantity available during each period.
          </p>
          {selectedKit.availabilitySchedule.map((avail, index) =>
            this.renderAvailabilityWindow({
              avail,
              index,
              selectedKey: 'selectedKit',
            }),
          )}
          <button
            onClick={this.onAddAvailabilityWindow('selectedKit')}
            style={{
              borderColor: theme.primaryColor,
              color: theme.primaryColor,
            }}
            className="mb-5 mlb-modal-button self-start"
          >
            Add Availability Window
          </button>

          <h2
            style={{ color: theme.textColor }}
            className="mlb-modal-subtitle mb-1"
          >
            Pack Supplies
          </h2>
          {(selectedKit.supplies || []).map((supply, index) =>
            this.renderSupply({ supply, index, selectedKey: 'selectedKit' }),
          )}
          <button
            onClick={this.onAddSupply('selectedKit')}
            style={{
              borderColor: theme.primaryColor,
              color: theme.primaryColor,
            }}
            className="mb-5 mlb-modal-button self-start"
          >
            Add Supply
          </button>

          <div className="flex justify-end">
            {selectedKit.id && (
              <button
                onClick={this.onDeleteKit}
                className="mt-8 mlb-modal-delete self-end"
              >
                Delete Pack
              </button>
            )}
            <button
              onClick={this.onSaveKitItem}
              style={{ backgroundColor: theme.primaryColor }}
              className="mt-5 mlb-modal-save self-end"
            >
              Save Changes
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  renderServings = (item) => {
    if (item.minServings && item.maxServings) {
      if (item.minServings === item.maxServings) {
        return item.minServings
      } else {
        return `${item.minServings} to ${item.maxServings}`
      }
    } else {
      return false
    }
  }

  render() {
    const { theme, mlbPartnerName } = this.props
    const {
      menu: { kits },
      menuItemsCategorized,
      showItemModal,
      showKitModal,
    } = this.state
    const kitHeadings = ['Image', 'Name', 'Price', 'Serves', 'Enabled', '']
    const itemHeadings = [
      'Image',
      'Name',
      'Price',
      'Serves',
      'Enabled',
      'Add On?',
      '',
    ]

    return (
      <div className="mlb-menu-container flex flex-col">
        {showItemModal && this.renderItemModal()}
        {showKitModal && this.renderKitModal()}

        <div className="mlb-menu-section">
          <div className="mlb-menu-section-heading">
            <p>Packs</p>
            <button
              onClick={() =>
                this.setState({
                  showKitModal: true,
                  selectedKit: {
                    mlbPartnerId: mlbPartnerName,
                    availabilitySchedule: [],
                    includedItems: [],
                    itemOptions: [],
                  },
                })
              }
              className="mlb-add-item"
              style={{ backgroundColor: theme.primaryColor }}
            >
              + Add New Pack
            </button>
          </div>
          <table className="mlb-menu-table mt-3">
            <tr>
              {kitHeadings.map((h) => (
                <th key={h}>{h}</th>
              ))}
            </tr>
            {kits.map((k) => (
              <tr key={k.id}>
                <td>
                  <div className="mlb-table-image">
                    <img className="camera" src={camera} />
                    {k.image && <img src={k.image.url} />}
                  </div>
                </td>
                <td>
                  <p>{k.name}</p>
                </td>
                <td>
                  <p>${k.price}</p>
                </td>
                <td>
                  <p>{this.renderServings(k)}</p>
                </td>
                <td>{k.isEnabled ? 'Enabled' : 'Disabled'}</td>
                <td style={{ width: '60px' }}>
                  <button
                    className="mlb-edit-item-button"
                    onClick={() =>
                      this.setState({
                        showKitModal: true,
                        selectedKit: { ...k },
                      })
                    }
                    style={{
                      color: theme.textColor,
                      borderColor: theme.primaryColor,
                    }}
                  >
                    edit
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>
        {menuItemsCategorized &&
          menuItemsCategorized.map((c) => (
            <div key={c[0]} className="mlb-menu-section mt-10">
              <div className="mlb-menu-section-heading">
                <p className="flex">{c[0]}</p>
                <button
                  onClick={() =>
                    this.setState({
                      showItemModal: true,
                      selectedAddon: {
                        availabilitySchedule: [],
                        itemType: c[0],
                        mlbPartnerId: mlbPartnerName,
                      },
                    })
                  }
                  className="mlb-add-item"
                  style={{ backgroundColor: theme.primaryColor }}
                >
                  + Add New Item
                </button>
              </div>
              <table className="mlb-menu-table mt-3">
                <tr>
                  {itemHeadings.map((h) => (
                    <th key={h}>{h}</th>
                  ))}
                </tr>
                {c[1].map((k) => (
                  <tr key={k.id}>
                    <td>
                      <div className="mlb-table-image">
                        <img className="camera" src={camera} />
                        {k.image && <img src={k.image} />}
                      </div>
                    </td>
                    <td>
                      <p>{k.name}</p>
                    </td>
                    <td>
                      <p>${k.price}</p>
                    </td>
                    <td>
                      <p>{this.renderServings(k)}</p>
                    </td>
                    <td>{k.isEnabled ? 'Enabled' : 'Disabled'}</td>
                    <td>{k.addOn ? 'Yes' : 'No'}</td>
                    <td style={{ width: '60px' }}>
                      <button
                        onClick={() =>
                          this.setState({
                            showItemModal: true,
                            selectedAddon: { ...k },
                          })
                        }
                        className="mlb-edit-item-button"
                        style={{
                          color: theme.textColor,
                          borderColor: theme.primaryColor,
                        }}
                      >
                        edit
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          ))}
      </div>
    )
  }
}

MLBEditMenuPage.propTypes = {
  defaultDate: PropTypes.object,
  mlbPartnerId: PropTypes.string,
  mlbPartnerName: PropTypes.string,
  theme: PropTypes.object,

  deleteMLBMenuItem: PropTypes.func,
  deleteMLBKit: PropTypes.func,
  loadAllMLBItems: PropTypes.func,
  loadTaxCodes: PropTypes.func,
  saveMLBMenuItem: PropTypes.func,
  saveMLBKit: PropTypes.func,
  uploadMLBMenuItemImg: PropTypes.func,
  uploadMLBMenuKitImg: PropTypes.func,
}

export default MLBEditMenuPage
