import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

import { ordersCSVColumns, workwaveCSVColumns } from './constants'
import { DateInput } from '@components/common/form'
import { ExportCSVButton } from '@components/dashboard'
import { pMBLOrdersCSV, pMBLWorkwaveCSV } from '@presenters/api/mlbPartners'

class MLBOrdersPage extends Component {
  state = {
    gameDay: undefined,
    mlbPartner: undefined,
    singleOrderIdx: undefined,
  }

  componentDidMount() {
    const { mlbPartner } = this.props
    if (mlbPartner) {
      const nextGameDay = mlbPartner.calendar.find((c) =>
        c.date.isAfter(Moment()),
      )
      this.setState({ mlbPartner, gameDay: nextGameDay && nextGameDay.date })
    }
  }

  showSingleOrder = (singleOrderIdx) => () => {
    this.setState({ singleOrderIdx })
    this.props.setShowSidebar(false)
  }

  showAllOrders = () => {
    this.setState({ singleOrderIdx: undefined })
    this.props.setShowSidebar(true)
  }

  render() {
    const { gameDay, mlbPartner, singleOrderIdx } = this.state
    let { orders } = this.props

    if (!mlbPartner) {
      return null
    }

    const { depot } = mlbPartner
    const gameDaySched =
      gameDay &&
      mlbPartner.calendar.find(
        (sched) =>
          sched.date.format('YYYY-MM-DD') === gameDay.format('YYYY-MM-DD'),
      )
    const singleOrder = singleOrderIdx != undefined
    if (singleOrder) {
      orders = [orders[singleOrderIdx]]
    }
    const subtotal = orders.reduce(
      (ttl, order) => ttl + parseFloat(order.subtotal),
      0.0,
    )

    return (
      <div className="mlb-panel">
        {!singleOrder && (
          <div className="flex mb-6 justify-between align-center">
            <div className="flex flex-row">
              <div className="flex flex-col">
                <div>
                  {!depot && 'No active pickup address'}
                  {
                    <div className="mb-5">
                      <p className="bold">Pickup Address: </p>
                      <p>
                        {depot.address.line1}, {depot.address.line2}
                        {depot.address.city}, {depot.address.state}{' '}
                        {depot.address.zip}
                      </p>
                    </div>
                  }
                </div>
                <div className="mr-2 bold">
                  {gameDay && (
                    <ExportCSVButton
                      text="Export Orders CSV"
                      filename={`${mlbPartner.name} MLB Orders ${gameDay.format(
                        'MM-DD-YYYY',
                      )}.csv`}
                      pCSVItems={(order) => order}
                      data={pMBLOrdersCSV(orders)}
                      columns={ordersCSVColumns}
                    />
                  )}
                </div>
                <div className="mr-2 mt-2 bold">
                  {gameDay && (
                    <ExportCSVButton
                      text="Export Workwave CSV"
                      filename={`${
                        mlbPartner.name
                      } MLB Workwave ${gameDay.format('MM-DD-YYYY')}.csv`}
                      pCSVItems={(order) => order}
                      data={pMBLWorkwaveCSV(orders)}
                      columns={workwaveCSVColumns}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row">
              <div>
                <p className="mb-1 bold">Game Day</p>
                <DateInput
                  className="w-full"
                  date={gameDay}
                  dateFormat="default"
                  isStatic={false}
                  onChange={(gameDay) =>
                    this.setState({ gameDay }, this.props.setGameDay(gameDay))
                  }
                />
                <div>
                  <p className="mt-2">
                    <span className="bold">Orders:</span> {orders.length}
                  </p>
                  <p className="mt-1">
                    <span className="bold">Gross Subtotal: </span>${subtotal}
                  </p>
                  <p className="mt-1">
                    <span className="bold">Cutoff:</span>{' '}
                    {gameDaySched &&
                      gameDaySched.cutoff.format('M-D-YYYY h:mma z')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {singleOrder && (
          <div className="flex mb-6 justify-between align-center">
            <button
              className="mlb-modal-button self-start"
              onClick={this.showAllOrders}
            >
              All Orders
            </button>
          </div>
        )}
        <div>
          <table className="w-full border partners-table">
            <tr className="bg-gray-200">
              <th className="py-1 pr-4 bold uppercase">Delivery</th>
              <th className="py-1 pr-4 bold uppercase">Packs</th>
              <th className="py-1 pr-4 bold uppercase">Add Ons</th>
            </tr>
            {orders.map((order, i) => (
              <tr key={i}>
                <td className="pr-4">
                  <div
                    className="bold mb-1 text-blue-700 underline"
                    onClick={this.showSingleOrder(i)}
                  >
                    {order.number}
                    <br />
                  </div>
                  <div className="mt-1">Created At:</div>
                  <div className="">
                    {order.createdAt &&
                      Moment(order.createdAt).format('MM-DD-YYYY h:mma Z')}
                  </div>

                  <div>
                    {order.legalName || `${order.firstName} ${order.lastName}`}
                  </div>
                  <div>
                    {order.dropoffAddress.line1}, {order.dropoffAddress.line2}
                  </div>
                  <div>
                    {order.dropoffAddress.city}, {order.dropoffAddress.state}{' '}
                    {order.dropoffAddress.zip}
                  </div>
                  <div>
                    {order.dateOfBirth && `Check ID: ${order.dateOfBirth}`}
                  </div>

                  <div className="mt-3 underline">Contact Info:</div>
                  <div className="">{order.clientEmail}</div>
                  <div className="">{order.phone}</div>
                </td>
                <td className="pr-4">
                  {(order.kits || []).map((kit, j) => (
                    <div key={j} className="mb-5">
                      {kit.quantity}x {kit.name}
                      {(kit.kitItems || []).map((kitItem, k) => (
                        <div key={k}>
                          &emsp;{`- ${kitItem.quantity}x ${kitItem.name}`}
                          {(kitItem.supplies || []).map((supply, l) => (
                            <div key={l}>
                              &emsp;&emsp;{`+ ${supply.count}x ${supply.name}`}
                            </div>
                          ))}
                        </div>
                      ))}
                      {(kit.supplies || []).map((supply, k) => (
                        <div key={k}>
                          &emsp;{`+ ${supply.count}x ${supply.name}`}
                        </div>
                      ))}
                    </div>
                  ))}
                  {!order.kits && 'No kits'}
                </td>
                <td className="pr-4">
                  {(order.addons || []).map((addon, j) => (
                    <div key={j}>
                      {addon.quantity}x {addon.name}
                      {(addon.supplies || []).map((supply, k) => (
                        <div key={k}>
                          &emsp;{`+ ${supply.count}x ${supply.name}`}
                        </div>
                      ))}
                    </div>
                  ))}
                  {!order.addons && 'No add ons'}
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    )
  }
}

MLBOrdersPage.propTypes = {
  mlbPartner: PropTypes.object,
  orders: PropTypes.array,

  loadMLBPartner: PropTypes.func,
  setGameDay: PropTypes.func,
  setShowSidebar: PropTypes.func,
}

export default MLBOrdersPage
