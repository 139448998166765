import { connect } from 'react-redux'

import { ChefPage } from '@components/chef'

import coordinators from '@coordinators'
import services from '@services'

const mapStateToProps = (state) => {
  const chef = state.chef || {}
  const chefName = [chef.firstName, chef.lastName].filter((n) => n).join(' ')

  return { chefName }
}

const mapDispatchToProps = () => {
  const { ChefService, UIService } = services
  const { NewChef } = coordinators

  const newChef = NewChef({ ChefService, UIService })

  return { newChef }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChefPage)
