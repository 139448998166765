import React, { Component } from 'react'
import PropTypes from 'prop-types'
import YSpacing from '@components/common/YSpacing'
import { Input, LinkText, Checkbox, Button } from '@components/common/form'
import AutocompleteInput from '@containers/common/form/AutocompleteInput'
import { FormContainer } from '@res/styledComponents/index'

class ContactInput extends Component {
  state = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    purchaserTaxStatus: null,
    title: '',
    hubspotContact: null,
    isReferralPartner: false,
    // unmutated
    id: undefined,
  }

  componentDidMount() {
    const { contact } = this.props
    this.setState({ ...contact })
    contact.id && this.props.loadHubspotContact(contact.id)
  }

  componentWillReceiveProps(nextProps) {
    const { contact, hubspotContact } = nextProps
    if (contact !== this.props.contact) {
      this.setState({ ...contact })
    }
    if (hubspotContact !== this.props.hubspotContact) {
      this.setState({ hubspotContact })
    }
  }

  onInput = (key) => (e) => {
    const { onChange, pBuildContact } = this.props
    this.setState({ [key]: e.target.value }, () => {
      const contact = pBuildContact(this.state)
      contact.existing = this.state.existing || false
      onChange && onChange(contact)
    })
  }

  onInputPhoneNumber = (e) => {
    const { onChange, pBuildContact } = this.props
    let phoneNumber = e.target.value
    phoneNumber = phoneNumber.replace(/[^0-9#()\- ]/g, '')
    this.setState({ phoneNumber }, () => {
      const contact = pBuildContact(this.state)
      onChange && onChange(contact)
    })
  }

  onInputTaxStatus = (e) => {
    const { onChange, pBuildContact } = this.props
    let taxStatus
    switch (e.target.value) {
      case 'true':
        taxStatus = true
        break
      case 'false':
        taxStatus = false
        break
      default:
        taxStatus = null
    }
    this.setState({ purchaserTaxStatus: taxStatus }, () => {
      const contact = pBuildContact(this.state)
      contact.existing = this.state.existing || false
      onChange && onChange(contact)
    })
  }

  onSelectHubspotContact = (c) => {
    this.setState({ hubspotContact: c }, () =>
      this.props.onSelectHubspotContact(this.props.contact.id, c.id, c.name),
    )
  }

  onChangeCheckbox = (e) => {
    const { onChange, pBuildContact } = this.props
    const { checked, value: key } = e.target
    this.setState({ [key]: checked }, () => {
      const contact = pBuildContact(this.state)
      contact.existing = this.state.existing || false
      onChange && onChange(contact)
    })
  }

  onCancel = () => {
    this.props.onCancel()
  }

  render() {
    const { errors, loadHubspotContacts, hasBackground, onSave } = this.props
    const {
      id,
      email,
      firstName,
      lastName,
      hubspotContact,
      phoneNumber,
      title,
      isReferralPartner,
    } = this.state

    return (
      <FormContainer hasBackground={hasBackground}>
        <Input
          label="Title"
          testId="title"
          value={title}
          onChange={this.onInput('title')}
        />
        <Input
          label="First Name"
          testId="first-name"
          value={firstName}
          onChange={this.onInput('firstName')}
        />
        <Input
          label="Last Name"
          testId="last-name"
          value={lastName}
          onChange={this.onInput('lastName')}
        />
        <Input
          label="Phone #"
          testId="phone-number"
          value={phoneNumber}
          onChange={this.onInputPhoneNumber}
        />
        <Input
          label="Email"
          testId="email"
          value={email}
          error={errors['contacts.email']}
          onChange={this.onInput('email')}
        />
        <AutocompleteInput
          label="Link to Hubspot Contact"
          value={hubspotContact && hubspotContact.name}
          placeholder={'Search by email address'}
          loaderFunction={loadHubspotContacts}
          onSelect={this.onSelectHubspotContact}
        />
        <YSpacing height="20px" />
        <Checkbox
          label="Is Referrable?"
          value="isReferralPartner"
          checked={isReferralPartner}
          onChange={this.onChangeCheckbox}
        />
        <YSpacing height="20px" />
        <div className="flex flex-row justify-between items-center">
          {!id && (
            <LinkText
              label="Cancel New"
              fontSize="13px"
              onClick={() => this.onCancel()}
            />
          )}
          {onSave && <Button label="Save" onClick={() => onSave(this.state)} />}
        </div>
      </FormContainer>
    )
  }
}

ContactInput.propTypes = {
  hasBackground: PropTypes.boolean,
  contact: PropTypes.object,
  errors: PropTypes.object,
  hubspotContact: PropTypes.object,

  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  pBuildContact: PropTypes.func,
  loadHubspotContact: PropTypes.func,
  loadHubspotContacts: PropTypes.func,
  onSelectHubspotContact: PropTypes.func,
  onSave: PropTypes.func,
}

ContactInput.defaultProps = {
  errors: {},
}

export default ContactInput
