import { DinerProfile } from '@types'

export const validateDinerProfileTags = (
  menuItems: {
    id: string
    name: string
    tagsDietaryPreferenceList: string
  }[],
  dinerProfile: DinerProfile,
): { includeWarningTags: string[]; excludeWarningTags: string[] } => {
  const allTags = Object.keys(
    menuItems
      .map((menuItem) => menuItem.tagsDietaryPreferenceList)
      .reduce(
        (acc, tagsList) => {
          const tagsArray = tagsList.split(',')
          tagsArray.forEach((tag) => {
            acc[tag] = true
          })

          return acc
        },
        {} as { [tag: string]: boolean },
      ),
  )

  const warnings: {
    includeWarningTags: string[]
    excludeWarningTags: string[]
  } = {
    includeWarningTags: [],
    excludeWarningTags: [],
  }
  if (dinerProfile.mustIncludeDietPrefsActive) {
    dinerProfile.mustIncludeDietPrefs.forEach((tag) => {
      // all must includes must have at least one item with this tag
      if (!allTags.some((menuItemTag) => menuItemTag.includes(tag))) {
        warnings.includeWarningTags.push(tag)
      }
    })
  }
  if (dinerProfile.mustExcludeDietPrefsActive) {
    dinerProfile.mustExcludeDietPrefs.forEach((tag) => {
      // at least one item has this tag -> warning
      if (allTags.some((menuItemTag) => menuItemTag.includes(tag))) {
        warnings.excludeWarningTags.push(tag)
      }
    })
  }

  return warnings
}
