import { connect } from 'react-redux'

import AuditHistoryModal from '@components/common/modal/AuditHistoryModal'
import services from '@services'

const mapStateToProps = (state) => {
  const { auditHistoryModal } = state
  const { auditHistory, show, title } = auditHistoryModal

  return {
    show,
    auditHistory,
    title,
  }
}

const mapDispatchToProps = () => {
  const { UIService } = services

  const { close } = UIService.AuditHistory

  return {
    close,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditHistoryModal)
