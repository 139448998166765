import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropdown from '@components/common/form/Dropdown'
import LinkText from '@components/common/form/LinkText'
import FlexContainer from '@components/common/FlexContainer'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import Input from '@components/common/form/Input'
import Button from '@components/common/form/Button'
import TextArea from '@components/common/form/TextArea'
import { colors } from '../../../../../constants'
const InitialState = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  zip: '',
  buildingInstructions: '',
}

class AddressSection extends Component {
  state = {
    dropoffAddress: null,
    ...InitialState,

    isFocused: false,
    isNewAddress: false,
  }

  componentDidMount() {
    const { addresses, hasAccount } = this.props
    if (hasAccount) {
      this.setState({ isNewAddress: addresses.length === 0 })
    }
  }

  onBlur = () => {
    this.setState({ isFocused: false })
  }

  onCancel = () => {
    this.setState({ isNewAddress: false, ...InitialState }, () => {
      const { dropoffAddress } = this.state
      this.props.onChange({ dropoffAddress, newDropoffAddress: {} })
    })
  }

  onFocus = () => {
    this.setState({ isFocused: true })
  }

  onInput = (field) => (e) => {
    const { clearError, onChange, pBuildAddress } = this.props
    const { value } = e.target
    this.setState({ [field]: value }, () => {
      clearError(field)
      const newDropoffAddress = pBuildAddress(this.state)
      onChange && onChange({ newDropoffAddress })
    })
  }

  onInputZip = (e) => {
    const { clearError, pBuildAddress } = this.props
    let zip = e.target.value
    zip = zip.replace(/[^0-9]/g, '').slice(0, 5)
    this.setState({ zip }, () => {
      clearError('zip')
      const newDropoffAddress = pBuildAddress(this.state)
      this.props.onChange({ newDropoffAddress })
    })
  }

  onSave = async () => {
    const isValid = await this.props.onSave()
    if (isValid) {
      this.setState({ isNewAddress: false, ...InitialState })
    }
  }

  onSelectAddress = (e) => {
    const { addresses } = this.props
    const addressId = e.target.value
    const dropoffAddress = addresses.find((x) => x.id === addressId)
    this.props.onChange({ dropoffAddress, newDropoffAddress: {} })
  }

  onShowNewAddress = () => {
    this.setState({ isNewAddress: true })
  }

  renderNewAddress() {
    const { errors } = this.props
    const { line1, line2, city, state, zip, buildingInstructions } = this.state

    return (
      <div className="p-3 bg-indigo-100">
        <Input
          label="Address Line 1"
          value={line1}
          error={errors.line1}
          onChange={this.onInput('line1')}
        />
        <Input
          label="Address Line 2"
          value={line2}
          error={errors.line2}
          onChange={this.onInput('line2')}
        />
        <Input
          label="City"
          value={city}
          error={errors.city}
          onChange={this.onInput('city')}
        />
        <Input
          label="State"
          value={state}
          error={errors.state}
          onChange={this.onInput('state')}
        />
        <Input
          label="Zip code"
          value={zip}
          error={errors.zip}
          onChange={this.onInputZip}
        />
        <TextArea
          label="Building/Delivery Instructions"
          value={buildingInstructions}
          onChange={this.onInput('buildingInstructions')}
        />
        <FlexContainer alignItems="center" justifyContent="flex-end">
          <LinkText
            label="Cancel"
            onClick={this.onCancel}
            color={colors.violet}
          />
          <XSpacing width="20px" />
          <Button label="Save" onClick={this.onSave} />
        </FlexContainer>
      </div>
    )
  }

  renderAddressDropdown() {
    let { addresses } = this.props
    const { label, dropoffAddress, errors, hasAccount, newDropoffAddress } =
      this.props
    const { isNewAddress } = this.state
    // show empty option if adding new address
    const addressId = isNewAddress ? '' : (dropoffAddress || {}).id
    if (addresses.length > 0) {
      addresses = [newDropoffAddress, ...addresses]
    }
    const disabled = !hasAccount

    return (
      <div style={{ width: '100%' }}>
        <Dropdown
          disabled={disabled}
          error={errors.dropoffAddress}
          label={label}
          marginBottom="0"
          width="100%"
          value={addressId}
          onChange={this.onSelectAddress}
        >
          {addresses.map((a) => (
            <option key={a.id} value={a.id}>
              {a.fullAddress}
            </option>
          ))}
        </Dropdown>
      </div>
    )
  }

  render() {
    const { hasAccount, width } = this.props
    const { isNewAddress } = this.state

    return (
      <div style={{ width }}>
        <FlexContainer width="100%" flexDirection="column">
          {this.renderAddressDropdown()}
          {hasAccount && !isNewAddress && (
            <div>
              <YSpacing height="3px" />
              <LinkText
                fontSize="11px"
                label="Add New Address"
                onClick={this.onShowNewAddress}
              />
            </div>
          )}
        </FlexContainer>
        {isNewAddress && this.renderNewAddress()}
      </div>
    )
  }
}

AddressSection.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  dropoffAddress: PropTypes.object,
  hasAccount: PropTypes.bool,
  newDropoffAddress: PropTypes.object,
  width: PropTypes.string,
  errors: PropTypes.object,

  clearError: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  pBuildAddress: PropTypes.func,
}

AddressSection.defaultProps = {
  addresses: [],
  errors: {},
}

export default AddressSection
