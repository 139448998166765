import Moment from 'moment-timezone'
import { MISSED_CHEF_EMAIL } from '../../../constants'

// duplicate in @utils - be sure to update both if changes
export const getOrderType = (dTypes, cateringType) => {
  let orderType
  ;(dTypes || []).forEach((dType) => {
    switch (dType) {
      case 'CateringOrder':
        orderType = cateringType === 'VCX' ? 'VCX' : 'Catering'
        break
      case 'PopUp':
        orderType = 'Pop Up'
        break
      case 'GroupOrder':
        orderType = 'Group Order'
        break
    }
  })

  return orderType
}

// duplicate in @utils - be sure to update both if changes
export const pAccountingOrderType = (order) => {
  const { cateringType } = order
  const dTypes = order['dgraph.type']

  return getOrderType(dTypes, cateringType)
}

export const pResponseSalesReport = (json) => {
  return json.map((order) => {
    const {
      orderNumber,
      headCount,
      createdAt,
      date,
      dueDate,
      effectiveDate,
      status,
      predictedServiceCost,
      suppliesCost,
      subtotal,
      deliveryFee,
      staffingFee,
      serviceFee,
      discount,
      tax,
      tip,
      total,
      subsidy,
      subsidyTax,
      subsidyTip,
      invoiceContact,
      isPaid,
      orderType,
      margin,
      chefs,
      chefPay,
      captains,
      captainPay,
      captainHours,
      headquarter,
      clientName,
      clientPin,
      salesRep,
      totalRevenue,
      carbonNeutral,
      carbonNeutralContribution,
      hungryCarbonNeutralContribution,
      discountKind,
      discountReason,
      incumbent,
      numEmployees,
      expectedOrderingHeadcount,
      estimatedMonthlyValue,
      orderingCadence,
      averagePricePerPerson,
      averagePricePerPersonType,
      serviceDays,
      firstOrderDate,
      firstOrderDateType,
      sow,
      clientChampion,
      clientHunter,
      clientSDR,
    } = order

    return {
      'Order Number': orderNumber,
      Market: headquarter,
      'Event Date': Moment(date).format('MM/DD/YYYY'),
      'Due Date': Moment(dueDate).format('MM/DD/YYYY'),
      'Effective Date': Moment(effectiveDate).format('MM/DD/YYYY'),
      'Date Order Was Created': createdAt
        ? Moment(createdAt).format('MM/DD/YYYY h:mm a')
        : '',
      _Date: Moment(date),
      'Order Type': orderType,
      Client: clientName,
      'Client Pin': clientPin,
      Incumbent: incumbent,
      'Building/Office Population': numEmployees,
      'Expected Ordering Headcount': expectedOrderingHeadcount,
      'Estimated Monthly Value': estimatedMonthlyValue,
      'Ordering Cadence': orderingCadence,
      SOW: { type: 'link', url: sow, text: 'Open Document' },
      'Budget Per Person': averagePricePerPerson,
      'Budget Per Person Type': averagePricePerPersonType,
      'Service Days': serviceDays,
      'First Order Date': firstOrderDate
        ? Moment(firstOrderDate).format('MM/DD/YYYY')
        : '',
      'First Order Date Type': firstOrderDateType,
      'Account Executive': clientHunter,
      'Client Success Manager': clientChampion,
      'Client SDR': clientSDR,
      'Order Sales Rep': salesRep,
      'Invoice Contact': invoiceContact,
      'Head Count': headCount,
      Status: status,
      'Predicted Service Cost': `$${parseFloat(predictedServiceCost).toFixed(
        2,
      )}`,
      'Supplies Cost': `$${parseFloat(suppliesCost).toFixed(2)}`,
      Chefs: chefs,
      'Chef Pay': `$${parseFloat(chefPay).toFixed(2)}`,
      Captains: captains,
      'Captain Pay': `$${parseFloat(captainPay).toFixed(2)}`,
      'Captain Hours': captainHours,
      Subtotal: `$${parseFloat(subtotal).toFixed(2)}`,
      'Delivery Fee': `$${parseFloat(deliveryFee).toFixed(2)}`,
      'Staffing Fee': `$${parseFloat(staffingFee).toFixed(2)}`,
      'Total Service Fee': `$${parseFloat(serviceFee).toFixed(2)}`,
      Discount: `$${parseFloat(discount).toFixed(2)}`,
      'Discount Kind': discountKind,
      'Discount Reason': discountReason,
      Tax: `$${parseFloat(tax).toFixed(2)}`,
      Tip: `$${parseFloat(tip).toFixed(2)}`,
      Subsidy: `$${parseFloat(subsidy).toFixed(2)}`,
      'Subsidy Tax': `$${parseFloat(subsidyTax).toFixed(2)}`,
      'Subsidy Tip': `$${parseFloat(subsidyTip).toFixed(2)}`,
      Total: `$${parseFloat(total).toFixed(2)}`,
      'Total Revenue': `$${parseFloat(totalRevenue).toFixed(2)}`,
      Margin: parseFloat(margin).toFixed(4),
      'Is Paid': isPaid ? 'Yes' : 'No',
      'Carbon Neutral?': carbonNeutral ? 'Yes' : 'No',
      'Client Carbon Neutral Contribution': `$${parseFloat(
        carbonNeutralContribution,
      ).toFixed(2)}`,
      'HUNGRY Carbon Neutral Contribution': `$${parseFloat(
        hungryCarbonNeutralContribution,
      ).toFixed(2)}`,
    }
  })
}

export const pResponseAccountingSalesReport = (json) => {
  return json.map((order) => {
    const {
      orderNumber,
      headCount,
      date,
      dueDate,
      effectiveDate,
      status,
      subtotal,
      serviceFee,
      discount,
      tax,
      tip,
      total,
      subsidy,
      subsidyTax,
      subsidyTip,
      orderType,
      headquarter,
      clientName,
      clientPin,
      salesRep,
      totalRevenue,
      carbonNeutralContribution,
      hungryCarbonNeutralContribution,
      discountKind,
      discountReason,
      orderingCadence,
      firstOrderDate,
      clientChampion,
      clientHunter,
    } = order

    return {
      'Order Number': orderNumber,
      Market: headquarter,
      'Event Date': Moment(date).format('MM/DD/YYYY'),
      _Date: Moment(date),
      'Order Type': orderType,
      Client: clientName,
      'Client Pin': clientPin,
      'Ordering Cadence': orderingCadence,
      'First Order Date': firstOrderDate
        ? Moment(firstOrderDate).format('MM/DD/YYYY')
        : '',
      'Account Executive': clientHunter,
      'Client Success Manager': clientChampion,
      'Order Sales Rep': salesRep,
      'Head Count': headCount,
      Status: status,
      Subtotal: `$${parseFloat(subtotal).toFixed(2)}`,
      'Total Service Fee': `$${parseFloat(serviceFee).toFixed(2)}`,
      Discount: `$${parseFloat(discount).toFixed(2)}`,
      'Discount Kind': discountKind,
      'Discount Reason': discountReason,
      Tax: `$${parseFloat(tax).toFixed(2)}`,
      Tip: `$${parseFloat(tip).toFixed(2)}`,
      Subsidy: `$${parseFloat(subsidy).toFixed(2)}`,
      'Subsidy Tax': `$${parseFloat(subsidyTax).toFixed(2)}`,
      'Subsidy Tip': `$${parseFloat(subsidyTip).toFixed(2)}`,
      Total: `$${parseFloat(total).toFixed(2)}`,
      'Total Revenue': `$${parseFloat(totalRevenue).toFixed(2)}`,
      'Client Carbon Neutral Contribution': `$${parseFloat(
        carbonNeutralContribution,
      ).toFixed(2)}`,
      'HUNGRY Carbon Neutral Contribution': `$${parseFloat(
        hungryCarbonNeutralContribution,
      ).toFixed(2)}`,
      'Due Date': Moment(dueDate).format('MM/DD/YYYY'),
      'Effective Date': Moment(effectiveDate).format('MM/DD/YYYY'),
    }
  })
}

export const pResponseCaptPayReport = (json) => {
  return json.map((pay) => {
    const {
      orderNumber,
      eventType,
      updatedAt,
      date,
      start,
      end,
      hours,
      rate,
      salary,
      tip,
      total,
      clientName,
      adjustments,
      status,

      headquarter,
      captain,
    } = pay

    return {
      'Order Number': orderNumber,
      Date: Moment(date).format('MM/DD/YYYY'),
      _Date: Moment(date),
      Updated: Moment(updatedAt).format('MMM DD'),
      _Updated: Moment(updatedAt),
      Market: headquarter,
      Captain: captain,
      'Client Name': clientName,
      Status: status,
      Start: Moment(start).format('h:mm:ss a'),
      _Start: Moment(start),
      End: Moment(end).format('h:mm:ss a'),
      _End: Moment(end),
      Hours: parseFloat(hours).toFixed(2),
      Rate: parseFloat(rate).toFixed(2),
      Wages: `$${parseFloat(salary).toFixed(2)}`,
      Adjustments: `$${parseFloat(adjustments).toFixed(2)}`,
      Tip: `$${parseFloat(tip).toFixed(2)}`,
      Total: `$${parseFloat(total).toFixed(2)}`,
      'Ad Hoc Type': eventType,
    }
  })
}

export const pResponseChefPayReport = (json) => {
  return json.map((pay) => {
    const {
      orderNumber,
      updatedAt,
      date,
      status,
      chefPayoutType,
      client,
      market,
      chef,
      total,
      headcount,
      orderType,
      payToName,
    } = pay

    return {
      'Order Number': orderNumber,
      'Order Type': orderType,
      'Payout Type': chefPayoutType,
      Status: status,
      Client: client,
      Date: Moment(date).format('MM/DD/YYYY'),
      _Date: Moment(date),
      Updated: Moment(updatedAt).format('MMM DD'),
      _Updated: Moment(updatedAt),
      Market: market,
      Chef: chef,
      'Head Count': headcount,
      'Pay to Name': payToName,
      Total: `$${parseFloat(total).toFixed(2)}`,
    }
  })
}

export const pResponseSalesCommissionReport = (json) => {
  return json.map((order) => {
    const {
      orderNumber,
      headCount,
      date,
      status,
      suppliesCost,
      subtotal,
      serviceFee,
      discount,
      tax,
      tip,
      total,
      subsidy,
      subsidyTax,
      subsidyTip,
      isPaid,
      orderType,
      headquarter,
      clientName,
      clientPin,
      salesRep,
      hunter,
      champion,
      firstOrderDate,
      sow,
      chefPay,
      captainPay,
      margin,
      totalRevenue,
    } = order

    return {
      'Order Number': orderNumber,
      Market: headquarter,
      Date: Moment(date).format('MM/DD/YYYY'),
      _Date: Moment(date),
      'Order Type': orderType,
      Client: clientName,
      Pin: clientPin,
      'First Order Date': Moment(firstOrderDate).format('MM/DD/YYYY'),
      '_First Order Date': Moment(firstOrderDate),
      SOW: { type: 'link', url: sow, text: 'Open Document' },
      Hunter: hunter,
      Champion: champion,
      'Order Sales Rep': salesRep,
      'Head Count': headCount,
      Status: status,
      'Supplies Cost': `$${parseFloat(suppliesCost).toFixed(2)}`,
      'Chef Pay': `$${parseFloat(chefPay).toFixed(2)}`,
      'Captain Pay': `$${parseFloat(captainPay).toFixed(2)}`,
      Subtotal: `$${parseFloat(subtotal).toFixed(2)}`,
      'Service Fee': `$${parseFloat(serviceFee).toFixed(2)}`,
      Discount: `$${parseFloat(discount).toFixed(2)}`,
      Tax: `$${parseFloat(tax).toFixed(2)}`,
      Tip: `$${parseFloat(tip).toFixed(2)}`,
      Subsidy: `$${parseFloat(subsidy).toFixed(2)}`,
      'Subsidy Tax': `$${parseFloat(subsidyTax).toFixed(2)}`,
      'Subsidy Tip': `$${parseFloat(subsidyTip).toFixed(2)}`,
      Total: `$${parseFloat(total).toFixed(2)}`,
      'Total Revenue': `$${parseFloat(totalRevenue).toFixed(2)}`,
      Margin: parseFloat(margin).toFixed(4),
      'Is Paid': isPaid ? 'Yes' : 'No',
    }
  })
}

export const pResponseGroupOrderDashReport = (json) => {
  return json.map((report) => {
    const {
      activeOrdersCount,
      activeOrdersForMenuCount,
      avgFoodMargin,
      chefAcceptanceStatus,
      chefEmailSentAt,
      chefName,
      client,
      dropoffTime,
      earliestOrderingCutoff,
      eventDay,
      groupOrderActive,
      latestOrderingCutoff,
      market,
      menuActive,
      menuName,
      menuOrderingCutoff,
      orderNumber,
      subsidySettings,
    } = report

    let chefEmailSentVal
    if (!chefEmailSentAt && activeOrdersForMenuCount == 0) {
      chefEmailSentVal = 'Empty - No email sent on empty orders'
    } else if (
      !chefEmailSentAt &&
      Moment().isAfter(Moment(menuOrderingCutoff))
    ) {
      chefEmailSentVal = MISSED_CHEF_EMAIL
    } else if (
      !chefEmailSentAt &&
      Moment().isBefore(Moment(menuOrderingCutoff))
    ) {
      chefEmailSentVal = 'Not Yet Sent'
    } else {
      chefEmailSentVal = Moment(chefEmailSentAt).format('MM/DD/YYYY h:mm a')
    }

    return {
      Market: market,
      'Order Number': orderNumber,
      Client: client,
      'Subsidy Settings': subsidySettings,
      'Earliest Ordering Cutoff': Moment(earliestOrderingCutoff).format(
        'MM/DD/YYYY h:mm a',
      ),
      'Latest Ordering Cutoff':
        Moment(latestOrderingCutoff).format('MM/DD/YYYY h:mm a'),
      'Dropoff Date/Time': Moment(dropoffTime).format('MM/DD/YYYY h:mm a'),
      'Event Day': eventDay,
      'Group Order Active/Inactive': groupOrderActive,
      'Group Order Active Orders Count': activeOrdersCount,
      Chef: chefName,
      'Chef Order Acceptance Status': chefAcceptanceStatus,
      'Menu Card Active/Inactive': menuActive,
      'Menu Name': menuName,
      'Active Orders for Menu Count': activeOrdersForMenuCount,
      'Menu Ordering Cutoff Date/Time':
        Moment(menuOrderingCutoff).format('MM/DD/YYYY h:mm a'),
      'Menu Chef Email Sent At': chefEmailSentVal,
      'Avg Food Margin': `${(avgFoodMargin * 100).toFixed(2)}%`,
    }
  })
}

export const pAccountingCsvUrlPath = (reportName) => {
  switch (reportName) {
    case 'Sales':
      return 'orders/csv'
    case 'Captain Pay':
      return 'captains/payouts-csv'
    case 'Chef Pay':
      return 'chefs/payouts-csv'
    case 'Chef Pay Itemized':
      return 'chefs/payouts-itemized-csv'
    case 'Sales Commission':
      return 'orders/sales-commission-csv'
    default:
      return ''
  }
}

export const pAccountingCsvParamsString = (
  {
    hqIds,
    salesRepId,
    clientId,
    chefId,
    captainId,
    orderTypes,
    from,
    to,
    isPaid,
    page,
  },
  csvName,
) => {
  if (!page) {
    return
  }
  const params = {
    salesRepId,
    clientId,
    chefId,
    captainId,
    isPaid,
  }
  const limit = 0

  if (from) {
    const fromDate = from.format()
    csvName === 'Captain Pay'
      ? (params.startTime = fromDate)
      : (params.from = fromDate)
  }
  if (to) {
    const toDate = to.format()
    csvName === 'Captain Pay' ? (params.endTime = toDate) : (params.to = toDate)
  }
  if (csvName === 'Captain Pay') {
    params.headquarters = hqIds
  } else {
    params.hqIds = hqIds
    params.limit = limit
    params.offset = (page - 1) * limit
  }

  if (orderTypes && orderTypes.length > 0) {
    const orderTypesPart = []
    const cateringTypesPart = []
    orderTypes.forEach((type) => {
      const strs = type.split('.')
      orderTypesPart.push(strs[0])
      if (strs.length > 1) {
        cateringTypesPart.push(strs[1])
      }
    })
    params.orderTypes = orderTypesPart.join(',')
    if (cateringTypesPart.length > 0) {
      params.cateringTypes = cateringTypesPart.join(',')
    }
  }

  Object.keys(params).forEach((key) => {
    if (params[key] === undefined || params[key] === '') {
      delete params[key]
    }
  })
  if (params['hqIds'] && params['hqIds'].length <= 0) {
    delete params['hqIds']
  }

  if (csvName === 'Accounting Sales') {
    params.forAccounting = true
  }

  return new URLSearchParams(params).toString()
}

export const pGroupOrderDashReportCSVParams = ({
  hqIds,
  chefs,
  clients,
  orderNumbers,
  dropoffTimeStart,
  dropoffTimeEnd,
  goCutoffStart,
  goCutoffEnd,
  menuCutoffStart,
  menuCutoffEnd,
  page,
  limit,
}) => {
  if (!page) {
    return
  }
  const params = {
    hqIds,
    chefIds: chefs ? chefs.map((chef) => chef.id) : [],
    clientIds: clients ? clients.map((client) => client.id) : [],
    orderNumbers: orderNumbers ? orderNumbers : [],
    limit,
    offset: (page - 1) * limit,
    forCSV: true,
  }
  if (dropoffTimeStart) {
    params.dropoffStart = dropoffTimeStart.format()
  }
  if (dropoffTimeEnd) {
    params.dropoffEnd = dropoffTimeEnd.format()
  }
  if (goCutoffStart) {
    params.goCutoffStart = goCutoffStart.format()
  }
  if (goCutoffEnd) {
    params.goCutoffEnd = goCutoffEnd.format()
  }
  if (menuCutoffStart) {
    params.menuCutoffStart = menuCutoffStart.format()
  }
  if (menuCutoffEnd) {
    params.menuCutoffEnd = menuCutoffEnd.format()
  }
  Object.keys(params).forEach((key) => {
    if (
      params[key] === undefined ||
      params[key] === '' ||
      params[key].length === 0
    ) {
      delete params[key]
    }
  })
  if (params['hqIds'] && params['hqIds'].length <= 0) {
    delete params['hqIds']
  }

  return new URLSearchParams(params).toString()
}
