import React, { useState } from 'react'
import { PageBody } from '@res/styledComponents/index'
import Sidebar from '@components/sidebar/Sidebar'
import SidebarListItem from '@components/sidebar/SidebarListItem'
import Panel from '@components/common/Panel'
import { LatePaymentsTab, AutoInvoiceTab } from '@containers/notifications'

const HEADINGS: { [key: string]: string } = {
  latePayments: 'Late Payment Reminders',
  autoInvoice: 'Automatic Invoice Emails',
}

const TIMEZONES: { [key: string]: string }[] = [
  {
    label: 'Eastern Time (US & Canada)',
    value: 'Eastern Time (US & Canada)',
    alt: 'America/New_York',
  },
  {
    label: 'Central Time (US & Canada)',
    value: 'Central Time (US & Canada)',
    alt: 'America/Chicago',
  },
  {
    label: 'Mountain Time (US & Canada)',
    value: 'Mountain Time (US & Canada)',
    alt: 'America/Denver',
  },
  {
    label: 'Pacific Time (US & Canada)',
    value: 'Pacific Time (US & Canada)',
    alt: 'America/Los_Angeles',
  },
]

const AuthorizedRoles = ['master admin', 'finance', 'sales lead', 'sales rep']

const NotificationsPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('latePayments')

  return (
    <PageBody>
      <Sidebar heading="Notifications">
        {Object.keys(HEADINGS).map((tab) => (
          <SidebarListItem
            key={tab}
            isSelected={tab === activeTab}
            onClick={() => setActiveTab(tab)}
          >
            {HEADINGS[tab]}
          </SidebarListItem>
        ))}
      </Sidebar>
      <div className="sidebar-body">
        <div className="sidebar-body-inner">
          <Panel width="100%" maxWidth="1500px" heading={HEADINGS[activeTab]}>
            {activeTab === 'latePayments' && (
              <LatePaymentsTab
                timezones={TIMEZONES}
                authRoles={AuthorizedRoles}
              />
            )}
            {activeTab === 'autoInvoice' && (
              <AutoInvoiceTab
                timezones={TIMEZONES}
                authRoles={AuthorizedRoles}
              />
            )}
          </Panel>
        </div>
      </div>
    </PageBody>
  )
}

export default NotificationsPage
