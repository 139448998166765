import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class ReferralService extends BaseService {
  setReferredBy({ referrable, referralPartners }) {
    const { referredBy } = this.getState()[referrable] || {}
    let contact = null
    if (referredBy) {
      contact = referralPartners.find((r) => r.id === referredBy.contactId)
      referredBy.contact = contact
    }
    this.dispatch(actions.setReferredBy(referredBy))
  }

  setReferralPartners(referralPartners) {
    this.dispatch(actions.setReferralPartners(referralPartners))
  }
}
