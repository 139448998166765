import { camelCaseify } from '~/utils'
import Moment from 'moment-timezone'

export const pResponseVXAccount = (json) => {
  const account = camelCaseify(json)

  return {
    id: account.id,
    name: account.name,
    pin: account.pin,
    contacts: account.contacts
      .map((contact) => ({
        id: contact.id,
        name: contact.name,
        email: contact.email,
        phone: contact.phoneNumber,
        createdAt: Moment(contact.createdAt),
      }))
      .sort((a, b) => (a.createdAt.isBefore(b.createdAt) ? -1 : 1)),
    locations: account.addresses
      .map((addr) => ({
        id: addr.id,
        name: addr.fullAddress,
        line1: addr.line1,
        line2: addr.line2,
        city: addr.city,
        state: addr.state,
        zip: addr.zip,
        tolls: addr.allowTolls,
        buildingInstructions: addr.buildingInstructions,
        createdAt: Moment(addr.createdAt),
      }))
      .sort((a, b) => (a.createdAt.isBefore(b.createdAt) ? -1 : 1)),
  }
}

export const pResponseVXAccounts = (json) => {
  return json.map((acct) => pResponseVXAccount(acct))
}

export const pShippingFormData = (data, client, number) => {
  const shippingRows = []

  data.forEach((attendee) => {
    shippingRows.push({
      name: attendee.name,
      email: attendee.email,
      phone: attendee.phone,
      line1: attendee.address.line1,
      line2: attendee.address.line2,
      city: attendee.address.city,
      state: attendee.address.state,
      zip: attendee.address.zip,
      country: 'US',
      client,
      orderNumber: number,
    })
  })

  return shippingRows
}
