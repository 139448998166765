import { connect } from 'react-redux'

import { ServiceDetails } from '@components/order/orderSections/panel'

import { HandleError } from '@coordinators/composed'
import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { serviceDepots } = state

  return { serviceDepots }
}

const mapDispatchToProps = () => {
  const { OrderService, RestService, UIService } = services
  const {
    AsyncLoadCateringCaptains,
    EmailServicesSurvey,
    EmailServicesTeam,
    LoadServiceDepots,
    LoadServiceOrderTimes,
    UpdateServicesInfo,
  } = coordinators
  const {
    pResponseCateringCaptains,
    pResponseOrder,
    pResponseServiceOrder,
    pRequestUpdateOrderServiceInfo,
    pResponseGeneric,
  } = presenters.Api

  const emailServicesTeam = EmailServicesTeam({
    OrderService,
    RestService,
    UIService,
    HandleError,
  })
  const emailServicesSurvey = EmailServicesSurvey({ RestService, UIService })
  const loadCateringCaptains = AsyncLoadCateringCaptains({
    RestService,
    pResponseCateringCaptains,
  })
  const loadServiceDepots = LoadServiceDepots({
    OrderService,
    RestService,
    pRequestServiceDepots: pResponseGeneric,
  })
  const loadServiceOrderTimes = LoadServiceOrderTimes({
    RestService,
    pResponseServiceOrder,
  })
  const updateServicesInfo = UpdateServicesInfo({
    OrderService,
    RestService,
    pRequestUpdateOrderServiceInfo,
    pResponseOrder,
  })

  return {
    emailServicesSurvey,
    emailServicesTeam,
    loadCateringCaptains,
    loadServiceDepots,
    loadServiceOrderTimes,
    updateServicesInfo,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetails)
