import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Flatpickr from 'react-flatpickr'
import { FormInputContainer } from '@res/styledComponents/index'
import { convertTimeIntoMomentTz, convertTimeIntoBrowserTz } from '~/utils'

class DateTimeInput extends Component {
  state = {
    date: undefined,
    isFocused: false,
  }

  componentDidMount() {
    const { date } = this.props
    this.setState({ date })

    window.addEventListener('keydown', this.onDeleteDate)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onDeleteDate)
  }

  componentWillReceiveProps(nextProps) {
    const { date } = nextProps

    this.setState({ date })
  }

  onBlur = () => {
    this.setState({ isFocused: false })
  }

  onFocus = () => {
    this.setState({ isFocused: true })
  }

  onChange = (date) => {
    const { onChange } = this.props

    if (Array.isArray(date)) {
      date = date.pop()
    }
    if (!date) {
      return
    }

    const dateInTz = convertTimeIntoMomentTz(date)

    if (dateInTz !== this.state.date) {
      this.setState({ date: dateInTz, isFocused: false })
      onChange && onChange(dateInTz)
    }
  }

  render() {
    const {
      label,
      width,
      hideClearDate,

      clearDate,
    } = this.props
    const { date } = this.state
    const pickerOptions = {
      dateFormat: 'm/d/y h:i K',
      enableTime: true,
    }

    return (
      <FormInputContainer style={{ width: width }}>
        <label>{label}</label>
        <div style={{ position: 'relative', width: '100%' }}>
          <Flatpickr
            name={label}
            height="400px"
            data-enable-time
            options={pickerOptions}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onFocus={this.onFocus}
            value={date && convertTimeIntoBrowserTz(date)}
          />
          {!hideClearDate && date && date.toDate() && (
            <ClearDate onClick={clearDate}>X</ClearDate>
          )}
        </div>
      </FormInputContainer>
    )
  }
}

export const ClearDate = styled.button`
  position: absolute;
  right: 10px;
  top: 7px;
`

DateTimeInput.propTypes = {
  date: PropTypes.string,
  hideClearDate: PropTypes.bool,
  label: PropTypes.string,
  width: PropTypes.string,

  onChange: PropTypes.func,
  clearDate: PropTypes.func,
}

export default DateTimeInput
