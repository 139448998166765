import { connect } from 'react-redux'

import { Concepts } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { chef, menuConcepts, settings } = state

  return {
    chefId: chef.id,
    menuConcepts,
    settings,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, SettingsService, UIService } = services
  const {
    pResponseConcept,
    pResponseConcepts,
    pRequestSettings,
    pResponseSettings,
  } = presenters.Api
  const {
    BulkDeleteConcepts,
    EditMenuConcept,
    LoadMenuConcepts,
    LoadSettings,
    RetryOnTimeout,
    NewMenuConcept,
  } = coordinators

  const editMenuConcept = EditMenuConcept({
    ChefService,
    RestService,
    UIService,
    pResponseConcept,
    HandleError,
  })
  const loadConcepts = LoadMenuConcepts({
    ChefService,
    RestService,
    pResponseConcepts,
    HandleError,
  })
  const newMenuConcept = NewMenuConcept({ ChefService, UIService })
  const loadConceptsSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('concepts')

  const bulkDeleteConcepts = BulkDeleteConcepts({
    RestService,
    UIService,
    ChefService,
    pResponseConcepts,
    HandleError,
  })
  const { displayFailureMessage } = UIService.FlashMessage

  return {
    bulkDeleteConcepts,
    displayFailureMessage,
    editMenuConcept,
    loadConcepts,
    loadConceptsSettings,
    newMenuConcept,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Concepts)
