import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AuthorizedInteractable } from '@containers/common/auth'

export class Packaging extends Component {
  constructor(props) {
    super(props)

    this.state = {
      packaging: props.packaging,
      newPackaging: null,
      editPackaging: null,
      sortAsc: true,
    }
  }

  componentWillMount() {
    this.props.loadPackaging()
  }

  componentWillReceiveProps(nextProps) {
    const { packaging } = nextProps
    if (packaging !== this.state.packaging) {
      this.setState({ packaging })
    }
  }

  sortByString = (columnName) => {
    const { packaging, sortAsc } = this.state
    const getColumn = (item) =>
      item[columnName] ? item[columnName].toLowerCase() : ''
    const sorted = packaging.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ packaging: sorted, sortAsc: !sortAsc })
  }

  sortByBoolean = (columnName) => {
    const { packaging, sortAsc } = this.state
    const getColumn = (item) => (item[columnName] === true ? 1 : 0)
    const sorted = packaging.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ packaging: sorted, sortAsc: !sortAsc })
  }

  sortByNumber = (columnName) => {
    const { packaging, sortAsc } = this.state
    const sorted = packaging.sort((a, b) => {
      if (sortAsc) {
        return a[columnName] - b[columnName]
      } else {
        return b[columnName] - a[columnName]
      }
    })
    this.setState({ packaging: sorted, sortAsc: !sortAsc })
  }

  renderStaticRow = (pkg, i) => {
    return (
      <tr
        key={i}
        className="clickable"
        onClick={(e) => {
          e.preventDefault()
          this.setState({ editPackaging: { ...pkg } })
        }}
      >
        <td>{pkg.name}</td>
        <td>{pkg.supplyType}</td>
        <td>{pkg.defaultUnitCost}</td>
        <td>
          {pkg.active ? (
            <span className="approved-check">✔</span>
          ) : (
            <span className="not-approved-x">✖</span>
          )}
        </td>
        <td>
          {pkg.trackInventory ? (
            <span className="approved-check">✔</span>
          ) : (
            <span className="not-approved-x">✖</span>
          )}
        </td>
        <td>{pkg.interchangeableType}</td>
        <td>{pkg.defaultServingsPerPackage}</td>
        <td>%{(pkg.dftRecoupPct * 100).toFixed(2)}</td>
        <td>%{(pkg.dftHungryVipRecoupPct * 100).toFixed(2)}</td>
      </tr>
    )
  }

  renderRow = (pkg, i) => {
    const { editPackaging } = this.state
    const id = editPackaging && editPackaging.id
    if (pkg.id == id) {
      return this.renderEditRow(editPackaging, i)
    } else {
      return this.renderStaticRow(pkg, i)
    }
  }

  renderEditRow = (pkg, i) => {
    return (
      <tr key={i} className="clickable">
        <td>
          <input
            type="text"
            value={pkg.name}
            onInput={(e) =>
              this.setState({ editPackaging: { ...pkg, name: e.target.value } })
            }
          />
        </td>
        <td>{pkg.supplyType}</td>
        <td>
          <input
            type="text"
            value={pkg.defaultUnitCost}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editPackaging: {
                  ...pkg,
                  defaultUnitCost: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <input
            type="checkbox"
            checked={pkg.active}
            onChange={(e) =>
              this.setState({
                editPackaging: { ...pkg, active: e.target.checked },
              })
            }
          />
        </td>
        <td>
          <input
            type="checkbox"
            checked={pkg.trackInventory}
            onChange={(e) =>
              this.setState({
                editPackaging: { ...pkg, trackInventory: e.target.checked },
              })
            }
          />
        </td>
        <td>
          <input
            type="text"
            value={pkg.interchangeableType}
            onInput={(e) =>
              this.setState({
                editPackaging: { ...pkg, interchangeableType: e.target.value },
              })
            }
          />
        </td>
        <td>
          <input
            type="text"
            value={pkg.defaultServingsPerPackage}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editPackaging: {
                  ...pkg,
                  defaultServingsPerPackage: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <input
            type="text"
            value={pkg.dftRecoupPct}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editPackaging: {
                  ...pkg,
                  dftRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <input
            type="text"
            value={pkg.dftHungryVipRecoupPct}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editPackaging: {
                  ...pkg,
                  dftHungryVipRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <AuthorizedInteractable
            roles={['master admin', 'chef lead', 'captain lead']}
          >
            <button
              className="button-neutral"
              key="editPackaging"
              onClick={async (event) => {
                event.preventDefault()
                const { deletePackaging } = this.props
                if (await deletePackaging(pkg.id)) {
                  this.setState({ editPackaging: null })
                }
              }}
            >
              Delete Supply
            </button>
          </AuthorizedInteractable>
        </td>
        <td>
          <button
            className="button-neutral"
            key="clearEditPackaging"
            onClick={(event) => {
              event.preventDefault()
              this.setState({ editPackaging: null })
            }}
          >
            Clear Updates
          </button>
        </td>
      </tr>
    )
  }

  renderNewPackaging = () => {
    const { newPackaging } = this.state

    return (
      <table className="table table-hover page">
        <thead>
          <tr>
            <th colSpan={8}>New Packaging</th>
          </tr>
          <tr>
            <th>Supply Name</th>
            <th>Active</th>
            <th>Default Unit Cost</th>
            <th>Track Inventory</th>
            <th>Interchangeable Type</th>
            <th>Default Servings Per Pkg</th>
            <th>Recoup Pct</th>
            <th>Hungry VIP Recoup Pct</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                value={newPackaging.name}
                onInput={(e) =>
                  this.setState({
                    newPackaging: { ...newPackaging, name: e.target.value },
                  })
                }
              />
            </td>
            <td>
              <input
                type="checkbox"
                checked={newPackaging.active}
                onChange={(e) =>
                  this.setState({
                    newPackaging: { ...newPackaging, active: e.target.checked },
                  })
                }
              />
            </td>

            <td>
              <input
                type="text"
                value={newPackaging.defaultUnitCost}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newPackaging: {
                      ...newPackaging,
                      defaultUnitCost: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
            <td>
              <input
                type="checkbox"
                checked={newPackaging.trackInventory}
                onChange={(e) =>
                  this.setState({
                    newPackaging: {
                      ...newPackaging,
                      trackInventory: e.target.checked,
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="text"
                value={newPackaging.interchangeableType}
                onInput={(e) =>
                  this.setState({
                    newPackaging: {
                      ...newPackaging,
                      interchangeableType: e.target.value,
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="text"
                value={newPackaging.defaultServingsPerPackage}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newPackaging: {
                      ...newPackaging,
                      defaultServingsPerPackage: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
            <td>
              <input
                type="text"
                value={newPackaging.dftRecoupPct}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newPackaging: {
                      ...newPackaging,
                      dftRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
            <td>
              <input
                type="text"
                value={newPackaging.dftHungryVipRecoupPct}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newPackaging: {
                      ...newPackaging,
                      dftHungryVipRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderButtons = () => {
    const { editPackaging, newPackaging } = this.state
    const { savePackaging } = this.props
    const renderNew = !newPackaging && !editPackaging
    const editedPackaging = newPackaging || editPackaging

    return (
      <div className="page">
        <center>
          {editedPackaging && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="editPackaging"
                onClick={async (event) => {
                  event.preventDefault()
                  if (await savePackaging(editedPackaging)) {
                    this.setState({ editPackaging: null, newPackaging: null })
                  }
                }}
              >
                Save Packaging Changes
              </button>
            </AuthorizedInteractable>
          )}
          {renderNew && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="newPackaging"
                onClick={(event) => {
                  event.preventDefault()
                  this.setState({
                    newPackaging: {
                      name: '',
                      active: true,
                      trackInventory: true,
                      interchangeableType: '',
                      defaultServingsPerPackage: 10,
                      defaultUnitcost: 0.0,
                      dftRecoupPct: 0.0,
                      dftHungryVipRecoupPct: 0.0,
                    },
                  })
                }}
              >
                New Packaging
              </button>
            </AuthorizedInteractable>
          )}
        </center>
      </div>
    )
  }

  render() {
    const { packaging, newPackaging } = this.state

    return (
      <div>
        <br />
        <h3>Description</h3>
        <p>
          These supplies are for packaging menu items for delivery. Packaging is
          provided by chefs and thus should have $0 cost per order. Packagings
          are mapped to menu items under the Chefs tab on the menu item edit
          modal.
        </p>
        <br />
        <p>
          Packagings have an interchangeable type which specifies groups of
          packages that are intechangeable if they share the same type. This
          allows for smaller packagings to be used if the larger packaging is
          too large for the remaining servings. Interchangeable packaging usage
          is calculated based on the ratio of their default servings per pkgs.
          IE Full Pan can package 6 servings of chicken and an order calls for 2
          servings of chicken, Full Pan has 20 default servings per pkg and Half
          Pan has 10 default servings per pkg, then a half pan can carry (10 /
          20) * 6 servings = 3 servings which is greater than or equal to 2
          servings of chicken so the half pan is used. Packaging add ons will
          also use this same ratio for calculating add on counts used for
          interchangeable packages. Interchangeable types are not used for
          events.
        </p>
        <br />

        <h3>Instructions</h3>
        <p>
          <ul>
            <li>Click a packaging to edit.</li>
          </ul>
        </p>
        <br />

        <h3>Columns</h3>
        <p>
          <ul>
            <li>
              Recoup Pct - Percent of supply recouped after a catering. Will
              factor into a lower cost per order.
            </li>
            <li>
              Hungry VIP Recoup Pct - Percent of supply recouped after a HUNGRY
              VIP service type catering. Replaces the recoup pct and will factor
              into a lower cost per order.
            </li>
            <li>
              Default Servings Per Pkg - The default servings that can be
              packaged. Used if the menu item servings per pkg is not defined.
              Also used to calculate interchangeable packaging usage.
            </li>
          </ul>
        </p>
        <br />

        <table className="table table-hover page">
          <thead>
            <tr>
              <th onClick={() => this.sortByString('name')}>Item Name</th>
              <th onClick={() => this.sortByString('supplyType')}>
                Supply Type
              </th>
              <th onClick={() => this.sortByNumber('defaultUnitCost')}>
                Default Unit Cost
              </th>
              <th onClick={() => this.sortByBoolean('active')}>Active</th>
              <th onClick={() => this.sortByBoolean('active')}>
                Track Inventory
              </th>
              <th onClick={() => this.sortByString('interchangeableType')}>
                Interchangeable Type
              </th>
              <th
                onClick={() => this.sortByNumber('defaultServingsPerPackage')}
              >
                Default Servings Per Pkg
              </th>
              <th onClick={() => this.sortByNumber('dftRecoupPct')}>
                Recoup Pct
              </th>
              <th onClick={() => this.sortByNumber('dftHungryVipRecoupPct')}>
                Hungry VIP Recoup Pct
              </th>
            </tr>
          </thead>
          <tbody>
            {packaging.length == 0 && (
              <tr>
                <td colSpan="9">
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '1.2em',
                      color: 'rgba(0,0,0,0.2)',
                      padding: '20px',
                      backgroundColor: 'rgba(0,0,0,0.01)',
                    }}
                  >
                    No supplies.
                  </div>
                </td>
              </tr>
            )}
            {packaging.length != 0 &&
              packaging.map((pkg, i) => this.renderRow(pkg, i))}
          </tbody>
        </table>

        {newPackaging && this.renderNewPackaging()}
        {this.renderButtons()}
      </div>
    )
  }
}

Packaging.propTypes = {
  packaging: PropTypes.arrayOf(PropTypes.object),

  loadPackaging: PropTypes.func,
  deletePackaging: PropTypes.func,
  savePackaging: PropTypes.func,
}

export default Packaging
