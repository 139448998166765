export const EditSnackPack =
  ({ ChefService, RestService, UIService, pResponseSnackPack }) =>
  async (snackPackId) => {
    const snackPack = await RestService.get(
      `/api/admin/snack_packs/${snackPackId}`,
    )
    ChefService.setEditSnackPack(pResponseSnackPack(snackPack))
    UIService.EditSnackPack.show()
  }

export const NewSnackPack =
  ({ ChefService, UIService, pDefaultSnackPackFields }) =>
  () => {
    const { chef, editSnackPack } = ChefService.getState()
    if (editSnackPack && editSnackPack.id) {
      ChefService.clearEditSnackPack()
    }

    if (chef) {
      ChefService.setEditSnackPack(pDefaultSnackPackFields(chef))
      UIService.EditSnackPack.show()
    }
  }

export const LoadChefSnackPacks =
  ({ ChefService, RestService, pResponseSnackPacks }) =>
  async (chefId) => {
    const params = {
      include_all: true,
      chef_ids: [chefId],
    }
    const response = await RestService.get('/api/admin/snack_packs', params, {
      timeout: 20000,
    })
    const snackPacks = pResponseSnackPacks(response)
    ChefService.setChefSnackPacks(snackPacks)
  }

export const DelayedUpdateSnackPack =
  ({ ChefService, UIService }) =>
  (snackPack) => {
    const call = () => ChefService.setEditSnackPack(snackPack)

    UIService.Timer.callAfterTimeout(call)
  }

export const SaveSnackPack =
  ({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateSnackPack,
    pResponseSnackPacks,
    HandleError,
  }) =>
  async (data) => {
    try {
      UIService.Errors.clear()
      const req = pRequestUpdateSnackPack(data)
      const headers = { 'Content-Type': 'multipart/form-data' }

      let snackPack = undefined
      if (data.id) {
        snackPack = await RestService.put(
          `/api/admin/snack_packs/${data.id}`,
          req,
          headers,
        )
      } else {
        snackPack = await RestService.post(
          '/api/admin/snack_packs',
          req,
          headers,
        )
      }

      UIService.EditSnackPack.close()
      ChefService.clearEditSnackPack()

      LoadChefSnackPacks({ ChefService, RestService, pResponseSnackPacks })(
        snackPack.chef_id,
      )
    } catch (error) {
      HandleError({ error, namespace: 'snackPackModal' })
    }
  }

export const DeleteSnackPack =
  ({
    ChefService,
    RestService,
    UIService,
    pResponseChef,
    HandleError,
    SelectChef,
  }) =>
  async ({ id, chefId, name }) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete the snack pack "${name}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/api/admin/snack_packs/${id}`)
        ChefService.clearEditSnackPack()
        SelectChef({ ChefService, RestService, pResponseChef })(chefId)
        UIService.EditSnackPack.close()
      } catch (error) {
        HandleError({ error })
      }
    }
  }
