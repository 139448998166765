import React, { useEffect, useState } from 'react'
import moment, { Moment } from 'moment-timezone'
import Modal from '@components/common/modal/Modal'
import { OrderableConfirmationModal } from '@components/common/modal'
import DividerLine from '@components/common/DividerLine'
import XSpacing from '@components/common/XSpacing'
import YSpacing from '@components/common/YSpacing'
import Table from '@components/common/Table'
import Loader from '@components/common/Loader'
import { colors } from '../../../../constants'
import {
  Button,
  Checkbox,
  DateInput,
  Dropdown,
  Input,
} from '@components/common/form'
import {
  CateringOrder,
  Orderable,
  OrderableConfirmationParamaters,
} from '@types'

interface LinkDinerProfileToPastOrdersModalProps {
  accountId: string
  dinerProfileMap: Record<string, string>
  hqLocaleMap: Record<string, string>
  hideModal: () => void
  confirmationModal: {
    show: (val: { text: string }) => Promise<boolean>
  }
  searchOrders: (filters: {
    accountId: string
    fromDate?: Moment
    toDate?: Moment
    search: string
    resultsPerPage: number
    serializer: string
  }) => Promise<CateringOrder[]>
  updateOrdersDinerProfileId: (
    hqLocaleMap: Record<number, string>,
    params: OrderableConfirmationParamaters[],
    callbackFn: (
      result: Orderable | null,
      index: number,
      error?: string,
    ) => void,
  ) => Orderable
  uri: string
}

const LinkDinerProfileToPastOrdersModal = ({
  accountId,
  confirmationModal,
  dinerProfileMap,
  hqLocaleMap,
  hideModal,
  searchOrders,
  updateOrdersDinerProfileId,
  uri,
}: LinkDinerProfileToPastOrdersModalProps) => {
  const [dinerProfileId, setDinerProfileId] = useState('')
  const [toDate, setToDate] = useState<Moment>()
  const [fromDate, setFromDate] = useState<Moment>()
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState<string>('')
  const [orders, setOrders] = useState<CateringOrder[]>([])
  const [selectedOrderMap, setSelectedOrderMap] = useState<
    Record<string, CateringOrder | undefined>
  >({})
  const [showOrderableConfirmationModal, setShowOrderableConfirmationModal] =
    useState(false)

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setIsLoading(true)
      const fetchedOrders = await searchOrders({
        accountId,
        fromDate,
        toDate,
        search,
        resultsPerPage: 10,
        serializer: 'for_account',
      })
      if (fetchedOrders) {
        const fetchedOrderIds = new Set(fetchedOrders.map((order) => order.id))
        // Add any selected orders that are not in the fetched orders
        const selectedOrders = Object.values(selectedOrderMap)
          .filter(
            (selectedOrder): selectedOrder is CateringOrder =>
              selectedOrder !== undefined,
          )
          .filter((selectedOrder) => !fetchedOrderIds.has(selectedOrder.id))

        const combinedOrders = [...fetchedOrders, ...selectedOrders]
        setOrders(combinedOrders)
      }
      setIsLoading(false)
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, fromDate, toDate, search, searchOrders])

  const handleSelectAll = () => {
    const allSelected =
      orders.length === Object.values(selectedOrderMap).filter((i) => i).length
    if (allSelected) {
      setSelectedOrderMap({})
    } else {
      const newSelectedOrderMap: Record<string, CateringOrder> = {}
      orders.forEach((o) => {
        newSelectedOrderMap[o.id] = o
      })
      setSelectedOrderMap(newSelectedOrderMap)
    }
  }

  const handleAddToDinerProfile = async () => {
    const message = Object.values(selectedOrderMap)
      .filter((i): i is CateringOrder => i !== undefined)
      .map(
        (o) =>
          `<strong>${moment(o.clientSetUpTime).format('dddd, MMMM, do')} - ${
            o.orderNumber
          }</strong>`,
      )
      .join(' <br />')
    const confirmed = await confirmationModal.show({
      text: `Are you sure you want to add the following orders to the Diner Profile: <strong>${dinerProfileMap[dinerProfileId]}</strong> <br /> ${message}`,
    })
    if (confirmed) {
      setShowOrderableConfirmationModal(true)
    }
  }

  const renderOrder = (order: CateringOrder) => {
    const {
      accountExecutive,
      contact,
      dinerProfileId,
      dropoffAddress,
      orderNumber,
      chefName,
      total,
      dateMoment,
      headCount,
    } = order

    return (
      <tr key={order.id}>
        <td>
          <Checkbox
            checked={selectedOrderMap[order.id]}
            onChange={() =>
              setSelectedOrderMap({
                ...selectedOrderMap,
                [order.id]: selectedOrderMap[order.id] ? undefined : order,
              })
            }
          />
        </td>
        <td>{orderNumber}</td>
        <td>{dateMoment && dateMoment.format('MMM Do YYYY')}</td>
        <td>{dateMoment && dateMoment.format('h:mm:ss a')}</td>
        <td>{headCount}</td>
        <td>{dinerProfileMap[dinerProfileId]}</td>
        <td>{contact.name}</td>
        <td>
          {dropoffAddress.line1 +
            ' ' +
            dropoffAddress.city +
            ' ' +
            dropoffAddress.state +
            ' ' +
            dropoffAddress.zip}
        </td>
        <td>{chefName}</td>
        <td>{accountExecutive.firstName + ' ' + accountExecutive.lastName}</td>
        <td>${total}</td>
      </tr>
    )
  }

  if (showOrderableConfirmationModal) {
    return (
      <OrderableConfirmationModal
        isUpdate={true}
        orderable="Order"
        orderableType="sales"
        params={Object.keys(selectedOrderMap)
          .filter((orderId) => selectedOrderMap[orderId])
          .map((orderId) => ({
            orderId,
            dinerProfileId: dinerProfileId,
          }))}
        hqLocaleMap={hqLocaleMap}
        coordinator={updateOrdersDinerProfileId}
        uri={uri}
        onClose={hideModal}
        errorLinks={[]}
        successLinks={[]}
      />
    )
  }

  return (
    <Modal
      title="Add Past Orders to Diner Profile"
      hideModal={hideModal}
      height="800px"
      width="1200px"
    >
      <Dropdown
        label="*Select Diner Profile"
        value={dinerProfileId}
        onChange={(e) => setDinerProfileId(e.target.value)}
        width="20%"
      >
        <option />
        {Object.keys(dinerProfileMap || {}).map((dpId) => (
          <option key={dpId} value={dpId}>
            {dinerProfileMap[dpId]}
          </option>
        ))}
      </Dropdown>
      {dinerProfileId && (
        <>
          <DividerLine margin="20px 0" />
          <div className="flex justify-start">
            <DateInput
              date={fromDate}
              label="From"
              onChange={(value: Moment) => setFromDate(value)}
              clearDate={() => setFromDate(undefined)}
              width="20%"
            />
            <XSpacing width="30px" />
            <DateInput
              date={toDate}
              label="To"
              onChange={(value: Moment) => setToDate(value)}
              clearDate={() => setToDate(undefined)}
              width="20%"
            />
            <XSpacing width="30px" />
            <Input
              type="text"
              pattern="[0-9]*"
              marginBottom="0"
              label="Order Number"
              value={undefined}
              onChange={(e) => setSearch(e.target.value)}
              width="15%"
            />
          </div>
          <YSpacing height="15px" />
          <Table>
            <tr>
              <th className="flex flex-align-center">
                Select All
                <Checkbox
                  checked={
                    orders.length ===
                    Object.values(selectedOrderMap).filter((i) => i).length
                  }
                  onChange={handleSelectAll}
                />
              </th>
              <th>Number</th>
              <th>Event Date</th>
              <th>Time</th>
              <th>HeadCount</th>
              <th>Diner Profile</th>
              <th>Contact Name</th>
              <th>Dropoff Address</th>
              <th>Chef</th>
              <th>Sales Rep</th>
              <th>Total</th>
            </tr>
            {isLoading ? <Loader /> : (orders || []).map((o) => renderOrder(o))}
          </Table>
          <YSpacing height="10px" />
          <div className="flex justify-end w-full">
            <Button
              label="Add to Diner Profile"
              onClick={handleAddToDinerProfile}
              disabled={
                Object.values(selectedOrderMap).filter((i) => i).length < 1
              }
            />
            <XSpacing width="15px" />
            <Button
              label="Cancel"
              backgroundColor={colors.violet}
              onClick={hideModal}
            />
          </div>
        </>
      )}
    </Modal>
  )
}

export default LinkDinerProfileToPastOrdersModal
