import { snakeCaseify } from '../../../utils'

export const EditMenuItem =
  ({ ChefService, RestService, UIService, pResponseMenuItem }) =>
  async (menuItemId) => {
    const menuItem = await RestService.get(
      `/api/admin/menu_items/${menuItemId}`,
    )
    ChefService.setEditMenuItem(pResponseMenuItem(menuItem))
    UIService.EditMenuItem.show()
  }

export const LoadMenuItem =
  ({ RestService, HandleError, pResponseMenuItem }) =>
  async (menuItemId) => {
    try {
      const menuItem = await RestService.get(
        `/api/admin/menu_items/${menuItemId}`,
      )

      return pResponseMenuItem(menuItem)
    } catch (error) {
      HandleError({ error })

      return false
    }
  }

export const CopyMenuItem =
  ({ RestService, UIService, HandleError, ChefService, pResponseMenuItems }) =>
  async (menuItemId, chefId, currentChefId) => {
    try {
      await RestService.post(`/api/admin/menu_items/${menuItemId}/copy`, {
        chef_id: chefId,
      })
      UIService.EditMenuItem.hideCopyMenuItem()
      UIService.FlashMessage.displaySuccessMessage(
        'Successfully copied menu item.',
      )
      if (currentChefId === chefId) {
        LoadChefMenuItems({ ChefService, RestService, pResponseMenuItems })(
          currentChefId,
        )
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
  }

export const EditChildMenuItem =
  ({ ChefService, RestService, UIService, pResponseMenuItem }) =>
  async (menuItemId) => {
    const menuItem = await RestService.get(
      `/api/admin/menu_items/${menuItemId}`,
    )
    ChefService.setEditChildMenuItem(pResponseMenuItem(menuItem))
    UIService.EditChildMenuItem.show()
  }

export const NewMenuItem =
  ({ ChefService, UIService, pDefaultMenuFields }) =>
  () => {
    const { chef, editMenuItem } = ChefService.getState()
    if (editMenuItem.id) {
      ChefService.clearEditMenuItem()
    }

    if (chef) {
      ChefService.setEditMenuItem(pDefaultMenuFields(chef))
      UIService.EditMenuItem.show()
    }
  }

export const NewChildMenuItem =
  ({ ChefService, UIService, pDefaultChildMenuFields }) =>
  ({ parentMenuItemId, parentMenuItemName, mealType }, parentMenuItem) => {
    const { chef } = ChefService.getState()
    UIService.EditMenuItem.close()
    ChefService.setEditMenuItem(parentMenuItem)
    ChefService.setEditChildMenuItem(
      pDefaultChildMenuFields({
        chef,
        parentMenuItemId,
        parentMenuItemName,
        mealType,
      }),
    )
    UIService.EditChildMenuItem.show()
  }

export const CloseChildMenuItemModal =
  ({ ChefService, UIService, pStateToReduxMenuItem }) =>
  (child) => {
    UIService.EditChildMenuItem.close()
    UIService.EditMenuItem.show()

    const { editMenuItem } = ChefService.getState()
    const { childMenuItems } = editMenuItem
    const current = childMenuItems.find((c) => c.id === child.id)
    if (current) {
      const index = childMenuItems.indexOf(current)
      ChefService.setEditMenuItem({
        ...editMenuItem,
        childMenuItems: [
          ...childMenuItems.slice(0, index),
          {
            ...current,
            ...pStateToReduxMenuItem(child),
          },
          ...childMenuItems.slice(index + 1),
        ],
      })
    } else if (child.id) {
      // only include if the menu item has valid id
      ChefService.setEditMenuItem({
        ...editMenuItem,
        childMenuItems: [...childMenuItems, pStateToReduxMenuItem(child)],
      })
    }
  }

export const DelayedUpdateMenuItem =
  ({ ChefService, UIService, pStateToReduxMenuItem }) =>
  (menuItem, menuItemSettings = {}) => {
    const call = () =>
      ChefService.setEditMenuItem(
        pStateToReduxMenuItem(menuItem),
        menuItemSettings,
      )

    UIService.Timer.callAfterTimeout(call)
  }

export const DelayedUpdateChildMenuItem =
  ({ ChefService, UIService, pStateToReduxMenuItem }) =>
  (menuItem, menuItemSettings = {}) => {
    const call = () =>
      ChefService.setEditChildMenuItem(
        pStateToReduxMenuItem(menuItem),
        menuItemSettings,
      )

    UIService.Timer.callAfterTimeout(call)
  }

export const LoadChefMenuItems =
  ({ ChefService, RestService, pResponseMenuItems }) =>
  async (chefId, viewArchivedItems = false) => {
    const params = {
      parent_items: true,
      include_all: true,
      serializer: 'chef_tab',
      chef_ids: [chefId],
    }
    if (viewArchivedItems) {
      params.archived_menu_items = true
    }

    const response = await RestService.get('/api/admin/menu_items', params, {
      timeout: 35000,
    })
    const menuItems = pResponseMenuItems(response)

    ChefService.setChefMenuItems(menuItems)
  }

export const LoadGroupOrderAddOnItems =
  ({ RestService, pResponseMenuItems }) =>
  async (filters) => {
    filters = snakeCaseify(filters)
    try {
      const response = await RestService.get(
        '/api/admin/menu_items',
        { page: 1, results_per_page: 100, ...filters },
        { timeout: 35000 },
      )

      return pResponseMenuItems(response)
    } catch (e) {
      return []
    }
  }

export const LoadMenuItemRatings =
  ({ RestService }) =>
  async (menuItemIds) => {
    const ratings = await RestService.put('group-orders/menu-item-ratings', {
      menuItemIds,
    })

    return ratings
  }

export const LoadPopUpMenuItems =
  ({ RestService, pResponseMenuItems }) =>
  async (chefId) => {
    const params = {
      parent_items: true,
      include_all: true,
      is_approved: true,
      chef_ids: [chefId],
    }

    const response = await RestService.get('/api/admin/menu_items', params, {
      timeout: 35000,
    })

    return pResponseMenuItems(response)
  }

export const SaveMenuItem =
  ({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateMarketplaceAddOn,
    pRequestUpdateMenuItem,
    pRequestUpdateItemImages,
    pResponseMenuItem,
    pResponseMenuItems,
    CloseChildMenuItemModal,
    EditMenuItem,
    HandleError,
    pStateToReduxMenuItem,
  }) =>
  async (data, isCopy, needResponsePresenter) => {
    if (
      !data.isSubMenuItem &&
      Array.isArray(data.tagsDietaryPreference) &&
      data.tagsDietaryPreference.length === 0
    ) {
      UIService.FlashMessage.displayFailureMessage(
        'Please select an Allergen/Dietary preference for this item.',
      )

      return
    }

    try {
      UIService.Errors.clear()
      const req = pRequestUpdateMenuItem(data)
      const headers = { 'Content-Type': 'multipart/form-data' }

      // create menu item
      let menuItem = undefined
      if (data.id) {
        menuItem = await RestService.put(
          `/api/admin/menu_items/${data.id}`,
          req,
          headers,
        )
      } else {
        menuItem = await RestService.post('/api/admin/menu_items', req, headers)
      }

      // create item's images
      const imageUpdateReq = pRequestUpdateItemImages(data)
      if (menuItem && menuItem.id && imageUpdateReq) {
        menuItem = await RestService.put(
          `/api/admin/menu_items/${menuItem.id}`,
          imageUpdateReq,
          headers,
        )
      }

      // create marketplace add on
      const marketplaceAddOnReq = pRequestUpdateMarketplaceAddOn({
        menuItemData: data,
        menuItemId: menuItem.id,
      })
      if (marketplaceAddOnReq && !marketplaceAddOnReq.destroy) {
        await RestService.post(
          '/api/admin/marketplace_add_on',
          marketplaceAddOnReq,
          headers,
        )
      } else if (marketplaceAddOnReq && marketplaceAddOnReq.destroy) {
        await RestService.delete(
          `/api/admin/marketplace_add_on/${marketplaceAddOnReq.id}`,
        )
      }

      // child items have no closing actions
      if (isCopy && data.isSubMenuItem) {
        return
      }

      if (data.isSubMenuItem) {
        CloseChildMenuItemModal({
          ChefService,
          RestService,
          UIService,
          pResponseMenuItem,
          EditMenuItem,
          pStateToReduxMenuItem,
        })(pResponseMenuItem(menuItem))
      } else {
        // create copy item children
        if (isCopy) {
          data.childMenuItems &&
            data.childMenuItems.forEach((item) => {
              item.parentMenuItemId = menuItem.id
              SaveMenuItem({
                ChefService,
                RestService,
                UIService,
                pRequestUpdateMarketplaceAddOn,
                pRequestUpdateMenuItem,
                pRequestUpdateItemImages,
                pResponseMenuItem,
                pResponseMenuItems,
                CloseChildMenuItemModal,
                EditMenuItem,
                HandleError,
              })(item, isCopy)
            })
          UIService.EditMenuItem.hideCopyMenuItem()
          UIService.EditMenuItem.close()
          UIService.Alert.showPopup({
            message: `"${menuItem.name}" copied!`,
            title: 'Item Alert',
          })
          ChefService.clearEditMenuItem()
        } else {
          UIService.EditMenuItem.close()
          UIService.Alert.showPopup({
            message: `"${menuItem.name}" ${data.id ? 'updated' : 'created'}!`,
            title: 'Item Alert',
          })
          ChefService.clearEditMenuItem()
          LoadChefMenuItems({ ChefService, RestService, pResponseMenuItems })(
            menuItem.chef_id,
          )
        }
      }

      if (needResponsePresenter) {
        return pResponseMenuItem(menuItem)
      } else {
        return true
      }
    } catch (error) {
      HandleError({ error, namespace: 'menuItemModal' })

      return false
    }
  }

export const DeleteMenuItem =
  ({
    ChefService,
    RestService,
    UIService,
    HandleError,
    pResponseMenuItems,
    LoadChefMenuItems,
  }) =>
  async ({ id, chefId, name }) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to archive menu item "${name}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/api/admin/menu_items/${id}`)
        ChefService.clearEditMenuItem()
        await LoadChefMenuItems({
          ChefService,
          RestService,
          pResponseMenuItems,
        })(chefId)
        UIService.EditMenuItem.close()
      } catch (error) {
        HandleError({ error })
      }
    }
  }

export const DeleteChildMenuItem =
  ({ RestService, UIService, HandleError }) =>
  async ({ id, name }) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to archive menu item "${name}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/api/admin/menu_items/${id}`)

        return true
      } catch (error) {
        HandleError({ error })

        return false
      }
    }
  }

export const DeleteMenuItems =
  ({
    ChefService,
    UIService,
    RestService,
    pResponseMenuItems,
    LoadChefMenuItems,
  }) =>
  async ({ checkedMenuItems, chefId }) => {
    const size = checkedMenuItems.length
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to archive ${size} menu items?`,
    })
    if (doDelete) {
      const failedArchiveItems = []
      const successfulArchivedItemsMap = {}
      for (let i = 0; i < size; i++) {
        const menuItem = checkedMenuItems[i]
        try {
          const success = await RestService.delete(
            `/api/admin/menu_items/${menuItem.id}`,
          )
          if (success) {
            successfulArchivedItemsMap[menuItem.id] = menuItem.name
          }
        } catch (error) {
          failedArchiveItems.push(menuItem.name)
        }
      }

      await LoadChefMenuItems({ ChefService, RestService, pResponseMenuItems })(
        chefId,
      )

      if (failedArchiveItems.length > 0) {
        UIService.FlashMessage.displayFailureMessage(
          `Failed to archive ${
            failedArchiveItems.length
          } menu items.\n ${failedArchiveItems.join(',\n')}`,
        )
      }
      if (Object.keys(successfulArchivedItemsMap).length > 0) {
        UIService.FlashMessage.displaySuccessMessage(
          `Successfully archived ${
            Object.keys(successfulArchivedItemsMap).length
          } menu items \n ${Object.values(successfulArchivedItemsMap).join(
            ',\n',
          )}`,
        )
      }

      return successfulArchivedItemsMap
    }

    return []
  }

export const RestoreMenuItem =
  ({
    ChefService,
    RestService,
    UIService,
    pResponseMenuItems,
    HandleError,
    LoadChefMenuItems,
  }) =>
  async ({ id, chefId, name }) => {
    const doRestore = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to restore menu item "${name}"?`,
    })
    if (doRestore) {
      try {
        await RestService.put(`/api/admin/menu_items/restore`, { id })
        ChefService.clearEditMenuItem()
        await LoadChefMenuItems({
          ChefService,
          RestService,
          pResponseMenuItems,
        })(chefId, true)
        UIService.EditMenuItem.close()
      } catch (error) {
        HandleError({ error })
      }
    }
  }

export const BulkRestoreMenuItems =
  ({
    ChefService,
    UIService,
    RestService,
    pResponseMenuItems,
    LoadChefMenuItems,
    HandleError,
  }) =>
  async ({ checkedMenuItems, chefId }) => {
    const size = checkedMenuItems.length
    const doRestore = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to restore ${size} menu items?`,
    })
    if (doRestore) {
      for (let i = 0; i < size; i++) {
        const menuItem = checkedMenuItems[i]
        try {
          await RestService.put(`/api/admin/menu_items/restore`, {
            id: menuItem.id,
          })
        } catch (error) {
          UIService.Alert.showPopup({
            message: `Failed to restore ${menuItem.name}`,
            title: 'Failure',
          })
          HandleError({ error })

          return false
        }
      }

      await LoadChefMenuItems({ ChefService, RestService, pResponseMenuItems })(
        chefId,
        true,
      )

      UIService.Alert.showPopup({
        message: `Restored ${size} menu items`,
        title: 'Success',
      })

      return true
    }

    return false
  }

export const CopyMenuItems =
  ({ RestService, ChefService, pResponseMenuItems, HandleError }) =>
  async (req) => {
    req = snakeCaseify(req)
    try {
      const messages = await RestService.post(
        '/api/admin/menu_items/copy_from_chef',
        req,
        { timeout: 60000 },
      )

      await LoadChefMenuItems({
        ChefService,
        RestService,
        pResponseMenuItems,
      })(req.chef_id)

      return messages
    } catch (error) {
      HandleError({ error })

      return undefined
    }
  }
