import Moment from 'moment-timezone'
import { camelCaseify, formAdd } from '~/utils'

export const pRequestSubscriber = (info) => {
  const {
    email,
    password,
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    phone,
    instructions,
    referrer,
    schedule,
    dietaryRestrictions,
    nonce,
  } = info

  return {
    email: email.toLowerCase(),
    password,
    firstName,
    lastName,
    zip,
    dietaryRestrictions: Object.entries(dietaryRestrictions)
      .filter(([_, chosen]) => chosen)
      .map(([r, _]) => r),
    weekdays: Object.entries(schedule)
      .filter(([_, chosen]) => chosen)
      .map(([d, _]) => Number(d)),
    streetLine1: addressLine1,
    streetLine2: addressLine2,
    city,
    state,
    phone,
    instructions,
    nonce,
    referrer,
  }
}

export const pRequestUpdateSubscriber = (data) => {
  const req = {}
  formAdd(data, req, 'active', 'active')
  formAdd(data, req, 'recurring', 'recurring')
  formAdd(data, req, 'dietaryRestrictions', 'dietaryRestrictions')

  return req
}

export const pRequestChooseSalesRep = (salesRep) => {
  return { salesRep: camelCaseify(salesRep) }
}

export const pRequestSubscription = (subscription) => {
  const itemChoices = []
  Object.values({
    ...subscription.mainItemMap,
    ...subscription.sideItemMap,
  }).forEach((item) => {
    const { chosen, id, quantity } = item
    if (chosen) {
      itemChoices.push({ id, quantity })
    }
  })

  return {
    date: subscription.dateStr,
    menuId: subscription.menu.id,
    headCount: subscription.headcount,
    itemChoices,
  }
}

export const pResponseSubscription = (lockedInCutoff) => (s) => {
  s.mainItems = []
  s.sideItems = []
  const mainMap = {}
  const sideMap = {}
  ;(s.menu.mainMenuItems || []).forEach((it) => {
    mainMap[it.id] = it
    it.childItems &&
      it.childItems.forEach((ch) => {
        mainMap[ch.id] = ch
      })
  })
  ;(s.menu.sideMenuItems || []).forEach((it) => {
    sideMap[it.id] = it
    it.childItems &&
      it.childItems.forEach((ch) => {
        mainMap[ch.id] = ch
      })
  })
  ;(s.items || []).forEach((it) => {
    if (mainMap[it.id]) {
      s.mainItems.push({ ...mainMap[it.id], quantity: parseFloat(it.quantity) })
    } else if (sideMap[it.id]) {
      s.sideItems.push({ ...sideMap[it.id], quantity: parseFloat(it.quantity) })
    }
  })

  const date = Moment(s.date)
  s.lockedIn = date < lockedInCutoff

  return s
}

export const pSubscriptionCutoffs = () => {
  // const lockedInCutoff = Moment().add( 24 + 7, 'days' ).startOf( 'isoWeek' ).add( 24 * 5 + 12, 'hours' ) // TODO - TESTING LOCKED IN
  const lockedInCutoff = Moment()

  return [
    lockedInCutoff,
    Moment().startOf('day'),
    lockedInCutoff
      .clone()
      .add(1, 'week')
      .startOf('isoWeek')
      .format('MM/DD/YYYY'),
  ]
}

export const pResponseSubscriber = (member) => {
  const lockedIn = []
  const editable = []
  const past = []

  const [lockedInCutoff, pastCutoff, week] = pSubscriptionCutoffs()

  let needsSelect = true
  ;(member.subscriptions || []).forEach((m) => {
    if (m.week === week) {
      needsSelect = false
    }
    const date = Moment(m.date)
    if (date < pastCutoff) {
      past.push(m)
    } else if (date < lockedInCutoff) {
      lockedIn.push(m)
    } else {
      editable.push(m)
    }
  })

  member.skipped = member.skippedWeeks.indexOf(week) !== -1
  if (!member.active || member.skipped) {
    member.needsSelect = false
  } else {
    member.needsSelect = needsSelect
  }
  member.lockedIn = lockedIn
  member.editable = editable
  member.past = past

  member.subscriptions = (member.subscriptions || []).map(
    pResponseSubscription(lockedInCutoff),
  )

  return member
}

export const pResponseHomeMenu = (menu) => {
  const concept = camelCaseify(menu)

  return {
    id: concept.id,
    name: concept.name,
    cuisines: concept.tagsCuisine,
    dayPart: concept.tagsDayPart[0],
    dietaryPrefs: concept.tagsDietaryPreference,
    chef: {
      id: concept.chef._id,
      name: concept.chef.name,
      description: concept.chef.chefProfile.description,
      rating: concept.chef.chefProfile.rating,
      image: concept.chef.chefProfile.image.thumborHeadshotUrl,
    },
    mainMenuItems: concept.mainMenuItems.map((item) => ({
      id: item.id,
      name: item.name,
      description: item.description,
      image: item.image && item.image.image.thumborUrl,
      dietaryPrefs: item.tagsDietaryPreferenceList.split(','),
      ingredients: item.tagsIngredientList.split(','),
    })),
    sideMenuItems: concept.sideMenuItems.map((item) => ({
      id: item.id,
      name: item.name,
      description: item.description,
      dietaryPrefs: item.tagsDietaryPreferenceList.split(','),
      ingredients: item.tagsIngredientList.split(','),
    })),
    price: '100',
    chefRating: concept.chefRating,
  }
}
