import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Moment from 'moment-timezone'
import Modal from '@components/common/modal/Modal'
import Table from '@components/common/Table'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { Button, CurrencyInput } from '@components/common/form'
import { Label } from '@res/styledComponents/index'
import { colors } from '../../../constants'
import { getOrderBals } from '@utils'
import LoadingIndicator from '../common/LoadingIndicator'

let timer

const callAfterTimeout = (func, delay = 3000) => {
  if (timer) {
    clearTimeout(timer)
  }
  timer = setTimeout(func, delay)
}

const InvoiceCustomPayModal = ({
  customAmtFromView, //<- when user clicks "view" on unpaid pmts table
  displayWarningMessage,
  handleCustomPayModal,
  invoice,
  invoicedOrders,
  newOrderPayment,
  payments,
  updateInvModal,
}) => {
  const [totalDue, setTotalDue] = useState(0)
  const [customPayAmount, setCustomPayAmount] = useState(0)
  const [calculatedOrders, setCalculatedOrders] = useState([])
  const [notAppliedOrders, setNotAppliedOrders] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  //If a user navigates to the custom payment modal via 'view' btn on customPay table.
  useEffect(() => {
    if (customAmtFromView) {
      setCustomPayAmount(customAmtFromView)
      calcAppliedOrdersFrmCustom(customAmtFromView)
    }
    const total = invoicedOrders.reduce(
      (acc, order) => (acc += getOrderBals(order).total),
      0,
    )
    setTotalDue(total - invoice.totalPaid)
  }, [])

  const calcAppliedOrdersFrmCustom = (amount) => {
    const sortedOrders = invoicedOrders.sort((a, b) => {
      return Moment(a.date).isAfter(Moment(b.date)) ? 1 : -1
    })
    const appliedOrders = []
    const notAppliedOrders = []
    let totalApplied = 0
    for (const order of sortedOrders) {
      //custom amount has been fully applied -> no amount left to apply to orders
      if (totalApplied == amount) {
        notAppliedOrders.push(order)
        continue
      }

      const paidAmount = invoice.payments.reduce((acc, pmt) => {
        if (pmt.isPaid && order.id === pmt.paymentFor.id) {
          acc += pmt.amount
        }

        return acc
      }, 0)

      const orderTotal = getOrderBals(order).total
      //Only apply if order is not fully paid
      if (paidAmount == orderTotal) {
        continue
      }

      let paymentAmount = amount - totalApplied
      if (paymentAmount > orderTotal - paidAmount) {
        paymentAmount = orderTotal - paidAmount
      }

      totalApplied += paymentAmount
      order.paymentToApply = paymentAmount
      if (orderTotal === 0) {
        order.percent = 1
        order.percentRemaining = 0
      } else {
        order.percent = paymentAmount / orderTotal
        order.percentRemaining =
          (orderTotal - paidAmount - paymentAmount) / orderTotal
      }
      order.totalDue = orderTotal - paidAmount
      appliedOrders.push(order)
    }

    setCalculatedOrders(appliedOrders)
    setNotAppliedOrders(notAppliedOrders)
  }

  const handleAddCustomPayment = async () => {
    if (customPayAmount > totalDue.toFixed(2)) {
      displayWarningMessage(
        'Custom payment amount is larger than the amount due on the invoice.',
      )
      setIsLoading(false)

      return
    }

    const newPayments = []
    const oldPaymentsMap = {}

    calculatedOrders.forEach((o) => {
      const order = invoicedOrders.find((ord) => ord.id === o.id)
      const newCustomPayment = newOrderPayment({
        id: undefined,
        amount: Number(o.paymentToApply.toFixed(2)),
        tax: Number((order.tax * o.percent).toFixed(2)),
        discount: Number((order.discount * o.percent).toFixed(2)),
        serviceFee: Number((order.serviceFee * o.percent).toFixed(2)),
        tip: Number((order.tip * o.percent).toFixed(2)),
        paymentForId: order.id,
        paymentForOrderNum: order.orderNumber,
        isInvoiced: true,
      })
      newPayments.push(newCustomPayment)
      const oldPayment = payments.find(
        (pmt) => !pmt.isPaid && pmt.paymentFor.id === o.id,
      )
      if (oldPayment && oldPayment.id) {
        oldPaymentsMap[oldPayment.id] = oldPayment
      }
    })

    const updatedPayments = [...newPayments]
    //update payments not applied by the customAmount - so paying invoice does not result in over payment.
    payments.forEach((pmt) => {
      if (!oldPaymentsMap[pmt.id] && pmt.id && !pmt.isPaid && pmt.amount > 0) {
        //won't update voids/refund
        //update all fields on pmt to zero since not applied on custom batch
        pmt.amount = 0
        pmt.discount = 0
        pmt.serviceFee = 0
        pmt.tax = 0
        pmt.tip = 0
        updatedPayments.push(pmt)
      } else if (pmt.id && !oldPaymentsMap[pmt.id]) {
        updatedPayments.push(pmt)
      }
    })

    const balances = updatedPayments.reduce(
      (acc, pmt) => {
        if (!oldPaymentsMap[pmt.id] && !pmt.isPaid) {
          acc.amount += pmt.amount
          acc.tip += pmt.tip
          acc.discountAmount += pmt.discount
        } else if (!oldPaymentsMap[pmt.id] && pmt.isPaid) {
          acc.tip += pmt.tip
          acc.discountAmount += pmt.discount
        }

        return acc
      },
      { amount: 0, tip: 0, discountAmount: 0 },
    )

    invoice.totalDue = balances.amount
    invoice.tip = balances.tip
    invoice.discountAmount = balances.discountAmount
    invoice.isPaid = false
    invoice.payments = updatedPayments

    updateInvModal({
      invoice,
      payments: updatedPayments,
      removedPayments: Object.values(oldPaymentsMap).filter((pmt) => pmt.id),
      showCustomPayModal: false,
    })
  }

  const handleAdjustPaymentAmt = (amount) => {
    setCustomPayAmount(amount)
    callAfterTimeout(() => calcAppliedOrdersFrmCustom(amount), 500)
  }

  const renderCustomPayTable = () => {
    return (
      <div style={{ width: '100%' }}>
        <YSpacing height="10px" />
        <Label>Orders To be Applied</Label>
        <Table
          headings={['Order Num', 'Date', 'Total Due', 'To Apply', 'Remaining']}
        >
          {calculatedOrders.map((o) => (
            <tr key={o.id}>
              <td>{o.orderNumber}</td>
              <td>{Moment(o.date).format('MM/DD/YYYY')}</td>
              <td>${o.totalDue.toFixed(2)}</td>
              <td>${o.paymentToApply.toFixed(2)}</td>
              <td>
                $
                {o.percentRemaining === 0
                  ? '0.00 (0.00)%'
                  : `${(o.totalDue - o.paymentToApply).toFixed(2)} (${(
                      o.percentRemaining * 100
                    ).toFixed(2)}%)`}
              </td>
            </tr>
          ))}
        </Table>
        <YSpacing height="25px" />
        <Label>Orders not affected by custom payment</Label>
        <Table headings={['Order Num', 'Date', 'Total on Order']}>
          {notAppliedOrders.map((o) => (
            <tr key={o.id}>
              <td>{o.orderNumber}</td>
              <td>{Moment(o.date).format('MM/DD/YYYY')}</td>
              <td>${getOrderBals(o).total.toFixed(2)}</td>
            </tr>
          ))}
        </Table>
      </div>
    )
  }

  return (
    <Modal
      width="800px"
      title={`Add Custom Payment on ${invoice.invoiceNumber}`}
      color="#001940"
    >
      <BackButton onClick={() => handleCustomPayModal('showCustomPayModal')}>
        ◀ Go back to Invoice: {invoice.invoiceNumber}
      </BackButton>
      <Label>
        Enter the Custom Payment Amount to Apply to {invoice.invoiceNumber}
      </Label>
      <FlexContainer flexDirection="column" width="100%" alignItems="center">
        <Table headings={['Total Paid', 'Total Due', 'Custom Amount']}>
          <tr>
            <td>${invoice.totalPaid.toFixed(2)}</td>
            <td>${totalDue.toFixed(2)}</td>
            <td>
              <CurrencyInput
                value={customPayAmount}
                onChange={handleAdjustPaymentAmt}
                width="30%"
              />
            </td>
          </tr>
        </Table>
        {customPayAmount > 0 && renderCustomPayTable()}
        <YSpacing height="20px" />
        {customPayAmount > 0 && isLoading ? (
          <LoadingIndicator />
        ) : (
          <Button
            label="Add to Batch Invoice"
            backgroundColor={colors.violet}
            onClick={() => {
              setIsLoading(true)
              handleAddCustomPayment()
            }}
          />
        )}
        <p style={{ color: 'red' }}>
          *Please save invoice first prior to paying
        </p>
        <p style={{ color: 'red' }}>
          *Adding these custom batched payments will override any unpaid ad-hoq
          payments
        </p>
        <YSpacing height="25px" />
        <p>
          Note: The custom amount entered will settle balances on the oldest
          invoices on the batch invoice first then update the % invoiced on the
          following invoices to reflect the remaining due for each invoice.
        </p>
      </FlexContainer>
    </Modal>
  )
}

const BackButton = styled.button`
  position: absolute;
  top: 45px;
`

InvoiceCustomPayModal.propTypes = {
  customAmtFromView: PropTypes.number,
  invoice: PropTypes.object,
  invoicedOrders: PropTypes.array,
  payments: PropTypes.array,
  title: PropTypes.string,
  user: PropTypes.string,

  displayWarningMessage: PropTypes.func,
  handleCustomPayModal: PropTypes.func,
  newOrderPayment: PropTypes.func,
  updateInvModal: PropTypes.func,
}

export default InvoiceCustomPayModal
