export const DietaryRestrictions = [
  'Dairy',
  'Eggs',
  'Gluten',
  'Nuts',
  'Pork',
  'Shellfish',
  'Soy',
]

export const ServingsForHeadCount = (headCount) => {
  switch (headCount) {
    case 4:
      return {
        sides: 8,
        mains: 4,
      }
    case 6:
      return {
        sides: 8,
        mains: 8,
      }
    default: // 8 head count and other
      return {
        sides: 16,
        mains: 8,
      }
  }
}
