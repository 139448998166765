import { camelCaseify } from '~/utils'

export const pResponseNotificationOrders = (orders) => {
  return orders.map((order) => pResponseNotificationOrder(order))
}

export const pResponseNotificationOrder = (json) => {
  const order = camelCaseify(json)

  return {
    id: order.id,
    orderNumber: order.orderNumber,
    date: order.clientSetUpTime,
    dueDate: order.dueDate,
    effectiveDate: order.effectiveDate,
    overdueBy: order.overdueBy,
    client: {
      id: order.account.id,
      name: order.account.name,
    },
    salesRep: {
      id: order.accountExecutive.id,
      name: order.accountExecutive.name,
      email: order.accountExecutive.email,
    },
    contact: {
      id: order.contact.id,
      name: order.contact.name,
      email: order.contact.email,
    },
    invoiceContact: {
      id: order.invoiceContact.id,
      name: order.invoiceContact.name,
      email: order.invoiceContact.email,
    },
    receiptContact: {
      id: order.receiptContact.id,
      name: order.receiptContact.name,
      email: order.receiptContact.email,
    },
    lateFee: order.lateFee,
    latePaymentReminderSentAt: order.latePaymentReminderSentAt,
  }
}
