export const CLEAR_EDIT_HQ = 'CLEAR_EDIT_HQ'
export const CLEAR_MARKET = 'CLEAR_MARKET'
export const SET_MARKETS = 'SET_MARKETS'

export const SET_EDIT_DELIVERY_ZONE = 'SET_EDIT_DELIVERY_ZONE'
export const SET_EDIT_HQ = 'SET_EDIT_HQ'
export const SET_EDIT_SERVICE_DEPOT = 'SET_EDIT_SERVICE_DEPOT'

export const SET_DELIVERY_ZONE = 'SET_DELIVERY_ZONE'
export const SET_HQ_DETAILS = 'SET_HQ_DETAILS'
export const SET_SERVICE_DEPOT = 'SET_SERVICE_DEPOT'

export const SET_DELIVERY_ZONES = 'SET_DELIVERY_ZONES'
export const SET_SERVICE_DEPOTS = 'SET_SERVICE_DEPOTS'

export const UPDATE_EDIT_MARKET = 'UPDATE_EDIT_MARKET'

export const clearEditHQ = () => ({ type: CLEAR_EDIT_HQ })
export const clearMarket = () => ({ type: CLEAR_MARKET })

export const setMarkets = (markets) => ({ type: SET_MARKETS, markets })

export const setEditDeliveryZone = (deliveryZone) => ({
  type: SET_EDIT_DELIVERY_ZONE,
  deliveryZone,
})
export const setEditHQ = (hq) => ({ type: SET_EDIT_HQ, hq })
export const setEditServiceDepot = (serviceDepot) => ({
  type: SET_EDIT_SERVICE_DEPOT,
  serviceDepot,
})

export const setDeliveryZone = (deliveryZone) => ({
  type: SET_DELIVERY_ZONE,
  deliveryZone,
})
export const setHQDetails = (hqDetails) => ({ type: SET_HQ_DETAILS, hqDetails })
export const setServiceDepot = (serviceDepot) => ({
  type: SET_SERVICE_DEPOT,
  serviceDepot,
})

export const setDeliveryZones = (deliveryZones) => ({
  type: SET_DELIVERY_ZONES,
  deliveryZones,
})
export const setServiceDepots = (serviceDepots) => ({
  type: SET_SERVICE_DEPOTS,
  serviceDepots,
})

export const updateEditMarket = (market) => ({
  type: UPDATE_EDIT_MARKET,
  market,
})
