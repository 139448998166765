import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

import { DateInput, TextInput, LinkText } from '@components/common/form'
import { NetPaymentDays, AutoBatchPeriods } from '@constants'

const Colspan = '8'
const Headers = [
  'Batch Invoice Number',
  'Batch Creation Type',
  'Created At',
  'Date Range',
  'Payment Terms',
  'Due Date',
  'Total Due',
  'Details',
  'PDF',
]

export class AccountBatchInvoices extends Component {
  state = {
    invoices: undefined,
    filters: {
      page: 1,
      resultsPerPage: 20,
      search: '',
      accountId: undefined,
      fromDate: undefined,
      toDate: undefined,
    },
  }

  componentDidMount() {
    const { filters } = this.state
    const { accountId } = this.props
    this.setState({ filters: { ...filters, accountId } }, () => {
      this.delayedSearchInvoices(true)
    })
  }

  delayedSearchInvoices = () => {
    this.props.callAfterTimeout(() => this.searchInvoices())
  }

  searchInvoices = () => {
    this.setState({ invoices: undefined }, this.loadInvoices)
  }

  loadInvoices = async () => {
    const { filters } = this.state
    const { searchInvoices, accountId } = this.props
    const invoices = await searchInvoices({ ...filters, clientId: accountId })
    this.setState({ invoices })
  }

  setFilter = (newFilters) => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, ...newFilters } }, () =>
      this.delayedSearchInvoices(),
    )
  }

  renderInvoice = (invoice) => {
    const {
      id,
      invoiceNumber,
      invoiceCreationType,
      createdAt,
      dueDate,
      totalDue,
      duration,
    } = invoice
    const { netPaymentDays, autoBatchPeriod, invoicePDFUrl } = this.props
    const link = `../invoices/${id}`
    const createdAtMoment = Moment(createdAt)

    return (
      <tr key={id}>
        <td>
          <div> {invoiceNumber}</div>
        </td>
        <td>
          <div> {invoiceCreationType}</div>
        </td>
        <td>
          <div> {createdAtMoment.format('MMM Do YYYY')}</div>
        </td>
        <td>
          {invoiceCreationType === 'Auto' && (
            <div> {AutoBatchPeriods[autoBatchPeriod]}</div>
          )}
          <div> {duration}</div>
        </td>
        <td>
          <div> {NetPaymentDays[netPaymentDays]}</div>
        </td>
        <td>
          <div> {Moment(dueDate).format('MMM Do YYYY')}</div>
        </td>
        <td>
          <div> ${totalDue}</div>
        </td>
        <td>
          <a
            className="order-link"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {invoiceNumber}
          </a>
        </td>
        <td>
          <LinkText
            isLink={true}
            link={`${invoicePDFUrl}/${invoice.id}/batch_invoice_pdf`}
            label="PDF"
          />
        </td>
      </tr>
    )
  }

  render() {
    const { filters, invoices } = this.state
    const { resultsPerPage, page, search, fromDate, toDate } = filters

    return (
      <table className="table page">
        <thead>
          <tr>
            <th colSpan={Colspan}>
              <h2>Batched Invoices</h2>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan={Colspan}>
              <div className="header-inputs-row">
                <div className="flex flex-align-center">
                  <p className="mr-5">Show</p>
                  <DateInput
                    date={fromDate}
                    onChange={(value) =>
                      this.setFilter({ fromDate: value, page: 1 })
                    }
                  />
                </div>
                <div className="flex flex-align-center ml-5">
                  <p className="mr-5">To</p>
                  <DateInput
                    date={toDate}
                    onChange={(value) =>
                      this.setFilter({ toDate: value, page: 1 })
                    }
                  />
                </div>
                <div className="flex">
                  {invoices && page > 1 && (
                    <button
                      className="page-arrow"
                      onClick={() => this.setFilter({ page: page - 1 })}
                    >
                      ◄
                    </button>
                  )}
                  <p className="nowrap page-number">Page {page}</p>
                  {invoices &&
                    invoices.length > 0 &&
                    invoices.length === resultsPerPage && (
                      <button
                        className="page-arrow"
                        onClick={() => this.setFilter({ page: page + 1 })}
                      >
                        ►
                      </button>
                    )}
                </div>
                <div className="flex flex-align-center ml-20">
                  <p className="mr-5">Show </p>
                  <select
                    className="page-select"
                    value={resultsPerPage}
                    onChange={(e) =>
                      this.setFilter(
                        {
                          resultsPerPage: parseInt(e.target.value || 20),
                          page: 1,
                        },
                        false,
                      )
                    }
                  >
                    {[20, 50, 100].map((o) => (
                      <option key={o} value={o}>
                        {o}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-align-center ml-15 search-input-icon mr-15">
                  <TextInput
                    placeholder="search"
                    value={search}
                    onInput={(e) =>
                      this.setFilter({ search: e.target.value, page: 1 })
                    }
                  />
                </div>
              </div>
            </th>
          </tr>
          <tr className="promocode-table-headers">
            {Headers.map((header, i) => (
              <th key={i}>{header}</th>
            ))}
          </tr>
          {invoices == undefined && (
            <tr>
              <td colSpan={Colspan}>
                <div className="no-promocodes">Loading...</div>
              </td>
            </tr>
          )}
          {invoices && invoices.length === 0 && (
            <tr>
              <td colSpan={Colspan}>
                <div className="no-promocodes">No results</div>
              </td>
            </tr>
          )}
          {invoices && invoices.map((invoice) => this.renderInvoice(invoice))}
        </tbody>
      </table>
    )
  }
}

AccountBatchInvoices.propTypes = {
  accountId: PropTypes.string,
  netPaymentDays: PropTypes.number,
  autoBatchPeriod: PropTypes.number,
  invoicePDFUrl: PropTypes.string,

  callAfterTimeout: PropTypes.func,
  searchInvoices: PropTypes.func,
}

export default AccountBatchInvoices
