import { connect } from 'react-redux'

import { ProposalMaster } from '@components/proposal'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { proposals, router } = state

  return { proposals, ...router }
}

const mapDispatchToProps = () => {
  const { ProposalService, RestService, UIService } = services
  const {
    pRequestOrdersFilter: pRequestProposalsFilter,
    pResponseProposals,
    pResponseProposal,
    pResponseAccountExecutives,
  } = presenters.Api
  const { LoadProposals, SelectProposal, LoadAccountExecutives } = coordinators

  const { clearOrderables: clearProposals } = ProposalService
  const loadProposals = LoadProposals({
    UIService,
    ProposalService,
    RestService,
    pRequestProposalsFilter,
    pResponseProposals,
  })
  const selectProposal = SelectProposal({
    ProposalService,
    RestService,
    pResponseProposal,
  })
  const loadAccountExecutives = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })
  const { setLastLoadProposalsParams } = UIService.ProposalPage

  return {
    clearProposals,
    loadProposals,
    selectProposal,
    loadAccountExecutives,
    setLastLoadProposalsParams,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalMaster)
