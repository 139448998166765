export const LoadConceptSuggestions =
  ({
    RestService,
    pRequestConceptSuggestions,
    pResponseGeneric,
    HandleError,
  }) =>
  async (
    clientSetUpTime,
    filter,
    cuisineType,
    setUpStyle,
    newOrder,
    headCount,
  ) => {
    try {
      const req = pRequestConceptSuggestions(
        clientSetUpTime,
        filter,
        cuisineType,
        setUpStyle,
        newOrder,
        headCount,
      )
      const suggestions = await RestService.get(
        '/api/admin/suggested_chefs',
        req,
      )

      return pResponseGeneric(suggestions)
    } catch (error) {
      HandleError({ error })
    }
  }

export const LoadProfileSuggestions =
  ({
    NodeApiService,
    RestService,
    UIService,
    pRequestConcepts,
    pResponseGeneric,
    HandleError,
  }) =>
  async (clientSetUpTime, cuisineType, accountId, headquarterId) => {
    if (!accountId) {
      UIService.FlashMessage.displayWarningMessage(
        'Account must be selected to load profile suggestions.',
      )
    }

    try {
      const params = {
        hide_metrics: '0',
        account_id: accountId,
        headquarter_id: headquarterId,
      }
      const data = await NodeApiService.get(
        'smart_catering_engine/suggestions',
        params,
      )
      const { suggested } = data

      const req = pRequestConcepts(clientSetUpTime, cuisineType, suggested)
      const suggestions = await RestService.get(
        '/api/admin/suggested_chefs',
        req,
      )

      return pResponseGeneric(suggestions)
    } catch (error) {
      HandleError({ error })
    }
  }
