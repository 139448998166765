import { connect } from 'react-redux'

import { EditChefContactModal } from '@components/chef/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { editChefContact, editChefContactModal, chef } = state
  const { show } = editChefContactModal
  const errors = state.errors.chefContactModal || {}

  return {
    chefId: chef.id,
    chefHqs: chef.headquarters,
    editChefContact,
    errors,
    show,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, UIService } = services
  const { pStateToReduxChefContact } = presenters
  const {
    pRequestUpdateChefContact,
    pRequestChefContacts,
    pResponseChefContacts,
    pResponseChef,
  } = presenters.Api
  const {
    DelayedUpdateChefContact,
    DeleteChefContact,
    SaveChefContact,
    LoadChefContacts,
  } = coordinators

  const { close } = UIService.EditChefContact
  const clearErrors = UIService.Errors.clear
  const delayedUpdateChefContact = DelayedUpdateChefContact({
    ChefService,
    UIService,
    pStateToReduxChefContact,
  })
  const deleteChefContact = DeleteChefContact({
    RestService,
    UIService,
    ChefService,
    pResponseChef,
    HandleError,
  })
  const saveChefContact = SaveChefContact({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateChefContact,
    pResponseChef,
    HandleError,
  })
  const loadChefContacts = LoadChefContacts({
    RestService,
    ChefService,
    pRequestChefContacts,
    pResponseChefContacts,
    HandleError,
  })

  return {
    clearErrors,
    close,
    delayedUpdateChefContact,
    deleteChefContact,
    saveChefContact,
    loadChefContacts,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditChefContactModal)
