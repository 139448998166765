import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { config } from 'hungry-core2'
import Input from '@components/common/form/Input'
import Button from '@components/common/form/Button'
import LinkText from '@components/common/form/LinkText'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import DividerLine from '@components/common/DividerLine'
import Dropdown from '@components/common/form/Dropdown'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'

class OutreachPage extends Component {
  state = {
    zipGroups: [],
    emailDistributions: [],
    newEmailDistribution: undefined,
    newZipGroup: undefined,
    newZipGroupZip: '',
  }

  componentDidMount() {
    this.getZipGroups()
    this.getEmailDistributions()
  }

  // Zip Groups

  getZipGroups = async () => {
    const { getZipGroups } = this.props
    const zipGroups = await getZipGroups()
    if (zipGroups) {
      this.setState({ zipGroups })
    }
  }

  onNewZipGroup = () => {
    const { newZipGroup: buildNewZipGroup, headquarter } = this.props
    const newZipGroup = buildNewZipGroup({ headquarter })
    this.setState({ newZipGroup, newZipGroupZip: '' })
  }

  onAddZip = (e) => {
    if (e.keyCode === 13) {
      const { newZipGroupZip: newZip, newZipGroup: newGroup } = this.state
      this.setState({
        newZipGroup: { ...newGroup, zips: [...newGroup.zips, newZip] },
        newZipGroupZip: '',
      })
    }
  }

  onRemoveZip = (idx) => () => {
    const { newZipGroup: newGroup } = this.state
    this.setState({
      newZipGroup: {
        ...newGroup,
        zips: [
          ...newGroup.zips.slice(0, idx),
          ...newGroup.zips.slice(idx + 1, newGroup.zips.length),
        ],
      },
    })
  }

  onSaveZipGroup = async () => {
    const { saveZipGroup } = this.props
    const { newZipGroup } = this.state
    const group = await saveZipGroup(newZipGroup)
    if (group) {
      this.setState({ newZipGroup: undefined, newZipGroupZip: undefined })
      this.getZipGroups()
      this.getEmailDistributions()
    }
  }

  // Email Distributions

  getEmailDistributions = async () => {
    const { getEmailDistributions } = this.props
    const emailDistributions = await getEmailDistributions()
    if (emailDistributions) {
      this.setState({ emailDistributions })
    }
  }

  onNewEmailDistribution = () => {
    const { newEmailDistribution: buildEmailDistribution, headquarter } =
      this.props
    const newEmailDistribution = buildEmailDistribution({ headquarter })
    this.setState({ newEmailDistribution })
  }

  onAddZipGroup = (e) => {
    const { zipGroups, newEmailDistribution } = this.state
    const group = zipGroups.find((group) => group.id === e.target.value)
    if (group) {
      this.setState({
        newEmailDistribution: {
          ...newEmailDistribution,
          zipGroupIds: [...newEmailDistribution.zipGroupIds, group.id],
          zipGroups: [...newEmailDistribution.zipGroups, group],
        },
      })
    }
  }

  onRemoveZipGroup = (idx) => () => {
    const { newEmailDistribution: dist } = this.state
    this.setState({
      newEmailDistribution: {
        ...dist,
        zipGroupIds: [
          ...dist.zipGroupIds.slice(0, idx),
          ...dist.zipGroupIds.slice(idx + 1, dist.zipGroupIds.length),
        ],
        zipGroups: [
          ...dist.zipGroups.slice(0, idx),
          ...dist.zipGroups.slice(idx + 1, dist.zipGroups.length),
        ],
      },
    })
  }

  onSaveEmailDistribution = async () => {
    const { saveEmailDistribution } = this.props
    const { newEmailDistribution } = this.state
    const dist = await saveEmailDistribution(newEmailDistribution)
    if (dist) {
      this.setState({ newEmailDistribution: undefined })
      this.getEmailDistributions()
    }
  }

  render() {
    const {
      emailDistributions,
      newEmailDistribution,
      newZipGroup,
      newZipGroupZip,
      zipGroups,
    } = this.state

    return (
      <Panel
        width="100%"
        maxWidth="500px"
        heading="Create Zipcode Based Email Distributions"
      >
        {/* Zip Groups */}
        {!newZipGroup && (
          <Button
            label="Create New Zip Code Group"
            onClick={this.onNewZipGroup}
          />
        )}
        {newZipGroup && (
          <div>
            <YSpacing height="20px" />
            <FlexContainer justifyContent="space-between">
              <Input
                label="Group Name"
                value={newZipGroup.name}
                onChange={(e) =>
                  this.setState({
                    newZipGroup: { ...newZipGroup, name: e.target.value },
                  })
                }
              />
              <XSpacing width="20px" />
              <Input
                label="Zip (Press Enter to add)"
                value={newZipGroupZip}
                onChange={(e) =>
                  this.setState({ newZipGroupZip: e.target.value })
                }
                onKeyDown={this.onAddZip}
              />
            </FlexContainer>
          </div>
        )}
        {newZipGroup && (
          <div className="flex flex-row">
            {(newZipGroup.zips || []).map((zip, idx) => (
              <div
                className="selected-zip"
                key={idx}
                onClick={this.onRemoveZip(idx)}
              >
                {zip}
              </div>
            ))}
          </div>
        )}
        {newZipGroup && (
          <FlexContainer justifyContent="flex-start" alignItems="center">
            <Button label="Save Zip Code Group" onClick={this.onSaveZipGroup} />
            <XSpacing width="20px" />
            <LinkText
              label="Cancel"
              color="#ee346d"
              onClick={() =>
                this.setState({
                  newZipGroup: undefined,
                  newZipGroupZip: undefined,
                })
              }
            />
          </FlexContainer>
        )}
        <YSpacing height="10px" />
        <Table headings={['Name', 'Zips', '']}>
          {zipGroups.map((group) => (
            <tr key={group.id}>
              <td>
                <p>{group.name}</p>
              </td>
              <td>
                <p>{group.zips.join(', ')}</p>
              </td>
              <td>
                <LinkText
                  label="Edit"
                  onClick={() =>
                    this.setState({ newZipGroup: group, newZipGroupZip: '' })
                  }
                />
              </td>
            </tr>
          ))}
        </Table>
        <DividerLine margin="20px 0" />
        {/* Email Distributions */}
        {!newEmailDistribution && (
          <Button
            label="Create New Email Distribution"
            onClick={this.onNewEmailDistribution}
          />
        )}
        {newEmailDistribution && (
          <div>
            <YSpacing height="20px" />
            <FlexContainer justifyContent="space-between">
              <Input
                label="Distribution Name"
                value={newEmailDistribution.name}
                onChange={(e) =>
                  this.setState({
                    newEmailDistribution: {
                      ...newEmailDistribution,
                      name: e.target.value,
                    },
                  })
                }
              />
              <XSpacing width="20px" />
              <Dropdown
                label="Zip code Groups"
                value=""
                onChange={this.onAddZipGroup}
              >
                {zipGroups.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </Dropdown>
            </FlexContainer>
            <div className="flex flex-row">
              {(newEmailDistribution.zipGroups || []).map((group, idx) => (
                <div
                  className="selected-zip"
                  key={idx}
                  onClick={this.onRemoveZipGroup(idx)}
                >
                  {group.name}
                </div>
              ))}
            </div>
          </div>
        )}
        {newEmailDistribution && (
          <FlexContainer justifyContent="flex-start" alignItems="center">
            <Button
              label="Save Email Distribution"
              onClick={this.onSaveEmailDistribution}
            />
            <XSpacing width="20px" />
            <LinkText
              label="Cancel"
              color="#ee346d"
              onClick={() => this.setState({ newEmailDistribution: undefined })}
            />
          </FlexContainer>
        )}
        <YSpacing height="10px" />
        <Table headings={['Name', 'Groups', '', '']}>
          {emailDistributions.map((dist) => (
            <tr key={dist.id}>
              <td>
                <p>{dist.name}</p>
              </td>
              <td>
                <p>{dist.zipGroups.map((group) => group.name).join(', ')}</p>
              </td>
              <td>
                <LinkText
                  label="Edit"
                  onClick={() => this.setState({ newEmailDistribution: dist })}
                />
              </td>
              <td>
                <LinkText
                  isLink={true}
                  link={`${config.api_host}/pop-up-customers/email-dists/${dist.id}/csv`}
                  label="Export CSV"
                />
              </td>
            </tr>
          ))}
        </Table>
      </Panel>
    )
  }
}

OutreachPage.propTypes = {
  headquarter: PropTypes.number,
  theme: PropTypes.object,

  getEmailDistributions: PropTypes.func,
  getZipGroups: PropTypes.func,
  newEmailDistribution: PropTypes.func,
  newZipGroup: PropTypes.func,
  saveEmailDistribution: PropTypes.func,
  saveZipGroup: PropTypes.func,
}

export default OutreachPage
