export const SET_LAST_LOAD_ORDERS_PARAMS = 'SET_LAST_LOAD_ORDERS_PARAMS'
export const SET_PAYMENT_PROCESSING = 'SET_PAYMENT_PROCESSING'
export const TOGGLE_EDIT_CHEF_PAYOUTS_MODAL = 'TOGGLE_EDIT_CHEF_PAYOUTS_MODAL'
export const TOGGLE_EDIT_ORDER_MODAL = 'TOGGLE_EDIT_ORDER_MODAL'
export const TOGGLE_INVOICE_BUTTON_ENABLED = 'TOGGLE_INVOICE_BUTTON_ENABLED'

export const setLastLoadOrdersParams = ({ page, search, status }) => ({
  type: SET_LAST_LOAD_ORDERS_PARAMS,
  page,
  search,
  status,
})

export const setPaymentProcessing = (processing) => ({
  type: SET_PAYMENT_PROCESSING,
  processing,
})

export const toggleEditChefPayoutsModal = ({ show }) => ({
  type: TOGGLE_EDIT_CHEF_PAYOUTS_MODAL,
  show,
})

export const toggleEditOrderModal = ({ show, mode, section }) => ({
  type: TOGGLE_EDIT_ORDER_MODAL,
  show,
  mode,
  section,
})

export const toggleInvoiceButtonEnabled = (on) => ({
  type: TOGGLE_INVOICE_BUTTON_ENABLED,
  on,
})
