import { BaseService } from 'hungry-core2'

import actions from '@actions'

import { SECTION1 } from '../../../constants'

export default class EditOrder extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditOrderModal({ show: false }))
  }

  closeChefPayouts = () => {
    this.dispatch(actions.toggleEditChefPayoutsModal({ show: false }))
  }

  getMode = () => {
    return this.getState().editOrderModal.mode
  }

  show = (mode, section = SECTION1) => {
    this.dispatch(actions.toggleEditOrderModal({ show: true, mode, section }))
  }

  showChefPayouts = () => {
    this.dispatch(actions.toggleEditChefPayoutsModal({ show: true }))
  }
}
