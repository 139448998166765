import { connect } from 'react-redux'

import NewSubscriberModal from '@components/subscription/edit/NewSubscriberModal'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapDispatchToProps = () => {
  const { RestService, SubscriptionService, UIService } = services
  const { CreateSubscriber, LinkSubscriber, LoadSubscribers } = coordinators
  const { pRequestSubscriber, pResponseSubscriber } = presenters.Api

  const flashError = (message) =>
    UIService.FlashMessage.displayFailureMessage(message)

  const createMember = CreateSubscriber({
    RestService,
    pRequestSubscriber,
    HandleError,
  })
  const linkMember = LinkSubscriber({
    RestService,
    pRequestSubscriber,
    HandleError,
  })

  const refreshMembers = LoadSubscribers({
    RestService,
    SubscriptionService,
    pResponseSubscriber,
    HandleError,
  })

  return {
    createMember,
    flashError,
    linkMember,
    refreshMembers,
  }
}

export default connect(undefined, mapDispatchToProps)(NewSubscriberModal)
