import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { AuthorizedInteractable } from '@containers/common/auth'

export class DiningSupplies extends Component {
  constructor(props) {
    super(props)

    this.state = {
      allOrderTypes: [],
      allServiceTypes: [],
      allSetUpStyles: [],
      diningSupplies: props.diningSupplies,
      orderMappings: [],
      selectedDiningSupply: null,
      editDiningSupply: null,
      editOrderMapping: null,
      newDiningSupply: null,
      newOrderMapping: null,
      sortAsc: true,
    }
  }

  componentWillMount() {
    ;(async () => {
      this.props.loadDiningSupplies()
      const { allOrderTypes, allServiceTypes, allSetUpStyles } =
        await this.props.loadOrderSettings()
      this.setState({
        allOrderTypes: allOrderTypes.map((t) => {
          return { label: t.value, value: t.value }
        }),
        allServiceTypes: allServiceTypes.map((t) => {
          return { label: t.value, value: t.value }
        }),
        allSetUpStyles: allSetUpStyles.map((t) => {
          return { label: t.value, value: t.value }
        }),
      })
    })()
  }

  componentWillReceiveProps(nextProps) {
    const { diningSupplies } = nextProps

    if (diningSupplies !== this.state.diningSupplies) {
      const newState = { diningSupplies }
      const { selectedDiningSupply } = this.state
      const updatedDiningSupply =
        selectedDiningSupply &&
        diningSupplies.find((p) => p.id == selectedDiningSupply.id)
      if (updatedDiningSupply) {
        const { orderMappings } = updatedDiningSupply
        newState.selectedDiningSupply = updatedDiningSupply
        newState.orderMappings = orderMappings
      } else {
        newState.selectedDiningSupply = null
        newState.orderMappings = []
      }

      this.setState(newState)
    }
  }

  sortByString = (collectionName, columnName) => {
    const { [collectionName]: collection, sortAsc } = this.state
    const getColumn = (item) =>
      item[columnName] ? item[columnName].toLowerCase() : ''
    const sorted = collection.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ [collectionName]: sorted, sortAsc: !sortAsc })
  }

  sortByBoolean = (collectionName, columnName) => {
    const { [collectionName]: collection, sortAsc } = this.state
    const getColumn = (item) => (item[columnName] === true ? 1 : 0)
    const sorted = collection.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ [collectionName]: sorted, sortAsc: !sortAsc })
  }

  sortByNumber = (collectionName, columnName) => {
    const { [collectionName]: collection, sortAsc } = this.state
    const sorted = collection.sort((a, b) => {
      if (sortAsc) {
        return a[columnName] - b[columnName]
      } else {
        return b[columnName] - a[columnName]
      }
    })
    this.setState({ [collectionName]: sorted, sortAsc: !sortAsc })
  }

  selectPrimaryRow = (addOn) => {
    const { selectedDiningSupply } = this.state
    if (selectedDiningSupply == addOn) {
      this.setState({
        newDiningSupply: null,
        newOrderMapping: null,
        editDiningSupply: { ...addOn },
        editOrderMapping: null,
      })
    } else {
      const { orderMappings } = addOn
      this.setState({
        selectedDiningSupply: addOn,
        orderMappings,
        newDiningSupply: null,
        newOrderMapping: null,
        editDiningSupply: null,
        editOrderMapping: null,
      })
    }
  }

  renderMapping = (orderMapping, i) => {
    const { editOrderMapping } = this.state
    const id = editOrderMapping && editOrderMapping.id
    if (orderMapping.id == id) {
      return this.renderEditMapping(editOrderMapping, i)
    } else {
      return this.renderStaticMapping(orderMapping, i)
    }
  }

  renderStaticMapping = (orderMapping, i) => {
    const isEvent =
      orderMapping.isEvent == null
        ? 'Both event and non-events'
        : orderMapping.isEvent
          ? 'Events only'
          : 'Non-Events only'
    const usageRate =
      orderMapping.supplyUsageRate == null
        ? 'Default'
        : '%' + (orderMapping.supplyUsageRate * 100).toFixed(2)

    return (
      <tr
        key={i}
        className="clickable"
        onClick={(e) => {
          e.preventDefault()
          this.setState({
            newDiningSupply: null,
            newOrderMapping: null,
            editDiningSupply: null,
            editOrderMapping: { ...orderMapping },
          })
        }}
      >
        <td>{isEvent}</td>
        <td>{orderMapping.serviceType.join(', ')}</td>
        <td>{orderMapping.orderType.join(', ')}</td>
        <td>{orderMapping.setUpStyle.join(', ')}</td>
        <td>{usageRate}</td>
      </tr>
    )
  }

  renderEditMapping = (orderMapping, i) => {
    const { allOrderTypes, allSetUpStyles, allServiceTypes } = this.state

    return (
      <tr key={i}>
        <td>
          <select
            value={orderMapping.isEvent}
            onChange={(e) => {
              this.setState({
                editOrderMapping: {
                  ...orderMapping,
                  isEvent: e.target.value,
                },
              })
            }}
          >
            <option>Both Event and Non-Events</option>
            <option value={true}>Events Only</option>
            <option value={false}>Non-Events Only</option>
          </select>
        </td>
        <td>
          <Select
            multi
            className="dietary-pref-selector"
            placeholder="Service Types"
            closeOnSelect={false}
            options={allServiceTypes}
            value={orderMapping.serviceType}
            onChange={(serviceType) =>
              this.setState({
                editOrderMapping: {
                  ...orderMapping,
                  serviceType: serviceType.map((t) => t.value),
                },
              })
            }
          />
        </td>
        <td>
          <Select
            multi
            className="dietary-pref-selector"
            placeholder="Order Types"
            closeOnSelect={false}
            options={allOrderTypes}
            value={orderMapping.orderType}
            onChange={(orderType) =>
              this.setState({
                editOrderMapping: {
                  ...orderMapping,
                  orderType: orderType.map((t) => t.value),
                },
              })
            }
          />
        </td>
        <td>
          <Select
            multi
            className="dietary-pref-selector"
            placeholder="Set Up Styles"
            closeOnSelect={false}
            options={allSetUpStyles}
            value={orderMapping.setUpStyle}
            onChange={(setUpStyle) =>
              this.setState({
                editOrderMapping: {
                  ...orderMapping,
                  setUpStyle: setUpStyle.map((t) => t.value),
                },
              })
            }
          />
        </td>
        <td>
          <input
            type="text"
            value={orderMapping.supplyUsageRate}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editOrderMapping: {
                  ...orderMapping,
                  supplyUsageRate: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <AuthorizedInteractable
            roles={['master admin', 'chef lead', 'captain lead']}
          >
            <button
              className="button-neutral"
              key="deleteOrderMapping"
              onClick={async (event) => {
                event.preventDefault()
                const { saveDiningSupply } = this.props
                orderMapping._destroy = true
                if (await saveDiningSupply({ orderMapping: orderMapping })) {
                  this.setState({ editOrderMapping: null })
                }
              }}
            >
              Delete Mapping
            </button>
          </AuthorizedInteractable>
        </td>
        <td>
          <button
            className="button-neutral"
            key="clearEditOrderMapping"
            onClick={(event) => {
              event.preventDefault()
              this.setState({ editOrderMapping: null })
            }}
          >
            Clear Updates
          </button>
        </td>
      </tr>
    )
  }

  renderPrimaryRow = (supply, i) => {
    const { editDiningSupply } = this.state
    const id = editDiningSupply && editDiningSupply.id
    if (supply.id == id) {
      return this.renderPrimaryEditRow(editDiningSupply, i)
    } else {
      return this.renderPrimaryStaticRow(supply, i)
    }
  }

  renderPrimaryStaticRow = (supply, i) => {
    const usageRate =
      supply.dftSupplyUsageRate == null
        ? 'None'
        : '%' + (supply.dftSupplyUsageRate * 100).toFixed(2)

    return (
      <tr
        key={i}
        className="clickable"
        onClick={(e) => {
          e.preventDefault()
          this.selectPrimaryRow(supply)
        }}
      >
        <td>{supply.name}</td>
        <td>{supply.supplyType}</td>
        <td>{supply.defaultUnitCost}</td>
        <td>
          {supply.active ? (
            <span className="approved-check">✔</span>
          ) : (
            <span className="not-approved-x">✖</span>
          )}
        </td>
        <td>
          {supply.trackInventory ? (
            <span className="approved-check">✔</span>
          ) : (
            <span className="not-approved-x">✖</span>
          )}
        </td>
        <td>{usageRate}</td>
        <td>%{(supply.dftRecoupPct * 100).toFixed(2)}</td>
        <td>%{(supply.dftHungryVipRecoupPct * 100).toFixed(2)}</td>
      </tr>
    )
  }

  renderPrimaryEditRow = (supply, i) => {
    return (
      <tr key={i}>
        <td>
          <input
            type="text"
            value={supply.name}
            onInput={(e) =>
              this.setState({
                editDiningSupply: { ...supply, name: e.target.value },
              })
            }
          />
        </td>
        <td>{supply.supplyType}</td>
        <td>
          <input
            type="text"
            value={supply.defaultUnitCost}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editDiningSupply: {
                  ...supply,
                  defaultUnitCost: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <input
            type="checkbox"
            checked={supply.active}
            onChange={(e) =>
              this.setState({
                editDiningSupply: { ...supply, active: e.target.checked },
              })
            }
          />
        </td>
        <td>
          <input
            type="checkbox"
            checked={supply.trackInventory}
            onChange={(e) =>
              this.setState({
                editDiningSupply: {
                  ...supply,
                  trackInventory: e.target.checked,
                },
              })
            }
          />
        </td>
        <td>
          <input
            type="text"
            value={supply.dftSupplyUsageRate}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editDiningSupply: {
                  ...supply,
                  dftSupplyUsageRate: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <input
            type="text"
            value={supply.dftRecoupPct}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editDiningSupply: {
                  ...supply,
                  dftRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <input
            type="text"
            value={supply.dftHungryVipRecoupPct}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editDiningSupply: {
                  ...supply,
                  dftHungryVipRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <AuthorizedInteractable
            roles={['master admin', 'chef lead', 'captain lead']}
          >
            <button
              className="button-neutral"
              key="deleteDiningSupply"
              onClick={async (event) => {
                event.preventDefault()
                const { deleteDiningSupply } = this.props
                if (await deleteDiningSupply(supply.id)) {
                  this.setState({ editDiningSupply: null })
                }
              }}
            >
              Delete Supply
            </button>
          </AuthorizedInteractable>
        </td>
        <td>
          <button
            className="button-neutral"
            key="clearEditOrderMapping"
            onClick={(event) => {
              event.preventDefault()
              this.setState({ editDiningSupply: null })
            }}
          >
            Clear Updates
          </button>
        </td>
      </tr>
    )
  }

  renderPrimaryButtons = () => {
    const {
      editDiningSupply,
      editOrderMapping,
      newDiningSupply,
      newOrderMapping,
    } = this.state
    const { saveDiningSupply } = this.props
    const renderNew =
      !newDiningSupply &&
      !newOrderMapping &&
      !editOrderMapping &&
      !editDiningSupply
    const editedSupply = editDiningSupply || newDiningSupply

    return (
      <div className="page">
        <center>
          {editedSupply && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="editDiningSupply"
                onClick={async (event) => {
                  event.preventDefault()
                  if (await saveDiningSupply({ diningSupply: editedSupply })) {
                    this.setState({
                      editDiningSupply: null,
                      newDiningSupply: null,
                    })
                  }
                }}
              >
                Save Dining Supply Changes
              </button>
            </AuthorizedInteractable>
          )}
          {renderNew && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="newDiningSupply"
                onClick={(event) => {
                  event.preventDefault()
                  this.setState({
                    newDiningSupply: {
                      name: '',
                      active: true,
                      trackInventory: true,
                      dftSupplyUsageRate: 1.0,
                      dftRecoupPct: 0.0,
                      dftHungryVipRecoupPct: 0.0,
                    },
                  })
                }}
              >
                New Dining Supply
              </button>
            </AuthorizedInteractable>
          )}
        </center>
      </div>
    )
  }

  renderMappingButtons = () => {
    const {
      selectedDiningSupply,
      editDiningSupply,
      editOrderMapping,
      newDiningSupply,
      newOrderMapping,
    } = this.state
    const { saveDiningSupply } = this.props
    const renderNew =
      !newDiningSupply &&
      !newOrderMapping &&
      !editOrderMapping &&
      !editDiningSupply &&
      selectedDiningSupply
    const editedMapping = editOrderMapping || newOrderMapping

    return (
      <div className="page">
        <center>
          {editedMapping && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="editOrderMapping"
                onClick={async (event) => {
                  event.preventDefault()
                  if (await saveDiningSupply({ orderMapping: editedMapping })) {
                    this.setState({
                      editOrderMapping: null,
                      newOrderMapping: null,
                    })
                  }
                }}
              >
                Save Order Mapping Changes
              </button>
            </AuthorizedInteractable>
          )}
          {renderNew && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="newPackagingMapping"
                onClick={(event) => {
                  event.preventDefault()
                  this.setState({
                    newOrderMapping: {
                      orderSuppliableId: selectedDiningSupply.id,
                      isEvent: null,
                      serviceType: [],
                      orderType: [],
                      setUpStyle: [],
                      supplyUsageRate: 1.0,
                    },
                  })
                }}
              >
                New Order Mapping
              </button>
            </AuthorizedInteractable>
          )}
        </center>
      </div>
    )
  }

  renderNewDiningSupply = () => {
    const { newDiningSupply } = this.state

    return (
      <table className="table table-hover page">
        <thead>
          <tr>
            <th colSpan={7}>New Dining Supply</th>
          </tr>
          <tr>
            <th>Supply Name</th>
            <th>Active</th>
            <th>Default Unit Cost</th>
            <th>Track Inventory</th>
            <th>Default Usage Per Headcount</th>
            <th>Recoup Pct</th>
            <th>Hungry VIP Recoup Pct</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                value={newDiningSupply.name}
                onInput={(e) =>
                  this.setState({
                    newDiningSupply: {
                      ...newDiningSupply,
                      name: e.target.value,
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="checkbox"
                checked={newDiningSupply.active}
                onChange={(e) =>
                  this.setState({
                    newDiningSupply: {
                      ...newDiningSupply,
                      active: e.target.checked,
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="text"
                value={newDiningSupply.defaultUnitCost}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newDiningSupply: {
                      ...newDiningSupply,
                      defaultUnitCost: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
            <td>
              <input
                type="checkbox"
                checked={newDiningSupply.trackInventory}
                onChange={(e) =>
                  this.setState({
                    newDiningSupply: {
                      ...newDiningSupply,
                      trackInventory: e.target.checked,
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="text"
                value={newDiningSupply.dftSupplyUsageRate}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newDiningSupply: {
                      ...newDiningSupply,
                      dftSupplyUsageRate: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
            <td>
              <input
                type="text"
                value={newDiningSupply.dftRecoupPct}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newDiningSupply: {
                      ...newDiningSupply,
                      dftRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
            <td>
              <input
                type="text"
                value={newDiningSupply.dftHungryVipRecoupPct}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newDiningSupply: {
                      ...newDiningSupply,
                      dftHungryVipRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderNewOrderMapping = () => {
    const { newOrderMapping, allServiceTypes, allOrderTypes, allSetUpStyles } =
      this.state

    return (
      <table className="table table-hover page">
        <thead>
          <tr>
            <th colSpan={5}>New Order Mapping</th>
          </tr>
          <tr>
            <th>Is Event</th>
            <th>Service Type</th>
            <th>Order Type</th>
            <th>Set Up Style</th>
            <th>Usage Per Headcount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <select
                value={newOrderMapping.isEvent}
                onChange={(e) => {
                  this.setState({
                    newOrderMapping: {
                      ...newOrderMapping,
                      isEvent: e.target.value,
                    },
                  })
                }}
              >
                <option>Both Event and Non-Events</option>
                <option value={true}>Events Only</option>
                <option value={false}>Non-Events Only</option>
              </select>
            </td>
            <td>
              <Select
                multi
                className="dietary-pref-selector"
                placeholder="Service Types"
                closeOnSelect={false}
                options={allServiceTypes}
                value={newOrderMapping.serviceType}
                onChange={(serviceType) =>
                  this.setState({
                    newOrderMapping: {
                      ...newOrderMapping,
                      serviceType: serviceType.map((t) => t.value),
                    },
                  })
                }
              />
            </td>
            <td>
              <Select
                multi
                className="dietary-pref-selector"
                placeholder="Order Types"
                closeOnSelect={false}
                options={allOrderTypes}
                value={newOrderMapping.orderType}
                onChange={(orderType) =>
                  this.setState({
                    newOrderMapping: {
                      ...newOrderMapping,
                      orderType: orderType.map((t) => t.value),
                    },
                  })
                }
              />
            </td>
            <td>
              <Select
                multi
                className="dietary-pref-selector"
                placeholder="Set Up Styles"
                closeOnSelect={false}
                options={allSetUpStyles}
                value={newOrderMapping.setUpStyle}
                onChange={(setUpStyle) =>
                  this.setState({
                    newOrderMapping: {
                      ...newOrderMapping,
                      setUpStyle: setUpStyle.map((t) => t.value),
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="text"
                value={newOrderMapping.supplyUsageRate}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newOrderMapping: {
                      ...newOrderMapping,
                      supplyUsageRate: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  render() {
    const {
      diningSupplies,
      orderMappings,
      selectedDiningSupply,
      newDiningSupply,
      newOrderMapping,
    } = this.state

    return (
      <div>
        <br />
        <h3>Description</h3>
        <p>
          These are supplies used by catering attendees for dining purposes and
          are mapped according to an orders headcount. Dining supplies can be
          mapped to orders by event, service_types, order types, and set up
          styles. Orders that match that mapping will include the dining supply
          with quantity equal to its mapped usage rate. Dining supplies can also
          be associated with service items and will be included with those items
          on orders.
        </p>
        <br />

        <h3>Instructions</h3>
        <p>
          <ul>
            <li>Click a supply to see what types of orders it is mapped to.</li>
            <li>Click the supply again to edit.</li>
            <li>Click order mappings to edit them as well.</li>
            <li>
              * Supplies should not have overlapping order mappings unless
              duplicated supplies are intended.
            </li>
          </ul>
        </p>
        <br />

        <h3>Columns</h3>
        <p>
          <ul>
            <li>
              Recoup Pct - Percent of supply recouped after a catering. Will
              factor into a lower cost per order.
            </li>
            <li>
              Hungry VIP Recoup Pct - Percent of supply recouped after a HUNGRY
              VIP service type catering. Replaces the recoup pct and will factor
              into a lower cost per order.
            </li>
            <li>
              Default Usage Per Headcount - The default usage rate of the supply
              used with each order as a percentage of head count. Used if the
              Usage Per Order is not defined.
            </li>
            <li>
              Usage Per Headcount - The usage rate of the supply used with each
              order as a percentage of head count.
            </li>
          </ul>
        </p>
        <br />

        <table className="table table-hover page">
          <thead>
            <tr>
              <th onClick={() => this.sortByString('diningSupplies', 'name')}>
                Supply Name
              </th>
              <th
                onClick={() =>
                  this.sortByString('diningSupplies', 'supplyType')
                }
              >
                Supply Type
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('diningSupplies', 'defaultUnitCost')
                }
              >
                Default Unit Cost
              </th>
              <th
                onClick={() => this.sortByBoolean('diningSupplies', 'active')}
              >
                Active
              </th>
              <th
                onClick={() =>
                  this.sortByBoolean('diningSupplies', 'trackInventory')
                }
              >
                Track Inventory
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('diningSupplies', 'dftSupplyUsageRate')
                }
              >
                Default Usage Per Headcount
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('diningSupplies', 'dftRecoupPct')
                }
              >
                Recoup Pct
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('diningSupplies', 'dftHungryVipRecoupPct')
                }
              >
                Hungry VIP Recoup Pct
              </th>
            </tr>
          </thead>
          <tbody>
            {diningSupplies.map((supply, i) =>
              this.renderPrimaryRow(supply, i),
            )}
          </tbody>
        </table>
        {newDiningSupply && this.renderNewDiningSupply()}
        {this.renderPrimaryButtons()}

        {selectedDiningSupply && (
          <div>
            <table className="table table-hover page">
              <thead>
                <tr>
                  <th colSpan={5}>
                    {selectedDiningSupply.name} - Order Mapping
                  </th>
                </tr>
                <tr>
                  <th
                    onClick={() =>
                      this.sortByString('orderMappings', 'isEvent')
                    }
                  >
                    Is Event
                  </th>
                  <th
                    onClick={() =>
                      this.sortByString('orderMappings', 'serviceType')
                    }
                  >
                    Service Type
                  </th>
                  <th
                    onClick={() =>
                      this.sortByString('orderMappings', 'orderType')
                    }
                  >
                    Order Type
                  </th>
                  <th
                    onClick={() =>
                      this.sortByString('orderMappings', 'setUpStyle')
                    }
                  >
                    Set Up Style
                  </th>
                  <th
                    onClick={() =>
                      this.sortByString('orderMappings', 'supplyUsageRate')
                    }
                  >
                    Usage Per Headcount
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderMappings.map((mapping, i) =>
                  this.renderMapping(mapping, i),
                )}
                {orderMappings.length == 0 && (
                  <tr>
                    <td colSpan={5}>No Order Mappings</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {newOrderMapping && this.renderNewOrderMapping()}
        {this.renderMappingButtons()}
      </div>
    )
  }
}

DiningSupplies.propTypes = {
  diningSupplies: PropTypes.arrayOf(PropTypes.object),

  deleteDiningSupply: PropTypes.func,
  loadDiningSupplies: PropTypes.func,
  loadOrderSettings: PropTypes.func,
  saveDiningSupply: PropTypes.func,
}

export default DiningSupplies
