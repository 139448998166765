import { connect } from 'react-redux'

import { InvoiceClient } from '@components/proposal/proposalSections/panel'

import coordinators from '@coordinators'
import services from '@services'
import { config } from 'hungry-core2'

const mapStateToProps = (state) => {
  const { emailProposalOn } = state.proposalPage
  const apiUrl = `${config.api_host}/api/admin/`
  const proposalPdfUrlPrefix = `${apiUrl}proposals`

  return {
    emailProposalButtonEnabled: emailProposalOn,
    proposalPdfUrlPrefix,
  }
}

const mapDispatchToProps = () => {
  const { ProposalService, RestService, UIService } = services
  const { EmailProposal } = coordinators
  const emailProposal = EmailProposal({
    ProposalService,
    RestService,
    UIService,
  })

  return {
    emailProposal,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceClient)
