import React from 'react'
import { BsChatDots } from 'react-icons/bs'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { colors } from '../../../constants'

const TechHelpForm = (props) => {
  return (
    <TechIssue margin={props.margin}>
      <BsChatDots size={18} color={colors.blue400} />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://form.asana.com/?k=b_k-Kcfya0-jUf29Xa4DZw&d=243108287573968"
      >
        New Request/Bug?
      </a>
    </TechIssue>
  )
}

export const TechIssue = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin};
  svg {
    margin-right: 5px;
  }
  a {
    display: inline-block;
    margin-left: 5px;
    color: ${colors.blue400};
    transition: 0.2s ease-in-out all;
    &:hover {
      color: #00535c;
      text-decoration: none;
    }
  }
`

TechHelpForm.propTypes = {
  margin: PropTypes.string,
}

export default TechHelpForm
