import { connect } from 'react-redux'

import { MarketsPage } from '@components/market'

import coordinators from '@coordinators'
import services from '@services'

const mapStateToProps = (state) => {
  const hqName = state.headquartersDetail && state.headquartersDetail.name

  return { hqName }
}

const mapDispatchToProps = () => {
  const { NewMarket } = coordinators
  const { MarketService, UIService } = services

  const newMarket = NewMarket({ MarketService, UIService })

  return { newMarket }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketsPage)
