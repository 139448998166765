import { connect } from 'react-redux'

import SubscriptionPage from '@components/subscription/SubscriptionPage'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { subscriptions, user } = state
  const { pUserHasCurrentRoles } = presenters.Api
  const userRep = pUserHasCurrentRoles(user, ['sales rep']) ? user : undefined

  return {
    subscriptions,
    hqId: user.lastLoggedInHq,
    userRep,
  }
}

const mapDispatchToProps = () => {
  const { RestService, SubscriptionService, UIService } = services
  const {
    pResponseAccountExecutives,
    pRequestChooseSalesRep,
    pRequestSubscription,
    pResponseHomeMenu,
    pResponseSubscription,
    pSubscriptionCutoffs,
  } = presenters.Api
  const {
    ChooseSubsSalesRep,
    DeleteSubscription,
    LoadAccountExecutives,
    LoadHomeMenu,
    LoadHomeMenus,
    LoadSubscriptions,
    NotifySubscriptionChef,
    SaveSubscription,
    SendSubscriptionSurveys,
  } = coordinators

  const { callAfterTimeout } = UIService.Timer
  const chooseSubsSalesRep = ChooseSubsSalesRep({
    RestService,
    pRequestChooseSalesRep,
    HandleError,
  })
  const deleteSubscription = DeleteSubscription({
    RestService,
    UIService,
    HandleError,
  })
  const flashError = (message) =>
    UIService.FlashMessage.displayFailureMessage(message)
  const flashSuccess = (message) =>
    UIService.FlashMessage.displaySuccessMessage(message)
  const loadHomeMenu = LoadHomeMenu({
    RestService,
    pResponseHomeMenu,
    HandleError,
  })
  const loadHomeMenus = LoadHomeMenus({ RestService, HandleError })
  const loadSalesReps = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })(false) // is vcx
  const loadSubscriptions = LoadSubscriptions({
    SubscriptionService,
    RestService,
    pResponseSubscription,
    pSubscriptionCutoffs,
    HandleError,
  })
  const notifySubscriptionChef = NotifySubscriptionChef({
    RestService,
    UIService,
    HandleError,
  })
  const saveSubscription = SaveSubscription({
    RestService,
    pRequestSubscription,
    HandleError,
  })
  const sendSurveys = SendSubscriptionSurveys({
    RestService,
    UIService,
    HandleError,
  })

  return {
    callAfterTimeout,
    chooseSubsSalesRep,
    deleteSubscription,
    flashError,
    flashSuccess,
    loadHomeMenu,
    loadHomeMenus,
    loadSalesReps,
    loadSubscriptions,
    notifySubscriptionChef,
    saveSubscription,
    sendSurveys,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage)
