import {
  GetOrderableCount,
  LoadOrderable,
  LoadQueryOrderable,
  SearchOrderable,
  SelectOrderable,
} from '@coordinators/orderable'
import { sortByAttribute } from '../../../utils'

export const LoadAccountExecutives =
  ({ RestService, pResponseAccountExecutives }) =>
  (isVCX) =>
  async (settings) => {
    const executives = await RestService.get('/api/admin/users', {
      ...settings,
      roles: ['account_executive', 'master_account_executive'],
      is_vcx: isVCX,
    })

    return pResponseAccountExecutives(executives)
  }

export const AsyncLoadAccount =
  ({ RestService, pResponseGeneric, HandleError }) =>
  async (accountId, namespace = 'editOrderModal') => {
    let account
    try {
      account = await RestService.get(`/api/admin/accounts/${accountId}`)
    } catch (error) {
      HandleError({ error, namespace })
    }

    return pResponseGeneric(account)
  }

export const AsyncLoadAccounts =
  ({ RestService, pResponseGeneric }) =>
  async (settings) => {
    const accounts = await RestService.get('/api/admin/accounts', settings, {
      timeout: 30000,
    })
    const accResp = pResponseGeneric(accounts)
    Array.isArray(accResp) && sortByAttribute(accResp, 'name')

    return accResp
  }

export const AsyncLoadCateringCaptains =
  ({ RestService, pResponseCateringCaptains }) =>
  async (settings) => {
    const cateringCaptains = await RestService.get('/api/admin/users', {
      ...settings,
      role: 'catering_captain',
    })

    return pResponseCateringCaptains(cateringCaptains)
  }

export const LoadAllMenuItemAttributes =
  ({ RestService, pResponseGeneric }) =>
  async () => {
    const response = await RestService.get(
      '/api/admin/menu_items/all_attributes',
    )

    return pResponseGeneric(response)
  }

export const LoadCateringCaptains =
  ({ OrderService, RestService, pResponseCateringCaptains }) =>
  async () => {
    let cateringCaptains = await RestService.get('/api/admin/users', {
      role: 'catering_captain',
    })
    cateringCaptains = pResponseCateringCaptains(cateringCaptains)
    OrderService.setCateringCaptains(cateringCaptains)
  }

export const LoadOrders =
  ({
    OrderService,
    RestService,
    UIService,
    pRequestOrdersFilter,
    pResponseOrders,
  }) =>
  async (filters) => {
    return LoadOrderable({
      reduxService: OrderService,
      RestService,
      GetLastLoadOrderablesParams: UIService.OrderPage.getLastLoadOrdersParams,
      pRequestFilter: pRequestOrdersFilter,
      pResponse: pResponseOrders,
      url: '/api/admin/orders',
    })(filters)
  }

export const SearchOrders =
  ({ RestService, pRequestOrdersFilter, pResponseOrders }) =>
  async (filters) => {
    return SearchOrderable({
      RestService,
      pRequestFilter: pRequestOrdersFilter,
      pResponse: pResponseOrders,
      url: '/api/admin/orders',
    })(filters)
  }

export const GetOrderCount =
  ({ RestService, pRequestOrdersFilter }) =>
  async (filters) => {
    return GetOrderableCount({
      RestService,
      pRequestFilter: pRequestOrdersFilter,
      url: '/api/admin/orders',
    })(filters)
  }

export const AsyncLoadLandingOrders =
  ({ RestService, pResponseGeneric }) =>
  async (date) => {
    const orders = date
      ? await RestService.get('/api/admin/landing', {
          view: 'orders',
          date: date.toDate(),
        })
      : await RestService.get('/api/admin/landing', { view: 'orders' })

    return pResponseGeneric(orders)
  }

export const LoadOrderOptions =
  ({ RestService, pResponseOrderOptions }) =>
  async () => {
    const options = await RestService.get('/api/admin/orders/all_order_options')

    return pResponseOrderOptions(options)
  }

export const LoadQueryOrder =
  ({ OrderService, RestService, RouterService, pResponseOrder }) =>
  async () => {
    return LoadQueryOrderable({
      reduxService: OrderService,
      RestService,
      RouterService,
      pResponse: pResponseOrder,
      orderableType: 'order',
      url: '/api/admin/orders',
    })()
  }

export const LoadServiceDepots =
  ({ OrderService, RestService, pRequestServiceDepots }) =>
  async () => {
    let serviceDepots = await RestService.get('/api/admin/service_depots')
    serviceDepots = pRequestServiceDepots(serviceDepots)
    OrderService.setServiceDepots(serviceDepots)
  }

export const LoadServiceOrderTimes =
  ({ RestService, pResponseServiceOrder }) =>
  async (order) => {
    let serviceTimes = await RestService.get(
      `/api/admin/services_dashboard/${order.orderNumber}`,
    )
    serviceTimes = pResponseServiceOrder(serviceTimes)

    return serviceTimes
  }

export const SelectOrder =
  ({ OrderService, RestService, pResponseOrder }) =>
  async (orderId) => {
    return SelectOrderable({
      reduxService: OrderService,
      RestService,
      pResponse: pResponseOrder,
      url: '/api/admin/orders',
    })(orderId)
  }

export const PredictServiceCosts =
  ({
    RestService,
    pRequestPredictServiceCosts,
    pRequestOrderSupplyBreakdown,
    pResponseServiceCosts,
  }) =>
  async (data) => {
    const svcReq = pRequestPredictServiceCosts(data)
    if (!svcReq) {
      return false
    }
    const { orderable_hash } = pRequestOrderSupplyBreakdown(data)
    let response
    try {
      response = await RestService.put(
        '/api/admin/services_dashboard/predict_service_costs',
        { ...svcReq, ...orderable_hash },
      )
    } catch (error) {
      return false
    }

    return pResponseServiceCosts(response)
  }

export const GetAccountingOrder =
  ({ RestService, HandleError }) =>
  async (id) => {
    try {
      const order = await RestService.get(`/accounting/orders/${id}`)

      return order
    } catch (error) {
      HandleError({ error })
    }
  }

export const UpsertAccountingOrder =
  ({ RestService }) =>
  async (data) => {
    const order = await RestService.post(`/accounting/orders/${data.id}`, data)

    return order
  }
