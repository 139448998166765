export const DelayedUpdateUser =
  ({ AdminUserService, UIService, pStateToReduxUser }) =>
  (user) => {
    const call = () => AdminUserService.setAdminUser(pStateToReduxUser(user))
    UIService.Timer.callAfterTimeout(call)
  }

// export const DeleteUser = ({ AdminUserService, RestService, UIService, HandleError }) => async () => {
//   const { id: userId, name } = AdminUserService.getState().adminUser
//   const text = `Are you sure you want to delete ${ name }?`
//   const doDelete = await UIService.ConfirmationModal.show({ text })
//   if ( doDelete ) {
//     try {
//       await RestService.delete( `/v2/users/admin/${ userId }` )
//       AdminUserService.clearEditUser()
//       AdminUserService.clearUser()
//       AdminUserService.removeUserFromUsers( userId )
//       UIService.Errors.clearNamespace( 'editUserModal' )
//       UIService.EditUser.close()
//     }
//     catch ( error ) {
//       HandleError({ error })
//     }
//   }
// }

export const NewUser =
  ({ AdminUserService, UIService }) =>
  async () => {
    AdminUserService.clearEditUser()
    UIService.EditUser.show()
  }

export const EditUser =
  ({
    AdminUserService,
    RestService,
    UIService,
    pResponseAdminUser,
    HandleError,
  }) =>
  async () => {
    const userId = AdminUserService.getState().adminUser.id
    try {
      let user = await RestService.get(`/v2/users/admin/${userId}`)
      user = pResponseAdminUser(user)
      AdminUserService.setEditUser(user)
      UIService.EditUser.show()
    } catch (error) {
      HandleError({ error })
    }
  }

export const SaveUser =
  ({
    AdminUserService,
    RestService,
    UIService,
    pRequestUpdateUser,
    pResponseAdminUser,
    HandleError,
  }) =>
  async (data) => {
    UIService.Errors.clear()
    data = pRequestUpdateUser(data)
    try {
      let user
      if (data.id) {
        user = await RestService.put(`/v2/users/admin/${data.id}`, data, {
          timeout: 30000,
        })
      } else {
        user = await RestService.post('/v2/users/admin', data, {
          timeout: 30000,
        })
      }
      user = pResponseAdminUser(user)
      AdminUserService.setUser(user)
      UIService.EditUser.close()
    } catch (error) {
      HandleError({ error, namespace: 'editUserModal' })
    }
  }
