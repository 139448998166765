import React, { useState, useEffect } from 'react'
import Moment from 'moment'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { colors } from '@constants'
import { FlexContainer } from '@components/common'
import Table from '@components/common/Table'
import { Dropdown, Button } from '@components/common/form'
import sort from '@res/images/sort.svg'

const OrderStatus = (props) => {
  const {
    headquarters,
    headquarterId,
    chefNetworkReviewedOrder,
    loadChefOrderStatus,
  } = props
  const [date, setDate] = useState(Moment())
  const [orders, setOrders] = useState([])
  const [sortAscending, setSortAscending] = useState(true)
  const [filter, setFilter] = useState('No Filter')
  const [hqId, setHqId] = useState(headquarterId)

  useEffect(() => {
    onLoadChefOrderStatus()
  }, [date, hqId])

  const onLoadChefOrderStatus = async () => {
    const req = {
      headquarterId: hqId,
      fromDate: Moment(date).startOf('week').format('YYYY-MM-DD'),
      toDate: Moment(date).endOf('week').format('YYYY-MM-DD'),
    }
    const orders = await loadChefOrderStatus(req)
    if (orders) {
      setOrders(orders)
    } else {
      setOrders([])
    }
  }

  const getAcceptedStatusValue = (filter) => {
    if (filter === 'Accepted') {
      return 1
    } else if (filter === 'Declined') {
      return 0
    } else {
      return -1
    }
  }

  const filterOrders = (orders) => {
    if (filter === 'No Filter') {
      return orders
    } else {
      const isAcceptedVal = getAcceptedStatusValue(filter)

      return orders.filter((o) => o.isAccepted === isAcceptedVal)
    }
  }

  const sortAndUpdateState = (orders, getColumn) => {
    const nextSort = !sortAscending
    const sortedOrders = orders.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return sortAscending ? (colA > colB ? 1 : -1) : colB > colB ? 1 : -1
    })

    setSortAscending(nextSort)
    setOrders(sortedOrders)
  }

  const sortOrdersByString = (columnName) => {
    const getColumn = (order) =>
      order[columnName] ? order[columnName].toLowerCase() : ''

    sortAndUpdateState(orders, getColumn)
  }

  const sortOrdersByChefValue = (columnName) => {
    const getColumn = (order) => order.chef[columnName]
    sortAndUpdateState(orders, getColumn)
  }

  const sortOrdersByAcceptedStatus = () => {
    const nextSort = !sortAscending

    const sortedOrders = orders.sort((a, b) => {
      const colA = a.isAccepted
      const colB = b.isAccepted

      return sortAscending ? (colA > colB ? 1 : -1) : colB > colA ? 1 : -1
    })

    setSortAscending(nextSort)
    setOrders(sortedOrders)
  }

  const onMarkReviewed = async (order) => {
    const req = {
      order,
      headquarterId: hqId,
      fromDate: Moment(date).startOf('week').format('YYYY-MM-DD'),
      toDate: Moment(date).endOf('week').format('YYYY-MM-DD'),
    }
    const orders = await chefNetworkReviewedOrder(req)
    if (orders) {
      setOrders(orders)
    } else {
      setOrders([])
    }
  }

  const nextWeek = () => {
    const newDate = Moment(date).add(1, 'weeks').startOf('week')
    setDate(newDate)
  }

  const lastWeek = () => {
    const newDate = Moment(date).subtract(1, 'weeks').startOf('week')
    setDate(newDate)
  }

  const renderCNReviewStatus = (order) => {
    if (order.isAccepted == 0) {
      return order.cnStatus ? (
        <p>Yes</p>
      ) : (
        <Button
          label="Mark Reviewed"
          width="auto"
          padding="5px 10px"
          onClick={() => onMarkReviewed(order)}
        />
      )
    }
  }

  const filteredOrders = filterOrders(orders)

  return (
    <div>
      <FlexContainer alignItems="center">
        <Dropdown
          width="200px"
          margin="0 20px 0 0"
          label="Headquarter"
          value={hqId}
          onChange={(e) => setHqId(Number(e.target.value))}
        >
          {headquarters.map((hq) => (
            <option key={hq.id} value={hq.id}>
              {hq.name}
            </option>
          ))}
        </Dropdown>
        <WeekToggle>
          <span className="arrow" onClick={lastWeek}>
            ◄
          </span>
          {Moment(date).startOf('week').format('MMMM D, YYYY (ddd)')}
          <span className="to">to</span>
          {Moment(date).endOf('week').format('MMMM D, YYYY (ddd)')}
          <span className="arrow" onClick={nextWeek}>
            ►
          </span>
        </WeekToggle>
      </FlexContainer>
      <FlexContainer flexDirection="row" justifyContent="flex-start">
        <Dropdown
          width="170px"
          label="Filter Order Status"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          {['No Filter', 'Accepted', 'Declined', 'Undecided'].map((filter) => (
            <option key={filter} value={filter}>
              {filter}
            </option>
          ))}
        </Dropdown>
      </FlexContainer>
      <Table>
        <tr>
          <th
            className="pointer"
            onClick={() => sortOrdersByString('orderNumber')}
          >
            <p className="flex">
              Order # <img className="item-sort" src={sort} />
            </p>
          </th>
          <th className="pointer" onClick={() => sortOrdersByString('date')}>
            <p className="flex">
              Order Date <img className="item-sort" src={sort} />
            </p>
          </th>
          <th className="pointer" onClick={() => sortOrdersByChefValue('name')}>
            <p className="flex">
              Chef <img className="item-sort" src={sort} />
            </p>
          </th>
          <th>Chef Email</th>
          <th>Chef Phone</th>
          <th className="pointer" onClick={() => sortOrdersByAcceptedStatus()}>
            <p className="flex">
              Accepted Order? <img className="item-sort" src={sort} />
            </p>
          </th>
          <th>CN Reviewed</th>
        </tr>
        {filteredOrders.map((order, idx) => (
          <tr key={`${order.id}-${order.chef.id}-${idx}`}>
            <td>
              <p>{order.orderNumber}</p>
            </td>
            <td>
              <p>{Moment(order.date).format('MM/DD/YYYY')}</p>
            </td>
            <td>
              <p>{order.chef.name}</p>
            </td>
            <td>
              <p>{order.chef.email}</p>
            </td>
            <td>
              <p>{order.chef.phone}</p>
            </td>
            <td>
              <p>
                <span
                  style={{
                    color:
                      order.isAccepted == -1
                        ? colors.orange
                        : order.isAccepted == 1
                          ? colors.blue400
                          : colors.violet,
                    fontWeight: 700,
                  }}
                >
                  {order.isAccepted == -1
                    ? 'Undecided'
                    : order.isAccepted == 1
                      ? 'Accepted'
                      : 'Declined: '}
                </span>
                {order.declineReason}
              </p>
            </td>
            <td>{renderCNReviewStatus(order)}</td>
          </tr>
        ))}
      </Table>
    </div>
  )
}

const WeekToggle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 16px;
  font-family: 'regular';
  .to,
  .arrow {
    display: inline-block;
    padding: 0 5px;
  }
  .arrow {
    cursor: pointer;
  }
`

OrderStatus.propTypes = {
  headquarters: PropTypes.array,
  headquarterId: PropTypes.number,

  chefNetworkReviewedOrder: PropTypes.func,
  loadChefOrderStatus: PropTypes.func,
}

export default OrderStatus
