export const EmailInvoice =
  ({ OrderService, pResponseOrder, RestService, UIService, HandleError }) =>
  async (invoiceFinalized) => {
    UIService.OrderPage.toggleInvoiceButtonEnabled(false)
    const orderId = UIService.getState().order.id
    try {
      await RestService.post(`/api/admin/orders/${orderId}/email_invoice`, {
        finalized: invoiceFinalized,
      })
      let orderable = await RestService.get(`/api/admin/orders/${orderId}`)
      orderable = pResponseOrder(orderable)
      OrderService.setOrderable(orderable)
      UIService.OrderPage.toggleInvoiceButtonEnabled(true)
      UIService.Alert.showPopup({ message: 'Invoice email sent.' })
    } catch (error) {
      HandleError({ error })
    }
  }

export const EmailBatchInvoice =
  ({ BatchOrderService, RestService, UIService, HandleError }) =>
  async (invoiceFinalized) => {
    UIService.BatchOrderPage.toggleInvoiceButtonEnabled(false)

    const batchOrderId = UIService.getState().batchOrder.id
    try {
      await RestService.post(
        `/api/admin/order_batches/${batchOrderId}/email_invoice`,
        { finalized: invoiceFinalized },
      )

      if (invoiceFinalized) {
        const batchOrder = BatchOrderService.clearedFinalizedInvoiceSentOrder()
        BatchOrderService.setBatchOrder(batchOrder)
      }

      UIService.BatchOrderPage.toggleInvoiceButtonEnabled(true)
      UIService.Alert.showPopup({ message: 'Invoice email sent.' })
    } catch (error) {
      HandleError({ error })
    }
  }

export const ChargeClient =
  ({
    HandleError,
    OrderService,
    RestService,
    UIService,
    pRequestPayOrderWithExternal,
    pResponseOrder,
  }) =>
  async ({ paymentSource, paymentAmount, isRefund, sendReceipt }) => {
    UIService.OrderPage.setPaymentProcessing(true)

    const orderId = UIService.getState().order.id
    try {
      const paidOrder = await RestService.post(
        '/api/admin/payments',
        pRequestPayOrderWithExternal(
          orderId,
          paymentSource,
          paymentAmount,
          isRefund,
          sendReceipt,
        ),
        { timeout: 15000 },
      )

      OrderService.setOrderable(pResponseOrder(paidOrder))
      UIService.OrderPage.setPaymentProcessing(false)
      if (isRefund) {
        UIService.Alert.showPopup({ message: 'Order refunded successfully.' })
      } else if (paymentSource !== 'automated') {
        UIService.Alert.showPopup({
          message: `Order has been marked as paid via ${paymentSource}.`,
        })
      } else {
        UIService.Alert.showPopup({ message: 'Order paid successfully.' })
      }
    } catch (error) {
      HandleError({ error, namespace: 'chargeClient' })
      UIService.OrderPage.setPaymentProcessing(false)
    }
  }

export const ChargeBatchOrderClient =
  ({
    HandleError,
    BatchOrderService,
    RestService,
    UIService,
    pRequestPayBatchOrderWithExternal,
    pResponseBatchOrder,
  }) =>
  async ({ paymentSource, paymentAmount, isRefund }) => {
    UIService.BatchOrderPage.setBatchPaymentProcessing(true)

    const batchOrderId = UIService.getState().batchOrder.id
    try {
      const paidOrder = await RestService.post(
        '/api/admin/payments',
        pRequestPayBatchOrderWithExternal(
          batchOrderId,
          paymentSource,
          paymentAmount,
          isRefund,
        ),
        { timeout: 30000 },
      )

      BatchOrderService.setBatchOrder(pResponseBatchOrder(paidOrder))
      UIService.BatchOrderPage.setBatchPaymentProcessing(false)
      if (isRefund) {
        UIService.Alert.showPopup({ message: 'Order refunded successfully.' })
      } else if (paymentSource !== 'automated') {
        UIService.Alert.showPopup({
          message: `Order has been marked as paid via ${paymentSource}.`,
        })
      } else {
        UIService.Alert.showPopup({ message: 'Order paid successfully.' })
      }
    } catch (error) {
      HandleError({ error, namespace: 'chargeClient' })
      UIService.BatchOrderPage.setBatchPaymentProcessing(false)
    }
  }
