import { connect } from 'react-redux'

import { AlertModal } from '@components/common/modal'

import services from '@services'

const mapStateToProps = (state) => {
  const { message, show, title, options } = state.alertModal

  return {
    message,
    show,
    title,
    options,
  }
}

const mapDispatchToProps = () => {
  const { closePopup: close } = services.UIService.Alert

  return { close }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal)
