import * as accountsReceivableCoordinators from './accountsReceivable'
import * as dashboardCoordinators from './dashboard'
import * as reportsCoordinators from './reports'
import * as orderCoordinators from './orders'

export default Object.freeze({
  ...accountsReceivableCoordinators,
  ...dashboardCoordinators,
  ...reportsCoordinators,
  ...orderCoordinators,
})
