import React, { useState, useEffect, useCallback } from 'react'
import Moment from 'moment-timezone'
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa'

import { config } from 'hungry-core2'
import { LoadingIndicator } from '@components/common'
import { Dropdown, AutocompleteInput } from '@components/common/form'
import Table from '@components/common/Table'
import { AuthorizedInteractable } from '@containers/common/auth'

import { snakeCaseify } from '../../../utils'
import { NotificationOrder } from '@types'

interface SendLatePaymentOrderReq {
  timezone: string
  date: string
  orderIds?: string[]
}

interface GetLatePaymentOrdersReq {
  clientId?: string
  timezone: string
  date: string
  page: number
  resultsPerPage: number
}

interface LatePaymentsTabProps {
  authRoles: string[]
  timezones: { [key: string]: string }[]
  getNextCronTime: (params: {
    timezone: string
    cron_job: string
  }) => Promise<string>
  getLatePaymentOrders: (
    params: GetLatePaymentOrdersReq,
  ) => Promise<NotificationOrder[]>
  sendLatePaymentOrders: (params: SendLatePaymentOrderReq) => Promise<void>
  searchAccounts: (filters: {
    search?: string
  }) => Promise<{ id: string; name: string }[]>
}

const Limit = 50

const LatePaymentsTab: React.FC<LatePaymentsTabProps> = ({
  authRoles,
  timezones,
  getNextCronTime,
  getLatePaymentOrders,
  sendLatePaymentOrders,
  searchAccounts,
}) => {
  const [selectedTimezone, setSelectedTimezone] = useState(
    'Eastern Time (US & Canada)',
  )
  const [nextCronTime, setNextCronTime] = useState<string | null>(null)
  const [orders, setOrders] = useState<NotificationOrder[]>([])
  const [selectedOrders, setSelectedOrders] = useState<{
    [key: string]: boolean
  }>({})
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [selectedAccount, setSelectedAccount] = useState<{
    id: string
    name: string
  } | null>(null)

  useEffect(() => {
    const fetchNextCronTime = async () => {
      const nextCronTime = await getNextCronTime({
        timezone: encodeURIComponent(selectedTimezone),
        cron_job: 'late_payments',
      })
      setNextCronTime(nextCronTime)
    }

    fetchNextCronTime()
  }, [selectedTimezone, getNextCronTime])

  const loadOrders = useCallback(async () => {
    if (nextCronTime === null) {
      return
    }
    setLoading(true)
    const req: GetLatePaymentOrdersReq = {
      timezone: encodeURIComponent(selectedTimezone),
      date: nextCronTime,
      page,
      resultsPerPage: Limit,
    }
    if (selectedAccount) {
      req.clientId = selectedAccount.id
    }

    const orders = await getLatePaymentOrders(req)

    setOrders(orders)
    setLoading(false)
  }, [
    nextCronTime,
    selectedTimezone,
    page,
    selectedAccount,
    getLatePaymentOrders,
  ])

  useEffect(() => {
    loadOrders()
  }, [loadOrders])

  const onSendTestOrders = async () => {
    if (nextCronTime === null) {
      return
    }
    const req: SendLatePaymentOrderReq = {
      timezone: encodeURIComponent(selectedTimezone),
      date: nextCronTime,
    }
    if (Object.keys(selectedOrders).length > 0) {
      req.orderIds = Object.keys(selectedOrders)
    }
    await sendLatePaymentOrders(req)
    await loadOrders()
    setSelectedOrders({})
  }

  const onSelectOrder = (id: string) => {
    const newOrders = { ...selectedOrders }
    if (selectedOrders[id]) {
      delete newOrders[id]
    } else {
      newOrders[id] = true
    }
    setSelectedOrders(newOrders)
  }

  const changePage = (dir: number) => {
    if (dir === -1 && page > 1) {
      setPage(page - 1)
    } else if (dir === 1 && orders.length === Limit) {
      setPage(page + 1)
    }
  }

  const renderOrderRow = (order: any) => {
    return (
      <tr key={order.id} onClick={() => onSelectOrder(order.id)}>
        <td className="py-2 flex flex-row">
          <AuthorizedInteractable roles={authRoles}>
            <input
              type="checkbox"
              checked={selectedOrders[order.id]}
              onChange={() => onSelectOrder(order.id)}
            />
          </AuthorizedInteractable>
          <p className="ml-2 pt-1">{order.orderNumber}</p>
        </td>
        <td className="py-2">
          {Moment(order.date).format('MM/DD/YYYY h:mma')}
        </td>
        <td className="py-2">
          {Moment(order.effectiveDate).format('MM/DD/YYYY')}
        </td>
        <td className="py-2">{Moment(order.dueDate).format('MM/DD/YYYY')}</td>
        <td className="py-2">{order.overdueBy}</td>
        <td className="py-2">{order.client.name}</td>
        <td className="py-2">
          <p>
            {order.salesRep.name}
            <br></br>
            {order.salesRep.email}
          </p>
        </td>
        <td className="py-2">
          <p>
            {order.invoiceContact.name}
            <br></br>
            {order.invoiceContact.email}
          </p>
        </td>
        <td className="py-2" style={{ width: '12.5%', maxWidth: '200px' }}>
          <p className="w-full overflow-x-scroll">{order.lateFee}</p>
        </td>
        <td className="py-2">
          {order.latePaymentReminderSentAt ? (
            <p>
              {Moment(order.latePaymentReminderSentAt).format(
                'MM/DD/YYYY h:mma',
              )}
            </p>
          ) : (
            <p>Not Notified</p>
          )}
        </td>
      </tr>
    )
  }

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <Dropdown
          label="Timezone"
          width="300px"
          marginBottom="20px"
          value={selectedTimezone}
          onChange={(e) => setSelectedTimezone(e.target.value)}
        >
          {timezones.map((timezone) => (
            <option key={timezone.value} value={timezone.value}>
              {timezone.label}
            </option>
          ))}
        </Dropdown>
        <div className="ml-10 h-12">
          <p className="font-semibold">Next Notification Period</p>
          <p className="text-sm mt-1">
            {(nextCronTime &&
              Moment(nextCronTime).format('MM/DD/YYYY h:mma')) ||
              'Unknown'}
          </p>
        </div>
        <div className="ml-10 h-12">
          <AutocompleteInput
            label="Search Client"
            displayAttribute="name"
            loaderFunction={(search: any) =>
              searchAccounts({ ...search, searchAllHqs: false })
            }
            filters={snakeCaseify({ latePaymentEligible: true })}
            value={selectedAccount && selectedAccount.name}
            onSelect={(account: { id: string; name: string }) =>
              setSelectedAccount(account)
            }
          />
        </div>
        {/* this method does not work with firefox's security protocols */}
        <a
          href={`${
            config.api_host
          }/api/admin/orders/late_payments/csv?timezone=${encodeURIComponent(
            selectedTimezone,
          )}&date=${nextCronTime}${
            selectedAccount ? `&client_id=${selectedAccount.id}` : ''
          }`}
          className="py-2 px-3 text-white bg-blue-500 rounded-lg font-semibold ml-10"
        >
          Download CSV
        </a>
        <AuthorizedInteractable roles={authRoles}>
          <button
            className="py-1 px-2 bg-blue-500 text-white rounded-lg ml-10 h-12 font-semibold"
            onClick={onSendTestOrders}
          >
            Send Test Notifications
          </button>
        </AuthorizedInteractable>
      </div>
      <div className="w-full">
        <div className="flex flex-row">
          <FaArrowAltCircleLeft
            size={25}
            className="cursor-pointer mr-2"
            onClick={() => changePage(-1)}
          />
          Page {page}
          <FaArrowAltCircleRight
            size={25}
            className="cursor-pointer ml-2"
            onClick={() => changePage(1)}
          />
        </div>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <Table>
            <thead className="font-semibold w-full">
              <tr>
                <th style={{ width: '12.5%' }}>Order Number</th>
                <th style={{ width: '12.5%' }}>Date &amp; Time</th>
                <th style={{ width: '12.5%' }}>Effective Date</th>
                <th style={{ width: '12.5%' }}>Due Date</th>
                <th style={{ width: '12.5%' }}>Overdue Days</th>
                <th style={{ width: '12.5%' }}>Client</th>
                <th style={{ width: '12.5%' }}>Sales Rep</th>
                <th style={{ width: '12.5%' }}>Invoice Contact</th>
                <th style={{ width: '12.5%' }}>Late Fee</th>
                <th style={{ width: '12.5%' }}>Last Notified</th>
              </tr>
            </thead>
            <tbody>{orders.map((order) => renderOrderRow(order))}</tbody>
          </Table>
        )}
      </div>
    </div>
  )
}

export default LatePaymentsTab
