import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { AuthorizedInteractable } from '@containers/common/auth'

export class CateringSupplies extends Component {
  constructor(props) {
    super(props)

    this.state = {
      allOrderTypes: [],
      allServiceTypes: [],
      allSetUpStyles: [],
      cateringSupplies: props.cateringSupplies,
      orderMappings: [],
      selectedCateringSupply: null,
      editCateringSupply: null,
      editOrderMapping: null,
      newCateringSupply: null,
      newOrderMapping: null,
      sortAsc: true,
    }
  }

  componentWillMount() {
    ;(async () => {
      this.props.loadCateringSupplies()
      const { allOrderTypes, allServiceTypes, allSetUpStyles } =
        await this.props.loadOrderSettings()
      this.setState({
        allOrderTypes: allOrderTypes.map((t) => {
          return { label: t.value, value: t.value }
        }),
        allServiceTypes: allServiceTypes.map((t) => {
          return { label: t.value, value: t.value }
        }),
        allSetUpStyles: allSetUpStyles.map((t) => {
          return { label: t.value, value: t.value }
        }),
      })
    })()
  }

  componentWillReceiveProps(nextProps) {
    const { cateringSupplies } = nextProps

    if (cateringSupplies !== this.state.cateringSupplies) {
      const newState = { cateringSupplies }
      const { selectedCateringSupply } = this.state
      const updatedCateringSupply =
        selectedCateringSupply &&
        cateringSupplies.find((p) => p.id == selectedCateringSupply.id)
      if (updatedCateringSupply) {
        const { orderMappings } = updatedCateringSupply
        newState.selectedCateringSupply = updatedCateringSupply
        newState.orderMappings = orderMappings
      } else {
        newState.selectedCateringSupply = null
        newState.orderMappings = []
      }

      this.setState(newState)
    }
  }

  sortByString = (collectionName, columnName) => {
    const { [collectionName]: collection, sortAsc } = this.state
    const getColumn = (item) =>
      item[columnName] ? item[columnName].toLowerCase() : ''
    const sorted = collection.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ [collectionName]: sorted, sortAsc: !sortAsc })
  }

  sortByBoolean = (collectionName, columnName) => {
    const { [collectionName]: collection, sortAsc } = this.state
    const getColumn = (item) => (item[columnName] === true ? 1 : 0)
    const sorted = collection.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ [collectionName]: sorted, sortAsc: !sortAsc })
  }

  sortByNumber = (collectionName, columnName) => {
    const { [collectionName]: collection, sortAsc } = this.state
    const sorted = collection.sort((a, b) => {
      if (sortAsc) {
        return a[columnName] - b[columnName]
      } else {
        return b[columnName] - a[columnName]
      }
    })
    this.setState({ [collectionName]: sorted, sortAsc: !sortAsc })
  }

  selectPrimaryRow = (addOn) => {
    const { selectedCateringSupply } = this.state
    if (selectedCateringSupply == addOn) {
      this.setState({
        newCateringSupply: null,
        newOrderMapping: null,
        editCateringSupply: { ...addOn },
        editOrderMapping: null,
      })
    } else {
      const { orderMappings } = addOn
      this.setState({
        selectedCateringSupply: addOn,
        orderMappings,
        newCateringSupply: null,
        newOrderMapping: null,
        editCateringSupply: null,
        editOrderMapping: null,
      })
    }
  }

  renderMapping = (orderMapping, i) => {
    const { editOrderMapping } = this.state
    const id = editOrderMapping && editOrderMapping.id
    if (orderMapping.id == id) {
      return this.renderEditMapping(editOrderMapping, i)
    } else {
      return this.renderStaticMapping(orderMapping, i)
    }
  }

  renderStaticMapping = (orderMapping, i) => {
    const isEvent =
      orderMapping.isEvent == null
        ? 'Both event and non-events'
        : orderMapping.isEvent
          ? 'Events only'
          : 'Non-Events only'

    return (
      <tr
        key={i}
        className="clickable"
        onClick={(e) => {
          e.preventDefault()
          this.setState({
            newCateringSupply: null,
            newOrderMapping: null,
            editCateringSupply: null,
            editOrderMapping: { ...orderMapping },
          })
        }}
      >
        <td>{isEvent}</td>
        <td>{orderMapping.serviceType.join(', ')}</td>
        <td>{orderMapping.orderType.join(', ')}</td>
        <td>{orderMapping.setUpStyle.join(', ')}</td>
        <td>{orderMapping.supplyUsageRate}</td>
      </tr>
    )
  }

  renderEditMapping = (orderMapping, i) => {
    const { allOrderTypes, allSetUpStyles, allServiceTypes } = this.state

    return (
      <tr key={i}>
        <td>
          <select
            value={orderMapping.isEvent}
            onChange={(e) => {
              this.setState({
                editOrderMapping: {
                  ...orderMapping,
                  isEvent: e.target.value,
                },
              })
            }}
          >
            <option>Both Event and Non-Events</option>
            <option value={true}>Events Only</option>
            <option value={false}>Non-Events Only</option>
          </select>
        </td>
        <td>
          <Select
            multi
            className="dietary-pref-selector"
            placeholder="Service Types"
            closeOnSelect={false}
            options={allServiceTypes}
            value={orderMapping.serviceType}
            onChange={(serviceType) =>
              this.setState({
                editOrderMapping: {
                  ...orderMapping,
                  serviceType: serviceType.map((t) => t.value),
                },
              })
            }
          />
        </td>
        <td>
          <Select
            multi
            className="dietary-pref-selector"
            placeholder="Order Types"
            closeOnSelect={false}
            options={allOrderTypes}
            value={orderMapping.orderType}
            onChange={(orderType) =>
              this.setState({
                editOrderMapping: {
                  ...orderMapping,
                  orderType: orderType.map((t) => t.value),
                },
              })
            }
          />
        </td>
        <td>
          <Select
            multi
            className="dietary-pref-selector"
            placeholder="Set Up Styles"
            closeOnSelect={false}
            options={allSetUpStyles}
            value={orderMapping.setUpStyle}
            onChange={(setUpStyle) =>
              this.setState({
                editOrderMapping: {
                  ...orderMapping,
                  setUpStyle: setUpStyle.map((t) => t.value),
                },
              })
            }
          />
        </td>
        <td>
          <input
            type="text"
            value={orderMapping.supplyUsageRate}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editOrderMapping: {
                  ...orderMapping,
                  supplyUsageRate: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <AuthorizedInteractable
            roles={['master admin', 'chef lead', 'captain lead']}
          >
            <button
              className="button-neutral"
              key="deleteOrderMapping"
              onClick={async (event) => {
                event.preventDefault()
                const { saveCateringSupply } = this.props
                orderMapping._destroy = true
                if (await saveCateringSupply({ orderMapping: orderMapping })) {
                  this.setState({ editOrderMapping: null })
                }
              }}
            >
              Delete Mapping
            </button>
          </AuthorizedInteractable>
        </td>
        <td>
          <button
            className="button-neutral"
            key="clearEditOrderMapping"
            onClick={(event) => {
              event.preventDefault()
              this.setState({ editOrderMapping: null })
            }}
          >
            Clear Updates
          </button>
        </td>
      </tr>
    )
  }

  renderPrimaryRow = (supply, i) => {
    const { editCateringSupply } = this.state
    const id = editCateringSupply && editCateringSupply.id
    if (supply.id == id) {
      return this.renderPrimaryEditRow(editCateringSupply, i)
    } else {
      return this.renderPrimaryStaticRow(supply, i)
    }
  }

  renderPrimaryStaticRow = (supply, i) => {
    return (
      <tr
        key={i}
        className="clickable"
        onClick={(e) => {
          e.preventDefault()
          this.selectPrimaryRow(supply)
        }}
      >
        <td>{supply.name}</td>
        <td>{supply.supplyType}</td>
        <td>{supply.defaultUnitCost}</td>
        <td>
          {supply.active ? (
            <span className="approved-check">✔</span>
          ) : (
            <span className="not-approved-x">✖</span>
          )}
        </td>
        <td>
          {supply.trackInventory ? (
            <span className="approved-check">✔</span>
          ) : (
            <span className="not-approved-x">✖</span>
          )}
        </td>
        <td>{supply.dftSupplyUsageRate}</td>
        <td>%{supply.dftRecoupPct * 100}</td>
        <td>%{supply.dftHungryVipRecoupPct * 100}</td>
      </tr>
    )
  }

  renderPrimaryEditRow = (supply, i) => {
    return (
      <tr key={i}>
        <td>
          <input
            type="text"
            value={supply.name}
            onInput={(e) =>
              this.setState({
                editCateringSupply: { ...supply, name: e.target.value },
              })
            }
          />
        </td>
        <td>{supply.supplyType}</td>
        <td>
          <input
            type="text"
            value={supply.defaultUnitCost}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editCateringSupply: {
                  ...supply,
                  defaultUnitCost: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <input
            type="checkbox"
            checked={supply.active}
            onChange={(e) =>
              this.setState({
                editCateringSupply: { ...supply, active: e.target.checked },
              })
            }
          />
        </td>
        <td>
          <input
            type="checkbox"
            checked={supply.trackInventory}
            onChange={(e) =>
              this.setState({
                editCateringSupply: {
                  ...supply,
                  trackInventory: e.target.checked,
                },
              })
            }
          />
        </td>
        <td>
          <input
            type="text"
            value={supply.dftSupplyUsageRate}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editCateringSupply: {
                  ...supply,
                  dftSupplyUsageRate: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <input
            type="text"
            value={supply.dftRecoupPct}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editCateringSupply: {
                  ...supply,
                  dftRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <input
            type="text"
            value={supply.dftHungryVipRecoupPct}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editCateringSupply: {
                  ...supply,
                  dftHungryVipRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <AuthorizedInteractable
            roles={['master admin', 'chef lead', 'captain lead']}
          >
            <button
              className="button-neutral"
              key="deleteCateringSupply"
              onClick={async (event) => {
                event.preventDefault()
                const { deleteCateringSupply } = this.props
                if (await deleteCateringSupply(supply.id)) {
                  this.setState({ editCateringSupply: null })
                }
              }}
            >
              Delete Supply
            </button>
          </AuthorizedInteractable>
        </td>
        <td>
          <button
            className="button-neutral"
            key="clearEditOrderMapping"
            onClick={(event) => {
              event.preventDefault()
              this.setState({ editCateringSupply: null })
            }}
          >
            Clear Updates
          </button>
        </td>
      </tr>
    )
  }

  renderPrimaryButtons = () => {
    const {
      editCateringSupply,
      editOrderMapping,
      newCateringSupply,
      newOrderMapping,
    } = this.state
    const { saveCateringSupply } = this.props
    const renderNew =
      !newCateringSupply &&
      !newOrderMapping &&
      !editOrderMapping &&
      !editCateringSupply
    const editedSupply = editCateringSupply || newCateringSupply

    return (
      <div className="page">
        <center>
          {editedSupply && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="editCateringSupply"
                onClick={async (event) => {
                  event.preventDefault()
                  if (
                    await saveCateringSupply({ cateringSupply: editedSupply })
                  ) {
                    this.setState({
                      editCateringSupply: null,
                      newCateringSupply: null,
                    })
                  }
                }}
              >
                Save Catering Supply Changes
              </button>
            </AuthorizedInteractable>
          )}
          {renderNew && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="newCateringSupply"
                onClick={(event) => {
                  event.preventDefault()
                  this.setState({
                    newCateringSupply: {
                      name: '',
                      active: true,
                      defaultUnitCost: 0.0,
                      trackInventory: true,
                      dftSupplyUsageRate: 1.0,
                      dftRecoupPct: 0.0,
                      dftHungryVipRecoupPct: 0.0,
                    },
                  })
                }}
              >
                New Catering Supply
              </button>
            </AuthorizedInteractable>
          )}
        </center>
      </div>
    )
  }

  renderMappingButtons = () => {
    const {
      selectedCateringSupply,
      editCateringSupply,
      editOrderMapping,
      newCateringSupply,
      newOrderMapping,
    } = this.state
    const { saveCateringSupply } = this.props
    const renderNew =
      !newCateringSupply &&
      !newOrderMapping &&
      !editOrderMapping &&
      !editCateringSupply &&
      selectedCateringSupply
    const editedMapping = editOrderMapping || newOrderMapping

    return (
      <div className="page">
        <center>
          {editedMapping && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="editOrderMapping"
                onClick={async (event) => {
                  event.preventDefault()
                  if (
                    await saveCateringSupply({ orderMapping: editedMapping })
                  ) {
                    this.setState({
                      editOrderMapping: null,
                      newOrderMapping: null,
                    })
                  }
                }}
              >
                Save Order Mapping Changes
              </button>
            </AuthorizedInteractable>
          )}
          {renderNew && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="newPackagingMapping"
                onClick={(event) => {
                  event.preventDefault()
                  this.setState({
                    newOrderMapping: {
                      orderSuppliableId: selectedCateringSupply.id,
                      isEvent: null,
                      serviceType: [],
                      orderType: [],
                      setUpStyle: [],
                      supplyUsageRate: 1.0,
                    },
                  })
                }}
              >
                New Order Mapping
              </button>
            </AuthorizedInteractable>
          )}
        </center>
      </div>
    )
  }

  renderNewCateringSupply = () => {
    const { newCateringSupply } = this.state

    return (
      <table className="table table-hover page">
        <thead>
          <tr>
            <th colSpan={7}>New Catering Supply</th>
          </tr>
          <tr>
            <th>Supply Name</th>
            <th>Active</th>
            <th>Default Unit cost</th>
            <th>Track Inventory</th>
            <th>Usage per Order</th>
            <th>Recoup Pct</th>
            <th>Hungry VIP Recoup Pct</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                value={newCateringSupply.name}
                onInput={(e) =>
                  this.setState({
                    newCateringSupply: {
                      ...newCateringSupply,
                      name: e.target.value,
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="checkbox"
                checked={newCateringSupply.active}
                onChange={(e) =>
                  this.setState({
                    newCateringSupply: {
                      ...newCateringSupply,
                      active: e.target.checked,
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="text"
                value={newCateringSupply.defaultUnitCost}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newCateringSupply: {
                      ...newCateringSupply,
                      defaultUnitCost: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
            <td>
              <input
                type="checkbox"
                checked={newCateringSupply.trackInventory}
                onChange={(e) =>
                  this.setState({
                    newCateringSupply: {
                      ...newCateringSupply,
                      trackInventory: e.target.checked,
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="text"
                value={newCateringSupply.dftSupplyUsageRate}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newCateringSupply: {
                      ...newCateringSupply,
                      dftSupplyUsageRate: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
            <td>
              <input
                type="text"
                value={newCateringSupply.dftRecoupPct}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newCateringSupply: {
                      ...newCateringSupply,
                      dftRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
            <td>
              <input
                type="text"
                value={newCateringSupply.dftHungryVipRecoupPct}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newCateringSupply: {
                      ...newCateringSupply,
                      dftHungryVipRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderNewOrderMapping = () => {
    const { newOrderMapping, allServiceTypes, allOrderTypes, allSetUpStyles } =
      this.state

    return (
      <table className="table table-hover page">
        <thead>
          <tr>
            <th colSpan={5}>New Order Mapping</th>
          </tr>
          <tr>
            <th>Is Event</th>
            <th>Service Type</th>
            <th>Order Type</th>
            <th>Set Up Style</th>
            <th>Usage Per Order</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <select
                value={newOrderMapping.isEvent}
                onChange={(e) => {
                  this.setState({
                    newOrderMapping: {
                      ...newOrderMapping,
                      isEvent: e.target.value,
                    },
                  })
                }}
              >
                <option>Both Event and Non-Events</option>
                <option value={true}>Events Only</option>
                <option value={false}>Non-Events Only</option>
              </select>
            </td>
            <td>
              <Select
                multi
                className="dietary-pref-selector"
                placeholder="Service Types"
                closeOnSelect={false}
                options={allServiceTypes}
                value={newOrderMapping.serviceType}
                onChange={(serviceType) =>
                  this.setState({
                    newOrderMapping: {
                      ...newOrderMapping,
                      serviceType: serviceType.map((t) => t.value),
                    },
                  })
                }
              />
            </td>
            <td>
              <Select
                multi
                className="dietary-pref-selector"
                placeholder="Order Types"
                closeOnSelect={false}
                options={allOrderTypes}
                value={newOrderMapping.orderType}
                onChange={(orderType) =>
                  this.setState({
                    newOrderMapping: {
                      ...newOrderMapping,
                      orderType: orderType.map((t) => t.value),
                    },
                  })
                }
              />
            </td>
            <td>
              <Select
                multi
                className="dietary-pref-selector"
                placeholder="Set Up Styles"
                closeOnSelect={false}
                options={allSetUpStyles}
                value={newOrderMapping.setUpStyle}
                onChange={(setUpStyle) =>
                  this.setState({
                    newOrderMapping: {
                      ...newOrderMapping,
                      setUpStyle: setUpStyle.map((t) => t.value),
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="text"
                value={newOrderMapping.supplyUsageRate}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newOrderMapping: {
                      ...newOrderMapping,
                      supplyUsageRate: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  render() {
    const {
      cateringSupplies,
      orderMappings,
      selectedCateringSupply,
      newCateringSupply,
      newOrderMapping,
    } = this.state

    return (
      <div>
        <br />
        <h3>Description</h3>
        <p>
          These are supplies used by captains to service the catering. Catering
          supplies can be mapped to orders by event, service_types, order types,
          and set up styles. Orders that match that mapping will include the
          catering supply with quantity equal to its mapped usage rate. Catering
          supplies can also be associated with service items and will be
          included with those items on orders.
        </p>
        <br />

        <h3>Instructions</h3>
        <p>
          <ul>
            <li>Click a supply to see what types of orders it is mapped to.</li>
            <li>Click the supply again to edit.</li>
            <li>Click order mappings to edit them as well.</li>
            <li>
              * Supplies should not have overlapping order mappings unless
              duplicated supplies are intended.
            </li>
          </ul>
        </p>
        <br />

        <h3>Columns</h3>
        <p>
          <ul>
            <li>
              Recoup Pct - Percent of supply recouped after a catering. Will
              factor into a lower cost per order.
            </li>
            <li>
              Hungry VIP Recoup Pct - Percent of supply recouped after a HUNGRY
              VIP service type catering. Replaces the recoup pct and will factor
              into a lower cost per order.
            </li>
            <li>
              Default Usage Per Order - The default fixed count of the supply
              used with each order. Used if the Usage Per Order is not defined.
            </li>
            <li>
              Usage Per Order - The fixed count of the supply used with each
              order.
            </li>
          </ul>
        </p>
        <br />

        <table className="table table-hover page">
          <thead>
            <tr>
              <th onClick={() => this.sortByString('cateringSupplies', 'name')}>
                Supply Name
              </th>
              <th
                onClick={() =>
                  this.sortByString('cateringSupplies', 'supplyType')
                }
              >
                Supply Type
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('cateringSupplies', 'defaultUnitCost')
                }
              >
                Default Unit Cost
              </th>
              <th
                onClick={() => this.sortByBoolean('cateringSupplies', 'active')}
              >
                Active
              </th>
              <th
                onClick={() =>
                  this.sortByBoolean('cateringSupplies', 'trackInventory')
                }
              >
                Track Inventory
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('cateringSupplies', 'dftSupplyUsageRate')
                }
              >
                Default Usage Per Order
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('cateringSupplies', 'dftRecoupPct')
                }
              >
                Recoup Pct
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('cateringSupplies', 'dftHungryVipRecoupPct')
                }
              >
                Hungry VIP Recoup Pct
              </th>
            </tr>
          </thead>
          <tbody>
            {cateringSupplies.map((supply, i) =>
              this.renderPrimaryRow(supply, i),
            )}
          </tbody>
        </table>
        {newCateringSupply && this.renderNewCateringSupply()}
        {this.renderPrimaryButtons()}

        {selectedCateringSupply && (
          <div>
            <table className="table table-hover page">
              <thead>
                <tr>
                  <th colSpan={5}>
                    {selectedCateringSupply.name} - Order Mapping
                  </th>
                </tr>
                <tr>
                  <th
                    onClick={() =>
                      this.sortByString('orderMappings', 'isEvent')
                    }
                  >
                    Is Event
                  </th>
                  <th
                    onClick={() =>
                      this.sortByString('orderMappings', 'serviceType')
                    }
                  >
                    Service Type
                  </th>
                  <th
                    onClick={() =>
                      this.sortByString('orderMappings', 'orderType')
                    }
                  >
                    Order Type
                  </th>
                  <th
                    onClick={() =>
                      this.sortByString('orderMappings', 'setUpStyle')
                    }
                  >
                    Set Up Style
                  </th>
                  <th
                    onClick={() =>
                      this.sortByString('orderMappings', 'supplyUsageRate')
                    }
                  >
                    Usage Per Order
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderMappings.map((mapping, i) =>
                  this.renderMapping(mapping, i),
                )}
                {orderMappings.length == 0 && (
                  <tr>
                    <td colSpan={5}>No Order Mappings</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {newOrderMapping && this.renderNewOrderMapping()}
        {this.renderMappingButtons()}
      </div>
    )
  }
}

CateringSupplies.propTypes = {
  cateringSupplies: PropTypes.arrayOf(PropTypes.object),

  deleteCateringSupply: PropTypes.func,
  loadCateringSupplies: PropTypes.func,
  loadOrderSettings: PropTypes.func,
  saveCateringSupply: PropTypes.func,
}

export default CateringSupplies
