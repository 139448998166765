export const TriggerCutoff =
  ({ RestService, HandleError }) =>
  async () => {
    try {
      await RestService.post('/subscriptions/trigger_cutoff')
    } catch (error) {
      HandleError({ error })
    }
  }

export const TriggerChargeOrders =
  ({ RestService, HandleError }) =>
  async () => {
    try {
      await RestService.post('/subscriptions/trigger_charge_orders')
    } catch (error) {
      HandleError({ error })
    }
  }
