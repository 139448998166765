import { connect } from 'react-redux'
import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'
import InvoicesPage from '../../components/invoice/InvoicesPage'
import { config } from 'hungry-core2'

const mapStateToProps = (state) => {
  const port = 3001
  const uri =
    config.marketplace_url +
    (config.marketplace_url === 'http://localhost' ? `:${port}` : '')
  const invoiceUrl = `${uri}/all-invoices`
  const invoicePDFUrl = `${config.api_host}/accounting/invoice`
  const { router } = state

  return {
    invoiceUrl,
    invoicePDFUrl,
    ...router,
  }
}

const mapDispatchToProps = () => {
  const {
    pResponseVXAccounts,
    pResponseInvoice,
    pResponseInvoices,
    pResponseGeneric,
    pResponseClientOutstandingOrders,
  } = presenters.Api
  const { RestService, UIService, VXInvoiceService } = services
  const {
    GetInvoice,
    GetAccountingClient,
    GetClientOutstandingOrders,
    GetClientsByAutoBatchDate,
    GetInvoiceHistory,
    SearchDetailAccounts,
    SearchInvoices,
    SearchOrders,
  } = coordinators
  const { newInvoice, newOrderPayment, newBuyer } = VXInvoiceService

  const { displayFailureMessage } = UIService.FlashMessage
  const getClientOutstandingOrders = GetClientOutstandingOrders({
    RestService,
    pResponseClientOutstandingOrders,
  })
  const getClientsByAutoBatchDate = GetClientsByAutoBatchDate({ RestService })
  const getAccountingClient = GetAccountingClient({
    RestService,
    pResponseEditClientAccounting: pResponseGeneric,
  })
  const getInvoice = GetInvoice({ RestService, pResponseInvoice, HandleError })
  const getInvoiceHistory = GetInvoiceHistory({
    RestService,
    HandleError,
    UIService,
  })
  const searchInvoices = SearchInvoices({
    RestService,
    pResponseInvoices,
    HandleError,
  })
  const searchOrders = SearchOrders({ RestService, HandleError })
  const searchClients = SearchDetailAccounts({
    RestService,
    pResponseAccounts: pResponseVXAccounts,
  })

  return {
    displayFailureMessage,
    getAccountingClient,
    getClientsByAutoBatchDate,
    getClientOutstandingOrders,
    getInvoice,
    getInvoiceHistory,
    searchClients,
    searchInvoices,
    newInvoice,
    newOrderPayment,
    newBuyer,
    searchOrders,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesPage)
