import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/common/modal/Modal'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { Button, Input, LinkText, Checkbox } from '@components/common/form'
import { AddressInput } from '@containers/common/form'

const initialState = {
  city: '',
  line1: '',
  line2: '',
  state: '',
  zip: '',
  id: null,
  name: '',
  isDefault: false,
  active: false,
}

class EditServiceDepotModal extends Component {
  state = { ...initialState }

  componentDidMount() {
    const { serviceDepot } = this.props
    this.loadServiceDepot(serviceDepot)
  }

  componentWillReceiveProps(nextProps) {
    const { serviceDepot } = nextProps
    if (serviceDepot && serviceDepot.id !== this.state.id) {
      this.loadServiceDepot(serviceDepot)
    }
  }

  loadServiceDepot = (serviceDepot) => {
    let newState = { ...serviceDepot }

    if (serviceDepot.address) {
      const { line1, line2, city, state, zip } = serviceDepot.address
      newState = {
        ...newState,
        line1,
        line2,
        city,
        state,
        zip,
        isDefault: serviceDepot.default,
      }
    }

    this.setState(newState)
  }

  onInput = (field) => (value) => {
    if (field === 'address') {
      this.setState({ ...value })
    } else {
      this.setState({ [field]: value })
    }
  }

  onHide = () => this.props.closeServiceDepot()

  save = async () => {
    const { flashError } = this.props
    const {
      id,
      headquarter,
      active,
      line1,
      line2,
      city,
      state,
      zip,
      isDefault,
      name,
    } = this.state

    if (name.length < 1) {
      flashError('Must have a name')

      return
    }
    if (line1.length < 1) {
      flashError('Must have an address')

      return
    }
    if (city.length < 1) {
      flashError('Must have a city')

      return
    }
    if (state.length < 1) {
      flashError('Must have a state')

      return
    }
    if (zip.length < 1) {
      flashError('Must have a zipcode')

      return
    }

    const newDepot = {
      id,
      active,
      address: {
        line1,
        line2,
        city,
        state,
        zip,
      },
      default: isDefault,
      name,
    }

    await this.props.saveServiceDepot(newDepot, headquarter.id)
    this.props.closeServiceDepot()
  }

  render() {
    const { errors } = this.props
    const { line1, line2, city, state, zip, id, active, isDefault, name } =
      this.state

    const address = {
      line1,
      line2,
      city,
      state,
      zip,
    }

    return (
      <Modal
        title={id ? `Edit ${name}` : 'New Service Depot'}
        hideModal={this.onHide}
        color="#001940"
        maxWidth="400px"
        width="400px"
      >
        <Input
          label="Name"
          value={name}
          error={errors.name}
          onChange={(e) => this.onInput('name')(e.target.value)}
        />
        {id && (
          <Checkbox
            checked={isDefault}
            label="Default Depot"
            marginBottom="20px"
            value="default"
            onClick={(e) => this.onInput('isDefault')(e.target.checked)}
          />
        )}
        <Checkbox
          checked={active}
          label="Active"
          marginBottom="20px"
          value="active"
          onClick={(e) => this.onInput('active')(e.target.checked)}
        />
        <AddressInput
          showBuildingInstructions={false}
          address={address}
          onChange={this.onInput('address')}
        />
        <FlexContainer justifyContent="flex-end" alignItems="center">
          <LinkText label="Cancel" onClick={this.onHide} color="#b30303" />
          <XSpacing width="40px" />
          <Button label="Save Service Depot" onClick={this.save} />
        </FlexContainer>
      </Modal>
    )
  }
}

EditServiceDepotModal.propTypes = {
  closeServiceDepot: PropTypes.func,
  saveServiceDepot: PropTypes.func,
  flashError: PropTypes.func,

  show: PropTypes.bool,
  errors: PropTypes.object,
  serviceDepot: PropTypes.object,
}

export default EditServiceDepotModal
