import React, { useEffect, useState } from 'react'
import Moment from 'moment-timezone'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors } from '@constants'
import { Button } from '@components/common/form'
import { ScheduleEditModal } from '@containers/chef/chefSections'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

const Schedule = (props) => {
  const { timeSlots, recurringSlots } = props
  const weekHeadings = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]
  const view = 'month'
  const [month, setMonth] = useState(Moment())
  const [selectedDate, setSelectedDate] = useState(undefined)

  useEffect(() => {
    loadTimeSlots(props.chefId)
  }, [props.chefId])

  const loadTimeSlots = () => {
    const date = new Date()
    props.loadTimeSlots({ date, view })
  }

  const onChangeMonth = (value) => () => {
    const currentMonth = Moment(month).clone()
    if (value < 0) {
      currentMonth.subtract(1, 'M')
    } else {
      currentMonth.add(1, 'M')
    }
    props.loadTimeSlots({ date: currentMonth, view })
    setMonth(currentMonth)
  }

  const onSelectDate = (slot, date) => {
    if (!slot.recurring) {
      setSelectedDate({
        ...slot,
        calendarDate: date,
      })
    } else {
      // find every recurring day that has same time as selected recurring day to group them together and get recurring days of week
      const matchingRecurringSchedule = recurringSlots.filter(
        (s) => s.title === slot.title,
      )
      let recurringDaysOfWeek = (matchingRecurringSchedule || []).map(
        (schedule) => schedule.dayOfWeek,
      )
      recurringDaysOfWeek = [...new Set(recurringDaysOfWeek)]

      setSelectedDate({
        ...slot,
        calendarDate: date,
        recurringDaysOfWeek,
      })
    }
  }

  const renderTimeSlot = (slot, date) => {
    const calendarDate = date.format('MM/DD/YY')
    const slotStartDate = slot.start.format('MM/DD/YY')
    // for non-recurring single day schedule
    if (calendarDate === slotStartDate && !slot.recurring) {
      return (
        <TimeSlot
          onClick={() => onSelectDate(slot, date)}
          key={slot.id}
          backgroundColor={colors.orange}
        >
          {`${slot.start.format('hh:mma')} - ${slot.end.format('hh:mma')}`}
        </TimeSlot>
      )
    } else if (calendarDate === slotStartDate) {
      return (
        <TimeSlot
          onClick={() => onSelectDate(slot, date)}
          key={slot.id}
          backgroundColor={colors.blue400}
        >
          {`${slot.start.format('hh:mma')} - ${slot.end.format('hh:mma')}`}
        </TimeSlot>
      )
    }
  }

  const onAddNewTime = (date) => {
    const newSlot = {
      repeatType: 'noRepeat',
      recurringDaysOfWeek: [],
      start: date.set({ hour: 8, minute: 0 }),
      end: date.clone().set({ hour: 17, minute: 0 }),
      calendarDate: date,
    }
    setSelectedDate(newSlot)
  }

  const getDays = () => {
    const schedule = [...recurringSlots, ...timeSlots]
    schedule.sort((a, b) => a.start.diff(b.start))
    const currentMonth = month
    const daysInMonth = currentMonth.daysInMonth()
    const currentMonthDates = new Array(currentMonth.daysInMonth())
      .fill(null)
      .map((x, i) => currentMonth.clone().startOf('month').add(i, 'days'))
    const firstDayOfWeek = currentMonthDates[0].isoWeekday()
    const days = []
    const rows = []
    let cells = []
    const prevMonthDays = firstDayOfWeek
    let calendarDays = daysInMonth + prevMonthDays
    const monthStart = month.clone().startOf('month')
    const startDate = monthStart.subtract(prevMonthDays - 1, 'days')
    // adds days from next month to total days to fill calendar
    while (calendarDays % 7 !== 0) {
      calendarDays += 1
    }
    for (let i = 0; i < calendarDays; i++) {
      const date = Moment(startDate).clone().add(i, 'days')
      const today = Moment().format('M D')
      const isToday = date.format('M D') === today
      const isCurrentMonth = date.format('M') === month.format('M')

      days.push(
        <td>
          <p
            className={`
              ${isCurrentMonth ? 'calendar-date' : 'calendar-date-light'}
              ${isToday && 'is-today'}
            `}
          >
            {date.format('D')}
          </p>
          {schedule.map((slot) => renderTimeSlot(slot, date))}
          <Button
            testId="add-time"
            label="Add Time"
            width="100%"
            onClick={() => onAddNewTime(date)}
            backgroundColor={colors.orange}
            margin="10px 0 0 0"
          />
        </td>,
      )
    }
    days.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row)
      } else {
        rows.push(cells)
        cells = []
        cells.push(row)
      }
      if (i === days.length - 1) {
        rows.push(cells)
      }
    })

    return rows.map((d, i) => <tr key={i}>{d}</tr>)
  }

  return (
    <div>
      <CalendarTools>
        <Arrows>
          <IoIosArrowBack fill="green" size={30} onClick={onChangeMonth(-1)} />
          <p className="calendar-title">
            Chef Availability for {Moment(month).format('MMMM YYYY')}
          </p>
          <IoIosArrowForward
            fill="green"
            size={30}
            onClick={onChangeMonth(1)}
          />
        </Arrows>
        <Legend>
          <p>
            <span style={{ backgroundColor: colors.orange }} />
            Single Day Schedule
          </p>
          <p>
            <span style={{ backgroundColor: colors.blue400 }} />
            Recurring Schedule
          </p>
        </Legend>
      </CalendarTools>
      <Calendar>
        <table>
          <tr>
            {weekHeadings.map((s) => (
              <th key={s}>{s}</th>
            ))}
          </tr>
          {getDays()}
        </table>
      </Calendar>
      {selectedDate && (
        <ScheduleEditModal
          timeSlot={selectedDate}
          chefId={props.chefId}
          hideModal={() => setSelectedDate(undefined)}
          month={month}
        />
      )}
    </div>
  )
}

const Calendar = styled.div`
  background: #fff;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  box-shadow:
    0 10px 15px -3px rgba(154, 167, 237, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  table {
    width: 100%;
  }
  th {
    font-family: 'regular';
    color: gray;
    opacity: 1;
    text-align: left;
    padding-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .calendar-date,
  .calendar-date-light {
    color: ${colors.gray};
    font-family: 'regular';
    font-size: 17px;
    text-align: left;
    width: 20px;
    height: 20px;
    text-align: center;
  }
  .calendar-date-light {
    color: ${colors.gray};
    opacity: 0.5;
  }
  .is-today {
    background: #3787e9;
    color: #fff;
    font-family: 'bold';
    border-radius: 10px;
  }
  td {
    border: 1px solid #e1e3ea;
    min-width: 25px;
    height: 60px;
    padding: 10px;
    text-align: left;
    &:hover {
      #add-time {
        opacity: 1;
      }
    }
  }
  #add-time {
    opacity: 0;
  }
`

const CalendarTools = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  .calendar-title {
    color: ${colors.gray400};
    font-family: 'bold';
    font-size: 23px;
    display: flex;
    justify-content: space-between;
  }
`

const Arrows = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  svg {
    padding: 0 5px;
  }
`

const TimeSlot = styled.div`
  background: ${(props) => props.backgroundColor};
  color: #fff;
  margin-top: 5px;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
`

const Legend = styled.div`
  display: flex;
  font-family: 'regular';
  color: ${colors.gray400};
  align-items: center;
  p {
    display: flex;
    margin-right: 20px;
  }
  span {
    background: ${(props) => props.color};
    width: 15px;
    height: 15px;
    border-radius: 30px;
    display: inline-block;
    margin-right: 5px;
  }
`

Schedule.propTypes = {
  authorized: PropTypes.bool,
  chefId: PropTypes.string,
  timeSlots: PropTypes.arrayOf(PropTypes.object),
  recurringSlots: PropTypes.arrayOf(PropTypes.object),

  loadTimeSlots: PropTypes.function,
}

export default Schedule
