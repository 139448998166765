import { connect } from 'react-redux'

import { GroupOrderReportsPage } from '@components/groupOrders'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { user = {}, headquarter = {} } = state
  const { lastLoggedInHq } = user
  const { userHeadquarters = [] } = headquarter
  const userHeadquarter =
    userHeadquarters.find((hq) => hq.id === lastLoggedInHq) || {}

  return {
    locale: userHeadquarter.locale,
    headquarter: userHeadquarter.id,
    currentUser: user,
  }
}

const mapDispatchToProps = () => {
  const { RestService, ChefService, UIService, MarketService } = services
  const {
    SearchGroupOrdersByDateRange,
    SearchGroupOrderSubOrders,
    SearchGroupOrderSubOrdersCount,
    SearchGroupOrdersCount,
    RequestSendGroupOrderItemsReport,
    RequestSendGroupOrdersReport,
    SearchDetailAccounts,
    LoadChefs,
    SelectChef,
    LoadMarkets,
  } = coordinators
  const {
    pResponseGroupOrderAccounts,
    pResponseChefs,
    pResponseChef,
    pResponseHeadquarters,
  } = presenters.Api
  const searchGroupOrdersByDateRange = SearchGroupOrdersByDateRange({
    RestService,
    HandleError,
  })
  const searchGroupOrderSubOrders = SearchGroupOrderSubOrders({
    RestService,
    HandleError,
  })
  const searchGroupOrderSubOrdersCount = SearchGroupOrderSubOrdersCount({
    RestService,
    HandleError,
  })
  const searchGroupOrdersCount = SearchGroupOrdersCount({
    RestService,
    HandleError,
  })
  const requestSendGroupOrderItemsReport = RequestSendGroupOrderItemsReport({
    RestService,
    UIService,
    HandleError,
  })
  const requestSendGroupOrdersReport = RequestSendGroupOrdersReport({
    RestService,
    UIService,
    HandleError,
  })
  const searchGroupOrdersClients = SearchDetailAccounts({
    RestService,
    pResponseAccounts: pResponseGroupOrderAccounts,
  })
  const loadChefs = LoadChefs({ ChefService, RestService, pResponseChefs })
  const selectChef = SelectChef({ ChefService, RestService, pResponseChef })
  const loadHeadquarters = LoadMarkets({
    MarketService,
    RestService,
    pResponseHeadquarters,
  })

  return {
    searchGroupOrdersByDateRange,
    searchGroupOrderSubOrders,
    searchGroupOrderSubOrdersCount,
    searchGroupOrdersCount,
    requestSendGroupOrdersReport,
    requestSendGroupOrderItemsReport,
    searchGroupOrdersClients,
    loadChefs,
    selectChef,
    loadHeadquarters,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupOrderReportsPage)
