import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import { Checkbox, MarketDropdown, Dropdown } from '@components/common/form'
import { AuthorizedInteractable } from '@containers/common/auth'
import TooltipModal from '@components/common/modal/TooltipModal'

import { CommunicationPreferences } from './EditChefModal'

const CATERING_CONTACT_ROLES = [
  {
    label: 'Orders',
    value: 'orders',
  },
  {
    label: 'Confirmations',
    value: 'confirmations',
  },
  {
    label: 'Invoices',
    value: 'invoices',
  },
]
const GO_CONTACT_ROLES = [
  {
    label: 'Next Week Reminder',
    value: 'goNextWeek',
  },
  {
    label: 'Day Before Reminder',
    value: 'goDayBefore',
  },
  {
    label: 'Cutoff Emails',
    value: 'goCutoff',
  },
]
const GENERAL_CONTACT_ROLES = [
  {
    label: 'Initial Order Confirmation',
    value: 'initialOrderConfirmation',
  },
  {
    label: 'Follow-Up Order Confirmation',
    value: 'followUpOrderConfirmation',
  },
  {
    label: 'Final Order Confirmation',
    value: 'finalOrderConfirmation',
  },
]

export class EditChefContactModal extends Component {
  constructor(props) {
    super(props)

    const { chefHqs, editChefContact } = props

    const selectedHqs = {}
    if (editChefContact.id) {
      editChefContact.activeHqs.forEach((hq) => {
        selectedHqs[hq.toString()] = true
      })
    } else {
      chefHqs.forEach((hq) => {
        selectedHqs[hq.id.toString()] = true
      })
    }

    this.state = {
      ...editChefContact,
      communicationPreferences:
        editChefContact.communicationPreferences || 'both',
      selectedHqs,
    }
  }

  componentWillMount() {
    const { clearErrors } = this.props
    clearErrors()
  }

  componentWillReceiveProps(nextProps) {
    const { editChefContact } = nextProps

    if (this.props.editChefContact !== editChefContact) {
      this.setState({
        ...editChefContact,
      })
    }
  }

  onHide = (e) => {
    const { close } = this.props
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }

    close()
  }

  onChange = (key) => (e) => {
    const { value } = e.target
    const { delayedUpdateChefContact } = this.props
    const valueWithoutUnicode =
      // eslint-disable-next-line no-control-regex
      typeof value === 'string' ? value.replace(/[^\x00-\x7F]/g, '') : value
    this.setState({ [key]: valueWithoutUnicode }, () =>
      delayedUpdateChefContact(this.state),
    )
  }

  onChangeCheckbox = (e) => {
    const { checked, value: key } = e.target
    const { delayedUpdateChefContact } = this.props
    this.setState({ [key]: checked }, () =>
      delayedUpdateChefContact(this.state),
    )
  }

  onDelete = async (e) => {
    const { chefId, close, deleteChefContact } = this.props
    const { id, name } = this.state
    e.stopPropagation()
    e.preventDefault()

    await deleteChefContact(id, chefId, name)
    close()
  }

  onSubmit = async (e) => {
    const { saveChefContact, loadChefContacts, chefId } = this.props

    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }

    await saveChefContact(this.state)
    await loadChefContacts(chefId)
  }

  onChangeHq = (hqId) => {
    const { delayedUpdateChefContact } = this.props
    const { selectedHqs } = this.state
    selectedHqs[hqId.toString()] = true
    this.setState({ selectedHqs }, () => delayedUpdateChefContact(this.state))
  }

  onDeleteHq = (hqId) => {
    const { delayedUpdateChefContact } = this.props
    const { selectedHqs } = this.state
    delete selectedHqs[hqId.toString()]
    this.setState({ selectedHqs }, () => delayedUpdateChefContact(this.state))
  }

  onChangeCommunicationPreferences = (e) => {
    const { value } = e.target
    const { delayedUpdateChefContact } = this.props
    this.setState({ communicationPreferences: value }, () =>
      delayedUpdateChefContact(this.state),
    )
  }

  render() {
    const { close, errors, show, chefHqs } = this.props
    const {
      id,
      email,
      name,
      phone,
      role,
      communicationPreferences,
      selectedHqs,
    } = this.state

    return (
      <Modal show={show} onHide={() => close()} dialogClassName="small-modal">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            {id ? 'Edit Chef Contact' : 'New Chef Contact'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-field-container">
            <div className="form-field-container__columns">
              <p>Name</p>
              <input
                type="text"
                value={name}
                onChange={this.onChange('name')}
              />
              {errors.name && (
                <div className="error-message"> {errors.name}</div>
              )}
            </div>

            <div className="form-field-container__columns">
              <p>Role</p>
              <input
                type="text"
                value={role}
                onChange={this.onChange('role')}
              />
              {errors.role && (
                <div className="error-message"> {errors.role}</div>
              )}
            </div>

            <div className="form-field-container__columns">
              <p>Email</p>
              <input
                type="text"
                value={email}
                onChange={this.onChange('email')}
              />
              {errors.email && (
                <div className="error-message"> {errors.email}</div>
              )}
            </div>

            <div className="form-field-container__columns">
              <p>Phone</p>
              <input
                type="text"
                value={phone}
                onChange={this.onChange('phone')}
              />
              {errors.phone && (
                <div className="error-message"> {errors.phone}</div>
              )}
            </div>

            <div className="flex flex-col items-center my-5 w-full">
              <p className="font-semibold">Email Notifications</p>
              <div className="flex flex-row w-2/3">
                <div className="w-1/2">
                  <div className="w-full text-center font-semibold my-3">
                    Catering
                  </div>
                  <div>
                    {CATERING_CONTACT_ROLES.map((role) => (
                      <Checkbox
                        key={role.value}
                        value={role.value}
                        label={role.label}
                        checked={this.state[role.value]}
                        onChange={this.onChangeCheckbox}
                      />
                    ))}
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="w-full text-center font-semibold my-3">
                    Group Order
                  </div>
                  <div>
                    {GO_CONTACT_ROLES.map((role) => (
                      <Checkbox
                        key={role.value}
                        value={role.value}
                        label={role.label}
                        checked={this.state[role.value]}
                        onChange={this.onChangeCheckbox}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center my-5 w-full">
              <div className="flex flex-row items-center">
                <span className="font-semibold">General Notifications</span>
                <TooltipModal
                  unicode="&#9432;"
                  width="400px"
                  marginTop="20px"
                  information="These notifications are for alerting chefs of their finalized pickup times on the day prior to an order."
                />
              </div>
              <div className="flex flex-col justify-items-center">
                {GENERAL_CONTACT_ROLES.map(({ label, value }) => (
                  <Checkbox
                    marginTop="5px"
                    marginBottom="5px"
                    key={value}
                    value={value}
                    label={label}
                    checked={this.state[value]}
                    onChange={this.onChangeCheckbox}
                  />
                ))}
              </div>
              <Dropdown
                label="Communication Preferences"
                width="45%"
                paddingTop="10px"
                onChange={this.onChangeCommunicationPreferences}
                value={communicationPreferences}
              >
                {CommunicationPreferences.map(({ value, label }) => (
                  <option
                    key={`communicationPreference${value}`}
                    value={value}
                    selected={communicationPreferences === value}
                  >
                    {label}
                  </option>
                ))}
              </Dropdown>
            </div>

            <div className="flex flex-col items-center my-5 w-full">
              <div className="my-3">
                <span className="font-semibold">Chef Locations:</span>
                <ul>
                  {chefHqs.map((hq) => (
                    <li key={`${hq.id}-${hq.name}`}>&#8226; {hq.name}</li>
                  ))}
                </ul>
              </div>
              <div>
                <MarketDropdown
                  label="Active Headquarters"
                  onSelectHq={this.onChangeHq}
                  onDeselectHq={this.onDeleteHq}
                  options={chefHqs}
                  selectedOptions={Object.keys(selectedHqs).map((hqId) =>
                    parseInt(hqId),
                  )}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {id && (
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <button
                style={{ float: 'left' }}
                onClick={this.onDelete}
                className="button-alert"
              >
                Delete
              </button>
            </AuthorizedInteractable>
          )}
          <AuthorizedInteractable roles={['master admin', 'chef lead']}>
            <button onClick={this.onSubmit} className="button-primary">
              Save
            </button>
          </AuthorizedInteractable>
          <button onClick={this.onHide} className="button-neutral">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

EditChefContactModal.propTypes = {
  editChefContact: PropTypes.object,
  errors: PropTypes.object,
  show: PropTypes.bool,
  chefId: PropTypes.string,
  chefHqs: PropTypes.arrayOf(PropTypes.object),
  activeHqs: PropTypes.arrayOf(PropTypes.number),

  close: PropTypes.func,
  clearErrors: PropTypes.func,
  delayedUpdateChefContact: PropTypes.func,
  deleteChefContact: PropTypes.func,
  saveChefContact: PropTypes.func,
  loadChefContacts: PropTypes.func,
}

export default EditChefContactModal
