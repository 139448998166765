import React, { Component } from 'react'
import Moment from 'moment-timezone'
import styled from '@emotion/styled'
import SidebarSearch from '@components/sidebar/SidebarSearch'
import SidebarListItem from '@components/sidebar/SidebarListItem'
import SidebarDropdown from '@components/sidebar/SidebarDropdown'
import SidebarDateAndTimeInput from '@components/sidebar/SidebarDateAndTimeInput'
import { Chef } from '@models'

type LoadChefsParams = {
  cuisineId?: string
  availableOn?: string
  marketType?: string
  search?: string
  status?: string
}

type Cuisine = {
  id: string
  name: string
}

type Props = {
  chefs: Chef[]
  filter: string
  loadChefs: (params: LoadChefsParams) => Promise<Chef[]>
  loadCuisineTags: () => Promise<Cuisine[]>
  selectChef: (chefId: string) => void
}

type State = {
  activeChefId: string
  cuisineId: string
  cuisineTypes: Cuisine[]
  marketType: string
  search: string
  availableOn: undefined | Moment.Moment
  [key: string]: string | string[] | Cuisine[] | Moment.Moment | undefined
}

export class ChefMaster extends Component<Props, State> {
  searchTimer: ReturnType<typeof setTimeout> | null = null

  state: State = {
    activeChefId: '',
    cuisineId: '',
    cuisineTypes: [],
    availableOn: undefined,
    marketType: '',
    search: '',
  }

  getParams = (filter: string): LoadChefsParams => {
    const { cuisineId, availableOn, search, marketType } = this.state
    const params: LoadChefsParams = {
      cuisineId,
      marketType,
      search,
    }
    if (availableOn) {
      params.availableOn = availableOn.utc().format()
    }

    if (filter === 'active') {
      params.status = filter
    } else if (filter === 'inactive') {
      params.status = 'inactive,trial,review'
    } else {
      params.marketType = filter
      params.status = 'active'
    }

    return params
  }

  componentDidMount() {
    const { filter } = this.props
    const params = this.getParams(filter)
    this.loadCuisineTags()
    this.props.loadChefs({ ...params })
  }

  componentWillReceiveProps(nextProps: Props) {
    const { filter } = nextProps
    if (filter !== this.props.filter) {
      const { filter } = nextProps
      const params = this.getParams(filter)
      this.props.loadChefs({ ...params })
    }
  }

  loadCuisineTags = async () => {
    const { loadCuisineTags } = this.props
    const cuisines = await loadCuisineTags()
    this.setState({ cuisineTypes: cuisines })
  }

  loadChefs = () => {
    const { filter } = this.props
    const params = this.getParams(filter)
    this.props.loadChefs({ ...params })
  }

  onSelectChef = (chefId: string) => {
    this.setState({ activeChefId: chefId })
    this.props.selectChef(chefId)
  }

  onTextSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value
    this.setState({ search })
  }

  onSelect = (e: React.ChangeEvent<HTMLSelectElement>, field: string) => {
    this.setState({ [field]: e.target.value })
  }

  render() {
    const { chefs } = this.props
    const { activeChefId, cuisineId, cuisineTypes, marketType } = this.state

    return (
      <div>
        <SidebarDropdown
          label="Market Type"
          onChange={(e) => this.onSelect(e, 'marketType')}
          value={marketType}
        >
          {[
            { value: '', label: '' },
            { label: 'Group Order', value: 'group orders' },
            { label: 'Catering', value: 'catering' },
            { label: 'Pop Up', value: 'pop-ups' },
          ].map((type) => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </SidebarDropdown>
        <SidebarDropdown
          label="Cuisine"
          onChange={(e) => this.onSelect(e, 'cuisineId')}
          value={cuisineId}
        >
          <option value="">No Filter</option>
          {cuisineTypes &&
            cuisineTypes.map((cuisine) => (
              <option key={cuisine.id} value={cuisine.id}>
                {cuisine.name}
              </option>
            ))}
        </SidebarDropdown>
        <SidebarDateAndTimeInput
          label="Available On"
          onChange={(dt: Moment.Moment) => this.setState({ availableOn: dt })}
          clearDate={() => this.setState({ availableOn: undefined })}
        />
        <SidebarSearch label="Search Chefs" onChange={this.onTextSearch} />
        <SearchButton onClick={this.loadChefs} id="sidebar-search-button">
          Search
        </SearchButton>
        {chefs.length > 0 ? (
          chefs.map((c) => {
            const selected = c.id === activeChefId

            return (
              <SidebarListItem
                key={c.id}
                isSelected={selected}
                onClick={() => this.onSelectChef(c.id)}
              >
                {c.name}
              </SidebarListItem>
            )
          })
        ) : (
          <SidebarListItem>No Chefs Found</SidebarListItem>
        )}
      </div>
    )
  }
}

const SearchButton = styled.button`
  background: #fff;
  color: #ff624d;
  font-family: 'bold';
  text-transform: uppercase;
  font-size: 14px;
  margin: 0 20px;
  padding: 10px;
  border-radius: 4px;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: 0.2s ease-in-out all;
  &:hover {
    box-shadow:
      0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
`

export default ChefMaster
