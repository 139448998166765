import React, { useState } from 'react'
import Modal from '@components/common/modal/Modal'
import { CurrencyInput, Input, LinkText, Button } from '@components/common/form'
import { CreateOrderRefund, GOrder as Order } from '@types'
import FlexContainer from '@components/common/FlexContainer'

interface Props {
  orderId: string
  orderNumber: string

  onSave: (order: Order) => Promise<void>
  addRefundToSubOrder: (
    orderId: string,
    ref: CreateOrderRefund,
  ) => Promise<Order>
  displayInfoMessage: (text: string) => void
  closeModal: () => void
}

const EditOrderPartialRefund = (props: Props) => {
  const { addRefundToSubOrder, closeModal, orderId, orderNumber } = props

  const [isSaving, setIsSaving] = useState(false)
  const [createRefund, setCreateRefund] = useState({
    amount: 0.0,
    processor: 'Square',
    transactionId: '',
    reason: '',
  } as CreateOrderRefund)

  const onSave = async () => {
    if (isSaving) {
      return
    }
    setIsSaving(true)
    const updatedOrder = await addRefundToSubOrder(orderId, createRefund)
    setIsSaving(false)
    if (updatedOrder) {
      props.displayInfoMessage(`Refund saved for ${orderNumber}`)
      props.onSave(updatedOrder)
      closeModal()
    }
  }

  return (
    <Modal
      title={`Add Partial Refund for ${orderNumber}`}
      hideModal={closeModal}
      color="#001940"
      maxWidth="800px"
      width="800px"
      minHeight="600px"
      maxHeight="80vh"
    >
      <div className="edit-group-order-staffing-fee-settings-modal">
        <div className="info-container">
          <p>Create Partial Refund For Order {orderNumber}</p>
        </div>

        <div className="fee-value">
          <Input
            width="31%"
            label="Processor"
            value={createRefund.processor}
            testId="processor"
            disabled={true}
          />
        </div>

        <FlexContainer flexDirection="row" justifyContent="space-between">
          <CurrencyInput
            label="Amount"
            width="40%"
            value={createRefund.amount}
            decimals={2}
            onChange={(amount: number) =>
              setCreateRefund({ ...createRefund, amount })
            }
            // TODO - make these props optional
            disabled={false}
            className=""
            allowNegative={false}
            prefix="$"
            suffix=""
            testId="amount"
            delay={0}
            margin=""
            marginBottom=""
          />
          <Input
            width="40%"
            label="Transaction ID"
            value={createRefund.transactionId}
            testId="transactionId"
            onChange={(e) =>
              setCreateRefund({
                ...createRefund,
                transactionId: e.target.value,
              })
            }
          />
        </FlexContainer>

        <div className="fee-value">
          <Input
            width="100%"
            label="Reason"
            value={createRefund.reason}
            testId="reason"
            onChange={(e) =>
              setCreateRefund({ ...createRefund, reason: e.target.value })
            }
          />
        </div>

        <div className="buttons-container">
          <LinkText label="Cancel" onClick={closeModal} color="#b30303" />
          <Button label="Save" onClick={onSave} disabled={isSaving} />
        </div>
      </div>
    </Modal>
  )
}

export default EditOrderPartialRefund
