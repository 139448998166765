import { connect } from 'react-redux'

import { Orders } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { chef } = state

  return {
    chefId: chef.id,
  }
}

const mapDispatchToProps = () => {
  const { RestService } = services
  const {
    pRequestChefOrders,
    pRequestChefOrder,
    pResponseOrders,
    pResponseChefOrder,
  } = presenters.Api
  const { LoadChefOrders, LoadChefOrder } = coordinators

  const loadChefOrders = LoadChefOrders({
    RestService,
    pRequestChefOrders,
    pResponseOrders,
    HandleError,
  })

  const loadChefOrder = LoadChefOrder({
    RestService,
    pRequestChefOrder,
    pResponseChefOrder,
    HandleError,
  })

  return {
    loadChefOrders,
    loadChefOrder,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
