import React, { useState } from 'react'
import Modal from '@components/common/modal/Modal'
import FlexContainer from '@components/common/FlexContainer'
import { Button, DateInput, Dropdown } from '@components/common/form'
import { AuthorizedInteractable } from '@containers/common/auth'
import { YSpacing } from '@components/common'
import { AutocompleteInput } from '@containers/common/form'
import moment from 'moment'
import { MultiSelectOption } from '@res/styledComponents/index'
import LoadingIndicator from '@components/common/LoadingIndicator'

interface Chef {
  id: string
  name: string
}

interface Hq {
  id: number
  name: string
}

interface ResultCount {
  name: string
  totalPay: string
  count: number
  processingFee: number
}

interface ResultsCount {
  [key: string]: ResultCount
}

interface GenFeeResults {
  noFeeChefs: Chef[]
  counts: ResultsCount
}

interface GenFeeResultsAndQuery {
  results: GenFeeResults
  queryText: string
}

interface Props {
  userHeadquarters: Hq[]
  show: boolean
  close: () => void
  generateChefProcessingFees: ({
    chefId,
    processingDate,
    hqIds,
  }: {
    processingDate: string // YYYY-MM-DD
    chefId?: string
    hqIds?: number[]
  }) => Promise<GenFeeResults | undefined>
  searchChefs: (search: any) => Promise<Chef[] | false>
  showConfirmationModal: (text: string) => Promise<boolean>
}

const CalcChefFeesModal = (props: Props) => {
  const {
    show,
    userHeadquarters,
    close,
    searchChefs,
    showConfirmationModal,
    generateChefProcessingFees,
  } = props
  const [processingDate, setProcessingDate] = useState<moment.Moment>(moment())
  const [selectedChef, setSelectedChef] = useState<Chef | null>(null)
  const [selectedHqs, setSelectedHqs] = useState<Hq[]>([])
  const [results, setResults] = useState<GenFeeResultsAndQuery | undefined>(
    undefined,
  )
  const [isGenerating, setIsGenerating] = useState<boolean>(false)

  const onChangeProcDate = (value: moment.Moment) => {
    setProcessingDate(value)
  }

  const onGenerateProcessingFees = async (): Promise<void> => {
    setResults(undefined)
    const processingDateStr = processingDate.format('YYYY-MM-DD')
    const chefStr = selectedChef ? `Chef ${selectedChef.name}` : 'all chefs'
    const hqsStr = selectedHqs.length
      ? `markets ${selectedHqs.map((hq) => hq.name).join(', ')}`
      : `all markets`
    const queryText = `date ${processingDateStr} for ${chefStr} in ${hqsStr}`
    const confirmText = `Are you sure you want to generate chef processing fees for ${queryText}`
    const doGenerate = await showConfirmationModal(confirmText)
    if (doGenerate) {
      setIsGenerating(true)
      const genFeeResults = await generateChefProcessingFees({
        processingDate: processingDateStr,
        chefId: selectedChef?.id,
        hqIds: selectedHqs.length ? selectedHqs.map((hq) => hq.id) : undefined,
      })
      setIsGenerating(false)
      if (genFeeResults) {
        setResults({
          results: genFeeResults,
          queryText,
        })
      }
    }
  }

  return (
    <Modal
      show={show}
      title="Generate Chef Processing Fees"
      hideModal={close}
      color="#001940"
      width="800px"
      height="600px"
    >
      <FlexContainer
        justifyContent="space-between"
        flexWrap="wrap"
        flexDirection="row"
      >
        <p className="bold mb-5">
          Re-generates chef processing fees for a single selected date, chef(s),
          and market(s).
        </p>

        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-col">
            <DateInput
              dateFormat="Y-m-d"
              width="100%"
              label="Processing Date"
              date={processingDate}
              onChange={onChangeProcDate}
              // date of deployment of this feature since prior fee periods are calculated per week
              minDate={moment('2024-07-28')}
            />
          </div>

          <div className="flex flex-col ml-4">
            <AutocompleteInput
              label="Chef"
              loaderFunction={(search) => searchChefs({ ...search })}
              onSelect={(value: Chef | false) => setSelectedChef(value || null)}
            />
            <YSpacing height="5px" />
            <FlexContainer flexWrap="wrap">
              {selectedChef && (
                <MultiSelectOption onClick={() => setSelectedChef(null)}>
                  {selectedChef.name}
                </MultiSelectOption>
              )}
              {!selectedChef && <MultiSelectOption>All</MultiSelectOption>}
            </FlexContainer>
          </div>

          <div className="flex flex-col ml-4">
            <Dropdown
              label="Market(s)"
              width="100%"
              marginBottom="0"
              onChange={(e) => {
                const selectedValue = e.target.value
                if (!selectedValue) {
                  return
                }
                const selectedHq = userHeadquarters.find(
                  (userHq) => selectedValue === `${userHq.id}`,
                )
                if (selectedHq) {
                  setSelectedHqs([...selectedHqs, selectedHq])
                }
              }}
            >
              <option value=""> - </option>
              {userHeadquarters
                .filter((opt) => !selectedHqs.find((hq) => hq.id === opt.id))
                .map((opt) => (
                  <option key={opt.id} value={`${opt.id}`}>
                    {opt.name}
                  </option>
                ))}
            </Dropdown>
            <YSpacing height="5px" />
            <FlexContainer flexWrap="wrap">
              {selectedHqs.map((hq) => (
                <MultiSelectOption
                  key={hq.id}
                  onClick={() =>
                    setSelectedHqs(
                      selectedHqs.filter((hqOpt) => hqOpt.id !== hq.id),
                    )
                  }
                >
                  {hq.name}
                </MultiSelectOption>
              ))}
              {selectedHqs.length === 0 && (
                <MultiSelectOption>All</MultiSelectOption>
              )}
            </FlexContainer>
          </div>
        </div>

        <div className="mt-10 flex flex-align-center items-center justify-center w-full">
          {isGenerating ? (
            <LoadingIndicator />
          ) : (
            <AuthorizedInteractable roles={['master admin', 'finance']}>
              <Button
                onClick={onGenerateProcessingFees}
                label="Generate Fees"
              />
            </AuthorizedInteractable>
          )}
        </div>

        {results && (
          <div className="flex flex-row overflow-y-scroll h-100 justify-center mt-10 w-full mr-10">
            <div className="flex flex-col justify-center w-full">
              <div className="bold text-center underline mb-5">
                Processing fees generated for {results.queryText}
              </div>

              <div className="flex flex-row justify-center w-full">
                <div className="flex flex-col mr-4 border border-solid rounded border-gray-400 p-2 w-1/2">
                  <div className="bold text-center">
                    Generated fees for{' '}
                    {Object.keys(results.results.counts).length} chef(s)
                  </div>
                  <p>
                    {Object.keys(results.results.counts).length > 0 && (
                      <ul>
                        {Object.values(results.results.counts).map(
                          (chefResult, chefResultIdx) => (
                            <li key={chefResultIdx}>
                              {chefResult.name} generated $
                              {chefResult.processingFee.toFixed(2)} fees on{' '}
                              {chefResult.count} payouts totalling $
                              {chefResult.totalPay}
                            </li>
                          ),
                        )}
                      </ul>
                    )}
                  </p>
                </div>

                <div className="flex flex-col border border-solid rounded border-gray-400 p-2 w-1/2">
                  <div className="bold text-center">
                    {results.results.noFeeChefs.length} chefs with 0% fee rate
                  </div>
                  <p>
                    {results.results.noFeeChefs.length > 0 && (
                      <ul className="mt-2">
                        {results.results.noFeeChefs.map(
                          (noFeeChef, noFeeChefIdx) => (
                            <li key={noFeeChefIdx}>{noFeeChef.name || '?'}</li>
                          ),
                        )}
                      </ul>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </FlexContainer>
    </Modal>
  )
}

export default CalcChefFeesModal
