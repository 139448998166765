import queryString from 'query-string'

export const pProcessDashboardParams = (params) => {
  const finalParams = {
    accountName: '',
    from: '',
    to: '',
    hqs: [],
  }

  if (params === null || params.length < 1) {
    return finalParams
  }

  const { name = '', hqs = '', from = '', to = '' } = queryString.parse(params)

  finalParams.from = from
  finalParams.to = to
  finalParams.accountName = name
  finalParams.hqs = hqs.split(',')

  return finalParams
}

export const pProcessResetPassParams = (params) => {
  const cleanedParams = queryString.parse(params)

  return {
    token: cleanedParams.accessToken,
  }
}
