import Moment from 'moment-timezone'

import { camelCaseify } from '~/utils'

export const pResponseGeneric = (json) => {
  return camelCaseify(json)
}

export const pBuildGetRequest = (json) => {
  let result = ''
  Object.entries(json)
    .filter(([, value]) => value != undefined)
    .forEach(([key, value]) => {
      const keyStr = key.replace(/([A-Z])/g, '_$1').toLowerCase() // snake case
      let valueStr
      if (Moment.isMoment(value)) {
        valueStr = value.format()
      } else if (!(value instanceof String)) {
        valueStr = value.toString()
      } else {
        valueStr = value
      }

      result += `&${keyStr}=${valueStr}`
    })

  return result
}
