import Moment from 'moment-timezone'

export const VXOrderSync =
  ({ RestService, UIService, HandleError }) =>
  async (ids) => {
    try {
      const orders = await RestService.put('/virtual-xp/orders/sync', { ids })
      UIService.FlashMessage.displaySuccessMessage(
        'VX Order sync ran successfully, though orders with incomplete lookups will remain unsynced',
      )

      return orders
    } catch (error) {
      HandleError({ error })

      return false
    }
  }

export const GetVXOrder =
  ({ RestService, HandleError }) =>
  async (id) => {
    try {
      return await RestService.get(`/virtual-xp/order/${id}`)
    } catch (error) {
      HandleError({ error })
    }
  }

export const GetAccountingVXOrder =
  ({ RestService, HandleError }) =>
  async (id) => {
    try {
      return await RestService.get(`/virtual-xp/accounting-order/${id}`)
    } catch (error) {
      HandleError({ error })
    }
  }

export const SearchVXOrders =
  ({ RestService, HandleError }) =>
  async ({
    search,
    statuses,
    searchDate,
    isSynced,
    limit = 100,
    offset = 0,
  }) => {
    try {
      const params = {
        offset,
        limit,
      }
      if (search != null) {
        params.search = search
      }
      if (searchDate != null) {
        params.dateString = searchDate.format('YYYY-MM-DD')
      }
      if (statuses) {
        params.statuses = statuses
      }
      if (isSynced != null && isSynced != '') {
        params.isSynced = isSynced
      }

      return await RestService.get('/virtual-xp/orders', params)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const SaveVXOrder =
  ({ RestService, UIService, HandleError }) =>
  async (order) => {
    try {
      if (order.id) {
        await RestService.put(`/virtual-xp/order/${order.id}`, order)
      } else {
        await RestService.post('/virtual-xp/order', order)
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      `VX Order ${order.number} successfully updated`,
    )

    return true
  }

export const CalcBalance =
  ({ RestService, HandleError }) =>
  async (req) => {
    try {
      return await RestService.post('/virtual-xp/order/calc-balance', req)
    } catch (error) {
      HandleError({ error })
    }
  }

export const SearchAccountingVXOrders =
  ({ RestService, HandleError }) =>
  async ({ from, to, isInvoiced, isPaid }) => {
    try {
      const params = {}
      if (from != null) {
        params.from = Moment(from).format()
      }

      if (to != null) {
        params.to = Moment(to).format()
      }
      if (isInvoiced != null) {
        params.isInvoiced = isInvoiced
      }
      if (isPaid != null) {
        params.isPaid = isPaid
      }

      return await RestService.get('/virtual-xp/accounting-orders', params)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }
