import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import LoadingIndicator from '@components/common/LoadingIndicator'
import { AuthorizedInteractable } from '@containers/common/auth'
import { ButtonsBar } from '@res/styledComponents/index'
import { BiPencil } from 'react-icons/bi'

export class ServiceItems extends Component {
  constructor(props) {
    super(props)

    this.state = {
      serviceItems: props.serviceItems,
      serviceItem: null,
      editModalVisible: false,
      sortAsc: true,
    }
  }

  componentDidMount() {
    const { loadChefServiceItems, chefId, serviceItems } = this.props
    if (!serviceItems) {
      loadChefServiceItems(chefId)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { serviceItems } = nextProps
    if (serviceItems !== this.state.serviceItems) {
      this.setState({ serviceItems })
    }
  }

  newServiceItem = () => {
    const { newServiceItem } = this.props
    newServiceItem()
  }

  editServiceItem = (serviceItem) => {
    const { editServiceItem } = this.props
    editServiceItem(serviceItem)
  }

  sortServiceItemsByString = (columnName) => {
    const { serviceItems, sortAsc } = this.state
    const getColumn = (item) =>
      item[columnName] ? item[columnName].toLowerCase() : ''
    const sortedServiceItems = serviceItems.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ serviceItems: sortedServiceItems, sortAsc: !sortAsc })
  }

  sortServiceItemsByNumber = (columnName) => {
    const { serviceItems, sortAsc } = this.state
    const getColumn = (item) =>
      item[columnName] ? parseFloat(item[columnName].replace(/[$,]/g, '')) : 0.0
    const sortedServiceItems = serviceItems.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ serviceItems: sortedServiceItems, sortAsc: !sortAsc })
  }

  sortServiceItemsByBoolean = (columnName) => {
    const { serviceItems, sortAsc } = this.state
    const getColumn = (item) => (item[columnName] === true ? 1 : 0)
    const sortedServiceItems = serviceItems.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ serviceItems: sortedServiceItems, sortAsc: !sortAsc })
  }

  render() {
    const { serviceItems } = this.state
    const areServiceItemsLoaded = !!serviceItems
    const areServiceItemsEmpty = serviceItems && serviceItems.length === 0

    return (
      <div>
        <ButtonsBar>
          <AuthorizedInteractable
            roles={['master admin', 'sales lead', 'sales rep', 'chef lead']}
          >
            <button
              onClick={(event) => {
                event.preventDefault()
                this.newServiceItem()
              }}
            >
              <BiPencil />
              Add New Menu Item
            </button>
          </AuthorizedInteractable>
        </ButtonsBar>
        <Panel>
          <Table>
            <thead>
              <tr>
                <th onClick={() => this.sortServiceItemsByString('name')}>
                  Item Name
                </th>
                <th onClick={() => this.sortServiceItemsByNumber('cost')}>
                  Vendor Price
                </th>
                <th onClick={() => this.sortServiceItemsByNumber('price')}>
                  Price
                </th>
                <th onClick={() => this.sortServiceItemsByBoolean('isEnabled')}>
                  Live
                </th>
                <th
                  onClick={() => this.sortServiceItemsByBoolean('isApproved')}
                >
                  Approved
                </th>
              </tr>
            </thead>
            <tbody>
              {!areServiceItemsLoaded && (
                <tr>
                  <td colSpan="4">
                    <LoadingIndicator />
                  </td>
                </tr>
              )}

              {areServiceItemsLoaded && areServiceItemsEmpty && (
                <tr>
                  <td colSpan="5">
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '1.2em',
                        color: 'rgba(0,0,0,0.2)',
                        padding: '20px',
                        backgroundColor: 'rgba(0,0,0,0.01)',
                      }}
                    >
                      No service items yet.
                    </div>
                  </td>
                </tr>
              )}

              {areServiceItemsLoaded &&
                serviceItems.map((serviceItem, i) => (
                  <tr
                    key={i}
                    className="clickable"
                    onClick={(e) => {
                      e.preventDefault()
                      this.editServiceItem(serviceItem.id)
                    }}
                  >
                    <td>{serviceItem.name}</td>
                    <td>${parseFloat(serviceItem.cost).toFixed(2)}</td>
                    <td>${parseFloat(serviceItem.price).toFixed(2)}</td>
                    <td>
                      {serviceItem.isEnabled ? (
                        <span className="approved-check">✔</span>
                      ) : (
                        <span className="not-approved-x">✖</span>
                      )}
                    </td>
                    <td>
                      {serviceItem.isApproved ? (
                        <span className="approved-check">✔</span>
                      ) : (
                        <span className="not-approved-x">✖</span>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Panel>
      </div>
    )
  }
}

ServiceItems.propTypes = {
  chefId: PropTypes.string,
  serviceItems: PropTypes.arrayOf(PropTypes.object),

  delayedUpdateServiceItem: PropTypes.func,
  editServiceItem: PropTypes.func,
  loadChefServiceItems: PropTypes.func,
  newServiceItem: PropTypes.func,
}

export default ServiceItems
