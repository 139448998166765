import { connect } from 'react-redux'

import { SearchViaHubSpotModal } from '@components/account/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { hubspotCompanies } = state

  return {
    hubspotCompanies,
  }
}

const mapDispatchToProps = () => {
  const { AccountService, RestService, HubspotCompanyService, UIService } =
    services
  const { ConfirmationModal } = UIService
  const { SearchHubspotCompanyById, NewManualAccount, NewAccountFromHubspot } =
    coordinators
  const { pResponseHubspotCompanies, pResponseCreateAccountFromHubspot } =
    presenters.Api

  const newManualAccount = NewManualAccount({
    AccountService,
    UIService,
    HubspotService: HubspotCompanyService,
  })
  const newAccountFromHubspot = NewAccountFromHubspot({
    AccountService,
    HubspotService: HubspotCompanyService,
    UIService,
    pResponseCreateAccountFromHubspot,
  })

  const loadHubspotCompany = SearchHubspotCompanyById({
    RestService,
    HubspotService: HubspotCompanyService,
    pResponseHubspotList: pResponseHubspotCompanies,
    endpoint: 'companies/search',
    HandleError,
  })
  const close = UIService.EditAccount.closeSearchViaHubSpot

  return {
    close,
    confirmationModal: ConfirmationModal,
    loadHubspotCompany,
    newAccountFromHubspot,
    newManualAccount,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchViaHubSpotModal)
