import { connect } from 'react-redux'

import { ChefDashboard } from '@components/chef'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapDispatchToProps = () => {
  const { ChefService, RestService } = services
  const { pResponseGeneric, pResponseChef, pRequestLandingChefs } =
    presenters.Api
  const { AsyncLoadLandingChefs, SelectChef, SearchMenuItems } = coordinators

  const loadChefs = AsyncLoadLandingChefs({
    RestService,
    pResponseGeneric,
    pRequestLandingChefs,
  })
  const selectChef = SelectChef({ ChefService, RestService, pResponseChef })
  const searchMenuItems = SearchMenuItems({
    RestService,
    pResponseMenuItems: pResponseGeneric,
  })

  return {
    loadChefs,
    selectChef,
    searchMenuItems,
  }
}

export default connect(undefined, mapDispatchToProps)(ChefDashboard)
