export const filterRemainingItems = (allItems, selectedItems) => {
  return allItems.filter((option) =>
    selectedItems.filter((o) => o).every((o) => o.menuItem.id !== option.id),
  )
}

export const pStateToReduxChef = (state) => {
  state = { ...state }
  delete state.allStatuses
  delete state.editPayoutMethod

  return state
}

export const pStateToReduxConcept = (state) => {
  state = { ...state }

  const cuisines = []

  state.allCuisines &&
    state.allCuisines.forEach((c) => {
      if (state[`cuisine${c}`] === true) {
        cuisines.push(c)
      }
    })

  state['tagsCuisine'] = cuisines
  delete state.allCuisines
  delete state.allDayParts

  return state
}

export const pStateToReduxMenuItem = (state) => {
  state = { ...state }
  const tags = []
  state.allDietaryPreferences &&
    state.allDietaryPreferences.forEach((p) => {
      if (state[`dietaryTag${p}`] === true) {
        tags.push(p)
      }
    })
  state['tagsDietaryPreference'] = tags

  const goTags = []
  state.allGroupOrderMealTypes &&
    state.allGroupOrderMealTypes.forEach((t) => {
      if (state[`goMealType${t}`] === true) {
        goTags.push(t)
      }
    })
  state['tagsGroupOrderMealType'] = goTags

  return state
}

export const pStateToReduxChefContact = (state) => {
  state = { ...state }

  return state
}

export const pDefaultMenuFields = (chef) => {
  return {
    chefId: chef.id,
    menuId: chef.menuId,
    isSubMenuItem: false,
    images: [],
    price: 0.0,
    marketPrice: 0.0,
    menuItemImages: [],
    tagsDietaryPreference: [],
    tagsIngredient: [],
    tagsGroupOrderMealType: [],
  }
}

export const pDefaultRecipeFields = (chef) => {
  return {
    chefId: chef.id,
    chefName: chef.name,
    id: '',
    name: '',
    description: '',
    servings: '',
    ingredients: '',
    instructions: '',
    cookHours: 0,
    cookMinutes: 0,
    prepHours: 0,
    prepMinutes: 0,
  }
}

export const pDefaultServiceFields = (chef) => {
  return {
    chefId: chef.id,
    menuId: chef.menuId,
    isSubMenuItem: false,
    images: [],
    cost: 0.0,
    price: 0.0,
    serviceType: 'other',
    pricingRate: null,
  }
}

export const pDefaultChildMenuFields = (data) => {
  const { chef, mealType, parentMenuItemId, parentMenuItemName } = data

  return {
    chefId: chef.id,
    menuId: chef.menuId,
    parentMenuItemId,
    parentMenuItemName,
    isSubMenuItem: true,
    mealType,
    images: [],
    price: 0.0,
    marketPrice: 0.0,
    menuItemImages: [],
    tagsDietaryPreference: [],
    tagsIngredient: [],
  }
}

export const pDefaultVirtualFields = (chef) => {
  return {
    chefId: chef.id,
    isSubMenuItem: false,
    images: [],
    cost: 0.0,
    price: 0.0,
  }
}

export const pDefaultVirtualKitFields = () => {
  return {
    name: '',
    description: '',
    marketPrice: 0.0,
    price: 0.0,
    quantity: 0,
    virtualItems: [],
  }
}

export const pDefaultSnackPackFields = (chef) => {
  return {
    chefId: chef.id,
    name: '',
    description: '',
    marketPrice: 0.0,
    price: 0.0,
    quantity: 0,
  }
}
