import {
  TOGGLE_EDIT_PROPOSAL_MODAL,
  TOGGLE_EMAIL_PROPOSAL_BUTTON_ENABLED,
  TOGGLE_PROPOSAL_LOADING,
  SET_LAST_LOAD_PROPOSALS_PARAMS,
} from '@actions/ui/proposalPage'

import { SECTION1 } from '../../../constants'

const initialState = {
  page: undefined,
  search: undefined,
  status: undefined,
  fromDate: undefined,
  toDate: undefined,
  emailProposalOn: true,
}
const initialEditProposalModalState = {
  show: false,
  mode: 'edit',
  section: SECTION1,
}

export const proposalPage = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_EMAIL_PROPOSAL_BUTTON_ENABLED:
      return {
        ...state,
        emailProposalOn: action.on,
      }
    case TOGGLE_PROPOSAL_LOADING: {
      return { ...state, isLoading: action.on }
    }
    case SET_LAST_LOAD_PROPOSALS_PARAMS: {
      const { page, search, status, fromDate, toDate } = action

      return {
        ...state,
        page,
        search,
        status,
        fromDate,
        toDate,
      }
    }
    default:
      return state
  }
}

export const editProposalModal = (
  state = initialEditProposalModalState,
  action,
) => {
  switch (action.type) {
    case TOGGLE_EDIT_PROPOSAL_MODAL: {
      const { show, mode = state.mode, section = state.section } = action

      return { show, mode, section }
    }
    default:
      return state
  }
}
