import React from 'react'
import PropTypes from 'prop-types'

const AuthorizedDisplay = (props) => {
  const { children, visible } = props

  return (
    <div className="auth-container" style={{ width: '100%' }}>
      {visible && children}
    </div>
  )
}

AuthorizedDisplay.propTypes = {
  children: PropTypes.node,

  visible: PropTypes.bool,
}

export default AuthorizedDisplay
