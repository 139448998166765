import { connect } from 'react-redux'

import { VirtualItems } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { chef } = state

  const virtualItems = chef && chef.virtualItems
  const chefId = chef && chef.id

  return {
    chefId,
    virtualItems,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, UIService } = services
  const { pDefaultVirtualFields } = presenters
  const { pResponseVirtualItem, pResponseGeneric } = presenters.Api
  const {
    DelayedUpdateVirtualItem,
    EditVirtualItem,
    LoadChefVirtualItems,
    NewVirtualItem,
  } = coordinators

  const editVirtualItem = EditVirtualItem({
    ChefService,
    RestService,
    UIService,
    pResponseVirtualItem,
  })
  const loadChefVirtualItems = LoadChefVirtualItems({
    ChefService,
    RestService,
    pResponseVirtualItems: pResponseGeneric,
  })
  const newVirtualItem = NewVirtualItem({
    ChefService,
    UIService,
    pDefaultVirtualFields,
  })
  const delayedUpdateVirtualItem = DelayedUpdateVirtualItem({
    ChefService,
    UIService,
  })

  return {
    delayedUpdateVirtualItem,
    editVirtualItem,
    loadChefVirtualItems,
    newVirtualItem,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VirtualItems)
