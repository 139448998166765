import {
  GetOrderableCount,
  LoadQueryOrderable,
  SearchOrderable,
  SelectOrderable,
} from '@coordinators/orderable'

export const LoadProposals =
  ({
    ProposalService,
    RestService,
    UIService,
    pRequestProposalsFilter,
    pResponseProposals,
  }) =>
  async (filters) => {
    UIService.ProposalPage.toggleLoading(true)
    if (!filters) {
      ProposalService.clearOrderables()
      filters = UIService.ProposalPage.getLastLoadProposalsParams()
    }
    filters = pRequestProposalsFilter(filters)
    const params = {
      page: 1,
      results_per_page: 40,
      account_executive_id: 'all',
      ...filters,
    }
    let proposals = await RestService.get('/api/admin/proposals', params)
    proposals = pResponseProposals(proposals)
    ProposalService.setOrderables(proposals)
    UIService.ProposalPage.toggleLoading(false)

    return proposals
  }

export const LoadDashboardProposals =
  ({
    ProposalService,
    RestService,
    UIService,
    pRequestProposalsFilter,
    pResponseProposals,
  }) =>
  async (filters) => {
    UIService.ProposalPage.toggleLoading(true)
    if (!filters) {
      ProposalService.clearOrderables()
      filters = UIService.ProposalDashboard.getLastLoadProposalDashboardParams()
    }
    filters = pRequestProposalsFilter(filters)
    let proposals = await RestService.get('/api/admin/proposals', {
      page: 1,
      results_per_page: 20,
      ...filters,
    })
    proposals = pResponseProposals(proposals)
    if (filters.page === 1 || filters.page === undefined) {
      ProposalService.setOrderables(proposals)
    } else {
      ProposalService.appendOrderables(proposals)
    }
    UIService.ProposalPage.toggleLoading(false)

    return proposals
  }

export const SearchProposals =
  ({ RestService, pRequestProposalsFilter, pResponseProposals }) =>
  async (filters) => {
    return SearchOrderable({
      RestService,
      pRequestFilter: pRequestProposalsFilter,
      pResponse: pResponseProposals,
      url: '/api/admin/proposals',
    })(filters)
  }

export const GetProposalCount =
  ({ RestService, pRequestProposalsFilter }) =>
  async (filters) => {
    return GetOrderableCount({
      RestService,
      pRequestFilter: pRequestProposalsFilter,
      url: '/api/admin/proposals',
    })(filters)
  }

export const AsyncLoadLandingProposals =
  ({ ProposalService, RestService, pResponseGeneric }) =>
  async (date) => {
    let proposals = date
      ? await RestService.get(
          '/api/admin/landing',
          { view: 'proposals', date: date.toDate() },
          { timeout: 30000 },
        )
      : await RestService.get(
          '/api/admin/landing',
          { view: 'proposals' },
          { timeout: 30000 },
        )
    proposals = pResponseGeneric(proposals)
    ProposalService.setProposalDashboard(proposals)

    return proposals
  }

export const LoadQueryProposal =
  ({ ProposalService, RestService, RouterService, pResponseProposal }) =>
  async () => {
    return LoadQueryOrderable({
      reduxService: ProposalService,
      RestService,
      RouterService,
      pResponse: pResponseProposal,
      orderableType: 'proposal',
      url: '/api/admin/proposals',
    })()
  }

export const SelectProposal =
  ({ ProposalService, RestService, pResponseProposal }) =>
  async (proposalId) => {
    return SelectOrderable({
      reduxService: ProposalService,
      RestService,
      pResponse: pResponseProposal,
      url: '/api/admin/proposals',
    })(proposalId)
  }

export const EmailProposal =
  ({ ProposalService, RestService, UIService, HandleError }) =>
  async (proposalIds = [], toEmails = null) => {
    try {
      UIService.ProposalPage.toggleEmailProposalButtonEnabled(false)

      if (proposalIds.length === 0) {
        const proposalId = ProposalService.orderableId()
        proposalIds.push(proposalId)
      }

      if (proposalIds.length > 1) {
        const { proposals } = UIService.getState()
        const proposalsToEmail = proposals.filter((p) =>
          proposalIds.includes(p.id),
        )

        if (proposalsToEmail.length === 0) {
          UIService.ProposalPage.toggleEmailProposalButtonEnabled(true)

          return
        }

        const isSameAccount = proposalsToEmail.every(
          (p) => p.accountName === proposalsToEmail[0].accountName,
        )

        if (!isSameAccount) {
          UIService.Alert.showPopup({
            message:
              'Proposals should be for the same client. Proposals not sent.',
          })
          UIService.ProposalPage.toggleEmailProposalButtonEnabled(true)

          return
        }
      }

      const params = { proposal_ids: proposalIds }
      if (toEmails) {
        params.to_emails = toEmails
      }

      await RestService.post('/api/admin/proposals/email_proposal', params, {
        // TODO: this request is probably better handled async on backend instead of
        // having to return response immediately.
        timeout: 120000,
      })

      UIService.ProposalPage.toggleEmailProposalButtonEnabled(true)
      UIService.Alert.showPopup({ message: 'Proposal email sent.' })
    } catch (error) {
      UIService.ProposalPage.toggleEmailProposalButtonEnabled(true)
      HandleError({ error })
    }
  }

export const ProposalAction =
  ({ RestService }) =>
  async (proposalIds = [], action) => {
    if (proposalIds === []) {
      return
    }
    const ids = proposalIds.join('&')
    if (action === 'Combine PDF') {
      await RestService.get(`/api/admin/proposals/download_pdf/${ids}`)
    }
  }
