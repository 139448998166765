export const APPEND_PROPOSALS = 'APPEND_PROPOSALS'
export const CLEAR_EDIT_PROPOSAL = 'CLEAR_EDIT_PROPOSAL'
export const CLEAR_NEW_PROPOSAL = 'CLEAR_NEW_PROPOSAL'
export const CLEAR_PROPOSAL = 'CLEAR_PROPOSAL'
export const CLEAR_PROPOSALS = 'CLEAR_PROPOSALS'
export const REMOVE_PROPOSAL_FROM_PROPOSALS = 'REMOVE_PROPOSAL_FROM_PROPOSALS'
export const SET_EDIT_PROPOSAL = 'SET_EDIT_PROPOSAL'
export const SET_NEW_PROPOSAL = 'SET_NEW_PROPOSAL'
export const SET_PROPOSAL = 'SET_PROPOSAL'
export const SET_PROPOSAL_DASHBOARD = 'SET_PROPOSAL_DASHBOARD'
export const SET_PROPOSALS = 'SET_PROPOSALS'
export const SET_PROPOSAL_ITEMS = 'SET_PROPOSAL_ITEMS'
export const UPDATE_EDIT_PROPOSAL = 'UPDATE_EDIT_PROPOSAL'
export const UPDATE_NEW_PROPOSAL = 'UPDATE_NEW_PROPOSAL'
export const TOGGLE_PROPOSAL_LOADER = 'TOGGLE_PROPOSAL_LOADER'
export const TOGGLE_PROPOSAL_MENU_ITEMS_LOADER =
  'TOGGLE_PROPOSAL_MENU_ITEMS_LOADER'

export const clearEditProposal = () => ({ type: CLEAR_EDIT_PROPOSAL })
export const clearNewProposal = () => ({ type: CLEAR_NEW_PROPOSAL })
export const clearProposal = () => ({ type: CLEAR_PROPOSAL })
export const clearProposals = () => ({ type: CLEAR_PROPOSALS })

export const appendProposals = (proposals) => ({
  type: APPEND_PROPOSALS,
  proposals,
})
export const removeProposalFromProposals = (proposalId) => ({
  type: REMOVE_PROPOSAL_FROM_PROPOSALS,
  proposalId,
})
export const setEditProposal = (proposal) => ({
  type: SET_EDIT_PROPOSAL,
  proposal,
})
export const setNewProposal = (proposal) => ({
  type: SET_NEW_PROPOSAL,
  proposal,
})
export const setProposal = (proposal) => ({ type: SET_PROPOSAL, proposal })
export const setProposalDashboard = (proposals) => ({
  type: SET_PROPOSAL_DASHBOARD,
  proposals,
})
export const setProposals = (proposals) => ({ type: SET_PROPOSALS, proposals })
export const setProposalItems = (proposalItems) => ({
  type: SET_PROPOSAL_ITEMS,
  proposalItems,
})
export const toggleProposalLoader = (on) => ({
  type: TOGGLE_PROPOSAL_LOADER,
  on,
})
export const toggleProposalMenuItemsLoader = (menuLoaderOn) => ({
  type: TOGGLE_PROPOSAL_MENU_ITEMS_LOADER,
  menuLoaderOn,
})

export const updateEditProposal = (proposal) => ({
  type: UPDATE_EDIT_PROPOSAL,
  proposal,
})
export const updateNewProposal = (proposal) => ({
  type: UPDATE_NEW_PROPOSAL,
  proposal,
})
