import Moment from 'moment-timezone'
import { camelCaseify } from '~/utils'
import {
  CLIENT_ID_NAME_FIELD,
  GREATER_THAN_CUSTOM,
  LESS_THAN_CUSTOM,
  GREATER_THAN_PREFIX,
  LESS_THAN_PREFIX,
  TEN_TO_FIFTEEN_RANGE,
} from '../../../constants'

export const pGroupOrderOrdersCSV = (ar, location) => {
  return ar.reduce((rows, order) => {
    const data = {}
    const menus = order.menus.map((menu) => menu.menuName).join(', ')
    data['Order Number'] = order.orderNumber
    data['Location'] = location
    data['Date'] = order.date.slice(5) + '-' + order.date.slice(0, 4)
    data['Customer Name'] =
      order.customer.firstName + ' ' + order.customer.lastName
    data['Menu Name'] = menus
    data['Subtotal'] = '$' + parseFloat(order.subtotal).toFixed(2)
    data['Tax'] = '$' + parseFloat(order.tax).toFixed(2)
    data['Tip'] = '$' + parseFloat(order.tip).toFixed(2)
    data['Total'] = '$' + parseFloat(order.total).toFixed(2)
    rows.push(data)

    return rows
  }, [])
}

export const pGroupOrderItemsCSV = (ar, location) => {
  const itemMap = ar.reduce((map, order) => {
    const items = order.menus.map((menu) => menu.orderItems).flatten()
    items.forEach((item) => {
      if (map[item.menuItemId] != undefined) {
        map[item.menuItemId]['Quantity'] += item.quantity
      } else {
        const data = {}
        data['Item Name'] = item.name
        data['Item Type'] = item.itemType
        data['Quantity'] = item.quantity
        data['Cost'] = parseFloat(item.cost)
        data['Price'] = parseFloat(item.price)
        data['Location'] = location
        map[item.menuItemId] = data
      }
    })

    return map
  }, {})

  return Object.values(itemMap).reduce((rows, item) => {
    item['Total Cost'] = '$' + (item['Cost'] * item['Quantity']).toFixed(2)
    item['Cost'] = '$' + item['Cost'].toFixed(2)
    item['Total Price'] = '$' + (item['Price'] * item['Quantity']).toFixed(2)
    item['Price'] = '$' + item['Price'].toFixed(2)
    rows.push(item)

    return rows
  }, [])
}

export const pResponseGroupOrderAccount = (json) => {
  const account = camelCaseify(json)

  return {
    id: account.id,
    name: account.name,
    pin: account.pin,
    logoUrl: account.logoUrl,
    locations: account.addresses.map((addr) => ({
      id: addr.id,
      name: addr.fullAddress,
      line1: addr.line1,
      line2: addr.line2,
      city: addr.city,
      state: addr.state,
      zip: addr.zip,
      tolls: addr.allowTolls,
      buildingInstructions: addr.buildingInstructions,
    })),
    contacts: account.contacts.map((contact) => ({
      id: contact.id,
      name: contact.name,
      email: contact.email,
      phone: contact.phoneNumber,
    })),
  }
}

export const pResponseGroupOrderAccounts = (json) => {
  return json.map((acct) => pResponseGroupOrderAccount(acct))
}

export const pRequestSaveDateMenu = ({ dateMenu, changedDateMenuMap }) => {
  //changedDateMenuMap ex: (datemenu id will be substited by datestr of dateMenu if no id, i.e. new datemenu)
  // { [dateMenuId1]: {}, [dateMenuId2]: {[menuId]: {_destroy: true, _new: true}, [menuId]: { _destroy: false }} }
  const request = dateMenu
  for (const [menuId, menuInfo] of Object.entries(changedDateMenuMap || {})) {
    const menuIndex = request.menus.findIndex((m) => m.id === menuId)
    if (menuIndex <= -1) {
      continue
    }
    if (menuInfo['_destroy'] && !menuInfo['_new']) {
      request.menus.splice(menuIndex, 1)
    }
  }

  return request
}

export const pResponseGroupOrder = (order) => {
  const { id, name, email, phone, chefStatus } = order.chef
  const { isAccepted, declineReason, cnStatus } = chefStatus

  return {
    id: order.id,
    orderNumber: order.number,
    date: order.date,
    chef: {
      id,
      name,
      email,
      phone,
    },
    isAccepted,
    declineReason,
    cnStatus,
    mealType: 'Group Order',
  }
}

export const pResponseGroupOrderAuditLogs = (json) => {
  const boolToString = (val) => (val ? 'YES' : 'NO')
  const strToDollar = (val) => `$${val}`

  let auditLogs = json.map((log) => {
    const { id, timestamp, diffs } = log
    const out = {
      id,
      timestamp: Moment(timestamp),
      changes: [],
    }

    if (diffs.date !== undefined) {
      out.changes.push({ key: 'Date', value: diffs.date })
    }
    if (diffs.limitPurchase !== undefined) {
      out.changes.push({
        key: 'Limit Purchase?',
        value: boolToString(diffs.limitPurchase),
      })
    }
    if (diffs.fullySubsidized !== undefined) {
      out.changes.push({
        key: 'Fully Subsidized?',
        value: boolToString(diffs.fullySubsidized),
      })
    }
    if (diffs.perOrderSubsidy !== undefined) {
      out.changes.push({
        key: 'Per Order Subsidy',
        value: strToDollar(diffs.perOrderSubsidy),
      })
    }
    if (diffs.subsidyType !== undefined) {
      out.changes.push({ key: 'Subsidy Type', value: diffs.subsidyType })
    }
    if (diffs.subsidyPercentLimitAmt !== undefined) {
      out.changes.push({
        key: 'Subsidy % Limit',
        value: diffs.subsidyPercentLimitAmt,
      })
    }
    if (diffs.isSubsidyPercentLimit !== undefined) {
      out.changes.push({
        key: 'Is % Subsidy',
        value: boolToString(diffs.isSubsidyPercentLimit),
      })
    }
    if (diffs.totalWalletAmount !== undefined) {
      out.changes.push({
        key: 'Total Wallet Amt',
        value: strToDollar(diffs.totalWalletAmount),
      })
    }
    if (diffs.totalSubsidy !== undefined) {
      out.changes.push({
        key: 'Total Subsidy',
        value: strToDollar(diffs.totalSubsidy),
      })
    }
    if (diffs.serviceFee !== undefined) {
      out.changes.push({
        key: 'Service Fee',
        value: strToDollar(diffs.serviceFee),
      })
    }
    if (diffs.discountedSvcFee !== undefined) {
      out.changes.push({
        key: 'Discounted Service Fee',
        value: strToDollar(diffs.discountedSvcFee),
      })
    }
    if (diffs.tax !== undefined) {
      out.changes.push({ key: 'Tax', value: strToDollar(diffs.tax) })
    }
    if (diffs.tip !== undefined) {
      out.changes.push({ key: 'Tip', value: strToDollar(diffs.tip) })
    }
    if (diffs.discountedTipFee !== undefined) {
      out.changes.push({
        key: 'Discounted Tip Fee',
        value: strToDollar(diffs.discountedTipFee),
      })
    }
    if (diffs.total !== undefined) {
      out.changes.push({ key: 'Total', value: strToDollar(diffs.total) })
    }
    if (diffs.totalFromOrders !== undefined) {
      out.changes.push({
        key: 'Total from Orders',
        value: strToDollar(diffs.totalFromOrders),
      })
    }
    if (diffs.headCount !== undefined) {
      out.changes.push({ key: 'Headcount', value: diffs.headCount })
    }
    if (diffs.alertSent !== undefined) {
      out.changes.push({
        key: 'Alert Sent?',
        value: boolToString(diffs.alertSent),
      })
    }
    if (diffs.usesRacks !== undefined) {
      out.changes.push({
        key: 'Uses Racks?',
        value: boolToString(diffs.usesRacks),
      })
    }
    if (diffs.racksCount !== undefined) {
      out.changes.push({ key: 'Rack Count', value: diffs.racksCount })
    }
    if (diffs.overflowRacks !== undefined) {
      out.changes.push({ key: 'Overflow Racks', value: diffs.overflowRacks })
    }
    if (diffs.shelvesPerRack !== undefined) {
      out.changes.push({ key: 'Shelves Per Rack', value: diffs.shelvesPerRack })
    }
    if (diffs.overflowName !== undefined) {
      out.changes.push({
        key: 'Rack Overflow Area Name',
        value: diffs.overflowName,
      })
    }
    if (diffs.status !== undefined) {
      out.changes.push({ key: 'Status', value: diffs.status })
    }

    return out
  })

  auditLogs = auditLogs.filter((log) => log.changes.length > 0)
  auditLogs = auditLogs.sort((a, b) =>
    a.timestamp.isBefore(b.timestamp) ? 1 : -1,
  )

  return auditLogs
}

export const pResponseGroupOrderClientSettings = (settings) => {
  if (!Array.isArray(settings)) {
    return settings
  }

  settings.forEach((setting) => pResponseGroupOrderClientSetting(setting))

  return settings
}

export const pResponseGroupOrderClientSetting = (json) => {
  if (json.scheduleSettings?.cutoffTimeSettings?.time) {
    json.scheduleSettings.cutoffTimeSettings.time = Moment(
      json.scheduleSettings.cutoffTimeSettings.time,
    )
  }
  if (json.scheduleSettings?.dropoffTimeSettings?.time) {
    json.scheduleSettings.dropoffTimeSettings.time = Moment(
      json.scheduleSettings.dropoffTimeSettings.time,
    )
  }

  return json
}

export const pRequestSearchGOMenusParams = (
  json,
  customValues,
  clientGeo,
  hqId,
  forCSV = false,
) => {
  const out = {
    hqId,
  }
  if (forCSV) {
    out.forCSV = forCSV
  }
  for (const key in json) {
    const value = json[key]
    if (typeof value === 'string') {
      if (customValues[key]) {
        Object.assign(out, handleStringValue(key, customValues[key]))
      } else {
        Object.assign(out, handleStringValue(key, value))
      }
    } else if (typeof value === 'boolean') {
      out[key] = forCSV ? value.toString() : value
    } else if (typeof value === 'number' && value !== undefined) {
      out[key] = forCSV ? value.toString() : value
    } else if (Array.isArray(value) && value.length > 0) {
      out[key] = forCSV ? value.toString() : value
    } else if (value && Moment.isMoment(value)) {
      if (key === 'earliestPickup') {
        const val = Moment(value).hour() * 100
        out[key] = forCSV ? val.toString() : val
      } else {
        out[key] = forCSV ? value.toISOString() : value.format()
      }
    }
  }

  if (clientGeo && (out['distanceLT'] || forCSV)) {
    out['longitude'] = forCSV
      ? clientGeo.longitude.toString()
      : Number(clientGeo.longitude)
    out['latitude'] = forCSV
      ? clientGeo.latitude.toString()
      : Number(clientGeo.latitude)
  }

  return out
}

const handleStringValue = (key, value) => {
  const result = {}
  if (value.includes(GREATER_THAN_PREFIX)) {
    result[key + 'GT'] = Number(value.split('_')[1])
  } else if (value.includes(LESS_THAN_PREFIX)) {
    result[key + 'LT'] = Number(value.split('_')[1])
  } else if (value === TEN_TO_FIFTEEN_RANGE) {
    result[key + 'GT'] = 10
    result[key + 'LT'] = 15
  } else if (key === CLIENT_ID_NAME_FIELD) {
    result.clientName = value
  } else if (value !== '') {
    result[key] = value
  }

  return result
}

const deg2Radians = (degree) => {
  return degree * (Math.PI / 180)
}

export const getDistanceFromLatLonInMiles = (lat1, lon1, lat2, lon2) => {
  if (!lat1 || !lon1) {
    return 'Invalid or Missing Account Data'
  }
  if (!lat2 || !lon2) {
    return 'Invalid or Missing Menu Card Geo Coordinates'
  }
  const R = 3959 // Radius of the Earth in miles
  const dLat = deg2Radians(lat2 - lat1)
  const dLon = deg2Radians(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2Radians(lat1)) *
      Math.cos(deg2Radians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c // Distance in miles

  return distance.toFixed(2)
}
