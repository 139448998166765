import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AutocompleteInput } from '@containers/common/form'
import { Panel, YSpacing, FlexContainer } from '@components/common'
import {
  Button,
  Checkbox,
  Dropdown,
  DateInput,
  DateTimeInput,
  Input,
} from '@components/common/form'
import { Label, MultiSelectOption } from '@res/styledComponents/index'

const allOrderTypes = [
  { value: 'CateringOrder', text: 'Catering' },
  { value: 'PopUp', text: 'Pop Up' },
  { value: 'GroupOrder', text: 'Group Order' },
  { value: 'CateringOrder.VCX', text: 'VCX' },
]

let timer

const callAfterTimeout = (func, delay = 3000) => {
  if (timer) {
    clearTimeout(timer)
  }
  timer = setTimeout(func, delay)
}

const Filters = (props) => {
  const {
    searchSalesReps,
    loadAccounts,
    loadCateringCaptains,
    loadChefs,
    loadHeadquarters,
    onChange,

    filterConfigs,
    headquarters,
    numResults,
    userId,
    loadOnChange,
  } = props

  const [filters, setFilters] = useState({})
  const [multiSelectOptions, setMultiSelectOptions] = useState({})

  useEffect(() => {
    onLoadHeadquarters()
  }, [userId])

  useEffect(() => {
    onLoadHeadquarters()
    onLoadConfigs(filterConfigs)
  }, [filterConfigs])

  const onLoadConfigs = (filterConfigs) => {
    const initFilters = filterConfigs
      .filter((c) => c.default)
      .reduce((obj, c) => {
        obj[c.key] = c.default

        return obj
      }, {})
    setFilters(initFilters)
    onChange && onChange(initFilters)
  }

  const onLoadHeadquarters = async () => {
    await loadHeadquarters(userId)
  }

  useEffect(() => {
    const hqIds = [
      {
        label: '- All Markets -',
        value: headquarters.reduce((acc, hq) => [...acc, hq.id], []),
      },
      ...headquarters.map((hq) => ({ value: hq.id, label: hq.name })),
    ]
    setMultiSelectOptions({
      hqIds: hqIds,
    })
  }, [headquarters])

  const onChangeFilter = (key, value, delay = 0) => {
    const newFilters = { ...filters, [key]: value }
    setFilters(newFilters)
    if (onChange) {
      if (delay === 0) {
        onChange(newFilters)
      } else {
        callAfterTimeout(() => onChange(newFilters), delay)
      }
    }
  }

  // automplete requires a name for the value, so need to save value name as well as ID
  const onChangeAutocompleteFilter = (key, value, name) => {
    const newFilters = {
      ...filters,
      [key]: value.id,
      [`${key}_name`]: name,
    }
    setFilters(newFilters)
    onChange && onChange(newFilters)
  }

  const onChangeOrderType = (filterKey, type) => {
    const newFilters = { ...filters }
    const checkedValues = newFilters[filterKey] || []
    const index = checkedValues.findIndex((value) => value === type.value)
    if (index !== -1) {
      checkedValues.splice(index, 1)
    } else {
      checkedValues.push(type.value)
    }
    newFilters[filterKey] = checkedValues
    setFilters(newFilters)
    onChange && onChange(newFilters)
  }

  const chefFilter = (filterKey) => {
    return (
      <AutocompleteInput
        label="Chef"
        loaderFunction={(search) => loadChefs({ ...search })}
        value={filters[`${filterKey}_name`] ? filters[`${filterKey}_name`] : ''}
        onSelect={(value) =>
          onChangeAutocompleteFilter(filterKey, value, `${value.name}`)
        }
      />
    )
  }

  const multiSelectAutoComplete = (filterKey, loaderFunction, label = '') => {
    return (
      <div>
        <AutocompleteInput
          label={label ? label : filterKey}
          loaderFunction={loaderFunction}
          onSelect={(value) => onChangeMultiSelectFilter(filterKey, value)}
        />
        <YSpacing height="5px" />
        <FlexContainer flexWrap="wrap">
          {filters[filterKey] &&
            filters[filterKey].map((filterVal, i) => {
              return (
                <MultiSelectOption
                  key={filterVal}
                  onClick={() => onRemoveMultiSelectOption(filterKey, i)}
                >
                  {filterVal.name}
                </MultiSelectOption>
              )
            })}
        </FlexContainer>
      </div>
    )
  }

  const multiSelectText = (filterKey, parameter = '', label = '') => {
    const _handleKeyDown = (filterKey, e) => {
      if (e.key === 'Enter') {
        const { value } = e.target
        if (value) {
          onChangeMultiSelectFilter(filterKey, value)
        }
      }
    }

    return (
      <div>
        <p className="w text-center text-red-500">
          {`Press enter to include ${parameter ? parameter : label}`}
        </p>
        <Input
          label={label ? label : filterKey}
          type="text"
          onKeyDown={(e) => _handleKeyDown(filterKey, e)}
          marginBottom="0"
        />
        <YSpacing height="5px" />
        <FlexContainer flexWrap="wrap">
          {filters[filterKey] &&
            filters[filterKey].map((filterVal, i) => {
              return (
                <MultiSelectOption
                  key={filterVal}
                  onClick={() => onRemoveMultiSelectOption(filterKey, i)}
                >
                  {filterVal}
                </MultiSelectOption>
              )
            })}
        </FlexContainer>
      </div>
    )
  }

  const captainFilter = (filterKey) => {
    return (
      <AutocompleteInput
        label="Captain"
        loaderFunction={(search) => loadCateringCaptains({ ...search })}
        value={filters[`${filterKey}_name`] ? filters[`${filterKey}_name`] : ''}
        onSelect={(value) =>
          onChangeAutocompleteFilter(
            filterKey,
            value,
            `${value.firstName} ${value.lastName}`,
          )
        }
      />
    )
  }

  const clientFilter = (filterKey) => {
    return (
      <AutocompleteInput
        label="Client"
        loaderFunction={(search) => loadAccounts({ ...search })}
        value={filters[`${filterKey}_name`] ? filters[`${filterKey}_name`] : ''}
        onSelect={(value) =>
          onChangeAutocompleteFilter(filterKey, value, value.name)
        }
      />
    )
  }

  const salesRepFilter = (filterKey) => {
    return (
      <AutocompleteInput
        label="Sales Rep"
        loaderFunction={(search) => searchSalesReps({ ...search })}
        value={filters[`${filterKey}_name`] ? filters[`${filterKey}_name`] : ''}
        onSelect={(value) =>
          onChangeAutocompleteFilter(
            filterKey,
            value,
            `${value.firstName} ${value.lastName}`,
          )
        }
      />
    )
  }

  const orderTypeFilter = (filterKey) => {
    return (
      <div>
        <Label>Order Type</Label>
        <YSpacing height="5px" />
        {allOrderTypes.map((type) => (
          <div key={type.text}>
            <Checkbox
              label={type.text}
              checked={
                filters[filterKey]
                  ? filters[filterKey].find((filter) => filter === type.value)
                  : ''
              }
              onChange={() => onChangeOrderType(filterKey, type)}
            />
            <YSpacing height="5px" />
          </div>
        ))}
      </div>
    )
  }

  const isPaidFilter = (filterKey) => {
    return (
      <Checkbox
        label="Is Paid?"
        value={filters[filterKey]}
        checked={filters[filterKey]}
        onChange={(e) => onChangeFilter(filterKey, e.target.checked)}
      />
    )
  }

  const dropdownFilter = (filterKey, label, options) => {
    return (
      <Dropdown
        label={label}
        width="100%"
        marginBottom="0"
        value={filters[filterKey] || ''}
        onChange={(e) => onChangeFilter(filterKey, e.target.value)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Dropdown>
    )
  }

  const dateFilter = (filterKey, presenter, label = '') => {
    return (
      <DateInput
        width="100%"
        label={label ? label : filterKey}
        date={filters[filterKey]}
        onChange={(date) => {
          const newDate = presenter ? presenter(date) : date
          onChangeFilter(filterKey, newDate)
        }}
        clearDate={() => onChangeFilter(filterKey, null)}
      />
    )
  }

  const dateTimeFilter = (filterKey, presenter, label = '') => {
    return (
      <DateTimeInput
        width="100%"
        label={label ? label : filterKey}
        date={filters[filterKey]}
        onChange={(date) => onChangeFilter(filterKey, date)}
        clearDate={() => onChangeFilter(filterKey, null)}
      />
    )
  }

  const textFilter = (filterKey, label = '') => {
    return (
      <Input
        type="text"
        pattern="[0-9]*"
        marginBottom="0"
        label={label}
        value={filters[filterKey] || ''}
        onChange={(e) => onChangeFilter(filterKey, e.target.value, 500)}
      />
    )
  }

  const pageFilter = () => {
    const filterKey = 'page'
    const pageNum = parseInt(filters[filterKey])

    return (
      <FlexContainer flexDirection="column">
        <Label>Page</Label>
        <FlexContainer>
          {pageNum > 1 && (
            <button
              style={{ padding: '0 5px' }}
              onClick={() => onChangeFilter(filterKey, pageNum - 1, 200)}
            >
              ◂
            </button>
          )}
          <p>{pageNum}</p>
          {numResults == parseInt(filters['limit']) && (
            <button
              style={{ padding: '0 5px' }}
              onClick={() => onChangeFilter(filterKey, pageNum + 1, 200)}
            >
              ▸
            </button>
          )}
        </FlexContainer>
      </FlexContainer>
    )
  }

  const limitFilter = () => {
    const filterKey = 'limit'

    return (
      <Dropdown
        label="limit"
        width="100%"
        marginBottom="0"
        value={filters[filterKey]}
        onChange={(e) => onChangeFilter(filterKey, e.target.value)}
      >
        {[50, 100, 200, 250, 500].map((count) => (
          <option key={count} value={count}>
            {count}
          </option>
        ))}
      </Dropdown>
    )
  }

  const onChangeMultiSelectFilter = (key, value) => {
    const newFilters = { ...filters }
    if (filters[key]) {
      newFilters[key].push(value)
    } else {
      newFilters[key] = [value]
    }
    setFilters(newFilters)
    onChange(newFilters)
  }

  const onRemoveMultiSelectOption = (key, index) => {
    const newFilters = { ...filters }
    newFilters[key].splice(index, 1)
    // delete name key saved for filter
    if (newFilters[`name_${key}`]) {
      newFilters[`name_${key}`].splice(index, 1)
    }
    setFilters(newFilters)
    onChange(newFilters)
  }

  const multiSelectObjsFilter = (filterKey, name) => {
    const options = multiSelectOptions[filterKey]
    let notSelectedOptions = []
    if (options && filters[filterKey]) {
      notSelectedOptions = options.filter(
        (x) => !filters[filterKey].includes(x.value.toString()),
      )
    } else if (options) {
      notSelectedOptions = options
    }

    return (
      <div>
        <Dropdown
          label={name}
          width="100%"
          value=""
          defaultValue=""
          marginBottom="0"
          onChange={(e) => onChangeMultiSelectFilter(filterKey, e.target.value)}
        >
          <option value="" disabled hidden>
            - Select -
          </option>
          {notSelectedOptions.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </Dropdown>
        <YSpacing height="5px" />
        <FlexContainer flexWrap="wrap">
          {filters[filterKey] &&
            filters[filterKey].map((filterVal, i) => {
              const option = options.find(
                (o) => o.value.toString() === filterVal,
              )
              if (option) {
                return (
                  <MultiSelectOption
                    key={filterVal}
                    onClick={() => onRemoveMultiSelectOption(filterKey, i)}
                  >
                    {option.label}
                  </MultiSelectOption>
                )
              }
            })}
        </FlexContainer>
      </div>
    )
  }

  const renderFilter = (config) => {
    let filter
    switch (config.name) {
      case 'hqs':
        filter = multiSelectObjsFilter(config.key, config.name)
        break
      case 'client':
        filter = clientFilter(config.key)
        break
      case 'captain':
        filter = captainFilter(config.key)
        break
      case 'chef':
        filter = chefFilter(config.key)
        break
      case 'salesRep':
        filter = salesRepFilter(config.key)
        break
      case 'orderType':
        filter = orderTypeFilter(config.key)
        break
      case 'isPaid':
        filter = isPaidFilter(config.key)
        break
      case 'multiSelectAuto':
        filter = multiSelectAutoComplete(
          config.key,
          config.loaderFunction,
          config.label,
        )
        break
      case 'multiSelectText':
        filter = multiSelectText(config.key, config.parameter, config.label)
        break
      case 'date':
        filter = dateFilter(config.key, config.presenter, config.label)
        break
      case 'dateTime':
        filter = dateTimeFilter(config.key, config.presenter, config.label)
        break
      case 'dropdown':
        filter = dropdownFilter(config.key, config.label, config.options)
        break
      case 'text':
        filter = textFilter(config.key, config.label)
        break
      case 'page':
        filter = pageFilter(config.key)
        break
      case 'limit':
        filter = limitFilter(config.key)
    }

    return (
      <div>
        {filter}
        {filter && <YSpacing height="10px" />}
      </div>
    )
  }

  return (
    <Panel width="250px">
      {!loadOnChange && (
        <Button
          label="Search"
          width="100%"
          onClick={() => props.loadReport()}
        />
      )}
      <YSpacing height="10px" />
      {filterConfigs.map((config) => renderFilter(config))}
      <div>
        <label>Results ({numResults})</label>
      </div>
    </Panel>
  )
}

Filters.propTypes = {
  filterConfigs: PropTypes.array,
  headquarters: PropTypes.array,
  loadOnChange: PropTypes.bool,
  numResults: PropTypes.number,
  userId: PropTypes.string,

  loadAccounts: PropTypes.func,
  loadCateringCaptains: PropTypes.func,
  loadChefs: PropTypes.func,
  loadHeadquarters: PropTypes.func,
  loadReport: PropTypes.func,
  onChange: PropTypes.func,
  searchSalesReps: PropTypes.func,
}

export default Filters
