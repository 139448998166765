import { connect } from 'react-redux'

import { EditServiceDepotModal } from '@components/market/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { editServiceDepotModal, serviceDepot } = state
  const { show } = editServiceDepotModal
  const errors = state.errors.editServiceDepotModal || {}

  return {
    errors,
    serviceDepot,
    show,
  }
}

const mapDispatchToProps = () => {
  const { MarketService, RestService, UIService } = services
  const { SaveServiceDepot } = coordinators
  const { pResponseServiceDepots } = presenters.Api

  const { closeServiceDepot } = UIService.EditMarket
  const flashError = (message) =>
    UIService.FlashMessage.displayFailureMessage(message)

  const saveServiceDepot = SaveServiceDepot({
    HandleError,
    MarketService,
    RestService,
    UIService,
    pResponseServiceDepots,
  })

  return {
    closeServiceDepot,
    saveServiceDepot,
    flashError,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditServiceDepotModal)
