import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Moment from 'moment-timezone'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Filters } from '@containers/menuItemsSearch'
import { ColumnSorter } from '@components/menuItemsSearch'
import { FaFilter } from 'react-icons/fa'
import Modal from '@components/common/modal/Modal'
import {
  FlexContainer,
  Panel,
  YSpacing,
  XSpacing,
  Loader,
} from '@components/common'
import { Button, Dropdown, Input, Checkbox } from '@components/common/form'
import { MenuItemsData } from './constants'
import { colors } from '../../../constants'
import { CSVLink } from 'react-csv'
import { WithContext as ReactTags } from 'react-tag-input'

const apiFilters = {
  chefIds: [],
  chefStatus: '',
  hqs: [],
  search: '',
  page: 1,
  resultsPerPage: 50,
  marketTypes: [],
  isApproved: '',
}

const MenuItemsPage = ({ reduxState }) => {
  const [isAscending, setIsAscending] = useState(false)
  const [dateHeadings, setDateHeadings] = useState([])
  const [tableHeadings, setTableHeadings] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [originalData, setOriginalData] = useState([])
  const [prevApiFilters, setPrevApiFilters] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(true)
  const [itemLocations, setItemLocations] = useState([])
  const [filterConfigs] = useState(MenuItemsData.getFilters(reduxState))
  const [csvData, setCsvData] = useState(null)
  const [csvName, setCsvName] = useState(null)
  const [selected, setSelected] = useState({})
  const [selectAll, toggleSelectAll] = useState(false)
  const [showMarginsModal, toggleShowMarginsModal] = useState(false)
  const [margin, setMargin] = useState()
  const [updating, setUpdating] = useState()
  const [editMode, toggleEditMode] = useState(false)
  const [allergens, setAllergens] = useState([])

  useEffect(() => {
    if (reduxState.settings && reduxState.settings.menuItems) {
      setAllergens(reduxState.settings.menuItems.allDietaryAllergens)
    }
  }, [reduxState.settings])

  const onSetItemLocations = (data) => {
    let locations = data.map((d) => d['Item Pickup City'])
    locations = [...new Set(locations)]
    setItemLocations(locations)
  }

  const onFilterFrontend = (filters, data) => {
    const newData = data.filter((d) => {
      const itemLocationFilter = filters['itemLocation']
      const packagingFilter = filters['packaging']
      const mealTypeFilter = filters['mealType']
      const allergensFilter = filters['allergens']
      const allergens = d['Allergens'].split(', ')
      const chefPrice = Number(d['Chef Price'])
      const marketPrice = Number(d['Market Price'])
      const { chefPriceFrom, chefPriceTo, marketPriceFrom, marketPriceTo } =
        filters
      if (packagingFilter && packagingFilter.length > 0) {
        if (!packagingFilter.includes(d['Packaging'])) {
          return
        }
      }
      if (mealTypeFilter && mealTypeFilter.length > 0) {
        if (!mealTypeFilter.includes(d['Meal Type'])) {
          return
        }
      }
      if (allergensFilter && allergensFilter.length > 0) {
        if (
          !allergensFilter.every((allergen) => allergens.includes(allergen))
        ) {
          return
        }
      }
      if (itemLocationFilter && itemLocationFilter.length > 0) {
        const location = d['Item Pickup City']
        if (!itemLocationFilter.includes(location)) {
          return
        }
      }
      if (chefPriceFrom) {
        if (!(chefPrice >= Number(chefPriceFrom))) {
          return
        }
      }
      if (chefPriceTo) {
        if (!(chefPrice <= Number(chefPriceTo))) {
          return
        }
      }
      if (marketPriceFrom) {
        if (!(marketPrice >= Number(marketPriceFrom))) {
          return
        }
      }
      if (marketPriceTo) {
        if (!(marketPrice <= Number(marketPriceTo))) {
          return
        }
      }

      return d
    })
    setIsLoading(false)
    setFilteredData(newData)
  }

  const onFilterAPI = async (newApiFilters, filters) => {
    setIsLoading(true)
    const params = { ...apiFilters, ...newApiFilters }
    if (
      params['chefIds'].length > 0 ||
      params['search'].length > 0 ||
      params['isApproved'] ||
      params['chefStatus'] !== ''
    ) {
      const data = await MenuItemsData.loadData(params)
      onSetItemLocations(data)
      setOriginalData(data)
      onFilterFrontend(filters, data)
      if (data && data.length > 0) {
        if (tableHeadings.length === 0) {
          const newHeadings = Object.keys(data[0]).filter(
            (d) => !d.includes('_'),
          )
          const newDateHeadings = Object.keys(data[0]).filter((d) =>
            d.includes('_'),
          )
          setTableHeadings(newHeadings)
          setDateHeadings(newDateHeadings)
        }
      }
    } else {
      setOriginalData([])
      setFilteredData([])
    }
    setIsLoading(false)
  }

  const onLoadData = (filters) => {
    setIsLoading(true)
    setCsvData(null)
    const newApiFilters = {}
    Object.entries(filters).map((filter) => {
      const key = filter[0]
      const value = filter[1]
      if (apiFilters[key] !== undefined) {
        newApiFilters[key] = value
      }
    })

    // check if API filters have changed
    const prevFlattened = Object.values(prevApiFilters).flat()
    const newFlattened = Object.values(newApiFilters).flat()
    if (
      !(
        prevFlattened.length === newFlattened.length &&
        prevFlattened.every((item) => newFlattened.includes(item))
      )
    ) {
      onFilterAPI(newApiFilters, filters)
      setPrevApiFilters(newFlattened)
    } else {
      onFilterFrontend(filters, originalData)
    }
  }

  const onCsvData = () => {
    const csvData = filteredData.map((row) => {
      return Object.keys(row).reduce((obj, key) => {
        if (key.substr(0, 1) !== '_' && tableHeadings.includes(key)) {
          obj[key] = row[key]
        }

        return obj
      }, {})
    })
    setCsvData(csvData)
    const now = Moment()
    setCsvName(`menuitems-${now.format('MM-DD-YYYY_HH_mm')}.csv`)
  }

  const adjustSortValue = (value) => {
    if (typeof value === 'string') {
      // turn currency string into number so it can be sorted
      let newValue = value.split('')
      if (newValue[0] === '$') {
        newValue = Number(newValue.splice(1, newValue.length).join(''))

        return newValue
      } else {
        return value
      }
    } else {
      return value
    }
  }

  const onSortByDate = (key) => {
    let newArray
    if (isAscending) {
      newArray = [...filteredData].sort((a, b) => {
        return Moment.utc(a[`_${key}`]).diff(Moment.utc(b[`_${key}`]))
      })
    } else {
      newArray = [...filteredData].sort((a, b) => {
        return Moment.utc(b[`_${key}`]).diff(Moment.utc(a[`_${key}`]))
      })
    }
    setIsAscending(!isAscending)
    setFilteredData(newArray)
  }

  const onSort = (key) => {
    if (dateHeadings.includes(`_${key}`)) {
      return onSortByDate(key)
    }
    let newArray
    if (isAscending) {
      newArray = [...filteredData].sort((a, b) => {
        const valueA = adjustSortValue(a[key])
        const valueB = adjustSortValue(b[key])
        if (valueA < valueB) {
          return -1
        }

        return valueA > valueB ? 1 : 0
      })
    } else {
      newArray = [...filteredData].sort((a, b) => {
        const valueA = adjustSortValue(a[key])
        const valueB = adjustSortValue(b[key])
        if (valueA > valueB) {
          return -1
        }

        return valueA < valueB ? 1 : 0
      })
    }
    setIsAscending(!isAscending)
    setFilteredData(newArray)
  }

  const onAddTableHeading = (e) => {
    const { value } = e.target
    const newHeadings = [...tableHeadings]
    newHeadings.push(value)
    setTableHeadings(newHeadings)
  }

  const onSelectAll = () => {
    if (selectAll) {
      setSelected({})
    } else {
      setSelected(
        filteredData.reduce((map, item) => {
          map[item['_Id']] = item

          return map
        }, {}),
      )
    }
    toggleSelectAll(!selectAll)
  }

  const onToggleItem = (checked, item) => {
    const id = item['_Id']
    const copy = { ...selected }
    if (checked) {
      copy[id] = item
    } else {
      delete copy[id]
    }
    setSelected(copy)
  }

  const onSaveMargins = async () => {
    if (updating) {
      return
    }
    const values = Object.values(selected)
    const total = values.length
    const confirm = await MenuItemsData.confirmUpdate({
      text: `Are you sure you want to update margins for ${total} menu item${
        total > 1 ? 's' : ''
      }?`,
    })
    if (!confirm) {
      return
    }

    let response = []
    const max = 10
    setUpdating({ current: 0, total })
    for (let i = 0; i < Math.ceil(values.length / max); i++) {
      const offset = i * max
      const limit = offset + max
      const req = values.slice(offset, limit).map((item) => {
        const price = (
          item['Chef Price'] *
          (1 + (margin ? margin : 0) / 100)
        ).toFixed(2)

        return {
          ...item,
          'Market Price': price,
        }
      })
      const resp = await MenuItemsData.updateData(req, total)
      if (resp) {
        response = [...response, ...resp]
        setUpdating({ current: limit, total })
      } else {
        setUpdating(undefined)

        return
      }
    }
    const respMap = response.reduce((map, item) => {
      map[item['_Id']] = item

      return map
    }, {})

    const updated = filteredData.map((item) => {
      if (respMap[item['_Id']]) {
        return respMap[item['_Id']]
      } else {
        return item
      }
    })
    setFilteredData(updated)
    toggleShowMarginsModal(false)
    setMargin()
    setSelected({})
    toggleSelectAll(false)
    setUpdating(undefined)
  }

  const onSaveEditChanges = async () => {
    if (updating) {
      return
    }
    const values = Object.values(selected)
    const total = values.length
    const confirm = await MenuItemsData.confirmUpdate({
      text: `Are you sure you want to update data for ${total} menu item${
        total > 1 ? 's' : ''
      }?`,
    })
    if (!confirm) {
      return
    }

    let response = []
    const max = 10
    setUpdating({ current: 0, total })
    for (let i = 0; i < Math.ceil(values.length / max); i++) {
      const offset = i * max
      const limit = offset + max
      const req = values.slice(offset, limit)
      const resp = await MenuItemsData.updateData(req, total)
      if (resp) {
        response = [...response, ...resp]
        setUpdating({ current: limit, total })
      } else {
        setUpdating(undefined)

        return
      }
    }
    const respMap = response.reduce((map, item) => {
      map[item['_Id']] = item

      return map
    }, {})

    const updated = filteredData.map((item) => {
      if (respMap[item['_Id']]) {
        return respMap[item['_Id']]
      } else {
        return item
      }
    })
    setFilteredData(updated)
    toggleShowMarginsModal(false)
    setMargin()
    setSelected({})
    toggleSelectAll(false)
    toggleEditMode(false)
    setUpdating(undefined)
  }

  const onEditValue = (id, key, value) => {
    setSelected((selected) => ({
      ...selected,
      [id]: {
        ...selected[id],
        [key]: value,
      },
    }))
  }

  const handleAllergenTag = (tag, id) => {
    if (typeof tag === 'string') {
      if (!allergens.includes(tag)) {
        return
      }
      setSelected((state) => ({
        ...state,
        [id]: {
          ...state[id],
          Allergens: state[id]['Allergens'] + ',' + tag,
        },
      }))
    } else {
      setSelected((state) => {
        const allergens = state[id]['Allergens'].split(',')

        return {
          ...state,
          [id]: {
            ...state[id],
            Allergens: [
              ...allergens.slice(0, tag),
              ...allergens.slice(tag + 1),
            ].join(','),
          },
        }
      })
    }
  }

  const renderMarginsModal = () => {
    return (
      <Modal
        title="Update Margins"
        hideModal={() => toggleShowMarginsModal(false)}
        color="#001940"
        width="260px"
      >
        <Input
          label="Margin (%)"
          marginBottom="0"
          width="200px"
          type="number"
          value={margin}
          placeholder="Enter Margin"
          onChange={(e) => setMargin(e.target.value)}
        />
        <YSpacing height={20} />
        <FlexContainer justifyContent="flex-end" alignItems="center">
          {updating ? (
            <p>
              Updating {updating.current}/{updating.total}
            </p>
          ) : (
            <Button label="Save" onClick={onSaveMargins} />
          )}
        </FlexContainer>
      </Modal>
    )
  }

  const renderDataCell = (d, h) => {
    const id = d['_Id']
    if (editMode && selected[id]) {
      switch (true) {
        case h === 'Description':
          return (
            <td key={h}>
              <textarea
                style={{ display: 'flex', minHeight: 50 }}
                value={selected[id][h]}
                onChange={(e) => onEditValue(id, h, e.target.value)}
              />
            </td>
          )
        case h === 'Allergens':
          return (
            <td key={h}>
              <ReactTags
                inline
                autofocus={false}
                allowDragDrop={false}
                tags={selected[id][h]
                  .split(',')
                  .map((text) => ({ id: text, text }))}
                suggestions={allergens}
                handleDelete={(tag) => handleAllergenTag(tag, id)}
                handleAddition={(tag) => handleAllergenTag(tag, id)}
                allowDeleteFromEmptyInput={false}
                placeholder="Search for allergen"
              />
            </td>
          )
      }
    }

    return <td key={h}>{d[h]}</td>
  }

  const renderHeadingsDropdown = () => {
    const notSelected = MenuItemsData.columns.filter(
      (x) => !tableHeadings.includes(x),
    )

    return (
      <Dropdown
        label=""
        width="200px"
        marginBottom="0"
        value=""
        defaultValue=""
        onChange={onAddTableHeading}
      >
        <option value="" disabled hidden>
          - Add Column -
        </option>
        {notSelected.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Dropdown>
    )
  }

  return (
    <FlexContainer flexDirection="column">
      <YSpacing height="40px" />
      <ReportsPageBody>
        <h1>Menu Items Search</h1>
        <FlexContainer>
          <div style={{ display: 'flex', flexDirection: 'row', minWidth: 250 }}>
            {!csvData && <Button label="Export CSV" onClick={onCsvData} />}
            {csvData && (
              <CSVLink
                className="m-2"
                data={csvData}
                filename={csvName}
                target="_blank"
              >
                Download
              </CSVLink>
            )}
            <XSpacing width="20px" />
            <FilterButton onClick={() => setShowFilter(!showFilter)}>
              <FaFilter />
              {showFilter ? 'Hide Filters' : 'Show Filters'}
            </FilterButton>
          </div>
          <XSpacing width="20px" />
          <FlexContainer>
            {Object.values(selected).length > 0 && (
              <FlexContainer flexDirection="row" justifyContent="space-between">
                <FlexContainer>
                  <Button
                    label="Update Margins"
                    onClick={() => toggleShowMarginsModal(true)}
                  />
                </FlexContainer>
                <FlexContainer alignItems="center" justifyContent="flex-end">
                  {editMode &&
                    (updating ? (
                      <p>
                        Updating {updating.current}/{updating.total}
                      </p>
                    ) : (
                      <Button
                        label="Save Changes"
                        onClick={() => onSaveEditChanges(true)}
                      />
                    ))}
                  <XSpacing width="10px" />
                  <div
                    style={{
                      height: 39,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Checkbox
                      label="Edit Mode"
                      checked={editMode}
                      onChange={() => toggleEditMode((state) => !state)}
                    />
                  </div>
                </FlexContainer>
              </FlexContainer>
            )}
          </FlexContainer>
        </FlexContainer>
        <YSpacing height="10px" />
        <FlexContainer>
          <FilterContainer isVisible={showFilter}>
            <Filters
              filterConfigs={filterConfigs}
              numResults={filteredData.length}
              cityLocations={itemLocations}
              onChange={(newFilters) => onLoadData(newFilters)}
            />
            <XSpacing width="20px" />
          </FilterContainer>
          <Panel width="100%">
            {filteredData.length > 0 ? (
              <FlexContainer alignItems="center">
                {MenuItemsData.columns.length !== tableHeadings.length &&
                  renderHeadingsDropdown()}
                <p style={{ fontFamily: 'bold', marginLeft: '20px' }}>
                  *Drag & drop table headings to sort or click{' '}
                  <span style={{ color: 'red' }}>✕</span> to remove
                </p>
              </FlexContainer>
            ) : (
              <p style={{ fontFamily: 'bold' }}>
                Search menu items or chefs to get started
              </p>
            )}
            <YSpacing height="10px" />
            <ReportTable>
              <tbody>
                <ColumnSorter
                  sortList={tableHeadings}
                  onUpdateList={(headings) => setTableHeadings(headings)}
                  onSort={onSort}
                  onSelectAll={onSelectAll}
                  selectAll={selectAll}
                />
                {isLoading ? (
                  <tr>
                    <Loader isCenter={true} />
                  </tr>
                ) : (
                  filteredData.map((d, i) => (
                    <tr key={i}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selected[d['_Id']]}
                          onClick={(e) => onToggleItem(e.target.checked, d)}
                        />
                      </td>
                      {tableHeadings.map((h) => renderDataCell(d, h))}
                    </tr>
                  ))
                )}
              </tbody>
            </ReportTable>
          </Panel>
        </FlexContainer>
      </ReportsPageBody>
      {showMarginsModal && renderMarginsModal()}
    </FlexContainer>
  )
}

const ReportsPageBody = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  h1 {
    font-size: 25px;
    margin-bottom: 10px;
    font-family: 'bold';
    color: ${colors.gray400};
  }
`

const ReportTable = styled.table`
  position: relative;
  td {
    border-bottom: 1px solid ${colors.gray200};
    padding: 5px 15px;
    font-family: 'regular';
    font-size: 12px;
    max-width: 100px;
  }
  th {
    padding: 10px 15px;
    font-family: 'bold';
    text-transform: uppercase;
    font-size: 12px;
    background: ${colors.gray100};
    cursor: pointer;
    position: sticky;
    top: 0;
    border-bottom: 1px solid ${colors.gray300};
  }
`

const FilterContainer = styled.div`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: sticky;
  top: 0;
  z-index: 100;
`

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  font-family: 'regular';
  font-size: 12px;
  color: ${colors.blue400};
  margin: ${(props) => props.margin};
  svg {
    margin-right: 5px;
  }
`

MenuItemsPage.propTypes = {
  reduxState: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    reduxState: state,
  }
}

export default connect(mapStateToProps, undefined)(MenuItemsPage)
