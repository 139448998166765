import { connect } from 'react-redux'

import { EditRecipeModal } from '@components/chef/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { editRecipe, editRecipeModal } = state
  const errors = state.errors.editRecipeModal || {}
  const { show } = editRecipeModal

  return {
    recipe: editRecipe,
    show,
    errors,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, UIService } = services
  const { pResponseGeneric, pRequestUpdateRecipe } = presenters.Api
  const { DeleteRecipe, SaveRecipe, SelectChef } = coordinators
  const { close } = UIService.EditRecipe
  const saveRecipe = SaveRecipe({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateRecipe,
    pResponseGeneric,
    HandleError,
  })
  const clearErrors = UIService.Errors.clear
  const deleteRecipe = DeleteRecipe({
    ChefService,
    RestService,
    UIService,
    pResponseGeneric,
    HandleError,
    SelectChef,
  })

  return {
    close,
    clearErrors,
    saveRecipe,
    deleteRecipe,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditRecipeModal)
