import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AuthorizedInteractable } from '@containers/common/auth'

export class PackagingAddOns extends Component {
  constructor(props) {
    super(props)

    this.state = {
      packagingAddOns: props.packagingAddOns,
      packageMappings: [],
      selectedPackagingAddOn: null,
      editPackagingAddOn: null,
      editPackageMapping: null,
      newPackagingAddOn: null,
      newPackageMapping: null,
      sortAsc: true,
    }
  }

  componentWillMount() {
    this.props.loadPackagingAddOns()
    this.props.loadPackaging()
  }

  componentWillReceiveProps(nextProps) {
    const { packagingAddOns } = nextProps

    if (packagingAddOns !== this.state.packagingAddOns) {
      const newState = { packagingAddOns }
      const { selectedPackagingAddOn } = this.state
      const updatedPackagingAddOn =
        selectedPackagingAddOn &&
        packagingAddOns.find((p) => p.id == selectedPackagingAddOn.id)
      if (updatedPackagingAddOn) {
        const { packageMappings } = updatedPackagingAddOn
        newState.selectedPackagingAddOn = updatedPackagingAddOn
        newState.packageMappings = packageMappings
      } else {
        newState.selectedPackagingAddOn = null
        newState.packageMappings = []
      }

      this.setState(newState)
    }
  }

  sortByString = (collectionName, columnName) => {
    const { [collectionName]: collection, sortAsc } = this.state
    const getColumn = (item) =>
      item[columnName] ? item[columnName].toLowerCase() : ''
    const sorted = collection.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ [collectionName]: sorted, sortAsc: !sortAsc })
  }

  sortByBoolean = (collectionName, columnName) => {
    const { [collectionName]: collection, sortAsc } = this.state
    const getColumn = (item) => (item[columnName] === true ? 1 : 0)
    const sorted = collection.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ [collectionName]: sorted, sortAsc: !sortAsc })
  }

  sortByNumber = (collectionName, columnName) => {
    const { [collectionName]: collection, sortAsc } = this.state
    const sorted = collection.sort((a, b) => {
      if (sortAsc) {
        return a[columnName] - b[columnName]
      } else {
        return b[columnName] - a[columnName]
      }
    })
    this.setState({ [collectionName]: sorted, sortAsc: !sortAsc })
  }

  selectPrimaryRow = (addOn) => {
    const { selectedPackagingAddOn } = this.state
    if (selectedPackagingAddOn == addOn) {
      this.setState({
        newPackagingAddOn: null,
        newPackageMapping: null,
        editPackagingAddOn: { ...addOn },
        editPackageMapping: null,
      })
    } else {
      const { packageMappings } = addOn
      this.setState({
        selectedPackagingAddOn: addOn,
        packageMappings,
        newPackagingAddOn: null,
        newPackageMapping: null,
        editPackagingAddOn: null,
        editPackageMapping: null,
      })
    }
  }

  renderMapping = (pkgMapping, i) => {
    const { editPackageMapping } = this.state
    const id = editPackageMapping && editPackageMapping.id
    if (pkgMapping.id == id) {
      return this.renderEditMapping(editPackageMapping, i)
    } else {
      return this.renderStaticMapping(pkgMapping, i)
    }
  }

  renderStaticMapping = (pkgMapping, i) => {
    return (
      <tr
        key={i}
        className="clickable"
        onClick={(e) => {
          e.preventDefault()
          this.setState({
            newPackagingAddOn: null,
            newPackageMapping: null,
            editPackagingAddOn: null,
            editPackageMapping: { ...pkgMapping },
          })
        }}
      >
        <td>{pkgMapping.packagingName}</td>
        <td>{pkgMapping.countPerPkg}</td>
      </tr>
    )
  }

  renderEditMapping = (pkgMapping, i) => {
    return (
      <tr key={i}>
        <td>{pkgMapping.packagingName}</td>
        <td>
          <input
            type="text"
            value={pkgMapping.countPerPkg}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editPackageMapping: {
                  ...pkgMapping,
                  countPerPkg: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <AuthorizedInteractable
            roles={['master admin', 'chef lead', 'captain lead']}
          >
            <button
              className="button-neutral"
              key="deletePackageMapping"
              onClick={async (event) => {
                event.preventDefault()
                const { savePackagingAddOn } = this.props
                pkgMapping._destroy = true
                if (await savePackagingAddOn({ packageMapping: pkgMapping })) {
                  this.setState({ editPackageMapping: null })
                }
              }}
            >
              Delete Mapping
            </button>
          </AuthorizedInteractable>
        </td>
        <td>
          <button
            className="button-neutral"
            key="clearEditPackageMapping"
            onClick={(event) => {
              event.preventDefault()
              this.setState({ editPackageMapping: null })
            }}
          >
            Clear Updates
          </button>
        </td>
      </tr>
    )
  }

  renderPrimaryRow = (addOn, i) => {
    const { editPackagingAddOn } = this.state
    const id = editPackagingAddOn && editPackagingAddOn.id
    if (addOn.id == id) {
      return this.renderPrimaryEditRow(editPackagingAddOn, i)
    } else {
      return this.renderPrimaryStaticRow(addOn, i)
    }
  }

  renderPrimaryStaticRow = (addOn, i) => {
    return (
      <tr
        key={i}
        className="clickable"
        onClick={(e) => {
          e.preventDefault()
          this.selectPrimaryRow(addOn)
        }}
      >
        <td>{addOn.name}</td>
        <td>{addOn.supplyType}</td>
        <td>{addOn.defaultUnitCost}</td>
        <td>
          {addOn.active ? (
            <span className="approved-check">✔</span>
          ) : (
            <span className="not-approved-x">✖</span>
          )}
        </td>
        <td>
          {addOn.trackInventory ? (
            <span className="approved-check">✔</span>
          ) : (
            <span className="not-approved-x">✖</span>
          )}
        </td>
        <td>%{(addOn.dftRecoupPct * 100).toFixed(2)}</td>
        <td>%{(addOn.dftHungryVipRecoupPct * 100).toFixed(2)}</td>
      </tr>
    )
  }

  renderPrimaryEditRow = (addOn, i) => {
    return (
      <tr key={i}>
        <td>
          <input
            type="text"
            value={addOn.name}
            onInput={(e) =>
              this.setState({
                editPackagingAddOn: { ...addOn, name: e.target.value },
              })
            }
          />
        </td>
        <td>{addOn.supplyType}</td>
        <td>
          <input
            type="text"
            value={addOn.defaultUnitCost}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editPackagingAddOn: {
                  ...addOn,
                  defaultUnitCost: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <input
            type="checkbox"
            checked={addOn.active}
            onChange={(e) =>
              this.setState({
                editPackagingAddOn: { ...addOn, active: e.target.checked },
              })
            }
          />
        </td>
        <td>
          <input
            type="checkbox"
            checked={addOn.trackInventory}
            onChange={(e) =>
              this.setState({
                editPackagingAddOn: {
                  ...addOn,
                  trackInventory: e.target.checked,
                },
              })
            }
          />
        </td>
        <td>
          <input
            type="text"
            value={addOn.dftRecoupPct}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editPackagingAddOn: {
                  ...addOn,
                  dftRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <input
            type="text"
            value={addOn.dftHungryVipRecoupPct}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editPackagingAddOn: {
                  ...addOn,
                  dftHungryVipRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <AuthorizedInteractable
            roles={['master admin', 'chef lead', 'captain lead']}
          >
            <button
              className="button-neutral"
              key="deleteSupply"
              onClick={async (event) => {
                event.preventDefault()
                const { deletePackagingAddOn } = this.props
                if (await deletePackagingAddOn(addOn.id)) {
                  this.setState({ editPackagingAddOn: null })
                }
              }}
            >
              Delete Supply
            </button>
          </AuthorizedInteractable>
        </td>
        <td>
          <button
            className="button-neutral"
            key="clearEditPackageMapping"
            onClick={(event) => {
              event.preventDefault()
              this.setState({ editPackagingAddOn: null })
            }}
          >
            Clear Updates
          </button>
        </td>
      </tr>
    )
  }

  renderPrimaryButtons = () => {
    const {
      editPackagingAddOn,
      editPackageMapping,
      newPackagingAddOn,
      newPackageMapping,
    } = this.state
    const { savePackagingAddOn } = this.props
    const renderNew =
      !newPackagingAddOn &&
      !newPackageMapping &&
      !editPackageMapping &&
      !editPackagingAddOn
    const editedAddOn = editPackagingAddOn || newPackagingAddOn

    return (
      <div className="page">
        <center>
          {editedAddOn && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="editPackagingAddOn"
                onClick={async (event) => {
                  event.preventDefault()
                  if (
                    await savePackagingAddOn({ packagingAddOn: editedAddOn })
                  ) {
                    this.setState({
                      editPackagingAddOn: null,
                      newPackagingAddOn: null,
                    })
                  }
                }}
              >
                Save Packaging Add On Changes
              </button>
            </AuthorizedInteractable>
          )}
          {renderNew && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="newPackagingAddOn"
                onClick={(event) => {
                  event.preventDefault()
                  this.setState({
                    newPackagingAddOn: {
                      name: '',
                      active: true,
                      trackInventory: true,
                      dftRecoupPct: 0.0,
                      dftHungryVipRecoupPct: 0.0,
                    },
                  })
                }}
              >
                New Packaging Add On
              </button>
            </AuthorizedInteractable>
          )}
        </center>
      </div>
    )
  }

  renderMappingButtons = () => {
    const {
      selectedPackagingAddOn,
      editPackagingAddOn,
      editPackageMapping,
      newPackagingAddOn,
      newPackageMapping,
    } = this.state
    const { savePackagingAddOn } = this.props
    const renderNew =
      !newPackagingAddOn &&
      !newPackageMapping &&
      !editPackageMapping &&
      !editPackagingAddOn &&
      selectedPackagingAddOn
    const editedMapping = editPackageMapping || newPackageMapping

    return (
      <div className="page">
        <center>
          {editedMapping && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="savePackageMapping"
                onClick={async (event) => {
                  event.preventDefault()
                  if (
                    await savePackagingAddOn({ packageMapping: editedMapping })
                  ) {
                    this.setState({
                      editPackageMapping: null,
                      newPackageMapping: null,
                    })
                  }
                }}
              >
                Save Package Mapping Changes
              </button>
            </AuthorizedInteractable>
          )}
          {renderNew && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="newPackagingMapping"
                onClick={(event) => {
                  event.preventDefault()
                  this.setState({
                    newPackageMapping: {
                      orderSuppliableId: selectedPackagingAddOn.id,
                      suppliableForId: null,
                      packagingAddOnName: selectedPackagingAddOn.name,
                      countPerPkg: 1.0,
                    },
                  })
                }}
              >
                New Packaging Mapping
              </button>
            </AuthorizedInteractable>
          )}
        </center>
      </div>
    )
  }

  renderNewPackagingAddOn = () => {
    const { newPackagingAddOn } = this.state

    return (
      <table className="table table-hover page">
        <thead>
          <tr>
            <th colSpan={6}>New Packaging Add On</th>
          </tr>
          <tr>
            <th>Item Name</th>
            <th>Active</th>
            <th>Default Unit Cost</th>
            <th>Track Inventory</th>
            <th>Recoup Pct</th>
            <th>Hungry VIP Recoup Pct</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                value={newPackagingAddOn.name}
                onInput={(e) =>
                  this.setState({
                    newPackagingAddOn: {
                      ...newPackagingAddOn,
                      name: e.target.value,
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="checkbox"
                checked={newPackagingAddOn.active}
                onChange={(e) =>
                  this.setState({
                    newPackagingAddOn: {
                      ...newPackagingAddOn,
                      active: e.target.checked,
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="text"
                value={newPackagingAddOn.defaultUnitCost}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newPackagingAddOn: {
                      ...newPackagingAddOn,
                      defaultUnitCost: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
            <td>
              <input
                type="checkbox"
                checked={newPackagingAddOn.trackInventory}
                onChange={(e) =>
                  this.setState({
                    newPackagingAddOn: {
                      ...newPackagingAddOn,
                      trackInventory: e.target.checked,
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="text"
                value={newPackagingAddOn.dftRecoupPct}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newPackagingAddOn: {
                      ...newPackagingAddOn,
                      dftRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
            <td>
              <input
                type="text"
                value={newPackagingAddOn.dftHungryVipRecoupPct}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newPackagingAddOn: {
                      ...newPackagingAddOn,
                      dftHungryVipRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderNewPackageMapping = () => {
    const { newPackageMapping } = this.state
    const { packaging } = this.props

    return (
      <table className="table table-hover page">
        <thead>
          <tr>
            <th colSpan={3}>New Package Mapping</th>
          </tr>
          <tr>
            <th>Add On Name</th>
            <th>Packaging</th>
            <th>Count Per Pkg</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{newPackageMapping.packagingAddOnName}</td>
            <td>
              <select
                value={newPackageMapping.suppliableForId}
                onChange={(e) => {
                  this.setState({
                    newPackageMapping: {
                      ...newPackageMapping,
                      suppliableForId: e.target.value,
                    },
                  })
                }}
              >
                <option>Not Selected</option>
                {packaging.map((pkg, i) => (
                  <option key={i} value={pkg.id}>
                    {pkg.name}
                  </option>
                ))}
              </select>
            </td>
            <td>
              <input
                type="text"
                value={newPackageMapping.countPerPkg}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newPackageMapping: {
                      ...newPackageMapping,
                      countPerPkg: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  render() {
    const {
      packagingAddOns,
      packageMappings,
      selectedPackagingAddOn,
      newPackagingAddOn,
      newPackageMapping,
    } = this.state

    return (
      <div>
        <br />
        <h3>Description</h3>
        <p>
          These supplies are add ons to be included with packaging supplies. Add
          ons are mapped to specific packagings for which they are applicable.
        </p>

        <br />
        <h3>Instructions</h3>
        <p>
          <ul>
            <li>Click an add on to see which packages it is mapped to.</li>
            <li>Click the add on again to edit.</li>
            <li>Click package mappings to edit them as well.</li>
            <li>An add on can only be mapped to the same package once.</li>
          </ul>
        </p>

        <br />
        <h3>Columns</h3>
        <p>
          <ul>
            <li>
              Recoup Pct - Percent of supply recouped after a catering. Will
              factor into a lower cost per order.
            </li>
            <li>
              Hungry VIP Recoup Pct - Percent of supply recouped after a HUNGRY
              VIP service type catering. Replaces the recoup pct and will factor
              into a lower cost per order.
            </li>
            <li>
              Count Per Pkg - Fixed count of add ons to be included with each
              packaging.
            </li>
          </ul>
        </p>
        <br />

        <table className="table table-hover page">
          <thead>
            <tr>
              <th onClick={() => this.sortByString('packagingAddOns', 'name')}>
                Item Name
              </th>
              <th
                onClick={() =>
                  this.sortByString('packagingAddOns', 'supplyType')
                }
              >
                Supply Type
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('packagingAddOns', 'defaultUnitCost')
                }
              >
                Default Unit Cost
              </th>
              <th
                onClick={() => this.sortByBoolean('packagingAddOns', 'active')}
              >
                Active
              </th>
              <th
                onClick={() =>
                  this.sortByBoolean('packagingAddOns', 'trackInventory')
                }
              >
                Track Inventory
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('packagingAddOns', 'dftRecoupPct')
                }
              >
                Recoup Pct
              </th>
              <th
                onClick={() =>
                  this.sortByNumber('packagingAddOns', 'dftHungryVipRecoupPct')
                }
              >
                Hungry VIP Recoup Pct
              </th>
            </tr>
          </thead>
          <tbody>
            {packagingAddOns.map((addOn, i) => this.renderPrimaryRow(addOn, i))}
          </tbody>
        </table>
        {newPackagingAddOn && this.renderNewPackagingAddOn()}
        {this.renderPrimaryButtons()}

        {selectedPackagingAddOn && (
          <div>
            <table className="table table-hover page">
              <thead>
                <tr>
                  <th colSpan={2}>
                    {selectedPackagingAddOn.name} - Packaging Mapping
                  </th>
                </tr>
                <tr>
                  <th
                    onClick={() =>
                      this.sortByString('packageMappings', 'packageName')
                    }
                  >
                    Package
                  </th>
                  <th
                    onClick={() =>
                      this.sortByString('packageMappings', 'countPerPkg')
                    }
                  >
                    Count Per Pkg
                  </th>
                </tr>
              </thead>
              <tbody>
                {packageMappings.map((addOn, i) =>
                  this.renderMapping(addOn, i),
                )}
              </tbody>
            </table>
          </div>
        )}

        {newPackageMapping && this.renderNewPackageMapping()}
        {this.renderMappingButtons()}
      </div>
    )
  }
}

PackagingAddOns.propTypes = {
  packaging: PropTypes.arrayOf(PropTypes.object),
  packagingAddOns: PropTypes.arrayOf(PropTypes.object),

  deletePackagingAddOn: PropTypes.func,
  loadPackaging: PropTypes.func,
  loadPackagingAddOns: PropTypes.func,
  savePackagingAddOn: PropTypes.func,
}

export default PackagingAddOns
