import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors } from '../../../../constants'
import { BiError } from 'react-icons/bi'

const Error = (props) => {
  const { error } = props

  return (
    <StyledError>
      <BiError size={18} />
      {error}
    </StyledError>
  )
}

Error.propTypes = {
  error: PropTypes.string,
}

const StyledError = styled.p`
  margin-top: 3px;
  color: ${colors.violet};
  display: flex;
  line-height: 1.2;
  font-family: 'bold';
  text-transform: capitalize;
  svg {
    margin-right: 5px;
  }
`

export default Error
