import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { AutocompleteInput } from '@containers/common/form'

class AccountExecutiveSection extends Component {
  onSelectAccountExecutive = (accountExecutive) => {
    this.props.onChange({ accountExecutive })
  }

  render() {
    const { accountExecutive, loadAccountExecutives, orderType } = this.props

    return (
      <div className="mb-5 w-64">
        <p className="mb-1 text-hungryGray bold uppercase text-sm">
          {' '}
          Sales Rep{' '}
        </p>
        <AutocompleteInput
          value={accountExecutive && accountExecutive.name}
          loaderFunction={loadAccountExecutives(orderType == 'VCX')}
          onSelect={this.onSelectAccountExecutive}
        />
      </div>
    )
  }
}

AccountExecutiveSection.propTypes = {
  accountExecutive: PropTypes.object,
  orderType: PropTypes.string,

  loadAccountExecutives: PropTypes.func,
  onChange: PropTypes.func,
}

export default AccountExecutiveSection
