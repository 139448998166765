import { connect } from 'react-redux'

import { GroupOrderReviewsReportsPage } from '@components/groupOrders'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { user = {}, headquarter = {} } = state
  const { lastLoggedInHq } = user
  const { userHeadquarters = [] } = headquarter
  const userHeadquarter =
    userHeadquarters.find((hq) => hq.id === lastLoggedInHq) || {}

  return {
    locale: userHeadquarter.locale,
    headquarter: userHeadquarter.id,
    currentUser: user,
  }
}

const mapDispatchToProps = () => {
  const { RestService, ChefService, UIService, MarketService } = services
  const {
    SearchGroupOrderReviewsCount,
    RequestSendGroupOrderReviewsReport,
    SearchDetailAccounts,
    LoadChefs,
    SearchMenuItems,
    LoadMarkets,
  } = coordinators
  const {
    pResponseGroupOrderAccounts,
    pResponseChefs,
    pResponseGroupOrderMenuItemsSearch,
    pResponseHeadquarters,
  } = presenters.Api
  const searchGroupOrderReviewsCount = SearchGroupOrderReviewsCount({
    RestService,
    HandleError,
  })
  const requestSendGroupOrderReviewsReport = RequestSendGroupOrderReviewsReport(
    { RestService, HandleError, UIService },
  )
  const searchGroupOrdersClients = SearchDetailAccounts({
    RestService,
    pResponseAccounts: pResponseGroupOrderAccounts,
  })
  const searchChefs = LoadChefs({ ChefService, RestService, pResponseChefs })
  const searchMenuItems = SearchMenuItems({
    RestService,
    pResponseMenuItemsSearch: pResponseGroupOrderMenuItemsSearch,
  })
  const loadHeadquarters = LoadMarkets({
    MarketService,
    RestService,
    pResponseHeadquarters,
  })

  return {
    searchGroupOrderReviewsCount,
    requestSendGroupOrderReviewsReport,
    searchGroupOrdersClients,
    searchChefs,
    searchMenuItems,
    loadHeadquarters,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupOrderReviewsReportsPage)
