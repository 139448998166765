import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class AdminUserService extends BaseService {
  clearEditUser() {
    this.dispatch(actions.clearEditAdminUser())
  }

  clearUser() {
    this.dispatch(actions.clearAdminUser())
  }

  removeUserFromUsers = (userId) => {
    this.dispatch(actions.removeUserFromUsers(userId))
  }

  setEditUser(user) {
    this.dispatch(actions.setEditAdminUser(user))
  }

  setUser(user) {
    this.dispatch(actions.setAdminUser(user))
  }

  getUser() {
    return this.getState().user
  }

  setUsers(users) {
    this.dispatch(actions.setAdminUsers(users))
  }

  updateEditUser(user) {
    this.dispatch(actions.updateEditAdminUser(user))
  }
}
