import { connect } from 'react-redux'

import { UserPage } from '@components/user'

import coordinators from '@coordinators'
import services from '@services'

const mapStateToProps = (state) => {
  const userName = state.adminUser && state.adminUser.name

  return { userName }
}

const mapDispatchToProps = () => {
  const { NewUser } = coordinators
  const { AdminUserService, UIService } = services

  const newUser = NewUser({ AdminUserService, UIService })

  return { newUser }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPage)
