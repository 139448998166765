import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class InvoiceService extends BaseService {
  setInvoice(invoice) {
    // add invoice to redux state
    this.dispatch(actions.setInvoice(invoice))
  }
}
