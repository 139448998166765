import React, { Component } from 'react'
import PropTypes from 'prop-types'

class MLBSingleOrderPage extends Component {
  render() {
    const { order, gameDay } = this.props

    return (
      <div>
        <div>{gameDay.format('MM-DD-YYYY')}</div>
        <table className="w-full border partners-table">
          <tr className="bg-gray-200">
            <th className="py-1 pr-4 bold uppercase">Delivery</th>
            <th className="py-1 pr-4 bold uppercase">Packs</th>
            <th className="py-1 pr-4 bold uppercase">Add Ons</th>
          </tr>
          <tr>
            <td className="pr-4">
              <div className="bold mb-1">{order.number}</div>
              <div>
                {order.legalName || `${order.firstName} ${order.lastName}`}
              </div>
              <div>
                {order.dropoffAddress.line1}, {order.dropoffAddress.line2}
              </div>
              <div>
                {order.dropoffAddress.city}, {order.dropoffAddress.state}{' '}
                {order.dropoffAddress.zip}
              </div>
              <div>{order.dateOfBirth && `Check ID: ${order.dateOfBirth}`}</div>

              <div className="mt-3 underline">Contact Info:</div>
              <div className="">{order.clientEmail}</div>
              <div className="">{order.phone}</div>
            </td>
            <td className="pr-4">
              {(order.kits || []).map((kit, j) => (
                <div key={j} className="mb-5">
                  {kit.quantity}x {kit.name}
                  {(kit.kitItems || []).map((kitItem, k) => (
                    <div key={k}>
                      &emsp;{`- ${kitItem.quantity}x ${kitItem.name}`}
                    </div>
                  ))}
                </div>
              ))}
              {!order.kits && 'No kits'}
            </td>
            <td className="pr-4">
              {(order.addons || []).map((addon, j) => (
                <div key={j}>
                  {addon.quantity}x {addon.name}
                </div>
              ))}
              {!order.addons && 'No add ons'}
            </td>
          </tr>
        </table>
      </div>
    )
  }
}

MLBSingleOrderPage.propTypes = {
  order: PropTypes.object,
  gameDay: PropTypes.object,
}

export default MLBSingleOrderPage
