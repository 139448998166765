export const SaveZipGroup =
  ({ RestService, UIService, HandleError }) =>
  async (group) => {
    try {
      if (group.id) {
        await RestService.put(`/pop-up-customers/zip-groups/${group.id}`, group)
      } else {
        await RestService.post('/pop-up-customers/zip-groups', group)
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      `Zip Group ${group.name} successfully saved`,
    )

    return true
  }

export const GetZipGroups =
  ({ RestService, HandleError }) =>
  async () => {
    try {
      return await RestService.get('/pop-up-customers/zip-groups')
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const SaveEmailDistribution =
  ({ RestService, UIService, HandleError }) =>
  async (dist) => {
    try {
      if (dist.id) {
        await RestService.put(`/pop-up-customers/email-dists/${dist.id}`, dist)
      } else {
        await RestService.post('/pop-up-customers/email-dists', dist)
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      `Email Distribution ${dist.name} successfully saved`,
    )

    return true
  }

export const GetEmailDistributions =
  ({ RestService, HandleError }) =>
  async () => {
    try {
      return await RestService.get('/pop-up-customers/email-dists')
    } catch (error) {
      HandleError({ error })

      return []
    }
  }
