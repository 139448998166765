import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import styled from '@emotion/styled'
import { colors } from '../../../constants'

export class OrderDashboard extends Component {
  state = {
    todayOrders: [],
    tmrwOrders: [],
  }

  componentDidMount() {
    ;(async () => {
      const todayOrders = await this.props.loadOrders(Moment())
      const tmrwOrders = await this.props.loadOrders(Moment().add(1, 'day'))
      this.setState({ todayOrders, tmrwOrders })
    })()
  }

  onSelectOrder = (orderId) => {
    this.props.selectOrder(orderId)
  }

  calculateTotal = (orders) => {
    const total = orders.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue.totalRevenue),
      0,
    )

    return total.toLocaleString()
  }

  renderOrder(order) {
    const account =
      order.account && order.account.length > 22
        ? order.account.substring(0, 22) + '...'
        : order.account

    return (
      <DashboardCard
        key={order.id}
        onClick={() => this.onSelectOrder(order.id)}
      >
        <FlexContainer justifyContent="space-between" alignItems="center">
          <p className="dashboard-card-number">{order.orderNumber} </p>
          {order.paid ? (
            <p className="paid">Paid</p>
          ) : (
            <p className="unpaid">Unpaid</p>
          )}
        </FlexContainer>
        <YSpacing height="15px" />
        <div className="dashboard-total">
          ${Number(order.total).toLocaleString(2)}
        </div>
        <div className="dashboard-account">{account}</div>
        <YSpacing height="15px" />
        <p className="dashboard-card-copy">Chef: {order.chef} </p>
        <p className="dashboard-card-copy">Rep: {order.salesRep} </p>
      </DashboardCard>
    )
  }
  //
  render() {
    const { todayOrders, tmrwOrders } = this.state
    const todayTotal = this.calculateTotal(todayOrders)
    const tmrwTotal = this.calculateTotal(tmrwOrders)

    return (
      <div style={{ width: '100%' }}>
        <DashboardTitle>
          Today’s Orders: {todayOrders.length} totaling ${todayTotal} in revenue
        </DashboardTitle>
        <YSpacing height="20px" />
        <DashboardCardContainer>
          {todayOrders.map((order) => this.renderOrder(order))}
        </DashboardCardContainer>
        <YSpacing height="40px" />
        <DashboardTitle>
          Tomorrow’s Orders: {tmrwOrders.length} totaling ${tmrwTotal} in
          revenue
        </DashboardTitle>
        <YSpacing height="20px" />
        <DashboardCardContainer>
          {tmrwOrders.map((order) => this.renderOrder(order))}
        </DashboardCardContainer>
      </div>
    )
  }
}

const DashboardTitle = styled.h2`
  font-family: 'bold';
  font-size: 20px;
  color: ${colors.gray300};
`

const DashboardCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const DashboardCard = styled.div`
  transition: 0.2s ease-in-out all;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  padding: 20px;
  width: 100%;
  max-width: 250px;
  margin-right: 15px;
  margin-bottom: 15px;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 10%),
    0 4px 6px -2px rgb(0 0 0 / 5%);
  &:hover {
    box-shadow:
      0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .dashboard-card-number {
    font-family: 'regular';
    color: ${colors.gray300};
  }
  .dashboard-total {
    text-align: center;
    font-family: 'bold';
    font-size: 24px;
  }
  .dashboard-account {
    text-align: center;
    font-family: 'regular';
    color: ${colors.gray300};
    font-size: 17px;
  }
  .paid,
  .unpaid {
    font-family: 'bold';
    text-transform: uppercase;
    color: #fff;
    padding: 5px 0;
    border-radius: 20px;
    font-size: 10px;
    width: 50px;
    text-align: center;
  }
  .paid {
    background: ${colors.blue300};
  }
  .unpaid {
    background: ${colors.violet};
  }
  .dashboard-card-copy {
    color: ${colors.gray300};
    font-family: 'regular';
    font-size: 14px;
  }
`

OrderDashboard.propTypes = {
  loadOrders: PropTypes.func,
  selectOrder: PropTypes.func,
}

export default OrderDashboard
