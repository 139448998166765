export const pRequestBraintreeNonce = (data) => {
  const { cardNumber, cvv, expirationDate } = data

  return {
    creditCard: {
      number: cardNumber && cardNumber.replace(/\D/g, ''),
      cvv,
      expirationDate,
      options: {
        validate: true,
      },
    },
  }
}
