import { BaseService } from 'hungry-core2'

export class PopUpCustomerService extends BaseService {
  newPopUpCustomer = ({ headquarter }) => ({
    id: undefined,
    headquarter,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    primaryZip: '',
    zipcodes: [],
    locationTracking: false,
    allowNotifications: true,
  })

  newZipGroup = ({ headquarter }) => ({
    id: undefined,
    headquarter,
    name: '',
    zips: [],
  })

  newEmailDistribution = ({ headquarter }) => ({
    id: undefined,
    headquarter,
    name: '',
    zipGroupIds: [],
    zipGroups: [],
  })
}
