import { connect } from 'react-redux'

import { Contacts } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { chef } = state
  const chefContacts = chef ? chef.chefContacts : []
  const chefHqMap = chef
    ? chef.headquarters.reduce((map, hq) => {
        map[hq.id] = hq

        return map
      }, {})
    : {}

  return {
    chefId: chef.id,
    chefHqs: chef.headquarters,
    chefHqMap,
    chefContacts,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, UIService } = services
  const { pRequestChefContacts, pResponseChefContacts } = presenters.Api
  const { EditChefContact, LoadChefContacts, NewChefContact } = coordinators

  const loadChefContacts = LoadChefContacts({
    RestService,
    ChefService,
    pRequestChefContacts,
    pResponseChefContacts,
    HandleError,
  })
  const newChefContact = NewChefContact({ ChefService, UIService })
  const editChefContact = EditChefContact({ ChefService, UIService })

  return {
    editChefContact,
    loadChefContacts,
    newChefContact,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts)
