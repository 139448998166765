import {
  CLEAR_DASHBOARD_ORDERS,
  SET_DASHBOARD_ORDERS,
} from '@actions/dashboard'

const ordersDashboardInitialState = { orders: [] }

export function ordersDashboard(state = ordersDashboardInitialState, action) {
  switch (action.type) {
    case CLEAR_DASHBOARD_ORDERS: {
      return { orders: [] }
    }
    case SET_DASHBOARD_ORDERS: {
      return { orders: action.orders }
    }
    default:
      return state
  }
}
