import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { AutocompleteInput } from '@containers/common/form'
import { OrderSupplyBreakdownTable } from '@components/supply'

export class OrderBreakdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orderNumber: undefined,
      supplyBreakdown: undefined,
    }
  }

  selectOrder = async (order) => {
    const { loadOrderableSupplyBreakdown } = this.props
    const supplyBreakdown = await loadOrderableSupplyBreakdown({
      id: order.id,
      type: 'Order',
    })
    const orderNumber = supplyBreakdown && supplyBreakdown.orderNumber
    this.setState({ supplyBreakdown, orderNumber })
  }

  render() {
    const { orderNumber, supplyBreakdown } = this.state
    const { loadOrders } = this.props

    return (
      <div>
        <br />
        <h3>Description</h3>
        <p>
          Shows supply breakdown for an order. Supply total counts may not
          exactly equal order item totals as order supply totals are rounded up
          and order item totals are not (in order to correctly calculate add on
          usages).
        </p>
        <br />
        <div className="form-field-container">
          <div className="form-field-container__columns">
            <div className="dropdown">
              <AutocompleteInput
                displayAttribute="orderNumber"
                loaderFunction={loadOrders}
                placeholder={'Find an Order'}
                onSelect={this.selectOrder}
              />
            </div>
          </div>
        </div>
        <br />
        {orderNumber && <h2>{orderNumber}</h2>}

        {supplyBreakdown && (
          <OrderSupplyBreakdownTable supplyBreakdown={supplyBreakdown} />
        )}
      </div>
    )
  }
}

OrderBreakdown.propTypes = {
  loadOrders: PropTypes.func,
  loadOrderableSupplyBreakdown: PropTypes.func,
}

export default OrderBreakdown
