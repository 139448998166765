import { connect } from 'react-redux'

import SignIn from '@components/SignIn'

import services from '@services'
import presenters from '@presenters'
import coordinators from '@coordinators'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { headquarter } = state

  return {
    headquarters: headquarter && headquarter.headquarters,
    currentUser: state.user,
  }
}

const mapDispatchToProps = () => {
  const {
    HeadquarterService,
    RestService,
    RouterService,
    SessionService,
    UIService,
  } = services
  const { pResponseGeneric, pResponseUser } = presenters.Api

  const signIn = coordinators.SignIn({
    HeadquarterService,
    RestService,
    RouterService,
    SessionService,
    pResponseUser,
    HandleError,
  })
  const loadHeadquarters = coordinators.LoadHeadquarters({
    HeadquarterService,
    RestService,
    pResponseHeadquarters: pResponseGeneric,
    HandleError,
  })
  const redirect = (pathname) => RouterService.replace({ pathname })
  const displayAlert = ({ message, title }) =>
    UIService.Alert.showPopup({ message, title })

  return {
    displayAlert,
    redirect,
    signIn,
    loadHeadquarters,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
