import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

import { RecurringPopUpModal } from '@containers/popUps'
import { Checkbox, DateInput } from '@components/common/form'
import { rStatuses, dayMap } from './constants'
import Input from '@components/common/form/Input'
import Button from '@components/common/form/Button'
import Dropdown from '@components/common/form/Dropdown'
import LinkText from '@components/common/form/LinkText'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import DividerLine from '@components/common/DividerLine'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'

class RecurringPopUpsPage extends Component {
  state = {
    search: '',
    searchLimit: 20,
    searchOffset: 0,
    showEditModal: false,
    rPopUps: [],
    selectedRecurringPopUp: undefined,
    statuses: [...rStatuses],
    sortBy: [undefined, true],
    rPopUp: undefined,
    timeSlot: undefined,
    date: Moment(),
  }
  saving = false
  searchTimer = undefined

  componentDidMount() {
    this.searchRecurringPopUps()
  }

  searchRecurringPopUps = async () => {
    const { searchRecurringPopUps, headquarter } = this.props
    const { search, searchLimit, searchOffset, statuses } = this.state
    const rPopUps = await searchRecurringPopUps({
      headquarter,
      search,
      statuses,
      limit: searchLimit,
      offset: searchOffset,
    })
    if (rPopUps) {
      this.setState({
        rPopUps,
        rPopUp: undefined,
        timeSlot: undefined,
      })
    }
  }

  searchAfterTimeout = () => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer)
    }
    this.searchTimer = undefined
    this.searchTimer = setTimeout(() => {
      this.searchRecurringPopUps()
    }, 550)
  }

  editRecurringPopUp = (id) => async () => {
    const rp = await this.props.getRecurringPopUp(id)

    if (rp) {
      this.setState({
        selectedRecurringPopUp: rp,
        showEditModal: true,
      })
    }
  }

  hideEditModal = () =>
    this.setState({ showEditModal: false, selectedRecurringPopUp: undefined })

  onToggleStatus = (status) => () => {
    const { statuses } = this.state
    if (statuses.includes(status)) {
      this.setState(
        { statuses: statuses.filter((s) => s !== status) },
        this.searchRecurringPopUps,
      )
    } else {
      this.setState(
        { statuses: [...statuses, status] },
        this.searchRecurringPopUps,
      )
    }
  }

  onCreatePopUp = async () => {
    const { createPopUpFromTimeSlot } = this.props
    const { rPopUp, timeSlot, date } = this.state
    if (!rPopUp || !timeSlot || !date) {
      this.props.displayWarningMessage(
        'Must select recurring pop up, time slot, and date',
      )

      return
    }
    if (this.saving) {
      return
    }
    this.saving = true
    await createPopUpFromTimeSlot(rPopUp, timeSlot, date)
    this.saving = false
  }

  sortRecurringPopUps = (attribute, transform = (a) => a) => {
    const { rPopUps, sortBy } = this.state
    const asc = attribute !== sortBy[0] || !sortBy[1]
    this.setState({
      sortBy: [attribute, asc],
      rPopUps: rPopUps.sort((a, b) => {
        if (transform(a[attribute]) > transform(b[attribute])) {
          return asc ? 1 : -1
        }
        if (transform(a[attribute]) < transform(b[attribute])) {
          return asc ? -1 : 1
        }

        return 0
      }),
    })
  }

  render() {
    const { theme } = this.props
    const {
      rPopUps,
      search,
      searchLimit,
      searchOffset,
      selectedRecurringPopUp,
      showEditModal,
      statuses,
      rPopUp,
      timeSlot,
      date,
    } = this.state

    return (
      <FlexContainer justifyContent="space-between">
        <Panel
          width="100%"
          maxWidth="900px"
          heading="Create & Edit Recurring Pop Ups"
        >
          {showEditModal && (
            <RecurringPopUpModal
              rPopUp={selectedRecurringPopUp}
              theme={theme}
              hideModal={this.hideEditModal}
              searchRecurringPopUps={this.searchRecurringPopUps}
            />
          )}
          <FlexContainer alignItems="flex-end">
            <Input
              label="Search Pop Ups"
              marginBottom="0"
              width="200px"
              type="text"
              value={search}
              onChange={(e) =>
                this.setState(
                  {
                    search: e.target.value,
                    searchOffset: 0,
                  },
                  this.searchAfterTimeout,
                )
              }
            />
            <XSpacing width="20px" />
            <Button
              label="Create New Recurring Pop Up"
              onClick={() =>
                this.setState({
                  showEditModal: true,
                  selectedRecurringPopUp: undefined,
                })
              }
            />
          </FlexContainer>
          <YSpacing height="20px" />
          <FlexContainer alignItems="flex-start">
            {rStatuses.map((status) => (
              <div key={status} style={{ display: 'flex' }}>
                <Checkbox
                  label={`show ${status}`}
                  value={status}
                  checked={statuses.includes(status)}
                  onChange={this.onToggleStatus(status)}
                />
                <XSpacing width="30px" />
              </div>
            ))}
          </FlexContainer>
          <DividerLine margin="20px 0" />
          {/* table */}
          <Table>
            <tr>
              <th onClick={() => this.sortRecurringPopUps('name')}>Name</th>
              <th onClick={() => this.sortRecurringPopUps('status')}>Status</th>
              <th
                onClick={() =>
                  this.sortRecurringPopUps('startDay', (x) => Moment(x))
                }
              >
                Start
              </th>
              <th
                onClick={() =>
                  this.sortRecurringPopUps('client', (client) => client.name)
                }
              >
                Client
              </th>
              <th />
            </tr>
            {rPopUps.map((rPopUp) => (
              <tr key={rPopUp.id}>
                <td>
                  <p>{rPopUp.name}</p>
                </td>
                <td>
                  <p>{rPopUp.status}</p>
                </td>
                <td>
                  <p>{dayMap[rPopUp.startDay]}</p>
                </td>
                <td>
                  <p>{rPopUp.client.name} </p>
                </td>
                <td>
                  <LinkText
                    label="Edit"
                    onClick={this.editRecurringPopUp(rPopUp.id)}
                  />
                </td>
              </tr>
            ))}
          </Table>
          {searchOffset > 0 && (
            <button
              onClick={() =>
                this.setState(
                  { searchOffset: searchOffset - searchLimit },
                  this.searchRecurringPopUps,
                )
              }
            >
              &lt;
            </button>
          )}
          Page {1 + Math.floor(parseFloat(searchOffset / searchLimit))}
          {searchLimit === rPopUps.length && (
            <button
              onClick={() =>
                this.setState(
                  { searchOffset: searchOffset + searchLimit },
                  this.searchRecurringPopUps,
                )
              }
            >
              &gt;
            </button>
          )}
        </Panel>
        <XSpacing width="30px" />
        <Panel maxWidth="300px" heading="Schedule Recurring Pop Ups">
          <Dropdown
            label="Recurring Pop Up"
            value={rPopUp}
            onChange={(e) => this.setState({ rPopUp: e.target.value })}
          >
            <option />
            {rPopUps.map((r) => (
              <option key={r.id} value={r.id}>
                {r.name}
              </option>
            ))}
          </Dropdown>

          <Dropdown
            label="Time Slot"
            value={timeSlot}
            onChange={(e) => this.setState({ timeSlot: e.target.value })}
          >
            <option />
            {rPopUp &&
              rPopUps
                .find((r) => r.id === rPopUp)
                .timeSlots.map((ts) => (
                  <option key={ts.id} value={ts.id}>
                    {ts.name}
                  </option>
                ))}
          </Dropdown>
          <DateInput
            width="100%"
            label="Date"
            date={date}
            dateFormat="default"
            isStatic={false}
            onChange={(date) => this.setState({ date })}
            clearDate={() => this.setState({ date: undefined })}
          />
          <YSpacing height="20px" />
          <Button
            width="100%"
            label="Generate Pop Up"
            onClick={this.onCreatePopUp}
          />
        </Panel>
      </FlexContainer>
    )
  }
}

RecurringPopUpsPage.propTypes = {
  headquarter: PropTypes.number,
  theme: PropTypes.object,

  displayWarningMessage: PropTypes.func,
  getRecurringPopUp: PropTypes.func,
  searchRecurringPopUps: PropTypes.func,
  createPopUpFromTimeSlot: PropTypes.func,
}

export default RecurringPopUpsPage
