import { connect } from 'react-redux'

import { OrderDashboard } from '@components/order'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapDispatchToProps = () => {
  const { OrderService, RestService } = services
  const { pResponseGeneric, pResponseOrder } = presenters.Api
  const { AsyncLoadLandingOrders, SelectOrder } = coordinators

  const loadOrders = AsyncLoadLandingOrders({ RestService, pResponseGeneric })
  const selectOrder = SelectOrder({ OrderService, RestService, pResponseOrder })

  return {
    loadOrders,
    selectOrder,
  }
}

export default connect(undefined, mapDispatchToProps)(OrderDashboard)
