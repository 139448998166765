import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TechHelpForm } from '@components/common'

const ForgotPass = (props) => {
  const [email, setEmail] = useState('')

  const submit = (e) => {
    e.preventDefault()

    props.sendResetPassEmail({ email })
    setEmail('')
  }

  return (
    <div className="login-container">
      <div className="login-container__form">
        <form onSubmit={submit}>
          <input
            type="email"
            name="email"
            placeholder="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required={true}
          />
          <input type="submit" value="Send reset password link" />
        </form>
        <TechHelpForm />
      </div>
    </div>
  )
}

ForgotPass.propTypes = {
  sendResetPassEmail: PropTypes.func,
}

export default ForgotPass
