import { BaseService } from 'hungry-core2'

export class VXInvoiceService extends BaseService {
  newInvoice = () => ({
    id: undefined,
    tip: 0,
    discountAmount: 0,
    discountReason: '',
    totalDue: 0,
    totalPaid: 0,
    isPaid: false,
    isUniversal: true,
    buyer: undefined,
    contactEmail: '',
    contactPhone: '',
    contactName: '',
    payments: [],
  })

  newRemovedPayment = ({ id, paymentForId }) => ({
    id,
    paymentFor: {
      id: paymentForId,
      isInvoiced: false,
    },
  })

  newOrderPayment = ({
    id,
    amount,
    tax,
    tip,
    serviceFee,
    discount,
    paymentForId,
    paymentForOrderNum,
    isInvoiced,
  }) => ({
    id,
    amount,
    discount,
    tax,
    tip,
    serviceFee,
    isPaid: false,
    paymentFor: {
      id: paymentForId,
      isInvoiced,
      orderNumber: paymentForOrderNum,
    },
  })

  newBuyer = ({ id, name, email, phoneNumber, contactName, pin }) => ({
    id,
    name,
    email,
    phoneNumber,
    contactName,
    pin,
  })
}
