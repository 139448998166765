import { BaseService } from 'hungry-core2'

import actions from '@actions'

export default class EditBatchOrder extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditBatchOrderModal({ show: false }))
  }

  getMode = () => {
    return this.getState().editBatchOrderModal.mode
  }

  show = (mode) => {
    this.dispatch(actions.toggleEditBatchOrderModal({ show: true, mode }))
  }
}
