import { connect } from 'react-redux'

import { MarketMaster } from '@components/market'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { markets, hqDetails } = state

  return { markets, hqDetails }
}

const mapDispatchToProps = () => {
  const { MarketService, RestService } = services
  const { pResponseHeadquarters, pResponseMarket } = presenters.Api
  const { LoadMarkets, SelectMarket } = coordinators
  const loadHeadquarters = LoadMarkets({
    MarketService,
    RestService,
    pResponseHeadquarters,
  })
  const selectHeadquarter = SelectMarket({
    MarketService,
    RestService,
    pResponseMarket,
    HandleError,
  })

  return {
    loadHeadquarters,
    selectHeadquarter,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketMaster)
