import * as accountApiPresenters from './account'
import * as accountContactApiPresenters from './accountContact'
import * as accountExecutiveApiPresenters from './cart'
import * as accountsReceivableApiPresenters from './accountsReceivable'
import * as adminUserApiPresenters from './adminUser'
import * as batchOrderApiPresenters from './batchOrder'
import * as braintreeApiPresenters from './braintree'
import * as cartApiPresenters from './cart'
import * as chefApiPresenters from './chef'
import * as chefConceptApiPresenters from './chefConcept'
import * as chefContactApiPresenters from './chefContact'
import * as chefMenuItemApiPresenters from './chefMenuItem'
import * as chefScheduleApiPresenters from './chefSchedule'
import * as chefServiceItemPresenters from './chefServiceItem'
import * as chefSnackPackPresenters from './chefSnackPack'
import * as chefVirtualItemPresenters from './chefVirtualItem'
import * as chefVirtualKitPresenters from './chefVirtualKit'
import * as dashboardApiPresenters from './dashboard'
import * as dinerProfilesApiPresenters from './dinerProfiles'
import * as genericApiPresenters from './generic'
import * as groupOrderPresenters from './groupOrder'
import * as hubspotApiPresenters from './hubspot'
import * as invoiceApiPresenters from './invoice'
import * as marketApiPresenters from './market'
import * as menuItemsSearchPresenters from './menuItemsSearch'
import * as mlbPartnersApiPresenters from './mlbPartners'
import * as orderApiPresenters from './order'
import * as promoCodesApiPresenters from './promoCodes'
import * as popUpApiPresenters from './popUp'
import * as proposalApiPresenters from './proposal'
import * as recipeApiPresenters from './recipe'
import * as reportPresenters from './reports'
import * as salesStatsApiPresenters from './salesStats'
import * as settingsApiPresenters from './settings'
import * as subscriptionApiPresenters from './subscription'
import * as supplyApiPresenters from './supply'
import * as surveyApiPresenters from './survey'
import * as userApiPresenters from './user'
import * as vxApiPresenters from './virtualExperience'
import * as notificationApiPresenters from './notifications'

export default {
  ...accountApiPresenters,
  ...accountContactApiPresenters,
  ...accountExecutiveApiPresenters,
  ...accountsReceivableApiPresenters,
  ...adminUserApiPresenters,
  ...batchOrderApiPresenters,
  ...braintreeApiPresenters,
  ...cartApiPresenters,
  ...chefApiPresenters,
  ...chefServiceItemPresenters,
  ...chefSnackPackPresenters,
  ...chefVirtualItemPresenters,
  ...chefVirtualKitPresenters,
  ...chefConceptApiPresenters,
  ...chefContactApiPresenters,
  ...chefMenuItemApiPresenters,
  ...chefScheduleApiPresenters,
  ...dashboardApiPresenters,
  ...dinerProfilesApiPresenters,
  ...genericApiPresenters,
  ...groupOrderPresenters,
  ...hubspotApiPresenters,
  ...invoiceApiPresenters,
  ...marketApiPresenters,
  ...menuItemsSearchPresenters,
  ...mlbPartnersApiPresenters,
  ...orderApiPresenters,
  ...popUpApiPresenters,
  ...promoCodesApiPresenters,
  ...proposalApiPresenters,
  ...recipeApiPresenters,
  ...reportPresenters,
  ...salesStatsApiPresenters,
  ...settingsApiPresenters,
  ...subscriptionApiPresenters,
  ...supplyApiPresenters,
  ...surveyApiPresenters,
  ...userApiPresenters,
  ...vxApiPresenters,
  ...notificationApiPresenters,
}
