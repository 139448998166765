import { MarketTypes } from '@constants'

export const pToMenuItemVerifications = (menuItem) => {
  const {
    descriptionVerified,
    allergensVerified,
    servingDetailsVerified,
    imageVerified,
    marketType,
  } = menuItem
  const isGroupOrderMenuItem = marketType == MarketTypes.GROUP_ORDER
  const verifications = [
    allergensVerified,
    descriptionVerified,
    servingDetailsVerified,
  ]
  if (isGroupOrderMenuItem) {
    verifications.push(imageVerified)
  }

  return verifications
}
