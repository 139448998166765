import React from 'react'
import PropTypes from 'prop-types'
import { BiPencil } from 'react-icons/bi'

const getStaffingFeeDescription = (config) => {
  if (!config) {
    return 'N/A'
  }
  const staffCount = parseNumber(config.staffCount)
  const hours = parseNumber(config.hours)
  const rate = parseNumber(config.rate)
  const total = calcTotal(config.staffCount, config.hours, config.rate)

  return `${staffCount} staff, ${hours} hours, $${rate.toFixed(
    2,
  )}/hr = $${total.toFixed(2)}`
}

const parseNumber = (rawValue) => {
  const value = parseFloat(rawValue)
  if (
    typeof value !== 'number' ||
    (typeof value === 'number' && isNaN(value))
  ) {
    return null
  }

  return value
}

const calcTotal = (staffCountStr, hoursStr, rateStr) => {
  const staffCount = parseNumber(staffCountStr) || 0
  const hours = parseNumber(hoursStr) || 0
  const rate = parseNumber(rateStr) || 0

  return staffCount * hours * rate
}

const getDescription = (clientGlobalSettings, clientSettingsCollection) => {
  const globalConfig = clientGlobalSettings
    ? clientGlobalSettings.staffingFeeSettings
    : null
  const hasGlobalConfig = Boolean(globalConfig)
  const hasLocationSpecificConfig = clientSettingsCollection.some((cs) =>
    Boolean(cs.staffingFeeSettings),
  )
  if (hasGlobalConfig) {
    return (
      <div>
        <div className="description-label">Set globally to:</div>
        <div className="config-description global-description">
          {getStaffingFeeDescription(globalConfig)}
        </div>
      </div>
    )
  } else if (hasLocationSpecificConfig) {
    return (
      <div>
        <div className="description-label">Set at location level:</div>
        {clientSettingsCollection.map((cs) => {
          return (
            <div key={cs.id} className="location-item">
              <div className="location-name">{cs.name}</div>
              <div className="config-description">
                {getStaffingFeeDescription(cs.staffingFeeSettings)}
              </div>
            </div>
          )
        })}
      </div>
    )
  } else {
    return (
      <div>
        <div className="description-label">N/A</div>
      </div>
    )
  }
}

export const StaffingFeeSettingsSummary = (props) => {
  const { clientGlobalSettings, clientSettingsCollection, onRequestEdit } =
    props
  const description = getDescription(
    clientGlobalSettings,
    clientSettingsCollection,
  )
  const title = 'Client Global Staffing Fee Settings'

  return (
    <div className="staffing-settings-summary">
      <div className="summary-header">
        <h3>{title}</h3>
        <div>
          <button onClick={onRequestEdit} className="edit-button">
            <div className="button-content">
              <BiPencil />
              <div>Edit</div>
            </div>
          </button>
        </div>
      </div>
      <div className="summary-description">{description}</div>
    </div>
  )
}

StaffingFeeSettingsSummary.propTypes = {
  onRequestEdit: PropTypes.func,
  clientGlobalSettings: PropTypes.object,
  clientSettingsCollection: PropTypes.array,
}

export default StaffingFeeSettingsSummary
