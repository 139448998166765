import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import close from '@res/images/x-icon.png'

class Modal extends Component {
  render() {
    const {
      showOverflow,
      title,
      children,
      width,
      hideModal,
      height,
      className,
      innerClassName,
      includePadding = true,
    } = this.props

    return (
      <ModalOuter id="modal" className={className || ''}>
        <ModalInner
          showOverflow={showOverflow}
          width={width}
          height={height}
          className={innerClassName || ''}
        >
          <div className="modal-heading">
            <p>{title}</p>
            {hideModal && (
              <img
                className="modal-close"
                src={close}
                onClick={() => hideModal()}
              />
            )}
          </div>
          {includePadding ? <ModalPadding>{children}</ModalPadding> : children}
        </ModalInner>
      </ModalOuter>
    )
  }
}

const ModalOuter = styled.div`
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 130;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
`

const ModalInner = styled.div`
  background: #fff;
  border-radius: 4px;
  margin: 30px 20px;
  max-width: ${(props) => (props.width ? props.width : '700px')};
  height: ${(props) => (props.height ? props.height : '')};
  width: 100%;
  position: relative;
  overflow-y: ${(props) => (props.showOverflow ? 'visible' : 'auto')};
`

const ModalPadding = styled.div`
  padding: 30px;
`

Modal.propTypes = {
  showOverflow: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  children: PropTypes.object,
  theme: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  includePadding: PropTypes.bool,

  hideModal: PropTypes.func,
}

export default Modal
