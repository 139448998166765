import { connect } from 'react-redux'

import OrderBreakdown from '@components/supply/OrderBreakdown'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapDispatchToProps = () => {
  const { RestService, UIService, OrderService } = services
  const {
    pResponseGeneric,
    pRequestOrdersFilter,
    pRequestOrderSupplyBreakdown,
    pResponseOrders,
  } = presenters.Api
  const { LoadOrders, LoadOrderableSupplyBreakdown } = coordinators

  const loadOrders = LoadOrders({
    UIService,
    OrderService,
    RestService,
    pRequestOrdersFilter,
    pResponseOrders,
  })
  const loadOrderableSupplyBreakdown = LoadOrderableSupplyBreakdown({
    RestService,
    pRequestOrderSupplyBreakdown,
    pResponseOrderSupplyBreakdown: pResponseGeneric,
  })

  return {
    loadOrders,
    loadOrderableSupplyBreakdown,
  }
}

export default connect(undefined, mapDispatchToProps)(OrderBreakdown)
