import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import Modal from '@components/common/modal/Modal'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import { OrderFormSectionTitle } from '@res/styledComponents/index'
import FlexContainer from '@components/common/FlexContainer'
import LinkText from '@components/common/form/LinkText'
import Button from '@components/common/form/Button'
import { colors } from '../../../../constants'
import { TechHelpForm } from '@components/common'
import {
  AccountSection,
  AddressSection,
  PaymentSection,
} from '@containers/order/edit/sections'

import { ChildOrdersSection } from '@containers/batchOrder/edit/sections'
import {
  BatchOrderInfoSection,
  BatchTotalSection,
} from '@components/batchOrder/edit/sections'

const RefreshAttributes = [
  'account',
  'chargeDate',
  'startDate',
  'endDate',
  'netOffset',
  'paymentMethod',
  'purchaseOrderNumber',
  'billingAddress',

  // put in state but don't mutate
  'accountExecutive',
  'chefAmount',
  'contact',
  'id',
  'orderNumber',
  'originalPaymentMethod',
  'originalChildOrderIds', // store original item list to compare for deletions
  'subtotal',
  'serviceFee',
  'tax',
  'tip',
  'total',
]
const RefreshArrayAttributes = ['childOrders']

const InitialState = {
  account: undefined,
  accountExecutive: undefined,
  billingAddress: undefined,

  contact: undefined,

  paymentMethod: undefined,

  chargeDate: undefined,
  startDate: undefined,
  endDate: undefined,
  netOffset: undefined,
  purchaseOrderNumber: undefined,

  childOrders: [],
  subtotal: 0,
  total: 0,
  serviceFee: 0,
  tip: 0,
  taxRates: {},

  newAccount: {},
  newBillingAddress: {},
  newPaymentMethod: {},
  newContact: {},

  didEditChildOrders: false,
  didModify: false,
}

class EditBatchOrderModal extends Component {
  state = InitialState

  componentDidMount() {
    this.props.clearErrors()
    const newState = {}
    const { childOrders } = this.props
    RefreshAttributes.forEach((key) => {
      newState[key] = this.props[key]
    })
    RefreshArrayAttributes.forEach((key) => {
      newState[key] = this.props[key] || []
    })
    newState.didEditServiceFee = !!this.props.id
    newState.originalChildOrderIds = childOrders && childOrders.map((o) => o.id)
    newState.originalPaymentMethodId =
      this.props.paymentMethod && this.props.paymentMethod.id
    this.setState(newState)
  }

  /* set state will setting a did modify flag */
  updateState = (newState) => {
    const { clearError, delayedUpdateBatchOrder } = this.props
    for (const key in newState) {
      clearError(key)
    }
    newState.didModify = true

    this.setState(newState, () => {
      delayedUpdateBatchOrder(this.state)
    })
  }

  onChange = (newState) => {
    this.updateState(newState)
  }

  onChangeAddress = (newState) => {
    this.setState({
      billingAddress: newState.dropoffAddress,
      newBillingAddress: newState.newDropoffAddress,
    })
  }

  onChangePaymentMethod = (pmState) => {
    const { account } = this.state
    const { addresses } = account || {}
    const notAccountAddress =
      addresses &&
      !addresses.some((address) => address.id === pmState.billingAddressId)
    if (pmState.billingAddress && notAccountAddress) {
      pmState.account = {
        ...account,
        addresses: addresses.concat([pmState.billingAddress]),
      }
    }
    this.updateState(pmState)
  }

  onClearForm = () => {
    const { clearErrors, clearForm, delayedUpdateBatchOrder } = this.props

    this.setState({ ...InitialState })
    clearErrors()
    delayedUpdateBatchOrder({})
    clearForm()
  }

  onHide = () => {
    this.props.close()
  }

  onRemoveChildOrder = (order) => () => {
    let { childOrders } = this.state
    childOrders = this.props.removeChildOrderFromOrders({ order, childOrders })
    const { contact, accountExecutive } =
      this.props.recalculateContactAndAccountExec(childOrders)
    const newTotals = this.props.calculateAllTotals(childOrders)
    this.updateState({ childOrders, contact, accountExecutive, ...newTotals })
  }

  onSave = async () => {
    const { loadBatchOrders, saveBatchOrder } = this.props
    await saveBatchOrder(this.state)
    loadBatchOrders()
  }

  onSaveAccount = () => {
    ;(async () => {
      const account = await this.props.saveAccount({
        data: this.state,
        namespace: 'editBatchOrderModal',
      })
      const newState = { account, newAccount: {} }
      this.updateState(newState)
    })()
  }

  onSaveAddress = async () => {
    const { saveAddress, validateAddress } = this.props
    let { account, newBillingAddress: address } = this.state

    if (validateAddress(address)) {
      ;({ account, address } = await saveAddress({ account, address }))
      this.updateState({
        account,
        billingAddress: address,
        newBillingAddress: {},
      })

      return true
    }

    return false
  }

  onSaveContact = async () => {
    const { saveContact, validateContact } = this.props
    let { account, newContact: contact } = this.state

    if (validateContact(contact)) {
      ;({ account, contact } = await saveContact({
        account,
        contact,
        namespace: 'editBatchOrderModal',
      }))
      if (!account && !contact) {
        return
      }

      const newState = {
        account,
        newContact: {},
      }
      this.updateState(newState)

      return true
    }

    return false
  }

  onSavePaymentMethod = async () => {
    const { savePaymentMethod, validatePaymentMethod } = this.props
    let { account, newPaymentMethod: paymentMethod } = this.state

    if (validatePaymentMethod(paymentMethod)) {
      ;({ account, paymentMethod } = await savePaymentMethod({
        account,
        paymentMethod,
        namespace: 'editBatchOrderModal',
      }))
      this.updateState({
        account,
        paymentMethod,
        newPaymentMethod: {},
      })

      return true
    }

    return false
  }

  onSelectAccount = (account) => {
    ;(async () => {
      const { loadAccount, pDefaultAccountFields } = this.props
      if (account) {
        this.updateState({ newAccount: {} })

        if (account.id) {
          account = await loadAccount(account.id)
        }
        const newState = pDefaultAccountFields(account)
        this.updateState(newState)
      } else {
        const newState = { account }
        this.updateState(newState)
      }
    })()
  }

  onSelectChildOrder = (order) => {
    let { childOrders } = this.state
    childOrders = this.props.addOrderToChildOrders({ order, childOrders })

    const { contact, accountExecutive } =
      this.props.recalculateContactAndAccountExec(childOrders)
    const newTotals = this.props.calculateAllTotals({
      orders: childOrders,
      didEditOrders: true,
    })
    this.updateState({ childOrders, contact, accountExecutive, ...newTotals })
  }

  render() {
    const {
      id,
      isRecurring,

      errors,
      clearError,
      calculateAllTotals,
      loadAccountExecutives,
    } = this.props
    const {
      account,
      accountExecutive,
      billingAddress,

      contact,
      newContact,

      chargeDate,
      startDate,
      endDate,
      orderNumber,
      purchaseOrderNumber,
      netOffset,
      serviceFee,
      subtotal,
      tax,
      tip,
      total,

      paymentMethod,

      childOrders,

      newBillingAddress,
      newPaymentMethod,
    } = this.state
    const { addresses, contacts, paymentMethods } = account || {}
    const hasAccount = !!account

    const orderTypeTitle = isRecurring
      ? `Recurring ${netOffset} Order`
      : 'Multi Order'

    return (
      <Modal
        title={
          id ? `Edit ${orderTypeTitle} ${orderNumber}` : `New ${orderTypeTitle}`
        }
        hideModal={this.onHide}
        color="#001940"
        width="850px"
      >
        {/*<button className="clear-form"
          onClick={ this.onClearForm }
        >
            ✕ Clear Order Form
        </button>*/}
        <OrderFormSectionTitle>
          <span>1</span>Client & Payment Details
        </OrderFormSectionTitle>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <AccountSection
            account={account}
            onChange={this.onChange}
            onSelectAccount={this.onSelectAccount}
            onSave={this.onSaveAccount}
            width="31%"
          />
          <PaymentSection
            paymentMethod={paymentMethod}
            paymentMethods={paymentMethods}
            errors={errors}
            hasAccount={hasAccount}
            newPaymentMethod={newPaymentMethod}
            onChange={this.onChangePaymentMethod}
            onSave={this.onSavePaymentMethod}
            clearError={clearError}
            width="31%"
          />
          <AddressSection
            addresses={addresses}
            label={'Billing Address'}
            dropoffAddress={billingAddress}
            errors={errors}
            hasAccount={hasAccount}
            newDropoffAddress={newBillingAddress}
            onChange={this.onChangeAddress}
            onSave={this.onSaveAddress}
            clearError={clearError}
            width="31%"
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <BatchOrderInfoSection
          accountExecutive={accountExecutive}
          chargeDate={chargeDate}
          startDate={startDate}
          endDate={endDate}
          netOffset={netOffset}
          contact={contact}
          contacts={contacts}
          newContact={newContact}
          loadAccountExecutives={loadAccountExecutives(false)}
          purchaseOrderNumber={purchaseOrderNumber}
          errors={errors}
          hasAccount={hasAccount}
          onChange={this.onChange}
          onSaveContact={this.onSaveContact}
        />

        {account && (
          <div>
            <YSpacing height="40px" />
            <OrderFormSectionTitle>
              <span>2</span>Bundle Orders
            </OrderFormSectionTitle>
            <YSpacing height="20px" />
            <ChildOrdersSection
              account={account}
              childOrders={childOrders}
              endDate={endDate}
              startDate={startDate}
              onSelectChildOrder={this.onSelectChildOrder}
              onClickOrderNumber={this.onClickOrderNumber}
              onRemoveChildOrder={this.onRemoveChildOrder}
            />
            <div className="total-section p-r-20">
              <div className="total-section__inner">
                <BatchTotalSection
                  calculateAllTotals={calculateAllTotals}
                  childOrders={childOrders}
                  serviceFee={serviceFee}
                  subtotal={subtotal}
                  tax={tax}
                  tip={tip}
                  total={total}
                />
                <YSpacing height="20px" />
                <FlexContainer alignItems="center" justifyContent="flex-end">
                  <TechHelpForm margin="0 30px 0 0" />
                  <LinkText
                    testId="cancel"
                    label="Cancel"
                    onClick={this.onHide}
                    color={colors.violet}
                  />
                  <XSpacing width="20px" />
                  <Button
                    testId="save-order"
                    label="Save Order"
                    onClick={this.onSave}
                  />
                </FlexContainer>
              </div>
            </div>
          </div>
        )}
      </Modal>
    )
  }
}

EditBatchOrderModal.propTypes = {
  id: PropTypes.string,
  account: PropTypes.object,
  accountExecutive: PropTypes.object,
  billingAddress: PropTypes.object,

  contact: PropTypes.object,

  paymentMethod: PropTypes.object,

  chargeDate: PropTypes.instanceOf(Moment),
  startDate: PropTypes.instanceOf(Moment),
  endDate: PropTypes.instanceOf(Moment),
  netOffset: PropTypes.number,
  isRecurring: PropTypes.bool,

  childOrders: PropTypes.arrayOf(PropTypes.object),
  orderNumber: PropTypes.string,
  purchaseOrderNumber: PropTypes.string,
  serviceFee: PropTypes.number,
  subtotal: PropTypes.number,
  tax: PropTypes.number,
  tip: PropTypes.number,
  total: PropTypes.number,

  ordersPaymentMethods: PropTypes.object,
  originalChildOrderIds: PropTypes.arrayOf(PropTypes.string),

  errors: PropTypes.object,
  show: PropTypes.bool,

  addOrderToChildOrders: PropTypes.func,
  calculateAllTotals: PropTypes.func,
  clearError: PropTypes.func,
  clearErrors: PropTypes.func,
  clearForm: PropTypes.func,
  close: PropTypes.func,
  delayedUpdateBatchOrder: PropTypes.func,
  loadAccount: PropTypes.func,
  loadAccountExecutives: PropTypes.func,
  loadBatchOrders: PropTypes.func,
  pDefaultAccountFields: PropTypes.func,
  recalculateContactAndAccountExec: PropTypes.func,
  removeChildOrderFromOrders: PropTypes.func,
  saveAccount: PropTypes.func,
  saveAddress: PropTypes.func,
  saveContact: PropTypes.func,
  savePaymentMethod: PropTypes.func,
  saveBatchOrder: PropTypes.func,
  validateAddress: PropTypes.func,
  validateContact: PropTypes.func,
  validatePaymentMethod: PropTypes.func,
}

EditBatchOrderModal.defaultProps = {
  originalChildOrderIds: [],
}

export default EditBatchOrderModal
