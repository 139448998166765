import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/common/modal/Modal'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import LinkText from '@components/common/form/LinkText'
import Button from '@components/common/form/Button'
import Input from '@components/common/form/Input'
import { colors } from '../../../../constants'
import { Checkbox, CurrencyInput, TextArea } from '@components/common/form'
import { Label } from '@res/styledComponents/index'
import { AuthorizedInteractable } from '@containers/common/auth'

const virtualItemInitialState = {
  price: 0.0,
  marketPrice: 0.0,
}

class EditVirtualItemModal extends Component {
  state = { ...virtualItemInitialState }

  componentWillMount() {
    const { virtualItem, clearErrors } = this.props
    this.setState({ ...virtualItem })
    clearErrors()
  }

  componentWillReceiveProps(nextProps) {
    const { virtualItem } = nextProps
    if (this.props.virtualItem !== nextProps.virtualItem) {
      const newState = { ...virtualItem }
      this.setState(newState)
    }
  }

  delayedUpdate = () => {
    this.props.delayedUpdateVirtualItem(this.state)
  }

  /* Events */

  onChange = (key) => (e) => {
    const { value } = e.target
    this.setState({ [key]: value }, this.delayedUpdate)
  }

  onChangeVirtualItem = (key, value) => {
    this.setState({ [key]: value }, () =>
      this.props.delayedUpdateVirtualItem(this.state),
    )
  }

  onChangeRadioButton = (e) => {
    const { value: key, name } = e.target
    this.setState({ [name]: key }, this.delayedUpdate)
  }

  onChangeCheckbox = (e) => {
    const { checked, value: key } = e.target
    this.setState({ [key]: checked }, this.delayedUpdate)
  }

  onDelete = () => {
    const { close, deleteVirtualItem } = this.props
    const { id, chefId, name } = this.state
    deleteVirtualItem({ id, chefId, name })
    close()
  }

  onHide = () => {
    this.props.close()
  }

  onSave = () => {
    this.props.saveVirtualItem(this.state)
  }

  render() {
    const { errors, show } = this.props
    const { cost, description, id, isEnabled, isApproved, name, price } =
      this.state
    const title = id ? 'Edit Virtual Item' : 'New Virtual Item'

    return (
      <Modal
        show={show}
        title={title}
        hideModal={this.onHide}
        color="#001940"
        width="800px"
      >
        <FlexContainer justifyContent="space-between" flexWrap="wrap">
          <Input
            width="31%"
            label="Virtual Item Name"
            value={name}
            error={errors.name}
            onChange={this.onChange('name')}
          />
          <CurrencyInput
            width="31%"
            label="Vendor Price"
            value={cost}
            onChange={(value) => this.onChangeVirtualItem('cost', value)}
          />
          <CurrencyInput
            width="31%"
            label="Market Price"
            value={price}
            onChange={(value) => this.onChangeVirtualItem('price', value)}
          />
          <TextArea
            width="31%"
            height="100px"
            label="Description"
            value={description}
            onInput={this.onChange('description')}
          />
          <FlexContainer width="31%" flexDirection="column">
            <Label>Status</Label>
            <Checkbox
              label="Live"
              value="isEnabled"
              marginBottom="5px"
              checked={isEnabled}
              onChange={this.onChangeCheckbox}
            />
            <Checkbox
              label="Approved"
              value="isApproved"
              checked={isApproved}
              onChange={this.onChangeCheckbox}
            />
          </FlexContainer>
          <FlexContainer width="31%" />
        </FlexContainer>
        <FlexContainer alignItems="center" justifyContent="flex-end">
          <LinkText
            color={colors.gray300}
            onClick={this.onHide}
            label="Cancel"
          />
          {id && (
            <AuthorizedInteractable
              roles={['master admin', 'sales lead', 'chef lead']}
            >
              <LinkText
                color={colors.violet}
                onClick={this.onDelete}
                label="Delete"
              />
            </AuthorizedInteractable>
          )}
          <XSpacing width="20px" />
          <AuthorizedInteractable
            roles={['master admin', 'sales lead', 'chef lead']}
          >
            <Button onClick={this.onSave} label="Save" />
          </AuthorizedInteractable>
        </FlexContainer>
      </Modal>
    )
  }
}

EditVirtualItemModal.propTypes = {
  errors: PropTypes.object,
  virtualItem: PropTypes.object,
  show: PropTypes.bool,

  clearErrors: PropTypes.func,
  close: PropTypes.func,
  delayedUpdateVirtualItem: PropTypes.func,
  deleteVirtualItem: PropTypes.func,
  displayAlert: PropTypes.func,
  saveVirtualItem: PropTypes.func,
}

export default EditVirtualItemModal
