import React from 'react'
import PropTypes from 'prop-types'

const YSpacing = (props) => {
  const { height } = props

  return (
    <div
      style={{
        height,
        minHeight: height,
        width: '100%',
      }}
    />
  )
}

YSpacing.propTypes = {
  height: PropTypes.string,
}

export default YSpacing
