import Moment from 'moment-timezone'

import { camelCaseify, formAdd } from '~/utils'
import { getOrderBals } from '@utils'

export const pRequestUpdateInvoice = (data) => {
  const req = {}

  formAdd(data, req, 'invoice', 'invoice')
  formAdd(data, req, 'payments', 'payments')
  formAdd(data, req, 'removedPayments', 'removedPayments')
  formAdd(data, req, 'updatedTransactions', 'updatedTransactions')

  const buyer = {}
  formAdd(data.invoice.buyer, buyer, 'id', 'id')
  formAdd(data.invoice.buyer, buyer, 'pin', 'pin')
  formAdd(data.invoice.buyer, buyer, 'name', 'name')
  formAdd(data.invoice.buyer, buyer, 'contactName', 'contactName')
  formAdd(data.invoice.buyer, buyer, 'email', 'email')
  formAdd(data.invoice.buyer, buyer, 'phoneNumber', 'phoneNumber')
  formAdd(data.invoice.buyer, buyer, 'firstOrderDate', 'firstOrderDate', (v) =>
    Moment(v).format(),
  )
  formAdd(data.invoice.buyer, buyer, 'statementOfWork', 'statementOfWork')
  req.invoice.buyer = buyer

  return req
}

export const pResponseInvoice = (json) => {
  const invoice = camelCaseify(json)
  const { billingLine1, billingLine2, billingCity, billingState, billingZip } =
    invoice

  let fullBillingAddress = '' + (billingLine1 || '')
  if (billingLine2 && billingLine2 != '') {
    fullBillingAddress = fullBillingAddress + ' ' + billingLine2
  }
  if (billingCity && billingCity != '') {
    fullBillingAddress = fullBillingAddress + ', ' + billingCity
  }
  if (billingState && billingState != '') {
    fullBillingAddress = fullBillingAddress + ', ' + billingState
    if (billingZip && billingZip != '') {
      fullBillingAddress = fullBillingAddress + ' ' + billingZip
    }
  }

  return { ...invoice, fullBillingAddress }
}

export const pResponseInvoices = (json) => {
  const resp = camelCaseify(json)

  return resp.invoices.map((invoice) => {
    const initialPmt = (invoice.payments && invoice.payments[0]) || {}
    if (initialPmt && initialPmt.paymentFor) {
      let earliest = Moment(initialPmt && initialPmt.paymentFor.date)
      let latest = Moment(initialPmt && initialPmt.paymentFor.date)
      invoice.payments &&
        invoice.payments.forEach((payment) => {
          const { paymentFor } = payment || {}
          if (paymentFor) {
            const orderDate = Moment(paymentFor.date)
            if (orderDate.isBefore(earliest)) {
              earliest = orderDate
            }
            if (orderDate.isAfter(latest)) {
              latest = orderDate
            }
          }
        })

      const duration = `${earliest.format('MMM Do YYYY')} - ${latest.format(
        'MMM Do YYYY',
      )}`
      invoice.duration = duration
      invoice.effectiveDate = invoice.effectiveDateOverride || invoice.createdAt
    }

    return {
      ...invoice,
    }
  })
}

export const pResponseClientOutstandingOrders = (json) => {
  const orders = (json || []).sort((a, b) => {
    return Moment(a.date).isBefore(Moment(b.date)) ? -1 : 1
  })
  const now = Moment()

  return orders.map((order) => {
    const { total } = getOrderBals(order)
    let totalPaid = 0.0
    let totalAutoInvoiced = 0.0
    const isPastEffDate = now.isAfter(Moment(order.effectiveDate))
    const payments = order.orderPayments || []
    payments.forEach((pmt) => {
      const isAuto = pmt.paymentInvoice?.isAutoInv || false
      if (pmt.isPaid) {
        totalPaid += pmt.amount
      }
      if (pmt.isPaid || isAuto) {
        totalAutoInvoiced += pmt.amount
      }
    })

    const unpaidAmount = total - totalPaid
    const nextAutoInvAmount =
      total - totalAutoInvoiced < 0 ? 0 : total - totalAutoInvoiced

    return {
      ...order,
      isPastEffDate,
      unpaidAmount: unpaidAmount.toFixed(2),
      totalAutoInvoiced,
      nextAutoInvAmount: isPastEffDate ? nextAutoInvAmount.toFixed(2) : '0.00',
    }
  })
}
