export const EditServiceItem =
  ({ ChefService, RestService, UIService, pResponseServiceItem }) =>
  async (serviceItemId) => {
    const serviceItem = await RestService.get(
      `/api/admin/service_items/${serviceItemId}`,
    )
    ChefService.setEditServiceItem(pResponseServiceItem(serviceItem))
    UIService.EditServiceItem.show()
  }

export const NewServiceItem =
  ({ ChefService, UIService, pDefaultServiceFields }) =>
  () => {
    const { chef, editServiceItem } = ChefService.getState()
    if (editServiceItem.id) {
      ChefService.clearEditServiceItem()
    }

    if (chef) {
      ChefService.setEditServiceItem(pDefaultServiceFields(chef))
      UIService.EditServiceItem.show()
    }
  }

export const LoadChefServiceItems =
  ({ ChefService, RestService, pResponseServiceItems }) =>
  async (chefId) => {
    const params = {
      include_all: true,
      chef_ids: [chefId],
    }
    const response = await RestService.get('/api/admin/service_items', params, {
      timeout: 20000,
    })
    const serviceItems = pResponseServiceItems(response)
    ChefService.setChefServiceItems(serviceItems)
  }

export const LoadPopUpServiceItems =
  ({ RestService, pResponsePopUpServiceItems }) =>
  async (chefId) => {
    const params = {
      include_all: true,
      chef_ids: [chefId],
    }

    const response = await RestService.get('/api/admin/service_items', params, {
      timeout: 20000,
    })

    return pResponsePopUpServiceItems(response)
  }

export const DelayedUpdateServiceItem =
  ({ ChefService, UIService }) =>
  (serviceItem) => {
    const call = () => ChefService.setEditServiceItem(serviceItem)

    UIService.Timer.callAfterTimeout(call)
  }

export const SaveServiceItem =
  ({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateServiceItem,
    pRequestUpdateItemImages,
    pResponseServiceItems,
    HandleError,
  }) =>
  async (data) => {
    try {
      UIService.Errors.clear()
      const req = pRequestUpdateServiceItem(data)
      const headers = { 'Content-Type': 'multipart/form-data' }

      let serviceItem = undefined
      if (data.id) {
        serviceItem = await RestService.put(
          `/api/admin/service_items/${data.id}`,
          req,
          headers,
        )
      } else {
        serviceItem = await RestService.post(
          '/api/admin/service_items',
          req,
          headers,
        )
      }

      const imageUpdateReq = pRequestUpdateItemImages(data, 'service_item')
      if (serviceItem && serviceItem.id && imageUpdateReq) {
        serviceItem = await RestService.put(
          `/api/admin/service_items/${serviceItem.id}`,
          imageUpdateReq,
          headers,
        )
      }

      UIService.EditServiceItem.close()
      ChefService.clearEditServiceItem()

      LoadChefServiceItems({ ChefService, RestService, pResponseServiceItems })(
        serviceItem.chef_id,
      )
    } catch (error) {
      HandleError({ error, namespace: 'serviceItemModal' })
    }
  }

export const DeleteServiceItem =
  ({
    ChefService,
    RestService,
    UIService,
    pResponseChef,
    HandleError,
    SelectChef,
  }) =>
  async ({ id, chefId, name }) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete service item "${name}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/api/admin/service_items/${id}`)
        ChefService.clearEditServiceItem()
        SelectChef({ ChefService, RestService, pResponseChef })(chefId)
        UIService.EditServiceItem.close()
      } catch (error) {
        HandleError({ error })
      }
    }
  }
