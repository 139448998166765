import { connect } from 'react-redux'

import DashboardPage from '@components/dashboard/DashboardPage'

import presenters from '@presenters'

const mapStateToProps = (state) => {
  const { pProcessDashboardParams } = presenters
  const { router } = state

  const { tab, accountName, salesRepId } = pProcessDashboardParams(
    router.location.pathname,
    router.location.search,
  )

  return { tab, accountName, salesRepId }
}

export default connect(mapStateToProps, undefined)(DashboardPage)
