import React from 'react'
import PropTypes from 'prop-types'

class StatusDropdown extends React.Component {
  render() {
    const { order, attribute, key, orderUpdates, updateOrder } = this.props
    let { className } = this.props
    const orderUpdate = orderUpdates[order.id]
    const originalorder = order[attribute]
    const revisionorder = orderUpdate && orderUpdate[attribute]
    const value = revisionorder || originalorder

    if (revisionorder) {
      className = 'updated-input'
    }

    return (
      <td key={key}>
        <select
          onChange={(newVal) =>
            updateOrder(order.id, attribute, newVal.target.value)
          }
          value={value}
          className={className}
        >
          <optgroup>
            {['created', 'delivered', 'canceled'].map((o) => {
              return <option key={o}>{o}</option>
            })}
          </optgroup>
        </select>
      </td>
    )
  }
}

StatusDropdown.propTypes = {
  order: PropTypes.object,
  attribute: PropTypes.string,
  className: PropTypes.string,
  key: PropTypes.string,
  orderUpdates: PropTypes.arrayOf(PropTypes.object),

  updateOrder: PropTypes.func,
}

export default StatusDropdown
