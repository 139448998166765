import {
  SET_DINER_PROFILES,
  SET_DINER_PROFILES_IN_PROFILES,
} from '@actions/dinerProfiles'

import { DinerProfile } from '@types'
import { AnyAction } from 'redux'

const initialState: DinerProfile[] = []

export const dinerProfiles = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_DINER_PROFILES:
      return action.payload
    case SET_DINER_PROFILES_IN_PROFILES: {
      const updates = action.payload as DinerProfile[]
      const updated = state.slice()

      updates.forEach((update) => {
        const index = updated.findIndex((profile) => profile.id === update.id)
        if (index !== -1) {
          updated[index] = update
        }
      })

      return updated
    }
    default:
      return state
  }
}
