import Moment from 'moment-timezone'

export const pRequestRemoveEmptyFields = (request) => {
  const nullify = (obj) => {
    for (const key in obj) {
      if (obj[key] === '') {
        obj[key] = null
      } else if (typeof obj[key] == 'object' && !Date.parse(obj[key])) {
        obj[key] = nullify(obj[key])
      }
    }

    return obj
  }

  return nullify(request)
}

export const pResponseMLBPartner = (partner) => {
  partner.deliveryFee = parseFloat(partner.deliveryFee)
  partner.calendarMap = {}
  partner.calendar = partner.calendar.map((c) => {
    const { date, cutoff } = c
    const mDate = Moment(date).tz(partner.locale)
    const mCutoff = Moment(cutoff).tz(partner.locale)
    partner.calendarMap[mDate.format('ddd MMM DD YYYY')] = true

    return { date: mDate, cutoff: mCutoff }
  })

  return partner
}

export const pMBLOrdersCSV = (orders) => {
  return orders.map((order) => {
    let name = order.legalName || `${order.firstName} ${order.lastName}`
    if (order.dateOfBirth) {
      name += ` Check ID: ${order.dateOfBirth}`
    }
    const address = `${order.dropoffAddress.line1}, ${order.dropoffAddress.line2},
      ${order.dropoffAddress.city}, ${order.dropoffAddress.state}, ${order.dropoffAddress.zip}`
    const kits = (order.kits || [])
      .map((kit) => {
        return (
          `${kit.quantity}x ${kit.name}\n` +
          (kit.kitItems || [])
            .map((kitItem) => `    -${kitItem.quantity}x ${kitItem.name}`)
            .join('\n')
        )
      })
      .join('\n')
    const addons = (order.addons || [])
      .map((addon) => `${addon.quantity}x ${addon.name}`)
      .join('\n')

    return {
      number: order.number,
      name,
      address,
      kits,
      addons,
    }
  })
}
export const pMBLWorkwaveCSV = (orders) => {
  return orders.map((order) => ({
    CONTACT_ID: '',
    ORDER_ID: order.number,
    ORDER_DELIVERY_INSTRUCTIONS: order.instructions,
    CLIENT_LAST_NAME: order.lastName,
    CLIENT_FIRST_NAME: order.firstName,
    CLIENT_ADDRESS_ONE: order.dropoffAddress.line1,
    CLIENT_ADDRESS_TWO: order.dropoffAddress.line2,
    CLIENT_DEVELOPMENT_NAME: '',
    CLIENT_BOROUGH: '',
    CLIENT_ZIP: order.dropoffAddress.zip,
    CLIENT_PHONE_1: order.phone,
    CLIENT_PHONE_2: '',
    CLIENT_PHONE_3: '',
    VERIFY_DOB: order.dateOfBirth
      ? `Legal Name: ${order.legalName}\nCheck ID: ${order.dateOfBirth}`
      : '',
  }))
}
