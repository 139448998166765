export const DISPLAY_RESULTS_MESSAGE = 'DISPLAY_RESULTS_MESSAGE'
export const DISMISS_RESULTS_MESSAGE = 'DISMISS_RESULTS_MESSAGE'

export const displayResultsMessageModal = ({ show, successes, errors }) => {
  return {
    type: DISPLAY_RESULTS_MESSAGE,
    show,
    successes,
    errors,
  }
}

export const dismissResultsMessageModal = (show = false) => {
  return {
    type: DISMISS_RESULTS_MESSAGE,
    show,
  }
}
