import { connect } from 'react-redux'

import { BulkGenProposalsFromDinerProfile } from '@components/proposal/proposalSections'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'
import { config } from 'hungry-core2'

const mapStateToProps = (state) => {
  const { router, user } = state
  const { id } = user
  const port = router.location.port === 3000 ? 3001 : 3000
  const uri =
    config.admin_url +
    (config.admin_url === 'http://localhost' ? `:${port}` : '')

  const {
    headquarter: { headquarters },
  } = state
  const hqLocaleMap = headquarters.reduce((acc, hq) => {
    acc[hq.id] = hq.locale

    return acc
  }, {})

  return {
    hqLocaleMap,
    accountExecutiveId: id,
    uri,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService } = services
  const { ProposalsFromDinerProfile } = coordinators
  const { pRequestProposalFromDinerProfile, pResponseProposal } = presenters.Api
  const pResponseError = presenters.Errors.responseErrorMessage

  const generateBulkProposals = ProposalsFromDinerProfile({
    RestService,
    pRequestProposalFromDinerProfile,
    pResponseProposal,
    HandleError,
    pResponseError,
  })
  const { ConfirmationModal } = UIService

  return {
    confirmationModal: ConfirmationModal,
    generateBulkProposals,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BulkGenProposalsFromDinerProfile)
