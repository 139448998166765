import React from 'react'
import PropTypes from 'prop-types'

const FlexContainer = (props) => {
  const {
    flexDirection,
    flexWrap,
    alignItems,
    justifyContent,
    width,
    children,
    background,
    padding,
    height,
    overflow,
    maxHeight,
    margin,
    marginLeft,
    minWidth,
  } = props

  const style = {
    display: 'flex',
    flexDirection,
    flexWrap,
    alignItems,
    justifyContent,
    width,
    background,
    padding,
    margin,
  }
  if (height) {
    style.height = height
  }
  if (overflow) {
    style.overflow = overflow
  }
  if (marginLeft) {
    style.marginLeft = marginLeft
  }
  if (minWidth) {
    style.minWidth = minWidth
  }
  if (maxHeight) {
    style.maxHeight = maxHeight
  }

  return <div style={style}>{children}</div>
}

FlexContainer.propTypes = {
  children: PropTypes.node,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  flexDirection: PropTypes.string,
  flexWrap: PropTypes.string,
  width: PropTypes.string,
  background: PropTypes.string,
  padding: PropTypes.string,
  height: PropTypes.string,
  overflow: PropTypes.string,
  margin: PropTypes.string,
  marginLeft: PropTypes.string,
  marginTop: PropTypes.string,
  maxHeight: PropTypes.string,
  minWidth: PropTypes.string,
}

FlexContainer.defaultProps = {
  width: '100%',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  padding: '0px 0px 0px 0px',
}

export default FlexContainer
