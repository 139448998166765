import { connect } from 'react-redux'

import { ProposalDetail } from '@components/proposal'

const mapStateToProps = (state) => {
  const { proposal } = state

  return { proposal }
}

export default connect(mapStateToProps)(ProposalDetail)
