import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { MasterDetail } from '@components/common'

import { OrderDashboard } from '@containers/order'

class OrderablePage extends Component {
  componentDidMount() {
    // check for specific order in query string
    this.props.checkQueryOrderable()
  }

  render() {
    const {
      initialFilter,
      filters,
      orderableDetail,
      orderableMaster,
      orderableType,
      orderableNumber,
      renderDashboard,
      newOrderable,
    } = this.props
    const defaultTitle = orderableType == 'Order' ? 'Sales' : 'Proposals'

    return (
      <MasterDetail
        className={'orderable'}
        dashboard={renderDashboard && !orderableNumber && <OrderDashboard />}
        showDashboard={renderDashboard && !orderableNumber}
        detail={orderableDetail}
        master={orderableMaster}
        initialFilter={initialFilter}
        filters={filters}
        onNew={newOrderable}
        onNewAuthRoles={[
          'master admin',
          'sales rep',
          'sales lead',
          'chef lead',
        ]}
        onNewLabel={`New ${orderableType}`}
        title={defaultTitle}
      />
    )
  }
}

OrderablePage.propTypes = {
  initialFilter: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.string),
  orderableDetail: PropTypes.node,
  orderableMaster: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  orderableNumber: PropTypes.string,
  orderableType: PropTypes.string,
  renderDashboard: PropTypes.bool,

  checkQueryOrderable: PropTypes.func,
  newOrderable: PropTypes.func,
}

export default OrderablePage
