export const SET_LAST_LOAD_PROPOSAL_DASHBOARD_PARAMS =
  'SET_LAST_LOAD_PROPOSAL_DASHBOARD_PARAMS'

export const setLastLoadProposalDashboardParams = ({
  page,
  search,
  status,
  fromDate,
  toDate,
}) => ({
  type: SET_LAST_LOAD_PROPOSAL_DASHBOARD_PARAMS,
  page,
  search,
  status,
  fromDate,
  toDate,
})
