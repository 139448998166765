import { connect } from 'react-redux'

import { HubspotPage } from '@components/hubspot'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state: any) => {
  const { accounts, hubspotCompanies } = state

  return {
    accounts,
    hubspotCompanies,
  }
}

const mapDispatchToProps = () => {
  const { AccountService, RestService, HubspotCompanyService } = services
  const {
    pRequestHubspotList,
    pResponseHubspotCompanies,
    pResponseHubspotAccounts,
    pRequestBulkUpdateHubspotAccounts,
  } = presenters.Api
  const {
    LoadOrSearchHubspotList,
    BulkUpdateHubspotAccounts,
    UpdateHubspotCompanies,
  } = coordinators

  const loadHubspotCompanies = LoadOrSearchHubspotList({
    RestService,
    HubspotService: HubspotCompanyService,
    pRequestHubspotList,
    pResponseHubspotList: pResponseHubspotCompanies,
    endpoint: 'companies/text-search',
  })
  const updateHubspotCompanies = UpdateHubspotCompanies({
    RestService,
    HubspotService: HubspotCompanyService,
    pResponseHubspotList: pResponseHubspotCompanies,
    endpoint: 'companies/bulk-update',
  })
  const bulkUpdateHubspotAccounts = BulkUpdateHubspotAccounts({
    RestService,
    AccountService,
    HandleError,
    pResponseHubspotAccounts,
    pRequestBulkUpdateHubspotAccounts,
  })

  return {
    loadHubspotCompanies,
    bulkUpdateHubspotAccounts,
    updateHubspotCompanies,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HubspotPage)
