import { camelCaseify, formAdd, sortByAttribute } from '~/utils'

export const pResponseMenuItem = (json) => {
  let menuItem = camelCaseify(json)

  const cleanItem = (item) => {
    item.tagsDietaryPreference = (item.tagsDietaryPreferenceList || '').split(
      ',',
    )
    item.tagsIngredient = (item.tagsIngredientList || '')
      .split(',')
      .filter((t) => t.length > 0)
    item.tagsGroupOrderMealType = (item.tagsGroupOrderMealTypeList || '').split(
      ',',
    )
    item.tagsIngredient = item.tagsIngredient.map((t, i) => {
      return { id: i, text: t }
    })
    item.chefPrice = item.cost
    item.marketPrice = item.price
    delete item.cost
    delete item.price

    return item
  }

  menuItem = cleanItem(menuItem)
  menuItem.childMenuItems = menuItem.childItems.map(cleanItem)
  menuItem.childMenuItems.sort((a, b) =>
    (a.subMenuItemCategory || '').localeCompare(b.subMenuItemCategory),
  )

  return menuItem
}

export const pResponseCopyMenuItem = (json, chefId) => {
  const menuItem = pResponseMenuItem(json)
  menuItem.id = undefined
  menuItem.parentMenuItemId = undefined
  menuItem.chefId = chefId
  menuItem.childMenuItems &&
    menuItem.childMenuItems.forEach((i) => {
      i.id = undefined
      i.parentMenuItemId = undefined
      i.chefId = chefId
      i.packagingId = i.packaging && i.packaging.id
      i.servingUtensilId = i.servingUtensil && i.servingUtensil.id
    })

  return menuItem
}

export const pResponseChefMenuItem = (camelCaseMenuItem) => {
  const item = Object.assign({}, camelCaseMenuItem)
  item.menuItemId = item.id
  item.itemType = 'MenuItem'
  item.chefPrice = parseFloat(item.cost)
  item.price = parseFloat(item.price)
  item.retailPrice = parseFloat(item.retailPrice)
  item.childItems.forEach((i) => {
    i.itemType = 'MenuItem'
    i.category = i.subMenuItemCategory
    i.chefPrice = parseFloat(i.cost || 0)
    i.price = parseFloat(i.price)
    i.menuItemId = i.id
  })
  item.childItems.sort((a, b) =>
    a.subMenuItemCategory.localeCompare(b.subMenuItemCategory),
  )

  return item
}

export const pResponseOrderSearchMenuItem = (menuItem) => {
  const item = Object.assign({}, menuItem)
  item.price = parseFloat(item.price)
  item.tagsDietaryPreference = (item.tagsDietaryPreferenceList || '').split(',')

  return item
}

export const pResponseOrderSearchMenuItems = (items) => {
  const menuItems = {
    appetizers: [],
    entrees: [],
    sides: [],
    desserts: [],
    drinks: [],
  }

  camelCaseify(items).forEach((item) => {
    const menuItem = pResponseOrderSearchMenuItem(item)

    switch (menuItem.mealType) {
      case 'Main':
        menuItems.entrees.push(menuItem)
        break
      case 'Side':
        menuItems.sides.push(menuItem)
        break
      case 'Dessert':
        menuItems.desserts.push(menuItem)
        break
      case 'Drink':
        menuItems.drinks.push(menuItem)
        break
      case 'Appetizer':
        menuItems.appetizers.push(menuItem)
        break
      case 'Starch Side':
        menuItems.sides.push(menuItem)
        break
      case 'Vegetable Side':
        menuItems.sides.push(menuItem)
        break
      default:
        break
    }
  })

  sortByAttribute(menuItems.entrees, 'name')
  sortByAttribute(menuItems.sides, 'name')
  sortByAttribute(menuItems.desserts, 'name')
  sortByAttribute(menuItems.drinks, 'name')
  sortByAttribute(menuItems.appetizers, 'name')

  return menuItems
}

export const pRequestBulkUpdateMenuItem = (data) => {
  const req = {}

  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'chefId', 'chef_id')
  formAdd(data, req, 'isEnabled', 'is_enabled')
  formAdd(data, req, 'isApproved', 'is_approved')
  formAdd(data, req, 'isHot', 'is_hot')
  formAdd(data, req, 'chefPrice', 'cost')
  formAdd(data, req, 'marketPrice', 'price')
  formAdd(data, req, 'retailPrice', 'retail_price')
  formAdd(data, req, 'chefId', 'chef_id')
  formAdd(data, req, 'mealType', 'meal_type')

  return { menu_item: req }
}

export const pRequestUpdateMenuItem = (data) => {
  const req = {}

  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'chefId', 'chef_id')
  formAdd(data, req, 'name', 'name')
  formAdd(data, req, 'barcode', 'barcode')
  formAdd(data, req, 'isEnabled', 'is_enabled')
  formAdd(data, req, 'isApproved', 'is_approved')
  formAdd(data, req, 'description', 'description')
  formAdd(data, req, 'vendorDescription', 'vendor_description')
  formAdd(data, req, 'isSubMenuItem', 'is_sub_menu_item')
  formAdd(data, req, 'isHot', 'is_hot')
  formAdd(data, req, 'chefPrice', 'cost')
  formAdd(data, req, 'marketPrice', 'price')
  formAdd(data, req, 'pricePerPersonCalc', 'price_per_person_calc')
  formAdd(data, req, 'retailPrice', 'retail_price')
  formAdd(data, req, 'mealType', 'meal_type')
  formAdd(data, req, 'minQty', 'min_qty')
  formAdd(data, req, 'countPerShelf', 'count_per_shelf')
  formAdd(data, req, 'servingSize', 'serving_size')
  formAdd(data, req, 'servingWeightOzs', 'serving_weight_ozs')
  formAdd(data, req, 'dailyOrderLimit', 'daily_order_limit')
  formAdd(data, req, 'parentMenuItemId', 'parent_menu_item_id')
  formAdd(data, req, 'parentMenuItemName', 'parent_menu_item_name')
  formAdd(data, req, 'subMenuItemCategory', 'sub_menu_item_category')
  formAdd(data, req, 'imageSourceType', 'image_source_type')

  // Verifications
  formAdd(data, req, 'descriptionVerified', 'description_verified')
  formAdd(data, req, 'allergensVerified', 'allergens_verified')
  formAdd(data, req, 'imageVerified', 'image_verified')
  formAdd(data, req, 'servingDetailsVerified', 'serving_details_verified')

  formAdd(
    data,
    req,
    'tagsDietaryPreference',
    'tags_dietary_preference_list',
    (d) => d.join(','),
  )
  formAdd(data, req, 'tagsIngredient', 'tags_ingredient_list', (d) =>
    d.map((d) => d.text).join(','),
  )
  formAdd(
    data,
    req,
    'tagsGroupOrderMealType',
    'tags_group_order_meal_type_list',
    (d) => d.join(','),
  )
  formAdd(data, req, 'marketType', 'market_type')
  formAdd(data, req, 'creatorId', 'creator_id')

  formAdd(data, req, 'internalNotes', 'internal_notes')
  formAdd(data, req, 'vendorNotes', 'vendor_notes')

  if (data.packagingId) {
    req.menu_item_packaging_attributes = { order_suppliable_type: 'Packaging' }
    formAdd(
      data,
      req.menu_item_packaging_attributes,
      'packagingId',
      'order_suppliable_id',
    )
    formAdd(
      data,
      req.menu_item_packaging_attributes,
      'servingsPerPkg',
      'servings_per_pkg',
    )
  } else {
    req.packaging = null
  }

  if (data.servingUtensilId) {
    req.menu_item_serving_utensil_attributes = {
      order_suppliable_type: 'ServingUtensil',
    }
    formAdd(
      data,
      req.menu_item_serving_utensil_attributes,
      'servingUtensilId',
      'order_suppliable_id',
    )
  } else {
    req.serving_utensil = null
  }

  req.child_menu_items_join_attributes = pRequestUpdateChildItemsJoin(data)

  return { menu_item: req }
}

export const pRequestUpdateMarketplaceAddOn = ({
  menuItemData,
  menuItemId,
}) => {
  let req = undefined
  const { marketplaceAddOn } = menuItemData

  if (marketplaceAddOn && !marketplaceAddOn.destroy && !marketplaceAddOn.id) {
    req = { marketplace_add_on: { menu_item_id: menuItemId } }
  } else if (
    marketplaceAddOn &&
    marketplaceAddOn.destroy &&
    marketplaceAddOn.id
  ) {
    req = { destroy: true, id: marketplaceAddOn.id }
  }

  return req
}

// TODO - Combine chef images presenters to one
export const pRequestUpdateItemImages = (data, itemType = 'menu_item') => {
  if (data.images && data.images.length > 0) {
    const { images, chefId } = data
    const req = new FormData()
    let isModified = false

    req.append(`[${itemType}][chef_id]`, chefId)

    images.forEach((image, index) => {
      if (image.id && image.destroy) {
        req.append(
          `[${itemType}][images_attributes][` + index + '][id]',
          image.id,
        )
        req.append(
          `[${itemType}][images_attributes][` + index + '][_destroy]',
          true,
        )
        isModified = true
      } else if (!image.id) {
        req.append(
          `[${itemType}][images_attributes][` + index + '][image]',
          image,
        )
        isModified = true
      }
    })

    if (!isModified) {
      return undefined
    }

    return req
  } else {
    return undefined
  }
}

export const pRequestUpdateChildItemsJoin = (data) => {
  if (data.isSubMenuItem) {
    return null
  }
  const joinAttributes = []

  // delete for group order items
  if (data.marketType === 'Group Order' && data.id) {
    data.childMenuItemsJoin.forEach((join) => {
      if (
        !data.childMenuItems.find((child) => child.id === join.childMenuItemId)
      ) {
        joinAttributes.push({
          id: join.id,
          child_menu_item_id: join.childMenuItemId,
          _destroy: true,
        })
      }
    })
  }
  data.childMenuItems.forEach((child) => {
    if (
      !data.childMenuItemsJoin ||
      !data.childMenuItemsJoin.find((join) => join.childMenuItemId === child.id)
    ) {
      joinAttributes.push({
        child_menu_item_id: child.id,
      })
    }
  })

  return joinAttributes.length > 0 ? joinAttributes : null
}

export const pResponseMenuItemUpdate = (menuItemUpdate) => {
  const itemUpdate = camelCaseify(menuItemUpdate)
  const {
    id,
    image,
    menuItemId,
    menuItemName,
    chefId,
    chefHq,
    chefName,
    chefEmail,
    chefPhoneNumber,
    message,
    requestType,
    editDate,
    changesCompleted,
  } = itemUpdate

  return {
    id,
    image,
    menuItemId,
    menuItemName,
    chef: {
      id: chefId,
      hq: chefHq,
      name: chefName,
      email: chefEmail,
      phone: chefPhoneNumber,
    },
    message,
    requestType,
    editDate,
    changesCompleted,
  }
}

export const pResponseMenuItemCreation = (menuItemCreation) => {
  const itemCreation = camelCaseify(menuItemCreation)
  const {
    id,
    itemName,
    chefId,
    chefHq,
    chefName,
    chefEmail,
    chefPhoneNumber,
    description,
    dietaryPreferences,
    image,
    ingredients,
    internalNotes,
    itemCreated,
    requestDate,
    price,
  } = itemCreation

  return {
    id,
    menuItemName: itemName,
    chef: {
      id: chefId,
      hq: chefHq,
      name: chefName,
      email: chefEmail,
      phone: chefPhoneNumber,
    },
    description,
    dietaryPreferences,
    image,
    internalNotes,
    ingredients,
    itemCreated,
    requestDate,
    chefPrice: price,
  }
}

export const pResponseIndexMenuItems = (json) => {
  return json.map((item) => pResponseIndexMenuItem(item))
}

export const pResponseIndexMenuItem = (json) => ({
  id: json.id,
  name: json.name,
})
