import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { DateInput } from '@components/common/form'

class ReloadData extends Component {
  state = {
    fromDate: '',
    toDate: '',
  }

  componentDidMount() {
    const { reloadData, minDate, defaultFromDate, defaultToDate } = this.props

    const fromDate = defaultFromDate || minDate || ''
    const toDate = defaultToDate || ''

    reloadData({ fromDate, toDate })
    this.setState({ fromDate, toDate })
  }

  onChangeFromDate = (fromDate) => {
    this.setState({ fromDate })
    this.props.reloadData({ fromDate, toDate: this.state.toDate })
  }

  onChangeToDate = (toDate) => {
    this.setState({ toDate })
    this.props.reloadData({ fromDate: this.state.fromDate, toDate })
  }

  render() {
    const { clearable, isDisabled, minDate } = this.props
    const { fromDate, toDate } = this.state

    return (
      <div className="date-filters">
        <div className="date-filters__inner">
          <DateInput
            label="show"
            width="150px"
            disabled={isDisabled}
            date={fromDate}
            minDate={minDate}
            hideClearDate={true}
            onChange={this.onChangeFromDate}
          />
          {clearable && fromDate && (
            <button
              className="text-red-600 bold"
              onClick={() => this.onChangeFromDate('')}
            >
              X
            </button>
          )}
          <div style={{ width: '10px' }} />
          <DateInput
            label="to"
            width="150px"
            disabled={isDisabled}
            date={toDate}
            minDate={minDate}
            hideClearDate={true}
            onChange={this.onChangeToDate}
          />
          {clearable && toDate && (
            <button
              className="text-red-600 bold"
              onClick={() => this.onChangeToDate('')}
            >
              X
            </button>
          )}
        </div>
      </div>
    )
  }
}

ReloadData.propTypes = {
  clearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  minDate: PropTypes.object,
  defaultFromDate: PropTypes.object,
  defaultToDate: PropTypes.object,

  reloadData: PropTypes.func.isRequired,
}

ReloadData.defaultProps = {
  clearable: false,
  isDisabled: false,
}

export default ReloadData
