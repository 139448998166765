export const pRequestHubspotList = ({ searchString, page, limit }) => {
  const offset = (page - 1) * limit

  return {
    searchString: searchString || '',
    offset: offset,
    limit: limit,
  }
}

export const pResponseHubspotSingle = (response) => {
  return {
    hubspotId: response.hubspot_id ?? response.hubspotId,
    hungryId: response.hungry_id ?? response.hungryId,
    name: response.name,
  }
}

export const pResponseHubspotCompanies = (response) => {
  return response.map((c) => pResponseHubspotCompany(c))
}

export const pResponseHubspotCompany = (response) => {
  return {
    id: response.companyId,
    city: response.city,
    domain: response.domain,
    industry: response.industry,
    metro: response.metro,
    name: response.name,
    pin: response.pin,
    phone: response.phone,
    state: response.state,
    contacts: response.contacts || [],
  }
}

export const pResponseHubspotDeals = (response) => {
  const list = []
  response.forEach((d) => {
    list.push({ id: d.dealId, name: d.name })
  })

  return list
}

export const pResponseHubspotContacts = (response) => {
  const list = []
  response &&
    response.contacts.forEach((c) => {
      const cProps = c.properties || {}
      const email = cProps.email && cProps.email.value
      const firstName = cProps.firstname && cProps.firstname.value
      const lastName = cProps.lastname && cProps.lastname.value
      const id = c.vid
      let name = firstName + ' ' + lastName
      if (!lastName && !firstName) {
        name = email
      }
      list.push({
        id,
        email,
        name,
      })
    })

  return list
}

export const pResponseHubspotReps = (response) => {
  const list = []
  response.forEach((c) => {
    list.push({
      id: c.hubspotId,
      name: c.firstName + ' ' + c.lastName,
      email: c.email,
    })
  })

  return list
}
