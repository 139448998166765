import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/common/modal/Modal'

const OrderSurvey = (props) => {
  const onEmailSurvey = (e) => {
    e.preventDefault()

    props.emailSurvey()
  }

  const { order, hideModal } = props

  return (
    <Modal title="Send Survey to Client" hideModal={hideModal} color="#001940">
      <div className="center-panel">
        <p>
          After the order has been fulfilled, please send out the order survey
          to the customer:
        </p>
        {order.invoiceContact && order.invoiceContact.email ? (
          <button className="button-neutral" onClick={onEmailSurvey}>
            {' '}
            Email Order Survey{' '}
          </button>
        ) : (
          <p> Customer email is blank! </p>
        )}
      </div>
    </Modal>
  )
}

OrderSurvey.propTypes = {
  actionRequired: PropTypes.bool,
  className: PropTypes.string,
  order: PropTypes.object,

  hideModal: PropTypes.func,
  emailSurvey: PropTypes.func,
}

export default OrderSurvey
