import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import Input from '@components/common/form/Input'
import YSpacing from '@components/common/YSpacing'
import LabelInfo from '@components/common/form/LabelInfo'
import FlexContainer from '@components/common/FlexContainer'
import { DateInput, QuantityInput } from '@components/common/form'
import {
  BatchAccountExecutiveSection,
  BatchContactSection,
} from '@containers/batchOrder/edit/sections'

class BatchOrderInfoSection extends Component {
  state = {
    isFocused: false,
  }

  onBlur = () => {
    this.setState({ isFocused: false })
  }

  onFocus = () => {
    this.setState({ isFocused: true })
  }

  onInput = (field) => (value) => {
    if (value && value.target) {
      ;({ value } = value.target)
    }
    this.props.onChange({ [field]: value })
  }

  onInputEndDate = (endDate) => {
    const newState = { endDate }
    if (!endDate) {
      newState.chargeDate = null
    } else if (this.props.netOffset) {
      const newChargeDate = endDate.clone().add(this.props.netOffset, 'd')
      newChargeDate.hour(23).minute(30)
      newState.chargeDate = newChargeDate
    }
    this.props.onChange(newState)
  }

  onCancel = (contact) => {
    this.props.onChange({ contact: contact, newContact: {} })
  }

  onChangeAccountExecutive = ({ accountExecutive }) => {
    this.props.onChange({ accountExecutive })
  }

  onChangeContact = (contact) => {
    this.props.onChange({ contact })
  }

  onChangeNetOffset = (netOffset) => {
    const newState = { netOffset }
    if (this.props.endDate) {
      const newChargeDate = this.props.endDate.clone().add(netOffset, 'd')
      newChargeDate.hour(23).minute(30)
      newState.chargeDate = newChargeDate
    }
    this.setState(newState)
    this.props.onChange(newState)
  }

  onSelectSetupDate = (clientSetUpTime) => {
    const newState = {}
    if (!this.state.clientSetUpTime) {
      newState['clientSetUpTime'] = clientSetUpTime.hour(12)
    } else {
      const hour = this.state.clientSetUpTime.hour()
      const minute = this.state.clientSetUpTime.minute()
      clientSetUpTime.hour(hour)
      clientSetUpTime.minute(minute)
      newState['clientSetUpTime'] = clientSetUpTime
    }
    if (
      !this.state.chargeDate ||
      this.state.chargeDate.day() === this.state.clientSetUpTime.day()
    ) {
      newState['chargeDate'] = Moment(clientSetUpTime).hour(15)
    }
    this.setState(newState, () => {
      this.props.onChange(newState)
    })
  }

  onSaveContact = async () => {
    return await this.props.onSaveContact()
  }

  render() {
    const {
      accountExecutive,
      chargeDate,
      startDate,
      endDate,
      netOffset,
      contact,
      purchaseOrderNumber,
      contacts,
      errors,
      hasAccount,
      loadAccountExecutives,
      newContact,
    } = this.props

    return (
      <div>
        <FlexContainer justifyContent="space-between">
          <BatchAccountExecutiveSection
            width="31%"
            loadAccountExecutives={loadAccountExecutives}
            accountExecutive={accountExecutive}
            onChange={this.onChangeAccountExecutive}
          />
          <DateInput
            width="31%"
            label="Orders Start Date"
            date={startDate}
            onChange={this.onInput('startDate')}
            hideClearDate={true}
          />
          <DateInput
            width="31%"
            label="Orders End Date"
            date={endDate}
            onChange={this.onInputEndDate}
            hideClearDate={true}
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <Input
            width="31%"
            testId="po-number"
            label="PO Number / Reference"
            value={purchaseOrderNumber}
            onChange={this.onInput('purchaseOrderNumber')}
          />
          <QuantityInput
            width="31%"
            label="Net offset -Grace period in days"
            id="net-offset"
            value={netOffset || 0}
            error={errors.netOffset}
            onChange={this.onChangeNetOffset}
          />
          <LabelInfo
            width="31%"
            label="Charge Date"
            value={
              chargeDate
                ? Moment(chargeDate).format('MMM, Do YYYY')
                : 'Select Net Offset'
            }
            error={errors['Charge Date']}
          />
        </FlexContainer>
        <BatchContactSection
          width="31%"
          contactTitle="Contact"
          contact={contact}
          contacts={contacts}
          errors={errors}
          hasAccount={hasAccount}
          newContact={newContact}
          onCancel={this.onCancel}
          onChange={this.onChangeContact}
          onInputNew={this.onInput('newContact')}
          onSave={this.onSaveContact}
        />
      </div>
    )
  }
}

BatchOrderInfoSection.propTypes = {
  chargeDate: PropTypes.instanceOf(Moment),
  startDate: PropTypes.instanceOf(Moment),
  endDate: PropTypes.instanceOf(Moment),
  netOffset: PropTypes.number,
  purchaseOrderNumber: PropTypes.string,
  accountExecutive: PropTypes.object,
  contact: PropTypes.object,
  contacts: PropTypes.arrayOf(PropTypes.object),
  newContact: PropTypes.object,
  hasAccount: PropTypes.bool,

  errors: PropTypes.object,

  loadAccountExecutives: PropTypes.func,
  onChange: PropTypes.func,
  onSaveContact: PropTypes.func,
}

BatchOrderInfoSection.defaultProps = {
  errors: {},
}

export default BatchOrderInfoSection
