import { SET_PROMO_CODES, SET_PROMO_CODE_ATTRIBUTES } from '@actions/promoCodes'

const initialPromoCodesState = []
const initialPromoCodeAttributesState = []

export function promoCodes(state = initialPromoCodesState, action) {
  const { type, promoCodes } = action

  switch (type) {
    case SET_PROMO_CODES:
      return promoCodes
    default:
      return state
  }
}

export function promoCodeAttributes(
  state = initialPromoCodeAttributesState,
  action,
) {
  const { type, promoCodeAttributes } = action

  switch (type) {
    case SET_PROMO_CODE_ATTRIBUTES:
      return promoCodeAttributes
    default:
      return state
  }
}
