import { connect } from 'react-redux'

import ResetPass from '@components/ResetPass'

import services from '@services'
import coordinators from '@coordinators'
import { HandleError } from '@coordinators/composed'
import presenters from '@presenters'

const mapDispatchToProps = () => {
  const { CoreService, RestService, UIService, RouterService } = services

  const resetPass = coordinators.ResetPass({
    CoreService,
    RestService,
    UIService,
    RouterService,
    HandleError,
  })

  return {
    resetPass,
  }
}

const mapStateToProps = (state) => {
  const { pProcessResetPassParams } = presenters
  const { router } = state

  const token = router.location
    ? pProcessResetPassParams(router.location.search).token
    : ''

  return {
    token,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPass)
