import { SET_REFERRED_BY, SET_REFERRAL_PARTNERS } from '@actions/referral'

export function referredBy(state = {}, action) {
  const { type, referredBy } = action

  switch (type) {
    case SET_REFERRED_BY:
      return { ...referredBy }
    default:
      return state
  }
}

export function referralPartners(state = [], action) {
  const { type, referralPartners } = action

  switch (type) {
    case SET_REFERRAL_PARTNERS:
      return [...referralPartners]
    default:
      return state
  }
}
