import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BiPencil, BiTrash } from 'react-icons/bi'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import { ButtonsBar } from '@res/styledComponents/index'
import Table from '@components/common/Table'
import ReactPaginate from 'react-paginate'
import styled from '@emotion/styled'
import { colors } from '../../../../../constants'

class ServiceDepotListGroup extends Component {
  constructor() {
    super()
    this.perPage = 10
  }

  componentDidMount() {
    this.loadServiceDepots(0)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hqId !== this.props.hqId) {
      this.loadServiceDepots(0)
    }
  }

  getPageCount = () => {
    const { serviceDepots } = this.props
    const sDepot = serviceDepots[0]
    let total = 1
    if (sDepot && sDepot.meta && sDepot.meta.count) {
      total = sDepot.meta.count
    }

    return Math.ceil(total / this.perPage)
  }

  loadServiceDepots = (offset) =>
    this.props.loadServiceDepots({
      limit: this.perPage,
      offset,
      hqId: this.props.hqId,
    })

  handlePageClick = (data) => {
    const { selected } = data
    const offset = Math.ceil(selected * this.perPage)

    this.setState({ offset }, () => {
      this.loadServiceDepots()
    })
  }

  render() {
    const { onCreate, onDelete, onEdit, serviceDepots } = this.props

    const renderSDepots = (sDepot, index) => {
      return (
        <tr key={index}>
          <td onClick={() => onEdit(sDepot, index)}>
            {sDepot.name} {sDepot.default && <Default>Default</Default>}
          </td>
          <td>
            {sDepot.active && <span className="text-bold m-2">Active</span>}
          </td>
          <td>
            <FlexContainer>
              <BiTrash onClick={() => onDelete(sDepot)} />
              <XSpacing width="10px" />
              <BiPencil onClick={() => onEdit(sDepot, index)} />
            </FlexContainer>
          </td>
        </tr>
      )
    }

    return (
      <div>
        <ButtonsBar>
          <button onClick={onCreate}>
            <BiPencil /> New Service Depot{' '}
          </button>
        </ButtonsBar>
        <Panel width="100%" maxWidth="500px">
          <Table headings={['Name', 'Active?', '']}>
            {serviceDepots.map((depot, index) => renderSDepots(depot, index))}
          </Table>
          {serviceDepots.length > 0 ? (
            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.getPageCount()}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          ) : null}
        </Panel>
      </div>
    )
  }
}

const Default = styled.div`
  background: ${colors.blue300};
  display: inline-block;
  color: #fff;
  border-radius: 30px;
  padding: 5px 10px;
  margin-left: 10px;
  font-family: 'bold';
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
`

ServiceDepotListGroup.propTypes = {
  serviceDepots: PropTypes.array,

  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  loadServiceDepots: PropTypes.func,

  hqId: PropTypes.number,
}

export default ServiceDepotListGroup
