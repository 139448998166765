import React, { Component, Fragment } from 'react'
import Moment from 'moment-timezone'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Carousel } from 'react-bootstrap'
import { AuthorizedInteractable } from '@containers/common/auth'
import {
  Checkbox,
  QuantityInput,
  RadioButton,
  FileUpload,
  ConceptMenuItemSelectInput,
} from '@components/common/form'
import Modal from '@components/common/modal/Modal'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import DividerLine from '@components/common/DividerLine'
import LinkText from '@components/common/form/LinkText'
import Button from '@components/common/form/Button'
import Input from '@components/common/form/Input'
import { Label } from '@res/styledComponents/index'
import { TooltipModal } from '../../common/modal'
import {
  colors,
  MAIN,
  VEGETABLE_MAIN,
  STARCH_SIDE,
  VEGETABLE_SIDE,
  BUILD_YOUR_OWN,
  MaxImageSizeKB,
} from '../../../../constants'
import removedImage from '@res/images/image-removed-320x240.png'
import { GroupOrderMenuCutoffSettings } from '@containers/chef/edit'

const getConceptMenuItemImageUrl = (conceptMenuItem) => {
  const { menuItem } = conceptMenuItem
  if (menuItem && menuItem.images && menuItem.images.length) {
    return menuItem.images[0].thumborUrl
  }

  return ''
}

const getImagesCount = (conceptMenuItems) => {
  let count = 0
  conceptMenuItems.forEach((item) => {
    const imgUrl = getConceptMenuItemImageUrl(item)
    if (imgUrl) {
      count += 1
    }
  })

  return count
}

const getImagesCountCopy = (menuItems) => {
  const count = menuItems.length
  if (count === 0) {
    return ''
  }
  const imagesCount = getImagesCount(menuItems)

  return `${imagesCount} / ${count} menu items with photo`
}

class EditMenuConceptModal extends Component {
  state = {
    allCuisines: [],
    allDayParts: [],
    allMarketTypes: [],
    conceptsMenuItems: [],
    images: [],
    servingImages: [],
    isApproved: false,
    isEnabled: false,
    isUpdatedStructure: false,
    warningSeen: false,
    avgPricePerPerson: null,
    estimatedPPP: null,
    groupOrderCutoffDays: null,
    groupOrderCutoffTime: null,
    groupOrderPickupTime: null,
    groupOrderMaxItems: null,
    groupOrderAvailableDays: [0, 1, 2, 3, 4, 5, 6],
  }
  lastAvgPriceReq = null
  lastEstPriceReq = null

  componentWillMount() {
    ;(async () => {
      const {
        clearErrors,
        menuConcept,
        mainMenuItems,
        sideMenuItems,
        otherMenuItems,
      } = this.props

      clearErrors()

      const { allCuisines, allDayParts, allMarketTypes } =
        await this.props.loadConceptsSettings()

      const newState = {
        allCuisines,
        allDayParts,
        allMarketTypes,
        ...menuConcept,
      }

      if (!mainMenuItems || !sideMenuItems || !otherMenuItems) {
        this.props.loadChefMenuItems(this.props.chefId)
      }

      allCuisines.sort().forEach((c) => {
        newState[this.getCuisineKey(c)] = false
      })

      newState.tagsCuisine &&
        newState.tagsCuisine.forEach((c) => {
          newState[this.getCuisineKey(c)] = true
        })

      this.setState(newState, this.calcDynamicValues)
    })()
  }

  componentWillReceiveProps(nextProps) {
    ;(async () => {
      const newState = {}
      if (this.props.menuConcept !== nextProps.menuConcept) {
        const { menuConcept } = nextProps
        console.log('componentWillReceiveProps changed')
        delete menuConcept.mainMenuItems
        delete menuConcept.sideMenuItems
        delete menuConcept.otherMenuItems

        this.state.allCuisines.forEach((c) => {
          newState[this.getCuisineKey(c)] = false
        })

        menuConcept.tagsCuisine &&
          menuConcept.tagsCuisine.forEach((c) => {
            newState[this.getCuisineKey(c)] = true
          })

        Object.assign(newState, menuConcept)
        newState.chefId = nextProps.chefId
      }

      this.setState(newState)
    })()
  }

  updateState = (newState, callback = null) => {
    this.setState(newState, () => {
      this.calcDynamicValues()
      callback && callback()
    })
  }

  getCuisineKey = (tag) => {
    return `cuisine${tag}`
  }

  getGOMealTypeKey = (tag) => {
    return `goMealType${tag}`
  }

  onAddImage = (e) => {
    const { displayFailureMessage } = this.props

    const image = e.target.files[0]
    const { size } = image

    if (size / 1024 > MaxImageSizeKB) {
      displayFailureMessage(
        `Image size is too large. Please upload an image less than ${MaxImageSizeKB}KB.`,
      )

      return
    }

    let { images } = this.state
    images = [...images, image]
    this.setState({ images })
  }

  onAddServingImage = (e) => {
    const servingImage = e.target.files[0]
    let { servingImages } = this.state
    servingImages = [...servingImages, servingImage]

    this.updateState({ servingImages })
  }

  nextPosition = (conceptsMenuItems) => {
    let maxIndex = 0
    conceptsMenuItems.forEach((i) => {
      if (i.position > maxIndex) {
        maxIndex = i.position
      }
    })

    return maxIndex + 1
  }

  onAddMenuItem = (mealType, index) => {
    const { delayedUpdateConcept } = this.props
    const { conceptsMenuItems } = this.state
    conceptsMenuItems.push({
      menuItem: { mealType },
      index,
      position: this.nextPosition(conceptsMenuItems),
    })
    delayedUpdateConcept(this.state, this.calcDynamicValues)
  }

  onChange = (key) => (e) => {
    const { value } = e.target
    const { delayedUpdateConcept } = this.props
    this.updateState({ [key]: value }, () => delayedUpdateConcept(this.state))
  }

  onInput = (key) => (value) => {
    const { delayedUpdateConcept } = this.props
    this.updateState({ [key]: value }, () => delayedUpdateConcept(this.state))
  }

  onChangeCheckbox = (e) => {
    const { checked, value: key } = e.target
    const { delayedUpdateConcept } = this.props
    this.updateState({ [key]: checked }, () => delayedUpdateConcept(this.state))
  }

  onChangeConcept = (key, value) => {
    const newState = { ...this.state }
    const { delayedUpdateConcept } = this.props
    newState[key] = value
    this.updateState(newState, () => delayedUpdateConcept(this.state))
  }

  onChangeRadioButton = (e) => {
    const { name, value: key } = e.target
    const { delayedUpdateConcept } = this.props
    this.updateState({ [name]: key }, () => delayedUpdateConcept(this.state))
  }

  onChangeTagsCuisine = (e) => {
    const { delayedUpdateConcept } = this.props
    const { checked, value: key } = e.target
    this.updateState({ [this.getCuisineKey(key)]: checked }, () =>
      delayedUpdateConcept(this.state),
    )
  }

  onDelete = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const { id, name } = this.state
    this.props.deleteMenuConcept(id, name)
  }

  onDeleteImage = (index) => {
    const { delayedUpdateConcept } = this.props
    const images = this.state.images.slice()
    images[index].destroy = !images[index].destroy
    this.updateState({ images }, () => delayedUpdateConcept(this.state))
  }

  onDeleteServingImage = (index) => {
    const { delayedUpdateConcept } = this.props
    const servingImages = this.state.servingImages.slice()
    servingImages[index].destroy = !servingImages[index].destroy
    this.updateState({ servingImages }, () => delayedUpdateConcept(this.state))
  }

  onHide = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    this.props.close()
  }

  onMenuItemChange = (conceptsMenuItem, menuItem) => {
    const { conceptsMenuItems } = this.state
    if (!menuItem.id) {
      conceptsMenuItem.destroy = true
    }
    conceptsMenuItem.menuItem = menuItem
    conceptsMenuItems[conceptsMenuItem.index] = conceptsMenuItem
    this.onChangeConcept(
      'conceptsMenuItems',
      [...conceptsMenuItems],
      this.calcDynamicValues,
    )
  }

  onSubmit = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const {
      delayedUpdateConcept,
      saveMenuConcept,
      displayWarningMessage,
      displayFailureMessage,
    } = this.props
    const {
      marketType,
      warningSeen,
      images,
      avgPricePerPerson,
      groupOrderAvailableDays,
      conceptsMenuItems,
      isUpdatedStructure,
      tagsCuisine,
    } = this.state

    const isBYO = tagsCuisine?.includes(BUILD_YOUR_OWN) || false

    if (isUpdatedStructure && !isBYO) {
      const conceptItemCountMap = {
        [MAIN]: { requiredCount: 2, count: 0 },
        [VEGETABLE_MAIN]: { requiredCount: 1, count: 0 },
        [VEGETABLE_SIDE]: { requiredCount: 1, count: 0 },
        [STARCH_SIDE]: { requiredCount: 1, count: 0 },
      }
      conceptsMenuItems.forEach((conceptItem) => {
        const { menuItem } = conceptItem
        const { mealType, tagsDietaryPreferenceList } = menuItem || {}
        if (mealType === MAIN) {
          if ((tagsDietaryPreferenceList || '').includes('vegetarian')) {
            conceptItemCountMap[VEGETABLE_MAIN] -= 1
          } else {
            conceptItemCountMap[MAIN] -= 1
          }
        } else if (mealType === VEGETABLE_SIDE) {
          conceptItemCountMap[VEGETABLE_SIDE] -= 1
        } else if (mealType === STARCH_SIDE) {
          conceptItemCountMap[STARCH_SIDE] -= 1
        }
      })

      const errorList = ['Concept does not conform to updated menu structure.']
      const itemErrors = []

      for (const itemType in conceptItemCountMap) {
        const itemCount = conceptItemCountMap[itemType]
        if (itemCount && itemCount.requiredCount != itemCount.count) {
          const error = `${itemType} required count ${itemCount.requiredCount} have ${itemCount.count}`
          itemErrors.push(error)
        }
      }

      if (itemErrors.length > 0) {
        errorList.push(itemErrors.join(', '))
      }

      if (errorList.length > 1) {
        displayWarningMessage(errorList.join(' '))
      }
    }

    if (marketType === 'Group Order' && groupOrderAvailableDays.length === 0) {
      displayFailureMessage(
        'Please select at least one day of the week for Group Order availability.',
      )

      return
    }

    if (marketType !== 'Group Order' && !warningSeen && images.length === 0) {
      displayWarningMessage(
        'Are you sure you want to save this menu card without a photo? This will result in a default image of this menu on the Marketplace & Sales PDFs.',
      )
      this.updateState({ warningSeen: true })

      return
    }
    if (avgPricePerPerson !== null && avgPricePerPerson >= 20.0) {
      displayWarningMessage(
        'This menu card will display a Marketplace Average Price Per Person of $20 or higher. Are you sure this is accurate? If not, please double check the menu items added to this card.',
      )
    }

    delayedUpdateConcept(this.state)
    saveMenuConcept(this.state)
  }

  onUpdateArray = (key, newArray) => {
    let { conceptsMenuItems } = this.state
    const { pGetMainMenuItems, pGetSideMenuItems, pGetOtherMenuItems } =
      this.props
    let mainConceptsMenuItems = pGetMainMenuItems(conceptsMenuItems)
    let sideConceptsMenuItems = pGetSideMenuItems(conceptsMenuItems)
    let otherConceptsMenuItems = pGetOtherMenuItems(conceptsMenuItems)

    switch (key) {
      case 'mainConceptsMenuItems': {
        mainConceptsMenuItems = newArray
        break
      }
      case 'sideConceptsMenuItems': {
        sideConceptsMenuItems = newArray
        break
      }
      case 'otherConceptsMenuItems': {
        otherConceptsMenuItems = newArray
        break
      }
    }

    conceptsMenuItems = mainConceptsMenuItems
      .concat(sideConceptsMenuItems)
      .concat(otherConceptsMenuItems)
    conceptsMenuItems.map((c, i) => (c.index = i))
    this.onChangeConcept('conceptsMenuItems', conceptsMenuItems)
  }

  calcDynamicValues = () => {
    this.getAvgPricePerPerson()
    this.getConceptEstPricePerPerson()
  }

  getAvgPricePerPerson = async () => {
    const { delayedUpdateConcept } = this.props
    const { conceptsMenuItems, tagsDayPartList } = this.state
    const isHappyHour = tagsDayPartList === 'happy hour'
    const menuItemIds = conceptsMenuItems
      .map((item) => item.menuItem.id)
      .filter((id) => !!id)
    if (menuItemIds.length === 0) {
      if (this.state.avgPricePerPerson !== 0.0) {
        this.updateState({ avgPricePerPerson: 0.0 }, () =>
          delayedUpdateConcept(this.state),
        )
      }

      return
    }

    const menuItemIdsStr = menuItemIds
      .sort((a, b) => (a > b ? 1 : -1))
      .join(',')
    if (
      this.lastAvgPriceReq &&
      this.lastAvgPriceReq.isHappyHour === isHappyHour &&
      this.lastAvgPriceReq.menuItemIdsStr === menuItemIdsStr
    ) {
      return
    }

    this.lastAvgPriceReq = { isHappyHour, menuItemIdsStr }
    const avgPricePerPerson = await this.props.getAvgPricePerPerson(
      isHappyHour,
      menuItemIds,
    )
    if (avgPricePerPerson === this.state.avgPricePerPerson) {
      return
    }

    if (avgPricePerPerson !== null) {
      this.updateState(
        { avgPricePerPerson: parseFloat(avgPricePerPerson) },
        () => delayedUpdateConcept(this.state),
      )
    }
  }

  getConceptEstPricePerPerson = async () => {
    const { delayedUpdateConcept, pGetMainMenuItems, pGetSideMenuItems } =
      this.props
    const { conceptsMenuItems } = this.state
    const mainItemIds = pGetMainMenuItems(conceptsMenuItems)
      .sort((a, b) => {
        return a.position - b.position
      })
      .map((item) => item.menuItem.id)
      .filter((id) => !!id)
    const sideItemIds = pGetSideMenuItems(conceptsMenuItems)
      .sort((a, b) => {
        return a.position - b.position
      })
      .map((item) => item.menuItem.id)
      .filter((id) => !!id)
    if (mainItemIds.length === 0 && sideItemIds.length === 0) {
      if (this.state.estimatedPPP !== 0.0) {
        this.updateState({ estimatedPPP: 0.0 }, () =>
          delayedUpdateConcept(this.state),
        )
      }

      return
    }

    const mainItemIdsStr = mainItemIds.join(',')
    const sideItemIdsStr = sideItemIds.join(',')

    if (
      this.lastEstPriceReq &&
      this.lastEstPriceReq.mainItemIdsStr === mainItemIdsStr &&
      this.lastEstPriceReq.sideItemIdsStr === sideItemIdsStr
    ) {
      return
    }

    this.lastEstPriceReq = { mainItemIdsStr, sideItemIdsStr }
    const result = await this.props.getConceptEstPricePerPerson(
      mainItemIds,
      sideItemIds,
    )

    const estimatedPPP = parseFloat(result.pricePerPerson)
    if (estimatedPPP === this.state.estimatedPPP) {
      return
    }

    if (estimatedPPP !== null) {
      this.updateState({ estimatedPPP }, () => delayedUpdateConcept(this.state))
    }
  }

  onChangeGoAvailDays = (day) => {
    const { groupOrderAvailableDays: availDays } = this.state
    const { delayedUpdateConcept } = this.props

    let days = availDays
    if (days.includes(day)) {
      days = days.filter((d) => d !== day)
    } else {
      days.push(day)
    }
    days.sort((a, b) => a - b)

    this.setState(
      {
        groupOrderAvailableDays: [...days],
      },
      () => delayedUpdateConcept(this.state),
    )
  }

  onChangeGoCutoffDays = (value) => {
    const nan = isNaN(value)
    const newState = {}
    newState.groupOrderCutoffDays = nan ? null : value
    if (nan) {
      newState.groupOrderCutoffTime = null
    }

    this.setState({ ...newState }, () =>
      this.props.delayedUpdateConcept(this.state),
    )
  }

  onChangeGoCutoffTime = (value) => {
    this.setState(
      {
        groupOrderCutoffTime: value,
      },
      () => this.props.delayedUpdateConcept(this.state),
    )
  }

  onChangeGoPickupTime = (value) => {
    const { locale } = this.props

    let pickupTime = null
    if (value) {
      const m = Moment(value).tz(locale)
      pickupTime = m.hour() * 100 + m.minute()
    }

    this.setState(
      {
        groupOrderPickupTime: pickupTime,
      },
      () => this.props.delayedUpdateConcept(this.state),
    )
  }

  onChangeGoMaxItems = (value) => {
    this.setState(
      {
        groupOrderMaxItems: value,
      },
      () => this.props.delayedUpdateConcept(this.state),
    )
  }

  render() {
    let { mainMenuItems = [], sideMenuItems = [] } = this.props
    const {
      show,
      errors,
      filterRemainingItems,
      pGetMainMenuItems,
      pGetSideMenuItems,
      pGetOtherMenuItems,
    } = this.props
    const {
      allCuisines,
      allDayParts,
      allMarketTypes,
      conceptsMenuItems,
      margin,
      featured,
      id,
      name,
      images,
      isApproved,
      isEnabled,
      isUpdatedStructure,
      marketType,
      servingImages,
      tagsDayPartList,
      avgPricePerPerson,
      estimatedPPP,
      groupOrderAvailableDays,
      groupOrderCutoffDays,
      groupOrderCutoffTime,
      groupOrderPickupTime,
      groupOrderMaxItems,
      readyToHeat,
    } = this.state

    const nextIndex = conceptsMenuItems.length
    const itemType = marketType == 'Pop-Up' ? 'Office' : marketType
    if (marketType) {
      mainMenuItems = mainMenuItems.filter(
        (i) => i.marketType == itemType && i.isApproved,
      )
      sideMenuItems = sideMenuItems.filter(
        (i) => i.marketType == itemType && i.isApproved,
      )
    }
    const mainConceptsMenuItems = pGetMainMenuItems(conceptsMenuItems)
    const sideConceptsMenuItems = pGetSideMenuItems(conceptsMenuItems)
    const otherConceptsMenuItems = pGetOtherMenuItems(conceptsMenuItems)
    const mainMenuOptions = filterRemainingItems(
      mainMenuItems,
      mainConceptsMenuItems,
    )
    const sideMenuOptions = filterRemainingItems(
      sideMenuItems,
      sideConceptsMenuItems,
    )

    const hasEmptyMainConceptsMenuItem = mainConceptsMenuItems.some(
      (i) => i.menuItem.id === undefined,
    )
    const hasEmptySideConceptsMenuItem = sideConceptsMenuItems.some(
      (i) => i.menuItem.id === undefined,
    )
    const imagesCountCopy = getImagesCountCopy(conceptsMenuItems)

    return (
      <Modal
        show={show}
        title={id ? 'Edit Menu Card' : 'New Menu Card'}
        hideModal={this.onHide}
        color="#001940"
        width="800px"
      >
        <FlexContainer justifyContent="space-between" flexWrap="wrap">
          <Input
            width="31%"
            label="Menu Card Name"
            value={name}
            error={errors.name}
            onChange={this.onChange('name')}
          />
          <QuantityInput
            width="31%"
            label="Featured Ranking"
            error={errors.featured}
            value={featured || 0}
            onChange={this.onInput('featured')}
          />
          <FileUpload
            width="31%"
            label="Upload Cover Image"
            onChange={this.onAddImage}
          />
        </FlexContainer>
        {imagesCountCopy && (
          <FlexContainer>
            <p>{imagesCountCopy}</p>
          </FlexContainer>
        )}
        {images.length > 0 && (
          <Carousel>
            {images.map((image, i) => (
              <Carousel.Item key={i}>
                <div>
                  {image.destroy ? (
                    <img src={removedImage} />
                  ) : (
                    <img src={image.thumborUrl} />
                  )}
                  <button onClick={() => this.onDeleteImage(i)}>
                    {' '}
                    Delete / Undo
                  </button>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        )}
        <DividerLine margin="20px 0" />
        <FlexContainer justifyContent="space-between">
          <FlexContainer flexDirection="column" width="62%">
            <Label>Cuisine</Label>
            {errors.tagsCuisine && (
              <div className="error-message"> {errors.tagsCuisine}</div>
            )}
            <YSpacing height="5px" />
            <CheckboxColumn>
              {allCuisines.map((cuisine) => (
                <Checkbox
                  label={cuisine}
                  marginBottom="5px"
                  key={cuisine}
                  value={cuisine}
                  checked={this.state[this.getCuisineKey(cuisine)]}
                  onChange={this.onChangeTagsCuisine}
                />
              ))}
            </CheckboxColumn>
          </FlexContainer>
          <FlexContainer flexDirection="column" width="31%">
            <Label>Status</Label>
            <YSpacing height="5px" />
            <Checkbox
              value="isEnabled"
              label="Enabled"
              checked={isEnabled}
              onChange={this.onChangeCheckbox}
            />
            <YSpacing height="5px" />
            <Checkbox
              value="isApproved"
              label="Approved"
              checked={isApproved}
              onChange={this.onChangeCheckbox}
            />
            <YSpacing height="5px" />
            <Checkbox
              value="isUpdatedStructure"
              label="Updated Menu Structure"
              checked={isUpdatedStructure}
              onChange={this.onChangeCheckbox}
            />
            <YSpacing height="30px" />
            <Label>Day Part</Label>
            {errors.tagsDayPart && (
              <div className="error-message"> {errors.tagsDayPart}</div>
            )}
            <YSpacing height="5px" />
            {allDayParts.map((dayPart) => (
              <RadioButton
                name="tagsDayPartList"
                value={dayPart}
                marginBottom="5px"
                key={dayPart}
                checked={dayPart === tagsDayPartList}
                onChange={this.onChangeRadioButton}
              />
            ))}
            <YSpacing height="30px" />
            <Label>Market Type</Label>
            {errors.tagsDayPart && (
              <div className="error-message"> {errors.tagsDayPart}</div>
            )}
            <YSpacing height="5px" />
            {allMarketTypes.map((type) => (
              <RadioButton
                name="marketType"
                marginBottom="5px"
                value={type}
                key={type}
                checked={type === marketType}
                onChange={this.onChangeRadioButton}
              />
            ))}
            {marketType === 'Group Order' && (
              <>
                <YSpacing height="30px" />
                <Checkbox
                  value="readyToHeat"
                  label="Ready to Heat"
                  checked={readyToHeat}
                  onChange={this.onChangeCheckbox}
                />
              </>
            )}
          </FlexContainer>
        </FlexContainer>
        <DividerLine margin="20px 0" />
        {marketType === 'Pop-Up' && (
          <div>
            <FileUpload
              width="31%"
              label="Upload Pop Up Serving Image"
              onChange={this.onAddServingImage}
            />
            <Carousel>
              {servingImages.map((servingImage, i) => (
                <Carousel.Item key={i}>
                  <div>
                    {servingImage.destroy ? (
                      <img src={removedImage} />
                    ) : (
                      <img src={servingImage.thumborUrl} />
                    )}
                    <button onClick={() => this.onDeleteServingImage(i)}>
                      {' '}
                      Delete / Undo
                    </button>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
            <DividerLine margin="20px 0" />
          </div>
        )}
        {errors.menuItems && (
          <div className="error-message"> {errors.menuItems}</div>
        )}
        {marketType === 'Group Order' && (
          <Fragment>
            <YSpacing height="10px" />
            <GroupOrderMenuCutoffSettings
              groupOrderAvailableDays={groupOrderAvailableDays}
              onChangeAvailableDays={this.onChangeGoAvailDays}
              groupOrderCutoffDays={groupOrderCutoffDays}
              onChangeCutoffDays={this.onChangeGoCutoffDays}
              groupOrderCutoffTime={groupOrderCutoffTime}
              onChangeCutoffTime={this.onChangeGoCutoffTime}
              groupOrderPickupTime={groupOrderPickupTime}
              onChangePickupTime={this.onChangeGoPickupTime}
              groupOrderMaxItems={groupOrderMaxItems}
              onChangeMaxItems={this.onChangeGoMaxItems}
            />
            <YSpacing height="20px" />
          </Fragment>
        )}
        <Label>Select Entrees</Label>
        <YSpacing height="10px" />
        {mainConceptsMenuItems.map((mainConceptsMenuItem, i) => (
          <ConceptMenuItemSelectInput
            key={`mainConceptsMenuItem${i}`}
            menuItems={mainMenuOptions}
            selectedConceptsMenuItem={mainConceptsMenuItem}
            mainArray={mainConceptsMenuItems}
            updateArray={(newArray) =>
              this.onUpdateArray('mainConceptsMenuItems', newArray)
            }
            index={i}
            onChange={(menuItem) =>
              this.onMenuItemChange(mainConceptsMenuItem, menuItem)
            }
            pMenuItemMargin={this.props.pMenuItemMargin}
          />
        ))}
        {!hasEmptyMainConceptsMenuItem && (
          <LinkText
            fontSize="12px"
            onClick={() => this.onAddMenuItem('Main', nextIndex)}
            label="Add Another Entree"
          />
        )}
        <YSpacing height="30px" />
        <Label>Select Vegetable and/or Starch Sides</Label>
        <YSpacing height="10px" />
        {sideConceptsMenuItems.map((sideConceptsMenuItem, i) => (
          <ConceptMenuItemSelectInput
            key={`sideConceptsMenuItem${i}`}
            menuItems={marketType === 'Group Order' ? [] : sideMenuOptions} //GO menu concepts should only have entrees -> only able to remove if GO concept
            selectedConceptsMenuItem={sideConceptsMenuItem}
            mainArray={sideConceptsMenuItems}
            updateArray={(newArray) =>
              this.onUpdateArray('sideConceptsMenuItems', newArray)
            }
            index={i}
            onChange={(menuItem) =>
              this.onMenuItemChange(sideConceptsMenuItem, menuItem)
            }
            pMenuItemMargin={this.props.pMenuItemMargin}
          />
        ))}
        {!hasEmptySideConceptsMenuItem && (
          <LinkText
            fontSize="12px"
            onClick={() => this.onAddMenuItem('Side', nextIndex)}
            label="Add another Side"
          />
        )}

        {id &&
          marketType === 'Group Order' &&
          otherConceptsMenuItems.length > 0 && ( //Allows for GO concept's to be able to remove Non-Entrees but cannot add new menu items
            <div>
              <YSpacing height="30px" />
              <Label>Remove Drinks/Dessert/Appetizers on Menu Card</Label>
              {otherConceptsMenuItems.map((otherConceptMenuItem, i) => (
                <ConceptMenuItemSelectInput
                  key={`sideConceptsMenuItem${i}`}
                  menuItems={[]} //should not be able to add any menu items
                  selectedConceptsMenuItem={otherConceptMenuItem}
                  mainArray={otherConceptsMenuItems}
                  updateArray={(newArray) =>
                    this.onUpdateArray('otherConceptsMenuItems', newArray)
                  }
                  index={i}
                  onChange={(menuItem) =>
                    this.onMenuItemChange(otherConceptMenuItem, menuItem)
                  }
                  pMenuItemMargin={this.props.pMenuItemMargin}
                />
              ))}
            </div>
          )}
        <YSpacing height="30px" />
        <FlexContainer flexDirection="row" justifyContent="center">
          <FlexContainer flexDirection="column">
            <FlexContainer alignItems="center">
              <Label>Estimated Pricing Per Person</Label>
              <TooltipModal
                unicode="&#9432;"
                width="400px"
                information="This the pre tax & pre delivery price per person ($) based on the suggested breakdown for all Catering orders & includes a buffer: 1st protein (50%), 2nd protein (50%), 1 vegetable entree (10%) ,1 starch side (75%), 1 veggie side (75%)"
              />
            </FlexContainer>
            <YSpacing height="10px" />
            <EstimatedNumberDiv>
              <p style={{ color: colors.blue, fontWeight: 'bold' }}>
                {estimatedPPP ? `$${estimatedPPP.toFixed(2)}` : '$0.00'}
              </p>
            </EstimatedNumberDiv>
          </FlexContainer>
          <XSpacing width="25px" />
          <FlexContainer flexDirection="column">
            <FlexContainer alignItems="center">
              <Label>Marketplace Price Per Person</Label>
              <TooltipModal
                unicode="&#9432;"
                width="400px"
                information="This is the average price per person based on item's market price. For items that are priced by servings per package, the servings per package value is used to calculate the price per person."
              />
            </FlexContainer>
            <YSpacing height="10px" />
            <EstimatedNumberDiv>
              {avgPricePerPerson !== null ? (
                <p style={{ color: colors.blue, fontWeight: 'bold' }}>
                  {' '}
                  ${avgPricePerPerson.toFixed(2) || '$0.00'}{' '}
                </p>
              ) : (
                <p>Calculating price per person...</p>
              )}
            </EstimatedNumberDiv>
          </FlexContainer>
          <XSpacing width="25px" />
          <FlexContainer flexDirection="column" alignItems="center">
            <Label>Menu Card Margin</Label>
            <YSpacing height="10px" />
            <EstimatedNumberDiv>
              <p style={{ color: colors.blue, fontWeight: 'bold' }}>
                {' '}
                {margin ? margin.toFixed(2) : '0.00'}%
              </p>
            </EstimatedNumberDiv>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer alignItems="center" justifyContent="flex-end">
          {this.props.menuConcept && this.props.menuConcept.id && (
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <LinkText
                color={colors.violet}
                onClick={this.onDelete}
                label="Delete"
              />
            </AuthorizedInteractable>
          )}
          <XSpacing width="20px" />
          <LinkText
            color={colors.gray300}
            onClick={this.onHide}
            label="Cancel"
          />
          <XSpacing width="20px" />
          <AuthorizedInteractable roles={['master admin', 'chef lead']}>
            <Button onClick={this.onSubmit} label="Save" />
          </AuthorizedInteractable>
        </FlexContainer>
      </Modal>
    )
  }
}

const CheckboxColumn = styled.div`
  column-count: 3;
  break-inside: avoid-column;
  display: inline-block;
`

const EstimatedNumberDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100px;
  height: 70px;
  background-color: ${colors.gray100};
  border-radius: 25px;
`

EditMenuConceptModal.propTypes = {
  locale: PropTypes.string,
  chefId: PropTypes.string,
  errors: PropTypes.object,
  mainMenuItems: PropTypes.arrayOf(PropTypes.object),
  sideMenuItems: PropTypes.arrayOf(PropTypes.object),
  otherMenuItems: PropTypes.arrayOf(PropTypes.object),
  menuConcept: PropTypes.object,
  show: PropTypes.bool,

  clearErrors: PropTypes.func,
  close: PropTypes.func,
  delayedUpdateConcept: PropTypes.func,
  deleteMenuConcept: PropTypes.func,
  filterRemainingItems: PropTypes.func,
  loadChefMenuItems: PropTypes.func,
  loadConceptsSettings: PropTypes.func,
  pCalculateConceptMargin: PropTypes.func,
  pGetMainMenuItems: PropTypes.func,
  pGetSideMenuItems: PropTypes.func,
  pGetOtherMenuItems: PropTypes.func,
  pMenuItemMargin: PropTypes.func,
  saveMenuConcept: PropTypes.func,
  displayWarningMessage: PropTypes.func,
  displayFailureMessage: PropTypes.func,
  getAvgPricePerPerson: PropTypes.func,
  getConceptEstPricePerPerson: PropTypes.func,
}

export default EditMenuConceptModal
