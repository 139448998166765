import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from '@components/common/form/Input'
import LinkText from '@components/common/form/LinkText'
import Button from '@components/common/form/Button'
import YSpacing from '@components/common/YSpacing'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import FlexContainer from '@components/common/FlexContainer'

import { PopUpCustomerModal } from '@containers/popUps'

class PopUpCustomersPage extends Component {
  state = {
    accountMap: {},
    search: '',
    searchLimit: 20,
    searchOffset: 0,
    showEditModal: false,
    customers: [],
    selectedCustomer: undefined,
    sortBy: [undefined, true],
  }
  searchTimer = undefined

  componentDidMount() {
    this.searchCustomers()
  }

  searchCustomers = async () => {
    const { searchPopUpCustomers } = this.props
    const { search, searchLimit, searchOffset } = this.state
    const customers = await searchPopUpCustomers({
      search,
      limit: searchLimit,
      offset: searchOffset,
    })
    if (customers) {
      this.setState({ customers })
    }
  }

  searchAfterTimeout = () => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer)
    }
    this.searchTimer = undefined
    this.searchTimer = setTimeout(() => {
      this.searchCustomers()
    }, 550)
  }

  editCustomer = (customer) => async () => {
    const accountIds = Object.keys((customer && customer.accountRoles) || {})
    const accountMap = await this.loadNewAccounts(accountIds)
    this.setState({
      selectedCustomer: customer,
      showEditModal: true,
      accountMap,
    })
  }

  loadNewAccounts = async (accountIds) => {
    const { accountMap } = this.state
    const newIds = []
    accountIds.forEach((accountId) => {
      if (!accountMap[accountId]) {
        newIds.push(accountId)
      }
    })
    if (newIds.length === 0) {
      return accountMap
    }

    const accounts = await this.props.getIndexAccountsByIds(newIds.join(','))
    if (accounts) {
      accounts.forEach((account) => (accountMap[account.id] = account))
    }

    return accountMap
  }

  addAccountsToAccountMap = (accounts) => {
    const { accountMap } = this.state
    accounts.forEach((account) => (accountMap[account.id] = account))
    this.setState({ accountMap })
  }

  hideEditModal = () => this.setState({ showEditModal: false })

  sortCustomers = (attribute, transform = (a) => a) => {
    const { customers, sortBy } = this.state
    const asc = attribute !== sortBy[0] || !sortBy[1]
    this.setState({
      sortBy: [attribute, asc],
      customers: customers.sort((a, b) => {
        if (transform(a[attribute]) > transform(b[attribute])) {
          return asc ? 1 : -1
        }
        if (transform(a[attribute]) < transform(b[attribute])) {
          return asc ? -1 : 1
        }

        return 0
      }),
    })
  }

  render() {
    const { theme } = this.props
    const {
      accountMap,
      customers,
      search,
      searchLimit,
      searchOffset,
      selectedCustomer,
      showEditModal,
    } = this.state

    return (
      <Panel width="100%" maxWidth="800px" heading="Pop Up Customers">
        {showEditModal && (
          <PopUpCustomerModal
            popUpCustomer={selectedCustomer}
            theme={theme}
            hideModal={this.hideEditModal}
            searchCustomers={this.searchCustomers}
            accountMap={accountMap}
            addAccountsToAccountMap={this.addAccountsToAccountMap}
          />
        )}
        <FlexContainer justifyContent="space-between" alignItems="flex-end">
          <Input
            label="Search Customers"
            marginBottom="0"
            width="200px"
            type="text"
            value={search}
            onChange={(e) =>
              this.setState(
                {
                  search: e.target.value,
                  searchOffset: 0,
                },
                this.searchAfterTimeout,
              )
            }
          />
          <Button label="Create Customer" onClick={this.editCustomer()} />
        </FlexContainer>
        <YSpacing height="20px" />
        <Table>
          <tr>
            <th onClick={() => this.sortCustomers('firstName')}>First Name</th>
            <th onClick={() => this.sortCustomers('lastName')}>Last Name</th>
            <th onClick={() => this.sortCustomers('email')}>Email</th>
            <th onClick={() => this.sortCustomers('phone')}>Phone</th>
            <th onClick={() => this.sortCustomers('primaryZip')}>
              Primary Zipcode
            </th>
            <th />
          </tr>
          {customers.map((customer) => (
            <tr key={customer.id}>
              <td>
                <p>{customer.firstName}</p>
              </td>
              <td>
                <p>{customer.lastName}</p>
              </td>
              <td>
                <p>{customer.email}</p>
              </td>
              <td>
                <p>{customer.phone}</p>
              </td>
              <td>
                <p>{customer.primaryZip}</p>
              </td>
              <td>
                <LinkText label="Edit" onClick={this.editCustomer(customer)} />
              </td>
            </tr>
          ))}
        </Table>
        {searchOffset > 0 && (
          <button
            onClick={() =>
              this.setState(
                { searchOffset: searchOffset - searchLimit },
                this.searchCustomers,
              )
            }
          >
            &lt;
          </button>
        )}
        Page {1 + Math.floor(parseFloat(searchOffset / searchLimit))}
        {searchLimit === customers.length && (
          <button
            onClick={() =>
              this.setState(
                { searchOffset: searchOffset + searchLimit },
                this.searchCustomers,
              )
            }
          >
            &gt;
          </button>
        )}
      </Panel>
    )
  }
}

PopUpCustomersPage.propTypes = {
  headquarter: PropTypes.number,
  theme: PropTypes.object,

  searchPopUpCustomers: PropTypes.func,
  getIndexAccountsByIds: PropTypes.func,
}

export default PopUpCustomersPage
