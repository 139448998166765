import React from 'react'

const LoadingIndicator = () => {
  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n.loader {\n  margin:60px; \nborder: 8px solid #f3f3f3;\n  border-radius: 50%;\n  border-top: 8px solid #3498db;\n  width: 60px;\n  height: 60px;\n  -webkit-animation: spin 2s linear infinite;\n  animation: spin 1s linear infinite;\n}\n\n@-webkit-keyframes spin {\n  0% { -webkit-transform: rotate(0deg); }\n  100% { -webkit-transform: rotate(360deg); }\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n',
        }}
      />
      <center>
        <div className="loader" />
      </center>
    </div>
  )
}

export default LoadingIndicator
