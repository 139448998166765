import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import { DateInput } from '@components/common/form'

class MLBInventoryPage extends Component {
  state = {
    menu: {
      kits: [],
      items: [],
    },
    date: undefined,
  }

  componentDidMount() {
    this.setState({ date: this.props.defaultDate }, this.loadMenu)
  }

  componentWillReceiveProps(nextProps) {
    const { date } = nextProps
    if (!this.props.date || !this.props.date.isSame(date)) {
      this.setState({ date }, this.loadMenu)
    }
  }

  loadMenu = async () => {
    const { date } = this.state
    const { defaultZip, loadMLBMenu, mlbPartnerName } = this.props
    if (date) {
      const menu = await loadMLBMenu({
        mlbPartnerName,
        mDate: date,
        zipCode: defaultZip,
      })
      if (menu) {
        this.setState({ menu })
      }
    }
  }

  render() {
    const {
      menu: { kits, items },
      date,
    } = this.state
    const { setGameDay } = this.props

    return (
      <div className="mlb-panel">
        <div className="flex flex-row">
          <div>
            <p className="mb-1 bold">Game Day</p>
            <DateInput
              className="w-full"
              date={date}
              dateFormat="default"
              isStatic={false}
              onChange={(date) => this.setState({ date }, setGameDay(date))}
            />
          </div>
        </div>
        <table className="w-full border partners-table">
          <tr className="bg-gray-200">
            <th className="py-1 pr-4 bold uppercase">Name</th>
            <th className="py-1 pr-4 bold uppercase">Type</th>
            <th className="py-1 pr-4 bold uppercase">Price</th>
            <th className="py-1 pr-4 bold uppercase">Active</th>
            <th className="py-1 pr-4 bold uppercase">Svgs</th>
            <th className="py-1 pr-4 bold uppercase whitespace-no-wrap">
              Avail From
            </th>
            <th className="py-1 pr-4 bold uppercase whitespace-no-wrap">
              Avail To
            </th>
            <th className="py-1 pr-4 bold uppercase whitespace-no-wrap">
              # Sold
            </th>
            <th className="py-1 pr-4 bold uppercase">Limit</th>
          </tr>
          {kits.map((kit, i) => (
            <tr key={i}>
              <td className="pr-4">
                <div className="bold mb-1">{kit.name}</div>
              </td>
              <td className="pr-4">
                <div>Pack</div>
              </td>
              <td className="pr-4">
                <div>{kit.price && `$${parseFloat(kit.price).toFixed(2)}`}</div>
              </td>
              <td className="pr-4">
                <div>{kit.isEnabled ? 'Active' : 'Inactive'}</div>
              </td>
              <td className="pr-4">
                <div>
                  {kit.minServings} - {kit.maxServings}
                </div>
              </td>
              <td className="pr-4">
                <div>
                  {kit.availableWindow &&
                    kit.availableWindow.start &&
                    Moment(kit.availableWindow.start).format(
                      'MM-DD-YYYY h:mma z',
                    )}
                </div>
              </td>
              <td className="pr-4">
                <div>
                  {kit.availableWindow &&
                    kit.availableWindow.end &&
                    Moment(kit.availableWindow.end).format(
                      'MM-DD-YYYY h:mma z',
                    )}
                </div>
              </td>
              <td className="pr-4">
                <div>{kit.count}</div>
              </td>
              <td className="pr-4">
                <div>
                  {kit.availableWindow && kit.availableWindow.orderLimit}
                </div>
              </td>
            </tr>
          ))}
          {items
            .filter((it) => it.addOn)
            .map((item, i) => (
              <tr key={i}>
                <td className="pr-4">
                  <div className="bold mb-1">{item.name}</div>
                </td>
                <td className="pr-4">
                  <div className="whitespace-no-wrap">Add On</div>
                </td>
                <td className="pr-4">
                  <div>
                    {item.price && `$${parseFloat(item.price).toFixed(2)}`}
                  </div>
                </td>
                <td>
                  <div>{item.isEnabled ? 'Active' : 'Inactive'}</div>
                </td>
                <td>
                  <div>
                    {item.minServings} - {item.maxServings}
                  </div>
                </td>
                <td>
                  <div>
                    {item.availableWindow &&
                      item.availableWindow.start &&
                      Moment(item.availableWindow.start).format(
                        'MM-DD-YYYY h:mma z',
                      )}
                  </div>
                </td>
                <td>
                  <div>
                    {item.availableWindow &&
                      item.availableWindow.end &&
                      Moment(item.availableWindow.end).format(
                        'MM-DD-YYYY h:mma z',
                      )}
                  </div>
                </td>
                <td>
                  <div>{item.count}</div>
                </td>
                <td>
                  <div>
                    {item.availableWindow && item.availableWindow.orderLimit}
                  </div>
                </td>
              </tr>
            ))}
        </table>
      </div>
    )
  }
}

MLBInventoryPage.propTypes = {
  depot: PropTypes.object,
  defaultZip: PropTypes.string,
  defaultDate: PropTypes.object,
  date: PropTypes.object,
  mlbPartnerName: PropTypes.string,

  setGameDay: PropTypes.func,
  loadMLBMenu: PropTypes.func,
}

export default MLBInventoryPage
