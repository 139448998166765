import * as chefEmailsCoordinators from './chefEmails'
import * as clientSettingsCoordinators from './clientSettings'
import * as clientGlobalSettingsCoordinators from './clientGlobalSettings'
import * as dateMenusCoordinators from './dateMenus'
import * as groupOrdersCoordinators from './groupOrders'
import * as menuCoordinators from './menus'

export default Object.freeze({
  ...chefEmailsCoordinators,
  ...clientSettingsCoordinators,
  ...clientGlobalSettingsCoordinators,
  ...dateMenusCoordinators,
  ...groupOrdersCoordinators,
  ...menuCoordinators,
})
