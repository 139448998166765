import {
  CLEAR_EDIT_SERVICE_ITEM,
  SET_EDIT_SERVICE_ITEM,
  SET_SERVICE_ITEMS,
  UPDATE_EDIT_SERVICE_ITEM,
} from '@actions/serviceVendor'

const initialEditServiceItemState = {}
const initialServiceItems = []

export function editServiceItem(state = initialEditServiceItemState, action) {
  const { type, serviceItem } = action

  switch (type) {
    case CLEAR_EDIT_SERVICE_ITEM:
      return { ...initialEditServiceItemState }
    case SET_EDIT_SERVICE_ITEM:
      return { ...serviceItem }
    case UPDATE_EDIT_SERVICE_ITEM:
      return { ...state, ...serviceItem }
    default:
      return state
  }
}

export function serviceItems(state = initialServiceItems, action) {
  const { type, serviceItems } = action
  switch (type) {
    case SET_SERVICE_ITEMS:
      return serviceItems
    default:
      return state
  }
}
