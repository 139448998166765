export const LoadPackaging =
  ({ RestService, SupplyService, pResponsePackaging, HandleError }) =>
  async () => {
    try {
      let packaging = await RestService.get('/api/admin/packaging')
      packaging = pResponsePackaging(packaging)
      SupplyService.setPackaging(packaging)
    } catch (error) {
      HandleError({ error, namespace: 'packaging' })
    }
  }

export const LoadPackagingAddOns =
  ({ RestService, SupplyService, pResponsePackagingAddOns, HandleError }) =>
  async () => {
    try {
      let packagingAddOns = await RestService.get(
        '/api/admin/packaging_add_ons',
      )
      packagingAddOns = pResponsePackagingAddOns(packagingAddOns)
      SupplyService.setPackagingAddOns(packagingAddOns)
    } catch (error) {
      HandleError({ error, namespace: 'packagingAddOns' })
    }
  }

export const LoadServingUtensils =
  ({ RestService, SupplyService, pResponseServingUtensils, HandleError }) =>
  async () => {
    try {
      let servingUtensils = await RestService.get('/api/admin/serving_utensils')
      servingUtensils = pResponseServingUtensils(servingUtensils)
      SupplyService.setServingUtensils(servingUtensils)
    } catch (error) {
      HandleError({ error, namespace: 'servingUtensils' })
    }
  }

export const LoadCateringSupplies =
  ({ RestService, SupplyService, pResponseCateringSupplies, HandleError }) =>
  async () => {
    try {
      let cateringSupplies = await RestService.get(
        '/api/admin/catering_supplies',
      )
      cateringSupplies = pResponseCateringSupplies(cateringSupplies)
      SupplyService.setCateringSupplies(cateringSupplies)
    } catch (error) {
      HandleError({ error, namespace: 'cateringSupplies' })
    }
  }

export const LoadDiningSupplies =
  ({ RestService, SupplyService, pResponseDiningSupplies, HandleError }) =>
  async () => {
    try {
      let diningSupplies = await RestService.get('/api/admin/dining_supplies')
      diningSupplies = pResponseDiningSupplies(diningSupplies)
      SupplyService.setDiningSupplies(diningSupplies)
    } catch (error) {
      HandleError({ error, namespace: 'diningSupplies' })
    }
  }

export const LoadOrderableSupplyBreakdown =
  ({
    RestService,
    pRequestOrderSupplyBreakdown,
    pResponseOrderSupplyBreakdown,
  }) =>
  async (data) => {
    const req = pRequestOrderSupplyBreakdown(data)
    const supplyBreakdown = await RestService.put(
      '/api/admin/order_supplies/supply_breakdown',
      req,
    )

    return pResponseOrderSupplyBreakdown(supplyBreakdown)
  }

export const DelayedLoadOrderableSupplyBreakdown =
  ({
    RestService,
    UIService,
    pRequestOrderSupplyBreakdown,
    pResponseOrderSupplyBreakdown,
  }) =>
  async (data) => {
    const call = async () => {
      return await LoadOrderableSupplyBreakdown({
        RestService,
        pRequestOrderSupplyBreakdown,
        pResponseOrderSupplyBreakdown,
      })(data)
    }

    return await UIService.Timer.callAfterTimeout(call)
  }

export const LoadInventoryTransactions =
  ({
    RestService,
    UIService,
    pRequestInventoryTransactions,
    pResponseInventoryTransactions,
    HandleError,
  }) =>
  async (orderSupplyId, date) => {
    try {
      const req = pRequestInventoryTransactions(orderSupplyId, date)
      let response = await RestService.get(
        '/api/admin/inventory_transactions',
        req,
      )
      response = pResponseInventoryTransactions(response)
      let message
      if (response.length === 0) {
        message = 'No supplies used this day.'
      } else {
        const total = response.reduce((accumulator, transaction) => {
          return accumulator + transaction.quantity
        }, 0)
        message = `Total: ${total}\n`
        message += response
          .map((transaction) => transaction.description)
          .join('\n')
      }
      const title = `Inventory Transaction Breakdown - ${date}`

      UIService.Alert.showPopup({ message, title })
    } catch (error) {
      HandleError({ error, namespace: 'inventories' })
    }
  }

export const LoadInventories =
  ({ RestService, SupplyService, pResponseInventories, HandleError }) =>
  async () => {
    try {
      const response = await RestService.get('/api/admin/inventories')
      const inventories = pResponseInventories(response)
      SupplyService.setInventories(inventories)
    } catch (error) {
      HandleError({ error, namespace: 'inventories' })
    }
  }

export const ProcessTransactions =
  ({ RestService, SupplyService, pResponseInventories, HandleError }) =>
  async () => {
    try {
      await RestService.put(
        '/api/admin/inventory_transactions/process_transactions',
      )
      await LoadInventories({
        RestService,
        SupplyService,
        pResponseInventories,
        HandleError,
      })()
    } catch (error) {
      HandleError({ error, namespace: 'inventories' })
    }
  }
