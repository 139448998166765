import { BaseService } from 'hungry-core2'

import actions from '@actions'

export default class Alert extends BaseService {
  showPopup = ({ message, title, options }) => {
    this.dispatch(actions.showPopupAlert({ message, title, options }))
  }

  closePopup = () => {
    this.dispatch(actions.hidePopupAlert())
  }
}
