import { connect } from 'react-redux'

import { OrderStatus } from '@components/chefNetwork'
import coordinators from '@coordinators'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = () => undefined

const mapDispatchToProps = () => {
  const { RestService } = services
  const { ChefNetworkReviewedOrder, LoadChefOrderStatus } = coordinators
  const loadChefOrderStatus = LoadChefOrderStatus({
    RestService,
    HandleError,
  })

  const chefNetworkReviewedOrder = ChefNetworkReviewedOrder({
    RestService,
    HandleError,
  })

  return {
    chefNetworkReviewedOrder,
    loadChefOrderStatus,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatus)
