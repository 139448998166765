import Moment from 'moment-timezone'

import { camelCaseify, formAdd } from '~/utils'

import { addPossibleNullDate } from './order'

export const pResponseBatchOrders = (json) => {
  const batchOrders = camelCaseify(json)
  batchOrders.forEach((order) => {
    order.chefName = order.chefsString
    order.dateStr = `${Moment(order.startDate).format('MM/DD')} - ${Moment(
      order.endDate,
    ).format('MM/DD')}`
    order.total = parseFloat(order.total).toFixed(2)
  })

  return batchOrders
}

export const pResponseBatchOrder = (json) => {
  const batchOrder = camelCaseify(json)

  batchOrder.startDate = batchOrder.startDate && Moment(batchOrder.startDate)
  batchOrder.endDate = batchOrder.endDate && Moment(batchOrder.endDate)
  batchOrder.chargeDate = batchOrder.chargeDate && Moment(batchOrder.chargeDate)

  batchOrder.isRecurring = batchOrder.isNet
  batchOrder.startDateStr =
    batchOrder.startDate && Moment(batchOrder.startDate).format('MM/DD')
  batchOrder.endDateStr =
    batchOrder.endDate && Moment(batchOrder.endDate).format('MM/DD')
  batchOrder.dateStr =
    batchOrder.startDate &&
    batchOrder.endDate &&
    `${Moment(batchOrder.startDate).format('MM/DD')} - ${Moment(
      batchOrder.endDate,
    ).format('MM/DD')}`
  batchOrder.chargeDateStr =
    batchOrder.chargeDate && batchOrder.chargeDate.format('MM/DD')

  const pm = batchOrder.paymentMethod
  batchOrder.paymentMethodStr =
    pm &&
    `${pm.cardholderName || 'Name'} - ${pm.cardType || 'Card Type'} - Last 4${
      pm.last4 && ' *' + pm.last4
    } - ${pm.expirationDate || 'Exp Date'}`

  batchOrder.contactStr =
    (batchOrder.contact && batchOrder.contact.formattedDescription) ||
    'None Selected'

  batchOrder.amountPaid = parseFloat(batchOrder.amountPaid || 0).toFixed(2)
  batchOrder.subtotal = parseFloat(batchOrder.subtotal || 0).toFixed(2)
  batchOrder.serviceFee =
    batchOrder.serviceFee && parseFloat(batchOrder.serviceFee || 0).toFixed(2)
  batchOrder.tax = parseFloat(batchOrder.tax || 0).toFixed(2)
  batchOrder.tip = parseFloat(batchOrder.tip || 0).toFixed(2)
  batchOrder.total = parseFloat(batchOrder.total || 0).toFixed(2)

  batchOrder.orders = (batchOrder.orders || []).map((o) =>
    pResponseChildOrder(o),
  )

  const ae = batchOrder.accountExecutive
  if (ae) {
    ae.name = [ae.firstName, ae.lastName].filter((o) => o).join(' ')
  }

  batchOrder.audits.forEach((a) => {
    a.createdAt = Moment(a.createdAt).format('MM-DD-YY @ h:mma')
    a.auditedChanges = a.auditedChanges && JSON.stringify(a.auditedChanges)
  })
  batchOrder.transactions.forEach((t) => {
    t.createdAt = Moment(t.createdAt).format('MM-DD-YY @ h:mma')
    t.amount = parseFloat(t.amount || 0).toFixed(2)
    t.serviceFeeAmount = parseFloat(t.serviceFeeAmount || 0).toFixed(2)
  })

  return batchOrder
}

export const pResponseChildOrder = (order) => {
  order.eventDate = order.eventDate && Moment(order.eventDate)
  order.clientSetUpTime = order.clientSetUpTime && Moment(order.clientSetUpTime)
  order.chargeDate = order.chargeDate && Moment(order.chargeDate)

  order.eventDateStr = order.clientSetUpTime.format(
    'dddd, MMMM Do YYYY, h:mm:ss a',
  )
  order.dateStr = order.clientSetUpTime && order.clientSetUpTime.format('LLLL')
  order.chargeDateStr = order.chargeDate && order.chargeDate.format('LLLL')

  const pm = order.paymentMethod
  order.paymentMethodStr =
    pm &&
    `${pm.cardholderName || 'Name'} - ${pm.cardType || 'Card Type'} - Last 4${
      pm.last4 && ' *' + pm.last4
    } - ${pm.expirationDate || 'Exp Date'}`

  order.contactStr =
    (order.contact && order.contact.formattedDescription) || 'None Selected'
  order.invoiceContactStr =
    (order.invoiceContact && order.invoiceContact.formattedDescription) ||
    'None Selected'
  order.receiptContactStr =
    (order.receiptContact && order.receiptContact.formattedDescription) ||
    'None Selected'

  order.paidAmount = parseFloat(order.paidAmount || 0).toFixed(2)
  order.subtotal = parseFloat(order.subtotal || 0).toFixed(2)
  order.serviceFee = parseFloat(order.serviceFee || 0).toFixed(2)
  order.tax = parseFloat(order.tax || 0).toFixed(2)
  order.tip = parseFloat(order.tip || 0).toFixed(2)
  order.total = parseFloat(order.total || 0).toFixed(2)

  const ae = order.accountExecutive
  if (ae) {
    ae.name = [ae.firstName, ae.lastName].filter((o) => o).join(' ')
  }

  return order
}

export const pResponseEditBatchOrder = (json) => {
  const batchOrder = camelCaseify(json)

  batchOrder.chargeDate = batchOrder.chargeDate && Moment(batchOrder.chargeDate)
  batchOrder.startDate = batchOrder.startDate && Moment(batchOrder.startDate)
  batchOrder.endDate = batchOrder.endDate && Moment(batchOrder.endDate)
  batchOrder.amountPaid = parseFloat(batchOrder.amountPaid || 0)
  batchOrder.serviceFee = parseFloat(batchOrder.serviceFee || 0)
  batchOrder.subtotal = parseFloat(batchOrder.subtotal || 0)
  batchOrder.tax = parseFloat(batchOrder.tax || 0)
  batchOrder.tip = parseFloat(batchOrder.tip || 0)
  batchOrder.total = parseFloat(batchOrder.total || 0)

  const ae = batchOrder.accountExecutive
  if (ae) {
    ae.name = [ae.firstName, ae.lastName].filter((o) => o).join(' ')
  }

  batchOrder.orders = (batchOrder.orders || []).map((o) =>
    pResponseChildOrder(o),
  )

  return batchOrder
}

export const pResponseBatchOrderError = (e) => {
  let errors = {}
  const errorMessage = 'Please check the errors below'

  const messageMap = {
    account: {
      messages: {
        "can't be blank": 'Select an account',
      },
    },
    contact: {
      target: 'cateringContact',
      messages: {
        "can't be blank": 'Select a catering contact',
      },
    },
    'Charge Date': {
      messages: {
        'cannot be in the past': 'Select a charge date in the in the future',
      },
    },
  }

  if (e.response && e.response.data && e.response.data.errors) {
    const { errors: errors_ } = camelCaseify(e.response.data)
    for (let k in errors_) {
      if (Array.isArray(errors_[k])) {
        const mappedMsg = messageMap[k]
        let msg = errors_[k][0]
        k = (mappedMsg && mappedMsg.target) || k
        msg = (mappedMsg && mappedMsg.messages[msg]) || msg
        errors[k] = msg
      }
    }
  } else {
    errors = e
  }

  return {
    errors,
    errorMessage,
  }
}

export const pRequestUpdateBatchOrderPaymentMethod = (data) => {
  const req = {}
  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'account.id', 'account_id')

  const { paymentMethod } = data
  req.payment_method_id = paymentMethod ? paymentMethod.id : null

  return req
}

export const pRequestUpdateBatchOrder = (data) => {
  const req = {}

  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'account.id', 'account_id')

  const { paymentMethod } = data
  req.payment_method_id = paymentMethod ? paymentMethod.id : null

  formAdd(data, req, 'netOffset', 'net_offset')
  formAdd(data, req, 'contact.id', 'contact_id')
  formAdd(data, req, 'startDate', 'start_date', (v) => addPossibleNullDate(v))
  formAdd(data, req, 'endDate', 'end_date', (v) => addPossibleNullDate(v))
  formAdd(data, req, 'chargeDate', 'charge_date', (v) => addPossibleNullDate(v))
  formAdd(data, req, 'purchaseOrderNumber', 'purchase_order_number')
  formAdd(data, req, 'accountExecutive.id', 'account_executive_id')
  formAdd(data, req, 'billingAddress.id', 'billing_address_id')
  // formAdd( data, req, 'tip', 'tip', v => (v || 0).toFixed( 2 ))

  /* childOrders */
  const order_ids = []
  const orders_attributes = []
  data.childOrders.forEach((order) => {
    order_ids.push(order.id)
  })
  req.order_ids = order_ids

  // find deleted items
  if (data.originalChildOrderIds) {
    const originalChildOrderIds = data.originalChildOrderIds.slice()
    req.order_ids &&
      req.order_ids.forEach((id) => {
        const idx = originalChildOrderIds.indexOf(id)
        if (idx !== -1) {
          originalChildOrderIds.splice(idx, 1)
        }
      })
    originalChildOrderIds.forEach((id) => {
      req.order_ids.push(id)
      orders_attributes.push({ id, order_batch_id: null })
    })
    req.orders_attributes = orders_attributes
  }

  return req
}

/*
 *
 *   ADHOC ACCOUNT INFO SAVING ON NEW ORDER
 *
 */

export const pRequestBatchOrdersFilter = (filters) => {
  filters = { ...filters }
  const { search } = filters
  const m = search.match(/(\d{2})[-/.](\d{2})[-/.](\d{4})/)
  if (search && m) {
    filters.search = `start_date:${m[3]}-${m[1]}-${m[2]}`
  }

  return filters
}

export const pRequestBatchChildOrder = ({ order, batchOrderId }) => {
  return {
    id: order.id,
    order_batch_id: batchOrderId,
  }
}

export const pRequestPayBatchOrderWithExternal = (
  order_batch_id,
  source,
  amount,
  is_refund,
) => {
  let json_source = ''
  switch (source) {
    case 'Manual Braintree':
      json_source = 'braintree'
      break
    case 'ACH':
      json_source = 'ach'
      break
    case 'Check':
      json_source = 'check'
      break
    case 'Braintree':
      json_source = 'automated'
      break
  }
  if (is_refund) {
    json_source = 'automated'
  }

  return { order_batch_id, payment_source: json_source, amount, is_refund }
}
