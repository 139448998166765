import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from '@components/common/form'
import { TechHelpForm } from '@components/common'

const ResetPass = (props) => {
  const [formData, setFormData] = useState({
    password: '',
    confirmpass: '',
    error: '',
  })

  const onChange = (e) => {
    const newData = { ...formData, [e.target.id]: e.target.value }

    if (
      newData.confirmpass !== '' &&
      newData.password !== newData.confirmpass
    ) {
      newData.error = 'Password does not match'
    } else {
      newData.error = ''
    }

    setFormData(newData)
  }

  const submit = (e) => {
    e.preventDefault()

    props.resetPass({
      password: formData.password,
      token: props.token,
    })
  }

  const isDisabled = formData.confirmpass === '' || formData.error !== ''

  return (
    <div className="login-container">
      <div className="login-container__form">
        <h2> Reset Password </h2>
        <form onSubmit={submit}>
          <Input
            type="password"
            testId="password"
            label="Password"
            placeholder="Password"
            onChange={onChange}
            value={formData.password}
          />
          <Input
            type="password"
            testId="confirmpass"
            label="Confirm Password"
            placeholder="Confirm password"
            onChange={onChange}
            value={formData.confirmpass}
            error={formData.error}
          />
          <input type="submit" value="Continue" disabled={isDisabled} />
        </form>
        <TechHelpForm />
      </div>
    </div>
  )
}

ResetPass.propTypes = {
  token: PropTypes.string,
  resetPass: PropTypes.func,
}

export default ResetPass
