import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PopUpDiscountModal } from '@containers/popUps'
import Input from '@components/common/form/Input'
import Button from '@components/common/form/Button'
import LinkText from '@components/common/form/LinkText'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'

class PopUpDiscountsPage extends Component {
  state = {
    search: '',
    searchLimit: 20,
    searchOffset: 0,
    showEditModal: false,
    discounts: [],
    selectedDiscount: undefined,
    sortBy: [undefined, true],
  }
  searchTimer = undefined

  componentDidMount() {
    this.searchDiscounts()
  }

  searchDiscounts = async () => {
    const { headquarter, searchPopUpDiscounts } = this.props
    const { search, searchLimit, searchOffset } = this.state
    const discounts = await searchPopUpDiscounts({
      headquarter,
      search,
      limit: searchLimit,
      offset: searchOffset,
    })
    if (discounts) {
      this.setState({ discounts })
    }
  }

  searchAfterTimeout = () => {
    if (this.timer) {
      clearTimeout(this.searchTimer)
    }
    this.searchTimer = undefined
    this.searchTimer = setTimeout(() => {
      this.searchDiscounts()
    }, 550)
  }

  editDiscount = (discount) => () => {
    this.setState({ selectedDiscount: discount, showEditModal: true })
  }

  hideEditModal = () => this.setState({ showEditModal: false })

  sortDiscounts = (attribute, transform = (a) => a) => {
    const { discounts, sortBy } = this.state
    const asc = attribute !== sortBy[0] || !sortBy[1]
    this.setState({
      sortBy: [attribute, asc],
      discounts: discounts.sort((a, b) => {
        if (transform(a[attribute]) > transform(b[attribute])) {
          return asc ? 1 : -1
        }
        if (transform(a[attribute]) < transform(b[attribute])) {
          return asc ? -1 : 1
        }

        return 0
      }),
    })
  }

  render() {
    const { theme } = this.props
    const {
      discounts,
      search,
      searchLimit,
      searchOffset,
      selectedDiscount,
      showEditModal,
    } = this.state

    return (
      <Panel width="100%" maxWidth="500px" heading="Discounts">
        {showEditModal && (
          <PopUpDiscountModal
            discountLedger={selectedDiscount}
            theme={theme}
            hideModal={this.hideEditModal}
            searchPopUpDiscounts={this.searchDiscounts}
          />
        )}
        <FlexContainer alignItems="flex-end">
          <Input
            label="Search Pop Ups"
            marginBottom="0"
            width="200px"
            type="text"
            value={search}
            onChange={(e) =>
              this.setState(
                {
                  search: e.target.value,
                  searchOffset: 0,
                },
                this.searchAfterTimeout,
              )
            }
          />
          <XSpacing width="20px" />
          <Button
            label="New Discount"
            onClick={() =>
              this.setState({
                showEditModal: true,
                selectedDiscount: undefined,
              })
            }
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <Table>
          <tr>
            <th onClick={() => this.sortDiscounts('name')}>Name</th>
            <th onClick={() => this.sortDiscounts('discountType')}>Type</th>
            <th
              onClick={() =>
                this.sortDiscounts('value', (disc) => parseFloat(disc.value))
              }
            >
              Value
            </th>
            <th onClick={() => this.sortDiscounts('enabled')}>Enabled</th>
            <th />
          </tr>
          {discounts.map((discount) => (
            <tr key={discount.id}>
              <td>
                <p>{discount.name}</p>
              </td>
              <td>
                <p>{discount.discountType}</p>
              </td>
              <td>
                <p>
                  {discount.discountType === 'Percent' ? '%' : '$'}
                  {parseFloat(discount.value).toFixed(2)}
                </p>
              </td>
              <td>
                <p>{discount.enabled ? 'Enabled' : 'Disabled'}</p>
              </td>
              <td>
                <LinkText label="Edit" onClick={this.editDiscount(discount)} />
              </td>
            </tr>
          ))}
        </Table>
        <YSpacing height="20px" />
        {searchOffset > 0 && (
          <button
            onClick={() =>
              this.setState(
                { searchOffset: searchOffset - searchLimit },
                this.searchDiscounts,
              )
            }
          >
            &lt;
          </button>
        )}
        Page {1 + Math.floor(parseFloat(searchOffset / searchLimit))}
        {searchLimit === discounts.length && (
          <button
            onClick={() =>
              this.setState(
                { searchOffset: searchOffset + searchLimit },
                this.searchDiscounts,
              )
            }
          >
            &gt;
          </button>
        )}
      </Panel>
    )
  }
}

PopUpDiscountsPage.propTypes = {
  headquarter: PropTypes.number,
  theme: PropTypes.object,

  searchPopUpDiscounts: PropTypes.func,
}

export default PopUpDiscountsPage
