import { connect } from 'react-redux'

import { VirtualKits } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { chef } = state

  const virtualKits = chef && chef.virtualKits
  const chefId = chef && chef.id

  return {
    chefId,
    virtualKits,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, UIService } = services
  const { pDefaultVirtualKitFields } = presenters
  const { pResponseVirtualKit, pResponseGeneric } = presenters.Api
  const {
    DelayedUpdateVirtualKit,
    EditVirtualKit,
    LoadChefVirtualKits,
    NewVirtualKit,
  } = coordinators

  const editVirtualKit = EditVirtualKit({
    ChefService,
    RestService,
    UIService,
    pResponseVirtualKit,
  })
  const loadChefVirtualKits = LoadChefVirtualKits({
    ChefService,
    RestService,
    pResponseVirtualKits: pResponseGeneric,
  })
  const newVirtualKit = NewVirtualKit({
    ChefService,
    UIService,
    pDefaultVirtualKitFields,
  })
  const delayedUpdateVirtualKit = DelayedUpdateVirtualKit({
    ChefService,
    UIService,
  })

  return {
    delayedUpdateVirtualKit,
    editVirtualKit,
    loadChefVirtualKits,
    newVirtualKit,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VirtualKits)
