import React, { useEffect, useState, useCallback } from 'react'
import Moment from 'moment-timezone'
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa'

import { LoadingIndicator } from '@components/common'
import {
  Dropdown,
  DateTextInput,
  AutocompleteInput,
} from '@components/common/form'
import Table from '@components/common/Table'
import { TooltipModal } from '@components/common/modal'
import { AuthorizedInteractable } from '@containers/common/auth'

import { snakeCaseify } from '../../../utils'
import { NotificationOrder } from '@types'

interface SendAutoInvoiceOrdersReq {
  timezone: string
  date: string
  orderIds?: string[]
}

interface GetAutoInvoiceOrdersReq {
  clientId?: string
  timezone: string
  date: string
  fromDate?: string
  page: number
  resultsPerPage: number
}

interface AutoInvoiceTabProps {
  authRoles: string[]
  timezones: { [key: string]: string }[]
  getNextCronTime: (params: {
    timezone: string
    cron_job: string
  }) => Promise<string>
  getAutoInvoiceOrders: (
    params: GetAutoInvoiceOrdersReq,
  ) => Promise<NotificationOrder[]>
  sendAutoInvoiceOrders: (params: SendAutoInvoiceOrdersReq) => Promise<void>
  searchAccounts: (filters: {
    search?: string
  }) => Promise<{ id: string; name: string }[]>
}

const Limit = 50

const AutoInvoiceTab: React.FC<AutoInvoiceTabProps> = ({
  authRoles,
  timezones,
  getNextCronTime,
  getAutoInvoiceOrders,
  sendAutoInvoiceOrders,
  searchAccounts,
}) => {
  const [selectedTimezone, setSelectedTimezone] = useState<{
    railsTz: string
    jsTz: string
  }>({
    railsTz: 'Eastern Time (US & Canada)',
    jsTz: 'America/New_York',
  })
  const [nextCronTime, setNextCronTime] = useState<string | null>(null)
  const [orders, setOrders] = useState<NotificationOrder[]>([])
  const [selectedOrders, setSelectedOrders] = useState<{
    [key: string]: boolean
  }>({})
  const [page, setPage] = useState(1)
  const [fromDate, setFromDate] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<{
    id: string
    name: string
  } | null>(null)

  useEffect(() => {
    const fetchNextCronTime = async () => {
      const nextCronTime = await getNextCronTime({
        timezone: encodeURIComponent(selectedTimezone.railsTz),
        cron_job: 'auto_invoices',
      })
      setNextCronTime(nextCronTime)
      setFromDate(nextCronTime)
    }

    fetchNextCronTime()
  }, [selectedTimezone, getNextCronTime])

  const loadOrders = useCallback(async () => {
    if (nextCronTime === null) {
      return
    }
    setLoading(true)

    const req: GetAutoInvoiceOrdersReq = {
      timezone: encodeURIComponent(selectedTimezone.railsTz),
      date: fromDate ?? nextCronTime,
      page,
      resultsPerPage: Limit,
    }
    if (selectedAccount) {
      req.clientId = selectedAccount.id
    }
    const orders = await getAutoInvoiceOrders(req)

    setOrders(orders)
    setLoading(false)
  }, [
    nextCronTime,
    selectedTimezone,
    selectedAccount,
    fromDate,
    page,
    getAutoInvoiceOrders,
  ])

  useEffect(() => {
    loadOrders()
  }, [loadOrders])

  const onSendTestOrders = async () => {
    if (nextCronTime === null) {
      return
    }
    const req: SendAutoInvoiceOrdersReq = {
      timezone: encodeURIComponent(selectedTimezone.railsTz),
      date: fromDate ?? nextCronTime,
    }
    if (Object.keys(selectedOrders).length > 0) {
      req.orderIds = Object.keys(selectedOrders)
    }
    await sendAutoInvoiceOrders(req)
    await loadOrders()
    setSelectedOrders({})
  }

  const onSelectOrder = (id: string) => {
    const newOrders = { ...selectedOrders }
    if (selectedOrders[id]) {
      delete newOrders[id]
    } else {
      newOrders[id] = true
    }
    setSelectedOrders(newOrders)
  }

  const changePage = (dir: number) => {
    if (dir === -1 && page > 1) {
      setPage(page - 1)
    } else if (dir === 1 && orders.length === Limit) {
      setPage(page + 1)
    }
  }

  const onChangeTime = (time: string) => {
    setFromDate(Moment(time).set({ h: 12 }).format())
  }

  const renderOrderRow = (order: any) => (
    <tr key={order.id}>
      <td className="py-2 flex flex-row">
        <AuthorizedInteractable roles={authRoles}>
          <input
            type="checkbox"
            checked={selectedOrders[order.id]}
            onChange={() => onSelectOrder(order.id)}
          />
        </AuthorizedInteractable>
        <p className={'ml-2 pt-1'}>{order.orderNumber}</p>
      </td>
      <td className="py-2">{Moment(order.date).format('MM/DD/YYYY h:mma')}</td>
      <td className="py-2">
        {Moment(order.effectiveDate).format('MM/DD/YYYY')}
      </td>
      <td className="py-2">{Moment(order.dueDate).format('MM/DD/YYYY')}</td>
      <td className="py-2">{order.client.name}</td>
      <td className="py-2">
        <p>
          {order.salesRep.name}
          <br></br>
          {order.salesRep.email}
        </p>
      </td>
      <td className="py-2">
        <p>
          {order.invoiceContact.name}
          <br></br>
          {order.invoiceContact.email}
        </p>
      </td>
    </tr>
  )

  return (
    <div>
      <div className="flex flex-row items-center">
        <Dropdown
          label="Timezone"
          width="300px"
          marginBottom="20px"
          value={selectedTimezone.railsTz}
          onChange={(e) => {
            const railsTz = e.target.value
            const jsTz = timezones.find((tz) => tz.value === railsTz)!.alt

            setSelectedTimezone({ railsTz, jsTz })
          }}
        >
          {timezones.map((timezone) => (
            <option key={timezone.value} value={timezone.value}>
              {timezone.label}
            </option>
          ))}
        </Dropdown>
        <div className="ml-10 h-12">
          <p className="font-semibold">Next Notification Period</p>
          <p className="text-sm mt-1">
            {(nextCronTime &&
              Moment(nextCronTime).format('MM/DD/YYYY h:mma')) ||
              'Unknown'}
          </p>
        </div>
        <div className="ml-10 h-12">
          <AutocompleteInput
            label="Search Client"
            displayAttribute="name"
            loaderFunction={(search: any) =>
              searchAccounts({ ...search, searchAllHqs: false })
            }
            filters={snakeCaseify({ autoInvoiceEligible: true })}
            value={selectedAccount && selectedAccount.name}
            onSelect={(account: { id: string; name: string }) =>
              setSelectedAccount(account)
            }
          />
        </div>
        <div className="flex flex-row items-center ml-10 h-12">
          <TooltipModal
            unicode="&#9432;"
            width="150px"
            information={
              'This date corresponds to when a notification batch is to be sent out.'
            }
          />
          <DateTextInput
            label="Search Notification Date"
            dateFormat="MM/DD/YYYY"
            width="200px"
            timeZone={selectedTimezone.jsTz}
            date={fromDate}
            onChange={onChangeTime}
            onEmpty={() => setFromDate(undefined)}
          />
        </div>
        <div className="flex flex-row items-center justify-items-center">
          <AuthorizedInteractable roles={authRoles}>
            <button
              className="py-1 px-2 bg-blue-500 text-white rounded-lg mx-10 h-12 font-semibold"
              onClick={onSendTestOrders}
            >
              Send Test Notifications
            </button>
          </AuthorizedInteractable>
        </div>
      </div>
      <div>
        <div className="flex flex-row">
          <FaArrowAltCircleLeft
            size={25}
            className="cursor-pointer mr-2"
            onClick={() => changePage(-1)}
          />
          Page {page}
          <FaArrowAltCircleRight
            size={25}
            className="cursor-pointer ml-2"
            onClick={() => changePage(1)}
          />
        </div>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <Table>
            <thead className="font-semibold">
              <tr>
                <th className="w-1/5">Order Number</th>
                <th className="w-1/5">Date &amp; Time</th>
                <th>Effective Date</th>
                <th>Due Date</th>
                <th className="w-1/5">Client</th>
                <th className="w-1/5">Sales Rep</th>
                <th className="w-1/5">Invoice Contact</th>
              </tr>
            </thead>
            <tbody>{orders.map((order) => renderOrderRow(order))}</tbody>
          </Table>
        )}
      </div>
    </div>
  )
}

export default AutoInvoiceTab
