import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import Modal from '@components/common/modal/Modal'
import { DateTimeInput } from '@components/common/form'
import FlexContainer from '@components/common/FlexContainer'
import YSpacing from '@components/common/YSpacing'
import Button from '@components/common/form/Button'
import { Menu } from '@types'

type Props = {
  dateMenuCutoffTime: string
  editMenu: Menu

  displayFailureMessage: (text: string) => Promise<void>
  displayWarningMessage: (text: string) => Promise<void>
  hideModal: () => void
  showConfirmationModal: (obj: { text: string }) => Promise<boolean>
  saveMenu: (menu: Menu) => void
}

const EditDateMenuCutoffModal = (props: Props) => {
  const { editMenu } = props
  const [menu, setMenu] = useState<Menu>()
  const [initialCutoffTime, setInitialCutoffTime] = useState<string>()

  useEffect(() => {
    const { editMenu } = props
    setMenu(editMenu)
    setInitialCutoffTime(editMenu.cutoffTime)
  }, [editMenu])

  const onChangeCutoff = (date: moment.Moment) => {
    if (menu) {
      menu.cutoffTime = date.format()
      setMenu(menu)
    }
  }

  const onClearEditCutoff = () => {
    if (menu && initialCutoffTime) {
      menu.cutoffTime = initialCutoffTime
    }
  }

  const onSaveCutoff = async () => {
    const {
      displayFailureMessage,
      displayWarningMessage,
      showConfirmationModal,
      saveMenu,
      dateMenuCutoffTime,
    } = props
    if (menu) {
      if (moment(dateMenuCutoffTime).isBefore(moment())) {
        displayFailureMessage(
          "Unable to edit a menucard's cutoff time after the group order's cutoff time has passed",
        )

        return
      }

      if (moment(menu.cutoffTime).isAfter(moment(dateMenuCutoffTime))) {
        displayFailureMessage(
          "Cannot adjust the menu's cutoff time to be after the group order's cutoff time",
        )

        return
      }
      if (moment(menu.cutoffTime).isSame(initialCutoffTime)) {
        displayWarningMessage(
          'Cutoff time has not changed. Please adjust cutoff before saving new time.',
        )

        return
      }
      const confirm = await showConfirmationModal({
        text: `Are you sure you want to save the new cutoff time for ${
          menu.name
        } to be ${moment(menu.cutoffTime).format(
          'MM/DD/YYYY h:mm a',
        )} instead of ${moment(initialCutoffTime).format(
          'MM/DD/YYYY h:mm a',
        )}?`,
      })
      if (confirm) {
        saveMenu(menu)
      }
    }
  }

  const hideModal = () => {
    const { hideModal } = props
    hideModal()
  }

  const renderContent = (menu: Menu) => {
    return (
      <FlexContainer
        justifyContent="flex-start"
        flexDirection="column"
        alignItems="center"
      >
        {menu && (
          <h1>
            Edit cutoff time for <span className="font-bold">{menu.name}</span>
          </h1>
        )}
        <div className="h-5"></div>
        <DateTimeInput
          date={moment(menu.cutoffTime)}
          onChange={(date: any) => onChangeCutoff(date)}
          clearDate={() => onClearEditCutoff()}
          width="235px"
        />
        <YSpacing height="10px" />
        <Button label="Save Cutoff Time" onClick={() => onSaveCutoff()} />
      </FlexContainer>
    )
  }

  const renderNoContent = () => {
    return <div>Menu not available</div>
  }

  return (
    <Modal
      hideModal={hideModal}
      color="#001940"
      maxWidth="400px"
      width="400px"
      height="300px"
    >
      {!menu ? renderNoContent() : renderContent(menu)}
    </Modal>
  )
}

export default EditDateMenuCutoffModal
