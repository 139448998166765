import { connect } from 'react-redux'
import { BulkGenProposalsFromOrder } from '@components/proposal/proposalSections'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'
import { config } from 'hungry-core2'

const mapStateToProps = (state) => {
  const { router } = state
  const port = router.location.port === 3000 ? 3001 : 3000
  const uri =
    config.admin_url +
    (config.admin_url === 'http://localhost' ? `:${port}` : '')

  const {
    headquarter: { headquarters },
  } = state
  const hqLocaleMap = headquarters.reduce((acc, hq) => {
    acc[hq.id] = hq.locale

    return acc
  }, {})

  return {
    hqLocaleMap,
    uri,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService } = services
  const { OrderToProposals, SearchOrders } = coordinators
  const {
    pRequestOrdersFilter,
    pRequestProposalFromOrder,
    pResponseOrders,
    pResponseProposal,
  } = presenters.Api
  const pResponseError = presenters.Errors.responseErrorMessage

  const generateBulkProposals = OrderToProposals({
    RestService,
    pRequestProposalFromOrder,
    pResponseProposal,
    HandleError,
    pResponseError,
  })

  const searchOrders = SearchOrders({
    RestService,
    pRequestOrdersFilter: pRequestOrdersFilter,
    pResponseOrders: pResponseOrders,
    HandleError,
  })
  const { ConfirmationModal } = UIService

  return {
    confirmationModal: ConfirmationModal,
    generateBulkProposals,
    searchOrders,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BulkGenProposalsFromOrder)
