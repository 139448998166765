export const LoadReferralPartners =
  ({ ReferralService, RestService, pResponseGeneric }) =>
  async (referrable) => {
    let referralPartners = await RestService.get(
      '/api/admin/contacts',
      {
        page: 1,
        results_per_page: 100,
        is_referral_partner: true,
      },
      { timeout: 30000 },
    )
    referralPartners = pResponseGeneric(referralPartners)
    ReferralService.setReferralPartners(referralPartners)
    ReferralService.setReferredBy({ referrable, referralPartners })

    return referralPartners
  }
