import { connect } from 'react-redux'

import { ChefNetworkPage } from '@components/chefNetwork'
import presenters from '@presenters'
import coordinators from '@coordinators'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { headquarter, markets, user } = state
  const { userHeadquarters: headquarters } = headquarter

  return {
    markets,
    headquarters,
    hqId: user && user.lastLoggedInHq,
    userId: user && user.id,
  }
}

const mapDispatchToProps = () => {
  const { HeadquarterService, RestService } = services
  const { pResponseGeneric } = presenters.Api

  const loadHeadquarters = coordinators.LoadUserHeadquarters({
    HeadquarterService,
    RestService,
    pResponseHeadquarters: pResponseGeneric,
    HandleError,
  })

  return {
    loadHeadquarters,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChefNetworkPage)
