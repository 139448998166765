import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import LoadingIndicator from '@components/common/LoadingIndicator'
import { AuthorizedInteractable } from '@containers/common/auth'
import { ButtonsBar } from '@res/styledComponents/index'
import { BiPencil } from 'react-icons/bi'

const CHEF_CONTACT_ROLES = [
  {
    label: 'Orders',
    value: 'orders',
  },
  {
    label: 'Confirmations',
    value: 'confirmations',
  },
  {
    label: 'Invoices',
    value: 'invoices',
  },
  {
    label: 'GO Next Week Reminder',
    value: 'goNextWeek',
  },
  {
    label: 'GO Day Before Reminder',
    value: 'goDayBefore',
  },
  {
    label: 'GO Cutoff Emails',
    value: 'goCutoff',
  },
  {
    label: 'Initial Order Confirmation',
    value: 'initialOrderConfirmation',
  },
  {
    label: 'Follow-Up Order Confirmation',
    value: 'followUpOrderConfirmation',
  },
  {
    label: 'Final Order Confirmation',
    value: 'finalOrderConfirmation',
  },
]

export class Contacts extends Component {
  state = { chefContacts: undefined }

  componentWillMount() {
    ;(async () => {
      const chefContacts = await this.props.loadChefContacts(this.props.chefId)
      this.setState({ chefContacts })
    })()
  }

  componentWillReceiveProps(nextProps) {
    const { chefContacts } = nextProps
    if (chefContacts !== this.props.chefContacts) {
      this.setState({ chefContacts })
    }
  }

  newContact = () => {
    const { newChefContact } = this.props
    newChefContact()
  }

  editContact = (contact) => {
    const { editChefContact } = this.props
    editChefContact(contact)
  }

  render() {
    const { chefContacts } = this.state
    const { chefHqs, chefHqMap } = this.props
    const areContactsLoaded = !!chefContacts
    const areContactsEmpty = chefContacts && chefContacts.length === 0

    return (
      <div>
        <ButtonsBar>
          <AuthorizedInteractable roles={['master admin', 'chef lead']}>
            <button onClick={this.newContact}>
              <BiPencil />
              New Contact
            </button>
          </AuthorizedInteractable>
        </ButtonsBar>
        <Panel>
          <div className="mb-3">
            <p>
              <span className="font-semibold">Chef Headquarters:</span>
              {chefHqs.map((hq) => (
                <span
                  key={hq.id}
                  className="mx-3 p-2 rounded-lg text-white bg-blue-400 font-semibold"
                >
                  {hq.name}
                </span>
              ))}
            </p>
          </div>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Email</th>
                <th>Markets</th>
                <th>Phone Number</th>
                <th>Roles</th>
              </tr>
            </thead>
            <tbody>
              {!areContactsLoaded && (
                <tr>
                  <td colSpan="4">
                    <LoadingIndicator />
                  </td>
                </tr>
              )}

              {areContactsLoaded && areContactsEmpty && (
                <tr>
                  <td colSpan="5">
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '1.2em',
                        color: 'rgba(0,0,0,0.2)',
                        padding: '20px',
                        backgroundColor: 'rgba(0,0,0,0.01)',
                      }}
                    >
                      No contacts yet.
                    </div>
                  </td>
                </tr>
              )}

              {areContactsLoaded &&
                chefContacts.map((contact) => {
                  const roles = CHEF_CONTACT_ROLES.reduce((roles, role) => {
                    if (contact[role.value] === true) {
                      return [...roles, role.label]
                    }

                    return roles
                  }, [])
                  const { name, role, email, phone, activeHqs } = contact
                  const rolesString = roles.join(', ')

                  return (
                    <tr
                      className="cursor-pointer hover:bg-gray-100"
                      key={'contact' + contact.id}
                      onClick={() => this.editContact(contact)}
                    >
                      <td>{name}</td>
                      <td>{role}</td>
                      <td>{email}</td>
                      <td>
                        <ul>
                          {activeHqs.map((hqId) => (
                            <li key={hqId}>{chefHqMap[hqId].name}</li>
                          ))}
                        </ul>
                      </td>
                      <td>{phone}</td>
                      <td>{rolesString}</td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </Panel>
      </div>
    )
  }
}

Contacts.propTypes = {
  chefId: PropTypes.string,
  chefContacts: PropTypes.arrayOf(PropTypes.object),
  chefHqs: PropTypes.arrayOf(PropTypes.object),
  chefHqMap: PropTypes.object,

  loadChefContacts: PropTypes.func,
  newChefContact: PropTypes.func,
  editChefContact: PropTypes.func,
}

export default Contacts
