import { connect } from 'react-redux'

import { PreferenceProfile } from '@components/account/accountSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { account, user } = state
  const { lastLoggedInHq: headquarterId } = user || {}

  return {
    accountId: account.id,
    preferenceProfileId: account.preferenceProfileId,
    headquarterId,
  }
}

const mapDispatchToProps = () => {
  const { UIService, NodeApiService, RestService, SettingsService } = services
  const {
    pResponseGeneric,
    pRequestUpdatePreferenceProfile,
    pRequestSettings,
    pResponseSettings,
  } = presenters.Api
  const {
    AsyncLoadCachedConcepts,
    AsyncLoadSuggestedConcepts,
    AsyncSearchCachedConcepts,
    LoadPreferenceProfile,
    LoadSettings,
    RetryOnTimeout,
    SavePreferenceProfile,
  } = coordinators

  const loadCachedConcepts = AsyncLoadCachedConcepts({
    RestService,
    pResponseGeneric,
    HandleError,
  })
  const loadSuggestedConcepts = AsyncLoadSuggestedConcepts({
    NodeApiService,
    pResponseGeneric,
    HandleError,
  })
  const searchCachedConcepts = AsyncSearchCachedConcepts({
    RestService,
    pResponseGeneric,
    HandleError,
  })
  const loadConceptsSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('concepts')
  const loadMenuItemSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('menuItems')
  const loadPreferenceProfile = LoadPreferenceProfile({
    RestService,
    pResponsePreferenceProfile: pResponseGeneric,
  })
  const savePreferenceProfile = SavePreferenceProfile({
    UIService,
    RestService,
    pRequestUpdatePreferenceProfile,
    pResponsePreferenceProfile: pResponseGeneric,
    HandleError,
  })

  return {
    loadCachedConcepts,
    loadConceptsSettings,
    loadMenuItemSettings,
    loadPreferenceProfile,
    loadSuggestedConcepts,
    savePreferenceProfile,
    searchCachedConcepts,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreferenceProfile)
