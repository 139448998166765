import React from 'react'
import PropTypes from 'prop-types'

class GenericCell extends React.Component {
  render() {
    const { order, attribute, className, key } = this.props
    const cellClassName = className ? className + '-cell' : ''
    const divClassName = className ? className + '-div' : ''

    return (
      <td key={key} className={cellClassName}>
        <div className={divClassName}>{order[attribute]}</div>
      </td>
    )
  }
}

GenericCell.propTypes = {
  order: PropTypes.object,
  attribute: PropTypes.string,
  className: PropTypes.string,
  key: PropTypes.string,
}

export default GenericCell
