import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Panel } from 'react-bootstrap'

class ActionPanel extends Component {
  state = {
    open: false,
  }

  render() {
    const { actionRequired, children, className, title } = this.props
    const { open } = this.state

    return (
      <Panel
        collapsible
        className={`action-panel ${className}`}
        expanded={open}
        bsStyle={actionRequired ? 'warning' : 'success'}
        header={
          <div
            className="panel__inner"
            onClick={() => this.setState({ open: !open })}
          >
            <p className="panel__title"> {title} </p>
            <div className="panel-arrow">
              {actionRequired ||
                (className !== 'services-panel' && (
                  <span className="complete">COMPLETE</span>
                ))}
              {open ? '▼' : '▶'}
            </div>
          </div>
        }
      >
        {children}
      </Panel>
    )
  }
}

ActionPanel.propTypes = {
  actionRequired: PropTypes.bool,
  className: PropTypes.string,
  isPaid: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
}

ActionPanel.defaultProps = {
  actionRequired: true,
  isPaid: false,
  title: '',
}

export default ActionPanel
