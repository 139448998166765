import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'react-bootstrap'
import Modal from '@components/common/modal/Modal'

import pdf from '@res/images/pdf-file.svg'

export class AwaitingPayment extends Component {
  state = {
    invoiceFinalized: false,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ invoiceFinalized: nextProps.order.isInvoiceFinalized })
    if (this.props.order !== nextProps.order) {
      this.setState({ paymentSource: '' })
    }
  }

  onEmailInvoice = (e) => {
    e.preventDefault()

    this.props.emailInvoice(this.state.invoiceFinalized)
  }

  get link() {
    const { invoiceUrlPrefix } = this.props

    return `${invoiceUrlPrefix}${this.props.order.id}`
  }

  render() {
    const { id, invoiceContact, isInvoiceSent, unpaidBalance } =
      this.props.order
    const { hideModal, invoicePdfUrlPrefix, invoicePdfUrlPath } = this.props
    // change path to prop
    const hasInvoiceContact = !!(invoiceContact && invoiceContact.email)

    return (
      <Modal
        // TODO: should we use title here
        title="Send Proposal & Invoice To Client"
        hideModal={hideModal}
        color="#001940"
      >
        <div>
          <a
            className="download-pdf"
            href={`${invoicePdfUrlPrefix}${id}${invoicePdfUrlPath}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={pdf} alt="printer" />
            Invoice PDF link
          </a>

          <p>
            <b> Awaiting payment of ${unpaidBalance}</b>. Please pay the invoice
            or send a link out to the customer if they&#39;d prefer to handle
            the payment.{' '}
          </p>

          {isInvoiceSent && <p> Invoice has been emailed to customer. </p>}

          <p> Link to payment form: </p>
          <a
            className="link"
            href={this.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {this.link}
          </a>
        </div>
        <div>
          <center>
            <span>
              <Checkbox
                value={this.state.invoiceFinalized}
                checked={this.state.invoiceFinalized}
                onChange={(e) =>
                  this.setState({ invoiceFinalized: e.target.checked })
                }
              >
                {' '}
                Invoice Finalized{' '}
              </Checkbox>
            </span>
            <span>
              <button
                className="button-neutral"
                id="email-invoice"
                disabled={
                  !hasInvoiceContact || !this.props.invoiceButtonEnabled
                }
                onClick={this.onEmailInvoice}
              >
                {hasInvoiceContact
                  ? 'Email Invoice'
                  : 'No invoice email contact'}
              </button>
            </span>
          </center>
        </div>
      </Modal>
    )
  }
}

AwaitingPayment.propTypes = {
  className: PropTypes.string,
  invoiceButtonEnabled: PropTypes.bool,
  invoicePdfUrlPrefix: PropTypes.string,
  invoicePdfUrlPath: PropTypes.string,
  invoiceUrlPrefix: PropTypes.string,
  order: PropTypes.object,

  hideModal: PropTypes.func,
  emailInvoice: PropTypes.func,
}

export default AwaitingPayment
