export const SearchVXLookups =
  ({ RestService, HandleError }) =>
  async ({ search, types, hasMatch, limit = 100, offset = 0 }) => {
    try {
      const params = {
        offset,
        limit,
      }

      if (search != null) {
        params.search = search
      }

      if (types && types.length) {
        params.types = types
      }

      if (hasMatch != '') {
        params.hasMatch = hasMatch
      }

      return await RestService.get('/virtual-xp/lookups', params)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const SaveVXLookup =
  ({ RestService, UIService, HandleError }) =>
  async (lookup) => {
    try {
      await RestService.put(`/virtual-xp/lookup/${lookup.id}`, lookup)
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      `VX Lookup for ${lookup.name} successfully updated`,
    )

    return true
  }
