import React from 'react'
import PropTypes from 'prop-types'

const SidebarListItem = (props) => {
  const { id, children, isSelected, onClick } = props

  return (
    <div
      id={id}
      className={`${isSelected ? 'sidebar-item-selected' : 'sidebar-item'}`}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

SidebarListItem.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
}

export default SidebarListItem
