import { Recipe } from '@types'

export const pRequestUpdateRecipe = (data: Recipe) => {
  const req = {
    id: data.id,
    chef_id: data.chefId,
    name: data.name,
    description: data.description,
    servings: data.servings,
    ingredients: data.ingredients,
    instructions: data.instructions,
    prep_hours: data.prepHours,
    prep_minutes: data.prepMinutes,
    cook_hours: data.cookHours,
    cook_minutes: data.cookMinutes,
    image_url: data.imageUrl,
  }

  return req
}
