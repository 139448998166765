import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

class DietaryPrefSelectInput extends Component {
  state = {
    allDietaryPreferences: [],
    selectedDietaryPreferences: [],
    tagsDietaryPreferenceList: '',
  }

  componentDidMount() {
    ;(async () => {
      const allDietaryPreferences = await this.props.loadDietaryPreferences()
      const { tagsDietaryPreferenceList = '' } = this.props
      this.setState({
        allDietaryPreferences,
        selectedDietaryPreferences: tagsDietaryPreferenceList.split(','),
      })
    })()
  }

  componentWillReceiveProps(nextProps) {
    const { tagsDietaryPreferenceList = '' } = nextProps
    if (tagsDietaryPreferenceList !== this.props.tagsDietaryPreferenceList) {
      this.setState({
        tagsDietaryPreferenceList,
        selectedDietaryPreferences: tagsDietaryPreferenceList.split(','),
      })
    }
  }

  onChange = (selectedDietaryPreferences) => {
    if (selectedDietaryPreferences) {
      const { onChange } = this.props
      const tagsDietaryPreferenceList = (selectedDietaryPreferences || [])
        .filter((o) => o)
        .map((o) => o.value)
        .join(',')

      this.setState({ tagsDietaryPreferenceList })
      onChange && onChange(tagsDietaryPreferenceList)
    }
  }

  render() {
    const { allDietaryPreferences, selectedDietaryPreferences } = this.state

    return (
      <div className="flex flex-col w-64">
        <p className="mb-1 mt-3 text-hungryGray bold uppercase text-sm">
          Select Dietary Tags
        </p>
        <div className="section">
          <Select
            multi
            className="border w-100 regular text-lg text-hungryGray rounded border-gray-400"
            closeOnSelect={false}
            options={allDietaryPreferences}
            value={selectedDietaryPreferences}
            onChange={this.onChange}
          />
        </div>
      </div>
    )
  }
}

DietaryPrefSelectInput.propTypes = {
  tagsDietaryPreferenceList: PropTypes.string,

  loadDietaryPreferences: PropTypes.func,
  onChange: PropTypes.func,
}

export default DietaryPrefSelectInput
