import Moment from 'moment-timezone'

import { camelCaseify, formAdd } from '~/utils'
import { NetPaymentDays, AutoBatchPeriods } from '../../../constants'

export const pRequestARParams = (ARParams) => {
  const params = {}
  if (ARParams.fromDate) {
    formAdd(ARParams, params, 'fromDate', 'from')
  }
  if (ARParams.toDate) {
    formAdd(ARParams, params, 'toDate', 'to')
  }
  formAdd(ARParams, params, 'name', 'search')
  if (
    ARParams.selectedHeadquarters &&
    Object.keys(ARParams.selectedHeadquarters).length > 0
  ) {
    formAdd(ARParams, params, 'selectedHeadquarters', 'headquarterIds', (v) =>
      Object.keys(v),
    )
  }

  return params
}

export const pResponseAccountsReceivable = (unpaidAccounts) => {
  return camelCaseify(unpaidAccounts)
}

export const pFormatCommaSeparatedCurrency = (currency) => {
  let currencyNum = parseFloat(currency)
  currencyNum = currencyNum.toFixed(2)

  return currencyNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const pGroupARByClient = (groupedAR, accountsRecs) => {
  accountsRecs.forEach((ar) => {
    const { account, accountId, daysPastDue, unpaidBalance } = ar

    const arRes = groupedAR.find((r) => r.Id.id === accountId)
    if (arRes) {
      arRes.orders.push(ar)
      arRes.orders = arRes.orders.sort((a, b) => {
        if (a.daysPastDue !== b.daysPastDue) {
          return a.daysPastDue > b.daysPastDue ? -1 : 1
        }

        return Moment(a.date).isBefore(Moment(b.date)) ? -1 : 1
      })
      arRes.unpaidBalance =
        parseFloat(arRes.unpaidBalance) + parseFloat(unpaidBalance)
      arRes.orderCount = arRes.orderCount + 1
      if (arRes.largestDaysPastDue.daysPastDue < daysPastDue) {
        arRes.largestDaysPastDue = ar
      }
    } else {
      groupedAR.push({
        orderCount: 1,
        largestDaysPastDue: ar,
        orders: [ar],
        unpaidBalance,
        Id: {
          id: accountId,
          name: account,
        },
      })
    }
  })

  return groupedAR
}

export const pGroupARByRep = (groupedAR, accountsRecs) => {
  accountsRecs.forEach((ar) => {
    const { salesRep, salesRepId, daysPastDue, unpaidBalance } = ar

    const arRes = groupedAR.find((r) => r.Id.id === salesRepId)
    if (arRes) {
      arRes.orders.push(ar)
      arRes.orders = arRes.orders.sort((a, b) => {
        if (a.daysPastDue !== b.daysPastDue) {
          return a.daysPastDue > b.daysPastDue ? -1 : 1
        }

        return Moment(a.date).isBefore(Moment(b.date)) ? -1 : 1
      })
      arRes.unpaidBalance =
        parseFloat(arRes.unpaidBalance) + parseFloat(unpaidBalance)
      arRes.orderCount = arRes.orderCount + 1
      if (arRes.largestDaysPastDue.daysPastDue < daysPastDue) {
        arRes.largestDaysPastDue = ar
      }
    } else {
      groupedAR.push({
        orderCount: 1,
        largestDaysPastDue: ar,
        orders: [ar],
        unpaidBalance,
        Id: {
          id: salesRepId,
          name: salesRep,
        },
      })
    }
  })

  return groupedAR
}

export const pAccountsReceivableCSV = (ar) => {
  const arRows = []

  ar.forEach((it) => {
    it.orders.forEach((order) => {
      arRows.push({
        accountName: order.account,
        orderNumber: order.orderNumber,
        invoiceNumber: order.invoiceNumber,
        orderDate: Moment(order.date || order.clientSetUpTime).format(
          'MM-DD-YYYY',
        ),
        salesRep: order.salesRep,
        csmName: order.csmName,
        hunterName: order.hunterName,
        contact: order.contact && order.contact.name,
        paymentMethod: order.paymentMethod || 'N/A',
        paymentTerms: NetPaymentDays[order.netPaymentDays],
        invoicingCadence: AutoBatchPeriods[order.autoBatchPeriod],
        effectiveDate: Moment(order.effectiveDate).format('MM-DD-YYYY'),
        dueDate: Moment(order.dueDate).format('MM-DD-YYYY'),
        daysPastDue: order.daysPastDue,
        total: order.total,
        unpaidAmount: order.unpaidBalance,
        notes: order.notes,
        autoBatchActive: order.autoBatchActive,
        lateBatchInvoiceEmail: order.lateBatchInvoiceEmail,
        latePaymentEmailByInvoiceContact:
          order.latePaymentEmailByInvoiceContact,
        latePaymentEmailByOrderContact: order.latePaymentEmailByOrderContact,
        autoInvoiceEmailByInvoiceContact:
          order.autoInvoiceEmailByInvoiceContact,
        autoInvoiceEmailByOrderContact: order.autoInvoiceEmailByOrderContact,
      })
    })
  })

  return arRows
}
