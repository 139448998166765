import { connect } from 'react-redux'

import { CopyMenuItemModal } from '@components/chef/edit'
import { HandleError } from '@coordinators/composed'
import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'

const mapStateToProps = (state) => {
  const { copyMenuItemModal, chef } = state
  const { menuItemId, show } = copyMenuItemModal

  return {
    show,
    menuItemId,
    currentChefId: chef.id,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, UIService } = services
  const { pResponseChefs, pResponseMenuItems } = presenters.Api
  const { LoadChefs, CopyMenuItem } = coordinators

  const copyMenuItem = CopyMenuItem({
    RestService,
    UIService,
    HandleError,
    ChefService,
    pResponseMenuItems,
  })
  const loadChefs = LoadChefs({ ChefService, RestService, pResponseChefs })
  const onHide = () => UIService.EditMenuItem.hideCopyMenuItem()

  return {
    copyMenuItem,
    onHide,
    loadChefs,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyMenuItemModal)
