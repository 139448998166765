import { connect } from 'react-redux'

import { InvoiceCustomPayModal } from '@components/invoice'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { user = {} } = state

  return {
    user,
  }
}

const mapDispatchToProps = () => {
  const { pRequestUpdateInvoice } = presenters.Api
  const { RestService, UIService, VXInvoiceService } = services
  const { SaveInvoice } = coordinators

  const { newOrderPayment } = VXInvoiceService

  const { displayWarningMessage } = UIService.FlashMessage

  const saveInvoice = SaveInvoice({
    RestService,
    UIService,
    pRequestUpdateInvoice,
    HandleError,
  })

  return {
    displayWarningMessage,
    newOrderPayment,
    saveInvoice,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoiceCustomPayModal)
