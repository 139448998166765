export const SET_ACCOUNTS_RECEIVABLE = 'SET_ACCOUNTS_RECEIVABLE'
export const CLEAR_ACCOUNTS_RECEIVABLE = 'CLEAR_ACCOUNTS_RECEIVABLE'

export const setAccountsReceivable = (accountsReceivable, requestTime) => ({
  type: SET_ACCOUNTS_RECEIVABLE,
  accountsReceivable,
  requestTime,
})
export const clearAccountsReceivable = () => ({
  type: CLEAR_ACCOUNTS_RECEIVABLE,
})
