export const CLEAR_EDIT_SERVICE_ITEM = 'CLEAR_EDIT_SERVICE_ITEM'
export const SET_SERVICE_ITEMS = 'SET_SERVICE_ITEMS'
export const SET_EDIT_SERVICE_ITEM = 'SET_EDIT_SERVICE_ITEM'
export const UPDATE_EDIT_SERVICE_ITEM = 'UPDATE_EDIT_SERVICE_ITEM'

export const clearEditServiceItem = () => ({ type: CLEAR_EDIT_SERVICE_ITEM })
export const setServiceItems = (serviceItems) => ({
  type: SET_SERVICE_ITEMS,
  serviceItems,
})
export const setEditServiceItem = (serviceItem) => ({
  type: SET_EDIT_SERVICE_ITEM,
  serviceItem,
})
export const updateEditServiceItem = (serviceItem) => ({
  type: UPDATE_EDIT_SERVICE_ITEM,
  serviceItem,
})
