import { connect } from 'react-redux'

import { EditSnackPackModal } from '@components/chef/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { chef, editSnackPack, editSnackPackModal } = state
  const errors = state.errors.snackPackModal || {}
  const { show } = editSnackPackModal
  const { id } = chef

  return {
    chefId: id,
    snackPack: editSnackPack,
    show,
    errors,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, UIService } = services
  const { pResponseChef, pResponseSnackPacks, pRequestUpdateSnackPack } =
    presenters.Api
  const { DelayedUpdateSnackPack, DeleteSnackPack, SaveSnackPack, SelectChef } =
    coordinators
  const { close } = UIService.EditSnackPack
  const clearErrors = UIService.Errors.clear
  const delayedUpdateSnackPack = DelayedUpdateSnackPack({
    ChefService,
    UIService,
  })
  const deleteSnackPack = DeleteSnackPack({
    ChefService,
    RestService,
    UIService,
    pResponseChef,
    HandleError,
    SelectChef,
  })
  const saveSnackPack = SaveSnackPack({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateSnackPack,
    pResponseSnackPacks,
    HandleError,
  })

  return {
    close,
    clearErrors,
    saveSnackPack,
    delayedUpdateSnackPack,
    deleteSnackPack,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSnackPackModal)
