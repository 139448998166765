export const SET_PACKAGING = 'SET_PACKAGING'
export const SET_PACKAGING_ADD_ONS = 'SET_PACKAGING_ADD_ONS'
export const SET_SERVING_UTENSILS = 'SET_SERVING_UTENSILS'
export const SET_CATERING_SUPPLIES = 'SET_CATERING_SUPPLIES'
export const SET_DINING_SUPPLIES = 'SET_DINING_SUPPLIES'
export const SET_INVENTORIES = 'SET_INVENTORIES'

export const setPackaging = (packaging) => ({ type: SET_PACKAGING, packaging })
export const setPackagingAddOns = (packagingAddOns) => ({
  type: SET_PACKAGING_ADD_ONS,
  packagingAddOns,
})
export const setServingUtensils = (servingUtensils) => ({
  type: SET_SERVING_UTENSILS,
  servingUtensils,
})
export const setCateringSupplies = (cateringSupplies) => ({
  type: SET_CATERING_SUPPLIES,
  cateringSupplies,
})
export const setDiningSupplies = (diningSupplies) => ({
  type: SET_DINING_SUPPLIES,
  diningSupplies,
})
export const setInventories = (inventories) => ({
  type: SET_INVENTORIES,
  inventories,
})
