import {
  REPLACE_SUBSCRIBER,
  REPLACE_SUBSCRIPTION,
  SET_SUBSCRIBERS,
  SET_SUBSCRIPTIONS,
} from '@actions/subscription'

const initialSubscribersState = []
const initialSubscriptionsState = []

export function subscribers(state = initialSubscribersState, action) {
  const { type } = action

  switch (type) {
    case REPLACE_SUBSCRIBER: {
      const { member } = action
      const members = []
      state.forEach((m) => {
        if (member.id === m.id) {
          members.push(member)
        } else {
          members.push(m)
        }
      })

      return members
    }
    case SET_SUBSCRIBERS: {
      const { members } = action

      return members
    }
    default:
      return state
  }
}

export function subscriptions(state = initialSubscriptionsState, action) {
  const { type } = action

  switch (type) {
    case REPLACE_SUBSCRIPTION: {
      const { subscription } = action
      const subscriptions = []
      state.forEach((s) => {
        if (subscription.id === s.id) {
          subscriptions.push(subscription)
        } else {
          subscriptions.push(s)
        }
      })

      return subscriptions
    }
    case SET_SUBSCRIPTIONS: {
      const { subscriptions } = action

      return subscriptions
    }
    default:
      return state
  }
}
