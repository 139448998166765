import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { colors } from '../../../../constants'

const DropDownOption = ({ value, onRemove }) => {
  return (
    <Option key={value} onClick={() => onRemove(value)}>
      {value} <span>x</span>
    </Option>
  )
}

const Option = styled.button`
  background: ${() => colors.blue};
  border: none;
  color: #fff;
  font-family: regular;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  span {
    background: ${() => colors.blue400};
    width: 20px;
    height: 20px;
    min-width: 10px;
    display: inline-block;
    border-radius: 50px;
  }
  &:hover {
    background: ${() => colors.blue200};
  }
`
DropDownOption.propTypes = {
  value: PropTypes.string,

  onRemove: PropTypes.func,
}

export default DropDownOption
