import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

import { AutocompleteInput, DateInput } from '@components/common/form'
import { ServingsForHeadCount } from './constants'

class SubscriptionEditable extends Component {
  state = {
    editSubscription: undefined,
  }

  onEditSubscription = (subscription) => () => {
    if (subscription === undefined) {
      this.setState({ editSubscription: undefined })
    } else {
      this.setState({
        editSubscription: this.props.buildEditSubscription(subscription),
      })
    }
  }

  onChangeSubscriptionDate = (date) => {
    const editSubscription = { ...this.state.editSubscription }
    editSubscription.dateStr = date.format('MM/DD/YYYY')
    this.setState({ editSubscription })
  }

  onChangeSubscriptionHeadcount = (e) => {
    const editSubscription = {
      ...this.state.editSubscription,
      headcount: parseFloat(e.target.value),
    }
    this.setState({ editSubscription })
  }

  onChooseMainItem = (itemId) => () => {
    const editSubscription = { ...this.state.editSubscription }
    editSubscription.mainItemMap[itemId].chosen =
      !editSubscription.mainItemMap[itemId].chosen
    if (!editSubscription.mainItemMap[itemId].chosen) {
      editSubscription.mainItemMap[itemId].quantity = undefined
    }
    this.setState({ editSubscription })
  }

  onChangeMainItemQty = (itemId) => (e) => {
    const editSubscription = { ...this.state.editSubscription }
    editSubscription.mainItemMap[itemId].quantity = parseFloat(e.target.value)
    this.setState({ editSubscription })
  }

  onChooseSideItem = (itemId) => () => {
    const editSubscription = { ...this.state.editSubscription }
    editSubscription.sideItemMap[itemId].chosen =
      !editSubscription.sideItemMap[itemId].chosen
    if (!editSubscription.sideItemMap[itemId].chosen) {
      editSubscription.sideItemMap[itemId].quantity = undefined
    }
    this.setState({ editSubscription })
  }

  onChangeSideItemQty = (itemId) => (e) => {
    const editSubscription = { ...this.state.editSubscription }
    editSubscription.sideItemMap[itemId].quantity = parseFloat(e.target.value)
    this.setState({ editSubscription })
  }

  onChooseMenu = async (menu) => {
    menu = await this.props.loadHomeMenu(menu.id)
    // set menu and clear items
    let subscription = { ...this.state.editSubscription }
    subscription.menu = menu
    subscription.items = []
    subscription = this.props.buildEditSubscription(subscription)
    this.setState({ editSubscription: subscription })
  }

  onSaveSubscription = async () => {
    const { flashError, onSaveSubscription } = this.props
    const { editSubscription } = this.state
    const validQtys = ServingsForHeadCount(editSubscription.headcount)
    const nMain = Object.values(editSubscription.mainItemMap)
      .filter((b) => b.chosen)
      .reduce((acc, item) => {
        return acc + item.quantity
      }, 0)
    const nSides = Object.values(editSubscription.sideItemMap)
      .filter((b) => b.chosen)
      .reduce((acc, item) => {
        return acc + item.quantity
      }, 0)

    if (!editSubscription.headcount) {
      flashError('Must select a head count')

      return
    }
    if (validQtys['mains'] !== nMain) {
      flashError('Invalid quantity of entrees')

      return
    }
    if (validQtys['sides'] !== nSides) {
      flashError('Invalid quantity of sides')

      return
    }
    if (await onSaveSubscription(editSubscription)) {
      this.setState({ editSubscription: undefined })
    }
  }

  render() {
    const {
      className,
      hqId,
      loadHomeMenus,
      subscription,
      onDeleteSubscription,
      onNotifyChef,
      onSendSurveys,
    } = this.props
    const { editSubscription } = this.state
    const { id: editSubscriptionId } = editSubscription || {}

    return (
      <div
        key={subscription.id}
        className={`subscription-card relative p-2 border-solid border border-gray-400 rounded ${className}`}
      >
        {editSubscriptionId === subscription.id ? (
          <div>
            <h3 className="font-bold text-hungryGray mt-1 mb-2">
              {subscription.number}
            </h3>
            <label className="font-bold text-hungryGray">Date</label>
            <DateInput
              className="w-full"
              date={Moment(editSubscription.dateStr, 'MM/DD/YYYY')}
              onChange={this.onChangeSubscriptionDate}
            />
            <label className="font-bold text-hungryGray">Head Count</label>
            <select
              className="w-full font-bold text-hungryGray"
              value={editSubscription.headcount}
              onChange={this.onChangeSubscriptionHeadcount}
            >
              {[null, 4, 6, 8].map((headcount) => (
                <option key={headcount} value={headcount}>
                  {headcount}
                </option>
              ))}
            </select>
            <label className="font-bold text-hungryGray">Menu</label>
            <AutocompleteInput
              value={`Chef ${editSubscription.menu.chef.name}'s ${editSubscription.menu.name}`}
              loaderFunction={() => loadHomeMenus(hqId)}
              placeholder={'Select a Menu'}
              onSelect={this.onChooseMenu}
            />
            <div className="mb-2">
              <label className="font-bold text-hungryGray w-16">Mains</label>
              {Object.values(editSubscription.mainItemMap).map((item) => (
                <div key={item.id}>
                  <div className="flex items-center mb-1">
                    <input
                      className="checkbox-input"
                      type="checkbox"
                      checked={item.chosen}
                      onChange={this.onChooseMainItem(item.id)}
                    />
                    <p className="ml-2">{item.name}</p>
                  </div>
                  {item.chosen && (
                    <div className="flex items-center mb-1">
                      Serving Quantity&nbsp;
                      <select
                        className="w-full font-bold text-hungryGray"
                        value={item.quantity || 0}
                        onChange={this.onChangeMainItemQty(item.id)}
                      >
                        {[null, 4, 8].map((headcount) => (
                          <option key={headcount} value={headcount}>
                            {headcount}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="mb-2">
              <label className="font-bold text-hungryGray w-16">Sides</label>
              {Object.values(editSubscription.sideItemMap).map((item) => (
                <div key={item.id}>
                  <div className="flex items-center mb-1">
                    <input
                      className="checkbox-input"
                      type="checkbox"
                      checked={item.chosen}
                      onChange={this.onChooseSideItem(item.id)}
                    />
                    <p className="ml-2">{item.name}</p>
                  </div>
                  {item.chosen && (
                    <div className="flex items-center mb-1">
                      Serving Quantity&nbsp;
                      <select
                        className="w-full font-bold text-hungryGray"
                        value={item.quantity || 0}
                        onChange={this.onChangeSideItemQty(item.id)}
                      >
                        {[null, 4, 8, 16].map((headcount) => (
                          <option key={headcount} value={headcount}>
                            {headcount}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex flex-col justify-center">
              <button
                className="button-alert focus:outline-none my-1"
                onClick={() => onDeleteSubscription(subscription)}
              >
                Delete
              </button>
              <button
                className="button-primary focus:outline-none my-1"
                onClick={this.onSaveSubscription}
              >
                Save
              </button>
              <button
                className="button-neutral focus:outline-none my-1"
                onClick={this.onEditSubscription(undefined)}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col h-full justify-between">
            <button
              className="subscription-edit-button absolute right-0 top-0 text-xl px-2 py-1 text-hungryGray focus:outline-none"
              onClick={this.onEditSubscription(subscription)}
            >
              ✎
            </button>
            <div>
              <h3 className="font-bold text-hungryGray mt-1 mb-2">
                {subscription.number}
              </h3>
              <div>
                {Moment(subscription.dateStr, 'MM/DD/YYYY').format(
                  'ddd MMM D, YYYY',
                )}
              </div>
              <div>Head Count {subscription.headcount}</div>
              <h3 className="font-bold text-hungryGray mt-1 mb-2">
                Chef {subscription.menu.chef.name + "'"}s{' '}
                {subscription.menu.name}
              </h3>
              <div>
                <label className="font-bold text-hungryGray w-16">Mains</label>
                {subscription.mainItems
                  .map((item) => `${item.quantity}x ${item.name}`)
                  .join(', ')}
              </div>
              <div>
                <label className="font-bold text-hungryGray w-16">Sides</label>
                {subscription.sideItems
                  .map((item) => `${item.quantity}x ${item.name}`)
                  .join(', ')}
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <button
                className="button-neutral focus:outline-none my-1"
                onClick={onNotifyChef}
              >
                Email Chef
              </button>
              <button
                className="button-neutral focus:outline-none my-1"
                onClick={() => onSendSurveys('customer')}
              >
                Email Customer Survey
              </button>
              <button
                className="button-neutral focus:outline-none my-1"
                onClick={() => onSendSurveys('chef')}
              >
                Email Chef Survey
              </button>
              <button
                className="button-neutral focus:outline-none my-1"
                onClick={() => onSendSurveys('captain')}
              >
                Email Captain Survey
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

SubscriptionEditable.propTypes = {
  className: PropTypes.string,
  hqId: PropTypes.number,
  subscription: PropTypes.object,

  buildEditSubscription: PropTypes.func,
  flashError: PropTypes.func,
  loadHomeMenu: PropTypes.func,
  loadHomeMenus: PropTypes.func,
  onNotifyChef: PropTypes.func,
  onDeleteSubscription: PropTypes.func,
  onSaveSubscription: PropTypes.func,
  onSendSurveys: PropTypes.func,
}

export default SubscriptionEditable
