import { connect } from 'react-redux'

import { CalcChefFeesModal } from '@components/chef/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { calcChefFeesModal, headquarter } = state
  const userHeadquarters = headquarter?.userHeadquarters || []
  const { show } = calcChefFeesModal

  return {
    show,
    userHeadquarters,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService } = services
  const { pResponseChefs } = presenters.Api
  const { SearchChefsForFeesModal, GenerateChefProcessingFees } = coordinators

  const generateChefProcessingFees = GenerateChefProcessingFees({
    RestService,
    HandleError,
  })
  const searchChefs = SearchChefsForFeesModal({
    RestService,
    pResponseChefs,
    HandleError,
  })
  const close = () =>
    UIService.CalcChefProcFeeModal.displayCalcChefProcFeeModal({ show: false })
  const showConfirmationModal = async (text) =>
    UIService.ConfirmationModal.show({ text })

  return {
    close,
    generateChefProcessingFees,
    searchChefs,
    showConfirmationModal,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalcChefFeesModal)
