import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

import { DateInput } from '@components/common/form'
import Input from '@components/common/form/Input'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import Button from '@components/common/form/Button'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import Dropdown from '@components/common/form/Dropdown'
import Checkbox from '@components/common/form/Checkbox'
import LinkText from '@components/common/form/LinkText'
import { vxOrderStatuses } from './constants'

class VXOrdersPage extends Component {
  state = {
    search: '',
    syncing: false,
    searchDate: Moment(),
    searchLimit: 20,
    searchOffset: 0,
    isSynced: '',
    showEditModal: false,
    orders: [],
    enabled: undefined,
    selectedOrder: undefined,
    sortBy: [undefined, true],
    statuses: vxOrderStatuses.reduce((map, status) => {
      map[status] = true

      return map
    }, {}),
  }
  searchTimer = undefined

  componentDidMount() {
    this.searchVXOrders()
  }

  searchVXOrders = async () => {
    const { searchVXOrders } = this.props
    const {
      search,
      searchDate,
      searchLimit,
      searchOffset,
      statuses,
      isSynced,
    } = this.state
    const statusesArr = Object.keys(statuses).filter((key) => {
      if (statuses[key]) {
        return key === 'single' ? 'single((?!prepaid).)*$' : key
      }
    })
    statusesArr.push('^$')
    const orders = await searchVXOrders({
      search,
      isSynced,
      statuses: statusesArr.join(','),
      searchDate,
      limit: searchLimit,
      offset: searchOffset,
    })

    this.setState({ orders })
  }

  searchAfterTimeout = () => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer)
    }
    this.searchTimer = undefined
    this.searchTimer = setTimeout(() => {
      this.searchVXOrders()
    }, 550)
  }

  editOrder = (id) => async () => {
    const order = await this.props.getVXOrder(id)
    if (order) {
      this.setState({
        selectedOrder: order,
        showEditModal: true,
      })
    }
  }

  generateInvoice = (order) => async () => {
    const { saveVXInvoice, newInvoice, newOrderPayment, newBuyer } = this.props
    const accOrder = await this.props.getAccountingVXOrder(order.id)

    if (!accOrder || accOrder.isInvoiced) {
      this.props.displayWarningMessage(
        'Order has been fully accounted for on invoices.',
      )

      return
    }

    const invoiceAmount = accOrder.invoicedAmount ? 'the remainder' : '50%'
    const doChange = await this.props.showConfirmationModal({
      text: `Do you want to generate an invoice for ${invoiceAmount} of the invoice total?`,
    })
    if (!doChange) {
      return
    }

    const invoice = newInvoice()
    const percent = accOrder.invoicedAmount
      ? 1 - accOrder.invoicedAmount / accOrder.total
      : 0.5
    const amount = parseFloat((accOrder.total * percent).toFixed(2))
    const serviceFee = parseFloat((accOrder.serviceFee * percent).toFixed(2))
    const tax = parseFloat((accOrder.tax * percent).toFixed(2))
    const discount = parseFloat((accOrder.discount * percent).toFixed(2))

    invoice.totalDue = amount
    invoice.discountAmount = discount

    const payments = [
      newOrderPayment({
        amount,
        tax,
        discount,
        serviceFee,
        paymentForId: accOrder.id,
        isInvoiced: accOrder.invoicedAmount + amount >= accOrder.total,
        orderNumber: accOrder.orderNumber,
        description: accOrder.description,
      }),
    ]

    invoice.contactEmail = order.client.email
    invoice.contactPhone = order.client.phone
    invoice.contactName = order.client.name
    invoice.buyer = newBuyer({
      id: order.client.airtableId,
      name: order.client.name,
      email: order.client.email,
      phoneNumber: order.client.phone,
      contactName: order.client.name,
      pin: order.client.pin,
    })

    return saveVXInvoice({ invoice, payments }, true)
  }

  sortOrders = (attribute, transform = (a) => a) => {
    const { orders, sortBy } = this.state
    const asc = attribute !== sortBy[0] || !sortBy[1]
    this.setState({
      sortBy: [attribute, asc],
      orders: orders.sort((a, b) => {
        if (transform(a[attribute]) > transform(b[attribute])) {
          return asc ? 1 : -1
        }
        if (transform(a[attribute]) < transform(b[attribute])) {
          return asc ? -1 : 1
        }

        return 0
      }),
    })
  }

  syncOrders = async () => {
    const { syncing, orders } = this.state
    const { vxOrderSync } = this.props

    if (syncing) {
      return
    }
    this.setState({ syncing: true })
    const ids = orders.map((o) => o.id)
    const synced = await vxOrderSync(ids)
    this.setState({ syncing: false, orders: synced ? synced : orders })
  }

  render() {
    const {
      orders,
      search,
      searchDate,
      searchLimit,
      searchOffset,
      isSynced,
      statuses,
      syncing,
    } = this.state

    return (
      <Panel width="100%" maxWidth="900px" heading="VX Orders">
        <FlexContainer width="100%" alignItems="flex-end">
          <Input
            label="Search Name/Number"
            marginBottom="0"
            width="200px"
            type="text"
            value={search}
            onChange={(e) =>
              this.setState(
                {
                  search: e.target.value,
                  searchOffset: 0,
                },
                this.searchAfterTimeout,
              )
            }
          />
          <XSpacing width="20px" />
          <DateInput
            width="200px"
            label="Date"
            date={searchDate}
            dateFormat="default"
            isStatic={false}
            onChange={(searchDate) =>
              this.setState({ searchDate }, this.searchVXOrders)
            }
            clearDate={() =>
              this.setState({ searchDate: undefined }, this.searchVXOrders)
            }
          />
          <XSpacing width="20px" />
          <Dropdown
            label="Sync Status"
            value={isSynced}
            marginBottom="0"
            width="200px"
            onChange={(e) => {
              const statusCode = e.target.value
              this.setState({ isSynced: statusCode }, this.searchVXOrders)
            }}
          >
            <option value={''}>All</option>
            <option value={false}>Unsynced</option>
            <option value={true}>Synced</option>
          </Dropdown>
          <XSpacing width="20px" />
          <Button label="Sync" marginBottom="0" onClick={this.syncOrders} />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between" width="760px">
          {vxOrderStatuses.map((status) => {
            return (
              <Checkbox
                key={status}
                label={status}
                value={status}
                checked={statuses[status]}
                onChange={(e) => {
                  const status = e.target.value
                  const current = statuses[status]
                  this.setState(
                    { statuses: { ...statuses, [status]: !current } },
                    this.searchVXOrders,
                  )
                }}
              />
            )
          })}
        </FlexContainer>
        <YSpacing height="20px" />
        <Table>
          <tr>
            <th onClick={() => this.sortOrders('orderNumber')}>Order Num</th>
            <th onClick={() => this.sortOrders('dateStr')}>Date</th>
            <th onClick={() => this.sortOrders('status')}>Status</th>
            <th onClick={() => this.sortOrders('client', (x) => x.name)}>
              Client
            </th>
            <th
              onClick={() =>
                this.sortOrders('talents', (x) => (x[0] ? x[0].name : ''))
              }
            >
              Talents
            </th>
            <th onClick={() => this.sortOrders('salesRep', (x) => x.name)}>
              Sales Rep
            </th>
            <th
              style={{ width: '100px' }}
              onClick={() =>
                this.sortOrders('synced', (x) => (x ? 'Yes' : 'No'))
              }
            >
              {syncing ? 'Syncing...' : 'Is Synced?'}
            </th>
          </tr>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>
                <p>{order.orderNumber}</p>
              </td>
              <td>
                <p>{order.dateString}</p>
              </td>
              <td>
                <p>{order.status}</p>
              </td>
              <td>
                <p>{order.client.name}</p>
              </td>
              <td>
                <p>{order.talents.map((t) => t.name).join(', ')}</p>
              </td>
              <td>
                <p>{order.salesRep.name}</p>
              </td>
              <td>
                {order.synced ? (
                  <FlexContainer
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <p>Yes</p>
                    <XSpacing width="10px" />
                    <LinkText
                      label="Create Invoice"
                      onClick={this.generateInvoice(order)}
                    />
                  </FlexContainer>
                ) : (
                  <p>No</p>
                )}
              </td>
            </tr>
          ))}
        </Table>
        {searchOffset > 0 && (
          <button
            onClick={() =>
              this.setState(
                { searchOffset: searchOffset - searchLimit },
                this.searchVXOrders,
              )
            }
          >
            &lt;
          </button>
        )}
        Page {1 + Math.floor(parseFloat(searchOffset / searchLimit))}
        {searchLimit === orders.length && (
          <button
            onClick={() =>
              this.setState(
                { searchOffset: searchOffset + searchLimit },
                this.searchVXOrders,
              )
            }
          >
            &gt;
          </button>
        )}
      </Panel>
    )
  }
}

VXOrdersPage.propTypes = {
  headquarter: PropTypes.number,
  theme: PropTypes.object,

  getVXOrder: PropTypes.func,
  newBuyer: PropTypes.func,
  newOrderPayment: PropTypes.func,
  newInvoice: PropTypes.func,
  searchVXOrders: PropTypes.func,
  saveVXInvoice: PropTypes.func,
  getAccountingVXOrder: PropTypes.func,
  displayWarningMessage: PropTypes.func,
  flashSuccess: PropTypes.func,
  showConfirmationModal: PropTypes.func,
  vxOrderSync: PropTypes.func,
}

export default VXOrdersPage
