export const CancelMLBOrder =
  ({ RestService, UIService, HandleError }) =>
  async ({ partnerId, orderId, orderNumber }) => {
    const text = `Are you sure you want to cancel order ${orderNumber}?`
    const doCancel = await UIService.ConfirmationModal.show({ text })
    if (doCancel) {
      try {
        const order = { status: 'Cancelled' }
        await RestService.put(`/mlb/${partnerId}/orders/${orderId}`, { order })
      } catch (error) {
        HandleError({ error })

        return false
      }
      UIService.FlashMessage.displaySuccessMessage(
        `Order ${orderNumber} successfully cancelled`,
      )

      return true
    }

    return false
  }

export const SendMLBOrderReceipt =
  ({ RestService, UIService, HandleError }) =>
  async ({ partnerId, orderId, orderNumber }) => {
    const text = `Are you sure you want to send a receipt for order ${orderNumber}?`
    const doSend = await UIService.ConfirmationModal.show({ text })
    if (doSend) {
      try {
        await RestService.put(
          `/mlb/${partnerId}/orders/${orderId}/send_receipt`,
        )

        UIService.FlashMessage.displaySuccessMessage(
          `Order ${orderNumber} receipt sent`,
        )
      } catch (error) {
        HandleError({ error })

        return false
      }
    }

    return true
  }

export const SaveMLBOrder =
  ({ RestService, UIService, HandleError }) =>
  async ({ order, freeOrder }) => {
    try {
      if (order.id) {
        await RestService.put(`/mlb/${order.partnerId}/orders/${order.id}`, {
          order,
          freeOrder,
        })
        UIService.FlashMessage.displaySuccessMessage(
          `Order ${order.number} successfully updated`,
        )
      } else {
        const newOrder = await RestService.post(
          `/mlb/${order.partnerId}/orders`,
          { order, freeOrder },
        )
        UIService.FlashMessage.displaySuccessMessage(
          `Order ${newOrder.number} successfully created`,
        )
      }
    } catch (error) {
      HandleError({ error })

      return false
    }

    return true
  }

export const CalcMLBOrderBalance =
  ({ RestService, HandleError }) =>
  async (order) => {
    let balances
    try {
      if (order.id) {
        balances = await RestService.post(
          `/mlb/${order.partnerId}/calc_balance`,
          order,
        )
      }
    } catch (error) {
      HandleError({ error })

      return false
    }

    return balances
  }

export const MarkMLBOrderDelivered =
  ({ RestService, HandleError }) =>
  async ({ mlbPartnerId, orderId, delivered }) => {
    try {
      await RestService.put(
        `/mlb/${mlbPartnerId}/orders/${orderId}/delivered`,
        { delivered },
      )
    } catch (error) {
      HandleError({ error })

      return false
    }

    return true
  }
