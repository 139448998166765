import { connect } from 'react-redux'

import ServingUtensils from '@components/supply/ServingUtensils'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { servingUtensils } = state

  return {
    servingUtensils,
  }
}

const mapDispatchToProps = () => {
  const {
    RestService,
    RouterService,
    SessionService,
    SupplyService,
    UIService,
  } = services
  const { pResponseGeneric, pRequestUpdateServingUtensil } = presenters.Api
  const { pResponseErrorsArray } = presenters.Errors
  const {
    DeleteServingUtensil,
    LoadServingUtensils,
    SaveServingUtensil,
    HandleError: HandleError_,
  } = coordinators
  const HandleError = HandleError_({
    RouterService,
    SessionService,
    UIService,
    pResponseError: pResponseErrorsArray,
  })

  const deleteServingUtensil = DeleteServingUtensil({
    LoadServingUtensils,
    RestService,
    SupplyService,
    UIService,
    pResponseServingUtensils: pResponseGeneric,
    HandleError,
  })
  const loadServingUtensils = LoadServingUtensils({
    RestService,
    SupplyService,
    pResponseServingUtensils: pResponseGeneric,
    HandleError,
  })
  const saveServingUtensil = SaveServingUtensil({
    LoadServingUtensils,
    RestService,
    SupplyService,
    pRequestUpdateServingUtensil,
    pResponseServingUtensils: pResponseGeneric,
    HandleError,
  })

  return {
    deleteServingUtensil,
    loadServingUtensils,
    saveServingUtensil,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServingUtensils)
