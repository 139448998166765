import React, { useState, ChangeEvent, Fragment } from 'react'
import styled from '@emotion/styled'
import { FlexContainer, YSpacing } from '@components/common'
import { ClientTable } from '@containers/common'
import { colors } from '../../../constants'
import { SearchCompaniesReq } from '@hooks/hubspot'
import { HubspotCompany, HubspotAccount, IndexHeadquarter } from '@types'
import { HubspotCompanyTable } from '@components/common'
import MarketSelectionModal from './MarketSelectionModal'

interface HubspotPageProps {
  accounts: HubspotAccount[]
  hubspotCompanies: HubspotCompany[]
  loadHubspotCompanies: (req: SearchCompaniesReq) => Promise<HubspotCompany[]>
  bulkUpdateHubspotAccounts: (accounts: HubspotAccount[]) => Promise<boolean>
  updateHubspotCompanies: (
    companies: { id: string; properties: { [key: string]: string | number } }[],
  ) => Promise<boolean>
}

const HubspotPage: React.FC<HubspotPageProps> = ({
  accounts,
  hubspotCompanies,
  loadHubspotCompanies,
  bulkUpdateHubspotAccounts,
  updateHubspotCompanies,
}) => {
  const [selectedHubspotCompanies, setSelectedHubspotCompanies] = useState<{
    [key: string]: boolean
  }>({})
  const [selectedRailsAccounts, setSelectedRailsAccounts] = useState<{
    [key: string]: boolean
  }>({})
  const [isReconciling, setIsReconciling] = useState<boolean>(false)
  const [toReconcile, setToReconcile] = useState<
    { hubspotId: string; name: string; markets: IndexHeadquarter[] }[]
  >([])
  const [hubspotUpdates, setHubspotUpdates] = useState<
    { id: string; properties: { [key: string]: string | number } }[]
  >([])

  const onSelect =
    (type: string) => (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target as HTMLInputElement
      switch (type) {
        case 'hubspot': {
          const newCompanies = { ...selectedHubspotCompanies }
          if (!checked) {
            delete newCompanies[id]
          } else {
            newCompanies[id] = true
          }
          setSelectedHubspotCompanies(newCompanies)
          break
        }
        case 'admin': {
          const newAccounts = { ...selectedRailsAccounts }
          if (!checked) {
            delete newAccounts[id]
          } else {
            newAccounts[id] = true
          }
          setSelectedRailsAccounts(newAccounts)
          break
        }
        default:
          break
      }
    }

  const onUpdateAccounts = async () => {
    const hubspotCompany = hubspotCompanies.find(
      (company: HubspotCompany) =>
        company.id.toString() === Object.keys(selectedHubspotCompanies)[0],
    )
    if (!hubspotCompany) {
      return
    }

    const { id, domain } = hubspotCompany
    const selectedAccountIds = Object.keys(selectedRailsAccounts)

    const toUpdate = accounts
      .filter((account: HubspotAccount) =>
        selectedAccountIds.includes(account.id),
      )
      .map((account: HubspotAccount) => ({
        ...account,
        domain,
        hubspotId: id,
      }))

    const updated = await bulkUpdateHubspotAccounts(toUpdate)
    if (updated) {
      setSelectedRailsAccounts({})
      setSelectedHubspotCompanies({})
    }
  }

  const onUpdateHubspotCompanies = async () => {
    if (hubspotUpdates.length > 0) {
      const updated = await updateHubspotCompanies(hubspotUpdates)
      if (updated) {
        setSelectedRailsAccounts({})
        setSelectedHubspotCompanies({})
      }
      setHubspotUpdates([])

      return
    }

    const railsAccount = accounts.find(
      (account: HubspotAccount) =>
        account.id === Object.keys(selectedRailsAccounts)[0],
    )
    if (!railsAccount) {
      return
    }

    const selectedHubspotIds = Object.keys(selectedHubspotCompanies)
    const companies = hubspotCompanies.filter((company: HubspotCompany) =>
      selectedHubspotIds.includes(company.id.toString()),
    )

    const { pin, markets } = railsAccount
    if (markets.length > 1) {
      setIsReconciling(true)
      setToReconcile(
        companies.map((company: HubspotCompany) => ({
          hubspotId: company.id,
          name: company.name,
          markets,
        })),
      )
      setHubspotUpdates(
        companies.map((company: HubspotCompany) => ({
          id: company.id,
          properties: {
            pin: `${pin}`,
          },
        })),
      )

      return
    }

    const toUpdate = companies.map((company: HubspotCompany) => ({
      id: company.id,
      properties: {
        pin: `${pin}`,
        metro: markets[0].prefix, // options in hubspot are the metro ordering prefix
      },
    }))

    const updated = await updateHubspotCompanies(toUpdate)
    if (updated) {
      setSelectedRailsAccounts({})
      setSelectedHubspotCompanies({})
    }
  }

  const onConfirmReconcile = (reconciledMap: { [key: string]: string }) => {
    hubspotUpdates.forEach((update) => {
      update.properties.metro = reconciledMap[update.id]
    })
    setHubspotUpdates([...hubspotUpdates])
    setIsReconciling(false)
  }

  const renderSaveButton = () => {
    const countSelectedHubspotCompanies = Object.keys(
      selectedHubspotCompanies,
    ).length
    const countSelectedRailsAccounts = Object.keys(selectedRailsAccounts).length

    if (countSelectedHubspotCompanies == 1 && countSelectedRailsAccounts > 1) {
      return renderLinkHubspotButton()
    } else if (
      countSelectedHubspotCompanies > 1 &&
      countSelectedRailsAccounts === 1
    ) {
      return renderLinkAdminButton()
    } else if (
      countSelectedHubspotCompanies === 1 &&
      countSelectedRailsAccounts === 1
    ) {
      return (
        <div className="flex flex-col">
          {renderLinkHubspotButton()}
          <YSpacing height="10px" />
          {renderLinkAdminButton()}
        </div>
      )
    }

    return null
  }

  const renderLinkHubspotButton = () => {
    const accountCount = Object.keys(selectedRailsAccounts).length

    return (
      <button
        style={{
          padding: '8px 15px',
          borderRadius: '5px',
        }}
        className="font-semibold bg-blue-500 text-white hover:bg-blue-700"
        onClick={onUpdateAccounts}
      >
        {`Link Hubspot ID with ${accountCount} Account${
          accountCount === 1 ? '' : 's'
        }`}
      </button>
    )
  }

  const renderLinkAdminButton = () => {
    const companyCount = Object.keys(selectedHubspotCompanies).length

    return (
      <button
        style={{
          padding: '8px 15px',
          borderRadius: '5px',
        }}
        className="font-semibold bg-blue-500 text-white hover:bg-blue-700"
        onClick={onUpdateHubspotCompanies}
      >
        {`Link Admin PIN with ${companyCount} Compan${
          companyCount === 1 ? 'y' : 'ies'
        }`}
      </button>
    )
  }

  return (
    <Fragment>
      <FlexContainer flexDirection="column">
        <YSpacing height="40px" />
        <HubspotPageBody>
          <h1>Hubspot Dashboard</h1>
          <FlexContainer flexDirection="row" width="100%">
            <div
              id="hubspot-table"
              style={{
                height: '80vh',
                width: '50%',
                overflowY: 'scroll',
              }}
            >
              <HubspotCompanyTable
                companies={hubspotCompanies}
                renderSaveButton={renderSaveButton}
                loadHubspotCompanies={loadHubspotCompanies}
                selectedCompanies={selectedHubspotCompanies}
                onSelectCompany={onSelect('hubspot')}
              />
            </div>
            <div
              id="client-table"
              style={{
                height: '80vh',
                width: '50%',
                overflowY: 'scroll',
              }}
            >
              <ClientTable
                selectedAccounts={selectedRailsAccounts}
                onSelectAccount={onSelect('admin')}
              />
            </div>
          </FlexContainer>
        </HubspotPageBody>
      </FlexContainer>
      {isReconciling && (
        <MarketSelectionModal
          toReconcile={toReconcile}
          onConfirm={onConfirmReconcile}
          hideModal={() => setIsReconciling(false)}
        />
      )}
    </Fragment>
  )
}

export default HubspotPage

const HubspotPageBody = styled.div`
  width: 100vw;
  padding: 0 20px;
  h1 {
    font-size: 25px;
    margin-bottom: 10px;
    font-family: 'bold';
    color: ${colors.gray400};
  }
`
