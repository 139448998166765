import { connect } from 'react-redux'
import { LatePaymentsTab } from '@components/notifications'
import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapDispatchToProps = () => {
  const { RestService, UIService } = services

  const { pResponseNotificationOrders, pResponseAccounts } = presenters.Api

  const {
    GetNextCronTime,
    GetLatePaymentOrders,
    SendLatePaymentOrders,
    SearchIndexAccounts,
  } = coordinators

  const getNextCronTime = GetNextCronTime({ RestService, HandleError })
  const getLatePaymentOrders = GetLatePaymentOrders({
    RestService,
    HandleError,
    pResponseNotificationOrders,
  })
  const sendLatePaymentOrders = SendLatePaymentOrders({
    RestService,
    UIService,
    HandleError,
  })

  const searchAccounts = SearchIndexAccounts({ RestService, pResponseAccounts })

  return {
    getNextCronTime,
    getLatePaymentOrders,
    sendLatePaymentOrders,
    searchAccounts,
  }
}

export default connect(undefined, mapDispatchToProps)(LatePaymentsTab)
