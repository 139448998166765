import { connect } from 'react-redux'

import PackagingAddOns from '@components/supply/PackagingAddOns'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { packaging, packagingAddOns } = state

  return {
    packaging,
    packagingAddOns,
  }
}

const mapDispatchToProps = () => {
  const {
    RestService,
    RouterService,
    SessionService,
    SupplyService,
    UIService,
  } = services
  const { pResponseGeneric, pRequestUpdatePackagingAddOn } = presenters.Api
  const { pResponseErrorsArray } = presenters.Errors
  const {
    DeletePackagingAddOn,
    LoadPackaging,
    LoadPackagingAddOns,
    SavePackagingAddOn,
    HandleError: HandleError_,
  } = coordinators

  const HandleError = HandleError_({
    RouterService,
    SessionService,
    UIService,
    pResponseError: pResponseErrorsArray,
  })
  const deletePackagingAddOn = DeletePackagingAddOn({
    LoadPackagingAddOns,
    RestService,
    SupplyService,
    UIService,
    pResponsePackagingAddOns: pResponseGeneric,
    HandleError,
  })
  const loadPackaging = LoadPackaging({
    RestService,
    SupplyService,
    pResponsePackaging: pResponseGeneric,
    HandleError,
  })
  const loadPackagingAddOns = LoadPackagingAddOns({
    RestService,
    SupplyService,
    pResponsePackagingAddOns: pResponseGeneric,
    HandleError,
  })
  const savePackagingAddOn = SavePackagingAddOn({
    LoadPackagingAddOns,
    RestService,
    SupplyService,
    pRequestUpdatePackagingAddOn,
    pResponsePackagingAddOns: pResponseGeneric,
    HandleError,
  })

  return {
    deletePackagingAddOn,
    loadPackaging,
    loadPackagingAddOns,
    savePackagingAddOn,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PackagingAddOns)
