import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/common/modal/Modal'
import { SelectInput } from '@components/common/form'

class AlertChef extends Component {
  state = {
    chefId: undefined,
  }

  onAlertChef = () => {
    const { chefId } = this.state
    if (chefId) {
      this.props.emailChef(chefId)
    }
  }

  onAlertChefs = () => {
    this.props.emailChefs()
  }

  onSelectChef = (chefId) => {
    this.setState({ chefId })
  }

  render() {
    const { order, hideModal } = this.props
    const { chefId } = this.state

    return (
      <Modal title="Send Order To Chef" hideModal={hideModal} color="#001940">
        <div className="center-panel">
          <p> Alert the chef about the upcoming order:</p>
          {order && order.chefs ? (
            <div>
              <SelectInput
                label="Alert Chef"
                name="alert_chef"
                value={chefId}
                options={order.chefs.map((c) => ({
                  value: c.id,
                  text: c.name,
                }))}
                onChange={this.onSelectChef}
              />
              <button
                className="button-neutral"
                disabled={!chefId}
                onClick={this.onAlertChef}
              >
                Email &amp; Text Selected Chef
              </button>
              <button className="button-neutral" onClick={this.onAlertChefs}>
                Email &amp; Text All Updated Chefs
              </button>
            </div>
          ) : (
            <p> No chefs for this order! </p>
          )}
        </div>
      </Modal>
    )
  }
}

AlertChef.propTypes = {
  actionRequired: PropTypes.bool,
  className: PropTypes.string,
  order: PropTypes.object,

  emailChef: PropTypes.func,
  emailChefs: PropTypes.func,
  hideModal: PropTypes.func,
}

AlertChef.defaultProps = {
  actionRequired: true,
}

export default AlertChef
