import { connect } from 'react-redux'

import AccountingTab from '@components/dashboard/AccountingTab'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { user, ordersDashboard, ordersDashboardPage } = state
  const { isLoading } = ordersDashboardPage
  const { orders } = ordersDashboard
  const { lastHeadquarterSignIn } = user || {}

  return {
    data: orders || [],
    isLoading,
    hq: lastHeadquarterSignIn,
  }
}

const mapDispatchToProps = () => {
  const { DashboardService, RestService, UIService } = services
  const {
    DashboardEditOrder,
    DashboardEditOrders,
    UpdatePaymentReceived,
    GetAccountingDashboard,
  } = coordinators

  const {
    pRequestCateringOrdersDashboardParams,
    pResponseCateringOrderDashboardItems,
    pRequestMLBOrdersDashboardParams,
    pRequestPayOrderWithExternal,
    pResponseMLBOrderDashboardItems,
    pRequestPopUpsDashboardParams,
    pResponsePopUpsDashboardItems,
    pOrderDashboardUpdate,
    pOrderDashboardUpdates,
    pRequestVXOrdersDashboardParams,
    pResponseVXOrderDashboardItems,
    pRequestGroupOrdersDashboardParams,
    pResponseGroupOrderDashboardItems,
    pCSVOrderDashboardItems,
  } = presenters.Api
  const { suggestServiceTime } = DashboardService

  const dashboardEditOrder = DashboardEditOrder({
    DashboardService,
    RestService,
    UIService,
    pOrderDashboardUpdate,
    HandleError,
  })
  const dashboardEditOrders = DashboardEditOrders({
    DashboardService,
    RestService,
    UIService,
    pOrderDashboardUpdates,
  })
  const updatePaymentReceived = UpdatePaymentReceived({
    RestService,
    HandleError,
    pRequestPayOrderWithExternal,
  })
  const timeoutInfiniteLoadData = GetAccountingDashboard({
    DashboardService,
    RestService,
    UIService,
    pRequestCateringOrdersDashboardParams,
    pResponseCateringOrderDashboardItems,
    pRequestPopUpsDashboardParams,
    pResponsePopUpsDashboardItems,
    pRequestMLBOrdersDashboardParams,
    pResponseMLBOrderDashboardItems,
    pRequestVXOrdersDashboardParams,
    pResponseVXOrderDashboardItems,
    pRequestGroupOrdersDashboardParams,
    pResponseGroupOrderDashboardItems,
    HandleError,
  })

  return {
    dashboardEditOrder,
    dashboardEditOrders,
    suggestServiceTime,
    timeoutInfiniteLoadData,
    updatePaymentReceived,
    pCSVOrderDashboardItems,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingTab)
