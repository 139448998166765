import { connect } from 'react-redux'

import { EditUserModal } from '@components/user/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { editAdminUser, editUserModal, headquarter, hubspotUser, user } = state
  const { show } = editUserModal
  const errors = state.errors.editUserModal || {}
  const { headquarters } = headquarter
  const { lastLoggedInHq: currentHeadquarterId } = user || {}

  return {
    currentHeadquarterId,
    errors,
    headquarters,
    hubspotUser,
    user: editAdminUser,
    show,
  }
}

const mapDispatchToProps = () => {
  const { AdminUserService, HubspotUserService, RestService, UIService } =
    services
  const {
    DelayedUpdateUser,
    LoadHubspotSingle,
    LoadOrSearchHubspotList,
    SaveUser,
    SetHubspotRelation,
  } = coordinators
  const { pStateToReduxUser } = presenters
  const {
    pRequestHubspotList,
    pRequestUpdateUser,
    pResponseAdminUser,
    pResponseHubspotReps,
    pResponseHubspotSingle,
  } = presenters.Api

  const { close } = UIService.EditUser
  const { clear: clearErrors, clearSingle } = UIService.Errors
  const clearError = clearSingle('editUserModal')
  const delayedUpdateUser = DelayedUpdateUser({
    AdminUserService,
    UIService,
    pStateToReduxUser,
  })
  // const deleteUser = DeleteUser({ AdminUserService, RestService, UIService, HandleError })
  const saveUser = SaveUser({
    AdminUserService,
    RestService,
    UIService,
    pRequestUpdateUser,
    pResponseAdminUser,
    HandleError,
  })
  const loadHubspotUser = LoadHubspotSingle({
    RestService,
    HubspotService: HubspotUserService,
    pResponseHubspotSingle,
    endpoint: 'users',
  })
  const loadHubspotUsers = LoadOrSearchHubspotList({
    RestService,
    HubspotService: HubspotUserService,
    pRequestHubspotList,
    pResponseHubspotList: pResponseHubspotReps,
    endpoint: 'users',
  })
  const updateHubspotUserRef = SetHubspotRelation({
    RestService,
    HubspotService: HubspotUserService,
    pResponseHubspotSingle,
    endpoint: 'users',
  })

  return {
    clearError,
    clearErrors,
    close,
    delayedUpdateUser,
    loadHubspotUser,
    loadHubspotUsers,
    saveUser,
    updateHubspotUserRef,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal)
