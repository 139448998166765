import React, { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import SidebarSearch from '@components/sidebar/SidebarSearch'
import SidebarListItem from '@components/sidebar/SidebarListItem'
import { Dropdown } from '@components/common/form'
import FlexContainer from '@components/common/FlexContainer'

export class AccountMaster extends Component {
  state = {
    search: '',
    sourceType: 'admin_panel',
    page: 1,
    resultsPerPage: 30,
  }

  componentDidMount() {
    const { filter, location, selectAccount } = this.props
    const { search, sourceType, resultsPerPage } = this.state

    const qParams = location.search
    if (qParams) {
      const params = new URLSearchParams(qParams)
      const accountId = params.get('account_id')
      if (accountId) {
        this.setState({ activeAccountId: accountId })
        selectAccount(accountId)
      }
    }

    this.props.loadAccounts({
      status: filter,
      search,
      sourceType,
      resultsPerPage,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filter !== this.props.filter) {
      const { search, sourceType } = this.state
      this.props.loadAccounts({ status: nextProps.filter, search, sourceType })
    }
  }

  onSelectAccount = (accountId) => {
    this.setState({ activeAccountId: accountId })
    this.props.selectAccount(accountId)
  }

  onSelectAccountType = (e) => {
    this.setState({ sourceType: e.target.value }, this.onDelaySearch)
  }

  onSearch = (e) => {
    const search = e.target.value
    this.setState({ search }, this.onDelaySearch)
  }

  onDelaySearch = () => {
    const { filter, loadAccounts } = this.props
    clearTimeout(this.searchTimer)

    this.searchTimer = setTimeout(() => {
      const { sourceType, search } = this.state
      const params = { status: filter, search }
      if (sourceType) {
        params.sourceType = sourceType
      }
      loadAccounts(params)
    }, 300)
  }

  loadNextAccounts() {
    const { filter, loadAccounts } = this.props
    const { search, sourceType, page, resultsPerPage } = this.state

    loadAccounts({
      page,
      status: filter,
      search,
      sourceType,
      resultsPerPage,
    })
  }

  render() {
    const { accounts } = this.props
    const { activeAccountId, sourceType, page } = this.state

    return (
      <div>
        <SidebarSearch label="Search Accounts" onChange={this.onSearch} />
        <FlexContainer padding="0px 10px 0px 10px">
          <Dropdown
            label="Creation Source"
            width="90%"
            value={sourceType}
            onChange={this.onSelectAccountType}
          >
            <option value=""> All </option>
            <option value="marketplace"> Marketplace </option>
            <option value="admin_panel"> Admin Panel </option>
          </Dropdown>
        </FlexContainer>
        {accounts.map((a) => {
          const selected = a.id === activeAccountId

          return (
            <SidebarListItem
              key={a.id}
              id={a.id}
              isSelected={selected}
              onClick={() => this.onSelectAccount(a.id)}
            >
              {a.name}
            </SidebarListItem>
          )
        })}
        {accounts.length === 0 && (
          <SidebarListItem>
            <ItemDetails>No Accounts Found</ItemDetails>
          </SidebarListItem>
        )}
        {
          <div className="flex">
            {accounts && page > 1 && (
              <ArrowButton
                className="page-arrow"
                onClick={() => {
                  this.setState(
                    (state) => ({ page: state.page - 1 }),
                    this.loadNextAccounts,
                  )
                }}
                disabled={page === 1}
              >
                ◄
              </ArrowButton>
            )}
            <Page className="pre page-number">Page {page}</Page>
            {accounts && accounts.length > 0 && (
              <ArrowButton
                className="page-arrow"
                onClick={() => {
                  this.setState(
                    (state) => ({ page: state.page + 1 }),
                    this.loadNextAccounts,
                  )
                }}
                disabled={false}
              >
                ►
              </ArrowButton>
            )}
          </div>
        }
      </div>
    )
  }
}

export const ItemDetails = styled.div`
  color: white;
  font-size: 14px;
  p {
    color: white;
  }
  .extrabold {
    font-family: 'extrabold';
  }
`

const Page = styled.div`
  padding: 0 20px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  padding: 6px 10px;
  color: white;
  // background: #fff;
  //border: 1px solid #fff;
  border-radius: 4px;
  font-size: 15px;
  ::placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-family: 'regular';
  }
`

const ArrowButton = styled.button`
  padding: 0 20px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  padding: 6px 10px;
  color: white;
  // background: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 15px;
  ::placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-family: 'regular';
  }
`

AccountMaster.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.object,
  location: PropTypes.object,

  loadAccounts: PropTypes.func,
  selectAccount: PropTypes.func,
}

export default AccountMaster
