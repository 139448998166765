import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { LabelInfo } from '@components/common/form'
import { BiPencil } from 'react-icons/bi'
import { IoCloseOutline } from 'react-icons/io5'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import { AuthorizedInteractable } from '@containers/common/auth'
import { ButtonsBar } from '@res/styledComponents/index'
import Moment from 'moment-timezone'

const HQListGroup = (props) => {
  const [lastChange, setLastChange] = useState(null)
  const [showLastChange, setShowLastchange] = useState(false)
  const [marketplaceSalesRep, setMarketplaceSalesRep] = useState({})
  const {
    hqDetails,
    onEdit,
    getAdminUser,
    getLastChangeToGroupOrderMinimumItems,
  } = props

  const {
    address,
    locale,
    name,
    prefix,
    cartMinimum,
    deliveryFee,
    groupOrderMinimumItems,
    marketplaceSalesRepId,
  } = hqDetails
  const fullAddress = address
    ? `${address.line1}, ${address.line2}, ${address.city}, ${address.state} ${address.zip}`
    : null

  useEffect(() => {
    if (hqDetails?.id) {
      setLastChange(null)
      setShowLastchange(false)
    }
  }, [hqDetails?.id])

  useEffect(() => {
    getMarketplaceSalesRep()
  }, [marketplaceSalesRepId])

  if (!hqDetails) {
    return null
  }

  const getMarketplaceSalesRep = async () => {
    const marketplaceSalesRep = await getAdminUser(marketplaceSalesRepId)
    if (marketplaceSalesRep) {
      setMarketplaceSalesRep(marketplaceSalesRep)
    }
  }

  const getLastChange = async () => {
    const result = await getLastChangeToGroupOrderMinimumItems({
      id: hqDetails.id,
    })
    if (result) {
      setLastChange(result.changeData)
    }
    setShowLastchange(true)
  }

  return (
    <div>
      <ButtonsBar>
        <AuthorizedInteractable roles={['master admin', 'captain lead']}>
          <button onClick={onEdit}>
            <BiPencil /> Edit
          </button>
        </AuthorizedInteractable>
      </ButtonsBar>
      <Panel width="100%" maxWidth="400px" heading="Headquarter Details">
        <FlexContainer justifyContent="space-between">
          <LabelInfo label="Name" value={name} width="48%" testId="name" />
          <LabelInfo
            label="Order Prefix"
            value={prefix}
            width="48%"
            testId="orderPrefix"
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <LabelInfo
            label="Locale"
            value={locale}
            width="48%"
            testId="locale"
          />
          <LabelInfo
            label="Address"
            value={fullAddress}
            width="48%"
            testId="address"
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <LabelInfo
            label="Cart Minimum"
            value={cartMinimum}
            width="48%"
            testId="cartMinimum"
          />
          <LabelInfo
            label="Delivery Fee"
            value={deliveryFee}
            width="48%"
            testId="deliveryFee"
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <LabelInfo
            label="Daily Group Order Items Minimum"
            value={groupOrderMinimumItems}
            width="48%"
            testId="groupOrderMinimumItems"
          />
        </FlexContainer>
        <YSpacing height="10px" />
        <FlexContainer>
          <div className="last-change-section">
            {showLastChange ? (
              <div className="last-change-container">
                <div className="change-close-button-container">
                  <button
                    onClick={() => setShowLastchange(false)}
                    className="change-close-button"
                  >
                    <IoCloseOutline />
                  </button>
                </div>
                <div className="change-content">
                  {lastChange === null ? (
                    <div className="no-changes-found">No changes found</div>
                  ) : (
                    <div className="changes-found">
                      <div className="change-item">
                        <div className="change-label">Current value:</div>
                        <div className="change-value">
                          {lastChange.currentValue}
                        </div>
                      </div>
                      <div className="change-item">
                        <div className="change-label">Previous value:</div>
                        <div className="change-value">
                          {lastChange.prevValue}
                        </div>
                      </div>
                      <div className="change-item">
                        <div className="change-label">Changed by:</div>
                        <div className="change-value">
                          {lastChange.userEmail}
                        </div>
                      </div>
                      <div className="change-item">
                        <div className="change-label">Changed at:</div>
                        <div className="change-value">
                          {Moment(lastChange.timestamp).format('LLLL')}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <button
                onClick={() => getLastChange()}
                className="show-change-button"
              >
                View change info
              </button>
            )}
          </div>
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <LabelInfo
            label="Marketplace Sales Rep"
            value={
              marketplaceSalesRep.firstName
                ? `${marketplaceSalesRep.firstName} ${marketplaceSalesRep.lastName}`
                : ''
            }
            width="48%"
            testId="marketplaceSalesRep"
          />
        </FlexContainer>
        <YSpacing height="20px" />
      </Panel>
    </div>
  )
}

HQListGroup.propTypes = {
  onEdit: PropTypes.func,
  getAdminUser: PropTypes.func,
  getLastChangeToGroupOrderMinimumItems: PropTypes.func,

  hqDetails: PropTypes.object,
}

export default HQListGroup
