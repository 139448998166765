import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {
  DeliveryZoneListGroup,
  HQListGroup,
  ServiceDepotListGroup,
} from '@components/market/edit/sections'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { colors } from '../../../constants'

export class MarketDetail extends Component {
  state = {
    tab: 'Headquarter Details',
  }

  onEditHQ = (e) => {
    e.preventDefault()
    e.stopPropagation()

    this.props.editHeadquarter()
  }

  onCreateServiceDepot = () =>
    this.props.createServiceDepot(this.props.hqDetails)

  onEditServiceDepot = (sDepot) =>
    this.props.editServiceDepot({
      ...sDepot,
      headquarter: this.props.hqDetails,
    })

  onDeleteServiceDepot = (sDepot) =>
    this.props.deleteServiceDepot(sDepot, this.props.hqDetails)

  onEditDeliveryZone = (dZone) =>
    this.props.editDeliveryZone({ ...dZone, headquarter: this.props.hqDetails })

  onCreateDeliveryZone = () =>
    this.props.createDeliveryZone(this.props.hqDetails)

  onDeleteDeliveryZone = (dZone) =>
    this.props.deleteDeliveryZone(dZone, this.props.hqDetails)

  render() {
    const { tab } = this.state
    const {
      deliveryZones,
      hqDetails,
      loadDeliveryZones,
      loadServiceDepots,
      serviceDepots,
      getAdminUser,
      getLastChangeToGroupOrderMinimumItems,
    } = this.props
    if (!hqDetails) {
      return null
    }

    const tabs = ['Headquarter Details', 'Service Depots', 'Delivery Zones']

    return (
      <FlexContainer width="100%" alignItems="center" justifyContent="center">
        <div>
          {tabs.map((t) => (
            <Tab
              key={t}
              isActive={t === tab}
              onClick={() => this.setState({ tab: t })}
            >
              {t}
            </Tab>
          ))}
          <YSpacing height="20px" />
          {tab === 'Headquarter Details' && (
            <HQListGroup
              hqDetails={hqDetails}
              onEdit={this.onEditHQ}
              getAdminUser={getAdminUser}
              getLastChangeToGroupOrderMinimumItems={
                getLastChangeToGroupOrderMinimumItems
              }
            />
          )}
          {tab === 'Service Depots' && (
            <ServiceDepotListGroup
              hqId={hqDetails.id}
              onCreate={this.onCreateServiceDepot}
              onDelete={this.onDeleteServiceDepot}
              onEdit={this.onEditServiceDepot}
              serviceDepots={serviceDepots}
              loadServiceDepots={loadServiceDepots}
            />
          )}
          {tab === 'Delivery Zones' && (
            <DeliveryZoneListGroup
              hqId={hqDetails.id}
              onCreate={this.onCreateDeliveryZone}
              onDelete={this.onDeleteDeliveryZone}
              onEdit={this.onEditDeliveryZone}
              loadDeliveryZones={loadDeliveryZones}
              deliveryZones={deliveryZones}
            />
          )}
        </div>
      </FlexContainer>
    )
  }
}

const Tab = styled.button`
  font-family: 'bold';
  padding: 10px 0;
  margin-right: 20px;
  border-bottom: 3px solid
    ${(props) => (props.isActive ? colors.orange : 'rgba(0,0,0,0)')};
  color: ${colors.gray400};
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
`

MarketDetail.propTypes = {
  deliveryZones: PropTypes.array,
  serviceDepots: PropTypes.array,

  hqDetails: PropTypes.object,

  createDeliveryZone: PropTypes.func,
  createServiceDepot: PropTypes.func,
  deleteDeliveryZone: PropTypes.func,
  deleteServiceDepot: PropTypes.func,
  editDeliveryZone: PropTypes.func,
  editHeadquarter: PropTypes.func,
  editServiceDepot: PropTypes.func,
  loadDeliveryZones: PropTypes.func,
  loadServiceDepots: PropTypes.func,
  saveHeadquarter: PropTypes.func,
  getAdminUser: PropTypes.func,
  getLastChangeToGroupOrderMinimumItems: PropTypes.func,
}

export default MarketDetail
