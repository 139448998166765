import Moment from 'moment-timezone'

import { sortByAttribute } from '~/utils'

export const requestCreateCart = ({ deliveryInfo, menuCard }) => {
  const { date, headCount, zipcode } = deliveryInfo

  return {
    cart: {
      chef_id: menuCard.chef.id,
      concept_id: menuCard.id,
      client_set_up_time: Moment(date).toDate(),
      head_count: headCount,
      zip_code: zipcode,
    },
  }
}

export const responseCreateCart = (json) => {
  const {
    id,
    cart_items: cartItems,
    carts_payment_methods: paymentMethods,
    chef_id: chefId,
    client_set_up_time: deliveryDate,
    concept_id: conceptId,
    dropoff_address: deliveryAddress,
    head_count: headCount,
    hungry_other_menu_items: hungryMenuItems,
    payment_method: paymentMethod,
    service_fee: serviceFee,
    set_up_style: setupStyle,
    set_up_style_pricing: setupStylePricing,
    special_instructions: chefInstructions,
    subtotal,
    total,
  } = json

  const entrees = []
  const sides = []
  const desserts = []
  const drinks = []
  const appetizers = []
  for (const i of cartItems) {
    let collection
    let ignore = false
    switch (i.meal_type) {
      case 'Main':
        collection = entrees
        break
      case 'Side':
        collection = sides
        break
      case 'Dessert':
        collection = desserts
        break
      case 'Drink':
        collection = drinks
        break
      case 'Appetizer':
        collection = appetizers
        break
      default:
        ignore = true
    }
    if (!ignore) {
      collection.push({
        id: i.id,
        menuItemID: i.menu_item_id,
        name: i.name,
        price: Number(i.price),
        quantity: i.quantity == 0 ? '' : i.quantity,
        suppliedByHungry: i.supplied_by_hungry,
        displayOrder: i.display_order,
      })
    }
  }

  /* sort by most recommended */
  sortByAttribute(entrees, 'displayOrder')
  sortByAttribute(sides, 'displayOrder')
  sortByAttribute(desserts, 'displayOrder')
  sortByAttribute(drinks, 'displayOrder')
  sortByAttribute(appetizers, 'displayOrder')

  return {
    id,
    appetizers,
    chefId,
    chefInstructions,
    conceptId,
    deliveryAddress,
    deliveryDate: Moment(deliveryDate),
    desserts,
    drinks,
    entrees,
    headCount: Number(headCount),
    hungryMenuItems,
    paymentMethod,
    paymentMethods,
    serviceFee: Number(serviceFee),
    setupStyle,
    setupStylePricing,
    sides,
    subtotal: Number(subtotal),
    total: Number(total),
  }
}

export const requestUpdateCartItem = ({ itemID, quantity }) => {
  return {
    cart: {
      cart_items_attributes: [
        {
          id: itemID,
          quantity,
        },
      ],
    },
  }
}

export const requestUpdateCartSides = ({
  itemID,
  displayOrder,
  removeID,
  quantity,
}) => {
  const request = {
    cart: {
      cart_items_attributes: [],
    },
  }
  if (itemID) {
    request.cart.cart_items_attributes.push({
      id: itemID,
      display_order: displayOrder,
      quantity,
    })
  }
  if (removeID) {
    request.cart.cart_items_attributes.push({
      id: removeID,
      display_order: null,
      quantity: 0,
    })
  }

  return request
}

export const requestUpdateCartSetup = (setupStyle) => {
  return {
    cart: {
      set_up_style: setupStyle,
    },
  }
}

export const requestUpdateCartChefInstructions = (chefInstructions) => {
  return {
    cart: {
      special_instructions: chefInstructions,
    },
  }
}

export const requestCartToOrder = ({
  account,
  cartId,
  deliveryInstructions,
  dropoffAddress,
  eventDescription,
  mainContact,
  paymentInfo,
}) => {
  return {
    cart: {
      id: cartId,
      description: eventDescription,
      delivery_instructions: deliveryInstructions,
      contact_attributes: {
        email: mainContact.email,
        first_name: mainContact.firstName,
        last_name: mainContact.lastName,
        phone_number: mainContact.phoneNumber,
      },
      account_attributes: account && {
        name: account.name,
        needs_utensils: account.needs_utensils || false,
        needs_serving_utensils: account.needs_serving_utensils || false,
        building_instructions: account.building_instructions || '',
      },
      carts_dropoff_addresses_attributes: dropoffAddress && {
        source_address_attributes: {
          line1: dropoffAddress.line1 || '',
          line2: dropoffAddress.line2 || '',
          city: dropoffAddress.city || '',
          state: dropoffAddress.state || '',
          zip: dropoffAddress.zip || '',
        },
      },
      carts_payment_methods_attributes: {
        source_payment_method_attributes: {
          nonce: paymentInfo.nonce,
          cardholder_name: paymentInfo.cardHolderName,
        },
      },
    },
  }
}
