import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/common/modal/Modal'
import Button from '@components/common/form/Button'
import YSpacing from '@components/common/YSpacing'

class ClientOrderReminder extends Component {
  state = { recipients: '' }

  onEmailClient = () => {
    const { order } = this.props
    this.props.emailClientOrderReminder(order.id, '')
  }

  onDisableAutoEmailClient = (enabled) => {
    const { order } = this.props
    this.props.disableAutoEmailClientOrderReminder(order.id, enabled)
  }

  render() {
    const { recipients } = this.state
    const { emailClientOrderReminder, order, hideModal } = this.props
    const { clientOrderReminderLastSentStr, autoClientOrderReminder } = order
    const lastSent = clientOrderReminderLastSentStr || 'Never'
    const autoReminder = !autoClientOrderReminder ? 'Enable' : 'Disable'

    return (
      <Modal
        title="Send Client Order Menu Reminder"
        hideModal={hideModal}
        color="#001940"
      >
        <div className="center-panel">
          <p> Get your client excited about their upcoming order!</p>
          <div>Client Reminder Last Sent: {lastSent}</div>
          <button className="button-neutral" onClick={this.onEmailClient}>
            Email Client Their Upcoming Order
          </button>
          {!clientOrderReminderLastSentStr && (
            <button
              className="button-neutral"
              onClick={() =>
                this.onDisableAutoEmailClient(!autoClientOrderReminder)
              }
            >
              {autoReminder} Auto Client Order Reminder
            </button>
          )}
          <div>
            <YSpacing height="20px" />
            Send test email to following semicolon (;) separated email addresses
            <input
              type="text"
              style={{ border: '1px solid #8397ab', borderRadius: '3px' }}
              name="recipients"
              value={recipients}
              onChange={(e) => this.setState({ recipients: e.target.value })}
            />
            <br />
            <YSpacing height="20px" />
            <Button
              label="Send Emails"
              onClick={() =>
                emailClientOrderReminder(order.id, recipients, false)
              }
            />
          </div>
        </div>
      </Modal>
    )
  }
}

ClientOrderReminder.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object,

  disableAutoEmailClientOrderReminder: PropTypes.func,
  emailClientOrderReminder: PropTypes.func,
  hideModal: PropTypes.func,
}

export default ClientOrderReminder
