import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

class PaymentReceivedButton extends React.Component {
  render() {
    const {
      order,
      attribute,
      className,
      key,
      orderUpdates,
      updateOrder,
      updatePaymentReceived,
    } = this.props
    const orderUpdate = orderUpdates[order.id]
    const originalOrder = order[attribute]
    const revisionOrder = orderUpdate && orderUpdate[attribute]
    const value = revisionOrder || originalOrder

    return (
      <td key={key}>
        <select
          onChange={(newVal) =>
            updateOrder(order.id, attribute, newVal.target.value)
          }
          value={value}
          className={className}
          id="select-payment-method"
        >
          <optgroup>
            {[
              'Please select a payment source',
              'Manual Braintree',
              'ACH',
              'Check',
            ].map((o) => {
              return <option key={o}>{o}</option>
            })}
          </optgroup>
        </select>
        <Button
          bsSize="small"
          bsStyle="primary"
          className="dashboard-save-button"
          onClick={() => updatePaymentReceived(order.id, value)}
        >
          Mark Payment Received
        </Button>
      </td>
    )
  }
}

PaymentReceivedButton.propTypes = {
  order: PropTypes.object,
  attribute: PropTypes.string,
  className: PropTypes.string,
  key: PropTypes.string,
  orderUpdates: PropTypes.arrayOf(PropTypes.object),

  updateOrder: PropTypes.func,
  updatePaymentReceived: PropTypes.func,
}

export default PaymentReceivedButton
