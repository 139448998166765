import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import styled from '@emotion/styled'
import Modal from '@components/common/modal/Modal'
import Table from '@components/common/Table'
import Checkbox from '@components/common/form/Checkbox'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { Button, CurrencyInput } from '@components/common/form'
import { Label } from '@res/styledComponents/index'
import LoadingIndicator from '../common/LoadingIndicator'

const InvoiceRefundModal = ({
  displayWarningMessage,
  handleRefundModal,
  invoice,
  overPaymentMap,
  payments,
  refundableTxns,
  refundOrderOnInvoice,
  title,
  updateInvoices,
  user,
}) => {
  const [isLoading, setIsLoading] = useState('')
  const [refundOrderId, setRefundOrderId] = useState('')
  const [refundTxnId, setRefundTxnId] = useState('')
  const [refundAmount, setRefundAmount] = useState('')
  const refundTxn = refundableTxns.find((txn) => txn.id === refundTxnId)

  const handleRefundOrderId = (orderId) => {
    setRefundOrderId(orderId)
    setRefundTxnId('')
  }

  const processRefund = async () => {
    if (!refundAmount) {
      displayWarningMessage('Please enter a refund amount greater than zero.')
      setIsLoading(false)

      return
    }
    const payment = refundTxn.payments.find(
      (p) => p.paymentFor.id === refundOrderId,
    )

    const inv = await refundOrderOnInvoice({
      order: payment.paymentFor,
      invoiceId: invoice.id,
      refundAmount,
      userName: user.name,
      paymentId: payment.id,
      transaction: refundTxn,
    })

    if (inv) {
      updateInvoices(inv)
    } else {
      setIsLoading(false)
    }
  }

  const ordersMap = payments.reduce((acc, pmt) => {
    if (!acc[pmt.paymentFor.id]) {
      acc[pmt.paymentFor.id] = pmt.paymentFor
    }

    return acc
  }, {})
  const transactionsAgainstOrder = refundableTxns.filter(
    (txn) =>
      txn.amount > 0 &&
      txn.payments.find((p) => p.paymentFor.id === refundOrderId),
  )

  return (
    <Modal width="800px" title={title} color="#001940">
      <BackButton onClick={() => handleRefundModal('showRefundModal')}>
        ◀ Go back to Invoice: {invoice.invoiceNumber}
      </BackButton>
      <FlexContainer
        width="80%"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
      >
        <Label>Please select the order you would like to refund</Label>
        <YSpacing height="10px" />
        <Table headings={['Order Number', 'Selected']}>
          {Object.values(ordersMap).map((order) => (
            <tr key={order.id}>
              <td>{order.orderNumber}</td>
              <Checkbox
                label={''}
                checked={order.id === refundOrderId}
                onChange={() => handleRefundOrderId(order.id)}
              />
            </tr>
          ))}
        </Table>
        <YSpacing height="10px" />
        {refundOrderId && (
          <FlexContainer flexDirection="column">
            <YSpacing height="15px" />
            <FlexContainer flexDirection="column">
              <Label>Please select Transaction To Refund</Label>
              <YSpacing height="10px" />
              <Table
                headings={[
                  'Transaction Date',
                  'Transaction Amount',
                  'Selected',
                ]}
              >
                {transactionsAgainstOrder.map((txn) => (
                  <tr key={txn.id}>
                    <td>{Moment(txn.createdAt).format('MM/DD/YYYY')}</td>
                    <td>${txn.amount.toFixed(2)}</td>
                    <td>
                      <Checkbox
                        label=""
                        value=""
                        checked={txn.id === refundTxnId}
                        onChange={() => setRefundTxnId(txn.id)}
                      />
                    </td>
                  </tr>
                ))}
              </Table>
            </FlexContainer>
            {refundTxn && overPaymentMap[refundOrderId] && (
              <div>
                <YSpacing height="10px" />
                <strong style={{ color: 'red' }}>
                  Order {ordersMap[refundOrderId].orderNumber} is currently over
                  paid by ${overPaymentMap[refundOrderId]}
                </strong>
              </div>
            )}
            <YSpacing height="10px" />
            {refundTxn && (
              <Table
                headings={['Order Number', 'Total Paid', 'Refund Amount', '']}
              >
                <tr>
                  <td>{ordersMap[refundOrderId].orderNumber}</td>
                  <td>
                    $
                    {refundTxn.payments
                      .find((p) => p.paymentFor.id === refundOrderId)
                      .amount.toFixed(2)}
                  </td>
                  <td>
                    <CurrencyInput
                      testId="Refund Amount"
                      disabled={!refundTxn}
                      value={refundAmount}
                      onChange={setRefundAmount}
                      width="80%"
                    />
                  </td>
                  <td>
                    {isLoading ? (
                      <LoadingIndicator />
                    ) : (
                      <Button
                        disabled={!refundTxnId}
                        testId="Process Refund"
                        label="Process Refund"
                        onClick={() => {
                          setIsLoading(true)
                          processRefund()
                        }}
                      />
                    )}
                  </td>
                </tr>
              </Table>
            )}
          </FlexContainer>
        )}
      </FlexContainer>
    </Modal>
  )
}

const BackButton = styled.button`
  position: absolute;
  top: 45px;
`

InvoiceRefundModal.propTypes = {
  invoice: PropTypes.object,
  overPaymentMap: PropTypes.object,
  payments: PropTypes.array,
  refundableTxns: PropTypes.array,
  title: PropTypes.string,
  user: PropTypes.string,

  displayWarningMessage: PropTypes.func,
  handleRefundModal: PropTypes.func,
  refundOrderOnInvoice: PropTypes.func,
  updateInvoices: PropTypes.func,
}

export default InvoiceRefundModal
