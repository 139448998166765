export const SendPopUpCancelled =
  ({ RestService, UIService, HandleError }) =>
  async (popUp, testEmail) => {
    try {
      if (popUp.id) {
        await RestService.post('/pop-ups/notif-cancelled', {
          popupId: popUp.id,
          testEmail,
        })
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      'Notifications successfully sent',
    )

    return true
  }

export const SendPopUpDelayed =
  ({ RestService, UIService, HandleError }) =>
  async (popUp, testEmail) => {
    try {
      if (popUp.id) {
        await RestService.post('/pop-ups/notif-delayed', {
          popupId: popUp.id,
          testEmail,
        })
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      'Notifications successfully sent',
    )

    return true
  }

export const SendPopUpNewLocation =
  ({ RestService, UIService, HandleError }) =>
  async (popUp, testEmail) => {
    try {
      if (popUp.id) {
        await RestService.post('/pop-ups/notif-new-location', {
          popupId: popUp.id,
          testEmail,
        })
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      'Notifications successfully sent',
    )

    return true
  }

export const SendPopUpSoldOut =
  ({ RestService, UIService, HandleError }) =>
  async (popUp, testEmail) => {
    try {
      if (popUp.id) {
        await RestService.post('/pop-ups/notif-sold-out', {
          popupId: popUp.id,
          testEmail,
        })
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      'Notifications successfully sent',
    )

    return true
  }

export const SendPopUpAlmostSoldOut =
  ({ RestService, UIService, HandleError }) =>
  async (popUp, testEmail) => {
    try {
      if (popUp.id) {
        await RestService.post('/pop-ups/notif-almost-sold-out', {
          popupId: popUp.id,
          testEmail,
        })
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      'Notifications successfully sent',
    )

    return true
  }

export const SendPopUpNewMenu =
  ({ RestService, UIService, HandleError }) =>
  async (popUp, testEmail) => {
    try {
      if (popUp.id) {
        await RestService.post('/pop-ups/notif-new-menu', {
          popupId: popUp.id,
          testEmail,
        })
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      'Notifications successfully sent',
    )

    return true
  }

export const SendPopUpNewChef =
  ({ RestService, UIService, HandleError }) =>
  async (popUp, testEmail) => {
    try {
      if (popUp.id) {
        await RestService.post('/pop-ups/notif-new-chef', {
          popupId: popUp.id,
          testEmail,
        })
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      'Notifications successfully sent',
    )

    return true
  }

export const GetTargetCustomersCount =
  ({ RestService, HandleError }) =>
  async (popUp) => {
    try {
      if (popUp.id) {
        return await RestService.get('/pop-ups/target-customers', {
          popupId: popUp.id,
        })
      }
    } catch (error) {
      HandleError({ error })

      return 0
    }

    return 0
  }
