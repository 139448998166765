import React from 'react'
import Moment from 'moment-timezone'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Modal from '@components/common/modal/Modal'
import { colors } from '@constants'

const GroupOrderModal = (props) => {
  return (
    <div>
      <Modal hideModal={props.onHide} color="#001940" width="500px">
        <Title>Orders for {Moment(props.date).format('MMM. D (ddd)')}</Title>
        {props.children}
      </Modal>
    </div>
  )
}

const Title = styled.h1`
  color: ${colors.gray400};
  font-family: 'bold';
  font-size: 23px;
`
GroupOrderModal.propTypes = {
  children: PropTypes.obj,
  date: PropTypes.obj,
  onHide: PropTypes.function,
}

export default GroupOrderModal
