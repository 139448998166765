import { BaseService } from 'hungry-core2'

import actions from '@actions'

export default class ProposalPage extends BaseService {
  setLastLoadProposalsParams = ({ page, search, status, fromDate, toDate }) => {
    this.dispatch(
      actions.setLastLoadProposalsParams({
        page,
        search,
        status,
        fromDate,
        toDate,
      }),
    )
  }

  getLastLoadProposalsParams = () => {
    const { page, search, status, fromDate, toDate } =
      this.getState().proposalPage

    return { page, search, status, fromDate, toDate }
  }

  toggleEmailProposalButtonEnabled = (on) => {
    this.dispatch(actions.toggleEmailProposalButtonEnabled(on))
  }

  toggleLoading = (on) => this.dispatch(actions.toggleProposalLoading(on))
}
