import Moment from 'moment-timezone'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import {
  pAccountingCsvUrlPath,
  pAccountingCsvParamsString,
  pGroupOrderDashReportCSVParams,
} from '@presenters/api/reports'
import { HandleError } from '@coordinators/composed'
import {
  CHEF_ACCEPTANCE_COLUMN,
  CHEF_DECLINED_ORDER,
  CHEF_DECLINED_ORDER_BG_COLOR,
  CHEF_EMAIL_GO_DASHBOARD_COLUMN,
  MISSED_CHEF_EMAIL,
  MISSED_CHEF_EMAIL_BG_COLOR,
} from '../../../constants'

const { SessionService, UIService } = services
const pResponseError = presenters.Errors.responseErrorMessage

const getGroupOrderCSVUrl = (apiHost, serverFilters) => {
  return `${apiHost}/group-orders/dashboard-report?${pGroupOrderDashReportCSVParams(
    serverFilters,
  )}`
}

const ReportErrorHandler = ({ error, doFlash }) => {
  doFlash = doFlash === undefined ? true : doFlash
  const errResp = pResponseError(error)
  const { errors } = errResp
  let { errorMessage } = errResp

  if (
    errorMessage &&
    errorMessage.includes('received message larger than max')
  ) {
    errorMessage = `Report results were too large. Please select more filter options. \n(${errorMessage})`
  }

  if (doFlash) {
    if (Array.isArray(errorMessage)) {
      errorMessage.forEach((message) => {
        UIService.FlashMessage.displayFailureMessage(message)
      })
    } else {
      UIService.FlashMessage.displayFailureMessage(errorMessage)
    }
  }

  if (error && error.response && error.response.status === 401) {
    SessionService.clearUserSession()
  }

  return { errors, errorMessage }
}

// Sales Report

const loadSalesReport = coordinators.GetSalesReport({
  RestService: services.RestService,
  pResponseSalesReport: presenters.Api.pResponseSalesReport,
  HandleError: ReportErrorHandler,
})

const loadAccountingSalesReport = coordinators.GetAccountingSalesReport({
  RestService: services.RestService,
  pResponseSalesReport: presenters.Api.pResponseAccountingSalesReport,
  HandleError: ReportErrorHandler,
})

const getSalesFilters = () => {
  return [
    {
      name: 'hqs',
      key: 'hqIds',
      default: [],
    },
    {
      name: 'salesRep',
      key: 'salesRepId',
    },
    {
      name: 'client',
      key: 'clientId',
    },
    {
      name: 'orderType',
      key: 'orderTypes',
    },
    {
      name: 'date',
      key: 'from',
      default: Moment().subtract(1, 'M').startOf('day'),
      presenter: (date) => date.startOf('day'),
    },
    {
      name: 'date',
      key: 'to',
      default: Moment().add(1, 'M').endOf('day'),
      presenter: (date) => date.endOf('day'),
    },
    {
      name: 'dropdown',
      key: 'isPaid',
      label: 'Is Paid?',
      options: [
        { label: 'All', value: null },
        { label: 'Paid', value: true },
        { label: 'Unpaid', value: false },
      ],
    },
    {
      name: 'page',
      key: 'page',
      default: 1,
    },
    {
      name: 'limit',
      key: 'limit',
      default: 250,
    },
  ]
}

const getSalesReportStyles = () => {
  return {
    wordBreak: 'break-all',
    minWidth: '100px',
    background: '',
  }
}

const getAccountingSalesFilters = () => {
  return [
    {
      name: 'hqs',
      key: 'hqIds',
      default: [],
    },
    {
      name: 'salesRep',
      key: 'salesRepId',
    },
    {
      name: 'client',
      key: 'clientId',
    },
    {
      name: 'orderType',
      key: 'orderTypes',
    },
    {
      name: 'date',
      key: 'from',
      default: Moment().subtract(1, 'M').startOf('day'),
      presenter: (date) => date.startOf('day'),
    },
    {
      name: 'date',
      key: 'to',
      default: Moment().add(1, 'M').endOf('day'),
      presenter: (date) => date.endOf('day'),
    },
    {
      name: 'dropdown',
      key: 'isPaid',
      label: 'Is Paid?',
      options: [
        { label: 'All', value: null },
        { label: 'Paid', value: true },
        { label: 'Unpaid', value: false },
      ],
    },
    {
      name: 'page',
      key: 'page',
      default: 1,
    },
    {
      name: 'limit',
      key: 'limit',
      default: 250,
    },
  ]
}

const getAccountingSalesReportStyles = () => {
  return {
    wordBreak: 'break-all',
    minWidth: '100px',
    background: '',
  }
}

const getSalesCSVUrl = (apiHost, serverFilters) => {
  return `${apiHost}/accounting/${pAccountingCsvUrlPath(
    'Sales',
  )}?${pAccountingCsvParamsString(serverFilters, 'Sales')}`
}

const getAccountingSalesCSVUrl = (apiHost, serverFilters) => {
  return `${apiHost}/accounting/orders/accounting-sales-report-csv?${pAccountingCsvParamsString(
    serverFilters,
    'Accounting Sales',
  )}`
}

// Chef Payout report

const loadChefPayReport = coordinators.GetChefPayReport({
  RestService: services.RestService,
  pResponseChefPayReport: presenters.Api.pResponseChefPayReport,
  HandleError,
})

const getChefPayFilters = () => {
  return [
    {
      name: 'hqs',
      key: 'hqIds',
      default: [],
    },
    {
      name: 'chef',
      key: 'chefId',
    },
    {
      name: 'date',
      key: 'from',
      default: Moment().subtract(1, 'M').startOf('day'),
      presenter: (date) => date.startOf('day'),
    },
    {
      name: 'date',
      key: 'to',
      default: Moment().add(1, 'M').endOf('day'),
      presenter: (date) => date.endOf('day'),
    },
    {
      name: 'text',
      key: 'orderNumber',
      label: 'Order Number',
    },
    {
      name: 'page',
      key: 'page',
      default: 1,
    },
    {
      name: 'limit',
      key: 'limit',
      default: 250,
    },
  ]
}

const getChefPayReportStyles = () => {
  return {
    wordBreak: 'break-all',
    minWidth: '100px',
    background: '',
  }
}

const getChefPayCSVUrl = (apiHost, serverFilters, forItemized = false) => {
  const reportName = forItemized ? 'Chef Pay Itemized' : 'Chef Pay'

  return `${apiHost}/accounting/${pAccountingCsvUrlPath(
    reportName,
  )}?${pAccountingCsvParamsString(serverFilters, reportName)}`
}

// Group Order report

const loadGroupOrderReport = coordinators.GetGroupOrderReport({
  RestService: services.RestService,
  pResponseGroupOrderDashReport: presenters.Api.pResponseGroupOrderDashReport,
  HandleError: ReportErrorHandler,
})

const loadChefFunction = coordinators.LoadChefs({
  ChefService: services.ChefService,
  RestService: services.RestService,
  pResponseChefs: presenters.Api.pResponseChefs,
})

const loadAccountsFunction = coordinators.AsyncLoadAccounts({
  RestService: services.RestService,
  pResponseGeneric: presenters.Api.pResponseGeneric,
})

const getGroupOrderReportFilters = () => {
  return [
    {
      name: 'hqs',
      key: 'hqIds',
      default: [],
    },
    {
      name: 'multiSelectAuto',
      key: 'chefs',
      label: 'chefs',
      loaderFunction: loadChefFunction,
    },
    {
      name: 'multiSelectText',
      parameter: 'order number',
      key: 'orderNumbers',
      label: 'Order Numbers',
    },
    {
      name: 'multiSelectAuto',
      key: 'clients',
      label: 'clients',
      loaderFunction: loadAccountsFunction,
    },
    {
      name: 'dateTime',
      key: 'dropoffTimeStart',
      default: Moment().subtract(3, 'd').startOf('day'),
      label: 'Dropoff Time Start',
    },
    {
      name: 'dateTime',
      key: 'dropoffTimeEnd',
      default: Moment().add(3, 'd').endOf('day'),
      label: 'Dropoff Time End',
    },
    {
      name: 'dateTime',
      key: 'goCutoffStart',
      default: Moment().subtract(3, 'd').startOf('day'),
      label: 'Group Order Cutoff Start',
    },
    {
      name: 'dateTime',
      key: 'goCutoffEnd',
      default: Moment().add(3, 'd').endOf('day'),
      label: 'Group Order Cutoff End',
    },
    {
      name: 'dateTime',
      key: 'menuCutoffStart',
      default: Moment().subtract(3, 'd').startOf('day'),
      label: 'MenuCard Cutoff Start',
    },
    {
      name: 'dateTime',
      key: 'menuCutoffEnd',
      default: Moment().add(3, 'd').endOf('day'),
      label: 'MenuCard Cutoff End',
    },
    {
      name: 'page',
      key: 'page',
      default: 1,
    },
    {
      name: 'limit',
      key: 'limit',
      default: 50,
    },
  ]
}

const getGroupOrderReportStyles = () => {
  return {
    background: '',
    minWidth: '150px',
    wordBreak: 'normal',
  }
}

// Captain Pay Report

const loadCaptPayReport = coordinators.GetCaptPayReport({
  RestService: services.RestService,
  pResponseCaptPayReport: presenters.Api.pResponseCaptPayReport,
  HandleError,
})

const getCaptPayFilters = () => {
  return [
    {
      name: 'hqs',
      key: 'hqIds',
      default: [],
    },
    {
      name: 'captain',
      key: 'captainId',
    },
    {
      name: 'date',
      key: 'from',
      default: Moment().subtract(1, 'M').startOf('day'),
      presenter: (date) => date.startOf('day'),
    },
    {
      name: 'date',
      key: 'to',
      default: Moment().add(1, 'M').endOf('day'),
      presenter: (date) => date.endOf('day'),
    },
    {
      name: 'page',
      key: 'page',
      default: 1,
    },
    {
      name: 'limit',
      key: 'limit',
      default: 250,
    },
  ]
}

const getCaptPayReportStyles = () => {
  return {
    wordBreak: 'break-all',
    minWidth: '100px',
    background: '',
  }
}

const getCaptPayCSVUrl = (apiHost, serverFilters) => {
  return `${apiHost}/accounting/${pAccountingCsvUrlPath(
    'Captain Pay',
  )}?${pAccountingCsvParamsString(serverFilters, 'Captain Pay')}`
}

// Reports

export const Reports = [
  {
    name: 'Sales',
    loadReport: loadSalesReport,
    getFilters: getSalesFilters,
    getTableStyles: getSalesReportStyles,
    csvExports: [
      {
        label: 'Download CSV',
        url: getSalesCSVUrl,
      },
    ],
    csvPrefix: 'sales-report',
    roles: [
      'master admin',
      'sales rep',
      'sales lead',
      'chef lead',
      'captain lead',
      'finance',
      'accounting dashboard read only',
    ],
    loadOnChange: true,
  },
  {
    name: 'Accounting Sales Report',
    loadReport: loadAccountingSalesReport,
    getFilters: getAccountingSalesFilters,
    getTableStyles: getAccountingSalesReportStyles,
    csvExports: [
      {
        label: 'Download CSV',
        url: getAccountingSalesCSVUrl,
      },
    ],
    csvPrefix: 'accounting-sales-report',
    roles: ['master admin', 'finance'],
    loadOnChange: true,
  },
  {
    name: 'Captain Pay',
    loadReport: loadCaptPayReport,
    getFilters: getCaptPayFilters,
    getTableStyles: getCaptPayReportStyles,
    csvExports: [
      {
        label: 'Download CSV',
        url: getCaptPayCSVUrl,
      },
    ],
    csvPrefix: 'captain-pay-report',
    roles: [
      'master admin',
      'captain lead',
      'finance',
      'accounting dashboard read only',
    ],
    loadOnChange: true,
  },
  {
    name: 'Chef Pay',
    loadReport: loadChefPayReport,
    getFilters: getChefPayFilters,
    getTableStyles: getChefPayReportStyles,
    csvExports: [
      {
        label: 'Download CSV',
        url: getChefPayCSVUrl,
      },
      {
        label: 'Download Itemized Payout CSV',
        filter: (filters) => filters && filters.chefId,
        url: getChefPayCSVUrl,
      },
    ],
    csvPrefix: 'chef-pay-report',
    roles: [
      'master admin',
      'sales rep',
      'sales lead',
      'chef lead',
      'finance',
      'accounting dashboard read only',
    ],
    loadOnChange: true,
    additionalButtons: [
      {
        text: 'Recalculate Chef Fees',
        callback: () =>
          UIService.CalcChefProcFeeModal.displayCalcChefProcFeeModal({
            show: true,
          }),
        roles: ['master admin', 'finance'],
      },
    ],
  },
  {
    name: 'Group Order',
    loadReport: loadGroupOrderReport,
    getFilters: getGroupOrderReportFilters,
    getTableStyles: getGroupOrderReportStyles,
    cellBackGroundColor: (column, data) => {
      let bgColor = ''
      // Can add different background colors based on Column/Data
      if (
        column === CHEF_EMAIL_GO_DASHBOARD_COLUMN &&
        data === MISSED_CHEF_EMAIL
      ) {
        bgColor = MISSED_CHEF_EMAIL_BG_COLOR
      }

      if (column === CHEF_ACCEPTANCE_COLUMN && data === CHEF_DECLINED_ORDER) {
        bgColor = CHEF_DECLINED_ORDER_BG_COLOR
      }

      return bgColor
    },
    csvExports: [
      {
        label: 'Download CSV',
        url: getGroupOrderCSVUrl,
      },
    ],
    csvPrefix: 'group-order-dashboard-report',
    roles: [
      'master admin',
      'sales rep',
      'sales lead',
      'chef lead',
      'captain lead',
      'finance',
      'accounting dashboard read only',
    ],
    loadOnChange: false,
  },
  // {
  //   name: 'Sales Commission',
  //   loadReport: loadSalesCommissionReport,
  //   getFilters: getSalesCommissionFilters,
  //   csvPrefix: 'sales-commission-report',
  //   roles: [
  //     'master admin',
  //     'sales rep',
  //     'sales lead',
  //     'finance',
  //     'accounting dashboard read only',
  //   ],
  // },
]

export const allReportingRoles = Reports.reduce((array, config) => {
  config.roles.forEach((role) => {
    if (!array.includes(role)) {
      array.push(role)
    }
  })

  return array
}, [])
