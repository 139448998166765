export const CreateServiceDepot =
  ({ MarketService, UIService }) =>
  async (hqDetails) => {
    MarketService.setServiceDepot({ headquarter: hqDetails })
    const { serviceDepot } = MarketService.getState()
    MarketService.setEditServiceDepot(serviceDepot)
    UIService.EditMarket.showServiceDepot()
  }

export const DeleteServiceDepot =
  ({ HandleError, MarketService, RestService, UIService }) =>
  async (sDepot, hqDetails) => {
    const text = 'Are you sure you want to delete this service depot?'
    const doDelete = await UIService.ConfirmationModal.show({ text })
    if (doDelete) {
      try {
        const { depots } = hqDetails
        hqDetails.depots = depots.filter((d) => d.name !== sDepot.name)
        await RestService.delete(
          `/headquarters/${hqDetails.id}/depots/${sDepot.id}`,
        )
        MarketService.deleteServiceDepot(sDepot.id)
        UIService.FlashMessage.displaySuccessMessage(
          'Service depot deleted successfully',
        )
      } catch (err) {
        HandleError({ error: err, namespace: 'editServiceDepotModal' })
      }
    }
  }

export const EditServiceDepot =
  ({ MarketService, UIService }) =>
  async (sDepot) => {
    MarketService.setServiceDepot(sDepot)
    MarketService.setEditServiceDepot(sDepot)
    UIService.EditMarket.showServiceDepot()
  }

export const LoadHQServiceDepots =
  ({ HandleError, MarketService, pResponseMarket, RestService }) =>
  async ({ hqId, limit, offset }) => {
    try {
      const params = { headquarter_id: hqId, limit, offset }
      const hq = await RestService.get(`/headquarters/${hqId}`, params)
      const pSDepots = pResponseMarket(hq).depots
      MarketService.setServiceDepots(pSDepots)
    } catch (err) {
      HandleError({ error: err, namespace: 'editServiceDepotModal' })
    }
  }

export const SaveServiceDepot =
  ({
    HandleError,
    MarketService,
    RestService,
    UIService,
    pResponseServiceDepots,
  }) =>
  async (data, headquarterId) => {
    let sDepots
    let successMessage = ''
    try {
      if (data.id) {
        successMessage = 'Service depot updated successfully'
        const result = await RestService.put(
          `/headquarters/${headquarterId}/depots/${data.id}`,
          data,
        )
        sDepots = result.depots
      } else {
        successMessage = 'Service depot created successfully'
        const result = await RestService.post(
          `/headquarters/${headquarterId}/depots`,
          data,
        )
        sDepots = result.depots
      }
      sDepots = pResponseServiceDepots(sDepots)
      MarketService.setServiceDepots(sDepots)
      UIService.FlashMessage.displaySuccessMessage(successMessage)
    } catch (err) {
      HandleError({ error: err, namespace: 'editServiceDepotModal' })
    }
  }
