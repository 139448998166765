import { connect } from 'react-redux'

import { AccountDetail } from '@components/account'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { account, router } = state

  return { account, ...router }
}

const mapDispatchToProps = () => {
  const { AccountService, RestService } = services
  const {
    pResponseAccount,
    pResponseAccounts,
    pRequestOrdersFilter,
    pResponseOrders,
    pResponseProposals,
  } = presenters.Api
  const {
    GetOrderCount,
    GetProposalCount,
    LoadAccounts,
    SelectAccount,
    SearchOrders,
    SearchProposals,
  } = coordinators

  const loadAccounts = LoadAccounts({
    AccountService,
    RestService,
    pResponseAccounts,
  })
  const selectAccount = SelectAccount({
    AccountService,
    RestService,
    pResponseAccount,
  })

  const searchOrders = SearchOrders({
    RestService,
    pRequestOrdersFilter: pRequestOrdersFilter,
    pResponseOrders,
  })

  const getOrderCount = GetOrderCount({ RestService, pRequestOrdersFilter })

  const searchProposals = SearchProposals({
    RestService,
    pRequestProposalsFilter: pRequestOrdersFilter,
    pResponseProposals,
  })

  const getProposalCount = GetProposalCount({
    RestService,
    pRequestProposalsFilter: pRequestOrdersFilter,
  })

  return {
    loadAccounts,
    selectAccount,
    searchOrders,
    getOrderCount,
    searchProposals,
    getProposalCount,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetail)
