import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Moment from 'moment-timezone'
import loadGif from '@res/images/loading.gif'
import ChefCalendarPopup from './ChefCalendarPopup'
import { colors } from '../../../constants'

class ChefCalendar extends Component {
  state = {
    order: {},
    orders: [],
    wWidth: 0,
    wHeight: 0,
    showOrderModal: false,
  }

  setPopupRef = (element) => {
    element && this.setState({ [`popup${element.id}`]: element })
  }

  componentWillMount() {
    const { orders } = this.props
    this.setState({ orders })
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  componentWillReceiveProps(nextProps) {
    const { order, orders } = nextProps
    this.setState({ order, orders })
  }

  updateWindowDimensions = () => {
    this.setState({ wWidth: window.innerWidth, wHeight: window.innerHeight })
  }

  onLoadOrder = (o) => {
    this.props.loadOrder({
      chefId: this.props.chefId,
      orderId: o.id,
      orderType: o.mealType,
    })

    this.setState({
      [`showHover${o.id}`]: true,
      o,
    })
  }

  arrangeOrderItems = (order) => {
    const itemsArranged = [
      ...order.orderMenuItems.sort(
        (a, b) => parseFloat(a.displayOrder) - parseFloat(b.displayOrder),
      ),
    ]
    order['orderMenuItems'] = itemsArranged

    return order
  }

  generateDays = () => {
    const { month, year, orders } = this.props
    const firstDayOfMonth = Moment({ year: year, month: month })
      .startOf('month')
      .day()
    const daysInMonth = Moment({ year: year, month: month }).daysInMonth()
    const days = []
    const rows = []
    let cells = []

    const currentMonthorders = []

    orders &&
      orders.map((o) => {
        if (
          Moment(o.date).format('M') - 1 == month &&
          Moment(o.date).format('Y') == year
        ) {
          currentMonthorders.push(o)
        }
      })

    const getCalendarColor = (o) => {
      if (o.orderNumber.includes('GRP')) {
        return 'bg-red-300'
      } else if (o.orderNumber.includes('POP')) {
        return 'bg-yellow-300'
      } else {
        return 'bg-green-300'
      }
    }

    const navToGroupOrder = async (id) => {
      const { history, loadAccount, loadGroupOrder, setGroupOrderPage } =
        this.props
      const grp = await loadGroupOrder({ id })
      const {
        date,
        clientSettingsId,
        client: { id: clientId },
      } = grp
      const account = await loadAccount(clientId)
      setGroupOrderPage({
        id,
        account,
        date,
        clientSettingsId,
      })
      history.push('/group-orders')
    }

    const navToOrder = (o) => {
      const { history } = this.props

      if (o.orderNumber.includes('GRP')) {
        navToGroupOrder(o.id)
      } else if (o.orderNumber.includes('POP')) {
        history.push(`/pop-ups/${o.id}`)
      } else {
        history.push(`/sales/${o.id}`)
      }
    }

    const findMatchingDays = (day) => {
      const { chef } = this.props
      const matchingDays = []
      currentMonthorders.filter((o) => {
        if (Moment(o.date).format('D') == day) {
          matchingDays.push(
            <a onClick={() => navToOrder(o)}>
              <div
                className="relative"
                onMouseEnter={() => this.onLoadOrder(o)}
                onMouseLeave={() =>
                  this.setState({ [`showHover${o.id}`]: false })
                }
              >
                <div
                  id={o.id}
                  ref={this.setPopupRef}
                  className={`mb-5 large-calendar-order relative ${getCalendarColor(
                    o,
                  )} calendar-date ${
                    this.state[`showHover${o.id}`] && 'calendar-date-hover'
                  }`}
                >
                  <p className="calendar-time-copy center-align">
                    <p>{o.orderNumber}</p>
                    <p className="regular">
                      Time: {Moment(o.date).format('h:mm a')}
                    </p>
                    <p className="regular"> Head Count: {o.headCount}</p>
                    <p className="regular">
                      Rep:{' '}
                      {(o.salesRep && o.salesRep.name) ||
                        (o.cuisineType && o.cuisineType)}
                    </p>
                    <p className="regular">Payout: ${o.totalPayout}</p>
                  </p>
                  {this.state[`showHover${o.id}`] && (
                    <p className="calendar-edit">
                      <span className="calendar-plus">+</span>
                      <p>{'View Menu'}</p>
                    </p>
                  )}
                </div>
                {this.state[`showHover${o.id}`] && (
                  <div
                    id="calendarHover"
                    className="calendar-hover"
                    style={this.getDimensions(o.id)}
                  >
                    <ChefCalendarPopup order={o} chefId={chef.id} />
                  </div>
                )}
              </div>
            </a>,
          )
        }
      })

      return matchingDays
    }
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(
        <td className={'td-large'}>
          <div />
        </td>,
      )
    }
    for (let i = 1; i < daysInMonth + 1; i++) {
      days.push(
        <td className={'td-large'}>
          <p className="calendar-date">{i}</p>
          {findMatchingDays(i)}
        </td>,
      )
    }
    days.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row)
      } else {
        rows.push(cells)
        cells = []
        cells.push(row)
      }
      if (i === days.length - 1) {
        for (let i = cells.length; i < 7; i++) {
          cells.push(
            <td className={'td-large'}>
              <div />
            </td>,
          )
        }
        rows.push(cells)
      }
    })

    return rows.map((d, i) => <tr key={i}>{d}</tr>)
  }

  getDimensions = (id) => {
    const { wWidth, wHeight } = this.state
    const css = {}
    const elTop =
      this.state[`popup${id}`] &&
      this.state[`popup${id}`].getBoundingClientRect().top
    const elLeft =
      this.state[`popup${id}`] &&
      this.state[`popup${id}`].getBoundingClientRect().left
    const elWidth =
      this.state[`popup${id}`] &&
      this.state[`popup${id}`].getBoundingClientRect().width
    if (elLeft + elWidth + 500 > wWidth) {
      // position to the left
      css.marginLeft = -500
      css.paddingLeft = 20
    }
    if (elLeft + elWidth + 500 < wWidth) {
      // position to the right
      css.paddingLeft = '20px'
      css.marginLeft = elWidth
    }

    if (wHeight - elTop < 600) {
      css.top = -350
    }

    return css
  }

  sendChefSchedEmail = () => {
    const { chef, locale, sendChefSchedEmailReminder } = this.props
    const req = {
      id: chef.id,
      email: chef.email,
      startOf: Moment().endOf('week'),
      endOf: Moment().endOf('week').add(7, 'days'),
      locale,
    }
    sendChefSchedEmailReminder(req)
  }

  renderHeadingToggles = () => {
    const { year, month, prevMonth, nextMonth } = this.props

    return (
      <div className="month-toggle mb-4">
        <div className="w-50">
          <span onClick={prevMonth} className="next-arrow mr-10">
            ◄
          </span>
        </div>
        <h1 className="big-calendar-title">
          {Moment({ year: year, month: month, day: 1 }).format('MMMM')} {year}
        </h1>
        <div className="w-50">
          <span onClick={nextMonth} className="next-arrow ml-10">
            ►
          </span>
        </div>
        <ChefEmailButton onClick={this.sendChefSchedEmail}>
          Email Upcoming GO Schedule
        </ChefEmailButton>
      </div>
    )
  }

  render() {
    const { isLoading } = this.props

    return (
      <div className={'relative'}>
        {this.renderHeadingToggles()}
        {isLoading && (
          <img className="calendar-loader" src={loadGif} alt="loader" />
        )}
        <table className={'big-calendar'}>
          <tr className="calendar-tr">
            <th>Sun</th>
            <th>Mon</th>
            <th>Tue</th>
            <th>Wed</th>
            <th>Thu</th>
            <th>Fri</th>
            <th>Sat</th>
          </tr>
          {this.generateDays()}
        </table>
      </div>
    )
  }
}

const ChefEmailButton = styled.button`
  color: ${colors.blue300};
  border-bottom: 1px solid ${colors.blue300};
  margin-left: 40px;
`

ChefCalendar.propTypes = {
  chef: PropTypes.object,
  isLoading: PropTypes.bool,
  locale: PropTypes.string,
  prevMonth: PropTypes.func,
  nextMonth: PropTypes.func,
  month: PropTypes.string,
  year: PropTypes.string,
  orders: PropTypes.arrayOf(PropTypes.object),
  order: PropTypes.object,
  chefId: PropTypes.string,
  history: PropTypes.object,

  calculateTotal: PropTypes.func,
  setOrder: PropTypes.func,
  selectOrder: PropTypes.func,
  sendChefSchedEmailReminder: PropTypes.func,
  loadCalendarOrder: PropTypes.func,
  loadOrder: PropTypes.func,
  loadGroupOrder: PropTypes.func,
  loadAccount: PropTypes.func,
  setGroupOrderPage: PropTypes.func,
}

export default ChefCalendar
