import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/common/modal/Modal'
import { HeadquarterSection } from './sections'

import { Input, QuantityInput } from '@components/common/form'

import { AddressInput } from '@containers/common/form'

const initialAddressState = {
  city: '',
  line1: '',
  line2: '',
  state: '',
  zip: '',
}
const initialState = {
  address: initialAddressState,
  id: '',
  name: '',
  locale: '',
  prefix: '',
  roles: [],
  zipcode: '',
  deliveryFee: 19.99,
  cartMinimum: 200.0,
  groupOrderMinimumItems: 0,
  marketplaceSalesRepId: '',
  marketplaceSalesRep: {},
  chefRecruiterId: '',
  chefRecruiter: {},
  chefNetworkEmailDistribution: '',
  salesAREmailDistribution: '',
  opsEmailDistribution: '',
}

class EditHQModal extends Component {
  state = { ...initialState }

  componentDidMount() {
    const { clearErrors, editHQ } = this.props
    clearErrors()
    this.getMarketplaceSalesRep(editHQ.marketplaceSalesRepId)
    this.getChefRecruiter(editHQ.chefRecruiterId)
    this.setState({ ...editHQ })
  }

  onInput = (field) => (value) => {
    if (value.target) {
      ;({ value } = value.target)
    }
    this.setState({ [field]: value })
    this.props.clearError(field)
  }

  onHide = () => this.props.closeHQ()

  save = () => {
    const {
      id,
      name,
      prefix,
      zips,
      locale,
      address,
      deliveryFee,
      cartMinimum,
      groupOrderMinimumItems,
      depots,
      captRates,
      marketplaceSalesRepId,
      chefRecruiterId,
      chefNetworkEmailDistribution,
      salesAREmailDistribution,
      opsEmailDistribution,
    } = this.state

    if (
      this.props.validateEmail({ email: chefNetworkEmailDistribution }) &&
      this.props.validateEmail({ email: salesAREmailDistribution }) &&
      this.props.validateEmail({ email: opsEmailDistribution })
    ) {
      const hqData = {
        id,
        name,
        prefix,
        zips,
        locale,
        address,
        deliveryFee,
        cartMinimum,
        depots,
        captRates,
        marketplaceSalesRepId,
        chefRecruiterId,
        chefNetworkEmailDistribution,
        salesAREmailDistribution,
        opsEmailDistribution,
        // Value can be null or NaN, api expects int
        groupOrderMinimumItems: groupOrderMinimumItems || 0,
      }
      this.props.saveHeadquarter(hqData)
    }
  }

  renderAddressInput = ({ address, field }) => {
    return (
      <AddressInput
        showBuildingInstructions={false}
        address={address}
        onChange={this.onInput(field)}
      />
    )
  }

  renderTextInput = ({ label, error, onInputParam, testId, value }) => {
    return (
      <Input
        label={label}
        value={value}
        error={error}
        onChange={this.onInput(onInputParam)}
        testId={testId}
      />
    )
  }

  renderNumberInput = ({ label, onInputParam, value }) => {
    return (
      <QuantityInput
        width="100%"
        pattern="[0-9\.-]*"
        label={label}
        marginBottom="20px"
        onChange={this.onInput(onInputParam)}
        value={value}
      />
    )
  }

  onChangeMarketplaceSalesRep = ({ id, firstName, lastName, phoneNumber }) => {
    this.setState({
      marketplaceSalesRep: {
        id,
        firstName,
        lastName,
        phone: phoneNumber,
      },
      marketplaceSalesRepId: id,
    })
  }

  onChangeChefRecruiter = ({ id, firstName, lastName, phoneNumber }) => {
    this.setState({
      chefRecruiter: {
        id,
        firstName,
        lastName,
        phone: phoneNumber,
      },
      chefRecruiterId: id,
    })
  }

  addCaptainRate = (name, rate, hq) => {
    const { captRates } = this.state
    captRates.push({ name, rate, hq })
    this.setState({ captRates })
  }

  modifyCaptainRate = (index, rate) => {
    const { captRates } = this.state
    captRates[index].rate = rate
    this.setState({ captRates })
  }

  removeCaptainRate = (index) => {
    const { captRates } = this.state
    captRates.splice(index, 1)
    this.setState({ captRates })
  }

  getChefRecruiter = async (chefRecruiterId) => {
    if (chefRecruiterId) {
      const chefRecruiter = await this.props.getAdminUser(chefRecruiterId)
      if (chefRecruiter) {
        this.setState({ chefRecruiter })
      }
    }
  }

  getMarketplaceSalesRep = async (marketplaceSalesRepId) => {
    if (marketplaceSalesRepId) {
      const marketplaceSalesRep = await this.props.getAdminUser(
        marketplaceSalesRepId,
      )
      if (marketplaceSalesRep) {
        this.setState({ marketplaceSalesRep })
      }
    }
  }

  render() {
    const { errors, loadAccountExecutives, loadChefRecruiters } = this.props
    const {
      address,
      id,
      name,
      locale,
      prefix,
      deliveryFee,
      cartMinimum,
      groupOrderMinimumItems,
      captRates,
      marketplaceSalesRep,
      chefRecruiter,
      chefNetworkEmailDistribution,
      salesAREmailDistribution,
      opsEmailDistribution,
    } = this.state

    return (
      <Modal
        title={id ? `Edit ${name}` : 'New Market'}
        hideModal={this.onHide}
        color="#001940"
        maxWidth="400px"
        width="400px"
      >
        <HeadquarterSection
          name={name}
          locale={locale}
          address={address}
          captRates={captRates}
          errors={errors}
          orderPrefix={prefix}
          chefNetworkEmailDistribution={chefNetworkEmailDistribution}
          salesAREmailDistribution={salesAREmailDistribution}
          opsEmailDistribution={opsEmailDistribution}
          deliveryFee={deliveryFee}
          cartMinimum={cartMinimum}
          groupOrderMinimumItems={groupOrderMinimumItems}
          onSelectLocale={(locale) => this.setState({ locale })}
          renderAddressInput={this.renderAddressInput}
          renderTextInput={this.renderTextInput}
          renderNumberInput={this.renderNumberInput}
          save={this.save}
          onHide={this.onHide}
          addCaptainRate={this.addCaptainRate}
          modifyCaptainRate={this.modifyCaptainRate}
          removeCaptainRate={this.removeCaptainRate}
          id={id}
          marketplaceSalesRep={marketplaceSalesRep}
          onChangeMarketplaceSalesRep={this.onChangeMarketplaceSalesRep}
          loadAccountExecutives={loadAccountExecutives}
          loadChefRecruiters={loadChefRecruiters}
          chefRecruiter={chefRecruiter}
          onChangeChefRecruiter={this.onChangeChefRecruiter}
        />
        {errors.role && <div className="error-message"> {errors.role}</div>}
      </Modal>
    )
  }
}

EditHQModal.propTypes = {
  show: PropTypes.bool,

  clearError: PropTypes.func,
  clearErrors: PropTypes.func,
  closeHQ: PropTypes.func,
  saveHeadquarter: PropTypes.func,

  errors: PropTypes.object,
  editHQ: PropTypes.object,

  loadAccountExecutives: PropTypes.func,
  loadChefRecruiters: PropTypes.func,
  getAdminUser: PropTypes.func,
  validateEmail: PropTypes.func,
}

export default EditHQModal
