import { CLOSE_ALERT_MODAL, SHOW_ALERT_MODAL } from '@actions/ui/alert'

const initialState = {
  show: false,
}

export const alertModal = (state = initialState, action) => {
  const { type, message, title, options } = action

  switch (type) {
    case SHOW_ALERT_MODAL:
      return { show: true, message, title, options }
    case CLOSE_ALERT_MODAL:
      return { show: false }
    default:
      return state
  }
}
