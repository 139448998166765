import { BaseService } from 'hungry-core2'
import { deepCopy } from '~/utils'

export class MLBOrderService extends BaseService {
  copyOrder = (order) => {
    const copied = deepCopy(order)
    delete copied.id
    delete copied.paymentMethod
    delete copied.updatedAt
    delete copied.createdAt
    delete copied.orderNumber

    return copied
  }

  createKitChoice = ({ kit = {}, quantity = 1 } = { kit: {}, quantity: 1 }) => {
    return {
      id: kit.id,
      name: kit.name,
      description: kit.description,
      image: kit.image && kit.image.url,
      quantity,
      price: kit.price,
      taxCode: kit.taxCode,
      hasAlcohol: kit.hasAlcohol,
      supplies: (kit.supplies || []).map((s) => ({
        ...s,
        count: s.count * quantity,
      })),
      kitItems: (kit.includedItems || []).map((it) => {
        return {
          id: it.itemId,
          name: it.name,
          description: it.description,
          quantity: it.count * quantity,
          supplies: (it.supplies || []).map((s) => ({
            ...s,
            count: s.count * it.count * quantity,
          })),
        }
      }),
    }
  }

  createAddonChoice = (
    { item = {}, quantity = 1 } = { item: {}, quantity: 1 },
  ) => {
    return {
      id: item.id,
      name: item.name,
      description: item.description,
      image: item.image,
      quantity,
      price: item.price,
      taxCode: item.taxCode,
      hasAlcohol: item.hasAlcohol,
      supplies: (item.supplies || []).map((s) => ({
        ...s,
        count: s.count * quantity,
      })),
    }
  }
}
