import { connect } from 'react-redux'

import { EditVirtualItemModal } from '@components/chef/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { editVirtualItem, editVirtualItemModal } = state
  const errors = state.errors.virtualItemModal || {}
  const { show } = editVirtualItemModal

  return {
    virtualItem: editVirtualItem,
    show,
    errors,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, UIService } = services
  const { pResponseChef, pResponseVirtualItems, pRequestUpdateVirtualItem } =
    presenters.Api
  const {
    DelayedUpdateVirtualItem,
    DeleteVirtualItem,
    SaveVirtualItem,
    SelectChef,
  } = coordinators
  const { close } = UIService.EditVirtualItem
  const clearErrors = UIService.Errors.clear
  const delayedUpdateVirtualItem = DelayedUpdateVirtualItem({
    ChefService,
    UIService,
  })
  const deleteVirtualItem = DeleteVirtualItem({
    ChefService,
    RestService,
    UIService,
    pResponseChef,
    HandleError,
    SelectChef,
  })
  const saveVirtualItem = SaveVirtualItem({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateVirtualItem,
    pResponseVirtualItems,
    HandleError,
  })

  return {
    close,
    clearErrors,
    saveVirtualItem,
    delayedUpdateVirtualItem,
    deleteVirtualItem,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditVirtualItemModal)
