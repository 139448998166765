import { connect } from 'react-redux'

import { Recipes } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { chef } = state

  const recipes = chef && chef.recipes
  const chefId = chef && chef.id

  return {
    chefId,
    recipes,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, UIService } = services
  const { pDefaultRecipeFields } = presenters
  const { pResponseGeneric } = presenters.Api
  const { EditRecipe, LoadRecipes, NewRecipe } = coordinators

  const editRecipe = EditRecipe({
    ChefService,
    RestService,
    UIService,
    pResponseGeneric,
    HandleError,
  })
  const loadRecipes = LoadRecipes({
    ChefService,
    RestService,
    pResponseGeneric,
    HandleError,
  })
  const newRecipe = NewRecipe({ ChefService, UIService, pDefaultRecipeFields })

  return {
    editRecipe,
    loadRecipes,
    newRecipe,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recipes)
