import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SelectInput, LinkText } from '@components/common/form'
import { TechHelpForm } from '@components/common'

export default class SignIn extends Component {
  state = {
    email: '',
    password: '',
    headquarterId: undefined,
    headquarters: [],
  }

  signIn = (e) => {
    e.preventDefault()

    const { email, password, headquarters, headquarterId } = this.state
    if (!headquarterId) {
      this.props.displayAlert({
        title: 'Alert',
        message: 'Please select a headquarter',
      })
    } else {
      const headquarter = headquarters.find((h) => h.id == headquarterId)
      this.props.signIn({ email, password, headquarter })
    }
  }

  componentWillMount() {
    this.props.loadHeadquarters()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.headquarters) {
      this.setState({ headquarters: nextProps.headquarters })
    }
    if (nextProps.currentUser) {
      nextProps.redirect('/')
    }
  }

  onSelectHeadquarter = (headquarterId) => {
    this.setState({ headquarterId })
  }

  render() {
    const { email, headquarterId, headquarters, password } = this.state

    return (
      <div className="login-container">
        <div className="login-container__form">
          <h2> Sign In </h2>
          <form onSubmit={this.signIn}>
            <input
              type="text"
              name="email"
              placeholder="email"
              onChange={(e) => this.setState({ email: e.target.value })}
              value={email}
            />
            <input
              type="password"
              name="password"
              placeholder="••••••"
              onChange={(e) => this.setState({ password: e.target.value })}
              value={password}
            />
            <SelectInput
              label="Select Headquarters"
              value={headquarterId}
              options={headquarters.map((h) => ({ value: h.id, text: h.name }))}
              onChange={this.onSelectHeadquarter}
            />
            <LinkText
              label="forgot password?"
              onClick={() => this.props.redirect('/forgotpassword')}
            />
            <input type="submit" value="Sign In" />
          </form>
          <TechHelpForm />
        </div>
      </div>
    )
  }
}

SignIn.propTypes = {
  currentUser: PropTypes.object,
  headquarters: PropTypes.arrayOf(PropTypes.object),

  displayAlert: PropTypes.func,
  loadHeadquarters: PropTypes.func,
  redirect: PropTypes.func,
  signIn: PropTypes.func,
}
