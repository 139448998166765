import { connect } from 'react-redux'

import MLBEditMenuPage from '@components/mlbPartners/MLBEditMenuPage'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapDispatchToProps = () => {
  const { pRequestRemoveEmptyFields } = presenters.Api
  const { UIService, RestService } = services
  const {
    DeleteMLBMenuItem,
    DeleteMLBKit,
    LoadAllMLBItems,
    LoadTaxCodes,
    SaveMLBMenuItem,
    SaveMLBKit,
    UploadMLBMenuItemImg,
    UploadMLBMenuKitImg,
  } = coordinators

  const loadAllMLBItems = LoadAllMLBItems({ RestService, HandleError })
  const loadTaxCodes = LoadTaxCodes({ RestService, HandleError })
  const deleteMLBMenuItem = DeleteMLBMenuItem({
    RestService,
    UIService,
    HandleError,
  })
  const deleteMLBKit = DeleteMLBKit({
    RestService,
    UIService,
    HandleError,
  })
  const saveMLBMenuItem = SaveMLBMenuItem({
    RestService,
    pRequestRemoveEmptyFields,
    UIService,
    HandleError,
  })
  const saveMLBKit = SaveMLBKit({
    RestService,
    pRequestRemoveEmptyFields,
    UIService,
    HandleError,
  })
  const uploadMLBMenuItemImg = UploadMLBMenuItemImg({
    RestService,
    UIService,
    HandleError,
  })
  const uploadMLBMenuKitImg = UploadMLBMenuKitImg({
    RestService,
    UIService,
    HandleError,
  })

  return {
    deleteMLBKit,
    deleteMLBMenuItem,
    loadAllMLBItems,
    loadTaxCodes,
    saveMLBMenuItem,
    saveMLBKit,
    uploadMLBMenuItemImg,
    uploadMLBMenuKitImg,
  }
}

export default connect(undefined, mapDispatchToProps)(MLBEditMenuPage)
