import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SidebarSearch from '@components/sidebar/SidebarSearch'
import SidebarListItem from '@components/sidebar/SidebarListItem'

export class MarketMaster extends Component {
  state = {
    search: '',
  }

  componentDidMount() {
    const { filter } = this.props
    const { search } = this.state
    this.props.loadHeadquarters({ headquarter: filter, search })
  }

  onSelectMarket = (marketId) => {
    this.props.selectHeadquarter(marketId)
  }

  onSearch = (e) => {
    const { filter, loadHeadquarters } = this.props
    const search = e.target.value

    this.setState({ search }, () => {
      clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(() => {
        loadHeadquarters({ headquarter: filter, search })
      }, 300)
    })
  }

  render() {
    const { markets, hqDetails } = this.props
    const activeMarketId = hqDetails ? hqDetails.id : null

    return (
      <div>
        <SidebarSearch label="Search Markets" onChange={this.onSearch} />
        {markets.map((m) => {
          const selected = m.id === activeMarketId

          return (
            <SidebarListItem
              key={m}
              isSelected={selected}
              onClick={() => this.onSelectMarket(m.id)}
            >
              {m.name}
            </SidebarListItem>
          )
        })}
      </div>
    )
  }
}

MarketMaster.propTypes = {
  markets: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.object,
  hqDetails: PropTypes.object,

  loadHeadquarters: PropTypes.func,
  selectHeadquarter: PropTypes.func,
}

export default MarketMaster
