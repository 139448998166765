import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class EditChef extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditChefModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleEditChefModal(true))
  }

  toggleLoading = (on) => this.dispatch(actions.toggleEditChefLoading(on))
}

export class EditChildChefs extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditChildChefsModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleEditChildChefsModal(true))
  }
}

export class EditMenuConcept extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditMenuConceptModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleEditMenuConceptModal(true))
  }
}

export class EditMenuItem extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditMenuItemModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleEditMenuItemModal(true))
  }

  showCopyMenuItem = (menuItemId) => {
    this.dispatch(actions.toggleCopyMenuItemModal(true, menuItemId))
  }

  hideCopyMenuItem = () => {
    this.dispatch(actions.toggleCopyMenuItemModal(false, undefined))
  }
}

export class EditChildMenuItem extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditChildMenuItemModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleEditChildMenuItemModal(true))
  }
}

export class EditRecipe extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditRecipeModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleEditRecipeModal(true))
  }
}

export class EditServiceItem extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditServiceItemModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleEditServiceItemModal(true))
  }
}

export class EditVirtualItem extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditVirtualItemModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleEditVirtualItemModal(true))
  }
}

export class EditVirtualKit extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditVirtualKitModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleEditVirtualKitModal(true))
  }
}

export class EditSnackPack extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditSnackPackModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleEditSnackPackModal(true))
  }
}

export class EditChefContact extends BaseService {
  close = () => {
    this.dispatch(actions.toggleEditChefContactModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleEditChefContactModal(true))
  }
}

export class CopyMenuItems extends BaseService {
  close = () => {
    this.dispatch(actions.toggleCopyMenuItemsModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleCopyMenuItemsModal(true))
  }
}

export class DisplayChefFutureOrders extends BaseService {
  close = () => {
    this.dispatch(actions.toggleDisplayChefFutureOrdersModal(false))
  }

  show = () => {
    this.dispatch(actions.toggleDisplayChefFutureOrdersModal(true))
  }
}
