import { BaseService } from 'hungry-core2'
import Moment from 'moment-timezone'

export class VXService extends BaseService {
  newVirtualExperience = (status) => ({
    id: undefined,
    name: '',
    description: '',
    containsAlcohol: false,
    enabled: true,
    status,
    talent: undefined,
    price: 0.0,
    cost: 0.0,
  })

  newAddOn = () => ({
    id: undefined,
    name: '',
    description: '',
    price: 0.0,
    cost: 0.0,
  })

  newVXOrder = ({ headquarter, locale, status }) => ({
    id: undefined,
    headquarter,
    client: undefined,
    virtualExperience: undefined,
    date: '',
    start: Moment.tz(locale),
    status,
    addOns: [],
    attendees: [],
    amountPaid: 0.0,
    discount: 0.0,
    billingAddress: undefined,
  })
}
