import React, { Component } from 'react'

import { AutocompleteInput } from '@containers/common/form'
import { DefaultStyles as theme } from './theme'
import { AuthorizedDisplay } from '@containers/common/auth'
import Checkbox from '@components/common/form/Checkbox'
import YSpacing from '@components/common/YSpacing'
import Button from '@components/common/form/Button'
import DateTextInput from '@components/common/form/DateTextInput'
import FlexContainer from '@components/common/FlexContainer'
import { ValidationsModal } from '@components/groupOrders'
import { EditDateMenuCutoffModal } from '@components/groupOrders'
import TooltipModal from '@components/common/modal/TooltipModal'
import moment from 'moment-timezone'
import { FaTrash } from 'react-icons/fa'
import { BiPencil } from 'react-icons/bi'
import {
  CutoffTimeDayOffSetType,
  DateMenus,
  DateMenusDraft,
  Menu,
} from '@types'

import {
  GROUP_ORDER_SUBSIDY_TYPE_FIXED,
  GROUP_ORDER_SUBSIDY_TYPE_PERCENT,
  GROUP_ORDER_SUBSIDY_TYPE_REVERSE,
  GROUP_ORDER_ITEM_TYPE_ENTREE,
  GROUP_ORDER_ITEM_TYPE_SIDE,
  GROUP_ORDER_ITEM_TYPE_ALL,
} from './constants'
import {
  subtractBusinessDays,
  GetMenuCutoffTime,
  GetMenuPickupTime,
  GetUpdatedDateMenusAfterCutoffChange,
} from '../../../utils'
import {
  ValidateDateMenus,
  GetUniqueMenuItemsCountForDateMenus,
} from '../../../utils/groupOrderUtils'

type Props = {
  headquarter?: any
  locale?: any
  groupOrderMinimumItems?: any
  clientId?: any
  clientSettingsId?: any
  clientSettings?: any
  groupOrder?: any
  date?: any
  dateMenuId?: any
  dateMenus?: any
  changedDateMenuMap?: any
  hasActiveOrders?: any
  validateMenus?: any
  perOrderSubsidy?: any
  subsidyPercentLimitAmt?: any
  subsidyType?: any
  isSubsidyPercentLimit?: any
  countMap?: any
  forCalendarModal?: any
  itemPriceSettings?: any
  scheduleSettings?: any

  deleteDateMenus?: any
  getGroupOrderConcept?: any
  getGroupOrderBySettingsDate?: any
  newDateMenu?: any
  saveDateMenu?: any
  searchGroupOrderConcepts?: any
  showConfirmationModal: (obj: { text: string }) => Promise<boolean>
  displayFailureMessage?: any
  displayWarningMessage?: any
  displaySuccessMessage?: any
  setChangedDateMenuMap?: any
  updateDateMenus?: any
  onReloadDates?: any
  loadMenuItemRatings?: any

  hideModal?: any

  validateChefPickupTimes: any
}

type State = {
  dateMenu?: Partial<DateMenusDraft> | DateMenus
  groupOrder?: any
  dates?: any
  editCutoffMenu: Menu | undefined
  editCutoffTimeModal: boolean
  showMenu?: any
  newMenuIds?: any
  menusToDelete?: any
  warningsModalOpen?: any
  updatedCutoffMap: Record<string, boolean>
  validationErrors?: any
  validationWarnings?: any
  pickupsConfirmed?: boolean
}

class DateMenusComponent extends Component<Props, State> {
  state = {
    dateMenu: undefined,
    groupOrder: undefined,
    dates: [],
    editCutoffMenu: undefined,
    editCutoffTimeModal: false,
    showMenu: undefined,
    newMenuIds: [],
    menusToDelete: [],
    warningsModalOpen: false,
    updatedCutoffMap: {},
    validationErrors: [],
    validationWarnings: [],
    pickupsConfirmed: false,
  }

  changed = false
  changedMenu = false

  componentWillMount() {
    const { date, dateMenus, dateMenuId, clientSettingsId } = this.props
    this.loadDateMenu(date, dateMenus, dateMenuId, clientSettingsId)
  }

  componentWillReceiveProps(nextProps: Props) {
    const { date, dateMenus, dateMenuId, clientSettingsId } = nextProps
    if (
      date !== this.props.date ||
      dateMenus !== this.props.dateMenus ||
      dateMenuId !== this.props.dateMenuId
    ) {
      this.loadDateMenu(date, dateMenus, dateMenuId, clientSettingsId)
    }
  }

  onCloseWarningsModal = () => {
    this.setState({
      warningsModalOpen: false,
      validationErrors: [],
      validationWarnings: [],
    })
  }

  loadDateMenu = (
    date: any,
    dateMenus: any,
    dateMenuId: any,
    clientSettingsId: any,
  ) => {
    let newDateMenu = dateMenus.find((dm: any) => dm.id === dateMenuId)
    if (!newDateMenu) {
      //try and get it by the dateStr
      newDateMenu = dateMenus.find((dm: any) => dm.dateStr === dateMenuId)
    }
    if (!dateMenuId) {
      //dateMenu is from groupOrdersPage, use date to find it instead
      newDateMenu = dateMenus.find((dm: any) => {
        return (
          date.isSame(dm.date, 'day') &&
          dm.clientSettingsId === clientSettingsId
        )
      })
    }
    this.setDateMenu(date, newDateMenu)
  }

  setDateMenu = (nDate: any, dateMenu: any) => {
    const {
      newDateMenu,
      clientId,
      clientSettingsId,
      locale,
      scheduleSettings,
    } = this.props
    const { cutoffTimeSettings, dropoffTimeSettings } = scheduleSettings || {}
    let date = moment(nDate).set({ h: 12, m: 0, s: 0 })
    let cutoffTime = moment(nDate).clone().tz(locale).set({ h: 9, m: 0, s: 0 })
    if (scheduleSettings?.isActive) {
      if (cutoffTimeSettings) {
        let cutoffTimeFromSettings
        if (
          cutoffTimeSettings.dayOffsetType ===
          CutoffTimeDayOffSetType.BUSINESS_DAYS
        ) {
          cutoffTimeFromSettings = subtractBusinessDays(
            cutoffTime,
            cutoffTimeSettings.dayOffset,
          )
        } else if (
          cutoffTimeSettings?.dayOffsetType === CutoffTimeDayOffSetType.DAYS
        ) {
          cutoffTimeFromSettings = cutoffTime
            .clone()
            .subtract(cutoffTimeSettings.dayOffset, 'days')
        }
        // Do not set cutoff time if it's in the past
        if (
          cutoffTimeFromSettings &&
          cutoffTimeFromSettings.isAfter(moment())
        ) {
          cutoffTime = cutoffTimeFromSettings.clone()
        }

        if (moment.isMoment(cutoffTimeSettings?.time)) {
          cutoffTime = cutoffTime.clone().set({
            hour: cutoffTimeSettings.time.hour(),
            minutes: cutoffTimeSettings.time.minutes(),
          })
        }
      }

      if (moment.isMoment(dropoffTimeSettings?.time)) {
        date = date.clone().set({
          hour: dropoffTimeSettings.time.hour(),
          minutes: dropoffTimeSettings.time.minutes(),
        })
      }
    }

    if (dateMenu) {
      this.setState({
        dateMenu,
        updatedCutoffMap: {},
      })
    } else {
      const newDate = newDateMenu()
      this.setState({
        dateMenu: {
          ...newDate,
          clientId,
          clientSettingsId,
          date,
          cutoffTime,
          dateStr: date.format('YYYY-MM-DD'),
        },
        updatedCutoffMap: {},
      })
    }
  }

  validateMenuToDateMenu = (dateMenu: any, menu: any) => {
    const { date } = dateMenu
    const { availableDays } = menu

    if (!availableDays.includes(moment(date).day())) {
      return 'Unable to select menu as it is not available for the selected day of the week.'
    }

    // Do not allow adding menus with a cutoff time that has already passed
    if (moment().isSameOrAfter(menu.cutoffTime)) {
      return `Unable to select menu because its cutoff time has already passed: ${moment(
        menu.cutoffTime,
      ).format('lll z')}`
    }

    // Do not allow adding menus with a pickup time before date menus dropoff time
    if (moment(menu.pickupTime).isSameOrAfter(date)) {
      return `Unable to select menu because its earliest pickup time is set to ${moment(
        menu.pickupTime,
      ).format('lll z')} which is after the event's dropoff time.`
    }

    const itemsWithMutipleMealTypes = menu.menuItems.filter((item: any) => {
      return item.mealTypes.length > 1
    })

    if (itemsWithMutipleMealTypes.length) {
      let msg = `Menu "${menu.name}" for Chef ${menu.chef.name} has items with multiple GO meal type tags. Please update the following menu items to have only 1 GO meal type tag:\n`
      itemsWithMutipleMealTypes.forEach((item: any) => {
        msg += `${item.name}\n`
      })

      return msg
    }

    return null
  }

  onSelectMenu = (menu: any) => {
    const {
      validateMenus,
      perOrderSubsidy,
      displayFailureMessage,
      subsidyType,
      subsidyPercentLimitAmt,
      isSubsidyPercentLimit,
      itemPriceSettings,
      locale,
    } = this.props
    const { newMenuIds } = this.state
    const { dateMenu }: { dateMenu: any } = this.state
    if (dateMenu.menus.find((m: any) => m.id === menu.id)) {
      return
    }

    // Calculate menu-level times
    menu = {
      ...menu,
      cutoffTime: GetMenuCutoffTime(menu, dateMenu, locale),
      pickupTime: GetMenuPickupTime(menu, dateMenu, locale),
    }

    const menuMessage = this.validateMenuToDateMenu(dateMenu, menu)
    if (menuMessage) {
      return displayFailureMessage(menuMessage)
    }

    if (validateMenus) {
      if (subsidyType === GROUP_ORDER_SUBSIDY_TYPE_FIXED) {
        const aboveSubsidy = menu.menuItems.some(
          (item: any) => item.price > perOrderSubsidy,
        )
        if (aboveSubsidy) {
          const message =
            'Unable to select menu as some items are above the subsidy per order amount.'

          return displayFailureMessage(message)
        }
      } else if (
        subsidyType === GROUP_ORDER_SUBSIDY_TYPE_PERCENT &&
        isSubsidyPercentLimit &&
        typeof subsidyPercentLimitAmt === 'number'
      ) {
        const aboveSubsidy = menu.menuItems.some(
          (item: any) => item.price > subsidyPercentLimitAmt,
        )
        if (aboveSubsidy) {
          const message =
            'Unable to select menu as some items are above the subsidy per order amount.'

          return displayFailureMessage(message)
        }
      } else if (
        subsidyType === GROUP_ORDER_SUBSIDY_TYPE_REVERSE &&
        !!itemPriceSettings &&
        !!itemPriceSettings.budgets
      ) {
        if (
          Object.keys(itemPriceSettings.budgets).includes(
            GROUP_ORDER_ITEM_TYPE_ALL,
          )
        ) {
          const aboveSubsidy = menu.menuItems.some(
            (item: any) =>
              item.price >
              parseFloat(
                itemPriceSettings.budgets[GROUP_ORDER_ITEM_TYPE_ALL].budget,
              ),
          )
          if (aboveSubsidy) {
            const message =
              'Unable to select menu as some items are above the subsidy per order amount.'

            return displayFailureMessage(message)
          }
        } else {
          if (
            Object.keys(itemPriceSettings.budgets).includes(
              GROUP_ORDER_ITEM_TYPE_ENTREE,
            )
          ) {
            const aboveSubsidy = menu.menuItems
              .filter((m: any) => m.itemType === GROUP_ORDER_ITEM_TYPE_ENTREE)
              .some(
                (item: any) =>
                  item.price >
                  parseFloat(
                    itemPriceSettings.budgets[GROUP_ORDER_ITEM_TYPE_ENTREE]
                      .budget,
                  ),
              )
            if (aboveSubsidy) {
              const message =
                'Unable to select menu as entree items are above the client budget per order amount.'

              return displayFailureMessage(message)
            }
          }
          if (
            Object.keys(itemPriceSettings.budgets).includes(
              GROUP_ORDER_ITEM_TYPE_SIDE,
            )
          ) {
            const aboveSubsidy = menu.menuItems
              .filter((m: any) => m.itemType === GROUP_ORDER_ITEM_TYPE_SIDE)
              .some(
                (item: any) =>
                  item.price >
                  parseFloat(
                    itemPriceSettings.budgets[GROUP_ORDER_ITEM_TYPE_SIDE]
                      .budget,
                  ),
              )
            if (aboveSubsidy) {
              const message =
                'Unable to select menu as side items are above the client budget per order amount.'

              return displayFailureMessage(message)
            }
          }
        }
      }
    }
    const zeroChefPrice = menu.menuItems.some(
      (item: any) => item.chefPrice === 0,
    )
    if (zeroChefPrice) {
      const message =
        'Unable to select menu as some items do not contain accurate price for chef.'

      return displayFailureMessage(message)
    }
    const zeroMarketPrice = menu.menuItems.some((item: any) => item.price === 0)
    if (zeroMarketPrice) {
      const message =
        'Unable to select menu as some items do not contain accurate market price.'

      return displayFailureMessage(message)
    }

    this.setState(
      {
        dateMenu: {
          ...dateMenu,
          menus: [...dateMenu.menus, { ...menu, active: false }],
        },
        newMenuIds: [...newMenuIds, menu.id],
        pickupsConfirmed: false,
      },
      () => this.loadMenuItemRatings(menu.id, menu.menuItems),
    )
  }

  loadMenuItemRatings = async (menuId: any, items: any) => {
    const { dateMenu }: { dateMenu: any } = this.state
    const { loadMenuItemRatings } = this.props
    const ratings = await loadMenuItemRatings(items.map((item: any) => item.id))

    this.setState({
      dateMenu: {
        ...dateMenu,
        menus: dateMenu.menus.map((menu: any) => {
          if (menu.id === menuId) {
            return {
              ...menu,
              menuItems: items.map((item: any) => {
                return {
                  ...item,
                  ratingInfo: {
                    averageRating: ratings[item.id].rating,
                    numRatings: ratings[item.id].count,
                  },
                }
              }),
            }
          }

          return menu
        }),
      },
    })
  }

  onRemoveMenu = (idx: any, menuId: any) => {
    const { newMenuIds, menusToDelete } = this.state
    const { dateMenu }: { dateMenu: any } = this.state
    const menu = dateMenu.menus[idx]
    const menus = [
      ...dateMenu.menus.slice(0, idx),
      ...dateMenu.menus.slice(idx + 1),
    ]
    const newIndex = newMenuIds.find((id) => id === menuId)
    // TODO: this check is incorrect; newIndex will either be undefined or the found item id (NOT an index)
    if ((newIndex as unknown as number) > -1) {
      this.setState({ dateMenu: { ...dateMenu, menus }, newMenuIds })
    } else {
      this.setState({
        dateMenu: { ...dateMenu, menus },
        menusToDelete: [...menusToDelete, menu.id],
      })
    }
    this.changedMenu = true
  }

  onChangeMenuActive = (idx: any) => (e: any) => {
    const { dateMenu }: { dateMenu: any } = this.state
    const { changedDateMenuMap, setChangedDateMenuMap } = this.props
    const { menus } = dateMenu
    const menu = menus[idx]
    const newState: any = {
      dateMenu: {
        ...dateMenu,
        menus: [
          ...menus.slice(0, idx),
          { ...menu, active: e.target.checked },
          ...menus.slice(idx + 1, menus.length),
        ],
      },
    }
    newState.changedActive = true
    this.changedMenu = true

    this.setState(newState, () => {
      const mapKey = dateMenu.id || dateMenu.dateStr
      const mapEntry =
        changedDateMenuMap &&
        changedDateMenuMap[mapKey] &&
        changedDateMenuMap[mapKey][menu.id]
      if (!mapEntry) {
        changedDateMenuMap[mapKey] = { ...changedDateMenuMap[mapKey] }
        changedDateMenuMap[mapKey][menu.id] = {}
        setChangedDateMenuMap({ ...changedDateMenuMap })
      } else if (mapEntry && !mapEntry['_new']) {
        const newMap = { ...changedDateMenuMap }
        delete newMap[mapKey][menu.id]
        setChangedDateMenuMap(newMap)
      }
    })
  }

  onSyncMenu = async (menuId: any) => {
    const { getGroupOrderConcept, clientSettingsId, date, locale } = this.props
    const { dateMenu = {}, updatedCutoffMap }: State = this.state
    const latestMenu = await getGroupOrderConcept(menuId)
    if (!latestMenu) {
      return
    }
    if (dateMenu?.menus) {
      const groupOrder = await this.props.getGroupOrderBySettingsDate({
        settingsId: clientSettingsId,
        date: date.format('YYYY-MM-DD'),
      })
      const orders = groupOrder
        ? groupOrder.orders.filter((o: any) => o.status === 'Active')
        : []

      const oldMenu = dateMenu.menus.find((m: Menu) => m.id === menuId)
      // update menu without removing items that already have orders placed
      const menus = dateMenu.menus.map((menu: Menu) => {
        if (menu.id !== menuId) {
          return menu
        }
        if (oldMenu) {
          const menuItems = [...latestMenu.menuItems]
          const unsafeRemIts: any = []
          oldMenu.menuItems.forEach((it: any) => {
            const foundOrder = orders.find((order: any) =>
              order.menus.find(
                (oMenu: any) =>
                  oMenu.menuId === menuId &&
                  oMenu.orderItems.find(
                    (orderIt: any) => orderIt.menuItemId === it.id,
                  ),
              ),
            )
            if (foundOrder) {
              unsafeRemIts.push(it)
              if (!menuItems.find((mIt) => mIt.id === it.id)) {
                menuItems.push(it)
              }
            }
          })
          if (unsafeRemIts.length > 0) {
            this.props.displayWarningMessage(
              `The following items could not be removed from the menu because they were ordered already: ${unsafeRemIts
                .map((it: any) => it.name)
                .join(', ')}`,
            )
          }

          return {
            ...oldMenu,
            ...latestMenu,
            cutoffTime: updatedCutoffMap[menu.id]
              ? oldMenu.cutoffTime
              : GetMenuCutoffTime(latestMenu, dateMenu, locale),
            pickupTime: GetMenuPickupTime(latestMenu, dateMenu, locale),
            menuItems,
          }
        }
      })

      this.changed = true
      this.setState({
        dateMenu: { ...dateMenu, menus },
      })
    }
  }

  onStageMenus = async (skipWarnings = false) => {
    const { newMenuIds, menusToDelete, pickupsConfirmed } = this.state
    const { dateMenu }: { dateMenu: any } = this.state
    const {
      hideModal,
      saveDateMenu,
      forCalendarModal,
      updateDateMenus,
      changedDateMenuMap,
      dateMenus,
      clientSettings,
      groupOrder,
      displayFailureMessage,
      locale,
      groupOrderMinimumItems,
      validateChefPickupTimes,
    } = this.props

    const { cutoffTime, date } = dateMenu
    if (
      (!newMenuIds || newMenuIds.length <= 0) &&
      menusToDelete.length === 0 &&
      !this.changed &&
      !this.changedMenu
    ) {
      const message = 'No changes to stage.'

      return displayFailureMessage(message)
    }
    const errorList = []
    if (moment(cutoffTime).isSameOrAfter(date)) {
      errorList.push('Dropoff time must be after cutoff time')
    }

    if (errorList.length > 0) {
      const errorString =
        'Please check the following errors:\n' +
        '• ' +
        errorList.join('.\n• ') +
        '.'
      displayFailureMessage(errorString)

      return
    }
    //only for saving directly from the component on the client tab
    const mapKey = dateMenu.id || dateMenu.dateStr
    let changedMap = { ...changedDateMenuMap[mapKey] }
    if (!changedMap) {
      changedMap = {}
    }
    dateMenu.menus.forEach((menu: any) => {
      if (this.changed) {
        changedMap[menu.id] = {}
      }
      if (menusToDelete.find((id) => id === menu.id)) {
        changedMap[menu.id] = { _destroy: true }
      }
      if (newMenuIds.find((id) => id === menu.id)) {
        changedMap[menu.id] = { _new: true }
      }
    })

    const { warnings } = ValidateDateMenus(
      dateMenu,
      clientSettings,
      groupOrder,
      locale,
      groupOrderMinimumItems,
    )

    if (!pickupsConfirmed) {
      const pickupTimesConfirmed = await validateChefPickupTimes({
        orderType: 'Group Order',
        orderSetupTime: moment(dateMenu.date).tz(locale).format(),
        chefIds: dateMenu.menus.map((menu: Menu) => menu.chef.id),
        headquarterId: this.props.headquarter,
      })
      if (!pickupTimesConfirmed) {
        return
      } else {
        this.setState({ pickupsConfirmed: true })
      }
    }

    if (warnings.length && !skipWarnings) {
      this.setState({
        warningsModalOpen: true,
        validationWarnings: warnings,
      })

      return
    }

    // Close and reset warnings modal if open
    this.setState({
      warningsModalOpen: false,
      validationErrors: [],
      validationWarnings: [],
    })

    if (forCalendarModal) {
      const newMap = { ...changedDateMenuMap }
      newMap[mapKey] = changedMap
      const dateMenuIndex = dateMenus.findIndex((dm: any) => dm.id === mapKey)

      if (dateMenuIndex <= -1) {
        // remove duplicate menus
        const duplicateDateIndex = dateMenus.findIndex(
          (date: any) => date.dateStr === dateMenu.dateStr,
        )
        if (duplicateDateIndex >= 0) {
          const newDateMenus = [...dateMenus]
          newDateMenus[duplicateDateIndex] = dateMenu
          await updateDateMenus({
            dateMenus: newDateMenus,
            changedDateMenuMap: newMap,
          })
        } else {
          await updateDateMenus({
            dateMenus: [...dateMenus, { ...dateMenu }],
            changedDateMenuMap: newMap,
          })
        }
      } else {
        dateMenus.splice(dateMenuIndex, 1, dateMenu)
        const newDateMenus = [...dateMenus]
        await updateDateMenus({
          dateMenus: newDateMenus,
          changedDateMenuMap: newMap,
        })
      }
    } else {
      // When saving date menus, ensure that all event menus cutoff times are set correctly,
      // which is always in relation to date menus cutoff time
      const nextDateMenu = GetUpdatedDateMenusAfterCutoffChange(
        dateMenu,
        locale,
      )

      const saveSuccess = await saveDateMenu({
        dateMenu: nextDateMenu,
        changedDateMenuMap: changedDateMenuMap[mapKey],
        dateStr: dateMenu.dateStr,
      })
      if (saveSuccess) {
        this.setState({ dateMenu: nextDateMenu })
        this.props.onReloadDates()
      }
    }
    // TODO: verify this is correct; I believe tihs was a typo
    this.setState({ newMenuIds: [], menusToDelete: [], updatedCutoffMap: {} })
    hideModal && hideModal()
  }

  onChangeTime = async (key: any, value: any) => {
    const { locale } = this.props
    const { dateMenu }: { dateMenu: any } = this.state
    //DateTextInput does not update when date attribute is changed; reverts to today's date
    if (['date', 'cutoffTime'].includes(key)) {
      const mVal = moment(value)
      const locDate = moment.tz(this.props.date, locale)
      value = locDate.set({ h: mVal.hour(), m: mVal.minutes(), s: 0 })
    }
    this.changed = true
    this.setState({
      dateMenu: {
        ...dateMenu,
        [key]: value,
      },
    })
  }

  onChangeDateTime = async (key: any, value: any) => {
    this.changed = true
    const { dateMenu }: { dateMenu: any } = this.state

    this.setState({
      dateMenu: {
        ...dateMenu,
        [key]: value,
      },
    })
  }

  handleSaveCutoffTime = (menu: Menu) => {
    const { dateMenu = {}, updatedCutoffMap }: State = this.state
    if (dateMenu?.menus) {
      const menuIdx: number = dateMenu.menus.findIndex(
        (m: Menu) => m.id === menu.id,
      )
      if (menuIdx < 0) {
        return
      }
      dateMenu.menus[menuIdx] = menu
      this.changed = true
      updatedCutoffMap[menu.id] = true
      this.setState({
        dateMenu,
        editCutoffMenu: undefined,
        editCutoffTimeModal: false,
        updatedCutoffMap,
      })
    }
  }

  getUniqueMenuItemsCount = () => {
    const {
      dateMenu,
      groupOrder,
    }: {
      dateMenu: any
      groupOrder: any
    } = this.state

    return GetUniqueMenuItemsCountForDateMenus(dateMenu, groupOrder)
  }

  renderMenuItems = (items: any, label: any) => {
    const { countMap } = this.props

    return (
      <div className="group-menu-items">
        <p className="group-menu-items-title">{label}</p>
        {items.map((item: any) => {
          const { averageRating, numRatings } = item.ratingInfo || {}

          return (
            <p className="group-menu-item" key={item.id}>
              - {item.name} | Rating:{' '}
              {numRatings && numRatings !== 0
                ? `${averageRating.toFixed(2)} ⭐ (${numRatings})`
                : 'N/A'}{' '}
              | Orders: {countMap[item.id] || 0}
            </p>
          )
        })}
      </div>
    )
  }

  render() {
    const {
      dateMenu = {},
      editCutoffTimeModal,
      editCutoffMenu,
      showMenu,
      updatedCutoffMap,
    }: State = this.state
    const { headquarter, groupOrderMinimumItems } = this.props
    let date, cutoffTime, menus
    let currDate: moment.Moment = moment()
    let formattedCutoff = ''
    if (dateMenu) {
      ;({ menus, date, cutoffTime } = dateMenu)
    }
    const {
      searchGroupOrderConcepts,
      locale,
      countMap,
      hasActiveOrders,
      forCalendarModal,
    } = this.props
    if (date) {
      currDate =
        typeof date === 'string' ? moment.tz(date, locale) : date.tz(locale)
    }
    if (cutoffTime) {
      formattedCutoff =
        typeof cutoffTime === 'string' ? cutoffTime : cutoffTime.toJSON()
    }

    return (
      <div>
        <YSpacing height="20px" />
        {hasActiveOrders ? (
          <FlexContainer flexDirection="column">
            <div>
              <label className="sidebar-body-label">Cutoff Time</label>
              <p>{moment(cutoffTime).format('lll z')}</p>
            </div>
            <YSpacing height="10px" />
            <div>
              <label className="sidebar-body-label">
                Dropoff Time ({moment(date).format('M/DD')})
              </label>
              <p>{moment(date).format('h:mm a')}</p>
            </div>
          </FlexContainer>
        ) : (
          <FlexContainer flexDirection="column" justifyContent="space-between">
            <DateTextInput
              date={formattedCutoff}
              dateFormat="lll"
              label={`Cutoff Time (${currDate && currDate.format('z')})`}
              onChange={(e: any) => this.onChangeDateTime('cutoffTime', e)}
              timeZone={locale}
              width="75%"
            />
            <YSpacing height="10px" />
            <DateTextInput
              date={date}
              dateFormat="h:mm a"
              label={`Dropoff Time (${currDate && currDate.format('M/DD')})`}
              onChange={(e: any) => this.onChangeTime('date', e)}
              timeZone={locale}
              width="75%"
            />
          </FlexContainer>
        )}
        <YSpacing height="20px" />
        <DateTextInput
          className="input-background"
          label="Set Live Date"
          date={dateMenu.liveDate}
          onChange={(e: any) => this.onChangeTime('liveDate', e)}
          dateFormat="MM/DD/YYYY"
          timeZone={locale}
          placeholder="MM/DD/YYYY"
          width="100%"
        />
        <YSpacing height="20px" />
        <FlexContainer flexDirection="column" justifyContent="space-between">
          <AutocompleteInput
            width="100%"
            label="Add Menu"
            displayAttribute="autocompleteKey"
            loaderFunction={(search) =>
              searchGroupOrderConcepts({
                clientSetUpTime: currDate,
                headquarterId: headquarter,
                search: { ...search },
              })
            }
            placeholder={'Search Menus'}
            onSelect={this.onSelectMenu}
          />
          <YSpacing height="10px" />
          <Checkbox
            label="Active Delivery Date?"
            value={dateMenu.active}
            checked={dateMenu.active}
            onChange={(e: any) =>
              this.setState(
                { dateMenu: { ...dateMenu, active: e.target.checked } },
                () => (this.changed = true),
              )
            }
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <label className="sidebar-body-label">Menus (Click to see items)</label>
        <YSpacing height="10px" />
        <div className="panel-legend">
          <span style={{ background: '#f89eac' }} />
          <p style={{ marginRight: '10px' }}>Non-active menus</p>
        </div>
        <YSpacing height="10px" />
        <div className="go-items-counter-container">
          <div className="items-counter-group">
            <div className="items-counter-label">Unique items scheduled:</div>
            <div
              className={`items-counter-value ${
                this.getUniqueMenuItemsCount() >= groupOrderMinimumItems
                  ? 'min-reached'
                  : 'min-not-reached'
              }`}
            >
              {this.getUniqueMenuItemsCount()}
            </div>
          </div>
          <div className="items-counter-group">
            <div className="items-counter-label">
              Required min unique items:
            </div>
            <div className="items-counter-value">{groupOrderMinimumItems}</div>
          </div>
        </div>
        <YSpacing height="10px" />
        {(menus || []).length === 0 && (
          <div>
            <p>No menus selected for this day</p>
            <YSpacing height="10px" />
          </div>
        )}
        {(menus || []).map((menu: any, idx: any) => {
          const entrees = menu.menuItems.filter(
            (it: any) => it.itemType === 'Entree',
          )
          const sides = menu.menuItems.filter(
            (it: any) =>
              it.itemType === 'Side' ||
              it.itemType === 'Starch Side' ||
              it.itemType === 'Vegetable Side',
          )
          const drinks = menu.menuItems.filter(
            (it: any) => it.itemType === 'Drink',
          )
          const desserts = menu.menuItems.filter(
            (it: any) => it.itemType === 'Dessert',
          )

          return (
            <div
              key={menu.id}
              onClick={() => this.setState({ showMenu: menu.id })}
              className={`group-menu-container ${!menu.active ? 'pink' : ''}`}
            >
              <FlexContainer flexDirection="row" justifyContent="space-between">
                <FlexContainer flexDirection="column">
                  <p>
                    <span
                      style={{ color: theme.textColor }}
                      className="group-menu-name font-semibold mr-2"
                    >
                      Partner:
                    </span>
                    {menu.chef.name}
                  </p>
                  <p>
                    <span
                      style={{ color: theme.textColor }}
                      className="group-menu-name font-semibold mr-2"
                    >
                      Menu Name:
                    </span>
                    {menu.name}
                  </p>
                  <p>
                    <span
                      style={{ color: theme.textColor }}
                      className="group-menu-name font-semibold mr-2"
                    >
                      Order Count:
                    </span>
                    {countMap[menu.id] || 0}
                  </p>
                  {editCutoffTimeModal && editCutoffMenu && (
                    <EditDateMenuCutoffModal
                      editMenu={editCutoffMenu}
                      dateMenuCutoffTime={formattedCutoff}
                      displayFailureMessage={this.props.displayFailureMessage}
                      displayWarningMessage={this.props.displayWarningMessage}
                      hideModal={() =>
                        this.setState({
                          editCutoffTimeModal: false,
                          editCutoffMenu: undefined,
                        })
                      }
                      saveMenu={(menu: Menu) => this.handleSaveCutoffTime(menu)}
                      showConfirmationModal={this.props.showConfirmationModal}
                    />
                  )}
                  {updatedCutoffMap[menu.id] && (
                    <span className="text-red-800 font-bold">
                      Cutoff Updated
                    </span>
                  )}
                  <div className="flex flex-row space-between">
                    <div>
                      <p className="font-semibold">
                        <span
                          style={{ color: theme.textColor }}
                          className="group-menu-name mr-2"
                        >
                          Menu Cutoff Time:
                        </span>
                        {moment(menu.cutoffTime).format('lll z')}
                      </p>
                    </div>
                    {moment(formattedCutoff).isAfter(moment()) && (
                      <FlexContainer
                        flexDirection="row"
                        justifyContent="start"
                        width="30%"
                      >
                        <AuthorizedDisplay
                          roles={['master admin', 'chef lead']}
                        >
                          <div className="summary-header">
                            <button
                              onClick={() =>
                                this.setState({
                                  editCutoffTimeModal: true,
                                  editCutoffMenu: menu,
                                })
                              }
                              className="edit-button"
                            >
                              <div className="button-content">
                                <BiPencil />
                                <div>Edit</div>
                              </div>
                            </button>
                          </div>
                        </AuthorizedDisplay>
                        <TooltipModal
                          unicode="&#9432;"
                          width="400px"
                          information={
                            'Primary use of this edit button should to be fix an incorrectly entered menu card cutoff time.'
                          }
                          marginTop="20px"
                        />
                      </FlexContainer>
                    )}
                  </div>
                  {menu.chef.cutoffEmailSentAt && (
                    <p className="font-semibold">
                      <span
                        style={{ color: theme.textColor }}
                        className="group-menu-name mr-2"
                      >
                        Chef Email Sent At:
                      </span>
                      {moment(menu.chef.cutoffEmailSentAt).format('lll z')}
                    </p>
                  )}
                </FlexContainer>
                {!hasActiveOrders && (
                  <Button
                    label={<FaTrash />}
                    onClick={() => this.onRemoveMenu(idx, menu.id)}
                  />
                )}
              </FlexContainer>

              {showMenu === menu.id && (
                <div>
                  <YSpacing height="10px" />
                  <Checkbox
                    label={`Active? ( Last Activated: ${
                      menu.lastActive
                        ? moment(menu.lastActive).format('MM-DD-YYYY h:mm a')
                        : 'N/A'
                    } )`}
                    value={menu.active}
                    checked={menu.active}
                    onChange={this.onChangeMenuActive(idx)}
                  />
                  <Button
                    label="Sync Menu"
                    onClick={() => this.onSyncMenu(menu.id)}
                  />
                  {!!entrees.length && this.renderMenuItems(entrees, 'Entrees')}
                  {!!sides.length && this.renderMenuItems(sides, 'Sides')}
                  {!!drinks.length && this.renderMenuItems(drinks, 'Drinks')}
                  {!!desserts.length &&
                    this.renderMenuItems(desserts, 'Desserts')}
                </div>
              )}
            </div>
          )
        })}
        <Button
          width="100%"
          label={
            dateMenu.id
              ? forCalendarModal
                ? 'Stage Changes'
                : 'Save Changes'
              : 'Add Menus'
          }
          onClick={() => this.onStageMenus()}
        />

        {this.state.warningsModalOpen && (
          <ValidationsModal
            onClose={this.onCloseWarningsModal}
            onSave={() => this.onStageMenus(true)}
            validationErrors={this.state.validationErrors}
            validationWarnings={this.state.validationWarnings}
          />
        )}
      </div>
    )
  }
}

export default DateMenusComponent
