import { connect } from 'react-redux'

import { ChefNote } from '@components/order/edit/sections'

import services from '@services'

const mapDispatchToProps = () => {
  const { UIService } = services
  const flashInfoOptions = UIService.FlashMessage.displayInfoMessageOptions

  return { flashInfoOptions }
}

export default connect(undefined, mapDispatchToProps)(ChefNote)
