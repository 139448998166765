import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet'
import { LatLngTuple, LatLngExpression } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import { Moment } from 'moment'
import { BsArrowsExpand } from 'react-icons/bs'
import { BiTrash } from 'react-icons/bi'
import {
  MapChef,
  MapClient,
  MapHeadquarter,
  MapMarker,
  MapFilterKey,
} from '@types'
import FlexContainer from '@components/common/FlexContainer'
import XSpacing from '@components/common/XSpacing'
import YSpacing from '@components/common/YSpacing'
import {
  Checkbox,
  DateTimeInput,
  Dropdown,
  Input,
} from '@components/common/form'
import Loader from '@components/common/Loader'
import DividerLine from '@components/common/DividerLine'
import TooltipModal from '@components/common/modal/TooltipModal'
import {
  redClientIcon,
  blueChefIcon,
  blackHQIcon,
  cateringChefIcon,
  cateringClientIcon,
  groupOrderChefIcon,
  groupOrderClientIcon,
  cateringAndGroupOrderChefIcon,
  cateringAndGroupOrderClientIcon,
} from './mapIcons'
import presenters from '@presenters'
const { getDistanceFromLatLonInMiles } = presenters.Api

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

const isFilterKey = (key: string): key is MapFilterKey => {
  return [
    'acceptsGroupOrders',
    'acceptsCateringOrders',
    'acceptsBoth',
  ].includes(key)
}
const HIDE = 'hide'
const FILTERS_TAB = 'FILTERS'
const ROUTE_TAB = 'ROUTE'
interface VendorClientMapProps {
  headquarterId: number

  loadChefs: (hqId: number, date: Moment | undefined) => MapChef[]
  loadClients: (hqId: number) => MapClient[]
  loadHeadquarter: (hqId: number) => MapHeadquarter
}

const VendorClientMap = ({
  headquarterId,
  loadClients,
  loadChefs,
  loadHeadquarter,
}: VendorClientMapProps) => {
  const [chefs, setChefs] = useState<MapChef[]>([])
  const [selectedChefs, setSelectedChefs] = useState<Record<string, boolean>>(
    {},
  )
  const [chefMarkers, setChefMarkers] = useState<MapMarker[]>([])
  const [chefFilter, setChefFilter] = useState('')
  const [searchChefValue, setSearchChefValue] = useState('')
  const [showChefMarkers, setShowChefMarkers] = useState(true)
  const [shouldLoadChefs, setShouldLoadChefs] = useState(false)

  const [clients, setClients] = useState<MapClient[]>([])
  const [selectedClients, setSelectedClients] = useState<
    Record<string, boolean>
  >({})
  const [clientFilter, setClientFilter] = useState('')
  const [clientMarkers, setClientMarkers] = useState<MapMarker[]>([])
  const [searchClientValue, setSearchClientValue] = useState('')
  const [showClientMarkers, setShowClientMarkers] = useState(true)

  const [hqMarkers, setHqMarkers] = useState<MapMarker[]>()

  const [markersForDistCalc, setMarkersForDistCalc] = useState<MapMarker[]>([])
  const [polyline, updatePolyline] = useState<LatLngExpression[]>()
  const [availableOn, setAvailableOn] = useState<undefined | Moment>()

  const [sideBarTab, setSideBarTab] = useState(FILTERS_TAB)
  const [collapseChefs, setCollapseChefs] = useState(true)
  const [collapseClients, setCollapseClients] = useState(true)

  // Fetch Data
  const loadChefsByHq = useCallback(async () => {
    const chefs = await loadChefs(headquarterId, availableOn)
    if (chefs) {
      setChefs(chefs)
      const markers: MapMarker[] = []
      chefs.forEach((chef) => {
        chef.addresses.forEach((address) => {
          if (!address.geoCoordinates[0] || !address.geoCoordinates[1]) {
            return
          }
          markers.push({
            id: chef.id,
            address: `${address.line1}, ${address.city} ${address.state}`,
            coordinates: address.geoCoordinates,
            name: chef.name,
            acceptsCateringOrders: chef.acceptsCateringOrders,
            acceptsGroupOrders: chef.acceptsGroupOrders,
            acceptsBoth: chef.acceptsCateringOrders && chef.acceptsGroupOrders,
          })
        })
      })
      setChefMarkers(markers)
    }
  }, [headquarterId, loadChefs, availableOn])

  const loadClientsByHq = useCallback(async () => {
    const clients = await loadClients(headquarterId)
    if (clients) {
      setClients(clients)
      const markers: MapMarker[] = []
      clients.forEach((client) => {
        client.addresses.forEach((address) => {
          if (!address.geoCoordinates[0] || !address.geoCoordinates[1]) {
            return
          }
          markers.push({
            id: client.id,
            address: `${address.line1}, ${address.city} ${address.state}`,
            coordinates: address.geoCoordinates,
            name: client.name,
            acceptsCateringOrders: client.acceptsCateringOrders,
            acceptsGroupOrders: client.acceptsGroupOrders,
            acceptsBoth:
              client.acceptsCateringOrders && client.acceptsGroupOrders,
          })
        })
      })
      setClientMarkers(markers)
    }
  }, [headquarterId, loadClients])

  const loadHQ = useCallback(async () => {
    const headquarter = await loadHeadquarter(headquarterId)
    if (headquarter) {
      const { depots } = headquarter
      const markers: MapMarker[] = []
      depots
        .filter((d) => d.active && d.address.geo)
        .forEach((depot) => {
          const { address } = depot
          markers.push({
            id: depot.id,
            acceptsCateringOrders: false,
            acceptsGroupOrders: false,
            acceptsBoth: false,
            address: `${address.line1} ${address.city} ${address.zip}`,
            name: `Depot: ${headquarter.name}`,
            coordinates: [
              address.geo.coordinates[1],
              address.geo.coordinates[0],
            ],
          })
        })
      setHqMarkers(markers)
    }
  }, [headquarterId, loadHeadquarter])

  const calculatePolyline = () => {
    const newPolyLine: LatLngExpression[] = []

    markersForDistCalc.forEach((marker) => {
      newPolyLine.push([marker.coordinates[0], marker.coordinates[1]])
    })

    updatePolyline(newPolyLine)
  }

  useEffect(() => {
    calculatePolyline()
  }, [markersForDistCalc])

  useEffect(() => {
    loadChefsByHq()
    loadClientsByHq()
    loadHQ()
  }, [])

  useEffect(() => {
    if (availableOn || shouldLoadChefs) {
      loadChefsByHq()
      if (shouldLoadChefs) {
        setShouldLoadChefs(false)
      }
    }
  }, [availableOn, shouldLoadChefs])

  const onRemoveMarker = (index: number) => {
    const newArray = markersForDistCalc.slice()
    newArray.splice(index, 1)
    setMarkersForDistCalc(newArray)
  }

  const onChefFilterChange = (value: string) => {
    setChefFilter(value)
    setShowChefMarkers(value !== HIDE)
  }

  const onClearDate = () => {
    setAvailableOn(undefined)
    setShouldLoadChefs(true)
  }

  const filterClientMarkers = (): MapMarker[] => {
    const atleastOneClientSelected =
      Object.values(selectedClients).length > 0 &&
      Object.values(selectedClients).some((v) => v)
    if (!clientFilter) {
      return atleastOneClientSelected
        ? clientMarkers.filter((m) => selectedClients[m.id])
        : clientMarkers
    } else {
      return clientMarkers.filter(
        (m) =>
          (isFilterKey(clientFilter) ? m[clientFilter] : true) &&
          (atleastOneClientSelected ? selectedClients[m.id] : true),
      )
    }
  }

  const onClientFilterChange = (value: string) => {
    setClientFilter(value)
    setShowClientMarkers(value !== HIDE)
  }

  const filterChefMarkers = (): MapMarker[] => {
    const atleastOneChefSelected =
      Object.values(selectedChefs).length > 0 &&
      Object.values(selectedChefs).some((v) => v)
    if (!chefFilter) {
      return atleastOneChefSelected
        ? chefMarkers.filter((m) => selectedChefs[m.id])
        : chefMarkers
    } else {
      return chefMarkers.filter(
        (m) =>
          (isFilterKey(chefFilter) ? m[chefFilter] : true) &&
          (atleastOneChefSelected ? selectedChefs[m.id] : true),
      )
    }
  }

  const determineClientIcon = () => {
    if (clientFilter === 'acceptsBoth') {
      return cateringAndGroupOrderClientIcon
    } else if (clientFilter === 'acceptsCateringOrders') {
      return cateringClientIcon
    } else if (clientFilter === 'acceptsGroupOrders') {
      return groupOrderClientIcon
    } else {
      return redClientIcon
    }
  }

  const determineChefIcon = () => {
    if (chefFilter === 'acceptsBoth') {
      return cateringAndGroupOrderChefIcon
    } else if (chefFilter === 'acceptsCateringOrders') {
      return cateringChefIcon
    } else if (chefFilter === 'acceptsGroupOrders') {
      return groupOrderChefIcon
    } else {
      return blueChefIcon
    }
  }

  const renderMapLegend = () => {
    return (
      <FlexContainer alignItems="center" justifyContent="center">
        <LegendContainer>
          <LegendTitle>Legend</LegendTitle>
          <YSpacing height="10px" />
          <FlexContainer alignItems="center" justifyContent="space-evenly">
            <FlexContainer width="30%">
              <strong style={{ fontSize: '20px' }}>Chef Icon: </strong>
              <XSpacing width="10px" />
              <img
                style={{ height: '30px' }}
                src="https://img.icons8.com/?size=100&id=20441&format=png&color=5C7CFA"
              />
            </FlexContainer>
            <FlexContainer width="30%">
              <strong style={{ fontSize: '20px' }}>Client Icon: </strong>
              <XSpacing width="10px" />
              <img
                style={{ height: '30px' }}
                src="https://img.icons8.com/?size=100&id=85794&format=png&color=FB1B1B"
              />
            </FlexContainer>
            <FlexContainer width="30%">
              <strong style={{ fontSize: '20px' }}>
                Headquarter Depot Icon
              </strong>
              <XSpacing width="10px" />
              <img
                style={{ height: '30px', marginTop: '10px' }}
                src="https://img.icons8.com/?size=100&id=7880&format=png&color=000000"
              />
            </FlexContainer>
          </FlexContainer>
        </LegendContainer>
      </FlexContainer>
    )
  }

  const renderChefs = () => {
    const loweredSearchValue = searchChefValue.toLowerCase()

    return (
      <FlexContainer flexDirection="column" alignItems="left">
        {chefs
          .filter((chef) => {
            const nameMatches = chef.name
              .toLowerCase()
              .includes(loweredSearchValue)
            const addressMatches = chef.addresses.some(
              (address) =>
                address.city?.toLowerCase().includes(loweredSearchValue) ||
                address.line1?.toLowerCase().includes(loweredSearchValue) ||
                address.state?.toLowerCase().includes(loweredSearchValue),
            )

            return nameMatches || addressMatches
          })
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((c) => (
            <>
              <Checkbox
                label={c.name}
                width="100%"
                onChange={() =>
                  setSelectedChefs({
                    ...selectedChefs,
                    [c.id]: !selectedChefs[c.id],
                  })
                }
                checked={selectedChefs[c.id]}
              />
              <YSpacing height="2px" />
            </>
          ))}
      </FlexContainer>
    )
  }

  const renderClients = () => {
    const loweredSearchValue = searchClientValue.toLowerCase()

    return (
      <FlexContainer flexDirection="column" alignItems="left">
        {clients
          .filter((client) => {
            const nameMatches = client.name
              .toLowerCase()
              .includes(loweredSearchValue)
            const addressMatches = client.addresses.some(
              (address) =>
                address.city?.toLowerCase().includes(loweredSearchValue) ||
                address.line1?.toLowerCase().includes(loweredSearchValue) ||
                address.state?.toLowerCase().includes(loweredSearchValue),
            )

            return nameMatches || addressMatches
          })
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((c) => (
            <>
              <Checkbox
                label={c.name}
                width="100%"
                onChange={() =>
                  setSelectedClients({
                    ...selectedClients,
                    [c.id]: !selectedClients[c.id],
                  })
                }
                checked={selectedClients[c.id]}
              />
              <YSpacing height="2px" />
            </>
          ))}
      </FlexContainer>
    )
  }

  const renderFilters = () => {
    return (
      <FlexContainer
        flexDirection="column"
        alignItems="left"
        justifyContent="center"
        padding="0px 0px 0px 5px"
      >
        <FlexContainer
          width="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <TabTitle>Filters</TabTitle>
        </FlexContainer>
        <YSpacing height="10px" />
        <FlexContainer>
          <DateTimeInput
            width="90%"
            label="Chef Availability"
            date={availableOn}
            onChange={(date: Moment) => setAvailableOn(date)}
            clearDate={onClearDate}
          />
          <TooltipModal
            unicode="&#9432;"
            width="400px"
            information="To view chefs available at a specific time, select a date and time. This will refresh the data and filter chefs based on their availability."
            marginTop="40px"
            spanMarginRight="210px"
          />
        </FlexContainer>
        <YSpacing height="10px" />
        <FlexContainer>
          <Dropdown
            label="Filter Chefs"
            width="90%"
            value={chefFilter}
            onChange={(e) => onChefFilterChange(e.target.value)}
          >
            <option value="">All</option>
            <option value={HIDE}>Hide Chef Markers</option>
            <option value="acceptsGroupOrders">Provides Group Orders</option>
            <option value="acceptsCateringOrders">
              Provides Catering Orders
            </option>
            <option value="acceptsBoth">
              Provides Both Catering & Group Order
            </option>
          </Dropdown>
          <TooltipModal
            unicode="&#9432;"
            width="400px"
            information="Selecting 'All' will display all chefs, regardless of the types of orders they provide. You can also filter by order type: provides catering orders, group orders, or both!"
            marginTop="40px"
            spanMarginRight="210px"
          />
        </FlexContainer>
        <FlexContainer>
          <Dropdown
            label="Filter Clients"
            width="90%"
            value={clientFilter}
            onChange={(e) => onClientFilterChange(e.target.value)}
          >
            <option value="">All</option>
            <option value={HIDE}>Hide Client Markers</option>
            <option value="acceptsGroupOrders">Accepts Group Orders</option>
            <option value="acceptsCateringOrders">
              Accepts Catering Orders
            </option>
            <option value="acceptsBoth">
              Accepts both Catering & Group Orders
            </option>
          </Dropdown>
          <TooltipModal
            unicode="&#9432;"
            width="400px"
            information="Selecting 'All' will display all clients found, regardless of order acceptance. You can also filter by order acceptance: accepts catering orders, group orders, or both!"
            marginTop="40px"
            spanMarginRight="210px"
          />
        </FlexContainer>
        <FlexContainer>
          <Input
            label="Search Chefs"
            placeholder={'Search by Name or Address...'}
            value={searchChefValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchChefValue(e.target.value)
            }
            width="90%"
          />
          <TooltipModal
            unicode="&#9432;"
            width="400px"
            information="Search chefs by name or address. Selecting one or more chefs will display only the selected chefs. If no chefs are selected, all chefs will be displayed."
            marginTop="40px"
            spanMarginRight="210px"
          />
        </FlexContainer>
        <CollapseBtn
          onClick={() => setCollapseChefs(!collapseChefs)}
          collapsed={collapseChefs}
        >
          {collapseChefs ? 'View' : 'Collapse'} Chefs{' '}
          <BsArrowsExpand size={20} />
        </CollapseBtn>
        <YSpacing height="10px" />
        {!collapseChefs && renderChefs()}
        <YSpacing height="10px" />
        <FlexContainer>
          <Input
            label="Search Clients"
            value={searchClientValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchClientValue(e.target.value)
            }
            width="90%"
            placeholder={'Search by Name or Address...'}
          />
          <TooltipModal
            unicode="&#9432;"
            width="400px"
            information="Search clients by name or address. Selecting one or more clients will display only the selected clients. If no clients are selected, all clients will be displayed."
            marginTop="40px"
            spanMarginRight="210px"
          />
        </FlexContainer>
        <CollapseBtn
          onClick={() => setCollapseClients(!collapseClients)}
          collapsed={collapseClients}
        >
          {collapseClients ? 'View' : 'Collapse'} Clients{' '}
          <BsArrowsExpand size={20} />
        </CollapseBtn>
        <YSpacing height="10px" />
        {!collapseClients && renderClients()}
      </FlexContainer>
    )
  }

  const renderRouteBuilder = () => {
    return (
      <FlexContainer flexDirection="column" alignItems="left">
        <FlexContainer flexDirection="column" alignItems="center">
          <TabTitle>Steps to Build Route</TabTitle>
        </FlexContainer>
        <TabOrderedList>
          <li>
            Click on a Client or Chef Icon you&apos;d like to add as a stop
          </li>
          <li>Select &ldquo;Add as Stop&rdquo; from the pop-up</li>
          <li>Add More Stops & View Route Below!</li>
        </TabOrderedList>
        <YSpacing height="10px" />
        {renderMarkersDistance()}
      </FlexContainer>
    )
  }

  const renderSideBar = () => {
    return (
      <SideBar>
        <FlexContainer
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <FlexContainer flexDirection="row" justifyContent="space-around">
            <Tab
              selected={sideBarTab === FILTERS_TAB}
              onClick={() => setSideBarTab(FILTERS_TAB)}
            >
              Filters
            </Tab>
            <Tab
              selected={sideBarTab !== FILTERS_TAB}
              onClick={() => setSideBarTab(ROUTE_TAB)}
            >
              Build Route
            </Tab>
          </FlexContainer>
        </FlexContainer>
        <YSpacing height="10px" />
        {sideBarTab === FILTERS_TAB ? renderFilters() : renderRouteBuilder()}
      </SideBar>
    )
  }

  const renderMarkersDistance = () => {
    let totalDistance = 0
    if (markersForDistCalc.length == 0) {
      return (
        <FlexContainer flexDirection="column" alignItems="center">
          <p>No Stops Added</p>
        </FlexContainer>
      )
    } else {
      return (
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="95%"
        >
          {markersForDistCalc.map((marker, index, array) => {
            const nextMarker = array[index + 1]
            if (nextMarker) {
              totalDistance += Number(
                getDistanceFromLatLonInMiles(
                  marker.coordinates[0],
                  marker.coordinates[1],
                  nextMarker.coordinates[0],
                  nextMarker.coordinates[1],
                ),
              )
            }

            return (
              <FlexContainer flexDirection="column" width="100%" key={index}>
                <StopContainer>
                  <p>Stop: {index + 1}</p>
                  <strong>{marker.name}</strong>
                  <button onClick={() => onRemoveMarker(index)}>
                    <BiTrash size={20} />
                  </button>
                </StopContainer>
                <DistanceContainer>
                  {nextMarker && (
                    <p>
                      Distance from <strong>{marker.name}</strong> to{' '}
                      <strong>{nextMarker.name}</strong> is{' '}
                      {getDistanceFromLatLonInMiles(
                        marker.coordinates[0],
                        marker.coordinates[1],
                        nextMarker.coordinates[0],
                        nextMarker.coordinates[1],
                      )}{' '}
                      Miles
                    </p>
                  )}
                </DistanceContainer>
              </FlexContainer>
            )
          })}
          <br />
          <strong>Total Distance: {totalDistance.toFixed(2)} Miles</strong>
        </FlexContainer>
      )
    }
  }

  const limeOptions = { color: 'lime' }
  if (!hqMarkers) {
    return <Loader width="200px" />
  } else if (!hqMarkers.length) {
    return (
      <p>
        This headquarter does not have any valid depots with geo-locations to
        center the map.
      </p>
    )
  } else {
    return (
      <>
        {renderMapLegend()}
        <YSpacing height="10px" />
        <DividerLine height="2px" />
        <YSpacing height="10px" />
        <FlexContainer flexDirection="row" height="1000px">
          {renderSideBar()}
          <XSpacing width="5px" />
          <FlexContainer flexDirection="column" height="100%">
            <MapContainer
              center={hqMarkers[0].coordinates}
              zoom={10}
              scrollWheelZoom={false}
              style={{
                width: '100%',
                height: '1000px',
                zIndex: '0',
              }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {hqMarkers.length > 0 &&
                hqMarkers.map((hqMarker, i) => (
                  <Marker
                    position={hqMarker.coordinates}
                    icon={blackHQIcon}
                    key={i}
                  >
                    <Popup>
                      {hqMarker.name} <br /> {hqMarker.address}
                      <br />
                      <button
                        onClick={() =>
                          setMarkersForDistCalc([
                            ...markersForDistCalc,
                            hqMarker,
                          ])
                        }
                        style={{ color: 'grey' }}
                      >
                        Add as Stop
                      </button>
                    </Popup>
                  </Marker>
                ))}
              {showClientMarkers &&
                filterClientMarkers().map((marker, i) => {
                  const position: LatLngTuple = marker.coordinates

                  return (
                    <Marker
                      position={position}
                      icon={determineClientIcon()}
                      key={i}
                    >
                      <Popup>
                        <strong>
                          Client: {marker.name} <br /> {marker.address}
                        </strong>
                        <p>
                          Accepts Group Orders:{' '}
                          {marker.acceptsGroupOrders ? 'YES' : 'NO'}
                        </p>
                        <p>
                          Accepts Catering Orders:{' '}
                          {marker.acceptsCateringOrders ? 'YES' : 'NO'}
                        </p>
                        {sideBarTab !== FILTERS_TAB && (
                          <button
                            onClick={() =>
                              setMarkersForDistCalc([
                                ...markersForDistCalc,
                                marker,
                              ])
                            }
                            style={{ color: 'grey' }}
                          >
                            Add as Stop
                          </button>
                        )}
                      </Popup>
                    </Marker>
                  )
                })}
              {showChefMarkers &&
                filterChefMarkers().map((marker, i) => {
                  const position: LatLngTuple = marker.coordinates

                  return (
                    <Marker
                      position={position}
                      icon={determineChefIcon()}
                      key={i}
                    >
                      <Popup>
                        <strong>
                          Chef: {marker.name} <br /> {marker.address}
                        </strong>
                        <p>
                          Provides Group Orders:{' '}
                          {marker.acceptsGroupOrders ? 'YES' : 'NO'}
                        </p>
                        <p>
                          Provides Catering Orders:{' '}
                          {marker.acceptsCateringOrders ? 'YES' : 'NO'}
                        </p>
                        {sideBarTab !== FILTERS_TAB && (
                          <button
                            onClick={() =>
                              setMarkersForDistCalc([
                                ...markersForDistCalc,
                                marker,
                              ])
                            }
                            style={{ color: 'grey' }}
                          >
                            Add as Stop
                          </button>
                        )}
                      </Popup>
                    </Marker>
                  )
                })}
              {polyline && polyline.length > 0 && (
                <Polyline pathOptions={limeOptions} positions={polyline} />
              )}
            </MapContainer>
          </FlexContainer>
        </FlexContainer>
      </>
    )
  }
}

const LegendContainer = styled.div`
  width: 700px;
  height: 100px;
  min-width: 700px;
  border: 1px dotted black;
  border-radius: 5px;
  text-align: center;
`

const LegendTitle = styled.h1`
  color: grey;
  font-size: 20px;
`

const TabTitle = styled.h1`
  color: grey;
  font-size: 25px;
`

const TabOrderedList = styled.ol`
  color: grey;
  font-size: 18px;
  list-style-type: decimal;

  & > li {
    margin-left: 30px;
    list-style-type: decimal;
  }
`

const SideBar = styled.div`
  width: 30%;
  min-width: 350px;
  border: 1px solid grey;
  height: 100%;
  padding: 5px;
  overflow-y: auto;
`

const StopContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding: 10px 0px 0px 0px;
`

const DistanceContainer = styled.div`
  background: #d6d5d4;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
interface TabProps {
  selected: boolean
}
const Tab = styled.button`
  font-size: 18px;
  font-weight: bold;
  border: none;
  outline: none;
  border-bottom: ${(props: TabProps) =>
    props.selected ? '2px solid blue' : 'none'};
  &:hover {
    border-bottom: 2px solid red;
  }
  &:focus {
    outline: none;
  }
`

interface CollapseBtnProps {
  collapsed: boolean
}
const CollapseBtn = styled.button`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  border: none;
  outline: none;
  width: ${(props: CollapseBtnProps) => (props.collapsed ? '30%' : '40%')};
  &:focus {
    outline: none;
  }
  &:hover {
    border-bottom: 2px solid blue;
  }
  svg {
    margin-left: 5px;
  }
`

export default VendorClientMap
