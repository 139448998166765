import React from 'react'
import { FormInputContainer } from '@res/styledComponents/index'
import { FilterConfig } from '@types'

interface DropdownWithCustomInputsProps {
  customKey: string
  customValue: string
  filter: FilterConfig
  hasCustomNumberInput: boolean
  selectedValue: string
  onCustomValueChange: (key: string, value: string) => void
  onValueChange: (key: string, value: string) => void
  // Style Props
  marginBottom: string
  width: string
}

const DropdownWithCustomInputs: React.FC<DropdownWithCustomInputsProps> = ({
  customKey,
  customValue,
  filter,
  hasCustomNumberInput,
  selectedValue,
  onCustomValueChange,
  onValueChange,
  width,
  marginBottom,
}) => {
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    onValueChange(filter.key, value)
  }

  const handleCustomInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    onCustomValueChange(filter.key, value)
  }

  const renderCustomInput = () => {
    if (selectedValue && selectedValue.includes(customKey)) {
      return (
        <input
          type={hasCustomNumberInput ? 'number' : 'text'}
          value={customValue}
          placeholder={filter.placeHolder}
          onChange={handleCustomInputChange}
        />
      )
    } else {
      return <></>
    }
  }

  return (
    <FormInputContainer width={width} marginBottom={marginBottom}>
      <label>{filter.label}</label>
      <select onChange={handleSelectChange}>
        <option value=""></option>
        {filter.options.map((option, idx) => (
          <option key={idx} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {renderCustomInput()}
    </FormInputContainer>
  )
}

export default DropdownWithCustomInputs
