const hubspotUrlPrefix = '/hubspot'
export const DelayedUpdateAccount =
  ({ AccountService, UIService, pStateToReduxAccount }) =>
  (account) => {
    const call = () => AccountService.setAccount(pStateToReduxAccount(account))
    UIService.Timer.callAfterTimeout(call)
  }

export const DeleteAccount =
  ({ AccountService, RestService, UIService }) =>
  async () => {
    const { id: accountId, name } = AccountService.getState().account
    const text = `Are you sure you want to delete ${name}?`
    const doDelete = await UIService.ConfirmationModal.show({ text })
    if (doDelete) {
      await RestService.delete(`/api/admin/accounts/${accountId}`)
      AccountService.clearEditAccount()
      AccountService.clearAccount()
      AccountService.removeAccountFromAccounts(accountId)
      UIService.EditAccount.close()
    }
  }

export const EditAccount =
  ({
    AccountService,
    RestService,
    UIService,
    pResponseEditAccount,
    pResponseEditClientAccounting,
  }) =>
  async () => {
    const accountId = AccountService.getState().account.id
    let account = await RestService.get(`/api/admin/accounts/${accountId}`)
    const accounting = await RestService.get(`/accounting/client/${accountId}`)
    account = {
      ...pResponseEditAccount(account),
      ...pResponseEditClientAccounting(accounting),
    }
    AccountService.setEditAccount(account)
    UIService.EditAccount.show()
  }

export const SaveAccount =
  ({
    AccountService,
    RestService,
    UIService,
    pRequestUpdateAccount,
    pRequestUpsertAccountingClient,
    pResponseAccount,
    pResponseEditClientAccounting,
    HandleError,
  }) =>
  async (data) => {
    UIService.Errors.clear()
    const accountData = pRequestUpdateAccount(data)
    let account
    try {
      // rails
      if (accountData.account.id) {
        account = await RestService.put(
          `/api/admin/accounts/${accountData.account.id}`,
          accountData,
          { timeout: 30000 },
        )
      } else {
        account = await RestService.post('/api/admin/accounts', accountData, {
          timeout: 30000,
        })
      }

      // accounting
      const accountingData = pRequestUpsertAccountingClient(data)
      const accounting = await RestService.put(
        `/accounting/client/${account.id}`,
        accountingData,
      )
      account = {
        ...pResponseAccount(account),
        ...pResponseEditClientAccounting(accounting),
      }
      if (data.needHubspotSync) {
        // will sync companiesCache collection
        // neccessary for new clients created from hubspot
        const { id: hungryId, name, hubspotId } = account
        const endpoint = 'companies'
        const urlString = `${hubspotUrlPrefix}/${endpoint}/${hungryId}`
        const params = { hubspotId: hubspotId, hungryId: hungryId, name }
        await RestService.post(urlString, params)
      }
      AccountService.setAccount(account)
      UIService.EditAccount.close()
      AccountService.clearEditAccount()
    } catch (error) {
      HandleError({ error, namespace: 'accountModal' })
    }
  }

export const SavePreferenceProfile =
  ({
    UIService,
    RestService,
    pRequestUpdatePreferenceProfile,
    pResponsePreferenceProfile,
    HandleError,
  }) =>
  async (accountId, data) => {
    try {
      const profileData = pRequestUpdatePreferenceProfile(data)
      const profile = await RestService.put(
        `/api/admin/preference_profiles/${accountId}`,
        profileData,
      )
      UIService.FlashMessage.displaySuccessMessage(
        'Your changes have been saved!',
      )

      return pResponsePreferenceProfile(profile)
    } catch (error) {
      HandleError({ error, namespace: 'preferenceProfile' })
    }
  }

export const CloseEditAccount =
  ({ AccountService, UIService }) =>
  () => {
    UIService.EditAccount.close()
    AccountService.clearEditAccount()
  }

export const BulkUpdateHubspotAccounts =
  ({
    RestService,
    AccountService,
    HandleError,
    pResponseHubspotAccounts,
    pRequestBulkUpdateHubspotAccounts,
  }) =>
  async (data) => {
    try {
      const req = pRequestBulkUpdateHubspotAccounts(data)
      const response = await RestService.put(
        '/api/admin/accounts/hubspot/bulk_update',
        req,
        { timeout: 30000 },
      )
      const accounts = pResponseHubspotAccounts(response)
      AccountService.setAccountsInAccounts(accounts)

      return true
    } catch (error) {
      HandleError({ error })

      return false
    }
  }
