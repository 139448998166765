import React from 'react'
import PropTypes from 'prop-types'

const XSpacing = (props) => {
  const { width } = props

  return (
    <div
      style={{
        width,
        minWidth: width,
        height: '100%',
      }}
    />
  )
}

XSpacing.propTypes = {
  width: PropTypes.string,
}

export default XSpacing
