import React from 'react'
import PropTypes from 'prop-types'

const Log = (props) => {
  const { audits } = props

  return (
    <div>
      <table className="table page">
        <thead>
          <tr>
            <th>Date</th>
            <th>User</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {audits
            .map((audit) => [
              <tr key={audit.id}>
                <td style={{ backgroundColor: '#FCFCFC', width: '200px' }}>
                  {' '}
                  {audit.createdAt}{' '}
                </td>
                <td style={{ backgroundColor: '#FCFCFC' }}>
                  <a href={`/users/${audit.userId}`}> {audit.userEmail} </a>
                </td>
                <td style={{ backgroundColor: '#FCFCFC' }}> {audit.action} </td>
              </tr>,
              <tr key={`${audit.id}_changes`}>
                <td colSpan="3">
                  <div
                    style={{
                      wordBreak: 'break-all',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {audit.auditedChanges}
                  </div>
                </td>
              </tr>,
            ])
            .reduce((a, b) => a.concat(b), [])}
        </tbody>
      </table>
    </div>
  )
}

Log.propTypes = {
  audits: PropTypes.arrayOf(PropTypes.object),
}

Log.defaultProps = {
  audits: [],
}

export default Log
