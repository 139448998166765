export const DeletePackagingAddOn =
  ({
    LoadPackagingAddOns,
    RestService,
    SupplyService,
    UIService,
    pResponsePackagingAddOns,
    HandleError,
  }) =>
  async (id) => {
    const text = 'Are you sure you want to delete this packaging add on?'
    const doDelete = await UIService.ConfirmationModal.show({ text })
    if (doDelete) {
      try {
        await RestService.delete(`/api/admin/packaging_add_ons/${id}`)
        await LoadPackagingAddOns({
          RestService,
          SupplyService,
          pResponsePackagingAddOns,
          HandleError,
        })()

        return true
      } catch (error) {
        HandleError({ error, namespace: 'packagingAddOns', doFlash: true })

        return false
      }
    }
  }

export const DeletePackaging =
  ({
    LoadPackaging,
    RestService,
    SupplyService,
    UIService,
    pResponsePackaging,
    HandleError,
  }) =>
  async (id) => {
    const text = 'Are you sure you want to delete this packaging?'
    const doDelete = await UIService.ConfirmationModal.show({ text })
    if (doDelete) {
      try {
        await RestService.delete(`/api/admin/packaging/${id}`)
        await LoadPackaging({
          RestService,
          SupplyService,
          pResponsePackaging,
          HandleError,
        })()

        return true
      } catch (error) {
        HandleError({ error, namespace: 'packaging', doFlash: true })

        return false
      }
    }
  }

export const DeleteServingUtensil =
  ({
    LoadServingUtensils,
    RestService,
    SupplyService,
    UIService,
    pResponseServingUtensils,
    HandleError,
  }) =>
  async (id) => {
    const text = 'Are you sure you want to delete this serving utensil?'
    const doDelete = await UIService.ConfirmationModal.show({ text })
    if (doDelete) {
      try {
        await RestService.delete(`/api/admin/serving_utensils/${id}`)
        await LoadServingUtensils({
          RestService,
          SupplyService,
          pResponseServingUtensils,
          HandleError,
        })()

        return true
      } catch (error) {
        HandleError({ error, namespace: 'servingUtensils', doFlash: true })

        return false
      }
    }
  }

export const DeleteCateringSupply =
  ({
    LoadCateringSupplies,
    RestService,
    SupplyService,
    UIService,
    pResponseCateringSupplies,
    HandleError,
  }) =>
  async (id) => {
    const text = 'Are you sure you want to delete this catering supply?'
    const doDelete = await UIService.ConfirmationModal.show({ text })
    if (doDelete) {
      try {
        await RestService.delete(`/api/admin/catering_supplies/${id}`)
        await LoadCateringSupplies({
          RestService,
          SupplyService,
          pResponseCateringSupplies,
          HandleError,
        })()

        return true
      } catch (error) {
        HandleError({ error, namespace: 'cateringSupplies', doFlash: true })

        return false
      }
    }
  }

export const DeleteDiningSupply =
  ({
    LoadDiningSupplies,
    RestService,
    SupplyService,
    UIService,
    pResponseDiningSupplies,
    HandleError,
  }) =>
  async (id) => {
    const text = 'Are you sure you want to delete this catering supply?'
    const doDelete = await UIService.ConfirmationModal.show({ text })
    if (doDelete) {
      try {
        await RestService.delete(`/api/admin/dining_supplies/${id}`)
        await LoadDiningSupplies({
          RestService,
          SupplyService,
          pResponseDiningSupplies,
          HandleError,
        })()

        return true
      } catch (error) {
        HandleError({ error, namespace: 'diningSupplies', doFlash: true })

        return false
      }
    }
  }
