import React from 'react'
import { FlexContainer, YSpacing } from '@components/common'
import Button from '@components/common/form/Button'

interface BulkGenerateOrderableProps {
  title: string
  message: string
  example: string
  buttonDisabled: boolean
  buttonDisableMessage: string
  buttonLabel: string
  buttonAction: () => void
}

const BulkGenerateOrderable = ({
  title,
  message,
  example,
  buttonDisabled = false,
  buttonDisableMessage,
  buttonLabel,
  buttonAction,
}: BulkGenerateOrderableProps) => {
  return (
    <FlexContainer flexDirection="column" width="80%" marginLeft="30px">
      <h1 className="text-red-500 font-bold underline">{title}</h1>
      <YSpacing height="10px" />
      <p>{message}</p>
      <YSpacing height="10px" />
      <p>{example}</p>
      <YSpacing height="10px" />
      <YSpacing height="10px" />
      <FlexContainer flexDirection="column" alignItems="flex-start">
        <Button
          label={buttonLabel}
          backgroundColor="#66CC66"
          onClick={buttonAction}
          disabled={buttonDisabled}
        />
        {buttonDisabled && buttonDisableMessage && (
          <p className="text-red-500 font-bold">{buttonDisableMessage}</p>
        )}
      </FlexContainer>
    </FlexContainer>
  )
}

export default BulkGenerateOrderable
