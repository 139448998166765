import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import LoadingIndicator from '@components/common/LoadingIndicator'
import { AuthorizedInteractable } from '@containers/common/auth'
import { ButtonsBar } from '@res/styledComponents/index'
import { BiPencil } from 'react-icons/bi'

export class VirtualKits extends Component {
  constructor(props) {
    super(props)

    this.state = {
      virtualKits: props.virtualKits,
      virtualKit: null,
      editModalVisible: false,
      sortAsc: true,
    }
  }

  componentDidMount() {
    const { loadChefVirtualKits, chefId, virtualKits } = this.props
    if (!virtualKits) {
      loadChefVirtualKits(chefId)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { virtualKits } = nextProps
    if (virtualKits !== this.state.virtualKits) {
      this.setState({ virtualKits })
    }
  }

  newVirtualKit = () => {
    const { newVirtualKit } = this.props
    newVirtualKit()
  }

  editVirtualKit = (virtualKit) => {
    const { editVirtualKit } = this.props
    editVirtualKit(virtualKit)
  }

  sortVirtualKitsByString = (columnName) => {
    const { virtualKits, sortAsc } = this.state
    const getColumn = (kit) =>
      kit[columnName] ? kit[columnName].toLowerCase() : ''
    const sortedVirtualKits = virtualKits.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ virtualKits: sortedVirtualKits, sortAsc: !sortAsc })
  }

  sortVirtualKitsByNumber = (columnName) => {
    const { virtualKits, sortAsc } = this.state
    const getColumn = (item) =>
      item[columnName] ? parseFloat(item[columnName].replace(/[$,]/g, '')) : 0.0
    const sortedVirtualKits = virtualKits.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ virtualKits: sortedVirtualKits, sortAsc: !sortAsc })
  }

  sortVirtualKitsByChildNumber = () => {
    const { virtualKits, sortAsc } = this.state
    const numChildren = (kit) =>
      kit.virtualItems ? kit.virtualItems.length : 0
    const sortedVirtualKits = virtualKits.sort((a, b) => {
      const childrenInA = numChildren(a)
      const childrenInB = numChildren(b)

      return !sortAsc
        ? childrenInB > childrenInA
          ? 1
          : -1
        : childrenInA > childrenInB
          ? 1
          : -1
    })
    this.setState({ virtualKits: sortedVirtualKits, sortAsc: !sortAsc })
  }

  sortVirtualKitsByBoolean = (columnName) => {
    const { virtualKits, sortAsc } = this.state
    const getColumn = (item) => (item[columnName] === true ? 1 : 0)
    const sortedVirtualKits = virtualKits.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ virtualKits: sortedVirtualKits, sortAsc: !sortAsc })
  }

  render() {
    const { virtualKits } = this.state
    const areVirtualKitsLoaded = !!virtualKits
    const areVirtualKitsEmpty = virtualKits && virtualKits.length === 0

    return (
      <div>
        <ButtonsBar>
          <AuthorizedInteractable
            roles={['master admin', 'sales lead', 'sales rep', 'chef lead']}
          >
            <button
              onClick={(event) => {
                event.preventDefault()
                this.newVirtualKit()
              }}
            >
              <BiPencil />
              New Virtual Kit
            </button>
          </AuthorizedInteractable>
        </ButtonsBar>
        <Panel>
          <Table>
            <thead>
              <tr>
                <th onClick={() => this.sortVirtualItemsByString('name')}>
                  Item Name
                </th>
                <th onClick={() => this.sortVirtualItemsByNumber('cost')}>
                  Vendor Price
                </th>
                <th onClick={() => this.sortVirtualItemsByNumber('price')}>
                  Price
                </th>
                <th onClick={() => this.sortVirtualItemsByBoolean('isEnabled')}>
                  Live
                </th>
                <th
                  onClick={() => this.sortVirtualItemsByBoolean('isApproved')}
                >
                  Approved
                </th>
              </tr>
            </thead>
            <tbody>
              {!areVirtualKitsLoaded && (
                <tr>
                  <td colSpan="4">
                    <LoadingIndicator />
                  </td>
                </tr>
              )}

              {areVirtualKitsLoaded && areVirtualKitsEmpty && (
                <tr>
                  <td colSpan="5">
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '1.2em',
                        color: 'rgba(0,0,0,0.2)',
                        padding: '20px',
                        backgroundColor: 'rgba(0,0,0,0.01)',
                      }}
                    >
                      No virtual items yet.
                    </div>
                  </td>
                </tr>
              )}

              {areVirtualKitsLoaded &&
                virtualKits.map((virtualKit, i) => (
                  <tr
                    key={i}
                    className="clickable"
                    onClick={(e) => {
                      e.preventDefault()
                      this.editVirtualKit(virtualKit.id)
                    }}
                  >
                    <td>{virtualKit.name}</td>
                    <td>${parseFloat(virtualKit.cost).toFixed(2)}</td>
                    <td>${parseFloat(virtualKit.price).toFixed(2)}</td>
                    <td>
                      {virtualKit.isEnabled ? (
                        <span className="approved-check">✔</span>
                      ) : (
                        <span className="not-approved-x">✖</span>
                      )}
                    </td>
                    <td>
                      {virtualKit.isApproved ? (
                        <span className="approved-check">✔</span>
                      ) : (
                        <span className="not-approved-x">✖</span>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Panel>
      </div>
    )
  }
}

VirtualKits.propTypes = {
  chefId: PropTypes.string,
  virtualKits: PropTypes.arrayOf(PropTypes.object),

  delayedUpdateVirtualKit: PropTypes.func,
  editVirtualKit: PropTypes.func,
  loadChefVirtualKits: PropTypes.func,
  newVirtualKit: PropTypes.func,
}

export default VirtualKits
