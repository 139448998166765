import { BaseService } from 'hungry-core2'

export class PopUpClientService extends BaseService {
  newPopUpClient = ({ headquarter, defaultLocale = '' }) => ({
    id: undefined,
    headquarter,
    name: '',
    locations: [],
    prefix: '',
    defaultLocale,
    contacts: [],
  })

  newPopUpLocation = ({ locale }) => ({
    id: undefined,
    name: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    geolocation: undefined,
    locale,
  })

  newClientContact = () => ({
    id: undefined,
    name: '',
    email: '',
    phone: '',
  })
}
