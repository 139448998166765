import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class SubscriptionService extends BaseService {
  setMembers = (members) => {
    this.dispatch(actions.setSubscribers(members))
  }

  replaceMember = (member) => {
    this.dispatch(actions.replaceSubscriber(member))
  }

  setSubscriptions = (subscriptions) => {
    this.dispatch(actions.setSubscriptions(subscriptions))
  }

  replaceSubscription = (subscription) => {
    this.dispatch(actions.replaceSubscription(subscription))
  }
}
