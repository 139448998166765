import React, { Component } from 'react'
import { Tabs, Tab } from 'react-bootstrap'

import { SurveyDashboardList, SurveyList } from '@containers/survey'

export class SurveyPage extends Component {
  state = {
    activeKey: '#dashboard',
    orderNumber: undefined,
    selectedSurvey: undefined,
  }

  onSelectSurveyCell = (orderNumber, surveyName) => () => {
    this.setState({
      activeKey: '#list',
      orderNumber,
      selectedSurvey: surveyName,
    })
  }

  render() {
    const { activeKey, orderNumber, selectedSurvey } = this.state

    return (
      <div className="children-container__inner survey-page">
        <h2 className="page-title"> Surveys </h2>
        <div className="tabs-container">
          <Tabs
            className="tabs"
            activeKey={activeKey}
            onSelect={(key) => {
              this.setState({ activeKey: key })
            }}
            animation={false}
          >
            <Tab
              id="survey-tab-overview"
              eventKey={'#dashboard'}
              title="Survey Dashboard"
            >
              <SurveyDashboardList
                onSelectSurveyCell={this.onSelectSurveyCell}
              />
            </Tab>

            <Tab id="survey-tab-list" eventKey={'#list'} title="Survey List">
              <SurveyList
                orderNumber={orderNumber}
                selectedSurvey={selectedSurvey}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    )
  }
}

export default SurveyPage
