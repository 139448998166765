import { connect } from 'react-redux'

import { EditBatchOrderModal } from '@components/batchOrder/edit'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'
import { HandleError, Validate } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { mode, show } = state.editBatchOrderModal
  const errors = state.errors.editBatchOrderModal || {}
  const {
    id,
    account,
    accountExecutive,
    billingAddress,

    contact,

    paymentMethod,

    chargeDate,
    startDate,
    endDate,
    netOffset,
    orderNumber,
    purchaseOrderNumber,
    concept,

    orders,
    serviceFee,
    subtotal,
    tax,
    tip,
    total,

    originalOrderIds,
  } = mode === 'edit' ? state.editBatchOrder : state.newBatchOrder

  return {
    id,
    account,
    accountExecutive,
    billingAddress,

    contact,

    paymentMethod,

    chargeDate,
    startDate,
    endDate,
    netOffset,
    orderNumber,
    purchaseOrderNumber,
    concept,

    childOrders: orders,
    serviceFee,
    subtotal,
    tax,
    tip,
    total,

    originalOrderIds,

    errors,
    show,
  }
}

const mapDispatchToProps = () => {
  const {
    BatchOrderService,
    RestService,
    RouterService,
    SessionService,
    UIService,
  } = services
  const {
    AsyncLoadAccount,
    DelayedUpdateBatchOrder,
    HandleError: HandleError_,
    LoadAccountExecutives,
    LoadBatchOrders,
    SaveAdhocAccount,
    SaveAdhocAddress,
    SaveAdhocContact,
    SaveAdhocPaymentMethod,
    SaveBatchOrder,
    SelectBatchOrder,
  } = coordinators
  const {
    pContactKeys,
    pDefaultAccountFields,
    pFindIdentical,
    pFindIdenticalPaymentMethod,
    pRemovedItemFromArray,
    pStateToReduxOrder,
  } = presenters
  const {
    pRequestSaveAdhocAccount,
    pRequestSaveAdhocAddress,
    pRequestSaveAdhocContact,
    pRequestSaveAdhocPaymentMethod,
    pRequestBatchOrdersFilter,
    pRequestUpdateBatchOrder,
    pResponseAccountExecutives,
    pResponseGeneric,
    pResponseBatchOrder,
    pResponseBatchOrders,
    pResponseBatchOrderError,
  } = presenters.Api
  const { vAddress, vContact, vPaymentMethod } = presenters.Validation

  const {
    addOrderToChildOrders,
    calculateAllTotals,
    recalculateContactAndAccountExec,
    removeChildOrderFromOrders,
  } = BatchOrderService
  const { clearEditBatchOrder: clearForm } = BatchOrderService
  const { clear: clearErrors, clearSingle } = UIService.Errors
  const clearError = clearSingle('editBatchOrderModal')
  const { close } = UIService.EditBatchOrder
  const loadAccount = AsyncLoadAccount({
    RestService,
    pResponseGeneric,
    HandleError,
  })
  const loadAccountExecutives = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })
  const loadBatchOrders = LoadBatchOrders({
    UIService,
    BatchOrderService,
    RestService,
    pRequestBatchOrdersFilter,
    pResponseBatchOrders,
  })
  const delayedUpdateBatchOrder = DelayedUpdateBatchOrder({
    BatchOrderService,
    UIService,
    pStateToReduxOrder,
  })
  const selectBatchOrder = SelectBatchOrder({
    BatchOrderService,
    RestService,
    pResponseBatchOrder,
  })
  const saveAccount = SaveAdhocAccount({
    RestService,
    pRequestSaveAdhocAccount,
    pResponseGeneric,
    HandleError,
  })
  const saveBatchOrder = SaveBatchOrder({
    BatchOrderService,
    LoadBatchOrders: loadBatchOrders,
    SelectBatchOrder: selectBatchOrder,
    RestService,
    RouterService,
    SessionService,
    UIService,
    pRequestUpdateBatchOrder,
    pResponseBatchOrder,
    pResponseBatchOrderError,
    HandleError: HandleError_,
  })
  const saveAddress = SaveAdhocAddress({
    RestService,
    pFindIdentical,
    pRequestSaveAdhocAddress,
    pResponseGeneric,
    HandleError,
  })
  const saveContact = SaveAdhocContact({
    RestService,
    pFindIdentical,
    pRequestSaveAdhocContact,
    pResponseGeneric,
    HandleError,
  })
  const savePaymentMethod = SaveAdhocPaymentMethod({
    RestService,
    pFindIdenticalPaymentMethod,
    pRequestSaveAdhocPaymentMethod,
    pResponseGeneric,
    HandleError,
  })
  const validateAddress = Validate({
    validator: vAddress,
    namespace: 'editOrderModal',
  })
  const validateContact = Validate({
    validator: vContact,
    namespace: 'editBatchOrderModal',
  })
  const validatePaymentMethod = Validate({
    validator: vPaymentMethod,
    namespace: 'editBatchOrderModal',
  })

  return {
    addOrderToChildOrders,
    calculateAllTotals,
    clearError,
    clearErrors,
    clearForm,
    close,
    delayedUpdateBatchOrder,
    loadAccount,
    loadAccountExecutives,
    loadBatchOrders,
    pContactKeys,
    pDefaultAccountFields,
    pRemovedItemFromArray,
    recalculateContactAndAccountExec,
    removeChildOrderFromOrders,
    saveAccount,
    saveAddress,
    saveBatchOrder,
    saveContact,
    savePaymentMethod,
    validateAddress,
    validateContact,
    validatePaymentMethod,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBatchOrderModal)
