import React from 'react'
import PropTypes from 'prop-types'
import { FormInputContainer } from '@res/styledComponents/index'

const Textarea = (props) => {
  const {
    height,
    value,
    disabled,
    label,
    marginBottom,
    width,
    maxLength,
    onChange,
    placeholder,
  } = props

  return (
    <FormInputContainer width={width} marginBottom={marginBottom}>
      <label>{label}</label>
      <textarea
        name={label}
        disabled={disabled}
        maxLength={maxLength}
        style={{ cursor: disabled ? 'not-allowed' : 'auto', height }}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </FormInputContainer>
  )
}

Textarea.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.string,
  height: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.boolean,
  marginBottom: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,

  onChange: PropTypes.func,
}

Textarea.defaultProps = {
  disabled: false,
  marginBottom: '20px',
  width: '100%',
}

export default Textarea
