import { connect } from 'react-redux'

import Modals from '@components/Modals'

import services from '@services'

const mapStateToProps = (state) => {
  const {
    copyMenuItemModal,
    calcChefFeesModal,
    editAccountModal,
    editAccountContactModal,
    editBatchOrderModal,
    editChefModal,
    editChefContactModal,
    editChefPayoutsModal,
    editChildMenuItemModal,
    editDinerProfileModal,
    editDeliveryZoneModal,
    editHQModal,
    editMenuConceptModal,
    editMenuItemModal,
    editOrderModal,
    editProposalModal,
    editRecipeModal,
    editServiceDepotModal,
    editServiceItemModal,
    editSnackPackModal,
    editVirtualItemModal,
    editVirtualKitModal,
    editUserModal,
    editChildChefsModal,
    copyMenuItemsModal,
    auditHistoryModal,
    chefFutureOrdersModal,
    resultsMessageModal,
    searchViaHubSpotModal,
  } = state

  return {
    showCalcChefFeesModal: calcChefFeesModal.show,
    showCopyMenuItem: copyMenuItemModal.show,
    showAccount: editAccountModal.show,
    showAccountContact: editAccountContactModal.show,
    showAuditHistory: auditHistoryModal.show,
    showBatchOrder: editBatchOrderModal.show,
    showConcepts: editMenuConceptModal.show,
    showChefContact: editChefContactModal.show,
    showChef: editChefModal.show,
    showChefPayouts: editChefPayoutsModal.show,
    showChefFutureOrders: chefFutureOrdersModal.show,
    showChildMenuItem: editChildMenuItemModal.show,
    showDinerProfile: editDinerProfileModal.show,
    showDeliveryZone: editDeliveryZoneModal.show,
    showHQ: editHQModal.show,
    showMenuItem: editMenuItemModal.show,
    showOrder: editOrderModal.show,
    showProposal: editProposalModal.show,
    showRecipe: editRecipeModal.show,
    showResultsMessageModal: resultsMessageModal.show,
    showServiceDepot: editServiceDepotModal.show,
    showServiceItem: editServiceItemModal.show,
    showSnackPack: editSnackPackModal.show,
    showSearchViaHubSpotModal: searchViaHubSpotModal.show,
    showVirtualItem: editVirtualItemModal.show,
    showVirtualKit: editVirtualKitModal.show,
    showUser: editUserModal.show,
    showEditChildChefs: editChildChefsModal.show,
    showCopyMenuItems: copyMenuItemsModal.show,
  }
}

const mapDispatchToProps = () => {
  const { UIService } = services

  const { close, confirm } = UIService.ConfirmationModal

  return {
    close,
    confirm,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modals)
