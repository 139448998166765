import {
  TOGGLE_COPY_MENU_ITEM_MODAL,
  TOGGLE_EDIT_CHEF_MODAL,
  TOGGLE_EDIT_CHILD_CHEFS_MODAL,
  TOGGLE_EDIT_MENU_CONCEPT_MODAL,
  TOGGLE_EDIT_MENU_ITEM_MODAL,
  TOGGLE_EDIT_CHILD_MENU_ITEM_MODAL,
  TOGGLE_EDIT_CHEF_CONTACT_MODAL,
  TOGGLE_EDIT_RECIPE_MODAL,
  TOGGLE_EDIT_SERVICE_ITEM_MODAL,
  TOGGLE_EDIT_SNACK_PACK_MODAL,
  TOGGLE_EDIT_VIRTUAL_ITEM_MODAL,
  TOGGLE_EDIT_VIRTUAL_KIT_MODAL,
  TOGGLE_EDIT_CHEF_LOADING,
  TOGGLE_COPY_MENU_ITEMS_MODAL,
  TOGGLE_DISPLAY_CHEF_FUTURE_ORDERS_MODAL,
} from '@actions/ui/chefPage'

const initialModalState = { show: false, isLoading: false }
const initialCopyModalState = { show: false, menuItemId: undefined }

export const copyMenuItemModal = (state = initialCopyModalState, action) => {
  switch (action.type) {
    case TOGGLE_COPY_MENU_ITEM_MODAL: {
      const { show, menuItemId } = action

      return { show, menuItemId }
    }
    default:
      return state
  }
}

export const editChefModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_CHEF_MODAL:
      return {
        ...state,
        show: action.show,
      }
    case TOGGLE_EDIT_CHEF_LOADING:
      return {
        ...state,
        isLoading: action.on,
      }
    default:
      return state
  }
}

export const editChildChefsModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_CHILD_CHEFS_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}

export const editMenuConceptModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_MENU_CONCEPT_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}

export const editMenuItemModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_MENU_ITEM_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}

export const editChildMenuItemModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_CHILD_MENU_ITEM_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}

export const editRecipeModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_RECIPE_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}

export const editServiceItemModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_SERVICE_ITEM_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}

export const editSnackPackModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_SNACK_PACK_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}

export const editVirtualItemModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_VIRTUAL_ITEM_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}

export const editVirtualKitModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_VIRTUAL_KIT_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}

export const editChefContactModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_CHEF_CONTACT_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}

export const copyMenuItemsModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_COPY_MENU_ITEMS_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}

export const chefFutureOrdersModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_DISPLAY_CHEF_FUTURE_ORDERS_MODAL:
      return {
        ...state,
        show: action.show,
      }
    default:
      return state
  }
}
