import { BaseService } from 'hungry-core2'

export class PopUpDiscountService extends BaseService {
  newDiscountLedger = () => ({
    id: undefined,
    name: '',
    description: '',
    enabled: true,
    start: undefined,
    end: undefined,
    orderTypes: ['Pop-Up'],
    headquarters: [],
    clientIds: [],
    customerIds: [],
    popUpIds: [],
    availableForGuests: false,
    totalUsageLimit: undefined,
    totalDollarLimit: undefined,
    perCustomerLimit: 1,
    perCustomerDollarLimit: undefined,
    perOrderLimit: 1,
    orderMinimum: undefined,
    value: 0.0,
    discountType: 'Fixed',
  })
}
