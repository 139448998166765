import { connect } from 'react-redux'

import { AccountSection } from '@components/order/edit/sections'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'

const mapDispatchToProps = () => {
  const { RestService } = services
  const { AsyncLoadAccounts } = coordinators
  const { pResponseGeneric } = presenters.Api

  const loadAccounts = AsyncLoadAccounts({ RestService, pResponseGeneric })

  return { loadAccounts }
}

export default connect(undefined, mapDispatchToProps)(AccountSection)
