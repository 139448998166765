export const TOGGLE_EDIT_CHEF_MODAL = 'TOGGLE_EDIT_CHEF_MODAL'
export const TOGGLE_EDIT_CHILD_CHEFS_MODAL = 'TOGGLE_EDIT_CHILD_CHEFS_MODAL'
export const TOGGLE_EDIT_MENU_CONCEPT_MODAL = 'TOGGLE_EDIT_MENU_CONCEPT_MODAL'
export const TOGGLE_EDIT_MENU_ITEM_MODAL = 'TOGGLE_EDIT_MENU_ITEM_MODAL'
export const TOGGLE_EDIT_CHILD_MENU_ITEM_MODAL =
  'TOGGLE_EDIT_CHILD_MENU_ITEM_MODAL'
export const TOGGLE_EDIT_CHEF_CONTACT_MODAL = 'TOGGLE_EDIT_CHEF_CONTACT_MODAL'
export const TOGGLE_EDIT_RECIPE_MODAL = 'TOGGLE_EDIT_RECIPE_MODAL'
export const TOGGLE_EDIT_SERVICE_ITEM_MODAL = 'TOGGLE_EDIT_SERVICE_ITEM_MODAL'
export const TOGGLE_EDIT_SNACK_PACK_MODAL = 'TOGGLE_EDIT_SNACK_PACK_MODAL'
export const TOGGLE_EDIT_VIRTUAL_ITEM_MODAL = 'TOGGLE_EDIT_VIRTUAL_ITEM_MODAL'
export const TOGGLE_EDIT_VIRTUAL_KIT_MODAL = 'TOGGLE_EDIT_VIRTUAL_KIT_MODAL'
export const TOGGLE_COPY_MENU_ITEM_MODAL = 'TOGGLE_COPY_MENU_ITEM_MODAL'
export const TOGGLE_EDIT_CHEF_LOADING = 'TOGGLE_CHEF_LOADING'
export const TOGGLE_COPY_MENU_ITEMS_MODAL = 'TOGGLE_COPY_MENU_ITEMS_MODAL'
export const TOGGLE_DISPLAY_CHEF_FUTURE_ORDERS_MODAL =
  'TOGGLE_DISPLAY_CHEF_FUTURE_ORDERS_MODAL'

export const toggleCopyMenuItemModal = (show, menuItemId) => ({
  type: TOGGLE_COPY_MENU_ITEM_MODAL,
  show,
  menuItemId,
})
export const toggleEditChefModal = (show) => ({
  type: TOGGLE_EDIT_CHEF_MODAL,
  show,
})
export const toggleEditChildChefsModal = (show) => ({
  type: TOGGLE_EDIT_CHILD_CHEFS_MODAL,
  show,
})
export const toggleEditMenuConceptModal = (show) => ({
  type: TOGGLE_EDIT_MENU_CONCEPT_MODAL,
  show,
})
export const toggleEditMenuItemModal = (show) => ({
  type: TOGGLE_EDIT_MENU_ITEM_MODAL,
  show,
})
export const toggleEditChildMenuItemModal = (show) => ({
  type: TOGGLE_EDIT_CHILD_MENU_ITEM_MODAL,
  show,
})
export const toggleEditChefContactModal = (show) => ({
  type: TOGGLE_EDIT_CHEF_CONTACT_MODAL,
  show,
})
export const toggleEditRecipeModal = (show) => ({
  type: TOGGLE_EDIT_RECIPE_MODAL,
  show,
})
export const toggleEditServiceItemModal = (show) => ({
  type: TOGGLE_EDIT_SERVICE_ITEM_MODAL,
  show,
})
export const toggleEditSnackPackModal = (show) => ({
  type: TOGGLE_EDIT_SNACK_PACK_MODAL,
  show,
})
export const toggleEditVirtualItemModal = (show) => ({
  type: TOGGLE_EDIT_VIRTUAL_ITEM_MODAL,
  show,
})
export const toggleEditVirtualKitModal = (show) => ({
  type: TOGGLE_EDIT_VIRTUAL_KIT_MODAL,
  show,
})
export const toggleEditChefLoading = (on) => ({
  type: TOGGLE_EDIT_CHEF_LOADING,
  on,
})
export const toggleCopyMenuItemsModal = (show) => ({
  type: TOGGLE_COPY_MENU_ITEMS_MODAL,
  show,
})

export const toggleDisplayChefFutureOrdersModal = (show) => ({
  type: TOGGLE_DISPLAY_CHEF_FUTURE_ORDERS_MODAL,
  show,
})
