import React, { useState, useEffect, ChangeEvent } from 'react'
import { BiTrash } from 'react-icons/bi'

import Modal from '@components/common/modal/Modal'
import { YSpacing, FlexContainer } from '@components/common'
import { Button, Dropdown, Checkbox } from '@components/common/form'
import { AutocompleteInput } from '@containers/common/form'
import { Chef, IndexHeadquarter } from '@types'

interface CopyMenuItemsModalProps {
  chef: Chef
  close: () => void
  loadHqs: () => Promise<IndexHeadquarter[]>
  loadChefs: (filters: { search?: string; status: 'active' }) => Promise<void>
  searchMenuItems: (filters: {
    chefIds: string[]
    search: any
    page: number | undefined
    resultsPerPage: number | undefined
    marketTypes: string[] | undefined
    hqs: number[] | undefined
    isApproved: string | undefined
    chefStatus: string | undefined
    excludingChefIds: string[] | undefined
  }) => Promise<MenuItem[]>
  copyMenuItems: (req: {
    chefId: string
    copyItems: { [key: string]: string[] }
  }) => Promise<
    | { successes: string[] | null; errors: { [key: string]: string[] } }
    | undefined
  >
  displaySuccessMessage: (message: string) => void
  displayFailureMessage: (message: string) => void
}

interface SearchFilters {
  excludingChefIds?: string[]
  hq?: number | null
}

interface MenuItem {
  id: string
  name: string
}

const CopyMenuItemsModal: React.FC<CopyMenuItemsModalProps> = ({
  chef,
  close,
  loadHqs,
  loadChefs,
  searchMenuItems,
  copyMenuItems,
  displaySuccessMessage,
  displayFailureMessage,
}) => {
  const [hqs, setHqs] = useState<IndexHeadquarter[]>([])
  const [selectedHq, setSelectedHq] = useState<number | undefined>(undefined)
  const [filters, setFilters] = useState<SearchFilters>({})
  const [selectedChefs, setSelectedChefs] = useState<
    { id: string; name: string }[]
  >([])
  const [copyItemsMap, setCopyItemsMap] = useState<{
    [key: string]: MenuItem[]
  }>({})
  const [chefsCopyingAll, setChefsCopyingAll] = useState<{
    [key: string]: boolean
  }>({})
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      const hqs = await loadHqs()
      setHqs(hqs)
    })()

    setFilters({
      excludingChefIds: [chef.id],
    })
  }, [loadHqs, chef.id])

  const onChangeHq = (e: ChangeEvent<HTMLSelectElement>) => {
    const hq = parseInt(e.target.value)
    if (!hq) {
      setSelectedHq(undefined)

      const newFilters = { ...filters }
      delete newFilters.hq

      setFilters(newFilters)
    } else {
      setSelectedHq(hq)
      setFilters({ ...filters, hq })
    }
  }

  const onSelectChef = ({ id, name }: Chef) => {
    setSelectedChefs([...selectedChefs, { id, name }])
    setFilters({
      ...filters,
      excludingChefIds: [chef.id, ...selectedChefs.map((c) => c.id)],
    })
    const newCopyMap = { ...copyItemsMap }
    newCopyMap[id] = []
    setCopyItemsMap(newCopyMap)
  }

  const onRemoveChef = (id: string) => {
    const newSelectedChefs = selectedChefs.filter((c) => c.id !== id)
    setSelectedChefs(newSelectedChefs)
    setFilters({
      ...filters,
      excludingChefIds: [chef.id, ...newSelectedChefs.map((c) => c.id)],
    })
    const newCopyMap = { ...copyItemsMap }
    delete newCopyMap[id]
    setCopyItemsMap(newCopyMap)
  }

  const onSelectMenuItem = (chefId: string) => (item: MenuItem) => {
    const newCopyMap = { ...copyItemsMap }
    newCopyMap[chefId].push(item)
    setCopyItemsMap(newCopyMap)
  }

  const onRemoveMenuItem = (chefId: string) => (itemId: string) => {
    const newCopyMap = { ...copyItemsMap }
    const idx = newCopyMap[chefId].findIndex((i) => i.id === itemId)
    if (idx !== -1) {
      newCopyMap[chefId].splice(idx, 1)
    }
    setCopyItemsMap(newCopyMap)
  }

  const onSave = async () => {
    setLoading(true)

    const copyItems = Object.keys(copyItemsMap)
      .filter((chefId) => {
        if (chefsCopyingAll[chefId]) {
          return true
        } else if (copyItemsMap[chefId].length > 0) {
          return true
        }

        return false
      })
      .reduce(
        (acc, chefId) => ({
          ...acc,
          [chefId]: chefsCopyingAll[chefId]
            ? []
            : copyItemsMap[chefId].map((i) => i.id),
        }),
        {},
      )

    const messages = await copyMenuItems({
      chefId: chef.id,
      copyItems,
    })
    if (messages) {
      // if either are empty, rails sends null
      const { successes, errors } = messages
      if (successes) {
        const items =
          successes.length > 5
            ? `${successes.slice(0, 5).join(', ')} and ${
                successes.length - 5
              } others successfully copied`
            : `${successes.join(', ')} successfully copied`

        displaySuccessMessage(items)
      }
      if (errors) {
        Object.keys(errors).forEach((itemName) => {
          displayFailureMessage(
            `${itemName} copied, but images were not: ${errors[itemName].join(
              ', ',
            )}`,
          )
        })
      }
    }
    setLoading(false)
    close()
  }

  const onChangeCopyAll = (chefId: string) => {
    const newChefsCopyingAll = { ...chefsCopyingAll }
    if (newChefsCopyingAll[chefId]) {
      delete newChefsCopyingAll[chefId]
    } else {
      newChefsCopyingAll[chefId] = true
      const newCopyMap = { ...copyItemsMap }
      newCopyMap[chefId] = []
      setCopyItemsMap(newCopyMap)
    }
    setChefsCopyingAll(newChefsCopyingAll)
  }

  const renderSelectedChef = (chef: Chef) => {
    return (
      <div>
        <div className="flex flex-row items-center mb-10">
          <BiTrash
            size={20}
            className="cursor-pointer"
            onClick={() => onRemoveChef(chef.id)}
          />
          <h3 className="ml-2 font-semibold">
            Copy menu items from {chef.name}
          </h3>
        </div>

        <FlexContainer flexDirection="row">
          <FlexContainer flexDirection="column" width="40%">
            <Checkbox
              label="Copy All"
              checked={chefsCopyingAll[chef.id] || false}
              onChange={() => onChangeCopyAll(chef.id)}
              marginTop="10px"
              marginBottom="10px"
            />
            <h3 className="ml-2 font-semibold">
              Chefs with 100+ items will timeout. Please ask the Tech Team for
              help copying large chefs.
            </h3>
          </FlexContainer>

          <FlexContainer flexDirection="column" width="20%" margin="4px">
            <h3 className="ml-2 font-semibold uppercase">OR</h3>
          </FlexContainer>

          {!chefsCopyingAll[chef.id] ? (
            <FlexContainer flexDirection="column" width="40%">
              <AutocompleteInput
                label="Select Specific Menu Items"
                width="100%"
                displayAttribute="name"
                loaderFunction={(search) =>
                  searchMenuItems({
                    ...search,
                    chefIds: [chef.id],
                    page: undefined,
                    resultsPerPage: undefined,
                    marketTypes: [],
                    hqs: filters.hq ? [filters.hq] : [],
                    isApproved: '',
                    chefStatus: '',
                    excludingChefIds: filters.excludingChefIds,
                  })
                }
                onSelect={onSelectMenuItem(chef.id)}
              />
              <YSpacing height="10px" />
              {((copyItemsMap[chef.id] as MenuItem[]) || []).map((item) => (
                <div key={item.id} className="flex flex-row items-center">
                  <BiTrash
                    size={20}
                    className="cursor-pointer"
                    onClick={() => onRemoveMenuItem(chef.id)(item.id)}
                  />
                  <h3 className="ml-2 font-semibold">{item.name}</h3>
                </div>
              ))}
            </FlexContainer>
          ) : (
            <h3 className="ml-2 font-semibold uppercase">
              Select Specific Menu Items
            </h3>
          )}
        </FlexContainer>
      </div>
    )
  }

  return (
    <Modal
      hideModal={close}
      title={`Copy Menu Items to ${chef.name}`}
      height="700px"
    >
      <FlexContainer flexDirection="column">
        <Dropdown
          label="Select Headquarter"
          width="30%"
          value={selectedHq}
          onChange={onChangeHq}
        >
          <option value={undefined}>- Select Headquarter -</option>
          {hqs.map((hq, idx) => (
            <option key={`${hq.id}-${idx}`} value={hq.id}>
              {hq.name}
            </option>
          ))}
        </Dropdown>
        <AutocompleteInput
          label="Search Chefs"
          width="30%"
          displayAttribute="name"
          loaderFunction={(search) =>
            loadChefs({ ...search, status: 'active' })
          }
          onSelect={onSelectChef}
          filters={filters}
        />
      </FlexContainer>
      <YSpacing height="20px" />
      {selectedChefs.map((c) => renderSelectedChef(c))}
      <div className="flex justify-center items-center mt-3">
        <Button
          onClick={onSave}
          disabled={loading}
          label={loading ? 'Saving...' : 'Save'}
        />
      </div>
    </Modal>
  )
}

export default CopyMenuItemsModal
