import { connect } from 'react-redux'

import { Overview } from '@components/proposal/proposalSections'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'
import { HandleError as HandleErrorProposalToOrder } from '@coordinators/errors'
import { config } from 'hungry-core2'

const mapStateToProps = (state) => {
  const { user = {}, router } = state
  const { lastLoggedInHq, permissions } = user
  const userPermissions = permissions[lastLoggedInHq]
  const port = router.location.port === 3000 ? 3001 : 3000
  const uri =
    config.admin_url +
    (config.admin_url === 'http://localhost' ? `:${port}` : '')

  return {
    userPermissions,
    uri,
  }
}

const mapDispatchToProps = () => {
  const { OrderService, ProposalService, RestService, UIService } = services
  const {
    ChangeProposalStatus,
    CopyProposal,
    DeleteProposal,
    EditProposal,
    ProposalToOrder,
    LoadDinerProfile,
  } = coordinators
  const { pResponseEditProposal, pResponseProposal, pResponseOrderError } =
    presenters.Api
  const { pResponseErrorsArray } = presenters.Errors
  const { pAccountSettingsFromAccount, pAccountSettingsFromOrderable } =
    presenters

  const {
    calculateDiscounts,
    calculateGrossMargin,
    getDeliveryFeePercentAndLimit,
  } = OrderService

  const flashError = (message) =>
    UIService.FlashMessage.displayFailureMessage(message)
  const flashInfoOptions = UIService.FlashMessage.displayInfoMessageOptions
  const flashWarningOptions =
    UIService.FlashMessage.displayWarningMessageOptions
  const changeProposalStatus = ChangeProposalStatus({
    ProposalService,
    RestService,
    pResponseProposal,
  })
  const createOrder = ProposalToOrder({
    ProposalService,
    RestService,
    UIService,
    pResponseErrorsArray,
    HandleError: HandleErrorProposalToOrder,
  })
  const copyProposal = CopyProposal({
    OrderService,
    ProposalService,
    RestService,
    UIService,
    pAccountSettingsFromAccount,
    pAccountSettingsFromOrderable,
    pResponseEditProposal,
    HandleError,
  })
  const deleteProposal = DeleteProposal({
    ProposalService,
    RestService,
    UIService,
    pResponseProposalError: pResponseOrderError,
    HandleError,
  })
  const editProposal = EditProposal({
    OrderService,
    ProposalService,
    RestService,
    UIService,
    pResponseEditProposal,
  })
  const loadDinerProfile = LoadDinerProfile({
    RestService,
    HandleError,
  })

  return {
    flashError,
    calculateDiscounts,
    calculateGrossMargin,
    changeProposalStatus,
    copyProposal,
    createOrder,
    deleteProposal,
    editProposal,
    getDeliveryFeePercentAndLimit,
    flashInfoOptions,
    flashWarningOptions,
    loadDinerProfile,
    pResponseEditProposal,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview)
