import { connect } from 'react-redux'

import GroupOrderSchedulePlanner from '@components/groupOrders/SchedulePlanner/GroupOrderSchedulePlanner'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state: any) => {
  const { user } = state
  const { lastHeadquarterSignIn } = user || {}

  return {
    hq: lastHeadquarterSignIn,
  }
}

const mapDispatchToProps = () => {
  const { RestService } = services
  const { SearchGOMenus } = coordinators
  const { pRequestSearchGOMenusParams } = presenters.Api

  const searchGOMenus = SearchGOMenus({
    RestService,
    pRequestSearchGOMenusParams,
    HandleError,
  })

  return {
    searchGOMenus,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupOrderSchedulePlanner)
