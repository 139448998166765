import { SET_RECEIPT } from '@actions/receipt'

const initialState = {
  order_items: [],
  customer: null,
  event_location: null,
  amount_due: 0,
  total: 0,
  id: '',
  subtotal: 0,
  service_fee: 0,
  tax: 0,
  discounts_description: '',
  order_number: '',
  account_name: '',
}

export const receipt = (state = initialState, action) => {
  const { type, receipt } = action
  switch (type) {
    case SET_RECEIPT:
      return { ...state, ...receipt }
    default:
      return state
  }
}
