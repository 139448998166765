export const SET_PROMO_CODES = 'SET_PROMO_CODES'
export const SET_PROMO_CODE_ATTRIBUTES = 'SET_PROMO_CODE_ATTRIBUTES'

export const setPromoCodes = (promoCodes) => ({
  type: SET_PROMO_CODES,
  promoCodes,
})

// TODO: use settings coordinator?
export const setPromoCodeAttributes = (promoCodeAttributes) => ({
  type: SET_PROMO_CODE_ATTRIBUTES,
  promoCodeAttributes,
})
