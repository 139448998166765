export const CLEAR_ERROR = 'CLEAR_ERROR'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const CLEAR_NAMESPACE = 'CLEAR_NAMESPACE'
export const SHOW_ERROR_ALERT = 'SHOW_ERROR_ALERT'
export const UPDATE_ERRORS = 'UPDATE_ERRORS'

export const clearError = ({ key, namespace }) => ({
  type: CLEAR_ERROR,
  key,
  namespace,
})

export const clearNamespace = ({ namespace }) => ({
  type: CLEAR_NAMESPACE,
  namespace,
})

export const clearErrors = () => ({ type: CLEAR_ERRORS })

export const updateErrors = ({ errors, namespace }) => ({
  type: UPDATE_ERRORS,
  errors,
  namespace,
})
