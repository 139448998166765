// accountStats reducer
export const SET_ACCOUNT_AVG_MARGIN = 'SET_ACCOUNT_AVG_MARGIN'
export const SET_COMPARE_TO_ACCOUNT_AVG_MARGIN =
  'SET_COMPARE_TO_ACCOUNT_AVG_MARGIN'
export const SET_ACCOUNT_AVG_ORDER_TOTAL = 'SET_ACCOUNT_AVG_ORDER_TOTAL'
export const SET_COMPARE_TO_ACCOUNT_AVG_ORDER_TOTAL =
  'SET_COMPARE_TO_ACCOUNT_AVG_ORDER_TOTAL'
export const SET_ACCOUNT_TOTAL_ORDERS = 'SET_ACCOUNT_TOTAL_ORDERS'
export const SET_COMPARE_TO_ACCOUNT_TOTAL_ORDERS =
  'SET_COMPARE_TO_ACCOUNT_TOTAL_ORDERS'
export const SET_ACCOUNT_TOTAL_SALES = 'SET_ACCOUNT_TOTAL_SALES'
export const SET_COMPARE_TO_ACCOUNT_TOTAL_SALES =
  'SET_COMPARE_TO_ACCOUNT_TOTAL_SALES'
export const SET_ACCOUNT_TOTAL_SALES_CHART_DATA =
  'SET_ACCOUNT_TOTAL_SALES_CHART_DATA'
export const SET_ACCOUNT_AVG_ORDER_TOTALS_CHART_DATA =
  'SET_ACCOUNT_AVG_ORDER_TOTALS_CHART_DATA'
export const SET_ACCOUNT_TOTAL_ORDERS_CHART_DATA =
  'SET_ACCOUNT_TOTAL_ORDERS_CHART_DATA'
export const SET_ACCOUNT_AVG_MARGINS_CHART_DATA =
  'SET_ACCOUNT_AVG_MARGINS_CHART_DATA'
export const SET_TOP_3_MENUS_FOR_ACCOUNT = 'SET_TOP_3_MENUS_FOR_ACCOUNT'
export const SET_COMPARE_TO_TOP_3_MENUS_FOR_ACCOUNT =
  'SET_COMPARE_TO_TOP_3_MENUS_FOR_ACCOUNT'

export const setAccountAvgMargin = (avgMargin) => ({
  type: SET_ACCOUNT_AVG_MARGIN,
  avgMargin,
})
export const setCompareToAccountAvgMargin = (avgMargin) => ({
  type: SET_COMPARE_TO_ACCOUNT_AVG_MARGIN,
  avgMargin,
})
export const setAccountAvgOrderTotal = (avgOrderTotal) => ({
  type: SET_ACCOUNT_AVG_ORDER_TOTAL,
  avgOrderTotal,
})
export const setCompareToAccountAvgOrderTotal = (avgOrderTotal) => ({
  type: SET_COMPARE_TO_ACCOUNT_AVG_ORDER_TOTAL,
  avgOrderTotal,
})
export const setAccountTotalOrders = (totalOrders) => ({
  type: SET_ACCOUNT_TOTAL_ORDERS,
  totalOrders,
})
export const setCompareToAccountTotalOrders = (totalOrders) => ({
  type: SET_COMPARE_TO_ACCOUNT_TOTAL_ORDERS,
  totalOrders,
})
export const setAccountTotalSales = (totalSales) => ({
  type: SET_ACCOUNT_TOTAL_SALES,
  totalSales,
})
export const setCompareToAccountTotalSales = (totalSales) => ({
  type: SET_COMPARE_TO_ACCOUNT_TOTAL_SALES,
  totalSales,
})
export const setAccountTotalSalesChartData = (totalSalesChartData) => ({
  type: SET_ACCOUNT_TOTAL_SALES_CHART_DATA,
  totalSalesChartData,
})
export const setAccountAvgOrderTotalsChartData = (avgOrderTotalsChartData) => ({
  type: SET_ACCOUNT_AVG_ORDER_TOTALS_CHART_DATA,
  avgOrderTotalsChartData,
})
export const setAccountTotalOrdersChartData = (totalOrdersChartData) => ({
  type: SET_ACCOUNT_TOTAL_ORDERS_CHART_DATA,
  totalOrdersChartData,
})
export const setAccountAvgMarginsChartData = (avgMarginsChartData) => ({
  type: SET_ACCOUNT_AVG_MARGINS_CHART_DATA,
  avgMarginsChartData,
})
export const setTop3MenusForAccount = (top3Menus) => ({
  type: SET_TOP_3_MENUS_FOR_ACCOUNT,
  top3Menus,
})
export const setCompareToTop3MenusForAccount = (top3Menus) => ({
  type: SET_COMPARE_TO_TOP_3_MENUS_FOR_ACCOUNT,
  top3Menus,
})

// nationalStats reducer
export const SET_MARKET_OVERVIEW_STATS = 'SET_MARKET_OVERVIEW_STATS'
export const SET_NATIONAL_AVG_MARGIN = 'SET_NATIONAL_AVG_MARGIN'
export const SET_NATIONAL_AVG_ORDER_TOTAL = 'SET_NATIONAL_AVG_ORDER_TOTAL'
export const SET_TOP_ACCOUNTS_PER_MARKET = 'SET_TOP_ACCOUNTS_PER_MARKET'
export const SET_TOP_MENUS_PER_MARKET = 'SET_TOP_MENUS_PER_MARKET'
export const SET_TOP_REPS_PER_MARKET = 'SET_TOP_REPS_PER_MARKET'
export const SET_NATIONAL_TOTAL_ORDERS = 'SET_NATIONAL_TOTAL_ORDERS'
export const SET_NATIONAL_TOTAL_SALES = 'SET_NATIONAL_TOTAL_SALES'
export const SET_NATIONAL_TOTAL_SALES_CHART_DATA =
  'SET_NATIONAL_TOTAL_SALES_CHART_DATA'
export const SET_NATIONAL_TOTAL_ORDERS_CHART_DATA =
  'SET_NATIONAL_TOTAL_ORDERS_CHART_DATA'
export const SET_NATIONAL_AVG_MARGINS_CHART_DATA =
  'SET_NATIONAL_AVG_MARGINS_CHART_DATA'
export const SET_NATIONAL_AVG_ORDER_TOTALS_CHART_DATA =
  'SET_NATIONAL_AVG_ORDER_TOTALS_CHART_DATA'

export const setMarketOverviewStats = (stats) => ({
  type: SET_MARKET_OVERVIEW_STATS,
  stats,
})
export const setNationalAvgMargin = (avgMargin) => ({
  type: SET_NATIONAL_AVG_MARGIN,
  avgMargin,
})
export const setNationalAvgOrderTotal = (avgOrderTotal) => ({
  type: SET_NATIONAL_AVG_ORDER_TOTAL,
  avgOrderTotal,
})
export const setTopAccountsPerMarket = (topAccountsPerMarket) => ({
  type: SET_TOP_ACCOUNTS_PER_MARKET,
  topAccountsPerMarket,
})
export const setTopMenusPerMarket = (topMenusPerMarket) => ({
  type: SET_TOP_MENUS_PER_MARKET,
  topMenusPerMarket,
})
export const setTopRepsPerMarket = (topRepsPerMarket) => ({
  type: SET_TOP_REPS_PER_MARKET,
  topRepsPerMarket,
})
export const setNationalTotalOrders = (totalOrders) => ({
  type: SET_NATIONAL_TOTAL_ORDERS,
  totalOrders,
})
export const setNationalTotalSales = (totalSales) => ({
  type: SET_NATIONAL_TOTAL_SALES,
  totalSales,
})
export const setNationalTotalSalesChartData = (totalSalesChartData) => ({
  type: SET_NATIONAL_TOTAL_SALES_CHART_DATA,
  totalSalesChartData,
})
export const setNationalTotalOrdersChartData = (totalOrdersChartData) => ({
  type: SET_NATIONAL_TOTAL_ORDERS_CHART_DATA,
  totalOrdersChartData,
})
export const setNationalAvgMarginsChartData = (avgMarginsChartData) => ({
  type: SET_NATIONAL_AVG_MARGINS_CHART_DATA,
  avgMarginsChartData,
})
export const setNationalAvgOrderTotalsChartData = (
  avgOrderTotalsChartData,
) => ({
  type: SET_NATIONAL_AVG_ORDER_TOTALS_CHART_DATA,
  avgOrderTotalsChartData,
})

// marketStats reducer
export const SET_MARKET_AVG_MARGIN = 'SET_MARKET_AVG_MARGIN'
export const SET_MARKET_AVG_ORDER_TOTAL = 'SET_MARKET_AVG_ORDER_TOTAL'
export const SET_SALES_REP_DETAILS = 'SET_SALES_REP_DETAILS'
export const SET_TOP_3_ACCOUNTS_IN_MARKET = 'SET_TOP_3_ACCOUNTS_IN_MARKET'
export const SET_TOP_3_MENUS_IN_MARKET = 'SET_TOP_3_MENUS_IN_MARKET'
export const SET_TOP_3_REPS_IN_MARKET = 'SET_TOP_3_REPS_IN_MARKET'
export const SET_MARKET_TOTAL_ORDERS = 'SET_MARKET_TOTAL_ORDERS'
export const SET_MARKET_TOTAL_SALES = 'SET_MARKET_TOTAL_SALES'
export const SET_MARKET_TOTAL_SALES_CHART_DATA =
  'SET_MARKET_TOTAL_SALES_CHART_DATA'
export const SET_MARKET_TOTAL_ORDERS_CHART_DATA =
  'SET_MARKET_TOTAL_ORDERS_CHART_DATA'
export const SET_MARKET_AVG_MARGINS_CHART_DATA =
  'SET_MARKET_AVG_MARGINS_CHART_DATA'
export const SET_MARKET_AVG_ORDER_TOTALS_CHART_DATA =
  'SET_MARKET_AVG_ORDER_TOTALS_CHART_DATA'

export const setMarketAvgMargin = (avgMargin) => ({
  type: SET_MARKET_AVG_MARGIN,
  avgMargin,
})
export const setMarketAvgOrderTotal = (avgOrderTotal) => ({
  type: SET_MARKET_AVG_ORDER_TOTAL,
  avgOrderTotal,
})
export const setSalesRepDetails = (salesRepDetails) => ({
  type: SET_SALES_REP_DETAILS,
  salesRepDetails,
})
export const setTop3AccountsInMarket = (top3Accounts) => ({
  type: SET_TOP_3_ACCOUNTS_IN_MARKET,
  top3Accounts,
})
export const setTop3MenusInMarket = (top3Menus) => ({
  type: SET_TOP_3_MENUS_IN_MARKET,
  top3Menus,
})
export const setTop3RepsInMarket = (top3Reps) => ({
  type: SET_TOP_3_REPS_IN_MARKET,
  top3Reps,
})
export const setMarketTotalOrders = (totalOrders) => ({
  type: SET_MARKET_TOTAL_ORDERS,
  totalOrders,
})
export const setMarketTotalSales = (totalSales) => ({
  type: SET_MARKET_TOTAL_SALES,
  totalSales,
})
export const setMarketTotalSalesChartData = (totalSalesChartData) => ({
  type: SET_MARKET_TOTAL_SALES_CHART_DATA,
  totalSalesChartData,
})
export const setMarketTotalOrdersChartData = (totalOrdersChartData) => ({
  type: SET_MARKET_TOTAL_ORDERS_CHART_DATA,
  totalOrdersChartData,
})
export const setMarketAvgMarginsChartData = (avgMarginsChartData) => ({
  type: SET_MARKET_AVG_MARGINS_CHART_DATA,
  avgMarginsChartData,
})
export const setMarketAvgOrderTotalsChartData = (avgOrderTotalsChartData) => ({
  type: SET_MARKET_AVG_ORDER_TOTALS_CHART_DATA,
  avgOrderTotalsChartData,
})

// salesRepStats reducer
export const SET_REP_AVG_MARGIN = 'SET_REP_AVG_MARGIN'
export const SET_COMPARE_TO_REP_AVG_MARGIN = 'SET_COMPARE_TO_REP_AVG_MARGIN'
export const SET_REP_AVG_ORDER_TOTAL = 'SET_REP_AVG_ORDER_TOTAL'
export const SET_COMPARE_TO_REP_AVG_ORDER_TOTAL =
  'SET_COMPARE_TO_REP_AVG_ORDER_TOTAL'
export const SET_TOP_3_ACCOUNTS_FOR_REP = 'SET_TOP_3_ACCOUNTS_FOR_REP'
export const SET_TOP_3_MENUS_FOR_REP = 'SET_TOP_3_MENUS_FOR_REP'
export const SET_REP_TOTAL_ORDERS = 'SET_REP_TOTAL_ORDERS'
export const SET_COMPARE_TO_REP_TOTAL_ORDERS = 'SET_COMPARE_TO_REP_TOTAL_ORDERS'
export const SET_REP_TOTAL_SALES = 'SET_REP_TOTAL_SALES'
export const SET_COMPARE_TO_REP_TOTAL_SALES = 'SET_COMPARE_TO_REP_TOTAL_SALES'
export const SET_REP_TOTAL_SALES_CHART_DATA = 'SET_REP_TOTAL_SALES_CHART_DATA'
export const SET_REP_TOTAL_ORDERS_CHART_DATA = 'SET_REP_TOTAL_ORDERS_CHART_DATA'
export const SET_REP_AVG_MARGINS_CHART_DATA = 'SET_REP_AVG_MARGINS_CHART_DATA'
export const SET_REP_AVG_ORDER_TOTALS_CHART_DATA =
  'SET_REP_AVG_ORDER_TOTALS_CHART_DATA'
export const SET_ACCOUNT_DETAILS = 'SET_ACCOUNT_DETAILS'

export const setAccountDetails = (accountDetails) => ({
  type: SET_ACCOUNT_DETAILS,
  accountDetails,
})
export const setRepAvgMargin = (avgMargin) => ({
  type: SET_REP_AVG_MARGIN,
  avgMargin,
})
export const setCompareToRepAvgMargin = (compareToAvgMargin) => ({
  type: SET_COMPARE_TO_REP_AVG_MARGIN,
  compareToAvgMargin,
})
export const setRepAvgOrderTotal = (avgOrderTotal) => ({
  type: SET_REP_AVG_ORDER_TOTAL,
  avgOrderTotal,
})
export const setCompareToRepAvgOrderTotal = (compareToAvgOrderTotal) => ({
  type: SET_COMPARE_TO_REP_AVG_ORDER_TOTAL,
  compareToAvgOrderTotal,
})
export const setTop3AccountsForRep = (top3Accounts) => ({
  type: SET_TOP_3_ACCOUNTS_FOR_REP,
  top3Accounts,
})
export const setTop3MenusForRep = (top3Menus) => ({
  type: SET_TOP_3_MENUS_FOR_REP,
  top3Menus,
})
export const setRepTotalOrders = (totalOrders) => ({
  type: SET_REP_TOTAL_ORDERS,
  totalOrders,
})
export const setCompareToRepTotalOrders = (compareToTotalOrders) => ({
  type: SET_COMPARE_TO_REP_TOTAL_ORDERS,
  compareToTotalOrders,
})
export const setRepTotalSales = (totalSales) => ({
  type: SET_REP_TOTAL_SALES,
  totalSales,
})
export const setCompareToRepTotalSales = (compareToTotalSales) => ({
  type: SET_COMPARE_TO_REP_TOTAL_SALES,
  compareToTotalSales,
})
export const setRepTotalSalesChartData = (totalSalesChartData) => ({
  type: SET_REP_TOTAL_SALES_CHART_DATA,
  totalSalesChartData,
})
export const setRepTotalOrdersChartData = (totalOrdersChartData) => ({
  type: SET_REP_TOTAL_ORDERS_CHART_DATA,
  totalOrdersChartData,
})
export const setRepAvgMarginsChartData = (avgMarginsChartData) => ({
  type: SET_REP_AVG_MARGINS_CHART_DATA,
  avgMarginsChartData,
})
export const setRepAvgOrderTotalsChartData = (avgOrderTotalsChartData) => ({
  type: SET_REP_AVG_ORDER_TOTALS_CHART_DATA,
  avgOrderTotalsChartData,
})
