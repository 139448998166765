import { connect } from 'react-redux'

import DiningSupplies from '@components/supply/DiningSupplies'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { diningSupplies } = state

  return {
    diningSupplies,
  }
}

const mapDispatchToProps = () => {
  const {
    RestService,
    RouterService,
    SessionService,
    SettingsService,
    SupplyService,
    UIService,
  } = services
  const { pOrderSettings } = presenters
  const {
    pResponseGeneric,
    pRequestUpdateDiningSupply,
    pRequestSettings,
    pResponseSettings,
  } = presenters.Api
  const { pResponseErrorsArray } = presenters.Errors
  const {
    AsyncLoadOrderSettings,
    DeleteDiningSupply,
    LoadSettings,
    LoadDiningSupplies,
    RetryOnTimeout,
    SaveDiningSupply,
    HandleError: HandleError_,
  } = coordinators
  const HandleError = HandleError_({
    RouterService,
    SessionService,
    UIService,
    pResponseError: pResponseErrorsArray,
  })

  const deleteDiningSupply = DeleteDiningSupply({
    LoadDiningSupplies,
    RestService,
    SupplyService,
    UIService,
    pResponseDiningSupplies: pResponseGeneric,
    HandleError,
  })
  const loadDiningSupplies = LoadDiningSupplies({
    RestService,
    SupplyService,
    pResponseDiningSupplies: pResponseGeneric,
    HandleError,
  })
  const saveDiningSupply = SaveDiningSupply({
    LoadDiningSupplies,
    RestService,
    SupplyService,
    pRequestUpdateDiningSupply,
    pResponseDiningSupplies: pResponseGeneric,
    HandleError,
  })
  const loadOrderSettings = AsyncLoadOrderSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    pOrderSettings,
    LoadSettings,
    RetryOnTimeout,
    HandleError,
  })

  return {
    deleteDiningSupply,
    loadDiningSupplies,
    loadOrderSettings,
    saveDiningSupply,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiningSupplies)
