import React from 'react'
import PropTypes from 'prop-types'

const ChefPayouts = (props) => {
  const { order, attribute, key } = props
  const payouts = order[attribute] ? order[attribute].split('\n') : []

  return (
    <td key={key} className="dashboard-long-text-cell">
      <div className="dashboard-chefs-container">
        {payouts.map((payout, i) => {
          if (/\.\d$/.test(payout)) {
            payout = payout + '0'
          }

          return (
            <p className="dashboard-chef" key={`chef${i}`}>
              <span>{`${i + 1}`}</span>
              {payout}
            </p>
          )
        })}
      </div>
    </td>
  )
}

ChefPayouts.propTypes = {
  order: PropTypes.object,
  attribute: PropTypes.string,
  key: PropTypes.string,
}

export default ChefPayouts
