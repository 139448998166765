export const escapeAllAmpersand = (strings) => {
  return strings.map((s) => s.replace(/&/g, /\u0026/))
}

export const escapeAllAmpersandByKey = (strings, key) => {
  return strings.map((s) => {
    s = { ...s }
    if (s[key] && typeof s[key] === 'string') {
      s.value = s.value.replace(/&/g, /\u0026/)
    }

    return s
  })
}
