import { TrackJS } from 'trackjs'
import { AxiosError } from 'axios'
import { config } from 'hungry-core2'

export const InstallTrackJS = () => {
  const { track_js_token: trackJSToken, env } = config
  const trackJSEnv = env === 'production' ? 'production' : 'development'
  if (trackJSToken) {
    TrackJS.install({
      token: trackJSToken,
      application: `hungry-admin-${trackJSEnv}`,
    })
    TrackJS.addMetadata('env', trackJSEnv)
  }
}

export const IdentifyUser = (email: string) => {
  TrackJS.configure({
    userId: email.toLowerCase(),
  })
}

export const trackJSErrorLogger = (error: AxiosError) => {
  if (TrackJS) {
    TrackJS.console.log({
      message: error.response?.data?.message,
      method: error.config?.method,
      url: error.config?.url,
      status: error.response?.status,
      statusText: error.response?.statusText,
      responseData: error.response?.data,
      requestParams: error.config.params,
      response: error.response,
    })

    TrackJS.track({
      message: error.response?.data?.message,
      method: error.config?.method,
      url: error.config?.url,
      status: error.response?.status,
      statusText: error.response?.statusText,
      responseData: error.response?.data,
      requestParams: error.config.params,
      response: error.response,
    })
  }
}
