import { connect } from 'react-redux'

import { PaymentMethodInput } from '@components/common/form'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'

const mapStateToProps = (state) => {
  const errors = state.errors.paymentMethodInput || {}

  return {
    errors,
  }
}

const mapDispatchToProps = () => {
  const {
    BraintreeService,
    RestService,
    RouterService,
    SessionService,
    UIService,
  } = services
  const { InitBraintree, UpdateNonce, HandleError: HandleError_ } = coordinators
  const { pBuildPaymentMethod, pFormatCreditCard, pFormatMMYY } = presenters
  const { pRequestBraintreeNonce } = presenters.Api
  const { pResponseBraintreeErrorMessage: pResponseError } = presenters.Errors

  const clearErrors = UIService.Errors.clear
  const HandleError = HandleError_({
    UIService,
    RouterService,
    SessionService,
    pResponseError,
  })
  const initBraintree = InitBraintree({ BraintreeService, RestService })
  const updateNonce = UpdateNonce({
    BraintreeService,
    UIService,
    pRequestBraintreeNonce,
    HandleError,
  })
  const { displayFailureMessage } = UIService.FlashMessage
  const { validate: validatePaymentMethod } = BraintreeService

  return {
    clearErrors,
    displayFailureMessage,
    initBraintree,
    pBuildPaymentMethod,
    pFormatCreditCard,
    pFormatMMYY,
    updateNonce,
    validatePaymentMethod,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodInput)
