import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import { Checkbox, DateInput } from '@components/common/form'
import Input from '@components/common/form/Input'
import LinkText from '@components/common/form/LinkText'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import DividerLine from '@components/common/DividerLine'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import Modal from '@components/common/modal/Modal'
import Dropdown from '@components/common/form/Dropdown'
import { AuthorizedInteractable } from '@containers/common/auth'

const AllStatuses = ['Empty', 'Pending', 'In-Route', 'Delivered', 'Paid']
const DateFilterTypes = ['Day', 'Range']

class GroupOrdersSearchPage extends Component {
  state = {
    search: '',
    dateFilterType: 'Day',
    searchDate: Moment(),
    filterDateStart: undefined,
    filterDateEnd: undefined,
    searchSortBy: 'groupOrder.dropoff',
    searchLimit: 20,
    searchOffset: 0,
    groupOrders: [],
    statuses: [...AllStatuses],
    sortBy: [undefined, true],
    selectAll: false,
    selected: {},
    showMarkPaidModal: false,
    paymentType: '',
  }
  searchTimer = undefined

  componentDidMount() {
    this.searchGroupOrders()
  }

  searchGroupOrders = async () => {
    const { searchGroupOrders } = this.props
    const {
      dateFilterType,
      filterDateStart,
      filterDateEnd,
      search,
      searchSortBy: sortBy,
      searchDate,
      searchLimit,
      searchOffset,
      statuses,
    } = this.state

    const searchParams = {
      search,
      statuses,
      limit: searchLimit,
      offset: searchOffset,
      sortBy,
    }
    if (dateFilterType === 'Day') {
      searchParams.searchDate = searchDate
    } else if (dateFilterType === 'Range') {
      searchParams.start = filterDateStart
      searchParams.end = filterDateEnd
    }

    const groupOrders = await searchGroupOrders(searchParams)
    this.setState({ groupOrders })
  }

  searchAfterTimeout = () => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer)
    }
    this.searchTimer = undefined
    this.searchTimer = setTimeout(() => {
      this.searchGroupOrders()
    }, 550)
  }

  navToGroupOrder = async (id, clientId, date, clientSettingsId) => {
    const { history, setGroupOrderPage, loadAccount } = this.props
    const account = await loadAccount(clientId)
    setGroupOrderPage({ account, id, date, clientSettingsId })
    history.push('/group-orders/')
  }

  onToggleStatus = (status) => () => {
    const { statuses } = this.state
    if (statuses.includes(status)) {
      this.setState(
        { statuses: statuses.filter((s) => s !== status) },
        this.searchGroupOrders,
      )
    } else {
      this.setState({ statuses: [...statuses, status] }, this.searchGroupOrders)
    }
  }

  sortGroupOrders = (attribute, transform = (a) => a) => {
    const { groupOrders, sortBy } = this.state
    const asc = attribute !== sortBy[0] || !sortBy[1]
    this.setState({
      sortBy: [attribute, asc],
      groupOrders: groupOrders.sort((a, b) => {
        if (transform(a[attribute]) > transform(b[attribute])) {
          return asc ? 1 : -1
        }
        if (transform(a[attribute]) < transform(b[attribute])) {
          return asc ? -1 : 1
        }

        return 0
      }),
    })
  }

  sortGroupOrdersByClientAttrib = (attribute) => {
    const { groupOrders, sortBy } = this.state
    const asc = attribute !== sortBy[0] || !sortBy[1]
    this.setState({
      sortBy: [attribute, asc],
      groupOrders: groupOrders.sort((a, b) => {
        if (a.client[attribute] > b.client[attribute]) {
          return asc ? 1 : -1
        }
        if (a.client[attribute] < b.client[attribute]) {
          return asc ? -1 : 1
        }

        return 0
      }),
    })
  }

  onSelectAll = () => {
    const { groupOrders, selectAll } = this.state
    if (selectAll) {
      this.setState({ selected: {} })
    } else {
      this.setState({
        selected: groupOrders.reduce((map, order) => {
          if (order.status !== 'Paid') {
            map[order.id] = order
          }

          return map
        }, {}),
      })
    }
    this.setState({ selectAll: !selectAll })
  }

  onToggleItem = (checked, order) => {
    const { selected } = this.state
    const { id, status } = order
    const copy = { ...selected }
    if (checked && status !== 'Paid') {
      copy[id] = order
    } else {
      delete copy[id]
    }
    this.setState({ selected: copy })
  }

  onToggleMarkPaidModal = (show) => {
    this.setState({ showMarkPaidModal: show })
  }

  onSelectPaymentType = (e) => {
    const paymentType = e.target.value
    this.setState({ paymentType })
  }

  onMarkPaid = async () => {
    const { selected, paymentType } = this.state
    const { markGroupOrdersPaid, email } = this.props
    const ids = Object.keys(selected)
    if (await markGroupOrdersPaid({ ids, paymentType, alias: email })) {
      this.setState({ selected: {}, showMarkPaidModal: false })
      this.searchGroupOrders()
    }
  }

  renderMarkPaidModal = () => {
    const { paymentType } = this.state

    return (
      <Modal
        title="Mark Paid"
        hideModal={() => this.onToggleMarkPaidModal(false)}
        color="#001940"
        width="400px"
      >
        <div className="flex flex-col">
          <p className="my-3">
            Please select a payment type for marked orders.
          </p>
          <Dropdown
            label="Payment Type"
            testId="payment-type-dropdown"
            value={paymentType}
            onChange={this.onSelectPaymentType}
          >
            <option key={-1} value="">
              Select a payment type
            </option>
            <option key={'ach'} value="ACH">
              ACH
            </option>
            <option key={'check'} value="Check">
              Check
            </option>
          </Dropdown>
          <button
            className="btn gradient-bg text-white font-semibold my-3 justify-end"
            onClick={this.onMarkPaid}
          >
            Save
          </button>
        </div>
      </Modal>
    )
  }

  render() {
    const {
      dateFilterType,
      filterDateStart,
      filterDateEnd,
      groupOrders,
      search,
      searchSortBy,
      searchDate,
      searchLimit,
      searchOffset,
      statuses,
      selected,
      selectAll,
      showMarkPaidModal,
    } = this.state

    const selectedSum = Object.values(selected).reduce((sum, order) => {
      return (sum += parseFloat(order.total))
    }, 0.0)

    return (
      <Panel width="100%" maxWidth="1600px" heading="Search Group Orders">
        <FlexContainer alignItems="flex-end">
          <Input
            label="Search"
            marginBottom="0"
            width="200px"
            type="text"
            value={search}
            onChange={(e) =>
              this.setState(
                {
                  search: e.target.value,
                  searchOffset: 0,
                },
                this.searchAfterTimeout,
              )
            }
          />
          <XSpacing width="20px" />

          <Dropdown
            marginBottom="0"
            width="200px"
            label="Date Filter By"
            value={dateFilterType}
            onChange={(e) => this.setState({ dateFilterType: e.target.value })}
          >
            {DateFilterTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Dropdown>
          <XSpacing width="20px" />

          {dateFilterType === 'Day' && (
            <div>
              <DateInput
                width="200px"
                label="Date"
                date={searchDate}
                dateFormat="default"
                isStatic={false}
                onChange={(searchDate) =>
                  this.setState({ searchDate }, this.searchGroupOrders)
                }
                clearDate={() =>
                  this.setState(
                    { searchDate: undefined },
                    this.searchGroupOrders,
                  )
                }
              />
              <XSpacing width="20px" />
            </div>
          )}

          {dateFilterType === 'Range' && (
            <div>
              <DateInput
                width="200px"
                label="Start"
                date={filterDateStart}
                dateFormat="default"
                isStatic={false}
                onChange={(filterDateStart) =>
                  this.setState({ filterDateStart }, this.searchGroupOrders)
                }
                clearDate={() =>
                  this.setState(
                    { filterDateStart: undefined },
                    this.searchGroupOrders,
                  )
                }
              />
              <XSpacing width="20px" />
              <DateInput
                width="200px"
                label="End"
                date={filterDateEnd}
                dateFormat="default"
                isStatic={false}
                onChange={(filterDateEnd) =>
                  this.setState(
                    {
                      filterDateEnd: filterDateEnd.set({ h: 23, m: 59, s: 59 }),
                    },
                    this.searchGroupOrders,
                  )
                }
                clearDate={() =>
                  this.setState(
                    { filterDateEnd: undefined },
                    this.searchGroupOrders,
                  )
                }
              />
              <XSpacing width="20px" />
            </div>
          )}

          <XSpacing width="20px" />
          <Dropdown
            marginBottom="0"
            width="200px"
            label="Date Filter By"
            value={searchSortBy}
            onChange={(e) =>
              this.setState(
                { searchSortBy: e.target.value },
                this.searchGroupOrders,
              )
            }
          >
            <option value="groupOrder.dropoff"> Event Date Asc </option>
            <option value="-groupOrder.dropoff"> Event Date Desc </option>
            <option value="-groupOrder.updatedAt"> Last Updated </option>
          </Dropdown>
          <XSpacing width="20px" />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer alignItems="flex-start">
          {AllStatuses.map((status) => (
            <div key={status} style={{ display: 'flex' }}>
              <Checkbox
                label={status}
                value={status}
                checked={statuses.includes(status)}
                onChange={this.onToggleStatus(status)}
              />
              <XSpacing width="30px" />
            </div>
          ))}
        </FlexContainer>
        <DividerLine margin="20px 0" />
        {Object.values(selected).length > 0 && (
          <div>
            <FlexContainer alignItems="flex-start">
              <AuthorizedInteractable roles={['master admin', 'finance']}>
                <button
                  className="btn gradient-bg text-white font-semibold"
                  onClick={() => this.onToggleMarkPaidModal(true)}
                >
                  Mark Paid ${selectedSum.toFixed(2)}
                </button>
              </AuthorizedInteractable>
            </FlexContainer>
            <YSpacing height="10px" />
          </div>
        )}
        <Table>
          <tr>
            <th>
              <AuthorizedInteractable roles={['master admin', 'finance']}>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={this.onSelectAll}
                />
              </AuthorizedInteractable>
            </th>
            <th
              className="cursor-pointer"
              onClick={() => this.sortGroupOrders('number')}
            >
              Number
            </th>
            <th
              className="cursor-pointer"
              onClick={() => this.sortGroupOrders('status')}
            >
              Status
            </th>
            <th
              className="cursor-pointer"
              onClick={() =>
                this.sortGroupOrders('chefs', (chefs) =>
                  JSON.stringify(chefs.map((c) => c.name)),
                )
              }
            >
              Chef
            </th>
            <th
              className="cursor-pointer"
              onClick={() =>
                this.sortGroupOrders('createdAt', (x) => Moment(x))
              }
            >
              Created At
            </th>
            <th
              className="cursor-pointer"
              onClick={() => this.sortGroupOrders('date', (x) => Moment(x))}
            >
              Event Date
            </th>
            <th
              className="cursor-pointer"
              onClick={() =>
                this.sortGroupOrders('cutoffTime', (x) => Moment(x))
              }
            >
              Ordering Cutoff
            </th>
            {/* <th className="cursor-pointer" onClick={ () => this.sortGroupOrders( 'weeklyChefEmailSentDate', x => Moment( x )) }>
              Chef Weekly Email Sent At
            </th> */}
            <th
              className="cursor-pointer"
              onClick={() => this.sortGroupOrdersByClientAttrib('name')}
            >
              Client
            </th>
            <th
              className="cursor-pointer"
              onClick={() => this.sortGroupOrdersByClientAttrib('location')}
            >
              Location
            </th>
            <th
              className="cursor-pointer"
              onClick={() =>
                this.sortGroupOrders('total', (x) => parseFloat(x))
              }
            >
              Total
            </th>
            <th />
          </tr>
          {groupOrders.map((groupOrder) => (
            <tr key={groupOrder.id}>
              <td>
                <AuthorizedInteractable roles={['master admin', 'finance']}>
                  <input
                    type="checkbox"
                    disabled={groupOrder.status === 'Paid'}
                    checked={selected[groupOrder.id] || false}
                    onChange={(e) =>
                      this.onToggleItem(e.target.checked, groupOrder)
                    }
                  />
                </AuthorizedInteractable>
              </td>
              <td>
                <p>{groupOrder.number}</p>
              </td>
              <td>
                <p>{groupOrder.status}</p>
              </td>
              <td className="flex flex-col">
                {Object.values(
                  groupOrder.chefs.reduce((acc, chef) => {
                    return { ...acc, [chef.id]: chef }
                  }, {}),
                ).map((chef) => (
                  <p key={chef.id} className="mt-1">
                    Chef {chef.name}
                  </p>
                ))}
              </td>
              <td>
                <p>{Moment(groupOrder.createdAt).format('lll')}</p>
              </td>
              <td>
                <p>{Moment(groupOrder.dropoffTime).format('lll')}</p>
              </td>
              <td>
                <p>{Moment(groupOrder.cutoffTime).format('lll')}</p>
              </td>
              {/* <td>
                <p>
                  { isZeroTime( groupOrder.weeklyChefEmailSentDate ) ?
                    'Never'
                    :
                    Moment( groupOrder.weeklyChefEmailSentDate ).format( 'lll' )
                  }
                </p>
              </td> */}
              <td>
                <p>{groupOrder.client.name}</p>
              </td>
              <td>
                <p>
                  {groupOrder.client.location === ''
                    ? groupOrder.client.name
                    : groupOrder.client.location}
                </p>
              </td>
              <td>
                <p>${groupOrder.total}</p>
              </td>
              <td>
                <div>
                  <LinkText
                    label="View/Edit"
                    onClick={() =>
                      this.navToGroupOrder(
                        groupOrder.id,
                        groupOrder.client.id,
                        groupOrder.date,
                        groupOrder.clientSettingsId,
                      )
                    }
                  />
                </div>
              </td>
            </tr>
          ))}
        </Table>
        {searchOffset > 0 && (
          <button
            onClick={() =>
              this.setState(
                { searchOffset: searchOffset - searchLimit },
                this.searchGroupOrders,
              )
            }
          >
            &lt;
          </button>
        )}
        Page {1 + Math.floor(parseFloat(searchOffset / searchLimit))}
        {searchLimit === groupOrders.length && (
          <button
            onClick={() =>
              this.setState(
                { searchOffset: searchOffset + searchLimit },
                this.searchGroupOrders,
              )
            }
          >
            &gt;
          </button>
        )}
        {showMarkPaidModal && this.renderMarkPaidModal()}
      </Panel>
    )
  }
}

GroupOrdersSearchPage.propTypes = {
  headquarter: PropTypes.number,
  email: PropTypes.string,
  theme: PropTypes.object,
  history: PropTypes.object,

  markGroupOrdersPaid: PropTypes.func,
  searchGroupOrders: PropTypes.func,
  setGroupOrderPage: PropTypes.func,
  loadAccount: PropTypes.func,
}

export default GroupOrdersSearchPage
