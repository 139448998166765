import posthog from 'posthog-js'
import { config } from 'hungry-core2'

const analytics = {
  init,
  identify,
  track,
} as const

function init() {
  const token = config.posthog_token
  if (!token) {
    return
  }

  posthog.init(token, {
    api_host: 'https://app.posthog.com',
  })
}

function identify(email: string) {
  posthog.identify(email)
}

function track(
  eventName: keyof typeof customEvents,
  properties?: Record<string, string>,
) {
  posthog.capture(eventName, properties)
}

const customEvents = {
  flash_message_displayed: 'Whenever we display a UI flash message',
} as const

export default analytics
