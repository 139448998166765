import { BaseService } from 'hungry-core2'
import actions from '@actions'

export default class ResultsMessageModal extends BaseService {
  displayResultsMessageModal = ({ show, successes, errors }) => {
    this.dispatch(
      actions.displayResultsMessageModal({ show, successes, errors }),
    )
  }

  dismissResultsMessageModal = () => {
    this.dispatch(actions.dismissResultsMessageModal())
  }
}
