import { connect } from 'react-redux'

import MLBManageOrdersPage from '@components/mlbPartners/MLBManageOrdersPage'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = () => {
  return {
    mlbPartnerName: 'Nationals',
  }
}

const mapDispatchToProps = () => {
  const { MLBOrderService, RestService, UIService } = services
  const { pResponseMLBPartner } = presenters.Api
  const {
    CalcMLBOrderBalance,
    CancelMLBOrder,
    LoadMLBMenu,
    LoadMLBOrders,
    LoadMLBPartner,
    SaveMLBOrder,
    SendMLBOrderReceipt,
  } = coordinators

  const { createKitChoice, createAddonChoice, copyOrder } = MLBOrderService
  const calcMLBOrderBalance = CalcMLBOrderBalance({ RestService, HandleError })
  const cancelMLBOrder = CancelMLBOrder({
    RestService,
    UIService,
    HandleError,
  })
  const { displayFailureMessage } = UIService.FlashMessage

  const loadMLBMenu = LoadMLBMenu({ RestService, HandleError })
  const loadMLBOrders = LoadMLBOrders({ RestService, HandleError })
  const loadMLBPartner = LoadMLBPartner({
    RestService,
    pResponseMLBPartner,
    HandleError,
  })
  const saveMLBOrder = SaveMLBOrder({
    RestService,
    UIService,
    HandleError,
  })
  const sendMLBOrderReceipt = SendMLBOrderReceipt({
    RestService,
    UIService,
    HandleError,
  })

  return {
    calcMLBOrderBalance,
    createKitChoice,
    createAddonChoice,
    cancelMLBOrder,
    copyOrder,
    displayFailureMessage,
    saveMLBOrder,
    sendMLBOrderReceipt,
    loadMLBMenu,
    loadMLBOrders,
    loadMLBPartner,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MLBManageOrdersPage)
