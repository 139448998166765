import { connect } from 'react-redux'

import { EditServiceItemModal } from '@components/chef/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const {
    cateringSupplies,
    diningSupplies,
    editServiceItem,
    editServiceItemModal,
  } = state
  const errors = state.errors.serviceItemModal || {}
  const { show } = editServiceItemModal

  return {
    allCateringSupplies: cateringSupplies,
    allDiningSupplies: diningSupplies,
    serviceItem: editServiceItem,
    show,
    errors,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, SupplyService, UIService } = services
  const {
    pResponseChef,
    pResponseGeneric,
    pRequestUpdateItemImages,
    pRequestUpdateServiceItem,
  } = presenters.Api
  const {
    DelayedUpdateServiceItem,
    DeleteServiceItem,
    LoadCateringSupplies,
    LoadDiningSupplies,
    SaveServiceItem,
    SelectChef,
  } = coordinators
  const { close } = UIService.EditServiceItem
  const saveServiceItem = SaveServiceItem({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateServiceItem,
    pRequestUpdateItemImages,
    pResponseServiceItems: pResponseGeneric,
    HandleError,
  })
  const clearErrors = UIService.Errors.clear
  const delayedUpdateServiceItem = DelayedUpdateServiceItem({
    ChefService,
    UIService,
  })
  const deleteServiceItem = DeleteServiceItem({
    ChefService,
    RestService,
    UIService,
    pResponseChef,
    HandleError,
    SelectChef,
  })
  const loadCateringSupplies = LoadCateringSupplies({
    RestService,
    SupplyService,
    pResponseCateringSupplies: pResponseGeneric,
    HandleError,
  })
  const loadDiningSupplies = LoadDiningSupplies({
    RestService,
    SupplyService,
    pResponseDiningSupplies: pResponseGeneric,
    HandleError,
  })

  return {
    close,
    clearErrors,
    loadCateringSupplies,
    loadDiningSupplies,
    saveServiceItem,
    deleteServiceItem,
    delayedUpdateServiceItem,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditServiceItemModal)
