import { connect } from 'react-redux'

import { InvoiceRefundModal } from '@components/invoice'

import services from '@services'
import coordinators from '@coordinators'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { user = {} } = state

  return {
    user,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService } = services
  const { RefundOrderOnInvoice } = coordinators

  const { displayWarningMessage } = UIService.FlashMessage

  const refundOrderOnInvoice = RefundOrderOnInvoice({
    RestService,
    UIService,
    HandleError,
  })

  return {
    displayWarningMessage,
    refundOrderOnInvoice,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceRefundModal)
