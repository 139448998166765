import Moment from 'moment-timezone'

export const SearchPopUps =
  ({ RestService, HandleError }) =>
  async ({
    headquarter,
    search,
    searchDate,
    statuses,
    limit = 100,
    offset = 0,
    popUpIds,
  }) => {
    try {
      const params = {
        headquarter,
        offset,
        limit,
      }
      if (search != null) {
        params.search = search
      }
      if (searchDate != null) {
        params.date = searchDate.format('YYYY-MM-DD')
      }
      if (statuses) {
        params.status = statuses
      }

      if (popUpIds != null) {
        params.popUpIds = popUpIds
      }

      return await RestService.get('/pop-ups', params)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const SearchPopUpsByDateRange =
  ({ RestService, HandleError }) =>
  async ({ startDate, endDate, clientId }) => {
    const popups = []
    const limit = 25
    let offset = 0

    const params = {}
    if (startDate != null) {
      params.startDate = Moment(startDate).format()
    }
    if (endDate != null) {
      params.endDate = Moment(endDate).format()
    }
    if (clientId != null) {
      params.clientId = clientId
    }

    try {
      while (true) {
        const response = await RestService.get(
          '/pop-ups/accounting/orders/range',
          { ...params, offset, limit },
        )

        if (response.length === 0) {
          break
        }

        popups.push(...response)
        offset += limit
      }

      return popups
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const CopyPopUp =
  ({ RestService, HandleError }) =>
  async (id) => {
    try {
      return await RestService.get(`/pop-ups/${id}/copy`)
    } catch (error) {
      HandleError({ error })
    }
  }

export const GetPopUp =
  ({ RestService, HandleError }) =>
  async (id) => {
    try {
      const popUp = await RestService.get(`/pop-ups/${id}`)

      return popUp
    } catch (error) {
      HandleError({ error })
    }
  }

export const GetAccountingPopUp =
  ({ RestService, HandleError }) =>
  async (id) => {
    try {
      return await RestService.get(`/pop-ups/accounting/${id}`)
    } catch (error) {
      HandleError({ error })
    }
  }

export const SavePopUp =
  ({ RestService, UIService, HandleError }) =>
  async (popUp) => {
    try {
      if (popUp.id) {
        await RestService.put(`/pop-ups/${popUp.id}`, popUp)
      } else {
        await RestService.post('/pop-ups', popUp)
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      `Pop Up ${popUp.name} successfully updated`,
    )

    return true
  }

export const DeletePopUp =
  ({ RestService, UIService, HandleError }) =>
  async ({ id, name }) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete Pop Up "${name}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/pop-ups/${id}`)
      } catch (error) {
        HandleError({ error })

        return false
      }

      return true
    }

    return false
  }

export const NotifyPopUpChef =
  ({ RestService, UIService, HandleError }) =>
  async (popUpId, chefId) => {
    try {
      await RestService.post('/pop-ups/email_chef', {
        popUpId,
        chefId,
        force: true,
      })
      UIService.FlashMessage.displaySuccessMessage('PopUp chefs notified!')
    } catch (error) {
      HandleError({ error })
    }
  }

export const SendPopUpMarginReport =
  ({ RestService, UIService, HandleError }) =>
  async (start, end) => {
    try {
      await RestService.post('/pop-ups/accounting/send_margin_report', {
        start,
        end,
      })
      UIService.FlashMessage.displaySuccessMessage('Margin Report Sent!')
    } catch (error) {
      HandleError({ error })
    }
  }

export const GetPopUpPrePaymentTax =
  ({ RestService, HandleError }) =>
  async (clientId, subtotal, line1, city, state, zip) => {
    try {
      return await RestService.get('/pop-ups/pre_payment_tax', {
        clientId,
        subtotal,
        line1,
        city,
        state,
        zip,
      })
    } catch (error) {
      HandleError({ error })
    }
  }

export const MarkPopUpPaid =
  ({ RestService, UIService, HandleError }) =>
  async ({ id, number, paymentType, alias }) => {
    const markPaid = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to mark Pop-Up "${number}" as paid?`,
    })
    if (markPaid) {
      try {
        await RestService.put('/pop-ups/mark_paid', { id, paymentType, alias })
      } catch (error) {
        HandleError({ error })

        return false
      }
      UIService.FlashMessage.displaySuccessMessage(
        'Pop-Up successfully marked as paid.',
      )

      return true
    }

    return false
  }

export const MarkPopUpsPaid =
  ({ RestService, UIService, HandleError }) =>
  async ({ ids, paymentType, alias }) => {
    const markPaid = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to mark these ${ids.length} pop-ups as paid?`,
    })
    if (markPaid) {
      try {
        await RestService.put('/pop-ups/bulk_mark_paid', {
          ids,
          paymentType,
          alias,
        })
      } catch (error) {
        HandleError({ error })

        return false
      }

      return true
    }

    return false
  }
