import { connect } from 'react-redux'

import EditSubscriberModal from '@components/subscription/edit/EditSubscriberModal'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapDispatchToProps = () => {
  const { RestService, SubscriptionService, UIService } = services
  const { UpdateSubscriber, LoadSubscribers } = coordinators
  const { pRequestSubscriber, pResponseSubscriber } = presenters.Api

  const flashError = (message) =>
    UIService.FlashMessage.displayFailureMessage(message)

  const updateMember = UpdateSubscriber({
    RestService,
    pRequestSubscriber,
    HandleError,
  })

  const refreshMembers = LoadSubscribers({
    RestService,
    SubscriptionService,
    pResponseSubscriber,
    HandleError,
  })

  return {
    flashError,
    refreshMembers,
    updateMember,
  }
}

export default connect(undefined, mapDispatchToProps)(EditSubscriberModal)
