import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AuthorizedInteractable } from '@containers/common/auth'

export class ServingUtensils extends Component {
  constructor(props) {
    super(props)

    this.state = {
      servingUtensils: props.servingUtensils,
      newServingUtensil: null,
      editServingUtensil: null,
      sortAsc: true,
    }
  }

  componentWillMount() {
    this.props.loadServingUtensils()
  }

  componentWillReceiveProps(nextProps) {
    const { servingUtensils } = nextProps
    if (servingUtensils !== this.state.servingUtensils) {
      this.setState({ servingUtensils })
    }
  }

  sortByString = (columnName) => {
    const { servingUtensils, sortAsc } = this.state
    const getColumn = (item) =>
      item[columnName] ? item[columnName].toLowerCase() : ''
    const sorted = servingUtensils.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ servingUtensils: sorted, sortAsc: !sortAsc })
  }

  sortByBoolean = (columnName) => {
    const { servingUtensils, sortAsc } = this.state
    const getColumn = (item) => (item[columnName] === true ? 1 : 0)
    const sorted = servingUtensils.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ servingUtensils: sorted, sortAsc: !sortAsc })
  }

  sortByNumber = (columnName) => {
    const { servingUtensils, sortAsc } = this.state
    const sorted = servingUtensils.sort((a, b) => {
      if (sortAsc) {
        return a[columnName] - b[columnName]
      } else {
        return b[columnName] - a[columnName]
      }
    })
    this.setState({ servingUtensils: sorted, sortAsc: !sortAsc })
  }

  renderStaticRow = (srvUtensil, i) => {
    return (
      <tr
        key={i}
        className="clickable"
        onClick={(e) => {
          e.preventDefault()
          this.setState({ editServingUtensil: { ...srvUtensil } })
        }}
      >
        <td>{srvUtensil.name}</td>
        <td>{srvUtensil.supplyType}</td>
        <td>{srvUtensil.defaultUnitCost}</td>
        <td>
          {srvUtensil.active ? (
            <span className="approved-check">✔</span>
          ) : (
            <span className="not-approved-x">✖</span>
          )}
        </td>
        <td>
          {srvUtensil.trackInventory ? (
            <span className="approved-check">✔</span>
          ) : (
            <span className="not-approved-x">✖</span>
          )}
        </td>
        <td>{srvUtensil.dftSupplyUsageRate}</td>
        <td>%{(srvUtensil.dftRecoupPct * 100).toFixed(2)}</td>
        <td>%{(srvUtensil.dftHungryVipRecoupPct * 100).toFixed(2)}</td>
      </tr>
    )
  }

  renderRow = (srvUtensil, i) => {
    const { editServingUtensil } = this.state
    const id = editServingUtensil && editServingUtensil.id
    if (srvUtensil.id == id) {
      return this.renderEditRow(editServingUtensil, i)
    } else {
      return this.renderStaticRow(srvUtensil, i)
    }
  }

  renderEditRow = (srvUtensil, i) => {
    return (
      <tr key={i} className="clickable">
        <td>
          <input
            type="text"
            value={srvUtensil.name}
            onInput={(e) =>
              this.setState({
                editServingUtensil: { ...srvUtensil, name: e.target.value },
              })
            }
          />
        </td>
        <td>{srvUtensil.supplyType}</td>
        <td>
          <input
            type="text"
            value={srvUtensil.defaultUnitCost}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editServingUtensil: {
                  ...srvUtensil,
                  defaultUnitCost: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <input
            type="checkbox"
            checked={srvUtensil.active}
            onChange={(e) =>
              this.setState({
                editServingUtensil: { ...srvUtensil, active: e.target.checked },
              })
            }
          />
        </td>
        <td>
          <input
            type="checkbox"
            checked={srvUtensil.trackInventory}
            onChange={(e) =>
              this.setState({
                editServingUtensil: {
                  ...srvUtensil,
                  trackInventory: e.target.checked,
                },
              })
            }
          />
        </td>
        <td>
          <input
            type="text"
            value={srvUtensil.dftSupplyUsageRate}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editServingUtensil: {
                  ...srvUtensil,
                  dftSupplyUsageRate: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <input
            type="text"
            value={srvUtensil.dftRecoupPct}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editServingUtensil: {
                  ...srvUtensil,
                  dftRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <input
            type="text"
            value={srvUtensil.dftHungryVipRecoupPct}
            onInput={(e) => {
              const val = e.target.value || ''
              this.setState({
                editServingUtensil: {
                  ...srvUtensil,
                  dftHungryVipRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                },
              })
            }}
          />
        </td>
        <td>
          <AuthorizedInteractable
            roles={['master admin', 'chef lead', 'captain lead']}
          >
            <button
              className="button-neutral"
              key="editServingUtensil"
              onClick={async (event) => {
                event.preventDefault()
                const { deleteServingUtensil } = this.props
                if (await deleteServingUtensil(srvUtensil.id)) {
                  this.setState({ editServingUtensil: null })
                }
              }}
            >
              Delete Supply
            </button>
          </AuthorizedInteractable>
        </td>
        <td>
          <button
            className="button-neutral"
            key="clearEditServingUtensil"
            onClick={(event) => {
              event.preventDefault()
              this.setState({ editServingUtensil: null })
            }}
          >
            Clear Updates
          </button>
        </td>
      </tr>
    )
  }

  renderNewServingUtensil = () => {
    const { newServingUtensil } = this.state

    return (
      <table className="table table-hover page">
        <thead>
          <tr>
            <th colSpan={7}>New ServingUtensil</th>
          </tr>
          <tr>
            <th>Supply Name</th>
            <th>Active</th>
            <th>Default Unit Cost</th>
            <th>Track Inventory</th>
            <th>Supply Usage Rate</th>
            <th>Recoup Pct</th>
            <th>Hungry VIP Recoup Pct</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                value={newServingUtensil.name}
                onInput={(e) =>
                  this.setState({
                    newServingUtensil: {
                      ...newServingUtensil,
                      name: e.target.value,
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="checkbox"
                checked={newServingUtensil.active}
                onChange={(e) =>
                  this.setState({
                    newServingUtensil: {
                      ...newServingUtensil,
                      active: e.target.checked,
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="text"
                value={newServingUtensil.defaultUnitCost}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newServingUtensil: {
                      ...newServingUtensil,
                      defaultUnitCost: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
            <td>
              <input
                type="checkbox"
                checked={newServingUtensil.trackInventory}
                onChange={(e) =>
                  this.setState({
                    newServingUtensil: {
                      ...newServingUtensil,
                      trackInventory: e.target.checked,
                    },
                  })
                }
              />
            </td>
            <td>
              <input
                type="text"
                value={newServingUtensil.dftSupplyUsageRate}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newServingUtensil: {
                      ...newServingUtensil,
                      dftSupplyUsageRate: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
            <td>
              <input
                type="text"
                value={newServingUtensil.dftRecoupPct}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newServingUtensil: {
                      ...newServingUtensil,
                      dftRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
            <td>
              <input
                type="text"
                value={newServingUtensil.dftHungryVipRecoupPct}
                onInput={(e) => {
                  const val = e.target.value || ''
                  this.setState({
                    newServingUtensil: {
                      ...newServingUtensil,
                      dftHungryVipRecoupPct: /[0-9]*\.*[0-9]*/.exec(val)[0],
                    },
                  })
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderButtons = () => {
    const { editServingUtensil, newServingUtensil } = this.state
    const { saveServingUtensil } = this.props
    const renderNew = !newServingUtensil && !editServingUtensil
    const editedServingUtensil = newServingUtensil || editServingUtensil

    return (
      <div className="page">
        <center>
          {editedServingUtensil && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="editServingUtensil"
                onClick={async (event) => {
                  event.preventDefault()
                  if (await saveServingUtensil(editedServingUtensil)) {
                    this.setState({
                      editServingUtensil: null,
                      newServingUtensil: null,
                    })
                  }
                }}
              >
                Save Serving Utensil Changes
              </button>
            </AuthorizedInteractable>
          )}
          {renderNew && (
            <AuthorizedInteractable
              roles={['master admin', 'chef lead', 'captain lead']}
            >
              <button
                className="button-neutral"
                key="newServingUtensil"
                onClick={(event) => {
                  event.preventDefault()
                  this.setState({
                    newServingUtensil: {
                      name: '',
                      active: true,
                      trackInventory: true,
                      defaultUnitCost: 0.0,
                      dftSupplyUsageRate: 1,
                      dftRecoupPct: 0.0,
                      dftHungryVipRecoupPct: 0.0,
                    },
                  })
                }}
              >
                New Serving Utensil
              </button>
            </AuthorizedInteractable>
          )}
        </center>
      </div>
    )
  }

  render() {
    const { servingUtensils, newServingUtensil } = this.state

    return (
      <div>
        <br />
        <h3>Description</h3>
        <p>
          These supplies are utensils for serving menu items. Serving utensils
          are mapped on the Chefs tab in the menu item modal.
        </p>

        <br />
        <h3>Instructions</h3>
        <p>
          <ul>
            <li>Click a serving utensil to edit it.</li>
          </ul>
        </p>

        <br />
        <h3>Columns</h3>
        <p>
          <ul>
            <li>
              Recoup Pct - Percent of supply recouped after a catering. Will
              factor into a lower cost per order.
            </li>
            <li>
              Hungry VIP Recoup Pct - Percent of supply recouped after a HUNGRY
              VIP service type catering. Replaces the recoup pct and will factor
              into a lower cost per order.
            </li>
          </ul>
        </p>
        <br />

        <table className="table table-hover page">
          <thead>
            <tr>
              <th onClick={() => this.sortByString('name')}>Item Name</th>
              <th onClick={() => this.sortByString('supplyType')}>
                Supply Type
              </th>
              <th onClick={() => this.sortByNumber('defaultUnitCost')}>
                Default Unit Cost
              </th>
              <th onClick={() => this.sortByBoolean('active')}>Active</th>
              <th onClick={() => this.sortByBoolean('trackInventory')}>
                Track Inventory
              </th>
              <th onClick={() => this.sortByNumber('dftSupplyUsageRate')}>
                Supply Usage Rate
              </th>
              <th onClick={() => this.sortByNumber('dftRecoupPct')}>
                Recoup Pct
              </th>
              <th onClick={() => this.sortByNumber('dftHungryVipRecoupPct')}>
                Hungry VIP Recoup Pct
              </th>
            </tr>
          </thead>
          <tbody>
            {servingUtensils.length == 0 && (
              <tr>
                <td colSpan="8">
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '1.2em',
                      color: 'rgba(0,0,0,0.2)',
                      padding: '20px',
                      backgroundColor: 'rgba(0,0,0,0.01)',
                    }}
                  >
                    No supplies.
                  </div>
                </td>
              </tr>
            )}
            {servingUtensils.length != 0 &&
              servingUtensils.map((pkg, i) => this.renderRow(pkg, i))}
          </tbody>
        </table>

        {newServingUtensil && this.renderNewServingUtensil()}
        {this.renderButtons()}
      </div>
    )
  }
}

ServingUtensils.propTypes = {
  servingUtensils: PropTypes.arrayOf(PropTypes.object),

  loadServingUtensils: PropTypes.func,
  deleteServingUtensil: PropTypes.func,
  saveServingUtensil: PropTypes.func,
}

export default ServingUtensils
