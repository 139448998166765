import { connect } from 'react-redux'

import { OrderSurvey } from '@components/order/orderSections/panel'

import coordinators from '@coordinators'
import services from '@services'

const mapDispatchToProps = () => {
  const { RestService, UIService } = services
  const { EmailSurvey } = coordinators
  const emailSurvey = EmailSurvey({ RestService, UIService })

  return { emailSurvey }
}

export default connect(undefined, mapDispatchToProps)(OrderSurvey)
