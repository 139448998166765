export const APPEND_ORDERS = 'APPEND_ORDERS'
export const CLEAR_EDIT_ORDER = 'CLEAR_EDIT_ORDER'
export const CLEAR_NEW_ORDER = 'CLEAR_NEW_ORDER'
export const CLEAR_ORDER = 'CLEAR_ORDER'
export const CLEAR_ORDERS = 'CLEAR_ORDERS'
export const REMOVE_ORDER_FROM_ORDERS = 'REMOVE_ORDER_FROM_ORDERS'
export const SET_CATERING_CAPTAINS = 'SET_CATERING_CAPTAINS'
export const SET_SERVICE_DEPOTS = 'SET_SERVICE_DEPOTS'
export const SET_EDIT_ORDER = 'SET_EDIT_ORDER'
export const SET_NEW_ORDER = 'SET_NEW_ORDER'
export const SET_ORDER = 'SET_ORDER'
export const SET_ORDERS = 'SET_ORDERS'
export const SET_ORDER_ITEMS = 'SET_ORDER_ITEMS'
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS'
export const UPDATE_EDIT_ORDER = 'UPDATE_EDIT_ORDER'
export const UPDATE_NEW_ORDER = 'UPDATE_NEW_ORDER'

export const clearEditOrder = () => ({ type: CLEAR_EDIT_ORDER })
export const clearNewOrder = () => ({ type: CLEAR_NEW_ORDER })
export const clearOrder = () => ({ type: CLEAR_ORDER })
export const clearOrders = () => ({ type: CLEAR_ORDERS })

export const appendOrders = (orders) => ({ type: APPEND_ORDERS, orders })
export const removeOrderFromOrders = (orderId) => ({
  type: REMOVE_ORDER_FROM_ORDERS,
  orderId,
})
export const setCateringCaptains = (cateringCaptains) => ({
  type: SET_CATERING_CAPTAINS,
  cateringCaptains,
})
export const setServiceDepots = (serviceDepots) => ({
  type: SET_SERVICE_DEPOTS,
  serviceDepots,
})
export const setEditOrder = (order) => ({ type: SET_EDIT_ORDER, order })
export const setNewOrder = (order) => ({ type: SET_NEW_ORDER, order })
export const setOrder = (order) => ({ type: SET_ORDER, order })
export const setOrders = (orders) => ({ type: SET_ORDERS, orders })
export const setOrderItems = (orderItems) => ({
  type: SET_ORDER_ITEMS,
  orderItems,
})
export const setOrderStatus = (orderStatus) => ({
  type: SET_ORDER_STATUS,
  orderStatus,
})

export const updateEditOrder = (order) => ({ type: UPDATE_EDIT_ORDER, order })
export const updateNewOrder = (order) => ({ type: UPDATE_NEW_ORDER, order })
