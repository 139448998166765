import React, { Component } from 'react'

import { Tab, Label } from '@res/styledComponents/index'
import FlexContainer from '@components/common/FlexContainer'
import { GroupOrdersPage, GroupOrdersCalendar } from '@containers/groupOrders'
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa'
import {
  EditGroupOrderDeliveryFeeSettingsModal,
  EditGroupOrderStaffingFeeSettingsModal,
  GroupOrdersSecheduleSettings,
  NotificationsSettingsView,
} from '@components/groupOrders'
import Sidebar from '@components/sidebar/Sidebar'
import SidebarSearch from '@components/sidebar/SidebarSearch'
import SidebarListItem from '@components/sidebar/SidebarListItem'
import AuthorizedDisplay from '@containers/common/auth/AuthorizedDisplay'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import Panel from '@components/common/Panel'
import Dropdown from '@components/common/form/Dropdown'
import GroupOrderSchedulePlanner from '@containers/groupOrders/SchedulePlanner/GroupOrderSchedulePlanner'
import {
  GroupOrderReportsPage,
  GroupOrderReviewsReportsPage,
} from '@containers/groupOrders'
import {
  ValidateClientSettings,
  ValidateClientGlobalSettings,
} from '../../../utils/groupOrderUtils'
import { ValidationsModal } from '@components/groupOrders'
import { ClientGlobalSettings, ClientSettings } from '@types'
import services from '@services'

const SCHEDULE_SETTINGS_TAB = 'SCHEDULE_SETTINGS_TAB'
const CLIENT_TAB = 'CLIENT_TAB'
const CALENDAR_TAB = 'CALENDAR_TAB'
const NOTIFICATIONS_TAB = 'NOTIFICATIONS_TAB'

enum WarningsModalEntity {
  CLIENT_SETTINGS = 'CLIENT_SETTINGS',
  CLIENT_GLOBAL_SETTINGS = 'CLIENT_GLOBAL_SETTINGS',
}

type Props = {
  client: any
  headquarter: number
  routedFromSearch: boolean
  location: any
  groupOrderPage: any
  clientGlobalSettings: any
  loadAccounts: any
  loadAccount: any
  loadHeadquarters: any
  searchSlackAuthData: any
  searchPopUpCustomers: any
  setGroupOrderPage: any
  searchClientSettings: any
  getClientGlobalSettings: any
  saveClientSettings: any
  saveClientGlobalSettings: any
  saveStaffingFeeSettings: any
  displayFailureMessage: any
  newClientSettings: any
  newClientGlobalSettings: any
  newScheduleSettings: any
}

type State = {
  activeTab: string
  activeSidebar: string
  accounts: any
  account: any
  search: string
  clientSettings: any
  savedClientSettings: any
  selectedIndex: number
  clientGlobalSettings: any
  page: string
  savedClientGlobalSettings: any
  warningsModalOpen: boolean
  warningsModalEntity: WarningsModalEntity | null
  warningsModalClientGlobalSettings: any
  validationErrors: string[]
  validationWarnings: string[]
  sideBarVisible: boolean
  deliveryFeeSettingsModalOpen: boolean
  staffingFeeSettingsModalOpen: boolean
}

class GroupOrdersMaster extends Component<Props, State> {
  state = {
    activeTab: CLIENT_TAB,
    activeSidebar: 'general',
    accounts: [],
    account: undefined,
    search: '',
    page: '',
    // NOTE: we need to distinguish between saved clientSettings (state in backend) vs
    // staged but un-saved clientSettings on client
    clientSettings: [],
    savedClientSettings: [],
    selectedIndex: 0,
    // NOTE: we need to distinguish between saved clientGlobalSettings (state in backend) vs
    // staged but un-saved clientGlobalSettings on client
    clientGlobalSettings: {},
    savedClientGlobalSettings: {},
    warningsModalOpen: false,
    warningsModalEntity: null,
    warningsModalClientGlobalSettings: null,
    validationErrors: [],
    validationWarnings: [],
    sideBarVisible: true,
    deliveryFeeSettingsModalOpen: false,
    staffingFeeSettingsModalOpen: false,
  }
  searchTimer: ReturnType<typeof setTimeout> | undefined = undefined

  componentDidMount() {
    const {
      client,
      routedFromSearch,
      setGroupOrderPage,
      location,
      loadHeadquarters,
    } = this.props

    loadHeadquarters()

    const newState = { account: client, activeTab: this.state.activeTab }
    if (routedFromSearch) {
      newState.activeTab = CLIENT_TAB
      this.loadClient(client)
    }
    this.setState(newState)

    const pathBreakdown = location.pathname.match(
      /\/group-orders\/([^/]*)\/([^/]*)/,
    )
    if (pathBreakdown && pathBreakdown.length > 2) {
      this.setState({ activeSidebar: 'client' })
      setGroupOrderPage({
        account: { id: pathBreakdown[1] },
        date: pathBreakdown[2],
      })
      this.initClientFromAR(pathBreakdown[1])
    }
  }

  flashError = (message: string) =>
    services.UIService.FlashMessage.displayFailureMessage(message)

  onCloseWarningsModal = () => {
    this.setState({
      warningsModalOpen: false,
      warningsModalEntity: null,
      warningsModalClientGlobalSettings: null,
      validationErrors: [],
      validationWarnings: [],
    })
  }

  toggleDeliveryFeeSettingsModal = (value: boolean) => {
    this.setState({ deliveryFeeSettingsModalOpen: value })
  }

  toggleStaffingFeeSettingsModal = (value: boolean) => {
    this.setState({ staffingFeeSettingsModalOpen: value })
  }

  updateClientSettings = (clientSettings: any) => {
    this.setState({ clientSettings })
  }

  updateClientGlobalSettings = (clientGlobalSettings: any) => {
    this.setState({ clientGlobalSettings })
  }

  updateSelectedIndex = (selectedIndex: number) => {
    this.setState({ selectedIndex })
  }

  initClientFromAR = async (accountId: string) => {
    const accounts = await this.props.loadAccounts({
      search: '',
      account_serializer: true,
    })
    const account = await this.props.loadAccount(accountId)
    if (account) {
      this.setState({
        accounts,
        account,
        page: 'Clients',
        activeTab: CLIENT_TAB,
      })
    }
  }

  onSearch = (e: any) => {
    const search = e.target.value
    this.setState({ search }, () => {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(async () => {
        const accounts = await this.props.loadAccounts({
          search,
          account_serializer: true,
        })
        this.setState({ accounts })
      }, 550)
    })
  }

  onSaveClientSettings = async (skipWarnings = false) => {
    const { saveClientSettings } = this.props
    const {
      clientSettings: clientSettingsCollection,
      selectedIndex: i,
      savedClientGlobalSettings,
    } = this.state
    const settings = clientSettingsCollection[i]

    const { errors, warnings } = ValidateClientSettings(
      settings,
      savedClientGlobalSettings as ClientGlobalSettings,
      this.state.savedClientSettings,
    )

    if (errors.length || (warnings.length && !skipWarnings)) {
      this.setState({
        warningsModalOpen: true,
        warningsModalEntity: WarningsModalEntity.CLIENT_SETTINGS,
        validationErrors: errors,
        validationWarnings: warnings,
      })

      return
    }

    // Close and reset warnings modal if open
    this.setState({
      warningsModalOpen: false,
      validationErrors: [],
      validationWarnings: [],
    })

    const result = await saveClientSettings(
      settings,
      (settings as any).client.name,
    )
    if (result) {
      const updatedSettings = [
        ...clientSettingsCollection.slice(0, i),
        result,
        ...clientSettingsCollection.slice(i + 1),
      ]
      this.setState({
        clientSettings: [...updatedSettings],
        savedClientSettings: [...updatedSettings],
      })
    }
  }

  onClientSettingsUpdateSuccess = (cs: ClientSettings) => {
    const { clientSettings: clientSettingsCollection, selectedIndex: i } =
      this.state

    const updatedSettings = [
      ...clientSettingsCollection.slice(0, i),
      cs,
      ...clientSettingsCollection.slice(i + 1),
    ]
    this.setState({
      clientSettings: [...updatedSettings],
      savedClientSettings: [...updatedSettings],
    })
  }

  // TODO: validate that value passed as `clientGlobalSettings` can be a partial update
  // and won't clear data from state if so. Another option is to use the `result` from update
  // call to api as new `clientGlobalSettings` value of state
  onSaveClientGlobalSettings = async (
    clientGlobalSettings: any,
    skipWarnings = false,
  ) => {
    const { displayFailureMessage, saveClientGlobalSettings, headquarter } =
      this.props
    const { account, savedClientSettings: clientSettingsCollection } =
      this.state

    if (!clientGlobalSettings.headquarter && headquarter) {
      clientGlobalSettings.headquarter = headquarter
    } else if (!clientGlobalSettings.headquarter) {
      displayFailureMessage(
        "Missing Headquarter on the Client's Global Settings",
      )

      return
    }

    const { errors, warnings } = ValidateClientGlobalSettings(
      clientGlobalSettings,
      clientSettingsCollection,
    )

    if (errors.length || (warnings.length && !skipWarnings)) {
      this.setState({
        warningsModalOpen: true,
        warningsModalEntity: WarningsModalEntity.CLIENT_GLOBAL_SETTINGS,
        warningsModalClientGlobalSettings: clientGlobalSettings,
        validationErrors: errors,
        validationWarnings: warnings,
      })

      return
    }

    // Close and reset warnings modal if open
    this.onCloseWarningsModal()

    const result = await saveClientGlobalSettings(
      clientGlobalSettings,
      (account as any).name,
    )

    if (result) {
      this.setState({
        clientGlobalSettings: { ...result },
        savedClientGlobalSettings: { ...result },
        // Ensure modals for partial updates to client global settings are closed
        deliveryFeeSettingsModalOpen: false,
      })
    }

    return result
  }

  onSaveStaffingFeeSettings = async (
    globalStaffingFeeSettings: any,
    locationsConfig: any,
  ) => {
    const { displayFailureMessage, saveStaffingFeeSettings, headquarter } =
      this.props

    const clientGlobalSettings = this.state.clientGlobalSettings as any

    let clientGlobalSettingsId = clientGlobalSettings.id
    // Check if clientGlobalSettings exists (has id) first. If not create, then save new properties
    if (!clientGlobalSettingsId) {
      if (!clientGlobalSettings.headquarter && headquarter) {
        clientGlobalSettings.headquarter = headquarter
      } else {
        displayFailureMessage(
          "Missing Headquarter on the Client's Global Settings",
        )

        return
      }
      // TODO: verify this works correctly, i.e. validations run
      const newClientGlobalSettings =
        await this.onSaveClientGlobalSettings(clientGlobalSettings)
      if (newClientGlobalSettings) {
        clientGlobalSettingsId = newClientGlobalSettings.id
      } else {
        // Creation of global settings failed, bail here
        return
      }
    }
    const result = await saveStaffingFeeSettings(
      clientGlobalSettingsId,
      globalStaffingFeeSettings,
      locationsConfig,
    )
    if (result) {
      const nextClientSettingsMap: any = {}
      this.state.clientSettings.forEach((cs: any) => {
        const updatedClientSettings = result.clientSettingsMap[cs.id]
        const nextClientSettings = updatedClientSettings || cs
        nextClientSettingsMap[cs.id] = nextClientSettings
      })

      this.setState({
        clientSettings: Object.values(nextClientSettingsMap),
        clientGlobalSettings: result.clientGlobalSettings,
        savedClientGlobalSettings: result.clientGlobalSettings,
        staffingFeeSettingsModalOpen: false,
      })
    }

    return result
  }

  onSaveWithWarnings = () => {
    const { warningsModalEntity, warningsModalClientGlobalSettings } =
      this.state
    if (warningsModalEntity === WarningsModalEntity.CLIENT_SETTINGS) {
      this.onSaveClientSettings(true)
    } else if (
      warningsModalEntity === WarningsModalEntity.CLIENT_GLOBAL_SETTINGS
    ) {
      this.onSaveClientGlobalSettings(warningsModalClientGlobalSettings, true)
    }
  }

  renderSideBar() {
    const { account, accounts, page, activeSidebar, activeTab } = this.state
    const { setGroupOrderPage } = this.props
    const sidebarPages = ['Clients']

    if (activeSidebar !== 'general') {
      const newTab = activeTab === 'reports' ? CALENDAR_TAB : activeTab

      return (
        <Sidebar heading="Clients">
          <SidebarListItem
            onClick={() => {
              this.setState({ activeSidebar: 'general' })
            }}
          >
            {'< Back'}
          </SidebarListItem>
          <SidebarSearch label="Search Clients" onChange={this.onSearch} />
          <div className="sidebar-scroll">
            {accounts.map((acct: any) => {
              const selected = account && acct.id === (account as any).id

              return (
                <SidebarListItem
                  key={acct.id}
                  isSelected={selected}
                  onClick={() => {
                    this.setState({ account: acct, activeTab: newTab }, () =>
                      this.loadClient(acct),
                    )
                  }}
                >
                  {acct.name}
                </SidebarListItem>
              )
            })}
          </div>
        </Sidebar>
      )
    }

    return (
      <Sidebar heading="Group Orders">
        <div>
          <FaArrowCircleLeft
            style={{ marginLeft: 'auto', marginRight: 0 }}
            onClick={() => this.setState({ sideBarVisible: false })}
          />
        </div>
        <div className="sidebar-scroll">
          {sidebarPages.map((p) => {
            const selected = p === page

            return (
              <SidebarListItem
                key={p}
                isSelected={selected}
                onClick={() => {
                  setGroupOrderPage({ account: account })
                  this.setState(
                    { account: account, page: p, activeSidebar: 'client' },
                    () => this.loadClient(account),
                  )
                }}
              >
                {p}
              </SidebarListItem>
            )
          })}
          <SidebarListItem
            isSelected={'schedule' === page}
            onClick={() => {
              this.setState({ page: 'schedule', activeTab: 'schedule' })
            }}
          >
            Schedule Planner
          </SidebarListItem>
          <AuthorizedDisplay
            roles={[
              'developer',
              'master admin',
              'sales lead',
              'sales rep',
              'chef lead',
            ]}
          >
            <SidebarListItem
              isSelected={'reports' === page}
              onClick={() => {
                this.setState({ page: 'reports', activeTab: 'reports' })
              }}
            >
              Reports
            </SidebarListItem>
          </AuthorizedDisplay>
        </div>
      </Sidebar>
    )
  }

  loadClient = (client: any) => {
    ;(async (client) => {
      if (!client) {
        return
      }
      const {
        headquarter,
        searchClientSettings,
        getClientGlobalSettings,
        newClientSettings,
        newClientGlobalSettings,
        groupOrderPage,
      } = this.props
      const { clientSettingsId } = groupOrderPage || {}
      const newState = {} as Pick<State, keyof State>

      const clientSettings = await searchClientSettings({
        clientPin: client.pin,
      })
      if (clientSettings) {
        newState.clientSettings = [...clientSettings]
        newState.savedClientSettings = [...clientSettings]
        newState.selectedIndex = 0
        if (clientSettings.length === 0) {
          newState.clientSettings = [newClientSettings({ headquarter, client })]
        } else {
          const index = clientSettings.findIndex(
            (s: any) => s.id === clientSettingsId,
          )
          if (index >= 0) {
            newState.selectedIndex = index
          }
        }
      }

      const clientGlobalSettings = await getClientGlobalSettings({
        clientId: client.id,
      })

      newState.clientGlobalSettings = { ...clientGlobalSettings }
      newState.savedClientGlobalSettings = { ...clientGlobalSettings }

      if (!(clientGlobalSettings && clientGlobalSettings.clientId)) {
        const newGlobalSettings = newClientGlobalSettings({
          headquarter,
          client,
        })
        newState.clientGlobalSettings = newGlobalSettings
      }

      this.setState(newState)
    })(client)
  }

  render() {
    const {
      account,
      activeTab,
      clientSettings,
      selectedIndex,
      clientGlobalSettings,
    } = this.state

    const { newScheduleSettings, displayFailureMessage } = this.props

    return (
      <div className="flex flex-row w-full">
        {' '}
        {/* Similar to the styling on PageBody */}
        {this.state.sideBarVisible && this.renderSideBar()}
        {!this.state.sideBarVisible && (
          <div className="pt-5 pr-10">
            <button>
              <FaArrowCircleRight
                onClick={() => this.setState({ sideBarVisible: true })}
              />
            </button>
          </div>
        )}
        {/* PANEL */}
        <div
          className={`${
            this.state.sideBarVisible ? 'sidebar-body' : ''
          } w-[1400px] overflow-x-scroll mt-3`}
        >
          {' '}
          {/* Need to override width: 100% to get scrolling to work right */}
          <div className="sidebar-body-inner">
            <div
              style={{ width: 'auto', position: 'static', overflowX: 'scroll' }}
            >
              {account ? (
                <FlexContainer>
                  <Panel>
                    <Label>Client</Label>
                    <p>{(account as any).name}</p>
                    <YSpacing height="20px" />
                    <Dropdown
                      label="Selected Location"
                      value={selectedIndex}
                      onChange={(e) =>
                        this.updateSelectedIndex(Number(e.target.value) || 0)
                      }
                    >
                      {clientSettings.map((settings: any, i) => (
                        <option key={settings.id} value={i}>
                          {settings.name ||
                            (account
                              ? `${(account as any).name} (default)`
                              : '')}
                        </option>
                      ))}
                    </Dropdown>
                  </Panel>
                </FlexContainer>
              ) : (
                <></>
              )}
              {activeTab !== 'reports' && activeTab !== 'schedule' && (
                <FlexContainer>
                  <Tab
                    onClick={() => this.setState({ activeTab: CLIENT_TAB })}
                    isActive={activeTab === CLIENT_TAB}
                  >
                    Client Details
                  </Tab>
                  <Tab
                    onClick={() =>
                      this.setState({ activeTab: NOTIFICATIONS_TAB })
                    }
                    isActive={activeTab === NOTIFICATIONS_TAB}
                  >
                    Notification Settings
                  </Tab>
                  <Tab
                    onClick={() =>
                      this.setState({ activeTab: SCHEDULE_SETTINGS_TAB })
                    }
                    isActive={activeTab === SCHEDULE_SETTINGS_TAB}
                  >
                    Default Schedule Settings
                  </Tab>
                  <Tab
                    onClick={() => this.setState({ activeTab: CALENDAR_TAB })}
                    isActive={activeTab === CALENDAR_TAB}
                  >
                    Calendar
                  </Tab>
                </FlexContainer>
              )}

              <YSpacing height="20px" />
              {activeTab === CLIENT_TAB && account && (
                <GroupOrdersPage
                  client={account}
                  clientSettings={clientSettings}
                  selectedIndex={selectedIndex}
                  clientGlobalSettings={clientGlobalSettings}
                  updateClientSettings={this.updateClientSettings}
                  updateClientGlobalSettings={this.updateClientGlobalSettings}
                  updateSelectedIndex={this.updateSelectedIndex}
                  onSaveClientSettings={this.onSaveClientSettings}
                  onSaveClientGlobalSettings={this.onSaveClientGlobalSettings}
                  toggleDeliveryFeeSettingsModal={
                    this.toggleDeliveryFeeSettingsModal
                  }
                  toggleStaffingFeeSettingsModal={
                    this.toggleStaffingFeeSettingsModal
                  }
                />
              )}
              {activeTab === NOTIFICATIONS_TAB &&
                account &&
                (clientSettings as any)[selectedIndex] && (
                  <NotificationsSettingsView
                    clientSettings={
                      (clientSettings as any)[selectedIndex] as ClientSettings
                    }
                    displayFailureMessage={displayFailureMessage}
                    saveClientSettings={this.props.saveClientSettings}
                    onClientSettingsUpdateSuccess={
                      this.onClientSettingsUpdateSuccess
                    }
                    searchSlackAuthData={this.props.searchSlackAuthData}
                    searchPopUpCustomers={this.props.searchPopUpCustomers}
                  />
                )}
              {activeTab === SCHEDULE_SETTINGS_TAB && account && (
                <GroupOrdersSecheduleSettings
                  selectedIndex={selectedIndex}
                  clientSettings={clientSettings}
                  newScheduleSettings={newScheduleSettings}
                  displayFailureMessage={displayFailureMessage}
                  updateClientSettings={this.updateClientSettings}
                  onSaveClientSettings={this.onSaveClientSettings}
                />
              )}
              {activeTab === CALENDAR_TAB && account && (
                <GroupOrdersCalendar
                  client={account}
                  clientSettings={clientSettings}
                  selectedIndex={selectedIndex}
                />
              )}
              {activeTab === 'reports' && (
                <FlexContainer>
                  <GroupOrderReportsPage />
                  <XSpacing width="20px" />
                  <GroupOrderReviewsReportsPage />
                </FlexContainer>
              )}
              {activeTab === 'schedule' && <GroupOrderSchedulePlanner />}
              <YSpacing height="20px" />
            </div>
          </div>
        </div>
        {this.state.deliveryFeeSettingsModalOpen && (
          <EditGroupOrderDeliveryFeeSettingsModal
            clientName={this.props.client ? this.props.client.name : ''}
            clientGlobalSettings={clientGlobalSettings}
            onSaveClientGlobalSettings={this.onSaveClientGlobalSettings}
            closeModal={() => this.toggleDeliveryFeeSettingsModal(false)}
            flashError={this.flashError}
          />
        )}
        {this.state.staffingFeeSettingsModalOpen && (
          <EditGroupOrderStaffingFeeSettingsModal
            clientName={this.props.client ? this.props.client.name : ''}
            clientGlobalSettings={clientGlobalSettings}
            clientSettingsCollection={clientSettings}
            onSaveStaffingFeeSettings={this.onSaveStaffingFeeSettings}
            closeModal={() => this.toggleStaffingFeeSettingsModal(false)}
            flashError={this.flashError}
          />
        )}
        {this.state.warningsModalOpen && (
          <ValidationsModal
            onClose={this.onCloseWarningsModal}
            onSave={this.onSaveWithWarnings}
            validationErrors={this.state.validationErrors}
            validationWarnings={this.state.validationWarnings}
          />
        )}
      </div>
    )
  }
}

export default GroupOrdersMaster
