import { connect } from 'react-redux'

import { MenuItems } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { chef } = state
  const chefId = chef && chef.id
  const { mainMenuItems, sideMenuItems, otherMenuItems } = chef || {}
  let menuItems
  if (mainMenuItems && sideMenuItems && otherMenuItems) {
    menuItems = [...mainMenuItems, ...sideMenuItems, ...otherMenuItems]
  }

  return {
    chefId,
    menuItems,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, UIService, SettingsService } = services
  const {
    pDefaultMenuFields,
    pStateToReduxMenuItem,
    pToMenuItemVerifications,
  } = presenters
  const {
    pResponseMenuItem,
    pResponseMenuItems,
    pResponseSettings,
    pRequestBulkUpdateMenuItem,
    pRequestSettings,
    pRequestUpdateItemImages,
    pRequestUpdateMarketplaceAddOn,
  } = presenters.Api
  const {
    BulkRestoreMenuItems,
    CloseChildMenuItemModal,
    DelayedUpdateMenuItem,
    DeleteMenuItems,
    EditMenuItem,
    LoadChefMenuItems,
    LoadMenuItem,
    LoadSettings,
    NewMenuItem,
    RetryOnTimeout,
    SaveMenuItem,
    LoadMenuItemRatings,
    ShowCopyMenuItems,
  } = coordinators

  const editMenuItem = EditMenuItem({
    ChefService,
    RestService,
    UIService,
    pResponseMenuItem,
  })
  const loadMenuItem = LoadMenuItem({
    RestService,
    pResponseMenuItem,
    HandleError,
  })
  const loadChefMenuItems = LoadChefMenuItems({
    ChefService,
    RestService,
    pResponseMenuItems,
  })
  const loadMenuItemRatings = LoadMenuItemRatings({ RestService })
  const newMenuItem = NewMenuItem({
    ChefService,
    UIService,
    pDefaultMenuFields,
  })
  const deleteMenuItems = DeleteMenuItems({
    ChefService,
    UIService,
    RestService,
    pResponseMenuItems,
    LoadChefMenuItems,
    HandleError,
  })

  const bulkRestoreMenuItems = BulkRestoreMenuItems({
    ChefService,
    UIService,
    RestService,
    pResponseMenuItems,
    LoadChefMenuItems,
    HandleError,
  })
  const loadMenuItemSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('menuItems')
  const saveMenuItem = SaveMenuItem({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateMarketplaceAddOn,
    pRequestUpdateMenuItem: pRequestBulkUpdateMenuItem,
    pRequestUpdateItemImages,
    pResponseMenuItem,
    pResponseMenuItems,
    CloseChildMenuItemModal,
    EditMenuItem,
    HandleError,
    pStateToReduxMenuItem,
  })
  const delayedUpdateMenuItem = DelayedUpdateMenuItem({
    ChefService,
    UIService,
    pStateToReduxMenuItem,
  })
  const showCopyMenuItem = (menuItemId) =>
    UIService.EditMenuItem.showCopyMenuItem(menuItemId)

  const copyMenuItems = ShowCopyMenuItems({ UIService })

  return {
    bulkRestoreMenuItems,
    delayedUpdateMenuItem,
    deleteMenuItems,
    editMenuItem,
    loadChefMenuItems,
    loadMenuItemRatings,
    loadMenuItem,
    loadMenuItemSettings,
    newMenuItem,
    pToMenuItemVerifications,
    showCopyMenuItem,
    saveMenuItem,
    copyMenuItems,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItems)
