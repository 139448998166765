import React from 'react'
import PropTypes from 'prop-types'
import Panel from '@components/common/Panel'
import FlexContainer from '@components/common/FlexContainer'
import { LabelInfo } from '@components/common/form'

const AccountListGroup = (props) => {
  const { user, currentHeadquarter, hubspotUser } = props
  if (!user || !currentHeadquarter) {
    return null
  }

  const { email, isVip, name, phoneNumber, roles = [] } = user

  const rolesList = roles
    .filter((r) => r.headquarterId === currentHeadquarter.id)
    .map((r) => r.name)

  return (
    <Panel width="100%" maxWidth="450px" heading="Account">
      <FlexContainer justifyContent="space-between" flexWrap="wrap">
        <LabelInfo
          label="Name"
          value={name}
          width="48%"
          testId="name"
          marginBottom="20px"
        />
        <LabelInfo
          label="Email"
          value={email}
          width="48%"
          testId="email"
          marginBottom="20px"
        />
        <LabelInfo
          label="Phone Number"
          value={phoneNumber ? phoneNumber : 'N/A'}
          width="48%"
          marginBottom="20px"
        />
        <LabelInfo
          label="Roles"
          value={rolesList.length > 0 ? rolesList.join(', ') : 'N/A'}
          width="48%"
          marginBottom="20px"
        />
        <LabelInfo label="VIP" value={isVip ? 'Yes' : 'No'} width="48%" />
        <LabelInfo
          label="Connected Hubspot Rep"
          value={hubspotUser.name ? hubspotUser.name : 'N/A'}
          width="48%"
        />
      </FlexContainer>
    </Panel>
  )
}

AccountListGroup.propTypes = {
  currentHeadquarter: PropTypes.object,
  user: PropTypes.object,
  hubspotUser: PropTypes.object,
}

export default AccountListGroup
