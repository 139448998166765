import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/common/modal/Modal'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { Button, Input, LinkText } from '@components/common/form'

const initialState = {
  id: '',
  headquarter: {},
  zipcode: '',
}

class EditDeliveryZoneModal extends Component {
  state = { ...initialState }

  componentDidMount() {
    const { deliveryZone } = this.props
    this.setState({ ...deliveryZone })
  }

  onInput = (field) => (value) => {
    this.setState({ [field]: value })
  }

  onHide = () => this.props.closeDeliveryZone()

  save = async () => {
    const { id, headquarter, zipcode } = this.state

    const { zips } = headquarter

    if (id) {
      const newZip = { id, zipcode }
      const index = zips.findIndex(
        (zip) => zip.zipcode === this.props.deliveryZone.zipcode,
      )
      zips[index] = newZip
      headquarter.zips = zips
      headquarter.updateZipcode = newZip
    } else {
      const newZip = { zipcode }
      zips.push(newZip)
      headquarter.zips = zips
    }

    await this.props.saveHeadquarter(headquarter)
    this.props.closeDeliveryZone()
  }

  render() {
    const { id, zipcode } = this.state

    return (
      <Modal
        title={id ? `Edit ${name}` : 'New Delivery Zone'}
        hideModal={this.onHide}
        color="#001940"
        maxWidth="400px"
        width="400px"
      >
        <Input
          label="Zip Code"
          value={zipcode}
          onChange={(e) => this.onInput('zipcode')(e.target.value)}
        />
        <FlexContainer justifyContent="flex-end" alignItems="center">
          <LinkText label="Cancel" onClick={this.onHide} color="#b30303" />
          <XSpacing width="40px" />
          <Button label="Save Delivery Zone" onClick={this.save} />
        </FlexContainer>
      </Modal>
    )
  }
}

EditDeliveryZoneModal.propTypes = {
  closeDeliveryZone: PropTypes.func,
  saveDeliveryZone: PropTypes.func,
  saveHeadquarter: PropTypes.func,
  loadDeliveryZones: PropTypes.func,

  show: PropTypes.bool,
  errors: PropTypes.object,
  deliveryZone: PropTypes.object,
}

export default EditDeliveryZoneModal
