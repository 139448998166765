import React from 'react'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'

class ExportCSVButton extends React.Component {
  state = { data: [] }

  componentDidMount() {
    const { data } = this.props
    const formattedData = this.formatData(data)
    this.setState({ data: formattedData })
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps
    if (data !== this.props.data) {
      const formattedData = this.formatData(data)
      this.setState({ data: formattedData })
    }
  }

  formatData = (data) => {
    const filteredData = []
    const columnHeaders = this.props.columns

    data.forEach((d) => {
      const columnData = {}
      columnHeaders.forEach((h) => {
        const columnName = h['header'] ? h['header'] : h['key']
        const columnKey = h['key']
        if (Object.prototype.hasOwnProperty.call(d, columnKey)) {
          columnData[columnName] = d[columnKey]
        } else {
          columnData[columnName] = ''
        }
      })
      filteredData.push(columnData)
    })

    return filteredData
  }

  render() {
    const { data: writeData } = this.state
    const { data, filename, text, style } = this.props
    const showButton = data && data.length > 0
    const buttonStyle = showButton ? { display: 'block' } : { display: 'none' }

    return (
      <div
        className="export-csv-button text-blue-600 underline"
        style={buttonStyle}
      >
        <CSVLink
          data={writeData}
          filename={filename}
          target="_blank"
          style={style}
        >
          {text}
        </CSVLink>
      </div>
    )
  }
}

ExportCSVButton.propTypes = {
  text: PropTypes.string,
  filename: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.arrayOf(PropTypes.object),
  style: PropTypes.object,
}

ExportCSVButton.defaultProps = {
  text: 'Export CSV',
}

export default ExportCSVButton
