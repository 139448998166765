import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BiPencil } from 'react-icons/bi'
import { FiMail } from 'react-icons/fi'
import ChefProfileListGroup from './ChefProfileListGroup'
import { AuthorizedInteractable } from '@containers/common/auth'
import { ButtonsBar } from '@res/styledComponents/index'
import FlexContainer from '@components/common/FlexContainer'

export class ChefProfile extends Component {
  onEditChef = (e) => {
    e.preventDefault()

    this.props.editChef()
  }

  onInviteChef = () => {
    const { chef, sendChefDashboardInvite } = this.props
    sendChefDashboardInvite(chef.email)
  }

  onAddChildChefs = (e) => {
    e.preventDefault()

    this.props.editChildChefs()
  }

  render() {
    const { chef } = this.props
    const { chefProfile } = chef

    return (
      <div>
        <ButtonsBar>
          <FlexContainer>
            <AuthorizedInteractable
              roles={['master admin', 'sales lead', 'chef lead']}
            >
              <button onClick={this.onEditChef}>
                <BiPencil />
                Edit
              </button>
            </AuthorizedInteractable>
            <button onClick={this.onInviteChef}>
              <FiMail /> Email Chef Dashboard Invite{' '}
            </button>
            <AuthorizedInteractable
              roles={['master admin', 'sales lead', 'chef lead']}
            >
              <button onClick={this.onAddChildChefs}>Add Child Chefs</button>
            </AuthorizedInteractable>
          </FlexContainer>
        </ButtonsBar>
        <ChefProfileListGroup
          chefProfile={chefProfile}
          address={chef.pickupAddress}
          headquarters={chef.headquarters}
        />
      </div>
    )
  }
}

ChefProfile.propTypes = {
  chef: PropTypes.object,

  editChef: PropTypes.func,
  editChildChefs: PropTypes.func,
  sendChefDashboardInvite: PropTypes.func,
}

export default ChefProfile
