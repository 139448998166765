import { SET_SETTINGS } from '@actions/settings'

const initialSettingsState = {}

export function settings(state = initialSettingsState, action) {
  const { settingsKey, settingsValue, type } = action

  switch (type) {
    case SET_SETTINGS: {
      const newState = { ...state }
      newState[settingsKey] = settingsValue

      return newState
    }
    default:
      return state
  }
}
