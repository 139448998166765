import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/common/modal/Modal'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import LinkText from '@components/common/form/LinkText'
import Button from '@components/common/form/Button'
import Input from '@components/common/form/Input'
import { colors } from '../../../../constants'
import {
  Checkbox,
  Dropdown,
  CurrencyInput,
  TextArea,
} from '@components/common/form'
import { Label } from '@res/styledComponents/index'
import { AuthorizedInteractable } from '@containers/common/auth'

const serviceItemInitialState = {
  price: 0.0,
  marketPrice: 0.0,
}

class EditServiceItemModal extends Component {
  state = { ...serviceItemInitialState }

  componentWillMount() {
    const {
      loadCateringSupplies,
      loadDiningSupplies,
      serviceItem,
      clearErrors,
    } = this.props
    this.setState({ ...serviceItem })
    loadCateringSupplies()
    loadDiningSupplies()
    clearErrors()
  }

  componentWillReceiveProps(nextProps) {
    const { serviceItem } = nextProps
    if (this.props.serviceItem !== nextProps.serviceItem) {
      const newState = { ...serviceItem }
      this.setState(newState)
    }
  }

  delayedUpdate = () => {
    this.props.delayedUpdateServiceItem(this.state)
  }

  /* Events */

  onChange = (key) => (e) => {
    const { value } = e.target
    this.setState({ [key]: value }, this.delayedUpdate)
  }

  onChangeServiceItem = (key, value) => {
    this.setState({ [key]: value }, () =>
      this.props.delayedUpdateServiceItem(this.state),
    )
  }

  onChangeRadioButton = (e) => {
    const { value: key, name } = e.target
    this.setState({ [name]: key }, this.delayedUpdate)
  }

  onChangeCheckbox = (e) => {
    const { checked, value: key } = e.target
    this.setState({ [key]: checked }, this.delayedUpdate)
  }

  onDelete = () => {
    const { close, deleteServiceItem } = this.props
    const { id, name } = this.state
    deleteServiceItem({ id, name })
    close()
  }

  onHide = () => {
    this.props.close()
  }

  onSave = () => {
    this.props.saveServiceItem(this.state)
  }

  renderCateringSupplies = () => {
    const { cateringSupplies = [], editCateringSupplyRow } = this.state
    const { allCateringSupplies } = this.props
    const supplies = cateringSupplies.filter((supply) => !supply._destroy)

    return (
      <div>
        <table className="table table-hover page">
          <thead>
            <tr>
              <th>Name</th>
              <th>Fixed Count per Order</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {supplies.length === 0 && (
              <tr>
                <td>No catering supplies.</td>
              </tr>
            )}
            {supplies.map((supply, i) => {
              const { suppliedForId, name, supplyUsageRate } = supply

              if (suppliedForId && i != editCateringSupplyRow) {
                return (
                  <tr key={i}>
                    <td>{name}</td>
                    <td>{parseFloat(supplyUsageRate).toFixed(2)}</td>
                    <td>
                      <button
                        className="button-neutral"
                        onClick={(e) => {
                          e.preventDefault()
                          this.setState({ editCateringSupplyRow: i })
                        }}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                )
              } else {
                return (
                  <tr key={i}>
                    <td>
                      <Dropdown
                        label=""
                        marginBottom="0"
                        width="300px"
                        onChange={(e) => {
                          this.setState({
                            cateringSupplies: [
                              ...cateringSupplies.slice(0, i),
                              { ...supply, id: e.target.value },
                              ...cateringSupplies.slice(i + 1),
                            ],
                          })
                        }}
                      >
                        <option>Not Selected</option>
                        {allCateringSupplies.map((supply, i) => (
                          <option key={i} value={supply.id}>
                            {supply.name}
                          </option>
                        ))}
                      </Dropdown>
                    </td>
                    <td>
                      <Input
                        type="text"
                        value={supplyUsageRate}
                        marginBottom="0"
                        onChange={(e) => {
                          const val = e.target.value || ''
                          this.setState({
                            cateringSupplies: [
                              ...cateringSupplies.slice(0, i),
                              {
                                ...supply,
                                supplyUsageRate: /[0-9]*\.*[0-9]*/.exec(val)[0],
                              },
                              ...cateringSupplies.slice(i + 1),
                            ],
                          })
                        }}
                      />
                    </td>
                    {this.renderCateringSupplyButtons(supply, i)}
                  </tr>
                )
              }
            })}
          </tbody>
        </table>
        <LinkText
          onClick={(event) => {
            event.preventDefault()
            this.setState({
              cateringSupplies: [
                ...cateringSupplies,
                { costPerOrder: 0, supplyUsageRate: 1 },
              ],
            })
          }}
          label="Add Catering Supply"
        />
      </div>
    )
  }

  renderCateringSupplyButtons = (cateringSupply, i) => {
    const { cateringSupplies } = this.state
    const deleteNew = (event) => {
      event.preventDefault()
      this.setState({
        cateringSupplies: [
          ...cateringSupplies.slice(0, i),
          ...cateringSupplies.slice(i + 1),
        ],
      })
    }
    const deleteExisting = (event) => {
      event.preventDefault()
      this.setState({
        cateringSupplies: [
          ...cateringSupplies.slice(0, i),
          { ...cateringSupply, _destroy: true },
          ...cateringSupplies.slice(i + 1),
        ],
      })
    }
    const unselect = (event) => {
      event.preventDefault()
      this.setState({ editCateringSupplyRow: null })
    }
    const existing = cateringSupply.suppliedForId
    const deleteRecord = existing ? deleteExisting : deleteNew

    return (
      <td>
        {existing && (
          <button
            className="button-neutral"
            key="clearCateringSupply"
            onClick={unselect}
          >
            Unselect
          </button>
        )}
        <button
          className="button-neutral"
          key="deleteCateringSupply"
          onClick={deleteRecord}
        >
          Delete
        </button>
      </td>
    )
  }

  renderDiningSupplies = () => {
    const { errors } = this.props
    const { diningSupplies = [], editDiningSupplyRow } = this.state
    const { allDiningSupplies } = this.props
    const supplies = diningSupplies.filter((supply) => !supply._destroy)

    return (
      <div>
        <table className="table table-hover page">
          <thead>
            <tr>
              <th>Name</th>
              <th>Usage Rate per Head Count</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {supplies.length === 0 && (
              <tr>
                <td>No dining supplies.</td>
              </tr>
            )}
            {supplies.map((supply, i) => {
              const { suppliedForId, name, supplyUsageRate } = supply

              if (suppliedForId && i != editDiningSupplyRow) {
                return (
                  <tr key={i}>
                    <td>{name}</td>
                    <td>%{(supplyUsageRate * 100).toFixed(0)}</td>
                    <td>
                      <button
                        className="button-neutral"
                        onClick={(e) => {
                          e.preventDefault()
                          this.setState({ editDiningSupplyRow: i })
                        }}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                )
              } else {
                return (
                  <tr key={i}>
                    <td>
                      <Dropdown
                        label=""
                        marginBottom="0"
                        width="300px"
                        onChange={(e) => {
                          this.setState({
                            diningSupplies: [
                              ...diningSupplies.slice(0, i),
                              { ...supply, id: e.target.value },
                              ...diningSupplies.slice(i + 1),
                            ],
                          })
                        }}
                      >
                        <option>Not Selected</option>
                        {allDiningSupplies.map((supply, i) => (
                          <option key={i} value={supply.id}>
                            {supply.name}
                          </option>
                        ))}
                      </Dropdown>
                    </td>
                    <td>
                      <Input
                        type="text"
                        value={supplyUsageRate}
                        error={
                          supplyUsageRate > 1 &&
                          errors['serviceItemDiningSupplies.supplyUsageRate']
                        }
                        onChange={(e) => {
                          const val = e.target.value || ''
                          this.setState({
                            diningSupplies: [
                              ...diningSupplies.slice(0, i),
                              {
                                ...supply,
                                supplyUsageRate: /[0-9]*\.*[0-9]*/.exec(val)[0],
                              },
                              ...diningSupplies.slice(i + 1),
                            ],
                          })
                        }}
                      />
                    </td>
                    {this.renderDiningSupplyButtons(supply, i)}
                  </tr>
                )
              }
            })}
          </tbody>
        </table>
        <LinkText
          onClick={(event) => {
            event.preventDefault()
            this.setState({
              diningSupplies: [
                ...diningSupplies,
                { costPerOrder: 0, supplyUsageRate: 1 },
              ],
            })
          }}
          label="Add Dining Supply"
        />
      </div>
    )
  }

  renderDiningSupplyButtons = (diningSupply, i) => {
    const { diningSupplies } = this.state
    const deleteNew = (event) => {
      event.preventDefault()
      this.setState({
        diningSupplies: [
          ...diningSupplies.slice(0, i),
          ...diningSupplies.slice(i + 1),
        ],
      })
    }
    const deleteExisting = (event) => {
      event.preventDefault()
      this.setState({
        diningSupplies: [
          ...diningSupplies.slice(0, i),
          { ...diningSupply, _destroy: true },
          ...diningSupplies.slice(i + 1),
        ],
      })
    }
    const unselect = (event) => {
      event.preventDefault()
      this.setState({ editDiningSupplyRow: null })
    }
    const existing = diningSupply.suppliedForId
    const deleteRecord = existing ? deleteExisting : deleteNew

    return (
      <td>
        {existing && (
          <button
            className="button-neutral"
            key="clearDiningSupply"
            onClick={unselect}
          >
            Unselect
          </button>
        )}
        <button
          className="button-neutral"
          key="deleteDiningSupply"
          onClick={deleteRecord}
        >
          Delete
        </button>
      </td>
    )
  }

  render() {
    const { errors, show } = this.props
    const {
      cost,
      description,
      id,
      isEnabled,
      isApproved,
      name,
      price,
      pricingRate,
    } = this.state
    const title = id ? 'Edit Service Item' : 'New Service Item'

    return (
      <Modal
        show={show}
        title={title}
        hideModal={this.onHide}
        color="#001940"
        width="800px"
      >
        <FlexContainer justifyContent="space-between" flexWrap="wrap">
          <Input
            width="31%"
            label="Service Item Name"
            value={name}
            error={errors.name}
            onChange={this.onChange('name')}
          />
          <CurrencyInput
            width="31%"
            label="Vendor Price"
            value={cost}
            onChange={(value) => this.onChangeServiceItem('cost', value)}
          />
          <CurrencyInput
            width="31%"
            label="Market Price"
            value={price}
            onChange={(value) => this.onChangeServiceItem('price', value)}
          />
          <TextArea
            width="31%"
            height="100px"
            label="Description"
            value={description}
            onChange={this.onChange('description')}
          />
          <Input
            width="31%"
            label="Pricing Rate (per 30 mins, per 1 lb)"
            type="text"
            value={pricingRate}
            onChange={this.onChange('pricingRate')}
          />
          <FlexContainer width="31%" flexDirection="column">
            <Label>Status</Label>
            <YSpacing height="5px" />
            <Checkbox
              label="Live"
              value="isEnabled"
              marginBottom="5px"
              checked={isEnabled}
              onChange={this.onChangeCheckbox}
            />
            <Checkbox
              label="Approved"
              marginBottom="5px"
              value="isApproved"
              checked={isApproved}
              onChange={this.onChangeCheckbox}
            />
          </FlexContainer>
          <FlexContainer width="31%" />
        </FlexContainer>
        <Label> Dining Supplies </Label>
        {/* Right column */ this.renderDiningSupplies()}
        <YSpacing height="30px" />
        <Label> Catering Supplies </Label>
        {/* Right column */ this.renderCateringSupplies()}
        <FlexContainer alignItems="center" justifyContent="flex-end">
          <LinkText
            color={colors.gray300}
            onClick={this.onHide}
            label="Cancel"
          />
          {id && (
            <AuthorizedInteractable
              roles={['master admin', 'sales lead', 'chef lead']}
            >
              <LinkText
                color={colors.violet}
                onClick={this.onDelete}
                label="Delete"
              />
            </AuthorizedInteractable>
          )}
          <XSpacing width="20px" />
          <AuthorizedInteractable
            roles={['master admin', 'sales lead', 'chef lead']}
          >
            <Button onClick={this.onSave} label="Save" />
          </AuthorizedInteractable>
        </FlexContainer>
      </Modal>
    )
  }
}

EditServiceItemModal.propTypes = {
  allCateringSupplies: PropTypes.arrayOf(PropTypes.object),
  allDiningSupplies: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.object,
  serviceItem: PropTypes.object,
  show: PropTypes.bool,

  clearErrors: PropTypes.func,
  close: PropTypes.func,
  delayedUpdateServiceItem: PropTypes.func,
  deleteServiceItem: PropTypes.func,
  displayAlert: PropTypes.func,
  loadCateringSupplies: PropTypes.func,
  loadDiningSupplies: PropTypes.func,
  saveServiceItem: PropTypes.func,
}

export default EditServiceItemModal
