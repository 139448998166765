import { connect } from 'react-redux'

import { EditChefModal } from '@components/chef/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'
import { GetCurrentHq } from '~/utils'

const mapStateToProps = (state) => {
  const { editChef, editChefModal, headquarter, settings, user } = state
  const { userHeadquarters } = headquarter
  const { show, isLoading } = editChefModal
  const chefsSettings = settings && settings['chefs']
  const errors = state.errors.chefModal || {}

  const currentHq = GetCurrentHq({
    headquarters: userHeadquarters,
    user,
  })

  return {
    locale: user.locale,
    chef: editChef,
    currentHq,
    errors,
    settings: chefsSettings,
    show,
    isLoading,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, SettingsService, UIService } = services
  const { pStateToReduxChef } = presenters
  const {
    pRequestSettings,
    pResponseSettings,
    pRequestUpdateChef,
    pResponseChef,
    pResponseChefs,
  } = presenters.Api
  const {
    DelayedUpdateChef,
    DeleteChef,
    LoadSettings,
    RetryOnTimeout,
    SaveChef,
    LoadExistingChefs,
    SelectExistingChef,
    UploadChefLogoImage,
    UploadChefCustomMenuImage,
    CheckPdfImage,
  } = coordinators

  const { close } = UIService.EditChef
  const clearErrors = UIService.Errors.clear
  const saveChef = SaveChef({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateChef,
    pResponseChef,
    HandleError,
  })
  const delayedUpdateChef = DelayedUpdateChef({
    ChefService,
    UIService,
    pStateToReduxChef,
  })
  const deleteChef = DeleteChef({
    ChefService,
    RestService,
    UIService,
    pResponseChefs,
    HandleError,
  })
  const loadChefsSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('chefs')
  const loadChefs = LoadExistingChefs({ RestService, pResponseChefs })
  const selectChef = SelectExistingChef({ RestService, pResponseChef })
  const uploadChefLogoImage = UploadChefLogoImage({ RestService, HandleError })
  const uploadChefCustomMenuImage = UploadChefCustomMenuImage({
    RestService,
    HandleError,
  })
  const checkPdfImage = CheckPdfImage({ RestService, HandleError })
  const { displayFailureMessage } = UIService.FlashMessage

  return {
    clearErrors,
    close,
    delayedUpdateChef,
    deleteChef,
    loadChefsSettings,
    saveChef,
    loadChefs,
    selectChef,
    uploadChefLogoImage,
    uploadChefCustomMenuImage,
    checkPdfImage,
    displayFailureMessage,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditChefModal)
