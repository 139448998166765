import { BaseService } from 'hungry-core2'
import {
  goBack,
  goForward,
  push as routerPush,
  replace as routerReplace,
} from 'react-router-redux'

export class RouterService extends BaseService {
  push(uri) {
    this.dispatch(routerPush(uri))
  }

  replace(uri) {
    this.dispatch(routerReplace(uri))
  }

  back() {
    this.dispatch(goBack())
  }

  forward() {
    this.dispatch(goForward())
  }

  getQuery(key) {
    const { search } = this.getState().router.location
    const params = new URLSearchParams(search)

    return params.get(key)
  }
}
