import React, { Component } from 'react'

import { DefaultStyles as theme } from './theme'
import { PageBody } from '@res/styledComponents/index'
import { VXOrdersPage, VXLookupsPage } from '@containers/virtualExperiences'
import Tab from '@components/common/Tab'
import Sidebar from '@components/sidebar/Sidebar'
import SidebarListItem from '@components/sidebar/SidebarListItem'

const sidebarPages = ['Orders', 'Lookups']

class VXMaster extends Component {
  state = {
    page: 'Orders',
  }

  render() {
    const { page } = this.state

    return (
      <PageBody>
        {/* SIDEBAR */}
        <Sidebar heading="Virtual Xperiences">
          {sidebarPages.map((p) => {
            const selected = p === page

            return (
              <SidebarListItem
                key={p}
                isSelected={selected}
                onClick={() => {
                  this.setState({ page: p })
                }}
              >
                {p}
              </SidebarListItem>
            )
          })}
        </Sidebar>
        {/* PANEL */}
        <div className="sidebar-body">
          <div className="sidebar-body-inner">
            <Tab name="Orders" activeTab={page}>
              <VXOrdersPage theme={theme} />
            </Tab>
            <Tab name="Lookups" activeTab={page}>
              <VXLookupsPage theme={theme} />
            </Tab>
          </div>
        </div>
      </PageBody>
    )
  }
}

export default VXMaster
