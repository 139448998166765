import React from 'react'
import PropTypes from 'prop-types'
import { Panel } from 'react-bootstrap'

const FormSection = (props) => {
  const { backgroundColor, children, title } = props

  return (
    <Panel className="page" style={{ backgroundColor }}>
      {title && (
        <h4>
          <center> {title} </center>
        </h4>
      )}

      <fieldset>{children}</fieldset>
    </Panel>
  )
}

FormSection.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
}

FormSection.defaultProps = {
  backgroundColor: 'white',
}

export default FormSection
