import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FlashMessage } from '@components/common'

class FlashMessages extends Component {
  renderAlert = (data, i) => {
    const onHide = () => {
      this.props.dismissMessage(data.timestamp)
    }

    return (
      <FlashMessage
        key={`alert_${data.timestamp}`}
        alertType={data.alertType}
        onHide={onHide}
        message={data.message}
        options={data.options}
        top={20 + i * 80}
      />
    )
  }

  render() {
    const { flashMessages } = this.props

    return (
      <div className="alert-container">
        {flashMessages.map((data, i) => this.renderAlert(data, i))}
      </div>
    )
  }
}

FlashMessages.propTypes = {
  flashMessages: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.number,
      message: PropTypes.string,
      options: PropTypes.object,
      alertType: PropTypes.string,
    }),
  ),

  dismissMessage: PropTypes.func,
}

export default FlashMessages
