import { connect } from 'react-redux'

import { BatchAccountExecutiveSection } from '@components/batchOrder/edit/sections'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'

const mapDispatchToProps = () => {
  const { RestService } = services
  const { LoadAccountExecutives } = coordinators
  const { pResponseAccountExecutives } = presenters.Api
  const loadAccountExecutives = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })(false) // is vcx

  return { loadAccountExecutives }
}

export default connect(
  undefined,
  mapDispatchToProps,
)(BatchAccountExecutiveSection)
