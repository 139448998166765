export const RetryOnTimeout = async (request, retries = 3) => {
  let successful = false
  let tries = 0
  while (!successful && tries < retries) {
    try {
      tries += 1
      successful = true
      const response = await request()

      return response
    } catch (error) {
      successful = false
      if (tries === retries) {
        throw error
      }
    }
  }

  return null
}
