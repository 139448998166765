import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

class SelectedChildOrder extends Component {
  // possibly unnecessary. only need props since it's not editable?
  state = {
    // unmutated
    id: undefined,
    orderNumber: '',
    clientSetUpTime: undefined,
    concept: '',
    contact: undefined,
    total: undefined,
  }

  componentDidMount() {
    const newState = {}
    for (const k in this.state) {
      newState[k] = this.props.order[k]
    }
    this.setState(newState)
  }

  componentWillReceiveProps(nextProps) {
    const { order } = nextProps
    if (order !== this.props.order) {
      const newState = {}
      for (const k in this.state) {
        newState[k] = order[k]
      }
      this.setState(newState)
    }
  }

  render() {
    const { order, onRemove } = this.props
    const { total, orderNumber, clientSetUpTime, concept, contact } = this.state

    const cateringDateString =
      clientSetUpTime && Moment(clientSetUpTime).format('MM/DD')

    return (
      <tbody>
        <tr className="menu-item-table__input-fields">
          <td
            className="minus-sign"
            id="remove-bundled-order"
            onClick={onRemove(order)}
          >
            {' '}
            ✕{' '}
          </td>
          <td>
            <div id="bundled-order-number">{orderNumber}</div>
          </td>
          <td>
            <div id="bundled-catering-date">{cateringDateString}</div>
          </td>
          <td>
            <div id="bundled-poc-name">
              {(contact && contact.name) || 'none selected'}
            </div>
          </td>
          <td>
            <div id="bundled-concept-name">
              {(concept && concept.name) || 'custom'}
            </div>
          </td>
          <td>
            <div id="bundled-total">{`$${total}`}</div>
          </td>
        </tr>
      </tbody>
    )
  }
}

SelectedChildOrder.propTypes = {
  order: PropTypes.object,

  onClickOrderNumber: PropTypes.func,
  onRemove: PropTypes.func,
}

export default SelectedChildOrder
