import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class ChefService extends BaseService {
  appendSchedule = ({
    disabledWeeks,
    recurringSlotInfo,
    recurringSlots,
    timeSlots,
  }) => {
    this.dispatch(
      actions.appendScheduleTimeSlots({
        disabledWeeks,
        recurringSlotInfo,
        recurringSlots,
        timeSlots,
      }),
    )
  }

  clearChef = () => {
    this.dispatch(actions.clearChef())
  }

  clearEditChef = () => {
    this.dispatch(actions.clearEditChef())
  }

  clearEditChefContact = () => {
    this.dispatch(actions.clearEditChefContact())
  }

  clearEditConcept = () => {
    this.dispatch(actions.clearEditConcept())
  }

  clearEditMenuItem = () => {
    this.dispatch(actions.clearEditMenuItem())
  }

  clearEditRecipe = () => {
    this.dispatch(actions.clearEditRecipe())
  }

  clearEditServiceItem = () => {
    this.dispatch(actions.clearEditServiceItem())
  }

  clearEditVirtualItem = () => {
    this.dispatch(actions.clearEditVirtualItem())
  }

  clearEditVirtualKit = () => {
    this.dispatch(actions.clearEditVirtualKit())
  }

  clearEditSnackPack = () => {
    this.dispatch(actions.clearEditSnackPack())
  }

  getCurrentChefId = () => {
    return this.getState().chef.id
  }

  enableWeek = (week) => {
    this.dispatch(actions.removeScheduleDisabledWeek(week))
  }

  setChef = (chef) => {
    this.dispatch(actions.setChef(chef))
  }

  setChefs = (chefs) => {
    this.dispatch(actions.setChefs(chefs))
  }

  setChefMenuItems = (menuItems) => {
    this.dispatch(actions.setChefMenuItems(menuItems))
  }

  setChefServiceItems = (serviceItems) => {
    this.dispatch(actions.setChefServiceItems(serviceItems))
  }

  setChefVirtualItems = (virtualItems) => {
    this.dispatch(actions.setChefVirtualItems(virtualItems))
  }

  setChefVirtualKits = (virtualKits) => {
    this.dispatch(actions.setChefVirtualKits(virtualKits))
  }

  setChefSnackPacks = (snackPacks) => {
    this.dispatch(actions.setChefSnackPacks(snackPacks))
  }

  setConcepts = (concepts) => {
    this.dispatch(actions.setConcepts(concepts))
  }

  setEditChef = () => {
    const { chef } = this.getState()
    this.dispatch(actions.setEditChef(chef))
  }

  setEditChefContact = (contact) => {
    this.dispatch(actions.setEditChefContact(contact))
  }

  setEditConcept = (concept) => {
    this.dispatch(actions.setEditConcept(concept))
  }

  setEditMenuItem = (menuItem, menuItemSettings = {}) => {
    if (
      menuItem.packaging &&
      !menuItem.servingsPerPkg &&
      menuItemSettings.defaultServingsPerPackage
    ) {
      menuItem.servingsPerPkg =
        menuItemSettings['defaultServingsPerPackage'][menuItem.packaging]
    }
    this.dispatch(actions.setEditMenuItem(menuItem))
  }

  setEditChildMenuItem = (menuItem, menuItemSettings = {}) => {
    if (
      menuItem.packaging &&
      !menuItem.servingsPerPkg &&
      menuItemSettings.defaultServingsPerPackage
    ) {
      menuItem.servingsPerPkg =
        menuItemSettings['defaultServingsPerPackage'][menuItem.packaging]
    }
    this.dispatch(actions.setEditChildMenuItem(menuItem))
  }

  setEditServiceItem = (serviceItem) => {
    this.dispatch(actions.setEditServiceItem(serviceItem))
  }

  setEditVirtualItem = (virtualItem) => {
    this.dispatch(actions.setEditVirtualItem(virtualItem))
  }

  setEditVirtualKit = (virtualKit) => {
    this.dispatch(actions.setEditVirtualKit(virtualKit))
  }

  setEditRecipe = (recipe) => {
    this.dispatch(actions.setEditRecipe(recipe))
  }

  setEditSnackPack = (snackPack) => {
    this.dispatch(actions.setEditSnackPack(snackPack))
  }

  setRecipes = (recipes) => {
    this.dispatch(actions.setRecipes(recipes))
  }

  setSchedule = ({
    disabledWeeks,
    recurringSlotInfo,
    recurringSlots,
    timeSlots,
  }) => {
    this.dispatch(
      actions.setScheduleTimeSlots({
        disabledWeeks,
        recurringSlotInfo,
        recurringSlots,
        timeSlots,
      }),
    )
  }

  updateEditChef = (chef) => {
    this.dispatch(actions.updateEditChef(chef))
  }

  updateEditChefContact = (contact) => {
    this.dispatch(actions.updateEditChefContact(contact))
  }

  updateEditConcept = (concept) => {
    this.dispatch(actions.updateEditConcept(concept))
  }

  updateEditMenuItem = (menuItem) => {
    this.dispatch(actions.updateEditMenuItem(menuItem))
  }

  updateEditRecipe = (recipe) => {
    this.dispatch(actions.updateEditRecipe(recipe))
  }

  updateEditServiceItem = (serviceItem) => {
    this.dispatch(actions.updateEditServiceItem(serviceItem))
  }

  updateEditVirtualItem = (virtualItem) => {
    this.dispatch(actions.updateEditVirtualItem(virtualItem))
  }

  updateEditVirtualKit = (virtualKit) => {
    this.dispatch(actions.updateEditVirtualKit(virtualKit))
  }

  updateEditSnackPack = (snackPack) => {
    this.dispatch(actions.updateEditSnackPack(snackPack))
  }

  updateTimeSlot = (timeSlot) => {
    this.dispatch(actions.updateScheduleTimeSlot(timeSlot))
  }
}
