export const SaveVirtualExperience =
  ({ RestService, UIService, HandleError }) =>
  async (vx) => {
    try {
      if (vx.id) {
        await RestService.put(`/virtual-xp/${vx.id}`, vx)
      } else {
        await RestService.post('/virtual-xp', vx)
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      `VX ${vx.name} successfully updated`,
    )

    return true
  }

export const SearchVirtualExperiences =
  ({ RestService, HandleError }) =>
  async ({ search, statuses, enabled, limit = 100, offset = 0 }) => {
    try {
      const params = {
        offset,
        limit,
      }
      if (search) {
        params.search = search
      }

      if (statuses) {
        params.status = statuses
      }

      if (enabled) {
        params.enabled = enabled
      }

      return await RestService.get('/virtual-xp', params)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const GetVirtualExperience =
  ({ RestService, HandleError }) =>
  async (id) => {
    try {
      return await RestService.get(`/virtual-xp/${id}`)
    } catch (error) {
      HandleError({ error })
    }
  }

export const DeleteVirtualExperience =
  ({ RestService, UIService, HandleError }) =>
  async ({ id, name }) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete VX "${name}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/virtual-xp/${id}`)
      } catch (error) {
        HandleError({ error })

        return false
      }

      return true
    }

    return false
  }

export const SaveAddOn =
  ({ RestService, UIService, HandleError }) =>
  async (item) => {
    try {
      if (item.id) {
        await RestService.put(`/virtual-xp/kit-item/${item.id}`, item)
      } else {
        await RestService.post('/virtual-xp/kit-item', item)
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      `Add-On ${item.name} successfully updated`,
    )

    return true
  }

export const SearchAddOns =
  ({ RestService, HandleError }) =>
  async ({ search, limit = 100, offset = 0 }) => {
    try {
      const params = {
        offset,
        limit,
      }
      if (search != null) {
        params.search = search
      }

      return await RestService.get('/virtual-xp/kit-items', params)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const GetAddOn =
  ({ RestService, HandleError }) =>
  async (id) => {
    try {
      return await RestService.get(`/virtual-xp/kit-item/${id}`)
    } catch (error) {
      HandleError({ error })
    }
  }

export const DeleteAddOn =
  ({ RestService, UIService, HandleError }) =>
  async (id) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: 'Are you sure you want to delete add-on?',
    })
    if (doDelete) {
      try {
        await RestService.delete(`/virtual-xp/kit-item/${id}`)
      } catch (error) {
        HandleError({ error })

        return false
      }

      return true
    }

    return false
  }
