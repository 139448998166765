import React, { useState, Fragment, useEffect } from 'react'
import Moment from 'moment-timezone'
import {
  FlexContainer,
  XSpacing,
  YSpacing,
  DividerLine,
} from '@components/common'
import {
  Checkbox,
  Dropdown,
  DateTextInput,
  QuantityInput,
} from '@components/common/form'
import { Label } from '@res/styledComponents/index'
import { IoCaretDownCircle, IoCaretForwardCircle } from 'react-icons/io5'
import { colors } from '../../../../constants'

const DAYS_OF_WEEK = [
  { value: 0, label: 'SU' },
  { value: 1, label: 'M' },
  { value: 2, label: 'T' },
  { value: 3, label: 'W' },
  { value: 4, label: 'TH' },
  { value: 5, label: 'F' },
  { value: 6, label: 'S' },
]

const ORDER_CUTOFF_DAYS = [
  { value: undefined, label: 'N/A' }, // order cutoff is null, this is null
  { value: 0, label: 'Same Day' },
  { value: 1, label: '1 Business Day Before' },
  { value: 2, label: '2 Business Days Before' },
]

const ORDER_CUTOFF_TIMES = [
  { value: 900, label: '9:00 AM' },
  { value: 1700, label: '5:00 PM' },
]

interface GroupOrderMenuCutoffSettingsProps {
  locale: string
  groupOrderCutoffDays: number | null
  onChangeCutoffDays: (value: number | null) => void
  groupOrderCutoffTime: number | null
  onChangeCutoffTime: (value: number | null) => void
  groupOrderPickupTime: number | null
  onChangePickupTime: (value: string | null) => void
  groupOrderMaxItems: number | null
  onChangeMaxItems: (value: number) => void
  groupOrderAvailableDays: number[]
  onChangeAvailableDays: (day: number) => void
}

const GroupOrderMenuCutoffSettings: React.FC<
  GroupOrderMenuCutoffSettingsProps
> = ({
  locale,
  groupOrderCutoffDays,
  onChangeCutoffDays,
  groupOrderCutoffTime,
  onChangeCutoffTime,
  groupOrderPickupTime,
  onChangePickupTime,
  groupOrderMaxItems,
  onChangeMaxItems,
  groupOrderAvailableDays,
  onChangeAvailableDays,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [usingEarliestPickup, setUsingEarliestPickup] = useState<boolean>(
    groupOrderPickupTime !== null,
  )

  useEffect(() => {
    if (groupOrderCutoffDays === null || isNaN(groupOrderCutoffDays)) {
      onChangeCutoffTime(null)
    } else if (groupOrderCutoffDays === 0) {
      onChangeCutoffTime(ORDER_CUTOFF_TIMES[0].value)
    } else {
      onChangeCutoffTime(ORDER_CUTOFF_TIMES[1].value)
    }
  }, [groupOrderCutoffDays])

  const pPickupTime = (pickupTime: number): Moment.Moment => {
    return Moment()
      .tz(locale)
      .set({
        h: pickupTime / 100,
        m: pickupTime % 100,
        s: 0,
      })
  }

  const onCheckEarliestPickup = () => {
    if (usingEarliestPickup) {
      onChangePickupTime(null)
    }
    setUsingEarliestPickup(!usingEarliestPickup)
  }

  return (
    <Fragment>
      <FlexContainer alignItems="center">
        <Label>Group Order Menu Cutoff Settings</Label>
        <XSpacing width="20px" />
        {expanded ? (
          <IoCaretDownCircle
            color={colors.orange}
            style={{ cursor: 'pointer' }}
            size={25}
            onClick={() => setExpanded(!expanded)}
          />
        ) : (
          <IoCaretForwardCircle
            color={colors.orange}
            style={{ cursor: 'pointer' }}
            size={25}
            onClick={() => setExpanded(!expanded)}
          />
        )}
      </FlexContainer>
      {expanded && (
        <Fragment>
          <YSpacing height="20px" />
          <FlexContainer
            width="80%"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Label>Select the Day(s) Menu is Available</Label>
            <YSpacing height="10px" />
            <FlexContainer justifyContent="space-between">
              {DAYS_OF_WEEK.map((day, i) => (
                <Checkbox
                  key={i}
                  label={day.label}
                  value={day.value}
                  checked={groupOrderAvailableDays.includes(day.value)}
                  onChange={() => onChangeAvailableDays(day.value)}
                />
              ))}
            </FlexContainer>
          </FlexContainer>
          <YSpacing height="20px" />
          <FlexContainer
            width="100%"
            justifyContent="space-between"
            flexDirection="row"
            alignItems="end"
          >
            <FlexContainer width="30%">
              <Dropdown
                label="Order Cutoff Day(s) on or Before Delivery Date"
                onChange={(e) => onChangeCutoffDays(parseInt(e.target.value))}
                value={groupOrderCutoffDays}
              >
                {ORDER_CUTOFF_DAYS.map((day, i) => (
                  <option
                    key={i}
                    value={day.value}
                    selected={groupOrderCutoffDays === day.value}
                  >
                    {day.label}
                  </option>
                ))}
              </Dropdown>
            </FlexContainer>
            {groupOrderCutoffDays !== null && (
              <Fragment>
                <FlexContainer width="30%">
                  <Dropdown
                    label={'Order Cutoff Time'}
                    onChange={(e) =>
                      onChangeCutoffTime(parseInt(e.target.value))
                    }
                    value={groupOrderCutoffTime}
                  >
                    {/*
                        Per spec, if cutoff days is > 0, user can only select 5 PM option.
                        If cutoff days is 0, user can only select 9 AM option.
                    */}
                    {groupOrderCutoffDays !== 0 ? (
                      <option
                        value={ORDER_CUTOFF_TIMES[1].value}
                        selected={true}
                      >
                        {ORDER_CUTOFF_TIMES[1].label}
                      </option>
                    ) : (
                      <option
                        value={ORDER_CUTOFF_TIMES[0].value}
                        selected={true}
                      >
                        {ORDER_CUTOFF_TIMES[0].label}
                      </option>
                    )}
                  </Dropdown>
                </FlexContainer>
                <FlexContainer width="30%">
                  <p>
                    {
                      "*All times used to set up the cutoff time on the menu card level will be relative to the order's cutoff time & timezone."
                    }
                  </p>
                </FlexContainer>
              </Fragment>
            )}
          </FlexContainer>
          <YSpacing height="20px" />
          <FlexContainer
            width="100%"
            justifyContent="left"
            flexDirection="row"
            alignItems="end"
          >
            <FlexContainer width="30%" flexDirection="column">
              <Label>Earliest Pickup Time on Delivery Date</Label>
              <Checkbox
                label="Set up an earliest pickup time?"
                value={usingEarliestPickup}
                checked={usingEarliestPickup}
                onChange={() => onCheckEarliestPickup()}
              />
            </FlexContainer>
            {usingEarliestPickup && (
              <FlexContainer width="30%">
                <DateTextInput
                  date={pPickupTime(groupOrderPickupTime || 1100)}
                  dateFormat="h:mm a"
                  onChange={onChangePickupTime}
                  timeZone={locale}
                />
              </FlexContainer>
            )}
          </FlexContainer>
          <YSpacing height="20px" />
          <FlexContainer width="100%" justifyContent="left" flexDirection="row">
            <FlexContainer width="30%" flexDirection="column">
              <QuantityInput
                label="Overall Menucard Cap"
                value={groupOrderMaxItems}
                placeholder="Enter Max QTY Cap"
                onChange={onChangeMaxItems}
              />
            </FlexContainer>
          </FlexContainer>
        </Fragment>
      )}
      <DividerLine margin="20px 0 10px" />
    </Fragment>
  )
}

export default GroupOrderMenuCutoffSettings
