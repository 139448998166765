import React from 'react'
import Moment from 'moment-timezone'
import ReactJson from '@microlink/react-json-view'

import Modal from '@components/common/modal/Modal'
import { FlexContainer } from '@components/common'
import { AuditLog, EventSource } from '@types'

interface AuditHistoryModalProps {
  title: string
  auditHistory: AuditLog[]
  close: () => void
}

const AuditHistoryModal: React.FC<AuditHistoryModalProps> = ({
  title,
  auditHistory,
  close,
}) => {
  const eventSourceName = (eventSource: EventSource): string => {
    return (
      eventSource.userEmail ||
      eventSource.callingProcess ||
      eventSource.remoteAddress ||
      'N/A'
    )
  }

  return (
    <Modal hideModal={close} title={title} height="700px">
      <FlexContainer flexDirection="column">
        {auditHistory.map((auditLog) => (
          <FlexContainer flexDirection="column" key={auditLog.id}>
            <span className="text-sm semibold p-2 m-2">
              {eventSourceName(auditLog.eventSource)} @{' '}
              {Moment(auditLog.timestamp).format('lll')}
            </span>
            <ReactJson
              name="snapshot"
              src={auditLog.snapshot}
              collapsed={true}
            />
            <ReactJson name="changes" src={auditLog.diffs} collapsed={true} />
          </FlexContainer>
        ))}
      </FlexContainer>
    </Modal>
  )
}

export default AuditHistoryModal
