import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@components/common/form/Button'
import Dropdown from '@components/common/form/Dropdown'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import Checkbox from '@components/common/form/Checkbox'
import Input from '@components/common/form/Input'
import { BiMoney } from 'react-icons/bi'
import {
  GROUP_ORDER_ITEM_TYPE_ENTREE,
  GroupOrderItemPriceSettingsType,
  GroupOrderHungryWalletTypes,
} from './constants'
import {
  DeliveryFeeSettingsSummary,
  StaffingFeeSettingsSummary,
  GroupOrderInvoiceModal,
} from '@components/groupOrders'
import services from '@services'

class ClientGlobalSettings extends Component {
  state = {
    itemType: GROUP_ORDER_ITEM_TYPE_ENTREE,
    priceType: GROUP_ORDER_ITEM_TYPE_ENTREE,
    deliveryFeeSettingsModalOpen: false,
    staffingFeeSettingsModalOpen: false,
    groupOrderInvoiceModalOpen: false,
  }

  flashError = (message) =>
    services.UIService.FlashMessage.displayFailureMessage(message)

  addFlatPrice = (priceType) => {
    const { clientGlobalSettings, onChangeClientGlobalSettings } = this.props

    onChangeClientGlobalSettings('flatPriceMap', {
      ...clientGlobalSettings.flatPriceMap,
      [priceType]: {
        priceType,
      },
    })
  }

  addItemLimits = (itemType) => {
    const { clientGlobalSettings, onChangeClientGlobalSettings } = this.props

    onChangeClientGlobalSettings('itemLimitMap', {
      ...clientGlobalSettings.itemLimitMap,
      [itemType]: {
        itemType,
      },
    })
  }

  removeFlatPrice = (priceType) => {
    const { clientGlobalSettings, onChangeClientGlobalSettings } = this.props
    const { [priceType]: _, ...updatedFlatPriceMap } =
      clientGlobalSettings.flatPriceMap

    onChangeClientGlobalSettings('flatPriceMap', {
      ...updatedFlatPriceMap,
    })
  }

  removeItemLimits = (itemType) => {
    const { clientGlobalSettings, onChangeClientGlobalSettings } = this.props
    const { [itemType]: _, ...updatedLimitMap } =
      clientGlobalSettings.itemLimitMap

    onChangeClientGlobalSettings('itemLimitMap', {
      ...updatedLimitMap,
    })
  }

  onChangeFlatPrice = (priceType, field, value) => {
    const { clientGlobalSettings, onChangeClientGlobalSettings } = this.props

    onChangeClientGlobalSettings('flatPriceMap', {
      ...clientGlobalSettings.flatPriceMap,
      [priceType]: {
        ...clientGlobalSettings.flatPriceMap[priceType],
        [field]: value,
      },
    })
  }

  onChangeLimits = (itemType, field, value) => {
    const { clientGlobalSettings, onChangeClientGlobalSettings } = this.props

    onChangeClientGlobalSettings('itemLimitMap', {
      ...clientGlobalSettings.itemLimitMap,
      [itemType]: {
        ...clientGlobalSettings.itemLimitMap[itemType],
        [field]: value,
      },
    })
  }

  onChangeWalletSettings = (field, value) => {
    const { clientGlobalSettings, onChangeClientGlobalSettings } = this.props

    onChangeClientGlobalSettings('hungryWalletSettings', {
      ...clientGlobalSettings.hungryWalletSettings,
      [field]: value,
    })
  }

  toggleGroupOrderInvoiceModal = (value) => {
    this.setState({ groupOrderInvoiceModalOpen: value })
  }

  render() {
    const { itemType, priceType } = this.state
    const {
      clientGlobalSettings,
      clientSettingsCollection,
      onChangeClientGlobalSettings,
      onSaveClientGlobalSettings,
    } = this.props
    const {
      limitItems,
      itemLimitMap,
      flatPriceMap,
      isFlatPriceEnabled,
      hungryWalletSettings,
    } = clientGlobalSettings || {}

    const {
      amount: walletAmount,
      durationType,
      isActive: isWalletActive,
    } = hungryWalletSettings || {}

    return (
      <FlexContainer justifyContent="space-between" flexDirection="column">
        <Checkbox
          label="Limit # of Items Per Meal Period?"
          value={limitItems}
          checked={limitItems}
          onChange={(e) =>
            onChangeClientGlobalSettings('limitItems', e.target.checked)
          }
        />
        {limitItems && (
          <FlexContainer justifyContent="space-between" flexDirection="column">
            <YSpacing height="10px" />
            <p>
              <span className="bold">{'ITEM LIMIT SETTINGS'}</span>
            </p>
            <FlexContainer
              justifyContent="space-between"
              flexDirection="row"
              alignItems="center"
            >
              <Dropdown
                label="Settings Type"
                width="48%"
                value={itemType}
                onChange={(e) => this.setState({ itemType: e.target.value })}
              >
                {GroupOrderItemPriceSettingsType.map((type) => (
                  <option key={type} value={type} selected={type === itemType}>
                    {type}
                  </option>
                ))}
              </Dropdown>
              <Button
                label={'Add Item Limit'}
                onClick={() => this.addItemLimits(itemType)}
                style={{ width: 20 }}
              />
            </FlexContainer>
            {itemLimitMap &&
              Object.keys(itemLimitMap).map((itemType, index) => {
                const itemLimit = itemLimitMap[itemType] || {}

                return (
                  <FlexContainer
                    justifyContent="space-between"
                    flexDirection="column"
                    key={index}
                  >
                    <p>
                      <span className="bold">Type: </span>
                      {itemLimit.itemType}
                    </p>
                    <Input
                      label={'Quantity'}
                      marginBottom="20px"
                      width="48%"
                      type="number"
                      value={itemLimit.quantity || ''}
                      onChange={(e) =>
                        this.onChangeLimits(
                          itemType,
                          'quantity',
                          e.target.valueAsNumber,
                        )
                      }
                    />
                    <Button
                      label={'Remove'}
                      onClick={() => this.removeItemLimits(itemType)}
                      style={{ width: 20 }}
                    />
                    <YSpacing height="20px" />
                  </FlexContainer>
                )
              })}
          </FlexContainer>
        )}
        <Checkbox
          label="Enable Flat Prices?"
          value={isFlatPriceEnabled}
          checked={isFlatPriceEnabled}
          onChange={(e) =>
            onChangeClientGlobalSettings('isFlatPriceEnabled', e.target.checked)
          }
        />
        {isFlatPriceEnabled && (
          <FlexContainer justifyContent="space-between" flexDirection="column">
            <YSpacing height="10px" />
            <p>
              <span className="bold">{'FLAT PRICE SETTINGS'}</span>
            </p>
            <FlexContainer
              justifyContent="space-between"
              flexDirection="row"
              alignItems="center"
            >
              <Dropdown
                label="Settings Type"
                width="48%"
                value={priceType}
                onChange={(e) => this.setState({ priceType: e.target.value })}
              >
                {GroupOrderItemPriceSettingsType.map((type) => (
                  <option key={type} value={type} selected={type === priceType}>
                    {type}
                  </option>
                ))}
              </Dropdown>
              <Button
                label={'Add Flat Price'}
                onClick={() => this.addFlatPrice(priceType)}
                style={{ width: 20 }}
              />
            </FlexContainer>
            {flatPriceMap &&
              Object.keys(flatPriceMap).map((priceType, index) => {
                const itemFlatPrice = flatPriceMap[priceType] || {}

                return (
                  <FlexContainer
                    justifyContent="space-between"
                    flexDirection="column"
                    key={index}
                  >
                    <p>
                      <span className="bold">Type: </span>
                      {itemFlatPrice.priceType}
                    </p>
                    <Input
                      label={'Price ($)'}
                      marginBottom="20px"
                      width="48%"
                      type="number"
                      value={itemFlatPrice.price || ''}
                      onChange={(e) =>
                        this.onChangeFlatPrice(
                          priceType,
                          'price',
                          e.target.valueAsNumber,
                        )
                      }
                    />
                    <Button
                      label={'Remove'}
                      onClick={() => this.removeFlatPrice(priceType)}
                      style={{ width: 20 }}
                    />
                    <YSpacing height="20px" />
                  </FlexContainer>
                )
              })}
          </FlexContainer>
        )}
        <Checkbox
          label="Enable HUNGRY Wallet?"
          value={isWalletActive}
          checked={isWalletActive}
          onChange={(e) =>
            this.onChangeWalletSettings('isActive', e.target.checked)
          }
        />
        {isWalletActive && (
          <FlexContainer justifyContent="space-between">
            <Dropdown
              label="Wallet Type"
              width="48%"
              value={durationType}
              onChange={(e) =>
                this.onChangeWalletSettings('durationType', e.target.value)
              }
            >
              {GroupOrderHungryWalletTypes.map((type) => (
                <option
                  key={type}
                  value={type}
                  selected={type === durationType}
                >
                  {type}
                </option>
              ))}
            </Dropdown>
            <Input
              label={'Wallet Amount ($)'}
              marginBottom="20px"
              width="48%"
              type="number"
              value={walletAmount}
              onChange={(e) =>
                this.onChangeWalletSettings('amount', e.target.value)
              }
            />
          </FlexContainer>
        )}
        <YSpacing height="10px" />
        <FlexContainer>
          <DeliveryFeeSettingsSummary
            clientGlobalSettings={clientGlobalSettings}
            onRequestEdit={() =>
              this.props.toggleDeliveryFeeSettingsModal(true)
            }
          />
        </FlexContainer>
        <YSpacing height="10px" />
        <FlexContainer>
          <StaffingFeeSettingsSummary
            clientGlobalSettings={clientGlobalSettings}
            clientSettingsCollection={clientSettingsCollection}
            onRequestEdit={() =>
              this.props.toggleStaffingFeeSettingsModal(true)
            }
          />
        </FlexContainer>
        <YSpacing height="10px" />
        <FlexContainer>
          <div className="client-global-settings-section">
            <div className="section-header">
              <div className="section-title">
                Invoice Group Orders For Client
              </div>
            </div>
            <div className="section-content">
              <div className="section-description"></div>
              <div className="section-buttons">
                <button
                  onClick={() => this.toggleGroupOrderInvoiceModal(true)}
                  className="invoice-button"
                >
                  <div className="button-content">
                    <BiMoney />
                    <div>Prepare Group Order Invoice</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          {this.state.groupOrderInvoiceModalOpen && this.props.clientId && (
            <GroupOrderInvoiceModal
              clientId={this.props.clientId}
              clientSettingsCollection={this.props.clientSettingsCollection}
              onPrepareGroupOrderInvoice={this.props.onPrepareGroupOrderInvoice}
              onSearchGroupOrders={this.props.onSearchGroupOrders}
              closeModal={() => this.toggleGroupOrderInvoiceModal(false)}
              flashError={this.flashError}
            />
          )}
        </FlexContainer>
        <YSpacing height="10px" />
        <Button
          label={'Save Client Global Settings'}
          onClick={() => onSaveClientGlobalSettings(clientGlobalSettings)}
        />
      </FlexContainer>
    )
  }
}

ClientGlobalSettings.propTypes = {
  clientGlobalSettings: PropTypes.object,
  clientSettingsCollection: PropTypes.array,
  clientName: PropTypes.string,
  clientId: PropTypes.string,
  onChangeClientGlobalSettings: PropTypes.func,
  onSaveClientGlobalSettings: PropTypes.func,
  onSaveStaffingFeeSettings: PropTypes.func,
  onPrepareGroupOrderInvoice: PropTypes.func,
  onSearchGroupOrders: PropTypes.func,
  toggleDeliveryFeeSettingsModal: PropTypes.func,
  toggleStaffingFeeSettingsModal: PropTypes.func,
}

export default ClientGlobalSettings
