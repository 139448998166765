import React from 'react'
import PropTypes from 'prop-types'

import { MasterDetail } from '@components/common'

import { MarketDetail, MarketMaster } from '@containers/market'

const MarketsPage = (props) => {
  const { marketName, newMarket } = props

  return (
    <MasterDetail
      detail={<MarketDetail />}
      master={(props) => <MarketMaster {...props} />}
      filters={['', 'philly', 'atlanta']}
      onNewAuthRoles={['master admin', 'sales lead']}
      onNew={newMarket}
      onNewLabel="Market"
      title={marketName ? marketName : 'Markets'}
      hasAddButton={false}
    />
  )
}

MarketsPage.propTypes = {
  marketName: PropTypes.string,

  newMarket: PropTypes.func,
}

export default MarketsPage
