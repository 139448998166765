import React, { Component } from 'react'
import { DefaultStyles as theme } from './theme'
import { PageBody } from '@res/styledComponents/index'
import {
  OutreachPage,
  PopUpAdminPage,
  PopUpCustomersPage,
  PopUpDiscountsPage,
  PopUpsPage,
  RecurringPopUpsPage,
} from '@containers/popUps'
import Tab from '@components/common/Tab'
import AuthorizedDisplay from '@containers/common/auth/AuthorizedDisplay'
import Sidebar from '@components/sidebar/Sidebar'
import SidebarListItem from '@components/sidebar/SidebarListItem'

class PopUpMaster extends Component {
  state = {
    hideSidebar: false,
    page: 'Pop Ups',
  }

  render() {
    const { page } = this.state
    const sidebarPages = [
      'Pop Ups',
      'Recurring Pop Ups',
      'Discounts',
      'Customers',
      'Outreach',
    ]

    return (
      <PageBody>
        {/* SIDEBAR */}
        <Sidebar heading="Pop Ups">
          <div className="sidebar-scroll">
            {sidebarPages.map((p) => {
              const selected = p === page

              return (
                <SidebarListItem
                  key={p}
                  isSelected={selected}
                  onClick={() => {
                    this.setState({ page: p })
                  }}
                >
                  {p}
                </SidebarListItem>
              )
            })}
            <AuthorizedDisplay
              roles={['developer', 'master admin', 'sales rep', 'sales lead']}
            >
              <SidebarListItem
                isSelected={'Reports' === page}
                onClick={() => {
                  this.setState({ page: 'Reports' })
                }}
              >
                Reports
              </SidebarListItem>
            </AuthorizedDisplay>
          </div>
        </Sidebar>
        {/* PANEL */}
        <div className="sidebar-body">
          <div className="sidebar-body-inner">
            <Tab name="Pop Ups" activeTab={page}>
              <PopUpsPage theme={theme} />
            </Tab>

            <Tab name="Recurring Pop Ups" activeTab={page}>
              <RecurringPopUpsPage theme={theme} />
            </Tab>

            <Tab name="Discounts" activeTab={page}>
              <PopUpDiscountsPage theme={theme} />
            </Tab>

            <Tab name="Customers" activeTab={page}>
              <PopUpCustomersPage theme={theme} />
            </Tab>

            <Tab name="Outreach" activeTab={page}>
              <OutreachPage theme={theme} />
            </Tab>

            <Tab name="Reports" activeTab={page}>
              <PopUpAdminPage theme={theme} />
            </Tab>
          </div>
        </div>
      </PageBody>
    )
  }
}

export default PopUpMaster
