import { HandleError as HandleError_ } from './errors'
import { Validate as Validate_ } from './validation'
import { ValidateAndReturnError as ValidateAndReturnError_ } from './validation'
import services from '@services'
import presenters from '@presenters'

const { RouterService, SessionService, UIService, ValidationService } = services
const pResponseError = presenters.Errors.responseErrorMessage

export const HandleError = HandleError_({
  RouterService,
  SessionService,
  UIService,
  pResponseError,
})
export const Validate = Validate_({ UIService, ValidationService })
export const ValidateAndReturnError = ValidateAndReturnError_({
  UIService,
  ValidationService,
})
