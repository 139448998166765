import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropdown from '@components/common/form/Dropdown'
import { Button, Input, LinkText } from '@components/common/form'
import { AutocompleteInput } from '@containers/common/form'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { colors } from '../../../../../constants'

const InitialState = {
  id: '',
  title: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  purchaserTaxStatus: null,
}

class Contact extends Component {
  state = {
    ...InitialState,

    isExistingContact: false,
    isFocused: false,
    isNewContact: false,
  }

  onBlur = () => {
    this.setState({ isFocused: false })
  }

  onCancel = () => {
    this.setState(
      {
        ...InitialState,
        isExistingContact: false,
        isNewContact: false,
      },
      () => {
        const { contact } = this.state
        this.props.onCancel(contact)
      },
    )
  }

  onFocus = () => {
    this.setState({ isFocused: true })
  }

  onInput = (key) => (e) => {
    const { clearError, onInputNew, pBuildContact } = this.props
    this.setState({ [key]: e.target.value }, () => {
      clearError(key)
      const contact = pBuildContact(this.state)
      onInputNew(contact)
    })
  }

  onInputPhoneNumber = (e) => {
    const { clearError, onInputNew, pBuildContact } = this.props
    let phoneNumber = e.target.value
    phoneNumber = phoneNumber.replace(/[^0-9#()\- ]/g, '')
    this.setState({ phoneNumber }, () => {
      clearError('phoneNumber')
      const contact = pBuildContact(this.state)
      onInputNew(contact)
    })
  }

  onInputTaxStatus = (e) => {
    const { clearError, onInputNew, pBuildContact } = this.props
    let taxStatus
    switch (e.target.value) {
      case 'true':
        taxStatus = true
        break
      case 'false':
        taxStatus = false
        break
      default:
        taxStatus = null
    }
    this.setState({ purchaserTaxStatus: taxStatus }, () => {
      clearError('purchaserTaxStatus')
      const contact = pBuildContact(this.state)
      onInputNew(contact)
    })
  }

  onSave = async () => {
    const isValid = await this.props.onSave()
    if (isValid) {
      this.setState({
        isNewContact: false,
        isExistingContact: false,
        ...InitialState,
      })
    }
  }

  onSelectContact = (e) => {
    const { contacts } = this.props
    const contactId = e.target.value
    const contact = contacts.find((x) => x.id === contactId)
    this.setState({ contact })

    this.props.onChange(contact)
  }

  onSelectExistingContact = (contact) => {
    this.setState({ ...contact })

    this.props.onInputNew(contact)
  }

  onShowNewContact = () => {
    this.setState({ isNewContact: true })
  }

  onShowExistingContact = () => {
    this.setState({ isNewContact: false, isExistingContact: true })
  }

  renderNewContact() {
    const { errors } = this.props
    const { title, firstName, lastName, phoneNumber, email } = this.state

    return (
      <div className="p-3 bg-indigo-100">
        <Input label="Title" value={title} onChange={this.onInput('title')} />
        <Input
          label="First Name"
          value={firstName}
          error={errors.firstName}
          onChange={this.onInput('firstName')}
        />
        <Input
          label="Last Name"
          value={lastName}
          error={errors.lastName}
          onChange={this.onInput('lastName')}
        />
        <Input
          label="Phone #"
          value={phoneNumber}
          error={errors.phoneNumber}
          onChange={this.onInputPhoneNumber}
        />
        <Input
          label="Email"
          value={email}
          error={errors.email}
          onChange={this.onInput('email')}
        />
        <FlexContainer alignItems="center" justifyContent="flex-end">
          <LinkText
            label="Cancel"
            onClick={this.onCancel}
            color={colors.violet}
          />
          <XSpacing width="20px" />
          <Button label="Save" onClick={this.onSave} />
        </FlexContainer>
      </div>
    )
  }

  renderContactDropdown() {
    let { contacts } = this.props
    const { contact, errors, hasAccount, newContact, contactTitle } = this.props
    const { isNewContact } = this.state

    const contactId = isNewContact ? '' : (contact || {}).id
    contacts = [newContact, ...contacts]
    const disabled = !hasAccount

    const [empty, ...rest] = contacts
    const sortedContacts = [
      empty,
      ...rest.sort((aContact, bContact) => {
        const { firstName: afName, lastName: alName } = aContact
        const { firstName: bfName, lastName: blName } = bContact

        const result = (afName || '').localeCompare(bfName || '', 'en', {
          sensitivity: 'base',
        })
        if (result !== 0) {
          return result
        }

        return (alName || '').localeCompare(blName || '', 'en', {
          sensitivity: 'base',
        })
      }),
    ]

    return (
      <div style={{ width: '100%' }}>
        <Dropdown
          disabled={disabled}
          error={errors.contact}
          label={contactTitle}
          width="100%"
          marginBottom="0"
          value={contactId}
          onChange={this.onSelectContact}
        >
          {sortedContacts.map((a) => (
            <option key={a.id} value={a.id}>
              {a.name}
            </option>
          ))}
        </Dropdown>
      </div>
    )
  }

  renderExistingContactsDropdown() {
    const { errors, loadContacts } = this.props
    const { firstName, lastName } = this.state

    const contactName = firstName && lastName ? firstName + '' + lastName : ''

    return (
      <div style={{ width: '100%' }}>
        <AutocompleteInput
          label="Select A Contact"
          value={contactName}
          width="100%"
          error={errors.contact}
          loaderFunction={loadContacts}
          onSelect={this.onSelectExistingContact}
        />
        <YSpacing height="5px" />
        <FlexContainer alignItems="center" justifyContent="flex-end">
          <LinkText
            label="Cancel"
            onClick={this.onCancel}
            color={colors.violet}
          />
          <XSpacing width="20px" />
          <Button label="Save" onClick={this.onSave} />
        </FlexContainer>
      </div>
    )
  }

  render() {
    const { hasAccount, width } = this.props
    const { isExistingContact, isNewContact } = this.state

    return (
      <div style={{ width }}>
        <FlexContainer width="100%" flexDirection="column">
          {isExistingContact
            ? this.renderExistingContactsDropdown()
            : this.renderContactDropdown()}
          <FlexContainer flexDirection="column" alignItems="flex-start">
            {hasAccount && !isNewContact && !isExistingContact && (
              <div>
                <YSpacing height="5px" />
                <LinkText
                  fontSize="11px"
                  label="Create New Contact"
                  onClick={this.onShowNewContact}
                />
              </div>
            )}
            {hasAccount && !isNewContact && !isExistingContact && (
              <div>
                <YSpacing height="2px" />
                <LinkText
                  fontSize="11px"
                  label="Use Existing Contact"
                  onClick={this.onShowExistingContact}
                />
              </div>
            )}
          </FlexContainer>
        </FlexContainer>
        {isNewContact && !isExistingContact && this.renderNewContact()}
      </div>
    )
  }
}

Contact.propTypes = {
  contact: PropTypes.object,
  contacts: PropTypes.arrayOf(PropTypes.object),
  contactTitle: PropTypes.string,
  hasAccount: PropTypes.bool,
  newContact: PropTypes.object,
  width: PropTypes.string,
  errors: PropTypes.object,

  clearError: PropTypes.func,
  loadContacts: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onInputNew: PropTypes.func,
  onSave: PropTypes.func,
  pBuildContact: PropTypes.func,
}

Contact.defaultProps = {
  contacts: [],
  errors: {},
}

export default Contact
