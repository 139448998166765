import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { CurrencyInput } from '@components/common/form'

const RefreshingAttributes = [
  'childOrders',
  'serviceFee',
  'subtotal',
  'tax',
  'tip',
  'total',
]

class BatchTotalSection extends Component {
  state = {
    childOrders: [],
    serviceFee: 0,
    subtotal: 0,
    tax: 0,
    tip: 0,
    total: 0,
  }

  componentDidMount() {
    const newState = {}
    RefreshingAttributes.forEach((k) => {
      newState[k] = this.props[k]
    })
    this.setState(newState)
  }

  componentWillReceiveProps(nextProps) {
    const { childOrders } = nextProps
    if (childOrders !== this.props.childOrders) {
      let newState = {}
      RefreshingAttributes.forEach((k) => {
        newState[k] = this.props[k]
      })
      newState = {
        ...newState,
        ...this.props.calculateAllTotals({
          orders: childOrders,
          didEditOrders: true,
        }),
      }
      this.setState(newState)
    }
  }

  onChangeTip = () => {
    // eventually add if supporting tip addition on the multi order
    // add logic here to disallow removing tip
  }

  // renderTipButton( tipPercent ) {
  //   const onClick = e => {
  //     e.preventDefault()
  //     e.stopPropagation()
  //
  //     const tip = this.calculateTip( tipPercent )
  //
  //     this.setState({ tip })
  //     this.props.onChange({ tip })
  //   }
  //
  //   return (
  //     <button className="tip-button" key={ tipPercent } onClick={ onClick }>
  //       { tipPercent }%
  //     </button>
  //   )
  // }

  render() {
    const { serviceFee, subtotal, tax, tip, total } = this.state

    return (
      <div>
        <div className="container-space-between">
          <div> Subtotal: </div>
          <div className="subtotal" id="subtotal">
            ${subtotal.toFixed(2)}{' '}
          </div>
        </div>

        <div className="container-space-between">
          <div>
            Delivery Fee:
            <label> Free Delivery</label>
          </div>
          <CurrencyInput
            testId="delivery-fee"
            disabled={true}
            value={serviceFee}
            onChange={this.onChangeServiceFee}
          />
        </div>

        <div className="container-space-between">
          <div>Sales Tax:</div>
          <div id="sales-tax">${tax.toFixed(2)}</div>
        </div>

        <div className="container-space-between">
          <div>
            Gratuity:
            {/*
            { [ 5, 10, 15 ].map( tipPercent =>
              this.renderTipButton( tipPercent ))
            }*/}
          </div>
          <CurrencyInput testId="tip" disabled={true} value={tip} />{' '}
          {/* onChange={ this.onChangeCurrency( 'tip' ) } /> */}
        </div>

        <div className="total">
          Total: <span id="total">${total.toFixed(2)}</span>
        </div>
      </div>
    )
  }
}

BatchTotalSection.propTypes = {
  childOrders: PropTypes.arrayOf(PropTypes.object),
  serviceFee: PropTypes.number,
  subtotal: PropTypes.number,
  tax: PropTypes.number,
  tip: PropTypes.number,
  total: PropTypes.number,

  calculateAllTotals: PropTypes.func,
}

BatchTotalSection.defaultProps = {
  childOrders: [],
  serviceFee: 0,
  subtotal: 0,
  tax: 0,
  tip: 0,
  total: 0,
}

export default BatchTotalSection
