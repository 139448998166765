import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from '@components/common/form/Input'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import Dropdown from '@components/common/form/Dropdown'
import Checkbox from '@components/common/form/Checkbox'
import { lookupTypes } from './constants'
import { toIdMap } from '~/utils'

class VXLookupsPage extends Component {
  state = {
    loading: true,
    search: '',
    searchLimit: 20,
    searchOffset: 0,
    showEditModal: false,
    orders: [],
    lookups: [],
    enabled: undefined,
    selectedOrder: undefined,
    sortBy: [undefined, true],
    hasMatch: '',
    types: lookupTypes.reduce((map, type) => {
      map[type] = true

      return map
    }, {}),
    salesReps: {},
    clients: {},
    talents: {},
    salesRepsArr: [],
    clientsArr: [],
    talentsArr: [],
  }
  searchTimer = undefined

  componentDidMount() {
    this.searchVXLookups()
    this.searchVXPersons()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.accounts !== this.props.accounts) {
      const clientsArr = nextProps.accounts
      const clients = toIdMap(clientsArr)

      this.setState({ clients, clientsArr })
    }
  }

  searchVXPersons = async () => {
    const { searchSalesReps, searchClients, searchTalents } = this.props
    const salesRepsArr = await searchSalesReps()
    const talentsArr = await searchTalents()
    await searchClients()

    salesRepsArr.sort((a, b) => (a.name > b.name ? 1 : -1))
    talentsArr.sort((a, b) => (a.name > b.name ? 1 : -1))

    const salesReps = toIdMap(salesRepsArr)
    const talents = toIdMap(talentsArr)

    this.setState({
      salesReps,
      talents,
      salesRepsArr,
      talentsArr,
      loading: false,
    })
  }

  searchVXLookups = async () => {
    const { searchVXLookups } = this.props
    const { search, hasMatch, types, searchLimit, searchOffset } = this.state
    const lookups = await searchVXLookups({
      search,
      hasMatch,
      types: Object.keys(types).filter((key) => {
        if (types[key]) {
          return key
        }
      }),
      limit: searchLimit,
      offset: searchOffset,
    })

    this.setState({ lookups })
  }

  searchAfterTimeout = () => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer)
    }
    this.searchTimer = undefined
    this.searchTimer = setTimeout(() => {
      this.searchVXLookups()
    }, 550)
  }

  saveVXLookup = (matchId, i) => {
    const { lookups } = this.state
    const { saveVXLookup } = this.props
    const lookup = { ...lookups[i], matchId }

    saveVXLookup(lookup)
    this.setState({
      lookups: [...lookups.slice(0, i), lookup, ...lookups.slice(i + 1)],
    })
  }

  sortLookups = (attribute) => {
    const { lookups, sortBy, salesReps, clients, talents } = this.state
    const asc = attribute !== sortBy[0] || !sortBy[1]
    this.setState({
      sortBy: [attribute, asc],
      lookups: lookups.sort((a, b) => {
        const isMatchId = attribute == 'matchId'
        if (isMatchId) {
          const persons = [a, b]
          persons.forEach((person) => {
            switch (person.type) {
              case 'Sales Rep':
                person = salesReps[person.matchId]
                break
              case 'Client':
                person = clients[person.matchId]
                break
              case 'Talent':
                person = talents[person.matchId]
                break
            }
            if (person == undefined) {
              person = { name: '' }
            }
          })
        }
        if (
          a[isMatchId ? 'name' : attribute] > b[isMatchId ? 'name' : attribute]
        ) {
          return asc ? 1 : -1
        }
        if (
          a[isMatchId ? 'name' : attribute] < b[isMatchId ? 'name' : attribute]
        ) {
          return asc ? -1 : 1
        }

        return 0
      }),
    })
  }

  render() {
    const {
      lookups,
      search,
      searchLimit,
      searchOffset,
      hasMatch,
      types,
      salesReps,
      clients,
      talents,
      salesRepsArr,
      clientsArr,
      talentsArr,
      loading,
    } = this.state

    return (
      <Panel width="100%" maxWidth="900px" heading="VX Lookups">
        <FlexContainer alignItems="flex-end">
          <Input
            label="Search Name"
            marginBottom="0"
            width="200px"
            type="text"
            value={search}
            onChange={(e) =>
              this.setState(
                {
                  search: e.target.value,
                  searchOffset: 0,
                },
                this.searchAfterTimeout,
              )
            }
          />
          <XSpacing width="20px" />
          <Dropdown
            label="Match Status"
            value={hasMatch}
            marginBottom="0"
            width="200px"
            onChange={(e) => {
              const statusCode = e.target.value
              this.setState({ hasMatch: statusCode }, this.searchVXLookups)
            }}
          >
            <option value={''}>All</option>
            <option value={false}>Unmatched</option>
            <option value={true}>Matched</option>
          </Dropdown>
          <XSpacing width="20px" />
          <FlexContainer justifyContent="space-between" width="300px">
            {lookupTypes.map((type) => {
              return (
                <Checkbox
                  key={type}
                  label={type}
                  value={type}
                  checked={types[type]}
                  onChange={(e) => {
                    const type = e.target.value
                    const current = types[type]
                    this.setState(
                      { types: { ...types, [type]: !current } },
                      this.searchVXLookups,
                    )
                  }}
                />
              )
            })}
          </FlexContainer>
        </FlexContainer>
        <YSpacing height="20px" />
        <Table>
          <tr>
            <th onClick={() => this.sortLookups('name')}>Airtable Name</th>
            <th onClick={() => this.sortLookups('matchId')}>
              {loading ? 'Loading Matches...' : 'Match'}
            </th>
            <th onClick={() => this.sortLookups('type')}>Type</th>
          </tr>
          {lookups.map((lookup, i) => {
            let match, options
            switch (lookup.type) {
              case 'Sales Rep':
                match = salesReps[lookup.matchId]
                options = salesRepsArr
                break
              case 'Client':
                match = clients[lookup.matchId]
                options = clientsArr
                break
              case 'Talent':
                match = talents[lookup.matchId]
                options = talentsArr
                break
            }

            return (
              <tr key={lookup.id}>
                <td style={{ width: '210px' }}>
                  <p>{lookup.name}</p>
                </td>
                <td style={{ width: '348px' }}>
                  <Dropdown
                    width="90%"
                    marginBottom="0"
                    value={match && match.id}
                    onChange={(e) => this.saveVXLookup(e.target.value, i)}
                  >
                    <option />
                    {(options || []).map((person) => (
                      <option key={person.id} value={person.id}>
                        {person.name}
                      </option>
                    ))}
                  </Dropdown>
                </td>
                <td>
                  <p>{lookup.type}</p>
                </td>
              </tr>
            )
          })}
        </Table>
        {searchOffset > 0 && (
          <button
            onClick={() =>
              this.setState(
                { searchOffset: searchOffset - searchLimit },
                this.searchVXLookups,
              )
            }
          >
            &lt;
          </button>
        )}
        Page {1 + Math.floor(parseFloat(searchOffset / searchLimit))}
        {searchLimit === lookups.length && (
          <button
            onClick={() =>
              this.setState(
                { searchOffset: searchOffset + searchLimit },
                this.searchVXLookups,
              )
            }
          >
            &gt;
          </button>
        )}
      </Panel>
    )
  }
}

VXLookupsPage.propTypes = {
  headquarter: PropTypes.number,
  theme: PropTypes.object,

  searchVXLookups: PropTypes.func,
  saveVXLookup: PropTypes.func,
  searchSalesReps: PropTypes.func,
  searchClients: PropTypes.func,
  searchTalents: PropTypes.func,
  displayWarningMessage: PropTypes.func,
  flashSuccess: PropTypes.func,
  showConfirmationModal: PropTypes.func,
  accounts: PropTypes.arrayOf(PropTypes.object),
}

export default VXLookupsPage
