export const EmailBatchInvoice =
  ({ RestService, UIService }) =>
  async (invoiceFinalized) => {
    UIService.BatchOrderPage.toggleInvoiceButtonEnabled(false)

    const batchOrderId = UIService.getState().batchOrder.id
    await RestService.post(
      `/api/admin/order_batches/${batchOrderId}/email_invoice`,
      { finalized: invoiceFinalized },
    )

    UIService.BatchOrderPage.toggleInvoiceButtonEnabled(true)
    UIService.Alert.showPopup({ message: 'Invoice email sent.' })
  }

export const PayBatchOrderViaExternal =
  ({
    HandleError,
    BatchOrderService,
    RestService,
    UIService,
    pRequestPayBatchOrderWithExternal,
    pResponseBatchOrder,
  }) =>
  async (paymentSource) => {
    UIService.BatchOrderPage.toggleInvoiceButtonEnabled(false)

    const batchOrderId = UIService.getState().batchOrder.id
    const payload = pRequestPayBatchOrderWithExternal(
      batchOrderId,
      paymentSource,
    )
    try {
      const paidOrder = await RestService.post('/api/admin/payments', payload)

      BatchOrderService.setBatchOrder(pResponseBatchOrder(paidOrder))
      UIService.BatchOrderPage.toggleInvoiceButtonEnabled(true)
      UIService.Alert.showPopup({
        message: `Multi Order has been marked as paid via ${paymentSource}.`,
      })
    } catch (error) {
      HandleError({ error })
      UIService.BatchOrderPage.toggleInvoiceButtonEnabled(true)
    }
  }

export const ChargeBatchOrderClient =
  ({
    HandleError,
    BatchOrderService,
    RestService,
    UIService,
    pRequestPayBatchOrderWithExternal,
    pResponseBatchOrder,
  }) =>
  async () => {
    UIService.BatchOrderPage.setBatchPaymentProcessing(true)

    const batchOrderId = UIService.getState().batchOrder.id
    try {
      const paidOrder = await RestService.post(
        '/api/admin/payments',
        pRequestPayBatchOrderWithExternal(batchOrderId, 'automated'),
      )

      BatchOrderService.setBatchOrder(pResponseBatchOrder(paidOrder))
      UIService.BatchOrderPage.setBatchPaymentProcessing(false)
    } catch (error) {
      HandleError({ error, namespace: 'chargeClient' })
      UIService.BatchOrderPage.setBatchPaymentProcessing(false)
    }
  }
