import { connect } from 'react-redux'

import CateringCaptainDropdown from '@components/dashboard/CateringCaptainDropdown'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapDispatchToProps = () => {
  const { RestService } = services
  const { AsyncLoadCateringCaptains } = coordinators
  const { pResponseCateringCaptains } = presenters.Api

  const loadCateringCaptains = AsyncLoadCateringCaptains({
    RestService,
    pResponseCateringCaptains,
  })

  return { loadCateringCaptains }
}

export default connect(undefined, mapDispatchToProps)(CateringCaptainDropdown)
