import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import styled from '@emotion/styled'
import Modal from '@components/common/modal/Modal'
import Table from '@components/common/Table'
import Checkbox from '@components/common/form/Checkbox'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { Button } from '@components/common/form'
import { Label } from '@res/styledComponents/index'
import LoadingIndicator from '../common/LoadingIndicator'

const InvoiceVoidModal = ({
  handleVoidModal,
  invoice,
  title,
  updateInvoices,
  user,
  voidableTxns,
  voidInvoicePayment,
}) => {
  const [voidedTxnId, setVoidedTxnId] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const processVoid = async () => {
    const txn = invoice.transactions.find((t) => t.id === voidedTxnId)

    const inv = await voidInvoicePayment({
      invoice,
      voidedBy: user.name,
      txn: txn,
    })
    if (inv) {
      updateInvoices(inv)
    } else {
      setIsLoading(false)
    }
  }

  return (
    <Modal width="800px" title={title} color="#001940">
      <BackButton onClick={() => handleVoidModal('showVoidModal')}>
        ◀ Go back to Invoice: {invoice.invoiceNumber}
      </BackButton>
      <FlexContainer
        width="80%"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
      >
        <Label>Please Select the Transaction to Void</Label>
        <YSpacing height="10px" />
        <Table
          headings={[
            'Payment Last 4',
            'Transaction Date',
            'Transaction Amount',
            '',
          ]}
        >
          {voidableTxns.map((txn) => (
            <tr key={txn.id}>
              <td>{txn.paymentAlias}</td>
              <td>{Moment(txn.createdAt).format('MM/DD/YYYY')}</td>
              <td>${txn.amount.toFixed(2)}</td>
              <td>
                <Checkbox
                  label={''}
                  value={''}
                  checked={txn.id === voidedTxnId}
                  onChange={() => setVoidedTxnId(txn.id)}
                />
              </td>
            </tr>
          ))}
        </Table>
        <YSpacing height="15px" />
        {voidedTxnId &&
          (isLoading ? (
            <LoadingIndicator />
          ) : (
            <Button
              label="Process Void"
              testId="Process Void"
              onClick={() => {
                setIsLoading(true)
                processVoid()
              }}
            />
          ))}
      </FlexContainer>
    </Modal>
  )
}

const BackButton = styled.button`
  position: absolute;
  top: 45px;
`

InvoiceVoidModal.propTypes = {
  invoice: PropTypes.object,
  title: PropTypes.string,
  user: PropTypes.string,
  voidableTxns: PropTypes.array,

  handleVoidModal: PropTypes.func,
  updateInvoices: PropTypes.func,
  voidInvoicePayment: PropTypes.func,
}

export default InvoiceVoidModal
