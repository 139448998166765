import { connect } from 'react-redux'

import CalendarPopup from '@components/proposal/CalendarPopup'

const mapStateToProps = (state) => {
  const { proposal } = state

  return { proposal }
}

export default connect(mapStateToProps)(CalendarPopup)
