import { connect } from 'react-redux'

import { PopUpCustomerModal } from '@components/popUps'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { user = {}, headquarter = {} } = state
  const { lastLoggedInHq } = user
  const { userHeadquarters = [] } = headquarter
  const userHeadquarter =
    userHeadquarters.find((hq) => hq.id === lastLoggedInHq) || {}

  return {
    headquarter: userHeadquarter.id,
    defaultLocale: userHeadquarter.locale,
  }
}

const mapDispatchToProps = () => {
  const { pResponseAccounts } = presenters.Api
  const { PopUpCustomerService, RestService, UIService } = services
  const {
    SavePopUpCustomer,
    DeletePopUpCustomer,
    SearchIndexAccounts,
    UpsertAccountRole,
  } = coordinators
  const savePopUpCustomer = SavePopUpCustomer({
    RestService,
    UIService,
    HandleError,
  })
  const deletePopUpCustomer = DeletePopUpCustomer({
    RestService,
    UIService,
    HandleError,
  })
  const searchIndexAccounts = SearchIndexAccounts({
    RestService,
    pResponseAccounts,
  })
  const upsertAccountRole = UpsertAccountRole({
    RestService,
    UIService,
    HandleError,
  })

  const { newPopUpCustomer } = PopUpCustomerService

  return {
    newPopUpCustomer,
    deletePopUpCustomer,
    savePopUpCustomer,
    searchIndexAccounts,
    upsertAccountRole,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpCustomerModal)
