import { RECURRING } from '../../../constants'

export const LoadChefScheduleTimeSlots =
  ({
    ChefService,
    RestService,
    AdminUserService,
    pGenerateRecurring,
    pRequestTimeWeeks,
    pRequestTimeSlotMonth,
    pResponseAllTimeSlots,
    HandleError,
  }) =>
  async ({ date, view }) => {
    const user = AdminUserService.getUser()
    const chefId = ChefService.getCurrentChefId()
    try {
      const month = pRequestTimeSlotMonth(date)
      const weeks = pRequestTimeWeeks(date)

      const slots = await RestService.get(`/chefs/schedules/${chefId}/month`, {
        month,
      })

      const { timeSlots, recurringSlotInfo } = pResponseAllTimeSlots(
        weeks,
        slots,
        user.locale,
      )
      const recurringSlots = pGenerateRecurring({
        date,
        recurringSlotInfo,
        timeSlots,
        view,
        locale: user.locale,
      })
      ChefService.setSchedule({ recurringSlotInfo, recurringSlots, timeSlots })
    } catch (error) {
      HandleError({ error })
    }
  }

export const UpdateChefSchedule =
  ({
    ChefService,
    RestService,
    AdminUserService,
    pRequestTimeSlot,
    pRequestTimeWeeks,
    pGenerateRecurring,
    pResponseAllTimeSlots,
    UIService,
    HandleError,
  }) =>
  async ({ timeSlot, chefId, monthDate, closeModal }) => {
    const user = AdminUserService.getUser()
    const data = pRequestTimeSlot(timeSlot)
    const { date } = data
    const weeks = pRequestTimeWeeks(monthDate)

    let mixedTimeSlots = []
    try {
      if (data.id) {
        // update time slot
        mixedTimeSlots = await RestService.put(
          `/chefs/schedules/${chefId}/slots/${data.id}`,
          data,
          { timeout: 20000 },
        )
      } else {
        // create new time slot
        mixedTimeSlots = await RestService.post(
          `/chefs/schedules/${chefId}/slots`,
          data,
          { timeout: 20000 },
        )
      }
      if (mixedTimeSlots) {
        UIService.FlashMessage.displaySuccessMessage(
          'Schedule has been updated.',
        )
        closeModal()
      }
      const { timeSlots, recurringSlotInfo } = pResponseAllTimeSlots(
        weeks,
        mixedTimeSlots,
        user.locale,
      )
      const recurringSlots = pGenerateRecurring({
        date,
        recurringSlotInfo,
        timeSlots,
        view: 'month',
        locale: user.locale,
      })
      ChefService.setSchedule({ recurringSlotInfo, recurringSlots, timeSlots })
    } catch (error) {
      HandleError({ error })
    }
  }

export const DeleteChefSchedule =
  ({
    ChefService,
    RestService,
    AdminUserService,
    pGenerateRecurring,
    pRequestTimeWeeks,
    pResponseAllTimeSlots,
    UIService,
    HandleError,
  }) =>
  async ({ slotId, date, month, closeModal, monthDate, week, day }) => {
    const user = AdminUserService.getUser()
    const chefId = ChefService.getCurrentChefId()
    const weeks = pRequestTimeWeeks(monthDate)
    try {
      const params = {
        slotId,
        month,
      }
      if (week === RECURRING) {
        params.week = week
      }
      if (!!week && !!day) {
        params.week = week
        params.day = day
      }

      const mixedTimeSlots = await RestService.delete(
        `/chefs/schedules/${chefId}/slots`,
        params,
        { timeout: 20000 },
      )

      if (mixedTimeSlots) {
        UIService.FlashMessage.displaySuccessMessage(
          'Removed from Chef Schedule.',
        )
        closeModal()
      }

      const { timeSlots, recurringSlotInfo } = pResponseAllTimeSlots(
        weeks,
        mixedTimeSlots,
        user.locale,
      )
      const recurringSlots = pGenerateRecurring({
        date,
        recurringSlotInfo,
        timeSlots,
        view: 'month',
        locale: user.locale,
      })
      ChefService.setSchedule({ recurringSlotInfo, recurringSlots, timeSlots })
    } catch (error) {
      HandleError({ error })
    }
  }

export const DeleteDayFromRecurringSchedule =
  ({
    ChefService,
    RestService,
    AdminUserService,
    pGenerateRecurring,
    pRequestTimeWeeks,
    pResponseAllTimeSlots,
    UIService,
    HandleError,
  }) =>
  async (data, closeModal, monthDate) => {
    const user = AdminUserService.getUser()
    const chefId = ChefService.getCurrentChefId()
    const date = new Date(data.calendarDate)
    const weeks = pRequestTimeWeeks(monthDate)

    try {
      const mixedTimeSlots = await RestService.delete(
        `/chefs/schedules/${chefId}/slots/single-recur`,
        data,
        { timeout: 20000 },
      )

      if (mixedTimeSlots) {
        const removedDate = data.calendarDate
          .add(data.day - 1, 'days')
          .format('MM/DD/YYYY')
        UIService.FlashMessage.displaySuccessMessage(
          `Removed day ${removedDate} from schedule.`,
        )
        closeModal()
      }

      const { timeSlots, recurringSlotInfo } = pResponseAllTimeSlots(
        weeks,
        mixedTimeSlots,
        user.locale,
      )
      const recurringSlots = pGenerateRecurring({
        date,
        recurringSlotInfo,
        timeSlots,
        view: 'month',
        locale: user.locale,
      })
      ChefService.setSchedule({ recurringSlotInfo, recurringSlots, timeSlots })
    } catch (error) {
      HandleError({ error })
    }
  }
