import { connect } from 'react-redux'

import { MarketDetail } from '@components/market'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { deliveryZones, hqDetails, serviceDepots } = state

  return {
    deliveryZones,
    hqDetails,
    serviceDepots,
  }
}

const mapDispatchToProps = () => {
  const { MarketService, RestService, UIService } = services
  const {
    pResponseMarket,
    pResponseAdminUser,
    pResponseLastChangeToGroupOrderMinimumItems,
  } = presenters.Api
  const {
    CreateDeliveryZone,
    CreateServiceDepot,
    DeleteDeliveryZone,
    DeleteServiceDepot,
    EditDeliveryZone,
    EditHeadquarter,
    EditServiceDepot,
    LoadDeliveryZones,
    LoadHQServiceDepots,
    SaveHeadquarter,
    GetAdminUser,
    GetLastChangeToGroupOrderMinimumItems,
  } = coordinators

  const createDeliveryZone = CreateDeliveryZone({ MarketService, UIService })
  const createServiceDepot = CreateServiceDepot({ MarketService, UIService })

  const deleteDeliveryZone = DeleteDeliveryZone({
    HandleError,
    MarketService,
    RestService,
    UIService,
  })
  const deleteServiceDepot = DeleteServiceDepot({
    HandleError,
    MarketService,
    RestService,
    UIService,
  })

  const editDeliveryZone = EditDeliveryZone({ MarketService, UIService })
  const editHeadquarter = EditHeadquarter({ MarketService, UIService })
  const editServiceDepot = EditServiceDepot({ MarketService, UIService })

  const loadDeliveryZones = LoadDeliveryZones({
    MarketService,
    RestService,
    pResponseMarket,
    HandleError,
  })
  const loadServiceDepots = LoadHQServiceDepots({
    MarketService,
    RestService,
    pResponseMarket,
    HandleError,
  })
  const saveHeadquarter = SaveHeadquarter({
    RestService,
    UIService,
    MarketService,
    pResponseMarket,
    HandleError,
  })
  const getAdminUser = GetAdminUser({
    RestService,
    pResponseAdminUser,
    HandleError,
  })
  const getLastChangeToGroupOrderMinimumItems =
    GetLastChangeToGroupOrderMinimumItems({
      RestService,
      HandleError,
      pResponseLastChangeToGroupOrderMinimumItems,
    })

  return {
    createDeliveryZone,
    createServiceDepot,
    deleteServiceDepot,
    deleteDeliveryZone,
    editDeliveryZone,
    editHeadquarter,
    editServiceDepot,
    loadDeliveryZones,
    loadServiceDepots,
    saveHeadquarter,
    getAdminUser,
    getLastChangeToGroupOrderMinimumItems,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketDetail)
