import { SET_LAST_LOAD_PROPOSAL_DASHBOARD_PARAMS } from '@actions/ui/proposalDashboard'

const initialState = {
  page: undefined,
  search: undefined,
  status: undefined,
  fromDate: undefined,
  toDate: undefined,
}

export const proposalDashboardParams = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAST_LOAD_PROPOSAL_DASHBOARD_PARAMS: {
      const { page, search, status, fromDate, toDate } = action

      return {
        ...state,
        page,
        search,
        status,
        fromDate,
        toDate,
      }
    }
    default:
      return state
  }
}
