import { connect } from 'react-redux'
import { GroupOrderCustomers } from '@components/account/accountSections'
import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { account } = state

  return {
    accountId: account.id,
  }
}

const mapDispatchToProps = () => {
  const { RestService } = services

  const { pResponseGeneric } = presenters.Api

  const { LoadAccountCustomers, LoadAccountMarketplaceUsers } = coordinators

  const loadAccountCustomers = LoadAccountCustomers({
    RestService,
    pResponseGeneric,
    HandleError,
  })
  const loadAccountMarketplaceUsers = LoadAccountMarketplaceUsers({
    RestService,
    pResponseGeneric,
    HandleError,
  })

  return {
    loadAccountCustomers,
    loadAccountMarketplaceUsers,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupOrderCustomers)
