import React from 'react'
import PropTypes from 'prop-types'
import { FormControl } from 'react-bootstrap'

class EditTextCell extends React.Component {
  render() {
    const { order, attribute, key, orderUpdates, updateOrder, columnsLookup } =
      this.props
    let { className } = this.props
    const orderUpdate = orderUpdates[order.id]
    const originalData = order[attribute]
    const revisionData = orderUpdate && orderUpdate[attribute]
    const placeholder = columnsLookup[attribute].header

    className = className || 'normal-text-area'
    let value = originalData
    if (revisionData) {
      className = 'normal-text-area updated-input'
      value = revisionData
    }

    return (
      <td key={key} className={`${className}`}>
        <div className="dashboard-textarea-div">
          <p>
            <FormControl
              className={className || 'form-control'}
              componentClass="textarea"
              placeholder={placeholder}
              value={value}
              onChange={(e) => updateOrder(order.id, attribute, e.target.value)}
            />
          </p>
        </div>
      </td>
    )
  }
}

EditTextCell.propTypes = {
  order: PropTypes.object,
  attribute: PropTypes.string,
  className: PropTypes.string,
  columnsLookup: PropTypes.object,
  key: PropTypes.string,
  orderUpdates: PropTypes.arrayOf(PropTypes.object),

  updateOrder: PropTypes.func,
}

export default EditTextCell
