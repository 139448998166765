import {
  APPEND_PROPOSALS,
  CLEAR_EDIT_PROPOSAL,
  CLEAR_NEW_PROPOSAL,
  CLEAR_PROPOSAL,
  CLEAR_PROPOSALS,
  REMOVE_PROPOSAL_FROM_PROPOSALS,
  SET_EDIT_PROPOSAL,
  SET_NEW_PROPOSAL,
  SET_PROPOSAL,
  SET_PROPOSAL_DASHBOARD,
  SET_PROPOSALS,
  SET_PROPOSAL_ITEMS,
  TOGGLE_PROPOSAL_LOADER,
  TOGGLE_PROPOSAL_MENU_ITEMS_LOADER,
  UPDATE_EDIT_PROPOSAL,
  UPDATE_NEW_PROPOSAL,
} from '@actions/proposal'

import { initialNewOrderState } from './order'
import { deepCopy } from '~/utils'

const initialProposalState = null
const initialProposalsState = []
const initialProposalItemsState = []
const initialState = {
  show: false,
  on: false,
  menuLoaderOn: false,
}

export function proposal(state = initialProposalState, action) {
  const { type, proposal } = action

  switch (type) {
    case CLEAR_PROPOSAL:
      return initialProposalState
    case SET_PROPOSAL:
      return { ...proposal }
    default:
      return state
  }
}

export function proposalDashboard(state = [], action) {
  const { type, proposals } = action

  switch (type) {
    case SET_PROPOSAL_DASHBOARD:
      return proposals
    default:
      return state
  }
}

export function proposals(state = initialProposalsState, action) {
  const { type, proposals } = action

  switch (type) {
    case APPEND_PROPOSALS:
      return [...state, ...proposals]
    case CLEAR_PROPOSALS:
      return initialProposalsState.slice()
    case SET_PROPOSALS:
      return proposals.slice()
    case REMOVE_PROPOSAL_FROM_PROPOSALS: {
      const newProposals = state.slice()
      const idx = newProposals.findIndex((o) => o.id === action.proposalsId)
      if (idx !== -1) {
        newProposals.splice(idx, 1)
      }

      return newProposals
    }
    default:
      return state
  }
}

export function proposalsItems(state = initialProposalItemsState, action) {
  const { type, proposalsItems } = action

  switch (type) {
    case SET_PROPOSAL_ITEMS:
      return proposalsItems.slice()
    default:
      return state
  }
}

export function editProposal(
  state = { ...deepCopy(initialNewOrderState) },
  action,
) {
  const { type, proposal } = action

  switch (type) {
    case SET_EDIT_PROPOSAL:
      return { ...proposal }
    case UPDATE_EDIT_PROPOSAL:
      return { ...state, ...proposal }
    case CLEAR_EDIT_PROPOSAL:
      return { ...deepCopy(initialNewOrderState) }
    default:
      return state
  }
}

export function newProposal(
  state = { ...deepCopy(initialNewOrderState) },
  action,
) {
  const { type, proposal } = action

  switch (type) {
    case SET_NEW_PROPOSAL:
      return { ...proposal }
    case UPDATE_NEW_PROPOSAL:
      return { ...state, ...proposal }
    case CLEAR_NEW_PROPOSAL:
      return { ...deepCopy(initialNewOrderState) }
    default:
      return state
  }
}

export const toggleProposalLoader = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_PROPOSAL_LOADER: {
      return {
        on: action.on,
      }
    }
    case TOGGLE_PROPOSAL_MENU_ITEMS_LOADER: {
      return {
        menuLoaderOn: action.menuLoaderOn,
      }
    }
    default:
      return state
  }
}
