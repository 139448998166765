import { camelCaseify, formAdd, sortByAttribute, roundMoneyUp } from '~/utils'

import presenters from '@presenters'

export const pGetMainMenuItems = (conceptsMenuItems) => {
  return conceptsMenuItems
    .filter((i) => i.menuItem && i.menuItem.mealType === 'Main' && !i.destroy)
    .sort((a, b) => a.position - b.position)
}

export const pGetSideMenuItems = (conceptsMenuItems) => {
  return conceptsMenuItems
    .filter(
      (i) =>
        i.menuItem &&
        (i.menuItem.mealType === 'Side' ||
          i.menuItem.mealType === 'Vegetable Side' ||
          i.menuItem.mealType === 'Starch Side') &&
        !i.destroy,
    )
    .sort((a, b) => a.position - b.position)
}

export const pGetOtherMenuItems = (conceptsMenuItems) => {
  return conceptsMenuItems
    .filter(
      (i) =>
        i.menuItem &&
        !['Main', 'Side', 'Starch Side', 'Vegetable Side'].includes(
          i.menuItem.mealType,
        ) &&
        !i.destroy,
    )
    .sort((a, b) => a.position - b.position)
}

export const pGetServingsPerPerson = (menuItem, headCount) => {
  const { pricePerPersonCalc, quantity, servingsPerPkg } = menuItem
  const pricedPerPkg = pricePerPersonCalc === 'PerPackage'
  const servPerPerson = pricedPerPkg
    ? ((quantity || 0) * servingsPerPkg) / headCount
    : (quantity || 0) / headCount

  return servPerPerson
}

export const pGetPricePerPerson = (menuItem, headCount) => {
  const { marketPrice, price, quantity } = menuItem
  const pricePerPerson = (quantity * Number(price || marketPrice)) / headCount

  return pricePerPerson
}

export const pCalculatePPP = (mains, sides, headCount) => {
  const totalPrice = [...mains, ...sides].reduce((sum, menuItem) => {
    return (
      menuItem.quantity * Number(menuItem.price || menuItem.marketPrice) + sum
    )
  }, 0.0)

  return !headCount ? 0.0 : roundMoneyUp(totalPrice / headCount)
}

export const pCalculateConceptPPP = (mains, sides) => {
  if (mains.length == 0 && sides.length == 0) {
    return 0
  }
  const averagedItems = {
    protein: [],
    veg: [],
    starchSide: [],
    vegSide: [],
  }
  mains.forEach((mainItem) => {
    if (mainItem.menuItem) {
      //subfield is used on render
      mainItem = mainItem.menuItem
    }
    if (!mainItem.id) {
      //handles new items added via component
      return
    }
    if (mainItem.tagsDietaryPreferenceList.includes('vegetarian')) {
      averagedItems.veg.push(mainItem)
    } else {
      averagedItems.protein.push(mainItem)
    }
  })
  sides.forEach((sideItem) => {
    if (sideItem.menuItem) {
      //subfield is used on render
      sideItem = sideItem.menuItem
    }
    if (!sideItem.id) {
      //handles new items added via component
      return
    }
    if (sideItem.mealType === 'Starch Side') {
      averagedItems.starchSide.push(sideItem)
    } else {
      averagedItems.vegSide.push(sideItem)
    }
  })
  let preMainTotal = 0.0
  let preSideTotal = 0.0
  averagedItems.protein.forEach((item, idx) => {
    if (idx >= 2) {
      return
    } else {
      preMainTotal += item.price
        ? Number(item.price) * 20
        : Number(item.marketPrice) * 20
    }
  })
  const vegEntree = averagedItems.veg[0]
  if (vegEntree) {
    preMainTotal += vegEntree.price
      ? Number(vegEntree.price) * 8
      : Number(vegEntree.marketPrice) * 8
  }
  const vegSide = averagedItems.vegSide[0]
  if (vegSide) {
    preSideTotal += vegSide.price
      ? Number(vegSide.price) * 30
      : Number(vegSide.marketPrice) * 30
  }
  const starchSide = averagedItems.starchSide[0]
  if (starchSide) {
    preSideTotal += starchSide.price
      ? Number(starchSide.price) * 30
      : Number(starchSide.marketPrice) * 30
  }

  return (preMainTotal / 40 + preSideTotal / 40).toFixed(2)
}

export const pCalculateConceptMargin = (items) => {
  let marketPrice = 0.0
  let chefPrice = 0.0
  items.forEach((item) => {
    const { menuItem } = item
    if (menuItem && menuItem.id) {
      marketPrice += menuItem.price
        ? Number(menuItem.price)
        : Number(menuItem.marketPrice)
      chefPrice += menuItem.cost
        ? Number(menuItem.cost)
        : Number(menuItem.chefPrice)
    } else if (item.price && (item.cost || item.chefPrice)) {
      marketPrice += Number(item.price)
      chefPrice += item.cost ? Number(item.cost) : Number(item.chefPrice)
    }
  })

  //eliminates dividing by 0
  return !marketPrice ? 0.0 : ((marketPrice - chefPrice) / marketPrice) * 100
}

export const pResponseConcept = (json) => {
  const concept = camelCaseify(json)
  concept.tagsCuisine = (concept.tagsCuisineList || '').split(',')
  concept.tagsDayPart = (concept.tagsDayPartList || '').split(',')
  concept.conceptsMenuItems = concept.conceptsMenuItems || []
  concept.groupOrderAvailableDays = (concept.groupOrderAvailableDays || '')
    .split(',')
    .map((d) => parseInt(d))

  const cleanItem = (isChild) => (item) => {
    const cleanedItem = {
      ...item,
      menuItemId: item.id,
      chefPrice: Number(item.cost || ''),
      price: Number(item.price || ''),
      marketPrice: Number(item.price || ''),
      retailPrice: Number(item.retailPrice || ''),
    }

    if (isChild) {
      cleanedItem.category = item.subMenuItemCategory
    }

    return cleanedItem
  }

  concept.conceptsMenuItems.forEach((conceptItem) => {
    let { menuItem } = conceptItem

    menuItem = cleanItem(false)(menuItem)

    if (menuItem.childItems) {
      menuItem.childItems = menuItem.childItems.map(cleanItem(true))
    }

    conceptItem.menuItem = menuItem
  })
  concept.estimatedPricePerPerson = Number(
    concept.estimatedPricePerPerson || '',
  )
  concept.margin = Number(concept.margin || '')

  return concept
}

export const pResponseGroupOrderConcepts = (json) => {
  return json.map((menu) => pResponseGroupOrderConcept(menu))
}

export const pResponseGroupOrderConcept = (data) => {
  const menu = camelCaseify(data)
  const {
    id,
    chef = {},
    coverImage,
    name,
    tagsCuisine: cuisines = [],
    allMenuItems = [],
    groupOrderAvailableDays: availableDays,
    groupOrderCutoffDays: cutoffDays,
    groupOrderCutoffTime: cutoffHours,
    groupOrderPickupTime: pickupHours,
    groupOrderMaxItems: maxItems,
  } = menu
  //flters non Entrees, so dateMenu cannot have anything besides Entrees
  const menuItems = allMenuItems
    .map((it) => pResponseGroupOrderMenuItem(it))
    .filter((item) => item.itemType === 'Entree')
  const image = coverImage && coverImage.image && coverImage.image.url
  const { Id: chefId, name: chefName } = chef

  return {
    id,
    name,
    chef: {
      id: chefId,
      name: chefName,
    },
    menuItems,
    image,
    cuisines,
    availableDays,
    cutoffDays,
    cutoffHours,
    pickupHours,
    maxItems,
    autocompleteKey: `${chefName} - ${name}`,
  }
}

export const pResponseGroupOrderMenuItem = (json, childItems = false) => {
  const it = camelCaseify(json)

  let itemType
  switch (it.mealType) {
    case 'Main':
      itemType = 'Entree'
      break
    case 'Dessert':
      itemType = 'Dessert'
      break
    case 'Drink':
      itemType = 'Drink'
      break
    default:
      itemType = 'Side'
  }

  let childItemsArr = []
  if (!childItems && it.childItems) {
    childItemsArr = it.childItems.map((child) =>
      pResponseGroupOrderMenuItem(child, true),
    )
  }

  return {
    id: it.id,
    name: it.name,
    description: it.description,
    vendorDescription: it.vendorDescription,
    vendorNotes: it.vendorNotes,
    dailyOrderLimit: it.dailyOrderLimit,
    price: parseFloat(it.price),
    chefPrice: parseFloat(it.chefPrice),
    retailPrice: parseFloat(it.retailPrice),
    itemType,
    dietaryPrefs: it.tagsDietaryPreferenceList.split(',').filter((t) => t),
    ingredients: it.tagsIngredientList.split(',').filter((t) => t),
    image: it.image && it.image.image && it.image.image.url,
    isHot: it.isHot,
    countPerShelf: it.countPerShelf,
    averageRating: parseFloat(it.averageRating),
    mealTypes: (it.tagsGroupOrderMealTypeList || '')
      .split(',')
      .filter((t) => t),
    childItems: childItemsArr,
  }
}

export const pResponsePopUpConcepts = (json) => {
  const menus = camelCaseify(json)

  return menus.map((menu) => pResponsePopUpConcept(menu))
}

export const pResponsePopUpConcept = (menu) => {
  const {
    id,
    chef = {},
    coverImage,
    name,
    tagsCuisine: cuisines = [],
    allMenuItems = [],
    servingImages = [],
  } = menu
  const entrees = []
  const sides = []
  const drinks = []
  const desserts = []

  allMenuItems.forEach((it) => {
    const menuItem = pResponsePopUpMenuItem(it)
    switch (it.mealType) {
      case 'Main':
        entrees.push(menuItem)
        break
      case 'Dessert':
        desserts.push(menuItem)
        break
      case 'Drink':
        drinks.push(menuItem)
        break
      default:
        sides.push(menuItem)
    }
  })
  const image = coverImage && coverImage.image && coverImage.image.url
  const { Id: chefId, name: chefName } = chef

  return {
    id,
    name,
    chef: {
      id: chefId,
      name: chefName,
    },
    image,
    cuisines,
    entrees,
    sides,
    drinks,
    desserts,
    servingImages,
    autocompleteKey: `${chefName} - ${name}`,
  }
}

export const pResponsePopUpMenuItem = (json) => {
  const it = camelCaseify(json)

  return {
    id: it.id,
    name: it.name,
    barcode: it.barcode,
    description: it.description,
    quantity: 0,
    minQty: it.minQty,
    price: parseFloat(it.price),
    chefPrice: parseFloat(it.chefPrice),
    retailPrice: parseFloat(it.retailPrice),
    servingSize: it.servingSize,
    supplierType: 'Chef',
    dietaryPrefs: it.tagsDietaryPreferenceList.split(',').filter((t) => t),
    ingredients: it.tagsIngredientList.split(',').filter((t) => t),
    image: it.image && it.image.image && it.image.image.url,
    childItems: it.childItems.map((ch) => ({
      id: ch.id,
      name: ch.name,
      description: ch.description,
      quantity: 0,
      price: parseFloat(ch.price),
      chefPrice: parseFloat(ch.chefPrice),
      dietaryPrefs: ch.tagsDietaryPreferenceList.split(',').filter((t) => t),
      ingredients: ch.tagsIngredientList.split(',').filter((t) => t),
    })),
  }
}

export const pResponsePopUpMenuItems = (json) => {
  return json.map((it) => pResponsePopUpMenuItem(it))
}

export const pResponseConcepts = (json) => {
  return camelCaseify(json).map((c) => {
    return {
      ...c,
      margin: pCalculateConceptMargin(c.menuItems),
    }
  })
}

export const pResponseFilteredConcepts = (json) => {
  const data = camelCaseify(json)

  return data
}

export const pResponseAllChefConcepts = (json) => {
  const concepts = camelCaseify(json).map((c) => {
    return {
      id: c.id,
      name: c.name,
      tagsCuisineList: c.tagsCuisineList,
      tagsDayPartList: c.tagsDayPartList,
      items: c.menuItems.map((i) => presenters.Api.pResponseChefMenuItem(i)),
    }
  })

  sortByAttribute(concepts, 'name')

  return concepts
}

export const pRequestUpdateMenuConcept = (data) => {
  const req = {}

  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'chefId', 'chef_id')
  formAdd(data, req, 'featured', 'featured')
  formAdd(data, req, 'name', 'name')
  formAdd(data, req, 'isEnabled', 'is_enabled')
  formAdd(data, req, 'isApproved', 'is_approved')
  formAdd(data, req, 'isUpdatedStructure', 'is_updated_structure')
  formAdd(data, req, 'tagsCuisine', 'tags_cuisine_list', (d) => d.join(','))
  formAdd(data, req, 'tagsDayPartList', 'tags_day_part_list')
  formAdd(data, req, 'estimatedPricePerPerson', 'estimated_price_per_person')
  formAdd(data, req, 'margin', 'margin')
  formAdd(data, req, 'marketType', 'market_type')
  formAdd(data, req, 'readyToHeat', 'ready_to_heat')
  formAdd(
    data,
    req,
    'groupOrderAvailableDays',
    'group_order_available_days',
    (days) => days.join(','),
  )
  formAdd(data, req, 'groupOrderCutoffDays', 'group_order_cutoff_days')
  formAdd(data, req, 'groupOrderCutoffTime', 'group_order_cutoff_time')
  formAdd(data, req, 'groupOrderPickupTime', 'group_order_pickup_time')
  formAdd(data, req, 'groupOrderMaxItems', 'group_order_max_items')

  const conceptMenuItemsAttributes = []
  data.conceptsMenuItems.forEach((item) => {
    const { destroy, id, menuItem, position } = item
    const conceptMenuItem = { menu_item_id: menuItem.id, position, id }
    if (destroy) {
      conceptMenuItem._destroy = true
      delete conceptMenuItem.menu_item_id
    }
    if (conceptMenuItem._destroy || conceptMenuItem.menu_item_id) {
      conceptMenuItemsAttributes.push(conceptMenuItem)
    }
  })
  req.concepts_menu_items_attributes = conceptMenuItemsAttributes

  return { concept: req }
}

export const pRequestUpdateConceptImages = (data) => {
  if (
    (data.images && data.images.length > 0) ||
    (data.servingImages && data.servingImages.length > 0)
  ) {
    const { images, servingImages } = data
    const req = new FormData()
    let isModified = false

    images.forEach((image, index) => {
      if (image.id && image.destroy) {
        req.append('[concept][images_attributes][' + index + '][id]', image.id)
        req.append(
          '[concept][images_attributes][' + index + '][_destroy]',
          true,
        )
        isModified = true
      } else if (!image.id) {
        req.append('[concept][images_attributes][' + index + '][image]', image)
        isModified = true
      }
    })

    servingImages.forEach((servingImage, index) => {
      if (servingImage.id && servingImage.destroy) {
        req.append(
          '[concept][serving_images_attributes][' + index + '][id]',
          servingImage.id,
        )
        req.append(
          '[concept][serving_images_attributes][' + index + '][_destroy]',
          true,
        )
        isModified = true
      } else if (!servingImage.id) {
        req.append(
          '[concept][serving_images_attributes][' + index + '][flags]',
          1,
        )
        req.append(
          '[concept][serving_images_attributes][' + index + '][image]',
          servingImage,
        )
        isModified = true
      }
    })

    if (!isModified) {
      return undefined
    }

    return req
  } else {
    return undefined
  }
}

const OrderType = {
  LUNCH: 'Lunch',
  BREAKFAST: 'Breakfast',
  DINNER: 'Dinner',
  DESSERT: 'Dessert',
  SNACK: 'Snack',
  SNACK_PACK: 'Snack Pack',
  HOME: 'Home',
  POP_UP: 'Pop-Up',
  VCX: 'VCX',
}

const ConceptMarketType = {
  OFFICE: 'Office',
  HOME: 'Home',
  POP_UP: 'Pop-Up',
  GROUP_ORDER: 'Group Order',
}

const getConceptMarketTypeFromOrderType = (orderType) => {
  const cateringOrderTypes = [
    OrderType.LUNCH,
    OrderType.BREAKFAST,
    OrderType.DINNER,
    OrderType.DESSERT,
    OrderType.SNACK,
    OrderType.SNACK_PACK,
  ]
  if (cateringOrderTypes.includes(orderType)) {
    return ConceptMarketType.OFFICE
  } else if (orderType === OrderType.HOME) {
    return ConceptMarketType.HOME
  } else if (orderType === OrderType.POP_UP) {
    return ConceptMarketType.POP_UP
  }

  return null
}

export const pRequestLoadConcepts = (orderType) => {
  const request = { verbose: 1 }
  const marketType = getConceptMarketTypeFromOrderType(orderType)
  if (marketType) {
    request['market_type'] = marketType
  }

  return request
}

export const pRequestLoadCachedFilteredConcepts = ({
  clientSetUpTime,
  headquarterId,
  search,
  orderType,
  chefId,
  filters,
  offset,
  limit,
  sortBy,
  sortOrder,
}) => {
  let request = {
    sort_by: 'margin',
    sort_order: -1,
    // is_updated_structure: true,
  }
  if (clientSetUpTime) {
    request.client_set_up_time = clientSetUpTime
  }
  if (headquarterId) {
    request.headquarter_id = headquarterId
  }
  if (search) {
    request.search = search
  }
  if (orderType) {
    request = {
      ...request,
      ...pRequestLoadConcepts(orderType),
    }
  }
  if (chefId) {
    request.chef_id = chefId
  }
  if (filters) {
    if (filters.cuisineFilter) {
      request.cuisine_filter = filters.cuisineFilter
    }
    if (filters.dietaryTagFilter) {
      request.dietary_tag_filter = filters.dietaryTagFilter
    }
    if (filters.neDietaryTagFilter) {
      request.ne_dietary_tag_filter = filters.neDietaryTagFilter
    }
    if (filters.estimatedPricePerPersonFilter) {
      request.estimated_price_per_person_filter =
        filters.estimatedPricePerPersonFilter
    }
  }
  if (typeof offset === 'number' && offset >= 0) {
    request.offset = offset
  }
  if (limit) {
    request.limit = limit
  }
  if (sortBy) {
    request.sort_by = sortBy
  }
  if (sortOrder) {
    request.sort_order = sortOrder
  }

  return request
}
