import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class SettingsService extends BaseService {
  setSettings = ({ settingsKey, settingsValue }) => {
    this.dispatch(actions.setSettings({ settingsKey, settingsValue }))
  }

  getSettings(settingsKey) {
    return this.getState().settings[settingsKey]
  }
}
