import React, { useState, useEffect, useCallback } from 'react'
import Modal from '@components/common/modal/Modal'
import FlexContainer from '@components/common/FlexContainer'
import TooltipModal from '@components/common/modal/TooltipModal'
import YSpacing from '@components/common/YSpacing'
import Table from '@components/common/Table'
import Loader from '@components/common/Loader'
import Button from '@components/common/form/Button'
import { Label } from '@res/styledComponents/index'
import { TextInput } from '@components/common/form'
import { HubspotCompany } from '@types'

interface SearchViaHubSpotModalProps {
  close: () => void
  confirmationModal: { show: (val: { text: string }) => boolean }
  hubspotCompanies: HubspotCompany[]
  loadHubspotCompany: (val: { search: string }) => Promise<HubspotCompany[]>
  newAccountFromHubspot: (c: HubspotCompany) => void
  newManualAccount: () => void
}

const SearchViaHubSpotModal = ({
  close,
  confirmationModal,
  hubspotCompanies,
  loadHubspotCompany,
  newAccountFromHubspot,
  newManualAccount,
}: SearchViaHubSpotModalProps) => {
  const [inputValue, setInputValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  let timer: NodeJS.Timeout | null = null

  const callAfterTimeout = (func: () => void, delay = 1500) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(func, delay)
  }

  const searchHubspotCompany = useCallback(async () => {
    setIsLoading(true)
    await loadHubspotCompany({ search: inputValue })
    setIsLoading(false)
  }, [inputValue])

  useEffect(() => {
    callAfterTimeout(searchHubspotCompany, 1500)

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [inputValue])

  const onChangeInput = (e: InputEvent) => {
    setInputValue((e.target as HTMLInputElement).value)
  }

  const onManualInput = async () => {
    const confirmManual = await confirmationModal.show({
      text: 'Are you sure you wish to enter a client manually? Tech team suggests importing clients from Hubspot.',
    })
    if (confirmManual) {
      newManualAccount()
    }
  }

  const onClientSelect = async (company: HubspotCompany) => {
    const confirmCorrectClient = await confirmationModal.show({
      text: `Are you sure you want to create a client for ${company.name}?`,
    })
    if (confirmCorrectClient) {
      newAccountFromHubspot(company)
    }
  }

  return (
    <Modal width="1200px" title="Search Hubspot Clients" hideModal={close}>
      <FlexContainer flexDirection="row" alignItems="flex-start">
        <FlexContainer flexDirection="column" width="30%">
          <FlexContainer>
            <Label>Enter HubSpot Company Record Number</Label>
            <TooltipModal
              unicode="&#9432;"
              width="400px"
              information="Please ensure that you have successfully created a client in hubspot & then search by company's record number."
              marginTop="18px"
            />
          </FlexContainer>
          <TextInput
            type="text"
            placeholder="Record Number"
            value={inputValue}
            onChange={onChangeInput}
            width="200px"
          />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer flexDirection="column">
        {isLoading ? (
          <Loader />
        ) : (
          <Table
            headings={[
              'Name',
              'Domain',
              'Phone',
              'City/State',
              'Industry',
              'Contacts',
            ]}
          >
            {hubspotCompanies.map((c) => (
              <tr
                key={c.id}
                onClick={() => onClientSelect(c)}
                style={{ cursor: 'pointer' }}
              >
                <td>{c.name}</td>
                <td>{c.domain}</td>
                <td>{c.phone}</td>
                <td>
                  {c.city}/{c.state}
                </td>
                <td>{c.industry}</td>
                <td>
                  {c.contacts.length === 0
                    ? 'No Contacts'
                    : c.contacts
                        .map(
                          (contact) =>
                            `${contact.firstName} ${contact.lastName} (${contact.email})`,
                        )
                        .join(', ')}
                </td>
              </tr>
            ))}
          </Table>
        )}
      </FlexContainer>
      <YSpacing height="30px" />
      <FlexContainer
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <p style={{ color: 'red' }}>
          If you prefer not to import a company from HubSpot, please click the
          button below to manually enter a new client.
        </p>
        <Button label="Manual Entry" onClick={onManualInput} />
      </FlexContainer>
    </Modal>
  )
}

export default SearchViaHubSpotModal
