export const CreateDeliveryZone =
  ({ MarketService, UIService }) =>
  async (hqDetails) => {
    MarketService.setDeliveryZone({ headquarter: hqDetails })
    const { deliveryZone } = MarketService.getState()
    MarketService.setEditDeliveryZone(deliveryZone)
    UIService.EditMarket.showDeliveryZone()
  }

export const LoadDeliveryZones =
  ({ MarketService, pResponseMarket, RestService, HandleError }) =>
  async ({ hqId, limit, offset }) => {
    try {
      const params = { headquarter_id: hqId, limit, offset }
      const hq = await RestService.get(`/headquarters/${hqId}`, params)
      const pDZones = pResponseMarket(hq).zips
      MarketService.setDeliveryZones(pDZones)
    } catch (err) {
      HandleError({ error: err, namespace: 'editDeliveryZoneModal' })
    }
  }

export const EditDeliveryZone =
  ({ MarketService, UIService }) =>
  async (dZone) => {
    MarketService.setDeliveryZone(dZone)
    MarketService.setEditDeliveryZone(dZone)
    UIService.EditMarket.showDeliveryZone()
  }

export const DeleteDeliveryZone =
  ({ HandleError, MarketService, RestService, UIService }) =>
  async (dZone, hqDetails) => {
    const text = 'Are you sure you want to delete this delivery zone?'
    const doDelete = await UIService.ConfirmationModal.show({ text })
    if (doDelete) {
      try {
        const { zips } = hqDetails
        hqDetails.zips = zips.filter((z) => z.zipcode !== dZone.zipcode)
        await RestService.put(`/headquarters/${hqDetails.id}`, {
          deleteZipcode: dZone.zipcode,
          ...hqDetails,
        })
        MarketService.deleteDeliveryZone(dZone.id)
      } catch (err) {
        HandleError({ error: err, namespace: 'editDeliveryZoneModal' })
      }
    }
  }

export const SaveDeliveryZone =
  ({
    MarketService,
    RestService,
    UIService,
    pRequestUpdateDeliveryZone,
    pResponseMarket,
    HandleError,
  }) =>
  async (data) => {
    const pData = pRequestUpdateDeliveryZone(data)
    let dZone
    try {
      if (data.id) {
        dZone = await RestService.put(
          `/api/admin/delivery_zones/${data.id}`,
          pData,
        )
      } else {
        dZone = await RestService.post('/api/admin/delivery_zones', pData)
      }
      MarketService.updateDeliveryZone(pResponseMarket(dZone))
      UIService.EditMarket.closeDeliveryZone()
    } catch (err) {
      HandleError({ error: err, namespace: 'editDeliveryZoneModal' })
    }
  }
