import React from 'react'
import PropTypes from 'prop-types'

const SurveyItem = (props) => {
  const { isActive, onSelectSurvey, surveyDisplayName, surveyName } = props

  return (
    <div
      className={isActive ? 'list-item highlighted' : 'list-item'}
      onClick={() => onSelectSurvey(surveyName)}
    >
      <div>
        <span className={isActive && 'extra-bold'}>{surveyDisplayName}</span>
      </div>
    </div>
  )
}

SurveyItem.propTypes = {
  isActive: PropTypes.bool,
  surveyDisplayName: PropTypes.string,
  surveyName: PropTypes.string,

  onSelectSurvey: PropTypes.func,
}

export default SurveyItem
