import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class PromoCodesService extends BaseService {
  setPromoCode(promoCode) {
    this.dispatch(actions.setPromoCode(promoCode))
  }

  setPromoCodes(promoCodes) {
    this.dispatch(actions.setPromoCodes(promoCodes))
  }

  setPromoCodeAttributes(promoCodeAttributes) {
    this.dispatch(actions.setPromoCodeAttributes(promoCodeAttributes))
  }
}
