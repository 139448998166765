export const REPLACE_SUBSCRIBER = 'REPLACE_SUBSCRIBER'
export const SET_SUBSCRIBERS = 'SET_SUBSCRIBERS'
export const REPLACE_SUBSCRIPTION = 'REPLACE_SUBSCRIPTION'
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS'

export const replaceSubscriber = (member) => ({
  type: REPLACE_SUBSCRIBER,
  member,
})
export const setSubscribers = (members) => ({ type: SET_SUBSCRIBERS, members })
export const replaceSubscription = (subscription) => ({
  type: REPLACE_SUBSCRIPTION,
  subscription,
})
export const setSubscriptions = (subscriptions) => ({
  type: SET_SUBSCRIPTIONS,
  subscriptions,
})
