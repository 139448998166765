import { connect } from 'react-redux'

import { AccountPage } from '@components/account'

import coordinators from '@coordinators'
import services from '@services'

const mapStateToProps = (state) => {
  const accountName = state.account && state.account.name

  return { accountName }
}

const mapDispatchToProps = () => {
  const { UIService, AccountService } = services
  const { NewAccount } = coordinators

  const newAccount = NewAccount({ AccountService, UIService })

  return { newAccount }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage)
