import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import { CurrencyInput } from '@components/common/form'

const RefreshArrayAttributes = ['chefPayouts']
const InitialState = {
  chefPayouts: [],
}

class EditChefPayoutsModal extends Component {
  state = InitialState

  componentDidMount() {
    const newState = {}
    RefreshArrayAttributes.forEach((key) => {
      newState[key] = this.props[key].slice() || []
    })

    this.setState(newState)
  }

  adjustPayout = (oldPayout, newPayout, isDefault) => {
    this.setState((state) => {
      const chefPayouts = state.chefPayouts.map((p) => {
        if (p.chefId == oldPayout.chefId) {
          p.payout = newPayout
          p['isDefault'] = isDefault
        }

        return p
      })

      return {
        chefPayouts,
      }
    })
  }

  onAdjust50 = (oldPayout) => () => {
    this.adjustPayout(oldPayout, oldPayout.payout / 2, false)
  }

  onAdjustDefault = (oldPayout) => () => {
    this.adjustPayout(
      oldPayout,
      this.props.defaultPayouts.find((d) => d.chefId == oldPayout.chefId)
        .payout,
      true,
    )
  }

  onChangePayout = (oldPayout) => (val) =>
    this.adjustPayout(oldPayout, val, false)

  onHide = () => this.props.closeChefPayouts()

  onSave = async () => {
    const { orderStatus } = this.props
    this.props.adjustChefPayouts({
      orderStatus,
      orderId: this.props.orderId,
      chefPayouts: this.state.chefPayouts.filter((c) => !c.isDefault),
    })
    this.onHide()
  }

  renderPayouts = () => {
    return this.state.chefPayouts.map((p) => {
      return (
        <div className="label-padding mb-12" key={p.chefId}>
          <div> Chef {p.chefName} </div>
          <CurrencyInput
            value={p.payout || 0}
            onChange={this.onChangePayout(p)}
          />
          <button
            className="btn btn-link"
            id="adjust-50"
            onClick={this.onAdjust50(p)}
          >
            Pay 50%
          </button>
          <button
            className="btn btn-link"
            id="adjust-default"
            onClick={this.onAdjustDefault(p)}
          >
            Pay Default
          </button>
        </div>
      )
    })
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.onHide}
        dialogClassName="chefPayoutsModal chef-payouts-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            Adjust Chef Payouts
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="padding-20">
            <div className="form-field-container">{this.renderPayouts()}</div>
            <div className="mt-25">
              <button
                className="button-primary"
                id="save-chef-payouts"
                onClick={this.onSave}
              >
                {' '}
                Save{' '}
              </button>
              <button
                className="button-neutral"
                id="cancel"
                onClick={this.onHide}
              >
                {' '}
                Cancel{' '}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

EditChefPayoutsModal.propTypes = {
  chefPayouts: PropTypes.array,
  defaultPayouts: PropTypes.array,

  show: PropTypes.bool,

  adjustChefPayouts: PropTypes.func,
  changeOrderStatus: PropTypes.func,
  closeChefPayouts: PropTypes.func,

  orderId: PropTypes.string,
  orderStatus: PropTypes.string,
}

export default EditChefPayoutsModal
