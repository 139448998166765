import { connect } from 'react-redux'

import ForgotPass from '@components/ForgotPass'

import services from '@services'
import coordinators from '@coordinators'
import { HandleError } from '@coordinators/composed'

const mapDispatchToProps = () => {
  const { RestService, UIService } = services

  const sendResetPassEmail = coordinators.SendResetPassEmail({
    RestService,
    UIService,
    HandleError,
  })

  return {
    sendResetPassEmail,
  }
}

export default connect(null, mapDispatchToProps)(ForgotPass)
