import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { CurrencyInput, QuantityInput } from '@components/common/form'
import { AuthorizedInteractable } from '@containers/common/auth'
import trash from '@res/images/bin.svg'

class OrderVirtualKit extends Component {
  state = {
    chefId: undefined,
    id: undefined,
    virtualKitId: undefined,
    displayOrder: undefined,

    name: '',
    pricingRate: undefined,
    rateQuantity: 0.0,
    quantity: 0,
    price: 0,
    cost: 0,
    total: 0,
  }

  componentDidMount() {
    const {
      name,
      quantity,
      cost,
      price,
      total,
      chefId,
      id,
      virtualKitId,
      displayOrder,
      pricingRate,
      rateQuantity,
    } = this.props.virtualKit

    this.setState({
      name,
      quantity,
      cost,
      price,
      total,
      chefId,
      id,
      virtualKitId,
      displayOrder,
      pricingRate,
      rateQuantity,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.virtualKit !== this.props.virtualKit) {
      const {
        name,
        quantity,
        cost,
        price,
        total,
        chefId,
        id,
        virtualKitId,
        displayOrder,
        pricingRate,
        rateQuantity,
      } = nextProps.virtualKit

      this.setState({
        name,
        quantity,
        cost,
        price,
        total,
        chefId,
        id,
        virtualKitId,
        displayOrder,
        pricingRate,
        rateQuantity,
      })
    }
  }

  calcTotalAndUpdateItem = () => {
    const { quantity, price, rateQuantity } = this.state
    this.setState(
      { total: quantity * price * (rateQuantity || 1) },
      this.updateItem,
    )
  }

  updateItem = () => {
    this.props.onEdit(this.state)
  }

  onInputQuantity = (attribute) => (quantity) => {
    this.setState({ [attribute]: quantity || 0 }, this.calcTotalAndUpdateItem)
  }

  onInputCurrency = (attribute) => (value) => {
    this.setState(
      { [attribute]: parseFloat(value) },
      this.calcTotalAndUpdateItem,
    )
  }

  renderName = () => {
    const { name, pricingRate } = this.state
    const nameWithRate = pricingRate ? name + ' per ' + pricingRate : name

    return <p className="regular text-base">{nameWithRate}</p>
  }

  renderReOrder = (change) => {
    const { isFirst, isLast, virtualKit, onReorder } = this.props

    let symbol
    if (change > 0 && !isLast) {
      symbol = '▼'
    } else if (change < 0 && !isFirst) {
      symbol = '▲'
    }
    if (symbol) {
      return (
        <span className="pointer" onClick={() => onReorder(virtualKit, change)}>
          {' '}
          {symbol}{' '}
        </span>
      )
    }
  }

  render() {
    const { onRemove, virtualKit } = this.props
    const { quantity, cost, price, rateQuantity } = this.state
    let { total } = this.state
    total = total || quantity * price * (rateQuantity || 1)
    const pricingRoles = ['master admin', 'sales lead', 'chef lead']

    return (
      <tr className="">
        <td className="flex w-2 pr-3">
          <div className="flex flex-col mr-5 justify-center">
            {this.renderReOrder(-1)}
            {this.renderReOrder(1)}
          </div>
        </td>
        <td className="align-middle">{this.renderName()}</td>
        <td className="text-right align-middle">
          <QuantityInput
            className="text-right border w-20 h-8 regular text-base text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
            value={quantity}
            onChange={this.onInputQuantity('quantity')}
          />
        </td>
        <td className="text-right align-middle">
          <AuthorizedInteractable roles={pricingRoles}>
            <CurrencyInput
              className="text-right border w-20 h-8 regular text-base text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
              value={cost}
              disabled={true}
              onChange={this.onInputCurrency('cost')}
            />
          </AuthorizedInteractable>
        </td>
        <td className="text-right align-middle">
          <AuthorizedInteractable roles={pricingRoles}>
            <CurrencyInput
              className="text-right border w-20 h-8 regular text-base text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
              value={price}
              disabled={true}
              onChange={this.onInputCurrency('price')}
            />
          </AuthorizedInteractable>
        </td>
        <td className="text-right align-middle">
          <CurrencyInput
            className="text-right border w-20 h-8 regular text-base text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
            value={total}
            disabled={true}
            onChange={this.onInputCurrency('total')}
          />
        </td>
        <td className="align-middle">
          <img
            className="w-5 ml-3 box-content"
            onClick={() => onRemove(virtualKit)}
            src={trash}
          />
        </td>
      </tr>
    )
  }
}

OrderVirtualKit.propTypes = {
  virtualKit: PropTypes.object,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,

  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onReorder: PropTypes.func,
}

export default OrderVirtualKit
