import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors } from '../../../constants'

const Table = (props) => {
  const { children, headings, isStickyHeading } = props

  return (
    <TableStyles isStickyHeading={isStickyHeading}>
      {headings && (
        <tr>
          {headings.map((h, i) => (
            <th key={i}>{h}</th>
          ))}
        </tr>
      )}
      {children}
    </TableStyles>
  )
}

const TableStyles = styled.table`
  width: 100%;
  th {
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'bold';
    color: ${colors.gray400};
    padding: 5px 10px;
    background: #eff1f6;
    white-space: nowrap;
    position: ${(props) => (props.isStickyHeading ? 'sticky' : 'static')};
    top: ${(props) => (props.isStickyHeading ? 0 : 'auto')};
  }
  tr {
    border-bottom: 1px solid ${colors.gray200};
  }
  td {
    color: ${colors.gray400};
    vertical-align: middle;
    padding: 5px 10px;
    font-family: 'regular';
  }
  .right {
    text-align: right;
  }
`

Table.propTypes = {
  isStickyHeading: PropTypes.boolean,
  children: PropTypes.node,
  headings: PropTypes.array,
}

export default Table
