import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

export const OrderablePropType = PropTypes.shape({
  id: PropTypes.string,
  account: PropTypes.object,
  accountExecutive: PropTypes.object,
  accountSettings: PropTypes.object,
  chefPayouts: PropTypes.object,
  chefStatuses: PropTypes.object,
  dropoffAddress: PropTypes.object,
  billingAddress: PropTypes.object,
  invoiceContact: PropTypes.object,
  receiptContact: PropTypes.object,
  cateringContact: PropTypes.object,
  paymentMethod: PropTypes.object,
  createdAt: PropTypes.instanceOf(Moment),
  chargeDate: PropTypes.instanceOf(Moment),
  conceptId: PropTypes.string,
  clientSetUpTime: PropTypes.instanceOf(Moment),
  clientDoNotArriveBeforeTime: PropTypes.instanceOf(Moment),
  headCount: PropTypes.number,
  mealType: PropTypes.string,
  serviceType: PropTypes.string,
  setUpStyle: PropTypes.string,
  orderType: PropTypes.string,
  primaryChef: PropTypes.object,
  conceptName: PropTypes.string,
  chefs: PropTypes.arrayOf(PropTypes.object),
  customOrderItems: PropTypes.arrayOf(PropTypes.object),
  chefAmount: PropTypes.number,
  discount: PropTypes.object,
  discounts: PropTypes.arrayOf(PropTypes.object),
  hasNetPaymentSettings: PropTypes.bool,
  isDeliveryFeeOverride: PropTypes.bool,
  isEvent: PropTypes.bool,
  isPaid: PropTypes.bool,
  isTaxExempt: PropTypes.bool,
  isVCX: PropTypes.bool,
  needsCleanup: PropTypes.bool,
  needsStaffing: PropTypes.bool,
  order: PropTypes.object,
  orderableType: PropTypes.string,
  orderMenuItems: PropTypes.arrayOf(PropTypes.object),
  orderNumber: PropTypes.string,
  orderServiceCost: PropTypes.object,
  orderTaxExemptCause: PropTypes.string,
  purchaseOrderNumber: PropTypes.string,
  setUpInstructions: PropTypes.string,
  deliveryFeePercent: PropTypes.number,
  deliveryFeeLimit: PropTypes.number,
  defaultTipPercent: PropTypes.number,
  deliveryFee: PropTypes.number,
  cleanupFee: PropTypes.number,
  staffingFee: PropTypes.number,
  status: PropTypes.string,
  numberOfStaff: PropTypes.number,
  staffingHours: PropTypes.number,
  serviceFee: PropTypes.number,
  orderServiceItems: PropTypes.arrayOf(PropTypes.object),
  orderVirtualItems: PropTypes.arrayOf(PropTypes.object),
  orderVirtualKits: PropTypes.arrayOf(PropTypes.object),
  orderSnackPacks: PropTypes.arrayOf(PropTypes.object),
  serviceNotes: PropTypes.string,
  predictedServiceCosts: PropTypes.number,
  paidAmount: PropTypes.number,
  subtotal: PropTypes.number,
  tax: PropTypes.number,
  taxRates: PropTypes.arrayOf(PropTypes.number),
  deliveryTaxRates: PropTypes.arrayOf(PropTypes.number),
  referredBy: PropTypes.object,
  referralPartners: PropTypes.array,
  servicesTaxRates: PropTypes.arrayOf(PropTypes.number),
  alcoholTaxRates: PropTypes.arrayOf(PropTypes.number),
  supplyBreakdown: PropTypes.object,
  tip: PropTypes.number,
  tipLocked: PropTypes.bool,
  total: PropTypes.number,

  hubspotProposal: PropTypes.object,
  hubspotProposals: PropTypes.arrayOf(PropTypes.object),

  selectedConceptId: PropTypes.string,
  selectedConcept: PropTypes.object,

  newCateringContact: PropTypes.object,
  newContact: PropTypes.object,
  newDropoffAddress: PropTypes.object,
  newBillingAddress: PropTypes.object,
  newInvoiceContact: PropTypes.object,
  newPaymentMethod: PropTypes.object,
  newReceiptContact: PropTypes.object,

  originalMenuItemIds: PropTypes.arrayOf(PropTypes.string),
  originalServiceItemIds: PropTypes.arrayOf(PropTypes.string),
  originalVirtualItemIds: PropTypes.arrayOf(PropTypes.string),
  originalVirtualKitIds: PropTypes.arrayOf(PropTypes.string),
  originalSnackPackIds: PropTypes.arrayOf(PropTypes.string),

  adjustedChefPayouts: PropTypes.object,

  numberOfEntrees: PropTypes.number,
  recommendedPortion: PropTypes.number,
})
