import { connect } from 'react-redux'

import { CafeMaster } from '@components/cafes'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'
import { config } from 'hungry-core2'

const mapStateToProps = (state) => {
  const { loadingIndicator, user, router } = state
  const { lastLoggedInHq } = user
  const port = router.location.port === 3000 ? 3001 : 3000
  const uri =
    config.cafe_coffee_url +
    (config.cafe_coffee_url === 'http://localhost' ? `:${port}` : '')

  return {
    isLoading: loadingIndicator.on,
    headquarter: lastLoggedInHq,
    uri,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService } = services
  const { AsyncLoadAccounts, LoadAccount, SaveCafeMenu, GetCafePDFMenu } =
    coordinators
  const { pResponseGeneric, pResponseAccount } = presenters.Api

  const loadAccounts = AsyncLoadAccounts({ RestService, pResponseGeneric })
  const loadAccount = LoadAccount({ RestService, pResponseAccount })
  const saveCafeMenu = SaveCafeMenu({
    UIService,
    RestService,
    HandleError,
  })

  const getCafePDFMenu = GetCafePDFMenu({ RestService, HandleError })

  return {
    loadAccount,
    loadAccounts,
    saveCafeMenu,
    getCafePDFMenu,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CafeMaster)
