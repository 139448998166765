import { SET_HEADQUARTERS, SET_USER_HEADQUARTERS } from '../actions/headquarter'

const headquarterInitialState = {
  headquarters: [],
  userHeadquarters: [],
}

export function headquarter(state = headquarterInitialState, action) {
  switch (action.type) {
    case SET_HEADQUARTERS: {
      return {
        headquarters: [...action.headquarters],
        userHeadquarters: [...state.userHeadquarters],
      }
    }
    case SET_USER_HEADQUARTERS: {
      return {
        headquarters: [...state.headquarters],
        userHeadquarters: [...action.headquarters],
      }
    }
    default:
      return state
  }
}
