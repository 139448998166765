import { connect } from 'react-redux'

import { EditVirtualKitModal } from '@components/chef/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { chef, editVirtualKit, editVirtualKitModal } = state
  const errors = state.errors.virtualKitModal || {}
  const { show } = editVirtualKitModal
  const { id } = chef

  return {
    chefId: id,
    virtualKit: editVirtualKit,
    show,
    errors,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, SettingsService, UIService } = services
  const {
    pResponseChef,
    pResponseVirtualItems, // TODO: add in loader for virtual items
    pResponseVirtualKits,
    pRequestUpdateVirtualKit,
    pRequestSettings,
    pResponseSettings,
  } = presenters.Api
  const {
    DelayedUpdateVirtualKit,
    DeleteVirtualKit,
    LoadChefVirtualItems,
    LoadSettings,
    RetryOnTimeout,
    SaveVirtualKit,
    SelectChef,
  } = coordinators
  const { close } = UIService.EditVirtualKit
  const clearErrors = UIService.Errors.clear
  const loadVirtualItems = LoadChefVirtualItems({
    ChefService,
    RestService,
    pResponseVirtualItems,
  })
  const delayedUpdateVirtualKit = DelayedUpdateVirtualKit({
    ChefService,
    UIService,
  })
  const deleteVirtualKit = DeleteVirtualKit({
    ChefService,
    RestService,
    UIService,
    pResponseChef,
    HandleError,
    SelectChef,
  })
  const saveVirtualKit = SaveVirtualKit({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateVirtualKit,
    pResponseVirtualKits,
    HandleError,
  })
  const loadSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('menuItems')

  return {
    close,
    clearErrors,
    saveVirtualKit,
    delayedUpdateVirtualKit,
    deleteVirtualKit,
    loadVirtualItems,
    loadSettings,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditVirtualKitModal)
