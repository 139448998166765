export const pSearchSubscribers = (members, search) => {
  search = search.replace(/ /g, '')
  const pattern = new RegExp(search, 'i')

  return members.filter((m) => {
    if (pattern.test(`${m.firstName}${m.lastName}`.replace(/ /g, ''))) {
      return true
    }
    if (pattern.test(m.email)) {
      return true
    }
    if (pattern.test(m.phone)) {
      return true
    }
    if (pattern.test(m.referrer)) {
      return true
    }

    return false
  })
}
