import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/common/modal/Modal'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import LinkText from '@components/common/form/LinkText'
import Button from '@components/common/form/Button'
import Input from '@components/common/form/Input'
import { colors } from '../../../../constants'
import {
  Checkbox,
  RadioButton,
  CurrencyInput,
  TextArea,
} from '@components/common/form'
import { Label } from '@res/styledComponents/index'
import { AuthorizedInteractable } from '@containers/common/auth'

const virtualKitInitialState = {
  price: 0.0,
  marketPrice: 0.0,
  virtualItems: [],
  search: '',
  chefVirtualItems: [],

  allDefaultPackagings: [],
}

class EditVirtualKitModal extends Component {
  state = { ...virtualKitInitialState }

  componentWillMount() {
    const { virtualKit, clearErrors } = this.props
    const packagingId = virtualKit.packaging && virtualKit.packaging.id
    this.setState({ ...virtualKit, packagingId })
    clearErrors()
    ;(async () => {
      const { allDefaultPackagings } = await this.props.loadSettings()

      this.setState({ allDefaultPackagings })
    })()
  }

  componentWillReceiveProps(nextProps) {
    const { virtualKit } = nextProps
    if (this.props.virtualKit !== nextProps.virtualKit) {
      const newState = { ...virtualKit }
      // this.setState( newState, () => this.loadFilteredVirtualItems( '' ))
      this.setState(newState)
    }
  }

  delayedUpdate = () => {
    this.props.delayedUpdateVirtualKit(this.state)
  }

  // loadFilteredVirtualItems = async search => {
  //   const { chefId } = this.state
  //   const { loadChefVirtualItems } = this.props
  //
  //   const filteredVirtualItems = loadChefVirtualItems({ chefId, search })
  //   this.setState({ chefVirtualItems: filteredVirtualItems })
  // }

  nextPosition = (conceptsMenuItems) => {
    let maxIndex = 0
    conceptsMenuItems.forEach((i) => {
      if (i.position > maxIndex) {
        maxIndex = i.position
      }
    })

    return maxIndex + 1
  }

  /* Events */

  onChange = (key) => (e) => {
    const { value } = e.target
    this.setState({ [key]: value }, this.delayedUpdate)
  }

  onChangeVirtualKit = (key, value) => {
    this.setState({ [key]: value }, () =>
      this.props.delayedUpdateVirtualKit(this.state),
    )
  }

  onChangeRadioButton = (e) => {
    const { value: key, name } = e.target
    this.setState({ [name]: key }, this.delayedUpdate)
  }

  onChangeCheckbox = (e) => {
    const { checked, value: key } = e.target
    this.setState({ [key]: checked }, this.delayedUpdate)
  }

  onDelete = () => {
    const { close, deleteVirtualKit } = this.props
    const { id, chefId, name } = this.state
    deleteVirtualKit({ id, chefId, name })
    close()
  }

  // onAddVirtualItem = index => {
  //   const { delayedUpdateVirtualKit } = this.props
  //   const { virtualItems } = this.state
  //   virtualItems.push({
  //     index,
  //     position: this.nextPosition( virtualItems ),
  //   })
  //   delayedUpdateVirtualKit( this.state )
  // }
  //
  // onVirtualItemChange = virtualItem => {
  //   const { virtualItems } = this.state
  //   if ( !virtualItem.id ) {
  //     virtualItem.destroy = true
  //     //TODO fix this destroy method
  //   }
  //   virtualItems[virtualItem.index] = virtualItem
  //   this.onChangeVirtualKit( 'virtualItems', [ ...virtualItems ])
  // }

  onHide = () => {
    this.props.close()
  }

  onSave = () => {
    const { chefId } = this.props
    this.props.saveVirtualKit({ ...this.state, chefId })
  }

  render() {
    const { errors, show } = this.props
    const {
      allDefaultPackagings,
      cost,
      containsAlcohol,
      description,
      id,
      isEnabled,
      isApproved,
      name,
      price,
      packagingId,
      // virtualItems,
      // chefVirtualItems,
    } = this.state
    const title = id ? 'Edit Virtual Kit' : 'New Virtual Kit'
    // const hasEmptyVirtualItem = virtualItems.some( i => i.id === undefined ) || false

    return (
      <Modal
        show={show}
        title={title}
        hideModal={this.onHide}
        color="#001940"
        width="800px"
      >
        <FlexContainer justifyContent="space-between" flexWrap="wrap">
          <Input
            width="31%"
            label="Virtual Kit Name"
            value={name}
            error={errors.name}
            onChange={this.onChange('name')}
          />
          <CurrencyInput
            width="31%"
            label="Vendor Price"
            value={cost}
            onChange={(value) => this.onChangeVirtualKit('cost', value)}
          />
          <CurrencyInput
            width="31%"
            label="Market Price"
            value={price}
            onChange={(value) => this.onChangeVirtualKit('price', value)}
          />
          <TextArea
            width="31%"
            height="100px"
            label="Description"
            value={description}
            onInput={this.onChange('description')}
          />
          <FlexContainer width="31%" flexDirection="column">
            <Label>Status</Label>
            <YSpacing height="5px" />
            <Checkbox
              label="Live"
              value="isEnabled"
              marginBottom="5px"
              checked={isEnabled}
              onChange={this.onChangeCheckbox}
            />
            <Checkbox
              label="Approved"
              marginBottom="5px"
              value="isApproved"
              checked={isApproved}
              onChange={this.onChangeCheckbox}
            />
            <Checkbox
              label="Kit Contains Alcohol"
              value="containsAlcohol"
              checked={containsAlcohol}
              onChange={this.onChangeCheckbox}
            />
          </FlexContainer>
          <FlexContainer width="31%" />
        </FlexContainer>
        <Label>Packaging</Label>
        <YSpacing height="5px" />
        <RadioButton
          name="packagingId"
          value={null}
          marginBottom="5px"
          label={'No Packaging'}
          key={'noPackaging'}
          checked={!packagingId}
          onChange={() => this.setState({ packagingId: null })}
        />
        {allDefaultPackagings.map((p) => {
          const { name, id } = p

          return (
            <RadioButton
              name="packagingId"
              marginBottom="5px"
              value={id}
              label={name}
              key={id}
              checked={id === packagingId}
              onChange={this.onChangeRadioButton}
            />
          )
        })}
        <FlexContainer alignItems="center" justifyContent="flex-end">
          <LinkText
            color={colors.gray300}
            onClick={this.onHide}
            label="Cancel"
          />
          {id && (
            <AuthorizedInteractable
              roles={['master admin', 'sales lead', 'chef lead']}
            >
              <LinkText
                color={colors.violet}
                onClick={this.onDelete}
                label="Delete"
              />
            </AuthorizedInteractable>
          )}
          <XSpacing width="20px" />
          <AuthorizedInteractable
            roles={['master admin', 'sales lead', 'chef lead']}
          >
            <Button onClick={this.onSave} label="Save" />
          </AuthorizedInteractable>
        </FlexContainer>
      </Modal>
    )
  }
}

EditVirtualKitModal.propTypes = {
  errors: PropTypes.object,
  virtualKit: PropTypes.object,
  chefId: PropTypes.string,
  show: PropTypes.bool,

  //TODO Add async load
  clearErrors: PropTypes.func,
  close: PropTypes.func,
  delayedUpdateVirtualKit: PropTypes.func,
  deleteVirtualKit: PropTypes.func,
  displayAlert: PropTypes.func,
  loadChefVirtualItems: PropTypes.func,
  loadSettings: PropTypes.func,
  saveVirtualKit: PropTypes.func,
}

export default EditVirtualKitModal
