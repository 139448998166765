import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FlexContainer from '@components/common/FlexContainer'
import YSpacing from '@components/common/YSpacing'
import TextArea from '@components/common/form/TextArea'
import { Label } from '@res/styledComponents/index'
import { FormContainer } from '@res/styledComponents/index'
import { LinkText, RadioButton, Input, Button } from '@components/common/form'

class AddressInput extends Component {
  state = {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    allowTolls: false,
    buildingInstructions: '',

    // unmutated
    id: undefined,
  }

  componentDidMount() {
    this.setState({ ...this.props.address })
  }

  componentWillReceiveProps(nextProps) {
    const { address } = nextProps

    if (address !== this.props.address) {
      this.setState({ ...address })
    }
  }

  onChangeAllowTolls = (allowTolls) => () => {
    const { onChange } = this.props
    this.setState({ allowTolls }, () => {
      const address = this.props.pBuildAddress(this.state)
      onChange && onChange(address)
    })
  }

  onInput = (field) => (e) => {
    const { onChange } = this.props
    const { value } = e.target
    this.setState({ [field]: value }, () => {
      const address = this.props.pBuildAddress(this.state)
      onChange && onChange(address)
    })
  }

  onInputZip = (e) => {
    const { onChange } = this.props
    let zip = e.target.value
    zip = zip.replace(/[^0-9]/g, '').slice(0, 5)
    this.setState({ zip }, () => {
      const address = this.props.pBuildAddress(this.state)
      onChange && onChange(address)
    })
  }

  onCancel = () => {
    this.props.onCancel()
  }

  render() {
    const {
      errors,
      showAllowTolls,
      showBuildingInstructions,
      hasBackground,
      onCancel,
      onSave,
    } = this.props
    const {
      id,
      line1,
      line2,
      city,
      state,
      zip,
      allowTolls,
      buildingInstructions,
    } = this.state

    return (
      <FormContainer hasBackground={hasBackground}>
        <FlexContainer flexDirection="column" width="100%">
          <Input
            label="Address Line 1"
            value={line1}
            error={errors.line1}
            onChange={this.onInput('line1')}
          />
          <Input
            label="Address Line 2"
            value={line2}
            error={errors.line2}
            onChange={this.onInput('line2')}
          />
          <Input
            label="City"
            value={city}
            error={errors.city}
            onChange={this.onInput('city')}
          />
          <Input
            label="State"
            value={state}
            error={errors.state}
            onChange={this.onInput('state')}
          />
          <Input
            label="Zip code"
            value={zip}
            error={errors.zip}
            onChange={this.onInputZip}
          />
          {showAllowTolls && (
            <FlexContainer
              width="100%"
              justifyContent="space-between"
              flexDirection="column"
            >
              <Label> Allow Tolls </Label>
              <YSpacing height="5px" />
              <RadioButton
                name="allowTolls"
                value={false}
                label={'No'}
                checked={!allowTolls}
                onChange={this.onChangeAllowTolls(false)}
              />
              <YSpacing height="5px" />
              <RadioButton
                name="allowTolls"
                value={true}
                label={'Yes'}
                checked={allowTolls}
                onChange={this.onChangeAllowTolls(true)}
              />
            </FlexContainer>
          )}
          {showAllowTolls && <YSpacing height="20px" />}
          {showBuildingInstructions && (
            <TextArea
              label="Building/Delivery Instructions"
              value={buildingInstructions}
              onChange={this.onInput('buildingInstructions')}
            />
          )}
        </FlexContainer>
        {!id && onCancel && (
          <LinkText
            label="Cancel New"
            fontSize="13px"
            onClick={() => this.onCancel()}
          />
        )}
        {onSave && <Button label="Save" onClick={() => onSave(this.state)} />}
      </FormContainer>
    )
  }
}

AddressInput.propTypes = {
  hasBackground: PropTypes.bool,
  address: PropTypes.object,
  errors: PropTypes.object,
  showAllowTolls: PropTypes.bool,
  showBuildingInstructions: PropTypes.bool,

  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  pBuildAddress: PropTypes.func,
}

AddressInput.defaultProps = {
  errors: {},
  showBuildingInstructions: true,
}

export default AddressInput
