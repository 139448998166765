import { camelCaseify, formAdd } from '~/utils'
import Moment from 'moment-timezone'

export const pRequestInventoryTransactions = (orderSupplyId, date) => {
  const req = { order_supply_id: orderSupplyId }

  // convert date from MM/DD/YYYY to DD/MM/YYYY format
  const dateFields = date.match(/(\d{2})\/(\d{2})\/(\d{4})/)
  req.date = dateFields[2] + '/' + dateFields[1] + '/' + dateFields[3]

  return req
}

export const pRequestUpdateInventory = (data) => {
  const req = {}

  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'unitCost', 'unit_cost')
  if (data.tracked) {
    formAdd(data, req, 'count', 'count')
    formAdd(data, req, 'alertThreshold', 'alert_threshold')
    formAdd(data, req, 'alertEnabled', 'alert_enabled')
  }

  return { inventory: req }
}

export const pResponseInventories = (json) => {
  const inventories = camelCaseify(json)
  const dates = []
  const days = (inventories[0] && inventories[0].daysTotalled) || 7
  for (let i = 0; i <= days; i++) {
    dates.push(Moment().add(i, 'days').format('MM/DD/YYYY'))
  }
  inventories.forEach((inventory) => {
    inventory.totalValue = inventory.unitCost * inventory.count
    const last = inventory.lastRevision
    inventory.lastCount = last && last.count
    inventory.lastTotalValue = last && last.unitCost * last.count
  })
  inventories
    .filter((inventory) => inventory.tracked)
    .forEach((inventory) => {
      let runningCount = inventory.count
      inventory.dailyCounts = dates.map((date) => {
        runningCount += inventory.dailyTotals[date] || 0.0

        return runningCount
      })
    })

  return { records: inventories, dates }
}

export const pRequestUpdatePackagingAddOn = ({
  packagingAddOn,
  packageMapping,
}) => {
  const req = {}

  if (packagingAddOn) {
    formAdd(packagingAddOn, req, 'id', 'id')
    formAdd(packagingAddOn, req, 'name', 'name')
    formAdd(packagingAddOn, req, 'dftRecoupPct', 'dft_recoup_pct')
    formAdd(
      packagingAddOn,
      req,
      'dftHungryVipRecoupPct',
      'dft_hungry_vip_recoup_pct',
    )
    formAdd(packagingAddOn, req, 'defaultUnitCost', 'default_unit_cost')
    formAdd(packagingAddOn, req, 'active', 'active')
    formAdd(packagingAddOn, req, 'trackInventory', 'track_inventory')
  } else if (packageMapping) {
    formAdd(packageMapping, req, 'orderSuppliableId', 'id')
    req.packaging_add_on_packagings_attributes = [
      {
        id: packageMapping.id,
        suppliable_for_id: packageMapping.suppliableForId,
        supply_usage_rate: packageMapping.countPerPkg,
        _destroy: packageMapping._destroy,
      },
    ]
  }

  return { packaging_add_on: req }
}

export const pRequestUpdatePackaging = (data) => {
  const req = {}

  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'name', 'name')
  formAdd(data, req, 'active', 'active')
  formAdd(data, req, 'trackInventory', 'track_inventory')
  formAdd(data, req, 'interchangeableType', 'interchangeable_type')
  formAdd(
    data,
    req,
    'defaultServingsPerPackage',
    'default_servings_per_package',
  )
  formAdd(data, req, 'dftRecoupPct', 'dft_recoup_pct')
  formAdd(data, req, 'dftHungryVipRecoupPct', 'dft_hungry_vip_recoup_pct')
  formAdd(data, req, 'defaultUnitCost', 'default_unit_cost')

  return { packaging: req }
}

export const pRequestUpdateServingUtensil = (data) => {
  const req = {}

  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'name', 'name')
  formAdd(data, req, 'active', 'active')
  formAdd(data, req, 'trackInventory', 'track_inventory')
  formAdd(data, req, 'dftSupplyUsageRate', 'dft_supply_usage_rate')
  formAdd(data, req, 'dftRecoupPct', 'dft_recoup_pct')
  formAdd(data, req, 'dftHungryVipRecoupPct', 'dft_hungry_vip_recoup_pct')
  formAdd(data, req, 'defaultUnitCost', 'default_unit_cost')

  return { serving_utensil: req }
}

export const pRequestUpdateCateringSupply = ({
  cateringSupply,
  orderMapping,
}) => {
  const req = {}

  if (cateringSupply) {
    formAdd(cateringSupply, req, 'id', 'id')
    formAdd(cateringSupply, req, 'name', 'name')
    formAdd(cateringSupply, req, 'defaultUnitCost', 'default_unit_cost')
    formAdd(cateringSupply, req, 'dftRecoupPct', 'dft_recoup_pct')
    formAdd(
      cateringSupply,
      req,
      'dftHungryVipRecoupPct',
      'dft_hungry_vip_recoup_pct',
    )
    formAdd(cateringSupply, req, 'dftSupplyUsageRate', 'dft_supply_usage_rate')
    formAdd(cateringSupply, req, 'active', 'active')
    formAdd(cateringSupply, req, 'trackInventory', 'track_inventory')
  } else if (orderMapping) {
    formAdd(orderMapping, req, 'orderSuppliableId', 'id')
    req.order_mappings_attributes = [
      {
        id: orderMapping.id,
        supply_usage_rate: orderMapping.supplyUsageRate,
        is_event: orderMapping.isEvent,
        service_type: orderMapping.serviceType,
        order_type: orderMapping.orderType,
        set_up_style: orderMapping.setUpStyle,
        _destroy: orderMapping._destroy,
      },
    ]
  }

  return { catering_supply: req }
}

export const pRequestUpdateDiningSupply = ({ diningSupply, orderMapping }) => {
  const req = {}

  if (diningSupply) {
    formAdd(diningSupply, req, 'id', 'id')
    formAdd(diningSupply, req, 'name', 'name')
    formAdd(diningSupply, req, 'defaultUnitCost', 'default_unit_cost')
    formAdd(diningSupply, req, 'dftRecoupPct', 'dft_recoup_pct')
    formAdd(
      diningSupply,
      req,
      'dftHungryVipRecoupPct',
      'dft_hungry_vip_recoup_pct',
    )
    formAdd(diningSupply, req, 'dftSupplyUsageRate', 'dft_supply_usage_rate')
    formAdd(diningSupply, req, 'active', 'active')
    formAdd(diningSupply, req, 'trackInventory', 'track_inventory')
  } else if (orderMapping) {
    formAdd(orderMapping, req, 'orderSuppliableId', 'id')
    req.order_mappings_attributes = [
      {
        id: orderMapping.id,
        supply_usage_rate: orderMapping.supplyUsageRate,
        is_event: orderMapping.isEvent,
        service_type: orderMapping.serviceType,
        order_type: orderMapping.orderType,
        set_up_style: orderMapping.setUpStyle,
        _destroy: orderMapping._destroy,
      },
    ]
  }

  return { dining_supply: req }
}

export const pRequestOrderSupplyBreakdown = (orderable) => {
  const req = {}

  if (orderable.type) {
    req.id = orderable.id
    req.type = orderable.type

    return req
  } else {
    formAdd(orderable, req, 'orderNumber', 'order_number')
    formAdd(orderable, req, 'headquarterId', 'headquarter_id')
    formAdd(orderable, req, 'headCount', 'head_count')
    formAdd(orderable, req, 'isEvent', 'is_event')
    formAdd(orderable, req, 'serviceType', 'service_type')
    formAdd(orderable, req, 'orderType', 'order_type')
    formAdd(orderable, req, 'setUpStyle', 'set_up_style')

    const orderItems = {}
    orderable.chefs.forEach((chef) => {
      let itemIdx = 0
      chef.orderMenuItems.forEach((item) => {
        const index = item.id || ++itemIdx
        orderItems[index] = {
          id: index,
          chef_item_id: item.menuItemId,
          quantity: item.quantity,
        }
        item.childItems.forEach((cItem) => {
          const cIdx = cItem.id || ++itemIdx
          orderItems[cIdx] = {
            id: cIdx,
            chef_item_id: cItem.menuItemId,
            quantity: cItem.quantity,
          }
        })
      })
      chef.orderServiceItems.forEach((item) => {
        const index = item.id || ++itemIdx
        orderItems[index] = {
          id: index,
          chef_item_id: item.serviceItemId,
          quantity: item.quantity,
        }
      })
    })
    req.order_items = orderItems

    return { orderable_hash: req }
  }
}
