import { camelCaseify } from '~/utils'

export const pResponsePopUpServiceItem = (json) => {
  const item = camelCaseify(json)

  return {
    id: item.id,
    name: item.name,
    description: item.description,
    addOn: false,
    quantity: 0,
    price: parseFloat(item.price),
    chefPrice: parseFloat(item.cost),
  }
}

export const pResponsePopUpServiceItems = (json) => {
  return json.map((data) => pResponsePopUpServiceItem(data))
}

export const pResponsePopUpAddOnMenuItem = (json) => {
  const item = camelCaseify(json)

  const getMenuType = (it) => {
    let menuType
    switch (it.mealType) {
      case 'Main':
        menuType = 'entrees'
        break
      case 'Side':
        menuType = 'sides'
        break
      case 'Drink':
        menuType = 'drinks'
        break
      case 'Dessert':
        menuType = 'desserts'
        break
      default:
    }

    return menuType
  }

  const toMenuItem = (it) => {
    return {
      id: it.id,
      name: it.name,
      barcode: it.barcode,
      description: it.description,
      supplierType: 'Add On',
      price: parseFloat(it.price),
      quantity: 0,
      chefPrice: 0.0,
      dietaryPrefs: it.tagsDietaryPreferenceList.split(',').filter((t) => t),
      ingredients: it.tagsIngredientList.split(',').filter((t) => t),
      image: it.images && it.images.length > 0 ? it.images[0].thumborUrl : '',
      menuType: getMenuType(it),
    }
  }

  return {
    ...toMenuItem(item),
    childItems: (item.childItems || []).map((ch) => toMenuItem(ch)),
  }
}

export const pResponsePopUpOnSiteMenuItem = (json) => {
  const item = camelCaseify(json)

  const getMenuType = (it) => {
    let menuType
    switch (it.mealType) {
      case 'Main':
        menuType = 'entrees'
        break
      case 'Side':
        menuType = 'sides'
        break
      case 'Drink':
        menuType = 'drinks'
        break
      case 'Dessert':
        menuType = 'desserts'
        break
      default:
    }

    return menuType
  }

  const toMenuItem = (it) => {
    return {
      id: it.id,
      name: it.name,
      barcode: it.barcode,
      description: it.description,
      supplierType: 'On Site',
      price: parseFloat(it.price),
      quantity: 0,
      chefPrice: 0.0,
      dietaryPrefs: it.tagsDietaryPreferenceList.split(',').filter((t) => t),
      ingredients: it.tagsIngredientList.split(',').filter((t) => t),
      image: it.images && it.images.length > 0 ? it.images[0].thumborUrl : '',
      menuType: getMenuType(it),
    }
  }

  return {
    ...toMenuItem(item),
    childItems: (item.childItems || []).map((ch) => toMenuItem(ch)),
  }
}

export const pResponsePopUpOnSiteMenuItems = (json) => {
  return json
    .map((data) => pResponsePopUpOnSiteMenuItem(data))
    .filter((it) => it.menuType)
}

export const pResponsePopUpAddOnMenuItems = (json) => {
  return json
    .map((data) => pResponsePopUpAddOnMenuItem(data))
    .filter((it) => it.menuType)
}

export const pResponsePopUpAccount = (json) => {
  const account = camelCaseify(json)

  return {
    id: account.id,
    name: account.name,
    pin: account.pin,
    logoUrl: account.logoUrl,
    locations: account.addresses.map((addr) => ({
      id: addr.id,
      name: addr.fullAddress,
      line1: addr.line1,
      line2: addr.line2,
      city: addr.city,
      state: addr.state,
      zip: addr.zip,
      tolls: addr.allowTolls,
      buildingInstructions: addr.buildingInstructions,
    })),
    contacts: account.contacts.map((contact) => ({
      id: contact.id,
      name: contact.name,
      email: contact.email,
      phone: contact.phoneNumber,
    })),
  }
}

export const pResponsePopUpAccounts = (json) => {
  return json.map((acct) => pResponsePopUpAccount(acct))
}

export const pResponsePopUpOrder = (order) => {
  return {
    id: order.id,
    orderNumber: order.number,
    date: order.date,
    chef: {
      id: order.menu.chef.id,
      name: order.menu.chef.name,
      email: order.menu.chef.email,
      phone: order.menu.chef.phone,
    },
    isAccepted: order.isAccepted,
    declineReason: order.declineReason,
    cnStatus: order.cnStatus,
    mealType: 'Pop-Up',
  }
}
