import React, { Component } from 'react'
import PropTypes from 'prop-types'

import OrderVirtualItem from './OrderVirtualItem'

class VirtualItemsSection extends Component {
  render() {
    const {
      onEditOrderVirtualItem,
      onEditCustomOrderVirtualItem,
      onRemoveOrderVirtualItem,
      onRemoveCustomOrderVirtualItem,
      onReorderCustomVirtualItem,
      onReorderVirtualItem,
    } = this.props
    const orderVirtualItems = this.props.orderVirtualItems || []
    const customOrderVirtualItems = this.props.customOrderVirtualItems || []

    if (orderVirtualItems.length + customOrderVirtualItems.length === 0) {
      return null
    }

    return (
      <div className="mt-5">
        <table className="w-100 border-separate order-table">
          <tr>
            <td />
            <td className="w-55 bold uppercase"> Virtual Item Name </td>
            <td className="bold uppercase text-right"> QTY </td>
            <td className="bold uppercase text-right"> Vendor Price </td>
            <td className="bold uppercase text-right"> Client Price </td>
            <td className="bold uppercase text-right"> Total </td>
          </tr>
          <tr className="">
            <td className="flex w-2 pr-3">
              <div className="flex flex-col mr-5 justify-center" />
            </td>
            <td
              className="text-left red-text align-middle"
              style={{ fontSize: '0.8em' }}
            >
              (These items are not taxed!)
            </td>
          </tr>
          {orderVirtualItems.map((i, index) => (
            <OrderVirtualItem
              key={i.displayOrder}
              virtualItem={i}
              onEdit={onEditOrderVirtualItem}
              onRemove={onRemoveOrderVirtualItem}
              onReorder={onReorderVirtualItem}
              isCustom={false}
              isFirst={index == 0}
              isLast={index == orderVirtualItems.length - 1}
            />
          ))}
          {customOrderVirtualItems.map((i, index) => (
            <OrderVirtualItem
              key={i.displayOrder}
              virtualItem={i}
              onEdit={onEditCustomOrderVirtualItem}
              onRemove={onRemoveCustomOrderVirtualItem}
              onReorder={onReorderCustomVirtualItem}
              isCustom={true}
              isFirst={index == 0}
              isLast={index == customOrderVirtualItems.length - 1}
            />
          ))}
        </table>
      </div>
    )
  }
}

VirtualItemsSection.propTypes = {
  orderVirtualItems: PropTypes.arrayOf(PropTypes.object),
  customOrderVirtualItems: PropTypes.arrayOf(PropTypes.object),

  onEditOrderVirtualItem: PropTypes.func,
  onEditCustomOrderVirtualItem: PropTypes.func,
  onRemoveOrderVirtualItem: PropTypes.func,
  onRemoveCustomOrderVirtualItem: PropTypes.func,
  onReorderCustomVirtualItem: PropTypes.func,
  onReorderVirtualItem: PropTypes.func,
}

export default VirtualItemsSection
