export const CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL'
export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL'

export const showConfirmationModal = ({ title, text }) => {
  title = title || 'Are You Sure?'
  text = text || ''

  return { type: SHOW_CONFIRMATION_MODAL, title, text }
}

export const closeConfirmationModal = () => ({ type: CLOSE_CONFIRMATION_MODAL })
