import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

const TableHeaders = ['Game Day', 'Order Cutoff']

function MLBCalendarView(props) {
  const tableHeaders = [...TableHeaders]
  const { calendar, locale } = props.mlbPartner || {}

  return (
    <div className="mlb-panel">
      <table className="w-full border partners-table">
        <tbody>
          <tr className="bg-gray-200">
            {tableHeaders.map((h, i) => (
              <th key={i} className="py-1 bold uppercase">
                {h}
              </th>
            ))}
          </tr>
          {calendar.map((c, i) => (
            <tr key={i}>
              <td>
                <div>
                  {Moment(c.date).tz(locale).format('MM-DD-YYYY h:mma z')}
                </div>
              </td>
              <td>
                <div>
                  {Moment(c.cutoff).tz(locale).format('MM-DD-YYYY h:mma z')}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

MLBCalendarView.propTypes = {
  mlbPartner: PropTypes.object,
}

export default MLBCalendarView
