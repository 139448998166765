import { camelCaseify, snakeCaseify } from '~/utils'

export const pRequestChefContacts = (chefId) => {
  return {
    chef_id: chefId,
    results_per_page: 500,
  }
}

export const pResponseChefContacts = (json) => {
  return camelCaseify(json)
}

export const pRequestUpdateChefContact = (data) => {
  const contact = {
    ...data,
    activeHqs: Object.keys(data.selectedHqs).join(','),
  }
  delete contact.selectedHqs

  return { contact: snakeCaseify(contact) }
}
