import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import { FormInputContainer } from '@res/styledComponents/index'
import { colors } from '../../../../constants'

class DateTextInput extends Component {
  state = {
    text: undefined,
    updated: false,
  }

  callAfterTimeout = (func, delay = 3000) => {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(func, delay)
  }

  onChange = (e) => {
    const text = e.target.value
    if (text == '' && this.props.onEmpty) {
      this.setState({ text, updated: true })
      this.props.onEmpty()

      return
    }
    this.setState({ text, updated: false })
    this.callAfterTimeout(this.onChangeValid)
  }

  onChangeValid = () => {
    const { dateFormat, timeZone, onChange } = this.props
    const { text } = this.state
    const mDate = Moment.tz(text, dateFormat, timeZone)
    if (mDate.isValid()) {
      onChange(mDate.format())
      this.setState({ text: undefined, updated: true })
    }
  }

  onBlur = () => {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.onChangeValid()
  }

  render() {
    const {
      className,
      date,
      dateFormat,
      timeZone,
      placeholder,
      label,
      marginBottom,
      width,
      testId,
    } = this.props
    const { text, updated } = this.state

    let value = ''
    if (text) {
      value = text
    } else if (date) {
      const mDate = Moment.tz(date, timeZone)
      value = mDate.format(dateFormat)
    }

    return (
      <FormInputContainer width={width} marginBottom={marginBottom}>
        <label>
          {label}
          {updated && <span className={colors.violet}> Updated </span>}
        </label>
        <input
          id={testId}
          type="text"
          className={className}
          value={value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          placeholder={placeholder}
        />
      </FormInputContainer>
    )
  }
}

DateTextInput.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  marginBottom: PropTypes.string,
  timeZone: PropTypes.string,
  date: PropTypes.string,
  dateFormat: PropTypes.string,
  width: PropTypes.string,

  onChange: PropTypes.func,
  onEmpty: PropTypes.func,
}

DateTextInput.defaultProps = {
  disabled: false,
  dateFormat: 'MM/DD/YYYY',
}

export default DateTextInput
