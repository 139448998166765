import { connect } from 'react-redux'

import { PaymentSection } from '@components/order/edit/sections'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapDispatchToProps = () => {
  const { BraintreeService, RestService, UIService } = services
  const { InitBraintree, UpdateNonce } = coordinators
  const { pBuildPaymentMethod, pFormatCreditCard, pFormatMMYY } = presenters
  const { pRequestBraintreeNonce } = presenters.Api

  const initBraintree = InitBraintree({ BraintreeService, RestService })
  const updateNonce = UpdateNonce({
    BraintreeService,
    UIService,
    pRequestBraintreeNonce,
    HandleError,
  })

  return {
    initBraintree,
    pBuildPaymentMethod,
    pFormatCreditCard,
    pFormatMMYY,
    updateNonce,
  }
}

export default connect(undefined, mapDispatchToProps)(PaymentSection)
