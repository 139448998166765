import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const SidebarMealTypeDropdown = (props) => {
  const { label, onChange } = props

  return (
    <Dropdown>
      <label>{label}</label>
      <select onChange={onChange}>
        <option> All </option>
        <option> Lunch </option>
        <option> Dinner </option>
      </select>
    </Dropdown>
  )
}

const Dropdown = styled.div`
  padding: 0 20px;
  margin-bottom: 10px;
  label {
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
  }
  select {
    width: 100%;
    box-sizing: border-box;
    padding: 6px 10px;
    background: rgba(255, 255, 255, 0.15);
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    font-size: 15px;
    ::placeholder {
      color: rgba(255, 255, 255, 0.6);
      font-family: 'regular';
    }
    option {
      color: gray;
    }
  }
`

SidebarMealTypeDropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
}

export default SidebarMealTypeDropdown
