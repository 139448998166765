export const ordersCSVColumns = [
  { key: 'number' },
  { key: 'name' },
  { key: 'address' },
  { key: 'kits' },
  { key: 'addons' },
]

export const workwaveCSVColumns = [
  { key: 'CONTACT_ID' },
  { key: 'ORDER_ID' },
  { key: 'ORDER_DELIVERY_INSTRUCTIONS' },
  { key: 'CLIENT_LAST_NAME' },
  { key: 'CLIENT_FIRST_NAME' },
  { key: 'CLIENT_ADDRESS_ONE' },
  { key: 'CLIENT_ADDRESS_TWO' },
  { key: 'CLIENT_DEVELOPMENT_NAME' },
  { key: 'CLIENT_BOROUGH' },
  { key: 'CLIENT_ZIP' },
  { key: 'CLIENT_PHONE_1' },
  { key: 'CLIENT_PHONE_2' },
  { key: 'CLIENT_PHONE_3' },
  { key: 'VERIFY_DOB' },
]
