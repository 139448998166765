import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import { AuthorizedInteractable } from '@containers/common/auth'
import { ButtonsBar } from '@res/styledComponents/index'
import { BiPencil } from 'react-icons/bi'
import { Recipe } from '@types'

interface Props {
  recipes: Recipe[]
  chefId: string
  editRecipe: (recipeId: string) => void
  loadRecipes: (chefId: string) => void
  newRecipe: () => void
}

const Recipes = (props: Props) => {
  const { recipes, chefId, editRecipe, loadRecipes, newRecipe } = props

  useEffect(() => {
    chefId && loadRecipes(chefId)
  }, [chefId])

  return (
    <div>
      <ButtonsBar>
        <AuthorizedInteractable
          roles={['master admin', 'sales lead', 'sales rep', 'chef lead']}
        >
          <button onClick={newRecipe}>
            <BiPencil />
            Add New Recipe
          </button>
        </AuthorizedInteractable>
      </ButtonsBar>
      <Panel>
        <Table>
          <thead>
            <tr>
              <th>Recipe Name</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {recipes &&
              recipes.map((recipe, i) => (
                <tr
                  key={i}
                  className="clickable"
                  onClick={() => editRecipe(recipe.id)}
                >
                  <td>{recipe.name}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Panel>
    </div>
  )
}

Recipes.propTypes = {
  chefId: PropTypes.string,
  recipes: PropTypes.arrayOf(PropTypes.object),
  editRecipe: PropTypes.func,
  loadRecipes: PropTypes.func,
  newRecipe: PropTypes.func,
}

export default Recipes
