import { camelCaseify, formAdd } from '~/utils'

export const pResponseVirtualKits = (json) => {
  const virtualKits = json.map((j) => {
    const res = camelCaseify(j)
    res.virtualKitId = res.id
    res.cost = parseFloat(res.cost)
    res.price = parseFloat(res.price)
    res.itemType = 'VirtualKit'

    return res
  })

  return virtualKits
}

export const pResponseVirtualKit = (json) => {
  const virtualKit = camelCaseify(json)

  return virtualKit
}

export const pRequestUpdateVirtualKit = (data) => {
  const req = {}

  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'chefId', 'chef_id')
  formAdd(data, req, 'name', 'name')
  formAdd(data, req, 'isEnabled', 'is_enabled')
  formAdd(data, req, 'isApproved', 'is_approved')
  formAdd(data, req, 'description', 'description')
  formAdd(data, req, 'price', 'price')
  formAdd(data, req, 'marketPrice', 'cost')
  formAdd(data, req, 'containsAlcohol', 'contains_alcohol')

  if (data.packagingId) {
    req.virtual_kit_packaging_attributes = {
      order_suppliable_type: 'Packaging',
    }
    formAdd(
      data,
      req.virtual_kit_packaging_attributes,
      'packagingId',
      'order_suppliable_id',
    )
  } else {
    req.packaging = null
  }

  return { virtual_kit: req }
}

export const pDefaultVirtualKitFields = () => {
  return {
    name: '',
    description: '',
    marketPrice: 0.0,
    price: 0.0,
    quantity: 0,
    containsAlcohol: false,
    virtualItems: [],
  }
}
