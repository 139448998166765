export const vPaymentMethod = {
  cardholderName: [{ type: 'present', message: 'cardholder name missing' }],
  expirationDate: [{ type: 'present', message: 'expiration date missing' }],
  last4: [{ type: 'present', message: 'card number missing', map: ['number'] }],
  nonce: [
    {
      type: 'present',
      message: 'invalid card',
      map: ['number', 'cvv', 'expirationYear'],
    },
  ],
}
