import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import { FlexContainer } from '@components/common'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  whiteSpace: 'nowrap',
  fontSize: '13px',

  // change background color if dragging
  background: isDragging && '#c1eff5',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = () => ({
  background: 'white',
  overflow: 'auto',
})

export class ColumnSorter extends Component {
  constructor(props) {
    super(props)
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  onDragEnd(result) {
    const { sortList, onUpdateList } = this.props
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const sorted = reorder(
      sortList,
      result.source.index,
      result.destination.index,
    )

    onUpdateList(sorted)
  }

  onRemoveMultiSelectOption = (list, index) => {
    list.splice(index, 1)
    this.props.onUpdateList([...list])
  }

  render() {
    const { sortList, onSort } = this.props

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <tr
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {sortList.length > 0 && (
                <th className="table-heading" style={{ height: 39 }}>
                  <input
                    type="checkbox"
                    checked={this.props.selectAll}
                    onClick={this.props.onSelectAll}
                  />
                </th>
              )}
              {sortList.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided, snapshot) => (
                    <th
                      className="table-heading"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <FlexContainer>
                        <p onClick={() => onSort(item)}>{item}</p>
                        <span
                          style={{ marginLeft: '10px', color: 'red' }}
                          onClick={() =>
                            this.onRemoveMultiSelectOption(sortList, index)
                          }
                        >
                          ✕
                        </span>
                      </FlexContainer>
                    </th>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </tr>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
}

ColumnSorter.propTypes = {
  sortList: PropTypes.array,
  selectAll: PropTypes.bool,
  onSelectAll: PropTypes.func,
  onSort: PropTypes.func,
  onUpdateList: PropTypes.func,
}

export default ColumnSorter
