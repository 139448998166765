import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

class TooltipModal extends Component {
  state = { value: '' }

  componentDidMount() {
    this.setState({ value: this.props.information })
  }

  render() {
    const { value } = this.state
    const {
      color,
      marginTop,
      spanMarginLeft,
      spanMarginRight,
      spanTop,
      unicode,
      width,
    } = this.props

    return (
      <Tooltip
        color={color}
        marginTop={marginTop}
        spanMarginLeft={spanMarginLeft}
        spanMarginRight={spanMarginRight}
        spanTop={spanTop}
      >
        <sup>{unicode}</sup>
        <span width={width}>{value}</span>
      </Tooltip>
    )
  }
}

const Tooltip = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => props.marginTop};
  sup {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 20px;
    cursor: pointer;
    color: ${(props) => props.color};
  }
  span {
    visibility: hidden;
    background-color: #fff;
    color: black;
    text-align: center;
    font-size: 12px;
    border-radius: 6px;
    padding: 5px;
    cursor: help;
    width: ${(props) => props.width};
    margin-left: ${(props) => props.spanMarginLeft};
    margin-right: ${(props) => props.spanMarginRight};
    min-width: 260px;
    border: 2px solid grey;
    line-height: normal;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: ${(props) => props.spanTop};
  }
  &:hover {
    span {
      visibility: visible;
    }
  }
`

TooltipModal.propTypes = {
  color: PropTypes.string,
  information: PropTypes.string,
  marginTop: PropTypes.string,
  spanMarginLeft: PropTypes.string,
  spanMarginRight: PropTypes.string,
  spanTop: PropTypes.string,
  width: PropTypes.string,
  unicode: PropTypes.string,
}

export default TooltipModal
