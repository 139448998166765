export const AsyncLoadAccountOrders =
  ({ RestService, pResponseOrders }) =>
  async ({ accountId, page }) => {
    const orders = await RestService.get('/api/admin/orders', {
      account_id: accountId,
      child_order_serializer: true,
      for_order_batch: true,
      status: 'not_canceled',
      page,
    })

    return pResponseOrders(orders)
  }
