export const LoadBatchOrders =
  ({
    BatchOrderService,
    RestService,
    UIService,
    pRequestBatchOrdersFilter,
    pResponseBatchOrders,
  }) =>
  async (filters) => {
    /* filters is object with shape { search, status }, both of which are optional */
    if (!filters) {
      BatchOrderService.clearBatchOrders()
      filters = UIService.BatchOrderPage.getLastLoadBatchOrdersParams()
    }
    filters = pRequestBatchOrdersFilter(filters)
    let batchOrders = await RestService.get('/api/admin/order_batches', {
      page: 1,
      results_per_page: 20,
      ...filters,
    })
    batchOrders = pResponseBatchOrders(batchOrders)
    BatchOrderService.appendBatchOrders(batchOrders)
  }

export const LoadQueryBatchOrder =
  ({ BatchOrderService, RestService, RouterService, pResponseBatchOrder }) =>
  async () => {
    const batchOrderId = RouterService.getQuery('batch_order_id')
    if (batchOrderId) {
      SelectBatchOrder({ BatchOrderService, RestService, pResponseBatchOrder })(
        batchOrderId,
      )
    }
  }

export const LoadOrder =
  ({ RouterService }) =>
  (orderId) => {
    RouterService.push(`/sales/${orderId}`)
  }

export const SelectBatchOrder =
  ({ BatchOrderService, RestService, pResponseBatchOrder }) =>
  async (batchOrderId) => {
    let batchOrder = await RestService.get(
      `/api/admin/order_batches/${batchOrderId}`,
    )
    batchOrder = pResponseBatchOrder(batchOrder)
    BatchOrderService.setBatchOrder(batchOrder)
  }
