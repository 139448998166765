import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormInputContainer } from '@res/styledComponents/index'
import Error from '@components/common/form/Error'

class QuantityInput extends Component {
  state = {
    input: '',
    isFocused: false,
  }

  componentDidMount() {
    const { value } = this.props
    this.onHandleValue(value)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.onHandleValue(nextProps.value)
    }
  }

  onHandleValue = (value) => {
    if (!isNaN(value) && value != null) {
      let input = parseFloat(value).toFixed(0)
      if (input === '0') {
        input = ''
      }
      this.setState({ input })
    } else {
      this.setState({ input: '' })
    }
  }

  onBlur = () => {
    const { onBlur } = this.props
    onBlur && onBlur(parseFloat(this.state.input))
    this.setState({ isFocused: false })
  }

  onFocus = () => {
    this.setState({ isFocused: true })
  }

  onInput = (e) => {
    let input = e.target.value.replace(/[^0-9]/g, '')
    if (!input) {
      input = ''
    }
    this.setState({ input }, () => {
      const { onChange } = this.props
      onChange && onChange(parseFloat(this.state.input))
    })
  }

  render() {
    const {
      pattern,
      label,
      width,
      margin,
      marginBottom,
      name,
      error,
      testId,
      subLabel,
      ...remainingProps
    } = this.props
    delete remainingProps.onChange
    delete remainingProps.value
    const { input } = this.state

    return (
      <FormInputContainer
        width={width}
        marginBottom={marginBottom}
        margin={margin}
      >
        <label>{label}</label>
        {subLabel && <span style={{ fontSize: '12px' }}>{subLabel}</span>}
        <input
          type="text"
          {...remainingProps}
          name={name}
          id={testId}
          pattern={pattern}
          style={{ textAlign: 'right' }}
          value={input}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onInput={this.onInput}
        />
        {error && <Error error={error} />}
      </FormInputContainer>
    )
  }
}

QuantityInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  pattern: PropTypes.string,
  testId: PropTypes.string,
  value: PropTypes.number,
  error: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  marginBottom: PropTypes.string,
  name: PropTypes.string,
  subLabel: PropTypes.string,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

export default QuantityInput
