import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors } from '../../../constants'

import AuthorizedInteractable from '@containers/common/auth/AuthorizedInteractable'
import {
  PageBody,
  PageContainer,
  PageContainerInner,
} from '@res/styledComponents/index'
import Sidebar from '@components/sidebar/Sidebar'
import YSpacing from '@components/common/YSpacing'

export class MasterDetail extends Component {
  state = {
    showSidebar: true,
    currentFilter: this.props.initialFilter,
  }

  onNew = (e) => {
    e.preventDefault()

    const { onNew } = this.props
    onNew && onNew()
  }

  render() {
    const {
      dashboard,
      detail,
      filters,
      master,
      onNewLabel,
      onNewAuthRoles = ['master admin'],
      title,
      hasAddButton = true,
      showDashboard,
      setFilter,
    } = this.props
    const { currentFilter } = this.state

    return (
      <PageBody>
        <Sidebar heading={title}>
          {onNewLabel && hasAddButton && (
            <AuthorizedInteractable roles={onNewAuthRoles}>
              <SidebarButton onClick={this.onNew}>
                Add {onNewLabel}
              </SidebarButton>
            </AuthorizedInteractable>
          )}
          <YSpacing height="20px" />
          {filters.length > 0 && (
            <SidebarDropdown
              onChange={(e) => {
                this.setState({ currentFilter: e.target.value })
                setFilter && setFilter(e.target.value)
              }}
              name={title + ' filters'}
            >
              {filters.map((o) => (
                <option key={o} value={o} selected={o === currentFilter}>
                  {' '}
                  {o}{' '}
                </option>
              ))}
            </SidebarDropdown>
          )}
          <YSpacing height="10px" />
          {typeof master === 'function'
            ? master({ filter: currentFilter })
            : master}
        </Sidebar>
        <PageContainer>
          <PageContainerInner>
            {showDashboard ? (
              dashboard
            ) : (
              <div className="tabs-container">
                <div className="details-tab">{detail}</div>
              </div>
            )}
          </PageContainerInner>
        </PageContainer>
      </PageBody>
    )
  }
}

const SidebarButton = styled.button`
  background: #fff;
  color: ${colors.orange};
  font-family: 'bold';
  text-transform: uppercase;
  font-size: 14px;
  margin: 0 20px;
  padding: 10px;
  border-radius: 4px;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: 0.2s ease-in-out all;
  &:hover {
    box-shadow:
      0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
`

const SidebarDropdown = styled.select`
  margin: 0 20px;
  border: 1px solid white;
  font-family: 'bold';
  background: rgba(255, 255, 255, 0.15);
  padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  border-radius: 4px;
  option {
    color: gray;
  }
`

MasterDetail.propTypes = {
  className: PropTypes.string,
  detail: PropTypes.node,
  dashboard: PropTypes.node,
  initialFilter: PropTypes.string,
  master: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  showDashboard: PropTypes.bool,

  filters: PropTypes.arrayOf(PropTypes.string),
  onNew: PropTypes.func,
  onNewAuthRoles: PropTypes.arrayOf(PropTypes.string),
  onNewLabel: PropTypes.string,
  title: PropTypes.string,
  hasAddButton: PropTypes.bool,
  setFilter: PropTypes.func,
}

MasterDetail.defaultProps = {
  filters: [],
  initialFilter: '',
}

export default MasterDetail
