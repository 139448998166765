export const SearchPopUpDiscounts =
  ({ RestService, HandleError }) =>
  async ({ headquarter, search, limit = 100, offset = 0 }) => {
    try {
      return await RestService.get('/pop-ups/discounts', {
        headquarter,
        search,
        limit,
        offset,
      })
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const SaveDiscountLedger =
  ({ RestService, UIService, HandleError }) =>
  async (discountLedger) => {
    try {
      if (discountLedger.id) {
        await RestService.put(
          `/pop-ups/discounts/${discountLedger.id}`,
          discountLedger,
        )
      } else {
        await RestService.post('/pop-ups/discounts', discountLedger)
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      `Discount ${discountLedger.name} successfully updated`,
    )

    return true
  }
