import React, { Component, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link, NavLink } from 'react-router-dom'
import { MenuItem } from 'react-bootstrap'

import { TechHelpForm } from '@components/common'
import { SelectInput } from '@components/common/form'
import { allReportingRoles } from '@components/reports/constants'

import AuthorizedDisplay from '@containers/common/auth/AuthorizedDisplay'

import logo from '@res/images/hungry-logo.png'
import menu from '@res/images/menu.svg'
import close from '@res/images/x-icon.png'

let hqCheckTimer = null

const HqDropdown = ({ headquarters, userName, onSelectHeadquarter }) => {
  const [lastLoggedInHq, setLastLoggedInHq] = useState(0)
  const nextLoggedInHq = window.localStorage.getItem('lastLoggedInHq')

  const checkLogInHq = useCallback(() => {
    // nextLoggedInHq needs to be redefined in this scope so that it has latest data in localstorage
    const nextLoggedInHq = window.localStorage.getItem('lastLoggedInHq')
    const nextHq = nextLoggedInHq ? parseInt(nextLoggedInHq) : 0
    if (nextHq !== lastLoggedInHq) {
      setLastLoggedInHq(nextHq)
    }
  }, [lastLoggedInHq, setLastLoggedInHq])

  const startCheckLoggedInHq = useCallback(() => {
    checkLogInHq()
    hqCheckTimer = setTimeout(startCheckLoggedInHq, 2500)
  }, [checkLogInHq])

  useEffect(() => {
    if (hqCheckTimer) {
      clearTimeout(hqCheckTimer)
    }
    startCheckLoggedInHq()
  }, [startCheckLoggedInHq])

  useEffect(() => {
    checkLogInHq()
  }, [nextLoggedInHq, checkLogInHq])

  const currentHq = headquarters.find((h) => h.id === lastLoggedInHq)
  const otherHqs = headquarters.filter((h) => h.id !== lastLoggedInHq)
  let selectHqTitle = userName
  if (currentHq) {
    selectHqTitle = `${userName} - ${currentHq.name}`
  }

  return (
    <li className="navigation-dropdown">
      <p className="p-10 nowrap mr-15">{selectHqTitle} ▼</p>
      <div className="navigation-dropdown-content">
        <SelectInput
          defaultOption={currentHq && currentHq.name}
          defaultValue={currentHq && currentHq.id}
          options={otherHqs
            .map((h) => ({ value: h.id, text: h.name }))
            .sort((a, b) => {
              return a.value - b.value
            })}
          onChange={onSelectHeadquarter}
        />
      </div>
    </li>
  )
}

HqDropdown.propTypes = {
  headquarters: PropTypes.arrayOf(PropTypes.object),
  userName: PropTypes.string,

  onSelectHeadquarter: PropTypes.func,
}

class NavBar extends Component {
  state = {
    showNav: false,
    lastLoggedInHq: '',
  }

  componentWillMount() {
    const { headquarters, loadHeadquarters, loadAllHeadquarters, userId } =
      this.props
    if (headquarters.length === 0) {
      loadHeadquarters(userId)
      loadAllHeadquarters()
    }
  }

  onSelectHeadquarter = (value) => {
    const { changeHeadquarter, lastLoggedInHq, userId } = this.props
    if (value !== lastLoggedInHq) {
      changeHeadquarter({ headquarterId: value, userId })
    }
  }

  render() {
    const { headquarters, signOut, userName } = this.props
    const { showNav } = this.state

    return (
      <nav className="navbar-container">
        <Link className="nav-logo" to="/">
          <img alt="Hungry logo" src={logo} />
        </Link>
        <div className="flex align-center">
          <TechHelpForm />
          <HqDropdown
            headquarters={headquarters}
            userName={userName}
            onSelectHeadquarter={this.onSelectHeadquarter}
          />
          <p>Menu</p>
          <img
            src={menu}
            onClick={() => this.setState({ showNav: true })}
            alt="menu"
            className="open-navigation"
          />
          {showNav && (
            <div
              className="admin-dropdown"
              onClick={() => this.setState({ showNav: false })}
            >
              <img className="close-navigation" src={close} alt="close" />
              <li className="mt-20">
                <NavLink activeClassName="bold" exact to="/">
                  {' '}
                  Home{' '}
                </NavLink>
              </li>

              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/clients">
                    {' '}
                    Clients{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>

              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'finance',
                    'accounting dashboard read only',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/hubspot">
                    {' '}
                    Hubspot{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>

              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/invoices">
                    {' '}
                    Batch Invoicing{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>

              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/proposals">
                    {' '}
                    Proposals{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>

              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/proposals-dashboard">
                    {' '}
                    Proposals Dashboard
                  </NavLink>
                </AuthorizedDisplay>
              </li>

              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                    'finance',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/sales">
                    {' '}
                    Sales{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>

              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                    'finance',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/pop-ups">
                    {' '}
                    Pop Ups{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>

              {/* <li>
              <AuthorizedDisplay roles={ [ 'master admin', 'sales rep', 'sales lead', 'chef lead', 'captain lead', 'finance' ] }>
                <NavLink activeClassName="bold" to="/virtual-xp"> VX </NavLink>
              </AuthorizedDisplay>
            </li> */}

              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                    'finance',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/group-orders">
                    {' '}
                    Group Orders{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>

              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                    'finance',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/group-order-search">
                    {' '}
                    Group Order Search{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>

              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                    'finance',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/cafes">
                    {' '}
                    Cafes & Coffee Bars
                  </NavLink>
                </AuthorizedDisplay>
              </li>

              <li className="mb-30">
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/markets-manager">
                    {' '}
                    Markets Manager{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>

              <li>
                <AuthorizedDisplay roles={allReportingRoles}>
                  <NavLink activeClassName="bold" to="/feedback">
                    {' '}
                    Catering Feedback{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>
              <li>
                <AuthorizedDisplay roles={allReportingRoles}>
                  <NavLink activeClassName="bold" to="/reports">
                    {' '}
                    Reports{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>
              <li className="mb-30">
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef',
                    'chef lead',
                    'catering captain',
                    'captain lead',
                    'finance',
                    'accounting dashboard read only',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/dashboard">
                    {' '}
                    AR Dashboard{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>

              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/users">
                    {' '}
                    Users{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>
              <li className="mb-30">
                <AuthorizedDisplay roles={['master admin', 'finance']}>
                  <NavLink activeClassName="bold" to="/search-users">
                    {' '}
                    Search Users{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>

              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/chefs">
                    {' '}
                    Chefs{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>
              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/chef-network">
                    {' '}
                    Chef Network{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>
              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/menuitems">
                    Menu Items Search
                  </NavLink>
                </AuthorizedDisplay>
              </li>
              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/supplies">
                    {' '}
                    Supplies{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>
              <li>
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'accounting dashboard read only',
                    'finance',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/notifications">
                    {' '}
                    Notifications{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>
              <li className="mb-30">
                <AuthorizedDisplay
                  roles={[
                    'master admin',
                    'sales rep',
                    'sales lead',
                    'chef lead',
                    'captain lead',
                  ]}
                >
                  <NavLink activeClassName="bold" to="/surveys">
                    {' '}
                    Surveys{' '}
                  </NavLink>
                </AuthorizedDisplay>
              </li>

              <li className="mb-30">
                <MenuItem onSelect={signOut}> Sign Out </MenuItem>
              </li>
            </div>
          )}
        </div>
      </nav>
    )
  }
}

NavBar.propTypes = {
  headquarters: PropTypes.arrayOf(PropTypes.object),
  lastLoggedInHq: PropTypes.number,
  userId: PropTypes.string,
  userName: PropTypes.string,

  changeHeadquarter: PropTypes.func,
  loadHeadquarters: PropTypes.func,
  loadAllHeadquarters: PropTypes.func,
  signOut: PropTypes.func,
}

export default NavBar
