import { BaseService } from 'hungry-core2'
import Moment from 'moment-timezone'

import {
  isNumber,
  roundMoneyUp,
  removedFromArrayByAttribute,
  replacedInArrayByAttribute,
  reorderArrayByAttribute,
  sortByAttribute,
  deepCopy,
  escapeRegex,
} from '~/utils'
import actions from '@actions'
import {
  HUNGRY_PERC,
  HolidayDates,
  DEFAULT_DELIVERY_FEE_PERCENT_HOLIDAY,
  DEFAULT_DELIVERY_FEE_PERCENT_WEEKEND,
  DEFAULT_DELIVERY_FEE_PERCENT_WEEKDAY,
  DEFAULT_DELIVERY_FEE_CAP_HOLIDAY,
  DEFAULT_DELIVERY_FEE_CAP_WEEKEND,
  DEFAULT_DELIVERY_FEE_CAP_WEEKDAY,
  MAIN,
  PROTEIN,
  BYO_MAIN_ITEM_PERCENT,
  BYO_MAIN_CHILD_ITEM_PERCENT,
  BYO_CHILD_ITEM_PERCENT,
} from '../../constants'

export class OrderService extends BaseService {
  orderableId = () => {
    return this.getState().order.id
  }

  orderable = () => {
    return this.getState().order
  }

  appendOrderables = (orders) => {
    this.dispatch(actions.appendOrders(orders))
  }

  clearEditOrderable = () => {
    this.dispatch(actions.clearEditOrder())
  }

  clearNewOrderable = () => {
    this.dispatch(actions.clearNewOrder())
  }

  clearOrderable = () => {
    this.dispatch(actions.clearOrder())
  }

  clearOrderables = () => {
    this.dispatch(actions.clearOrders())
  }

  removeOrderableFromOrderables = (orderId) => {
    this.dispatch(actions.removeOrderFromOrders(orderId))
  }

  setCateringCaptains = (cateringCaptains) => {
    this.dispatch(actions.setCateringCaptains(cateringCaptains))
  }

  setServiceDepots = (serviceDepots) => {
    this.dispatch(actions.setServiceDepots(serviceDepots))
  }

  setEditOrderable = (order) => {
    this.dispatch(actions.setEditOrder(order))
  }

  setNewOrderable = (order) => {
    this.dispatch(actions.setNewOrder(order))
  }

  setOrderable = (order) => {
    this.dispatch(actions.setOrder(order))
  }

  setOrderables = (orders) => {
    this.dispatch(actions.setOrders(orders))
  }

  setOrderableItems = (orderItems) => {
    this.dispatch(actions.setOrderItems(orderItems))
  }

  setReduxOrderStatus = (s) => {
    this.dispatch(actions.setOrderStatus(s))
  }

  updateEditOrderable = (order) => {
    this.dispatch(actions.updateEditOrder(order))
  }

  updateNewOrderable = (order) => {
    this.dispatch(actions.updateNewOrder(order))
  }

  syncChildItems = (orderItem1, orderItem2) => {
    const childItems1 = orderItem1.childItems || []
    const childItems2 = orderItem2.childItems || []

    const childItems2Map = {}
    childItems2.forEach((childItem) => {
      childItems2Map[childItem.name] = childItem
    })

    childItems1.forEach((childItem1) => {
      const childItem2 = childItems2Map[childItem1.name]
      if (childItem2) {
        childItem2.quantity = childItem1.quantity
        childItems2Map[childItem1.name] = childItem2
      }
    })

    return { orderItem1, orderItem2 }
  }

  addCheftoChefs = ({ chefs, chef }, chefNotes = undefined) => {
    const foundChef = chefs.find((c) => c.id === chef.id)
    if (foundChef) {
      return chefs
    }

    chef.orderMenuItems = []
    chef.orderServiceItems = []
    chef.orderVirtualItems = []
    chef.orderVirtualKits = []
    chef.orderSnackPacks = []
    chef.customOrderMenuItems = []
    chef.customOrderServiceItems = []
    chef.customOrderVirtualItems = []
    chef.displayOrder = chefs.length
    if (chefNotes) {
      chef.chefNote = {
        chefId: chef.id,
        words: chefNotes,
      }
    }
    chefs.push(chef)

    return chefs.slice()
  }

  removeChefFromChefs = ({ chefs, chef }) => {
    const chefIndex = chefs.findIndex((c) => c.id === chef.id)
    if (chefIndex > -1) {
      chefs.splice(chefIndex, 1)
    }
    chefs.forEach((chef, i) => (chef.displayOrder = i))

    return chefs
  }

  getEmptyChefs = ({ chefs }) => {
    return chefs.filter((c) => this._isChefEmpty(c))
  }

  _isChefEmpty = (chef) => {
    return [
      chef.orderMenuItems,
      chef.customOrderMenuItems,
      chef.orderServiceItems,
      chef.customOrderServiceItems,
      chef.orderVirtualItems,
      chef.customOrderVirtualItems,
      chef.orderVirtualKits,
      chef.orderSnackPacks,
    ].every((itemArr) => itemArr.length === 0)
  }

  addMenuItemToChefs = ({
    chefs,
    chef,
    conceptId,
    menuItem,
    isCustom = false,
    isBYO = false,
  }) => {
    const { chefId } = menuItem
    const foundChef = chefs.find((c) => c.id === chefId)
    if (!foundChef) {
      chef.isBYOConcept = isBYO
      chef.orderMenuItems = []
      chef.orderServiceItems = []
      chef.orderVirtualItems = []
      chef.orderVirtualKits = []
      chef.orderSnackPacks = []
      chef.customOrderMenuItems = []
      chef.customOrderServiceItems = []
      chef.customOrderVirtualItems = []

      chefs.push(chef)
    }
    chef = foundChef || chef

    const orderMenuItems = isCustom
      ? chef.customOrderMenuItems
      : chef.orderMenuItems
    const orderItem = this._menuItemToOrderItem(
      menuItem,
      this._maxDisplayOrderOf(orderMenuItems) + 1,
    )

    orderItem.conceptId = conceptId
    if (orderItem.conceptId) {
      orderItem.isBYOConcept = isBYO
    }

    /* No menu item id means custom item. Check menu item not already added by menu item id */
    if (
      orderMenuItems.find(
        (i) => orderItem.menuItemId && i.menuItemId === orderItem.menuItemId,
      )
    ) {
      return chefs
    }

    if (isCustom) {
      chef.customOrderMenuItems = [...orderMenuItems, orderItem]
    } else {
      chef.orderMenuItems = [...orderMenuItems, orderItem]
    }

    return chefs.slice()
  }

  addMenuItemsToChefs = ({
    chefs,
    chef,
    conceptId,
    menuItems,
    isBYO = false,
  }) => {
    let chefsCopy = deepCopy(chefs)
    for (let index = 0; index < menuItems.length; index++) {
      const menuItem = menuItems[index]
      chefsCopy = this.addMenuItemToChefs({
        chefs: chefsCopy,
        chef,
        conceptId,
        menuItem,
        isCustom: false,
        isBYO,
      })
    }

    return chefsCopy
  }

  addServiceItemToChefs = ({ chefs, chef, serviceItem, isCustom = false }) => {
    const { chefId } = serviceItem

    const foundChef = chefs.find((c) => c.id === chefId)
    if (!foundChef) {
      chef.id = chefId
      chef.orderMenuItems = []
      chef.orderServiceItems = []
      chef.orderVirtualItems = []
      chef.orderVirtualKits = []
      chef.orderSnackPacks = []
      chef.customOrderMenuItems = []
      chef.customOrderServiceItems = []
      chef.customOrderVirtualItems = []
      chefs.push(chef)
    }
    chef = foundChef || chef
    const orderServiceItems = isCustom
      ? chef.customOrderServiceItems
      : chef.orderServiceItems

    const orderItem = this._serviceItemToOrderItem(
      serviceItem,
      this._maxDisplayOrderOf(orderServiceItems) + 1,
    )

    // no duplicate service items
    if (
      orderServiceItems.find(
        (i) =>
          orderItem.serviceItemId &&
          i.serviceItemId === orderItem.serviceItemId,
      )
    ) {
      return chefs
    }

    if (isCustom) {
      chef.customOrderServiceItems = [...orderServiceItems, orderItem]
    } else {
      chef.orderServiceItems = [...orderServiceItems, orderItem]
    }

    return chefs.slice()
  }

  addVirtualItemToChefs = ({ chefs, chef, virtualItem, isCustom = false }) => {
    const { chefId } = virtualItem

    const foundChef = chefs.find((c) => c.id === chefId)
    if (!foundChef) {
      chef.id = chefId
      chef.orderMenuItems = []
      chef.orderServiceItems = []
      chef.orderVirtualItems = []
      chef.orderVirtualKits = []
      chef.orderSnackPacks = []
      chef.customOrderMenuItems = []
      chef.customOrderServiceItems = []
      chef.customOrderVirtualItems = []
      chefs.push(chef)
    }
    chef = foundChef || chef
    const orderVirtualItems = isCustom
      ? chef.customOrderVirtualItems
      : chef.orderVirtualItems

    const orderItem = this._virtualItemToOrderItem(
      virtualItem,
      this._maxDisplayOrderOf(orderVirtualItems) + 1,
    )

    // no duplicate virtual items
    if (
      orderVirtualItems.find(
        (i) =>
          orderItem.virtualItemId &&
          i.virtualItemId === orderItem.virtualItemId,
      )
    ) {
      return chefs
    }

    if (isCustom) {
      chef.customOrderVirtualItems = [...orderVirtualItems, orderItem]
    } else {
      chef.orderVirtualItems = [...orderVirtualItems, orderItem]
    }

    return chefs.slice()
  }

  addVirtualKitToChefs = ({ chefs, chef, virtualKit }) => {
    const { chefId } = virtualKit

    const foundChef = chefs.find((c) => c.id === chefId)
    if (!foundChef) {
      chef.id = chefId
      chef.orderMenuItems = []
      chef.orderServiceItems = []
      chef.orderVirtualItems = []
      chef.orderVirtualKits = []
      chef.orderSnackPacks = []
      chef.customOrderMenuItems = []
      chef.customOrderServiceItems = []
      chef.customOrderVirtualItems = []
      chefs.push(chef)
    }
    chef = foundChef || chef
    const { orderVirtualKits } = chef

    const orderItem = this._virtualKitToOrderItem(
      virtualKit,
      this._maxDisplayOrderOf(orderVirtualKits) + 1,
    )

    if (
      orderVirtualKits.find(
        (i) =>
          orderItem.virtualKitId && i.virtualKitId === orderItem.virtualKitId,
      )
    ) {
      return chefs
    }

    chef.orderVirtualKits = [...orderVirtualKits, orderItem]

    return chefs.slice()
  }

  addSnackPackToChefs = ({ chefs, chef, snackPack }) => {
    const { chefId } = snackPack

    const foundChef = chefs.find((c) => c.id === chefId)
    if (!foundChef) {
      chef.id = chefId
      chef.orderMenuItems = []
      chef.orderServiceItems = []
      chef.orderVirtualItems = []
      chef.orderVirtualKits = []
      chef.orderSnackPacks = []
      chef.customOrderMenuItems = []
      chef.customOrderServiceItems = []
      chef.customOrderVirtualItems = []
      chefs.push(chef)
    }
    chef = foundChef || chef
    const { orderSnackPacks } = chef

    const orderItem = this._snackPackToOrderItem(
      snackPack,
      this._maxDisplayOrderOf(orderSnackPacks) + 1,
    )

    if (
      orderSnackPacks.find(
        (i) => orderItem.snackPackId && i.snackPackId === orderItem.snackPackId,
      )
    ) {
      return chefs
    }

    chef.orderSnackPacks = [...orderSnackPacks, orderItem]

    return chefs.slice()
  }

  _maxDisplayOrderOf = (orderItems) => {
    let max = 0
    orderItems.forEach((item) => {
      if (item.displayOrder > max) {
        max = item.displayOrder
      }
    })

    return max
  }

  _sortChildItems = (childItems) => {
    const proteins = []
    const mains = []
    const bases = []
    const dressings = []
    const toppings = []
    const sides = []
    let other = []
    childItems &&
      childItems.forEach((childItem) => {
        const category = (childItem.category || '').toLowerCase()
        if (category.includes('protein')) {
          proteins.push(childItem)
        } else if (category.includes('main')) {
          mains.push(childItem)
        } else if (category.includes('base')) {
          bases.push(childItem)
        } else if (category.includes('dressing')) {
          dressings.push(childItem)
        } else if (category.includes('topping')) {
          toppings.push(childItem)
        } else if (category.includes('side')) {
          sides.push(childItem)
        } else {
          other.push(childItem)
        }
      })
    other = other.sort((a, b) => a.category > b.category)

    return [
      ...proteins,
      ...mains,
      ...bases,
      ...dressings,
      ...toppings,
      ...sides,
      ...other,
    ]
  }

  _menuItemToOrderItem = (menuItem, displayOrder = null, index = null) => {
    const {
      category,
      chefId,
      chefPrice = 0,
      retailPrice,
      childItems,
      conceptId,
      conceptsMenuItemId,
      mealType,
      menuItemId,
      name = '',
      packaging,
      quantity,
      price = 0,
      servingsPerPkg,
      servingUtensil,
      tagsDietaryPreferenceList,
      parentMenuItemId,
      minQty,
      servingSize,
      internalNotes,
      description,
    } = menuItem
    const childOrderItems =
      childItems &&
      this._sortChildItems(childItems).map((i, index) =>
        this._menuItemToOrderItem(i, displayOrder, index),
      )

    const newDisplayOrder = parentMenuItemId
      ? parseFloat(displayOrder) + (index + 1) / 100.0
      : displayOrder
    const orderItem = {
      category,
      chefPrice,
      retailPrice,
      childItems: childOrderItems,
      displayOrder: newDisplayOrder,
      mealType,
      name,
      packaging,
      price,
      quantity: quantity || 0,
      servingsPerPkg,
      servingUtensil,
      tagsDietaryPreferenceList,
      invoiceable: true,
      itemType: 'MenuItem',
      minQty,
      servingSize,
      internalNotes,
      description,
    }
    if (menuItemId) {
      orderItem.menuItemId = menuItemId
    }
    if (conceptsMenuItemId) {
      orderItem.conceptId = conceptId
      orderItem.conceptsMenuItemId = conceptsMenuItemId
    }
    if (chefId) {
      orderItem.chefId = chefId
    }

    return orderItem
  }

  _serviceItemToOrderItem = (serviceItem, displayOrder = null) => {
    const {
      chefId,
      cost = 0,
      serviceItemId,
      name = '',
      price = 0,
      pricingRate,
    } = serviceItem

    const orderItem = {
      chefId,
      cost,
      serviceItemId,
      name,
      price,
      pricingRate,
      displayOrder,
      quantity: 0,
      invoiceable: true,
      itemType: 'ServiceItem',
    }

    return orderItem
  }

  _virtualItemToOrderItem = (virtualItem, displayOrder = null) => {
    const {
      chefId,
      cost = 0,
      virtualItemId,
      name = '',
      price = 0,
    } = virtualItem

    const orderItem = {
      chefId,
      cost,
      virtualItemId,
      name,
      price,
      displayOrder,
      quantity: 0,
      invoiceable: true,
      itemType: 'VirtualItem',
    }

    return orderItem
  }

  _virtualKitToOrderItem = (virtualKit, displayOrder = null) => {
    const { chefId, cost = 0, virtualKitId, name = '', price = 0 } = virtualKit

    const orderItem = {
      chefId,
      cost,
      virtualKitId,
      name,
      price,
      displayOrder,
      quantity: 0,
      invoiceable: true,
      itemType: 'VirtualKit',
    }

    return orderItem
  }

  _snackPackToOrderItem = (snackPack, displayOrder = null) => {
    const { chefId, cost = 0, snackPackId, name = '', price = 0 } = snackPack

    const orderItem = {
      chefId,
      cost,
      snackPackId,
      name,
      price,
      displayOrder,
      quantity: 0,
      invoiceable: true,
      itemType: 'SnackPack',
    }

    return orderItem
  }

  replaceOrderMenuItemInChefs = ({ chefs, orderItem, isCustom = false }) => {
    const updateFunction = (items, item, sortBy) =>
      replacedInArrayByAttribute(items, item, sortBy)

    return this._updateChefMenuOrderItem({
      chefs,
      orderItem,
      isCustom,
      updateFunction,
      sortByArray: [
        'id',
        'displayOrder',
        'menuItemId',
        'serviceItemId',
        'virtualItemId',
      ],
    })
  }

  reorderItemInChefs = ({
    chefs,
    orderItem,
    change,
    isCustom = false,
    itemType,
  }) => {
    const chefIdx = chefs.findIndex((c) => c.id === orderItem.chefId)
    if (chefIdx === -1) {
      return chefs
    }
    chefs = chefs.slice()
    const chef = { ...chefs[chefIdx] }

    const attrs = [
      'id',
      'displayOrder',
      'menuItemId',
      'serviceItemId',
      'virtualItemId',
      'snackPackId',
    ]
    let collectionName
    if (itemType === 'MenuItem') {
      collectionName = isCustom ? 'customOrderMenuItems' : 'orderMenuItems'
    } else if (itemType === 'ServiceItem') {
      collectionName = isCustom
        ? 'customOrderServiceItems'
        : 'orderServiceItems'
    } else if (itemType === 'VirtualItem') {
      collectionName = isCustom
        ? 'customOrderVirtualItems'
        : 'orderVirtualItems'
    } else if (itemType === 'VirtualKit') {
      collectionName = 'orderVirtualKits'
    } else if (itemType == 'SnackPack') {
      collectionName = 'orderSnackPacks'
    }
    chef[collectionName] = reorderArrayByAttribute(
      chef[collectionName],
      orderItem,
      attrs,
      'displayOrder',
      change,
    )
    sortByAttribute(chef[collectionName], 'displayOrder')
    chefs[chefIdx] = chef

    return chefs
  }

  reorderChef = ({ chefs, chef, change }) => {
    let chefsCopy = deepCopy(chefs)
    // missing displayOrder prevented this from sorting correctly
    chefsCopy = chefsCopy.map((c, i) => ({ ...c, displayOrder: i + 1 }))
    chefsCopy = reorderArrayByAttribute(
      chefsCopy,
      chef,
      ['id'],
      'displayOrder',
      change,
    )
    sortByAttribute(chefsCopy, 'displayOrder')

    return chefsCopy
  }

  removeOrderMenuItemFromChefs = ({ chefs, orderItem, isCustom = false }) => {
    const updateFunction = (items, item, sortBy) =>
      removedFromArrayByAttribute(items, item, sortBy)

    return this._updateChefMenuOrderItem({
      chefs,
      orderItem,
      isCustom,
      updateFunction,
      sortByArray: ['id', 'displayOrder', 'menuItemId', 'serviceItemId'],
    })
  }

  replaceOrderServiceItemInChefs = ({ chefs, orderItem, isCustom = false }) => {
    const updateFunction = (items, item, sortBy) =>
      replacedInArrayByAttribute(items, item, sortBy)

    return this._updateChefServiceOrderItem({
      chefs,
      orderItem,
      isCustom,
      updateFunction,
      sortByArray: ['id', 'displayOrder', 'menuItemId', 'serviceItemId'],
    })
  }

  removeOrderServiceItemFromChefs = ({
    chefs,
    orderItem,
    isCustom = false,
  }) => {
    const updateFunction = (items, item, sortBy) =>
      removedFromArrayByAttribute(items, item, sortBy)

    return this._updateChefServiceOrderItem({
      chefs,
      orderItem,
      isCustom,
      updateFunction,
      sortByArray: ['id', 'displayOrder', 'menuItemId', 'serviceItemId'],
    })
  }

  replaceOrderVirtualItemInChefs = ({ chefs, orderItem, isCustom = false }) => {
    const updateFunction = (items, item, sortBy) =>
      replacedInArrayByAttribute(items, item, sortBy)

    return this._updateChefVirtualOrderItem({
      chefs,
      orderItem,
      isCustom,
      updateFunction,
      sortByArray: ['id', 'displayOrder', 'menuItemId', 'virtualItemId'],
    })
  }

  removeOrderVirtualItemFromChefs = ({
    chefs,
    orderItem,
    isCustom = false,
  }) => {
    const updateFunction = (items, item, sortBy) =>
      removedFromArrayByAttribute(items, item, sortBy)

    return this._updateChefVirtualOrderItem({
      chefs,
      orderItem,
      isCustom,
      updateFunction,
      sortByArray: ['id', 'displayOrder', 'menuItemId', 'virtualItemId'],
    })
  }

  replaceOrderVirtualKitInChefs = ({ chefs, orderItem }) => {
    const updateFunction = (items, item, sortBy) =>
      replacedInArrayByAttribute(items, item, sortBy)

    return this._updateChefVirtualOrderKit({
      chefs,
      orderItem,
      updateFunction,
      sortByArray: ['id', 'displayOrder', 'menuItemId', 'virtualKitId'],
    })
  }

  removeOrderVirtualKitFromChefs = ({ chefs, orderItem }) => {
    const updateFunction = (items, item, sortBy) =>
      removedFromArrayByAttribute(items, item, sortBy)

    return this._updateChefVirtualOrderKit({
      chefs,
      orderItem,
      updateFunction,
      sortByArray: ['id', 'displayOrder', 'menuItemId', 'virtualKitId'],
    })
  }

  replaceOrderSnackPackInChefs = ({ chefs, orderItem }) => {
    const updateFunction = (items, item, sortBy) =>
      replacedInArrayByAttribute(items, item, sortBy)

    return this._updateChefSnackOrderPack({
      chefs,
      orderItem,
      updateFunction,
      sortByArray: ['id', 'displayOrder', 'menuItemId', 'snackPackId'],
    })
  }

  removeOrderSnackPackFromChefs = ({ chefs, orderItem }) => {
    const updateFunction = (items, item, sortBy) =>
      removedFromArrayByAttribute(items, item, sortBy)

    return this._updateChefSnackOrderPack({
      chefs,
      orderItem,
      updateFunction,
      sortByArray: ['id', 'displayOrder', 'menuItemId', 'snackPackId'],
    })
  }

  _updateChefMenuOrderItem = ({
    chefs,
    orderItem,
    isCustom,
    updateFunction,
    sortByArray,
  }) => {
    const chefIdx = chefs.findIndex((c) => c.id === orderItem.chefId)
    if (chefIdx === -1) {
      return chefs
    }
    chefs = chefs.slice()
    const chef = { ...chefs[chefIdx] }

    // update corresponding item depending on item type and custom
    if (isCustom) {
      chef.customOrderMenuItems = updateFunction(
        chef.customOrderMenuItems,
        orderItem,
        sortByArray,
      )
    } else {
      chef.orderMenuItems = updateFunction(
        chef.orderMenuItems,
        orderItem,
        sortByArray,
      )
    }

    chefs[chefIdx] = chef

    return chefs
  }

  _updateChefServiceOrderItem = ({
    chefs,
    orderItem,
    isCustom,
    updateFunction,
    sortByArray,
  }) => {
    const chefIdx = chefs.findIndex((c) => c.id === orderItem.chefId)
    if (chefIdx === -1) {
      return chefs
    }
    chefs = chefs.slice()
    const chef = { ...chefs[chefIdx] }

    // update corresponding item depending on item type and custom
    if (isCustom) {
      chef.customOrderServiceItems = updateFunction(
        chef.customOrderServiceItems,
        orderItem,
        sortByArray,
      )
    } else {
      chef.orderServiceItems = updateFunction(
        chef.orderServiceItems,
        orderItem,
        sortByArray,
      )
    }

    chefs[chefIdx] = chef

    return chefs
  }

  _updateChefVirtualOrderItem = ({
    chefs,
    orderItem,
    isCustom,
    updateFunction,
    sortByArray,
  }) => {
    const chefIdx = chefs.findIndex((c) => c.id === orderItem.chefId)
    if (chefIdx === -1) {
      return chefs
    }
    chefs = chefs.slice()
    const chef = { ...chefs[chefIdx] }

    // update corresponding item depending on item type and custom
    if (isCustom) {
      chef.customOrderVirtualItems = updateFunction(
        chef.customOrderVirtualItems,
        orderItem,
        sortByArray,
      )
    } else {
      chef.orderVirtualItems = updateFunction(
        chef.orderVirtualItems,
        orderItem,
        sortByArray,
      )
    }

    chefs[chefIdx] = chef

    return chefs
  }

  _updateChefVirtualOrderKit = ({
    chefs,
    orderItem,
    updateFunction,
    sortByArray,
  }) => {
    const chefIdx = chefs.findIndex((c) => c.id === orderItem.chefId)
    if (chefIdx === -1) {
      return chefs
    }
    chefs = chefs.slice()
    const chef = { ...chefs[chefIdx] }

    chef.orderVirtualKits = updateFunction(
      chef.orderVirtualKits,
      orderItem,
      sortByArray,
    )

    chefs[chefIdx] = chef

    return chefs
  }

  _updateChefSnackOrderPack = ({
    chefs,
    orderItem,
    updateFunction,
    sortByArray,
  }) => {
    const chefIdx = chefs.findIndex((c) => c.id === orderItem.chefId)
    if (chefIdx === -1) {
      return chefs
    }
    chefs = chefs.slice()
    const chef = { ...chefs[chefIdx] }

    chef.orderSnackPacks = updateFunction(
      chef.orderSnackPacks,
      orderItem,
      sortByArray,
    )

    chefs[chefIdx] = chef

    return chefs
  }

  replaceChefNoteInAddOnChefs = ({ chefs, chefNote }) => {
    const chefIdx = chefs.findIndex((c) => c.id === chefNote.chefId)
    if (chefIdx === -1) {
      return chefs
    }
    chefs = chefs.slice()
    const chef = { ...chefs[chefIdx] }
    chef.chefNote = { ...chefNote }
    chefs[chefIdx] = chef

    return chefs
  }

  getChefAmountMap = ({ adjustedChefPayouts, chefs }) => {
    const filterAdjustedChefs = ({ adjustedChefPayouts, chefs }) =>
      chefs.filter((c) => !adjustedChefPayouts.find((a) => a.id === c.id))
    const c =
      adjustedChefPayouts && adjustedChefPayouts.length !== 0
        ? filterAdjustedChefs({ adjustedChefPayouts, chefs })
        : chefs.slice()

    const chefsMap = {}

    c.forEach((chef) => {
      let total = 0
      total += this._sumItemsChefPrice(chef.orderMenuItems)
      total += this._sumItemsChefPrice(chef.orderServiceItems)
      total += this._sumItemsChefPrice(chef.orderVirtualItems)
      total += this._sumItemsChefPrice(chef.orderVirtualKits)
      total += this._sumItemsChefPrice(chef.orderSnackPacks)
      total += this._sumItemsChefPrice(chef.customOrderMenuItems)
      total += this._sumItemsChefPrice(chef.customOrderServiceItems)
      total += this._sumItemsChefPrice(chef.customOrderVirtualItems)
      chefsMap[chef.id] = total
    })

    return chefsMap
  }

  calculateChefAmount = ({ adjustedChefPayouts, chefs }) => {
    // this is the same calculation as subtotal now
    let total = 0
    const filterAdjustedChefs = ({ adjustedChefPayouts, chefs }) =>
      chefs.filter((c) => !adjustedChefPayouts.find((a) => a.id === c.id))
    const c =
      adjustedChefPayouts && adjustedChefPayouts.length !== 0
        ? filterAdjustedChefs({ adjustedChefPayouts, chefs })
        : chefs.slice()
    c.forEach((chef) => {
      total += this._sumItemsChefPrice(chef.orderMenuItems)
      total += this._sumItemsChefPrice(chef.orderServiceItems)
      total += this._sumItemsChefPrice(chef.orderVirtualItems)
      total += this._sumItemsChefPrice(chef.orderVirtualKits)
      total += this._sumItemsChefPrice(chef.orderSnackPacks)
      total += this._sumItemsChefPrice(chef.customOrderMenuItems)
      total += this._sumItemsChefPrice(chef.customOrderServiceItems)
      total += this._sumItemsChefPrice(chef.customOrderVirtualItems)
    })
    if (adjustedChefPayouts && adjustedChefPayouts.length !== 0) {
      total = adjustedChefPayouts.reduce((a, b) => {
        return a + b.payout
      }, total)
    }

    return total
  }

  calculateChefPrice = (marketPrice, markupPercentage) => {
    if (!marketPrice) {
      return 0
    }

    if (!markupPercentage) {
      return marketPrice / 2
    }

    return marketPrice * (1 - markupPercentage)
  }

  calculateChefPriceFromRetail = (retailPrice) => {
    if (!retailPrice) {
      return 0
    }

    return retailPrice * (1 - HUNGRY_PERC)
  }

  calculatePrice = (chefPrice, markupPercentage) => {
    if (!chefPrice) {
      return 0
    }

    if (!markupPercentage) {
      return chefPrice * 2
    }

    return chefPrice / (1 - markupPercentage)
  }

  calculatePreTaxTotal = ({
    clientSetUpTime,
    needsStaffing,
    chefs,
    deliveryFee,
    discountAmount,
    discountType,
    discounts,
    subtotal,
    isDeliveryFeeOverride,
    isFreeDelivery,
    isVCX,
    cleanupFee,
    numberOfStaff,
    staffingHours,
    staffingRate,
    carbonNeutralContribution = 0,
    deliveryFeePercent,
    deliveryFeeLimit,
  }) => {
    if (
      discounts
        .filter((d) => !d._destroy)
        .some((d) => ['comped_meal', 'zero_total'].includes(d.kind))
    ) {
      subtotal = 0
    } else if (subtotal == null) {
      subtotal = this.calculateSubtotal({ chefs })
    }
    let total =
      subtotal -
      this.calculateDiscounts({
        subtotal,
        discounts,
        discountAmount,
        discountType,
      })

    const serviceFee = this.calculatePretotalServiceFee({
      clientSetUpTime,
      needsStaffing,
      isDeliveryFeeOverride,
      isFreeDelivery,
      isVCX,
      subtotal,
      cleanupFee,
      deliveryFee,
      numberOfStaff,
      staffingHours,
      staffingRate,
      discounts,
      deliveryFeePercent,
      deliveryFeeLimit,
    })
    total += serviceFee
    total += carbonNeutralContribution

    return total
  }

  calculateDeliveryFee = ({
    isDeliveryFeeOverride,
    isVCX = false,
    subtotal = 0,
    deliveryFee = 0,
    percent = 10, // Default to 10 percent
    limit,
  }) => {
    if (isVCX) {
      return 0.0
    }

    if (isDeliveryFeeOverride) {
      return deliveryFee
    }

    const fee = roundMoneyUp((percent / 100) * subtotal)

    return isNumber(limit) && fee > limit ? limit : fee
  }

  calculateStaffingFee = ({
    numberOfStaff = 0,
    staffingHours = 0,
    staffingRate,
    needsStaffing = false,
  }) => {
    if (!needsStaffing) {
      return 0
    }

    let rate = 50 // Default rate
    if (isNumber(staffingRate)) {
      rate = staffingRate
    }

    return roundMoneyUp(numberOfStaff * staffingHours * rate)
  }

  calculateServiceFee = ({
    clientSetUpTime,
    needsStaffing,
    isDeliveryFeeOverride,
    isVCX = false,
    subtotal = 0,
    cleanupFee = 0,
    deliveryFee: inDeliveryFee = 0,
    numberOfStaff = 0,
    staffingHours = 0,
    staffingRate,
    deliveryFeePercent,
    deliveryFeeLimit,
  }) => {
    const deliveryFee = this.calculateDeliveryFee({
      clientSetUpTime,
      isDeliveryFeeOverride,
      isVCX,
      subtotal,
      deliveryFee: inDeliveryFee,
      percent: deliveryFeePercent,
      limit: deliveryFeeLimit,
    })
    const staffingFee = this.calculateStaffingFee({
      numberOfStaff,
      staffingHours,
      staffingRate,
      needsStaffing,
    })

    return roundMoneyUp(deliveryFee + cleanupFee + staffingFee)
  }

  getDeliveryFeesOrDefault = ({
    deliveryAndServiceFeePercentWeekend,
    deliveryAndServiceFeePercentWeekday,
    deliveryAndServiceFeePercentHoliday,
    deliveryAndServiceFeeCapWeekend,
    deliveryAndServiceFeeCapWeekday,
    deliveryAndServiceFeeCapHoliday,
  }) => {
    const deliveryFeeStructure = {}

    if (deliveryAndServiceFeePercentWeekend) {
      deliveryFeeStructure.deliveryAndServiceFeePercentWeekend =
        deliveryAndServiceFeePercentWeekend
      deliveryFeeStructure.deliveryAndServiceFeeCapWeekend =
        deliveryAndServiceFeeCapWeekend
    } else {
      deliveryFeeStructure.deliveryAndServiceFeePercentWeekend =
        DEFAULT_DELIVERY_FEE_PERCENT_WEEKEND
      deliveryFeeStructure.deliveryAndServiceFeeCapWeekend =
        DEFAULT_DELIVERY_FEE_CAP_WEEKEND
    }

    if (deliveryAndServiceFeePercentWeekday) {
      deliveryFeeStructure.deliveryAndServiceFeePercentWeekday =
        deliveryAndServiceFeePercentWeekday
      deliveryFeeStructure.deliveryAndServiceFeeCapWeekday =
        deliveryAndServiceFeeCapWeekday
    } else {
      deliveryFeeStructure.deliveryAndServiceFeePercentWeekday =
        DEFAULT_DELIVERY_FEE_PERCENT_WEEKDAY
      deliveryFeeStructure.deliveryAndServiceFeeCapWeekday =
        DEFAULT_DELIVERY_FEE_CAP_WEEKDAY
    }

    if (deliveryAndServiceFeePercentHoliday) {
      deliveryFeeStructure.deliveryAndServiceFeePercentHoliday =
        deliveryAndServiceFeePercentHoliday
      deliveryFeeStructure.deliveryAndServiceFeeCapHoliday =
        deliveryAndServiceFeeCapHoliday
    } else {
      deliveryFeeStructure.deliveryAndServiceFeePercentHoliday =
        DEFAULT_DELIVERY_FEE_PERCENT_HOLIDAY
      deliveryFeeStructure.deliveryAndServiceFeeCapHoliday =
        DEFAULT_DELIVERY_FEE_CAP_HOLIDAY
    }

    return deliveryFeeStructure
  }

  getDeliveryFeePercentAndLimit = ({
    clientSetUpTime,
    deliveryAndServiceFeePercentHoliday,
    deliveryAndServiceFeePercentWeekend,
    deliveryAndServiceFeePercentWeekday,
    deliveryAndServiceFeeCapHoliday,
    deliveryAndServiceFeeCapWeekend,
    deliveryAndServiceFeeCapWeekday,
  }) => {
    const deliveryFeeStructure = this.getDeliveryFeesOrDefault({
      deliveryAndServiceFeePercentWeekend,
      deliveryAndServiceFeePercentWeekday,
      deliveryAndServiceFeePercentHoliday,
      deliveryAndServiceFeeCapWeekend,
      deliveryAndServiceFeeCapWeekday,
      deliveryAndServiceFeeCapHoliday,
    })

    let percent
    let limit
    if (clientSetUpTime && HolidayDates[clientSetUpTime.format('M/D')]) {
      // Check Holiday
      if (isNumber(deliveryFeeStructure.deliveryAndServiceFeePercentHoliday)) {
        percent = deliveryFeeStructure.deliveryAndServiceFeePercentHoliday
      }
      if (isNumber(deliveryFeeStructure.deliveryAndServiceFeeCapHoliday)) {
        limit = deliveryFeeStructure.deliveryAndServiceFeeCapHoliday
      }
    } else if (
      clientSetUpTime &&
      ['6', '7'].includes(clientSetUpTime.format('E'))
    ) {
      // Check Weekend
      if (isNumber(deliveryFeeStructure.deliveryAndServiceFeePercentWeekend)) {
        percent = deliveryFeeStructure.deliveryAndServiceFeePercentWeekend
      }
      if (isNumber(deliveryFeeStructure.deliveryAndServiceFeeCapWeekend)) {
        limit = deliveryFeeStructure.deliveryAndServiceFeeCapWeekend
      }
    } else {
      // Default to weekday
      if (isNumber(deliveryFeeStructure.deliveryAndServiceFeePercentWeekday)) {
        percent = deliveryFeeStructure.deliveryAndServiceFeePercentWeekday
      }
      if (isNumber(deliveryFeeStructure.deliveryAndServiceFeeCapWeekday)) {
        limit = deliveryFeeStructure.deliveryAndServiceFeeCapWeekday
      }
    }

    return { percent, limit }
  }

  calculatePretotalServiceFee = ({
    clientSetUpTime,
    needsStaffing,
    isDeliveryFeeOverride,
    isFreeDelivery,
    isVCX = false,
    subtotal = 0,
    cleanupFee = 0,
    deliveryFee = 0,
    numberOfStaff = 0,
    staffingHours = 0,
    staffingRate,
    discounts = [],
    deliveryFeePercent,
    deliveryFeeLimit,
  }) => {
    const hasDiscountedDelivery = discounts
      .filter((d) => !d._destroy)
      .some((d) => d.kind === 'free_delivery')
    if (hasDiscountedDelivery) {
      isFreeDelivery = true // has discounted delivery can only make it free
    }
    let serviceFee = this.calculateServiceFee({
      clientSetUpTime,
      needsStaffing,
      isDeliveryFeeOverride,
      isVCX,
      subtotal,
      cleanupFee,
      deliveryFee,
      numberOfStaff,
      staffingHours,
      deliveryFeePercent,
      deliveryFeeLimit,
      staffingRate,
    })
    if (isFreeDelivery) {
      serviceFee -= this.calculateDeliveryFee({
        clientSetUpTime,
        isDeliveryFeeOverride,
        isVCX,
        subtotal,
        deliveryFee,
        deliveryFeePercent,
        deliveryFeeLimit,
      })
    }

    return serviceFee
  }

  calculateDiscounts = ({
    subtotal,
    discounts,
    discountAmount,
    discountType,
  }) => {
    let totalDiscounts = 0.0
    let discountsNotZero = false
    // add discounts from api
    discounts &&
      discounts
        .filter((d) => !d._destroy)
        .forEach((discount) => {
          if (discount.kind === 'percentage') {
            totalDiscounts += roundMoneyUp(discount.value * subtotal)
          } else if (discount.value) {
            totalDiscounts += roundMoneyUp(discount.value)
          }
          discountsNotZero = true
        })
    // add front end discounts... these should not be different
    if (discountAmount) {
      if (discountType === 'flat') {
        totalDiscounts += roundMoneyUp(discountAmount)
      } else if (discountType === 'percent') {
        totalDiscounts +=
          discountAmount < 1
            ? roundMoneyUp(discountAmount * subtotal)
            : roundMoneyUp(subtotal * (discountAmount / 100))
      }
      discountsNotZero = true
    }
    // prevent discounts from being greater than subtotal
    if (totalDiscounts > subtotal) {
      totalDiscounts = subtotal
    }

    return discountsNotZero ? totalDiscounts : 0
  }

  prePopulateMenu = (
    mains,
    sides,
    isUpdatedStructure,
    isBYO,
    headCount,
    pricePerPersonData,
  ) => {
    let prePopulatedMains = []
    let prePopulatedSides = []
    const adjustedHeadCount = isNaN(Number(headCount)) ? 0 : Number(headCount)
    const {
      entreeProtein1,
      entreeProtein2,
      entreeVeg1,
      starchSide1,
      vegSide1,
    } = pricePerPersonData

    if (adjustedHeadCount && mains?.length > 0) {
      if (isBYO) {
        prePopulatedMains = deepCopy(mains).map((conceptItem) => {
          let parentCount = 0
          const { menuItem } = conceptItem
          parentCount = Math.ceil(adjustedHeadCount * BYO_MAIN_ITEM_PERCENT)
          if (menuItem.childItems) {
            const childItems = deepCopy(menuItem.childItems).map(
              (childItem) => {
                const { subMenuItemCategory } = childItem
                let childCount = Math.ceil(parentCount * BYO_CHILD_ITEM_PERCENT)
                // For some reason main subMenuItemCategory is "Main " in our system
                if (
                  subMenuItemCategory?.trim() === MAIN ||
                  subMenuItemCategory?.trim() === PROTEIN
                ) {
                  childCount = Math.ceil(
                    parentCount * BYO_MAIN_CHILD_ITEM_PERCENT,
                  )
                }
                childItem.quantity = childCount

                return childItem
              },
            )

            menuItem.childItems = childItems
          }
          menuItem.quantity = parentCount

          return menuItem
        })
      } else {
        const entreesSettings = [entreeProtein1, entreeProtein2, entreeVeg1]
        prePopulatedMains = deepCopy(mains).map((main) => {
          const entreeSetting = entreesSettings.find(
            (setting) => setting && setting.id === main.menuItem.id,
          )
          main.menuItem.quantity = entreeSetting
            ? Math.round(adjustedHeadCount * entreeSetting.ratio)
            : 0

          // default to item min qty if calc qty is less than min
          if (main.menuItem.quantity !== 0) {
            if (
              main.menuItem.minQty &&
              main.menuItem.quantity < main.menuItem.minQty
            ) {
              main.menuItem.quantity = main.menuItem.minQty
            }
          }

          return main.menuItem
        })
      }
    }

    if (sides?.length > 0) {
      const sidesSettings = [starchSide1, vegSide1]
      prePopulatedSides = deepCopy(sides).map((side) => {
        const sideSetting = sidesSettings.find(
          (setting) => setting && setting.id === side.menuItem.id,
        )
        side.menuItem.quantity = sideSetting
          ? Math.round(adjustedHeadCount * sideSetting.ratio)
          : 0

        // default to item min qty if calc qty is less than min
        if (side.menuItem.quantity !== 0) {
          if (
            side.menuItem.minQty &&
            side.menuItem.quantity < side.menuItem.minQty
          ) {
            side.menuItem.quantity = side.menuItem.minQty
          }
        }

        return side.menuItem
      })
    }

    return { prePopulatedMains, prePopulatedSides }
  }

  _sumItemsChefPrice = (items) => {
    let total = 0
    items &&
      items.forEach((item) => {
        const {
          chefPrice,
          cost,
          quantity,
          rateQuantity,
          childItems = [],
        } = item
        const childCosts = this._sumItemsChefPrice(childItems)
        const itemCost =
          (chefPrice === undefined || chefPrice === null ? cost : chefPrice) ||
          0
        const itemQuantity = quantity || 0
        const itemRateQty = rateQuantity || 1

        total += itemCost * itemQuantity * itemRateQty + childCosts
      })

    return total
  }

  _sumItemsPrice = (items) => {
    let total = 0
    items &&
      items.forEach((item) => {
        const { price, quantity, rateQuantity, childItems = [] } = item
        const childCosts = this._sumItemsPrice(childItems)
        const itemPrice = price || 0
        const itemQuantity = quantity || 0
        const itemRateQty = rateQuantity || 1

        // take total first if set explicity, but this isn't used currently
        total +=
          (item.total || itemPrice * itemQuantity * itemRateQty) + childCosts
      })

    return total
  }

  _sumMenuItemsTax = (
    items,
    discountFraction,
    taxRates,
    alcoholTaxRates,
    canHaveAlcohol,
  ) => {
    let tax = 0
    items &&
      items.forEach((item) => {
        const { price, quantity, rateQuantity, childItems = [] } = item
        let { total } = item
        const taxRatesToUse =
          canHaveAlcohol && item['containsAlcohol'] ? alcoholTaxRates : taxRates
        total = total || quantity * price * (rateQuantity || 1)
        total += this._sumItemsPrice(childItems)
        tax += this._taxWithTaxRates(total, discountFraction, taxRatesToUse)
      })

    return tax
  }

  _sumItemsTax = (
    items,
    discountFraction,
    taxRates,
    alcoholTaxRates,
    canHaveAlcohol,
  ) => {
    let tax = 0
    items &&
      items.forEach((item) => {
        const { price, quantity, rateQuantity } = item
        let { total } = item
        const taxRatesToUse =
          canHaveAlcohol && item['containsAlcohol'] ? alcoholTaxRates : taxRates
        total = total || quantity * price * (rateQuantity || 1)
        tax += this._taxWithTaxRates(total, discountFraction, taxRatesToUse)
      })

    return tax
  }

  _taxWithTaxRates = (price, discountFraction, taxRates) => {
    let tax = 0
    taxRates &&
      taxRates.forEach((taxRate) => {
        tax += roundMoneyUp(price * discountFraction * taxRate)
      })

    return tax
  }

  calculateSubtotal = ({ chefs }) => {
    let total = 0
    chefs.forEach((chef) => {
      total += this._sumItemsPrice(chef.orderMenuItems)
      total += this._sumItemsPrice(chef.orderServiceItems)
      total += this._sumItemsPrice(chef.orderVirtualItems)
      total += this._sumItemsPrice(chef.orderVirtualKits)
      total += this._sumItemsPrice(chef.orderSnackPacks)
      total += this._sumItemsPrice(chef.customOrderMenuItems)
      total += this._sumItemsPrice(chef.customOrderServiceItems)
      total += this._sumItemsPrice(chef.customOrderVirtualItems)
    })

    return total
  }

  calculateTax = ({
    clientSetUpTime,
    needsStaffing,
    chefs,
    deliveryFee,
    discountAmount,
    discountType,
    discounts,
    isDeliveryFeeOverride,
    isFreeDelivery,
    isTaxExempt,
    isVCX,
    subtotal,
    cleanupFee,
    numberOfStaff,
    staffingHours,
    staffingRate,
    taxRates = [],
    deliveryTaxRates = [],
    servicesTaxRates = [],
    alcoholTaxRates = [],
    deliveryFeePercent,
    deliveryFeeLimit,
  }) => {
    if (isTaxExempt) {
      return 0
    }

    // calculate pre-discount subtotal
    if (subtotal == null) {
      subtotal = this.calculateSubtotal({ chefs })
    }
    const isCompedMeal = discounts
      .filter((d) => !d._destroy)
      .some((d) => ['comped_meal', 'zero_total'].includes(d.kind))
    if (isCompedMeal) {
      subtotal = 0
    }
    const serviceFee = this.calculatePretotalServiceFee({
      clientSetUpTime,
      needsStaffing,
      isDeliveryFeeOverride,
      isFreeDelivery,
      isVCX,
      subtotal,
      cleanupFee,
      deliveryFee,
      numberOfStaff,
      staffingHours,
      discounts,
      deliveryFeePercent,
      deliveryFeeLimit,
      staffingRate,
    })

    const total = subtotal + serviceFee
    const totalDiscounts = this.calculateDiscounts({
      subtotal,
      discounts,
      discountAmount,
      discountType,
    })
    const calcDiscountRate = total > 0.0 ? (total - totalDiscounts) / total : 0
    const discountFraction = totalDiscounts > 0 ? calcDiscountRate : 1

    // calculate tax for each item
    let tax = this._taxWithTaxRates(
      serviceFee,
      discountFraction,
      deliveryTaxRates,
    )
    !isCompedMeal &&
      chefs.forEach((chef) => {
        tax += this._sumMenuItemsTax(
          chef.orderMenuItems,
          discountFraction,
          taxRates,
          alcoholTaxRates,
          true,
          'menuItem',
        )
        tax += this._sumItemsTax(
          chef.customOrderMenuItems,
          discountFraction,
          taxRates,
          alcoholTaxRates,
          true,
          'menuItem',
        )
        tax += this._sumItemsTax(
          chef.orderServiceItems,
          discountFraction,
          servicesTaxRates,
          null,
          false,
          'serviceItem',
        )
        tax += this._sumItemsTax(
          chef.customOrderServiceItems,
          discountFraction,
          servicesTaxRates,
          null,
          false,
          'serviceItem',
        )
        tax += this._sumItemsTax(
          chef.orderVirtualKits,
          discountFraction,
          taxRates,
          alcoholTaxRates,
          true,
          'virtualKit',
        )
        tax += this._sumItemsTax(
          chef.orderSnackPacks,
          discountFraction,
          taxRates,
          alcoholTaxRates,
          true,
          'snackPack',
        )
      })

    return tax
  }

  calculateTip = ({ percent, total }) => {
    total = Math.max(0, total)

    return roundMoneyUp((total * percent) / 100)
  }

  calculateTotal = ({ tax, tip = 0, total, discounts }) => {
    const taxAmt = discounts
      .filter((d) => !d._destroy)
      .some((d) => ['tax_exempt', 'zero_total'].includes(d.kind))
      ? 0
      : tax

    return Math.max(0, total + taxAmt + tip)
  }

  calculateTotalRevenue = ({
    total,
    tax,
    tip = 0,
    carbonNeutralContribution = 0,
  }) => {
    return total - tax - tip - carbonNeutralContribution
  }

  calculateGrossProfit = ({
    adjustedChefPayouts,
    total,
    tax,
    tip = 0,
    chefs,
    predictedServiceCosts,
    suppliesCost,
    carbonNeutralContribution = 0,
  }) => {
    return (
      this.calculateTotalRevenue({
        total,
        tax,
        tip,
        carbonNeutralContribution,
      }) -
      this.calculateChefAmount({ adjustedChefPayouts, chefs }) -
      suppliesCost -
      predictedServiceCosts
    )
  }

  calculateGrossMargin = ({
    adjustedChefPayouts,
    chefs,
    total,
    tax,
    tip = 0,
    predictedServiceCosts,
    suppliesCost,
    carbonNeutralContribution = 0,
  }) => {
    const grossProfitMargin = this.calculateGrossProfit({
      adjustedChefPayouts,
      total,
      tax,
      tip,
      chefs,
      predictedServiceCosts,
      suppliesCost,
      carbonNeutralContribution,
    })
    const totalRevenue = this.calculateTotalRevenue({
      total,
      tax,
      tip,
      carbonNeutralContribution,
    })

    return roundMoneyUp((grossProfitMargin / totalRevenue) * 100)
  }

  calculateAll = ({
    adjustedChefPayouts,
    alcoholTaxRates,
    chefs,
    chefAmount,
    clientSetUpTime,
    deliveryFee,
    deliveryTaxRates,
    discount,
    discounts,
    didModify,
    isDeliveryFeeOverride,
    isFreeDelivery,
    isTaxExempt,
    isVCX,
    orderType,
    cleanupFee = 0,
    numberOfStaff,
    staffingHours,
    staffingRate,
    serviceFee = 0,
    servicesTaxRates,
    subtotal,
    tax,
    taxRates,
    tip = 0,
    total,
    carbonNeutralContribution = 0,
    deliveryFeePercent,
    deliveryFeeLimit,
    needsStaffing,
  }) => {
    const { amount: discountAmount = 0, type: discountType = '' } =
      discount || {}
    const isFreeOrder = ['Tasting', 'Hungry HQ Tasting', 'Comped'].includes(
      orderType,
    )
    if (isFreeOrder) {
      subtotal = 0
      serviceFee = 0
    } else if (didModify) {
      subtotal = this.calculateSubtotal({ chefs })
      serviceFee = this.calculateServiceFee({
        clientSetUpTime,
        needsStaffing,
        isDeliveryFeeOverride,
        isVCX,
        subtotal,
        cleanupFee,
        deliveryFee,
        numberOfStaff,
        staffingHours,
        staffingRate,
        deliveryFeePercent,
        deliveryFeeLimit,
      })
    }
    const preTaxTotal = isFreeOrder
      ? 0
      : this.calculatePreTaxTotal({
          clientSetUpTime,
          needsStaffing,
          chefs,
          deliveryFee,
          discountAmount,
          discountType,
          discounts,
          subtotal,
          isDeliveryFeeOverride,
          isFreeDelivery,
          isVCX,
          cleanupFee,
          numberOfStaff,
          staffingHours,
          staffingRate,
          carbonNeutralContribution,
          deliveryFeePercent,
          deliveryFeeLimit,
        })
    if (didModify) {
      chefAmount = this.calculateChefAmount({ adjustedChefPayouts, chefs })
      tax = isFreeOrder
        ? 0
        : this.calculateTax({
            clientSetUpTime,
            needsStaffing,
            chefs,
            deliveryFee,
            didModify,
            discountAmount,
            discountType,
            discounts,
            isDeliveryFeeOverride,
            isFreeDelivery,
            isTaxExempt,
            isVCX,
            subtotal,
            cleanupFee,
            numberOfStaff,
            staffingHours,
            staffingRate,
            taxRates,
            servicesTaxRates,
            deliveryTaxRates,
            alcoholTaxRates,
            deliveryFeePercent,
            deliveryFeeLimit,
          })
      total = this.calculateTotal({ tax, tip, total: preTaxTotal, discounts })
    }

    return {
      chefAmount,
      cleanupFee,
      preTaxTotal,
      serviceFee,
      subtotal,
      tax,
      total,
    }
  }

  filterMenuItems = (menuItems, search) => {
    const safeSearchStr = escapeRegex(search.trim())

    return menuItems.filter((i) => new RegExp(safeSearchStr, 'i').test(i.name))
  }

  updateTaxExemptStatus = ({
    contact = {},
    account = {},
    orderable = {},
    newState,
  }) => {
    const { purchaserTaxStatus } = contact || {}
    const { isTaxExempt } = account || {}
    const { orderTaxExempt, orderTaxExemptReason } = orderable || {}
    if (orderTaxExempt) {
      newState.isTaxExempt = true
      newState.orderTaxExemptCause = orderTaxExemptReason
    } else if (
      purchaserTaxStatus !== null &&
      purchaserTaxStatus !== undefined
    ) {
      newState.isTaxExempt = !purchaserTaxStatus
      newState.orderTaxExemptCause = 'Invoice Contact'
    } else {
      newState.isTaxExempt = isTaxExempt
      newState.orderTaxExemptCause = 'Account'
    }
  }

  clearedChefAlertOrder = () => {
    let { order } = this.getState()
    order = {
      ...order,
      chefAlerts: [],
      needAlertChef: false,
    }

    return order
  }

  clearedServiceAlertOrder = () => {
    let { order } = this.getState()
    order = {
      ...order,
      needServiceDetails: false,
    }

    return order
  }

  clearedFinalizedInvoiceSentOrder = () => {
    let { order } = this.getState()
    order = {
      ...order,
      isInvoiceFinalized: true,
    }

    return order
  }

  updatedChefAlertOrder = (chefId) => {
    let { order } = this.getState()
    const chefAlerts = order.chefAlerts.filter((c) => c !== chefId)
    order = {
      ...order,
      chefAlerts,
      needAlertChef: chefAlerts.length > 0,
    }

    return order
  }

  clientOrderReminderSent = () => {
    let { order } = this.getState()
    order = {
      ...order,
      clientOrderReminderLastSentStr: Moment().format(
        'dddd, MMMM Do YYYY, h:mm a',
      ),
    }

    return order
  }

  autoClientOrderReminderSet = (enabled) => {
    let { order } = this.getState()
    order = {
      ...order,
      autoClientOrderReminder: enabled,
    }

    return order
  }
}
