import { connect } from 'react-redux'

import coordinators from '@coordinators'
import services from '@services'
import { HandleError } from '@coordinators/composed'

import { PopUpNotificationsModal } from '@components/popUps'

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = () => {
  const { RestService, UIService } = services
  const {
    SendPopUpCancelled,
    SendPopUpDelayed,
    SendPopUpNewLocation,
    SendPopUpNewMenu,
    SendPopUpNewChef,
    SendPopUpSoldOut,
    SendPopUpAlmostSoldOut,
    GetTargetCustomersCount,
  } = coordinators

  const sendPopUpCancelled = SendPopUpCancelled({
    RestService,
    UIService,
    HandleError,
  })

  const sendPopUpDelayed = SendPopUpDelayed({
    RestService,
    UIService,
    HandleError,
  })

  const sendPopUpNewLocation = SendPopUpNewLocation({
    RestService,
    UIService,
    HandleError,
  })

  const sendPopUpNewMenu = SendPopUpNewMenu({
    RestService,
    UIService,
    HandleError,
  })

  const sendPopUpNewChef = SendPopUpNewChef({
    RestService,
    UIService,
    HandleError,
  })

  const sendPopUpSoldOut = SendPopUpSoldOut({
    RestService,
    UIService,
    HandleError,
  })

  const sendPopUpAlmostSoldOut = SendPopUpAlmostSoldOut({
    RestService,
    UIService,
    HandleError,
  })

  const getTargetCustomersCount = GetTargetCustomersCount({
    RestService,
    UIService,
    HandleError,
  })

  return {
    sendPopUpCancelled,
    sendPopUpDelayed,
    sendPopUpAlmostSoldOut,
    sendPopUpNewChef,
    sendPopUpNewLocation,
    sendPopUpSoldOut,
    sendPopUpNewMenu,
    getTargetCustomersCount,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PopUpNotificationsModal)
