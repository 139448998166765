import editCoordinators from './edit'
import * as emailCoordinators from './email'
import * as orderCoordinators from './order'
import * as paymentCoordinators from './payment'

export default Object.freeze({
  ...editCoordinators,
  ...emailCoordinators,
  ...orderCoordinators,
  ...paymentCoordinators,
})
