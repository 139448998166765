import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import Button from '@components/common/form/Button'
import YSpacing from '@components/common/YSpacing'
import Panel from '@components/common/Panel'

import { DateTextInput } from '@components/common/form'
import ExportCSVButton from '../dashboard/ExportCSVButton'
import { AutocompleteInput } from '@containers/common/form'
import LoadingIndicator from '../common/LoadingIndicator'

const DateFormat = 'MM/DD/YYYY h:mm a'

const ordersHeaders = [
  { key: 'Order Number' },
  { key: 'Pop-Up Date' },
  { key: 'Pop-Up Number' },
  { key: 'Client Name' },
  { key: 'Customer Email' },
  { key: 'Subtotal' },
  { key: 'Discount' },
  { key: 'Tax' },
  { key: 'Tip' },
  { key: 'Total' },
]

const itemsHeaders = [
  { key: 'Name' },
  { key: 'Description' },
  { key: 'Quantity' },
  { key: 'Price' },
  { key: 'Chef Price' },
  { key: 'Tax Code' },
  { key: 'Item Type' },
  { key: 'Supplier Type' },
  { key: 'Order Number' },
  { key: 'Created At' },
  { key: 'Date' },
  { key: 'Pop-Up Number' },
  { key: 'Chef Name' },
  { key: 'Pre-Order Status' },
  { key: 'Client Name' },
  { key: 'Customer Email' },
  { key: 'Total' },
]

const REPORT_TYPE = {
  ORDERS: 'orders',
  ITEMS_ORDERED: 'itemsOrdered',
}

class PopUpAdminPage extends Component {
  state = {
    marginReportStart: Moment.tz(this.props.locale),
    marginReportEnd: Moment.tz(this.props.locale),
    ordersCSVStart: Moment.tz(this.props.locale),
    ordersCSVEnd: Moment.tz(this.props.locale),
    ordersInDateRange: [],
    itemsOrderedInDateRange: [],
    numberOfOrders: 0,
    client: { id: null },
    isLoading: false,
    reportType: REPORT_TYPE.ORDERS,
  }

  componentDidMount = () => {
    this.searchPopUps()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.ordersCSVStart !== this.state.ordersCSVStart ||
      prevState.ordersCSVEnd !== this.state.ordersCSVEnd ||
      prevState.client.id !== this.state.client.id ||
      prevState.reportType !== this.state.reportType
    ) {
      this.searchPopUps()
    }
  }

  formatCurrency = (num) => `$${num.toFixed(2)}`

  searchPopUps = async () => {
    this.setState({ isLoading: true })
    const { ordersCSVStart, ordersCSVEnd, client } = this.state

    if (this.state.reportType === REPORT_TYPE.ORDERS) {
      const orders = await this.props.searchPopUpsByDateRange({
        startDate: ordersCSVStart,
        endDate: ordersCSVEnd,
        clientId: client.id,
      })

      if (orders) {
        const ordersMap = orders.map((order) => {
          order['Order Number'] = order.number
          order['Pop-Up Date'] = order.popUpDate
          order['Pop-Up Number'] = order.popUpNumber
          order['Client Name'] = order.client.name
          order['Customer Email'] = order.customer.email
          order['Subtotal'] = this.formatCurrency(+order.subtotal)
          order['Discount'] = this.formatCurrency(+order.discount)
          order['Tax'] = this.formatCurrency(+order.tax)
          order['Tip'] = this.formatCurrency(+order.tip)
          order['Total'] = this.formatCurrency(+order.total)

          return order
        })

        this.setState({ ordersInDateRange: ordersMap, isLoading: false })
      }
    } else {
      const orders = await this.props.searchPopUpsByDateRange({
        startDate: ordersCSVStart,
        endDate: ordersCSVEnd,
        clientId: client.id,
      })

      if (orders) {
        const ordersMap = orders.map((order) => {
          const items = order.orderItems.map((item) => {
            item['Name'] = item.name
            item['Description'] = item.description
            item['Quantity'] = item.quantity
            item['Price'] = this.formatCurrency(+item.price)
            item['Chef Price'] = this.formatCurrency(+item.chefPrice)
            item['Tax Code'] = item.taxCode || 'none'
            item['Item Type'] = item.itemType
            item['Supplier Type'] = item.supplierType
            item['Order Number'] = order.number
            item['Created At'] = order.createdAt
            item['Date'] = order.date
            item['Pop-Up Number'] = order.popUpNumber
            item['Chef Name'] = order.chefName
            item['Pre-Order Status'] = order.preOrderStatus
            item['Client Name'] = order.client.name
            item['Customer Email'] = order.customer.email
            item['Total'] = this.formatCurrency(+order.total)

            return item
          })

          return items
        })

        this.setState({
          itemsOrderedInDateRange: ordersMap.flat(),
          isLoading: false,
          numberOfOrders: orders.length,
        })
      }
    }
  }

  onSendMarginReport = () => {
    const { marginReportStart, marginReportEnd } = this.state
    if (this.props.sendPopUpMarginReport(marginReportStart, marginReportEnd)) {
      this.setState({ start: undefined, end: undefined })
    }
  }

  onSelectClient = (client) => {
    this.setState({ client })
  }

  render() {
    const { locale, headquarter } = this.props
    const {
      marginReportStart,
      marginReportEnd,
      ordersCSVStart,
      ordersCSVEnd,
      ordersInDateRange,
      itemsOrderedInDateRange,
      numberOfOrders,
    } = this.state

    return (
      <div className="flex-col">
        <Panel width="100%" maxWidth="500px" heading="Generate Margin Report">
          <DateTextInput
            label="Start Time"
            date={marginReportStart}
            onChange={(date) => this.setState({ marginReportStart: date })}
            dateFormat={DateFormat}
            timeZone={locale}
          />
          <YSpacing height="20px" />
          <DateTextInput
            label="End Time"
            date={marginReportEnd}
            onChange={(date) => this.setState({ marginReportEnd: date })}
            dateFormat={DateFormat}
            timeZone={locale}
          />
          <YSpacing height="20px" />
          {marginReportStart && marginReportEnd && (
            <Button
              label="Send Margin Report"
              onClick={this.onSendMarginReport}
            />
          )}
        </Panel>
        <Panel width="100%" maxWidth="500px" heading="Orders Report">
          <select
            onChange={(e) => this.setState({ reportType: e.target.value })}
          >
            <option value={REPORT_TYPE.ORDERS}>Orders</option>
            <option value={REPORT_TYPE.ITEMS_ORDERED}>Items ordered</option>
          </select>
          <YSpacing height="20px" />
          <DateTextInput
            label="Start Time"
            date={ordersCSVStart}
            onChange={(date) => this.setState({ ordersCSVStart: date })}
            dateFormat={DateFormat}
            timeZone={locale}
          />
          <YSpacing height="20px" />
          <DateTextInput
            label="End Time"
            date={ordersCSVEnd}
            onChange={(date) => this.setState({ ordersCSVEnd: date })}
            dateFormat={DateFormat}
            timeZone={locale}
          />
          <YSpacing height="20px" />
          <AutocompleteInput
            displayAttribute="name"
            loaderFunction={(search) =>
              this.props.searchPopUpClients({ ...search, headquarter })
            }
            placeholder={'Search Clients'}
            onSelect={this.onSelectClient}
          />

          {this.state.client.id && (
            <div>
              <YSpacing height="20px" />
              <Button
                onClick={() => this.setState({ client: { id: null } })}
                label="Clear client"
              />
            </div>
          )}

          <YSpacing height="20px" />
          <span className="mr-4">
            Found&nbsp;
            {this.state.reportType === REPORT_TYPE.ORDERS
              ? ordersInDateRange.length
              : numberOfOrders}
            &nbsp;
            {this.state.reportType === REPORT_TYPE.ORDERS
              ? ordersInDateRange.length !== 1
                ? 'orders'
                : 'order'
              : numberOfOrders !== 1
                ? 'orders'
                : 'order'}
            {this.state.reportType === REPORT_TYPE.ITEMS_ORDERED
              ? ' with ' + itemsOrderedInDateRange.length
              : null}
            &nbsp;
            {this.state.reportType === REPORT_TYPE.ITEMS_ORDERED
              ? itemsOrderedInDateRange.length !== 1
                ? 'items'
                : 'item'
              : null}
          </span>
          <YSpacing height="20px" />
          {this.state.isLoading ? <LoadingIndicator /> : null}
          <ExportCSVButton
            data={
              this.state.reportType === REPORT_TYPE.ORDERS
                ? ordersInDateRange
                : itemsOrderedInDateRange
            }
            columns={
              this.state.reportType === REPORT_TYPE.ORDERS
                ? ordersHeaders
                : itemsHeaders
            }
            filename="hungry-dashboard-pop-ups.csv"
            pCSVItems={(order) => order}
            className="export-csv-button"
          />
        </Panel>
      </div>
    )
  }
}

PopUpAdminPage.propTypes = {
  locale: PropTypes.string,
  headquarter: PropTypes.number,
  theme: PropTypes.object,

  sendPopUpMarginReport: PropTypes.func,
  searchPopUpsByDateRange: PropTypes.func,
  searchPopUpClients: PropTypes.func,
}

export default PopUpAdminPage
