import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { AutocompleteInput } from '@containers/common/form'
import { colors } from '../../../constants'
import { YSpacing, FlexContainer } from '@components/common'
import { Dropdown, Input } from '@components/common/form'
import { Label, MultiSelectOption } from '@res/styledComponents/index'

let timer

const callAfterTimeout = (func, delay = 3000) => {
  if (timer) {
    clearTimeout(timer)
  }
  timer = setTimeout(func, delay)
}

const Filters = (props) => {
  const {
    loadChefs,
    loadHeadquarters,
    loadMenuItemSettings,
    onChange,

    filterConfigs,
    numResults,
    userId,
    userHeadquarters,
  } = props

  const [filters, setFilters] = useState({})
  const [multiSelectOptions, setMultiSelectOptions] = useState({})

  useEffect(() => {
    onLoadHeadquarters()
  }, [userId])

  useEffect(() => {
    onLoadHeadquarters()
    if (filterConfigs && filterConfigs.length > 0) {
      onLoadConfigs(filterConfigs)
    }
  }, [filterConfigs])

  // load multiselect opts
  useEffect(() => {
    const loadMultiSelectOptions = async () => {
      if (
        userHeadquarters.length > 0 &&
        Object.keys(multiSelectOptions).length === 0
      ) {
        const {
          allDefaultPackagings,
          allDietaryPreferences,
          allMealTypes,
          allMarketTypes,
        } = await loadMenuItemSettings()
        setMultiSelectOptions({
          packaging: allDefaultPackagings.map((p) => p.name),
          allergens: allDietaryPreferences,
          mealType: allMealTypes,
          marketTypes: allMarketTypes,
          hqs: userHeadquarters.map((hq) => ({ value: hq.id, label: hq.name })),
        })
      }
    }
    loadMultiSelectOptions()
  }, [userHeadquarters])

  const onLoadConfigs = (filterConfigs) => {
    const initFilters = filterConfigs
      .filter((c) => c.default)
      .reduce((obj, c) => {
        obj[c.key] = c.default

        return obj
      }, {})
    setFilters(initFilters)
    onChange && onChange(initFilters)
  }

  const onLoadHeadquarters = async () => {
    await loadHeadquarters(userId)
  }

  const onChangeFilter = (key, value, delay = 0) => {
    const newFilters = { ...filters, [key]: value }
    setFilters(newFilters)
    if (onChange) {
      if (delay === 0) {
        onChange(newFilters)
      } else {
        callAfterTimeout(() => onChange(newFilters), delay)
      }
    }
  }

  const onChangeMultiSelectFilter = (key, value) => {
    const newFilters = { ...filters }
    if (filters[key]) {
      newFilters[key].push(value)
    } else {
      newFilters[key] = [value]
    }
    setFilters(newFilters)
    onChange(newFilters)
  }

  const onRemoveMultiSelectOption = (key, index) => {
    const newFilters = { ...filters }
    newFilters[key].splice(index, 1)
    // delete name key saved for filter
    if (newFilters[`name_${key}`]) {
      newFilters[`name_${key}`].splice(index, 1)
    }
    setFilters(newFilters)
    onChange(newFilters)
  }

  const onChangeMultiAutocompleteFilter = (key, value) => {
    const valueId = value.id
    const valueName = value.name

    const newFilters = { ...filters }
    if (filters[key]) {
      newFilters[key].push(valueId)
      newFilters[`name_${key}`].push(valueName)
      setFilters(newFilters)
      onChange(newFilters)
    } else {
      newFilters[key] = []
      newFilters[key].push(valueId)
      newFilters[`name_${key}`] = []
      newFilters[`name_${key}`].push(valueName)
      setFilters(newFilters)
      onChange(newFilters)
    }
  }

  const chefFilter = (filterKey) => {
    return (
      <div>
        <AutocompleteInput
          label="Chef"
          loaderFunction={(search) => loadChefs({ ...search })}
          value=""
          alreadySelectedIDs={filters[filterKey]}
          onSelect={(value) =>
            onChangeMultiAutocompleteFilter(filterKey, value, `${value.name}`)
          }
        />
        <YSpacing height="5px" />
        <FlexContainer flexWrap="wrap">
          {filters[`name_${filterKey}`] &&
            filters[`name_${filterKey}`].map((filter, i) => (
              <MultiSelectOption
                key={filter}
                onClick={() => onRemoveMultiSelectOption(filterKey, i)}
              >
                {filter}
              </MultiSelectOption>
            ))}
        </FlexContainer>
      </div>
    )
  }

  const cityFilter = (filterKey, filterName) => {
    const { cityLocations } = props
    // need to add id and name for autocompleteinput
    const locations = cityLocations.map((city) => {
      return {
        id: city,
        name: city,
      }
    })

    const searchLocations = (value) => {
      const { search } = value
      if (search.length > 0) {
        const matcher = new RegExp(search, 'i')

        return locations.filter((location) => location.name.match(matcher))
      } else {
        return locations
      }
    }

    return (
      <div>
        <AutocompleteInput
          label={filterName}
          loaderFunction={(value) => searchLocations(value)}
          value=""
          alreadySelectedIDs={filters[filterKey]}
          onSelect={(value) =>
            onChangeMultiAutocompleteFilter(filterKey, value, `${value.name}`)
          }
        />
        <YSpacing height="5px" />
        <FlexContainer flexWrap="wrap">
          {filters[`name_${filterKey}`] &&
            filters[`name_${filterKey}`].map((filter, i) => (
              <MultiSelectOption
                key={filter}
                onClick={() => onRemoveMultiSelectOption(filterKey, i)}
              >
                {filter}
              </MultiSelectOption>
            ))}
        </FlexContainer>
      </div>
    )
  }

  const multiSelectFilter = (filterKey, name) => {
    const options = multiSelectOptions[filterKey]
    let notSelectedOptions = []
    if (options && filters[filterKey]) {
      notSelectedOptions = options.filter(
        (x) => !filters[filterKey].includes(x),
      )
    } else if (options) {
      notSelectedOptions = options
    }

    return (
      <div>
        <Dropdown
          label={name}
          width="100%"
          value=""
          defaultValue=""
          marginBottom="0"
          onChange={(e) => onChangeMultiSelectFilter(filterKey, e.target.value)}
        >
          <option value="" disabled hidden>
            - Select -
          </option>
          {notSelectedOptions.map((o) => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </Dropdown>
        <YSpacing height="5px" />
        <FlexContainer flexWrap="wrap">
          {filters[filterKey] &&
            filters[filterKey].map((selected, i) => {
              const option = options.find((o) => o === selected)
              if (option) {
                return (
                  <MultiSelectOption
                    key={selected}
                    onClick={() => onRemoveMultiSelectOption(filterKey, i)}
                  >
                    {option}
                  </MultiSelectOption>
                )
              }
            })}
        </FlexContainer>
      </div>
    )
  }

  const multiSelectObjsFilter = (filterKey, name) => {
    const options = multiSelectOptions[filterKey]
    let notSelectedOptions = []
    if (options && filters[filterKey]) {
      notSelectedOptions = options.filter(
        (x) => !filters[filterKey].includes(x.value.toString()),
      )
    } else if (options) {
      notSelectedOptions = options
    }

    return (
      <div>
        <Dropdown
          label={name}
          width="100%"
          value=""
          defaultValue=""
          marginBottom="0"
          onChange={(e) => onChangeMultiSelectFilter(filterKey, e.target.value)}
        >
          <option value="" disabled hidden>
            - Select -
          </option>
          {notSelectedOptions.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </Dropdown>
        <YSpacing height="5px" />
        <FlexContainer flexWrap="wrap">
          {filters[filterKey] &&
            filters[filterKey].map((filterVal, i) => {
              const option = options.find(
                (o) => o.value.toString() === filterVal,
              )
              if (option) {
                return (
                  <MultiSelectOption
                    key={filterVal}
                    onClick={() => onRemoveMultiSelectOption(filterKey, i)}
                  >
                    {option.label}
                  </MultiSelectOption>
                )
              }
            })}
        </FlexContainer>
      </div>
    )
  }

  const dropdownFilter = (filterKey, label, options) => {
    return (
      <Dropdown
        label={label}
        width="100%"
        marginBottom="0"
        value={filters[filterKey] || ''}
        onChange={(e) => onChangeFilter(filterKey, e.target.value)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Dropdown>
    )
  }

  const textFilter = (filterKey, label = '') => {
    return (
      <Input
        type="text"
        pattern="[0-9]*"
        marginBottom="0"
        label={label}
        value={filters[filterKey] || ''}
        onChange={(e) => onChangeFilter(filterKey, e.target.value, 500)}
      />
    )
  }

  const searchFilter = (filterKey, label) => {
    return (
      <Input
        type="text"
        pattern="[0-9]*"
        marginBottom="0"
        label={label}
        value={filters[filterKey] || ''}
        onChange={(e) => onChangeFilter(filterKey, e.target.value, 500)}
      />
    )
  }

  const priceFilter = (filterKey, label) => {
    return (
      <FlexContainer justifyContent="space-between">
        <Input
          type="number"
          width="95px"
          pattern="[0-9]*"
          marginBottom="0"
          label={`From ${label.replace('price', '')}`}
          value={filters[`${filterKey}From`] || ''}
          onChange={(e) =>
            onChangeFilter(`${filterKey}From`, e.target.value, 500)
          }
        />
        <Input
          type="number"
          width="95px"
          pattern="[0-9]*"
          marginBottom="0"
          label={`To ${label.replace('price', '$')}`}
          value={filters[`${filterKey}To`] || ''}
          onChange={(e) =>
            onChangeFilter(`${filterKey}To`, e.target.value, 500)
          }
        />
      </FlexContainer>
    )
  }

  const pageFilter = () => {
    const filterKey = 'page'
    const pageNum = parseInt(filters[filterKey])

    return (
      <FlexContainer flexDirection="column">
        <Label>Page</Label>
        <FlexContainer>
          {pageNum > 1 && (
            <button
              style={{ padding: '0 5px' }}
              onClick={() => onChangeFilter(filterKey, pageNum - 1, 200)}
            >
              ◂
            </button>
          )}
          <p>{pageNum}</p>
          {numResults === parseInt(filters['resultsPerPage']) && (
            <button
              style={{ padding: '0 5px' }}
              onClick={() => onChangeFilter(filterKey, pageNum + 1, 200)}
            >
              ▸
            </button>
          )}
        </FlexContainer>
      </FlexContainer>
    )
  }

  const limitFilter = (filterKey) => {
    return (
      <Dropdown
        label="limit"
        width="100%"
        marginBottom="0"
        value={filters[filterKey]}
        onChange={(e) => onChangeFilter(filterKey, e.target.value)}
      >
        {[50, 100, 200, 250].map((count) => (
          <option key={count} value={count}>
            {count}
          </option>
        ))}
      </Dropdown>
    )
  }

  const renderFilter = (config) => {
    let filter
    switch (config.name) {
      case 'search menu items':
        filter = searchFilter(config.key, config.name)
        break
      case 'chef':
        filter = chefFilter(config.key)
        break
      case 'chef status':
        filter = dropdownFilter(config.key, config.label, config.options)
        break
      case 'item pickup city':
        filter = cityFilter(config.key, config.name)
        break
      case 'chef price':
        filter = priceFilter(config.key, config.name)
        break
      case 'market price':
        filter = priceFilter(config.key, config.name)
        break
      case 'approved':
        filter = dropdownFilter(config.key, config.label, config.options)
        break
      case 'packaging':
        filter = multiSelectFilter(config.key, config.name)
        break
      case 'dish type':
        filter = multiSelectFilter(config.key, config.name)
        break
      case 'market types':
        filter = multiSelectFilter(config.key, config.name)
        break
      case 'allergens':
        filter = multiSelectFilter(config.key, config.name)
        break
      case 'dropdown':
        filter = dropdownFilter(config.key, config.label, config.options)
        break
      case 'text':
        filter = textFilter(config.key, config.label)
        break
      case 'page':
        filter = pageFilter(config.key)
        break
      case 'resultsPerPage':
        filter = limitFilter(config.key)
    }

    return (
      <div>
        {filter}
        {filter && <YSpacing height="20px" />}
      </div>
    )
  }

  return (
    <Panel width="250px">
      <div>
        {multiSelectObjsFilter('hqs', 'Markets')}
        {filterConfigs && filterConfigs.map((config) => renderFilter(config))}
        <label>Results ({numResults})</label>
      </div>
    </Panel>
  )
}

const Panel = styled.div`
  -webkit-column-break-inside: avoid;
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  page-break-inside: avoid;
  height: 500px;
  overflow-y: scroll;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 4px;
  /* display: inline-flex;
   flex-direction: column; */
  padding: 25px;
  position: relative;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  .sidebar-body-heading {
    color: ${colors.orange};
    font-family: 'bold';
    text-transform: uppercase;
  }
`

Filters.propTypes = {
  filterConfigs: PropTypes.array,
  cityLocations: PropTypes.array,
  userHeadquarters: PropTypes.arrayOf(PropTypes.object),
  numResults: PropTypes.number,
  userId: PropTypes.string,

  loadChefs: PropTypes.func,
  loadHeadquarters: PropTypes.func,
  loadMenuItemSettings: PropTypes.func,
  onChange: PropTypes.func,
}

export default Filters
