import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { OrderablePage } from '@components/orderable'

import { ProposalDetail, ProposalMaster } from '@containers/proposal'

class ProposalPage extends Component {
  render() {
    const {
      checkQueryProposal,
      filters,
      orderableType,
      proposalNumber,
      newProposal,
    } = this.props

    return (
      <OrderablePage
        initialFilter={'created'}
        filters={filters}
        orderableDetail={<ProposalDetail />}
        orderableMaster={(props) => <ProposalMaster {...props} />}
        orderableNumber={proposalNumber}
        orderableType={orderableType}
        checkQueryOrderable={checkQueryProposal}
        newOrderable={newProposal}
      />
    )
  }
}

ProposalPage.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string),
  orderableType: PropTypes.string,
  proposalNumber: PropTypes.string,

  checkQueryProposal: PropTypes.func,
  newProposal: PropTypes.func,
}

export default ProposalPage
