import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab } from 'react-bootstrap'
import AccountsReceivableTab from '@containers/dashboard/AccountsReceivableTab'
import AuthorizedDisplay from '@containers/common/auth/AuthorizedDisplay'

import { ARRoles } from '@constants'

class DashboardPage extends Component {
  state = {
    activeKey: '#accountsReceivable',
  }

  componentDidMount() {
    this.setState({ activeKey: '#accountsReceivable' })
  }

  render() {
    const { activeKey } = this.state

    return (
      <div className="children-container__inner dashboard-container">
        <h2 className="page-title"> Dashboard </h2>
        <Tabs
          className="tabs"
          activeKey={activeKey}
          onSelect={(key) => {
            this.setState({ activeKey: key })
          }}
          mountOnEnter={true}
          unmountOnExit={true}
          animation={false}
        >
          <Tab eventKey={'#accountsReceivable'} title="Accounts Receivable">
            <AuthorizedDisplay roles={ARRoles}>
              <AccountsReceivableTab />
            </AuthorizedDisplay>
          </Tab>
        </Tabs>
      </div>
    )
  }
}

DashboardPage.propTypes = {
  tab: PropTypes.string,
  accountName: PropTypes.string,
  salesRepId: PropTypes.string,
}

export default DashboardPage
