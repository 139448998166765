import React from 'react'
import PropTypes from 'prop-types'

const sidebarColor = {
  background: '-webkit-linear-gradient(top, #ff624d 0%, #ee346d 100%)',
}
const proposalSidebarColor = {
  background: '-webkit-linear-gradient(top, #ee346d 0%, #00acc1 100%)',
}
const Sidebar = (props) => {
  const { children, heading } = props

  return (
    <div
      className="sidebar"
      style={heading === 'Proposals' ? proposalSidebarColor : sidebarColor}
    >
      <h2>{heading}</h2>
      {children}
    </div>
  )
}

Sidebar.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node,
}

export default Sidebar
