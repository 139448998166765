import axios from 'axios'
import { Recipe, RecipeSnakeCase } from '@types'

interface EditRecipeCoordinator {
  ChefService: {
    setEditRecipe: (recipe: Recipe) => void
  }
  RestService: {
    get: (path: string) => Promise<RecipeSnakeCase>
  }
  UIService: {
    EditRecipe: {
      show: () => void
    }
  }
  pResponseGeneric: (recipe: RecipeSnakeCase) => Recipe
  HandleError: (val: { error: unknown }) => void
}

export const EditRecipe =
  ({
    ChefService,
    RestService,
    UIService,
    pResponseGeneric,
    HandleError,
  }: EditRecipeCoordinator) =>
  async (recipeId: string) => {
    try {
      const recipe = await RestService.get(
        `/api/admin/chef_recipes/${recipeId}`,
      )
      ChefService.setEditRecipe(pResponseGeneric(recipe))
      UIService.EditRecipe.show()
    } catch (error) {
      HandleError({ error })
    }
  }

interface StateRes {
  chef: { id: string; name: string }
  editRecipe: { id: string }
}

interface NewRecipeCoordinator {
  ChefService: {
    clearEditRecipe: () => void
    getState: () => StateRes
    setEditRecipe: (recipe: Recipe) => void
  }
  UIService: {
    EditRecipe: {
      show: () => void
    }
  }
  pDefaultRecipeFields: (chef: { id: string; name: string }) => Recipe
  HandleError: (val: { error: unknown }) => void
}

export const NewRecipe =
  ({ ChefService, UIService, pDefaultRecipeFields }: NewRecipeCoordinator) =>
  () => {
    const { chef, editRecipe } = ChefService.getState()
    if (editRecipe.id) {
      ChefService.clearEditRecipe()
    }
    if (chef) {
      ChefService.setEditRecipe(pDefaultRecipeFields(chef))
      UIService.EditRecipe.show()
    }
  }

interface LoadRecipesCoordinator {
  ChefService: {
    clearEditRecipe: () => void
    getState: () => StateRes
    setRecipes: (recipes: Recipe[]) => void
  }
  RestService: {
    get: <T>(
      path: string,
      params: Record<string, string>,
      timeout: { timeout: number },
    ) => Promise<T>
  }
  pResponseGeneric: (recipes: RecipeSnakeCase[]) => Recipe[]
  HandleError?: (val: { error: unknown }) => void
}

export const LoadRecipes =
  ({
    ChefService,
    RestService,
    pResponseGeneric,
    HandleError,
  }: LoadRecipesCoordinator) =>
  async (chefId: string) => {
    try {
      const response = await RestService.get<RecipeSnakeCase[]>(
        `/api/admin/chef_recipes`,
        { chef_id: chefId },
        { timeout: 20000 },
      )
      const recipes = pResponseGeneric(response)

      ChefService.setRecipes(recipes)
    } catch (error) {
      HandleError?.({ error })
    }
  }

interface DeleteRecipesCoordinator {
  ChefService: {
    clearEditRecipe: () => void
    getState: () => StateRes
    setRecipes: (recipes: Recipe[]) => void
  }
  RestService: {
    delete: (path: string) => Promise<RecipeSnakeCase[]>
    get: <T>(
      path: string,
      params: Record<string, string>,
      timeout: { timeout: number },
    ) => Promise<T>
  }
  UIService: {
    ConfirmationModal: {
      show: ({ text }: { text: string }) => boolean
    }
    EditRecipe: {
      close: () => void
    }
  }
  pResponseGeneric: (recipes: RecipeSnakeCase[]) => Recipe[]
  HandleError: (val: { error: unknown }) => void
}

interface DeleteParams {
  id: string
  chefId: string
  name: string
}

export const DeleteRecipe =
  ({
    ChefService,
    RestService,
    UIService,
    pResponseGeneric,
    HandleError,
  }: DeleteRecipesCoordinator) =>
  async ({ id, chefId, name }: DeleteParams) => {
    const doDelete = UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete the recipe for "${name}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/api/admin/chef_recipes/${id}`)
        ChefService.clearEditRecipe()
        LoadRecipes({
          ChefService,
          RestService,
          pResponseGeneric,
          HandleError,
        })(chefId)
        UIService.EditRecipe.close()
      } catch (error) {
        HandleError({ error })
      }
    }
  }

interface SaveRecipeCoordinator {
  ChefService: {
    clearEditRecipe: () => void
    getState: () => StateRes
    setRecipes: (recipes: Recipe[]) => void
  }
  RestService: {
    post: (
      path: string,
      params: RecipeSnakeCase,
      headers: { 'Content-Type': string },
    ) => Promise<RecipeSnakeCase>
    put: (
      path: string,
      params: RecipeSnakeCase,
      headers: { 'Content-Type': string },
    ) => Promise<RecipeSnakeCase>
    get: <T>(path: string, params: Record<string, string>) => Promise<T>
  }
  UIService: {
    Errors: {
      clear: () => void
    }
    EditRecipe: {
      close: () => void
    }
  }
  pRequestUpdateRecipe: (recipe: Recipe) => RecipeSnakeCase
  pResponseGeneric: (recipes: RecipeSnakeCase[]) => Recipe[]
  HandleError: (val: { error: unknown; namespace: string }) => void
}

interface Params {
  presignedUrl: string
  publicUrl: string
}

export const SaveRecipe =
  ({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateRecipe,
    pResponseGeneric,
    HandleError,
  }: SaveRecipeCoordinator) =>
  async (data: Recipe) => {
    try {
      UIService.Errors.clear()
      const req = pRequestUpdateRecipe(data)
      const { id, imageUrl } = data
      const headers = { 'Content-Type': 'multipart/form-data' }
      let recipe = undefined

      // Creates public image url link
      if (imageUrl instanceof File) {
        const presignedUrlData = {
          imageType: 'recipes',
          mimeType: imageUrl.type,
        }
        const { presignedUrl, publicUrl } = await RestService.get<Params>(
          '/images/presigned',
          presignedUrlData,
        )

        const config = {
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': imageUrl.type,
          },
          timeout: 120000,
        }

        let s3Response
        try {
          s3Response = await axios.put(presignedUrl, imageUrl, config)
        } catch (error) {
          HandleError({ error, namespace: 'recipeModal' })
          req.image_url = ''
        }

        if (
          s3Response &&
          (s3Response.status === 200 || s3Response.statusText === 'OK')
        ) {
          req.image_url = publicUrl
        }
      }

      if (id) {
        recipe = await RestService.put(
          `/api/admin/chef_recipes/${id}`,
          req,
          headers,
        )
      } else {
        recipe = await RestService.post(`/api/admin/chef_recipes`, req, headers)
      }

      UIService.EditRecipe.close()
      ChefService.clearEditRecipe()

      LoadRecipes({
        ChefService,
        RestService,
        pResponseGeneric,
      })(recipe.chef_id)
    } catch (error) {
      HandleError({ error, namespace: 'recipeModal' })
    }
  }
