import { GOScheduleMenu } from '@types'

interface SearchGOMenusCoordinator {
  RestService: {
    post: (path: string, filters: any) => Promise<GOScheduleMenu>
  }
  HandleError: any
  pRequestSearchGOMenusParams: (
    val: any,
    customValues: any,
    clientGeo: any,
    hqId: number,
  ) => any
}

export const SearchGOMenus =
  ({
    RestService,
    pRequestSearchGOMenusParams,
    HandleError,
  }: SearchGOMenusCoordinator) =>
  async ({ filters, customValues, clientGeo, hqId }: any) => {
    try {
      const params = pRequestSearchGOMenusParams(
        filters,
        customValues,
        clientGeo,
        hqId,
      )
      const menus = await RestService.post(
        '/group-orders/schedule-planner/menus',
        params,
      )

      return menus
    } catch (error) {
      HandleError({ error })
      const emptyArry: GOScheduleMenu[] = []

      return emptyArry
    }
  }
