import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

import { DateInput } from '@components/common/form'

export class SurveyDashboardList extends Component {
  state = {
    endDate: Moment().hour(0).minute(0).second(0).millisecond(0).add(1, 'd'),
    isShowingLowRatings: false,
    search: '',
    sort: 1, // ascending date
    startDate: Moment().hour(0).minute(0).second(0).millisecond(0),
  }

  componentDidMount() {
    this.refreshSurveys()
  }

  getRatingColorClass(rating) {
    if (rating == null) {
      return ''
    }
    if (rating <= 1) {
      return 'survey-danger-color'
    }
    if (rating <= 3) {
      return 'survey-warning-color'
    }
    if (rating >= 4) {
      return 'survey-success-color'
    }

    return ''
  }

  refreshSurveys = () => {
    const { endDate, isShowingLowRatings, search, sort, startDate } = this.state

    this.props.loadDashboardSurveys({
      endDate,
      isShowingLowRatings,
      search,
      sort,
      startDate,
    })
  }

  onChangeDate = (field) => (date) => {
    this.setState({ [field]: date }, this.refreshSurveys)
  }

  onChangeSearch = (e) => {
    const search = e.target.value
    this.setState({ search }, this.refreshSurveys)
  }

  onToggleSeeLowRatings = (e) => {
    this.setState(
      { isShowingLowRatings: e.target.checked },
      this.refreshSurveys,
    )
  }

  onToggleSort = () => {
    this.setState({ sort: -this.state.sort }, this.refreshSurveys)
  }

  render() {
    const { onSelectSurveyCell, surveys } = this.props
    const { endDate, isShowingLowRatings, search, sort, startDate } = this.state

    return (
      <div className="survey-table-container">
        <div className="survey-filters-container">
          <div className="date-filters">
            <div className="date-filters__inner">
              <p className="date-label"> From </p>
              <DateInput
                date={startDate}
                onChange={this.onChangeDate('startDate')}
              />
              <p className="date-label"> To </p>
              <DateInput
                date={endDate}
                clearDate={() =>
                  this.setState({ endDate: undefined }, this.refreshSurveys)
                }
                onChange={this.onChangeDate('endDate')}
              />
            </div>
          </div>
          <div className="survey-filter">
            <div className="input-container">
              <input
                type="text"
                placeholder="order #, client, chef, captain, rep"
                value={search}
                onInput={this.onChangeSearch}
              />
            </div>
          </div>
          <div>
            <div className="single-checkbox">
              <label className="label-padding" htmlFor="low_rating">
                See only low ratings
              </label>
              <input
                id="low_rating"
                type="checkbox"
                value={isShowingLowRatings}
                onChange={this.onToggleSeeLowRatings}
              />
            </div>
          </div>
        </div>
        <table className="survey-dashboard">
          <tr className="first-header-row">
            <th className="order-number">
              <div className="center-cell-vertical"> Order # </div>
            </th>
            <th className="date" onClick={this.onToggleSort}>
              <div className="center-cell-vertical">
                Date
                <div className="sort">
                  <span className={`filter-up-arrow ${sort === 1 && 'active'}`}>
                    ▲
                  </span>
                  <span
                    className={`filter-down-arrow ${sort === -1 && 'active'}`}
                  >
                    ▼
                  </span>
                </div>
              </div>
            </th>
            <th className="name">
              <div className="center-cell-vertical"> Client </div>
            </th>
            <th className="name">
              <div className="center-cell-vertical"> Chef </div>
            </th>
            <th className="name">
              <div className="center-cell-vertical"> Captain </div>
            </th>
            <th className="name">
              <div className="center-cell-vertical"> Sales Rep </div>
            </th>
            <th className="total">
              <div className="center-cell-vertical"> $ </div>
            </th>
            <th className="chef-feedback" colSpan="4">
              {' '}
              Chef Feedback{' '}
            </th>
            <th className="cc-feedback" colSpan="4">
              {' '}
              Catering Captain Feedback{' '}
            </th>
            <th className="rep-feedback" colSpan="2">
              {' '}
              Sales Rep{' '}
            </th>
          </tr>
          <tr>
            <th colSpan="7"></th>
            <th className="rating"> Client </th>
            <th className="rating"> Rep </th>
            <th className="rating"> CC </th>
            <th className="rating"> Self </th>
            <th className="rating"> Client </th>
            <th className="rating"> Chef </th>
            <th className="rating"> Rep </th>
            <th className="rating"> Self </th>
            <th className="rating"> Chef </th>
            <th className="rating"> Self </th>
          </tr>

          {surveys.map((s, i) => (
            <tr key={i}>
              <td className="survey-order-info"> {s.orderNumber} </td>
              <td className="survey-order-info"> {s.orderDate} </td>
              <td className="survey-order-info"> {s.account} </td>
              <td className="survey-order-info"> {s.chefs} </td>
              <td className="survey-order-info"> {s.captain} </td>
              <td className="survey-order-info"> {s.rep} </td>
              <td className="survey-order-total"> {s.total} </td>
              <td
                className={this.getRatingColorClass(s.officeChefRating)}
                onClick={
                  s.officeChefRating &&
                  onSelectSurveyCell(s.orderNumber, 'office_post')
                }
              >
                {s.officeChefRating}
              </td>
              <td
                className={this.getRatingColorClass(s.repChefRating)}
                onClick={
                  s.repChefRating &&
                  onSelectSurveyCell(s.orderNumber, 'rep_post')
                }
              >
                {s.repChefRating}
              </td>
              <td
                className={this.getRatingColorClass(s.captainChefRating)}
                onClick={
                  s.captainChefRating &&
                  onSelectSurveyCell(s.orderNumber, 'captain_post')
                }
              >
                {s.captainChefRating}
              </td>
              <td
                className={this.getRatingColorClass(s.chefChefRating)}
                onClick={
                  s.chefChefRating &&
                  onSelectSurveyCell(s.orderNumber, 'chef_post')
                }
              >
                {s.chefChefRating}
              </td>
              <td
                className={this.getRatingColorClass(s.officeCaptainRating)}
                onClick={
                  s.officeCaptainRating &&
                  onSelectSurveyCell(s.orderNumber, 'office_post')
                }
              >
                {s.officeCaptainRating}
              </td>
              <td
                className={this.getRatingColorClass(s.chefCaptainRating)}
                onClick={
                  s.chefCaptainRating &&
                  onSelectSurveyCell(s.orderNumber, 'chef_post')
                }
              >
                {s.chefCaptainRating}
              </td>
              <td
                className={this.getRatingColorClass(s.repCaptainRating)}
                onClick={
                  s.repCaptainRating &&
                  onSelectSurveyCell(s.orderNumber, 'rep_post')
                }
              >
                {s.repCaptainRating}
              </td>
              <td
                className={this.getRatingColorClass(s.captainCaptainRating)}
                onClick={
                  s.captainCaptainRating &&
                  onSelectSurveyCell(s.orderNumber, 'captain_post')
                }
              >
                {s.captainCaptainRating}
              </td>
              <td
                className={this.getRatingColorClass(s.chefRepRating)}
                onClick={
                  s.chefRepRating &&
                  onSelectSurveyCell(s.orderNumber, 'chef_post')
                }
              >
                {s.chefRepRating}
              </td>
              <td
                className={this.getRatingColorClass(s.repRepRating)}
                onClick={
                  s.repRepRating &&
                  onSelectSurveyCell(s.orderNumber, 'rep_post')
                }
              >
                {s.repRepRating}
              </td>
            </tr>
          ))}
        </table>
      </div>
    )
  }
}

SurveyDashboardList.propTypes = {
  surveys: PropTypes.array,

  loadDashboardSurveys: PropTypes.func,
  onSelectSurveyCell: PropTypes.func,
}

export default SurveyDashboardList
