import { connect } from 'react-redux'

import { PopUpAdminPage } from '@components/popUps'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { user = {}, headquarter = {} } = state
  const { lastLoggedInHq } = user
  const { userHeadquarters = [] } = headquarter
  const userHeadquarter =
    userHeadquarters.find((hq) => hq.id === lastLoggedInHq) || {}

  return {
    locale: userHeadquarter.locale,
    headquarter: userHeadquarter.id,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService } = services
  const {
    SendPopUpMarginReport,
    SearchPopUpsByDateRange,
    SearchDetailAccounts,
  } = coordinators
  const { pResponsePopUpAccounts } = presenters.Api
  const sendPopUpMarginReport = SendPopUpMarginReport({
    RestService,
    UIService,
    HandleError,
  })
  const searchPopUpsByDateRange = SearchPopUpsByDateRange({
    RestService,
    HandleError,
  })
  const searchPopUpClients = SearchDetailAccounts({
    RestService,
    pResponseAccounts: pResponsePopUpAccounts,
  })

  return {
    sendPopUpMarginReport,
    searchPopUpsByDateRange,
    searchPopUpClients,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpAdminPage)
