import Moment from 'moment-timezone'

import { camelCaseify, snakeCaseify } from '~/utils'

export const pCSVOrderDashboardItems = (items) => {
  // const items = snakeCaseify( json )
  const formattedItems = []

  items.forEach((i) => {
    const item = snakeCaseify({ ...i })
    // Updates for each attribute of an order
    for (const key in item) {
      if (Object.prototype.hasOwnProperty.call(item, key)) {
        // Remove null cells with blank text
        if (item[key] === null) {
          item[key] = ''
        }

        // Double quotes appear to be reserved characters in react-csv and must be escaped
        if (typeof item[key] === 'string') {
          item[key] = item[key].replace(/"/g, '""')
        }

        // parse strings in arrays too
        if (Array.isArray(item[key])) {
          item[key] = item[key].map((i) => {
            if (typeof i === 'string') {
              return i.replace(/"/g, '""')
            } else {
              return i
            }
          })
        }
      }
    }

    // Updates to an order object
    delete item.id
    delete item.catering_captain_id
    delete item.catering_captain
    formattedItems.push(item)
  })

  return formattedItems
}

export const pRequestDashboardParams = ({
  fromDate,
  toDate,
  ordersDashboardParams,
}) => {
  const params = {}

  if (Moment.isMoment(fromDate)) {
    params['from_date'] = fromDate.format('YYYY-MM-DD')
  }

  if (Moment.isMoment(toDate)) {
    params['to_date'] = toDate.format('YYYY-MM-DD')
  }

  if (Object.keys(params).length == 0) {
    params['status'] = 'open'
  }

  return Object.assign(params, ordersDashboardParams)
}

export const pRequestSubsDashboardParams = ({
  fromDate,
  toDate,
  headquarter,
}) => {
  const params = { headquarter }

  if (Moment.isMoment(fromDate)) {
    params['startTime'] = fromDate.format()
  } else {
    params['startTime'] = Moment().format()
  }

  if (Moment.isMoment(toDate)) {
    params['endTime'] = toDate.format()
  }

  return params
}

export const pRequestPopUpsDashboardParams = ({ fromDate, toDate, hq }) => {
  const params = { hq }

  if (Moment.isMoment(fromDate)) {
    params['start'] = fromDate.format()
  } else {
    params['start'] = Moment().format()
  }

  if (Moment.isMoment(toDate)) {
    params['end'] = toDate.format()
  }

  return params
}

export const pRequestVXOrdersDashboardParams = ({ fromDate, toDate }) => {
  const params = {}

  if (Moment.isMoment(fromDate)) {
    params['start'] = fromDate.format()
  } else {
    params['start'] = Moment().format()
  }

  if (Moment.isMoment(toDate)) {
    params['end'] = toDate.format()
  }

  params['status'] = ['Confirmed']

  return params
}

export const pRequestMLBOrdersDashboardParams = ({
  fromDate,
  toDate,
  headquarter,
}) => {
  const params = { headquarter }

  if (Moment.isMoment(fromDate)) {
    params['startTime'] = fromDate.format()
  } else {
    params['startTime'] = Moment().format()
  }

  if (Moment.isMoment(toDate)) {
    params['endTime'] = toDate.format()
  }

  return params
}

export const pRequestGroupOrdersDashboardParams = ({ fromDate, toDate }) => {
  const params = {}

  if (Moment.isMoment(fromDate)) {
    params['start'] = fromDate.format()
  } else {
    params['start'] = Moment().format()
  }

  if (Moment.isMoment(toDate)) {
    params['end'] = toDate.format()
  }

  return params
}

export const pRequestCateringOrdersDashboardParams = ({ fromDate, toDate }) => {
  const params = {}

  if (Moment.isMoment(fromDate)) {
    params['start'] = fromDate.format()
  } else {
    params['start'] = Moment().format()
  }

  if (Moment.isMoment(toDate)) {
    params['end'] = toDate.format()
  }

  return params
}

export const pResponseOrderDashboardItems = (json) => {
  const data = camelCaseify(json)

  const ordersDashboardItems = data.map((item) => {
    item.id = item.orderId
    ;(item.unixTimestamp = item.clientSetUpTime
      ? parseFloat(Moment(item.clientSetUpTime, Moment.ISO_8601).format('X'))
      : 0),
      (item.eventDate =
        item.eventDate &&
        Moment(item.eventDate, Moment.ISO_8601).format('MMM DD'))
    item.clientSetUpTime =
      item.clientSetUpTime &&
      Moment(item.clientSetUpTime, Moment.ISO_8601).format('MM-DD-YYYY h:mm a')
    item.hqArrivalTime =
      item.hqArrivalTime &&
      Moment(item.hqArrivalTime, Moment.ISO_8601).format('MM-DD-YYYY h:mm a')
    item.hqDepartureTime =
      item.hqDepartureTime &&
      Moment(item.hqDepartureTime, Moment.ISO_8601).format('MM-DD-YYYY h:mm a')
    item.kitchenArrivalTime =
      item.kitchenArrivalTime &&
      Moment(item.kitchenArrivalTime, Moment.ISO_8601).format(
        'MM-DD-YYYY h:mm a',
      )
    item.kitchenDepartureTime =
      item.kitchenDepartureTime &&
      Moment(item.kitchenDepartureTime, Moment.ISO_8601).format(
        'MM-DD-YYYY h:mm a',
      )
    item.clientArrivalTime =
      item.clientArrivalTime &&
      Moment(item.clientArrivalTime, Moment.ISO_8601).format(
        'MM-DD-YYYY h:mm a',
      )
    item.createdAt =
      item.createdAt &&
      Moment(item.createdAt, Moment.ISO_8601).format('MMM DD h:mm A')

    item.chefAmount = '$' + item.chefAmount
    item.paidAmount = '$' + item.paidAmount
    item.taxRate = '%' + item.taxRate * 100

    // if ( item.cateringCaptain ) {
    //   item.cateringCaptain.name = [ item.cateringCaptain.firstName, item.cateringCaptain.lastName ].join( ' ' )
    // }

    item.isClientAlertSent = item.isClientAlertSent ? 'Yes' : 'No'
    item.clientAlertLastSent =
      (item.clientAlertLastSent &&
        Moment(item.clientAlertLastSent, Moment.ISO_8601).format(
          'MM-DD-YYYY h:mm a',
        )) ||
      'N/A'

    item.isServicesAlertSent = item.isServicesAlertSent ? 'Yes' : 'No'
    item.servicesAlertLastSent =
      (item.servicesAlertLastSent &&
        Moment(item.servicesAlertLastSent, Moment.ISO_8601).format(
          'MM-DD-YYYY h:mm a',
        )) ||
      'N/A'

    item.isChefAlertSent = item.isChefAlertSent ? 'Yes' : 'No'
    item.chefAlertLastSent =
      (item.chefAlertLastSent &&
        Moment(item.chefAlertLastSent, Moment.ISO_8601).format(
          'MM-DD-YYYY h:mm a',
        )) ||
      'N/A'

    item.isSurveySent = item.isSurveySent ? 'Yes' : 'No'
    item.surveyLastSent =
      (item.surveyLastSent &&
        Moment(item.surveyLastSent, Moment.ISO_8601).format(
          'MM-DD-YYYY h:mm a',
        )) ||
      'N/A'

    item.isInvoiceSent = item.isInvoiceSent ? 'Yes' : 'No'
    item.invoiceLastSent =
      (item.invoiceLastSent &&
        Moment(item.invoiceLastSent, Moment.ISO_8601).format(
          'MM-DD-YYYY h:mm a',
        )) ||
      'N/A'

    item.isPaid = item.isPaid ? 'Yes' : 'Not Paid'
    item.lastPaidDate =
      (item.lastPaidDate &&
        Moment(item.lastPaidDate, Moment.ISO_8601).format(
          'MM-DD-YYYY h:mm a',
        )) ||
      'N/A'
    item.paymentType = item.paymentType || 'none'

    item.subtotal = '$' + item.subtotal.toFixed(2)
    item.discounts = '$' + item.discounts.toFixed(2)
    item.serviceFee = '$' + item.serviceFee.toFixed(2)
    item.tax = '$' + item.tax.toFixed(2)
    item.tip = item.tip ? '$' + item.tip.toFixed(2) : '$0.00'
    item.total = '$' + item.total.toFixed(2)
    item.totalRevenue = '$' + item.totalRevenue.toFixed(2)
    item.unpaidBalance = item.unpaidBalance
      ? '$' + item.unpaidBalance.toFixed(2)
      : '$0.00'
    item.orderContact = item.orderContact
      ? item.orderContact.split(' - ').join('\n')
      : 'No Invoice Contact Porvided'

    return item
  })

  return ordersDashboardItems
}

export const pResponseMLBOrderDashboardItems = (json) => {
  return json.map((order) => {
    const client = order.client || {
      name: 'N/A',
      email: 'N/A',
      phoneNumber: 'N/A',
    }
    const totalRevenue = parseFloat(order.totalRevenue)
    const chefAmount = 0.0
    const suppliesCost = order.suppliesCost
      ? parseFloat(order.suppliesCost)
      : 0.0
    const margin = (totalRevenue - chefAmount - suppliesCost) / totalRevenue

    return {
      id: order.id,
      unixTimestamp: parseFloat(
        Moment(order.date, Moment.ISO_8601).format('X'),
      ),
      orderType: 'MLB Order',
      orderNumber: order.number,
      client: client.name,
      orderContact: [client.name, client.email, client.phoneNumber].join('\n'),
      headCount: 'N/A',
      eventDate: Moment(order.date, Moment.ISO_8601).format('MMM DD'),
      clientSetUpTime: Moment(order.date, Moment.ISO_8601).format(
        'MM-DD-YYYY h:mm a',
      ),
      createdAt: Moment(order.createdAt, Moment.ISO_8601).format(
        'MM-DD-YYYY h:mm a',
      ),
      chefAmount: '$' + chefAmount.toFixed(2),
      paidAmount: order.paid
        ? '$' + parseFloat(order.totalBilling).toFixed(2)
        : '$0.00',
      taxRate: [],
      isEvent: 'Not Event',
      accountExecutive: 'N/A',
      orderSource: 'MLB Partner',
      serviceType: 'Delivery',
      isClientAlertSent: 'N/A',
      clientAlertLastSent: 'N/A',
      isServicesAlertSent: 'N/A',
      servicesAlertLastSent: 'N/A',
      isChefAlertSent: 'N/A',
      chefAlertLastSent: 'N/A',
      isSurveySent: 'N/A',
      surveyLastSent: 'N/A',
      isInvoiceSent: 'N/A',
      invoiceLastSent: 'N/A',
      isPaid: order.paid ? 'Yes' : 'Not Paid',
      lastPaidDate: Moment(order.createdAt, Moment.ISO_8601).format(
        'MM-DD-YYYY h:mm a',
      ),
      paymentType: 'N/A',
      suppliesCost: suppliesCost.toFixed(2),
      serviceCosts: 'N/A',
      discountReasons: 'N/A',

      disableEditPayment: true,

      eachChefPayout: 'N/A',
      subtotal: '$' + parseFloat(order.subtotal).toFixed(2),
      discounts: '$' + parseFloat(order.discounts).toFixed(2),
      serviceFee: '$' + parseFloat(order.serviceFee).toFixed(2),
      tax: '$' + parseFloat(order.tax).toFixed(2),
      tip: '$' + parseFloat(order.tip).toFixed(2),
      total: '$' + parseFloat(order.totalBilling).toFixed(2),
      totalRevenue: '$' + totalRevenue.toFixed(2),
      unpaidBalance: order.paid
        ? '$0.00'
        : '$' + parseFloat(order.totalBilling).toFixed(2),
      margin: margin.toFixed(4),
    }
  })
}

export const pResponseSubsDashboardItems = (json) => {
  return json.map((sub) => {
    const client = sub.client || {
      name: 'N/A',
      email: 'N/A',
      phoneNumber: 'N/A',
    }
    const acctExec = sub.salesRep
      ? `${sub.salesRep.firstName} ${sub.salesRep.lastName}`
      : 'None'
    const totalRevenue = parseFloat(sub.totalRevenue)
    const chefAmount = (sub.chef && parseFloat(sub.chef.payout)) || 0.0
    const suppliesCost = parseFloat(sub.suppliesCost)
    const margin = (totalRevenue - chefAmount - suppliesCost) / totalRevenue

    return {
      id: sub.id,
      unixTimestamp: parseFloat(Moment(sub.date, Moment.ISO_8601).format('X')),
      orderType: 'Home',
      orderNumber: sub.number,
      client: client.name,
      orderContact: [client.name, client.email, client.phoneNumber].join('\n'),
      headCount: sub.headCount,
      eventDate: Moment(sub.date, Moment.ISO_8601).format('MMM DD'),
      clientSetUpTime: Moment(sub.date, Moment.ISO_8601).format(
        'MM-DD-YYYY h:mm a',
      ),
      createdAt: Moment(sub.createdAt, Moment.ISO_8601).format(
        'MM-DD-YYYY h:mm a',
      ),
      chefAmount: '$' + chefAmount.toFixed(2),
      paidAmount: sub.paid
        ? '$' + parseFloat(sub.totalBilling).toFixed(2)
        : '$0.00',
      taxRate:
        (sub.taxRates && sub.taxRates.map((r) => `%${parseFloat(r) * 100}`)) ||
        [],
      isEvent: 'Not Event',
      accountExecutive: acctExec,
      orderSource: 'Home',
      serviceType: 'Regular',
      isClientAlertSent: 'N/A',
      clientAlertLastSent: 'N/A',
      isServicesAlertSent: 'N/A',
      servicesAlertLastSent: 'N/A',
      isChefAlertSent: 'N/A',
      chefAlertLastSent: 'N/A',
      isSurveySent: 'N/A',
      surveyLastSent: 'N/A',
      isInvoiceSent: 'N/A',
      invoiceLastSent: 'N/A',
      isPaid: sub.paid ? 'Yes' : 'Not Paid',
      lastPaidDate: 'N/A',
      paymentType: 'N/A',
      suppliesCost: suppliesCost.toFixed(2),
      serviceCosts: 'N/A',
      discountReasons: 'N/A',

      disableEditPayment: true,

      eachChefPayout: `${sub.chef.name}: $${
        (sub.chef && sub.chef.payout) || '0.00'
      }`,
      subtotal: '$' + parseFloat(sub.subtotal).toFixed(2),
      discounts: '$' + parseFloat(sub.discounts).toFixed(2),
      serviceFee: '$' + parseFloat(sub.serviceFee).toFixed(2),
      tax: '$' + parseFloat(sub.tax).toFixed(2),
      tip: '$' + parseFloat(sub.tip).toFixed(2),
      total: '$' + parseFloat(sub.totalBilling).toFixed(2),
      totalRevenue: '$' + totalRevenue.toFixed(2),
      unpaidBalance: sub.paid
        ? '$0.00'
        : '$' + parseFloat(sub.totalBilling).toFixed(2),
      margin: margin.toFixed(4),
    }
  })
}

export const pResponsePopUpsDashboardItems = (json) => {
  return json.map((popUp) => {
    const {
      id,
      createdAt,
      orderedBy: client,
      soldBy: salesRep,
      captainPayouts = [],
      chefPayouts = [],
      orderNumber,
      numOrders: headCount,
      date: eventDate,
      status: orderStatus,
      orderPayments = [],
      serviceFee,
      suppliesCost,
      tax,
      tip,
      total,
      subsidy,
      subsidyTax,
      subsidyTip,
      isPaid,
    } = popUp
    const accountExecutive = salesRep
      ? `${salesRep.firstName} ${salesRep.lastName}`
      : 'N/A'
    const date = Moment(eventDate)
    const chefPays = []
    let chefAmount = 0.0
    chefPayouts.forEach(({ total, preparedBy }) => {
      chefAmount += total
      chefPays.push(`${preparedBy.name}: $${total}`)
    })
    const eachChefPayout = chefPays.join('\n')
    const svcCosts = captainPayouts.reduce((sum, pay) => {
      return sum + (pay.total - (pay.tip || 0.0))
    }, 0.0)
    const paidPmt = orderPayments.find((pmt) => pmt.isPaid)
    const paidBal = orderPayments.reduce((sum, pmt) => {
      return sum + pmt.amount
    }, 0.0)
    const unpaidBal = subsidy - paidBal
    const subsidyTtl = subsidy + subsidyTax + subsidyTip + serviceFee
    const totalRev = total - tax - tip
    const margin =
      totalRev !== 0.0
        ? (totalRev - chefAmount - svcCosts - suppliesCost) / totalRev
        : 0.0

    return {
      id,
      unixTimestamp: parseFloat(date.format('X')),
      orderType: 'Pop-Up',
      orderNumber,
      orderStatus,
      client: client.name,
      pin: client ? client.pin : 'N/A',
      orderContact: `${client.contactName}\n${client.email}\n${client.phoneNumber}`,
      headCount,
      eventDate: date.format('MMM DD'),
      clientSetUpTime: date.format('MM-DD-YYYY h:mm a'),
      createdAt: Moment(createdAt).format('MMM DD h:mm A'),
      chefAmount: `$${chefAmount.toFixed(2)}`,
      paidAmount: isPaid ? `$${total}` : '$0.00',
      isEvent: 'Not Event',
      accountExecutive,
      orderSource: 'Customer App',
      serviceType: 'Delivery',
      isChefAlertSent: false,
      chefAlertLastSent: '',
      isPaid: isPaid ? 'Yes' : 'No',
      lastPaidDate: paidPmt
        ? Moment(paidPmt.paidAt).format('MMM DD h:mm A')
        : '',
      paymentType: paidPmt ? paidPmt.paymentTransaction.processor : '',
      suppliesCost: `$${suppliesCost.toFixed(2)}`,
      serviceCosts: `$${svcCosts.toFixed(2)}`,
      disableEditPayment: true,
      disableEditStatus: true,
      eachChefPayout,
      subtotal: `$${subsidy}`,
      discounts: '$0.00',
      serviceFee: `$${serviceFee}`,
      tax: `$${subsidyTax}`,
      tip: `$${subsidyTip}`,
      total: `$${subsidyTtl.toFixed(2)}`,
      totalRevenue: `$${totalRev.toFixed(2)}`,
      unpaidBalance: `$${unpaidBal.toFixed(2)}`,
      margin: margin.toFixed(4),
    }
  })
}

export const pResponseVXOrderDashboardItems = (json) => {
  return json.map((vx) => {
    const { client, salesRep, virtualExperience, total, discount, subtotal } =
      vx.vxOrder
    const acctExec = `${salesRep.firstName} ${salesRep.lastName}`
    const totalRevenue = total ? parseFloat(total) : 0.0
    let chefAmount = 0.0
    let paidAmount = 0.0
    const pmtType = 'Braintree'
    let lastPaidDate = 'Never'
    const sub = subtotal ? parseFloat(subtotal) : 0.0
    let unpaidBalance = total ? parseFloat(total) : 0.0
    let eachChefPayout = 0.0
    let disc = discount ? parseFloat(discount) : 0.0
    let tip = 0.0

    if (vx.accountingVXOrder) {
      const payments = vx.accountingVXOrder.payments || []
      chefAmount = parseFloat(virtualExperience.cost * vx.vxOrder.headCount)
      paidAmount = payments.reduce((ttl, pay) => {
        pay.invoice.forEach((inv) => {
          disc =
            disc + parseFloat(inv.discountAmount / inv.invoicedPayments.length)
          tip = tip + parseFloat(inv.tip / inv.invoicedPayments.length)
        })

        if (pay.isPaid) {
          lastPaidDate = Moment(pay.updatedAt).format('MM-DD-YYYY h:mm a')

          return ttl + parseFloat(pay.amount || 0.0)
        } else {
          return ttl
        }
      }, 0.0)
      eachChefPayout = chefAmount
    }

    if (paidAmount) {
      unpaidBalance = unpaidBalance - paidAmount
    }
    const margin =
      totalRevenue !== 0.0 ? (totalRevenue - chefAmount) / totalRevenue : 0.0

    return {
      id: vx.vxOrder.id,
      pin: client.pin,
      unixTimestamp: parseFloat(Moment(vx.vxOrder.start).format('X')),
      orderType: 'VX',
      orderNumber: vx.vxOrder.number,
      orderStatus: vx.vxOrder.status,
      client: client.name,
      orderContact: `${client.contact.name}\n${client.contact.email}\n${client.contact.phone}`,
      headCount: vx.vxOrder.headCount,
      eventDate: Moment(vx.vxOrder.start).format('MMM DD'),
      clientSetUpTime: Moment(vx.vxOrder.start).format('MM-DD-YYYY h:mm a'),
      createdAt: Moment(vx.vxOrder.createdAt).format('MMM DD h:mm A'),
      chefAmount: `$${chefAmount.toFixed(2)}`,
      paidAmount: `$${paidAmount.toFixed(2)}`,
      isEvent: 'Not Event',
      accountExecutive: acctExec,
      orderSource: 'Admin',
      serviceType: 'Regular',
      isChefAlertSent: !!vx.vxOrder.chefLastSent,
      chefAlertLastSent:
        vx.vxOrder.chefLastSent &&
        Moment(vx.vxOrder.chefLastSent).format('MM-DD-YYYY h:mm a'),
      isPaid: unpaidBalance > 0 ? 'No' : 'Yes',
      lastPaidDate,
      paymentType: pmtType,
      suppliesCost: '$0.00',
      serviceCosts: '$0.00',
      disableEditPayment: true,
      disableEditStatus: true,
      eachChefPayout: `${
        virtualExperience.talent.name
      }: $${eachChefPayout.toFixed(2)}`,
      subtotal: `$${sub.toFixed(2)}`,
      discounts: `$${parseFloat(disc).toFixed(2)}`,
      serviceFee: '$0.00',
      tax: `$${parseFloat(vx.vxOrder.tax).toFixed(2)}`,
      tip: `$${tip.toFixed(2)}`,
      total: `$${parseFloat(vx.vxOrder.total).toFixed(2)}`,
      totalRevenue: `$${totalRevenue.toFixed(2)}`,
      unpaidBalance: `$${unpaidBalance.toFixed(2)}`,
      margin: margin.toFixed(4),
    }
  })
}

export const pResponseGroupOrderDashboardItems = (json) => {
  return json.map((groupOrder) => {
    const {
      id,
      createdAt,
      orderedBy: client,
      soldBy: salesRep,
      captainPayouts = [],
      chefPayouts = [],
      orderNumber,
      numOrders: headCount,
      date: eventDate,
      status: orderStatus,
      orderPayments = [],
      serviceFee,
      tax,
      tip,
      total,
      subsidy,
      subsidyTax,
      subsidyTip,
      isPaid,
    } = groupOrder
    const accountExecutive = `${salesRep.firstName} ${salesRep.lastName}`
    const date = Moment(eventDate)
    const chefPays = []
    let chefAmount = 0.0
    chefPayouts.forEach(({ total, preparedBy }) => {
      chefAmount += total
      chefPays.push(`${preparedBy.name}: $${total}`)
    })
    const eachChefPayout = chefPays.join('\n')
    const svcCosts = captainPayouts.reduce((sum, pay) => {
      return sum + (pay.total - (pay.tip || 0.0))
    }, 0.0)
    const paidPmt = orderPayments.find((pmt) => pmt.isPaid)
    const totalRev = total - tax - tip
    const subsidyTtl = subsidy + subsidyTax + subsidyTip + serviceFee
    const margin =
      totalRev !== 0.0 ? (totalRev - chefAmount - svcCosts) / totalRev : 0.0

    return {
      id,
      unixTimestamp: parseFloat(date.format('X')),
      orderType: 'Group Order',
      orderNumber,
      orderStatus,
      client: client.name,
      pin: client ? client.pin : 'N/A',
      orderContact: `${client.contactName}\n${client.email}\n${client.phoneNumber}`,
      headCount,
      eventDate: date.format('MMM DD'),
      clientSetUpTime: date.format('MM-DD-YYYY h:mm a'),
      createdAt: Moment(createdAt).format('MMM DD h:mm A'),
      chefAmount: `$${chefAmount.toFixed(2)}`,
      paidAmount: isPaid ? `$${total}` : '$0.00',
      isEvent: 'Not Event',
      accountExecutive,
      orderSource: 'Customer App',
      serviceType: 'Delivery',
      isChefAlertSent: false,
      chefAlertLastSent: '',
      isPaid: isPaid ? 'Yes' : 'No',
      lastPaidDate: paidPmt
        ? Moment(paidPmt.paidAt).format('MMM DD h:mm A')
        : '',
      paymentType: paidPmt ? paidPmt.paymentTransaction : '',
      suppliesCost: '$0.00',
      serviceCosts: `$${svcCosts.toFixed(2)}`,
      disableEditPayment: true,
      disableEditStatus: true,
      eachChefPayout,
      subtotal: `$${subsidy}`,
      discounts: '$0.00',
      serviceFee: `$${serviceFee}`,
      tax: `$${subsidyTax}`,
      tip: `$${subsidyTip}`,
      total: `$${subsidyTtl.toFixed(2)}`,
      totalRevenue: `$${totalRev.toFixed(2)}`,
      unpaidBalance: isPaid ? '$0.00' : `$${total}`,
      margin: margin.toFixed(4),
    }
  })
}

export const pResponseCateringOrderDashboardItems = (json) => {
  return json.map((caterOrder) => {
    const {
      id,
      createdAt,
      orderedBy: client,
      soldBy: salesRep,
      captainPayouts = [],
      chefPayouts = [],
      orderNumber,
      cateringType,
      headCount,
      date: eventDate,
      status: orderStatus,
      orderPayments = [],
      suppliesCost,
      subtotal,
      serviceFee,
      discount,
      tax,
      tip,
      total,
      isPaid,
    } = caterOrder
    const accountExecutive = salesRep
      ? `${salesRep.firstName} ${salesRep.lastName}`
      : 'N/A'
    const date = Moment(eventDate)
    const chefPays = []
    let chefAmount = 0.0
    chefPayouts.forEach(({ total, preparedBy }) => {
      chefAmount += total
      chefPays.push(`${preparedBy.name}: $${total}`)
    })
    const eachChefPayout = chefPays.join('\n')
    const svcCosts = captainPayouts.reduce((sum, pay) => {
      return sum + (pay.total - (pay.tip || 0.0))
    }, 0.0)
    const paidPmt = orderPayments.find((pmt) => pmt.isPaid)
    const ttlRev = total - tip - tax
    const margin =
      ttlRev !== 0.0
        ? (ttlRev - chefAmount - svcCosts - suppliesCost) / ttlRev
        : 0.0

    return {
      id,
      unixTimestamp: parseFloat(date.format('X')),
      orderType: cateringType,
      orderNumber,
      orderStatus,
      client: client ? client.name : 'N/A',
      pin: client ? client.pin : 'N/A',
      orderContact: client
        ? `${client.contactName}\n${client.email}\n${client.phoneNumber}`
        : 'N/A',
      headCount,
      eventDate: date.format('MMM DD'),
      clientSetUpTime: date.format('MM-DD-YYYY h:mm a'),
      createdAt: Moment(createdAt).format('MMM DD h:mm A'),
      chefAmount: `$${chefAmount.toFixed(2)}`,
      paidAmount: isPaid ? `$${total}` : '$0.00',
      isEvent: 'Not Event',
      accountExecutive,
      orderSource: 'Customer App',
      serviceType: 'Delivery',
      isChefAlertSent: false,
      chefAlertLastSent: '',
      isPaid: isPaid ? 'Yes' : 'No',
      lastPaidDate: paidPmt
        ? Moment(paidPmt.paidAt).format('MMM DD h:mm A')
        : '',
      paymentType: paidPmt ? paidPmt.paymentTransaction : '',
      suppliesCost: `$${suppliesCost.toFixed(2)}`,
      serviceCosts: `$${svcCosts.toFixed(2)}`,
      disableEditPayment: true,
      disableEditStatus: true,
      eachChefPayout,
      subtotal: `$${subtotal}`,
      discounts: `$${discount.toFixed(2)}`,
      serviceFee: `$${serviceFee}`,
      tax: `$${tax}`,
      tip: `$${tip}`,
      total: `$${total.toFixed(2)}`,
      totalRevenue: `$${ttlRev.toFixed(2)}`,
      unpaidBalance: isPaid ? '$0.00' : `$${total}`,
      margin: margin.toFixed(4),
    }
  })
}

export const pOrderDashboardUpdate = ({ orderUpdate, orderDate }) => {
  const orderParams = snakeCaseify(orderUpdate)

  // orderDate = orderDate.date
  const orderDateFormatted = Moment(orderDate, 'MM-DD-YYYY h:mm a')
    .format()
    .split('T')[0]

  const time_keys = Object.keys(orderParams).filter((k) =>
    k.toLowerCase().endsWith('_time'),
  )
  time_keys.forEach((key) => {
    const timeArray = Moment(orderParams[key], 'MM-DD-YYYY h:mm a')
      .format()
      .split('T')

    return (orderParams[key] = `${orderDateFormatted}T${timeArray[1]}`)
  })

  if (orderParams.order_status) {
    orderParams.order_statuses_attributes = [{ code: orderParams.order_status }]
  }

  delete orderParams.send_services_alert
  delete orderParams.send_chef_alert
  delete orderParams.id

  const orderRequest = {
    id: orderUpdate.id,
    send_services_email: orderUpdate.sendServicesAlert,
    send_chef_email: orderUpdate.sendChefAlert,
  }

  if (Object.keys(orderParams).length > 0) {
    orderRequest.order = orderParams
  }

  return orderRequest
}

export const pOrderDashboardUpdates = ({ orderUpdates, orderDates }) => {
  const req = { order_updates: [] }
  Object.values(orderUpdates).forEach((order) => {
    const orderDate = orderDates.find((d) => d.id === order.id).date

    req.order_updates.push(
      pOrderDashboardUpdate({ orderUpdate: order, orderDate }),
    )
  })

  return req
}
