import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { AuthorizedInteractable } from '@containers/common/auth'

const Colspan = '11'
const ValueKinds = ['fixed', 'percentage']

export class PromoCodesTable extends Component {
  state = {
    activeRow: null,
    editedPromo: null,
    search: '',
  }

  clearEdits = () => {
    this.setState({
      activeRow: null,
      editedPromo: null,
    })
  }

  onSetActiveRow = (id) => () => {
    const { activeRow } = this.state
    if (activeRow == null) {
      const promoCode = this.props.promoCodes.find((p) => p.id === id)
      this.setState({
        activeRow: id,
        editedPromo: { ...promoCode },
      })
    } else {
      this.setState({
        activeRow: null,
        editedPromo: null,
      })
    }
  }

  updatePromoCode = (attrKey, value) => {
    const { editedPromo } = this.state
    const updated = { ...editedPromo, [attrKey]: value }
    if (!ValueKinds.includes(updated.kind)) {
      updated.value = null
    }
    this.setState({ editedPromo: updated })
  }

  renderSectionHeader = (headers, title) => (
    <tbody>
      <tr>
        <td colSpan={Colspan} className="promocode-header-container">
          <div className="promocode-header">{title}</div>
        </td>
      </tr>
      <tr>
        <td colSpan={Colspan} className="promocode-header-container">
          <div className="promocode-header">
            Search Filter
            <input
              type="text"
              value={this.state.search}
              onInput={(e) => this.setState({ search: e.target.value })}
            />
          </div>
        </td>
      </tr>
      <tr className="promocode-table-headers">
        {headers.map((header, i) => (
          <th key={i}>{header}</th>
        ))}
      </tr>
    </tbody>
  )

  renderStaticRow(promoCode) {
    return (
      <tr
        key={promoCode.id}
        className="clickable"
        onClick={this.onSetActiveRow(promoCode.id)}
      >
        <td> {promoCode.code} </td>
        <td> {promoCode.kind} </td>
        <td> {promoCode.isActive} </td>
        <td> {promoCode.validFrom} </td>
        <td> {promoCode.validTo} </td>
        <td> {promoCode.isPublic ? 'Public' : 'Private'} </td>
        <td> {promoCode.redeemLimitPerUser} </td>
        <td> {promoCode.reason} </td>
        <td> {promoCode.redemptionCount} </td>
        <td> {promoCode.description} </td>
        <td />
      </tr>
    )
  }

  renderEditableRow() {
    const { editedPromo } = this.state
    const {
      id,
      code,
      kind,
      isActive,
      validFrom,
      validTo,
      value,
      reason,
      redeemLimitPerUser,
      isPublic,
    } = editedPromo
    const {
      promoCodeKinds = [],
      promoCodeReasons = [],
      renderEditButtons,
      onSavePromoCode,
      onRemovePromoCode,
    } = this.props

    return (
      <tr key={id || -1}>
        <td>
          <input
            type="text"
            value={code}
            onInput={(e) => this.updatePromoCode('code', e.target.value)}
          />
        </td>
        <td>
          <select
            value={kind || ''}
            onChange={(e) => this.updatePromoCode('kind', e.target.value)}
          >
            <option key={-1} value={''}></option>
            {promoCodeKinds.map((k, i) => (
              <option key={i} value={k}>
                {k}
              </option>
            ))}
          </select>
        </td>
        <td> {isActive} </td>
        <td>
          <input
            type="text"
            placeholder="MM/DD/YYYY"
            value={validFrom}
            onInput={(e) => this.updatePromoCode('validFrom', e.target.value)}
          />
        </td>
        <td>
          <div className="relative pb-50">
            <input
              type="text"
              placeholder="MM/DD/YYYY"
              value={validTo}
              onInput={(e) => this.updatePromoCode('validTo', e.target.value)}
            />
            {/* moved buttons here to appear centered in table */}
            <div className="promocode-button-container">
              <button
                className="button-primary button-small"
                onClick={this.clearEdits}
              >
                Cancel
              </button>
              <AuthorizedInteractable roles={['master admin', 'sales lead']}>
                <button
                  className="button-primary button-small"
                  onClick={async () => {
                    if (await onSavePromoCode(editedPromo)) {
                      this.clearEdits()
                    }
                  }}
                >
                  Save
                </button>
              </AuthorizedInteractable>
              {onRemovePromoCode && (
                <AuthorizedInteractable roles={['master admin', 'sales lead']}>
                  <button
                    className="button-alert button-small"
                    onClick={async () => {
                      await onRemovePromoCode(editedPromo)
                      this.clearEdits()
                    }}
                  >
                    Remove Code
                  </button>
                </AuthorizedInteractable>
              )}
              {renderEditButtons &&
                renderEditButtons(editedPromo, this.clearEdits)}
            </div>
          </div>
        </td>
        <td>
          Public?
          <input
            type="checkbox"
            checked={isPublic}
            onChange={(e) => this.updatePromoCode('isPublic', e.target.checked)}
          />
        </td>
        <td>
          <input
            type="text"
            value={redeemLimitPerUser}
            onInput={(e) =>
              this.updatePromoCode('redeemLimitPerUser', e.target.value)
            }
          />
        </td>
        <td>
          <select
            value={reason || ''}
            onChange={(e) => this.updatePromoCode('reason', e.target.value)}
          >
            <option key={-1} value={''}></option>
            {promoCodeReasons.map((r, i) => (
              <option key={i} value={r}>
                {r}
              </option>
            ))}
          </select>
        </td>
        <td> {/* placeholder for total redemption */} </td>
        <td>
          {ValueKinds.includes(kind) ? (
            <div>
              {kind === 'percentage' ? '%' : '$'}
              <input
                type="text"
                value={value}
                onInput={(e) => this.updatePromoCode('value', e.target.value)}
              />
            </div>
          ) : (
            'Dynamic'
          )}
        </td>
      </tr>
    )
  }

  render() {
    let { promoCodes } = this.props
    const { title, headers } = this.props
    const { activeRow, search } = this.state

    if (search) {
      const pattern = new RegExp(search, 'i')
      promoCodes = promoCodes.filter(
        (promo) =>
          pattern.test(promo.code) ||
          pattern.test(promo.reason) ||
          pattern.test(promo.kind),
      )
    }

    return (
      <div className="promocode-table">
        <table className="table table-hover page promocode-table">
          {this.renderSectionHeader(headers, title)}
          <tbody>
            {promoCodes.length === 0 ? (
              <tr>
                <td colSpan={Colspan}>
                  <div className="no-promocodes">No Promo Codes.</div>
                </td>
              </tr>
            ) : (
              promoCodes.map((promoCode) =>
                activeRow === promoCode.id
                  ? this.renderEditableRow()
                  : this.renderStaticRow(promoCode),
              )
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

PromoCodesTable.propTypes = {
  title: PropTypes.string,
  headers: PropTypes.array,
  promoCodes: PropTypes.array,
  promoCodeKinds: PropTypes.array,
  promoCodeReasons: PropTypes.array,

  onRemovePromoCode: PropTypes.func,
  onSavePromoCode: PropTypes.func,
  renderEditButtons: PropTypes.func,
}

PromoCodesTable.defaultTypes = {
  promoCodes: [],
  promoCodeKinds: [],
  promoCodeReasons: [],
}

export default PromoCodesTable
