import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropdown from '@components/common/form/Dropdown'
import XSpacing from '@components/common/XSpacing'
import LinkText from '@components/common/form/LinkText'
import FlexContainer from '@components/common/FlexContainer'
import YSpacing from '@components/common/YSpacing'
import Input from '@components/common/form/Input'
import Button from '@components/common/form/Button'
import { colors } from '../../../../../constants'

const InitialState = {
  cardholderName: '',
  cardType: '',
  cardNumber: '',
  cvv: '',
  expirationDate: '',
  formattedCardNumber: '',
  nonce: '',
}

class PaymentSection extends Component {
  state = {
    paymentMethod: null,
    ...InitialState,

    isFocused: false,
    isNewPaymentMethod: false,
    needsBraintreeLoad: true,
  }
  cvvLength = 3

  componentDidMount() {
    ;(async () => {
      await this.props.initBraintree()

      this.setState({ needsBraintreeLoad: false })
    })()

    this.paymentMethodMap = {}
    this.props.paymentMethods.forEach((p) => (this.paymentMethodMap[p.id] = p))

    this.setState({ paymentMethod: this.props.paymentMethod })
  }

  componentWillReceiveProps(nextProps) {
    const { hasAccount, paymentMethod, paymentMethods } = nextProps

    if (paymentMethod !== this.props.paymentMethod) {
      this.setState({ paymentMethod })
    }
    if (paymentMethods !== this.props.paymentMethods) {
      this.paymentMethodMap = {}
      paymentMethods.forEach((p) => (this.paymentMethodMap[p.id] = p))

      if (hasAccount) {
        this.setState({ isNewPaymentMethod: paymentMethods.length === 0 })
      }
    }
  }

  updatePaymentMethod =
    ({ doUpdateNonce }) =>
    async () => {
      const { onChange, pBuildPaymentMethod, updateNonce } = this.props

      if (doUpdateNonce) {
        const nonce = await updateNonce(this.state)
        this.setState({ nonce }, () => {
          const newPaymentMethod = pBuildPaymentMethod(this.state)
          onChange && onChange({ newPaymentMethod })
        })
      } else {
        const newPaymentMethod = pBuildPaymentMethod(this.state)
        onChange && onChange({ newPaymentMethod })
      }
    }

  onBlur = () => {
    this.setState({ isFocused: false })
  }

  onCancel = () => {
    const { onCancel } = this.props
    this.setState(
      {
        ...InitialState,
        isNewPaymentMethod: false,
      },
      () => {
        const { paymentMethod } = this.state
        this.props.onChange({ paymentMethod, newPaymentMethod: {} })
        onCancel && onCancel()
      },
    )
  }

  onFocus = () => {
    this.setState({ isFocused: true })
  }

  onInputCardholderName = (e) => {
    const cardholderName = e.target.value
    this.setState(
      { cardholderName },
      this.updatePaymentMethod({ doUpdateNonce: false }),
    )
    this.props.clearError('cardholderName')
  }

  onInputCardNumber = (e) => {
    const { pFormatCreditCard } = this.props
    const { cardNumber, cardType, cvvLength, formattedCardNumber } =
      pFormatCreditCard(e.target.value)

    this.cvvLength = Math.max(cvvLength || 0, 3)
    this.setState(
      {
        cardNumber,
        cardType,
        formattedCardNumber,
      },
      this.updatePaymentMethod({ doUpdateNonce: true }),
    )
    this.props.clearError('number')
  }

  onInputCVV = (e) => {
    let cvv = e.target.value
    cvv = cvv.replace(/[^0-9]/g, '').slice(0, this.cvvLength)

    if (cvv.length === this.cvvLength) {
      this.setState({ cvv }, this.updatePaymentMethod({ doUpdateNonce: true }))
    } else {
      this.setState(
        { cvv, nonce: '' },
        this.updatePaymentMethod({ doUpdateNonce: false }),
      )
    }
    this.props.clearError('cvv')
  }

  onInputExpirationDate = (e) => {
    const { clearError, pFormatMMYY } = this.props
    const expirationDate = pFormatMMYY(e.target.value)

    this.setState(
      {
        expirationDate,
      },
      this.updatePaymentMethod({ doUpdateNonce: true }),
    )
    clearError('expirationMonth')
    clearError('expirationYear')
  }

  onSave = async () => {
    const isValid = await this.props.onSave()
    if (isValid) {
      this.setState({ isNewPaymentMethod: false, ...InitialState })
    }
  }

  onSelectPaymentMethod = (e) => {
    const paymentMethodId = e.target.value
    const paymentMethod = this.paymentMethodMap[paymentMethodId]
    this.setState({ paymentMethod })
    const newState = { paymentMethod, paymentMethodId, newPaymentMethod: {} }
    if (paymentMethod && paymentMethod.billingAddressId) {
      newState.billingAddressId = paymentMethod.billingAddressId
      newState.billingAddress = paymentMethod.billingAddress
    }

    this.props.onChange(newState)
  }

  onShowNewPaymentMethod = () => {
    const { onShowNewPaymentMethod } = this.props
    this.setState({ isNewPaymentMethod: true })
    onShowNewPaymentMethod && onShowNewPaymentMethod()
  }

  renderNewPaymentMethod() {
    const { errors } = this.props
    const { cardholderName, expirationDate, formattedCardNumber, cvv } =
      this.state

    return (
      <div className="p-3 bg-indigo-100">
        <Input
          label="Cardholder Name"
          value={cardholderName}
          error={errors.cardholderName}
          onChange={this.onInputCardholderName}
        />
        <Input
          label="Card Number"
          value={formattedCardNumber}
          error={errors.number}
          onChange={this.onInputCardNumber}
        />
        <Input
          placeholder="MM/YY"
          label="Expiration Date"
          value={expirationDate}
          error={errors.expirationYear || errors.expirationMonth}
          onChange={this.onInputExpirationDate}
          testId="expiration-date"
        />
        <Input
          label="CVV"
          value={cvv}
          error={errors.cvv}
          onChange={this.onInputCVV}
          testId="cvv"
        />
        <FlexContainer alignItems="center" justifyContent="flex-end">
          <LinkText
            label="Cancel"
            onClick={this.onCancel}
            color={colors.violet}
          />
          <XSpacing width="20px" />
          <Button label="Save" onClick={this.onSave} />
        </FlexContainer>
      </div>
    )
  }

  renderPaymentMethodDropdown() {
    const { paymentMethod } = this.props
    let { paymentMethods } = this.props
    const { errors, hasAccount, newPaymentMethod } = this.props
    const { isNewPaymentMethod } = this.state

    const paymentMethodId = isNewPaymentMethod
      ? ''
      : paymentMethod && paymentMethod.id

    // if selected payment method doesnt exist in list add it in so it renders correctly
    if (
      paymentMethod &&
      paymentMethod.id &&
      !paymentMethods.find((method) => method.id === paymentMethod.id)
    ) {
      paymentMethods = [paymentMethod, ...paymentMethods]
    }
    if (paymentMethods.length > 0) {
      paymentMethods = [newPaymentMethod, ...paymentMethods]
    }
    const disabled = !hasAccount

    return (
      <div style={{ width: '100%' }}>
        <Dropdown
          testId="choose-payment"
          disabled={disabled}
          error={errors.paymentMethod}
          label="Choose Payment"
          width="100%"
          marginBottom="0"
          value={paymentMethodId}
          onChange={this.onSelectPaymentMethod}
        >
          {paymentMethods.map((a) => (
            <option key={a.id} value={a.id}>
              {a.shortName}
            </option>
          ))}
        </Dropdown>
      </div>
    )
  }

  render() {
    const { hasAccount, width } = this.props
    const { isNewPaymentMethod, needsBraintreeLoad } = this.state

    return (
      <div style={{ width }}>
        {needsBraintreeLoad ? (
          <p> Loading Braintree </p>
        ) : (
          <FlexContainer width="100%" flexDirection="column">
            {this.renderPaymentMethodDropdown()}
            {hasAccount && !isNewPaymentMethod && (
              <div>
                <YSpacing height="3px" />
                <LinkText
                  testId="add-payment-method"
                  fontSize="11px"
                  label="Add Payment Method"
                  onClick={this.onShowNewPaymentMethod}
                />
              </div>
            )}
          </FlexContainer>
        )}
        {!needsBraintreeLoad &&
          isNewPaymentMethod &&
          this.renderNewPaymentMethod()}
      </div>
    )
  }
}

PaymentSection.propTypes = {
  hasAccount: PropTypes.bool,
  isManualChargeSection: PropTypes.bool,
  paymentMethod: PropTypes.object,
  paymentMethods: PropTypes.arrayOf(PropTypes.object),
  newPaymentMethod: PropTypes.object,
  width: PropTypes.string,
  errors: PropTypes.object,

  clearError: PropTypes.func,
  initBraintree: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onShowNewPaymentMethod: PropTypes.func,
  pBuildPaymentMethod: PropTypes.func,
  pFormatCreditCard: PropTypes.func,
  pFormatMMYY: PropTypes.func,
  updateNonce: PropTypes.func,
}

PaymentSection.defaultProps = {
  errors: {},
  paymentMethods: [],
}

export default PaymentSection
