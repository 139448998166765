import { BaseService } from 'hungry-core2'
import Schema from './schema'

export default class ValidationService extends BaseService {
  validate = (schema) => (data) => {
    schema = new Schema(schema)
    const { isValid, errors } = schema.validate(data)

    return { isValid, errors }
  }
}
