import {
  SET_GROUP_ORDER_PAGE,
  CLEAR_GROUP_ORDER_PAGE,
} from '@actions/groupOrderPage'

const initialGroupOrderPageState = {
  account: undefined,
  id: undefined,
  date: undefined,
}

export function groupOrderPage(state = initialGroupOrderPageState, action) {
  const { type, groupOrderPage } = action

  switch (type) {
    case SET_GROUP_ORDER_PAGE:
      return { ...groupOrderPage }
    case CLEAR_GROUP_ORDER_PAGE:
      return { ...initialGroupOrderPageState }
    default:
      return state
  }
}
