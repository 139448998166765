import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import { BiPencil, BiTrash } from 'react-icons/bi'
import Panel from '@components/common/Panel'
import { ButtonsBar } from '@res/styledComponents/index'
import Table from '@components/common/Table'
import { Input } from '@components/common/form'

class DeliveryZoneListGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      offset: 0,
      perPage: 10,
      search: '',
      deliveryZones: [],
    }
  }

  componentDidMount() {
    this.loadDeliveryZones(0)
  }

  componentDidUpdate(prevProps) {
    if (this.props.deliveryZones !== prevProps.deliveryZones) {
      this.loadDeliveryZones(0)
    }
  }

  getPageCount = () => {
    const { deliveryZones } = this.props
    const { perPage } = this.state

    return Math.ceil(deliveryZones.length / perPage)
  }

  loadDeliveryZones = (offset) => {
    const { deliveryZones } = this.props
    const { perPage } = this.state
    this.setState({
      offset,
      deliveryZones: deliveryZones.slice(offset, offset + perPage),
    })
  }

  handlePageClick = (data) => {
    const { selected } = data
    const offset = Math.ceil(selected * this.state.perPage)
    this.loadDeliveryZones(offset)
  }

  render() {
    const { onCreate, onDelete, onEdit } = this.props
    const { deliveryZones, offset, perPage, search } = this.state

    return (
      <div>
        <ButtonsBar>
          <button onClick={onCreate}>
            <BiPencil /> New Delivery Zone{' '}
          </button>
        </ButtonsBar>
        <Panel width="100%" maxWidth="500px">
          <Input
            label="Search"
            value={search}
            placeholder="Search Zips"
            onChange={(e) => {
              const search = e.target.value
              const rgx = new RegExp(search, 'g')
              const deliveryZones = this.props.deliveryZones
                .filter((z) => z.zipcode.match(rgx))
                .slice(offset, perPage)
              this.setState({ search, deliveryZones })
            }}
          />
          <Table headings={['Zip Codes', '']}>
            {deliveryZones.map((zone) => (
              <tr key={zone.id}>
                <td onClick={() => onEdit(zone)} id="deliveryZone">
                  {zone.zipcode}
                </td>
                <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <BiTrash onClick={() => onDelete(zone)} />
                </td>
              </tr>
            ))}
          </Table>
          {deliveryZones.length > 0 && (
            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.getPageCount()}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </Panel>
      </div>
    )
  }
}

DeliveryZoneListGroup.propTypes = {
  deliveryZones: PropTypes.array,

  loadDeliveryZones: PropTypes.func,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,

  hqId: PropTypes.number,
}

export default DeliveryZoneListGroup
