import React from 'react'
import PropTypes from 'prop-types'

const DividerLine = (props) => {
  const { background, height, width, margin } = props

  return (
    <div
      style={{
        height,
        width,
        background,
        margin,
      }}
    />
  )
}

DividerLine.propTypes = {
  background: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
}

DividerLine.defaultProps = {
  height: '0.5px',
  width: '100%',
  background: '#c9c9c9',
}

export default DividerLine
