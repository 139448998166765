import React from 'react'
import PropTypes from 'prop-types'
import { FormInputContainer } from '@res/styledComponents/index'
import Error from '@components/common/form/Error'

const FileUpload = (props) => {
  const {
    error,
    value,
    disabled,
    label,
    marginBottom,
    width,
    onChange,
    onKeyDown,
  } = props

  return (
    <FormInputContainer width={width} marginBottom={marginBottom}>
      <label>{label}</label>
      <label htmlFor="file-upload" className="file-upload-button">
        <p className="file-upload-text">Click to upload</p>
        <input
          id="file-upload"
          type="file"
          multiple
          disabled={disabled}
          style={{ cursor: disabled ? 'not-allowed' : 'auto' }}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      </label>
      {error && <Error error={error} />}
    </FormInputContainer>
  )
}

FileUpload.propTypes = {
  error: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.boolean,
  marginBottom: PropTypes.string,
  width: PropTypes.string,
  type: PropTypes.string,
  testId: PropTypes.string,

  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
}

FileUpload.defaultProps = {
  disabled: false,
  marginBottom: '20px',
  width: '100%',
  type: 'text',
}

export default FileUpload
