import {
  TOGGLE_EDIT_CHEF_PAYOUTS_MODAL,
  TOGGLE_EDIT_ORDER_MODAL,
  TOGGLE_INVOICE_BUTTON_ENABLED,
  SET_LAST_LOAD_ORDERS_PARAMS,
  SET_PAYMENT_PROCESSING,
} from '@actions/ui/orderPage'

import { SECTION1 } from '../../../constants'

const initialState = {
  on: true,
  page: undefined,
  search: undefined,
  status: undefined,
  processing: false,
}
const initialModalState = { show: false, mode: 'edit', section: SECTION1 }

export const orderPage = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_INVOICE_BUTTON_ENABLED:
      return {
        ...state,
        on: action.on,
      }
    case SET_LAST_LOAD_ORDERS_PARAMS: {
      const { page, search, status } = action

      return {
        ...state,
        page,
        search,
        status,
      }
    }
    case SET_PAYMENT_PROCESSING: {
      return {
        ...state,
        processing: action.processing,
      }
    }
    default:
      return state
  }
}

export const editChefPayoutsModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_CHEF_PAYOUTS_MODAL: {
      const { show } = action

      return { show }
    }
    default:
      return state
  }
}

export const editOrderModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_ORDER_MODAL: {
      const { show, mode = state.mode, section = state.section } = action

      return { show, mode, section }
    }
    default:
      return state
  }
}
