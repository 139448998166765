import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Input, LinkText } from '@components/common/form'
import { AutocompleteInput } from '@containers/common/form'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { colors } from '../../../../../constants'

class AccountSection extends Component {
  state = {
    accountInput: '',
    isExpanded: false,
  }

  onCancel = () => {
    this.setState({ isExpanded: false }, () => {
      this.props.onSelectAccount(this.state.account)
    })
  }

  onInput = (e) => {
    const accountInput = e.target.value
    this.setState({ accountInput })

    this.props.onChange({ newAccount: { name: accountInput } })
  }

  onSave = () => {
    this.setState({ isExpanded: false })
    this.props.onSave()
  }

  onSelectAccount = (account) => {
    this.props.onSelectAccount({ ...account })
  }

  onShowNewAccount = () => {
    this.setState({ isExpanded: true })
    this.props.onSelectAccount(null)
  }

  render() {
    const { loadAccounts, account, width } = this.props
    const { accountInput, isExpanded } = this.state
    const accountName = (account && account.name) || ''

    return (
      <div style={{ width }}>
        {isExpanded ? (
          <div style={{ width: '100%' }}>
            <Input
              label="Customer Account"
              value={accountInput}
              onChange={this.onInput}
            />
            <FlexContainer alignItems="center" justifyContent="flex-end">
              <LinkText
                label="Cancel"
                onClick={this.onCancel}
                color={colors.violet}
              />
              <XSpacing width="20px" />
              <Button label="Save" onClick={this.onSave} />
            </FlexContainer>
          </div>
        ) : (
          <AutocompleteInput
            width="100%"
            label="Customer Account"
            value={accountName}
            loaderFunction={loadAccounts}
            onSelect={this.onSelectAccount}
          />
        )}
        {!isExpanded && (
          <div>
            <YSpacing height="3px" />
            <LinkText
              fontSize="11px"
              label="Add New Account"
              onClick={this.onShowNewAccount}
            />
          </div>
        )}
      </div>
    )
  }
}

AccountSection.propTypes = {
  account: PropTypes.object,
  orderType: PropTypes.string,
  width: PropTypes.string,

  loadAccounts: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onSelectAccount: PropTypes.func,
}

export default AccountSection
