export const SearchRecurringPopUps =
  ({ RestService, HandleError }) =>
  async ({ headquarter, search, statuses, limit = 100, offset = 0 }) => {
    try {
      const params = {
        headquarter,
        offset,
        limit,
      }
      if (search != null) {
        params.search = search
      }
      if (statuses) {
        params.status = statuses
      }

      return await RestService.get('/pop-ups/recurring', params)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const GetRecurringPopUp =
  ({ RestService, HandleError }) =>
  async (id) => {
    try {
      return await RestService.get(`/pop-ups/recurring/${id}`)
    } catch (error) {
      HandleError({ error })
    }
  }

export const SaveRecurringPopUp =
  ({ RestService, UIService, HandleError }) =>
  async (rPopUp) => {
    try {
      if (rPopUp.id) {
        await RestService.put(`/pop-ups/recurring/${rPopUp.id}`, rPopUp)
      } else {
        await RestService.post('/pop-ups/recurring', rPopUp)
      }
      UIService.FlashMessage.displaySuccessMessage(
        `Recurring Pop Up ${rPopUp.name} successfully updated`,
      )

      return true
    } catch (error) {
      HandleError({ error })

      return false
    }
  }

export const CreatePopUpFromTimeSlot =
  ({ RestService, UIService, HandleError }) =>
  async (rPopUpId, timeSlot, date) => {
    try {
      await RestService.post(
        `/pop-ups/recurring/${rPopUpId}/timeslot/${timeSlot}`,
        { date },
      )
      UIService.FlashMessage.displaySuccessMessage(
        'Pop Up successfully created',
      )
    } catch (error) {
      HandleError({ error })
    }
  }
