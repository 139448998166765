export const SavePackagingAddOn =
  ({
    LoadPackagingAddOns,
    RestService,
    SupplyService,
    pRequestUpdatePackagingAddOn,
    pResponsePackagingAddOns,
    HandleError,
  }) =>
  async (data) => {
    const req = pRequestUpdatePackagingAddOn(data)
    const { id } = req.packaging_add_on

    try {
      if (id) {
        await RestService.put(`/api/admin/packaging_add_ons/${id}`, req)
      } else {
        await RestService.post('/api/admin/packaging_add_ons', req)
      }

      await LoadPackagingAddOns({
        RestService,
        SupplyService,
        pResponsePackagingAddOns,
        HandleError,
      })()

      return true
    } catch (error) {
      HandleError({ error, namespace: 'packagingAddOns', doFlash: true })

      return false
    }
  }

export const SavePackaging =
  ({
    LoadPackaging,
    RestService,
    SupplyService,
    pRequestUpdatePackaging,
    pResponsePackaging,
    HandleError,
  }) =>
  async (data) => {
    const req = pRequestUpdatePackaging(data)
    const { id } = data

    try {
      if (id) {
        await RestService.put(`/api/admin/packaging/${id}`, req)
      } else {
        await RestService.post('/api/admin/packaging', req)
      }

      await LoadPackaging({
        RestService,
        SupplyService,
        pResponsePackaging,
        HandleError,
      })()

      return true
    } catch (error) {
      HandleError({ error, namespace: 'packaging', doFlash: true })

      return false
    }
  }

export const SaveServingUtensil =
  ({
    LoadServingUtensils,
    RestService,
    SupplyService,
    pRequestUpdateServingUtensil,
    pResponseServingUtensils,
    HandleError,
  }) =>
  async (data) => {
    const req = pRequestUpdateServingUtensil(data)
    const { id } = data

    try {
      if (id) {
        await RestService.put(`/api/admin/serving_utensils/${id}`, req)
      } else {
        await RestService.post('/api/admin/serving_utensils', req)
      }

      await LoadServingUtensils({
        RestService,
        SupplyService,
        pResponseServingUtensils,
        HandleError,
      })()

      return true
    } catch (error) {
      HandleError({ error, namespace: 'servingUtensils', doFlash: true })

      return false
    }
  }

export const SaveCateringSupply =
  ({
    LoadCateringSupplies,
    RestService,
    SupplyService,
    pRequestUpdateCateringSupply,
    pResponseCateringSupplies,
    HandleError,
  }) =>
  async (data) => {
    const req = pRequestUpdateCateringSupply(data)
    const { id } = req.catering_supply

    try {
      if (id) {
        await RestService.put(`/api/admin/catering_supplies/${id}`, req)
      } else {
        await RestService.post('/api/admin/catering_supplies', req)
      }

      await LoadCateringSupplies({
        RestService,
        SupplyService,
        pResponseCateringSupplies,
        HandleError,
      })()

      return true
    } catch (error) {
      HandleError({ error, namespace: 'cateringSupplies', doFlash: true })

      return false
    }
  }

export const SaveDiningSupply =
  ({
    LoadDiningSupplies,
    RestService,
    SupplyService,
    pRequestUpdateDiningSupply,
    pResponseDiningSupplies,
    HandleError,
  }) =>
  async (data) => {
    const req = pRequestUpdateDiningSupply(data)
    const { id } = req.dining_supply

    try {
      if (id) {
        await RestService.put(`/api/admin/dining_supplies/${id}`, req)
      } else {
        await RestService.post('/api/admin/dining_supplies', req)
      }

      await LoadDiningSupplies({
        RestService,
        SupplyService,
        pResponseDiningSupplies,
        HandleError,
      })()

      return true
    } catch (error) {
      HandleError({ error, namespace: 'diningSupplies', doFlash: true })

      return false
    }
  }

export const SaveInventory =
  ({
    LoadInventories,
    RestService,
    SupplyService,
    pRequestUpdateInventory,
    pResponseInventories,
    HandleError,
  }) =>
  async (data) => {
    try {
      const req = pRequestUpdateInventory(data)
      await RestService.put(`/api/admin/inventories/${req.inventory.id}`, req)
      await LoadInventories({
        RestService,
        SupplyService,
        pResponseInventories,
        HandleError,
      })()

      return true
    } catch (error) {
      HandleError({ error, namespace: 'servingUtensils', doFlash: true })

      return false
    }
  }
