import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors } from '../../../constants'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { CgNotes } from 'react-icons/cg'
import { FiAlertTriangle } from 'react-icons/fi'

const Tooltip = (props) => {
  const { width, iconType, children, margin, isTooltipBottom } = props
  const [showTooltip, setShowTooltip] = useState(false)

  const renderIcon = (iconType) => {
    switch (iconType) {
      case 'info':
        return <AiOutlineInfoCircle size={20} color={colors.blue400} />
      case 'notes':
        return <CgNotes size={20} color={colors.blue400} />
      case 'alert':
        return <FiAlertTriangle size={20} color={colors.orange} />
      default:
        return <AiOutlineInfoCircle size={20} color={colors.blue400} />
    }
  }

  return (
    <TooltipContainer>
      {showTooltip && (
        <Tip width={width} isTooltipBottom={isTooltipBottom}>
          {children}
        </Tip>
      )}
      <Child
        onClick={() => setShowTooltip(!showTooltip)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <div style={{ margin }}>{renderIcon(iconType)}</div>
      </Child>
    </TooltipContainer>
  )
}

const TooltipContainer = styled.div`
  position: relative;
`

const Child = styled.button`
  display: inline-block;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
`

const Tip = styled.div`
  position: absolute;
  background: #fff;
  padding: 15px;
  width: ${(props) => (props.width ? props.width : '220px')};
  z-index: 10;
  line-height: 1.4;
  font-family: 'regular';
  color: ${colors.gray400};
  bottom: ${(props) => (props.isTooltipBottom ? 'auto' : '25px')};
  top: ${(props) => (props.isTooltipBottom ? '25px' : 'auto')};
  border-radius: 4px;
  border: 1px solid ${colors.gray300};
  transform: translate(-50%, 0);
  box-shadow:
    0 10px 15px -3px rgba(154, 167, 237, 0.3),
    0 4px 6px -2px rgba(0, 0, 0, 0.1);
`

Tooltip.propTypes = {
  width: PropTypes.string,
  margin: PropTypes.string,
  iconType: PropTypes.string,
  isTooltipBottom: PropTypes.bool,
  children: PropTypes.object,
}

export default Tooltip
