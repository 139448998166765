import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'

class SidebarSalesRepFilter extends Component {
  state = {
    options: [],
    selected: '',
  }
  searchTimer = undefined

  loadOptions = async () => {
    const options = await this.props.loaderFunction({
      page: 1,
      results_per_page: 15,
      search: '',
    })
    this.setState({ options })
  }

  updateOption = (e) => {
    const selected = e.target.value
    this.setState({ selected })
    this.props.onSelect(selected)
  }

  render() {
    const { options } = this.state

    return (
      <Dropdown>
        <label>{this.props.label}</label>
        {
          <select
            value={this.state.selected}
            onChange={this.updateOption}
            onFocus={this.loadOptions}
          >
            <option key={'All'} value={'all'}>
              All
            </option>
            {options.map((o) => (
              <option key={o} value={o.id} selected={o.name}>
                {' '}
                {o.name}{' '}
              </option>
            ))}
          </select>
        }
      </Dropdown>
    )
  }
}

const Dropdown = styled.div`
  padding: 0 20px;
  margin-bottom: 10px;
  label {
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
  }
  select {
    width: 100%;
    box-sizing: border-box;
    padding: 6px 10px;
    background: rgba(255, 255, 255, 0.15);
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    font-size: 15px;
    ::placeholder {
      color: rgba(255, 255, 255, 0.6);
      font-family: 'regular';
    }
    option {
      color: gray;
    }
  }
`

SidebarSalesRepFilter.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.string,
  label: PropTypes.string,

  loaderFunction: PropTypes.func,
  onSelect: PropTypes.func,
}

export default SidebarSalesRepFilter
