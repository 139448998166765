import React from 'react'
import Boxes from './Boxes'
import { Link } from 'react-router-dom'

export const links = [
  {
    name: 'Clients',
    link: '/clients',
    color: '#ec8871',
  },
  {
    name: 'Proposals',
    link: '/proposals',
    color: '#ed7a8b',
  },
  {
    name: 'Sales',
    link: '/sales',
    color: '#FF0D86',
  },
  {
    name: 'Pop Ups',
    link: '/pop-ups',
    color: 'linear-gradient(to right,#ed709d, #ec8970)',
  },
  {
    name: 'Group Orders',
    link: '/group-orders',
    color: 'linear-gradient(to right,#dd8ab7, #c1d3f9)',
  },
  {
    name: 'AR Dashboard',
    link: '/dashboard',
    color: 'linear-gradient(to right,#8489e1, #82d3cc)',
  },
  {
    name: 'Chefs',
    link: '/chefs',
    color: '#4950da',
  },
  {
    name: 'Supplies',
    link: '/supplies',
    color: '#8489e1',
  },
  {
    name: 'Reports',
    link: '/reports',
    color: '#98c4f9',
  },
]

const Homepage = () => {
  return (
    <div className="charts-container">
      <div className="boxes-container">
        {links.map((l) => (
          <Link key={l.name} to={l.link} style={{ textDecoration: 'none' }}>
            <Boxes name={l.name} color={l.color} />
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Homepage
