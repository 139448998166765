import { connect } from 'react-redux'

import { ChefProfile } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { chef } = state

  return { chef }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, UIService } = services
  const { EditChef, EditChildChefs, SendChefDashboardInvite } = coordinators

  const editChef = EditChef({ ChefService, UIService })
  const editChildChefs = EditChildChefs({ ChefService, UIService })
  const sendChefDashboardInvite = SendChefDashboardInvite({
    RestService,
    UIService,
    HandleError,
  })

  return {
    editChef,
    editChildChefs,
    sendChefDashboardInvite,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChefProfile)
