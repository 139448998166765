export const LoadSubscribers =
  ({ SubscriptionService, RestService, pResponseSubscriber, HandleError }) =>
  async ({ hqId, searchTerm, repId }) => {
    try {
      let allMembers = []
      let offset = 0
      const limit = 100
      while (true) {
        const req = {
          hq: hqId,
          offset,
          limit,
          search: searchTerm,
        }
        if (repId) {
          req.repId = repId
        }
        const members =
          (await RestService.get('/subscriptions/members', req)) || []
        if (members.length === 0) {
          break
        }
        allMembers.push(...members)
        offset += limit
      }
      allMembers = allMembers.map((m) => pResponseSubscriber(m))
      SubscriptionService.setMembers(allMembers)
    } catch (error) {
      HandleError({ error })
    }
  }

export const CreateSubscriber =
  ({ RestService, pRequestSubscriber, HandleError }) =>
  async (info) => {
    let member
    try {
      member = await RestService.post(
        '/subscriptions/members',
        pRequestSubscriber(info),
      )
    } catch (error) {
      HandleError({ error })

      return undefined
    }

    return member
  }

export const LinkSubscriber =
  ({ RestService, pRequestSubscriber, HandleError }) =>
  (info) => {
    let member
    try {
      member = RestService.post(
        '/subscriptions/members/link',
        pRequestSubscriber(info),
      )
    } catch (error) {
      HandleError({ error })

      return undefined
    }

    return member
  }

export const UpdateSubscriber =
  ({
    RestService,
    SubscriptionService,
    pRequestUpdateSubscriber,
    pResponseSubscriber,
    HandleError,
  }) =>
  async (memberId, info) => {
    let member
    try {
      member = await RestService.put(
        `/subscriptions/members/${memberId}`,
        pRequestUpdateSubscriber(info),
      )
      SubscriptionService.replaceMember(pResponseSubscriber(member))
    } catch (error) {
      HandleError({ error })

      return undefined
    }

    return member
  }

export const UpdateSubscriberWeekdays =
  ({ RestService, SubscriptionService, pResponseSubscriber, HandleError }) =>
  async (memberId, weekdays) => {
    let member
    try {
      member = await RestService.put(
        `/subscriptions/members/${memberId}/days`,
        { weekdays },
      )
      SubscriptionService.replaceMember(pResponseSubscriber(member))
    } catch (error) {
      HandleError({ error })

      return undefined
    }

    return member
  }

export const ChooseMemberSalesRep =
  ({ RestService, pRequestChooseSalesRep, HandleError }) =>
  async (member, salesRep) => {
    try {
      const req = pRequestChooseSalesRep(salesRep)
      await RestService.put(
        `/subscriptions/members/${member.id}/choose_rep`,
        req,
      )

      return true
    } catch (error) {
      HandleError({ error })
    }

    return false
  }
