import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SidebarSearch from '@components/sidebar/SidebarSearch'
import SidebarListItem from '@components/sidebar/SidebarListItem'

export class UserMaster extends Component {
  state = {
    search: '',
  }

  componentDidMount() {
    const { filter } = this.props
    const { search } = this.state
    this.props.loadUsers({ role: filter, search })
    this.props.loadHeadquarters()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filter !== this.props.filter) {
      const { search } = this.state
      this.props.loadUsers({ role: nextProps.filter, search })
    }
  }

  onSelectUser = (userId) => {
    this.setState({ activeUserId: userId })
    this.props.selectUser(userId)
  }

  onSearch = (e) => {
    const { filter, loadUsers } = this.props
    const search = e.target.value

    this.setState({ search }, () => {
      clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(() => {
        loadUsers({ role: filter, search })
      }, 300)
    })
  }

  render() {
    const { users } = this.props
    const { activeUserId } = this.state

    return (
      <div>
        <SidebarSearch label="Search Users" onChange={this.onSearch} />
        {users.map((u) => {
          const selected = u.id === activeUserId

          return (
            <SidebarListItem
              key={u.id}
              isSelected={selected}
              onClick={() => this.onSelectUser(u.id)}
            >
              {u.name}
            </SidebarListItem>
          )
        })}
      </div>
    )
  }
}

UserMaster.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.object,

  loadHeadquarters: PropTypes.func,
  loadUsers: PropTypes.func,
  selectUser: PropTypes.func,
}

export default UserMaster
