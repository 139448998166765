import {
  ClientSettings,
  ClientGlobalSettings,
  SubsidyType,
  Subsidy,
  DateMenus,
  GroupOrder,
  Menu,
  MenuItem,
} from '@types'
import Moment from 'moment-timezone'
import { GroupOrderDeliveryFeeType } from '../view/components/groupOrders/constants'

export const FLAT_PRICE_CONFIG_ERROR = `You have added a Flat Price Subsidy for this location but you have not configured flat price subsidy settings for this client. Please configure flat price subsidy settings for client and save client global settings before proceeding.`

export const GLOBAL_FLAT_PRICE_CONFIG_ERROR = `Some locations include flat price subsidies, but flat price settings are not configured at the client level. Either remove the flat price subsidies from client locations or configure flat price settings at the client level.`

export const FLAT_PRICE_CONFIG_WARNING = `You have configured flat price settings at the client level but some subsidies for this location are not setup as flat price.`

export const GLOBAL_FLAT_PRICE_CONFIG_WARNING = `You have configured flat price settings at the client level but some locations include subsidies that are not setup as flat price.`

export const HUNGRY_WALLET_CONFIG_ERROR = `You have added a Hungry Wallet Subsidy for this location but you have not configured Hungry Wallet settings for this client. Please configure Hungry Wallet settings for client and save client global settings before proceeding.`

export const GLOBAL_HUNGRY_WALLET_CONFIG_ERROR = `Some locations include Hungry Wallet subsidies, but Hungry Wallet settings are not configured at the client level. Either remove the Hungry Wallet subsidies from client locations or configure Hungry Wallet settings at the client level.`

export const HUNGRY_WALLET_CONFIG_WARNING = `You have configured Hungry Wallet settings at the client level but some subsidies for this location are not setup as Hungry Wallet.`

export const GLOBAL_HUNGRY_WALLET_CONFIG_WARNING = `You have configured Hungry Wallet settings at the client level but some locations include subsidies that are not setup as Hungry Wallet.`

export const DELIVERY_FEE_CONFIG_WARNING = `You have delivery fees configured at the client level AND the location level. Please note that client-level delivery fee settings will take precedence.`

// TODO: determine which subsidy types can be set to fully subsidized
export const SUBSIDY_TYPES_NO_FULLY_SUBSIDIZED = [
  SubsidyType.FLAT_PRICE,
  SubsidyType.HUNGRY_WALLET,
]

export type ValidationResult = {
  errors: string[]
  warnings: string[]
}

export const ValidateClientSettings = (
  clientSettings: ClientSettings,
  clientGlobalSettings: ClientGlobalSettings | null,
  allClientSettings: ClientSettings[],
): ValidationResult => {
  let errors: string[] = []
  const warnings: string[] = []

  const settingsWithDupeName = allClientSettings.find((cs) => {
    return cs.id !== clientSettings.id && cs.name === clientSettings.name
  })

  if (settingsWithDupeName) {
    const error = `Name "${
      clientSettings.name || clientSettings.client.name
    }" is already taken for another location.`
    errors.push(error)
  }

  // If client settings has flat price subsidy, require that client global settings has
  // flat price configured
  const hasActiveFlatPriceSubsidy = (
    clientSettings.subsidySettings?.subsidies || []
  ).some((subsidy) => {
    return subsidy.subsidyType === SubsidyType.FLAT_PRICE
  })
  const hasActiveNonFlatPriceSubsidies = (
    clientSettings.subsidySettings?.subsidies || []
  ).some((subsidy) => {
    return subsidy.subsidyType !== SubsidyType.FLAT_PRICE
  })

  const hasFlatPriceSettingsConfigured = Boolean(
    clientGlobalSettings &&
      clientGlobalSettings.isFlatPriceEnabled &&
      clientGlobalSettings.flatPriceMap,
  )

  if (hasActiveFlatPriceSubsidy && !hasFlatPriceSettingsConfigured) {
    errors.push(FLAT_PRICE_CONFIG_ERROR)
  }

  // Add warning if user has flat price config'ed but other subsidy types (this is a rare use case)
  if (hasFlatPriceSettingsConfigured && hasActiveNonFlatPriceSubsidies) {
    warnings.push(FLAT_PRICE_CONFIG_WARNING)
  }

  // If client settings has hungry wallet subsidy, require that client global settings has
  // hungry wallet configured
  const hasActiveHungryWalletSubsidy = (
    clientSettings.subsidySettings?.subsidies || []
  ).some((subsidy) => {
    return subsidy.subsidyType === SubsidyType.HUNGRY_WALLET
  })
  const hasActiveNonHungryWalletSubsidies = (
    clientSettings.subsidySettings?.subsidies || []
  ).some((subsidy) => {
    return subsidy.subsidyType !== SubsidyType.HUNGRY_WALLET
  })

  const hasHungryWalletSettingsConfigured = Boolean(
    clientGlobalSettings &&
      clientGlobalSettings.hungryWalletSettings &&
      clientGlobalSettings.hungryWalletSettings.isActive,
  )

  if (hasActiveHungryWalletSubsidy && !hasHungryWalletSettingsConfigured) {
    errors.push(HUNGRY_WALLET_CONFIG_ERROR)
  }

  // Add warning if user has hungry wallet config'ed but other subsidy types (this is a rare use case)
  if (hasHungryWalletSettingsConfigured && hasActiveNonHungryWalletSubsidies) {
    warnings.push(HUNGRY_WALLET_CONFIG_WARNING)
  }

  const deliveryFeeSettings = clientGlobalSettings?.deliveryFeeSettings || null

  const hasGlobalDeliveryFeeSettings =
    deliveryFeeSettings &&
    (Boolean(parseFloat(deliveryFeeSettings.flatFee)) ||
      Boolean(parseFloat(deliveryFeeSettings.percentFee)) ||
      Boolean(parseFloat(deliveryFeeSettings.perOrderFee)))

  const hasLocationLevelDeliveryFeeSettings = Boolean(
    parseFloat(clientSettings.deliveryFee),
  )

  if (hasGlobalDeliveryFeeSettings && hasLocationLevelDeliveryFeeSettings) {
    warnings.push(DELIVERY_FEE_CONFIG_WARNING)
  }

  const subsidyLevelErrors: string[] = []

  if (clientSettings.subsidySettings) {
    clientSettings.subsidySettings.subsidies.forEach((subsidy) => {
      if (
        subsidy.fullySubsidized &&
        SUBSIDY_TYPES_NO_FULLY_SUBSIDIZED.includes(
          subsidy.subsidyType as SubsidyType,
        )
      ) {
        const error = `Subsidy type ${subsidy.subsidyType} cannot be set as fully subsidized.`
        subsidyLevelErrors.push(error)
      }
    })
  }

  errors = errors.concat(subsidyLevelErrors)

  return { errors, warnings }
}

export const ValidateClientGlobalSettings = (
  clientGlobalSettings: ClientGlobalSettings,
  allClientSettings: ClientSettings[],
): ValidationResult => {
  const errors: string[] = []
  const warnings: string[] = []

  // If any client settings has flat price subsidy, require that client global settings has
  // flat price configured
  const hasActiveFlatPriceSubsidy = allClientSettings.some((cs) => {
    return (cs.subsidySettings?.subsidies || []).some((subsidy) => {
      return subsidy.subsidyType === SubsidyType.FLAT_PRICE
    })
  })
  const hasActiveNonFlatPriceSubsidies = allClientSettings.some((cs) => {
    return (cs.subsidySettings?.subsidies || []).some((subsidy) => {
      return subsidy.subsidyType !== SubsidyType.FLAT_PRICE
    })
  })

  const hasFlatPriceSettingsConfigured = Boolean(
    clientGlobalSettings.isFlatPriceEnabled &&
      clientGlobalSettings.flatPriceMap,
  )

  if (hasActiveFlatPriceSubsidy && !hasFlatPriceSettingsConfigured) {
    errors.push(GLOBAL_FLAT_PRICE_CONFIG_ERROR)
  }

  // Add warning if user has flat price config'ed but other subsidy types (this is a rare use case)
  if (hasFlatPriceSettingsConfigured && hasActiveNonFlatPriceSubsidies) {
    warnings.push(GLOBAL_FLAT_PRICE_CONFIG_WARNING)
  }

  // If client settings has hungry wallet subsidy, require that client global settings has
  // hungry wallet configured
  const hasActiveHungryWalletSubsidy = allClientSettings.some((cs) => {
    return (cs.subsidySettings?.subsidies || []).some((subsidy) => {
      return subsidy.subsidyType === SubsidyType.HUNGRY_WALLET
    })
  })
  const hasActiveNonHungryWalletSubsidies = allClientSettings.some((cs) => {
    return (cs.subsidySettings?.subsidies || []).some((subsidy) => {
      return subsidy.subsidyType !== SubsidyType.HUNGRY_WALLET
    })
  })

  const hasHungryWalletSettingsConfigured = Boolean(
    clientGlobalSettings.hungryWalletSettings &&
      clientGlobalSettings.hungryWalletSettings.isActive,
  )

  if (hasActiveHungryWalletSubsidy && !hasHungryWalletSettingsConfigured) {
    errors.push(GLOBAL_HUNGRY_WALLET_CONFIG_ERROR)
  }

  // Add warning if user has hungry wallet config'ed but other subsidy types (this is a rare use case)
  if (hasHungryWalletSettingsConfigured && hasActiveNonHungryWalletSubsidies) {
    warnings.push(GLOBAL_HUNGRY_WALLET_CONFIG_WARNING)
  }

  const { deliveryFeeSettings } = clientGlobalSettings

  const hasGlobalDeliveryFeeSettings =
    deliveryFeeSettings &&
    (Boolean(parseFloat(deliveryFeeSettings.flatFee)) ||
      Boolean(parseFloat(deliveryFeeSettings.percentFee)) ||
      Boolean(parseFloat(deliveryFeeSettings.perOrderFee)))

  const hasLocationLevelDeliveryFeeSettings = allClientSettings.some((cs) => {
    return Boolean(parseFloat(cs.deliveryFee))
  })

  if (hasGlobalDeliveryFeeSettings && hasLocationLevelDeliveryFeeSettings) {
    warnings.push(DELIVERY_FEE_CONFIG_WARNING)
  }

  return { errors, warnings }
}

export const ValidateDateMenus = (
  dateMenus: DateMenus,
  clientSettings: ClientSettings,
  groupOrder: GroupOrder | null,
  locale: string,
  groupOrderMinimumItems: number,
): ValidationResult => {
  const errors: string[] = []
  const warnings: string[] = []

  let subsidies: Subsidy[] | null = null
  let hasGroupOrderSubsidySettings = false

  if (groupOrder) {
    subsidies = groupOrder.subsidySettings ? [groupOrder.subsidySettings] : null
    if (subsidies && subsidies.length && subsidies[0].subsidyType) {
      // NOTE: Subsidy settings on GO do not have day of week field, so we just
      // check for their presence and that a subsidyType is assigned
      // when doing below validaiton
      hasGroupOrderSubsidySettings = true
    }
  } else {
    subsidies = clientSettings.subsidySettings?.subsidies
      ? clientSettings.subsidySettings.subsidies
      : null
  }

  // Check if subsidies configured and if so, show warning if date
  // menus is created for day of week without a subsidy configured as this is possibly
  // a user error
  if (subsidies && !hasGroupOrderSubsidySettings) {
    const subsidySettingsMap = GetSubsidySettingsMap(subsidies)
    const date = Moment(dateMenus.date).tz(locale)

    const subsidyForDate = subsidySettingsMap[date.day().toString()]
    if (!subsidyForDate) {
      const warning = `You have subsidies configured for other days of the week but not for ${date.format(
        'dddd',
      )}, the day of this Group Order.`
      warnings.push(warning)
    }
  }

  const uniqueMenuItemsCount = GetUniqueMenuItemsCountForDateMenus(
    dateMenus,
    groupOrder,
  )

  if (uniqueMenuItemsCount < groupOrderMinimumItems) {
    const warning = `The number of unique menu items is below the required minimum for this market. There are only ${uniqueMenuItemsCount} item(s) added but the minimum required is ${groupOrderMinimumItems}.`
    warnings.push(warning)
  }

  return { errors, warnings }
}

export const GetSubsidySettingsMap = (subsidies: Subsidy[] | null): any => {
  const subsidySettingsMap: Record<string, Subsidy> = {}
  if (!subsidies) {
    return subsidySettingsMap
  }
  subsidies.forEach((subsidy) => {
    if (subsidy.days && subsidy.isActive) {
      subsidy.days.forEach((day) => {
        subsidySettingsMap[day.toString()] = subsidy
      })
    }
  })

  return subsidySettingsMap
}

export const GetUniqueMenuItemsCountForDateMenus = (
  dateMenus: DateMenus,
  groupOrder: GroupOrder | null,
): number => {
  let itemsFromDateMenus: string[] = []
  if (dateMenus?.menus) {
    dateMenus.menus.forEach((menu: Menu) => {
      if (menu.active) {
        const itemIds = menu.menuItems.map((item: MenuItem) => item.id)
        itemsFromDateMenus = itemsFromDateMenus.concat(itemIds)
      }
    })
  }

  let itemsFromGroupOrder: string[] = []
  if (groupOrder) {
    groupOrder.orders.forEach((order) => {
      order.menus.forEach((menu) => {
        const itemIds = menu.orderItems.map((orderItem) => orderItem.menuItemId)
        itemsFromGroupOrder = itemsFromGroupOrder.concat(itemIds)
      })
    })
  }

  const nonUniqueMenuItemIds = itemsFromDateMenus.concat(itemsFromGroupOrder)

  const uniqueMenuItemIds = new Set(nonUniqueMenuItemIds)

  return uniqueMenuItemIds.size
}

export const GetDeliveryTypeDisplayName = (deliveryType: string): string => {
  switch (deliveryType) {
    case GroupOrderDeliveryFeeType.STANDARD: {
      return 'Standard'
    }
    case GroupOrderDeliveryFeeType.FIXED: {
      return 'Fixed'
    }
    case GroupOrderDeliveryFeeType.PERCENT: {
      return 'Fixed Percentage'
    }
    case GroupOrderDeliveryFeeType.PER_ORDER: {
      return 'Per Order'
    }
    case GroupOrderDeliveryFeeType.WAIVE_FEE: {
      return 'Waive Fee'
    }
    case GroupOrderDeliveryFeeType.UNDER_LIMIT: {
      return 'Under Limit'
    }
    default: {
      return 'Unknown'
    }
  }
}
