import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import Flatpickr from 'react-flatpickr'
import styled from '@emotion/styled'
import { FormInputContainer } from '@res/styledComponents/index'
import { convertTimeIntoMomentTz, convertTimeIntoBrowserTz } from '~/utils'

class TimeInput extends React.Component {
  state = {
    isFocused: false,
    time: undefined,
  }

  componentWillMount() {
    this.setTime(this.props.time)
  }

  componentWillReceiveProps(nextProps) {
    this.setTime(nextProps.time)
  }

  setTime = (time) => {
    if (time) {
      time.second(0)
      time.millisecond(0)
    }

    this.setState({ time })
  }

  onBlur = () => {
    this.setState({ isFocused: false })
  }

  onChange = (time) => {
    if (time) {
      if (Array.isArray(time)) {
        if (time.length === 0) {
          return null
        } else {
          time = time.pop()
        }
      }
      const { onChange } = this.props

      const timeInTz = convertTimeIntoMomentTz(time)
      this.setState({ time: timeInTz })
      onChange && onChange(timeInTz)
    }
  }

  onFocus = (e) => {
    const { onFocus } = this.props

    this.setState({ isFocused: true })
    onFocus && onFocus(e)
  }

  render() {
    const {
      disabled = false,
      error,
      width,
      label,
      minuteIncrement,
      testId,
      onClear,
    } = this.props
    const { isFocused } = this.state
    const { time } = this.state
    // flatpickr has no tz support only renders in js date or browser time
    // convert input value to date str as js date params so its converted back to browser tz for rendering
    const renderTime = time && convertTimeIntoBrowserTz(time)

    const pickerOptions = {
      dateFormat: 'h:i K',
      enableSeconds: false,
      enableTime: true,
      noCalendar: true,
      static: true,
      minuteIncrement: minuteIncrement > 0 ? minuteIncrement : 5,
    }

    return (
      <FormInputContainer width={width}>
        <label>{label}</label>
        <div style={{ position: 'relative', width: '100%' }}>
          {error && !isFocused && <p className="date-error">{error}</p>}
          <Flatpickr
            name={label}
            data-enable-time
            options={pickerOptions}
            value={renderTime}
            data-testId={testId}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onFocus={this.onFocus}
            disabled={disabled}
          />
          {onClear && <ClearInput onClick={onClear}>✕</ClearInput>}
        </div>
      </FormInputContainer>
    )
  }
}

export const ClearInput = styled.button`
  position: absolute;
  right: 10px;
  bottom: 7px;
`

TimeInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  width: PropTypes.string,
  label: PropTypes.string,
  minuteIncrement: PropTypes.number,
  time: PropTypes.instanceOf(Moment),
  testId: PropTypes.string,

  error: PropTypes.string,

  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onClear: PropTypes.func,
}

export default TimeInput
