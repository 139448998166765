import { connect } from 'react-redux'

import { ConfirmationModal } from '@components/common/modal'

import services from '@services'

const mapStateToProps = (state) => {
  const { show, text, title } = state.confirmationModal

  return {
    show,
    text,
    title,
  }
}

const mapDispatchToProps = () => {
  const { UIService } = services

  const { close, confirm } = UIService.ConfirmationModal

  return {
    close,
    confirm,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal)
