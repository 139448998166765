import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

import Input from '@components/common/form/Input'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import Dropdown from '@components/common/form/Dropdown'

const AllRoles = [
  '',
  'admin',
  'finance',
  'master_account_executive',
  'account_executive',
  'chef_recruiting',
  'chef',
  'captain_lead',
  'catering_captain',
]

const SortOptions = [
  { label: 'Updated At', value: 'updated_at-desc' },
  { label: 'Email', value: 'email-asc' },
]

class UserSearchPage extends Component {
  state = {
    search: '',
    resultsPerPage: 40,
    page: 1,
    users: [],
    roleFilter: '',
    sortOption: 'updated_at-desc',
    sortBy: [undefined, true],
  }
  searchTimer = undefined

  componentDidMount() {
    this.searchUsers()
  }

  searchUsers = async () => {
    const { sortOption } = this.state
    const { searchUsers } = this.props
    const { search, resultsPerPage, page, roleFilter } = this.state

    const searchParams = {
      resultsPerPage,
      page,
      sort: sortOption,
    }
    if (roleFilter) {
      searchParams.role = roleFilter
    }
    if (search) {
      searchParams.search = search
    }

    const users = await searchUsers(searchParams)
    if (users) {
      this.setState({ users })
    }
  }

  searchAfterTimeout = () => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer)
    }
    this.searchTimer = undefined
    this.searchTimer = setTimeout(() => {
      this.searchUsers()
    }, 550)
  }

  sortUsers = (attribute, transform = (a) => a) => {
    const { users, sortBy } = this.state
    const asc = attribute !== sortBy[0] || !sortBy[1]
    this.setState({
      sortBy: [attribute, asc],
      users: users.sort((a, b) => {
        if (transform(a[attribute]) > transform(b[attribute])) {
          return asc ? 1 : -1
        }
        if (transform(a[attribute]) < transform(b[attribute])) {
          return asc ? -1 : 1
        }

        return 0
      }),
    })
  }

  getUserRolesStr = (user) => {
    const { headquarterId } = this.props

    return (user.roles || [])
      .filter((r) => r.resourceId === headquarterId)
      .map((r) => r.name)
      .sort()
      .join(', ')
  }

  sortUsersByRoles = () => {
    const { users, sortBy } = this.state
    const attribute = 'roles'
    const asc = attribute !== sortBy[0] || !sortBy[1]
    this.setState({
      sortBy: [attribute, asc],
      users: users.sort((a, b) => {
        const aRoles = this.getUserRolesStr(a)
        const bRoles = this.getUserRolesStr(b)
        if (aRoles > bRoles) {
          return asc ? 1 : -1
        }
        if (aRoles < bRoles) {
          return asc ? -1 : 1
        }

        return 0
      }),
    })
  }

  render() {
    const { search, resultsPerPage, page, users, roleFilter, sortOption } =
      this.state

    return (
      <Panel width="100%" maxWidth="1200px" heading="Search Users">
        <FlexContainer alignItems="flex-end">
          <Input
            label="Search"
            marginBottom="0"
            width="200px"
            type="text"
            value={search}
            onChange={(e) =>
              this.setState(
                { search: e.target.value, page: 1 },
                this.searchAfterTimeout,
              )
            }
          />
          <XSpacing width="20px" />

          <Dropdown
            marginBottom="0"
            width="200px"
            label="Role"
            value={roleFilter}
            onChange={(e) =>
              this.setState(
                { roleFilter: e.target.value, page: 1 },
                this.searchAfterTimeout,
              )
            }
          >
            {AllRoles.map((role) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </Dropdown>
          <XSpacing width="20px" />

          <Dropdown
            marginBottom="0"
            width="200px"
            label="Sort"
            value={sortOption}
            onChange={(e) =>
              this.setState(
                { sortOption: e.target.value, page: 1 },
                this.searchAfterTimeout,
              )
            }
          >
            {SortOptions.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Dropdown>
          <XSpacing width="20px" />
        </FlexContainer>
        <YSpacing height="20px" />
        <Table>
          <tr>
            <th
              className="cursor-pointer"
              onClick={() => this.sortUsers('email')}
            >
              Email
            </th>
            <th
              className="cursor-pointer"
              onClick={() => this.sortUsers('firstName')}
            >
              First Name
            </th>
            <th
              className="cursor-pointer"
              onClick={() => this.sortUsers('lastName')}
            >
              Last Name
            </th>
            <th
              className="cursor-pointer"
              onClick={() => this.sortUsers('phoneNumber')}
            >
              Phone
            </th>
            <th className="cursor-pointer" onClick={this.sortUsersByRoles}>
              Market Roles
            </th>
            <th
              className="cursor-pointer"
              onClick={() => this.sortUsers('isDisabled')}
            >
              Disabled?
            </th>
            <th
              className="cursor-pointer"
              onClick={() => this.sortUsers('updatedAt')}
            >
              Last Updated
            </th>
          </tr>
          {users.map((user) => (
            <tr key={user.id}>
              <td>
                <p>{user.email}</p>
              </td>
              <td>
                <p>{user.firstName}</p>
              </td>
              <td>
                <p>{user.lastName}</p>
              </td>
              <td>
                <p>{user.phoneNumber}</p>
              </td>
              <td>
                <p>{this.getUserRolesStr(user)}</p>
              </td>
              <td>
                <p>{user.isDisabled ? 'Yes' : 'No'}</p>
              </td>
              <td>
                <p>{Moment(user.updatedAt).format('lll')}</p>
              </td>
            </tr>
          ))}
        </Table>
        {page > 1 && (
          <button
            onClick={() => this.setState({ page: page - 1 }, this.searchUsers)}
          >
            &lt;
          </button>
        )}
        Page {page}
        {resultsPerPage === users.length && (
          <button
            onClick={() => this.setState({ page: page + 1 }, this.searchUsers)}
          >
            &gt;
          </button>
        )}
      </Panel>
    )
  }
}

UserSearchPage.propTypes = {
  headquarterId: PropTypes.number,

  searchUsers: PropTypes.func,
}

export default UserSearchPage
