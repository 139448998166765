import {
  SET_PACKAGING,
  SET_PACKAGING_ADD_ONS,
  SET_SERVING_UTENSILS,
  SET_CATERING_SUPPLIES,
  SET_DINING_SUPPLIES,
  SET_INVENTORIES,
} from '@actions/supply'

const initialPackagingState = []
const initialPackagingAddOnsState = []
const initialServingUtensilsState = []
const initialCateringSuppliesState = []
const initialDiningSuppliesState = []
const initialInventoriesState = {
  records: [],
  dates: [],
}

export function inventories(state = initialInventoriesState, action) {
  const { type } = action

  switch (type) {
    case SET_INVENTORIES: {
      const { inventories } = action

      return inventories
    }
    default:
      return state
  }
}

export function packaging(state = initialPackagingState, action) {
  const { type, packaging } = action

  switch (type) {
    case SET_PACKAGING:
      return packaging
    default:
      return state
  }
}

export function packagingAddOns(state = initialPackagingAddOnsState, action) {
  const { type, packagingAddOns } = action

  switch (type) {
    case SET_PACKAGING_ADD_ONS:
      return packagingAddOns
    default:
      return state
  }
}

export function servingUtensils(state = initialServingUtensilsState, action) {
  const { type, servingUtensils } = action

  switch (type) {
    case SET_SERVING_UTENSILS:
      return servingUtensils
    default:
      return state
  }
}

export function cateringSupplies(state = initialCateringSuppliesState, action) {
  const { type, cateringSupplies } = action

  switch (type) {
    case SET_CATERING_SUPPLIES:
      return cateringSupplies
    default:
      return state
  }
}

export function diningSupplies(state = initialDiningSuppliesState, action) {
  const { type, diningSupplies } = action

  switch (type) {
    case SET_DINING_SUPPLIES:
      return diningSupplies
    default:
      return state
  }
}
