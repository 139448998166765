import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import Modal from '@components/common/modal/Modal'
import { YSpacing, FlexContainer, Loader } from '@components/common'
import { Checkbox } from '@components/common/form'
import Table from '@components/common/Table'
import Button from '@components/common/form/Button'
import { Chef, ChefOrder, UpdateChefOrderAddressReq } from '@types'

interface ChefFutureOrdersModalProps {
  chef: Chef
  headquarterId: number

  close: () => void
  loadOrders: (chefId: string) => Promise<ChefOrder[]>
  updateOrders: (req: UpdateChefOrderAddressReq) => Promise<boolean>
}

const ChefFutureOrdersModal = ({
  chef,
  headquarterId,
  close,
  loadOrders,
  updateOrders,
}: ChefFutureOrdersModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [orders, setOrders] = useState<ChefOrder[]>([])
  const [selectedOrders, setSelectedOrders] = useState<Record<string, boolean>>(
    {},
  )
  const [orderIdsToNumbers, setOrderIdsToNumbers] = useState<
    Record<string, string>
  >({})

  const loadChefOrders = useCallback(async () => {
    setIsLoading(true)
    const orders = await loadOrders(chef.id)
    setOrders(orders)
    setIsLoading(false)
    const orderIdsToNumbers: Record<string, string> = {}
    orders.forEach((o) => {
      orderIdsToNumbers[o.id] = o.orderNumber
    })

    setOrderIdsToNumbers(orderIdsToNumbers)
  }, [chef, loadOrders])

  useEffect(() => {
    loadChefOrders()
  }, [chef])

  const buildSelectedOrderReq = useCallback(() => {
    const out: UpdateChefOrderAddressReq = {
      address: chef.pickupAddress,
      chefId: chef.id,
      groupOrderIdMap: {},
      hqId: headquarterId,
      orderIdsToSyncServiceOrderAddresses: [],
      orderIdsToPersistAddressRevision: [],
    }
    if (chef.pickupAddressRevisionNumber) {
      // Use the previous address revision number for orders that should not be updated with the current address
      out.addressRevisionNumber = chef.pickupAddressRevisionNumber - 1
    }
    for (const orderId in orderIdsToNumbers) {
      const orderNumber = orderIdsToNumbers[orderId]
      if (selectedOrders[orderId]) {
        if (orderNumber.includes('GRP')) {
          out.groupOrderIdMap[orderId] = orderNumber
        } else {
          out.orderIdsToSyncServiceOrderAddresses.push(orderId)
        }
      } else if (!orderNumber.includes('GRP')) {
        out.orderIdsToPersistAddressRevision.push(orderId)
      }
    }

    return out
  }, [selectedOrders, chef])

  const onSelectAll = () => {
    const allSelected = Object.values(selectedOrders).every((o) => o)
    const newSelection = orders.reduce(
      (acc: Record<string, boolean>, order) => {
        acc[order.id] = !allSelected

        return acc
      },
      {},
    )
    setSelectedOrders(newSelection)
  }

  const onSave = async () => {
    setIsLoading(true)
    const req = buildSelectedOrderReq()
    const success = await updateOrders(req)
    if (success) {
      close()
    }
    setIsLoading(false)
  }

  const renderOrders = () => {
    const allSelected =
      Object.values(selectedOrders).length > 0 &&
      Object.values(selectedOrders).filter((o) => o).length === orders.length

    return (
      <FlexContainer
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <h1>
          There has been an address change for this chef - please select all
          future orders in which to apply this address. If you do not wish to
          update the address on future orders, simply press save.
        </h1>
        <YSpacing height="15px" />
        <Checkbox
          checked={allSelected}
          label={'Select All'}
          onClick={onSelectAll}
          width="100%"
          height="100%"
        />
        <YSpacing height="5px" />
        <Table headings={['Order Number', 'Date', 'Update Address']}>
          {orders.map((order) => {
            return (
              <tr key={order.id}>
                <td>{order.orderNumber}</td>
                <td>{moment(order.date).format('MM/DD/YYYY')}</td>
                <td>
                  <Checkbox
                    checked={selectedOrders[order.id]}
                    onChange={() =>
                      setSelectedOrders({
                        ...selectedOrders,
                        [order.id]: !selectedOrders[order.id],
                      })
                    }
                  />
                </td>
              </tr>
            )
          })}
        </Table>
        <YSpacing height="10px" />
        <FlexContainer flexDirection="column" alignItems="center">
          {isLoading ? (
            <Loader />
          ) : (
            <Button label="Save" onClick={onSave} width="30%" />
          )}
        </FlexContainer>
      </FlexContainer>
    )
  }

  const renderNoOrders = () => {
    if (isLoading) {
      return <Loader />
    }

    return (
      <FlexContainer
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <p>
          chef {chef.name} does not have any future orders where an Address
          would need to be updated. Please close this window.
        </p>
        <Button label="Close" onClick={close} />
      </FlexContainer>
    )
  }

  return (
    <Modal
      title={'Select Future Orders To Apply the Updated Address'}
      height="700px"
    >
      <FlexContainer>
        {orders.length > 0 ? renderOrders() : renderNoOrders()}
      </FlexContainer>
    </Modal>
  )
}

export default ChefFutureOrdersModal
