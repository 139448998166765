import { connect } from 'react-redux'

import { UserDetail } from '@components/user'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { adminUser, headquarter, hubspotUser, user } = state
  const { headquarters } = headquarter

  return {
    currentUser: user,
    headquarters,
    hubspotUser,
    user: adminUser,
  }
}

const mapDispatchToProps = () => {
  const { AdminUserService, HubspotUserService, RestService, UIService } =
    services
  const { pResponseAdminUsers, pResponseAdminUser, pResponseHubspotSingle } =
    presenters.Api
  const { EditUser, LoadHubspotSingle, LoadUsers, SelectUser } = coordinators

  const loadHubspotUser = LoadHubspotSingle({
    RestService,
    HubspotService: HubspotUserService,
    pResponseHubspotSingle,
    endpoint: 'users',
  })
  const editUser = EditUser({
    AdminUserService,
    RestService,
    UIService,
    pResponseAdminUser,
    HandleError,
  })
  const loadUsers = LoadUsers({
    AdminUserService,
    RestService,
    pResponseAdminUsers,
  })
  const selectUser = SelectUser({
    AdminUserService,
    RestService,
    pResponseAdminUser,
    HandleError,
  })

  return {
    editUser,
    loadHubspotUser,
    loadUsers,
    selectUser,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail)
