import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

import { DateInput } from '@components/common/form'
import { AutocompleteInput } from '@containers/common/form'
import SubscriptionEditable from './SubscriptionEditable'

class SubscriptionPage extends Component {
  state = {
    filteredRep: undefined,
    searchTerm: '',
    from: Moment().subtract(7, 'days'),
    to: undefined,
  }

  componentDidMount() {
    const { userRep } = this.props
    this.setState({ filteredRep: userRep }, this.loadSubscriptions)
  }

  loadSubscriptions = () => {
    const { callAfterTimeout, hqId, loadSubscriptions } = this.props
    const { from, to, searchTerm, filteredRep } = this.state
    let repId
    if (filteredRep) {
      repId = filteredRep.id
    }
    callAfterTimeout(() =>
      loadSubscriptions({ hqId, from, to, searchTerm, repId }),
    )
  }

  buildEditSubscription = (subscription) => {
    subscription = { ...subscription }
    subscription.mainItemMap = {}
    subscription.sideItemMap = {}
    subscription.menu.mainMenuItems.forEach((item) => {
      item.chosen = false
      subscription.mainItemMap[item.id] = item
    })
    subscription.menu.sideMenuItems.forEach((item) => {
      item.chosen = false
      subscription.sideItemMap[item.id] = item
    })
    subscription.items.forEach((item) => {
      const mainItem = subscription.mainItemMap[item.id]
      const sideItem = subscription.sideItemMap[item.id]
      if (mainItem) {
        mainItem.chosen = true
        mainItem.quantity = item.quantity
      } else if (sideItem) {
        sideItem.chosen = true
        sideItem.quantity = item.quantity
      }
    })
    delete subscription.date
    delete subscription.items
    delete subscription.mainItems
    delete subscription.sideItems

    return subscription
  }

  onDeleteSubscription = async (subscription) => {
    if (await this.props.deleteSubscription(subscription)) {
      await this.loadSubscriptions()
      this.setState({ activeSubscriptionId: undefined })
    }
  }

  onNotifyChef = (subscription) => () => {
    this.props.notifySubscriptionChef(
      subscription.id,
      subscription.menu.chef.id,
    )
  }

  onSaveSubscription = async (editSubscription) => {
    if (await this.props.saveSubscription(editSubscription)) {
      await this.loadSubscriptions()
      this.setState({ activeSubscriptionId: undefined })
    }
  }

  onSendSurveys = (subscription) => (surveyType) => {
    const { sendSurveys } = this.props
    sendSurveys(subscription.memberId, subscription.id, surveyType)
  }

  onChooseSalesRep = async (sub, salesRep) => {
    const { chooseSubsSalesRep, flashSuccess } = this.props
    if (await chooseSubsSalesRep(sub, salesRep)) {
      flashSuccess('Changes saved')
      this.loadSubscriptions()
    }
  }

  render() {
    const { loadSalesReps, subscriptions = [] } = this.props
    const {
      filteredRep: rep,
      activeSubscriptionId,
      from,
      searchTerm,
      to,
    } = this.state
    const repName = rep ? [rep.firstName, rep.lastName].join(' ') : ''

    return (
      <div>
        <div className="flex flex-col m-8">
          <div className="flex mb-6 justify-between">
            <div className="flex flex-row">
              <div>
                <span className="mr-2 ml-4">After</span>
                <DateInput
                  className="w-full"
                  date={from}
                  onChange={(date) =>
                    this.setState({ from: date }, this.loadSubscriptions)
                  }
                />
              </div>
              {from && (
                <button
                  className="text-red-600 bold ml-2 mr-4"
                  onClick={() =>
                    this.setState({ from: undefined }, this.loadSubscriptions)
                  }
                >
                  X
                </button>
              )}
            </div>

            <div className="flex flex-row">
              <div>
                <span className="mr-2 ml-4">Before</span>
                <DateInput
                  className="w-full"
                  date={to}
                  onChange={(date) =>
                    this.setState({ to: date }, this.loadSubscriptions)
                  }
                />
              </div>
              {to && (
                <button
                  className="text-red-600 bold ml-2 mr-4"
                  onClick={() =>
                    this.setState({ to: undefined }, this.loadSubscriptions)
                  }
                >
                  X
                </button>
              )}
            </div>

            <div className="flex flex-row">
              <div>
                <p className="ml-2"> Sales Rep </p>
                <div className="dropdown">
                  <AutocompleteInput
                    value={repName}
                    loaderFunction={loadSalesReps}
                    onSelect={(filteredRep) =>
                      this.setState({ filteredRep }, this.loadSubscriptions)
                    }
                  />
                </div>
              </div>
              {rep && (
                <button
                  className="text-red-600 bold ml-2 mr-4"
                  onClick={() =>
                    this.setState(
                      { filteredRep: undefined },
                      this.loadSubscriptions,
                    )
                  }
                >
                  X
                </button>
              )}
            </div>

            <input
              type="text"
              className="border regular text-lg text-hungryGray ml-2 px-3 bg-gray-100 rounded border-gray-400"
              placeholder="search..."
              value={searchTerm}
              onChange={(e) =>
                this.setState(
                  { searchTerm: e.target.value },
                  this.loadSubscriptions,
                )
              }
            />
          </div>

          <div className="flex font-bold mb-4 px-1">
            <div className="flex-1 m-2">Number</div>
            <div className="flex-1 m-2">Sales Rep</div>
            <div className="flex-1 m-2">Locked In</div>
            <div className="flex-1 m-2">Email</div>
            <div className="flex-1 m-2">City</div>
            <div className="flex-1 m-2">Date</div>
            <div className="flex-1 m-2">Week</div>
            <div className="flex-1 m-2">Headcount</div>
            <div className="flex-1 m-2">Menu</div>
            <div className="flex-1 m-2">Chef</div>
            <div className="flex-1 m-2">Total</div>
            <div className="flex-1 m-2">Paid</div>
          </div>

          {subscriptions.map((sub) => {
            const repName = sub.salesRep
              ? `${sub.salesRep.firstName} ${sub.salesRep.lastName}`
              : 'None'

            return (
              <div key={sub.id} className="mb-2">
                <div className="subscriber-row relative flex items-center px-1">
                  {activeSubscriptionId === sub.id ? (
                    <button
                      className="show-subscriber-button absolute bg-gray-200 px-1 border-gray-400 border border-solid mr-1 outline-none focus:outline-none"
                      onClick={() =>
                        this.setState({ activeSubscriptionId: undefined })
                      }
                    >
                      ▲
                    </button>
                  ) : (
                    <button
                      className="show-subscriber-button absolute bg-gray-200 px-1 border-gray-400 border border-solid mr-1 outline-none focus:outline-none"
                      onClick={() =>
                        this.setState({ activeSubscriptionId: sub.id })
                      }
                    >
                      ▼
                    </button>
                  )}

                  <div className="flex-1 m-2">{sub.number}</div>
                  <div className="flex-1 m-2">{repName}</div>
                  <div className="flex-1 m-2">
                    {sub.lockedIn ? 'Yes' : 'No'}
                  </div>
                  <div className="flex-1 m-2">{sub.clientEmail}</div>
                  <div className="flex-1 m-2">
                    {sub.dropoffAddress && sub.dropoffAddress.city}
                  </div>
                  <div className="flex-1 m-2">{sub.dateStr}</div>
                  <div className="flex-1 m-2">{sub.week}</div>
                  <div className="flex-1 m-2">{sub.headcount}</div>
                  <div className="flex-1 m-2">{sub.menu && sub.menu.name}</div>
                  <div className="flex-1 m-2">
                    {sub.menu && sub.menu.chef && sub.menu.chef.name}
                  </div>
                  <div className="flex-1 m-2">${sub.total}</div>
                  <div className="flex-1 m-2">{sub.paid ? 'Yes' : 'No'}</div>
                </div>

                {activeSubscriptionId === sub.id && (
                  <div className="flex flex-col px-8 mt-2 mb-6 items-center content-center">
                    <div className="flex flex-row px-8 mt-2 mb-6 w-full">
                      <div className="p-4 mr-6 flex flex-col w-full">
                        <p> Sales Rep </p>
                        <div className="dropdown">
                          <AutocompleteInput
                            value={repName}
                            loaderFunction={loadSalesReps}
                            onSelect={(salesRep) =>
                              this.onChooseSalesRep(sub, salesRep)
                            }
                          />
                        </div>
                      </div>

                      <div className="py-2 mr-6 w-full">
                        <div>
                          <label className="font-bold text-hungryGray w-16 mr-4">
                            Subtotal
                          </label>
                          ${sub.subtotal}
                        </div>
                        <div>
                          <label className="font-bold text-hungryGray w-16 mr-4">
                            Service Fee
                          </label>
                          ${sub.serviceFee}
                        </div>
                        <div>
                          <label className="font-bold text-hungryGray w-16 mr-4">
                            Discounts
                          </label>
                          ${sub.discounts}
                        </div>
                        <div>
                          <label className="font-bold text-hungryGray w-16 mr-4">
                            Tax
                          </label>
                          ${sub.tax}
                        </div>
                        <div>
                          <label className="font-bold text-hungryGray w-16 mr-4">
                            Tip
                          </label>
                          ${sub.tip}
                        </div>
                        <div>
                          <label className="font-bold text-hungryGray w-16 mr-4">
                            Total
                          </label>
                          ${sub.total}
                        </div>
                      </div>

                      <div className="py-2 mr-6 w-full">
                        <div className="flex flex-col">
                          <label className="font-bold text-hungryGray w-16">
                            Address
                          </label>
                          <span>
                            {sub.dropoffAddress && sub.dropoffAddress.line1}
                          </span>
                          <span>
                            {sub.dropoffAddress && sub.dropoffAddress.line2}
                          </span>
                          <span>
                            {sub.dropoffAddress && sub.dropoffAddress.city}
                            {sub.dropoffAddress && sub.dropoffAddress.state}
                            {sub.dropoffAddress && sub.dropoffAddress.zip}
                          </span>
                        </div>
                      </div>

                      <div className="py-2 mr-6 w-full">
                        <SubscriptionEditable
                          className="w-full"
                          key={sub.id}
                          hqId={sub.hqId}
                          subscription={sub}
                          buildEditSubscription={this.buildEditSubscription}
                          flashError={this.props.flashError}
                          loadHomeMenu={this.props.loadHomeMenu}
                          loadHomeMenus={this.props.loadHomeMenus}
                          onDeleteSubscription={this.onDeleteSubscription}
                          onNotifyChef={this.onNotifyChef(sub)}
                          onSaveSubscription={this.onSaveSubscription}
                          onSendSurveys={this.onSendSurveys(sub)}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

SubscriptionPage.propTypes = {
  userRep: PropTypes.object,
  subscriptions: PropTypes.array,
  hqId: PropTypes.number,

  callAfterTimeout: PropTypes.func,
  chooseSubsSalesRep: PropTypes.func,
  deleteSubscription: PropTypes.func,
  flashError: PropTypes.func,
  flashSuccess: PropTypes.func,
  loadHomeMenu: PropTypes.func,
  loadHomeMenus: PropTypes.func,
  loadSalesReps: PropTypes.func,
  loadSubscriptions: PropTypes.func,
  notifySubscriptionChef: PropTypes.func,
  saveDietaryRestrictions: PropTypes.func,
  saveMealSchedule: PropTypes.func,
  saveSubscription: PropTypes.func,
  sendSurveys: PropTypes.func,
  skipWeek: PropTypes.func,
  unskipWeek: PropTypes.func,
  updateMember: PropTypes.func,
}

export default SubscriptionPage
