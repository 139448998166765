import {
  CLEAR_EDIT_ADMIN_USER,
  CLEAR_ADMIN_USER,
  REMOVE_USER_FROM_USERS,
  SET_EDIT_ADMIN_USER,
  SET_ADMIN_USER,
  SET_ADMIN_USERS,
  UPDATE_EDIT_ADMIN_USER,
} from '@actions/adminUser'

const initialUsersState = []
const initialEditUserState = {}

export function adminUser(state = null, action) {
  const { type, user } = action
  switch (type) {
    case SET_ADMIN_USER:
      return { ...user }
    case CLEAR_ADMIN_USER:
      return null
    default:
      return state
  }
}

export function adminUsers(state = initialUsersState, action) {
  const { type, users } = action
  switch (type) {
    case SET_ADMIN_USERS:
      return users.slice()
    case REMOVE_USER_FROM_USERS: {
      const newUsers = state.slice()
      const idx = newUsers.findIndex((o) => o.id === action.userId)
      if (idx !== -1) {
        newUsers.splice(idx, 1)
      }

      return newUsers
    }
    default:
      return state
  }
}

export function editAdminUser(state = initialEditUserState, action) {
  const { type, user } = action

  switch (type) {
    case SET_EDIT_ADMIN_USER:
      return { ...user }
    case UPDATE_EDIT_ADMIN_USER:
      return { ...state, ...user }
    case CLEAR_EDIT_ADMIN_USER:
      return { ...initialEditUserState }
    default:
      return state
  }
}
