import React, { useState, useEffect, useCallback } from 'react'
import FlexContainer from '@components/common/FlexContainer'
import {
  FormInputContainer,
  MenuItemDropdown,
} from '@res/styledComponents/index'
import LinkText from '@components/common/form/LinkText'
import XSpacing from '@components/common/XSpacing'
import Loader from '@components/common/Loader'

import {
  ItemPickerMenuItem,
  NonFoodItem,
  ChefMenuItem,
  ServiceItem,
  VirtualItem,
  VirtualKit,
  SnackPack,
} from '@types'
import LoaderMenuItemGroup, {
  LoaderMenuItemGroupProps,
} from './LoaderMenuItemGroup'
import MenuItemGroup from './MenuItemGroup'

const ItemTypes = {
  'Snack Packs': 'snack_packs',
  'Service Items': 'service_items',
  'Virtual Items': 'virtual_items',
  'Virtual Kits': 'virtual_kits',
}

interface MenuItemResp {
  appetizers: ItemPickerMenuItem[]
  entrees: ItemPickerMenuItem[]
  sides: ItemPickerMenuItem[]
  desserts: ItemPickerMenuItem[]
  drinks: ItemPickerMenuItem[]
}

interface FoodCount {
  [key: string]: number
}

interface ItemPickerProps {
  chef: any
  orderType: string

  menuItems: MenuItemResp
  itemsLoading: boolean
  onSelectMenuItem: (item: ChefMenuItem) => void
  onSelectServiceItem: (item: NonFoodItem) => void
  onSelectSnackPack: (item: NonFoodItem) => void
  onSelectVirtualItem: (item: NonFoodItem) => void
  onSelectVirtualKit: (item: NonFoodItem) => void

  onCreateCustomMenuItem: ({
    chef,
    mealType,
  }: {
    chef: any
    mealType: string
  }) => any
  onCreateCustomServiceItem: ({ chef }: { chef: any }) => any
  onCreateCustomVirtualItem: ({ chef }: { chef: any }) => any

  loadServiceItems: (chefId: string) => Promise<ServiceItem[]>
  loadSnackPacks: (chefId: string) => Promise<SnackPack[]>
  loadVirtualItems: (chefId: string) => Promise<VirtualItem[]>
  loadVirtualKits: (chefId: string) => Promise<VirtualKit[]>
  loadMenuItem: (menuItemId: string) => Promise<ChefMenuItem>
  getItemCounts: (chefId: string, type: string) => Promise<number>
}

const ItemPicker: React.FC<ItemPickerProps> = ({
  chef,
  orderType,

  menuItems,
  itemsLoading,

  loadMenuItem,
  onSelectMenuItem,
  loadSnackPacks,
  onSelectSnackPack,
  loadServiceItems,
  onSelectServiceItem,
  loadVirtualItems,
  onSelectVirtualItem,
  loadVirtualKits,
  onSelectVirtualKit,

  onCreateCustomMenuItem,
  onCreateCustomServiceItem,
  onCreateCustomVirtualItem,

  getItemCounts,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const [search, setSearch] = useState<string>('')

  const [allItems, setAllItems] = useState<MenuItemResp>(menuItems)
  const [visibleItems, setVisibleItems] = useState<MenuItemResp>({
    appetizers: [],
    entrees: [],
    sides: [],
    desserts: [],
    drinks: [],
  })

  const [itemTypeCounts, setItemTypeCounts] = useState<FoodCount>({})
  const [foodItemCounts, setFoodItemCounts] = useState<FoodCount>({})

  // must be used in cases where allItems has already been validated to exist
  const frontendSearchItems = useCallback(() => {
    const searchItems: MenuItemResp = {
      appetizers: [],
      entrees: [],
      sides: [],
      desserts: [],
      drinks: [],
    }

    const { orderMenuItems: selectedMenuItems } = chef
    const selectedItemIds = new Set<string>(
      selectedMenuItems.map((i: { menuItemId: string }) => i.menuItemId),
    )

    const regex = new RegExp(search, 'gi')
    Object.keys(allItems || {}).forEach((key) => {
      searchItems[key as keyof MenuItemResp] = (allItems || {})[
        key as keyof MenuItemResp
      ].filter(
        (item) =>
          !selectedItemIds.has(item.id) &&
          (item.name.match(regex) ||
            (item.description && item.description.match(regex))),
      )
    })
    setVisibleItems(searchItems)
    updateFoodItemCounts(searchItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chef.orderMenuItems, search])

  const loadItemCounts = async () => {
    const counts: FoodCount = {}

    await Promise.allSettled([
      getItemCounts(chef.id, ItemTypes['Snack Packs']),
      getItemCounts(chef.id, ItemTypes['Service Items']),
      getItemCounts(chef.id, ItemTypes['Virtual Items']),
      getItemCounts(chef.id, ItemTypes['Virtual Kits']),
    ]).then((resps) => {
      resps.forEach((resp, index) => {
        if (resp.status === 'rejected') {
          counts[Object.keys(ItemTypes)[index]] = 0
        } else {
          counts[Object.keys(ItemTypes)[index]] = resp.value
        }
      })
    })

    setItemTypeCounts(counts)
  }

  const updateFoodItemCounts = (items: MenuItemResp) => {
    const counts: FoodCount = {}
    Object.keys(items || {}).forEach((key) => {
      counts[key] = items[key as keyof MenuItemResp].length
    })
    setFoodItemCounts(counts)
  }

  useEffect(() => {
    loadItemCounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateItems = useCallback(() => {
    if (itemsLoading) {
      return
    }

    updateFoodItemCounts(menuItems)
    setAllItems(menuItems)
    setVisibleItems(menuItems)
  }, [itemsLoading, menuItems])

  useEffect(() => {
    updateItems()
  }, [updateItems])

  useEffect(() => {
    frontendSearchItems()
  }, [frontendSearchItems])

  const onSearchMenuItems = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const onSelectItemPickerMenuItem = async (item: ItemPickerMenuItem) => {
    const menuItem = await loadMenuItem(item.id)
    onSelectMenuItem(menuItem)
  }

  const createCustomMenuItem = (mealType: string) => () => {
    onCreateCustomMenuItem({ chef, mealType })
  }

  const createCustomServiceItem = () => {
    onCreateCustomServiceItem({ chef })
  }

  const createCustomVirtualItem = () => {
    onCreateCustomVirtualItem({ chef })
  }

  const renderMenuItems = () => {
    const isVCX = orderType === 'VCX'
    const isSnackPack = orderType === 'Snack Pack'

    const {
      orderServiceItems: selectedServiceItems,
      orderVirtualItems: selectedVirtualItems,
      orderVirtualKits: selectedVirtualKits,
      orderSnackPacks: selectedSnackPacks,
    } = chef

    const selectedServiceItemIds = new Set<string>(
      selectedServiceItems.map(
        (i: { serviceItemId: string }) => i.serviceItemId,
      ),
    )
    const selectedVirtualItemIds = new Set<string>(
      selectedVirtualItems.map(
        (i: { virtualItemId: string }) => i.virtualItemId,
      ),
    )
    const selectedVirtualKitIds = new Set<string>(
      (selectedVirtualKits || []).map(
        (i: { virtualKitId: string }) => i.virtualKitId,
      ),
    )
    const selectedSnackPackIds = new Set<string>(
      (selectedSnackPacks || []).map(
        (i: { snackPackId: string }) => i.snackPackId,
      ),
    )

    const foodItemSections = [
      {
        name: `Entrees (${foodItemCounts['entrees']})`,
        chefId: chef.id,
        items: visibleItems.entrees,
        addCustomText: 'Add Custom Entree Item',
        onCreateCustom: createCustomMenuItem('Entree'),
        onSelect: onSelectItemPickerMenuItem,
      },
      {
        name: `Sides (${foodItemCounts['sides']})`,
        chefId: chef.id,
        items: visibleItems.sides,
        addCustomText: 'Add Custom Side Item',
        onCreateCustom: createCustomMenuItem('Side'),
        onSelect: onSelectItemPickerMenuItem,
      },
      {
        name: `Desserts (${foodItemCounts['desserts']})`,
        chefId: chef.id,
        items: visibleItems.desserts,
        addCustomText: 'Add Custom Dessert Item',
        onCreateCustom: createCustomMenuItem('Dessert'),
        onSelect: onSelectItemPickerMenuItem,
      },
      {
        name: `Drinks (${foodItemCounts['drinks']})`,
        chefId: chef.id,
        items: visibleItems.drinks,
        addCustomText: 'Add Custom Drink Item',
        onCreateCustom: createCustomMenuItem('Drink'),
        onSelect: onSelectItemPickerMenuItem,
      },
      {
        name: `Appetizers (${foodItemCounts['appetizers']})`,
        chefId: chef.id,
        items: visibleItems.appetizers,
        addCustomText: 'Add Custom Appetizer Item',
        onCreateCustom: createCustomMenuItem('Appetizer'),
        onSelect: onSelectItemPickerMenuItem,
      },
    ]

    const snackPackSections: LoaderMenuItemGroupProps[] = [
      {
        name: 'Snack Packs',
        searchText: search,
        count: itemTypeCounts['Snack Packs'],
        chefId: chef.id,
        selectedIds: selectedSnackPackIds,
        loaderFunc: loadSnackPacks,
        onSelect: onSelectSnackPack,
      },
    ]
    const nonVCXSections: LoaderMenuItemGroupProps[] = [
      {
        name: 'Service Items',
        searchText: search,
        count: itemTypeCounts['Service Items'],
        chefId: chef.id,
        selectedIds: selectedServiceItemIds,
        addCustomText: 'Add Custom Service Item',
        onCreateCustom: createCustomServiceItem,
        loaderFunc: loadServiceItems,
        onSelect: onSelectServiceItem,
      },
    ]
    const vItemSections: LoaderMenuItemGroupProps[] = [
      {
        name: 'Virtual Items',
        searchText: search,
        count: itemTypeCounts['Virtual Items'],
        chefId: chef.id,
        selectedIds: selectedVirtualItemIds,
        addCustomText: 'Add Custom Virtual Item',
        hideNonCustom: !isSnackPack,
        onCreateCustom: createCustomVirtualItem,
        loaderFunc: loadVirtualItems,
        onSelect: onSelectVirtualItem,
      },
    ]

    const vKitSections: LoaderMenuItemGroupProps[] = [
      {
        name: 'Virtual Kits',
        searchText: search,
        count: itemTypeCounts['Virtual Kits'],
        chefId: chef.id,
        selectedIds: selectedVirtualKitIds,
        loaderFunc: loadVirtualKits,
        onSelect: onSelectVirtualKit,
      },
    ]

    const loadedSections = []
    if (isVCX) {
      loadedSections.push(...vItemSections, ...vKitSections)
    } else if (isSnackPack) {
      loadedSections.push(...vItemSections, ...snackPackSections)
    } else {
      loadedSections.push(...nonVCXSections)
    }

    return (
      showDropdown && (
        <MenuItemDropdown>
          <>
            {foodItemSections.map((s) => (
              <MenuItemGroup key={s.name} {...s} />
            ))}
            {loadedSections.map((s) => (
              <LoaderMenuItemGroup key={s.name} {...s} />
            ))}
          </>
        </MenuItemDropdown>
      )
    )
  }

  return (
    <FlexContainer alignItems="flex-start">
      <FormInputContainer width="90%" marginBottom="20px">
        <input
          type="text"
          placeholder="Type here to search faster by name or description..."
          value={search}
          onInput={onSearchMenuItems}
          onClick={() => setShowDropdown(true)}
        />
        {showDropdown && itemsLoading && <Loader isCenter={true} />}
        {showDropdown && !itemsLoading && renderMenuItems()}
      </FormInputContainer>
      <XSpacing width="20px" />
      <LinkText onClick={() => setShowDropdown(false)} label="close" />
    </FlexContainer>
  )
}

export default ItemPicker
