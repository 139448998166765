import axios from 'axios'
import presenters from '@presenters'
const pResponseError = presenters.Errors.responseErrorMessage

export const SaveCafeMenu =
  ({ RestService, UIService }) =>
  async (data) => {
    const req = data
    UIService.LoadingIndicator.toggle(true)
    const headers = { 'Content-Type': 'multipart/form-data' }
    try {
      if (data.menu) {
        const file = data.menu
        const presignedUrlData = { imageType: 'cafe_pdfs', mimeType: file.type }
        const { presignedUrl, publicUrl } = await RestService.get(
          '/images/presigned',
          presignedUrlData,
        )
        req.menuPDF = publicUrl
        delete req.menu
        const config = {
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': file.type,
          },
          timeout: 120000,
        }

        const s3Response = await axios.put(presignedUrl, file, config)

        if (
          s3Response &&
          (s3Response.status === 200 || s3Response.statusText === 'OK')
        ) {
          req.menuPDF = publicUrl
          delete req.menu
        }
      }

      if (req.id) {
        await RestService.put(`/cafes/pdf-menu`, req, {
          ...headers,
          timeout: 10000,
        })
        UIService.FlashMessage.displaySuccessMessage('Menu has been updated!')
        UIService.LoadingIndicator.toggle(false)
      } else {
        await RestService.post('/cafes/pdf-menu', req, {
          ...headers,
          timeout: 10000,
        })
        UIService.FlashMessage.displaySuccessMessage('New menu has been saved!')
        UIService.LoadingIndicator.toggle(false)
      }
    } catch (error) {
      UIService.LoadingIndicator.toggle(false)
      UIService.FlashMessage.displayFailureMessage(
        'Error updating image: ',
        error,
      )

      return
    }
  }

export const GetCafePDFMenu =
  ({ RestService, HandleError }) =>
  async (search) => {
    try {
      const cafeMenu = await RestService.get(`/cafes/pdf-menu`, { url: search })

      return cafeMenu
    } catch (error) {
      const { errorMessage } = pResponseError(error)
      if (errorMessage === ' not found') {
        return false
      } else {
        HandleError({ error })
      }
    }
  }
