import { connect } from 'react-redux'

import ContactInput from '@components/common/form/ContactInput'

import presenters from '@presenters'

const mapDispatchToProps = () => {
  const { pBuildContact } = presenters

  return { pBuildContact }
}

export default connect(undefined, mapDispatchToProps)(ContactInput)
