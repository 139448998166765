export const LoadOrderable =
  ({
    reduxService,
    RestService,
    GetLastLoadOrderablesParams,
    pRequestFilter,
    pResponse,
    url,
  }) =>
  async (filters) => {
    /* filters is object with shape { search, status }, both of which are optional */
    if (!filters) {
      reduxService.clearOrderables()
      filters = GetLastLoadOrderablesParams()
    }
    filters = pRequestFilter(filters)
    let orderables = await RestService.get(url, {
      page: 1,
      results_per_page: 40,
      ...filters,
    })
    orderables = pResponse(orderables)
    reduxService.setOrderables(orderables)

    return orderables
  }

export const SearchOrderable =
  ({ RestService, pRequestFilter, pResponse, url }) =>
  async (filters) => {
    filters = pRequestFilter(filters)
    const orderables = await RestService.get(url, {
      page: 1,
      results_per_page: 20,
      ...filters,
    })

    return pResponse(orderables)
  }

export const GetOrderableCount =
  ({ RestService, pRequestFilter, url }) =>
  async (filters) => {
    filters = pRequestFilter(filters)

    return await RestService.get(url, {
      page: 1,
      results_per_page: 20,
      only_count: 'true',
      ...filters,
    })
  }

export const SelectOrderable =
  ({ reduxService, RestService, pResponse, url }) =>
  async (orderableId) => {
    if (!orderableId) {
      return
    }
    let orderable = await RestService.get(`${url}/${orderableId}`)
    orderable = pResponse(orderable)
    reduxService.setOrderable(orderable)
  }

export const LoadQueryOrderable =
  ({
    reduxService,
    RestService,
    RouterService,
    pResponse,
    orderableType,
    url,
  }) =>
  async () => {
    const orderableId = RouterService.getQuery(`${orderableType}_id`)
    if (orderableId) {
      SelectOrderable({ reduxService, RestService, pResponse, url })(
        orderableId,
      )
    }
  }

export const DeleteOrderable =
  ({ reduxService, RestService, UIService, HandleError, url }) =>
  async () => {
    const { id: orderableId, orderNumber } = reduxService.orderable()
    const text = `Are you sure you want to delete #${orderNumber}?`
    const doDelete = await UIService.ConfirmationModal.show({ text })
    if (doDelete) {
      try {
        await RestService.delete(`${url}${orderableId}`)
        reduxService.clearEditOrderable()
        reduxService.clearOrderable()
        reduxService.removeOrderableFromOrderables(orderableId)
      } catch (error) {
        HandleError({ error })
      }
    }
  }

export const EditOrderable =
  ({
    reduxService,
    OrderService,
    RestService,
    UIServiceEditOrderable,
    pResponseEdit,
    url,
  }) =>
  async (section) => {
    const orderableId = reduxService.orderableId()
    const orderableJson = await RestService.get(`${url}${orderableId}`)
    reduxService.clearEditOrderable()
    const { calculateDiscounts, getDeliveryFeePercentAndLimit } = OrderService
    const orderable = pResponseEdit({
      orderableJson,
      calculateDiscounts,
      getDeliveryFeePercentAndLimit,
    })
    // need default fields from clearing state here, hence we cannot directly set with the response
    reduxService.updateEditOrderable(orderable)
    UIServiceEditOrderable.show('edit', section)
  }

export const DelayedUpdateOrderable =
  ({ reduxService, UIService, UIServiceEditOrderable }) =>
  (data, delayedUpdate = false) => {
    const mode = UIServiceEditOrderable.getMode()
    const updateOrderable =
      mode === 'edit'
        ? reduxService.updateEditOrderable
        : reduxService.updateNewOrderable
    if (delayedUpdate) {
      const call = () => updateOrderable(data)
      UIService.Timer.callAfterTimeout(call)
    } else {
      updateOrderable(data)
    }
  }
