export const GetClientGlobalSettings =
  ({ RestService, HandleError }) =>
  async ({ id, clientId }) => {
    try {
      const search = { clientId }
      if (id) {
        search.id = id
      }
      const settings = await RestService.get(
        '/group-orders/client-global-settings',
        search,
      )

      return settings
    } catch (error) {
      if (error.response && error.response.status !== 404) {
        HandleError({ error })
      }

      return {}
    }
  }

export const SaveClientGlobalSettings =
  ({ RestService, UIService, HandleError }) =>
  async (clientGlobalSettings, clientName) => {
    let result
    try {
      if (clientGlobalSettings.id) {
        result = await RestService.put(
          `/group-orders/client-global-settings/${clientGlobalSettings.id}`,
          clientGlobalSettings,
        )
      } else {
        result = await RestService.post(
          '/group-orders/client-global-settings',
          clientGlobalSettings,
        )
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      `${clientName} Group Order Global Settings successfully updated`,
    )

    return result
  }

export const SaveStaffingFeeSettings =
  ({ RestService, UIService, HandleError }) =>
  async (
    clientGlobalSettingsId,
    globalStaffingFeeSettings,
    locationsConfig,
  ) => {
    let result = null
    try {
      const data = {
        globalConfig: globalStaffingFeeSettings,
        locationsConfig,
      }
      result = await RestService.put(
        `/group-orders/staffing-fee-settings/${clientGlobalSettingsId}`,
        data,
      )
    } catch (error) {
      HandleError({ error })

      return result
    }
    UIService.FlashMessage.displaySuccessMessage(
      'Staffing fee settings successfully updated',
    )

    return result
  }
