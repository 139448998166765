import { connect } from 'react-redux'

import { PromoCodes } from '@components/account/accountSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const {
    account,
    promoCodes,
    //TODO: for convert to settings
    promoCodeAttributes: { allKinds, allReasons },
  } = state

  return {
    accountId: account.id,
    accountPromoCodes: account.promoCodes,
    promoCodeKinds: allKinds,
    promoCodeReasons: allReasons,
    promoCodes,
  }
}

const mapDispatchToProps = () => {
  const {
    AccountService,
    PromoCodesService,
    RestService,
    RouterService,
    SessionService,
    UIService,
  } = services
  const {
    pPromoErrors,
    pRequestUpdateAccountPromoCodes: pRequestUpdateAccount,
    pRequestUpdatePromoCode,
    pResponseAccount,
    pResponseEditAccount,
    pResponsePromoCodes,
    pResponsePromoCodeAttributes,
  } = presenters.Api

  const {
    DeletePromoCode,
    EditAccount,
    HandleError,
    LoadPromoCodes,
    LoadPromoCodeAttributes,
    SaveAccount,
    SavePromoCode,
    SelectAccount,
  } = coordinators

  const deletePromoCode = DeletePromoCode({
    PromoCodesService,
    RestService,
    UIService,
    pResponsePromoCodes,
    LoadPromoCodes,
  })
  const editAccount = EditAccount({
    AccountService,
    RestService,
    UIService,
    pResponseEditAccount,
  })
  const loadPromoCodes = LoadPromoCodes({
    PromoCodesService,
    RestService,
    pResponsePromoCodes,
  })
  const loadPromoCodeAttributes = LoadPromoCodeAttributes({
    PromoCodesService,
    RestService,
    pResponsePromoCodeAttributes,
  })
  const saveAccount = SaveAccount({
    AccountService,
    RestService,
    UIService,
    pRequestUpdateAccount,
    pResponseAccount,
  })
  const selectAccount = SelectAccount({
    AccountService,
    RestService,
    pResponseAccount,
  })
  const handlePromoError = HandleError({
    RouterService,
    SessionService,
    UIService,
    pResponseError: pPromoErrors,
  })
  const savePromoCode = SavePromoCode({
    PromoCodesService,
    RestService,
    pRequestUpdatePromoCode,
    pResponsePromoCodes,
    HandleError: handlePromoError,
    SelectAccount: selectAccount,
  })

  return {
    deletePromoCode,
    editAccount,
    loadPromoCodes,
    loadPromoCodeAttributes,
    saveAccount,
    savePromoCode,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodes)
