import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Flatpickr from 'react-flatpickr'
import { convertTimeIntoMomentTz, convertTimeIntoBrowserTz } from '~/utils'

const SidebarDateAndTimeInput = (props) => {
  const { label } = props
  const [date, setDate] = useState()
  const pickerOptions = {
    dateFormat: 'm/d/y h:i K',
    enableTime: true,
  }

  const onChange = (date) => {
    const { onChange } = props

    if (Array.isArray(date)) {
      date = date.pop()
    }
    if (!date) {
      return
    }

    const dateInTz = convertTimeIntoMomentTz(date)

    if (dateInTz !== date) {
      setDate(dateInTz)
      onChange && onChange(dateInTz)
    }
  }

  const onClearDate = () => {
    setDate()
    props.clearDate()
  }

  return (
    <SidebarContainer>
      <label>{label}</label>
      <div style={{ position: 'relative', width: '100%' }}>
        <Flatpickr
          name={label}
          height="400px"
          data-enable-time
          options={pickerOptions}
          onChange={onChange}
          value={date && convertTimeIntoBrowserTz(date)}
        />
        {props.clearDate && date && date.toDate() && (
          <ClearDate onClick={onClearDate}>X</ClearDate>
        )}
      </div>
    </SidebarContainer>
  )
}

export const ClearDate = styled.button`
  position: absolute;
  right: 10px;
  top: 0px;
`

const SidebarContainer = styled.div`
  padding: 0 20px;
  margin-bottom: 10px;
  label {
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
  }
  select {
    width: 100%;
    box-sizing: border-box;
    padding: 6px 10px;
    background: rgba(255, 255, 255, 0.15);
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    font-size: 15px;
    ::placeholder {
      color: rgba(255, 255, 255, 0.6);
      font-family: 'regular';
    }
    option {
      color: gray;
    }
  }
`

SidebarDateAndTimeInput.propTypes = {
  label: PropTypes.string,

  clearDate: PropTypes.func,
  onChange: PropTypes.func,
}

export default SidebarDateAndTimeInput
