import { BaseService } from 'hungry-core2'

import actions from '@actions'

export default class AuditHistory extends BaseService {
  close = () => {
    this.dispatch(actions.toggleAuditHistoryModal(false, '', null))
  }

  show = (title, auditHistory) => {
    this.dispatch(actions.toggleAuditHistoryModal(true, title, auditHistory))
  }
}
