import React from 'react'
import PropTypes from 'prop-types'
import Error from '@components/common/form/Error'
import { FormInputContainer } from '@res/styledComponents/index'

const Dropdown = (props) => {
  const {
    error,
    value,
    label,
    marginBottom,
    paddingTop,
    width,
    children,
    onChange,
    disabled,
    testId,
  } = props

  return (
    <FormInputContainer
      width={width}
      marginBottom={marginBottom}
      paddingTop={paddingTop}
    >
      <label>{label}</label>
      <select
        name={label}
        id={testId}
        disabled={disabled}
        value={value}
        onChange={onChange}
      >
        {children}
      </select>
      {error && <Error error={error} />}
    </FormInputContainer>
  )
}

Dropdown.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  disabled: PropTypes.bool,
  marginBottom: PropTypes.string,
  options: PropTypes.array,
  optionKey: PropTypes.string,
  paddingTop: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.node,
  testId: PropTypes.string,

  onChange: PropTypes.func,
}

Dropdown.defaultProps = {
  option: [],
  marginBottom: '20px',
  width: '100%',
}

export default Dropdown
