import { connect } from 'react-redux'

import AutocompleteInput from '@components/common/form/AutocompleteInput'

import services from '@services'

const mapDispatchToProps = () => {
  const { UIService } = services

  const { callAfterTimeout } = UIService.Timer

  return { callAfterTimeout }
}

export default connect(undefined, mapDispatchToProps)(AutocompleteInput)
