import { connect } from 'react-redux'

import { AccountBatchInvoices } from '@components/account/accountSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'
import { config } from 'hungry-core2'

const mapStateToProps = (state) => {
  const { account } = state
  const invoicePDFUrl = `${config.api_host}/accounting/invoice`

  return {
    accountId: account && account.id,
    invoicePDFUrl,
  }
}

const mapDispatchToProps = () => {
  const { UIService, RestService } = services
  const { pResponseInvoices } = presenters.Api
  const { SearchInvoices } = coordinators

  const searchInvoices = SearchInvoices({
    RestService,
    pResponseInvoices,
    HandleError,
  })
  const { callAfterTimeout } = UIService.Timer

  return {
    callAfterTimeout,
    searchInvoices,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountBatchInvoices)
