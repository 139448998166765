import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { OrderableMaster } from '@components/orderable'

export class OrderMaster extends Component {
  componentDidMount() {
    const pathBreakdown = this.props.location.pathname.match(/\/sales\/(.*)/)
    if (pathBreakdown && pathBreakdown.length > 1) {
      this.props.selectOrder(pathBreakdown[1])
    }
  }

  render() {
    const {
      clearOrders,
      filter,
      loadOrders,
      orders,
      selectOrder,
      loadAccountExecutives,
      setLastLoadOrdersParams,
    } = this.props

    return (
      <OrderableMaster
        filter={filter}
        orderables={orders}
        clearOrderables={clearOrders}
        loadOrderables={loadOrders}
        selectOrderable={selectOrder}
        loadAccountExecutives={loadAccountExecutives}
        setLastLoadOrderablesParams={setLastLoadOrdersParams}
      />
    )
  }
}

OrderMaster.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.string,

  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),

  clearOrders: PropTypes.func,
  loadOrders: PropTypes.func,
  selectOrder: PropTypes.func,
  loadAccountExecutives: PropTypes.func,
  setLastLoadOrdersParams: PropTypes.func,
}

export default OrderMaster
