import React from 'react'

import { BsPerson } from 'react-icons/bs'
import { BiPencil, BiTrash } from 'react-icons/bi'
import { IoCheckbox } from 'react-icons/io5'

import { IconButton } from '@res/styledComponents/index'
import { YSpacing, FlexContainer } from '@components/common'
import { ContactInput, AutocompleteInput } from '@containers/common/form'

interface Contact {
  id?: string
  key?: number
  existing?: boolean
  accountContactId?: string
  firstName: string
  lastName: string
  name: string
  email: string
  phoneNumber: string
  title: string | null
  updatedBy: string | null
  hubspotContactId: string | null
}

interface AccountContact {
  id: string
  contactId: string
  isDefault: boolean
}

interface ContactListProps {
  accountId?: string
  contacts: Contact[]
  accountContacts: AccountContact[]
  newContacts: Contact[]
  editingContacts: { [key: string]: Contact }
  errors: { [key: string]: string }

  loadAccountContacts: (search: any) => Promise<Contact[]>

  hubspotContact: any
  loadHubspotContact: () => void
  loadHubspotContacts: () => void
  updateHubspotContactRef: () => void

  onAddNewContact: () => void
  onAddExistingContact: () => void
  onChangeNewContact: (contact: Contact, key: number) => void
  onSelectExistingContact: (contact: Contact, key: number) => void
  onCancelNewContact: (key: number) => void

  onEditContact: (contact: Contact) => void
  onChangeEditContact: (contact: Contact) => void
  onCancelEditContact: (contactId: string) => void

  onDeleteContact: (accountId: string, contact: Contact) => Promise<void>
  onSaveContact: (contact: Contact) => Promise<void>

  markDefaultContact: (
    accountId: string,
    accountContactId: string,
  ) => Promise<void>
}

const ContactList: React.FC<ContactListProps> = ({
  accountId,
  contacts,
  accountContacts,
  newContacts,
  editingContacts,
  errors,

  loadAccountContacts,

  hubspotContact,
  loadHubspotContact,
  loadHubspotContacts,
  updateHubspotContactRef,

  onAddNewContact,
  onAddExistingContact,
  onSelectExistingContact,
  onChangeNewContact,
  onCancelNewContact,

  onEditContact,
  onChangeEditContact,
  onCancelEditContact,

  onDeleteContact,
  onSaveContact,

  markDefaultContact,
}) => {
  const renderInput = (contact: Contact) => {
    const name =
      contact.firstName && contact.lastName
        ? `${contact.firstName} ${contact.lastName}`
        : ''

    return contact.existing ? (
      <div className="w-full">
        <AutocompleteInput
          label="Select Contact"
          width="100%"
          value={name}
          loaderFunction={loadAccountContacts}
          onSelect={(c: Contact) =>
            contact.key !== undefined && onSelectExistingContact(c, contact.key)
          }
          error={errors.contact}
        />
        <IconButton
          id={`delete-contact-${contact.key}`}
          onClick={() =>
            contact.key !== undefined && onCancelNewContact(contact.key)
          }
        >
          <BiTrash /> Cancel
        </IconButton>
      </div>
    ) : (
      <ContactInput
        key={`new-contact-${contact.key}`}
        hasBackground={true}
        contact={contact}
        hubspotContact={hubspotContact}
        loadHubspotContact={loadHubspotContact}
        loadHubspotContacts={loadHubspotContacts}
        onSelectHubspotContact={updateHubspotContactRef}
        onChange={(c) =>
          contact.key !== undefined && onChangeNewContact(c, contact.key)
        }
        onCancel={() =>
          contact.key !== undefined && onCancelNewContact(contact.key)
        }
        errors={errors}
      />
    )
  }

  const isDefault = (contact: Contact) => {
    const accountContact = accountContacts.find(
      (ac) => ac.contactId === contact.id,
    )

    return accountContact ? accountContact.isDefault : false
  }

  return (
    <FlexContainer
      width="100%"
      justifyContent="flex-start"
      flexDirection="column"
    >
      <h2
        className="mb-2"
        style={{ color: '#FB5754', fontSize: 16, fontWeight: 'bold' }}
      >
        CONTACTS:
      </h2>
      <YSpacing height="5px" />
      <IconButton id="add-new-contact" onClick={onAddNewContact}>
        <BsPerson /> Add New Contact
      </IconButton>
      <YSpacing height="5px" />
      <IconButton id="add-existing-contact" onClick={onAddExistingContact}>
        <BsPerson /> Add Existing Contact
      </IconButton>
      <YSpacing height="5px" />
      {newContacts.length > 0 && (
        <>
          <FlexContainer width="100%" flexDirection="row" flexWrap="wrap">
            {newContacts.map((contact) => (
              <div
                style={{ width: '31%' }}
                className="px-2"
                key={`new-contact-container-${contact.key}`}
              >
                {renderInput(contact)}
              </div>
            ))}
          </FlexContainer>
          <YSpacing height="5px" />
        </>
      )}
      <FlexContainer width="100%" flexDirection="column">
        {contacts.map((contact, i) => (
          <FlexContainer
            key={i}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <span>
              {`${contact.firstName} ${contact.lastName} - ${contact.email}`}
              {isDefault(contact) && ' (Default)'}
            </span>
            <div className="flex flex-row justify-items-start w-1/3">
              <IconButton
                id={`edit-contact-${i}`}
                onClick={() => onEditContact(contact)}
                style={{ marginRight: 20 }}
              >
                <BiPencil /> Edit
              </IconButton>
              <IconButton
                id={`delete-contact-${i}`}
                onClick={async () => {
                  if (!accountId) {
                    return
                  }
                  const { accountContactId, name } = contact

                  await onDeleteContact(accountId, {
                    id: accountContactId,
                    name,
                  } as Contact)
                }}
              >
                <BiTrash /> Delete
              </IconButton>
              {accountId && !isDefault(contact) && (
                <IconButton
                  id={`mark-default-contact-${i}`}
                  onClick={async () => {
                    const accountContact = accountContacts.find(
                      (ac) => ac.contactId === contact.id,
                    )
                    if (accountContact) {
                      await markDefaultContact(accountId, accountContact.id)
                    }
                  }}
                  style={{ marginLeft: 20 }}
                >
                  <IoCheckbox /> Mark Default
                </IconButton>
              )}
            </div>
          </FlexContainer>
        ))}
        <FlexContainer
          width="100%"
          padding="10px 0 10px 0"
          justifyContent="flex-start"
        >
          {Object.values(editingContacts).map((contact) => (
            <div key={`contact-container-${contact.id}`} className="p-2">
              <ContactInput
                key={`edit-contact-${contact.id}`}
                hasBackground={true}
                contact={contact}
                hubspotContact={hubspotContact}
                loadHubspotContact={loadHubspotContact}
                loadHubspotContacts={loadHubspotContacts}
                onSelectHubspotContact={updateHubspotContactRef}
                onChange={onChangeEditContact}
                onCancel={() => contact.id && onCancelEditContact(contact.id)}
                onSave={onSaveContact}
              />
            </div>
          ))}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  )
}

export default ContactList
