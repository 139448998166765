export const SaveAdhocAccount =
  ({ RestService, pRequestSaveAdhocAccount, pResponseGeneric, HandleError }) =>
  async ({ data, namespace = 'editOrderModal' }) => {
    data = pRequestSaveAdhocAccount(data)
    let account
    try {
      account = await RestService.post('/api/admin/accounts', data)
    } catch (error) {
      HandleError({ error, namespace })

      return {}
    }

    return pResponseGeneric(account)
  }

export const SaveAdhocAddress =
  ({
    RestService,
    pRequestSaveAdhocAddress,
    pResponseGeneric,
    pFindIdentical,
    HandleError,
  }) =>
  async ({ account, address }) => {
    address.allowTolls = false
    const data = pRequestSaveAdhocAddress({ account, address })
    try {
      account = await RestService.put(`/api/admin/accounts/${account.id}`, data)
    } catch (error) {
      HandleError({ error, namespace: 'editOrderModal' })

      return {}
    }

    account = pResponseGeneric(account)
    address = pFindIdentical(account.addresses, address)

    return {
      account,
      address,
    }
  }

export const SaveAdhocContact =
  ({
    RestService,
    pFindIdentical,
    pRequestSaveAdhocContact,
    pResponseGeneric,
    HandleError,
  }) =>
  async ({ account, contact, namespace = 'editOrderModal' }) => {
    const data = pRequestSaveAdhocContact({ account, contact })
    try {
      account = await RestService.put(`/api/admin/accounts/${account.id}`, data)
    } catch (error) {
      HandleError({ error, namespace })

      return {}
    }

    account = pResponseGeneric(account)
    contact = pFindIdentical(account.contacts, contact)

    return {
      account,
      contact,
    }
  }

export const SaveAdhocPaymentMethod =
  ({
    RestService,
    pFindIdenticalPaymentMethod,
    pRequestSaveAdhocPaymentMethod,
    pResponseGeneric,
    HandleError,
  }) =>
  async ({ account, paymentMethod, namespace = 'editOrderModal' }) => {
    const data = pRequestSaveAdhocPaymentMethod({ account, paymentMethod })
    try {
      account = await RestService.put(`/api/admin/accounts/${account.id}`, data)
    } catch (error) {
      HandleError({ error, namespace })

      return {}
    }

    account = pResponseGeneric(account)
    paymentMethod = pFindIdenticalPaymentMethod(
      account.paymentMethods,
      paymentMethod,
    )

    return {
      account,
      paymentMethod,
    }
  }
