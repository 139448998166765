import React, { useState, useEffect, ChangeEvent, Fragment } from 'react'
import Moment from 'moment-timezone'
import { Label } from '@res/styledComponents/index'
import { FlexContainer } from '@components/common'
import Table from '@components/common/Table'
import Panel from '@components/common/Panel'
import { MarketDropdown, TextInput, DateInput } from '@components/common/form'
import { HubspotAccount, IndexHeadquarter } from '@types'
import { colors } from '../../../constants'

interface ClientTableProps {
  accounts: HubspotAccount[]
  loadAccounts: (filters: { [key: string]: any }) => Promise<void>
  loadHeadquarters: () => Promise<IndexHeadquarter[]>
  selectedAccounts?: { [key: string]: boolean }
  onSelectAccount?: (id: string) => (e: ChangeEvent<HTMLInputElement>) => void
}

const ClientTable: React.FC<ClientTableProps> = ({
  accounts,
  loadAccounts,
  loadHeadquarters,
  selectedAccounts,
  onSelectAccount,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [filters, setFilters] = useState<{ [key: string]: any }>({
    accountHubspotSerializer: true,
    page: 1,
    resultsPerPage: 100,
  })
  const [headquarters, setHeadquarters] = useState<IndexHeadquarter[]>([])
  const [selectedHqs, setSelectedHqs] = useState<number[]>([])
  const [showFilters, setShowFilters] = useState<boolean>(true)

  useEffect(() => {
    const loadHqs = async () => {
      const hqData = await loadHeadquarters()
      setHeadquarters(hqData)
    }
    loadHqs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const loadAccs = async () => {
      await loadAccounts(filters)
    }
    loadAccs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const updateHqFilters = (newHqs: number[]) => {
    if (newHqs.length > 0) {
      setFilters({ ...filters, hqs: newHqs.join(','), page: 1 })
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newFilters: { [key: string]: any } = { ...filters, page: 1 }
      delete newFilters.hqs
      setFilters(newFilters)
    }
  }

  const onSelectHq = (hqId: number) => {
    if (!selectedHqs.includes(hqId)) {
      const newHqs = [...selectedHqs, hqId]
      setSelectedHqs(newHqs)
      updateHqFilters(newHqs)
    }
  }

  const onDeselectHq = (hqId: number) => {
    const newHqs = selectedHqs.filter((id) => id !== hqId)
    setSelectedHqs(newHqs)
    updateHqFilters(newHqs)
  }

  const onChangeAccountSearch = (e: InputEvent) => {
    setFilters({
      ...filters,
      hubspotSearch: (e.target as HTMLInputElement)?.value,
      page: 1,
    })
  }

  const onChangeContactSearch = (e: InputEvent) => {
    setFilters({
      ...filters,
      contactEmail: (e.target as HTMLInputElement)?.value,
      page: 1,
    })
  }

  const onChangeFirstOrderDateSearch =
    (dir: 'From' | 'To') => (date: Moment.Moment) => {
      setFilters({
        ...filters,
        [`firstOrder${dir}Date`]: date.format(),
        page: 1,
      })
    }

  const onClearFirstOrderDateSearch = (dir: 'From' | 'To') => () => {
    const newFilters = { ...filters }
    delete newFilters[`firstOrder${dir}Date`]

    setFilters({ ...newFilters, page: 1 })
  }

  const onChangePage = (dir: number) => {
    if (dir === -1 && filters.page > 1) {
      setFilters({ ...filters, page: filters.page - 1 })
    } else if (dir === 1 && accounts.length === filters.resultsPerPage) {
      setFilters({ ...filters, page: filters.page + 1 })
    }
  }

  const renderFilters = () => {
    return (
      <Fragment>
        <div
          className="font-semibold text-blue-500 cursor-pointer underline mb-3"
          onClick={() => setShowFilters(false)}
        >
          Hide Filters
        </div>
        <div className="flex flex-row mb-3">
          <FlexContainer flexDirection="column" width="35%">
            <Label>Search Accounts</Label>
            <TextInput
              type="text"
              placeholder="Search by name, pin, domain"
              value={filters.hubspotSearch || undefined}
              onChange={onChangeAccountSearch}
            />
          </FlexContainer>
          <div className="mx-2"></div>
          <FlexContainer flexDirection="column" width="35%">
            <Label>Search by Contact</Label>
            <TextInput
              type="text"
              placeholder="Search by contact email"
              value={filters.contactEmail || undefined}
              onChange={onChangeContactSearch}
            />
          </FlexContainer>
        </div>
        <div className="w-1/5 mb-3">
          <MarketDropdown
            label="Headquarters"
            options={headquarters}
            onSelectHq={onSelectHq}
            onDeselectHq={onDeselectHq}
            selectedOptions={selectedHqs}
          />
        </div>
        <div className="mb-3">
          <FlexContainer flexDirection="column">
            <Label>First Order Date</Label>
            <FlexContainer flexDirection="row">
              <DateInput
                label="From"
                date={
                  filters.firstOrderFromDate &&
                  Moment(filters.firstOrderFromDate)
                }
                onChange={onChangeFirstOrderDateSearch('From')}
                clearDate={onClearFirstOrderDateSearch('From')}
              />
              <div className="mx-3"></div>
              <DateInput
                label="To"
                date={
                  filters.firstOrderToDate && Moment(filters.firstOrderToDate)
                }
                onChange={onChangeFirstOrderDateSearch('To')}
                clearDate={onClearFirstOrderDateSearch('To')}
              />
            </FlexContainer>
          </FlexContainer>
        </div>
        <div className="mb-3 w-full flex flex-row justify-between items-center">
          <div>
            <FlexContainer flexDirection="row">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-6"
                onClick={() => onChangePage(-1)}
              >
                Previous Page
              </button>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => onChangePage(1)}
              >
                Next Page
              </button>
            </FlexContainer>
          </div>
          <div>{accounts.length} Results</div>
        </div>
      </Fragment>
    )
  }

  const pHubspotId = (hubspotId: string | null): string => {
    if (!hubspotId) {
      return 'Not linked'
    } else if (hubspotId === '0') {
      return 'Not linked'
    }

    return hubspotId
  }

  return (
    <Panel width="100%">
      <div className="sticky">
        <div className=" mb-3 py-2 sticky top-0 bg-white">
          {showFilters ? (
            renderFilters()
          ) : (
            <div
              className="font-semibold text-blue-500 cursor-pointer underline mb-3"
              onClick={() => setShowFilters(true)}
            >
              Show Filters
            </div>
          )}
        </div>
        <div
          style={{
            width: '100%',
            overflowX: 'scroll',
          }}
        >
          <Table
            headings={[
              '',
              'Name',
              'Pin',
              'Market',
              'First Order Date',
              'Domain',
              'Contacts',
              'Hubspot ID',
            ]}
          >
            {accounts.map((account, idx) => {
              const {
                id,
                name,
                pin,
                markets,
                firstOrderDate,
                domain,
                contacts,
                hubspotId,
              } = account

              return (
                <tr
                  key={`${id}-${idx}`}
                  style={{
                    backgroundColor:
                      selectedAccounts && selectedAccounts[id]
                        ? colors.blue100
                        : 'white',
                  }}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedAccounts && selectedAccounts[id]}
                      onChange={onSelectAccount && onSelectAccount(id)}
                    />
                  </td>
                  <td>{name}</td>
                  <td>{pin}</td>
                  <td>
                    {(markets || []).map((market) => market.name).join(', ')}
                  </td>
                  <td>
                    {(firstOrderDate &&
                      Moment(firstOrderDate).format('MM/DD/YYYY')) ??
                      ''}
                  </td>
                  <td>{domain ?? ''}</td>
                  <td>
                    {(contacts || [])
                      .map((contact) => `${contact.name} (${contact.email})`)
                      .join(', ')}
                  </td>
                  <td>{pHubspotId(hubspotId)}</td>
                </tr>
              )
            })}
          </Table>
        </div>
      </div>
    </Panel>
  )
}

export default ClientTable
