import React, { useState, useEffect, useMemo } from 'react'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import Table from '@components/common/Table'
import Loader from '@components/common/Loader'

const ACC_CUSTOMER_LIMIT = 40
const RENDER_LIMIT = 15

interface AccountCustomer {
  id: string
  firstName: string
  lastName: string
  email: string
  accountRoles: string[]
}

interface MarketplaceUser {
  id: string
  firstName: string
  lastName: string
  email: string
}

interface AccountUser {
  name: string
  email: string
  mpAccess: boolean
  isAdmin: boolean
  needsMarketplaceUser: boolean
}

interface GroupOrderCustomersProps {
  accountId: string
  loadAccountCustomers: ({
    accountId,
    limit,
    offset,
  }: {
    accountId: string
    limit: number
    offset: number
  }) => Promise<AccountCustomer[]>
  loadAccountMarketplaceUsers: (accountId: string) => Promise<MarketplaceUser[]>
}

const mergeUsers = (
  accountCustomers: AccountCustomer[],
  mpUserMap: { [key: string]: MarketplaceUser },
): AccountUser[] =>
  accountCustomers
    .sort((a, b) => a.lastName.localeCompare(b.lastName))
    .map((accCust) => {
      const mpUser = mpUserMap[accCust.email]
      const isAdmin = accCust.accountRoles.includes('Admin')

      return {
        name: `${accCust.firstName} ${accCust.lastName}`,
        email: accCust.email,
        mpAccess: !!mpUser,
        isAdmin,
        needsMarketplaceUser: isAdmin && !mpUser,
      }
    })

const filterUsers = (
  search: string,
  page: number,
  accountUsers: AccountUser[],
): AccountUser[] => {
  let filteredUsers = accountUsers

  if (search !== '') {
    const regex = new RegExp(search, 'i')
    filteredUsers = accountUsers.filter((accountUser) => {
      return (
        regex.test(`${accountUser.name}`) || regex.test(`${accountUser.email}`)
      )
    })
  }

  const start = (page - 1) * RENDER_LIMIT

  return filteredUsers.slice(start, start + RENDER_LIMIT)
}

const GroupOrderCustomers: React.FC<GroupOrderCustomersProps> = ({
  accountId,
  loadAccountCustomers,
  loadAccountMarketplaceUsers,
}) => {
  const [accountUsers, setAccountUsers] = useState<AccountUser[]>([])
  const [page, setPage] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const loadAccCusts = async (): Promise<AccountCustomer[]> => {
    let loadPage = 1,
      done = false
    let accCusts: AccountCustomer[] = []
    while (!done) {
      const accCustomers = await loadAccountCustomers({
        accountId,
        limit: ACC_CUSTOMER_LIMIT,
        offset: (loadPage - 1) * ACC_CUSTOMER_LIMIT,
      })
      accCusts = accCusts.concat(accCustomers)
      done = accCustomers.length < ACC_CUSTOMER_LIMIT
      loadPage++
    }

    return accCusts
  }

  const loadMpUsers = async (): Promise<{ [key: string]: MarketplaceUser }> => {
    const mpUsers = await loadAccountMarketplaceUsers(accountId)
    const mpUserMap: { [key: string]: MarketplaceUser } = {}
    mpUsers.forEach((mpUser) => {
      mpUserMap[mpUser.email] = mpUser
    })

    return mpUserMap
  }

  useEffect(() => {
    const loadUsers = async () => {
      setLoading(true)
      await Promise.all([loadAccCusts(), loadMpUsers()]).then(
        ([accCusts, mpUserMap]) => {
          setAccountUsers(mergeUsers(accCusts, mpUserMap))
        },
      )
      setLoading(false)
    }

    loadUsers()
  }, [accountId])

  const filteredAccUsers = useMemo(() => {
    return filterUsers(search, page, accountUsers)
  }, [accountUsers, search, page])

  const onChangePage = (dir: number) => {
    if (page === 1 && dir === -1) {
      return
    }
    if (filteredAccUsers.length < RENDER_LIMIT && dir === 1) {
      return
    }

    setPage(page + dir)
  }

  return (
    <div>
      <div className="flex flex-row items-center justify-items-center mt-5">
        <BiLeftArrow
          className="cursor-pointer"
          onClick={() => onChangePage(-1)}
        />
        <div className="text-gray-700 font-semibold">{`Group Order Customers: Page ${page} of ${Math.ceil(
          accountUsers.length / RENDER_LIMIT,
        )}`}</div>
        <BiRightArrow
          className="cursor-pointer"
          onClick={() => onChangePage(1)}
        />
      </div>
      <div>
        <input
          className="w-1/4 my-3 p-2 border border-gray-700 rounded-lg"
          type="text"
          placeholder="Search by name or email"
          value={search}
          onChange={(e) => {
            setPage(1)
            setSearch(e.target.value)
          }}
        />
      </div>
      {!loading ? (
        <Table
          headings={[
            'Name',
            'Email',
            'Marketplace Admin Dashboard Access?',
            'Group Order Admin Access',
          ]}
        >
          {filteredAccUsers.map((accUser, i) => {
            const needsMpUser: boolean = accUser.needsMarketplaceUser

            let style = ''
            if (needsMpUser) {
              style = 'bg-red-500 font-semibold'
            }

            return (
              <tr
                key={`${accUser.name}-${accUser.email}-${i}`}
                className={style}
              >
                <td
                  className="py-2"
                  style={needsMpUser ? { color: 'white' } : undefined}
                >
                  {accUser.name}
                </td>
                <td
                  className="py-2"
                  style={needsMpUser ? { color: 'white' } : undefined}
                >
                  {accUser.email}
                </td>
                <td
                  className="py-2"
                  style={needsMpUser ? { color: 'white' } : undefined}
                >
                  {accUser.mpAccess ? 'Yes' : 'No'}
                </td>
                <td
                  className="py-2"
                  style={needsMpUser ? { color: 'white' } : undefined}
                >
                  {accUser.isAdmin ? 'Yes' : 'No'}
                </td>
              </tr>
            )
          })}
        </Table>
      ) : (
        <Loader width="200px" />
      )}
    </div>
  )
}

export default GroupOrderCustomers
