import { TOGGLE_DASHBOARD_LOADING } from '@actions/ui/dashboard'

const ordersDashboardPageInitialState = {
  isLoading: false,
}

export function ordersDashboardPage(
  state = ordersDashboardPageInitialState,
  action,
) {
  const { on, type } = action
  switch (type) {
    case TOGGLE_DASHBOARD_LOADING: {
      return { ...state, isLoading: on }
    }
    default:
      return state
  }
}
