import * as addressSchemas from './address'
import * as contactSchemas from './contact'
import * as emailSchemas from './email'
import * as paymentMethodSchemas from './paymentMethod'

export default Object.freeze({
  ...addressSchemas,
  ...contactSchemas,
  ...emailSchemas,
  ...paymentMethodSchemas,
})
