export const InitBraintree =
  ({ BraintreeService, RestService }) =>
  async () => {
    if (BraintreeService.client) {
      return
    }

    const token = await RestService.get('/api/admin/client_token')

    return await BraintreeService.initBraintree(token)
  }

export const UpdateNonce =
  ({ BraintreeService, UIService, pRequestBraintreeNonce, HandleError }) =>
  async (data) => {
    if (!BraintreeService.validate(data)) {
      return null
    }

    data = pRequestBraintreeNonce(data)

    try {
      const response = await BraintreeService.updateNonce(data)
      UIService.Errors.clearNamespace('paymentMethodInput')
      UIService.FlashMessage.displaySuccessMessage(
        'Credit card successfully validated!',
      )

      return response.creditCards[0].nonce
    } catch (error) {
      console.log('Error Updating Nonce', error)
      HandleError({ error, namespace: 'paymentMethodInput' })
    }
  }
