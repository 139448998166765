export const Validate =
  ({ UIService, ValidationService }) =>
  ({ validator, namespace, doFlash = true }) =>
  (data) => {
    const { errors, isValid } = ValidationService.validate(validator)(data)
    if (!isValid) {
      UIService.Errors.updateErrors({ errors, namespace })
      if (doFlash) {
        UIService.FlashMessage.displayFailureMessage(
          'Please check the errors below',
        )
      }
    }

    return isValid
  }

export const ValidateAndReturnError =
  ({ UIService, ValidationService }) =>
  ({ validator, namespace, doFlash = true }) =>
  (data) => {
    const { errors, isValid } = ValidationService.validate(validator)(data)
    if (!isValid) {
      UIService.Errors.updateErrors({ errors, namespace })
      if (doFlash) {
        UIService.FlashMessage.displayFailureMessage(
          'Please check the errors below',
        )
      }
    }

    return { isValid, errors }
  }
