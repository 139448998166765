import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

import {
  PopUpModal,
  PopUpOrdersModal,
  PopUpNotificationsModal,
} from '@containers/popUps'
import { Checkbox, DateInput } from '@components/common/form'
import Input from '@components/common/form/Input'
import Button from '@components/common/form/Button'
import LinkText from '@components/common/form/LinkText'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import DividerLine from '@components/common/DividerLine'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import Modal from '@components/common/modal/Modal'
import Dropdown from '@components/common/form/Dropdown'
import { AuthorizedInteractable } from '@containers/common/auth'

const AllStatuses = [
  'Proposed',
  'Created',
  'Rejected',
  'Delivered',
  'Deleted',
  'Paid',
]

class PopUpsPage extends Component {
  state = {
    search: '',
    searchDate: Moment(),
    searchLimit: 20,
    searchOffset: 0,
    showEditModal: false,
    showOrdersModal: false,
    showNotifModal: false,
    popUps: [],
    selectedPopUp: undefined,
    selectedAccounting: undefined,
    selectedOrders: [],
    statuses: [...AllStatuses],
    sortBy: [undefined, true],
    selectAll: false,
    selected: {},
    showMarkPaidModal: false,
    paymentType: '',
  }
  searchTimer = undefined

  componentDidMount() {
    this.searchPopUps()
    const pathBreakdown = location.pathname.match(/\/pop-ups\/([^/]*)/)
    if (pathBreakdown && pathBreakdown.length > 1) {
      this.editPopUp(pathBreakdown[1])()
    }
  }

  searchPopUps = async () => {
    const { searchPopUps, headquarter } = this.props
    const { search, searchDate, searchLimit, searchOffset, statuses } =
      this.state
    const popUps = await searchPopUps({
      headquarter,
      search,
      searchDate,
      statuses,
      limit: searchLimit,
      offset: searchOffset,
    })
    if (popUps) {
      this.setState({ popUps })
    }
  }

  searchAfterTimeout = () => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer)
    }
    this.searchTimer = undefined
    this.searchTimer = setTimeout(() => {
      this.searchPopUps()
    }, 550)
  }

  copyPopUp = async (id) => {
    this.hideEditModal()
    const popUp = await this.props.copyPopUp(id)
    if (popUp) {
      this.setState({
        selectedPopUp: popUp,
        showEditModal: true,
      })
    }
  }

  editPopUp = (id) => async () => {
    const popUp = await this.props.getPopUp(id)
    if (popUp) {
      this.setState({
        selectedPopUp: popUp,
        showEditModal: true,
      })
    }
  }

  hideEditModal = () =>
    this.setState({ showEditModal: false, selectedPopUp: undefined })

  notifPopUp = (id) => async () => {
    const popUp = await this.props.getPopUp(id)
    if (popUp) {
      this.setState({
        selectedPopUp: popUp,
        showNotifModal: true,
      })
    }
  }

  hideNotifModal = () =>
    this.setState({ showNotifModal: false, selectedPopUp: undefined })

  showPopUpOrders = async (id) => {
    const resp = await this.props.getPopUpAndOrders(id)
    if (resp) {
      const { orders, popUp, accounting } = resp
      this.setState({
        selectedPopUp: popUp,
        selectedOrders: orders,
        showOrdersModal: true,
        selectedAccounting: accounting,
      })
    }
  }

  hideOrdersModal = () =>
    this.setState({
      showOrdersModal: false,
      selectedPopUp: undefined,
      selectedAccounting: undefined,
      selectedOrders: [],
    })

  onToggleStatus = (status) => () => {
    const { statuses } = this.state
    if (statuses.includes(status)) {
      this.setState(
        { statuses: statuses.filter((s) => s !== status) },
        this.searchPopUps,
      )
    } else {
      this.setState({ statuses: [...statuses, status] }, this.searchPopUps)
    }
  }

  sortPopUps = (attribute, transform = (a) => a) => {
    const { popUps, sortBy } = this.state
    const asc = attribute !== sortBy[0] || !sortBy[1]
    this.setState({
      sortBy: [attribute, asc],
      popUps: popUps.sort((a, b) => {
        if (transform(a[attribute]) > transform(b[attribute])) {
          return asc ? 1 : -1
        }
        if (transform(a[attribute]) < transform(b[attribute])) {
          return asc ? -1 : 1
        }

        return 0
      }),
    })
  }

  onSelectAll = () => {
    const { selectAll, popUps } = this.state
    if (selectAll) {
      this.setState({ selected: {} })
    } else {
      this.setState({
        selected: popUps.reduce((map, order) => {
          if (order.status !== 'Paid') {
            map[order.id] = order
          }

          return map
        }, {}),
      })
    }
    this.setState({ selectAll: !selectAll })
  }

  onToggleItem = (checked, order) => {
    const { selected } = this.state
    const { id, status } = order
    const copy = { ...selected }
    if (checked && status !== 'Paid') {
      copy[id] = order
    } else {
      delete copy[id]
    }
    this.setState({ selected: copy })
  }

  onToggleMarkPaidModal = (show) => {
    this.setState({ showMarkPaidModal: show })
  }

  onSelectPaymentType = (e) => {
    const paymentType = e.target.value
    this.setState({ paymentType })
  }

  onMarkPaid = async () => {
    const { selected, paymentType } = this.state
    const { markPopUpsPaid, email } = this.props
    const ids = Object.keys(selected)
    if (await markPopUpsPaid({ ids, paymentType, alias: email })) {
      this.setState({ selected: {}, showMarkPaidModal: false })
      this.searchPopUps()
    }
  }

  renderMarkPaidModal = () => {
    const { paymentType } = this.state

    return (
      <Modal
        title="Mark Paid"
        hideModal={() => this.onToggleMarkPaidModal(false)}
        color="#001940"
        width="400px"
      >
        <div className="flex flex-col">
          <p className="my-3">
            Please select a payment type for marked orders.
          </p>
          <Dropdown
            label="Payment Type"
            value={paymentType}
            onChange={this.onSelectPaymentType}
            testId="payment-type-dropdown"
          >
            <option key={-1} value="">
              Select a payment type
            </option>
            <option key={'ach'} value="ACH">
              ACH
            </option>
            <option key={'check'} value="Check">
              Check
            </option>
          </Dropdown>
          <button
            className="btn gradient-bg text-white font-semibold my-3 justify-end"
            onClick={this.onMarkPaid}
          >
            Save
          </button>
        </div>
      </Modal>
    )
  }

  render() {
    const { notifyPopUpChef, theme } = this.props
    const {
      popUps,
      search,
      searchDate,
      searchLimit,
      searchOffset,
      selectedOrders,
      selectedPopUp,
      selectedAccounting,
      showEditModal,
      showNotifModal,
      showOrdersModal,
      statuses,
      selected,
      selectAll,
      showMarkPaidModal,
    } = this.state

    return (
      <Panel width="100%" maxWidth="1200px" heading="Edit Pop Ups & See Orders">
        {showEditModal && (
          <PopUpModal
            popUp={selectedPopUp}
            notifyPopUpChef={notifyPopUpChef}
            theme={theme}
            hideModal={this.hideEditModal}
            searchPopUps={this.searchPopUps}
            copy={this.copyPopUp}
          />
        )}
        {showOrdersModal && (
          <PopUpOrdersModal
            popUp={selectedPopUp}
            orders={selectedOrders}
            isPaid={selectedAccounting.isPaid}
            theme={theme}
            hideModal={this.hideOrdersModal}
          />
        )}
        {showNotifModal && (
          <PopUpNotificationsModal
            popUp={selectedPopUp}
            notifyPopUpChef={notifyPopUpChef}
            theme={theme}
            hideModal={this.hideNotifModal}
            searchPopUps={this.searchPopUps}
          />
        )}
        <FlexContainer alignItems="flex-end">
          <Input
            label="Search Pop Ups"
            marginBottom="0"
            width="200px"
            type="text"
            value={search}
            onChange={(e) =>
              this.setState(
                {
                  search: e.target.value,
                  searchOffset: 0,
                },
                this.searchAfterTimeout,
              )
            }
          />
          <XSpacing width="20px" />
          <DateInput
            width="200px"
            label="Pop Up Date"
            date={searchDate}
            dateFormat="default"
            isStatic={false}
            onChange={(searchDate) =>
              this.setState({ searchDate }, this.searchPopUps)
            }
            clearDate={() =>
              this.setState({ searchDate: undefined }, this.searchPopUps)
            }
          />
          <XSpacing width="20px" />
          <Button
            label="New Pop Up"
            onClick={() =>
              this.setState({ showEditModal: true, selectedPopUp: undefined })
            }
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer alignItems="flex-start">
          {AllStatuses.map((status) => (
            <div key={status} style={{ display: 'flex' }}>
              <Checkbox
                label={status}
                value={status}
                checked={statuses.includes(status)}
                onChange={this.onToggleStatus(status)}
              />
              <XSpacing width="30px" />
            </div>
          ))}
        </FlexContainer>
        <DividerLine margin="20px 0" />
        {Object.values(selected).length > 0 && (
          <div>
            <FlexContainer alignItems="flex-start">
              <AuthorizedInteractable roles={['master admin', 'finance']}>
                <button
                  className="btn gradient-bg text-white font-semibold"
                  onClick={() => this.onToggleMarkPaidModal(true)}
                >
                  Mark Paid
                </button>
              </AuthorizedInteractable>
            </FlexContainer>
            <YSpacing height="10px" />
          </div>
        )}
        <Table>
          <tr>
            <th>
              <AuthorizedInteractable roles={['master admin', 'finance']}>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={this.onSelectAll}
                />
              </AuthorizedInteractable>
            </th>
            <th onClick={() => this.sortPopUps('number')}>Number</th>
            <th onClick={() => this.sortPopUps('name')}>Name</th>
            <th onClick={() => this.sortPopUps('status')}>Status</th>
            <th
              onClick={() => this.sortPopUps('menu', (menu) => menu.chef.name)}
            >
              Chef
            </th>
            <th onClick={() => this.sortPopUps('start', (x) => Moment(x))}>
              Date
            </th>
            <th
              onClick={() => this.sortPopUps('effectiveDate', (x) => Moment(x))}
            >
              Effective Date
            </th>
            <th onClick={() => this.sortPopUps('dueDate', (x) => Moment(x))}>
              Due Date
            </th>
            <th
              onClick={() => this.sortPopUps('client', (client) => client.name)}
            >
              Client
            </th>
            <th />
          </tr>
          {popUps.map((popUp) => (
            <tr key={popUp.id}>
              <td>
                <AuthorizedInteractable roles={['master admin', 'finance']}>
                  <input
                    type="checkbox"
                    checked={selected[popUp.id] || false}
                    onChange={(e) => this.onToggleItem(e.target.checked, popUp)}
                  />
                </AuthorizedInteractable>
              </td>
              <td>
                <p>{popUp.number}</p>
              </td>
              <td>
                <p>{popUp.name}</p>
              </td>
              <td>
                <p>{popUp.status}</p>
              </td>
              <td>
                <p>Chef {popUp.menu.chef.name}</p>
              </td>
              <td>
                <p>{Moment(popUp.start).format('LLL')}</p>
              </td>
              <td>
                <p>{Moment(popUp.effectiveDate).format('LLL')}</p>
              </td>
              <td>
                <p>{Moment(popUp.dueDate).format('LLL')}</p>
              </td>
              <td>
                <p>{popUp.client.name} </p>
              </td>
              <td>
                <div className="flex">
                  <LinkText label="Edit" onClick={this.editPopUp(popUp.id)} />
                  <XSpacing width="20px" />
                  <LinkText
                    label="Orders"
                    onClick={() => this.showPopUpOrders(popUp.id)}
                  />
                  <XSpacing width="20px" />
                  <LinkText label="Notif" onClick={this.notifPopUp(popUp.id)} />
                </div>
              </td>
            </tr>
          ))}
        </Table>
        {searchOffset > 0 && (
          <button
            onClick={() =>
              this.setState(
                { searchOffset: searchOffset - searchLimit },
                this.searchPopUps,
              )
            }
          >
            &lt;
          </button>
        )}
        Page {1 + Math.floor(parseFloat(searchOffset / searchLimit))}
        {searchLimit === popUps.length && (
          <button
            onClick={() =>
              this.setState(
                { searchOffset: searchOffset + searchLimit },
                this.searchPopUps,
              )
            }
          >
            &gt;
          </button>
        )}
        {showMarkPaidModal && this.renderMarkPaidModal()}
      </Panel>
    )
  }
}

PopUpsPage.propTypes = {
  headquarter: PropTypes.number,
  email: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  theme: PropTypes.object,

  copyPopUp: PropTypes.func,
  getPopUp: PropTypes.func,
  getPopUpAndOrders: PropTypes.func,
  markPopUpsPaid: PropTypes.func,
  searchPopUps: PropTypes.func,
  notifyPopUpChef: PropTypes.func,
}

export default PopUpsPage
