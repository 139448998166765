import React, { useState } from 'react'
import Modal from '@components/common/modal/Modal'
import { Button, LinkText } from '@components/common/form'
import Loader from '@components/common/Loader'
import { SlackAuthData, PopUpCustomer } from '@types'

export type SlackAuthDataOption = {
  authData: SlackAuthData
  customer: PopUpCustomer
}

type Props = {
  options: SlackAuthDataOption[]
  teamId: string
  isLoadingCustomers: boolean
  onSave: (customerId: string) => void
  onClose: () => void
}

const SlackAuthDataModal: React.FC<Props> = ({
  options,
  teamId,
  isLoadingCustomers,
  onSave,
  onClose,
}) => {
  const [selectedCustomerId, setSelectedCustomerId] = useState('')

  const handleSelectCustomer = (customerId: string) => {
    if (selectedCustomerId === customerId) {
      setSelectedCustomerId('')
    } else {
      setSelectedCustomerId(customerId)
    }
  }

  const handleSave = () => {
    if (!selectedCustomerId) {
      alert('Please select an employee before saving.')

      return
    }

    onSave(selectedCustomerId)
  }

  const renderContent = () => {
    return (
      <div className="slack-auth-data-modal">
        <div className="heading-container">
          <p className="heading-text">
            {`Select an employee from list below. Slack notifications will use the
            selected employee's Slack authentication data to send alerts to
            their Slack workspace.`}
          </p>
          <p className="heading-note">
            <b>*NOTE:</b> Only employees who have connected the Hungry Slack app
            to their Slack team with ID <b>{teamId}</b> will be shown below.
          </p>
        </div>

        <div className="options-section">
          {isLoadingCustomers ? (
            <div className="loader-container">
              <Loader />
            </div>
          ) : (
            <>
              {options.length === 0 && (
                <div className="no-slack-auth-data-options">
                  None of the employees for this location have added the Hungry
                  Slack app to their Slack team with ID <b>{teamId}</b>.
                </div>
              )}
              {options.length > 0 && (
                <div className="options-container">
                  {options.map((option) => (
                    <button
                      key={option.customer.id}
                      onClick={() => handleSelectCustomer(option.customer.id)}
                      className={`slack-auth-data-option ${
                        selectedCustomerId === option.customer.id
                          ? 'selected'
                          : ''
                      }`}
                    >
                      {option.customer.email}
                    </button>
                  ))}
                </div>
              )}
            </>
          )}
        </div>

        <div className="buttons-container">
          <LinkText label="Cancel" onClick={() => onClose()} color="#b30303" />
          <Button label="Save" onClick={handleSave} />
        </div>
      </div>
    )
  }

  return (
    <Modal
      title={'Select Slack User'}
      hideModal={onClose}
      color="#001940"
      maxWidth="400px"
      width="400px"
      height="600px"
    >
      {renderContent()}
    </Modal>
  )
}

export default SlackAuthDataModal
