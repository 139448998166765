import {
  AverageMenuMargin,
  AverageMenuPrice,
  Distance,
  FilterConfig,
  LastScheduled,
  VendorMaxGroupOrderItems,
  VendorMenucardCap,
} from '@types'
import { GREATER_THAN_CUSTOM, LESS_THAN_CUSTOM } from '../../../../constants'

export const scheduleFilters: FilterConfig[] = [
  {
    name: 'dateTime',
    key: 'availOn',
    label: 'Available On',
    default: '',
    options: [],
    placeHolder: '',
  },
  {
    name: 'time',
    key: 'earliestPickup',
    label: 'Chef Earliest Pickup Time',
    default: '',
    disabledKey: 'availOn',
    options: [],
    placeHolder: '',
    needsInfo: true,
    information:
      "Shows all chef menu cards whose pickup is before the entered time. A Chef's Group Order earliest cutoff time, is based on their group order earliest cutoff time. If that value is not set, it will default to the chef's start time on their schedule for the day in question. Due to this, we need to have a date & time to correctly calculate earliest pick up time.",
  },
  {
    name: 'multiSelectObj',
    key: 'cuisines',
    label: 'Cuisine',
    default: '',
    options: [],
    placeHolder: '',
  },
  {
    name: 'dropdown-number',
    key: 'menucardCutoff',
    label: 'Menucard Cutoff Time',
    options: [
      {},
      {
        label: 'Same Day',
        value: 0,
      },
      {
        label: '1 Business Day Before',
        value: 1,
      },
      {
        label: '2 Business Days Before',
        value: 2,
      },
    ],
    default: '',
    placeHolder: '',
  },
  {
    name: 'dropdown',
    key: 'avgMenuPrice',
    label: 'Average Menu Price Range',
    options: [
      {
        label: 'Less Than $10',
        value: AverageMenuPrice.LESS_THAN_TEN,
      },
      {
        label: '$10 - $15',
        value: AverageMenuPrice.TEN_TO_FIFTEEN,
      },
      {
        label: 'Greater Than $10',
        value: AverageMenuPrice.GREATER_THAN_10,
      },
    ],
    default: '',
    placeHolder: '',
  },
  {
    name: 'dropdown',
    key: 'avgMenuMargin',
    label: 'Average Menu Margin',
    placeHolder: 'Enter Value',
    options: [
      {
        label: 'Less Than 75%',
        value: AverageMenuMargin.LESS_THAN_75,
      },
      {
        label: 'Greater Than 50%',
        value: AverageMenuMargin.GREATER_THAN_50,
      },
      {
        label: 'Custom Less than %',
        value: LESS_THAN_CUSTOM,
      },
      {
        label: 'Custom Greater than %',
        value: GREATER_THAN_CUSTOM,
      },
    ],
    default: '',
  },
  {
    name: 'dropdown-boolean',
    key: 'readyToHeat',
    label: 'Ready to Eat / Ready to Heat',
    options: [
      {},
      {
        label: 'Ready to Eat',
        value: false,
      },
      {
        label: 'Ready to Heat',
        value: true,
      },
    ],
    default: '',
    placeHolder: '',
  },
  {
    name: 'dropdown',
    key: 'vendorMaxItems',
    label: 'Vendor Max GO Items Per Day',
    placeHolder: 'Enter Value',
    options: [
      {
        label: 'Greater Than/Equal 100',
        value: VendorMaxGroupOrderItems.GREATER_THAN_100,
      },
      {
        label: 'Less Than 100',
        value: VendorMaxGroupOrderItems.LESS_THAN_100,
      },
      {
        label: 'Custom Less than #',
        value: LESS_THAN_CUSTOM,
      },
      {
        label: 'Custom Greater than #',
        value: GREATER_THAN_CUSTOM,
      },
    ],
    default: '',
  },
  {
    name: 'dropdown',
    key: 'menuOrderingCap',
    label: 'Vendor Menucard Cap',
    placeHolder: 'Enter Value',
    options: [
      {
        label: 'Greater Than/Equal 100',
        value: VendorMenucardCap.GREATER_THAN_100,
      },
      {
        label: 'Less Than 100',
        value: VendorMenucardCap.LESS_THAN_100,
      },
      {
        label: 'Custom Less than #',
        value: LESS_THAN_CUSTOM,
      },
      {
        label: 'Custom Greater than #',
        value: GREATER_THAN_CUSTOM,
      },
    ],
    default: '',
  },
  {
    name: 'client',
    key: 'clientId',

    label: '',
    default: '',
    options: [],
    placeHolder: '',
  },
  {
    name: 'dropdown',
    key: 'distance',
    label: 'Distance From Client Address*',
    placeHolder: 'Enter Value',
    options: [
      {
        label: 'Within 10 Miles',
        value: Distance.LESS_THAN_10_MILES,
      },
      {
        label: 'Within 20 Miles',
        value: Distance.LESS_THAN_20_MILES,
      },
      {
        label: 'Custom: Within X Mile(s)',
        value: LESS_THAN_CUSTOM,
      },
    ],
    default: '',
    information:
      "This filter calculates the distance between the provided client's address and the chef's address on the menu.",
  },
  {
    name: 'dropdown',
    key: 'lastScheduled',
    label: 'Last Scheduled By Client*',
    placeHolder: 'Enter Value',
    options: [
      {
        label: 'Less Than 1 Week',
        value: LastScheduled.LESS_THAN_1_WEEK,
      },
      {
        label: 'Less Than 2 Weeks',
        value: LastScheduled.LESS_THAN_2_WEEKS,
      },
      {
        label: 'Less Than 4 Weeks',
        value: LastScheduled.LESS_THAN_4_WEEKS,
      },
      {
        label: 'Custom: Greater Than/Equal X Week(s)',
        value: GREATER_THAN_CUSTOM,
      },
      {
        label: 'Custom: Less Than X Week(s)',
        value: LESS_THAN_CUSTOM,
      },
    ],
    default: '',
    information:
      'This filter calculates when the menu card was last scheduled by the inputted client.',
  },
  {
    name: 'dropdown-number',
    key: 'avgRating',
    label: 'Avg Rating of Menu Items By Client*',
    placeHolder: 'Enter Value (3.5)',
    options: [
      {},
      {
        label: 'Greater Than 3 Stars',
        value: 3,
      },
      {
        label: 'Greater 4 Stars',
        value: 4,
      },
      {
        label: '5 Stars',
        value: 5,
      },
      {
        label: 'Custom: Greater Than # Stars',
        value: GREATER_THAN_CUSTOM,
      },
    ],
    default: '',
    information:
      'This filter calculates the average rating of menu items based on a min # of 5 reviews from employess, specifically for each client.',
  },
]

export const initialDisabledFilters = {
  availOn: true,
}
