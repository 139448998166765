import React, { Component } from 'react'
import PropTypes from 'prop-types'

class NumberInput extends Component {
  state = {
    value: undefined,
    isFocused: false,
  }

  componentDidMount() {
    let { value } = this.props
    if (value == null) {
      this.setState({ value: '' })
    } else {
      value = value.toString()
      this.setState({ value })
    }
  }

  componentWillReceiveProps(nextProps) {
    let { value } = nextProps
    if (value !== this.props.value) {
      if (value == null) {
        this.setState({ value: '' })
      } else {
        value = value.toString()
        this.setState({ value })
      }
    }
  }

  onBlur = (e) => {
    const { onBlur } = this.props

    this.setState({ isFocused: false })
    onBlur && onBlur(e)
  }

  onFocus = (e) => {
    const { onFocus } = this.props

    this.setState({ isFocused: true })
    onFocus && onFocus(e)
  }

  onInput = (e) => {
    let { value } = e.target
    const { onInput } = this.props
    const moreThanOneDecimal = /[.].*[.]/
    if (!moreThanOneDecimal.test(value)) {
      value = value.replace(/[^0-9.]/, '')
      this.setState({ value })
      onInput && onInput(value)
    }
  }

  render() {
    const { error, placeholder } = this.props
    const { isFocused, value } = this.state

    return (
      <div className="error-container">
        {error && !isFocused && (
          <p className="text-red-700 bold text-base">{error}</p>
        )}
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onInput={this.onInput}
        />
      </div>
    )
  }
}

NumberInput.propTypes = {
  error: PropTypes.string,
  value: PropTypes.number,
  placeholder: PropTypes.string,

  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onInput: PropTypes.func,
}

export default NumberInput
