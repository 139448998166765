import React, { useEffect, useState } from 'react'
import { config } from 'hungry-core2'
import { FaArrowCircleDown, FaArrowCircleUp, FaFilter } from 'react-icons/fa'
import XSpacing from '@components/common/XSpacing'
import YSpacing from '@components/common/YSpacing'
import { DateInput, Dropdown, TextInput } from '@components/common/form'
import { LinkDinerProfileToPastOrdersModal } from '@containers/account/accountSections'
import { AutocompleteInput } from '@containers/common/form'
import { Account, Chef, Orderable } from '@types'
import { Moment } from 'moment-timezone'

const Colspan = 12
const Headers = [
  'Number',
  'Event Date',
  'Time',
  'Head Count',
  'Diner Profile',
  'Contact Name',
  'Contact Email',
  'Delivery Address',
  'Chef',
  'Sales Rep',
  'Total',
]

interface Filters {
  contactId: string | undefined
  chefId: string | undefined
  dinerProfileId: string | undefined
  dropoffAddressId: string | undefined
  page: number
  resultsPerPage: number
  search: string | undefined
  accountId: string | undefined
  serializer: string
  fromDate: undefined | Moment
  toDate: undefined | Moment
}

interface OrderableHistoryProps {
  account: Account
  buildGetRequest: (val: Filters) => string
  callAfterTimeout: (fn: () => void) => void
  dinerProfiles: { id: string; name: string }[]
  getOrderableCount: (filters: Filters) => Promise<number>
  loadChefs: (val: { search: string }) => Chef[]
  orderable: string // Proposal or Order - used for display
  orderableType: string // proposals or sales - used for redirect
  searchOrderables: (filters: Filters) => Promise<Orderable[]>
}

const OrderableHistory = ({
  account,
  buildGetRequest,
  callAfterTimeout,
  dinerProfiles,
  getOrderableCount,
  loadChefs,
  orderable,
  orderableType,
  searchOrderables,
}: OrderableHistoryProps) => {
  const [dinerProfileMap, setDinerProfileMap] = useState<
    Record<string, string>
  >({})
  const [count, setCount] = useState(0)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [filters, setFilters] = useState<Filters>({
    contactId: undefined,
    chefId: undefined,
    dinerProfileId: undefined,
    dropoffAddressId: undefined,
    page: 1,
    resultsPerPage: 20,
    search: undefined,
    accountId: account.id,
    serializer: 'for_account',
    fromDate: undefined,
    toDate: undefined,
  })
  const [orderables, setOrderables] = useState<Orderable[]>([])
  const [showFilter, setShowFilter] = useState(true)
  const [showLinkDinerProfileModal, setShowLinkDinerProfileModal] =
    useState(false)

  useEffect(() => {
    const map: Record<string, string> = {}
    dinerProfiles.forEach((dp) => {
      map[dp.id] = dp.name
    })
    setDinerProfileMap(map)
    searchOrdersWithCount(filters, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id, dinerProfiles])

  const searchOrdersWithCount = (filters: Filters, getCounts: boolean) => {
    setOrderables([])
    loadOrders(filters)
    if (getCounts) {
      getOrderCountFromApi(filters)
    }
  }

  const getOrderCountFromApi = async (filters: Filters) => {
    const count = await getOrderableCount(filters)
    setCount(count)
  }

  const loadOrders = async (filters: Filters) => {
    const orderables = await searchOrderables(filters)
    setOrderables(orderables)
  }

  const delayedSearchOrders = (filters: Filters, getCounts: boolean) => {
    callAfterTimeout(() => searchOrdersWithCount(filters, getCounts))
  }

  const updateFilters = (
    newFilterValues: Partial<Filters>,
    getCounts = true,
  ) => {
    const newFilters = { ...filters, ...newFilterValues }
    setFilters(newFilters)
    delayedSearchOrders(newFilters, getCounts)
  }

  const renderOrderable = (orderable: Orderable) => {
    const {
      accountExecutive,
      id,
      contact,
      dinerProfileId,
      dropoffAddress,
      orderNumber,
      chefName,
      total,
      dateMoment,
      headCount,
    } = orderable
    const link = `../${orderableType}/${id}`

    return (
      <tr key={id}>
        <td>
          <a
            className="order-link"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {orderNumber}
          </a>
        </td>
        <td>
          <div> {dateMoment && dateMoment.format('MMM Do YYYY')}</div>
        </td>
        <td>
          <div> {dateMoment && dateMoment.format('h:mm:ss a')}</div>
        </td>
        <td>
          <div> {headCount}</div>
        </td>
        <td>
          <div>{dinerProfileMap[dinerProfileId]}</div>
        </td>
        <td>
          <div>{contact ? contact.name : 'No Contact'}</div>
        </td>
        <td>
          <div>{contact ? contact.email : 'No Contact'}</div>
        </td>
        <td>
          <div>
            {dropoffAddress
              ? dropoffAddress.line1 +
                ' ' +
                dropoffAddress.city +
                ' ' +
                dropoffAddress.state +
                ' ' +
                dropoffAddress.zip
              : 'No Address'}
          </div>
        </td>
        <td>
          <div> {chefName}</div>
        </td>
        <td>
          <div>
            {accountExecutive
              ? accountExecutive.firstName + ' ' + accountExecutive.lastName
              : 'No Sales Rep'}
          </div>
        </td>
        <td>
          <div> ${total}</div>
        </td>
      </tr>
    )
  }

  const renderFilters = () => {
    const csvUrl = buildGetRequest(filters)
    if (isCollapsed) {
      return <></>
    }

    return (
      <div
        className="flex flex-col border-r-2 bg-white px-4 shadow-md rounded-lg"
        style={{ maxHeight: '700px', minHeight: '600px', minWidth: '300px' }}
      >
        <div className="flex flex-col items-center pt-5">
          <h1 className="font-bold underline text-blue-500 text-2xl">
            Filters
          </h1>
        </div>
        <YSpacing height="20px" />
        <div className="flex justify-between">
          <TextInput
            placeholder={`Search ${orderable} Number`}
            value={filters.search}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateFilters({ search: e.target.value, page: 1 })
            }
            width="100%"
          />
        </div>
        <YSpacing height="20px" />
        <div>
          <DateInput
            date={filters.fromDate}
            label="From"
            onChange={(value: Moment) =>
              updateFilters({ fromDate: value, page: 1 })
            }
            clearDate={() => updateFilters({ fromDate: undefined, page: 1 })}
          />
        </div>
        <YSpacing height="5px" />
        <div>
          <DateInput
            date={filters.toDate}
            label="To"
            onChange={(value: Moment) =>
              updateFilters({ toDate: value, page: 1 })
            }
            clearDate={() => updateFilters({ toDate: undefined, page: 1 })}
          />
        </div>
        <YSpacing height="10px" />
        <Dropdown
          label="Contact"
          value={filters.contactId}
          onChange={(e) =>
            updateFilters({ contactId: e.target.value, page: 1 })
          }
        >
          <option />
          {account.contacts.map((c) => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))}
        </Dropdown>
        <YSpacing height="10px" />
        <Dropdown
          label="Dropoff Address"
          value={filters.dropoffAddressId}
          onChange={(e) =>
            updateFilters({ dropoffAddressId: e.target.value, page: 1 })
          }
        >
          <option />
          {account.addresses.map((a) => (
            <option key={a.id} value={a.id}>
              {a.line1 + ' ' + a.city + ' ' + a.state + ' ' + a.zip}
            </option>
          ))}
        </Dropdown>
        <YSpacing height="10px" />
        <Dropdown
          label="Diner Profile"
          value={filters.dinerProfileId}
          onChange={(e) =>
            updateFilters({ dinerProfileId: e.target.value, page: 1 })
          }
        >
          <option />
          {Object.keys(dinerProfileMap || {}).map((dpId) => (
            <option key={dpId} value={dpId}>
              {dinerProfileMap[dpId]}
            </option>
          ))}
        </Dropdown>
        <YSpacing height="10px" />
        <AutocompleteInput
          label="Chef"
          loaderFunction={(search) => loadChefs({ ...search })}
          onSelect={(value) => updateFilters({ chefId: value.id })}
        />
        <YSpacing height="20px" />
        <div className="flex">
          {orderables && (
            <button
              className="page-arrow"
              style={{ paddingTop: '5px' }}
              onClick={() => updateFilters({ page: filters.page - 1 })}
              disabled={filters.page <= 1}
            >
              ◄
            </button>
          )}
          <XSpacing width="5px" />
          <p className="nowrap page-number">Page {filters.page}</p>
          <XSpacing width="5px" />
          {orderables &&
            orderables.length > 0 &&
            orderables.length === filters.resultsPerPage && (
              <button
                className="page-arrow"
                onClick={() => updateFilters({ page: filters.page + 1 })}
                style={{ paddingTop: '5px' }}
              >
                ►
              </button>
            )}
        </div>
        <YSpacing height="20px" />
        <div className="flex flex-align-center">
          <p>Show </p>
          <select
            className="page-select"
            value={filters.resultsPerPage}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              updateFilters({
                resultsPerPage: parseInt(e.target.value || '20'),
                page: 1,
              })
            }
          >
            {[20, 50, 100].map((o) => (
              <option key={o} value={o}>
                {o}
              </option>
            ))}
          </select>
          <p>Per Page</p>
        </div>
        <YSpacing height="20px" />
        <div>
          {orderables && orderables.length > 0 && (
            <a
              className="button-primary"
              href={`${config.api_host}/api/admin/${orderableType}/?csv=true${csvUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Export To CSV
            </a>
          )}
        </div>
        <YSpacing height="10px" />
      </div>
    )
  }

  const { resultsPerPage, page } = filters
  let shown = `${(page - 1) * resultsPerPage + 1} - ${
    (page - 1) * resultsPerPage + ((orderables && orderables.length) || 0)
  }`
  if (orderables && orderables.length === 0) {
    shown = '0'
  }

  return (
    <div className="flex flex-start" style={{ backgroundColor: '#eff1f6' }}>
      {showLinkDinerProfileModal && (
        <LinkDinerProfileToPastOrdersModal
          dinerProfileMap={dinerProfileMap}
          hideModal={() => setShowLinkDinerProfileModal(false)}
        />
      )}
      {showFilter && renderFilters()}
      <XSpacing width="25px" />
      <div className="bg-white rounded-lg w-full">
        <h1 style={{ marginLeft: '28px' }} className="text-xl font-bold">
          {orderable} History
        </h1>
        <div className="overflow-x-auto">
          <table className="table page bg-white mx-5">
            <thead>
              <tr>
                <th colSpan={Colspan}>
                  <div className="flex justify-apart">
                    <div className="flex">
                      {isCollapsed ? (
                        <button
                          onClick={() => setIsCollapsed(false)}
                          className="flex items-center"
                        >
                          <strong>Show</strong>
                          <FaArrowCircleDown className="ml-2" />
                        </button>
                      ) : (
                        <button
                          onClick={() => setIsCollapsed(true)}
                          className="flex items-center"
                        >
                          <strong>Hide</strong>
                          <FaArrowCircleUp className="ml-2" />
                        </button>
                      )}
                      <XSpacing width="50px" />
                      {!isCollapsed && (
                        <button
                          onClick={() => setShowFilter(!showFilter)}
                          className="flex items-center text-blue-500"
                        >
                          <FaFilter />
                          {showFilter ? 'Hide Filters' : 'Show Filters'}
                        </button>
                      )}
                    </div>
                    <XSpacing width="100px" />
                    {Object.keys(dinerProfileMap).length > 0 &&
                      orderable === 'Order' && (
                        <button
                          className="text-black font-bold underline cursor-pointer"
                          onClick={() => setShowLinkDinerProfileModal(true)}
                        >
                          Add Past Orders to Diner Profile
                        </button>
                      )}
                  </div>
                  <YSpacing height="10px" />
                  {orderables && !isCollapsed && (
                    <p>
                      Showing {orderables ? orderables.length : 0} orderables{' '}
                      {shown} out of {count == undefined ? '...' : count}
                    </p>
                  )}
                </th>
              </tr>
            </thead>
            {!isCollapsed && (
              <tbody>
                <tr className="promocode-table-headers">
                  {Headers.map((header, i) => (
                    <th
                      key={i}
                      style={{ minWidth: '120px', maxWidth: '200px' }}
                      className="font-bold font-xl"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
                {orderables == undefined && (
                  <tr>
                    <td colSpan={Colspan}>
                      <div className="no-promocodes">Loading...</div>
                    </td>
                  </tr>
                )}
                {orderables && orderables.length === 0 && (
                  <tr>
                    <td colSpan={Colspan}>
                      <div className="no-promocodes">No results</div>
                    </td>
                  </tr>
                )}
                {orderables &&
                  orderables.map((orderable) => renderOrderable(orderable))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  )
}

export default OrderableHistory
