export const vxStatuses = ['Proposed', 'Approved']

export const vxOrderStatuses = [
  'single',
  'bundle',
  'prepaid',
  'cancelled',
  'not associated',
  'need confirmation',
]

export const lookupTypes = ['Client', 'Talent', 'Sales Rep']

export const orderStatuses = ['Proposed', 'Confirmed', 'Canceled']

export const discountTypes = ['', 'Marketing', 'HUNGRY Guarantee']

export const discountAmountTypes = ['$', '%']

export const ShippingColumns = [
  {
    key: 'name',
    header: 'Name',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Shipping',
    primaryKey: true,
  },
  {
    key: 'email',
    header: 'Email',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Shipping',
  },
  {
    key: 'phone',
    header: 'Phone Number',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Shipping',
  },
  {
    key: 'line1',
    header: 'Address Line 1',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Shipping',
  },
  {
    key: 'line2',
    header: 'Address Line 2',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Shipping',
  },
  {
    key: 'city',
    header: 'City',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Shipping',
  },
  {
    key: 'state',
    header: 'State',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Shipping',
  },
  {
    key: 'zip',
    header: 'Zip',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Shipping',
  },
  {
    key: 'country',
    header: 'Country',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Shipping',
  },
  {
    key: 'client',
    header: 'Client',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Shipping',
  },
  {
    key: 'orderNumber',
    header: 'Order Number',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Shipping',
  },
]
