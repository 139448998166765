import Moment from 'moment-timezone'

import { camelCaseify, formAdd } from '~/utils'

export const pResponsePromoCodes = (json) => {
  const promoCodes = camelCaseify(json)

  promoCodes.forEach((p) => {
    p.value = parseFloat(p.value || 0)
    if (p.kind === 'percentage') {
      p.value *= 100
    }
    p.value = p.value.toFixed(2)

    if (p.isDynamicCode) {
      p.description = 'Dynamic'
    } else if (p.isPercentage) {
      p.description = `%${p.value}`
    } else {
      p.description = `$${p.value}`
    }
    p.code = p.code || ''
    p.kind = p.kind || ''
    p.isActive = p.isActive ? 'Yes' : 'No'
    p.validFrom = p.validFrom
      ? Moment(p.validFrom, 'MM/DD/YYYY HH:mmaa').format('MM/DD/YYYY')
      : 'inactive'
    p.validTo = p.validTo
      ? Moment(p.validTo, 'MM/DD/YYYY HH:mmaa').format('MM/DD/YYYY')
      : 'inactive'
    // p.isPublic = p.isPublic ? 'Public' : 'Private'
    p.redeemLimitPerUser == null ? 'None' : p.redeemLimitPerUser
  })

  return promoCodes
}

export const pResponsePromoCodeAttributes = (json) => {
  const promoCodeAttributes = camelCaseify(json)

  return promoCodeAttributes
}

export const pRequestUpdatePromoCode = (data) => {
  const req = {
    valid_from: Moment(data.validFrom, 'MM/DD/YYYY').format(),
    valid_to: Moment(data.validTo, 'MM/DD/YYYY').format(),
  }

  formAdd(data, req, 'accountId', 'account_id')
  formAdd(data, req, 'code', 'code', (v) => v.toUpperCase())
  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'isPublic', 'is_public')
  formAdd(data, req, 'kind', 'kind')
  formAdd(data, req, 'reason', 'reason')
  formAdd(data, req, 'redeemLimit', 'redeem_limit')
  formAdd(data, req, 'redeemLimitPerUser', 'redeem_limit_per_user')
  if (!data.isDynamicCode) {
    formAdd(data, req, 'value', 'value', (v) =>
      data.kind === 'percentage' ? parseFloat(v) / 100 : v,
    )
  }

  return req
}

export const pPromoErrors = (error) => {
  const errorMessage = Object.entries(error.response.data.error)
    .map(([key, errs]) => {
      return errs.map((err) => `${key}: ${err}`)
    })
    .flat()

  return {
    error,
    errorMessage,
  }
}
