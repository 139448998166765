import { TOGGLE_AUDIT_HISTORY_MODAL } from '@actions/ui/auditHistory'

const initialModalState = { show: false, title: '', auditHistory: [] }

export const auditHistoryModal = (state = initialModalState, action) => {
  switch (action.type) {
    case TOGGLE_AUDIT_HISTORY_MODAL: {
      const { show, title, auditHistory } = action

      return {
        show,
        title: show ? title : '',
        auditHistory: show ? auditHistory : [],
      }
    }
    default:
      return state
  }
}
