import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const searchMenuItems = coordinators.SearchMenuItems({
  RestService: services.RestService,
  pResponseMenuItemsSearch: presenters.Api.pResponseMenuItemsSearch,
  HandleError,
})

const confirmationModal = services.UIService.ConfirmationModal.show

const bulkUpdateMenuItems = coordinators.BulkUpdateMenuItems({
  RestService: services.RestService,
  UIService: services.UIService,
  pResponseMenuItemsSearch: presenters.Api.pResponseMenuItemsSearch,
  pRequestBulkUpdateMenuItems: presenters.Api.pRequestBulkUpdateMenuItems,
  HandleError,
})

const getFilters = () => {
  return [
    {
      name: 'search menu items',
      key: 'search',
    },
    {
      name: 'chef',
      key: 'chefIds',
    },
    {
      name: 'chef status',
      key: 'chefStatus',
      label: 'Chef Status',
      options: [
        {
          label: '- Select -',
          value: '',
        },
        {
          label: 'Active',
          value: 'active',
        },
        {
          label: 'Inactive',
          value: 'inactive',
        },
      ],
    },
    {
      name: 'market types',
      key: 'marketTypes',
    },
    {
      name: 'approved',
      key: 'isApproved',
      label: 'approved',
      options: [
        {
          label: '- Select -',
          value: '',
        },
        {
          label: 'Approved',
          value: 'true',
        },
        {
          label: 'Not Approved',
          value: 'false',
        },
      ],
    },
    {
      name: 'packaging',
      key: 'packaging',
    },
    {
      name: 'dish type',
      key: 'mealType',
    },
    {
      name: 'allergens',
      key: 'allergens',
    },
    {
      name: 'item pickup city',
      key: 'itemLocation',
    },
    {
      name: 'chef price',
      key: 'chefPrice',
    },
    {
      name: 'market price',
      key: 'marketPrice',
    },
    {
      name: 'page',
      key: 'page',
      default: 1,
    },
    {
      name: 'resultsPerPage',
      key: 'resultsPerPage',
      default: 200,
    },
  ]
}

export const MenuItemsData = {
  name: 'Menu Items',
  confirmUpdate: confirmationModal,
  loadData: searchMenuItems,
  updateData: bulkUpdateMenuItems,
  getFilters: getFilters,
  csvPrefix: 'menu-items',
  columns: [
    'Name',
    'Chef',
    'Dish Type',
    'Allergens',
    'Packaging',
    'Chef Price',
    'Market Price',
    'Margin',
    'Menus It’s On',
    'Item Pickup City',
    'Description',
    'Approved',
  ],
  roles: [
    'master admin',
    'sales rep',
    'sales lead',
    'finance',
    'accounting dashboard read only',
  ],
}
