import { useQuery, useQueries } from 'react-query'
import services from '@services'
import { RatingRes, Order, Rating, Sort } from '@types'

const { RestService } = services
interface Props {
  fromDate: string
  toDate: string
  sort: Sort
  isSortAsc: boolean
}

export default function useFeedback(props: Props) {
  const { fromDate, toDate, sort, isSortAsc } = props

  // get all ratings
  const { data: ratingsRes = [] } = useQuery({
    queryKey: [fromDate, toDate],
    refetchOnWindowFocus: false,
    staleTime: 30000,
    queryFn: async (): Promise<Rating[]> => {
      let ratings = await RestService.get(
        `/feedback/filtered_catering_order_reviews?from=${fromDate}&to=${toDate}`,
      )
      if (ratings) {
        ratings = ratings?.map((rating: RatingRes) => {
          return {
            accountId: rating.accountUUID,
            date: rating.orderTime,
            feedback: rating.feedback,
            orderId: rating.orderUUID,
            email: rating.reviewerEmail,
            source: rating.source,
            itemRatings: Object.entries(rating.itemRatings).map((item) => {
              return {
                orderItemId: item[1].orderItemUUID,
                menuItemId: item[1].menuItemUUID,
                rating: item[1].rating,
              }
            }),
          }
        })
      }

      return ratings
    },
  })

  // get order data for each rating
  const orderIds: string[] = []
  ratingsRes.map((rating: Rating) => orderIds.push(rating.orderId))
  const orderIdsReq = [...new Set(orderIds)]
  const ordersRes = useQueries(
    orderIdsReq?.map((orderId) => {
      return {
        queryKey: [orderId],
        refetchOnWindowFocus: false,
        queryFn: async (): Promise<Order | null> => {
          const data = await RestService.get(`/api/admin/orders/${orderId}`, {
            timeout: 3000,
          })
          if (data) {
            const dataObj: Order = {
              id: data.id,
              client: data.account.name,
              orderNumber: data.order_number,
            }
            data.order_menu_items?.map(
              (item: {
                menu_item_id: string
                chef_name: string
                name: string
              }) => {
                dataObj[item.menu_item_id] = {
                  itemName: item.name,
                  chefName: item.chef_name,
                }
              },
            )

            return dataObj
          }

          return null
        },
      }
    }),
  )

  const orders: Record<string, Order> = {}
  if (ordersRes) {
    ordersRes.map((res) => {
      const { data } = res
      if (data) {
        orders[data.id as string] = data
      }
    })
  }

  const ratings: Rating[] = ratingsRes.map((rating: Rating) => {
    return {
      ...rating,
      orderNumber: orders[rating.orderId]?.orderNumber,
      client: orders[rating.orderId]?.client,
      avgRating:
        rating.itemRatings?.reduce((a, b) => a + b.rating, 0) /
        rating.itemRatings.length,
      itemRatings: rating.itemRatings.map((item) => {
        return {
          ...item,
          itemName: orders[rating.orderId]?.[item.menuItemId]?.itemName,
          chefName: orders[rating.orderId]?.[item.menuItemId]?.chefName,
        }
      }),
    }
  })

  if (sort && ratings) {
    ratings.sort((a, b) => {
      if (a[sort] > b[sort]) {
        return isSortAsc ? 1 : -1
      }
      if (a[sort] < b[sort]) {
        return isSortAsc ? -1 : 1
      }

      return 0
    })
  }

  return { ratings }
}
