import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { AuthorizedInteractable } from '@containers/common/auth'

class SelectInput extends Component {
  onChange = (e) => {
    const { onChange } = this.props
    const { value } = e.target

    onChange && onChange(value)
  }

  render() {
    const {
      authRoles,
      defaultOption,
      defaultOptionValue,
      error,
      label,
      name,
      options,
      testId,
      value,
    } = this.props

    return (
      <div className="form-group">
        {label && <label htmlFor={name}>{label}</label>}
        <div className="field">
          <AuthorizedInteractable roles={authRoles}>
            <select
              name={name}
              value={value || ''}
              onChange={this.onChange}
              className="form-control"
              id={testId}
            >
              <option value={defaultOptionValue}>{defaultOption}</option>
              {options.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </AuthorizedInteractable>
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
      </div>
    )
  }
}

SelectInput.propTypes = {
  authRoles: PropTypes.arrayOf(PropTypes.string),
  defaultOption: PropTypes.string,
  defaultOptionValue: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  testId: PropTypes.string,
  value: PropTypes.string,

  onChange: PropTypes.func,
}

SelectInput.defaultProps = {
  defaultOptionValue: '',
  authRoles: [],
}

export default SelectInput
