import React, { Component } from 'react'
import PropTypes from 'prop-types'

import OrderVirtualKit from './OrderVirtualKit'

class VirtualKitsSection extends Component {
  render() {
    const {
      onEditOrderVirtualKit,
      onRemoveOrderVirtualKit,
      onReorderVirtualKit,
    } = this.props
    const orderVirtualKits = this.props.orderVirtualKits || []

    if (orderVirtualKits.length === 0) {
      return null
    }

    return (
      <div className="mt-5">
        <table className="w-100 border-separate order-table">
          <tr>
            <td />
            <td className="w-55 bold uppercase"> Virtual Kit Name </td>
            <td className="bold uppercase text-right"> QTY </td>
            <td className="bold uppercase text-right"> Vendor Price </td>
            <td className="bold uppercase text-right"> Client Price </td>
            <td className="bold uppercase text-right"> Total </td>
          </tr>
          {orderVirtualKits.map((i, index) => (
            <OrderVirtualKit
              key={i.displayOrder}
              virtualKit={i}
              onEdit={onEditOrderVirtualKit}
              onRemove={onRemoveOrderVirtualKit}
              onReorder={onReorderVirtualKit}
              isFirst={index == 0}
              isLast={index == orderVirtualKits.length - 1}
            />
          ))}
        </table>
      </div>
    )
  }
}

VirtualKitsSection.propTypes = {
  orderVirtualKits: PropTypes.arrayOf(PropTypes.object),

  onEditOrderVirtualKit: PropTypes.func,
  onRemoveOrderVirtualKit: PropTypes.func,
  onReorderVirtualKit: PropTypes.func,
}

export default VirtualKitsSection
