import { BaseService } from 'hungry-core2'
import actions from '@actions'

export class HeadquarterService extends BaseService {
  setHeadquarters = (headquarters) => {
    this.dispatch(actions.setHeadquarters(headquarters))
  }
  setUserHeadquarters = (headquarters) => {
    this.dispatch(actions.setUserHeadquarters(headquarters))
  }
}
