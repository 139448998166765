export const EditVirtualItem =
  ({ ChefService, RestService, UIService, pResponseVirtualItem }) =>
  async (virtualItemId) => {
    const virtualItem = await RestService.get(
      `/api/admin/virtual_items/${virtualItemId}`,
    )
    ChefService.setEditVirtualItem(pResponseVirtualItem(virtualItem))
    UIService.EditVirtualItem.show()
  }

export const NewVirtualItem =
  ({ ChefService, UIService, pDefaultVirtualFields }) =>
  () => {
    const { chef, editVirtualItem } = ChefService.getState()
    if (editVirtualItem.id) {
      ChefService.clearEditVirtualItem()
    }

    if (chef) {
      ChefService.setEditVirtualItem(pDefaultVirtualFields(chef))
      UIService.EditVirtualItem.show()
    }
  }

export const LoadChefVirtualItems =
  ({ ChefService, RestService, pResponseVirtualItems }) =>
  async ({ chefId, search }) => {
    const params = {
      include_all: true,
      chef_ids: [chefId],
      search: search || '',
    }
    const response = await RestService.get('/api/admin/virtual_items', params, {
      timeout: 20000,
    })
    const virtualItems = pResponseVirtualItems(response)
    ChefService.setChefVirtualItems(virtualItems)
  }

export const DelayedUpdateVirtualItem =
  ({ ChefService, UIService }) =>
  (virtualItem) => {
    const call = () => ChefService.setEditVirtualItem(virtualItem)

    UIService.Timer.callAfterTimeout(call)
  }

export const SaveVirtualItem =
  ({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateVirtualItem,
    // pRequestUpdateItemImages,
    pResponseVirtualItems,
    HandleError,
  }) =>
  async (data) => {
    try {
      UIService.Errors.clear()
      const req = pRequestUpdateVirtualItem(data)
      const headers = { 'Content-Type': 'multipart/form-data' }

      let virtualItem = undefined
      if (data.id) {
        virtualItem = await RestService.put(
          `/api/admin/virtual_items/${data.id}`,
          req,
          headers,
        )
      } else {
        virtualItem = await RestService.post(
          '/api/admin/virtual_items',
          req,
          headers,
        )
      }

      // const imageUpdateReq = pRequestUpdateItemImages( data, 'virtual_item' )
      // if ( virtualItem && virtualItem.id && imageUpdateReq ) {
      //   virtualItem = await RestService.put( `/api/admin/virtual_items/${ virtualItem.id }`, imageUpdateReq, headers )
      // }

      UIService.EditVirtualItem.close()
      ChefService.clearEditVirtualItem()

      LoadChefVirtualItems({ ChefService, RestService, pResponseVirtualItems })(
        virtualItem.chef_id,
      )
    } catch (error) {
      HandleError({ error, namespace: 'virtualItemModal' })
    }
  }

export const DeleteVirtualItem =
  ({
    ChefService,
    RestService,
    UIService,
    pResponseChef,
    HandleError,
    SelectChef,
  }) =>
  async ({ id, chefId, name }) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete virtual item "${name}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/api/admin/virtual_items/${id}`)
        ChefService.clearEditVirtualItem()
        SelectChef({ ChefService, RestService, pResponseChef })(chefId)
        UIService.EditVirtualItem.close()
      } catch (error) {
        HandleError({ error })
      }
    }
  }
