import { connect } from 'react-redux'

import { Contact } from '@components/order/edit/sections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapDispatchToProps = () => {
  const { AccountService, RestService, UIService } = services
  const { AsyncLoadAccountContacts } = coordinators
  const { pBuildContact, pContactKeys } = presenters
  const { pResponseGeneric } = presenters.Api
  const { clearSingle } = UIService.Errors
  const clearError = clearSingle('BatchContactSection')

  const loadContacts = AsyncLoadAccountContacts({
    AccountService,
    RestService,
    pResponseGeneric,
  })

  return { loadContacts, pBuildContact, pContactKeys, clearError }
}

export default connect(undefined, mapDispatchToProps)(Contact)
