import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Label } from '@res/styledComponents/index'
import FlexContainer from '@components/common/FlexContainer'

interface Option {
  value: string
  label: string
}

interface MultiSelectDropdownProps {
  label: string
  includeSelectAll: boolean
  options: Option[]
  checkedOptions: string[]
  renderCheckedOptions?: boolean
  searchPlaceholder?: string
  onChange: (selected: string[]) => void

  width: string
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  label,
  includeSelectAll,
  options,
  checkedOptions,
  renderCheckedOptions,
  searchPlaceholder,
  onChange,

  width,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options)

  useEffect(() => {
    setSelectedOptions(checkedOptions)
  }, [checkedOptions])

  useEffect(() => {
    if (search === '') {
      setFilteredOptions(options)
    } else {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(search.toLowerCase()),
      )
      setFilteredOptions(filtered)
    }
  }, [search, options])

  const handleCheckboxChange = (optionValue: string) => {
    const newSelectedOptions = selectedOptions.includes(optionValue)
      ? selectedOptions.filter((item) => item !== optionValue)
      : [...selectedOptions, optionValue]

    setSelectedOptions(newSelectedOptions)
    onChange(newSelectedOptions)
  }

  const handleSelectAllChange = () => {
    const newSelectedOptions =
      selectedOptions.length === options.length
        ? []
        : filteredOptions.map((option) => option.value)

    setSelectedOptions(newSelectedOptions)
    onChange(newSelectedOptions)
  }

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  return (
    <FlexContainer flexDirection="column" width={width}>
      <Label>{label}</Label>
      <DropdownContainer>
        <DropdownButton onClick={toggleDropdown}>
          <DropdownHeader>
            <SearchInput
              type="text"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
              placeholder={searchPlaceholder ? searchPlaceholder : 'Search...'}
            />
            <span>{isDropdownVisible ? '▲' : '▼'}</span>
          </DropdownHeader>
        </DropdownButton>
        <DropdownContent isVisible={isDropdownVisible}>
          {includeSelectAll && (
            <CheckboxLabel>
              <input
                type="checkbox"
                checked={
                  selectedOptions.length === filteredOptions.length &&
                  filteredOptions.length > 0
                }
                onChange={handleSelectAllChange}
              />
              Select All
            </CheckboxLabel>
          )}
          {filteredOptions.map((option) => (
            <CheckboxLabel key={option.value}>
              <input
                type="checkbox"
                value={option.value}
                checked={selectedOptions.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}
              />
              {option.label}
            </CheckboxLabel>
          ))}
        </DropdownContent>
        {renderCheckedOptions && (
          <FlexContainer width="80%" flexWrap="wrap">
            {selectedOptions.map((option) => (
              <SelectedOption
                key={option}
                onClick={() => handleCheckboxChange(option)}
              >
                {option} <span style={{ color: 'red' }}>✕</span>
              </SelectedOption>
            ))}
          </FlexContainer>
        )}
      </DropdownContainer>
    </FlexContainer>
  )
}

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  background-color: white;
  color: #3c434e;
  line-height: 1.4;
  width: 100%;
`

const DropdownButton = styled.button`
  background-color: white;
  color: #3c434e;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: end;
  min-width: 160px;
  line-height: 1.4;
  width: 100%;
  &:hover {
    border-color: #999;
  }
`

const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: #3c434e;
  padding: 8px 12px;
  border: 1px solid gray;
  border-radius: 4px;
  min-width: 160px;
  line-height: 1.4;
  width: 100%;
`

const DropdownContent = styled.div<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  position: absolute;
  background-color: #3c434e;
  min-width: 160px;
  min-height: 250px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 4px;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  color: white;

  &:hover {
    background-color: #5496fb;
  }

  input {
    margin-right: 8px;
  }

  input&:hover {
    background-color: #5496fb;
  }
`

const SelectedOption = styled.button`
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 4px 8px;
  margin-top: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
`

const SearchInput = styled.input`
  width: 100%;
  flex-grow: 1;
`

export default MultiSelectDropdown
