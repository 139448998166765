import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class ProposalService extends BaseService {
  orderableId = () => {
    return this.getState().proposal.id
  }

  orderable = () => {
    return this.getState().proposal
  }

  appendOrderables = (proposals) => {
    this.dispatch(actions.appendProposals(proposals))
  }

  clearEditOrderable = () => {
    this.dispatch(actions.clearEditProposal())
  }

  clearNewOrderable = () => {
    this.dispatch(actions.clearNewProposal())
  }

  clearOrderable = () => {
    this.dispatch(actions.clearProposal())
  }

  clearOrderables = () => {
    this.dispatch(actions.clearProposals())
  }

  removeOrderableFromOrderables = (proposalId) => {
    this.dispatch(actions.removeProposalFromProposals(proposalId))
  }

  setEditOrderable = (proposal) => {
    this.dispatch(actions.setEditProposal(proposal))
  }

  setNewOrderable = (proposal) => {
    this.dispatch(actions.setNewProposal(proposal))
  }

  setOrderable = (proposal) => {
    this.dispatch(actions.setProposal(proposal))
  }

  setOrderables = (proposals) => {
    this.dispatch(actions.setProposals(proposals))
  }

  setOrderableItems = (proposalItems) => {
    this.dispatch(actions.setProposalItems(proposalItems))
  }

  setProposalDashboard = (proposals) => {
    this.dispatch(actions.setProposalDashboard(proposals))
  }

  toggleProposalLoader = (on) => {
    this.dispatch(actions.toggleProposalLoader(on))
  }

  toggleProposalMenuItemsLoader = (menuLoaderOn) => {
    this.dispatch(actions.toggleProposalMenuItemsLoader(menuLoaderOn))
  }

  updateEditOrderable = (proposal) => {
    this.dispatch(actions.updateEditProposal(proposal))
  }

  updateNewOrderable = (proposal) => {
    this.dispatch(actions.updateNewProposal(proposal))
  }
}
