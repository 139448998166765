import React, { useState } from 'react'
import { CSVLink } from 'react-csv'
import moment, { Moment } from 'moment-timezone'
import { useQuery } from 'react-query'
import services from '@services'
const { RestService } = services
import DateInput from '@components/common/form/DateInput'
import Input from '@components/common/form/Input'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import Dropdown from '@components/common/form/Dropdown'
import useFeedback from '@hooks/useFeedback'
import { ItemRatings, Rating, Sort } from '@types'

type Account = {
  id: string
  name: string
}

const getCSVData = (data: Rating[]) => {
  const csv = data.map((d) => {
    return {
      date: moment(d.date).format('MM/DD/YYYY'),
      orderNumber: d.orderNumber,
      client: d.client,
      source: d.source,
      email: d.email,
      avgRating: d.avgRating.toFixed(1),
      feedback: d.feedback,
      itemRatings: d.itemRatings
        .map(
          (item) =>
            `${item.rating} - ${item.itemName} (Vendor ${item.chefName})`,
        )
        .join('\n'),
    }
  })

  return csv
}

const FeedbackPage = () => {
  const [fromDate, setFrom] = useState(
    moment().subtract(1, 'month').format('YYYY-MM-DD'),
  )
  const [toDate, setTo] = useState(moment().format('YYYY-MM-DD'))
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState<Sort>('date')
  const [isSortAsc, setIsSortAsc] = useState(false)
  const [account, setAccount] = useState<string>('')
  const { ratings } = useFeedback({ fromDate, toDate, sort, isSortAsc })

  // get accounts for dropdown
  const { data: accounts } = useQuery({
    queryKey: ['accounts'],
    refetchOnWindowFocus: false,
    staleTime: 30000,
    queryFn: async (): Promise<Account[]> => {
      const accounts = await RestService.get('/api/admin/accounts', {
        timeout: 30000,
      })

      return accounts
    },
  })

  const selectedAccount = accounts?.find(
    (acc: { id: string }) => acc.id === account,
  )
  let sortedRatings: Rating[] = ratings ? [...ratings] : []
  let totalAvgRating = 0
  const getAvgRating = (sortedRatings: Rating[]) =>
    sortedRatings?.reduce((a, b) => a + b.avgRating, 0) / sortedRatings.length

  if (account && account.length > 0) {
    sortedRatings = sortedRatings.filter(
      (rating) => rating.accountId === account,
    )
    totalAvgRating = getAvgRating(sortedRatings)
  }
  if (search.length > 0) {
    sortedRatings = sortedRatings.filter((obj) =>
      Object.keys(obj).some((key) => {
        const value = obj[key as keyof typeof obj]
        if (key === 'itemRatings' && Array.isArray(value)) {
          const results = value.filter((item: ItemRatings) =>
            Object.keys(item).some(
              (key) =>
                item[key as keyof typeof item]
                  ?.toString()
                  .toLowerCase()
                  .includes(search.toLowerCase()),
            ),
          )
          if (results.length) {
            return obj
          }
        }

        return value?.toString().toLowerCase().includes(search.toLowerCase())
      }),
    )
    totalAvgRating = getAvgRating(sortedRatings)
  }

  const onChangeSort = (sort: Sort) => {
    setSort(sort)
    setIsSortAsc(!isSortAsc)
  }

  return (
    <div className="mt-10">
      <Panel width="100%" maxWidth="1300px" heading="Catering Diner Feedback">
        <FlexContainer alignItems="flex-end">
          <DateInput
            width="180px"
            label="From"
            date={moment(fromDate)}
            onChange={(date: Moment) => {
              setFrom(date.format('YYYY-MM-DD'))
            }}
          />
          <div className="w-5" />
          <DateInput
            width="180px"
            label="To"
            date={moment(toDate)}
            onChange={(date: Moment) => {
              setTo(date.format('YYYY-MM-DD'))
            }}
          />
          <div className="w-5" />
          <Dropdown
            marginBottom="0"
            width="200px"
            label="Client"
            value={account}
            onChange={(e) => setAccount(e.target.value)}
          >
            <option key="" value="">
              - Show All -
            </option>
            {accounts?.map((account: { id: string; name: string }) => (
              <option key={account.id} value={account.id}>
                {account.name}
              </option>
            ))}
          </Dropdown>
          <div className="w-5" />
          <Input
            label="Search"
            marginBottom="0"
            width="200px"
            type="text"
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="w-5" />
          {ratings && (
            <CSVLink
              data={getCSVData(sortedRatings)}
              filename="Catering Diner Feedback"
              target="_blank"
              className="underline text-turquoise font-semibold mb-2"
            >
              Download CSV
            </CSVLink>
          )}
          <XSpacing width="20px" />
        </FlexContainer>
        {selectedAccount ? (
          <h1 className="my-4">
            <span className="text-lg font-bold text-hungryGray">
              {selectedAccount.name}
            </span>{' '}
            -{' '}
            <span className="text-md font-regular text-hungryGray">
              {' '}
              Avg. rating for date range: {totalAvgRating.toFixed(1)}
            </span>
          </h1>
        ) : (
          <div className="mt-3" />
        )}
        {!selectedAccount && search.length > 0 && sortedRatings.length > 0 && (
          <h1 className="my-4">
            <span className="text-lg font-bold text-hungryGray">
              {' '}
              Avg. rating for all search results: {totalAvgRating.toFixed(1)}
            </span>
          </h1>
        )}
        <Table>
          <thead>
            <tr>
              <th
                className="cursor-pointer"
                onClick={() => onChangeSort('date')}
              >
                Date ▼
              </th>
              <th
                className="cursor-pointer"
                onClick={() => onChangeSort('orderNumber')}
              >
                Order Number ▼
              </th>
              <th
                className="cursor-pointer"
                onClick={() => onChangeSort('client')}
              >
                Client ▼
              </th>
              <th
                className="cursor-pointer"
                onClick={() => onChangeSort('email')}
              >
                Email ▼
              </th>
              <th
                className="cursor-pointer"
                onClick={() => onChangeSort('source')}
              >
                Source ▼
              </th>
              <th
                className="cursor-pointer"
                onClick={() => onChangeSort('avgRating')}
              >
                Avg. Rating ▼
              </th>
              <th className="cursor-pointer">Feedback</th>
              <th className="cursor-pointer">Ratings</th>
            </tr>
          </thead>
          {sortedRatings?.map((rating, i) => (
            <tbody key={i}>
              <tr>
                <td>
                  <p>{moment(rating.date).format('MM/DD/YYYY')}</p>
                </td>
                <td>
                  <p>{rating.orderNumber}</p>
                </td>
                <td>
                  <p>{rating.client}</p>
                </td>
                <td>
                  <p>{rating.email}</p>
                </td>
                <td>
                  <p>{rating.source}</p>
                </td>
                <td>
                  <p>{rating.avgRating.toFixed(1)}</p>
                </td>
                <td>
                  <p>{rating.feedback}</p>
                </td>
                <td>
                  {rating.itemRatings?.map((item: ItemRatings) => (
                    <p className="block mb-1" key={item.menuItemId}>
                      <span className="font-semibold">{item.rating}</span> -{' '}
                      {item.itemName} (Vendor: {item.chefName})
                    </p>
                  ))}
                </td>
              </tr>
            </tbody>
          ))}
        </Table>
      </Panel>
    </div>
  )
}

export default FeedbackPage
