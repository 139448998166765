import { DinerProfile, ApiDinerProfile, Contact, Address } from '@types'

export const pRequestDinerProfile = (
  profile: DinerProfile,
): ApiDinerProfile => {
  const { contact, address, ...rest } = profile

  return {
    ...rest,
    contactId: contact.id,
    addressId: address.id,
  }
}

export const pResponseDinerProfile = (
  profile: ApiDinerProfile,
  contacts: Contact[],
  addresses: Address[],
): DinerProfile => {
  const { contactId, addressId, ...rest } = profile

  const contact = contacts.find((c) => c.id === contactId)
  const address = addresses.find((a) => a.id === addressId)

  return {
    ...rest,
    contact: {
      id: contact?.id || '',
      name: contact?.name || '',
      email: contact?.email || '',
      phoneNumber: contact?.phoneNumber || '',
    },
    address: {
      id: address?.id || '',
      line1: address?.line1 || '',
      line2: address?.line2 || '',
      city: address?.city || '',
      state: address?.state || '',
      zip: address?.zip || '',
    },
  }
}

export const pResponseDinerProfiles = (
  profiles: ApiDinerProfile[],
  contacts: Contact[],
  addresses: Address[],
): DinerProfile[] => {
  return profiles.map((profile) =>
    pResponseDinerProfile(profile, contacts, addresses),
  )
}
