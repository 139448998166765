import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Moment from 'moment-timezone'
import Modal from '@components/common/modal/Modal'
import FlexContainer from '@components/common/FlexContainer'
import Button from '@components/common/form/Button'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import DividerLine from '@components/common/DividerLine'
import LinkText from '@components/common/form/LinkText'
import { colors } from '../../../constants'
import Table from '@components/common/Table'
import { AuthorizedInteractable } from '@containers/common/auth'

const ManualPaymentTypes = ['ACH', 'Check']

class PopUpOrdersModal extends Component {
  state = {
    showMenu: false,
    showPayments: false,
    manualPmtType: ManualPaymentTypes[0],
  }

  onMarkPaid = async (id, number) => {
    const { manualPmtType: paymentType } = this.state
    const { markPopUpPaid, hideModal, email: alias } = this.props
    if (await markPopUpPaid({ id, number, paymentType, alias })) {
      hideModal()
    }
  }

  renderMenuItem = ({
    id,
    name,
    addOn,
    quantity,
    quantitySold,
    quantityAdjusted,
    price,
    childItems,
  }) => {
    return (
      <tbody key={id}>
        <tr>
          <td>
            {name} {addOn && '(Add-On)'}
          </td>
          <td className="text-right">${price}</td>
          <td className="text-right">{quantity}</td>
          <td className="text-right">{quantitySold}</td>
          <td className="text-right">{quantityAdjusted}</td>
        </tr>
        {(childItems || []).map((child) => (
          <tr key={child.id}>
            <td>
              <FlexContainer>
                <XSpacing width="30px" />- {child.name} {addOn && '(Add-On)'}
              </FlexContainer>
            </td>
            <td className="text-right">${child.price}</td>
            <td className="text-right">{child.quantity}</td>
            <td className="text-right">{child.quantitySold}</td>
            <td className="text-right">{child.quantityAdjusted}</td>
          </tr>
        ))}
      </tbody>
    )
  }

  render() {
    const { hideModal, locale, orders, popUp, isPaid } = this.props
    const { manualPmtType, showMenu, showPayments } = this.state

    const {
      id,
      chefSubtotal,
      marketSubtotal,
      menu,
      number,
      prePaidAmount,
      prePaidTax,
      prePaymentTip,
      startedServingAt,
      stoppedServingAt,
    } = popUp

    const paidSubtotal = parseFloat(prePaidAmount || 0.0)
    const paidTax = parseFloat(prePaidTax || 0.0)
    const paidTip = parseFloat(prePaymentTip || 0.0)
    const paidTotal = paidSubtotal + paidTax + paidTip

    const ordersSub = orders.reduce(
      (sum, order) => sum + parseFloat(order.subtotal),
      0,
      0,
    )
    const ordersTax = orders.reduce(
      (sum, order) => sum + parseFloat(order.tax),
      0,
      0,
    )
    const ordersTip = orders.reduce(
      (sum, order) => sum + parseFloat(order.tip),
      0,
      0,
    )
    const ordersDisc = orders.reduce(
      (sum, order) => sum + parseFloat(order.discount),
      0,
      0,
    )
    const ordersTotal = orders.reduce(
      (sum, order) => sum + parseFloat(order.total),
      0,
      0,
    )
    const tableHeadings = ['Name', 'Price', 'QTY', 'QTY Sold', 'QTY Adj']

    return (
      <Modal
        title={`${number} Orders (${orders.length})`}
        hideModal={hideModal}
        color="#001940"
      >
        <PopupTitle>
          <h2>Total Orders: {orders.length}</h2>
          <FlexContainer>
            <p>
              Serving Started:{' '}
              {startedServingAt
                ? Moment(startedServingAt).format('HH:MM a')
                : 'N/A'}
            </p>
            <XSpacing width="30px" />
            <p>
              Serving Stopped:{' '}
              {stoppedServingAt
                ? Moment(stoppedServingAt).format('HH:MM a')
                : 'N/A'}
            </p>
          </FlexContainer>
        </PopupTitle>
        <LinkText
          label="Show / Hide Menu"
          onClick={() => this.setState({ showMenu: !showMenu })}
        />
        {showMenu && (
          <div>
            <YSpacing height="20px" />
            <MenuTitle>
              Entrees ({(menu && menu.entrees && menu.entrees.length) || 0})
            </MenuTitle>
            <Table headings={tableHeadings}>
              {((menu && menu.entrees) || []).map((menuItem) =>
                this.renderMenuItem(menuItem, 'entrees'),
              )}
            </Table>
            <YSpacing height="50px" />
            <MenuTitle>
              Sides ({(menu && menu.sides && menu.sides.length) || 0})
            </MenuTitle>
            <Table headings={tableHeadings}>
              {((menu && menu.sides) || []).map((menuItem) =>
                this.renderMenuItem(menuItem, 'sides'),
              )}
            </Table>
            <YSpacing height="50px" />
            <MenuTitle>
              Drinks ({(menu && menu.drinks && menu.drinks.length) || 0})
            </MenuTitle>
            <Table headings={tableHeadings}>
              {((menu && menu.drinks) || []).map((menuItem) =>
                this.renderMenuItem(menuItem, 'drinks'),
              )}
            </Table>
            <YSpacing height="50px" />
            <MenuTitle>
              Desserts ({(menu && menu.desserts && menu.desserts.length) || 0})
            </MenuTitle>
            <Table headings={tableHeadings}>
              {((menu && menu.desserts) || []).map((menuItem) =>
                this.renderMenuItem(menuItem, 'desserts'),
              )}
            </Table>
            <YSpacing height="50px" />
            <Table headings={['Chef Subtotal', 'Market Subtotal']}>
              <tr>
                <td> ${parseFloat(chefSubtotal).toFixed(2)} </td>
                <td> ${parseFloat(marketSubtotal).toFixed(2)} </td>
              </tr>
            </Table>
          </div>
        )}
        <DividerLine margin="20px 0" />
        <LinkText
          label="Show / Hide Subsidies"
          onClick={() => this.setState({ showPayments: !showPayments })}
        />
        {showPayments && <YSpacing height="20px" />}
        {showPayments && (
          <Table headings={['Subsidy', 'Tax', 'Tip', 'Total']}>
            <tr>
              <td> ${paidSubtotal.toFixed(2)} </td>
              <td> ${paidTax.toFixed(2)} </td>
              <td> ${paidTip.toFixed(2)} </td>
              <td> ${paidTotal.toFixed(2)} </td>
            </tr>
          </Table>
        )}
        <DividerLine margin="20px 0" />
        <Table
          headings={[
            'Number',
            'Pre-Order?',
            'Ordered',
            'Subtotal',
            'Tax',
            'Discount',
            'Tip',
            'Total',
          ]}
        >
          {orders.map((order) => (
            <tr key={order.id}>
              <td> {order.number} </td>
              <td> {order.preOrderStatus ? order.preOrderStatus : 'No'} </td>
              <td>
                {' '}
                {Moment(order.createdAt)
                  .tz(locale)
                  .format('MM/DD/YYYY hh:mm a')}{' '}
              </td>
              <td> ${parseFloat(order.subtotal).toFixed(2)} </td>
              <td> ${parseFloat(order.tax).toFixed(2)} </td>
              <td> ${parseFloat(order.discount).toFixed(2)} </td>
              <td> ${parseFloat(order.tip).toFixed(2)} </td>
              <td> ${parseFloat(order.total).toFixed(2)} </td>
            </tr>
          ))}
          <tr>
            <td />
            <td />
            <td />
            <td className="font-medium"> ${ordersSub.toFixed(2)} </td>
            <td className="font-medium"> ${ordersTax.toFixed(2)} </td>
            <td className="font-medium"> ${ordersDisc.toFixed(2)} </td>
            <td className="font-medium"> ${ordersTip.toFixed(2)} </td>
            <td className="font-medium"> ${ordersTotal.toFixed(2)} </td>
          </tr>
        </Table>
        {!isPaid && (
          <div>
            <FlexContainer justifyContent="flex-end" flexDirection="column">
              <AuthorizedInteractable roles={['master admin', 'finance']}>
                <Button
                  label="Mark Paid"
                  padding="10px"
                  onClick={() => this.onMarkPaid(id, number)}
                />
              </AuthorizedInteractable>
              <select
                value={manualPmtType}
                onChange={(e) =>
                  this.setState({ manualPmtType: e.target.value })
                }
              >
                {ManualPaymentTypes.map((pmtType) => (
                  <option key={pmtType} value={pmtType}>
                    {' '}
                    {pmtType}{' '}
                  </option>
                ))}
              </select>
            </FlexContainer>
          </div>
        )}
      </Modal>
    )
  }
}

const PopupTitle = styled.div`
  margin-bottom: 20px;
  h2 {
    color: ${colors.gray400};
    font-family: 'extrabold';
    font-size: 20px;
  }
  label,
  p {
    margin-top: 5px;
    font-family: 'regular';
  }
`

const MenuTitle = styled.h2`
  font-family: 'extrabold';
  text-transform: uppercase;
  color: ${colors.orange};
`

PopUpOrdersModal.propTypes = {
  email: PropTypes.string,
  locale: PropTypes.string,
  popUp: PropTypes.object,
  orders: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.object,
  isPaid: PropTypes.bool,

  hideModal: PropTypes.func,
  markPopUpPaid: PropTypes.func,
}

export default PopUpOrdersModal
