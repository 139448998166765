import { connect } from 'react-redux'

import { AddressInput } from '@components/common/form'

import presenters from '@presenters'

const mapDispatchToProps = () => {
  const { pBuildAddress } = presenters

  return { pBuildAddress }
}

export default connect(undefined, mapDispatchToProps)(AddressInput)
