import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import { BiPencil } from 'react-icons/bi'
import { ButtonsBar } from '@res/styledComponents/index'
import { LoadingIndicator } from '@components/common'
import { Checkbox, Dropdown } from '@components/common/form'
import FlexContainer from '@components/common/FlexContainer'
import XSpacing from '@components/common/XSpacing'
import sort from '@res/images/sort.svg'
import { AuthorizedInteractable } from '@containers/common/auth'
import { parseMilitaryTime } from '~/utils'
import { MarketTypes, GOrderCutoffTypes } from '@constants'

export class Concepts extends Component {
  state = {
    bulkDeleteMap: {},
    cuisineType: '',
    cuisineTypes: [],
    marketType: '',
    menuConcepts: undefined,
    goCutoffType: '',
  }

  componentWillMount() {
    const { loadConcepts, loadConceptsSettings } = this.props
    loadConcepts()
    loadConceptsSettings()
  }

  componentWillReceiveProps(nextProps) {
    const { menuConcepts, settings } = nextProps
    if (this.props.menuConcepts !== menuConcepts) {
      this.setState({ menuConcepts })
    }
    if (this.props.settings !== settings) {
      this.setState({ cuisineTypes: settings.concepts.allCuisines })
    }
  }

  newMenuConcept = (e) => {
    e.preventDefault()

    this.props.newMenuConcept()
  }

  editMenuConcept = (e, conceptId) => {
    e.preventDefault()

    this.props.editMenuConcept(conceptId)
  }

  sortMenuConceptsByString = (columnName) => {
    const { menuConcepts, sortAsc } = this.state
    const getColumn = (item) =>
      item[columnName] ? item[columnName].toLowerCase() : ''
    const sortedMenuConcepts = menuConcepts.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return sortAsc ? (colA > colB ? 1 : -1) : colB > colA ? 1 : -1
    })
    this.setState({ menuConcepts: sortedMenuConcepts, sortAsc: !sortAsc })
  }

  sortMenuConceptsByNumber = (columnName) => {
    const { menuConcepts, sortAsc } = this.state
    const getColumn = (item) => (item[columnName] ? item[columnName] : 0)
    const sortedMenuConcepts = menuConcepts.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return sortAsc ? (colA > colB ? 1 : -1) : colB > colA ? 1 : -1
    })
    this.setState({ menuConcepts: sortedMenuConcepts, sortAsc: !sortAsc })
  }

  sortMenuConceptsByBoolean = (columnName) => {
    const { menuConcepts, sortAsc } = this.state
    const getColumn = (item) => (item[columnName] === true ? 1 : 0)
    const sortedMenuConcepts = menuConcepts.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return sortAsc ? (colA > colB ? 1 : -1) : colB > colA ? 1 : -1
    })
    this.setState({ menuConcepts: sortedMenuConcepts, sortAsc: !sortAsc })
  }

  handleCheckDelete = (e, concept) => {
    const { bulkDeleteMap } = this.state
    if (Object.keys(bulkDeleteMap).length >= 20 && !bulkDeleteMap[concept.id]) {
      e.preventDefault()
      this.props.displayFailureMessage(
        'Checking this menu card for bulk deletion puts total menu cards over 20. Only able to delete 20 menu cards at a time.',
      )

      return
    }

    if (!bulkDeleteMap[concept.id]) {
      this.setState({
        bulkDeleteMap: { [concept.id]: concept.name, ...bulkDeleteMap },
      })
    } else {
      delete bulkDeleteMap[concept.id]
      this.setState({ bulkDeleteMap })
    }
  }

  handleBulkDelete = async () => {
    const { bulkDeleteMap } = this.state
    const { bulkDeleteConcepts } = this.props
    await bulkDeleteConcepts(bulkDeleteMap)
    this.setState({ bulkDeleteMap: {} })
  }

  getDisplayedMenuConcepts = () => {
    const { cuisineType, marketType, menuConcepts, goCutoffType } = this.state
    let displayedMenuConcepts = menuConcepts || []
    if (marketType) {
      displayedMenuConcepts = displayedMenuConcepts.filter(
        (concept) => concept.marketType === marketType,
      )
    }
    if (cuisineType) {
      displayedMenuConcepts = displayedMenuConcepts.filter((concept) =>
        concept.tagsCuisineList.split(',').includes(cuisineType),
      )
    }
    if (goCutoffType === GOrderCutoffTypes.CUSTOM) {
      displayedMenuConcepts = displayedMenuConcepts.filter((concept) =>
        Boolean(concept.groupOrderCutoffTime),
      )
    }
    if (goCutoffType === GOrderCutoffTypes.STANDARD) {
      displayedMenuConcepts = displayedMenuConcepts.filter(
        (concept) =>
          concept.marketType === MarketTypes.GROUP_ORDER &&
          concept.groupOrderCutoffTime === null,
      )
    }

    return displayedMenuConcepts
  }

  formatTimes = (menuConcept) => {
    let cutoffTime = '9AM'
    let pickupTime = '9AM'
    if (menuConcept.groupOrderCutoffTime !== null) {
      // eslint-disable-next-line prefer-const
      let [hours, minutes] = parseMilitaryTime(menuConcept.groupOrderCutoffTime)
      const amOrPM = hours > 12 ? 'PM' : 'AM'
      hours = hours > 12 ? hours - 12 : hours
      cutoffTime = `${hours}${!minutes ? '' : `:${minutes}`}${amOrPM}`
    }
    if (menuConcept.groupOrderPickupTime !== null) {
      // eslint-disable-next-line prefer-const
      let [hours, minutes] = parseMilitaryTime(menuConcept.groupOrderPickupTime)
      const amOrPM = hours >= 12 ? 'PM' : 'AM'
      hours = hours > 12 ? hours - 12 : hours
      pickupTime = `${hours}${!minutes ? '' : `:${minutes}`}${amOrPM}`
    }

    return [cutoffTime, pickupTime]
  }

  render() {
    const {
      bulkDeleteMap,
      cuisineType,
      goCutoffType,
      marketType,
      menuConcepts,
    } = this.state
    const areConceptsLoaded = !!menuConcepts
    const areConceptsEmpty = menuConcepts && menuConcepts.length === 0
    const displayedMenuConcepts = this.getDisplayedMenuConcepts()

    return (
      <div>
        <ButtonsBar>
          <FlexContainer>
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <button
                onClick={this.newMenuConcept}
                style={{ paddingTop: '25px' }}
              >
                {' '}
                <BiPencil />
                Add New Menu Card{' '}
              </button>
            </AuthorizedInteractable>
            <XSpacing width="20px" />
            <Dropdown
              label="Filter By Market Type"
              width="200px"
              value={marketType}
              onChange={(e) => this.setState({ marketType: e.target.value })}
            >
              <option value="">No Filter</option>
              {Object.values(MarketTypes).map((market) => (
                <option key={market} value={market}>
                  {market}
                </option>
              ))}
            </Dropdown>
            <XSpacing width="20px" />
            <Dropdown
              label="Filter By Cusine Type"
              width="200px"
              value={cuisineType}
              onChange={(e) => this.setState({ cuisineType: e.target.value })}
            >
              <option value="">No Filter</option>
              {this.state.cuisineTypes.map((cuisine) => (
                <option key={cuisine} value={cuisine}>
                  {cuisine}
                </option>
              ))}
            </Dropdown>
            <XSpacing width="20px" />
            <Dropdown
              label="Filter By GO Cutoff Type"
              width="200px"
              value={goCutoffType}
              onChange={(e) => this.setState({ goCutoffType: e.target.value })}
            >
              <option value="">No Filter</option>
              {Object.values(GOrderCutoffTypes).map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Dropdown>
          </FlexContainer>
        </ButtonsBar>
        <Panel isScroll>
          <Table>
            <thead>
              <tr>
                <th>Mark for Deletion</th>
                <th
                  className="pointer"
                  onClick={() => this.sortMenuConceptsByString('name')}
                >
                  <p className="flex">
                    Name
                    <img className="item-sort" src={sort} />
                  </p>{' '}
                </th>
                <th
                  className="pointer"
                  onClick={() => this.sortMenuConceptsByString('marketType')}
                >
                  <p className="flex">
                    Market Type
                    <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer"
                  onClick={() => this.sortMenuConceptsByNumber('margin')}
                >
                  <p className="flex">
                    Margin
                    <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer"
                  onClick={() =>
                    this.sortMenuConceptsByString('tagsCuisineList')
                  }
                >
                  <p className="flex">
                    Cuisine Type
                    <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer"
                  onClick={() =>
                    this.sortMenuConceptsByString('groupOrderMealTypes ')
                  }
                >
                  <p className="flex">
                    Group Order Meal Type
                    <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer"
                  onClick={() =>
                    this.sortMenuConceptsByString('tagsDayPartList')
                  }
                >
                  <p className="flex">
                    Day Part
                    <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer"
                  onClick={() =>
                    this.sortMenuConceptsByBoolean('individuallyPackaged')
                  }
                >
                  <p className="flex">
                    Individual Pkg
                    <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer"
                  onClick={() => this.sortMenuConceptsByBoolean('isEnabled')}
                >
                  <p className="flex">
                    Live
                    <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer"
                  onClick={() => this.sortMenuConceptsByBoolean('isApproved')}
                >
                  <p className="flex">
                    Approved
                    <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer"
                  onClick={() =>
                    this.sortMenuConceptsByBoolean('isUpdatedStructure')
                  }
                >
                  <p className="flex">
                    Updated Menu Structure
                    <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer"
                  onClick={() =>
                    this.sortMenuConceptsByNumber('groupOrderCutoffTime')
                  }
                >
                  <p className="flex">
                    GO Menu Cutoff Type
                    <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer"
                  onClick={() =>
                    this.sortMenuConceptsByNumber('groupOrderCutoffTime')
                  }
                >
                  <p className="flex">
                    GO Menu Cutoff Time
                    <img className="item-sort" src={sort} />
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {!areConceptsLoaded && (
                <tr>
                  <td colSpan="4">
                    <LoadingIndicator />
                  </td>
                </tr>
              )}

              {areConceptsLoaded && areConceptsEmpty && (
                <tr>
                  <td colSpan="4">
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '1.2em',
                        color: 'rgba(0,0,0,0.2)',
                        padding: '20px',
                        backgroundColor: 'rgba(0,0,0,0.01)',
                      }}
                    >
                      No concepts.
                    </div>
                  </td>
                </tr>
              )}

              {areConceptsLoaded &&
                displayedMenuConcepts.map((menuConcept) => {
                  const [cutoffTime, pickupTime] = this.formatTimes(menuConcept)

                  return (
                    <tr
                      key={(menuConcept && menuConcept.id) || '1'}
                      className="clickable"
                    >
                      <td>
                        <Checkbox
                          checked={bulkDeleteMap[menuConcept.id]}
                          onChange={(e) =>
                            this.handleCheckDelete(e, menuConcept)
                          }
                        />
                      </td>
                      <td
                        onClick={(e) => this.editMenuConcept(e, menuConcept.id)}
                      >
                        {' '}
                        {menuConcept.name}{' '}
                      </td>
                      <td
                        onClick={(e) => this.editMenuConcept(e, menuConcept.id)}
                      >
                        {' '}
                        {menuConcept.marketType}{' '}
                      </td>
                      <td
                        onClick={(e) => this.editMenuConcept(e, menuConcept.id)}
                      >
                        {' '}
                        {menuConcept.margin.toFixed(2)}%
                      </td>
                      <td
                        onClick={(e) => this.editMenuConcept(e, menuConcept.id)}
                      >
                        {menuConcept.tagsCuisineList &&
                          menuConcept.tagsCuisineList
                            .split(',')
                            .map((cuisine) => (
                              <span
                                key={`tag_${cuisine}`}
                                className="badge badge-cuisine"
                              >
                                {' '}
                                {cuisine}{' '}
                              </span>
                            ))}
                      </td>
                      <td
                        onClick={(e) => this.editMenuConcept(e, menuConcept.id)}
                      >
                        {menuConcept.groupOrderMealTypes &&
                          menuConcept.groupOrderMealTypes
                            .split(',')
                            .map((type) => (
                              <span
                                key={`tag_${type}`}
                                className="badge badge-group-order-meal-type"
                              >
                                {' '}
                                {type}{' '}
                              </span>
                            ))}
                      </td>
                      <td
                        onClick={(e) => this.editMenuConcept(e, menuConcept.id)}
                      >
                        {menuConcept.tagsDayPartList &&
                          menuConcept.tagsDayPartList
                            .split(',')
                            .map((dayPart) => (
                              <span
                                key={`meal_${dayPart}`}
                                className="badge badge-meal-period"
                              >
                                {' '}
                                {dayPart}{' '}
                              </span>
                            ))}
                      </td>
                      <td
                        onClick={(e) => this.editMenuConcept(e, menuConcept.id)}
                      >
                        {menuConcept.individuallyPackaged ? (
                          <span className="approved-check">✔</span>
                        ) : (
                          <span className="not-approved-x">✖</span>
                        )}
                      </td>
                      <td
                        onClick={(e) => this.editMenuConcept(e, menuConcept.id)}
                      >
                        {menuConcept.isEnabled ? (
                          <span className="approved-check">✔</span>
                        ) : (
                          <span className="not-approved-x">✖</span>
                        )}
                      </td>
                      <td
                        onClick={(e) => this.editMenuConcept(e, menuConcept.id)}
                      >
                        {menuConcept.isApproved ? (
                          <span className="approved-check">✔</span>
                        ) : (
                          <span className="not-approved-x">✖</span>
                        )}
                      </td>
                      <td
                        onClick={(e) => this.editMenuConcept(e, menuConcept.id)}
                      >
                        {menuConcept.isUpdatedStructure ? (
                          <span className="approved-check">✔</span>
                        ) : (
                          <span className="not-approved-x">✖</span>
                        )}
                      </td>
                      <td
                        onClick={(e) => this.editMenuConcept(e, menuConcept.id)}
                      >
                        {menuConcept.marketType === MarketTypes.GROUP_ORDER ? (
                          <div
                            className={
                              menuConcept.groupOrderCutoffTime !== null
                                ? 'custom-concept'
                                : 'standard-concept'
                            }
                          >
                            {menuConcept.groupOrderCutoffTime
                              ? GOrderCutoffTypes.CUSTOM
                              : GOrderCutoffTypes.STANDARD}
                          </div>
                        ) : (
                          <p>N/A</p>
                        )}
                      </td>
                      <td
                        onClick={(e) => this.editMenuConcept(e, menuConcept.id)}
                      >
                        {menuConcept.marketType === MarketTypes.GROUP_ORDER ? (
                          <div>
                            <p className="small-text">
                              Order Cutoff:{' '}
                              <span className="bold">{cutoffTime}</span>{' '}
                              {menuConcept.groupOrderCutoffDays
                                ? `${
                                    menuConcept.groupOrderCutoffDays
                                  } Business Day${
                                    menuConcept.groupOrderCutoffDays > 1
                                      ? 's'
                                      : ''
                                  } Before `
                                : 'Same Day as '}
                              Event Date
                            </p>
                            <p className="small-text">
                              Pick Up Time:{' '}
                              <span className="bold">{pickupTime}</span> on
                              Event Date
                            </p>
                          </div>
                        ) : (
                          <p></p>
                        )}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
          {Object.keys(bulkDeleteMap).length > 0 && (
            <button onClick={this.handleBulkDelete}>Bulk Delete</button>
          )}
        </Panel>
      </div>
    )
  }
}

Concepts.propTypes = {
  chefId: PropTypes.string,
  menuConcepts: PropTypes.arrayOf(PropTypes.object),
  settings: PropTypes.object,

  displayFailureMessage: PropTypes.func,
  bulkDeleteConcepts: PropTypes.func,
  editMenuConcept: PropTypes.func,
  loadConcepts: PropTypes.func,
  loadConceptsSettings: PropTypes.func,
  newMenuConcept: PropTypes.func,
}

export default Concepts
