import {
  CLEAR_EDIT_HQ,
  CLEAR_MARKET,
  SET_EDIT_HQ,
  SET_EDIT_SERVICE_DEPOT,
  SET_HQ_DETAILS,
  SET_DELIVERY_ZONE,
  SET_DELIVERY_ZONES,
  SET_SERVICE_DEPOT,
  SET_SERVICE_DEPOTS,
  SET_MARKETS,
  UPDATE_EDIT_MARKET,
} from '@actions/market'

const initialHQState = null
const initialMarketsState = []
const initialEditMarketState = {}

export function hqDetails(state = initialHQState, action) {
  const { type, hqDetails } = action
  switch (type) {
    case SET_HQ_DETAILS:
      return { ...hqDetails }
    case CLEAR_MARKET:
      return { ...initialHQState }
    default:
      return state
  }
}

export function deliveryZone(state = null, action) {
  const { type, deliveryZone } = action
  switch (type) {
    case SET_DELIVERY_ZONE:
      return { ...deliveryZone }
    default:
      return state
  }
}

export function serviceDepot(state = null, action) {
  const { type, serviceDepot } = action
  switch (type) {
    case SET_SERVICE_DEPOT:
      return { ...serviceDepot }
    default:
      return state
  }
}

export function deliveryZones(state = [], action) {
  const { type, deliveryZones } = action
  switch (type) {
    case SET_DELIVERY_ZONES:
      return deliveryZones
    default:
      return state
  }
}

export function serviceDepots(state = [], action) {
  const { type, serviceDepots } = action
  switch (type) {
    case SET_SERVICE_DEPOTS:
      return serviceDepots
    default:
      return state
  }
}

export function markets(state = initialMarketsState, action) {
  const { type, markets } = action
  switch (type) {
    case SET_MARKETS:
      return markets.slice()
    default:
      return state
  }
}

export function editServiceDepot(state = initialEditMarketState, action) {
  const { type, serviceDepot } = action

  switch (type) {
    case SET_EDIT_SERVICE_DEPOT:
      return { ...serviceDepot }
    default:
      return state
  }
}

export function editHQ(state = initialEditMarketState, action) {
  const { type, hq } = action

  switch (type) {
    case SET_EDIT_HQ:
      return { ...hq }
    case UPDATE_EDIT_MARKET:
      return { ...state, ...hq }
    case CLEAR_EDIT_HQ:
      return { ...initialEditMarketState }
    default:
      return state
  }
}
