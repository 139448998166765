export const DeletePromoCode =
  ({
    PromoCodesService,
    RestService,
    UIService,
    pResponsePromoCodes,
    LoadPromoCodes,
  }) =>
  async (data) => {
    const text = `Are you sure you want to delete promo code: ${data.code}?`
    const doDelete = await UIService.ConfirmationModal.show({ text })
    if (doDelete) {
      await RestService.delete(`/api/admin/promo_codes/${data.id}`)

      LoadPromoCodes({ PromoCodesService, RestService, pResponsePromoCodes })()
    }
  }

export const LoadPromoCodes =
  ({ PromoCodesService, RestService, pResponsePromoCodes }) =>
  async () => {
    const allCodes = []
    let page = 1
    const limit = 100
    while (true) {
      const params = { page, results_per_page: limit }
      const codes =
        (await RestService.get('/api/admin/promo_codes', params)) || []
      if (codes.length === 0) {
        break
      }
      allCodes.push(...pResponsePromoCodes(codes))
      page += 1
    }
    PromoCodesService.setPromoCodes(allCodes)
  }

export const LoadPromoCodeAttributes =
  ({ PromoCodesService, RestService, pResponsePromoCodeAttributes }) =>
  async () => {
    let promoCodeAttributes = await RestService.get(
      '/api/admin/promo_codes/all_attributes',
    )
    promoCodeAttributes = pResponsePromoCodeAttributes(promoCodeAttributes)
    PromoCodesService.setPromoCodeAttributes(promoCodeAttributes)
  }

export const SavePromoCode =
  ({
    PromoCodesService,
    RestService,
    pRequestUpdatePromoCode,
    pResponsePromoCodes,
    HandleError,
    SelectAccount,
  }) =>
  async (data, accountId) => {
    const promoCodeData = pRequestUpdatePromoCode(data)

    try {
      if (promoCodeData.id) {
        await RestService.put(
          `/api/admin/promo_codes/${promoCodeData.id}`,
          promoCodeData,
        )
      } else {
        await RestService.post('/api/admin/promo_codes', promoCodeData)
      }

      LoadPromoCodes({ PromoCodesService, RestService, pResponsePromoCodes })()
      SelectAccount(accountId)

      return true
    } catch (error) {
      HandleError({ error })

      return false
    }
  }
