import React, { useState } from 'react'
import Modal from '@components/common/modal/Modal'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import Button from '@components/common/form/Button'
import TooltipModal from '@components/common/modal/TooltipModal'

type Props = {
  hideModal: () => void
  sendEmail: (text: string) => void
}

const SendBatchProposalPDFsEmailModal = (props: Props) => {
  const { hideModal, sendEmail } = props
  const [recipients, setRecipients] = useState('')

  return (
    <Modal
      title="Emails to send batched proposal pdfs"
      hideModal={hideModal}
      color="#001940"
      width="500px"
    >
      <FlexContainer
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <h1>
          Enter recipients in the text box below separated by semicolons (;)
        </h1>
        <YSpacing height="10px" />
        <FlexContainer
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
        >
          <input
            type="text"
            style={{
              border: '1px solid #8397ab',
              borderRadius: '3px',
              height: '25px',
              width: '200px',
            }}
            name="recipients"
            value={recipients}
            onChange={(e) => setRecipients(e.target.value)}
            placeholder="jack@tryhungry.com;jill@tryhungry.com"
          />
          <TooltipModal
            unicode="&#9432;"
            width="400px"
            information={
              "If an error occurs during the process of creating the combined PDFs, error emails will exclusively be sent to the provided email addresses that have a 'tryhungry' domain."
            }
            marginTop="20px"
          />
        </FlexContainer>
        <YSpacing height="10px" />
        {recipients.length > 0 && (
          <Button label="Send Email" onClick={() => sendEmail(recipients)} />
        )}
        <YSpacing height="20px" />
      </FlexContainer>
    </Modal>
  )
}

export default SendBatchProposalPDFsEmailModal
