import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import FlexContainer from '@components/common/FlexContainer'
import { CurrencyInput, QuantityInput, Checkbox } from '@components/common/form'
import { AuthorizedInteractable } from '@containers/common/auth'
import { reorderArrayByAttribute, sortByAttribute } from '~/utils'
import Tooltip from '@components/common/Tooltip'
import YSpacing from '@components/common/YSpacing'
import { XSpacing } from '~/view/components/common'
import { CloseX } from '@res/styledComponents/index'

class OrderItem extends Component {
  state = {
    chefPrice: 0,
    childItems: [],
    price: 0,
    quantity: 0,
    total: undefined,
    invoiceable: true,
    originalPrice: 0,

    // unmutated
    id: undefined,
    chefId: undefined,
    menuItemId: undefined,
    displayOrder: undefined,
    quantityError: false,
  }

  componentDidMount() {
    const newState = {}
    for (const k in this.state) {
      newState[k] = this.props.orderItem[k]
    }
    newState.originalPrice = this.props.orderItem.price
    this.setState(newState)
  }

  componentWillReceiveProps(nextProps) {
    const { orderItem } = nextProps
    if (orderItem !== this.props.orderItem) {
      const newState = {}
      for (const k in this.state) {
        newState[k] = orderItem[k]
      }
      this.setState(newState)
    }
  }

  updateItem = () => {
    const { isFirst } = this.props
    this.props.onEdit(this.state, isFirst)
  }

  // TODO flatten children in state
  onInputChildCurrency = (attribute, i) => (value) => {
    const childItems = this.state.childItems.slice()
    childItems[i][attribute] = value
    this.setState({ childItems }, this.updateItem)
  }

  onInputChildQuantity = (i) => (quantity) => {
    const childItems = this.state.childItems.slice()
    childItems[i].quantity = quantity || 0
    this.setState({ childItems }, this.updateItem)
  }

  onInputCurrency = (attribute) => (value) => {
    const { isAuthorized, user } = this.props
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      const isMasterAdmin = isAuthorized(user, ['master admin'])
      const isChefLead = isAuthorized(user, ['chef lead'])
      const hasSalesRole = isAuthorized(user, ['sales lead', 'sales rep'])
      if (
        attribute === 'price' &&
        !(isMasterAdmin || isChefLead) &&
        hasSalesRole &&
        !(value >= this.state.originalPrice)
      ) {
        this.props.displayAlert({
          message: 'Sales can only increase the client price',
          title: 'Price Validation',
        })

        return
      }
      this.setState({ [attribute]: value }, this.updateItem)
    }, 1000)
  }

  onInputQuantity = (quantity) => {
    this.setState({ quantity: quantity || 0 }, this.updateItem)
  }

  onBlurQuantity = (quantity) => {
    const { orderItem, displayAlert } = this.props
    const minQty =
      orderItem.minQty || (orderItem.menuItem && orderItem.menuItem.minQty)
    if (quantity < (minQty || 0)) {
      displayAlert({
        message: `Minimum quantity of ${minQty} for ${orderItem.name}`,
        title: 'Quantity Validation',
      })
      this.setState({ quantityError: true, quantity: 0 }, this.updateItem)

      return
    }

    this.setState({ quantity: quantity || 0 }, this.updateItem)
  }

  onCloseQuantityError = () => {
    this.setState({ quantityError: false })
  }

  onRemoveChildItem = (i) => () => {
    const childItems = this.state.childItems.slice()
    childItems.splice(i, 1)
    this.setState({ childItems }, this.updateItem)
  }

  onUpdateInvoiceableChildItem = (i) => (e) => {
    let { childItems } = this.state
    childItems = [
      ...childItems.slice(0, i),
      { ...childItems[i], invoiceable: e.target.checked },
      ...childItems.slice(i + 1, childItems.length),
    ]
    this.setState({ childItems }, this.updateItem)
  }

  reOrderChildItem = (childItem, change) => {
    let { childItems } = this.state
    childItems = reorderArrayByAttribute(
      childItems,
      childItem,
      ['id', 'displayOrder', 'menuItemId'],
      'displayOrder',
      change,
    )
    sortByAttribute(childItems, 'displayOrder')
    this.setState({ childItems }, this.updateItem)
  }

  renderChildReOrder = (childItem, change, isFirst = false, isLast = false) => {
    let symbol
    if (change > 0 && !isLast) {
      symbol = '▼'
    } else if (change < 0 && !isFirst) {
      symbol = '▲'
    }
    if (symbol) {
      return (
        <span
          className="pointer"
          onClick={() => this.reOrderChildItem(childItem, change)}
        >
          {symbol}
        </span>
      )
    }
  }

  renderReOrder = (change) => {
    const { isFirst, isLast, orderItem, onReorder } = this.props

    let symbol
    if (change > 0 && !isLast) {
      symbol = '▼'
    } else if (change < 0 && !isFirst) {
      symbol = '▲'
    }

    if (symbol) {
      return (
        <span className="pointer" onClick={() => onReorder(orderItem, change)}>
          {symbol}
        </span>
      )
    }
  }

  renderItemInfoTooltip = (orderItem) => {
    const menuItem = orderItem.menuItem ? orderItem.menuItem : orderItem
    const {
      description,
      servingSize,
      servingsPerPkg,
      pricePerPersonCalc,
      price,
      marketPrice,
      tagsDietaryPreferenceList,
    } = menuItem
    const pricedPerPkg = pricePerPersonCalc === 'PerPackage'
    const itemPrice = Number(price || marketPrice)
    const pricePerServing =
      pricedPerPkg && servingsPerPkg > 0
        ? `${(itemPrice / servingsPerPkg).toFixed(2)} ($${itemPrice.toFixed(
            2,
          )} Per Pkg)`
        : itemPrice.toFixed(2)

    return (
      <FlexContainer flexDirection="column">
        <p className="font-bold">Price Per Serving:</p>
        <p className="ml-3">${pricePerServing}</p>
        <YSpacing height="10px" />
        <p className="font-extrabold">Description:</p>
        <p className="ml-3">{description}</p>
        <YSpacing height="10px" />
        <p className="font-bold">Servings Size:</p>
        <p className="ml-3">{servingSize || 'N/A'}</p>
        <YSpacing height="10px" />
        <p className="font-bold">Servings Per Pkg:</p>
        <p className="ml-3">{servingsPerPkg}</p>
        <YSpacing height="10px" />
        <p className="font-bold">Dietary Tags:</p>
        <p className="ml-3">{tagsDietaryPreferenceList}</p>
        {menuItem.packaging && (
          <Fragment>
            <YSpacing height="10px" />
            <p className="font-bold">Packaging:</p>
            <p className="ml-3">{menuItem.packaging.name}</p>
          </Fragment>
        )}
      </FlexContainer>
    )
  }

  renderChildItemInfoTooltip = (childItem) => {
    const { description, servingSize, tagsDietaryPreferenceList, packaging } =
      childItem.menuItem ? childItem.menuItem : childItem

    return (
      <FlexContainer flexDirection="column">
        <p className="font-extrabold">Description:</p>
        <p className="ml-3">{description}</p>
        <YSpacing height="10px" />
        <p className="font-bold">Servings Size:</p>
        <p className="ml-3">{servingSize || 'N/A'}</p>
        <YSpacing height="10px" />
        <p className="font-bold">Dietary Tags:</p>
        <p className="ml-3">{tagsDietaryPreferenceList}</p>
        {packaging && (
          <Fragment>
            <YSpacing height="10px" />
            <p className="font-bold">Packaging:</p>
            <p className="ml-3">{packaging.name}</p>
          </Fragment>
        )}
      </FlexContainer>
    )
  }

  render() {
    const {
      orderItem,
      orderType,
      onRemove,
      isSame,
      isFirst,
      isSync,
      pColorHashString,
      toggleOrderItemIdToSync,
    } = this.props
    const { chefPrice, childItems, quantity, invoiceable, quantityError } =
      this.state

    const { menuItemId, isBYOConcept } = orderItem

    const price = ['Tasting', 'Hungry HQ Tasting', 'Comped'].includes(orderType)
      ? 0
      : this.state.price
    const total = quantity * price
    const margin =
      price === 0.0 ? 0 : Math.trunc(((price - chefPrice) / price) * 100)

    return (
      <tbody>
        <tr>
          <td>
            <FlexContainer flexDirection="row">
              <CloseX onClick={() => onRemove(orderItem)}>✕</CloseX>
              <FlexContainer
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
                width="15px"
              >
                {this.renderReOrder(-1)}
                {this.renderReOrder(1)}
              </FlexContainer>
            </FlexContainer>
          </td>
          <td className="align-middle">
            <div className="regular text-base">{orderItem.name}</div>
          </td>
          <td className="align-middle">
            <FlexContainer margin="0 10px 0 0">
              <Tooltip iconType="info" isTooltipBottom={true}>
                {this.renderItemInfoTooltip(orderItem)}
              </Tooltip>
              <XSpacing width="5px" />
              <Tooltip iconType="notes" isTooltipBottom={true}>
                <p className="font-extrabold">Internal Notes</p>
                <p>
                  {orderItem.menuItem
                    ? orderItem.menuItem.internalNotes
                    : orderItem.internalNotes}
                </p>
              </Tooltip>
            </FlexContainer>
          </td>
          <td className="text-right align-middle">
            {quantityError && (
              <div
                className="menu-card-tooltip cursor-pointer"
                onClick={this.onCloseQuantityError}
              >
                <p>This quantity is below the minimum servings.</p>
              </div>
            )}
            <QuantityInput
              name={orderItem.name}
              margin="0 0 0 auto"
              width="80px"
              value={quantity}
              onChange={this.onInputQuantity}
              onBlur={this.onBlurQuantity}
            />
          </td>
          <td className="text-right align-middle">
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <CurrencyInput
                margin="0 0 0 auto"
                width="80px"
                value={chefPrice}
                onChange={this.onInputCurrency('chefPrice')}
              />
            </AuthorizedInteractable>
          </td>
          <td className="text-right align-middle">
            <AuthorizedInteractable
              roles={['master admin', 'sales lead', 'sales rep', 'chef lead']}
            >
              <CurrencyInput
                margin="0 0 0 auto"
                width="80px"
                value={price}
                onChange={this.onInputCurrency('price')}
              />
            </AuthorizedInteractable>
          </td>
          <td className="text-right align-middle regular text-base text-hungryGray">
            {margin}%
          </td>
          <td className="text-right align-middle">
            <p className="regular text-base text-hungryGray">
              ${total.toFixed(2)}
            </p>
          </td>
          <td className="align-middle text-center">
            <input
              type="checkbox"
              checked={invoiceable}
              onChange={(e) =>
                this.setState(
                  { invoiceable: e.target.checked },
                  this.updateItem,
                )
              }
            />
          </td>
        </tr>
        {childItems && isBYOConcept && !isFirst && (
          <tr>
            <td />
            <td colSpan={6}>
              <Checkbox
                label={
                  isSame
                    ? 'Same child items as the first BYO item added'
                    : 'This menu item has different child items, please adjust child items accordingly'
                }
                value="isEnabled"
                disabled={!isSame}
                marginBottom="5px"
                checked={isSync}
                onChange={(e) =>
                  toggleOrderItemIdToSync(menuItemId, e.target.checked)
                }
              />
            </td>
          </tr>
        )}
        {!isSync &&
          childItems &&
          childItems.map((item, i) => {
            const { price, quantity, chefPrice, name, category, invoiceable } =
              item
            const margin =
              price === 0.0
                ? 0
                : Math.trunc(((price - chefPrice) / price) * 100)

            return (
              <tr key={i} className="">
                <td />
                <td className="align-middle">
                  <FlexContainer alignItems="center">
                    <CloseX onClick={this.onRemoveChildItem(i)}>✕</CloseX>

                    <FlexContainer
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="flex-start"
                      width="15px"
                    >
                      {this.renderChildReOrder(
                        item,
                        -1,
                        i == 0,
                        i == childItems.length - 1,
                      )}
                      {this.renderChildReOrder(
                        item,
                        1,
                        i == 0,
                        i == childItems.length - 1,
                      )}
                    </FlexContainer>
                    <div>
                      <span
                        className="category"
                        style={{ color: pColorHashString(category || '') }}
                      >
                        {category}
                      </span>
                      {name}
                    </div>
                    <Tooltip
                      iconType="info"
                      isTooltipBottom={true}
                      margin={{ marginLeft: '20px' }}
                    >
                      {this.renderChildItemInfoTooltip(item)}
                    </Tooltip>
                  </FlexContainer>
                </td>
                <td />
                <td className="align-middle text-right">
                  <QuantityInput
                    margin="0 0 0 auto"
                    width="80px"
                    value={quantity}
                    onChange={this.onInputChildQuantity(i)}
                  />
                </td>
                <td className="align-middle text-right">
                  <AuthorizedInteractable roles={['master admin', 'chef lead']}>
                    <CurrencyInput
                      margin="0 0 0 auto"
                      width="80px"
                      value={chefPrice}
                      onChange={this.onInputChildCurrency('chefPrice', i)}
                    />
                  </AuthorizedInteractable>
                </td>
                <td className="align-middle text-right">
                  <AuthorizedInteractable
                    roles={[
                      'master admin',
                      'sales lead',
                      'sales rep',
                      'chef lead',
                    ]}
                  >
                    <CurrencyInput
                      margin="0 0 0 auto"
                      width="80px"
                      value={price}
                      onChange={this.onInputChildCurrency('price', i)}
                    />
                  </AuthorizedInteractable>
                </td>
                <td className="align-middle text-right regular text-base text-hungryGray">
                  {margin}%
                </td>
                <td className="text-right align-middle">
                  <p className="regular text-base text-hungryGray">
                    ${(price * quantity).toFixed(2)}
                  </p>
                </td>
                <td className="align-middle text-center">
                  <input
                    type="checkbox"
                    checked={invoiceable}
                    onChange={this.onUpdateInvoiceableChildItem(i)}
                  />
                </td>
              </tr>
            )
          })}
      </tbody>
    )
  }
}

OrderItem.propTypes = {
  isFirst: PropTypes.boolean,
  isLast: PropTypes.boolean,
  isSame: PropTypes.boolean,
  isSync: PropTypes.boolean,
  orderItem: PropTypes.object,
  orderType: PropTypes.string,
  user: PropTypes.object,

  displayAlert: PropTypes.func,
  isAuthorized: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onReorder: PropTypes.func,
  toggleOrderItemIdToSync: PropTypes.func,
  pColorHashString: PropTypes.func,
}

export default OrderItem
