export const timezones = [
  'America/New_York',
  'America/Denver',
  'America/Chicago',
  'America/Los_Angeles',
]

export const roles = ['Standard', 'Admin']

export const statuses = [
  'Proposed',
  'Rejected',
  'Created',
  'Delivered',
  'Deleted',
]

export const rStatuses = ['Active', 'Inactive']

export const dayMap = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}
