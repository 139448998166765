import { connect } from 'react-redux'

import SurveyList from '@components/survey/SurveyList'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'

const mapStateToProps = (state) => {
  const { headers, surveys } = state.surveys

  return {
    headers,
    surveys,
  }
}

const mapDispatchToProps = () => {
  const { RestService, SurveyService } = services
  const { pRequestSurveys, pResponseSurveyList } = presenters.Api
  const { AsyncLoadSurveyList, LoadSurveys } = coordinators

  const loadSurveyList = AsyncLoadSurveyList({
    RestService,
    pResponseSurveyList,
  })
  const loadSurveys = LoadSurveys({
    RestService,
    SurveyService,
    pRequestSurveys,
  })

  return {
    loadSurveyList,
    loadSurveys,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyList)
