import { connect } from 'react-redux'

import ResultsMessageModal from '@components/common/modal/ResultsMessageModal'

import services from '@services'

const mapStateToProps = (state) => {
  const { resultsMessageModal } = state
  const { show, successes, errors } = resultsMessageModal

  return {
    show,
    successes,
    errors,
  }
}

const mapDispatchToProps = () => {
  const { UIService } = services
  const close = UIService.ResultsMessageModal.dismissResultsMessageModal

  return { close }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsMessageModal)
