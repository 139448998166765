import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormInputContainer } from '@res/styledComponents/index'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { LinkText } from '@components/common/form'
import YSpacing from '@components/common/YSpacing'

RichTextView.propTypes = {
  width: PropTypes.string,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  instructions: PropTypes.string,

  saveInstructions: PropTypes.func,
  onChange: PropTypes.func,
}

export default function RichTextView({
  instructions,
  label,
  readOnly,
  width,

  saveInstructions,
  onChange,
}) {
  const [text, setText] = useState('')
  const [isChanged, setIsChanged] = useState(false)

  useEffect(() => {
    instructions && setText(instructions)
  }, [instructions])

  const richTextModules = {
    toolbar: [
      [{ header: 1 }, { header: 2 }],
      ['bold', 'underline'],
      ['link', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  }

  const onSaveInstructions = () => {
    saveInstructions(text)
    setIsChanged(false)
  }

  const onChangeInstructions = (text) => {
    setText(text)
    setIsChanged(true)
    onChange && onChange(text)
  }

  return (
    <FormInputContainer width={width}>
      <label>{label}</label>
      <ReactQuill
        name={label}
        style={{ backgroundColor: 'white' }}
        modules={richTextModules}
        theme="snow"
        value={text}
        onChange={onChangeInstructions}
        readOnly={readOnly}
      />
      <YSpacing height="10px" />
      {saveInstructions && isChanged && !readOnly && (
        <LinkText label="Save Instructions" onClick={onSaveInstructions} />
      )}
    </FormInputContainer>
  )
}
