import { BaseService } from 'hungry-core2'
import analytics from '@analytics'

import actions from '@actions'

export default class FlashMessage extends BaseService {
  #displayMessage = (type) => (options) => (message) => {
    this.dispatch(
      actions.displayFlashMessage({ alertType: type, message, options }),
    )
    analytics.track('flash_message_displayed', { type, message })
  }

  displaySuccessMessage = this.#displayMessage('success')(undefined)

  displayFailureMessage = this.#displayMessage('fail')(undefined)

  displayInfoMessage = this.#displayMessage('info')(undefined)

  displayWarningMessage = this.#displayMessage('warning')(undefined)

  displayInfoMessageOptions = (message, options) => {
    this.#displayMessage('info')(options)(message)
  }

  displayWarningMessageOptions = (message, options) => {
    this.#displayMessage('warning')(options)(message)
  }

  displaySuccessMssgOptions = (message, options) => {
    this.#displayMessage('success')(options)(message)
  }

  displayUnconfirmedEmailMessage = (message, buttonAction) => {
    const options = {
      buttonAction,
      buttonTitle: 'Resend Email',
    }
    this.#displayMessage('fail')(options)(message)
  }

  dismissMessage = (timestamp) => {
    this.dispatch(actions.dismissFlashMessage({ timestamp }))
  }

  dismissLastFlashMessage = () => {
    const { flashMessages } = this.getState().flashMessage
    const { timestamp } = flashMessages[flashMessages.length - 1]
    this.dispatch(actions.dismissFlashMessage({ timestamp }))
  }
}
