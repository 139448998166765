import { connect } from 'react-redux'

import Filters from '@components/reports/Filters'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { headquarter, markets, user } = state
  const { userHeadquarters: headquarters } = headquarter

  return {
    markets,
    headquarters,
    userId: user && user.id,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, HeadquarterService, RestService, SettingsService } =
    services
  const {
    AsyncLoadAccounts,
    AsyncLoadCateringCaptains,
    AsyncLoadOrderSettings,
    LoadChefs,
    LoadAccountExecutives,
    LoadSettings,
    RetryOnTimeout,
  } = coordinators
  const { pOrderSettings } = presenters
  const {
    pResponseAccountExecutives,
    pResponseCateringCaptains,
    pResponseChefs,
    pResponseGeneric,
    pRequestSettings,
    pResponseSettings,
  } = presenters.Api

  const loadHeadquarters = coordinators.LoadUserHeadquarters({
    HeadquarterService,
    RestService,
    pResponseHeadquarters: pResponseGeneric,
    HandleError,
  })
  const searchSalesReps = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })(true)
  const loadAccounts = AsyncLoadAccounts({ RestService, pResponseGeneric })
  const loadCateringCaptains = AsyncLoadCateringCaptains({
    RestService,
    pResponseCateringCaptains,
  })
  const loadChefs = LoadChefs({ ChefService, RestService, pResponseChefs })
  const loadOrderSettings = AsyncLoadOrderSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    pOrderSettings,
    LoadSettings,
    RetryOnTimeout,
    HandleError,
  })

  return {
    loadHeadquarters,
    loadAccounts,
    loadChefs,
    loadCateringCaptains,
    loadOrderSettings,
    searchSalesReps,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters)
