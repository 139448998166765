import { connect } from 'react-redux'

import { VendorClientMap } from '@components/chefNetwork'
import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { user } = state

  return {
    headquarterId: user.lastLoggedInHq,
  }
}

const mapDispatchToProps = () => {
  const { RestService } = services
  const { LoadHeadquarter, LoadChefsForCNMap, LoadClientsForCNMap } =
    coordinators
  const { pResponseGeneric, pResponseMarket } = presenters.Api

  const loadHeadquarter = LoadHeadquarter({
    RestService,
    pResponseMarket,
    HandleError,
  })

  const loadChefs = LoadChefsForCNMap({
    RestService,
    pResponseGeneric,
    HandleError,
  })

  const loadClients = LoadClientsForCNMap({
    RestService,
    pResponseGeneric,
    HandleError,
  })

  return {
    loadHeadquarter,
    loadChefs,
    loadClients,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorClientMap)
