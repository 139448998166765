import { connect } from 'react-redux'

import { EditHQModal } from '@components/market/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError, Validate } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { editHQ, editHQModal } = state
  const { show } = editHQModal
  const errors = state.errors.editHQModal || {}

  return {
    errors,
    editHQ,
    show,
  }
}

const mapDispatchToProps = () => {
  const { MarketService, RestService, UIService } = services
  const {
    DelayedUpdateHeadquarter,
    SaveHeadquarter,
    LoadAccountExecutives,
    GetAdminUser,
    LoadChefRecruiters,
  } = coordinators
  const { pStateToReduxMarket } = presenters
  const { pResponseMarket, pResponseAccountExecutives, pResponseAdminUser } =
    presenters.Api
  const { vHungryEmail } = presenters.Validation
  const loadAccountExecutives = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })
  const loadChefRecruiters = LoadChefRecruiters({
    RestService,
    pResponseAccountExecutives,
  })
  const { closeHQ } = UIService.EditMarket
  const { clear: clearErrors, clearSingle } = UIService.Errors
  const clearError = clearSingle('editMarketModal')
  const delayedUpdateHeadquarter = DelayedUpdateHeadquarter({
    MarketService,
    UIService,
    pStateToReduxMarket,
  })
  const saveHeadquarter = SaveHeadquarter({
    RestService,
    UIService,
    HandleError,
    MarketService,
    pResponseMarket,
  })
  const getAdminUser = GetAdminUser({
    RestService,
    pResponseAdminUser,
    HandleError,
  })
  const validateEmail = Validate({
    validator: vHungryEmail,
    namespace: 'editHQModal',
  })

  return {
    clearError,
    clearErrors,
    closeHQ,
    delayedUpdateHeadquarter,
    saveHeadquarter,
    loadAccountExecutives,
    loadChefRecruiters,
    getAdminUser,
    validateEmail,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditHQModal)
