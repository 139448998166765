import { connect } from 'react-redux'

import { ContactsSection } from '@components/order/edit/sections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapDispatchToProps = () => {
  const { AccountService, RestService } = services
  const { AsyncLoadAccountContacts } = coordinators
  const { pBuildContact, pContactKeys } = presenters
  const { pResponseGeneric } = presenters.Api

  const loadContacts = AsyncLoadAccountContacts({
    AccountService,
    RestService,
    pResponseGeneric,
  })

  return { loadContacts, pBuildContact, pContactKeys }
}

export default connect(undefined, mapDispatchToProps)(ContactsSection)
