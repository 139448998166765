import { camelCaseify, snakeCaseify } from '../../../utils'

export const GetNextCronTime =
  ({ RestService, HandleError }) =>
  async (req) => {
    try {
      const resp = await RestService.get(
        '/api/admin/orders/next_cron_time',
        snakeCaseify(req),
      )

      return camelCaseify(resp).nextTime
    } catch (error) {
      HandleError({ error, namespace: 'notifications' })

      return null
    }
  }

export const GetLatePaymentOrders =
  ({ RestService, HandleError, pResponseNotificationOrders }) =>
  async (req) => {
    try {
      const orders = await RestService.get(
        '/api/admin/orders/late_payments',
        snakeCaseify(req),
        { timeout: 50000 },
      )

      return pResponseNotificationOrders(orders)
    } catch (error) {
      HandleError({ error, namespace: 'notifications' })

      return []
    }
  }

export const SendLatePaymentOrders =
  ({ RestService, UIService, HandleError }) =>
  async (req) => {
    const text =
      'Are you sure you want to manually send these late payment emails?'
    const sendEmails = await UIService.ConfirmationModal.show({ text })
    if (!sendEmails) {
      return
    }

    try {
      await RestService.post(
        '/api/admin/orders/late_payments',
        snakeCaseify(req),
      )
    } catch (error) {
      HandleError({ error, namespace: 'notifications' })
    }
  }

export const GetAutoInvoiceOrders =
  ({ RestService, HandleError, pResponseNotificationOrders }) =>
  async (req) => {
    if (req.fromDate === undefined) {
      delete req.fromDate
    }

    try {
      const orders = await RestService.get(
        '/api/admin/orders/auto_invoices',
        snakeCaseify(req),
      )

      return pResponseNotificationOrders(orders)
    } catch (error) {
      HandleError({ error, namespace: 'notifications' })

      return []
    }
  }

export const SendAutoInvoiceOrders =
  ({ RestService, UIService, HandleError }) =>
  async (req) => {
    if (req.fromDate === undefined) {
      delete req.fromDate
    }

    const text =
      'Are you sure you want to manually send these auto invoice emails?'
    const sendEmails = await UIService.ConfirmationModal.show({ text })
    if (!sendEmails) {
      return
    }

    try {
      await RestService.post(
        '/api/admin/orders/auto_invoices',
        snakeCaseify(req),
      )
    } catch (error) {
      HandleError({ error, namespace: 'notifications' })
    }
  }
