import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { CurrencyInput, QuantityInput } from '@components/common/form'
import { AuthorizedInteractable } from '@containers/common/auth'
import trash from '@res/images/bin.svg'

class OrderServiceItem extends Component {
  state = {
    chefId: undefined,
    id: undefined,
    serviceItemId: undefined,
    displayOrder: undefined,

    name: '',
    pricingRate: undefined,
    description: '',
    rateQuantity: 0.0,
    quantity: 0,
    price: 0,
    cost: 0,
    total: 0,
    originalPrice: 0,
  }

  componentDidMount() {
    const {
      name,
      quantity,
      cost,
      description,
      price,
      total,
      chefId,
      id,
      serviceItemId,
      displayOrder,
      pricingRate,
      rateQuantity,
    } = this.props.serviceItem

    this.setState({
      name,
      quantity,
      cost,
      description,
      price,
      total,
      chefId,
      id,
      serviceItemId,
      displayOrder,
      pricingRate,
      rateQuantity,
      originalPrice: price,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.serviceItem !== this.props.serviceItem) {
      const {
        name,
        quantity,
        cost,
        price,
        total,
        chefId,
        id,
        serviceItemId,
        displayOrder,
        pricingRate,
        rateQuantity,
      } = nextProps.serviceItem

      this.setState({
        name,
        quantity,
        cost,
        price,
        total,
        chefId,
        id,
        serviceItemId,
        displayOrder,
        pricingRate,
        rateQuantity,
      })
    }
  }

  updateItem = () => {
    this.props.onEdit(this.state)
  }

  onInput = (attribute) => (e) => {
    const { value } = e.target
    this.setState({ [attribute]: value }, this.updateItem)
  }

  onInputQuantity = (attribute) => (quantity) => {
    this.setState({ [attribute]: quantity || 0 }, this.updateItem)
  }

  onInputCurrency = (attribute) => (value) => {
    const { isAuthorized, user } = this.props
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      const isMasterAdmin = isAuthorized(user, ['master admin'])
      const isChefLead = isAuthorized(user, ['chef lead'])
      const hasSalesRole = isAuthorized(user, ['sales lead', 'sales rep'])
      if (
        attribute === 'price' &&
        !(isMasterAdmin || isChefLead) &&
        hasSalesRole &&
        !(value >= this.state.originalPrice)
      ) {
        this.props.displayAlert({
          message: 'Sales can only increase the client price',
          title: 'Price Validation',
        })

        return
      }
      this.setState({ [attribute]: parseFloat(value) }, this.updateItem)
    }, 400)
  }

  renderName = () => {
    const { isCustom } = this.props
    const { name, pricingRate } = this.state

    if (isCustom) {
      return (
        <input
          className="border w-64 h-8 regular text-base text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
          placeholder="Enter Service Item Name"
          type="text"
          value={name}
          onInput={this.onInput('name')}
        />
      )
    } else {
      const nameWithRate = pricingRate ? name + ' per ' + pricingRate : name

      return <p className="regular text-base">{nameWithRate}</p>
    }
  }

  renderReOrder = (change) => {
    const { isFirst, isLast, serviceItem, onReorder } = this.props

    let symbol
    if (change > 0 && !isLast) {
      symbol = '▼'
    } else if (change < 0 && !isFirst) {
      symbol = '▲'
    }
    if (symbol) {
      return (
        <span
          className="pointer"
          onClick={() => onReorder(serviceItem, change)}
        >
          {' '}
          {symbol}{' '}
        </span>
      )
    }
  }

  render() {
    const { onRemove, serviceItem, isCustom } = this.props
    const { quantity, cost, price, pricingRate, description, rateQuantity } =
      this.state
    let { total } = this.state
    total = quantity * price * (rateQuantity || 1)
    const vendorPricingRoles = ['master admin', 'sales lead', 'chef lead']
    const clientPricingRoles = [
      'master admin',
      'sales lead',
      'chef lead',
      'sales rep',
    ]
    if (isCustom) {
      vendorPricingRoles.push('sales rep')
    }

    return (
      <Fragment>
        <tr className="">
          <td className="flex w-2 pr-3">
            <div className="flex flex-col mr-5 justify-center">
              {this.renderReOrder(-1)}
              {this.renderReOrder(1)}
            </div>
          </td>
          <td className="align-middle">{this.renderName()}</td>
          <td className="text-right align-middle">
            <QuantityInput
              className="text-right border w-20 h-8 regular text-base text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
              value={quantity}
              onChange={this.onInputQuantity('quantity')}
            />
          </td>
          <td className="text-right align-middle">
            {pricingRate && (
              <QuantityInput
                className="text-right border w-20 h-8 regular text-base text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                value={rateQuantity}
                onChange={this.onInputQuantity('rateQuantity')}
              />
            )}
          </td>
          <td className="text-right align-middle">
            <AuthorizedInteractable roles={vendorPricingRoles}>
              <CurrencyInput
                className="text-right border w-20 h-8 regular text-base text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                value={cost}
                onChange={this.onInputCurrency('cost')}
              />
            </AuthorizedInteractable>
          </td>
          <td className="text-right align-middle">
            <AuthorizedInteractable roles={clientPricingRoles}>
              <CurrencyInput
                className="text-right border w-20 h-8 regular text-base text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                value={price}
                onChange={this.onInputCurrency('price')}
              />
            </AuthorizedInteractable>
          </td>
          <td className="text-right align-middle">
            <CurrencyInput
              className="text-right border w-20 h-8 regular text-base text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
              value={total}
              onChange={this.onInputCurrency('total')}
            />
          </td>
          <td className="align-middle">
            <img
              className="w-5 ml-3 box-content"
              onClick={() => onRemove(serviceItem)}
              src={trash}
            />
          </td>
        </tr>
        <tr className="bg-indigo-100">
          <td />
          <td colSpan="7">
            <div className="flex">
              <div className="mr-6">
                <div className="flex flex-col mt-3 w-64">
                  <p className="mb-1 text-hungryGray bold uppercase text-sm">
                    {' '}
                    Service Item Description{' '}
                  </p>
                  <textarea
                    className="h-20 border w-100 regular text-lg text-hungryGray p-2 bg-white rounded border-gray-400"
                    value={description}
                    onInput={this.onInput('description')}
                  />
                </div>
                <button
                  className="add-button my-3"
                  onClick={this.onToggleExpand}
                >
                  {' '}
                  Collapse New Service Item Details{' '}
                </button>
              </div>
            </div>
          </td>
        </tr>
      </Fragment>
    )
  }
}

OrderServiceItem.propTypes = {
  isCustom: PropTypes.bool,
  serviceItem: PropTypes.object,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  user: PropTypes.bool,

  displayAlert: PropTypes.func,
  isAuthorized: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onReorder: PropTypes.func,
}

export default OrderServiceItem
