import { connect } from 'react-redux'
import GroupOrderSearchPage from '@components/groupOrderSearch/GroupOrderSearchPage'
import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { email } = state.user

  return {
    email,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService, GroupOrdersService } = services
  const { SearchGroupOrders, MarkGroupOrdersPaid, LoadAccount } = coordinators
  const { pResponseAccount } = presenters.Api

  const searchGroupOrders = SearchGroupOrders({
    RestService,
    HandleError,
  })

  const markGroupOrdersPaid = MarkGroupOrdersPaid({
    RestService,
    UIService,
    HandleError,
  })

  const loadAccount = LoadAccount({ RestService, pResponseAccount })

  const { setGroupOrderPage } = GroupOrdersService

  return {
    searchGroupOrders,
    markGroupOrdersPaid,
    loadAccount,
    setGroupOrderPage,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupOrderSearchPage)
