import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import styled from '@emotion/styled'
import { colors } from '../../../constants'

const getChefTotalSales = (chef) => {
  return chef.totalSales.toLocaleString(undefined, { maximumFractionDigits: 2 })
}

const ChefDashboard = (props) => {
  const [chefs, setChefs] = useState([])

  useEffect(() => {
    const { filter } = props

    const loadChefs = async (params) => {
      const chefs = await props.loadChefs(params)
      setChefs(chefs)
    }

    const params = {}

    if (filter === 'active') {
      params.status = filter
    } else if (filter === 'inactive') {
      params.status = 'inactive,trial,review'
    } else {
      params.marketType = filter
      params.status = 'active'
    }

    loadChefs(params)
  }, [props.filter])

  const onSelectChef = (chefId) => {
    props.selectChef(chefId)
  }

  return (
    <div>
      <DashboardTitle>
        {props.filter.toUpperCase() || 'ALL'} CHEFS
      </DashboardTitle>
      <YSpacing height="20px" />
      <DashboardCardContainer>
        {props.filter === 'inactive'
          ? 'Chef stats not shown for inactive chefs.'
          : chefs.map((chef) => (
              <DashboardCard
                key={chef.id}
                onClick={() => onSelectChef(chef.id)}
              >
                <FlexContainer justifyContent="space-between">
                  <div>
                    <p className="bold">{chef.name}</p>
                    <p>Total Sales: ${getChefTotalSales(chef)}</p>
                  </div>
                  <div className="image-container">
                    <img src={chef.image} />
                  </div>
                </FlexContainer>
              </DashboardCard>
            ))}
      </DashboardCardContainer>
    </div>
  )
}

const DashboardTitle = styled.h2`
  font-family: 'bold';
  font-size: 20px;
  color: ${colors.gray300};
`

const DashboardCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const DashboardCard = styled.div`
  transition: 0.2s ease-in-out all;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px;
  width: 100%;
  max-width: 250px;
  margin-right: 15px;
  margin-bottom: 15px;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 10%),
    0 4px 6px -2px rgb(0 0 0 / 5%);
  .image-container {
    width: 70px;
    min-width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 70px;
    background: ${colors.gray100};
  }
  img {
    width: 100%;
  }
  &:hover {
    box-shadow:
      0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
`

ChefDashboard.propTypes = {
  loadChefs: PropTypes.func,
  selectChef: PropTypes.func,
  searchMenuItems: PropTypes.func,
  filter: PropTypes.string,
}

export default ChefDashboard
