import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import loadGif from '@res/images/loading.gif'
import Modal from '@components/common/modal/Modal'
import Button from '@components/common/form/Button'
import Dropdown from '@components/common/form/Dropdown'
import FlexContainer from '@components/common/FlexContainer'
import LinkText from '@components/common/form/LinkText'
import XSpacing from '@components/common/XSpacing'
import YSpacing from '@components/common/YSpacing'

class PopUpNotificationsModal extends Component {
  state = {
    popUp: undefined,
    notifType: '',
    notifications: {
      'Pop-Up Cancelled': this.props.sendPopUpCancelled,
      'Pop-Up Delayed': this.props.sendPopUpDelayed,
      'Pop-Up New Service Location': this.props.sendPopUpNewLocation,
      'Pop-Up New Feature Chef': this.props.sendPopUpNewChef,
      'Pop-Up New Menu': this.props.sendPopUpNewMenu,
      'Pop-Up Almost Sold Out': this.props.sendPopUpAlmostSoldOut,
      'Pop-Up Sold Out': this.props.sendPopUpSoldOut,
    },
    isLoading: false,
    targetCustomerCount: 0,
  }

  componentWillMount() {
    const { popUp } = this.props

    const newState = {}
    newState.popUp = popUp
    this.setState(newState)
    this.onGetCustomers(popUp)
  }

  onChangeNotificationType = (notifType) => {
    this.setState({ notifType })
  }

  onSendNotification = async (type, testEmail) => {
    if (type === '') {
      return
    }
    this.setState({
      isLoading: true,
    })
    const notifFunc = this.state.notifications[type]
    const { hideModal } = this.props
    const { popUp } = this.state
    if (await notifFunc(popUp, testEmail)) {
      hideModal()
    }
    this.setState({
      isLoading: false,
    })
  }

  locationToString = (location) => {
    return `${location.line1} ${location.line2} ${location.city}, ${location.state}`
  }

  onGetCustomers = async (popUp) => {
    const { getTargetCustomersCount } = this.props
    this.setState({
      targetCustomerCount: await getTargetCustomersCount(popUp),
    })
  }

  render() {
    const { hideModal } = this.props
    const { popUp, targetCustomerCount, notifType } = this.state

    return (
      <Modal
        width="500px"
        title="Notifications"
        hideModal={hideModal}
        color="#001940"
      >
        <Dropdown
          label="Notification Type"
          width="100%"
          value={this.state.notifType}
          onChange={(e) => this.onChangeNotificationType(e.target.value)}
        >
          <option />
          {Object.keys(this.state.notifications).map((notif) => (
            <option key={notif} value={notif}>
              {notif}
            </option>
          ))}
        </Dropdown>

        {notifType !== '' && (
          <div>
            <p>Pop-Up Name: {popUp.name}</p>
            <p>Start Time: {Moment(popUp.start).format('LLL')}</p>
            <p>Address: {this.locationToString(popUp.location)}</p>
            <p>Chef: {popUp.menu.chef.name}</p>
            <p>Menu: {popUp.menu.name}</p>
          </div>
        )}
        <YSpacing height="30px" />
        {!this.state.isLoading && notifType !== '' && (
          <FlexContainer justifyContent="flex-end">
            <p style={{ marginBottom: '5px' }}>
              Customer Reach: {targetCustomerCount}
            </p>
          </FlexContainer>
        )}
        {this.state.isLoading ? (
          <div className="loading-gif">
            <img src={loadGif} alt="loading image" />
          </div>
        ) : (
          <FlexContainer alignItems="center" justifyContent="flex-end">
            <LinkText
              label="Test Email To Sales Rep"
              onClick={() =>
                this.onSendNotification(this.state.notifType, true)
              }
            />
            <XSpacing width="30px" />
            <Button
              label="Send Notification"
              onClick={() =>
                this.onSendNotification(this.state.notifType, false)
              }
            />
          </FlexContainer>
        )}
      </Modal>
    )
  }
}

PopUpNotificationsModal.propTypes = {
  popUp: PropTypes.object,
  theme: PropTypes.object,
  hideModal: PropTypes.func,

  sendPopUpCancelled: PropTypes.func,
  sendPopUpDelayed: PropTypes.func,
  sendPopUpNewMenu: PropTypes.func,
  sendPopUpNewLocation: PropTypes.func,
  sendPopUpAlmostSoldOut: PropTypes.func,
  sendPopUpSoldOut: PropTypes.func,
  sendPopUpNewChef: PropTypes.func,
  getTargetCustomersCount: PropTypes.func,
}

export default PopUpNotificationsModal
