export const GetUnpaidAccounts =
  ({
    AccountsReceivableService,
    RestService,
    UIService,
    pRequestARParams,
    pResponseAccountsReceivable,
    pGroupARByClient,
    pGroupARByRep,
    HandleError,
  }) =>
  async ({
    fromDate,
    toDate,
    sort,
    type,
    name,
    requestTime,
    selectedHeadquarters,
  }) => {
    try {
      UIService.AccountsReceivable.toggleLoading(true)
      AccountsReceivableService.clearAccountsReceivable()

      const params = pRequestARParams({
        type,
        name,
        fromDate,
        toDate,
        sort,
        selectedHeadquarters,
      })
      const response = await RestService.get(
        '/accounting/accounts-receivable',
        params,
        { timeout: 300000 },
      )
      let groupedAR = pResponseAccountsReceivable(response)
      const grpAR = []

      if (type === 'account') {
        groupedAR = pGroupARByClient(grpAR, groupedAR)
      } else {
        groupedAR = pGroupARByRep(grpAR, groupedAR)
      }

      // sorting
      if (sort === 'alpha') {
        groupedAR = groupedAR.sort((a, b) => (a.Id.name > b.Id.name ? 1 : -1))
      } else if (sort === 'total') {
        groupedAR = groupedAR.sort((a, b) =>
          a.unpaidBalance > b.unpaidBalance ? 1 : -1,
        )
      } else if (sort === 'oldest') {
        groupedAR = groupedAR.sort((a, b) =>
          a.largestDaysPastDue > b.largestDaysPastDue ? 1 : -1,
        )
      }

      AccountsReceivableService.setAccountsReceivable(groupedAR, requestTime)
      UIService.AccountsReceivable.toggleLoading(false)

      return true
    } catch (error) {
      HandleError({ error, namespace: 'accountsReceivable' })
      UIService.AccountsReceivable.toggleLoading(false)

      return false
    }
  }

export const UpdateARAfterMarkingPaid =
  ({ AccountsReceivableService }) =>
  (ar, recordId, orderId) => {
    ar.map((record) => {
      if (record.Id.id === recordId) {
        const { orders, unpaidBalance } = record
        const order = record.orders.find((o) => o.id === orderId)
        record.orders = orders.filter((o) => o.id !== orderId)
        record.unpaidBalance = unpaidBalance - order.unpaidBalance

        return record
      } else {
        return record
      }
    })
    AccountsReceivableService.setAccountsReceivable(ar)
  }

export const LoadOrder =
  ({ RouterService }) =>
  (orderId) => {
    RouterService.push(`/sales/${orderId}`)
  }
