import { connect } from 'react-redux'

import { EditChefPayoutsModal } from '@components/order/edit'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'

import { HandleError } from '@coordinators/composed'

const deepClone = (items) =>
  items.map((item) => (Array.isArray(item) ? deepClone(item) : { ...item }))

const mapStateToProps = (state) => {
  const { show } = state.editChefPayoutsModal
  const { order, orderStatus } = state
  const { chefPayouts } = order
  const { chefs, orderMenuItems, orderServiceItems } = order

  const defaultPayouts = chefs.map((c) => {
    const payout = [...orderMenuItems, ...orderServiceItems]
      .filter((o) => o.chefId == c.id)
      .map((o) => parseFloat(o.cost) * o.quantity)
      .reduce((a, b) => {
        return a + b
      }, 0)

    return {
      chefId: c.id,
      chefName: c.name,
      isDefault: true,
      payout,
    }
  })

  let payouts = Object.keys(chefPayouts).map((k) => {
    return {
      chefId: k,
      chefName: (chefs.find((c) => c.id == k) || {}).name,
      isDefault: false,
      payout: chefPayouts[k],
    }
  })
  if (Object.keys(chefPayouts).length !== defaultPayouts.length) {
    payouts = payouts.concat(
      deepClone(defaultPayouts).filter((d) => !chefPayouts[d.chefId]),
    )
  }

  return {
    chefPayouts: payouts.length === 0 ? deepClone(defaultPayouts) : payouts,
    defaultPayouts: defaultPayouts,
    orderId: order.id,
    orderStatus,
    show,
  }
}
const mapDispatchToProps = () => {
  const { RestService, OrderService, UIService } = services
  const { closeChefPayouts } = UIService.EditOrder

  const { AdjustChefPayouts, ChangeOrderStatus } = coordinators
  const { pRequestUpdateChefPayouts, pResponseOrder } = presenters.Api

  const adjustChefPayouts = AdjustChefPayouts({
    OrderService,
    RestService,
    UIService,
    pRequestUpdateChefPayouts,
    pResponseOrder,
    HandleError,
  })
  const changeOrderStatus = ChangeOrderStatus({
    OrderService,
    RestService,
    pResponseOrder,
    UIService,
    HandleError,
  })

  return {
    adjustChefPayouts,
    changeOrderStatus,
    closeChefPayouts,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditChefPayoutsModal)
