import { connect } from 'react-redux'

import AuthorizedInteractable from '@components/common/auth/AuthorizedInteractable'

import presenters from '@presenters'

function mapStateToProps(state, ownProps) {
  const authRoles = ownProps.roles || []
  const interactable =
    presenters.isAuthorized(state.user, authRoles) || authRoles.length === 0

  return { interactable }
}

export default connect(mapStateToProps)(AuthorizedInteractable)
