import React, { useState } from 'react'
import { ItemPickerMenuItem } from '@types'

interface MenuItemGroupProps {
  name: string
  chefId: string
  items: ItemPickerMenuItem[]
  addCustomText: string
  onCreateCustom: (customItem: any) => void
  onSelect: (item: ItemPickerMenuItem) => void
}

const MenuItemGroup: React.FC<MenuItemGroupProps> = ({
  name,
  items,
  addCustomText,
  onCreateCustom,
  onSelect,
}) => {
  const [expanded, setExpanded] = useState(true)

  const renderMenuItem = (item: ItemPickerMenuItem) => (
    <div
      className="hover:bg-blue-100 cursor-pointer flex justify-between py-2"
      onClick={() => onSelect(item)}
    >
      <div>
        <p className="text-base">{item.name}</p>
        {item.tagsDietaryPreference.map((tag, idx, arr) =>
          arr.length - 1 === idx ? (
            <span key={idx} className={`dietary-tag ${tag}`}>
              {tag}
            </span>
          ) : (
            <span key={idx}>
              <span className={`dietary-tag ${tag}`}>{tag}</span> •{' '}
            </span>
          ),
        )}
        {item.price && <div> ${item.price} </div>}
      </div>
    </div>
  )

  const customItem = {
    name: addCustomText,
    tagsDietaryPreferenceList: [],
  }

  return (
    <div className="mb-4">
      <div className="flex justify-between items-center">
        <p
          className="bold text-lg pb-1 relative items"
          onClick={() => setExpanded(!expanded)}
        >
          {name}
          <span className={expanded ? 'down-arrow' : 'side-arrow'} />
        </p>
        <p
          className="cursor-pointer rounded py-1 px-1 border border-green-500 border-solid bold text-base text-green-500"
          onClick={() => onCreateCustom(customItem)}
        >
          {customItem.name}
        </p>
      </div>
      {expanded && <ul>{items.map((item) => renderMenuItem(item))}</ul>}
    </div>
  )
}

export default MenuItemGroup
