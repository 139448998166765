import { connect } from 'react-redux'

import { AccountMaster } from '@components/account'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { accounts, router } = state

  return { accounts, ...router }
}

const mapDispatchToProps = () => {
  const { AccountService, RestService } = services
  const {
    pResponseAccounts,
    pResponseEditAccount,
    pResponseEditClientAccounting,
  } = presenters.Api
  const { LoadAccounts, SelectAccount } = coordinators

  const loadAccounts = LoadAccounts({
    AccountService,
    RestService,
    pResponseAccounts,
  })
  const selectAccount = SelectAccount({
    AccountService,
    RestService,
    pResponseEditAccount,
    pResponseEditClientAccounting,
  })

  return {
    loadAccounts,
    selectAccount,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountMaster)
