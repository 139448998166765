import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import Modal from '@components/common/modal/Modal'
import FlexContainer from '@components/common/FlexContainer'
import YSpacing from '@components/common/YSpacing'
import TooltipModal from '@components/common/modal/TooltipModal'
import { config } from 'hungry-core2'

class InvoiceClient extends Component {
  state = { recipients: '' }

  get link() {
    const port = location.port === 3000 ? 3001 : 3000
    const url =
      config.marketplace_url +
      (config.marketplace_url === 'http://localhost' ? `:${port}` : '')

    return `${url}/invoices/${this.props.proposal.id}`
  }

  onInvoiceClient = () => {
    this.props.emailProposal([], this.state.recipients)
  }

  render() {
    const { clientLastNotified, id, orderableErrors, validOrder } =
      this.props.proposal
    const { proposalPdfUrlPrefix, hideModal } = this.props
    const { recipients } = this.state

    return (
      <Modal
        title="Send Proposal To Client"
        hideModal={hideModal}
        color="#001940"
      >
        <div className="center-panel">
          Client last notified: {clientLastNotified}
          {!validOrder ? (
            <div>
              <YSpacing height="20px" />
              Having issues downloading PDF? Send it to yourself in an email.
              Include any recipients in the text box below separated by
              semicolons (;)
              <YSpacing height="10px" />
              <FlexContainer
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
              >
                <input
                  type="text"
                  style={{ border: '1px solid #8397ab', borderRadius: '3px' }}
                  name="recipients"
                  value={recipients}
                  onChange={(e) =>
                    this.setState({ recipients: e.target.value })
                  }
                  placeholder="jack@tryhungry.com;jill@tryhungry.com"
                />
                <TooltipModal
                  unicode="&#9432;"
                  width="400px"
                  information={
                    "If an error occurs during the process of creating the combined PDFs, error emails will exclusively be sent to the provided email addresses that have a 'tryhungry' domain."
                  }
                  marginTop="20px"
                />
              </FlexContainer>
              <YSpacing height="20px" />
              <Button
                className="button-neutral"
                onClick={this.onInvoiceClient}
                disabled={!this.props.emailProposalButtonEnabled}
              >
                Send Proposal to{' '}
                {recipients ? recipients.split(';').join(' & ') : 'client'}
              </Button>
            </div>
          ) : (
            <p>
              Please correct the below orders before sending invoice:
              <p style={{ 'text-align': 'left' }}>
                {orderableErrors.map((e, i) => (
                  <div key={i}>
                    {' '}
                    {i + 1}) {e}{' '}
                  </div>
                ))}
              </p>
            </p>
          )}
        </div>
        <Button
          className="button-neutral mt-2"
          disabled={!this.props.emailProposalButtonEnabled}
        >
          <a
            className="download-pdf"
            href={`${proposalPdfUrlPrefix}/download_pdf/${id}`}
          >
            Download PDF
          </a>
        </Button>
      </Modal>
    )
  }
}

InvoiceClient.propTypes = {
  actionRequired: PropTypes.bool,
  emailProposalButtonEnabled: PropTypes.bool,
  proposal: PropTypes.object,
  proposalPdfUrlPrefix: PropTypes.string,

  emailProposal: PropTypes.func,
  hideModal: PropTypes.func,
}

InvoiceClient.defaultProps = {
  actionRequired: true,
}

export default InvoiceClient
