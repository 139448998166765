import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { BsInfo } from 'react-icons/bs'

const SidebarSearchAndInfo = (props) => {
  const { label, placeholder, onChange } = props

  return (
    <div>
      <Search>
        <div>
          <label>{label}</label>
          <Info>
            <BsInfo />
            <PopOver className="tooltip">
              <p>Search by Client / Rep / Chef Name</p>
            </PopOver>
          </Info>
        </div>
        <input
          id="sidebar-search-input"
          name={label}
          type="text"
          placeholder={placeholder}
          onChange={onChange}
        />
      </Search>
    </div>
  )
}

const Info = styled.div`
  padding-left: 55px;
  color: white;
  z-index: 100;
  position: relative;
  display: inline-block;
  &:hover .tooltip {
    opacity: 1;
  }
`
const PopOver = styled.div`
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  white-space: nowrap;
  position: absolute;
  background: #fff;
  color: #fff;
  padding: 7px;
  border-radius: 3px;
  top: -34px;
  left: -15px;
  transform: translate(-50%);
  transition: all 0.2s ease-in-out;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 2.5px 0 2.5px;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    bottom: -5px;
    left: 87%;
  }
`

const Search = styled.div`
  padding: 0 20px;
  margin-bottom: 10px;
  label {
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
  }
  input {
    width: 100%;
    box-sizing: border-box;
    padding: 6px 10px;
    background: rgba(255, 255, 255, 0.15);
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    font-size: 15px;
    ::placeholder {
      color: rgba(255, 255, 255, 0.6);
      font-family: 'regular';
    }
  }
`

SidebarSearchAndInfo.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
}

export default SidebarSearchAndInfo
