import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextInput from './TextInput'

class EmailInput extends Component {
  state = { value: '' }

  componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value })
  }

  onInput = (e) => {
    this.setState({ value: e.target.value })
    this.props.onInput && this.props.onInput(e.target.value)
  }

  render() {
    const { error, label, testId } = this.props
    const { value } = this.state

    return (
      <div className="form-field-container">
        <div className="form-field-container__columns">
          <p>{label}</p>
          <TextInput
            type="email"
            placeholder="test@tryhungry.com"
            value={value}
            error={error}
            onInput={this.onInput}
            testId={testId}
          />
        </div>
      </div>
    )
  }
}

EmailInput.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  testId: PropTypes.string,
  value: PropTypes.string,

  onInput: PropTypes.func,
}

EmailInput.defaultProps = {
  label: 'Email',
}

export default EmailInput
