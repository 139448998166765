export const APPEND_SCHEDULE_TIME_SLOTS = 'APPEND_SCHEDULE_TIME_SLOTS'
export const CLEAR_CHEF = 'CLEAR_CHEF'
export const CLEAR_EDIT_CHEF = 'CLEAR_EDIT_CHEF'
export const CLEAR_EDIT_CHEF_CONTACT = 'CLEAR_EDIT_CHEF_CONTACT'
export const CLEAR_EDIT_CONCEPT = 'CLEAR_EDIT_CONCEPT'
export const CLEAR_EDIT_MENU_ITEM = 'CLEAR_EDIT_MENU_ITEM'
export const CLEAR_EDIT_SERVICE_ITEM = 'CLEAR_EDIT_SERVICE_ITEM'
export const CLEAR_EDIT_SNACK_PACK = 'CLEAR_EDIT_SNACK_PACK'
export const CLEAR_EDIT_RECIPE = 'CLEAR_EDIT_RECIPE'
export const CLEAR_EDIT_VIRTUAL_ITEM = 'CLEAR_EDIT_VIRTUAL_ITEM'
export const CLEAR_EDIT_VIRTUAL_KIT = 'CLEAR_EDIT_VIRTUAL_KIT'
export const REMOVE_SCHEDULE_DISABLED_WEEK = 'REMOVE_SCHEDULE_DISABLED_WEEK'
export const SET_CHEF = 'SET_CHEF'
export const SET_CHEFS = 'SET_CHEFS'
export const SET_CHEF_MENU_ITEMS = 'SET_CHEF_MENU_ITEMS'
export const SET_CHEF_SERVICE_ITEMS = 'SET_CHEF_SERVICE_ITEMS'
export const SET_CHEF_SNACK_PACKS = 'SET_CHEF_SNACK_PACKS'
export const SET_CHEF_VIRTUAL_ITEMS = 'SET_CHEF_VIRTUAL_ITEMS'
export const SET_CHEF_VIRTUAL_KITS = 'SET_CHEF_VIRTUAL_KITS'
export const SET_CONCEPTS = 'SET_CONCEPTS'
export const SET_EDIT_CHEF = 'SET_EDIT_CHEF'
export const SET_EDIT_CHEF_CONTACT = 'SET_EDIT_CHEF_CONTACT'
export const SET_EDIT_CHILD_MENU_ITEM = 'SET_EDIT_CHILD_MENU_ITEM'
export const SET_EDIT_CONCEPT = 'SET_EDIT_CONCEPT'
export const SET_EDIT_MENU_ITEM = 'SET_EDIT_MENU_ITEM'
export const SET_EDIT_RECIPE = 'SET_EDIT_RECIPE'
export const SET_EDIT_SERVICE_ITEM = 'SET_EDIT_SERVICE_ITEM'
export const SET_EDIT_SNACK_PACK = 'SET_EDIT_SNACK_PACK'
export const SET_EDIT_VIRTUAL_ITEM = 'SET_EDIT_VIRTUAL_ITEM'
export const SET_EDIT_VIRTUAL_KIT = 'SET_EDIT_VIRTUAL_KIT'
export const SET_SCHEDULE_TIME_SLOTS = 'SET_SCHEDULE_TIME_SLOTS'
export const SET_RECIPES = 'SET_RECIPES'
export const UPDATE_EDIT_CHEF = 'UPDATE_EDIT_CHEF'
export const UPDATE_EDIT_CHEF_CONTACT = 'UPDATE_EDIT_CHEF_CONTACT'
export const UPDATE_EDIT_CONCEPT = 'UPDATE_EDIT_CONCEPT'
export const UPDATE_EDIT_MENU_ITEM = 'UPDATE_EDIT_MENU_ITEM'
export const UPDATE_EDIT_RECIPE = 'UPDATE_EDIT_RECIPE'
export const UPDATE_EDIT_SERVICE_ITEM = 'UPDATE_EDIT_SERVICE_ITEM'
export const UPDATE_EDIT_SNACK_PACK = 'UPDATE_EDIT_SNACK_PACK'
export const UPDATE_EDIT_VIRTUAL_ITEM = 'UPDATE_EDIT_VIRTUAL_ITEM'
export const UPDATE_EDIT_VIRTUAL_KIT = 'UPDATE_EDIT_VIRTUAL_KIT'
export const UPDATE_SCHEDULE_TIME_SLOT = 'UPDATE_SCHEDULE_TIME_SLOT'

export const clearChef = () => ({ type: CLEAR_CHEF })
export const clearEditChef = () => ({ type: CLEAR_EDIT_CHEF })
export const clearEditChefContact = () => ({ type: CLEAR_EDIT_CHEF_CONTACT })
export const clearEditConcept = () => ({ type: CLEAR_EDIT_CONCEPT })
export const clearEditMenuItem = () => ({ type: CLEAR_EDIT_MENU_ITEM })
export const clearEditRecipe = () => ({ type: CLEAR_EDIT_RECIPE })
export const clearEditServiceItem = () => ({ type: CLEAR_EDIT_SERVICE_ITEM })
export const clearEditSnackPack = () => ({ type: CLEAR_EDIT_SNACK_PACK })
export const clearEditVirtualItem = () => ({ type: CLEAR_EDIT_VIRTUAL_ITEM })
export const clearEditVirtualKit = () => ({ type: CLEAR_EDIT_VIRTUAL_KIT })

export const setChef = (chef) => ({ type: SET_CHEF, chef })
export const setChefs = (chefs) => ({ type: SET_CHEFS, chefs })
export const setChefMenuItems = (menuItems) => ({
  type: SET_CHEF_MENU_ITEMS,
  menuItems,
})
export const setChefServiceItems = (serviceItems) => ({
  type: SET_CHEF_SERVICE_ITEMS,
  serviceItems,
})
export const setChefSnackPacks = (snackPacks) => ({
  type: SET_CHEF_SNACK_PACKS,
  snackPacks,
})
export const setChefVirtualItems = (virtualItems) => ({
  type: SET_CHEF_VIRTUAL_ITEMS,
  virtualItems,
})
export const setChefVirtualKits = (virtualKits) => ({
  type: SET_CHEF_VIRTUAL_KITS,
  virtualKits,
})
export const setConcepts = (concepts) => ({ type: SET_CONCEPTS, concepts })
export const setEditChef = (chef) => ({ type: SET_EDIT_CHEF, chef })
export const setEditChefContact = (contact) => ({
  type: SET_EDIT_CHEF_CONTACT,
  contact,
})
export const setEditConcept = (concept) => ({ type: SET_EDIT_CONCEPT, concept })
export const setEditChildMenuItem = (menuItem) => ({
  type: SET_EDIT_CHILD_MENU_ITEM,
  menuItem,
})
export const setEditMenuItem = (menuItem) => ({
  type: SET_EDIT_MENU_ITEM,
  menuItem,
})
export const setEditRecipe = (recipe) => ({ type: SET_EDIT_RECIPE, recipe })
export const setEditServiceItem = (menuItem) => ({
  type: SET_EDIT_SERVICE_ITEM,
  menuItem,
})
export const setEditSnackPack = (snackPack) => ({
  type: SET_EDIT_SNACK_PACK,
  snackPack,
})
export const setEditVirtualItem = (virtualItem) => ({
  type: SET_EDIT_VIRTUAL_ITEM,
  virtualItem,
})
export const setEditVirtualKit = (virtualKit) => ({
  type: SET_EDIT_VIRTUAL_KIT,
  virtualKit,
})
export const setRecipes = (recipes) => ({ type: SET_RECIPES, recipes })

export const appendScheduleTimeSlots = ({
  disabledWeeks,
  recurringSlotInfo,
  recurringSlots,
  timeSlots,
}) => ({
  type: APPEND_SCHEDULE_TIME_SLOTS,
  disabledWeeks,
  recurringSlotInfo,
  recurringSlots,
  timeSlots,
})
export const removeScheduleDisabledWeek = (week) => ({
  type: REMOVE_SCHEDULE_DISABLED_WEEK,
  week,
})
export const setScheduleTimeSlots = ({
  disabledWeeks,
  recurringSlotInfo,
  recurringSlots,
  timeSlots,
}) => ({
  type: SET_SCHEDULE_TIME_SLOTS,
  disabledWeeks,
  recurringSlotInfo,
  recurringSlots,
  timeSlots,
})
export const updateScheduleTimeSlot = (timeSlot) => ({
  type: UPDATE_SCHEDULE_TIME_SLOT,
  timeSlot,
})

export const updateEditChef = (chef) => ({ type: UPDATE_EDIT_CHEF, chef })
export const updateEditChefContact = (contact) => ({
  type: UPDATE_EDIT_CHEF_CONTACT,
  contact,
})
export const updateEditConcept = (concept) => ({
  type: UPDATE_EDIT_CONCEPT,
  concept,
})
export const updateEditMenuItem = (menuItem) => ({
  type: UPDATE_EDIT_MENU_ITEM,
  menuItem,
})
export const updateEditRecipe = (recipe) => ({
  type: UPDATE_EDIT_RECIPE,
  recipe,
})
export const updateEditServiceItem = (menuItem) => ({
  type: UPDATE_EDIT_SERVICE_ITEM,
  menuItem,
})
export const updateEditSnackPack = (snackPack) => ({
  type: UPDATE_EDIT_SNACK_PACK,
  snackPack,
})
export const updateEditVirtualItem = (virtualItem) => ({
  type: UPDATE_EDIT_VIRTUAL_ITEM,
  virtualItem,
})
export const updateEditVirtualKit = (virtualKit) => ({
  type: UPDATE_EDIT_VIRTUAL_KIT,
  virtualKit,
})
