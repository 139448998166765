import { connect } from 'react-redux'
import { DinerProfiles } from '@components/account/accountSections'
import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'
import { DinerProfile, Account } from '@types'

interface State {
  account: Account
  dinerProfiles: DinerProfile[]
}

const mapStateToProps = (state: State) => {
  const { account, dinerProfiles } = state

  return {
    account,
    dinerProfiles,
  }
}

const mapDispatchToProps = () => {
  const { RestService, AccountService, UIService } = services
  const { LoadAccountDinerProfiles, EditDinerProfile } = coordinators
  const { pResponseDinerProfiles } = presenters.Api

  const loadDinerProfiles = LoadAccountDinerProfiles({
    RestService,
    AccountService,
    HandleError,
    pResponseDinerProfiles,
  }) as (accountId: string) => Promise<DinerProfile[]>
  const editDinerProfile = EditDinerProfile({ AccountService, UIService })

  return {
    loadDinerProfiles,
    editDinerProfile,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DinerProfiles)
