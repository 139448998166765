import { connect } from 'react-redux'
import { AutoInvoiceTab } from '@components/notifications'
import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapDispatchToProps = () => {
  const { RestService, UIService } = services

  const { pResponseNotificationOrders, pResponseAccounts } = presenters.Api

  const {
    GetNextCronTime,
    GetAutoInvoiceOrders,
    SendAutoInvoiceOrders,
    SearchIndexAccounts,
  } = coordinators

  const getNextCronTime = GetNextCronTime({ RestService, HandleError })
  const getAutoInvoiceOrders = GetAutoInvoiceOrders({
    RestService,
    HandleError,
    pResponseNotificationOrders,
  })
  const sendAutoInvoiceOrders = SendAutoInvoiceOrders({
    RestService,
    UIService,
    HandleError,
  })

  const searchAccounts = SearchIndexAccounts({ RestService, pResponseAccounts })

  return {
    getNextCronTime,
    getAutoInvoiceOrders,
    sendAutoInvoiceOrders,
    searchAccounts,
  }
}

export default connect(undefined, mapDispatchToProps)(AutoInvoiceTab)
