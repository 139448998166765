import { connect } from 'react-redux'

import { EditDeliveryZoneModal } from '@components/market/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { deliveryZone, editDeliveryZoneModal } = state
  const { show } = editDeliveryZoneModal

  return {
    deliveryZone,
    show,
  }
}

const mapDispatchToProps = () => {
  const { MarketService, RestService, UIService } = services
  const { SaveDeliveryZone, SaveHeadquarter, LoadDeliveryZones } = coordinators
  const { pRequestUpdateDeliveryZone, pResponseMarket } = presenters.Api

  const { closeDeliveryZone } = UIService.EditMarket
  const saveDeliveryZone = SaveDeliveryZone({
    MarketService,
    RestService,
    UIService,
    pRequestUpdateDeliveryZone,
    pResponseMarket,
    HandleError,
  })
  const saveHeadquarter = SaveHeadquarter({
    RestService,
    UIService,
    HandleError,
    MarketService,
    pResponseMarket,
  })
  const loadDeliveryZones = LoadDeliveryZones({
    MarketService,
    RestService,
    pResponseMarket,
    HandleError,
  })

  return {
    closeDeliveryZone,
    saveDeliveryZone,
    saveHeadquarter,
    loadDeliveryZones,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditDeliveryZoneModal)
