import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class AccountsReceivableService extends BaseService {
  setAccountsReceivable = (accountsReceivable, requestTime) => {
    this.dispatch(
      actions.setAccountsReceivable(accountsReceivable, requestTime),
    )
  }

  clearAccountsReceivable = () => {
    this.dispatch(actions.clearAccountsReceivable())
  }
}
