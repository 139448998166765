import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Flatpickr from 'react-flatpickr'
import { FormInputContainer } from '@res/styledComponents/index'
import { convertTimeIntoMomentTz, convertTimeIntoBrowserTz } from '~/utils'

class DateInput extends Component {
  state = {
    date: undefined,
    isFocused: false,
  }

  componentDidMount() {
    const { date } = this.props
    this.setState({ date })

    window.addEventListener('keydown', this.onDeleteDate)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onDeleteDate)
  }

  componentWillReceiveProps(nextProps) {
    const { date } = nextProps

    this.setState({ date })
  }

  onBlur = () => {
    this.setState({ isFocused: false })
  }

  onChange = (date) => {
    const { onChange } = this.props

    if (Array.isArray(date)) {
      date = date.pop()
    }
    if (!date) {
      return
    }
    // pass the js date converted to a date str to moment to create a beginning of day moment with the moment default tz
    const dateInTz = convertTimeIntoMomentTz(date)

    if (dateInTz !== this.state.date) {
      this.setState({ date: dateInTz, isFocused: false })
      onChange && onChange(dateInTz)
    }
  }

  onDeleteDate = (e) => {
    const { onChange } = this.props

    if (e.key === 'Backspace' && this.state.isFocused) {
      this.setState({ date: undefined })
      onChange && onChange(undefined)
      this.ref && this.ref.flatpickr.close()
    }
  }

  onFocus = () => {
    this.setState({ isFocused: true })
  }

  render() {
    const {
      className,
      dateFormat,
      defaultDate,
      label,
      enableDates,
      disabled,
      hideClearDate,
      error,
      minDate,
      maxDate,
      isStatic,
      width,

      clearDate,
    } = this.props
    const { date, isFocused } = this.state

    const options = { dateFormat, static: isStatic }
    if (options.dateFormat === 'default') {
      delete options.dateFormat
    }
    if (minDate) {
      options.minDate = minDate.toDate()
    }
    if (maxDate) {
      options.maxDate = maxDate.toDate()
    }
    if (enableDates) {
      options.enable = enableDates
    }

    return (
      <FormInputContainer width={width}>
        <label>{label}</label>
        <div style={{ position: 'relative', width: '100%' }}>
          <Flatpickr
            name={label}
            ref={(ref) => (this.ref = ref)}
            style={{ cursor: disabled ? 'not-allowed' : 'default' }}
            className={`flatpickr flatpickr-input input time-field ${className}`}
            disabled={disabled}
            options={options}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onFocus={this.onFocus}
            defaultDate={defaultDate && defaultDate.toDate()}
            // flatpickr has no tz support only renders in js date or browser time
            // convert input value to date str as js date params so its converted back to browser tz for rendering
            value={date && convertTimeIntoBrowserTz(date)}
          />
          {!hideClearDate && date && date.toDate() && (
            <ClearDate onClick={clearDate}>X</ClearDate>
          )}
        </div>
        {error && !isFocused && <p className="date-error">{error}</p>}
      </FormInputContainer>
    )
  }
}

export const ClearDate = styled.button`
  position: absolute;
  right: 10px;
  top: 7px;
`

DateInput.propTypes = {
  className: PropTypes.string,
  date: PropTypes.object,
  defaultDate: PropTypes.object,
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  enableDates: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.string,
  hideClearDate: PropTypes.boolean,
  label: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  isStatic: PropTypes.bool,
  width: PropTypes.string,

  onChange: PropTypes.func,
  clearDate: PropTypes.func,
}

DateInput.defaultProps = {
  disabled: false,
  dateFormat: 'F j, Y',
  isStatic: true,
}

export default DateInput
