import React, { useState } from 'react'
import styled from '@emotion/styled'
import { FaFilter, FaCheckCircle } from 'react-icons/fa'
import { FaCircleXmark } from 'react-icons/fa6'
import { Moment } from 'moment'
import { config } from 'hungry-core2'
import presenters from '@presenters'
import { FlexContainer, Panel, XSpacing, YSpacing } from '@components/common'
import { LinkText } from '@components/common/form'
import { colors } from '../../../../constants'
import ScheduleFilters from '@containers/groupOrders/SchedulePlanner/ScheduleFilters'
import { scheduleFilters, initialDisabledFilters } from './constants'
import { GOScheduleMenu, SchedulePlannerMenuItem } from '@types'
import LoadingIndicator from '@components/common/LoadingIndicator'
import { FilterConfig } from '@types'
import { parseMilitaryTime } from '../../../../utils'
const { getDistanceFromLatLonInMiles, pRequestSearchGOMenusParams } =
  presenters.Api

const tableHeadings = [
  'Menu Card Name',
  'Menu Card Cutoff Time',
  'Menu Items',
  'Cuisine(s)',
  'Ready to Heat',
  'Avg Menu Item Price',
  'Avg Menu Margin',
  'Vendor',
  'Vendor Earliest Pickup Time',
  'Vendor Max Items / Day',
  'Vendor Menucard Cap',
  'Client Name',
  'Last Ordered by Client',
  'Distance (miles) from Client',
  'Avg Client Menu Item Rating',
]
interface FilterProps {
  isVisible?: boolean
  margin?: string
  onClick?: () => void
}

interface GOSchedulerPlannerProps {
  hq: number
  searchGOMenus: (val: any) => any
}

const GroupOrderSchedulePlanner = ({
  hq,
  searchGOMenus,
}: GOSchedulerPlannerProps) => {
  const [expanded, updateExpanded] = useState<Record<string, boolean>>({})
  const [filterConfigs, _] = useState<FilterConfig[]>(scheduleFilters)

  const [filters, setFilters] = useState<
    Record<string, string | Moment | boolean | number | string[] | null>
  >({})
  const [customValues, setCustomValues] = useState<
    Record<string, string | undefined>
  >({})
  const [menus, setMenus] = useState<GOScheduleMenu[]>([])
  const [loading, setIsLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(true)
  const [clientGeo, setClientGeo] = useState<{
    longitude: string
    latitude: string
  }>()

  const buildCSVUrl = () => {
    const paramsObject: Record<string, string> = pRequestSearchGOMenusParams(
      filters,
      customValues,
      clientGeo,
      hq,
      true,
    )
    const queryString = new URLSearchParams(paramsObject).toString()

    return `${config.apiHost}/group-orders/schedule-planner/menus-csv?${queryString}`
  }

  const handleOnSearch = async () => {
    setIsLoading(true)
    const concepts = await searchGOMenus({
      filters,
      customValues,
      clientGeo,
      hqId: hq,
    })
    if (concepts) {
      setMenus(concepts)
    }
    setIsLoading(false)
  }

  const handleOnChange = (
    filters: Record<
      string,
      string | Moment | boolean | number | string[] | null
    >,
    customValues: Record<string, string | undefined>,
  ) => {
    setFilters(filters)
    setCustomValues(customValues)
  }

  const renderMenuItemNames = (
    items: SchedulePlannerMenuItem[] = [],
    menuId: string,
  ) => {
    const names = items.map((it) => it.name).join(', ')

    const needExpand = names.length > 45

    const renderedNames = needExpand
      ? expanded[menuId]
        ? names
        : names.slice(0, 45).concat('...')
      : names

    return (
      <td className="menu-item-cell">
        <p>{renderedNames}</p>
        {needExpand && (
          <Expand
            expanded={expanded[menuId]}
            onClick={() =>
              updateExpanded({ ...expanded, [menuId]: !expanded[menuId] })
            }
          >
            {expanded[menuId] ? 'Close' : 'Expand'}
          </Expand>
        )}
      </td>
    )
  }

  const renderCutoffTime = (days: number, time: number) => {
    let out = ''
    switch (days) {
      case 1:
        out = 'One Day Before'
        break
      case 2:
        out = 'Two Days Before'
        break
      default:
        out = 'Same Day'
    }

    const res = parseMilitaryTime(time)
    const minutes = res[1]
    let hours = res[0]
    if (!hours && !minutes) {
      return <td>{out}</td>
    }
    const amOrPM = hours > 12 ? 'PM' : 'AM'
    hours = hours > 12 ? hours - 12 : hours
    const cutoffTime = `${hours}${!minutes ? ':00' : `:${minutes}`} ${amOrPM}`

    out += ` at ${cutoffTime}`

    return <td>{out}</td>
  }

  return (
    <FlexContainer flexDirection="column" width="100%">
      <SchedulePlannerBody>
        <h1>Group Order Schedule Planner</h1>
        <FlexContainer alignItems="center" flexDirection="row">
          <XSpacing width="50px" />
          <LinkText label={'Download CSV'} isLink={true} link={buildCSVUrl()} />
          <XSpacing width="50px" />
          <FilterButton
            onClick={() => setShowFilter(!showFilter)}
            margin="0 20px 0 0"
          >
            <FaFilter />
            {showFilter ? 'Hide Filters' : 'Show Filters'}
          </FilterButton>
        </FlexContainer>
        <YSpacing height="10px" />
        <FlexContainer flexDirection="row" justifyContent="flex-start">
          {showFilter && (
            <ScheduleFilters
              filterConfigs={filterConfigs}
              initialDisabledFilters={initialDisabledFilters}
              onSearch={handleOnSearch}
              onChange={handleOnChange}
              setClientGeo={setClientGeo}
            />
          )}
          <XSpacing width="20px" />
          <Panel width="100%">
            <PlannerTable>
              {loading ? (
                <LoadingIndicator />
              ) : (
                <tbody>
                  <tr className="table-heading">
                    {tableHeadings.map((t) => (
                      <th key={t}>{t}</th>
                    ))}
                  </tr>
                  {menus.map((menu) => (
                    <tr key={menu.id}>
                      <td>{menu.name}</td>
                      {renderCutoffTime(
                        menu.groupOrderCutoffDays,
                        menu.groupOrderCutoffTime,
                      )}
                      {renderMenuItemNames(menu.allMenuItems, menu.id)}
                      <td>{menu.cuisineTags.join(', ')}</td>
                      <td>
                        {menu.readyToHeat ? (
                          <FaCheckCircle
                            size={20}
                            color={'green'}
                            style={{ marginLeft: '30%' }}
                          />
                        ) : (
                          <FaCircleXmark
                            size={20}
                            color={'red'}
                            style={{ marginLeft: '30%' }}
                          />
                        )}
                      </td>
                      <td>${menu.avgPrice}</td>
                      <td>{menu.margin.toFixed(2)}%</td>
                      <td>{menu.chef.name}</td>
                      <td>{menu.pickupTime}</td>
                      <td>{menu.vendorMaxGOItems}</td>
                      <td>{menu.menuMaxItems}</td>
                      <td>{menu.clientName}</td>
                      <td>{menu.lastOrdered}</td>
                      <td>
                        {clientGeo && hq
                          ? getDistanceFromLatLonInMiles(
                              clientGeo.latitude,
                              clientGeo.longitude,
                              menu.geoCoordinates?.[hq]?.[1],
                              menu.geoCoordinates?.[hq]?.[0],
                            )
                          : 'No Address Selected'}
                      </td>
                      <td>{menu.avgMenuItemRatingByClient}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </PlannerTable>
          </Panel>
        </FlexContainer>
      </SchedulePlannerBody>
    </FlexContainer>
  )
}

const SchedulePlannerBody = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  min-width: 2800px;

  h1 {
    font-size: 25px;
    margin-bottom: 10px;
    font-family: 'bold';
    color: ${colors.gray400};
  }
`

const PlannerTable = styled.table`
  width: 100% .menu-item-cell {
    min-width: 200px;
    max-width: 300px;
  }
  td {
    border-bottom: 1px solid ${colors.gray200};
    padding: 5px 15px;
    font-family: 'regular';
    font-size: 12px;
    max-width: 200px;
    min-width: 100px;
  }
  th {
    padding: 10px 15px;
    font-family: 'bold';
    text-transform: uppercase;
    font-size: 12px;
    min-width: 100px;
    background: ${colors.gray100};
    cursor: pointer;
    position: sticky;
    top: 0;
    border-bottom: 1px solid ${colors.gray300};
  }
`

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  font-family: 'regular';
  color: ${colors.blue400};
  margin: ${(props: FilterProps) => props.margin};
  svg {
    margin-right: 5px;
  }
`
interface ExpandBtnProps {
  expanded: boolean | undefined
}

const Expand = styled.button`
  background-color: ${(props: ExpandBtnProps) =>
    props.expanded ? 'lightgrey' : colors.blue200};
  font-weight: bold;
  border-radius: 5px;
  padding: 3px 2px;
`

export default GroupOrderSchedulePlanner
