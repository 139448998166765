import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { AuthorizedInteractable } from '@containers/common/auth'
import { PromoCodesTable } from '@components/account/accountSections'

const AccountPromoCodeHeaders = [
  'Code',
  'Kind',
  'Active',
  'Valid From',
  'Valid To',
  'Public',
  'Limit',
  'Reason',
  '# Redeemed',
  'Value',
  '',
]
const PromoCodeHeaders = [
  'Code',
  'Kind',
  'Active',
  'Valid From',
  'Valid To',
  'Public',
  'Limit',
  'Reason',
  '',
  'Value',
  '',
]

const Colspan = '11'
const ValueKinds = ['fixed', 'percentage']

export class PromoCodes extends Component {
  state = {
    newPromoCode: null,
  }

  componentDidMount() {
    this.props.loadPromoCodes()
    this.props.loadPromoCodeAttributes()
  }

  clearEdits = () => {
    this.setState({
      newPromoCode: null,
    })
  }

  renderSectionHeader = (headers, title) => (
    <tbody>
      <tr>
        <td colSpan={Colspan} className="promocode-header-container">
          <div className="promocode-header">{title}</div>
        </td>
      </tr>
      <tr className="promocode-table-headers">
        {headers.map((header, i) => (
          <th key={i}>{header}</th>
        ))}
      </tr>
    </tbody>
  )

  renderEditAllPromoButtons = (promoCode, clearEdits) => {
    return (
      <AuthorizedInteractable roles={['master admin', 'sales lead']}>
        <button
          className="button-primary button-small"
          onClick={async () => {
            await this.onAddPromoCodeToAccount(promoCode)
            clearEdits()
          }}
        >
          Add to Account
        </button>
      </AuthorizedInteractable>
    )
  }

  onAddNewPromoCode = () => {
    this.setState({
      editedPromoCode: null,
      newPromoCode: { redeemLimitPerUser: 'None' },
    })
  }

  onAddPromoCodeToAccount = (promoCode) => {
    const { accountId, accountPromoCodes } = this.props
    accountPromoCodes.push({ promoCodeId: promoCode.id })
    this.props.saveAccount({ promoCodes: accountPromoCodes, id: accountId })
  }

  onDeletePromoCode = (promoCode) => {
    this.props.deletePromoCode(promoCode)
    this.clearEdits()
  }

  onRemovePromoCodeFromAccount = (promoCode) => {
    const { accountId, accountPromoCodes } = this.props
    accountPromoCodes.push({
      id: promoCode.accountPromoCodeId,
      _destroy: true,
    })
    this.props.saveAccount({ promoCodes: accountPromoCodes, id: accountId })
  }

  onSavePromoCode = async (promoCode) => {
    const { savePromoCode, accountId } = this.props
    const ok = await savePromoCode(promoCode, accountId)
    if (ok) {
      this.clearEdits()
    }

    return ok
  }

  updatePromoCode = (attrKey, value) => {
    const { newPromoCode } = this.state
    const updated = { ...newPromoCode, [attrKey]: value }
    if (!ValueKinds.includes(updated.kind)) {
      updated.value = null
    }
    this.setState({ newPromoCode: updated })
  }

  renderNewPromoCode() {
    const { newPromoCode } = this.state
    const {
      id,
      code,
      kind,
      isActive,
      validFrom,
      validTo,
      value,
      reason,
      redeemLimitPerUser,
      isPublic,
    } = newPromoCode
    const { promoCodeKinds = [], promoCodeReasons = [] } = this.props

    return (
      <tr key={id || -1}>
        <td>
          <input
            type="text"
            value={code}
            onInput={(e) => this.updatePromoCode('code', e.target.value)}
          />
        </td>
        <td>
          <select
            value={kind || ''}
            onChange={(e) => this.updatePromoCode('kind', e.target.value)}
          >
            <option key={-1} value={''}></option>
            {promoCodeKinds.map((k, i) => (
              <option key={i} value={k}>
                {k}
              </option>
            ))}
          </select>
        </td>
        <td> {isActive} </td>
        <td>
          <input
            type="text"
            placeholder="MM/DD/YYYY"
            value={validFrom || ''}
            onInput={(e) => this.updatePromoCode('validFrom', e.target.value)}
          />
        </td>
        <td>
          <div className="relative pb-50">
            <input
              type="text"
              placeholder="MM/DD/YYYY"
              value={validTo || ''}
              onInput={(e) => this.updatePromoCode('validTo', e.target.value)}
            />
            {/* moved buttons here to appear centered in table */}
            <div className="promocode-button-container">
              <button
                className="button-primary button-small"
                onClick={this.clearEdits}
              >
                Cancel
              </button>
              <AuthorizedInteractable roles={['master admin', 'sales lead']}>
                <button
                  className="button-primary button-small"
                  onClick={() => this.onSavePromoCode(newPromoCode)}
                >
                  Save
                </button>
              </AuthorizedInteractable>
            </div>
          </div>
        </td>
        <td>
          Public?
          <input
            type="checkbox"
            checked={isPublic}
            onChange={(e) => this.updatePromoCode('isPublic', e.target.checked)}
          />
        </td>
        <td>
          <input
            type="text"
            value={redeemLimitPerUser}
            onInput={(e) =>
              this.updatePromoCode('redeemLimitPerUser', e.target.value)
            }
          />
        </td>
        <td>
          <select
            value={reason || ''}
            onChange={(e) => this.updatePromoCode('reason', e.target.value)}
          >
            <option key={-1} value={''}></option>
            {promoCodeReasons.map((r, i) => (
              <option key={i} value={r}>
                {r}
              </option>
            ))}
          </select>
        </td>
        <td> {/* placeholder for total redemption */} </td>
        <td>
          {ValueKinds.includes(kind) ? (
            <div>
              {kind === 'percentage' ? '%' : '$'}
              <input
                type="text"
                value={value}
                onInput={(e) => this.updatePromoCode('value', e.target.value)}
              />
            </div>
          ) : (
            'Dynamic'
          )}
        </td>
      </tr>
    )
  }

  render() {
    const { accountPromoCodes, promoCodes, promoCodeKinds, promoCodeReasons } =
      this.props
    const { newPromoCode } = this.state

    return (
      <div>
        <table className="table table-hover page promocode-table">
          {/* New promo codes */}
          {this.renderSectionHeader(PromoCodeHeaders, 'New Promo Codes')}
          <tbody>
            {newPromoCode ? (
              this.renderNewPromoCode()
            ) : (
              <tr>
                <td colSpan={Colspan}>
                  <div className="center">
                    <AuthorizedInteractable
                      roles={['master admin', 'sales lead']}
                      customStyle={{ display: 'inline' }}
                    >
                      <button
                        className="button-primary"
                        onClick={this.onAddNewPromoCode}
                      >
                        New Promo Code
                      </button>
                    </AuthorizedInteractable>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <PromoCodesTable
          title="Account Promo Codes"
          headers={AccountPromoCodeHeaders}
          promoCodes={accountPromoCodes}
          promoCodeKinds={promoCodeKinds}
          promoCodeReasons={promoCodeReasons}
          onRemovePromoCode={this.onRemovePromoCodeFromAccount}
          onSavePromoCode={this.onSavePromoCode}
        />

        <PromoCodesTable
          title="All Promo Codes"
          headers={PromoCodeHeaders}
          promoCodes={promoCodes}
          promoCodeKinds={promoCodeKinds}
          promoCodeReasons={promoCodeReasons}
          onRemovePromoCode={this.onDeletePromoCode}
          onSavePromoCode={this.onSavePromoCode}
          renderEditButtons={this.renderEditAllPromoButtons}
        />
      </div>
    )
  }
}

PromoCodes.propTypes = {
  accountId: PropTypes.string,
  accountPromoCodes: PropTypes.array,
  promoCodes: PropTypes.array,
  promoCodeKinds: PropTypes.array,
  promoCodeReasons: PropTypes.array,

  deletePromoCode: PropTypes.func,
  loadPromoCodes: PropTypes.func,
  loadPromoCodeAttributes: PropTypes.func,
  saveAccount: PropTypes.func,
  savePromoCode: PropTypes.func,
}

PromoCodes.defaultTypes = {
  accountPromoCodes: [],
  promoCodes: [],
  promoCodeKinds: [],
}

export default PromoCodes
