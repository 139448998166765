import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router'
import 'core-js/fn/array/includes'

import Home from '@containers/Home'
import SignIn from '@containers/SignIn'
import ForgotPass from '@containers/ForgotPass'
import ResetPass from '@containers/ResetPass'
import FlashMessages from '@containers/common/FlashMessages'
import Timezone from '@containers/common/Timezone'

import { AlertModal, ConfirmationModal } from '@containers/common/modal'
import Modals from '@containers/Modals'
import { InstallTrackJS } from '@coordinators/trackJS'

const App = () => {
  useEffect(() => {
    InstallTrackJS()
  }, [])

  return (
    <div>
      <FlashMessages />
      <Timezone />
      <Switch>
        <Route path="/signin" component={SignIn} />
        <Route path="/forgotpassword" component={ForgotPass} />
        <Route path="/resetpassword" component={ResetPass} />
        <Route path="/" component={Home} />
      </Switch>

      <Modals />
      {/*<EditMenuConceptModal /> */}
      <AlertModal />
      <ConfirmationModal />
      {/* <ErrorModal /> */}
    </div>
  )
}

export default App
