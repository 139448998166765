import { connect } from 'react-redux'

import { SnackPacks } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { chef } = state

  const snackPacks = chef && chef.snackPacks
  const chefId = chef && chef.id

  return {
    chefId,
    snackPacks,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, UIService } = services
  const { pDefaultSnackPackFields } = presenters
  const { pResponseSnackPack, pResponseGeneric } = presenters.Api
  const {
    DelayedUpdateSnackPack,
    EditSnackPack,
    LoadChefSnackPacks,
    NewSnackPack,
  } = coordinators

  const editSnackPack = EditSnackPack({
    ChefService,
    RestService,
    UIService,
    pResponseSnackPack,
  })
  const loadChefSnackPacks = LoadChefSnackPacks({
    ChefService,
    RestService,
    pResponseSnackPacks: pResponseGeneric,
  })
  const newSnackPack = NewSnackPack({
    ChefService,
    UIService,
    pDefaultSnackPackFields,
  })
  const delayedUpdateSnackPack = DelayedUpdateSnackPack({
    ChefService,
    UIService,
  })

  return {
    delayedUpdateSnackPack,
    editSnackPack,
    loadChefSnackPacks,
    newSnackPack,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SnackPacks)
