import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DividerLine from '@components/common/DividerLine'
import XSpacing from '@components/common/XSpacing'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { BiTrash } from 'react-icons/bi'
import { Button, Dropdown, Input, LinkText } from '@components/common/form'
import { AutocompleteInput } from '@containers/common/form'
import Table from '@components/common/Table'
import { Label } from '@res/styledComponents/index'
const TimeZones = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
]

const HeadquarterSection = (props) => {
  const [newName, setNewName] = useState('')
  const [newRate, setNewRate] = useState(0)

  const onSelectLocale = (e) => {
    const locale = e.target.value
    props.onSelectLocale(locale)
  }

  const onNameChange = (e) => {
    setNewName(e.target.value)
  }

  const onNumberChange = (e) => {
    setNewRate(e.target.value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const { addCaptainRate, id } = props
    addCaptainRate(newName, newRate, id)
  }

  const onRateChange = (index, rate) => {
    const { modifyCaptainRate } = props
    modifyCaptainRate(index, rate)
  }

  const onRateDelete = (index) => {
    const { removeCaptainRate } = props
    removeCaptainRate(index)
  }

  const {
    address,
    captRates = [],
    errors,
    locale,
    name,
    orderPrefix,
    deliveryFee,
    cartMinimum,
    groupOrderMinimumItems,
    renderAddressInput,
    renderTextInput,
    renderNumberInput,
    save,
    onHide,
    marketplaceSalesRep,
    onChangeMarketplaceSalesRep,
    chefRecruiter,
    onChangeChefRecruiter,
    loadAccountExecutives,
    loadChefRecruiters,
    chefNetworkEmailDistribution,
    salesAREmailDistribution,
    opsEmailDistribution,
  } = props

  return (
    <div>
      {renderTextInput({
        label: 'HQ Name',
        value: name,
        error: errors.name,
        onInputParam: 'name',
        testId: 'name',
      })}
      {renderTextInput({
        label: 'Order Prefix',
        value: orderPrefix,
        error: errors.name,
        onInputParam: 'orderPrefix',
        testId: 'orderPrefix',
      })}
      <Dropdown
        label="Time Zone"
        width="100%"
        marginBottom="0"
        value={locale}
        onChange={onSelectLocale}
      >
        <option>Select Timezone</option>
        {TimeZones.map((s) => (
          <option key={s}>{s}</option>
        ))}
      </Dropdown>
      <YSpacing height="20px" />
      <FlexContainer justifyContent="space-between" alignItems="flex-end">
        <AutocompleteInput
          label="Marketplace Sales Rep"
          loaderFunction={loadAccountExecutives()}
          value={
            marketplaceSalesRep.firstName
              ? `${marketplaceSalesRep.firstName} ${marketplaceSalesRep.lastName}`
              : ''
          }
          onSelect={onChangeMarketplaceSalesRep}
        />
      </FlexContainer>
      <YSpacing height="20px" />
      <FlexContainer justifyContent="space-between" alignItems="flex-end">
        <AutocompleteInput
          label="Chef Network Lead"
          loaderFunction={loadChefRecruiters}
          value={
            chefRecruiter.firstName
              ? `${chefRecruiter.firstName} ${chefRecruiter.lastName}`
              : ''
          }
          onSelect={onChangeChefRecruiter}
        />
      </FlexContainer>
      <YSpacing height="20px" />
      {renderTextInput({
        label: 'Chef Network Distribution',
        value: chefNetworkEmailDistribution,
        error: errors.email,
        onInputParam: 'chefNetworkEmailDistribution',
        testId: 'chefNetworkEmailDistribution',
      })}
      <YSpacing height="20px" />
      {renderTextInput({
        label: 'Sales AR Email Distribution',
        value: salesAREmailDistribution,
        error: errors.email,
        onInputParam: 'salesAREmailDistribution',
        testId: 'salesAREmailDistribution',
      })}
      <YSpacing height="20px" />
      {renderTextInput({
        label: 'Ops Email Distribution',
        value: opsEmailDistribution,
        error: errors.email,
        onInputParam: 'opsEmailDistribution',
        testId: 'opsEmailDistribution',
      })}
      <DividerLine margin="20px 0" />
      <Label> Captain Rates </Label>
      <YSpacing height="10px" />
      <Table headings={['Rate Name', 'Rate ($)', '']}>
        {captRates.map((capt, idx) => (
          <tr key={idx}>
            <td>{capt.name}</td>
            <td>
              <Input
                type="number"
                pattern="[0-9\.-]*"
                onChange={(e) => onRateChange(idx, e.target.value)}
                value={capt.rate}
                width="50px"
                marginBottom="0"
              />
            </td>
            <td>
              {' '}
              <BiTrash onClick={() => onRateDelete(idx)} />
            </td>
          </tr>
        ))}
      </Table>
      <YSpacing height="20px" />
      <FlexContainer justifyContent="space-between" alignItems="flex-end">
        <Input
          label="Rate Name"
          value={newName}
          onChange={onNameChange}
          width="170px"
          marginBottom="0"
        />
        <Input
          type="number"
          label="Rate ($)"
          width="60px"
          value={newRate}
          onChange={onNumberChange}
          marginBottom="0"
        />
        <Button label="Add" onClick={onSubmit} />
      </FlexContainer>
      <DividerLine margin="20px 0" />
      {renderNumberInput({
        label: 'Delivery Fee',
        value: deliveryFee,
        error: errors.name,
        onInputParam: 'deliveryFee',
      })}
      {renderNumberInput({
        label: 'Cart Minimum',
        value: cartMinimum,
        error: errors.name,
        onInputParam: 'cartMinimum',
      })}
      {renderNumberInput({
        label: 'Daily Group Order Items Minimum',
        value: groupOrderMinimumItems,
        error: errors.name,
        onInputParam: 'groupOrderMinimumItems',
      })}
      {renderAddressInput({ address, field: 'address' })}
      <YSpacing height="20px" />
      <FlexContainer justifyContent="flex-end" alignItems="center">
        <LinkText label="Cancel" onClick={onHide} color="#b30303" />
        <XSpacing width="40px" />
        <Button label="Save Headquarter Details" onClick={save} />
      </FlexContainer>
    </div>
  )
}

HeadquarterSection.propTypes = {
  onSelectLocale: PropTypes.func,
  renderAddressInput: PropTypes.func,
  renderTextInput: PropTypes.func,
  renderNumberInput: PropTypes.func,
  onHide: PropTypes.func,
  save: PropTypes.func,

  address: PropTypes.object,
  errors: PropTypes.object,
  market: PropTypes.object,

  name: PropTypes.string,
  locale: PropTypes.string,
  orderPrefix: PropTypes.string,
  chefNetworkEmailDistribution: PropTypes.string,
  salesAREmailDistribution: PropTypes.string,
  opsEmailDistribution: PropTypes.string,
  deliveryFee: PropTypes.number,
  cartMinimum: PropTypes.number,
  groupOrderMinimumItems: PropTypes.number,
  captRates: PropTypes.arrayOf(PropTypes.object),
  addCaptainRate: PropTypes.func,
  modifyCaptainRate: PropTypes.func,
  removeCaptainRate: PropTypes.func,
  id: PropTypes.number,

  marketplaceSalesRep: PropTypes.object,
  chefRecruiter: PropTypes.object,
  onChangeMarketplaceSalesRep: PropTypes.func,
  onChangeChefRecruiter: PropTypes.func,
  loadAccountExecutives: PropTypes.func,
  loadChefRecruiters: PropTypes.func,
}

export default HeadquarterSection
