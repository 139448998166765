import { connect } from 'react-redux'

import { UserMaster } from '@components/user'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { adminUsers } = state

  return { users: adminUsers }
}

const mapDispatchToProps = () => {
  const { AdminUserService, HeadquarterService, RestService } = services
  const { pResponseGeneric, pResponseAdminUsers, pResponseAdminUser } =
    presenters.Api
  const { LoadUsers, SelectUser } = coordinators

  const loadUsers = LoadUsers({
    AdminUserService,
    RestService,
    pResponseAdminUsers,
  })
  const loadHeadquarters = coordinators.LoadHeadquarters({
    HeadquarterService,
    RestService,
    pResponseHeadquarters: pResponseGeneric,
    HandleError,
  })
  const selectUser = SelectUser({
    AdminUserService,
    RestService,
    pResponseAdminUser,
    HandleError,
  })

  return {
    loadHeadquarters,
    loadUsers,
    selectUser,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMaster)
