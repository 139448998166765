export const LoadSettings =
  ({
    RestService,
    SettingsService,
    pResponseSettings,
    pRequestSettings,
    RetryOnTimeout,
    HandleError,
  }) =>
  (settingsKey) =>
  async () => {
    let settingsValue = SettingsService.getSettings(settingsKey)
    if (settingsValue) {
      return settingsValue
    }

    const requestKey = pRequestSettings(settingsKey)
    const settingsRequest = (settingsKey) => () =>
      RestService.get(`/api/admin/${settingsKey}/settings`)
    try {
      settingsValue = await RetryOnTimeout(settingsRequest(requestKey))
    } catch (error) {
      HandleError({ error })
    }

    if (settingsValue) {
      settingsValue = pResponseSettings(settingsValue)
      SettingsService.setSettings({ settingsKey, settingsValue })

      return settingsValue
    }

    return null
  }
