import {
  DISPLAY_RESULTS_MESSAGE,
  DISMISS_RESULTS_MESSAGE,
} from '@actions/ui/resultsMessageModal'

export const resultsMessageModal = (state = false, action) => {
  switch (action.type) {
    case DISPLAY_RESULTS_MESSAGE: {
      const { show, successes, errors } = action

      return { show, successes, errors }
    }
    case DISMISS_RESULTS_MESSAGE: {
      const { show } = action

      return show
    }
    default:
      return state
  }
}
