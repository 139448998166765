import { connect } from 'react-redux'

import { ChefMaster } from '@components/chef'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { AppState } from '@models'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state: AppState) => {
  const { chefs } = state

  return { chefs }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService } = services
  const { pResponseChefs, pResponseChef } = presenters.Api
  const { LoadChefs, LoadCuisineTags, SelectChef } = coordinators

  const loadChefs = LoadChefs({ ChefService, RestService, pResponseChefs })
  const selectChef = SelectChef({ ChefService, RestService, pResponseChef })

  const loadCuisineTags = LoadCuisineTags({ RestService, HandleError })

  return {
    loadChefs,
    loadCuisineTags,
    selectChef,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChefMaster)
