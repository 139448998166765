import { connect } from 'react-redux'

import { MenuItemUpdates } from '@components/chefNetwork'
import coordinators from '@coordinators'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = () => undefined

//Admin changes
const mapDispatchToProps = () => {
  const { RestService } = services
  const {
    LoadMenuItemUpdates,
    LoadNewMenuItems,
    ChefNetworkCompletedItemUpdate,
    ChefNetworkCreatedNewItem,
    ChefNetworkDeletedCreateRequest,
    ChefNetworkDeletedUpdateRequest,
    ChefNetworkSaveMenuItemImage,
    ChefNetworkUpdateItemRequest,
  } = coordinators
  const loadMenuItemUpdates = LoadMenuItemUpdates({
    RestService,
    HandleError,
  })

  const loadNewMenuItems = LoadNewMenuItems({
    RestService,
    HandleError,
  })

  const chefNetworkCompletedItemUpdate = ChefNetworkCompletedItemUpdate({
    RestService,
    HandleError,
  })

  const chefNetworkCreatedNewItem = ChefNetworkCreatedNewItem({
    RestService,
    HandleError,
  })

  const chefNetworkDeletedCreateRequest = ChefNetworkDeletedCreateRequest({
    RestService,
    HandleError,
  })

  const chefNetworkDeletedUpdateRequest = ChefNetworkDeletedUpdateRequest({
    RestService,
    HandleError,
  })

  const chefNetworkSaveMenuItemImage = ChefNetworkSaveMenuItemImage({
    RestService,
    HandleError,
  })

  const chefNetworkUpdateItemRequest = ChefNetworkUpdateItemRequest({
    RestService,
    HandleError,
  })

  return {
    chefNetworkCreatedNewItem,
    chefNetworkCompletedItemUpdate,
    chefNetworkDeletedCreateRequest,
    chefNetworkDeletedUpdateRequest,
    chefNetworkSaveMenuItemImage,
    chefNetworkUpdateItemRequest,
    loadNewMenuItems,
    loadMenuItemUpdates,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemUpdates)
