import { PresentValidator, RegexValidator } from './validators'

/*
  Schema constructs validation schema from object representation:
  {
    [fieldName]: [
      {
        type: 'present',
        ( message: 'please enter an email'),  // optional
      },
      {
        type: 'regex',
        pattern: /asdf/,
        ( message )
      }
    ]
  }
*/

export default class Schema {
  constructor(schema) {
    this.schema = schema
    this.validators = []

    for (const fieldName in schema) {
      schema[fieldName].forEach((s) => {
        const { map, message, pattern, type } = s

        switch (type) {
          case 'present': {
            const validator = new PresentValidator(fieldName, { map, message })
            this.validators.push(validator)
            break
          }
          case 'regex': {
            const validator = new RegexValidator(fieldName, pattern, {
              map,
              message,
            })
            this.validators.push(validator)
            break
          }
        }
      })
    }
  }

  validate = (data) => {
    const errors = {}
    let isValid = true

    this.validators.forEach((v) => {
      const message = v.validate(data)
      if (message) {
        if (v.map) {
          v.map.forEach((k) => (errors[k] = message))
        } else {
          errors[v.key] = message
        }
        isValid = false
      }
    })

    return { errors, isValid }
  }
}
