import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab } from 'react-bootstrap'

import { Overview } from '@containers/proposal/proposalSections'
import { Transactions } from '@containers/order/orderSections'
import { AuditLog } from '../order/orderSections'

export class ProposalDetail extends Component {
  state = {
    activeKey: '#overview',
  }

  render() {
    const { proposal } = this.props
    if (!proposal) {
      return null
    }
    const { activeKey } = this.state

    return (
      <Tabs
        className="tabs"
        id="order_tab"
        activeKey={activeKey}
        onSelect={(activeKey) => {
          this.setState({ activeKey })
        }}
        mountOnEnter={true}
        unmountOnExit={true}
        animation={false}
      >
        <Tab eventKey={'#overview'} title="Overview">
          <Overview proposal={proposal} />
        </Tab>

        <Tab eventKey={'#transactions'} title="Transactions">
          <Transactions transactions={proposal.transactions} order={proposal} />
        </Tab>

        <Tab eventKey={'#auditing'} title="History">
          <AuditLog audits={proposal.audits} />
        </Tab>
      </Tabs>
    )
  }
}

ProposalDetail.propTypes = {
  proposal: PropTypes.object,
}

export default ProposalDetail
