import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import FlexContainer from '@components/common/FlexContainer'
import { AutocompleteInput } from '@containers/common/form'
import LoadingIndicator from '@components/common/LoadingIndicator'
import { AuthorizedInteractable } from '@containers/common/auth'
import { Label } from '@res/styledComponents/index'
import { ButtonsBar } from '@res/styledComponents/index'
import { BiPencil } from 'react-icons/bi'
import { EmailTypes } from './constants'

export class EmailNotifications extends Component {
  state = {
    accountContacts: undefined,
    existingContact: undefined,
  }

  componentWillMount() {
    ;(async () => {
      const accountContacts = await this.props.loadAccountContacts(
        this.props.accountId,
      )
      this.setState({ accountContacts })
    })()
  }

  componentWillReceiveProps(nextProps) {
    const { accountContacts } = nextProps
    if (accountContacts !== this.props.accountContacts) {
      this.setState({ accountContacts })
    }
  }

  newContact = () => {
    const { newAccountContact } = this.props
    newAccountContact(this.state.accountContacts)
  }

  editContact = (contact) => {
    const { editAccountContact } = this.props
    editAccountContact(contact, this.state.accountContacts)
  }

  handlePreExistingContact = (contact) => {
    contact.contactId = contact.id
    contact.phone = contact.phoneNumber
    delete contact.id
    this.editContact(contact)
  }

  render() {
    const { accountContacts, existingContact } = this.state
    const areContactsLoaded = !!accountContacts
    const areContactsEmpty = accountContacts && accountContacts.length === 0

    return (
      <div>
        <FlexContainer flexDirection="row" justifyContent="space-evenly">
          <ButtonsBar>
            <AuthorizedInteractable
              roles={['master admin', 'sales rep', 'sales lead']}
            >
              <button onClick={this.newContact}>
                <BiPencil />
                New Contact
              </button>
            </AuthorizedInteractable>
          </ButtonsBar>
          <FlexContainer flexDirection="row" alignItems="center">
            <Label>Add Pre-Existing Contact</Label>
            <AuthorizedInteractable
              roles={['master admin', 'sales rep', 'sales lead']}
            >
              <AutocompleteInput
                width="45%"
                marginBottom="20%"
                value={existingContact}
                loaderFunction={this.props.asyncLoadAccountContacts}
                onSelect={(contact) => this.handlePreExistingContact(contact)}
              />
            </AuthorizedInteractable>
          </FlexContainer>
        </FlexContainer>
        <Panel>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Email</th>
                <th>To Receive Email Notifications For</th>
              </tr>
            </thead>
            <tbody>
              {!areContactsLoaded && (
                <tr>
                  <td colSpan="4">
                    <LoadingIndicator />
                  </td>
                </tr>
              )}

              {areContactsLoaded && areContactsEmpty && (
                <tr>
                  <td colSpan="5">
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '1.2em',
                        color: 'rgba(0,0,0,0.2)',
                        padding: '20px',
                        backgroundColor: 'rgba(0,0,0,0.01)',
                      }}
                    >
                      No contacts yet.
                    </div>
                  </td>
                </tr>
              )}

              {areContactsLoaded &&
                accountContacts.map((contact) => {
                  const emails = EmailTypes.filter(
                    (email) => contact[email.value],
                  )
                  const { name, role, email } = contact
                  const emailsString = emails
                    .map((email) => email.label)
                    .join(', ')

                  return (
                    <tr
                      key={'contact' + contact.id}
                      onClick={() => this.editContact(contact)}
                    >
                      <td>{name}</td>
                      <td>{role && role.length ? role : 'No Role assigned'}</td>
                      <td>{email}</td>
                      <td>{emailsString}</td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </Panel>
      </div>
    )
  }
}

EmailNotifications.propTypes = {
  accountId: PropTypes.string,
  accountContacts: PropTypes.arrayOf(PropTypes.object),

  asyncLoadAccountContacts: PropTypes.func,
  loadAccountContacts: PropTypes.func,
  newAccountContact: PropTypes.func,
  editAccountContact: PropTypes.func,
}

export default EmailNotifications
