import { connect } from 'react-redux'

import { ItemPicker } from '@components/order/edit/sections'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'

const mapStateToProps = (state: {
  order?: {
    id: string
  }
}) => {
  const { order } = state
  const orderId = order && order.id

  return {
    orderId,
  }
}
const mapDispatchToProps = () => {
  const { RestService } = services
  const {
    LoadCateringMenuItem,
    AsyncLoadServiceItems,
    AsyncLoadSnackPacks,
    AsyncLoadVirtualItems,
    AsyncLoadVirtualKits,
    GetItemCountsByType,
  } = coordinators
  const {
    pResponseChefMenuItem,
    pResponseServiceItems,
    pResponseSnackPacks,
    pResponseVirtualItems,
    pResponseVirtualKits,
  } = presenters.Api

  const loadMenuItem = LoadCateringMenuItem({
    RestService,
    pResponseChefMenuItem,
  })
  const loadServiceItems = AsyncLoadServiceItems({
    RestService,
    pResponseServiceItems,
  })
  const loadSnackPacks = AsyncLoadSnackPacks({
    RestService,
    pResponseSnackPacks,
  })
  const loadVirtualItems = AsyncLoadVirtualItems({
    RestService,
    pResponseVirtualItems,
  })
  const loadVirtualKits = AsyncLoadVirtualKits({
    RestService,
    pResponseVirtualKits,
  })
  const getItemCounts = GetItemCountsByType({
    RestService,
  })

  return {
    loadMenuItem,
    loadServiceItems,
    loadSnackPacks,
    loadVirtualItems,
    loadVirtualKits,
    getItemCounts,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemPicker)
