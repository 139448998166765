import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/common/modal/Modal'
import { Button, Input, LinkText, Checkbox } from '@components/common/form'

const getNewEmptyConfig = () => {
  return {
    staffCount: 0,
    hours: 0,
    rate: 50,
  }
}

const staffingFeeSettingsToConfig = (staffingFeeSettings) => {
  return {
    staffCount: staffingFeeSettings.staffCount,
    hours: staffingFeeSettings.hours,
    rate: staffingFeeSettings.rate,
  }
}

const parseNumber = (rawValue) => {
  const value = parseFloat(rawValue)
  if (
    typeof value !== 'number' ||
    (typeof value === 'number' && isNaN(value))
  ) {
    return null
  }

  return value
}

const validateValue = (rawValue) => {
  const value = parseNumber(rawValue)
  if (typeof value !== 'number') {
    return 'Invalid value'
  }

  return ''
}

const validateConfig = (config) => {
  const staffCountError = validateValue(config.staffCount)
  const hoursError = validateValue(config.hours)
  const rateError = validateValue(config.rate)
  if (staffCountError || hoursError || rateError) {
    return 'Invalid values'
  } else {
    return ''
  }
}

const parseRawConfig = (config) => {
  return {
    staffCount: parseNumber(config.staffCount) || 0,
    hours: parseNumber(config.hours) || 0,
    rate: parseNumber(config.rate) || 0,
  }
}

const calcTotal = (staffCountStr, hoursStr, rateStr) => {
  const staffCount = parseNumber(staffCountStr) || 0
  const hours = parseNumber(hoursStr) || 0
  const rate = parseNumber(rateStr) || 0

  return staffCount * hours * rate
}

const getLocationsConfig = (clientSettingsCollection, initializeEmpty) => {
  const locationsConfig = {}
  clientSettingsCollection.forEach((cs) => {
    const locationConfig = cs.staffingFeeSettings
    const config =
      locationConfig && !initializeEmpty
        ? staffingFeeSettingsToConfig(locationConfig)
        : getNewEmptyConfig()
    locationsConfig[cs.id] = {
      id: cs.id,
      name: cs.name,
      config,
    }
  })

  return locationsConfig
}

const initForm = (clientGlobalSettings, clientSettingsCollection) => {
  const globalConfig = clientGlobalSettings.staffingFeeSettings
  const hasGlobalStaffingFee = Boolean(globalConfig)
  const locationHasStaffingFee = clientSettingsCollection.some((cs) =>
    Boolean(cs.staffingFeeSettings),
  )
  const staffingFeeEnabled = Boolean(
    hasGlobalStaffingFee || locationHasStaffingFee,
  )
  const locationsConfig = getLocationsConfig(clientSettingsCollection, false)

  return {
    staffingFeeEnabled,
    globalEnabled: hasGlobalStaffingFee,
    globalConfig: globalConfig
      ? staffingFeeSettingsToConfig(globalConfig)
      : getNewEmptyConfig(),
    locationsConfig,
    locationSpecificEnabled: !hasGlobalStaffingFee && locationHasStaffingFee,
  }
}

const infoCopy =
  'Staffing fee can apply to all locations or work on a location-specific basis.'

const initialFormState = {
  staffingFeeEnabled: false,
  globalEnabled: false,
  locationSpecificEnabled: false,
  globalConfig: null,
  locationsConfig: null,
}

const initialState = {
  form: { ...initialFormState },
  saveReqLoading: false,
}

class EditGroupOrderStaffingFeeSettingsModal extends Component {
  state = { ...initialState }

  componentDidMount() {
    const { clientGlobalSettings, clientSettingsCollection } = this.props
    if (clientGlobalSettings && clientSettingsCollection) {
      this.setForm(clientGlobalSettings, clientSettingsCollection)
    }
  }

  componentDidUpdate(prevProps) {
    const { clientGlobalSettings, clientSettingsCollection } = this.props
    const prevId = prevProps.clientGlobalSettings
      ? prevProps.clientGlobalSettings.id
      : null
    // TODO: may also need to check if `clientSettingsCollection` has changed
    if (
      clientGlobalSettings &&
      clientSettingsCollection &&
      clientGlobalSettings.id !== prevId
    ) {
      this.setForm(clientGlobalSettings, clientSettingsCollection)
    }
  }

  setForm = (clientGlobalSettings, clientSettingsCollection) => {
    const nextState = {
      ...this.state,
      form: initForm(clientGlobalSettings, clientSettingsCollection),
    }

    this.setState(nextState)
  }

  onHide = () => this.props.closeModal()

  onChangeStaffingFeeEnabled = (value) => {
    const form = {
      ...initialFormState,
      staffingFeeEnabled: value,
    }
    this.setState({ form })
  }

  onChangeGlobalEnabled = (value) => {
    const form = {
      ...this.state.form,
      locationSpecificEnabled: false,
      globalEnabled: value,
      globalConfig: value ? getNewEmptyConfig() : null,
    }
    this.setState({ form })
  }

  onChangeLocationSpecificEnabled = (value) => {
    const form = {
      ...this.state.form,
      globalEnabled: false,
      locationSpecificEnabled: value,
      locationsConfig: value
        ? getLocationsConfig(this.props.clientSettingsCollection, true)
        : null,
    }
    this.setState({ form })
  }

  onChangeGlobalConfig = (field) => (value) => {
    const nextForm = {
      ...this.state.form,
      globalConfig: {
        ...this.state.form.globalConfig,
        [field]: value,
      },
    }
    this.setState({ form: nextForm })
  }

  onChangeLocationConfig = (id, field) => (value) => {
    const locationConfig = this.state.form.locationsConfig[id]
    const nextLocation = {
      ...locationConfig,
      config: {
        ...locationConfig.config,
        [field]: value,
      },
    }
    const nextLocationsConfig = {
      ...this.state.form.locationsConfig,
      [id]: nextLocation,
    }
    const nextForm = {
      ...this.state.form,
      locationsConfig: nextLocationsConfig,
    }
    this.setState({ form: nextForm })
  }

  save = async () => {
    const { flashError, clientGlobalSettings, clientSettingsCollection } =
      this.props

    if (!clientGlobalSettings) {
      flashError('Cannot save because client global settings not availablle')

      return
    }

    const {
      staffingFeeEnabled,
      globalEnabled,
      locationSpecificEnabled,
      globalConfig,
      locationsConfig,
    } = this.state.form

    let nextGlobalStaffingFeeSettings = null
    const nextLocationsConfig = {}
    if (staffingFeeEnabled) {
      if (!(globalEnabled || locationSpecificEnabled)) {
        flashError('Select an option for how staffing fee should be applied')

        return
      }
      if (globalEnabled && globalConfig) {
        const globalConfigErrors = validateConfig(globalConfig)
        if (globalConfigErrors) {
          flashError(globalConfigErrors)

          return
        }
        nextGlobalStaffingFeeSettings = parseRawConfig(globalConfig)
        // Rm any location specific staffing fee settings if global enabled
        const locationsWithStaffingFeeSettings =
          clientSettingsCollection.filter((cs) => {
            return Boolean(cs.staffingFeeSettings)
          })
        locationsWithStaffingFeeSettings.forEach((cs) => {
          nextLocationsConfig[cs.id] = null
        })
      } else if (locationSpecificEnabled && locationsConfig) {
        const locationsConfigErrors = Object.values(locationsConfig).some(
          (csConfig) => {
            const configErrors = validateConfig(csConfig.config)

            return Boolean(configErrors)
          },
        )
        if (locationsConfigErrors) {
          flashError('Invalid values')

          return
        }
        nextGlobalStaffingFeeSettings = null
        Object.values(locationsConfig).forEach((csConfig) => {
          nextLocationsConfig[csConfig.id] = parseRawConfig(csConfig.config)
        })
      }
    } else {
      // Clear all staffing fee settings at global and location level
      nextGlobalStaffingFeeSettings = null
      const locationsWithStaffingFeeSettings = clientSettingsCollection.filter(
        (cs) => {
          return Boolean(cs.staffingFeeSettings)
        },
      )
      locationsWithStaffingFeeSettings.forEach((cs) => {
        nextLocationsConfig[cs.id] = null
      })
    }

    this.setState({ saveReqLoading: true })
    const result = await this.props.onSaveStaffingFeeSettings(
      nextGlobalStaffingFeeSettings,
      nextLocationsConfig,
    )
    this.setState({ saveReqLoading: false })

    if (result) {
      this.onHide()
    }
  }

  renderNoData() {
    return <div>Client global settings not available</div>
  }

  renderInputs = (staffingFeeSettings, onChange) => {
    const { staffCount, hours, rate } = staffingFeeSettings

    const total = calcTotal(staffCount, hours, rate)

    return (
      <div className="fee-inputs-container">
        <div className="fee-value">
          <Input
            label="# Staff"
            value={staffCount}
            onChange={(e) => onChange('staffCount')(e.target.value)}
            maxWidth="100px"
          />
        </div>
        <div className="fee-value">
          <Input
            label="# Hours"
            value={hours}
            onChange={(e) => onChange('hours')(e.target.value)}
            maxWidth="100px"
          />
        </div>
        <div className="fee-value">
          <Input
            label="$/Hour"
            value={rate}
            onChange={(e) => onChange('rate')(e.target.value)}
            maxWidth="100px"
          />
        </div>
        <div className="fee-value total-fee-container">
          <Input
            label="Total"
            value={`$${total.toFixed(2)}`}
            disabled={true}
            maxWidth="120px"
          />
        </div>
      </div>
    )
  }

  renderContent() {
    const {
      staffingFeeEnabled,
      globalEnabled,
      globalConfig,
      locationsConfig,
      locationSpecificEnabled,
    } = this.state.form

    return (
      <div className="edit-group-order-staffing-fee-settings-modal">
        <div className="info-container">
          <p>{infoCopy}</p>
        </div>
        <div>
          <Checkbox
            checked={staffingFeeEnabled}
            label="Charge additional staffing fee"
            marginBottom="20px"
            value="staffingFeeEnabled"
            onClick={(e) => this.onChangeStaffingFeeEnabled(e.target.checked)}
          />
          {staffingFeeEnabled && (
            <div className="all-configs-container">
              <div>
                <Checkbox
                  checked={globalEnabled}
                  label="Charge same staffing fee for all locations"
                  marginBottom="20px"
                  value="flatFeeWaiveStandard"
                  onClick={(e) => this.onChangeGlobalEnabled(e.target.checked)}
                />
              </div>
              {globalEnabled && globalConfig && (
                <div className="global-config-container">
                  {this.renderInputs(globalConfig, (field) =>
                    this.onChangeGlobalConfig(field),
                  )}
                </div>
              )}
              <div>
                <Checkbox
                  checked={locationSpecificEnabled}
                  label="Charge staffing fee on per location basis"
                  marginBottom="20px"
                  value="locationSpecificEnabled"
                  onClick={(e) =>
                    this.onChangeLocationSpecificEnabled(e.target.checked)
                  }
                />
              </div>
              {locationSpecificEnabled && locationsConfig && (
                <div>
                  {Object.values(locationsConfig).map((csConfig) => (
                    <div key={csConfig.id} className="location-container">
                      <div className="location-title">{csConfig.name}</div>
                      <div>
                        {this.renderInputs(csConfig.config, (field) =>
                          this.onChangeLocationConfig(csConfig.id, field),
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="buttons-container">
          <LinkText label="Cancel" onClick={this.onHide} color="#b30303" />
          <Button
            label="Save"
            onClick={this.save}
            disabled={this.state.saveReqLoading}
          />
        </div>
      </div>
    )
  }

  render() {
    const { clientGlobalSettings, clientSettingsCollection } = this.props

    const title = 'Staffing Fee Settings'

    return (
      <Modal
        title={title}
        hideModal={this.onHide}
        color="#001940"
        maxWidth="500px"
        width="500px"
        minHeight="600px"
        maxHeight="80vh"
      >
        {clientGlobalSettings && clientSettingsCollection
          ? this.renderContent()
          : this.renderNoData()}
      </Modal>
    )
  }
}

EditGroupOrderStaffingFeeSettingsModal.propTypes = {
  onSaveStaffingFeeSettings: PropTypes.func,
  flashError: PropTypes.func,
  closeModal: PropTypes.func,
  clientGlobalSettings: PropTypes.object,
  clientSettingsCollection: PropTypes.array,
}

export default EditGroupOrderStaffingFeeSettingsModal
