import { connect } from 'react-redux'

import { OrderInfoSection } from '@components/order/edit/sections'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapDispatchToProps = () => {
  const { RestService, SettingsService } = services
  const { AsyncLoadOrderSettings, LoadSettings, RetryOnTimeout } = coordinators
  const { pRequestSettings, pResponseSettings } = presenters.Api
  const { pOrderSettings } = presenters

  const loadOrderSettings = AsyncLoadOrderSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    pOrderSettings,
    LoadSettings,
    RetryOnTimeout,
    HandleError,
  })

  return { loadOrderSettings }
}

export default connect(undefined, mapDispatchToProps)(OrderInfoSection)
