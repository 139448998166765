import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PageBody } from '@res/styledComponents/index'
import Sidebar from '@components/sidebar/Sidebar'
import SidebarListItem from '@components/sidebar/SidebarListItem'
import Panel from '@components/common/Panel'
import {
  OrderStatus,
  MenuItemUpdates,
  ChefConfirmations,
  ChefDirectory,
  ChefScheduleUpdates,
  VendorClientMap,
} from '@containers/chefNetwork'

const Pages = {
  OrderStatus: 'orderStatus',
  MenuItemStatus: 'menuItemStatus',
  ChefConfirmations: 'chefConfirmations',
  ChefDirectory: 'chefDirectory',
  VendorClientMap: 'vendorClientMap',
  ChefScheduleUpdates: 'scheduleUpdates',
}

const ChefNetworkPage = (props) => {
  const { userId, headquarters, loadHeadquarters, hqId } = props
  const [page, setPage] = useState('orderStatus')

  useEffect(() => {
    onLoadHeadquarters()
  }, [userId])

  const onLoadHeadquarters = async () => {
    await loadHeadquarters(userId)
  }

  const renderSideBar = () => {
    return (
      <Sidebar heading="Chef Network">
        <SidebarListItem
          isSelected={Pages.OrderStatus === page}
          onClick={() => setPage(Pages.OrderStatus)}
        >
          Chef Order Status
        </SidebarListItem>
        <SidebarListItem
          isSelected={Pages.MenuItemStatus === page}
          onClick={() => setPage(Pages.MenuItemStatus)}
        >
          Menu Item Updates
        </SidebarListItem>
        <SidebarListItem
          isSelected={Pages.ChefScheduleUpdates === page}
          onClick={() => setPage(Pages.ChefScheduleUpdates)}
        >
          Chef Schedule Updates
        </SidebarListItem>
        <SidebarListItem
          isSelected={Pages.ChefConfirmations === page}
          onClick={() => setPage(Pages.ChefConfirmations)}
        >
          Chef Confirmations
        </SidebarListItem>
        <SidebarListItem
          isSelected={Pages.ChefDirectory === page}
          onClick={() => setPage(Pages.ChefDirectory)}
        >
          Chef Directory
        </SidebarListItem>
        <SidebarListItem
          isSelected={Pages.VendorClientMap === page}
          onClick={() => setPage(Pages.VendorClientMap)}
        >
          Vendor / Client Map
        </SidebarListItem>
      </Sidebar>
    )
  }

  let heading = ''
  switch (page) {
    case Pages.OrderStatus:
      heading = 'Chef Order Status'
      break
    case Pages.MenuItemStatus:
      heading = 'Menu Item Updates'
      break
    case 'scheduleUpdates':
      heading = 'Chef Schedule Updates'
      break
    case Pages.ChefConfirmations:
      heading = 'Chef Confirmations'
      break
    case Pages.ChefDirectory:
      heading = 'Chef Directory'
      break
    case Pages.VendorClientMap:
      heading = 'Vendor / Client Map'
      break
  }

  return (
    <PageBody>
      {renderSideBar()}
      {/* PANEL */}
      <div className="sidebar-body">
        <div className="sidebar-body-inner">
          <Panel width="100%" maxWidth="1600px" heading={heading}>
            {page === Pages.OrderStatus && (
              <OrderStatus headquarters={headquarters} headquarterId={hqId} />
            )}
            {page === Pages.MenuItemStatus && (
              <MenuItemUpdates
                headquarters={headquarters}
                headquarterId={hqId}
              />
            )}
            {page === Pages.ChefScheduleUpdates && <ChefScheduleUpdates />}
            {page === Pages.ChefConfirmations && (
              <ChefConfirmations
                headquarters={headquarters}
                headquarterId={hqId}
              />
            )}
            {page === Pages.ChefDirectory && (
              <ChefDirectory headquarters={headquarters} headquarterId={hqId} />
            )}
            {page === Pages.VendorClientMap && <VendorClientMap />}
          </Panel>
        </div>
      </div>
    </PageBody>
  )
}

ChefNetworkPage.propTypes = {
  hqId: PropTypes.number,
  headquarters: PropTypes.array,
  userId: PropTypes.string,

  loadHeadquarters: PropTypes.func,
}

export default ChefNetworkPage
