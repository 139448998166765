import React from 'react'
import PropTypes from 'prop-types'

import { MasterDetail } from '@components/common'

import { UserDetail, UserMaster } from '@containers/user'

const UserPage = (props) => {
  const { newUser } = props

  return (
    <MasterDetail
      detail={<UserDetail />}
      master={(props) => <UserMaster {...props} />}
      filters={[
        '',
        'account_executive',
        'master admin',
        'chef',
        'chef_recruiting',
        'catering_captain',
        'captain lead',
      ]}
      onNewAuthRoles={[
        'master admin',
        'sales lead',
        'captain lead',
        'chef lead',
      ]}
      onNew={newUser}
      onNewLabel="Dashboard User"
      title={'Users'}
    />
  )
}

UserPage.propTypes = {
  userName: PropTypes.string,

  newUser: PropTypes.func,
}

export default UserPage
