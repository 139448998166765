import { connect } from 'react-redux'

import CateringSupplies from '@components/supply/CateringSupplies'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { cateringSupplies } = state

  return {
    cateringSupplies,
  }
}

const mapDispatchToProps = () => {
  const {
    RestService,
    RouterService,
    SessionService,
    SettingsService,
    SupplyService,
    UIService,
  } = services
  const { pOrderSettings } = presenters
  const {
    pResponseGeneric,
    pRequestUpdateCateringSupply,
    pRequestSettings,
    pResponseSettings,
  } = presenters.Api
  const { pResponseErrorsArray } = presenters.Errors
  const {
    AsyncLoadOrderSettings,
    DeleteCateringSupply,
    LoadSettings,
    LoadCateringSupplies,
    RetryOnTimeout,
    SaveCateringSupply,
    HandleError: HandleError_,
  } = coordinators
  const HandleError = HandleError_({
    RouterService,
    SessionService,
    UIService,
    pResponseError: pResponseErrorsArray,
  })

  const deleteCateringSupply = DeleteCateringSupply({
    LoadCateringSupplies,
    RestService,
    SupplyService,
    UIService,
    pResponseCateringSupplies: pResponseGeneric,
    HandleError,
  })
  const loadCateringSupplies = LoadCateringSupplies({
    RestService,
    SupplyService,
    pResponseCateringSupplies: pResponseGeneric,
    HandleError,
  })
  const saveCateringSupply = SaveCateringSupply({
    LoadCateringSupplies,
    RestService,
    SupplyService,
    pRequestUpdateCateringSupply,
    pResponseCateringSupplies: pResponseGeneric,
    HandleError,
  })
  const loadOrderSettings = AsyncLoadOrderSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    pOrderSettings,
    LoadSettings,
    RetryOnTimeout,
    HandleError,
  })

  return {
    deleteCateringSupply,
    loadCateringSupplies,
    loadOrderSettings,
    saveCateringSupply,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CateringSupplies)
