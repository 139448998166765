import { connect } from 'react-redux'

import { DietaryPrefSelectInput } from '@components/common/form'

import services from '@services'
import presenters from '@presenters'
import coordinators from '@coordinators'
import { HandleError } from '@coordinators/composed'

const mapDispatchToProps = () => {
  const { RestService, SettingsService } = services
  const { pMapOptionAttributes } = presenters
  const { pRequestSettings, pResponseSettings } = presenters.Api
  const { AsyncLoadDietaryPreferences, LoadSettings, RetryOnTimeout } =
    coordinators

  const loadDietaryPreferences = AsyncLoadDietaryPreferences({
    RestService,
    SettingsService,
    pMapOptionAttributes,
    pRequestSettings,
    pResponseSettings,
    LoadSettings,
    RetryOnTimeout,
    HandleError,
  })

  return { loadDietaryPreferences }
}

export default connect(undefined, mapDispatchToProps)(DietaryPrefSelectInput)
