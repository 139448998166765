import { BaseService } from 'hungry-core2'

import actions from '@actions'

export default class Errors extends BaseService {
  clear = () => {
    this.dispatch(actions.clearErrors())
  }

  clearSingle = (namespace) => (key) => {
    this.dispatch(actions.clearError({ key, namespace }))
  }

  clearNamespace = (namespace) => {
    this.dispatch(actions.clearNamespace({ namespace }))
  }

  updateErrors = ({ errors, namespace }) => {
    this.dispatch(actions.updateErrors({ errors, namespace }))
  }
}
