import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/common/modal/Modal'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import LinkText from '@components/common/form/LinkText'
import Button from '@components/common/form/Button'
import Input from '@components/common/form/Input'
import { colors } from '../../../../constants'
import { Checkbox, CurrencyInput, TextArea } from '@components/common/form'
import { Label } from '@res/styledComponents/index'
import { AuthorizedInteractable } from '@containers/common/auth'

const snackPackInitialState = {
  price: 0.0,
  cost: 0.0,
  search: '',
}

class EditSnackPackModal extends Component {
  state = { ...snackPackInitialState }

  componentWillMount() {
    const { snackPack, clearErrors } = this.props
    this.setState({ ...snackPack })
    clearErrors()
  }

  componentWillReceiveProps(nextProps) {
    const { snackPack } = nextProps
    if (this.props.snackPack !== nextProps.snackPack) {
      const newState = { ...snackPack }
      this.setState(newState)
    }
  }

  delayedUpdate = () => {
    this.props.delayedUpdateSnackPack(this.state)
  }

  /* Events */

  onChange = (key) => (e) => {
    const { value } = e.target
    this.setState({ [key]: value }, this.delayedUpdate)
  }

  onChangeSnackPack = (key, value) => {
    this.setState({ [key]: value }, () =>
      this.props.delayedUpdateSnackPack(this.state),
    )
  }

  onChangeCheckbox = (e) => {
    const { checked, value: key } = e.target
    this.setState({ [key]: checked }, this.delayedUpdate)
  }

  onDelete = () => {
    const { close, deleteSnackPack } = this.props
    const { id, chefId, name } = this.state
    deleteSnackPack({ id, chefId, name })
    close()
  }

  onHide = () => {
    this.props.close()
  }

  onSave = () => {
    const { chefId } = this.props
    this.props.saveSnackPack({ ...this.state, chefId })
  }

  render() {
    const { errors, show } = this.props
    const { cost, description, id, isEnabled, isApproved, name, price } =
      this.state
    const title = id ? 'Edit Snack Pack' : 'New Snack Pack'

    return (
      <Modal
        show={show}
        title={title}
        hideModal={this.onHide}
        color="#001940"
        width="800px"
      >
        <FlexContainer justifyContent="space-between" flexWrap="wrap">
          <Input
            width="31%"
            label="Snack Pack Name"
            value={name}
            error={errors.name}
            onChange={this.onChange('name')}
          />
          <CurrencyInput
            width="31%"
            label="Vendor Price"
            value={cost}
            onChange={(value) => this.onChangeSnackPack('cost', value)}
          />
          <CurrencyInput
            width="31%"
            label="Market Price"
            value={price}
            onChange={(value) => this.onChangeSnackPack('price', value)}
          />
          <TextArea
            width="31%"
            height="100px"
            label="Description"
            value={description}
            onChange={this.onChange('description')}
          />
          <FlexContainer width="31%" flexDirection="column">
            <Label>Status</Label>
            <Checkbox
              label="Live"
              value="isEnabled"
              marginBottom="5px"
              checked={isEnabled}
              onChange={this.onChangeCheckbox}
            />
            <Checkbox
              label="Approved"
              value="isApproved"
              checked={isApproved}
              onChange={this.onChangeCheckbox}
            />
          </FlexContainer>
          <FlexContainer width="31%" />
        </FlexContainer>
        <FlexContainer alignItems="center" justifyContent="flex-end">
          {id && (
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <LinkText
                color={colors.violet}
                onClick={this.onDelete}
                label="Delete"
              />
            </AuthorizedInteractable>
          )}
          <XSpacing width="20px" />
          <AuthorizedInteractable
            roles={['master admin', 'sales lead', 'chef lead']}
          >
            <Button onClick={this.onSave} label="Save" />
          </AuthorizedInteractable>
        </FlexContainer>
      </Modal>
    )
  }
}

EditSnackPackModal.propTypes = {
  errors: PropTypes.object,
  snackPack: PropTypes.object,
  chefId: PropTypes.string,
  show: PropTypes.bool,

  //TODO Add async load
  clearErrors: PropTypes.func,
  close: PropTypes.func,
  delayedUpdateSnackPack: PropTypes.func,
  deleteSnackPack: PropTypes.func,
  displayAlert: PropTypes.func,
  loadChefSnackPacks: PropTypes.func,
  saveSnackPack: PropTypes.func,
}

export default EditSnackPackModal
