import { BaseService } from 'hungry-core2'

export class FakeService extends BaseService {
  async fakeInvoice() {
    return {
      id: 'c1e55b3a-9f99-40cd-97a0-ed2cfbf74361',
      description:
        '- 1x Test Item 1 @ $0.25 each = $0.25\n- 1x Test Item 2 @ $0.25 each = $0.25',
      event_date: '2017-09-14T12:00:00.000-04:00',
      order_number: 'FGY1000',
      subtotal: '0.5',
      tax: '0.0',
      service_fee: '0.05',
      total: '0.55',
      total_discount: '0.0',
      discounts_description: 'Sales Code: $0.49 Off',
      amount_due: 1,
      payment_info: '',
      delivery_instructions: 'Knock on the door',
      account_name: 'Test Account',
      order_items: [
        {
          item: 'Test Item 1',
          serving_size: '1',
          dietary_preferences: 'vegetarian, contains soy',
          servings: 1,
          price: '$0.25',
          total: '$0.25',
          total_packaging: '1x 18 inch Platter',
          total_serving_utensils: '0x Tong',
        },
        {
          item: 'Test Item 2',
          serving_size: '1',
          dietary_preferences: 'contains soy, contains gluten',
          servings: 1,
          price: '$0.25',
          total: '$0.25',
          total_packaging: '1x 48oz Small Bowl',
          total_serving_utensils: 'None',
        },
      ],
      customer: {
        id: 'ef59a229-592d-4c46-92d3-f280eb75ca00',
        email: 'test@accountcontact.com',
        phone_number: '23492039420',
        first_name: 'Test',
        last_name: 'Contact',
        rank: null,
        num_friends: null,
        invited_at: null,
        created_at: '2017-09-01T13:12:42.166-04:00',
        updated_at: '2017-09-01T13:12:42.166-04:00',
        contactable_id: null,
        contactable_type: null,
        title: 'Tester',
      },
      event_location: {
        id: 'cad80376-65db-4dc4-9bed-c873354ef6d7',
        line1: 'Another Street',
        line2: null,
        city: 'Nowhere',
        state: 'VA',
        zip: '22201',
        addressable_id: '05366c9a-a982-4a2a-8603-28c270dbf321',
        addressable_type: 'Account',
        is_default: true,
        created_at: null,
        updated_at: null,
        latitude: '0.0',
        longitude: '0.0',
        scoped: null,
        neighborhood: null,
        in_delivery_zone: false,
        error_message: null,
        label: null,
        business_name: null,
        special_requests: null,
      },
    }
  }

  async fakePayInvoice() {
    return {
      amountDue: '0.0',
      customer: {
        name: 'Bob Marley Co',
      },
      eventDate: 'Oct 12, 2017',
      eventLocation: '1560 Wilson Blv, suite 600, Arlington, VA, 22201,',
      description: 'Thanks for paying!',
      orderNumber: 'FY1234',
      paymentInfo: '$14.50 paid April 11, 1990, Visa *5555',
      subtotal: '13.50',
      tax: '1.00',
      total: '14.50',
    }
  }

  fakeMenuCards() {
    return [
      {
        name: 'T Treats',
        id: '1222-4140-8d77-19f4ffd367bf',
        tags_cuisine_list: 'indian,italian',
        tags_day_part_list: 'happy hour',
        cover_image: {
          image: {
            url: 'http://assets.epicurious.com/photos/576b38c090773c635caa8117/6:4/w_620%2Ch_413/ratatouille.jpg',
          },
        },
        under_capacity: true,
        enough_advance_notice: true,
        in_delivery_zone: null,
        available: true,
        chef: {
          name: 'Leland Treutel',
          chef_profile: {
            description:
              'i make tasty food. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            hometown: '',
            experience: '',
            specialties: '',
            years_cooking: 0,
            accomplishments: 'New Chef',
            image: {
              thumbor_headshot_url:
                'https://www.cordonbleu.edu/Files/MediaFile/1867.jpg',
            },
            rating: '1',
          },
        },
        main_menu_items: [],
        side_menu_items: [],
        other_menu_items: [
          {
            name: 'tiramisu',
            description: 'chocolate cake thingy',
            market_price: '21.0',
            rating: 0,
            meal_type: 'Dessert',
            tags_dietary_preference_list:
              'contains dairy,contains eggs,contains soy',
            tags_ingredient_list: 'batter,chocolate,dankness,stuff',
          },
          {
            name: 'mango lassi',
            description: 'here lassie',
            market_price: '31.0',
            rating: 0,
            meal_type: 'Drink',
            tags_dietary_preference_list: '',
            tags_ingredient_list: 'dog,mango',
          },
        ],
      },
      {
        name: 'Tasty Treats',
        id: '1a3c963f-a222-4140-8d77-19f4ffd367bf',
        tags_cuisine_list: 'indian,italian',
        tags_day_part_list: 'happy hour',
        cover_image: {
          image: {
            url: 'http://assets.epicurious.com/photos/576b38c090773c635caa8117/6:4/w_620%2Ch_413/ratatouille.jpg',
          },
        },
        under_capacity: true,
        enough_advance_notice: true,
        in_delivery_zone: null,
        available: true,
        chef: {
          name: 'Leland Treutel',
          sold_out: true,
          chef_profile: {
            description: 'i make tasty food',
            hometown: '',
            experience: '',
            specialties: '',
            years_cooking: 0,
            accomplishments: null,
            image: {
              thumbor_headshot_url:
                'https://www.cordonbleu.edu/Files/MediaFile/1867.jpg',
            },
            rating: '4.3',
          },
        },
        main_menu_items: [],
        side_menu_items: [],
        other_menu_items: [
          {
            name: 'tiramisu',
            description: 'chocolate cake thingy',
            market_price: '21.0',
            rating: 0,
            meal_type: 'Dessert',
            tags_dietary_preference_list:
              'contains dairy,contains eggs,contains soy',
            tags_ingredient_list: 'batter,chocolate,dankness,stuff',
          },
          {
            name: 'mango lassi',
            description: 'here lassie',
            market_price: '31.0',
            rating: 0,
            meal_type: 'Drink',
            tags_dietary_preference_list: '',
            tags_ingredient_list: 'dog,mango',
          },
        ],
      },
      {
        name: 'BYO Tex Mex',
        id: '1b0f287b-9c76-4cb2-82be-f1316a981e96',
        tags_cuisine_list: 'byo,mexican',
        tags_day_part_list: 'lunch / dinner',
        cover_image: {
          image: {
            url: 'http://assets.epicurious.com/photos/576b38c090773c635caa8117/6:4/w_620%2Ch_413/ratatouille.jpg',
          },
        },
        under_capacity: true,
        enough_advance_notice: true,
        in_delivery_zone: null,
        available: true,
        chef: {
          name: ' T',
          chef_profile: {
            description: 'i make tasty food',
            hometown: '',
            experience: '',
            specialties: '',
            years_cooking: 0,
            accomplishments: null,
            image: {
              thumbor_headshot_url:
                'https://www.cordonbleu.edu/Files/MediaFile/1867.jpg',
            },
            rating: '4.3',
          },
        },
        main_menu_items: [
          {
            id: '1b0f287b-9c76-4cb2-82be-f1316a981234',
            name: 'Build Your Own Breakfast Burrito',
            description:
              'Includes your choice of protein & tortilla. All packages include scrambled eggs, shredded cheese, roasted peppers, salsa, guacamole and lime crema.',
            market_price: '7.75',
            rating: 0,
            meal_type: 'Main',
            tags_dietary_preference_list:
              'contains dairy,contains eggs,contains gluten',
            tags_ingredient_list:
              'eggs,guacamole,lime crema,roasted peppers,salsa,shredded cheese',
            image: {
              image: {
                url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Burrito_with_rice.jpg/500px-Burrito_with_rice.jpg',
              },
            },
          },
          {
            id: '1b0f287b-9c76-4cb2-82be-f1316a982345',
            name: 'test burger',
            description: 'test',
            market_price: '16.25',
            rating: 0,
            meal_type: 'Main',
            tags_dietary_preference_list: 'contains eggs',
            tags_ingredient_list: 'test',
            image: {
              image: {
                url: 'https://pbs.twimg.com/media/DJESkH-UMAARvWQ.jpg:large',
              },
            },
          },
          {
            id: '1b0f287b-9c76-4cb2-82be-f1316a983456',
            name: 'test booger',
            description: 'testing',
            market_price: '15.5',
            rating: 0,
            meal_type: 'Main',
            tags_dietary_preference_list: 'vegetarian',
            tags_ingredient_list: 'booger',
            image: {
              image: {
                url: 'https://www.justonecookbook.com/wp-content/uploads/2014/09/Miso-Ramen.jpg',
              },
            },
          },
        ],
        side_menu_items: [
          {
            id: 'side1',
            name: 'Potato Hashbrowns',
            description: '',
            market_price: '2.0',
            rating: 0,
            meal_type: 'Side',
            tags_dietary_preference_list: 'contains gluten',
            tags_ingredient_list: 'potatoes',
          },
          {
            id: 'side2',
            name: 'Vanilla Yogurt',
            description: '',
            market_price: '2.0',
            rating: 0,
            meal_type: 'Side',
            tags_dietary_preference_list: 'contains dairy',
            tags_ingredient_list: 'yogurt',
          },
          {
            id: 'side3',
            name: 'Curry',
            description: '',
            market_price: '3.0',
            rating: 0,
            meal_type: 'Side',
            tags_dietary_preference_list: 'contains dairy',
            tags_ingredient_list: 'curry powder',
          },
          {
            id: 'side4',
            name: 'Hummus and chips',
            description: '',
            market_price: '3.0',
            rating: 0,
            meal_type: 'Side',
            tags_dietary_preference_list: 'contains dairy',
            tags_ingredient_list:
              'chickpease, lemon juice, olive oil, pita chips',
          },
        ],
        other_menu_items: [
          {
            id: 'drink1',
            name: 'mango lassi',
            description: 'here lassie',
            market_price: '31.0',
            rating: 0,
            meal_type: 'Drink',
            tags_dietary_preference_list: '',
            tags_ingredient_list: 'dog, mango',
          },
          {
            id: 'dessert1',
            name: 'tiramisu',
            description: 'chocolate cake thingy',
            market_price: '21.0',
            rating: 0,
            meal_type: 'Dessert',
            tags_dietary_preference_list:
              'contains dairy,contains eggs,contains soy',
            tags_ingredient_list: 'batter, chocolate, dankness, stuff',
          },
        ],
      },
      {
        name: 'donk menu',
        id: '61035b06-bb2f-4f7d-abc2-e66547a12e3a',
        tags_cuisine_list: 'brazilian,italian,jamaican,russian',
        tags_day_part_list: 'breakfast,lunch / dinner',
        cover_image: {
          image: {
            url: 'http://assets.epicurious.com/photos/576b38c090773c635caa8117/6:4/w_620%2Ch_413/ratatouille.jpg',
          },
        },
        under_capacity: true,
        enough_advance_notice: true,
        in_delivery_zone: null,
        available: true,
        chef: {
          name: 'Leland Treutel',
          chef_profile: {
            description: 'i make tasty food',
            hometown: '',
            experience: '',
            specialties: '',
            years_cooking: 0,
            accomplishments: null,
            image: {
              thumbor_headshot_url:
                'https://www.cordonbleu.edu/Files/MediaFile/1867.jpg',
            },
            rating: '4.3',
          },
        },
        main_menu_items: [
          {
            id: '1b0f287b-9c76-4cb2-82be-f1316a984567',
            name: 'Linguini with Basil Cream Sauce',
            description:
              'Hot pasta served with luscious, herb cream sauce on the side.',
            market_price: '54.0',
            rating: 0,
            meal_type: 'Main',
            tags_dietary_preference_list: 'contains soy',
            tags_ingredient_list: 'linguini',
            image: {
              image: {
                url: 'http://www.kevinandamanda.com/whatsnew/wp-content/uploads/2012/03/Pioneer-Woman-Pasta-with-Pesto-Cream-Sauce-Recipe-19.jpg',
              },
            },
          },
          {
            id: '1b0f287b-9c76-4cb2-82be-f1316a982345',
            name: 'test burger',
            description: 'test',
            market_price: '16.25',
            rating: 0,
            meal_type: 'Main',
            tags_dietary_preference_list: 'contains eggs',
            tags_ingredient_list: 'test',
            image: {
              image: {
                url: 'https://pbs.twimg.com/media/DJESkH-UMAARvWQ.jpg:large',
              },
            },
          },
        ],
        side_menu_items: [
          {
            id: 'side1',
            name: 'Potato Hashbrowns',
            description: '',
            market_price: '2.0',
            rating: 0,
            meal_type: 'Side',
            tags_dietary_preference_list: 'contains gluten',
            tags_ingredient_list: 'potatoes',
          },
        ],
        other_menu_items: [
          {
            name: 'mango lassi',
            description: 'here lassie',
            market_price: '31.0',
            rating: 0,
            meal_type: 'Drink',
            tags_dietary_preference_list: '',
            tags_ingredient_list: 'dog,mango',
          },
          {
            name: 'tiramisu',
            description: 'chocolate cake thingy',
            market_price: '21.0',
            rating: 0,
            meal_type: 'Dessert',
            tags_dietary_preference_list:
              'contains dairy,contains eggs,contains soy',
            tags_ingredient_list: 'batter,chocolate,dankness,stuff',
          },
        ],
      },
      {
        name: 'Tasty Treats',
        id: '1a3c963f7-19f4ffd367bf',
        tags_cuisine_list: 'indian,italian',
        tags_day_part_list: 'happy hour',
        cover_image: {
          image: {
            url: 'http://assets.epicurious.com/photos/576b38c090773c635caa8117/6:4/w_620%2Ch_413/ratatouille.jpg',
          },
        },
        under_capacity: true,
        enough_advance_notice: true,
        in_delivery_zone: null,
        available: true,
        chef: {
          name: 'Leland Treutel',
          chef_profile: {
            description: 'i make tasty food',
            hometown: '',
            experience: '',
            specialties: '',
            years_cooking: 0,
            accomplishments: null,
            image: {
              thumbor_headshot_url:
                'https://www.cordonbleu.edu/Files/MediaFile/1867.jpg',
            },
            rating: '4.3',
          },
        },
        main_menu_items: [],
        side_menu_items: [],
        other_menu_items: [
          {
            name: 'tiramisu',
            description: 'chocolate cake thingy',
            market_price: '21.0',
            rating: 0,
            meal_type: 'Dessert',
            tags_dietary_preference_list:
              'contains dairy,contains eggs,contains soy',
            tags_ingredient_list: 'batter,chocolate,dankness,stuff',
          },
          {
            name: 'mango lassi',
            description: 'here lassie',
            market_price: '31.0',
            rating: 0,
            meal_type: 'Drink',
            tags_dietary_preference_list: '',
            tags_ingredient_list: 'dog,mango',
          },
        ],
      },
    ]
  }

  async fakeFilters() {
    return [
      {
        type: 'chef_rating',
        display: '1 star',
        value: 1,
        id: 0,
      },
      {
        type: 'chef_rating',
        display: '2 star',
        value: 2,
        id: 1,
      },
      {
        type: 'chef_rating',
        display: '3 star',
        value: 3,
        id: 2,
      },
      {
        type: 'chef_rating',
        display: '4 star',
        value: 4,
        id: 3,
      },
      {
        type: 'chef_rating',
        display: '5 star',
        value: 5,
        id: 4,
      },
      {
        type: 'cuisine_types',
        value: 'Bbq',
        display: 'Bbq (2)',
        id: 5,
      },
      {
        type: 'cuisine_types',
        value: 'American',
        display: 'American (2)',
        id: 6,
      },
      {
        type: 'cuisine_types',
        value: 'Byo',
        display: 'Byo (2)',
        id: 7,
      },
      {
        type: 'cuisine_types',
        value: 'Italian',
        display: 'Italian (2)',
        id: 8,
      },
      {
        type: 'cuisine_types',
        value: 'Jamaican',
        display: 'Jamaican (1)',
        id: 9,
      },
      {
        type: 'cuisine_types',
        value: 'Brazilian',
        display: 'Brazilian (1)',
        id: 10,
      },
      {
        type: 'cuisine_types',
        value: 'Light \u0026 healthy',
        display: 'Light \u0026 healthy (1)',
        id: 11,
      },
      {
        type: 'cuisine_types',
        value: 'Russian',
        display: 'Russian (1)',
        id: 12,
      },
      {
        type: 'cuisine_types',
        value: 'Belgian',
        display: 'Belgian (1)',
        id: 13,
      },
      {
        type: 'cuisine_types',
        value: 'Greek',
        display: 'Greek (1)',
        id: 14,
      },
      {
        type: 'cuisine_types',
        value: 'Asian fusion',
        display: 'Asian fusion (1)',
        id: 15,
      },
      {
        type: 'cuisine_types',
        value: 'Brazil',
        display: 'Brazil (1)',
        id: 16,
      },
      {
        type: 'cuisine_types',
        value: 'Filipino',
        display: 'Filipino (1)',
        id: 17,
      },
      {
        type: 'cuisine_types',
        value: 'Pizza',
        display: 'Pizza (1)',
        id: 18,
      },
      {
        type: 'cuisine_types',
        value: 'Peruvian',
        display: 'Peruvian (1)',
        id: 19,
      },
      {
        type: 'cuisine_types',
        value: 'Indian',
        display: 'Indian (1)',
        id: 20,
      },
      {
        type: 'cuisine_types',
        value: 'Mexican',
        display: 'Mexican (1)',
        id: 21,
      },
    ]
  }

  async fakeCart() {
    return {
      id: '1a3c963f-a222-4140-8d77-19f4ffd367bf',
      name: 'BYO Tex Mex',
      tags_cuisine_list: 'byo,mexican',
      image:
        'http://assets.epicurious.com/photos/576b38c090773c635caa8117/6:4/w_620%2Ch_413/ratatouille.jpg',
      chef: {
        name: 'Linguine Weeny',
        id: 'bafbae10-8569-456e-a366-54aa40be6eb2',
        image:
          'http://images1.fanpop.com/images/photos/1800000/Ratatouille-Production-Stills-ratatouille-1847049-1902-2560.jpg',
        description:
          'Ray Mackintyre’s passion for cooking began around a big, black wood-burning stove. Set in the middle of the kitchen in his childhood home in Freeport, Louisiana, his grandmother would combine fat and flour to make brown roux for red-eye gravy and black roux for gumbo.',
        rating: '4.3',
      },
      main_menu_items: [
        {
          id: '39f8bdc5-591d-44e7-b7ee-9a329e22e7ad',
          menu_id: 'af942dfd-4747-4e3e-b5c1-cec9cf750e29',
          name: 'Build Your Own Breakfast Burrito',
          description:
            'Includes your choice of protein \u0026 tortilla. All packages include scrambled eggs, shredded cheese, roasted peppers, salsa, guacamole and lime crema.',
          price: '8.0',
          quantity: 6,
        },
        {
          id: '19701d47-065e-4c31-bfb5-ef8198fb7240',
          menu_id: 'af942dfd-4747-4e3e-b5c1-cec9cf750e29',
          name: 'Vanilla Yogurt',
          description: 'Plain old vanilla yogurt',
          price: '2.0',
          quantity: 6,
        },
      ],
      head_count: 6,
      created_at: '2017-09-13T13:00:00.000-04:00',
      updated_at: '2017-09-13T13:05:00.000-04:00',
      expires_at: '2017-09-14T13:05:00.000-04:00',
      is_paid: false,
    }
  }

  async fakeUpdateCart() {
    //Returns a cart with data on the 3rd (checkout) page. In this case everything starting with deliver_at
    return {
      id: '1a3c963f-a222-4140-8d77-19f4ffd367bf',
      name: 'BYO Tex Mex',
      tags_cuisine_list: 'byo,mexican',
      image:
        'http://assets.epicurious.com/photos/576b38c090773c635caa8117/6:4/w_620%2Ch_413/ratatouille.jpg',
      chef: {
        name: 'Linguine Weeny',
        id: 'bafbae10-8569-456e-a366-54aa40be6eb2',
        image:
          'http://images1.fanpop.com/images/photos/1800000/Ratatouille-Production-Stills-ratatouille-1847049-1902-2560.jpg',
        description:
          'Ray Mackintyre’s passion for cooking began around a big, black wood-burning stove. Set in the middle of the kitchen in his childhood home in Freeport, Louisiana, his grandmother would combine fat and flour to make brown roux for red-eye gravy and black roux for gumbo.',
        rating: '4.3',
      },
      main_menu_items: [
        {
          id: '39f8bdc5-591d-44e7-b7ee-9a329e22e7ad',
          menu_id: 'af942dfd-4747-4e3e-b5c1-cec9cf750e29',
          name: 'Build Your Own Breakfast Burrito',
          description:
            'Includes your choice of protein \u0026 tortilla. All packages include scrambled eggs, shredded cheese, roasted peppers, salsa, guacamole and lime crema.',
          price: '8.0',
          quantity: 6,
        },
        {
          id: '19701d47-065e-4c31-bfb5-ef8198fb7240',
          menu_id: 'af942dfd-4747-4e3e-b5c1-cec9cf750e29',
          name: 'Vanilla Yogurt',
          description: 'Plain old vanilla yogurt',
          price: '2.0',
          quantity: 6,
        },
      ],
      head_count: 6,
      created_at: '2017-09-13T13:00:00.000-04:00',
      updated_at: '2017-09-13T13:05:00.000-04:00',
      expires_at: '2017-09-14T13:05:00.000-04:00',
      is_paid: false,
      deliver_at: '2017-09-18T13:00:00.000-04:00',
      delivery_instructions: 'Test Delivery Instructions!!',
      dropoff_address: {
        addressable_id: '52e1d59a-e715-48ae-b4f0-924938fad1f0',
        addressable_type: 'Account',
        business_name: null,
        city: 'Washington',
        created_at: null,
        error_message: null,
        full_address:
          '815 Connecticut Ave NW, Washington, DC 20006, Washington, DC  20006',
        id: '6e41df08-763d-4d2e-a04c-ddcd23987a70',
        in_delivery_zone: true,
        is_default: true,
        label: null,
        latitude: '38.901057',
        line1: '815 Connecticut Ave NW, Washington, DC 20006',
        line2: null,
        longitude: '-77.0379279',
        neighborhood: 'Downtown',
        scoped: null,
        special_requests: null,
        state: 'DC ',
        updated_at: null,
        zip: '20006',
      },
      needs_utensils: false,
      needs_serving_utensils: false,
      contact1: {
        email: 'jonathan@tryhungry.com',
        first_name: 'Test',
        last_name: 'Contact',
        phone_number: '11111111111',
      },
      contact2: {
        first_name: 'Another',
        last_name: 'Test-Contact',
        phone_number: '2222222222',
      },
      company_name: 'Bob Marley Co',
      event_description: 'Some description of the event.',
      point_of_access: 'loading dock',
      security_check_in: true,
    }
  }

  fakeReceipt() {
    return {
      id: '72f7e45b-820b-4ca2-a98a-358ebe662116',
      description:
        '- 1x Baked Mac \u0026 Cheese @ $36.0 each = $36.0\n- 1x Chefs Smoked Chicken Breast @ $180.0 each = $180.0\n- 1x Green Beans @ $40.0 each = $40.0\n- 1x Mixed Greens Salad @ $0.0 each = $0.0',
      event_date: '2017-10-12T14:00:00.000-04:00',
      order_number: 'FGY1051',
      delivery_instructions:
        'Enter the building through the side door.  Please set up entrees in room 4B and desserts on the side table.',
      discounts_description: 'Sales Code: $255.98 Off',
      set_up_style: 'Extended-Life Buffet',
      head_count: 8,
      subtotal: '256.0',
      tax: '0.0',
      service_fee: '0.03',
      total: '0.05',
      total_discount: '255.98',
      amount_due: '0.0',
      payment_info: '$0.05 paid 10/4/2017, Visa *1881',
      account_name: 'Test Account',
      needs_utensils: true,
      needs_serving_utensils: true,
      building_instructions:
        'Point of Access: Loading Dock, Security Check-In Required: No',
      call_on_arrival: false,
      order_items: [
        {
          item: 'Baked Mac \u0026 Cheese',
          is_addon: false,
          servings: 1,
          serving_size: '',
          total_packaging: '1x ',
          total_serving_utensils: 'None',
          price: '$36.00',
          total: '$36.00',
          dietary_preferences:
            'Please confirm packaging and meal preferences with the Chef.',
        },
        {
          item: 'Chefs Smoked Chicken Breast',
          is_addon: false,
          servings: 1,
          serving_size: '',
          total_packaging: '1x ',
          total_serving_utensils: 'None',
          price: '$180.00',
          total: '$180.00',
          dietary_preferences:
            'Please confirm packaging and meal preferences with the Chef.',
        },
        {
          item: 'Green Beans',
          is_addon: false,
          servings: 1,
          serving_size: '',
          total_packaging: '1x ',
          total_serving_utensils: 'None',
          price: '$40.00',
          total: '$40.00',
          dietary_preferences:
            'Please confirm packaging and meal preferences with the Chef.',
        },
        {
          item: 'Mixed Greens Salad',
          is_addon: true,
          servings: 1,
          serving_size: '',
          total_packaging: '1x ',
          total_serving_utensils: 'None',
          price: '$0.00',
          total: '$0.00',
          dietary_preferences:
            'Please confirm packaging and meal preferences with the Chef.',
        },
      ],
      customer: {
        id: '0cdf3770-4805-4640-8703-49150a94bbae',
        email: 'jonathan@tryhungry.com',
        phone_number: '2022883355',
        first_name: 'Email',
        last_name: 'Test',
        rank: null,
        num_friends: null,
        invited_at: null,
        created_at: '2017-09-10T20:52:49.922-04:00',
        updated_at: '2017-09-10T20:52:49.922-04:00',
        contactable_id: null,
        contactable_type: null,
        title: 'Email Tester',
      },
      event_location: {
        id: 'cad80376-65db-4dc4-9bed-c873354ef6d7',
        line1: 'Another Street',
        line2: 'Apt 205',
        city: 'Nowhere',
        state: 'VA',
        zip: '22201',
        addressable_id: '05366c9a-a982-4a2a-8603-28c270dbf321',
        addressable_type: 'Account',
        is_default: true,
        created_at: null,
        updated_at: null,
        latitude: '0.0',
        longitude: '0.0',
        scoped: null,
        neighborhood: null,
        in_delivery_zone: false,
        error_message: null,
        label: null,
        business_name: null,
        special_requests: null,
      },
    }
  }
}
