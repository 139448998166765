import { connect } from 'react-redux'

import { OutreachPage } from '@components/popUps'

import coordinators from '@coordinators'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { lastLoggedInHq } = state.user

  return {
    headquarter: lastLoggedInHq,
  }
}

const mapDispatchToProps = () => {
  const { RestService, PopUpCustomerService, UIService } = services
  const {
    GetEmailDistributions,
    GetZipGroups,
    SaveEmailDistribution,
    SaveZipGroup,
  } = coordinators
  const { newZipGroup, newEmailDistribution } = PopUpCustomerService
  const getEmailDistributions = GetEmailDistributions({
    RestService,
    HandleError,
  })
  const getZipGroups = GetZipGroups({ RestService, HandleError })
  const saveEmailDistribution = SaveEmailDistribution({
    RestService,
    UIService,
    HandleError,
  })
  const saveZipGroup = SaveZipGroup({
    RestService,
    UIService,
    HandleError,
  })

  return {
    getEmailDistributions,
    getZipGroups,
    newZipGroup,
    newEmailDistribution,
    saveEmailDistribution,
    saveZipGroup,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutreachPage)
