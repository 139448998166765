import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import FlexContainer from '@components/common/FlexContainer'

const AlertModal = (props) => {
  const onHide = (e) => {
    e.stopPropagation()
    e.preventDefault()

    props.close()
  }

  const { message, show, title, options } = props
  const { isLink = false, link = '', message: linkMessage = '' } = options || {}

  return (
    <Modal show={show} onHide={onHide} backdrop={false} className="alert-modal">
      <Modal.Header closeButton>
        <Modal.Title> {title} </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {message &&
          message.split('\n').map((text, i) => (
            <p key={i} className="confirmation-text">
              {' '}
              {text}{' '}
            </p>
          ))}
        {isLink && (
          <FlexContainer
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <p className="confirmation-text">{linkMessage}</p>
            <a className="confirmation-link" href={link}>
              {link}
            </a>
          </FlexContainer>
        )}
      </Modal.Body>

      <Modal.Footer>
        <button className="button-neutral" onClick={onHide}>
          OK
        </button>
      </Modal.Footer>
    </Modal>
  )
}

AlertModal.propTypes = {
  message: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string,
  options: PropTypes.object,

  close: PropTypes.func,
}

export default AlertModal
