import { connect } from 'react-redux'

import Filters from '@components/menuItemsSearch/Filters'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { user, headquarter } = state

  return {
    userId: user && user.id,
    userHeadquarters: (headquarter && headquarter.userHeadquarters) || [],
  }
}

const mapDispatchToProps = () => {
  const { ChefService, HeadquarterService, SettingsService, RestService } =
    services
  const { LoadChefs, LoadSettings, RetryOnTimeout } = coordinators
  const {
    pRequestSettings,
    pResponseSettings,
    pResponseChefs,
    pResponseGeneric,
  } = presenters.Api
  const loadHeadquarters = coordinators.LoadUserHeadquarters({
    HeadquarterService,
    RestService,
    pResponseHeadquarters: pResponseGeneric,
    HandleError,
  })
  const loadChefs = LoadChefs({ ChefService, RestService, pResponseChefs })
  const loadMenuItemSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('menuItems')

  return {
    loadHeadquarters,
    loadChefs,
    loadMenuItemSettings,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters)
