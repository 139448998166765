import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { AuthorizedInteractable } from '@containers/common/auth'
import { Checkbox } from '@components/common/form'
import { EmailTypes } from '../accountSections/constants'

export class EditAccountContactModal extends Component {
  constructor(props) {
    super(props)

    this.state = { ...props.editAccountContact }
  }

  componentWillMount() {
    const { clearErrors } = this.props
    clearErrors()
  }

  componentWillReceiveProps(nextProps) {
    const { editAccountContact } = nextProps
    if (this.props.editAccountContact !== editAccountContact) {
      this.setState({ ...editAccountContact })
    }
  }

  onHide = (e) => {
    const { close } = this.props
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }

    close()
  }

  onChange = (key) => (e) => {
    const { value } = e.target
    const { delayedUpdateAccountContact } = this.props
    this.setState({ [key]: value }, () =>
      delayedUpdateAccountContact(this.state),
    )
  }

  onChangeCheckbox = (e) => {
    const { checked, value: key } = e.target
    const { delayedUpdateAccountContact } = this.props
    this.setState({ [key]: checked }, () =>
      delayedUpdateAccountContact(this.state),
    )
  }

  onDelete = async (e) => {
    const { accountId, close, deleteAccountContact, loadAccountContacts } =
      this.props
    const { id, name } = this.state
    e.stopPropagation()
    e.preventDefault()

    await deleteAccountContact(id, accountId, name)
    loadAccountContacts(accountId)
    close()
  }

  onSubmit = async (e) => {
    const { accountContacts, email, id } = this.state
    const {
      displayFailureMessage,
      saveAccountContact,
      accountId,
      loadAccountContacts,
    } = this.props
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }

    if (
      !id &&
      accountContacts.some((contact) => contact.email === email.toLowerCase())
    ) {
      displayFailureMessage(
        'Email is already associated with another contact on this account. Either update contact or use a different email.',
      )

      return
    }

    await saveAccountContact(this.state)
    loadAccountContacts(accountId)
  }

  render() {
    const { close, errors, show } = this.props
    const { id, email, firstName, lastName, phone, role } = this.state

    return (
      <Modal show={show} onHide={() => close()} dialogClassName="small-modal">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            {id ? 'Edit Account Contact' : 'New Account Contact'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-field-container">
            <div className="form-field-container__columns">
              <p>First Name</p>
              <input
                type="text"
                value={firstName}
                onChange={this.onChange('firstName')}
              />
              {errors.name && (
                <div className="error-message"> {errors.name}</div>
              )}
            </div>

            <div className="form-field-container__columns">
              <p>Last Name</p>
              <input
                type="text"
                value={lastName}
                onChange={this.onChange('lastName')}
              />
              {errors.name && (
                <div className="error-message"> {errors.name}</div>
              )}
            </div>

            <div className="form-field-container__columns">
              <p>Role</p>
              <input
                type="text"
                value={role}
                onChange={this.onChange('role')}
              />
              {errors.role && (
                <div className="error-message"> {errors.role}</div>
              )}
            </div>

            <div className="form-field-container__columns">
              <p>Email</p>
              <input
                type="text"
                value={email}
                onChange={this.onChange('email')}
              />
              {errors.email && (
                <div className="error-message"> {errors.email}</div>
              )}
            </div>

            <div className="form-field-container__columns">
              <p>Phone</p>
              <input
                type="text"
                value={phone}
                onChange={this.onChange('phone')}
              />
              {errors.phone && (
                <div className="error-message"> {errors.phone}</div>
              )}
            </div>

            <div className="form-field-container__columns">
              <p className="flex-align-top">Receive email notifications for:</p>
              <div>
                {EmailTypes.map((email) => (
                  <Checkbox
                    value={email.value}
                    key={email.value}
                    label={email.label}
                    checked={this.state[email.value]}
                    onChange={this.onChangeCheckbox}
                  />
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {id && (
            <AuthorizedInteractable
              roles={[
                'master admin',
                'account lead',
                'sales rep',
                'sales lead',
              ]}
            >
              <button
                style={{ float: 'left' }}
                onClick={this.onDelete}
                className="button-alert"
              >
                Delete
              </button>
            </AuthorizedInteractable>
          )}
          <AuthorizedInteractable
            roles={['master admin', 'account lead', 'sales rep', 'sales lead']}
          >
            <button onClick={this.onSubmit} className="button-primary">
              Save
            </button>
          </AuthorizedInteractable>
          <button onClick={this.onHide} className="button-neutral">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

EditAccountContactModal.propTypes = {
  editAccountContact: PropTypes.object,
  errors: PropTypes.object,
  show: PropTypes.bool,
  accountId: PropTypes.string,

  loadAccountContacts: PropTypes.func,
  close: PropTypes.func,
  clearErrors: PropTypes.func,
  delayedUpdateAccountContact: PropTypes.func,
  deleteAccountContact: PropTypes.func,
  displayFailureMessage: PropTypes.func,
  saveAccountContact: PropTypes.func,
}

export default EditAccountContactModal
