import {
  SET_ACCOUNTS_RECEIVABLE,
  CLEAR_ACCOUNTS_RECEIVABLE,
} from '@actions/accountsReceivable'

const initialState = {
  accountsReceivable: [],
}

export function accountsReceivable(state = initialState, action) {
  if (action.requestTime < state.requestTime) {
    return state
  }
  switch (action.type) {
    case SET_ACCOUNTS_RECEIVABLE: {
      return {
        ...state,
        accountsReceivable: action.accountsReceivable,
        requestTime: action.requestTime,
      }
    }
    case CLEAR_ACCOUNTS_RECEIVABLE: {
      return {
        ...state,
        accountsReceivable: [],
      }
    }
    default:
      return state
  }
}
