import {
  APPEND_ORDERS,
  CLEAR_EDIT_ORDER,
  CLEAR_NEW_ORDER,
  CLEAR_ORDER,
  CLEAR_ORDERS,
  REMOVE_ORDER_FROM_ORDERS,
  SET_CATERING_CAPTAINS,
  SET_EDIT_ORDER,
  SET_NEW_ORDER,
  SET_ORDER,
  SET_ORDERS,
  SET_ORDER_ITEMS,
  SET_ORDER_STATUS,
  SET_SERVICE_DEPOTS,
  UPDATE_EDIT_ORDER,
  UPDATE_NEW_ORDER,
} from '@actions/order'
import { deepCopy } from '~/utils'

const initialCaterinCaptainsState = []
export const initialNewOrderState = {
  id: undefined,
  account: undefined,
  accountExecutive: undefined,
  accountSettings: null,
  adjustedChefPayouts: [],
  user: undefined,
  dropoffAddress: undefined,
  billingAddress: undefined,

  invoiceContact: undefined,
  receiptContact: undefined,
  cateringContact: undefined,

  paymentMethod: {},

  chargeDate: undefined,
  chefPayouts: undefined,
  clientArrivalTime: undefined,
  clientSetUpTime: undefined,
  clientDoNotArriveBeforeTime: undefined,
  deliveryInstructions: undefined,
  headCount: undefined,
  hqArrivalTime: undefined,
  hqDepartureTime: undefined,
  kitchenArrivalTime: undefined,
  kitchenDepartureTime: undefined,
  loadChefs: undefined,
  mealType: undefined,
  orderServiceCost: {},
  orderType: 'Lunch',
  serviceType: 'Regular',
  setUpStyle: 'Standard Buffet',
  purchaseOrderNumber: undefined,
  setUpInstructions: undefined,
  conceptName: undefined,
  primaryChef: undefined,
  chefs: [], // rendered list of all add on chefs
  conceptId: undefined,
  discount: {},
  discounts: [],
  hasNetPaymentSettings: false,
  isEvent: false,
  carbonNeutral: false,
  needsCleanup: false,
  needsStaffing: false,
  isTaxExempt: false,
  orderTaxExemptCause: '',
  serviceNotes: undefined,
  supplyBreakdown: undefined,
  status: '',
  predictedServiceCosts: 0,
  chefAmount: 0,
  deliveryFee: 0,
  cleanupFee: 0,
  staffingFee: 0,
  numberOfStaff: 0,
  staffingHours: 0,
  serviceFee: 0,
  tip: 0,
  tax: 0,
  subtotal: 0,
  carbonNeutralContribution: 0,
  total: 0,
  tipLocked: false,
  taxWarningSeen: false,
  taxRates: [],
  deliveryTaxRates: [],
  referredBy: {},
  servicesTaxRates: [],
  alcoholTaxRates: [],
  originalMenuItemIds: [],
  originalServiceItemIds: [],
  originalVirtualItemIds: [],
  originalVirtualKitIds: [],
  originalSnackPackIds: [],
  deliveryFeePercent: null,
  deliveryFeeLimit: null,

  newAccount: {},
  newCateringContact: {},
  newContact: {},
  newDropoffAddress: {},
  newBillingAddress: {},
  newInvoiceContact: {},
  newPaymentMethod: {},
  newReceiptContact: {},

  referralPartners: [],
  numberOfEntrees: 0,
  recommendedPortion: 0,
  selectedConceptId: undefined,
  selectedConcept: undefined,

  didModify: false,
  chefStatuses: {},
  creatorId: '',
}
const initialOrderState = null
const initialOrdersState = []
const initialOrderItemsState = []
const initialServiceDepotsState = []

export function order(state = initialOrderState, action) {
  const { type, order } = action

  switch (type) {
    case CLEAR_ORDER:
      return initialOrderState
    case SET_ORDER:
      return { ...order }
    default:
      return state
  }
}

export function orders(state = initialOrdersState, action) {
  const { type, orders } = action

  switch (type) {
    case APPEND_ORDERS:
      return [...state, ...orders]
    case CLEAR_ORDERS:
      return initialOrdersState.slice()
    case SET_ORDERS:
      return orders.slice()
    case REMOVE_ORDER_FROM_ORDERS: {
      const newOrders = state.slice()
      const idx = newOrders.findIndex((o) => o.id === action.orderId)
      if (idx !== -1) {
        newOrders.splice(idx, 1)
      }

      return newOrders
    }
    default:
      return state
  }
}

export function orderItems(state = initialOrderItemsState, action) {
  const { type, orderItems } = action

  switch (type) {
    case SET_ORDER_ITEMS:
      return orderItems.slice()
    default:
      return state
  }
  // TODO: move to service
  // if ( action.type == actions.SET_ORDER_ITEM ) {
  //   const orderInfo = {}
  //   orderInfo[action.orderItemKey] = {
  //     quantity: action.quantity,
  //     chefPrice: action.chefPrice,
  //     price: action.price,
  //   }
  //
  //   return Object.assign({}, state, orderInfo )
  // }
  // else if ( action.type == actions.PARSE_RAW_ORDER_DATA ) {
  //   const orderItems = action.rawOrderData.split( '\n' )
  //   const formattedOrderItems = orderItems.map(( orderItem, index ) => {
  //     let itemDesc, itemPrice, itemPackaging, itemQuantity
  //     //Split the order item by tabs
  //     const itemParts = orderItem.match( /[\t]*([A-Za-z0-9$& \(\)\,\.\-\!]+)[\t]*/g )
  //     if ( itemParts.length > 3 ) {
  //       // Throw some kind of error (with index?)
  //     }
  //     // else if ( itemParts.length < 3 ){
  //     //   continue
  //     // }
  //     itemParts.forEach( string => {
  //       const part = string.trim()
  //       if ( part.charAt( 0 ).match( /[0-9]/ )) {
  //         itemPackaging = part
  //         itemQuantity = part.match( /[0-9]*/ )[0]
  //       }
  //       else if ( part.charAt( 0 ).match( /[A-Za-z\(\-]/ )) {
  //         //check if it might be a string meaning it's free...
  //         if ( part.match( /^included$/i ) || part.match( /^wa[i]*ved$/i ) || part.match( /^free$/i ) || part.match( /^compl[ie]mentary$/i )
  //           || part.match( /^[-]*$/i ) || part.match( /^n\/a$/i )) {
  //           itemPrice = 0.00
  //         }
  //         else {
  //           itemDesc = part
  //         }
  //       }
  //       else if ( part.charAt( 0 ).match( /\$/ )) {
  //         itemPrice = part.replace( '$', '' ).replace( ',', '' )
  //       }
  //     })
  //     itemPrice = itemPrice / itemQuantity
  //     const chefPrice = itemPrice * 0.60
  //
  //     return { name: itemDesc, price: itemPrice, chef_price: chefPrice, quantity: itemQuantity, packaging: itemPackaging, itemIndex: index }
  //   })
  //
  //   return Object.assign({}, state, { formattedOrderItems })
  // }
  // else if ( action.type == actions.CLEAR_PARSED_ORDER_ITEMS ) {
  //   return Object.assign({}, state, { formattedOrderItems: null })
  // }
  //
  // return state
}

export function orderStatus(state = null, action) {
  const { type, orderStatus } = action

  switch (type) {
    case SET_ORDER_STATUS:
      return orderStatus
    default:
      return state
  }
}

export function cateringCaptains(state = initialCaterinCaptainsState, action) {
  const { type, cateringCaptains } = action

  switch (type) {
    case SET_CATERING_CAPTAINS:
      return cateringCaptains.slice()
    default:
      return state
  }
}

export function editOrder(
  state = { ...deepCopy(initialNewOrderState) },
  action,
) {
  const { type, order } = action

  switch (type) {
    case SET_EDIT_ORDER:
      return { ...order }
    case UPDATE_EDIT_ORDER:
      return { ...state, ...order }
    case CLEAR_EDIT_ORDER:
      return { ...deepCopy(initialNewOrderState) }
    default:
      return state
  }
}

export function newOrder(
  state = { ...deepCopy(initialNewOrderState) },
  action,
) {
  const { type, order } = action

  switch (type) {
    case SET_NEW_ORDER:
      return { ...order }
    case UPDATE_NEW_ORDER:
      return { ...state, ...order }
    case CLEAR_NEW_ORDER:
      return { ...deepCopy(initialNewOrderState) }
    default:
      return state
  }
}

export function serviceDepots(state = initialServiceDepotsState, action) {
  const { type, serviceDepots } = action

  switch (type) {
    case SET_SERVICE_DEPOTS:
      return serviceDepots.slice()
    default:
      return state
  }
}
