import { Moment } from 'moment'

export type GOScheduleMenu = {
  id: string
  allMenuItems: SchedulePlannerMenuItem[]
  avgPrice: number
  avgMenuMargin: number
  avgMenuItemRatingByClient: string
  chef: Chef
  cuisineTags: string[]
  cutoffTime: string
  clientName: string
  distance: string | number
  geoCoordinates: Record<number, number[]>
  groupOrderCutoffDays: number
  groupOrderCutoffTime: number
  lastOrdered: string
  margin: number
  marketType: string
  menuMaxItems: number
  name: string
  pickupTime: string
  readyToHeat: boolean
  vendorMaxGOItems: number
}

type Chef = {
  name: string
}

export type SchedulePlannerMenuItem = {
  name: string
}

export type FilterConfig = {
  default: string
  information?: string
  disabledKey?: string
  name: string
  key: string
  label: string
  needsInfo?: boolean
  presenter?: () => void
  loaderFunction?: () => void
  placeHolder: string
  options: any[]
}

export type Cuisine = {
  id: string
  name: string
}

export type Account = {
  id: string
  name: string
  pin: number
  addresses: Address[]
  contacts: Contact[]
}

export type Address = {
  id: string
  city: string
  line1: string
  line2: string
  latitude: string
  longitude: string
  state: string
  zip: string
}

export type Contact = {
  id: string
  firstName: string
  lastName: string
  name: string
  email: string
  phoneNumber: string
}

export type Option = {
  value: string
  label: string
}

export type FilterOption = string | Moment | boolean | number | string[] | null

export enum AverageMenuPrice {
  LESS_THAN_TEN = 'lt_10',
  GREATER_THAN_10 = 'gt_10',
  TEN_TO_FIFTEEN = 'TEN_TO_FIFTEEN',
}

export enum AverageMenuMargin {
  LESS_THAN_75 = 'lt_75',
  GREATER_THAN_50 = 'gt_50',
}

export enum VendorMaxGroupOrderItems {
  GREATER_THAN_100 = 'gt_100',
  LESS_THAN_100 = 'lt_100',
}

export enum VendorMenucardCap {
  GREATER_THAN_100 = 'gt_100',
  LESS_THAN_100 = 'lt_100',
}

export enum Distance {
  LESS_THAN_10_MILES = 'lt_10',
  LESS_THAN_20_MILES = 'lt_20',
}

export enum LastScheduled {
  LESS_THAN_1_WEEK = 'lt_1',
  LESS_THAN_2_WEEKS = 'lt_2',
  LESS_THAN_4_WEEKS = 'lt_4',
}
