import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import { statuses } from './constants'
import styled from '@emotion/styled'
import { colors } from '../../../constants'

import { AutocompleteInput } from '@containers/common/form'
import {
  Button,
  Checkbox,
  DateTextInput,
  Dropdown,
  Input,
  TextArea,
} from '@components/common/form'
import Modal from '@components/common/modal/Modal'
import Table from '@components/common/Table'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import DividerLine from '@components/common/DividerLine'
import { ModalTitle, ModalSectionTitle } from '@res/styledComponents/index'
const DateFormat = 'MM/DD/YYYY h:mm a'

class PopUpDiscountModal extends Component {
  state = {
    discountLedger: undefined,
    clients: [],
    customers: [],
    markets: {},
    popups: [],
    searchLimit: 20,
    searchOffset: 0,
    editGroup: undefined,
    statuses: ['Created'],
  }
  saving = false

  componentWillMount() {
    const { newDiscountLedger, discountLedger } = this.props

    this.onLoadMarkets()
    const newState = {}
    if (!discountLedger) {
      newState.discountLedger = newDiscountLedger()
    } else {
      newState.discountLedger = discountLedger
      const { clientIds, customerIds, popUpIds } = discountLedger
      if (clientIds && clientIds.length > 0) {
        this.onLoadClients(clientIds)
      }
      if (customerIds && customerIds.length > 0) {
        this.onLoadCustomers(customerIds)
      }
      if (popUpIds && popUpIds.length > 0) {
        this.onLoadPopUps(popUpIds)
      }
    }
    this.setState(newState)
  }

  onChangeDiscountLedger = (key, value) => {
    const { discountLedger } = this.state
    discountLedger[key] = value
    this.setState({ discountLedger: { ...discountLedger } })
  }

  onLoadMarkets = async () => {
    const data = await this.props.loadMarkets()
    if (data) {
      const markets = {}
      data.forEach((mkt) => (markets[mkt.id] = mkt))
      this.setState({ markets })
    }
  }

  onSelectMarket = (hqId) => {
    const { discountLedger } = this.state
    if ((discountLedger.headquarters || []).includes(hqId)) {
      return
    }
    this.setState({
      discountLedger: {
        ...discountLedger,
        headquarters: [...(discountLedger.headquarters || []), hqId],
      },
    })
  }

  onRemoveMarket = (hqId) => () => {
    const { discountLedger } = this.state
    this.setState({
      discountLedger: {
        ...discountLedger,
        headquarters: discountLedger.headquarters.filter((hq) => hq !== hqId),
      },
    })
  }

  onLoadPopUps = async (popUpIds) => {
    const { searchPopUps, headquarter } = this.props
    const { searchLimit, searchOffset, statuses } = this.state
    const popups = await searchPopUps({
      headquarter,
      limit: searchLimit,
      offset: searchOffset,
      statuses,
      popUpIds,
    })
    if (popups) {
      this.setState({ popups })
    }
  }

  onSelectPopup = (popup) => {
    const { popups, discountLedger } = this.state
    if ((discountLedger.popUpIds || []).includes(popup.id)) {
      return
    }
    this.setState({
      popups: [...popups, popup],
      discountLedger: {
        ...discountLedger,
        popUpIds: [...(discountLedger.popUpIds || []), popup.id],
      },
    })
  }

  onRemovePopup = (popup) => () => {
    const { popups, discountLedger } = this.state
    this.setState({
      popups: popups.filter((c) => c.id !== popup.id),
      discountLedger: {
        ...discountLedger,
        popUpIds: discountLedger.popUpIds.filter((id) => id !== popup.id),
      },
    })
  }

  onLoadClients = async (ids) => {
    const clients = await this.props.getDetailAccountsByIds(ids)
    if (clients) {
      this.setState({ clients })
    }
  }

  onSelectClient = (client) => {
    const { clients, discountLedger } = this.state
    if ((discountLedger.clientIds || []).includes(client.id)) {
      return
    }
    this.setState({
      clients: [...clients, client],
      discountLedger: {
        ...discountLedger,
        clientIds: [...(discountLedger.clientIds || []), client.id],
      },
    })
  }

  onRemoveClient = (client) => () => {
    const { clients, discountLedger } = this.state
    this.setState({
      clients: clients.filter((c) => c.id !== client.id),
      discountLedger: {
        ...discountLedger,
        clientIds: discountLedger.clientIds.filter((id) => id !== client.id),
      },
    })
  }

  onLoadCustomers = async (ids) => {
    const { searchPopUpCustomers } = this.props
    const customers = await searchPopUpCustomers({ ids })
    if (customers) {
      this.setState({ customers })
    }
  }

  onSelectCustomer = (customer) => {
    const { customers, discountLedger } = this.state
    if ((discountLedger.customerIds || []).includes(customer.id)) {
      return
    }
    this.setState({
      customers: [...customers, customer],
      discountLedger: {
        ...discountLedger,
        customerIds: [...(discountLedger.customerIds || []), customer.id],
      },
    })
  }

  onRemoveCustomer = (customer) => () => {
    const { customers, discountLedger } = this.state
    this.setState({
      customers: customers.filter((c) => c.id !== customer.id),
      discountLedger: {
        ...discountLedger,
        customerIds: discountLedger.customerIds.filter(
          (id) => id !== customer.id,
        ),
      },
    })
  }

  onSaveDiscountLedger = async () => {
    const { saveDiscountLedger, searchPopUpDiscounts, hideModal } = this.props
    const { discountLedger } = this.state
    if (this.saving) {
      return
    }
    this.saving = true
    if (await saveDiscountLedger(discountLedger)) {
      hideModal()
      searchPopUpDiscounts()
    }
    this.saving = false
  }

  render() {
    const { clients, customers, discountLedger, markets, popups } = this.state

    if (!discountLedger) {
      return
    }

    const {
      id,
      name,
      createdAt,
      description,
      enabled,
      availableForGuests,
      start,
      end,
      headquarters = [],
      totalUsage,
      totalDollarUsage,
      totalUsageLimit,
      totalDollarLimit,
      orderMinimum,
      perCustomerLimit,
      perCustomerDollarLimit,
      perOrderLimit,
      value,
      discountType,
    } = discountLedger
    const {
      headquarter,
      hideModal,
      locale,
      theme,
      searchPopUpClients,
      searchPopUpCustomers,
      searchPopUps,
    } = this.props
    const title = id ? 'Edit Discount' : 'New Discount'

    return (
      <Modal width="650px" title={title} hideModal={hideModal} color="#001940">
        {id && (
          <div>
            <ModalTitle>
              Created on {Moment(createdAt).format('LLL')}
            </ModalTitle>
            <YSpacing height="20px" />
          </div>
        )}
        <Input
          label="Discount name"
          value={name}
          onChange={(e) => this.onChangeDiscountLedger('name', e.target.value)}
        />
        <TextArea
          value={description}
          label="Description"
          onChange={(e) =>
            this.onChangeDiscountLedger('description', e.target.value)
          }
        />
        <FlexContainer justifyContent="flex-start" width="100%">
          <Checkbox
            label="Enabled?"
            value={enabled}
            checked={enabled}
            onChange={(e) =>
              this.onChangeDiscountLedger('enabled', e.target.checked)
            }
          />
          <XSpacing width="20px" />
          <Checkbox
            label="Available for guests?"
            value={availableForGuests}
            checked={availableForGuests}
            onChange={(e) =>
              this.onChangeDiscountLedger(
                'availableForGuests',
                e.target.checked,
              )
            }
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <Dropdown
            label="Discount Type"
            width="48%"
            value={discountType}
            onChange={(e) => {
              const type = e.target.value
              this.onChangeDiscountLedger('discountType', type)
              if (type === 'Free') {
                this.onChangeDiscountLedger('value', undefined)
              }
            }}
          >
            {['Fixed', 'Percent', 'Free'].map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Dropdown>
          {discountType !== 'Free' && (
            <Input
              width="48%"
              label={discountType === 'Fixed' ? 'value ($)' : 'value (%)'}
              value={value}
              onChange={(e) =>
                this.onChangeDiscountLedger(
                  'value',
                  Math.trunc(parseFloat(e.target.value)),
                )
              }
            />
          )}
        </FlexContainer>
        <Hint>
          *If discount start and end dates aren’t selected the discount will
          always be available.
        </Hint>
        <FlexContainer justifyContent="space-between">
          <DateTextInput
            width="48%"
            label="Discount Start Date"
            date={start}
            onChange={(date) => this.onChangeDiscountLedger('start', date)}
            onEmpty={() => this.onChangeDiscountLedger('start', undefined)}
            dateFormat={DateFormat}
            timeZone={locale}
          />
          <DateTextInput
            width="48%"
            label="Discount End Date"
            date={end}
            onChange={(date) => this.onChangeDiscountLedger('end', date)}
            onEmpty={() => this.onChangeDiscountLedger('end', undefined)}
            dateFormat={DateFormat}
            timeZone={locale}
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <DividerLine margin="5px 0 20px 0" />
        <ModalSectionTitle>Discount Limits</ModalSectionTitle>
        <Table headings={['Limit Type', 'Limit', 'Total Usage']}>
          <tr>
            <td>Total Usage Limit (For all pop-ups)</td>
            <td>
              <Input
                label=""
                marginBottom="0"
                width="100px"
                value={totalUsageLimit}
                onChange={(e) => {
                  const value = parseFloat(e.target.value)
                  const limit = isNaN(value) ? undefined : Math.trunc(value)
                  this.onChangeDiscountLedger('totalUsageLimit', limit)
                }}
              />
            </td>
            <td>{totalUsage ? totalUsage : 'N/A'}</td>
          </tr>
          <tr>
            <td>Total $ Limit</td>
            <td>
              <Input
                label=""
                marginBottom="0"
                width="100px"
                value={totalDollarLimit}
                onChange={(e) => {
                  let value = parseFloat(e.target.value)
                  value = isNaN(value) ? undefined : value
                  this.onChangeDiscountLedger('totalDollarLimit', value)
                }}
              />
            </td>
            <td>{totalDollarUsage ? `$${totalDollarUsage}` : 'N/A'}</td>
          </tr>
          <tr>
            <td>Per Customer $ Limit</td>
            <td>
              <Input
                label=""
                marginBottom="0"
                width="100px"
                value={perCustomerDollarLimit}
                onChange={(e) => {
                  let value = parseFloat(e.target.value)
                  value = isNaN(value) ? undefined : value
                  this.onChangeDiscountLedger('perCustomerDollarLimit', value)
                }}
              />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Per Customer Usage Limit</td>
            <td>
              <Input
                label=""
                marginBottom="0"
                width="100px"
                value={perCustomerLimit}
                onChange={(e) => {
                  const value = parseFloat(e.target.value)
                  const limit = isNaN(value) ? undefined : Math.trunc(value)
                  this.onChangeDiscountLedger('perCustomerLimit', limit)
                }}
              />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>
              Per Order Limit (# of times a customer can use this coupon for the
              same pop-up order)
            </td>
            <td>
              <Input
                label=""
                marginBottom="0"
                width="100px"
                value={perOrderLimit}
                onChange={(e) => {
                  const value = parseFloat(e.target.value)
                  const limit = isNaN(value) ? undefined : Math.trunc(value)
                  this.onChangeDiscountLedger('perOrderLimit', limit)
                }}
              />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Min Order Subtotal ($)</td>
            <td>
              <Input
                label=""
                marginBottom="0"
                width="100px"
                value={orderMinimum}
                onChange={(e) => {
                  let value = parseFloat(e.target.value)
                  value = isNaN(value) ? undefined : value
                  this.onChangeDiscountLedger('orderMinimum', value)
                }}
              />
            </td>
            <td>N/A</td>
          </tr>
        </Table>
        <DividerLine margin="5px 0 20px 0" />
        <ModalSectionTitle>Discount Availability</ModalSectionTitle>
        <Table headings={['Category', 'Add Availability', 'Availability']}>
          <tr>
            <td>Markets</td>
            <td>
              <Dropdown
                label=""
                marginBottom="0"
                width="200px"
                onChange={(e) =>
                  this.onSelectMarket(Math.trunc(parseFloat(e.target.value)))
                }
              >
                <option />
                {Object.values(markets).map((market) => (
                  <option key={market.id} value={market.id}>
                    {market.name}
                  </option>
                ))}
              </Dropdown>
            </td>
            <td>
              {headquarters.length > 0 ? (
                headquarters.map((hq, idx) => (
                  <p
                    key={idx}
                    onClick={this.onRemoveMarket(hq)}
                    style={{ cursor: 'pointer' }}
                  >
                    <Check>✔</Check> {markets[hq] && markets[hq].name}
                  </p>
                ))
              ) : (
                <p>
                  <Check>✔</Check> Available in all markets
                </p>
              )}
            </td>
          </tr>
          <tr>
            <td>Clients</td>
            <td>
              <AutocompleteInput
                width="200px"
                displayAttribute="name"
                loaderFunction={(search) =>
                  searchPopUpClients({ ...search, headquarter })
                }
                placeholder={'Search Clients'}
                onSelect={this.onSelectClient}
              />
            </td>
            <td>
              {clients.length > 0 ? (
                clients.map((client, idx) => (
                  <p
                    key={idx}
                    onClick={this.onRemoveClient(client)}
                    style={{ cursor: 'pointer' }}
                  >
                    <Check>✔</Check> {client.name}
                  </p>
                ))
              ) : (
                <p>
                  <Check>✔</Check>Available to all clients
                </p>
              )}
            </td>
          </tr>
          {!availableForGuests && (
            <tr>
              <td>Customers</td>
              <td>
                <AutocompleteInput
                  width="200px"
                  displayAttribute="email"
                  loaderFunction={(search) =>
                    searchPopUpCustomers({ ...search, headquarter })
                  }
                  placeholder={'Search Customers'}
                  onSelect={this.onSelectCustomer}
                />
              </td>
              <td>
                {customers.length > 0 ? (
                  customers.map((cust, idx) => (
                    <p
                      key={idx}
                      onClick={this.onRemoveCustomer(cust)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Check>✔</Check> {cust.email}
                    </p>
                  ))
                ) : (
                  <p>
                    <Check>✔</Check> Available to all customers
                  </p>
                )}
              </td>
            </tr>
          )}
          <tr>
            <td>Popups</td>
            <td>
              <AutocompleteInput
                width="200px"
                displayAttribute="name"
                loaderFunction={(search) =>
                  searchPopUps({
                    search,
                    headquarter,
                    limit: this.state.searchLimit,
                    offset: this.state.searchOffset,
                    statuses,
                  })
                }
                placeholder={'Search Popups'}
                onSelect={this.onSelectPopup}
              />
            </td>
            <td>
              {popups.length > 0 ? (
                popups.map((pop, idx) => (
                  <p
                    key={idx}
                    onClick={this.onRemovePopup(pop)}
                    style={{ cursor: 'pointer' }}
                  >
                    <Check>✔</Check> {pop.name + ' / ' + pop.date}
                  </p>
                ))
              ) : (
                <p>
                  <Check>✔</Check> Available for all pop-ups
                </p>
              )}
            </td>
          </tr>
        </Table>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="flex-end">
          <Button
            label="Save Discount"
            onClick={this.onSaveDiscountLedger}
            style={{ backgroundColor: theme.primaryColor }}
          />
        </FlexContainer>
      </Modal>
    )
  }
}

const Check = styled.span`
  font-family: 'bold';
  color: ${colors.blue300};
  font-size: 18px;
`

const Hint = styled.p`
  font-family: 'regular';
  margin-bottom: 5px;
`

PopUpDiscountModal.propTypes = {
  headquarter: PropTypes.number,
  locale: PropTypes.string,
  discountLedger: PropTypes.object,
  theme: PropTypes.object,

  getDetailAccountsByIds: PropTypes.func,
  hideModal: PropTypes.func,
  loadMarkets: PropTypes.func,
  newDiscountLedger: PropTypes.func,
  saveDiscountLedger: PropTypes.func,
  searchPopUpClients: PropTypes.func,
  searchPopUpCustomers: PropTypes.func,
  searchPopUpDiscounts: PropTypes.func,
  showConfirmationModal: PropTypes.func,
  searchPopUps: PropTypes.func,
}

export default PopUpDiscountModal
