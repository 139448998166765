import { camelCaseify, formAdd } from '~/utils'

export const pResponseVirtualItems = (json) => {
  const virtualItems = json.map((j) => {
    const res = camelCaseify(j)
    res.virtualItemId = res.id
    res.cost = parseFloat(res.cost)
    res.price = parseFloat(res.price)
    res.itemType = 'VirtualItem'

    return res
  })

  return virtualItems
}

export const pResponseVirtualItem = (json) => {
  const virtualItem = camelCaseify(json)

  return virtualItem
}

export const pRequestUpdateVirtualItem = (data) => {
  const req = {}

  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'chefId', 'chef_id')
  formAdd(data, req, 'name', 'name')
  formAdd(data, req, 'isEnabled', 'is_enabled')
  formAdd(data, req, 'isApproved', 'is_approved')
  formAdd(data, req, 'description', 'description')
  formAdd(data, req, 'price', 'price')
  formAdd(data, req, 'cost', 'cost')

  return { virtual_item: req }
}

export const pDefaultVirtualFields = () => {
  return {
    name: '',
    description: '',
    marketPrice: 0.0,
    price: 0.0,
    quantity: 0,
  }
}
