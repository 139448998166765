import { connect } from 'react-redux'

import { ClientTable } from '@components/common'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'

const mapStateToProps = (state: any) => {
  const { accounts } = state

  return {
    accounts,
  }
}

const mapDispatchToProps = () => {
  const { AccountService, RestService, MarketService } = services
  const { LoadAccounts, LoadMarkets } = coordinators
  const { pResponseHubspotAccounts, pResponseHeadquarters } = presenters.Api

  const loadAccounts = LoadAccounts({
    AccountService,
    RestService,
    pResponseAccounts: pResponseHubspotAccounts,
  })

  const loadHeadquarters = LoadMarkets({
    MarketService,
    RestService,
    pResponseHeadquarters,
  })

  return {
    loadAccounts,
    loadHeadquarters,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientTable)
