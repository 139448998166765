import { connect } from 'react-redux'

import { DateMenus } from '@components/groupOrders'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state: any) => {
  const { dateMenus, changedDateMenuMap, autoStagedMenus } = state.groupOrder
  const { lastLoggedInHq, locale } = state.user
  const currentHq = state.headquarter.headquarters.find(
    (h: any) => h.id === lastLoggedInHq,
  )
  const groupOrderMinimumItems = currentHq
    ? currentHq.groupOrderMinimumItems
    : null

  return {
    locale,
    headquarter: lastLoggedInHq,
    dateMenus,
    changedDateMenuMap,
    autoStagedMenus,
    groupOrderMinimumItems,
  }
}

const mapDispatchToProps = () => {
  const {
    pResponseGroupOrderConcept,
    pResponseGroupOrderConcepts,
    pRequestSaveDateMenu,
  } = presenters.Api
  const { RestService, UIService, GroupOrdersService } = services
  const {
    DeleteDateMenus,
    GetGroupOrderBySettingsDate,
    GetGroupOrderConcept,
    SaveDateMenu,
    SearchGroupOrderConcepts,
    LoadMenuItemRatings,
    ValidatePickupTimes,
  } = coordinators

  const {
    newDateMenu,
    setChangedDateMenuMap,
    updateDateMenus,
    setAutoStagedMenus,
  } = GroupOrdersService
  const deleteDateMenus = DeleteDateMenus({
    RestService,
    UIService,
    HandleError,
  })
  const saveDateMenu = SaveDateMenu({
    RestService,
    UIService,
    HandleError,
    pRequestSaveDateMenu,
  })
  const getGroupOrderBySettingsDate = GetGroupOrderBySettingsDate({
    RestService,
  })
  const getGroupOrderConcept = GetGroupOrderConcept({
    RestService,
    pResponseGroupOrderConcept,
    HandleError,
  })
  const searchGroupOrderConcepts = SearchGroupOrderConcepts({
    RestService,
    pResponseGroupOrderConcepts,
    HandleError,
  })
  const loadMenuItemRatings = LoadMenuItemRatings({ RestService })
  const validateChefPickupTimes = ValidatePickupTimes({
    RestService,
    UIService,
    HandleError,
  })
  const {
    displayFailureMessage,
    displayWarningMessage,
    displaySuccessMessage,
  } = UIService.FlashMessage
  const { show: showConfirmationModal } = UIService.ConfirmationModal

  return {
    deleteDateMenus,
    getGroupOrderBySettingsDate,
    getGroupOrderConcept,
    newDateMenu,
    setAutoStagedMenus,
    setChangedDateMenuMap,
    updateDateMenus,
    saveDateMenu,
    showConfirmationModal,
    searchGroupOrderConcepts,
    displayFailureMessage,
    loadMenuItemRatings,
    displaySuccessMessage,
    displayWarningMessage,
    validateChefPickupTimes,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DateMenus)
