import { connect } from 'react-redux'

import Calendar from '@components/proposal/Calendar'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'

const mapDispatchToProps = () => {
  const { ProposalService, RestService } = services
  const { pResponseProposal } = presenters.Api
  const { SelectProposal } = coordinators

  const selectProposal = SelectProposal({
    ProposalService,
    RestService,
    pResponseProposal,
  })

  return {
    selectProposal,
  }
}

export default connect(null, mapDispatchToProps)(Calendar)
