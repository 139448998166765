import { connect } from 'react-redux'

import { AwaitingPayment } from '@components/order/orderSections/panel'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'
import { config } from 'hungry-core2'

const mapStateToProps = (state) => {
  const { on } = state.orderPage
  const { order, router } = state

  const port = router.location.port === 3000 ? 3001 : 3000
  const url =
    config.marketplace_url +
    (config.marketplace_url === 'http://localhost' ? `:${port}` : '')
  const apiUrl = `${config.api_host}/api/admin/`

  const invoiceUrlPrefix = `${url}/invoices/`
  const invoicePdfUrlPrefix = `${apiUrl}orders/`
  const invoicePdfUrlPath = '/invoice_pdf'

  return {
    invoiceButtonEnabled: on,
    invoicePdfUrlPrefix,
    invoicePdfUrlPath,
    invoiceUrlPrefix,
    order,
  }
}

const mapDispatchToProps = () => {
  const { OrderService, RestService, UIService } = services
  const { EmailInvoice } = coordinators
  const { pResponseOrder } = presenters.Api
  const emailInvoice = EmailInvoice({
    OrderService,
    pResponseOrder,
    RestService,
    UIService,
    HandleError,
  })

  return {
    emailInvoice,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AwaitingPayment)
