import React, { Component } from 'react'

import SubscriberPage from '@containers/subscription/SubscriberPage'
import SubscriptionPage from '@containers/subscription/SubscriptionPage'

class SubscriptionWrapperPage extends Component {
  state = {
    isSubscriberView: true,
  }

  render() {
    const { isSubscriberView } = this.state

    return (
      <div className="children-container__inner">
        <div>
          <div className="flex justify-end mt-6">
            <a
              className={`mr-4 ${isSubscriberView ? 'font-bold' : ''}`}
              onClick={() => this.setState({ isSubscriberView: true })}
            >
              Subscribers
            </a>
            <a
              className={isSubscriberView ? '' : 'font-bold'}
              onClick={() => this.setState({ isSubscriberView: false })}
            >
              Subscriptions
            </a>
          </div>
          {isSubscriberView ? <SubscriberPage /> : <SubscriptionPage />}
        </div>
      </div>
    )
  }
}

export default SubscriptionWrapperPage
