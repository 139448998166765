import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

class VirtualItemSelectInput extends Component {
  constructor(props) {
    super(props)
    const { mainArray, virtualItems, selectedVirtualItem } = props

    this.state = {
      mainArray,
      virtualItems,
      selectedVirtualItem,
      searchable: true,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { mainArray, virtualItems, selectedVirtualItem } = nextProps
    this.setState({
      mainArray,
      virtualItems,
      selectedVirtualItem,
    })
  }

  renderOption = (option) => {
    if (!option) {
      return null
    }
    const { name, description, virtualItemType } = option

    return (
      <div>
        <h4>{name}</h4>
        <p style={{ 'font-size': '80%', color: '#999999' }}>
          {description}
          <br />
          <b>Virtual Item Type: </b>
          {virtualItemType}
          <br />
        </p>
      </div>
    )
  }

  renderValue = (option) => {
    if (!option) {
      return option
    }
    const { name, virtualItemType } = option
    const virtualItemTypeString = virtualItemType ? ' - ' + virtualItemType : ''

    return (
      <div style={{ color: '#999999' }}>
        <h3>
          <b>{name}</b>
          {virtualItemTypeString}
        </h3>
      </div>
    )
  }

  onChange = (value) => {
    if (value) {
      this.setState({ selectedVirtualItem: value })

      if (this.props.onChange) {
        this.props.onChange(value)
      }
    }
  }

  updateArray = (value) => {
    if (this.props.updateArray) {
      this.props.updateArray(value)
    }
  }

  setDirection = (i, direction) => {
    const { mainArray } = this.state
    const newArray = [...mainArray]
    const target = newArray[i]

    let swapIdx = i + (direction === 'up' ? -1 : 1)
    if (swapIdx < 0) {
      swapIdx = mainArray.length - 1
    }
    if (swapIdx >= mainArray.length) {
      swapIdx = 0
    }

    const swapPosition = newArray[swapIdx].position
    newArray[swapIdx].position = target.position
    target.position = swapPosition

    this.setState({ mainArray: newArray })
    this.updateArray(newArray)

    return newArray
  }

  render() {
    const index = this.props.index !== undefined ? `${this.props.index}` : ''
    const { virtualItems, selectedVirtualItem } = this.state

    return (
      <div className={'section sortable-table'}>
        <div className="sortable-arrows">
          <div
            className="arrow"
            onClick={() => this.setDirection(Number(index), 'up')}
          >
            ▲
          </div>
          <div
            className="arrow"
            onClick={() => this.setDirection(Number(index), 'down')}
          >
            ▼
          </div>
        </div>

        <div className="sortable-table__inner">
          <Select
            options={virtualItems}
            optionRenderer={this.renderOption}
            value={selectedVirtualItem}
            valueRenderer={this.renderValue}
            labelKey="name"
            clearable={true}
            resetValue={{ id: null, menuItem: {} }}
            onChange={this.onChange}
            placeholder={'Select Virtual Item'}
          />

          {selectedVirtualItem && (
            <div>
              {selectedVirtualItem.mealType && (
                <div className="sortable-details">
                  <p>
                    Meal Type:
                    <span className="badge badge-meal-type">
                      {selectedVirtualItem.mealType}
                    </span>
                  </p>
                </div>
              )}
              {selectedVirtualItem.virtualItemType && (
                <div className="sortable-details">
                  <p>
                    Virtual Item Type:
                    <span className="badge badge-meal-type">
                      {selectedVirtualItem.virtualItemType}
                    </span>
                  </p>
                </div>
              )}
              {selectedVirtualItem.description && (
                <div className="sortable-details">
                  <p>Description: {selectedVirtualItem.description}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

VirtualItemSelectInput.defaultProps = {
  mainArray: [],
  virtualItems: [],
  selectedVirtualItem: {},
  searchable: true,
}

VirtualItemSelectInput.propTypes = {
  virtualItems: PropTypes.arrayOf(PropTypes.object),
  selectedVirtualItem: PropTypes.object,
  mainArray: PropTypes.arrayOf(PropTypes.object),
  index: PropTypes.number,

  onChange: PropTypes.func,
  updateArray: PropTypes.func,
}

export default VirtualItemSelectInput
