import { connect } from 'react-redux'

import { GroupOrderMenuCutoffSettings } from '@components/chef/edit'

const mapStateToProps = (state: any) => {
  const { user } = state
  const { locale } = user

  return {
    locale,
  }
}

export default connect(mapStateToProps)(GroupOrderMenuCutoffSettings)
