import {
  SET_DATE_MENUS,
  UPDATE_DATE_MENUS,
  SET_CHANGED_DATE_MENU_MAP,
  CLEAR_CHANGED_DATE_MENU_MAP,
  SET_AUTO_STAGED_MENUS,
} from '@actions/groupOrder'

const initialGroupOrderState = {
  dateMenus: [],
  changedDateMenuMap: {},
  autoStagedMenus: [],
}
//changedDateMenuMap ex:
// { [dateMenuId1]: {}, [dateMenuId2]: {[menuId1]: {_destroy: true, _new: true}, [menuId2]: { _destroy: false }} }
// will use dateMenu.dateStr as a standin for id on new, unsaved dateMenus

//autoStagedMenus are just {[dateMenuId]: [chefId]}

export function groupOrder(state = initialGroupOrderState, action) {
  const { type, dateMenus, changedDateMenuMap, autoStagedMenus } = action

  switch (type) {
    case SET_DATE_MENUS:
      return { ...state, dateMenus: dateMenus }
    case UPDATE_DATE_MENUS:
      return {
        ...state,
        dateMenus: dateMenus,
        changedDateMenuMap: changedDateMenuMap,
      }
    case SET_CHANGED_DATE_MENU_MAP:
      return { ...state, changedDateMenuMap: changedDateMenuMap }
    case CLEAR_CHANGED_DATE_MENU_MAP:
      return { ...state, changedDateMenuMap: {} }
    case SET_AUTO_STAGED_MENUS:
      return { ...state, autoStagedMenus: autoStagedMenus }
    default:
      return state
  }
}
