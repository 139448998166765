import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { OrderableMaster } from '@components/orderable'

export class ProposalMaster extends Component {
  componentDidMount() {
    const pathBreakdown =
      this.props.location.pathname.match(/\/proposals\/(.*)/)
    if (pathBreakdown && pathBreakdown.length > 1) {
      this.props.selectProposal(pathBreakdown[1])
    }
  }

  render() {
    const {
      clearProposals,
      filter,
      loadProposals,
      proposals,
      selectProposal,
      loadAccountExecutives,
      setLastLoadProposalsParams,
    } = this.props

    return (
      <OrderableMaster
        filter={filter}
        orderables={proposals}
        clearOrderables={clearProposals}
        loadOrderables={loadProposals}
        selectOrderable={selectProposal}
        loadAccountExecutives={loadAccountExecutives}
        setLastLoadOrderablesParams={setLastLoadProposalsParams}
      />
    )
  }
}

ProposalMaster.propTypes = {
  proposals: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.string,

  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),

  clearProposals: PropTypes.func,
  loadProposals: PropTypes.func,
  selectProposal: PropTypes.func,
  loadAccountExecutives: PropTypes.func,
  setLastLoadProposalsParams: PropTypes.func,
}

export default ProposalMaster
