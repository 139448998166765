import { DinerProfile } from '@types'

export const SET_DINER_PROFILES = 'SET_DINER_PROFILES'
export const SET_DINER_PROFILES_IN_PROFILES = 'SET_DINER_PROFILES_IN_PROFILES'

export const setDinerProfiles = (dinerProfiles: DinerProfile[]) => ({
  type: SET_DINER_PROFILES,
  payload: dinerProfiles,
})

export const setDinerProfilesInProfiles = (dinerProfiles: DinerProfile[]) => ({
  type: SET_DINER_PROFILES_IN_PROFILES,
  payload: dinerProfiles,
})
