import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from '@components/common/form/Input'
import ChildOrder from '@components/batchOrder/edit/sections/ChildOrder'
import SelectedChildOrder from '@components/batchOrder/edit/sections/SelectedChildOrder'
import FlexContainer from '@components/common/FlexContainer'
import Table from '@components/common/Table'

class ChildOrdersSection extends Component {
  state = {
    accountOrders: [],
    orderSearch: '',
    page: 1,
  }

  componentDidMount() {
    this.refreshOrders({ resetAccounts: false })
  }

  componentWillReceiveProps(nextProps) {
    const { account, startDate, endDate } = nextProps
    if (account !== this.props.account) {
      this.refreshOrders({ resetAccounts: true })
    }
    if (startDate !== this.props.startDate || endDate !== this.props.endDate) {
      this.refreshOrders({ resetAccounts: false })
    }
  }

  refreshOrders = async ({ resetAccounts, concatOrders }) => {
    const { loadAccountOrders, account } = this.props
    const { page, accountOrders } = this.state
    if (resetAccounts && resetAccounts) {
      this.setState({
        accountOrders: [],
      })

      return
    }
    const newOrders = await loadAccountOrders({ accountId: account.id, page })
    if (concatOrders) {
      const combinedOrders = accountOrders.concat(newOrders)

      return this.setState({ accountOrders: combinedOrders })
    }
    this.setState({ accountOrders: newOrders })
  }

  onLoadMore = async () => {
    const { page } = this.state
    this.setState({ page: page + 1 }, () => {
      this.refreshOrders({ concatOrders: true })
    })
  }

  onSearchOrder = (e) => {
    this.setState({ orderSearch: e.target.value })
  }

  renderAccountOrderGroup() {
    const {
      filterAccountOrdersByOrderNumber,
      onSelectChildOrder,
      childOrders,
      startDate,
      endDate,
    } = this.props
    const { orderSearch } = this.state
    let { accountOrders } = this.state
    if (orderSearch || startDate || endDate) {
      accountOrders = filterAccountOrdersByOrderNumber({
        accountOrders,
        searchString: orderSearch,
        startDate,
        endDate,
      })
    }
    const ordersRemoveSelected = accountOrders.filter((order) =>
      childOrders.every((childOrder) => !childOrder.id.includes(order.id)),
    )

    return ordersRemoveSelected.length ? (
      ordersRemoveSelected.map((order) => {
        return (
          <ChildOrder
            key={order.id}
            order={order}
            onSelect={onSelectChildOrder}
          />
        )
      })
    ) : (
      <div> No orders found </div>
    )
  }

  render() {
    const { onClickOrderNumber, onRemoveChildOrder } = this.props
    const { accountOrders, orderSearch } = this.state
    const childOrders = this.props.childOrders || []
    const shouldShowLoadButton =
      accountOrders && accountOrders.length % 20 === 0

    return (
      <FlexContainer justifyContent="space-between">
        <div style={{ width: '30%' }}>
          <Input
            testId="search-order"
            value={orderSearch}
            label="Search Order #"
            onChange={this.onSearchOrder}
          />
          <div className="menu-items-container">
            {this.renderAccountOrderGroup()}
            {shouldShowLoadButton && (
              <button
                className="button-neutral"
                onClick={this.onLoadMore}
                id="load-more"
              >
                {' '}
                Load More Orders{' '}
              </button>
            )}
          </div>
        </div>
        <div style={{ width: '68%' }}>
          <Table headings={['', 'Order #', 'Date', 'PoC', 'Concept', 'Total']}>
            {childOrders.map((i) => (
              <SelectedChildOrder
                key={i.id}
                order={i}
                onRemove={onRemoveChildOrder}
                onClickOrderNumber={onClickOrderNumber}
              />
            ))}
          </Table>
        </div>
      </FlexContainer>
    )
  }
}

ChildOrdersSection.propTypes = {
  account: PropTypes.object,
  childOrders: PropTypes.arrayOf(PropTypes.object),
  endDate: PropTypes.string,
  startDate: PropTypes.string,

  filterAccountOrdersByOrderNumber: PropTypes.func,
  loadAccountOrders: PropTypes.func,
  onClickOrderNumber: PropTypes.func,
  onRemoveChildOrder: PropTypes.func,
  onSelectChildOrder: PropTypes.func,
}

export default ChildOrdersSection
