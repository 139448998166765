import { connect } from 'react-redux'

import { AddressSection } from '@components/order/edit/sections'

import presenters from '@presenters'

const mapDispatchToProps = () => {
  const { pBuildAddress } = presenters

  return { pBuildAddress }
}

export default connect(undefined, mapDispatchToProps)(AddressSection)
