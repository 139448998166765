import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import { CSVLink } from 'react-csv'
import { DateInput } from '@components/common/form'

import SurveyItem from './SurveyItem'

export class SurveyList extends Component {
  state = {
    endDate: Moment().hour(0).minute(0).second(0).millisecond(0).add(1, 'd'),
    orderNumber: undefined,
    search: '',
    selectedSurvey: undefined,
    sort: undefined,
    startDate: Moment().hour(0).minute(0).second(0).millisecond(0),
    surveyNames: [],
  }

  componentDidMount() {
    ;(async () => {
      const surveyNames = await this.props.loadSurveyList()
      this.setState({ surveyNames })
    })()
  }

  componentWillReceiveProps(props) {
    const { orderNumber, selectedSurvey } = props

    if (
      orderNumber !== this.props.orderNumber ||
      selectedSurvey !== this.props.selectedSurvey
    ) {
      this.setState(
        {
          orderNumber,
          endDate: undefined,
          search: '',
          selectedSurvey,
          sort: undefined,
          startDate: undefined,
        },
        this.refreshSurveys,
      )
    }
  }

  getCSVData = () => {
    const { headers, surveys } = this.props
    const data = []
    surveys.map((survey) => {
      const row = {}
      survey.map((s, i) => {
        row[headers[i].label] = s
      })
      data.push(row)
    })

    return data
  }

  getSearchPlaceholder = (surveyName) => {
    switch (surveyName) {
      case 'captain_post':
        return 'order #, client, captain'
      case 'chef_post':
        return 'order #, client, chef'
      case 'office_post':
        return 'order #, client'
      case 'rep_post':
        return 'order #, client, sales rep'
      default:
        return 'search'
    }
  }

  refreshSurveys = () => {
    const { endDate, orderNumber, selectedSurvey, search, sort, startDate } =
      this.state

    this.props.loadSurveys({
      filters: {
        endDate,
        search,
        sort,
        startDate,
      },
      orderNumber,
      surveyName: selectedSurvey,
    })
  }

  onChangeDate = (field) => (date) => {
    this.setState(
      {
        [field]: date,
        orderNumber: undefined,
      },
      this.refreshSurveys,
    )
  }

  onChangeSearch = (e) => {
    const search = e.target.value

    this.setState(
      {
        search,
        orderNumber: undefined,
      },
      this.refreshSurveys,
    )
  }

  onSelectSurvey = (i) => () => {
    const surveyName = this.state.surveyNames[i].name
    this.setState(
      {
        selectedSurvey: surveyName,
        orderNumber: undefined,
      },
      this.refreshSurveys,
    )
  }

  onToggleSort = (field) => () => {
    const { sort } = this.state
    if (sort === field) {
      this.setState({ sort: `-${field}` }, this.refreshSurveys)
    } else if (sort === `-${field}`) {
      this.setState({ sort: undefined }, this.refreshSurveys)
    } else {
      this.setState({ sort: field }, this.refreshSurveys)
    }
  }

  render() {
    const { headers, surveys } = this.props
    const { endDate, search, selectedSurvey, sort, startDate, surveyNames } =
      this.state

    return (
      <div className="survey-table-container survey-list-view">
        <div className="survey-list-container">
          <p className="list-title"> Select A Survey: </p>
          {surveyNames.map((s, i) => (
            <SurveyItem
              key={i}
              isActive={s.name === selectedSurvey}
              surveyDisplayName={s.displayName}
              surveyName={s.name}
              onSelectSurvey={this.onSelectSurvey(i)}
            />
          ))}
        </div>
        {selectedSurvey && (
          <div>
            <div className="survey-filters-container">
              <p className="survey-name"> Survey Name </p>
              <div className="date-filters">
                <div className="date-filters__inner">
                  <p className="date-label"> From </p>
                  <DateInput
                    date={startDate}
                    onChange={this.onChangeDate('startDate')}
                  />
                  <p className="date-label"> To </p>
                  <DateInput
                    date={endDate}
                    onChange={this.onChangeDate('endDate')}
                  />
                </div>
              </div>
              <div className="survey-filter">
                <div className="input-container">
                  <input
                    type="text"
                    placeholder={this.getSearchPlaceholder(selectedSurvey)}
                    value={search}
                    onInput={this.onChangeSearch}
                  />
                </div>
              </div>
              <CSVLink
                className="download-csv"
                data={this.getCSVData()}
                filename={`${selectedSurvey}_${Moment().format('MM-DD-YYYY')}`}
                target="_blank"
              >
                Download CSV
              </CSVLink>
            </div>

            <table>
              <tr className="first-header-row">
                {headers.map((h, i) =>
                  h.sortable ? (
                    <th key={i} onClick={this.onToggleSort(h.field)}>
                      <div className="center-cell-vertical">
                        {h.label}
                        <div className="sort">
                          <span
                            className={`filter-up-arrow ${
                              sort === h.field && 'active'
                            }`}
                          >
                            ▲
                          </span>
                          <span
                            className={`filter-down-arrow ${
                              sort === `-${h.field}` && 'active'
                            }`}
                          >
                            ▼
                          </span>
                        </div>
                      </div>
                    </th>
                  ) : (
                    <th className="list-question" key={i}>
                      {' '}
                      {h.label}{' '}
                    </th>
                  ),
                )}
              </tr>
              {surveys.map((survey, i) => (
                <tr key={`survey${i}`}>
                  {survey.map((s, j) => (
                    <td key={`field${j}`}>
                      <div>{s}</div>
                    </td>
                  ))}
                </tr>
              ))}
            </table>
          </div>
        )}
      </div>
    )
  }
}

SurveyList.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  orderNumber: PropTypes.string,
  selectedSurvey: PropTypes.string,
  surveys: PropTypes.arrayOf(PropTypes.array),

  loadSurveyList: PropTypes.func,
  loadSurveys: PropTypes.func,
}

export default SurveyList
