export const GetSalesReport =
  ({ RestService, pResponseSalesReport, HandleError }) =>
  async ({
    hqIds,
    salesRepId,
    clientId,
    chefId,
    captainId,
    orderTypes,
    from,
    to,
    isPaid,
    page,
    limit,
  }) => {
    if (!page || !limit) {
      return
    }
    let report = []
    const params = {
      hqIds,
      salesRepId,
      clientId,
      chefId,
      captainId,
      isPaid,
      offset: (page - 1) * limit,
      limit,
    }

    if (from) {
      params.from = from.format()
    }
    if (to) {
      params.to = to.format()
    }
    if (!params.limit || params.limit < 0) {
      params.limit = 0
    }
    if (orderTypes && orderTypes.length > 0) {
      const orderTypesPart = []
      const cateringTypesPart = []
      orderTypes.forEach((type) => {
        const strs = type.split('.')
        orderTypesPart.push(strs[0])
        if (strs.length > 1) {
          cateringTypesPart.push(strs[1])
        }
      })
      params.orderTypes = orderTypesPart.join(',')
      if (cateringTypesPart.length > 0) {
        params.cateringTypes = cateringTypesPart.join(',')
      }
    }

    Object.keys(params).forEach((key) => {
      if (params[key] === undefined || params[key] === '') {
        delete params[key]
      }
    })
    if (params['hqIds'] && params['hqIds'].length <= 0) {
      delete params['hqIds']
    }

    try {
      const response = await RestService.get(
        '/accounting/orders/search-sales',
        params,
      )
      report = pResponseSalesReport(response)
    } catch (error) {
      HandleError({ error })
    }

    return report
  }

export const GetAccountingSalesReport =
  ({ RestService, pResponseSalesReport, HandleError }) =>
  async ({
    hqIds,
    salesRepId,
    clientId,
    chefId,
    captainId,
    orderTypes,
    from,
    to,
    isPaid,
    page,
    limit,
  }) => {
    if (!page || !limit) {
      return
    }
    let report = []
    const params = {
      hqIds,
      salesRepId,
      clientId,
      chefId,
      captainId,
      isPaid,
      offset: (page - 1) * limit,
      limit,
    }

    if (from) {
      params.from = from.format()
    }
    if (to) {
      params.to = to.format()
    }
    if (!params.limit || params.limit < 0) {
      params.limit = 0
    }
    if (orderTypes && orderTypes.length > 0) {
      const orderTypesPart = []
      const cateringTypesPart = []
      orderTypes.forEach((type) => {
        const strs = type.split('.')
        orderTypesPart.push(strs[0])
        if (strs.length > 1) {
          cateringTypesPart.push(strs[1])
        }
      })
      params.orderTypes = orderTypesPart.join(',')
      if (cateringTypesPart.length > 0) {
        params.cateringTypes = cateringTypesPart.join(',')
      }
    }

    Object.keys(params).forEach((key) => {
      if (params[key] === undefined || params[key] === '') {
        delete params[key]
      }
    })
    if (params['hqIds'] && params['hqIds'].length <= 0) {
      delete params['hqIds']
    }

    try {
      const response = await RestService.get(
        '/accounting/orders/accounting-sales-report',
        params,
      )
      report = pResponseSalesReport(response)
    } catch (error) {
      HandleError({ error })
    }

    return report
  }

export const GetCaptPayReport =
  ({ RestService, pResponseCaptPayReport, HandleError }) =>
  async ({ hqIds, captainId, from, to, page, limit }) => {
    if (!page || !limit) {
      return
    }
    let report = []
    const params = {
      hqIds,
      captainId,
      offset: (page - 1) * limit,
      limit,
    }

    if (from) {
      params.from = from.format()
    }
    if (to) {
      params.to = to.format()
    }
    if (!params.limit || params.limit < 0) {
      params.limit = 0
    }

    Object.keys(params).forEach((key) => {
      if (params[key] === undefined || params[key] === '') {
        delete params[key]
      }
    })
    if (params['hqIds'] && params['hqIds'].length <= 0) {
      delete params['hqIds']
    }

    try {
      const response = await RestService.get(
        '/accounting/captains/payouts/find-for-report',
        params,
      )
      report = pResponseCaptPayReport(response)
    } catch (error) {
      HandleError({ error })
    }

    return report
  }

export const GetChefPayReport =
  ({ RestService, pResponseChefPayReport, HandleError }) =>
  async ({ hqIds, chefId, from, to, orderNumber, page, limit }) => {
    if (!page || !limit) {
      return
    }
    let report = []
    const params = {
      hqIds,
      chefId,
      orderNumber,
      offset: (page - 1) * limit,
      limit,
    }

    if (from) {
      params.from = from.format()
    }
    if (to) {
      params.to = to.format()
    }
    if (!params.limit || params.limit < 0) {
      params.limit = 0
    }

    Object.keys(params).forEach((key) => {
      if (params[key] === undefined || params[key] === '') {
        delete params[key]
      }
    })
    if (params['hqIds'] && params['hqIds'].length <= 0) {
      delete params['hqIds']
    }

    try {
      const response = await RestService.get(
        '/accounting/chefs/payouts/search-for-report',
        params,
      )
      report = pResponseChefPayReport(response)
    } catch (error) {
      HandleError({ error })
    }

    return report
  }

export const GetAggregateSalesReport =
  ({ RestService, HandleError }) =>
  async ({
    hqIds,
    salesRepId,
    clientId,
    chefId,
    captainId,
    orderTypes,
    from,
    to,
    isPaid,
  }) => {
    let report = []
    const params = {
      hqIds,
      salesRepId,
      clientId,
      chefId,
      captainId,
      orderTypes,
      isPaid,
    }

    if (from) {
      params.from = from.format()
    }
    if (to) {
      params.to = to.format()
    }
    if (!params.limit || params.limit < 0) {
      params.limit = 0
    }

    Object.keys(params).forEach((key) => {
      if (params[key] === undefined) {
        delete params[key]
      }
    })
    if (params['hqIds'] && params['hqIds'].length <= 0) {
      delete params['hqIds']
    }

    try {
      const response = await RestService.get(
        '/accounting/orders/aggregate',
        params,
      )
      report = [response]
    } catch (error) {
      HandleError({ error })
    }

    return report
  }

export const GetSalesCommissionReport =
  ({ RestService, pResponseSalesCommissionReport, HandleError }) =>
  async ({
    hqIds,
    salesRepId,
    clientId,
    chefId,
    captainId,
    orderTypes,
    from,
    to,
    isPaid,
    page,
    limit,
  }) => {
    if (!page || !limit) {
      return
    }
    let report = []
    const params = {
      hqIds,
      salesRepId,
      clientId,
      chefId,
      captainId,
      isPaid,
      offset: (page - 1) * limit,
      limit,
    }

    if (from) {
      params.from = from.format()
    }
    if (to) {
      params.to = to.format()
    }
    if (orderTypes && orderTypes.length > 0) {
      params.orderTypes = orderTypes.join(',')
    }
    if (!params.limit || params.limit < 0) {
      params.limit = 0
    }

    Object.keys(params).forEach((key) => {
      if (params[key] === undefined || params[key] === '') {
        delete params[key]
      }
    })
    if (params['hqIds'] && params['hqIds'].length <= 0) {
      delete params['hqIds']
    }

    try {
      const response = await RestService.get(
        '/accounting/orders/search-sales-commission',
        params,
      )
      report = pResponseSalesCommissionReport(response)
    } catch (error) {
      HandleError({ error })
    }

    return report
  }

export const GetGroupOrderReport =
  ({ RestService, HandleError, pResponseGroupOrderDashReport }) =>
  async ({
    hqIds,
    chefs,
    clients,
    orderNumbers,
    dropoffTimeStart,
    dropoffTimeEnd,
    goCutoffStart,
    goCutoffEnd,
    menuCutoffStart,
    menuCutoffEnd,
    page,
    limit,
  }) => {
    let report = []

    const params = {
      hqIds,
      chefIds: chefs ? chefs.map((chef) => chef.id) : [],
      clientIds: clients ? clients.map((client) => client.id) : [],
      orderNumbers: orderNumbers ? orderNumbers : [],
      limit,
      offset: (page - 1) * limit,
    }
    if (dropoffTimeStart) {
      params.dropoffStart = dropoffTimeStart.format()
    }
    if (dropoffTimeEnd) {
      params.dropoffEnd = dropoffTimeEnd.format()
    }
    if (goCutoffStart) {
      params.goCutoffStart = goCutoffStart.format()
    }
    if (goCutoffEnd) {
      params.goCutoffEnd = goCutoffEnd.format()
    }
    if (menuCutoffStart) {
      params.menuCutoffStart = menuCutoffStart.format()
    }
    if (menuCutoffEnd) {
      params.menuCutoffEnd = menuCutoffEnd.format()
    }
    Object.keys(params).forEach((key) => {
      if (
        params[key] === undefined ||
        params[key] === '' ||
        params[key].length === 0
      ) {
        delete params[key]
      }
    })
    if (params['hqIds'] && params['hqIds'].length <= 0) {
      delete params['hqIds']
    }

    try {
      const response = await RestService.get(
        '/group-orders/dashboard-report',
        params,
      )
      report = pResponseGroupOrderDashReport(response)
    } catch (error) {
      HandleError({ error })
    }

    return report
  }
