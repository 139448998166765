import {
  SET_ACCOUNT_AVG_MARGIN,
  SET_COMPARE_TO_ACCOUNT_AVG_MARGIN,
  SET_ACCOUNT_AVG_ORDER_TOTAL,
  SET_COMPARE_TO_ACCOUNT_AVG_ORDER_TOTAL,
  SET_ACCOUNT_TOTAL_ORDERS,
  SET_COMPARE_TO_ACCOUNT_TOTAL_ORDERS,
  SET_ACCOUNT_TOTAL_SALES,
  SET_COMPARE_TO_ACCOUNT_TOTAL_SALES,
  SET_ACCOUNT_TOTAL_SALES_CHART_DATA,
  SET_ACCOUNT_AVG_ORDER_TOTALS_CHART_DATA,
  SET_ACCOUNT_TOTAL_ORDERS_CHART_DATA,
  SET_ACCOUNT_AVG_MARGINS_CHART_DATA,
  SET_TOP_3_MENUS_FOR_ACCOUNT,
  SET_COMPARE_TO_TOP_3_MENUS_FOR_ACCOUNT,
  SET_ACCOUNT_DETAILS,
  SET_MARKET_OVERVIEW_STATS,
  SET_MARKET_AVG_MARGIN,
  SET_MARKET_AVG_ORDER_TOTAL,
  SET_SALES_REP_DETAILS,
  SET_TOP_3_ACCOUNTS_IN_MARKET,
  SET_TOP_3_MENUS_IN_MARKET,
  SET_TOP_3_REPS_IN_MARKET,
  SET_MARKET_TOTAL_ORDERS,
  SET_MARKET_TOTAL_SALES,
  SET_REP_AVG_MARGIN,
  SET_COMPARE_TO_REP_AVG_MARGIN,
  SET_REP_AVG_ORDER_TOTAL,
  SET_COMPARE_TO_REP_AVG_ORDER_TOTAL,
  SET_TOP_3_ACCOUNTS_FOR_REP,
  SET_TOP_3_MENUS_FOR_REP,
  SET_REP_TOTAL_ORDERS,
  SET_COMPARE_TO_REP_TOTAL_ORDERS,
  SET_REP_TOTAL_SALES,
  SET_COMPARE_TO_REP_TOTAL_SALES,
  SET_NATIONAL_AVG_MARGIN,
  SET_NATIONAL_AVG_ORDER_TOTAL,
  SET_TOP_ACCOUNTS_PER_MARKET,
  SET_TOP_MENUS_PER_MARKET,
  SET_TOP_REPS_PER_MARKET,
  SET_NATIONAL_TOTAL_ORDERS,
  SET_NATIONAL_TOTAL_SALES,
  SET_NATIONAL_AVG_MARGINS_CHART_DATA,
  SET_NATIONAL_TOTAL_ORDERS_CHART_DATA,
  SET_NATIONAL_AVG_ORDER_TOTALS_CHART_DATA,
  SET_NATIONAL_TOTAL_SALES_CHART_DATA,
  SET_MARKET_AVG_MARGINS_CHART_DATA,
  SET_MARKET_TOTAL_ORDERS_CHART_DATA,
  SET_MARKET_AVG_ORDER_TOTALS_CHART_DATA,
  SET_MARKET_TOTAL_SALES_CHART_DATA,
  SET_REP_AVG_MARGINS_CHART_DATA,
  SET_REP_TOTAL_ORDERS_CHART_DATA,
  SET_REP_AVG_ORDER_TOTALS_CHART_DATA,
  SET_REP_TOTAL_SALES_CHART_DATA,
} from '@actions/salesStats'

const initialAccountStatsState = {
  avgMargin: undefined,
  compareToAvgMargin: undefined,
  avgOrderTotal: undefined,
  compareToAvgOrderTotal: undefined,
  top3Menus: [],
  compareToTop3Menus: undefined,
  totalOrders: undefined,
  compareToTotalOrders: undefined,
  totalSales: undefined,
  compareToTotalSales: undefined,
  totalSalesChartData: [],
  totalOrdersChartData: [],
  avgMarginsChartData: [],
  avgOrderTotalsChartData: [],
}

const initialMarketStatsState = {
  avgMargin: undefined,
  avgOrderTotal: undefined,
  salesRepDetails: [],
  top3Accounts: [],
  top3Menus: [],
  top3Reps: [],
  totalOrders: undefined,
  totalSales: undefined,
  totalSalesChartData: [],
  totalOrdersChartData: [],
  avgMarginsChartData: [],
  avgOrderTotalsChartData: [],
}

const initialNationalStatsState = {
  avgMargin: undefined,
  avgOrderTotal: undefined,
  topAccountsPerMarket: [],
  topMenusPerMarket: [],
  topRepsPerMarket: [],
  totalOrders: undefined,
  totalSales: undefined,
  totalSalesChartData: [],
  totalOrdersChartData: [],
  avgMarginsChartData: [],
  avgOrderTotalsChartData: [],
}

const initialSalesRepStatsState = {
  avgMargin: undefined,
  compareToAvgMargin: undefined,
  avgOrderTotal: undefined,
  compareToAvgOrderTotal: undefined,
  accountDetails: [],
  top3Accounts: [],
  top3Menus: [],
  totalOrders: undefined,
  compareToTotalOrders: undefined,
  totalSales: undefined,
  compareToTotalSales: undefined,
  totalSalesChartData: [],
  totalOrdersChartData: [],
  avgMarginsChartData: [],
  avgOrderTotalsChartData: [],
}

export const accountStats = (state = initialAccountStatsState, action) => {
  const { type } = action

  switch (type) {
    case SET_ACCOUNT_AVG_MARGIN:
      return { ...state, avgMargin: action.avgMargin }
    case SET_COMPARE_TO_ACCOUNT_AVG_MARGIN:
      return { ...state, compareToAvgMargin: action.avgMargin }
    case SET_ACCOUNT_AVG_ORDER_TOTAL:
      return { ...state, avgOrderTotal: action.avgOrderTotal }
    case SET_COMPARE_TO_ACCOUNT_AVG_ORDER_TOTAL:
      return { ...state, compareToAvgOrderTotal: action.avgOrderTotal }
    case SET_TOP_3_MENUS_FOR_ACCOUNT:
      return { ...state, top3Menus: action.top3Menus }
    case SET_COMPARE_TO_TOP_3_MENUS_FOR_ACCOUNT:
      return { ...state, compareToTop3Menus: action.top3Menus }
    case SET_ACCOUNT_TOTAL_ORDERS:
      return { ...state, totalOrders: action.totalOrders }
    case SET_COMPARE_TO_ACCOUNT_TOTAL_ORDERS:
      return { ...state, compareToTotalOrders: action.totalOrders }
    case SET_ACCOUNT_TOTAL_SALES:
      return { ...state, totalSales: action.totalSales }
    case SET_COMPARE_TO_ACCOUNT_TOTAL_SALES:
      return { ...state, compareToTotalSales: action.totalSales }
    case SET_ACCOUNT_TOTAL_SALES_CHART_DATA:
      return { ...state, totalSalesChartData: action.totalSalesChartData }
    case SET_ACCOUNT_AVG_ORDER_TOTALS_CHART_DATA:
      return {
        ...state,
        avgOrderTotalsChartData: action.avgOrderTotalsChartData,
      }
    case SET_ACCOUNT_TOTAL_ORDERS_CHART_DATA:
      return { ...state, totalOrdersChartData: action.totalOrdersChartData }
    case SET_ACCOUNT_AVG_MARGINS_CHART_DATA:
      return { ...state, avgMarginsChartData: action.avgMarginsChartData }
    default:
      return state
  }
}

export const marketOverviewStats = (state = [], action) => {
  const { type } = action

  switch (type) {
    case SET_MARKET_OVERVIEW_STATS:
      return action.stats
    default:
      return state
  }
}

export const nationalStats = (state = initialNationalStatsState, action) => {
  const { type } = action

  switch (type) {
    case SET_NATIONAL_AVG_MARGIN:
      return { ...state, avgMargin: action.avgMargin }
    case SET_NATIONAL_AVG_ORDER_TOTAL:
      return { ...state, avgOrderTotal: action.avgOrderTotal }
    case SET_TOP_ACCOUNTS_PER_MARKET:
      return { ...state, topAccountsPerMarket: action.topAccountsPerMarket }
    case SET_TOP_MENUS_PER_MARKET:
      return { ...state, topMenusPerMarket: action.topMenusPerMarket }
    case SET_TOP_REPS_PER_MARKET:
      return { ...state, topRepsPerMarket: action.topRepsPerMarket }
    case SET_NATIONAL_TOTAL_ORDERS:
      return { ...state, totalOrders: action.totalOrders }
    case SET_NATIONAL_TOTAL_SALES:
      return { ...state, totalSales: action.totalSales }
    case SET_NATIONAL_TOTAL_SALES_CHART_DATA:
      return { ...state, totalSalesChartData: action.totalSalesChartData }
    case SET_NATIONAL_AVG_ORDER_TOTALS_CHART_DATA:
      return {
        ...state,
        avgOrderTotalsChartData: action.avgOrderTotalsChartData,
      }
    case SET_NATIONAL_TOTAL_ORDERS_CHART_DATA:
      return { ...state, totalOrdersChartData: action.totalOrdersChartData }
    case SET_NATIONAL_AVG_MARGINS_CHART_DATA:
      return { ...state, avgMarginsChartData: action.avgMarginsChartData }
    default:
      return state
  }
}

export const marketStats = (state = initialMarketStatsState, action) => {
  const { type } = action

  switch (type) {
    case SET_MARKET_AVG_MARGIN:
      return { ...state, avgMargin: action.avgMargin }
    case SET_MARKET_AVG_ORDER_TOTAL:
      return { ...state, avgOrderTotal: action.avgOrderTotal }
    case SET_SALES_REP_DETAILS:
      return { ...state, salesRepDetails: action.salesRepDetails }
    case SET_TOP_3_ACCOUNTS_IN_MARKET:
      return { ...state, top3Accounts: action.top3Accounts }
    case SET_TOP_3_MENUS_IN_MARKET:
      return { ...state, top3Menus: action.top3Menus }
    case SET_TOP_3_REPS_IN_MARKET:
      return { ...state, top3Reps: action.top3Reps }
    case SET_MARKET_TOTAL_ORDERS:
      return { ...state, totalOrders: action.totalOrders }
    case SET_MARKET_TOTAL_SALES:
      return { ...state, totalSales: action.totalSales }
    case SET_MARKET_TOTAL_SALES_CHART_DATA:
      return { ...state, totalSalesChartData: action.totalSalesChartData }
    case SET_MARKET_AVG_ORDER_TOTALS_CHART_DATA:
      return {
        ...state,
        avgOrderTotalsChartData: action.avgOrderTotalsChartData,
      }
    case SET_MARKET_TOTAL_ORDERS_CHART_DATA:
      return { ...state, totalOrdersChartData: action.totalOrdersChartData }
    case SET_MARKET_AVG_MARGINS_CHART_DATA:
      return { ...state, avgMarginsChartData: action.avgMarginsChartData }
    default:
      return state
  }
}

export const salesRepStats = (state = initialSalesRepStatsState, action) => {
  const { type } = action

  switch (type) {
    case SET_ACCOUNT_DETAILS:
      return { ...state, accountDetails: action.accountDetails }
    case SET_REP_AVG_MARGIN:
      return { ...state, avgMargin: action.avgMargin }
    case SET_COMPARE_TO_REP_AVG_MARGIN:
      return { ...state, compareToAvgMargin: action.compareToAvgMargin }
    case SET_REP_AVG_ORDER_TOTAL:
      return { ...state, avgOrderTotal: action.avgOrderTotal }
    case SET_COMPARE_TO_REP_AVG_ORDER_TOTAL:
      return { ...state, compareToAvgOrderTotal: action.compareToAvgOrderTotal }
    case SET_TOP_3_ACCOUNTS_FOR_REP:
      return { ...state, top3Accounts: action.top3Accounts }
    case SET_TOP_3_MENUS_FOR_REP:
      return { ...state, top3Menus: action.top3Menus }
    case SET_REP_TOTAL_ORDERS:
      return { ...state, totalOrders: action.totalOrders }
    case SET_COMPARE_TO_REP_TOTAL_ORDERS:
      return { ...state, compareToTotalOrders: action.compareToTotalOrders }
    case SET_REP_TOTAL_SALES:
      return { ...state, totalSales: action.totalSales }
    case SET_COMPARE_TO_REP_TOTAL_SALES:
      return { ...state, compareToTotalSales: action.compareToTotalSales }
    case SET_REP_TOTAL_SALES_CHART_DATA:
      return { ...state, totalSalesChartData: action.totalSalesChartData }
    case SET_REP_AVG_ORDER_TOTALS_CHART_DATA:
      return {
        ...state,
        avgOrderTotalsChartData: action.avgOrderTotalsChartData,
      }
    case SET_REP_TOTAL_ORDERS_CHART_DATA:
      return { ...state, totalOrdersChartData: action.totalOrdersChartData }
    case SET_REP_AVG_MARGINS_CHART_DATA:
      return { ...state, avgMarginsChartData: action.avgMarginsChartData }
    default:
      return state
  }
}
