import React, { Component } from 'react'
import PropTypes from 'prop-types'

import OrderSnackPack from './OrderSnackPack'

class SnackPacksSection extends Component {
  render() {
    const { onEditOrderSnackPack, onRemoveOrderSnackPack, onReorderSnackPack } =
      this.props
    const orderSnackPacks = this.props.orderSnackPacks || []

    if (orderSnackPacks.length === 0) {
      return null
    }

    return (
      <div className="mt-5">
        <table className="w-100 border-separate order-table">
          <tr>
            <td />
            <td className="w-55 bold uppercase"> Snack Pack Name </td>
            <td className="bold uppercase text-right"> QTY </td>
            <td className="bold uppercase text-right"> Vendor Price </td>
            <td className="bold uppercase text-right"> Client Price </td>
            <td className="bold uppercase text-right"> Total </td>
          </tr>
          {orderSnackPacks.map((i, index) => (
            <OrderSnackPack
              key={i.displayOrder}
              snackPack={i}
              onEdit={onEditOrderSnackPack}
              onRemove={onRemoveOrderSnackPack}
              onReorder={onReorderSnackPack}
              isFirst={index == 0}
              isLast={index == orderSnackPacks.length - 1}
            />
          ))}
        </table>
      </div>
    )
  }
}

SnackPacksSection.propTypes = {
  orderSnackPacks: PropTypes.arrayOf(PropTypes.object),

  onEditOrderSnackPack: PropTypes.func,
  onRemoveOrderSnackPack: PropTypes.func,
  onReorderSnackPack: PropTypes.func,
}

export default SnackPacksSection
