import React, { useState, ChangeEvent } from 'react'
import { FlexContainer } from '@components/common'
import { Label } from '@res/styledComponents/index'
import { TextInput } from '@components/common/form'
import Table from '@components/common/Table'
import Panel from '@components/common/Panel'
import { colors } from '@constants'
import { useHubspotFetch, SearchCompaniesReq } from '../../../hooks'
import { HubspotCompany } from '@types'

interface HubspotCompanyTableProps {
  companies: HubspotCompany[]
  renderSaveButton: () => React.JSX.Element | null
  loadHubspotCompanies: (req: SearchCompaniesReq) => Promise<HubspotCompany[]>
  selectedCompanies: { [key: string]: boolean }
  onSelectCompany: (id: string) => (e: ChangeEvent<HTMLInputElement>) => void
}

const HubspotCompanyTable: React.FC<HubspotCompanyTableProps> = ({
  companies,
  renderSaveButton,
  loadHubspotCompanies,
  selectedCompanies,
  onSelectCompany,
}) => {
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState<number>(1)

  const { loading, error } = useHubspotFetch(
    { search, page, results_per_page: 100 },
    loadHubspotCompanies,
  )

  const onChangeSearch = (e: InputEvent) => {
    setSearch((e.target as HTMLInputElement).value)
  }

  const onChangePage = (dir: number) => {
    if (dir === -1 && page > 1) {
      setPage(page - 1)
    } else if (dir === 1 && companies?.length === 100) {
      setPage(page + 1)
    }
  }

  return (
    <Panel width="100%">
      <div className="sticky">
        <div className="mb-3 py-2 sticky top-0 bg-white">
          <div className="flex flex-row">
            <FlexContainer flexDirection="column" width="50%">
              <Label>Search Hubspot Companies</Label>
              <TextInput
                type="text"
                placeholder="Search by name, domain"
                value={search}
                onChange={onChangeSearch}
              />
            </FlexContainer>
            <FlexContainer
              width="50%"
              alignItems="center"
              justifyContent="center"
            >
              {renderSaveButton()}
            </FlexContainer>
          </div>
          <div className="mt-3 flex flex-row items-center justify-between">
            <div>
              <FlexContainer flexDirection="row">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-6"
                  onClick={() => onChangePage(-1)}
                >
                  Previous Page
                </button>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => onChangePage(1)}
                >
                  Next Page
                </button>
              </FlexContainer>
            </div>
            <div>{companies?.length} Results</div>
          </div>
        </div>
        <div>
          {loading ? (
            <div className="animate-pulse text-2xl font-bold">Loading...</div>
          ) : error ? (
            <div className="text-lg font-bold">Error: {error.message}</div>
          ) : (
            <Table
              headings={[
                '',
                'Company Name',
                'Domains',
                'Metro',
                'Pin',
                'Hubspot ID',
              ]}
            >
              {companies?.map((company: HubspotCompany, idx: number) => {
                const { id, name, domain, metro, pin } = company

                return (
                  <tr
                    key={`${id}-${idx}`}
                    onClick={() => onSelectCompany(id)}
                    style={{
                      backgroundColor: selectedCompanies[id]
                        ? colors.blue100
                        : 'white',
                      cursor: 'pointer',
                    }}
                  >
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedCompanies[id]}
                        onChange={onSelectCompany(id)}
                      />
                    </td>
                    <td>{name}</td>
                    <td>{domain}</td>
                    <td>{metro}</td>
                    <td>{pin}</td>
                    <td>{id}</td>
                  </tr>
                )
              })}
            </Table>
          )}
        </div>
      </div>
    </Panel>
  )
}

export default HubspotCompanyTable
