import { trackJSErrorLogger } from './trackJS'

export const HandleError =
  ({ RouterService, SessionService, UIService, pResponseError }) =>
  ({ error, namespace, doFlash }) => {
    doFlash = doFlash === undefined ? true : doFlash
    const { errors, errorMessage } = pResponseError(error)
    trackJSErrorLogger(error)
    namespace && UIService.Errors.updateErrors({ errors, namespace })

    if (doFlash) {
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((message) => {
          UIService.FlashMessage.displayFailureMessage(message)
        })
      } else {
        UIService.FlashMessage.displayFailureMessage(errorMessage)
      }
    }

    if (error && error.response && error.response.status === 401) {
      SessionService.clearUserSession()
      namespace && RouterService.replace(`/?redirect=${namespace}`)
    }

    return { errors, errorMessage }
  }
