export const SendPreEventEmails =
  ({ RestService, UIService, HandleError }) =>
  async ({ orders }) => {
    try {
      await RestService.post('/group-orders/pre-event-emails', orders)
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage('Chefs successfully notified')

    return true
  }
