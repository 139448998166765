import { SET_INVOICE } from '@actions/invoice'

export const invoice = (state = null, action) => {
  switch (action.type) {
    case SET_INVOICE: {
      return { ...action.invoice }
    }
    default:
      return state
  }
}
