import { connect } from 'react-redux'

import { PopUpOrdersModal } from '@components/popUps'
import coordinators from '@coordinators'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { user = {}, headquarter = {} } = state
  const { lastLoggedInHq, email } = user
  const { userHeadquarters = [] } = headquarter
  const userHeadquarter =
    userHeadquarters.find((hq) => hq.id === lastLoggedInHq) || {}

  return {
    email,
    locale: userHeadquarter.locale,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService } = services
  const { MarkPopUpPaid } = coordinators

  const markPopUpPaid = MarkPopUpPaid({ RestService, UIService, HandleError })

  return {
    markPopUpPaid,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpOrdersModal)
