import { connect } from 'react-redux'

import { OrderPage } from '@components/order'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'

const mapStateToProps = (state) => {
  const orderNumber = state.order && state.order.orderNumber
  const filters = ['open', 'closed', 'all']
  const accountName =
    state.order && state.order.account && state.order.account.name

  return {
    filters,
    orderableType: 'Order',
    accountName,
    orderNumber,
  }
}

const mapDispatchToProps = () => {
  const { OrderService, RestService, RouterService, UIService } = services
  const { LoadQueryOrder } = coordinators
  const { pResponseOrder } = presenters.Api

  const checkQueryOrder = LoadQueryOrder({
    OrderService,
    RestService,
    RouterService,
    pResponseOrder,
  })
  const newOrder = () => {
    OrderService.clearNewOrderable()
    UIService.EditOrder.show('new')
  }

  return {
    checkQueryOrder,
    newOrder,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage)
