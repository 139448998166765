import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormContainer } from '@res/styledComponents/index'
import { LinkText, Input } from '@components/common/form'

class PaymentMethodInput extends Component {
  state = {
    cardholderName: '',
    cardNumber: '',
    cardType: '',
    cvv: '',
    expirationDate: '',
    formattedCardNumber: '',
    last4: '',
    nonce: '',
    billingAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: '',
      buildingInstructions: '',
    },
    showBillingAddress: false,

    needsBraintreeLoad: true,
    isCardNumberFocused: false,

    // unmutated
    id: undefined,
  }
  cvvLength = 3
  cardNumberMinLength = 13

  componentDidMount() {
    ;(async () => {
      await this.props.initBraintree()

      this.setState({ needsBraintreeLoad: false })
    })()

    const newState = { ...this.props.paymentMethod }
    const { billingAddress } = this.props.paymentMethod
    if (!billingAddress) {
      newState.billingAddress = {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
        buildingInstructions: '',
      }
    }
    this.setState(newState)
  }

  componentWillReceiveProps(nextProps) {
    const { paymentMethod } = nextProps

    if (paymentMethod !== this.props.paymentMethod) {
      const newState = { ...paymentMethod }
      const { billingAddress } = this.props.paymentMethod
      if (!billingAddress) {
        newState.billingAddress = {
          line1: '',
          line2: '',
          city: '',
          state: '',
          zip: '',
          buildingInstructions: '',
        }
      }

      this.setState(newState)
    }
  }

  updatePaymentMethod =
    ({ doUpdateNonce }) =>
    async () => {
      const {
        displayFailureMessage,
        onChange,
        pBuildPaymentMethod,
        updateNonce,
        validatePaymentMethod,
      } = this.props

      if (doUpdateNonce) {
        if (!validatePaymentMethod(this.state)) {
          return
        }
        const nonce = await updateNonce(this.state)
        if (!nonce) {
          displayFailureMessage(
            'Unable to generate a valid nonce for this credit card. Please cancel and add a new payment method.',
          )

          return
        }
        this.setState({ nonce }, () => {
          const paymentMethod = pBuildPaymentMethod(this.state)
          onChange && onChange(paymentMethod)
        })
      } else {
        const paymentMethod = pBuildPaymentMethod(this.state)
        onChange && onChange(paymentMethod)
      }
    }

  onBlurCardNumber = () => {
    this.setState({ isCardNumberFocused: false })
  }

  onFocusCardNumber = () => {
    this.setState({ isCardNumberFocused: true })
  }

  onInputCardholderName = (e) => {
    const cardholderName = e.target.value
    this.setState(
      { cardholderName },
      this.updatePaymentMethod({ doUpdateNonce: false }),
    )
  }

  onInputCardNumber = (e) => {
    const { pFormatCreditCard } = this.props
    const {
      cardNumber,
      cardNumberMinLength,
      cardType,
      cvvLength,
      formattedCardNumber,
    } = pFormatCreditCard(e.target.value)

    this.cvvLength = Math.max(cvvLength || 0, 3)
    this.cardNumberMinLength = Math.max(cardNumberMinLength || 0, 13)
    this.setState(
      {
        cardNumber,
        cardType,
        formattedCardNumber,
      },
      () => {
        if (cardNumber.length >= this.cardNumberMinLength) {
          this.updatePaymentMethod({ doUpdateNonce: true })()
        }
      },
    )
  }

  onInputCVV = (e) => {
    let cvv = e.target.value
    cvv = cvv.replace(/[^0-9]/g, '').slice(0, this.cvvLength)

    if (cvv.length === this.cvvLength) {
      this.setState({ cvv }, this.updatePaymentMethod({ doUpdateNonce: true }))
    } else {
      this.setState(
        { cvv, nonce: '' },
        this.updatePaymentMethod({ doUpdateNonce: false }),
      )
    }
  }

  onInputExpirationDate = (e) => {
    const { pFormatMMYY } = this.props
    const expirationDate = pFormatMMYY(e.target.value)

    this.setState(
      {
        expirationDate,
      },
      this.updatePaymentMethod({ doUpdateNonce: true }),
    )
  }

  onCancel = () => {
    this.props.onCancel()
  }

  onChange = (e, resourceName) => {
    const { billingAddress } = this.state
    this.setState(
      {
        billingAddress: {
          ...billingAddress,
          [resourceName]: e.target.value,
        },
      },
      this.updatePaymentMethod({ doUpdateNonce: false }),
    )
  }

  render() {
    const { errors, hasBackground } = this.props
    const {
      id,
      cardholderName,
      cvv,
      expirationDate,
      formattedCardNumber,
      last4,
      billingAddress,

      showBillingAddress,
      isCardNumberFocused,
      needsBraintreeLoad,
    } = this.state
    const { line1, line2, city, state, zip, buildingInstructions } =
      billingAddress

    if (needsBraintreeLoad) {
      return null
    }

    const cardNumber = isCardNumberFocused
      ? formattedCardNumber
      : last4
        ? formattedCardNumber || `XXXX XXXX XXXX ${last4}`
        : formattedCardNumber

    return (
      <FormContainer hasBackground={hasBackground}>
        <Input
          testId="cardholder-name"
          label="Cardholder Name"
          value={cardholderName}
          onChange={this.onInputCardholderName}
        />
        <Input
          label="Card Number"
          value={cardNumber}
          error={errors.number}
          onChange={this.onInputCardNumber}
        />
        <Input
          placeholder="MM/YY"
          label="Expiration Date"
          value={expirationDate}
          error={errors.expirationYear || errors.expirationMonth}
          onChange={this.onInputExpirationDate}
          testId="expiration-date"
        />
        {(!last4 || formattedCardNumber) && (
          <Input
            label="CVV"
            value={cvv}
            error={errors.cvv}
            onChange={this.onInputCVV}
            testId="cvv"
          />
        )}
        {!showBillingAddress && (
          <LinkText
            label="Add Billing Address"
            fontSize="13px"
            onClick={() => this.setState({ showBillingAddress: true })}
          />
        )}
        {showBillingAddress && (
          <React.Fragment>
            <Input
              label="Address Line 1"
              value={line1}
              error={errors.line1}
              onChange={(e) => this.onChange(e, 'line1')}
            />
            <Input
              label="Address Line 2"
              value={line2}
              error={errors.line2}
              onChange={(e) => this.onChange(e, 'line2')}
            />
            <Input
              label="City"
              value={city}
              error={errors.city}
              onChange={(e) => this.onChange(e, 'city')}
            />
            <Input
              label="State"
              value={state}
              error={errors.state}
              onChange={(e) => this.onChange(e, 'state')}
            />
            <Input
              label="Zip"
              value={zip}
              error={errors.zip}
              onChange={(e) => this.onChange(e, 'zip')}
            />
            <Input
              label="Building/Delivery Instructions"
              value={buildingInstructions}
              error={errors.buildingInstructions}
              onChange={(e) => this.onChange(e, 'buildingInstructions')}
            />
            <LinkText
              label="Hide Billing Address"
              fontSize="13px"
              onClick={() => this.setState({ showBillingAddress: false })}
            />
          </React.Fragment>
        )}
        {!id && (
          <LinkText
            label="Cancel New"
            fontSize="13px"
            onClick={() => this.onCancel()}
          />
        )}
      </FormContainer>
    )
  }
}

PaymentMethodInput.propTypes = {
  addresses: PropTypes.array,
  paymentMethod: PropTypes.object,
  hasBackground: PropTypes.bool,
  errors: PropTypes.object,

  initBraintree: PropTypes.func,
  displayFailureMessage: PropTypes.func,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  pBuildPaymentMethod: PropTypes.func,
  pFormatCreditCard: PropTypes.func,
  pFormatMMYY: PropTypes.func,
  updateNonce: PropTypes.func,
  validatePaymentMethod: PropTypes.func,
}

PaymentMethodInput.defaultProps = {
  errors: {},
}

export default PaymentMethodInput
