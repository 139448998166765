import React from 'react'
import Panel from '@components/common/Panel'
import FlexContainer from '@components/common/FlexContainer'
import Checkbox from '@components/common/form/Checkbox'
import LabelInfo from '@components/common/form/LabelInfo'
import Input from '@components/common/form/Input'
import Dropdown from '@components/common/form/Dropdown'
import { Button, TimeInput } from '@components/common/form'
import { Moment } from 'moment-timezone'
import YSpacing from '@components/common/YSpacing'
import {
  ClientSettings,
  ScheduleSettings,
  CutoffTimeSettings,
  CutoffTimeDayOffSetType,
  DropoffTimeSettings,
} from '@types'

const CUTOFF_DAY_OFFSET_TYPES = [
  {
    value: CutoffTimeDayOffSetType.BUSINESS_DAYS,
    label: 'Business Days',
  },
  {
    value: CutoffTimeDayOffSetType.DAYS,
    label: 'Days - Includes Weekends',
  },
]

interface Props {
  selectedIndex: number
  clientSettings: ClientSettings[]
  displayFailureMessage: (message: string) => void
  newScheduleSettings: () => ScheduleSettings
  updateClientSettings: (clientSettings: ClientSettings[]) => void
  onSaveClientSettings: () => Promise<void>
}

const GroupOrdersSecheduleSettings = (props: Props) => {
  const {
    clientSettings,
    selectedIndex,
    displayFailureMessage,
    updateClientSettings,
    onSaveClientSettings,
  } = props

  const clientSetting = clientSettings[selectedIndex] || {}
  const { scheduleSettings } = clientSetting || {}

  const onChangeClientSettings = (updatedSetting: ClientSettings) => {
    updateClientSettings([
      ...clientSettings.slice(0, selectedIndex),
      { ...updatedSetting },
      ...clientSettings.slice(selectedIndex + 1),
    ])
  }

  const createScheduleSettings = () => {
    if (clientSetting) {
      const updatedClientSetting = {
        ...clientSetting,
        scheduleSettings: props.newScheduleSettings(),
      } as ClientSettings
      onChangeClientSettings(updatedClientSetting)
    } else {
      displayFailureMessage(
        'Client Setting does not exist. Cannot create new schedule settings.',
      )
    }
  }

  const updateCutoffTimeSettings = (
    key: string,
    value: number | Moment | string,
  ) => {
    const updatedClientSetting = {
      ...clientSetting,
      scheduleSettings: {
        ...scheduleSettings,
        cutoffTimeSettings: {
          ...scheduleSettings?.cutoffTimeSettings,
          [key]: value,
        } as CutoffTimeSettings,
      } as ScheduleSettings,
    } as ClientSettings

    onChangeClientSettings(updatedClientSetting)
  }

  const onChangeScheduleSettingsActive = (value: boolean) => {
    const updatedClientSetting = {
      ...clientSetting,
      scheduleSettings: {
        ...scheduleSettings,
        isActive: value,
      } as ScheduleSettings,
    } as ClientSettings
    onChangeClientSettings(updatedClientSetting)
  }

  const onChangeDropoffTime = (value: Moment) => {
    const updatedClientSetting = {
      ...clientSetting,
      scheduleSettings: {
        ...scheduleSettings,
        dropoffTimeSettings: {
          ...scheduleSettings?.dropoffTimeSettings,
          time: value,
        } as DropoffTimeSettings,
      } as ScheduleSettings,
    } as ClientSettings
    onChangeClientSettings(updatedClientSetting)
  }

  return (
    <Panel width="100%" maxWidth="800px" heading="Schedule Settings">
      <LabelInfo label="Default Cutoff Time" />
      {scheduleSettings ? (
        <>
          <FlexContainer
            width="100%"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Input
              width="20%"
              label="Cutoff Day"
              value={scheduleSettings.cutoffTimeSettings?.dayOffset?.toString()}
              type="number"
              min="0"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateCutoffTimeSettings('dayOffset', e.target.valueAsNumber)
              }
            />
            <Dropdown
              width="75%"
              value={scheduleSettings.cutoffTimeSettings?.dayOffsetType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateCutoffTimeSettings('dayOffsetType', e.target.value)
              }
            >
              {CUTOFF_DAY_OFFSET_TYPES.map((r) => (
                <option
                  key={r.value}
                  value={r.value}
                  selected={
                    r.value ===
                    scheduleSettings.cutoffTimeSettings?.dayOffsetType
                  }
                >
                  {r.label}
                </option>
              ))}
            </Dropdown>
          </FlexContainer>
          <TimeInput
            width="100%"
            label="Cutoff Time"
            time={scheduleSettings.cutoffTimeSettings?.time}
            testId="default-cutoff-time"
            onChange={(value: Moment) =>
              updateCutoffTimeSettings('time', value)
            }
          />
          <YSpacing height="20px" />
          <TimeInput
            width="100%"
            label="Dropoff Time"
            time={scheduleSettings.dropoffTimeSettings?.time}
            testId="default-dropoff-time"
            onChange={onChangeDropoffTime}
          />
          <YSpacing height="20px" />
          <Checkbox
            label="Active?"
            value={'checkbox'}
            checked={scheduleSettings.isActive}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeScheduleSettingsActive(e.target.checked)
            }
          />
          <YSpacing height="20px" />
          <Button label="Save Changes" onClick={() => onSaveClientSettings()} />
        </>
      ) : (
        <Checkbox
          label="Set Custom Default Cutoff Time"
          value={'cutoff-time-checkbox'}
          checked={scheduleSettings}
          onChange={() => {
            createScheduleSettings()
          }}
        />
      )}
    </Panel>
  )
}

export default GroupOrdersSecheduleSettings
