import React, { useState, useEffect, ChangeEvent } from 'react'

import Modal from '@components/common/modal/Modal'
import { YSpacing, FlexContainer } from '@components/common'
import { Button, Dropdown } from '@components/common/form'
import { AutocompleteInput } from '@containers/common/form'
import { Chef, IndexHeadquarter } from '@types'

interface EditChildChefsModalProps {
  chef: Chef
  closeModal: () => void
  loadChefs: (filters: { search?: string; status: 'active' }) => Promise<void>
  saveChef: (chef: Chef) => Promise<void>
  loadHeadquarters: () => Promise<IndexHeadquarter[]>
}

interface DropdownFilters {
  excluding_chef_ids?: string[]
  hq?: number | null
}

const EditChildChefsModal: React.FC<EditChildChefsModalProps> = ({
  chef,
  closeModal,
  loadChefs,
  saveChef,
  loadHeadquarters,
}) => {
  const [headquarters, setHeadquarters] = useState<IndexHeadquarter[]>([])
  const [updateChef, setUpdateChef] = useState<Chef>(chef)
  const [selectedHQ, setSelectedHQ] = useState<number | undefined>(undefined)
  const [filters, setFilters] = useState<DropdownFilters>({})
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      const hqs = await loadHeadquarters()
      setHeadquarters(hqs)
    })()

    setFilters({
      excluding_chef_ids: [
        chef.id,
        ...(chef.childChefs || []).map((childChef) => childChef.id),
      ],
    })
  }, [])

  const onSelectChef = (newChef: any) => {
    const children = updateChef.childChefs || []

    children.push({
      id: newChef.id,
      name: newChef.name,
    })
    setUpdateChef({ ...updateChef, childChefs: children })
    setFilters({
      ...filters,
      excluding_chef_ids: [
        chef.id,
        ...children.map((childChef) => childChef.id),
      ],
    })
  }

  const onRemoveChef = (chefId: string) => {
    const children = updateChef.childChefs || []

    const idx = children.findIndex((childChef) => childChef.id === chefId)
    if (idx !== -1) {
      children.splice(idx, 1)
      setUpdateChef({ ...updateChef, childChefs: children })
      setFilters({
        ...filters,
        excluding_chef_ids: [
          chef.id,
          ...children.map((childChef) => childChef.id),
        ],
      })
    }
  }

  const onSaveChef = async () => {
    setLoading(true)
    await saveChef(updateChef)
    closeModal()
    setLoading(false)
  }

  const onChangeHQ = (e: ChangeEvent<HTMLSelectElement>) => {
    const hq = parseInt(e.target.value)
    if (!hq) {
      setSelectedHQ(undefined)

      const newFilters = { ...filters }
      delete newFilters.hq

      setFilters(newFilters)
    } else {
      setSelectedHQ(hq)
      setFilters({
        ...filters,
        hq,
      })
    }
  }

  return (
    <Modal hideModal={closeModal} title={`Edit child chefs for ${chef.name}`}>
      <FlexContainer flexDirection="column">
        <Dropdown
          label="Select Headquarters"
          width="30%"
          value={selectedHQ}
          onChange={onChangeHQ}
        >
          <option value={undefined}>- Select Headquarter -</option>
          {headquarters.map((headquarter: IndexHeadquarter, idx: number) => (
            <option key={`${headquarter.id}-${idx}`} value={headquarter.id}>
              {headquarter.name}
            </option>
          ))}
        </Dropdown>
        <AutocompleteInput
          label="Search Chefs"
          width="30%"
          displayAttribute="name"
          loaderFunction={(search) =>
            loadChefs({ ...search, status: 'active' })
          }
          onSelect={onSelectChef}
          filters={filters}
        />
      </FlexContainer>
      <YSpacing height="20px" />
      <div>
        <h3 className="font-semibold">Child Chefs</h3>
        <div>
          {(updateChef.childChefs || []).map((childChef: any) => {
            return (
              <div
                key={childChef.id}
                style={{
                  width: 'fit-content',
                }}
                className="mt-2 p-2 bg-gray-300 rounded-lg"
              >
                Chef {childChef.name}
                <span
                  className="ml-2 text-red-700 cursor-pointer"
                  onClick={() => onRemoveChef(childChef.id)}
                >
                  ✕
                </span>
              </div>
            )
          })}
        </div>
      </div>
      <div className="flex justify-center items-center mt-3">
        <Button
          onClick={onSaveChef}
          disabled={loading}
          label={loading ? 'Saving...' : 'Save'}
        />
      </div>
    </Modal>
  )
}

export default EditChildChefsModal
