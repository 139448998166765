const DefaultStyles = {
  primaryColor: 'gray',
  secondaryColor: 'gray',
}

const WashingtonNationalsStyles = {
  primaryColor: '#00073b',
  secondaryColor: '#ba0c2f',
  textColor: '#00073b',
  logo: 'https://d18y2pm8p6g6xr.cloudfront.net/images/nationals-logo.png',
}

const RangersStyles = {
  primaryColor: '#003278',
  secondaryColor: '#c0111f',
  textColor: '#00073b',
  logo: 'https://d3om2wrv39qih6.cloudfront.net/rangers-logo.png',
}

export const getTheme = (name) => {
  let theme = null
  switch (name) {
    case 'Nationals':
      theme = WashingtonNationalsStyles
      break
    case 'Rangers':
      theme = RangersStyles
      break
    default:
      return DefaultStyles
  }

  return theme
}
