import { connect } from 'react-redux'

import { AlertChef } from '@components/order/orderSections/panel'

import { HandleError } from '@coordinators/composed'
import coordinators from '@coordinators'
import services from '@services'

const mapDispatchToProps = () => {
  const { OrderService, RestService, UIService } = services
  const { EmailChef, EmailChefs } = coordinators
  const emailChef = EmailChef({
    OrderService,
    RestService,
    UIService,
    HandleError,
  })
  const emailChefs = EmailChefs({
    OrderService,
    RestService,
    UIService,
    HandleError,
  })

  return {
    emailChef,
    emailChefs,
  }
}

export default connect(undefined, mapDispatchToProps)(AlertChef)
