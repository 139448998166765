import Api from './api'
import * as authPresenters from './auth'
import * as editAccountPresesnters from './editAccount'
import * as editChefPresenters from './editChef'
import * as editOrderPresenters from './editOrder'
import * as Encoding from './encoding'
import * as Errors from './errors'
import * as formPresenters from './form'
import * as serviceVendorPresenters from './editServiceVendor'
import * as menuItemPresenters from './menuItem'
import * as subscriptionPresenters from './subscription'
import * as urlPresenters from './url'
import Validation from './validation'

export default Object.freeze({
  Api,
  ...authPresenters,
  ...editAccountPresesnters,
  ...editChefPresenters,
  ...editOrderPresenters,
  Encoding,
  Errors,
  ...formPresenters,
  ...menuItemPresenters,
  ...serviceVendorPresenters,
  ...subscriptionPresenters,
  ...urlPresenters,
  Validation,
})
