import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { config } from 'hungry-core2'
import Moment from 'moment-timezone'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Filters } from '@containers/reports'
import { FaFilter } from 'react-icons/fa'
import {
  FlexContainer,
  Panel,
  YSpacing,
  XSpacing,
  Loader,
} from '@components/common'
import TooltipModal from '@components/common/modal/TooltipModal'
import { LinkText, Button } from '@components/common/form'
import { Reports } from './constants'
import { colors } from '../../../constants'
import { AuthorizedDisplay } from '@containers/common/auth'

const ReportsPage = ({ authorizedReports, reduxState }) => {
  const [isAscending, setIsAscending] = useState(false)
  const [dateHeadings, setDateHeadings] = useState([])
  const [reportData, setReportData] = useState([])
  const [tableHeadings, setTableHeadings] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(true)
  const [fetchedReport, setFetchedReport] = useState('')
  const [filterConfigs, setFilterConfigs] = useState(
    authorizedReports[0].getFilters(reduxState),
  )
  const [report, setReport] = useState(authorizedReports[0])
  const [serverFilters, setServerFilters] = useState({})
  const [tableStyles, setTableStyles] = useState({})

  useEffect(() => {
    document.body.style.overflow = 'auto'
  }, [])

  const onLoadReport = useCallback(async (report, filters) => {
    const selectedReport = report
    setIsLoading(true)
    const data = await report.loadReport(filters)
    setFetchedReport(selectedReport)
    setIsLoading(false)

    if (data && data.length > 0) {
      const tableHeadings = Object.keys(data[0]).filter((d) => !d.includes('_'))
      const dateHeadings = Object.keys(data[0]).filter((d) => d.includes('_'))
      setTableHeadings(tableHeadings)
      setDateHeadings(dateHeadings)
      setReportData(data)
    } else {
      setReportData([])
    }
    setIsLoading(false)
  }, [])

  const onSelectReport = (report) => {
    setReport(report)
    const newFilters = report.getFilters(reduxState)
    setServerFilters(newFilters)
    setFilterConfigs(newFilters)
    const styles = report.getTableStyles ? report.getTableStyles() : {}
    setTableStyles(styles)
    setReportData([])
    setTableHeadings([])
    setFetchedReport('')
    if (report.loadOnChange) {
      onLoadReport(report, newFilters)
    }
  }

  const adjustSortValue = (value) => {
    if (typeof value === 'string') {
      // turn currency string into number so it can be sorted
      let newValue = value.split('')
      if (newValue[0] === '$') {
        newValue = Number(newValue.splice(1, newValue.length).join(''))

        return newValue
      } else {
        return value
      }
    } else {
      return value
    }
  }

  const onSortByDate = (key) => {
    let newArray
    if (isAscending) {
      newArray = [...reportData].sort((a, b) => {
        return Moment.utc(a[`_${key}`]).diff(Moment.utc(b[`_${key}`]))
      })
    } else {
      newArray = [...reportData].sort((a, b) => {
        return Moment.utc(b[`_${key}`]).diff(Moment.utc(a[`_${key}`]))
      })
    }
    setIsAscending(!isAscending)
    setReportData(newArray)
  }

  const onSort = (key) => {
    if (dateHeadings.includes(`_${key}`)) {
      return onSortByDate(key)
    }
    let newArray
    if (isAscending) {
      newArray = [...reportData].sort((a, b) => {
        const valueA = adjustSortValue(a[key])
        const valueB = adjustSortValue(b[key])
        if (valueA < valueB) {
          return -1
        }

        return valueA > valueB ? 1 : 0
      })
    } else {
      newArray = [...reportData].sort((a, b) => {
        const valueA = adjustSortValue(a[key])
        const valueB = adjustSortValue(b[key])
        if (valueA > valueB) {
          return -1
        }

        return valueA < valueB ? 1 : 0
      })
    }
    setIsAscending(!isAscending)
    setReportData(newArray)
  }

  return (
    <FlexContainer flexDirection="column">
      <YSpacing height="40px" />
      <ReportsPageBody>
        <h1>HUNGRY Reports</h1>
        <FlexContainer alignItems="center">
          <div className="flex flex-row">
            {authorizedReports.map((r) => (
              <Tab
                key={r.name}
                isActive={r.name === report.name}
                onClick={() => onSelectReport(r)}
                display="flex"
              >
                {r.name}
                {!r.loadOnChange && (
                  <TooltipModal
                    unicode="&#9432;"
                    width="350px"
                    information={'Must press the Search button to load report'}
                    spanTop="-50px"
                    spanMarginLeft="10px"
                    marginTop="20px"
                  />
                )}
              </Tab>
            ))}
          </div>
          <XSpacing width="50px" />
          <FilterButton
            onClick={() => setShowFilter(!showFilter)}
            margin="0 20px 0 0"
          >
            <FaFilter />
            {showFilter ? 'Hide Filters' : 'Show Filters'}
          </FilterButton>
          <XSpacing width="30px" />
          <div className="flex flex-row">
            <div className="flex flex-col align-center items-center justify-center">
              {report.csvExports
                .filter((ex) => (ex.filter ? ex.filter(serverFilters) : true))
                .map((ex, i) => {
                  return (
                    <>
                      <LinkText
                        key={i}
                        label={ex.label}
                        isLink={true}
                        link={ex.url(config.api_host, serverFilters)}
                      />
                      <div className="mx-3"></div>
                    </>
                  )
                })}
            </div>
            <XSpacing width="30px" />
            {report.additionalButtons &&
              report.additionalButtons.map(({ text, callback, roles }) => (
                <AuthorizedDisplay roles={roles} key={`btn-${text}`}>
                  <Button label={text} width="100%" onClick={callback} />
                </AuthorizedDisplay>
              ))}
          </div>
        </FlexContainer>
        <YSpacing height="10px" />
        <FlexContainer>
          <FilterContainer isVisible={showFilter}>
            <Filters
              loadOnChange={report.loadOnChange}
              filterConfigs={filterConfigs}
              numResults={reportData.length}
              loadReport={() => onLoadReport(report, serverFilters)}
              onChange={(newFilters) => {
                setServerFilters(newFilters)
                if (report.loadOnChange) {
                  onLoadReport(report, newFilters)
                }
              }}
            />
            <XSpacing width="20px" />
          </FilterContainer>
          <Panel width="100%">
            {!isLoading &&
              (!reportData.length || fetchedReport !== report) &&
              !report.loadOnChange && <p>Press Search to generate report</p>}
            {isLoading && <Loader isCenter={true} />}
            {Boolean(!isLoading && fetchedReport === report) && (
              <ReportTable>
                <tbody>
                  <tr className="table-heading">
                    {tableHeadings.map((t) => (
                      <th onClick={() => onSort(t)} key={t}>
                        {t}
                      </th>
                    ))}
                  </tr>
                  {reportData.map((row, i) => (
                    <tr key={i}>
                      {tableHeadings.map((h) => {
                        const data = row[h]
                        let { backgroundColor } = tableStyles
                        if (
                          typeof data === 'object' &&
                          data !== null &&
                          data.type === 'link'
                        ) {
                          return (
                            <td key={h}>
                              <a
                                className="order-link"
                                href={data.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {data.text}
                              </a>
                            </td>
                          )
                        }

                        if (report.cellBackGroundColor) {
                          backgroundColor = report.cellBackGroundColor(h, data)
                        }

                        return (
                          <TableRow
                            key={h}
                            background={backgroundColor}
                            minWidth={tableStyles.minWidth}
                            wordBreak={tableStyles.wordBreak}
                          >
                            {data}
                          </TableRow>
                        )
                      })}
                    </tr>
                  ))}
                </tbody>
              </ReportTable>
            )}
          </Panel>
        </FlexContainer>
      </ReportsPageBody>
    </FlexContainer>
  )
}

const ReportsPageBody = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  h1 {
    font-size: 25px;
    margin-bottom: 10px;
    font-family: 'bold';
    color: ${colors.gray400};
  }
`

const Tab = styled.button`
  font-family: 'bold';
  padding: 10px 0;
  margin-right: 20px;
  border-bottom: 3px solid
    ${(props) => (props.isActive ? colors.orange : 'rgba(0,0,0,0)')};
  color: ${colors.gray400};
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  display: ${(props) => props.display};
`

const ReportTable = styled.table`
  position: relative;
  td {
    border-bottom: 1px solid ${colors.gray200};
    padding: 5px 15px;
    font-family: 'regular';
    font-size: 12px;
    max-width: 100px;
  }
  th {
    padding: 10px 15px;
    font-family: 'bold';
    text-transform: uppercase;
    font-size: 12px;
    background: ${colors.gray100};
    cursor: pointer;
    position: sticky;
    top: 0;
    border-bottom: 1px solid ${colors.gray300};
  }
`

const FilterContainer = styled.div`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: sticky;
  top: 0;
  z-index: 100;
`

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  font-family: 'regular';
  color: ${colors.blue400};
  margin: ${(props) => props.margin};
  svg {
    margin-right: 5px;
  }
`

const TableRow = styled.td`
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : 'break-all')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : '100px')};
  background: ${(props) => (props.background ? props.background : '')};
`

ReportsPage.propTypes = {
  authorizedReports: PropTypes.arrayOf(PropTypes.object),
  reduxState: PropTypes.object,
}

const mapStateToProps = (state) => {
  const { user } = state
  const currentHq = user && user.lastHeadquarterSignIn
  const currentRoles =
    (user &&
      user.roles
        .filter((r) => r.headquarterId == currentHq)
        .map((r) => r.name)) ||
    []
  const authorizedReports = Reports.filter((r) =>
    r.roles.some((role) => currentRoles.includes(role)),
  )

  return {
    authorizedReports,
    reduxState: state,
  }
}

export default connect(mapStateToProps, undefined)(ReportsPage)
