import { connect } from 'react-redux'
import { config } from 'hungry-core2'

import { Overview } from '@components/order/orderSections'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { order, hubspotDeal, router } = state

  let payouts = []
  let dealUrl

  if (order) {
    const { chefPayouts } = order
    payouts = chefPayouts
  }

  if (hubspotDeal) {
    dealUrl = `https://${config.hubspot_url_prefix}/deal/${
      hubspotDeal && hubspotDeal.hubspotId
    }`
  }

  const port = router.location.port === 3000 ? 3001 : 3000
  const uri =
    config.admin_url +
    (config.admin_url === 'http://localhost' ? `:${port}` : '')

  return {
    chefPayouts: payouts,
    dealUrl,
    hubspotDeal,
    uri,
  }
}
const mapDispatchToProps = () => {
  const { HubspotDealService, OrderService, RestService, UIService } = services
  const {
    ChangeOrderStatus,
    CopyOrder,
    OrderToProposal,
    DeleteOrder,
    EditOrder,
    LoadHubspotSingle,
  } = coordinators
  const {
    calculateDiscounts,
    calculateGrossMargin,
    getDeliveryFeePercentAndLimit,
  } = OrderService
  const { pCopyOrder } = presenters
  const {
    pResponseEditOrder,
    pResponseOrder,
    pResponseOrderError,
    pResponseHubspotSingle,
  } = presenters.Api

  const changeOrderStatus = ChangeOrderStatus({
    OrderService,
    RestService,
    pResponseOrder,
    UIService,
    HandleError,
  })
  const deleteOrder = DeleteOrder({
    OrderService,
    RestService,
    UIService,
    pResponseOrderError,
    HandleError,
  })
  const editOrder = EditOrder({
    OrderService,
    RestService,
    UIService,
    pResponseEditOrder,
  })
  const copyOrder = CopyOrder({
    OrderService,
    RestService,
    UIService,
    pCopyOrder,
    pResponseEditOrder,
  })
  const loadHubspotDeal = LoadHubspotSingle({
    RestService,
    HubspotService: HubspotDealService,
    pResponseHubspotSingle,
    endpoint: 'deals',
  })
  const orderToProposal = OrderToProposal({
    RestService,
    UIService,
    HandleError,
  })

  const setReduxOrderStatus = (s) => OrderService.setReduxOrderStatus(s)
  const showChefPayouts = () => UIService.EditOrder.showChefPayouts()

  return {
    calculateDiscounts,
    calculateGrossMargin,
    changeOrderStatus,
    copyOrder,
    deleteOrder,
    editOrder,
    getDeliveryFeePercentAndLimit,
    loadHubspotDeal,
    orderToProposal,
    pResponseEditOrder,
    setReduxOrderStatus,
    showChefPayouts,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview)
