export const AccountingSections = ['Financials']

export const ServicesSections = [
  'Services',
  'Catering Time & Item',
  'Notifications',
]

export const AccountingColumns = [
  {
    key: 'orderNumber',
    header: 'Order Number',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
    primaryKey: true,
  },
  {
    key: 'orderContact',
    header: 'Invoice Contact',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'createdAt',
    header: 'Creation Date',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'eventDate',
    header: 'Event Date',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'orderType',
    header: 'Order Type',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'orderSource',
    header: 'Order Source',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'serviceType',
    header: 'Service Type',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'isEvent',
    header: 'Is Event',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'orderStatus',
    header: 'Order Status',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
    render: 'editStatus',
  },
  {
    key: 'client',
    header: 'Client',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'pin',
    header: 'Client Pin',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'headCount',
    header: 'Head Count',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'accountExecutive',
    header: 'Account Executive',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'eachChefPayout',
    header: 'Each Chef Payout',
    sortable: true,
    searchable: false,
    filterable: false,
    className: 'dashboard-long-text',
    sectionGroup: 'Financials',
    render: 'renderEachChef',
  },
  {
    key: 'discountsDescription',
    header: 'Discounts Description',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Financials',
  },
  // { key: 'serviceItemsTotal', header: 'Service Items Cost', sortable: true, searchable: false, filterable: false, sectionGroup: 'Financials' },
  {
    key: 'chefAmount',
    header: 'Chefs Total',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Financials',
  },
  {
    key: 'suppliesCost',
    header: 'Supplies Total',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Financials',
  },
  {
    key: 'serviceCosts',
    header: 'Service Costs',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Financials',
  },
  {
    key: 'subtotal',
    header: 'Subtotal',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'discounts',
    header: 'Discounts',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'discountReasons',
    header: 'Discount Reasons',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'serviceFee',
    header: 'Delivery Fee',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Financials',
  },
  {
    key: 'tax',
    header: 'Tax',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Financials',
  },
  {
    key: 'tip',
    header: 'Tip',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Financials',
  },
  {
    key: 'total',
    header: 'Total Billing',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'totalRevenue',
    header: 'Total Revenue',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'isPaid',
    header: 'Paid?',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'lastPaidDate',
    header: 'Last Paid Date',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'margin',
    header: 'Margin',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'unpaidBalance',
    header: 'Unpaid Balance',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'paymentType',
    header: 'Payment Type',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
    render: 'editPayment',
  },
  {
    key: 'referral',
    header: 'Referred By',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  //{ key: 'margin', header: 'Margin on Chef', sortable: true, searchable: true, filterable: true, sectionGroup: 'Financials' },
]

export const ServicesColumns = [
  // hidden prefix for key name indicates a filler column that will be shown when that section is hidden

  //Services info
  {
    key: 'headCount',
    header: 'HC',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Services',
  },
  {
    key: 'orderType',
    header: 'Meal Type',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Services',
  },
  {
    key: 'serviceType',
    header: 'Service Type',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Services',
  },
  {
    key: 'isEvent',
    header: 'Is Event',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Services',
  },
  {
    key: 'servicesInstructions',
    header: 'Services Instructions',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Services',
    render: 'editText',
  },
  {
    key: 'dropoffAddress',
    header: 'Dropoff Address',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Services',
    className: 'dashboard-long-text',
  },
  //{ key: 'setUpStyle', header: 'Set Up Style', sortable: true, searchable: true, filterable: true, sectionGroup: 'Services' },
  { key: 'hiddenServices', header: '', sectionGroup: 'Services' },

  // Catering Time & Item Information
  //{ key: 'servingUtensilsDescription', header: 'Total Packaging', sortable: true, searchable: false, filterable: true, sectionGroup: 'Catering Time & Item', className: 'dashboard-long-text' },
  //{ key: 'servicesOrderItems', header: 'Services Order Items', sortable: true, searchable: false, filterable: true, sectionGroup: 'Catering Time & Item', className: 'dashboard-long-text' },
  //{ key: 'servicesAddOns', header: 'Add Ons', sortable: true, searchable: false, filterable: true, sectionGroup: 'Catering Time & Item', className: 'dashboard-long-text' },
  {
    key: 'hqArrivalTime',
    header: 'HQ Arrival',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Catering Time & Item',
    render: 'editDate',
    className: 'dashboard-small-field',
  },
  {
    key: 'hqDepartureTime',
    header: 'HQ Dep.',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Catering Time & Item',
    render: 'editDate',
    className: 'dashboard-small-field',
  },
  {
    key: 'kitchenArrivalTime',
    header: 'Kitchen Arrival',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Catering Time & Item',
    render: 'editDate',
    className: 'dashboard-small-field',
  },
  {
    key: 'kitchenDepartureTime',
    header: 'Kitchen Dep.',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Catering Time & Item',
    render: 'editDate',
    className: 'dashboard-small-field',
  },
  {
    key: 'clientArrivalTime',
    header: 'Client Arrival',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Catering Time & Item',
    render: 'editDate',
    className: 'dashboard-small-field',
  },
  {
    key: 'clientSetUpTime',
    header: 'Client Set Up',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Catering Time & Item',
    render: 'editDate',
    className: 'dashboard-small-field',
  },
  { key: 'hiddenTime', header: '', sectionGroup: 'Catering Time & Item' },
  {
    key: 'eachChefPayout',
    header: 'Each Chef Amount',
    sortable: true,
    searchable: false,
    filterable: false,
    className: 'dashboard-long-text',
    sectionGroup: 'Catering Time & Item',
    render: 'renderEachChef',
  },
  { key: 'hiddenChefs', header: '', sectionGroup: 'Chefs' },
  {
    key: 'orderNumber',
    header: 'Order Number',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Catering Time & Item',
    primaryKey: true,
  },
  {
    key: 'client',
    header: 'Client',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Catering Time & Item',
  },
  {
    key: 'eventDate',
    header: 'Event Date',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Catering Time & Item',
  },
  {
    key: 'orderStatus',
    header: 'Order Status',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Catering Time & Item',
    render: 'editStatus',
  },
  {
    key: 'accountExecutive',
    header: 'Sales Rep',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Catering Time & Item',
  },
  {
    key: 'deliveryInstructions',
    header: 'Delivery Instructions',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Catering Time & Item',
    render: 'editText',
  },
  {
    key: 'serviceDepotAddress',
    header: 'Service Depot Address',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Catering Time & Item',
    className: 'dashboard-long-text',
  },
  {
    key: 'foodPickupAddress',
    header: 'Food Pickup Address',
    sortable: true,
    searchable: false,
    filterable: false,
    sectionGroup: 'Catering Time & Item',
    className: 'dashboard-long-text',
  },

  //Chef info
  //{ key: 'concept', header: 'Menu Card', sortable: true, searchable: true, filterable: true, sectionGroup: 'Chefs' },
  //{ key: 'orderItemsDescription', header: 'Order Items', sortable: false, searchable: false, filterable: false, sectionGroup: 'Chefs', className: 'dashboard-long-text' }, // dont allow filter by order_items since they are obj and would be meaningless
  //{ key: 'chefAmount', header: 'Total Chef Amount', sortable: true, searchable: false, filterable: false, sectionGroup: 'Chefs' },

  //Notifications status
  {
    key: 'sendServicesAlert',
    header: 'Send Services Alert',
    sortable: false,
    searchable: false,
    filterable: false,
    sectionGroup: 'Notifications',
    render: 'editCheckbox',
  },
  {
    key: 'sendChefAlert',
    header: 'Send Chef Alert',
    sortable: false,
    searchable: false,
    filterable: false,
    sectionGroup: 'Notifications',
    render: 'editCheckbox',
  },
  { key: 'hiddenNotifications', header: '', sectionGroup: 'Notifications' },
]

export const ARColumns = [
  {
    key: 'accountName',
    header: 'Account Name',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'orderNumber',
    header: 'Order Number',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
    primaryKey: true,
  },
  {
    key: 'invoiceNumber',
    header: 'Batch Invoice',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'orderDate',
    header: 'Order Date',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'salesRep',
    header: 'Sales Rep',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'csmName',
    header: 'Client Success Manager',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'hunterName',
    header: 'Account Executive',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'contact',
    header: 'Invoice Contact',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'paymentMethod',
    header: 'Payment Method',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'paymentTerms',
    header: 'Payment Terms',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'invoicingCadence',
    header: 'Invoicing Cadence',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'effectiveDate',
    header: 'Effective Date',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'dueDate',
    header: 'Due Date',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'daysPastDue',
    header: 'Days Past Due',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'total',
    header: 'Total',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'unpaidAmount',
    header: 'Unpaid Amount',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'notes',
    header: 'Internal Notes',
    sortable: false,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'autoBatchActive',
    header: 'Auto Batch Active',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'lateBatchInvoiceEmail',
    header: 'Overdue Batch Invoice Rem.',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'latePaymentEmailByInvoiceContact',
    header: 'Overdue Catering Rem. (Invoice Contact)',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'latePaymentEmailByOrderContact',
    header: 'Overdue Catering Rem. (Order Contact)',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'autoInvoiceEmailByInvoiceContact',
    header: 'Catering Invoice Rem. (Invoice Contact)',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
  {
    key: 'autoInvoiceEmailByOrderContact',
    header: 'Catering Invoice Rem. (Order Contact)',
    sortable: true,
    searchable: true,
    filterable: true,
    sectionGroup: 'Financials',
  },
]

export const Configs = {
  defaultPageSize: 10,
  showFilter: false,
  showPageSize: false,
}

export const InitOrdersDashboardParams = { page: 1, results_per_page: 100 }

export const InitVisibleSections = {
  Services: true,
  'Catering Time & Item': true,
  Chefs: true,
  Notifications: false,
  Financials: true,
}
