import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import Modal from '@components/common/modal/Modal'

export class ServiceDetails extends Component {
  state = {
    id: undefined,
    clientArrivalTime: undefined,
    clientSetUpTime: undefined,
    hqArrivalTime: undefined,
    hqDepartureTime: undefined,
    kitchenArrivalTime: undefined,
    kitchenDepartureTime: undefined,
    selectedCateringCaptain: undefined,
    serviceDepotId: undefined,
    routes: [],
  }

  componentWillMount() {
    this.props.loadServiceDepots()
    if (this.props.order) {
      this.setTimes(this.props.order)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { order } = nextProps
    if (order && order.id !== this.props.order.id) {
      this.setTimes(order)
    }
  }

  getTimeClass(actualTime, time) {
    if (!actualTime) {
      return ''
    }

    const deltaArrival =
      actualTime && Math.round((Moment(actualTime) - Moment(time)) / 1000 / 60)
    if (deltaArrival <= 0) {
      return 'on-time'
    } else {
      return 'late'
    }
  }

  setTimes = async (order) => {
    const serviceOrder = await this.props.loadServiceOrderTimes(order)
    if (serviceOrder) {
      const { routes } = serviceOrder
      this.setState({
        id: order.id,
        clientArrivalTime: order.clientArrivalTime,
        clientSetUpTime: order.clientSetUpTime,
        hqArrivalTime: order.hqArrivalTime,
        hqDepartureTime: order.hqDepartureTime,
        kitchenArrivalTime: order.kitchenArrivalTime,
        kitchenDepartureTime: order.kitchenDepartureTime,
        routes,
        selectedCateringCaptain: order.cateringCaptain,
        serviceDepotId: order.serviceDepotId,
      })
    }
  }

  onEmailServicesTeam = (e) => {
    e.preventDefault()

    this.props.emailServicesTeam()
  }

  onEmailServicesSurvey = (e) => {
    e.preventDefault()

    this.props.emailServicesSurvey()
  }

  onSelectCateringCaptain = (cateringCaptain) => {
    this.setState({ selectedCateringCaptain: cateringCaptain })
  }

  onSelectServiceDepot = (value) => {
    this.setState({ serviceDepotId: value })
  }

  onSubmit = () => {
    this.props.updateServicesInfo(this.state)
  }

  renderProgressBar(stops, captain) {
    const captainName = captain ? captain.name : 'No Captain Selected'
    const progressLength = 100 / (stops.length * 2)
    const segments = []
    let onTimeClass = undefined
    let i = 0
    stops.forEach((s) => {
      const arrivalKey = `${s.key}${captainName}arrival`
      const departureKey = `${s.key}${captainName}departure`
      onTimeClass = this.getTimeClass(s.actualArrival, s.arrivalTime)
      const hasCheckinProblem = s.checkinDistance && s.checkinDistance > 5

      segments.push(
        <div
          key={i++}
          className={`progress ${onTimeClass}`}
          style={{ width: `${progressLength}%` }}
        >
          <p
            className={`progress-point ${
              hasCheckinProblem ? 'discrepency' : ''
            }`}
            onMouseOver={() => this.setState({ [arrivalKey]: true })}
            onMouseOut={() => this.setState({ [arrivalKey]: false })}
          >
            ⬤
          </p>
          {this.state[arrivalKey] && (
            <div
              className={`progress-tooltip ${s.actualArrival ? '' : 'small'} ${
                hasCheckinProblem ? 'large' : ''
              }`}
            >
              <p>
                {s.type} {s.type === 'chef' && s.chef.name}
              </p>
              <p>Arrival Time: {Moment(s.arrivalTime).format('h:mm a')}</p>
              {s.actualArrival && (
                <p>
                  Captain Arrival Time:{' '}
                  {Moment(s.actualArrival).format('h:mm a')}
                </p>
              )}
              {hasCheckinProblem && (
                <p className="discrepency">
                  Check-in discrepency: {s.checkinDistance}min
                </p>
              )}
            </div>
          )}
        </div>,
      )
      onTimeClass = this.getTimeClass(s.actualDeparture, s.departureTime)
      segments.push(
        <div
          key={i++}
          className={`progress ${onTimeClass}`}
          style={{ width: `${progressLength}%` }}
        >
          <p
            className="progress-point"
            onMouseOver={() => this.setState({ [departureKey]: true })}
            onMouseOut={() => this.setState({ [departureKey]: false })}
          >
            ⬤
          </p>
          {this.state[departureKey] && (
            <div
              className={`progress-tooltip ${s.actualDeparture ? '' : 'small'}`}
            >
              <p>
                {s.type} {s.type === 'chef' && s.chef.name}
              </p>
              <p>Departure Time: {Moment(s.departureTime).format('h:mm a')}</p>
              {s.actualDeparture && (
                <p>
                  Captain Departure Time:{' '}
                  {Moment(s.actualDeparture).format('h:mm a')}
                </p>
              )}
            </div>
          )}
        </div>,
      )
    })

    return <div className="progressbar">{segments}</div>
  }

  render() {
    const { hideModal } = this.props
    const { clientSetUpTime, routes } = this.state

    return (
      <Modal
        title="See Catering Captain Status"
        hideModal={hideModal}
        color="#001940"
      >
        <div>
          {clientSetUpTime && (
            <div className="form-field-container__columns">
              {!routes && <p>No routes calculated yet.</p>}
              <p className="mb-15 text-center bold">
                {' '}
                Hover over each point to see the captain’s progress{' '}
              </p>
              <div className="flex flex-col mb-20">
                <div>
                  <span className="progress-legend-not-started" />
                  <span>Not yet en route</span>
                </div>
                <div>
                  <span className="progress-legend-early" />
                  <span>Early/On Time</span>
                </div>
                <div>
                  <span className="progress-legend-late" />
                  <span>Late</span>
                </div>
                <div>
                  <span className="legend-point">⬤</span>
                  <span>Captain checked in but not at location</span>
                </div>
              </div>
              <div className="progressbar-container">
                {routes &&
                  routes.map((r, i) => (
                    <div key={`route${i}`} className="progressbar-single">
                      <p className="progress-captain">
                        {r.captain ? r.captain.name : 'No captain selected'}
                      </p>
                      {this.renderProgressBar(r.stops, r.captain)}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </Modal>
    )
  }
}

ServiceDetails.propTypes = {
  actionRequired: PropTypes.bool,
  className: PropTypes.string,
  order: PropTypes.object,
  serviceDepots: PropTypes.arrayOf(PropTypes.object),

  emailServicesSurvey: PropTypes.func,
  emailServicesTeam: PropTypes.func,
  loadCateringCaptains: PropTypes.func,
  loadServiceDepots: PropTypes.func,
  loadServiceOrderTimes: PropTypes.func,
  updateServicesInfo: PropTypes.func,
  hideModal: PropTypes.func,
}

ServiceDetails.defaultProps = {
  actionRequired: true,
}

export default ServiceDetails
