export const SET_HUBSPOT_CONTACTS = 'SET_HUBSPOT_CONTACTS'
export const SET_HUBSPOT_CONTACT = 'SET_HUBSPOT_CONTACT'
export const SET_HUBSPOT_COMPANIES = 'SET_HUBSPOT_COMPANIES'
export const SET_HUBSPOT_COMPANY = 'SET_HUBSPOT_COMPANY'
export const SET_HUBSPOT_COMPANY_IN_COMPANIES =
  'SET_HUBSPOT_COMPANY_IN_COMPANIES'
export const SET_HUBSPOT_USERS = 'SET_HUBSPOT_USERS'
export const SET_HUBSPOT_USER = 'SET_HUBSPOT_USER'
export const SET_HUBSPOT_DEALS = 'SET_HUBSPOT_DEALS'
export const SET_HUBSPOT_DEAL = 'SET_HUBSPOT_DEAL'

export const setHubspotContacts = (contacts) => ({
  type: SET_HUBSPOT_CONTACTS,
  contacts,
})

export const setHubspotContact = (contact) => ({
  type: SET_HUBSPOT_CONTACT,
  contact,
})

export const setHubspotCompanies = (companies) => ({
  type: SET_HUBSPOT_COMPANIES,
  companies,
})

export const setCompanyInCompanies = (company) => ({
  type: SET_HUBSPOT_COMPANY_IN_COMPANIES,
  company,
})

export const setHubspotCompany = (company) => ({
  type: SET_HUBSPOT_COMPANY,
  company,
})

export const setHubspotUsers = (users) => ({ type: SET_HUBSPOT_USERS, users })

export const setHubspotUser = (user) => ({ type: SET_HUBSPOT_USER, user })

export const setHubspotDeals = (deals) => ({ type: SET_HUBSPOT_DEALS, deals })

export const setHubspotDeal = (deal) => ({ type: SET_HUBSPOT_DEAL, deal })
