import * as chefCoordinators from './chef'
import * as conceptCoordinators from './concept'
import * as menuItemCoordinators from './menuItem'
import * as recipeCoordinators from './recipe'
import * as scheduleCoordinators from './schedule'
import * as serviceItemCoordinators from './serviceItem'
import * as snackPackCoordinators from './snackPack'
import * as suggestionCoordinators from './suggestion'
import * as virtualItemCoordinators from './virtualItem'
import * as virtualKitCoordinators from './virtualKit'

export default Object.freeze({
  ...chefCoordinators,
  ...conceptCoordinators,
  ...menuItemCoordinators,
  ...recipeCoordinators,
  ...scheduleCoordinators,
  ...serviceItemCoordinators,
  ...snackPackCoordinators,
  ...suggestionCoordinators,
  ...virtualItemCoordinators,
  ...virtualKitCoordinators,
})
