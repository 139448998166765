import React, { Component } from 'react'
import PropTypes from 'prop-types'

class PopUpCalculator extends Component {
  state = {
    headcount: null,
    mains: {
      chicken: 0,
      vegetarian: 0,
      alternative: 0,
      total: 0,
    },
    base: {
      each: 0,
      servingsTotal: 0,
    },
    toppings: {
      each: 0,
    },
    sides: {
      starch: 0,
      vegetable: 0,
      salad: 0,
    },
    sauce: {
      each: 0,
      total: 0,
    },
  }

  onChange = (e) => {
    const hc = e.target.value
    let chicken = 0
    let vegetarian = 0
    let alternative = 0
    let starch = 0
    let salad = 0
    if (hc <= 50) {
      chicken = Math.round(hc / 2)
      vegetarian = Math.round(hc * 0.2)
      alternative = hc - chicken - vegetarian
      starch = Math.round(hc * 0.8)
      salad = Math.round(hc / 2)
    } else if (hc > 50 && hc <= 75) {
      chicken = Math.round(hc * 0.46)
      vegetarian = Math.round(hc * 0.2)
      alternative = hc - chicken - vegetarian
      starch = Math.round(hc * 0.8)
      salad = Math.round(hc * 0.46)
    } else if (hc > 75) {
      chicken = Math.round(hc / 2)
      vegetarian = Math.round(hc * 0.15)
      alternative = hc - chicken - vegetarian
      starch = Math.round(hc * 0.75)
      salad = Math.round(hc * 0.45)
    }
    this.setState({
      headcount: hc,
      mains: {
        chicken: chicken,
        vegetarian: vegetarian,
        alternative: alternative,
        total: hc,
      },
      base: {
        each: Math.round(hc / 2),
        servingsTotal: Math.round(hc / 2) * 2,
      },
      toppings: {
        each: hc,
      },
      sides: {
        starch: starch,
        vegetable: starch,
        salad: salad,
      },
      sauce: {
        each: Math.round(hc / 2),
        total: Math.round(hc / 2) * 2,
      },
    })
  }

  render() {
    const { headcount, mains, base, toppings, sides, sauce } = this.state

    return (
      <div className="popup-calculator">
        <p className="popup-calculator-title">
          Enter headcount to get recommended quantities
          <input
            onChange={this.onChange}
            value={headcount}
            type="text"
            placeholder="0"
          />
        </p>
        <table className="popup-table">
          <tr>
            <th>Mains</th>
            <th>Base</th>
            <th>Toppings</th>
            <th>Sides</th>
            <th>Sauce</th>
          </tr>
          <tr>
            <td>
              <p>{mains.chicken} Chicken</p>
              <p>{mains.vegetarian} Vegetarian</p>
              <p>{mains.alternative} Alternative</p>
              <p>{mains.total} Total</p>
            </td>
            <td>
              <p>{base.each} of each</p>
              <p>{base.servingsTotal} total</p>
            </td>
            <td>
              <p>{toppings.each} of each</p>
            </td>
            <td>
              <p>{sides.starch} starch</p>
              <p>{sides.vegetable} vegetable</p>
              <p>{sides.salad} salad/3rd veg</p>
            </td>
            <td>
              <p>{sauce.each} of each</p>
              <p>{sauce.total} total</p>
            </td>
          </tr>
        </table>
      </div>
    )
  }
}

PopUpCalculator.propTypes = {
  headcount: PropTypes.string,
}

export default PopUpCalculator
