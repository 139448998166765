import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class SupplyService extends BaseService {
  setPackaging(packaging) {
    this.dispatch(actions.setPackaging(packaging))
  }

  setPackagingAddOns(packagingAddOns) {
    this.dispatch(actions.setPackagingAddOns(packagingAddOns))
  }

  setServingUtensils(servingUtensils) {
    this.dispatch(actions.setServingUtensils(servingUtensils))
  }

  setCateringSupplies(cateringSupplies) {
    this.dispatch(actions.setCateringSupplies(cateringSupplies))
  }

  setDiningSupplies(diningSupplies) {
    this.dispatch(actions.setDiningSupplies(diningSupplies))
  }

  setInventories(inventories) {
    this.dispatch(actions.setInventories(inventories))
  }
}
