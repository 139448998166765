import React from 'react'
import PropTypes from 'prop-types'

const Tab = (props) => {
  const { children, name, activeTab, style } = props
  const visible = name === activeTab

  return <div style={style}>{visible && children}</div>
}

Tab.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,

  name: PropTypes.string,
  activeTab: PropTypes.string,
}

export default Tab
