import accountCoordinators from './account'
import * as authCoordinators from './auth'
import batchOrderCoordinators from './batchOrder'
import * as braintreeCoordinators from './braintree'
import * as chartCoordinators from './chart'
import chefCoordinators from './chef'
import chefNetworkCoordinators from './chefNetwork'
import cafeCoordinators from './cafes'
import dashboardCoordinators from './dashboard'
import * as errorsCoordinators from './errors'
import groupOrdersCoordinators from './groupOrders'
import * as hubspotCoordinators from './hubspot'
import menuItemsSearchCoordinators from './menuItemsSearch'
import marketCoordinators from './market'
import * as orderableCoordinators from './orderable'
import orderCoordinators from './order'
import proposalCoordinators from './proposal'
import * as requestCoordinators from './request'
import referralCoordinators from './referral'
import * as settingsCoordinators from './settings'
import supplyCoordinators from './supply'
import * as surveyCoordinators from './survey'
import userCoordinators from './user'
import * as validationCoordinators from './validation'
import subscriptionCoordinators from './subscriptions'
import mlbPartnersCoordinators from './mlbPartners'
import popUpsCoordinators from './popUps'
import vxCoordinators from './virtualExperiences'
import invoiceCoordinators from './invoice'
import * as trackJSCoordinators from './trackJS'
import notificationsCoodinators from './notifications'

export default Object.freeze({
  ...accountCoordinators,
  ...authCoordinators,
  ...batchOrderCoordinators,
  ...braintreeCoordinators,
  ...cafeCoordinators,
  ...chartCoordinators,
  ...chefCoordinators,
  ...chefNetworkCoordinators,
  ...dashboardCoordinators,
  ...errorsCoordinators,
  ...groupOrdersCoordinators,
  ...hubspotCoordinators,
  ...menuItemsSearchCoordinators,
  ...marketCoordinators,
  ...orderableCoordinators,
  ...orderCoordinators,
  ...popUpsCoordinators,
  ...proposalCoordinators,
  ...requestCoordinators,
  ...referralCoordinators,
  ...settingsCoordinators,
  ...supplyCoordinators,
  ...surveyCoordinators,
  ...userCoordinators,
  ...validationCoordinators,
  ...subscriptionCoordinators,
  ...mlbPartnersCoordinators,
  ...vxCoordinators,
  ...invoiceCoordinators,
  ...trackJSCoordinators,
  ...notificationsCoodinators,
})
