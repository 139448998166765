import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, LabelInfo, Input } from '@components/common/form'
import Modal from '@components/common/modal/Modal'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { ModalSectionTitle } from '@res/styledComponents/index'
import { AutocompleteInput } from '@containers/common/form'
import { roles } from './constants'
import { Label } from '@res/styledComponents/index'

class PopUpCustomerModal extends Component {
  state = {
    popUpCustomer: {},
    accountRoles: {},
  }
  loading = false

  componentWillMount() {
    let { popUpCustomer } = this.props
    const { newPopUpCustomer, headquarter } = this.props

    if (!popUpCustomer) {
      popUpCustomer = newPopUpCustomer({ headquarter })
    }

    this.setState({
      popUpCustomer,
      accountRoles: { ...popUpCustomer.accountRoles },
    })
  }

  componentWillUnmount() {
    this.props.searchCustomers()
  }

  onChangePhone = (value) => {
    value = value.replace(/[^\d() +]/g, '')
    this.onChangeCustomer('phone', value)
  }

  onChangeZipcode = (value) => {
    value = value.replace(/[^\d]/g, '').slice(0, 5)
    this.onChangeCustomer('primaryZip', value)
  }

  onChangeCustomer = (key, value) => {
    this.setState(({ popUpCustomer }) => ({
      popUpCustomer: {
        ...popUpCustomer,
        [key]: value,
      },
    }))
  }

  onSaveCustomer = async () => {
    if (this.loading) {
      return
    }
    this.loading = true
    const { savePopUpCustomer } = this.props
    const { popUpCustomer } = this.state
    const resp = await savePopUpCustomer(popUpCustomer)
    if (resp) {
      this.setState({ popUpCustomer: resp })
    }
    this.loading = false
  }

  onAddAccount = (accountId) => {
    this.setState(({ accountRoles }) => ({
      accountRoles: {
        ...accountRoles,
        [accountId]: [],
      },
    }))
  }

  onAddAccountRole = (accountId, role) => {
    this.setState(({ accountRoles }) => ({
      accountRoles: {
        ...accountRoles,
        [accountId]: accountRoles[accountId].includes(role)
          ? accountRoles[accountId]
          : [...accountRoles[accountId], role],
      },
    }))
  }

  onRemoveAccountRole = (accountId, role) => {
    this.setState(({ accountRoles }) => ({
      accountRoles: {
        ...accountRoles,
        [accountId]: accountRoles[accountId].filter((r) => r !== role),
      },
    }))
  }

  onUpsertAccountRole = async (accountId, newRoles) => {
    if (this.loading) {
      return
    }
    this.loading = true
    const { id: employeeId, accountRoles } = this.state.popUpCustomer
    const { headquarter, upsertAccountRole } = this.props
    const req = {
      accountId,
      accountRoles: {
        ...accountRoles,
        [accountId]: newRoles,
      },
      headquarter,
      employeeId,
    }
    const popUpCustomer = await upsertAccountRole(req)
    if (popUpCustomer) {
      this.setState({ popUpCustomer })
    }
    this.loading = false
  }

  render() {
    const {
      popUpCustomer: {
        id,
        firstName,
        lastName,
        email,
        phone,
        primaryZip,
        zipcodes,
        locationTracking,
        allowNotifications,
        accountRoles: originalRoles,
      },
      accountRoles,
    } = this.state
    const { hideModal, theme, accountMap, searchIndexAccounts } = this.props
    const title = id ? 'Edit Customer' : 'New Customer'

    const customerAccounts = Object.entries(accountRoles)
      .map(([accountId, rolesArr]) => {
        return {
          accountId,
          account: accountMap[accountId],
          rolesArr,
        }
      })
      .sort((a, b) => {
        const aKey = (a.account && a.account.name.toLowerCase()) || a.accountId
        const bKey = (b.account && b.account.name.toLowerCase()) || b.accountId

        return aKey < bKey ? -1 : 1
      })

    return (
      <Modal theme={theme} title={title} hideModal={hideModal} width="550px">
        <ModalSectionTitle>Customer Details</ModalSectionTitle>
        <YSpacing height="10px" />
        {id ? (
          <FlexContainer justifyContent="space-between">
            <LabelInfo width="48%" label="First Name" value={firstName} />
            <LabelInfo width="48%" label="Last Name" value={lastName} />
          </FlexContainer>
        ) : (
          <FlexContainer justifyContent="space-between">
            <Input
              label="First Name"
              marginBottom="0"
              width="48%"
              type="text"
              value={firstName}
              onChange={(e) =>
                this.onChangeCustomer('firstName', e.target.value)
              }
            />
            <Input
              label="Last Name"
              marginBottom="0"
              width="48%"
              type="text"
              value={lastName}
              onChange={(e) =>
                this.onChangeCustomer('lastName', e.target.value)
              }
            />
          </FlexContainer>
        )}
        <YSpacing height="20px" />
        {id ? (
          <FlexContainer justifyContent="space-between">
            <LabelInfo width="48%" label="Email" value={email} />
            <LabelInfo width="48%" label="Phone" value={phone} />
          </FlexContainer>
        ) : (
          <FlexContainer justifyContent="space-between">
            <Input
              label="Email"
              marginBottom="0"
              width="48%"
              type="text"
              value={email}
              onChange={(e) => this.onChangeCustomer('email', e.target.value)}
            />
            <Input
              label="Phone"
              marginBottom="0"
              width="48%"
              type="text"
              value={phone}
              onChange={(e) => this.onChangePhone(e.target.value)}
            />
          </FlexContainer>
        )}
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <Dropdown
            label="Allow Notifications"
            width="48%"
            value={allowNotifications}
            onChange={() =>
              this.onChangeCustomer('allowNotifications', !allowNotifications)
            }
          >
            <option key={0} value={true}>
              {' '}
              Yes{' '}
            </option>
            <option key={1} value={false}>
              {' '}
              No{' '}
            </option>
          </Dropdown>
          <Dropdown
            label="Location Tracking"
            width="48%"
            value={locationTracking}
            onChange={() =>
              this.onChangeCustomer('locationTracking', !locationTracking)
            }
          >
            <option key={0} value={true}>
              {' '}
              Enabled{' '}
            </option>
            <option key={1} value={false}>
              {' '}
              Not Enabled{' '}
            </option>
          </Dropdown>
        </FlexContainer>
        <FlexContainer justifyContent="space-between">
          <Input
            label="Primary Zip"
            marginBottom="0"
            width="48%"
            type="text"
            value={primaryZip}
            onChange={(e) => this.onChangeZipcode(e.target.value)}
          />
          <FlexContainer width="48%" flexDirection="column">
            <Label>Subscribed Zipcodes</Label>
            <YSpacing height="10px" />
            <div className="flex flex-row">
              {zipcodes.length
                ? zipcodes.map((zip) => (
                    <div key={zip} className="selected-zip">
                      {' '}
                      {zip}{' '}
                    </div>
                  ))
                : 'None'}
            </div>
          </FlexContainer>
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="flex-end">
          <Button label="Save Details" onClick={this.onSaveCustomer} />
        </FlexContainer>
        {id && (
          <div>
            <YSpacing height="30px" />
            <ModalSectionTitle>Account Roles</ModalSectionTitle>
            <YSpacing height="10px" />
            <FlexContainer width="48%" flexDirection="row">
              <div className="active-role">Active</div>
              <div className="inactive-role">Inactive</div>
            </FlexContainer>
            <AutocompleteInput
              label="Add Customer to Account"
              placeholder="Search Account"
              displayAttribute="nameAndPin"
              width="48%"
              optionsHeight="64"
              loaderFunction={async ({ search }) => {
                if (!search) {
                  return []
                }

                const foundAccounts = await searchIndexAccounts({ search })
                this.props.addAccountsToAccountMap(foundAccounts)

                return foundAccounts
              }}
              value={null}
              onSelect={(account) => this.onAddAccount(account.id)}
            />
            <YSpacing height="20px" />
            {customerAccounts.map(({ accountId, rolesArr, account }) => (
              <FlexContainer
                justifyContent="space-between"
                key={accountId}
                alignItems="flex-end"
              >
                <FlexContainer flexDirection="column">
                  <YSpacing height={5} />
                  <p className="bold mb-1">
                    {account ? `${account.name} (${account.pin})` : 'Unknown'}
                  </p>
                  <FlexContainer flexDirection="row">
                    {roles.map((role) => {
                      const active = rolesArr.includes(role)

                      return (
                        <div
                          key={role}
                          className={active ? 'active-role' : 'inactive-role'}
                          onClick={
                            active
                              ? () => this.onRemoveAccountRole(accountId, role)
                              : () => this.onAddAccountRole(accountId, role)
                          }
                        >
                          {role}
                        </div>
                      )
                    })}
                  </FlexContainer>
                </FlexContainer>
                <div>
                  {!originalRoles[accountId] && (
                    <Button
                      label="Create Roles"
                      onClick={() =>
                        this.onUpsertAccountRole(accountId, rolesArr)
                      }
                    />
                  )}
                  {originalRoles[accountId] &&
                    (originalRoles[accountId].some(
                      (r) => !rolesArr.includes(r),
                    ) ||
                      rolesArr.some(
                        (r) => !originalRoles[accountId].includes(r),
                      )) && (
                      <Button
                        label="Update Roles"
                        onClick={() =>
                          this.onUpsertAccountRole(accountId, rolesArr)
                        }
                      />
                    )}
                  <YSpacing height="20px" />
                </div>
              </FlexContainer>
            ))}
            {customerAccounts.length === 0 && 'None'}
          </div>
        )}
      </Modal>
    )
  }
}

PopUpCustomerModal.propTypes = {
  popUpCustomer: PropTypes.object,
  theme: PropTypes.object,
  headquarter: PropTypes.number,
  accountMap: PropTypes.object,

  addAccountsToAccountMap: PropTypes.func,
  hideModal: PropTypes.func,
  newPopUpCustomer: PropTypes.func,
  deletePopUpCustomer: PropTypes.func,
  savePopUpCustomer: PropTypes.func,
  searchIndexAccounts: PropTypes.func,
  searchCustomers: PropTypes.func,
  upsertAccountRole: PropTypes.func,
}

export default PopUpCustomerModal
