import { connect } from 'react-redux'

import Inventories from '@components/supply/Inventories'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { inventories } = state
  const { records, dates } = inventories

  return {
    inventories: records,
    dates,
  }
}

const mapDispatchToProps = () => {
  const {
    RestService,
    RouterService,
    SessionService,
    SupplyService,
    UIService,
  } = services
  const {
    pRequestUpdateInventory,
    pRequestInventoryTransactions,
    pResponseInventories,
    pResponseGeneric,
  } = presenters.Api
  const { pResponseErrorsArray } = presenters.Errors
  const {
    LoadInventories,
    LoadInventoryTransactions,
    ProcessTransactions,
    SaveInventory,
    HandleError: HandleError_,
  } = coordinators
  const HandleError = HandleError_({
    RouterService,
    SessionService,
    UIService,
    pResponseError: pResponseErrorsArray,
  })

  const loadInventories = LoadInventories({
    RestService,
    SupplyService,
    pResponseInventories,
    HandleError,
  })
  const loadInventoryTransactions = LoadInventoryTransactions({
    RestService,
    UIService,
    pRequestInventoryTransactions,
    pResponseInventoryTransactions: pResponseGeneric,
    HandleError,
  })
  const processTransactions = ProcessTransactions({
    RestService,
    SupplyService,
    pResponseInventories,
    HandleError,
  })
  const saveInventory = SaveInventory({
    LoadInventories,
    RestService,
    SupplyService,
    pRequestUpdateInventory,
    pResponseInventories,
    HandleError,
  })

  return {
    loadInventories,
    loadInventoryTransactions,
    processTransactions,
    saveInventory,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Inventories)
