import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { config } from 'hungry-core2'
import styled from '@emotion/styled'
import { FlexContainer } from '@components/common'
import { Label } from '@res/styledComponents/index'
import { Dropdown, DropDownOption } from '@components/common/form'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'

const ChefDirectory = (props) => {
  const [allHqs, setAllHqs] = useState([])
  const [selectedHqs, setSelectedHqs] = useState([])
  const [selectedFilter, setSelectedFilter] = useState('Active')

  useEffect(() => {
    const loadHqs = async () => {
      const hqs = await props.loadHeadquarters()
      if (hqs) {
        setAllHqs(hqs)
      }
    }
    loadHqs()
  }, [])

  const onAddHq = (e) => {
    if (e.target.value === 'All Markets') {
      setSelectedHqs([...selectedHqs, ...allHqs])
      setAllHqs([])
    } else {
      const hq = allHqs.find((hq) => hq.id == e.target.value)
      const newAllHqs = allHqs.reduce((acc, hq) => {
        if (hq.id == e.target.value) {
          return acc
        }
        acc.push(hq)

        return acc
      }, [])
      setSelectedHqs([...selectedHqs, hq])
      setAllHqs(newAllHqs)
    }
  }

  const onRemoveHq = (headquarter) => {
    const newSelected = selectedHqs.reduce((acc, hq) => {
      if (hq.id == headquarter.id) {
        return acc
      }
      acc.push(hq)

      return acc
    }, [])
    setSelectedHqs(newSelected)
    setAllHqs([...allHqs, headquarter])
  }

  const ChefCSVUrl = props.buildGetRequest(selectedHqs, selectedFilter)

  return (
    <FlexContainer flexDirection="column">
      {selectedHqs.length >= 1 && (
        <FlexContainer alignItems="center">
          <Label>Selected Headquarters: </Label>
          <XSpacing width="15px" />
          {selectedHqs.map((hq) => (
            <React.Fragment key={hq.id}>
              <DropDownOption value={hq.name} onRemove={() => onRemoveHq(hq)} />
              <XSpacing width="10px" />
            </React.Fragment>
          ))}
        </FlexContainer>
      )}
      <YSpacing height="20px" />
      <Dropdown
        label="Select Headquarter(s)"
        testId="select-headquarters"
        width="31%"
        value={allHqs}
        onChange={onAddHq}
      >
        <option>- Select -</option>
        {allHqs.length > 0 && <option>All Markets</option>}
        {allHqs.map((hq) => (
          <option key={hq.id} value={hq.id}>
            {hq.name}
          </option>
        ))}
      </Dropdown>
      <YSpacing height="10px" />
      <ChefStatusDropdown
        onChange={(e) => setSelectedFilter(e.target.value)}
        name="Chef Status"
      >
        {['Active', 'Inactive', 'All Chefs'].map((o) => (
          <option key={o} value={o} selected={o === selectedFilter}>
            {o}
          </option>
        ))}
      </ChefStatusDropdown>
      <YSpacing height="20px" />
      {selectedHqs.length >= 1 && (
        <a
          className="button-primary"
          href={` ${config.api_host}/api/admin/chefs/chef_report_csv${ChefCSVUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Chef CSV
        </a>
      )}
    </FlexContainer>
  )
}

const ChefStatusDropdown = styled.select`
  width: 31%;
  border: 1px solid gray;
  font-family: 'regular';
  background: #fafbff;
  padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  color: #3d4853;
  border-radius: 4px;
  option {
    color: gray;
  }
`

ChefDirectory.propTypes = {
  headquarters: PropTypes.array,
  headquarterId: PropTypes.number,

  buildGetRequest: PropTypes.func,
  loadHeadquarters: PropTypes.func,
}

export default ChefDirectory
