import { camelCaseify, formAdd } from '~/utils'
import { snakeCaseify } from '../../../utils'

export const pRequestChefSuggestions = (clientSetUpTime) => {
  return { date: clientSetUpTime.toDate() }
}

export const pRequestConceptSuggestions = (
  clientSetUpTime,
  filter,
  cuisineType,
  setUpStyle,
  newOrder,
  headCount,
) => {
  const req = {
    date: clientSetUpTime.toDate(),
    filter,
  }

  if (cuisineType) {
    req['cuisine_type'] = cuisineType
  }

  if (filter === 'At Capacity') {
    req['set_up_style'] = setUpStyle
    if (newOrder) {
      req['head_count'] = headCount
    }
  }

  return req
}

export const pRequestConcepts = (clientSetUpTime, cuisineType, concepts) => {
  const req = {
    date: clientSetUpTime.toDate(),
    concept_ids: concepts.map((concept) => concept.id),
  }

  if (cuisineType) {
    req['cuisine_type'] = cuisineType
  }

  return req
}

export const pRequestUpdateChef = (data) => {
  const req = {}

  if (data.id) {
    formAdd(data, req, 'id', 'id')
  } else {
    const randomPassword = Math.floor(Math.random() * 10000000)
    req.password = randomPassword.toString() + 'z'
    req.password_confirmation = randomPassword.toString() + 'z'
  }
  formAdd(data, req, 'firstName', 'first_name')
  formAdd(data, req, 'lastName', 'last_name')
  formAdd(data, req, 'email', 'email')
  formAdd(data, req, 'phoneNumber', 'phone_number')
  formAdd(data, req, 'payToName', 'pay_to_name')

  const chefProfileAttributes = {}
  formAdd(data, chefProfileAttributes, 'accountName', 'account_name')
  formAdd(data, chefProfileAttributes, 'accomplishments', 'accomplishments')
  formAdd(data, chefProfileAttributes, 'addOnChef', 'add_on_chef')
  formAdd(data, chefProfileAttributes, 'yearsCooking', 'years_cooking')
  formAdd(data, chefProfileAttributes, 'vendorOnly', 'vendor_only')
  formAdd(data, chefProfileAttributes, 'chefType', 'chef_type')
  formAdd(data, chefProfileAttributes, 'isBusiness', 'is_business')
  formAdd(data, chefProfileAttributes, 'status', 'status')
  formAdd(
    data,
    chefProfileAttributes,
    'chefPriorityType',
    'chef_priority_type',
    (v) => (v === 'None' ? null : v),
  )
  formAdd(data, chefProfileAttributes, 'description', 'description')
  formAdd(data, chefProfileAttributes, 'experience', 'experience')
  formAdd(data, chefProfileAttributes, 'hideFromMapView', 'hide_from_map_view')
  formAdd(data, chefProfileAttributes, 'hometown', 'hometown')
  formAdd(data, chefProfileAttributes, 'maxBuffet', 'max_buffet')
  formAdd(data, chefProfileAttributes, 'maxIndividual', 'max_individual')
  formAdd(
    data,
    chefProfileAttributes,
    'maxGroupOrderItems',
    'max_group_order_items',
  )
  formAdd(
    data,
    chefProfileAttributes,
    'minCateringPayout',
    'min_catering_payout',
    (v) => Number(v || 0), // value cannot by null in MySQL
  )
  formAdd(data, chefProfileAttributes, 'onSiteFee', 'on_site_fee', (v) =>
    Number(v || 0).toFixed(2),
  ) // value cannot by null in MySQL
  formAdd(data, chefProfileAttributes, 'rating', 'rating')
  formAdd(data, chefProfileAttributes, 'yearsCooking', 'years_cooking')
  formAdd(data, chefProfileAttributes, 'advanceHrsNotice', 'advance_hrs_notice')
  formAdd(data, chefProfileAttributes, 'customPath', 'custom_path')
  formAdd(data, chefProfileAttributes, 'isVcx', 'is_vcx')
  formAdd(data, chefProfileAttributes, 'logoUrl', 'logo_url')
  formAdd(
    data,
    chefProfileAttributes,
    'customMenuImageUrl',
    'custom_menu_image_url',
  )
  formAdd(data, chefProfileAttributes, 'videoLink', 'video_link')
  formAdd(
    data,
    chefProfileAttributes,
    'groupOrderEarliestPickupTime',
    'group_order_earliest_pickup_time',
  )
  formAdd(
    data,
    chefProfileAttributes,
    'cateringEarliestPickupTime',
    'catering_earliest_pickup_time',
  )
  formAdd(data, chefProfileAttributes, 'processingFee', 'processing_fee')
  formAdd(
    data,
    chefProfileAttributes,
    'processingFeeReason',
    'processing_fee_reason',
  )
  formAdd(
    data,
    chefProfileAttributes,
    'communicationPreferences',
    'communication_preferences',
  )
  req.chef_profile_attributes = chefProfileAttributes

  const addressAttributes = {}
  formAdd(data, addressAttributes, 'pickupAddressId', 'id')
  formAdd(data, addressAttributes, 'allowTolls', 'allow_tolls')
  formAdd(
    data,
    addressAttributes,
    'buildingInstructions',
    'hungry_building_instructions',
  )
  formAdd(data, addressAttributes, 'line1', 'line1')
  formAdd(data, addressAttributes, 'line2', 'line2')
  formAdd(data, addressAttributes, 'city', 'city')
  formAdd(data, addressAttributes, 'state', 'state')
  formAdd(data, addressAttributes, 'zip', 'zip')

  if (data.updatedPickupAddress) {
    req.addresses_attributes = []
    req.chef_addresses_attributes = []
    if (Object.keys(addressAttributes).length !== 0) {
      if (data.pickupAddressId) {
        const addressesAttributes = [addressAttributes]
        req.addresses_attributes = addressesAttributes
      } else {
        const chefAddressesAttributes = [
          { address_attributes: addressAttributes },
        ]
        req.chef_addresses_attributes = chefAddressesAttributes
      }
    }
  } else if (data.id && addressAttributes['line1'] != '') {
    req.skip_address_validation = true
  }

  const payoutMethodAttributes = {}
  if (data.payoutMethodId) {
    formAdd(data, payoutMethodAttributes, 'payoutMethodId', 'id')
  }
  formAdd(data, payoutMethodAttributes, 'isBusiness', 'is_business')
  formAdd(data, payoutMethodAttributes, 'firstName', 'first_name')
  formAdd(data, payoutMethodAttributes, 'lastName', 'last_name')
  formAdd(data, payoutMethodAttributes, 'email', 'email')
  formAdd(data, payoutMethodAttributes, 'phoneNumber', 'phone_number')

  formAdd(
    data,
    payoutMethodAttributes,
    'payoutMethodStreetAddress',
    'street_address',
  )
  formAdd(data, payoutMethodAttributes, 'payoutMethodCity', 'city')
  formAdd(data, payoutMethodAttributes, 'payoutMethodState', 'state')
  formAdd(data, payoutMethodAttributes, 'payoutMethodZip', 'zip')
  formAdd(data, payoutMethodAttributes, 'routingNumber', 'routing_number')
  formAdd(
    data,
    payoutMethodAttributes,
    'accountNumberLast4',
    'account_number_last_4',
  )
  if (data.isBusiness) {
    formAdd(data, payoutMethodAttributes, 'businessName', 'business_name')
    formAdd(
      data,
      payoutMethodAttributes,
      'doingBusinessAs',
      'doing_business_as',
    )
    formAdd(
      data,
      payoutMethodAttributes,
      'etinOrFeinLast4',
      'etin_or_fein_last_4',
    )
  } else {
    formAdd(data, payoutMethodAttributes, 'ssnLast4', 'ssn_last_4')
  }
  //req.payout_method_attributes = payoutMethodAttributes

  req.child_chef_relationships_attributes =
    pRequestUpdateChildChefRelationships(data)

  return req
}

export const pRequestUpdateChildChefRelationships = (data) => {
  const childChefs = data.childChefs || []
  const childChefRelationships = data.childChefRelationships || []
  const joinAttributes = []

  childChefs.forEach((child) => {
    if (
      !data.childChefRelationships ||
      !data.childChefRelationships.find((join) => join.childChefId === child.id)
    ) {
      joinAttributes.push({
        child_chef_id: child.id,
      })
    }
  })

  childChefRelationships.forEach((join) => {
    if (!data.childChefs.find((child) => child.id === join.childChefId)) {
      joinAttributes.push({
        id: join.id,
        child_chef_id: join.childChefId,
        _destroy: true,
      })
    }
  })

  return joinAttributes.length > 0 ? joinAttributes : null
}

// TODO - Combine chef images presenters to one
export const pRequestUpdateChefImage = (data) => {
  if (data.image) {
    const { image } = data
    const req = new FormData()
    if (image.id && image._destroy) {
      req.append(
        'chef[chef_profile_attributes][image_attributes][id]',
        image.id,
      )
      req.append(
        'chef[chef_profile_attributes][image_attributes][_destroy]',
        true,
      )
    } else if (!image.id) {
      req.append(
        'chef[chef_profile_attributes][image_attributes][image]',
        image,
      )
    }

    return req
  } else {
    return undefined
  }
}

export const pResponseChef = (json) => {
  const chef = camelCaseify(json)

  if (chef.chefProfile) {
    const { chefProfile } = chef
    chef.accomplishments = chefProfile.accomplishments
    chef.accountName = chefProfile.accountName
    chef.advanceHrsNotice = chefProfile.advanceHrsNotice
    chef.addOnChef = chefProfile.addOnChef
    chef.isBusiness = chefProfile.isBusiness
    chef.chefPriorityType = chefProfile.chefPriorityType || 'None'
    chef.status = chefProfile.status
    chef.description = chefProfile.description
    chef.experience = chefProfile.experience
    chef.hideFromMapView = chefProfile.hideFromMapView
    chef.hometown = chefProfile.hometown
    chef.maxBuffet = chefProfile.maxBuffet
    chef.maxIndividual = chefProfile.maxIndividual
    chef.maxGroupOrderItems = chefProfile.maxGroupOrderItems
    chef.minCateringPayout = chefProfile.minCateringPayout
    chef.onSiteFee = chefProfile.onSiteFee
    chef.rating = chefProfile.rating
    chef.vendorOnly = chefProfile.vendorOnly
    chef.chefType = chefProfile.chefType
    chef.yearsCooking = chefProfile.yearsCooking
    chef.customPath = chefProfile.customPath
    chef.isVcx = chefProfile.isVcx
    chef.logoUrl = chefProfile.logoUrl
    chef.customMenuImageUrl = chefProfile.customMenuImageUrl
    chef.videoLink = chefProfile.videoLink
    chef.groupOrderEarliestPickupTime = chefProfile.groupOrderEarliestPickupTime
    chef.cateringEarliestPickupTime = chefProfile.cateringEarliestPickupTime
    chef.processingFee =
      chefProfile.processingFee != null
        ? parseFloat(chefProfile.processingFee)
        : chefProfile.processingFee
    chef.processingFeeReason = chefProfile.processingFeeReason
    chef.communicationPreferences = chefProfile.communicationPreferences
    if (chefProfile.image) {
      chef.chefProfile.headshotThumb = chefProfile.image.thumborHeadshotUrl
    }
  }

  if (chef.pickupAddress) {
    const address = chef.pickupAddress
    chef.pickupAddressId = address.id
    chef.line1 = address.line1
    chef.line2 = address.line2
    chef.city = address.city
    chef.state = address.state
    chef.zip = address.zip
    chef.allowTolls = address.allowTolls
    chef.buildingInstructions = address.hungryBuildingInstructions
  }

  if (chef.payoutMethod) {
    const { payoutMethod } = chef
    chef.payoutMethodId = payoutMethod.id
    chef.payoutMethodFirstName = payoutMethod.firstName
    chef.payoutMethodLastName = payoutMethod.lastName
    chef.payoutMethodEmail = payoutMethod.email
    chef.payoutMethodPhoneNumber = payoutMethod.phoneNumber
    chef.payoutMethodStreetAddress = payoutMethod.streetAddress
    chef.payoutMethodCity = payoutMethod.city
    chef.payoutMethodState = payoutMethod.state
    chef.payoutMethodZip = payoutMethod.zip
    chef.payoutMethodStatus = payoutMethod.status
    chef.routingNumber = payoutMethod.routingNumber
    chef.accountNumberLast4 = payoutMethod.accountNumberLast4
    chef.businessName = payoutMethod.businessName
    chef.doingBusinessAs = payoutMethod.doingBusinessAs
    chef.etinOrFeinLast4 = payoutMethod.etinOrFeinLast4
    chef.ssnLast4 = payoutMethod.ssnLast4
  }

  return chef
}

export const pResponseChefs = (json) => {
  const chefs = json.map((j) => ({
    id: j.id,
    name: j.name,
    pickupAddress: j.pickup_address,
    hungryChef: j.hungry_chef,
    addOnChef: j.add_on_chef,
    minCateringPayout: j.min_catering_payout,
  }))

  return chefs
}

export const pResponseMenuItems = (json) => {
  const menuItems = camelCaseify(json)

  menuItems.forEach((i) => {
    i.chefPrice = i.cost
    i.marketPrice = i.price
    i.retailPrice = i.retail_price
    delete i.cost
    delete i.price
    delete i.retail_price
  })

  const mainMenuItems = []
  const sideMenuItems = []
  const otherMenuItems = []
  for (const item of menuItems) {
    switch (item.mealType) {
      case 'Side':
        sideMenuItems.push(item)
        break
      case 'Main':
        mainMenuItems.push(item)
        break
      case 'Vegetable Side':
        sideMenuItems.push(item)
        break
      case 'Starch Side':
        sideMenuItems.push(item)
        break
      default:
        otherMenuItems.push(item)
    }
  }

  return { mainMenuItems, sideMenuItems, otherMenuItems }
}

export const pRequestLandingChefs = (params) => {
  if (params.marketType === 'pop-ups') {
    params.marketType = 'Pop-Up'
  } else if (params.marketType === 'group orders') {
    params.marketType = 'Group Order'
  } else if (params.marketType === 'catering') {
    params.marketType = 'Office'
  }

  return snakeCaseify(params)
}

export const pBuildChefReportCSVRequest = (hqs, filter) => {
  let str = '?csv=true'
  if (filter === 'Active') {
    str += `&selected_filter=${filter.toLowerCase()}`
  } else if (filter === 'All Chefs') {
    str += `&selected_filter=active,inactive,trial,review`
  } else {
    str += `&selected_filter=inactive,trial,review`
  }
  const hqIds = []
  hqs.forEach((hq) => hqIds.push(hq.id))
  str += `&selected_hqs=${hqIds}`

  return str
}
