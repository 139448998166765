import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

const Timezone = ({ locale }) => {
  useEffect(() => {
    if (locale) {
      console.log('setting timezone', locale)
      Moment.tz.setDefault(locale)
    }
  }, [locale])

  return <div />
}

const mapStateToProps = (state) => {
  const { user } = state
  const { locale } = user || {}

  return {
    locale,
  }
}

Timezone.propTypes = {
  locale: PropTypes.string,
}

export default connect(mapStateToProps, undefined)(Timezone)
