import { connect } from 'react-redux'

import { Schedule } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { chef, chefSchedule, user } = state
  const { disabledWeeks, recurringSlotInfo, recurringSlots, timeSlots } =
    chefSchedule
  const authorized = presenters.isAuthorized(user, [
    'master admin',
    'chef lead',
  ])

  return {
    authorized,
    chefId: chef.id,
    disabledWeeks,
    recurringSlotInfo,
    recurringSlots,
    timeSlots,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, AdminUserService } = services
  const {
    pGenerateRecurring,
    pRequestTimeSlotMonth,
    pRequestTimeWeeks,
    pResponseAllTimeSlots,
  } = presenters.Api
  const { LoadChefScheduleTimeSlots } = coordinators

  const loadTimeSlots = LoadChefScheduleTimeSlots({
    ChefService,
    RestService,
    AdminUserService,
    pGenerateRecurring,
    pRequestTimeWeeks,
    pRequestTimeSlotMonth,
    pResponseAllTimeSlots,
    HandleError,
  })

  return {
    loadTimeSlots,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)
