import { SET_DASHBOARD_SURVEYS, SET_SURVEYS } from '@actions/survey'

const initialDashbaordSurveysState = []
const initialSurveysState = {
  headers: [],
  surveys: [],
}

export const surveys = (state = initialSurveysState, action) => {
  const { type } = action

  switch (type) {
    case SET_SURVEYS:
      return { ...action.surveyInfo }
    default:
      return state
  }
}

export const surveysDashboard = (
  state = initialDashbaordSurveysState,
  action,
) => {
  const { type } = action

  switch (type) {
    case SET_DASHBOARD_SURVEYS:
      return action.surveys.slice()
    default:
      return state
  }
}
