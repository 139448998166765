import { connect } from 'react-redux'

import { ProposalPage } from '@components/proposal'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'

const mapStateToProps = (state) => {
  const proposalNumber = state.proposal && state.proposal.name
  const filters = ['created', 'final']

  return {
    filters,
    orderableType: 'Proposal',
    proposalNumber,
  }
}

const mapDispatchToProps = () => {
  const { ProposalService, RestService, RouterService, UIService } = services
  const { LoadQueryProposal } = coordinators
  const { pResponseProposal } = presenters.Api

  const checkQueryProposal = LoadQueryProposal({
    ProposalService,
    RestService,
    RouterService,
    pResponseProposal,
  })
  const newProposal = () => {
    ProposalService.clearNewOrderable()
    UIService.EditProposal.show('new')
  }

  return {
    checkQueryProposal,
    newProposal,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalPage)
