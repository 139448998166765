import { BaseService } from 'hungry-core2'

import actions from '@actions'

export default class ProposalDashboard extends BaseService {
  setLastLoadProposalDashboardParams = ({
    page,
    search,
    status,
    fromDate,
    toDate,
  }) => {
    this.dispatch(
      actions.setLastLoadProposalDashboardParams({
        page,
        search,
        status,
        fromDate,
        toDate,
      }),
    )
  }

  getLastLoadProposalDashboardParams = () => {
    const { page, search, status, fromDate, toDate } =
      this.getState().ProposalDashboardParams

    return { page, search, status, fromDate, toDate }
  }
}
