import { connect } from 'react-redux'

import OrderServiceItem from '@components/order/edit/sections/OrderServiceItem'

import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { user } = state

  return { user }
}

const mapDispatchToProps = () => {
  const { isAuthorized } = presenters
  const { UIService } = services
  const displayAlert = ({ message, title }) =>
    UIService.Alert.showPopup({ message, title })

  return {
    displayAlert,
    isAuthorized,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderServiceItem)
