import { connect } from 'react-redux'

import { RecurringPopUpsPage } from '@components/popUps'

import coordinators from '@coordinators'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { lastLoggedInHq } = state.user

  return {
    headquarter: lastLoggedInHq,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService } = services
  const { GetRecurringPopUp, SearchRecurringPopUps, CreatePopUpFromTimeSlot } =
    coordinators
  const getRecurringPopUp = GetRecurringPopUp({
    RestService,
    HandleError,
  })
  const searchRecurringPopUps = SearchRecurringPopUps({
    RestService,
    HandleError,
  })
  const createPopUpFromTimeSlot = CreatePopUpFromTimeSlot({
    RestService,
    UIService,
    HandleError,
  })
  const { displayWarningMessage } = UIService.FlashMessage

  return {
    getRecurringPopUp,
    searchRecurringPopUps,
    createPopUpFromTimeSlot,
    displayWarningMessage,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecurringPopUpsPage)
