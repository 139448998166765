import { BaseService } from 'hungry-core2'

import actions from '@actions'

export default class ConfirmationModal extends BaseService {
  close = () => {
    this.dispatch(actions.closeConfirmationModal())
    this.resolve && this.resolve(false)
    delete this.resolve
  }

  confirm = () => {
    this.dispatch(actions.closeConfirmationModal())
    this.resolve && this.resolve(true)
    delete this.resolve
  }

  show = ({ title, text }) => {
    return new Promise((resolve) => {
      this.resolve = resolve
      this.dispatch(actions.showConfirmationModal({ title, text }))
    })
  }
}
