import React, { useEffect, useRef } from 'react'
import Moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Checkbox } from '@components/common/form'
import XSpacing from '@components/common/XSpacing'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { Label } from '@res/styledComponents/index'
import { MarketTypes } from '@constants'

const MenuItemVerifications = ({
  menuItem,
  onChange,
  pToMenuItemVerifications,
}) => {
  const {
    marketType,
    descriptionVerified,
    descriptionVerifiedAt,
    descriptionVerifier,
    allergensVerified,
    allergensVerifiedAt,
    allergensVerifier,
    images,
    imageVerified,
    imageVerifiedAt,
    imageVerifier,
    servingDetailsVerified,
    servingDetailsVerifiedAt,
    servingDetailsVerifier,
  } = menuItem
  const isGroupOrderMenuItem = marketType == MarketTypes.GROUP_ORDER
  const verifications = pToMenuItemVerifications(menuItem)
  const verificationsCompleted = verifications.filter((v) => v).length
  const applicableVerifications = verifications.length
  const allVerificationsCompleted =
    verificationsCompleted === applicableVerifications
  const allergensAndDescriptionVerified =
    menuItem.allergensVerified && menuItem.descriptionVerified
  const imageTagDisabled = images.filter((i) => !i.destroy).length === 0
  const initialValues = useRef({
    descriptionVerified,
    imageVerified,
    allergensVerified,
    servingDetailsVerified,
  })

  useEffect(() => {
    initialValues.current = {
      descriptionVerified,
      imageVerified,
      allergensVerified,
      servingDetailsVerified,
    }
  }, [])

  const hasChanged = (field) => menuItem[field] !== initialValues.current[field]

  const renderVerificationDetails = (approved, date, verifier, field) => {
    // Do not render approved details if verified values have changed
    const changed = hasChanged(field)
    if (!approved || !date || !verifier || changed) {
      return <></>
    } else {
      return (
        <p>
          Approved by {`${verifier.firstName} ${verifier.lastName}`}{' '}
          {verifier.email} on {Moment(date).format('MM/DD/YYYY')}
        </p>
      )
    }
  }

  const onCheckboxChange = (field) => (event) => {
    const { checked } = event.target

    onChange({
      ...menuItem,
      [field]: checked,
    })
  }

  const onSelectAll = () => {
    const newValues = { ...menuItem }
    newValues.descriptionVerified = true
    newValues.allergensVerified = true
    newValues.servingDetailsVerified = true
    if (isGroupOrderMenuItem) {
      newValues.imageVerified = true
    }
    onChange(newValues)
  }

  return (
    <FlexContainer flexDirection="column">
      <FlexContainer>
        <Label>Menu Item Audit</Label>
        <XSpacing width="10px" />
        <p
          style={{
            color: allVerificationsCompleted
              ? '#4ad54a'
              : allergensAndDescriptionVerified
                ? 'orange'
                : 'red',
          }}
        >{`${verificationsCompleted}/${applicableVerifications} Completed`}</p>
      </FlexContainer>
      <FlexContainer
        flexDirection="row"
        justifyContent="start"
        alignItems="start"
      >
        <Checkbox
          label="Menu Item Description Verified"
          checked={descriptionVerified}
          onChange={onCheckboxChange('descriptionVerified')}
          width="30%"
        />
        {renderVerificationDetails(
          descriptionVerified,
          descriptionVerifiedAt,
          descriptionVerifier,
          'descriptionVerified',
        )}
      </FlexContainer>
      <YSpacing height="5px" />
      <FlexContainer>
        <Checkbox
          label="Allergen Tags Verified"
          checked={allergensVerified}
          onChange={onCheckboxChange('allergensVerified')}
          width="30%"
        />
        {renderVerificationDetails(
          allergensVerified,
          allergensVerifiedAt,
          allergensVerifier,
          'allergensVerified',
        )}
      </FlexContainer>
      <YSpacing height="5px" />
      <FlexContainer>
        <Checkbox
          label="Serving Details Verified"
          checked={servingDetailsVerified}
          onChange={onCheckboxChange('servingDetailsVerified')}
          width="30%"
        />
        {renderVerificationDetails(
          servingDetailsVerified,
          servingDetailsVerifiedAt,
          servingDetailsVerifier,
          'servingDetailsVerified',
        )}
      </FlexContainer>
      <YSpacing height="5px" />
      {isGroupOrderMenuItem && (
        <FlexContainer>
          <Checkbox
            label="Actual Image Verified"
            checked={imageVerified}
            onChange={onCheckboxChange('imageVerified')}
            width="30%"
            disabled={imageTagDisabled}
          />
          {renderVerificationDetails(
            imageVerified,
            imageVerifiedAt,
            imageVerifier,
            'imageVerified',
          )}
        </FlexContainer>
      )}
      <YSpacing height="5px" />
      <Checkbox
        label="Select All"
        checked={allVerificationsCompleted}
        onChange={() => onSelectAll()}
      />
    </FlexContainer>
  )
}

MenuItemVerifications.propTypes = {
  menuItem: PropTypes.object,
  onChange: PropTypes.func,
  pToMenuItemVerifications: PropTypes.func,
}

export default MenuItemVerifications
