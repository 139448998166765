import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Loader from '@components/common/Loader'
import { PageBody } from '@res/styledComponents/index'
import FlexContainer from '@components/common/FlexContainer'
import Sidebar from '@components/sidebar/Sidebar'
import SidebarSearch from '@components/sidebar/SidebarSearch'
import SidebarListItem from '@components/sidebar/SidebarListItem'
import Panel from '@components/common/Panel'
import DividerLine from '@components/common/DividerLine'
import Button from '@components/common/form/Button'
import Input from '@components/common/form/Input'
import LabelInfo from '@components/common/form/LabelInfo'
import { colors } from '../../../constants'

class CafeMaster extends Component {
  state = {
    accounts: [],
    account: undefined,
    error: undefined,
    search: '',
    menuPDF: undefined,
    cafeMenuId: '',
    imageURL: undefined,
    showLoader: false,
  }
  searchTimer = undefined

  componentDidMount() {
    const { client } = this.props
    const newState = { account: client }
    this.setState(newState)
  }

  onSearchClients = (e) => {
    const search = e.target.value
    this.setState({ search }, () => {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(async () => {
        const accounts = await this.props.loadAccounts({
          search,
          account_serializer: true,
        })
        this.setState({ accounts })
      }, 550)
    })
  }

  onChangeURL = async (e) => {
    const { getCafePDFMenu } = this.props
    const search = e.target.value
    this.setState({ clientURL: search }, () => {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(async () => {
        const pdfMenu = await getCafePDFMenu(search)
        if (!pdfMenu) {
          this.setState({ cafeMenuId: '' })
        } else {
          this.setState({ cafeMenuId: pdfMenu.id })
        }
      }, 550)
    })
  }

  onSaveMenu = async () => {
    const { headquarter, saveCafeMenu } = this.props
    const { account, menuPDF, clientURL, cafeMenuId } = this.state
    const menuRequest = {
      id: cafeMenuId,
      clientName: account.name,
      accountId: account.id,
      headquarter,
      menu: menuPDF,
      url: clientURL,
    }

    await saveCafeMenu(menuRequest)
  }

  onUploadImage = (file) => {
    const imageURL = URL.createObjectURL(file.files[0])
    this.setState({ menuPDF: file.files[0], imageURL })
  }

  renderSideBar() {
    const { account, accounts } = this.state

    return (
      <Sidebar heading="Cafe & Coffee Bars">
        <SidebarSearch label="Search Clients" onChange={this.onSearchClients} />
        <div className="sidebar-scroll">
          {accounts.map((acct) => {
            const selected = account && acct.id === account.id

            return (
              <SidebarListItem
                key={acct.id}
                isSelected={selected}
                onClick={() => {
                  this.setState({ account: acct })
                }}
              >
                {acct.name}
              </SidebarListItem>
            )
          })}
        </div>
      </Sidebar>
    )
  }

  render() {
    const { account, cafeMenuId, imageURL, clientURL } = this.state
    const { isLoading, uri } = this.props

    return (
      <PageBody>
        {this.renderSideBar()}
        {/* PANEL */}
        <div className="sidebar-body">
          <div className="sidebar-body-inner">
            <Panel
              width="100%"
              maxWidth="550px"
              heading={
                account ? `Menu For ${account.name}` : 'Upload Cafe Menu'
              }
            >
              {account ? (
                <MenuContainer>
                  <FlexContainer
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Input
                      label={`URL for menu: ${uri}/${clientURL}`}
                      placeholder=""
                      marginBottom="10px"
                      value={clientURL}
                      onChange={(e) => this.onChangeURL(e)}
                    />
                  </FlexContainer>
                  <p className="instructions">
                    View the menu here:{' '}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`${uri}/${clientURL}`}
                    >
                      {uri}/{clientURL}
                    </a>
                  </p>
                  <p className="instructions">
                    Make a free QR code of the menu link{' '}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.the-qrcode-generator.com/"
                    >
                      here
                    </a>
                    .
                  </p>
                  <DividerLine margin="20px 0" />
                  <LabelInfo
                    width="100%"
                    label="Upload Menu PDF"
                    marginBottom="20px"
                    value=""
                  />
                  <input
                    type="file"
                    className="file-upload-input"
                    ref={(ref) => (this.pdfImage = ref)}
                    onChange={() => this.onUploadImage(this.pdfImage)}
                  />
                  {imageURL && (
                    <embed className="pdf-view" src={imageURL} alt="menu" />
                  )}
                  {isLoading ? (
                    <Loader isCenter={true} />
                  ) : (
                    <Button
                      margin="30px 0 0 0"
                      label={cafeMenuId ? 'Save Updated Menu' : 'Save New Menu'}
                      onClick={this.onSaveMenu}
                    />
                  )}
                </MenuContainer>
              ) : (
                <p>Select client to upload menu</p>
              )}
            </Panel>
          </div>
        </div>
      </PageBody>
    )
  }
}

const MenuContainer = styled.div`
  text-align: center;
  h1 {
    font-family: 'bold';
    margin-bottom: 20px;
    font-size: 18px;
    color: ${colors.gray600};
  }
  .file-upload-input {
    margin-bottom: 10px;
  }
  .pdf-view {
    width: 100%;
    height: 400px;
  }
  .instructions {
    text-align: left;
    a {
      color: ${colors.blue400};
    }
  }
  /* .file-upload-input {
    display: none;
  }
  .file-instructions {
    color: ${colors.gray500};
    text-align: center;
  }
  .file-upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dotted ${colors.gray300};
    background: ${colors.gray100};
    border-radius: 4px;
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
  } */
`

CafeMaster.propTypes = {
  loadAccounts: PropTypes.func,
  saveCafeMenu: PropTypes.func,
  getCafePDFMenu: PropTypes.func,

  client: PropTypes.object,
  headquarter: PropTypes.number,
  isLoading: PropTypes.bool,
  uri: PropTypes.string,
}

export default CafeMaster
