import { connect } from 'react-redux'

import { ChefConfirmations } from '@components/chefNetwork'
import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = () => {
  const { RestService, ChefService } = services
  const { LoadChefConfirmations, LoadChefs } = coordinators
  const { pResponseChefs } = presenters.Api

  const loadChefConfirmations = LoadChefConfirmations({
    RestService,
    HandleError,
  })
  const loadChefs = LoadChefs({ RestService, ChefService, pResponseChefs })

  return {
    loadChefConfirmations,
    loadChefs,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChefConfirmations)
