import React, { Component } from 'react'

import { Tabs, Tab } from 'react-bootstrap'

import {
  CateringSupplies,
  DiningSupplies,
  OrderBreakdown,
  Packaging,
  PackagingAddOns,
  ServingUtensils,
  Inventories,
} from '@containers/supply'

export class SupplyDetail extends Component {
  state = {
    activeKey: '#inventories',
  }

  render() {
    const { activeKey } = this.state

    return (
      <div className="children-container__inner">
        <div className="tabs-container">
          <Tabs
            className="tabs"
            activeKey={activeKey}
            onSelect={(key) => {
              this.setState({ activeKey: key })
            }}
            mountOnEnter={true}
            unmountOnExit={true}
            animation={false}
          >
            <Tab
              id="supply-tab-inventories"
              eventKey={'#inventories'}
              title="Inventories"
            >
              <Inventories />
            </Tab>

            <Tab
              id="supply-tab-packaging"
              eventKey={'#packaging'}
              title="Packaging"
            >
              <Packaging />
            </Tab>

            <Tab
              id="supply-tab-packaging-add-ons"
              eventKey={'#packagingAddOns'}
              title="Packaging Add Ons"
            >
              <PackagingAddOns />
            </Tab>

            <Tab
              id="supply-tab-serving-utensils"
              eventKey={'#servingUtensils'}
              title="Serving Utensils"
            >
              <ServingUtensils />
            </Tab>

            <Tab
              id="supply-tab-catering-supplies"
              eventKey={'#cateringSupplies'}
              title="Catering Supplies"
            >
              <CateringSupplies />
            </Tab>

            <Tab
              id="supply-tab-dining-supplies"
              eventKey={'#diningSupplies'}
              title="Dining Supplies"
            >
              <DiningSupplies />
            </Tab>

            <Tab
              id="supply-tab-order-breakdown"
              eventKey={'#orderBreakdown'}
              title="Order Breakdown"
            >
              <OrderBreakdown />
            </Tab>
          </Tabs>
        </div>
      </div>
    )
  }
}

export default SupplyDetail
