import { BaseService } from 'hungry-core2'
import { statuses } from '../../view/components/popUps/constants'

export class PopUpService extends BaseService {
  newPopUp = ({ headquarter }) => ({
    id: undefined,
    headquarter,
    client: undefined,
    menu: undefined,
    date: '',
    start: undefined,
    end: undefined,
    location: undefined,
    status: statuses[0],
    marketSubtotal: 0.0,
    chefSubtotal: 0.0,
    isAccepted: -1,
    declineReason: '',
    cnStatus: false,
  })

  newMenu = () => ({
    id: undefined,
    chef: undefined,
    cuisines: [],
    image: '',
    entrees: [],
    sides: [],
    drinks: [],
    desserts: [],
  })
}
