import {
  SET_HUBSPOT_CONTACTS,
  SET_HUBSPOT_CONTACT,
  SET_HUBSPOT_COMPANIES,
  SET_HUBSPOT_COMPANY,
  SET_HUBSPOT_COMPANY_IN_COMPANIES,
  SET_HUBSPOT_USERS,
  SET_HUBSPOT_USER,
  SET_HUBSPOT_DEALS,
  SET_HUBSPOT_DEAL,
} from '@actions/hubspot'

const initialListState = []
const initialSingleState = {}

export const hubspotContacts = (state = initialListState, action) => {
  const { type } = action

  switch (type) {
    case SET_HUBSPOT_CONTACTS:
      return action.contacts.slice()
    default:
      return state
  }
}

export const hubspotContact = (state = initialSingleState, action) => {
  const { type } = action

  switch (type) {
    case SET_HUBSPOT_CONTACT:
      return { ...action.contact }
    default:
      return state
  }
}

export const hubspotCompanies = (state = initialListState, action) => {
  const { type } = action

  switch (type) {
    case SET_HUBSPOT_COMPANIES:
      return action.companies.slice()
    case SET_HUBSPOT_COMPANY_IN_COMPANIES:
      return state
        .map((c) => {
          if (c.id === action.company.id) {
            // updating company seems to return only the changed fields
            const cleanCompany = Object.entries(action.company)
              .filter(([_, v]) => v !== undefined)
              .reduce((company, [k, v]) => {
                company[k] = v

                return company
              }, {})

            return { ...c, ...cleanCompany }
          }

          return c
        })
        .slice()
    default:
      return state
  }
}

export const hubspotCompany = (state = initialSingleState, action) => {
  const { type } = action

  switch (type) {
    case SET_HUBSPOT_COMPANY:
      return { ...action.company }
    default:
      return state
  }
}

export const hubspotUsers = (state = initialListState, action) => {
  const { type } = action

  switch (type) {
    case SET_HUBSPOT_USERS:
      return action.users.slice()
    default:
      return state
  }
}

export const hubspotUser = (state = initialSingleState, action) => {
  const { type } = action

  switch (type) {
    case SET_HUBSPOT_USER:
      return { ...action.user }
    default:
      return state
  }
}

export const hubspotDeals = (state = initialListState, action) => {
  const { type } = action

  switch (type) {
    case SET_HUBSPOT_DEALS:
      return action.deals.slice()
    default:
      return state
  }
}

export const hubspotDeal = (state = initialSingleState, action) => {
  const { type } = action

  switch (type) {
    case SET_HUBSPOT_DEAL:
      return { ...action.deal }
    default:
      return state
  }
}
