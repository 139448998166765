import { connect } from 'react-redux'

import { OrderMaster } from '@components/order'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { orders, router } = state

  return { orders, ...router }
}

const mapDispatchToProps = () => {
  const { OrderService, RestService, UIService } = services
  const {
    pRequestOrdersFilter,
    pResponseOrders,
    pResponseOrder,
    pResponseAccountExecutives,
  } = presenters.Api
  const { LoadOrders, SelectOrder, LoadAccountExecutives } = coordinators

  const { clearOrderables: clearOrders } = OrderService
  const loadOrders = LoadOrders({
    UIService,
    OrderService,
    RestService,
    pRequestOrdersFilter,
    pResponseOrders,
  })
  const selectOrder = SelectOrder({ OrderService, RestService, pResponseOrder })
  const loadAccountExecutives = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })
  const { setLastLoadOrdersParams } = UIService.OrderPage

  return {
    clearOrders,
    loadOrders,
    selectOrder,
    loadAccountExecutives,
    setLastLoadOrdersParams,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderMaster)
