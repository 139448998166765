import { connect } from 'react-redux'

import { Contact } from '@components/order/edit/sections'

import presenters from '@presenters'

const mapDispatchToProps = () => {
  const { pBuildContact } = presenters

  return { pBuildContact }
}

export default connect(undefined, mapDispatchToProps)(Contact)
