export const TOGGLE_EDIT_BATCH_ORDER_MODAL = 'TOGGLE_EDIT_BATCH_ORDER_MODAL'
export const TOGGLE_BATCH_INVOICE_BUTTON_ENABLED =
  'TOGGLE_BATCH_INVOICE_BUTTON_ENABLED'
export const SET_LAST_LOAD_BATCH_ORDERS_PARAMS =
  'SET_LAST_LOAD_BATCH_ORDERS_PARAMS'
export const SET_BATCH_PAYMENT_PROCESSING = 'SET_BATCH_PAYMENT_PROCESSING'

export const toggleBatchInvoiceButtonEnabled = (on) => ({
  type: TOGGLE_BATCH_INVOICE_BUTTON_ENABLED,
  on,
})

export const toggleEditBatchOrderModal = ({ show, mode }) => ({
  type: TOGGLE_EDIT_BATCH_ORDER_MODAL,
  show,
  mode,
})

export const setLastLoadBatchOrdersParams = ({ page, search, status }) => ({
  type: SET_LAST_LOAD_BATCH_ORDERS_PARAMS,
  page,
  search,
  status,
})

export const setBatchPaymentProcessing = (processing) => ({
  type: SET_BATCH_PAYMENT_PROCESSING,
  processing,
})
