import { useState, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { HubspotCompany } from '@types'

interface HubspotReqQueueItem {
  id: string
  fetch: () => Promise<HubspotCompany[]>
  resolve: (companies: HubspotCompany[] | PromiseLike<HubspotCompany[]>) => void
  reject: (error: Error) => void
}

export interface SearchCompaniesReq {
  search: string
  page: number
  results_per_page: number
}

// Hubspot rate limits
// Search API: 4 per second
class HubspotReqQueue {
  queue: HubspotReqQueueItem[]
  maxCalls = 4
  frequency = 1000
  processing: boolean

  constructor() {
    this.queue = []
    this.processing = false
  }

  addReq = async (
    id: string,
    fetch: () => Promise<HubspotCompany[]>,
  ): Promise<HubspotCompany[]> => {
    return new Promise((resolve, reject) => {
      this.queue.push({
        id,
        fetch,
        resolve,
        reject,
      })

      if (!this.processing) {
        this.processing = true

        setTimeout(() => {
          this.process()
        }, this.frequency / this.maxCalls)
      }
    })
  }

  process = () => {
    const req = this.queue.shift()
    if (!req) {
      return
    }

    try {
      const companies = req.fetch()
      req.resolve(companies)
      if (this.queue.length > 0) {
        this.processing = true
        setTimeout(() => {
          this.process()
        }, this.frequency / this.maxCalls)
      } else {
        this.processing = false
      }
    } catch (err: any) {
      req.reject(err)
    }
  }

  removeReq = (id: string) => {
    this.queue.some((req, i) => {
      if (req.id === id) {
        this.queue.splice(i, 1)

        return true
      }

      return false
    })
  }
}

const hubspotReqQueue = new HubspotReqQueue()

export const useHubspotFetch = (
  req: SearchCompaniesReq,
  fetcher: (req: SearchCompaniesReq) => Promise<HubspotCompany[]>,
) => {
  const [companies, setCompanies] = useState<HubspotCompany[]>([])
  const [error, setError] = useState<Error | null>(null)
  const reqId = useRef<string | null>(null)

  const cancel = () => {
    if (reqId.current) {
      hubspotReqQueue.removeReq(reqId.current)
    }
  }

  useEffect(() => {
    reqId.current = uuidv4()
    const loadCompanies = async () => {
      setError(null)
      try {
        const data = await hubspotReqQueue.addReq(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          reqId.current!,
          async () => await fetcher(req),
        )
        setCompanies(data)
      } catch (err: any) {
        setError(err)
      }
    }
    loadCompanies()

    return () => {
      cancel()
    }
  }, [JSON.stringify(req), fetcher])

  return {
    companies,
    loading: !error && !companies.length,
    error,
  }
}
