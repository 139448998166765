import { camelCaseify, formAdd } from '~/utils'

export const pResponseServiceItems = (json) => {
  const serviceItems = json.map((j) => ({
    chefId: j.chef_id,
    serviceItemId: j.id,
    name: j.name,
    description: j.description,
    cost: parseFloat(j.cost),
    price: parseFloat(j.price),
    service_type: j.service_type,
    quantity: j.quantity,
    pricingRate: j.pricing_rate,
    itemType: 'ServiceItem',
  }))

  return serviceItems
}

export const pResponseServiceItem = (json) => {
  const serviceItem = camelCaseify(json)

  return serviceItem
}

export const pRequestUpdateServiceItem = (data) => {
  const req = {}

  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'chefId', 'chef_id')
  formAdd(data, req, 'name', 'name')
  formAdd(data, req, 'isEnabled', 'is_enabled')
  formAdd(data, req, 'isApproved', 'is_approved')
  formAdd(data, req, 'description', 'description')
  formAdd(data, req, 'price', 'price')
  formAdd(data, req, 'cost', 'cost')
  formAdd(data, req, 'serviceType', 'service_type')
  formAdd(data, req, 'pricingRate', 'pricing_rate')

  const { cateringSupplies, diningSupplies } = data
  if (cateringSupplies) {
    req.service_item_catering_supplies_attributes = cateringSupplies.map(
      (supply) => {
        return {
          id: supply.suppliedForId,
          order_suppliable_id: supply.id,
          supply_usage_rate: supply.supplyUsageRate,
          _destroy: supply._destroy,
        }
      },
    )
  }
  if (diningSupplies) {
    req.service_item_dining_supplies_attributes = diningSupplies.map(
      (supply) => {
        return {
          id: supply.suppliedForId,
          order_suppliable_id: supply.id,
          supply_usage_rate: supply.supplyUsageRate,
          _destroy: supply._destroy,
        }
      },
    )
  }

  return { service_item: req }
}

export const pDefaultServiceFields = () => {
  return {
    name: '',
    description: '',
    duration: 0.0,
    marketPrice: 0.0,
    price: 0.0,
    service_type: 'chef_on_site',
    quantity: 0,
  }
}
