import { connect } from 'react-redux'

import { InvoiceVoidModal } from '@components/invoice'

import services from '@services'
import coordinators from '@coordinators'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { user = {} } = state

  return {
    user,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService } = services
  const { VoidInvoicePayment } = coordinators

  const voidInvoicePayment = VoidInvoicePayment({
    RestService,
    UIService,
    HandleError,
  })

  return {
    voidInvoicePayment,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceVoidModal)
