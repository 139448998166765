import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import FlexContainer from '@components/common/FlexContainer'
import XSpacing from '@components/common/XSpacing'

const noImageCopy =
  'To add a photo, go to the individual menu item and upload an image.'

const getMenuItemImageUrl = (menuItem) => {
  if (menuItem.images && menuItem.images.length) {
    return menuItem.images[0].thumborUrl
  }

  return ''
}

class ConceptMenuItemSelectInput extends Component {
  constructor(props) {
    super(props)
    const { mainArray, menuItems, selectedConceptsMenuItem } = props

    this.state = {
      mainArray,
      menuItems,
      selectedConceptsMenuItem,
      selectedMenuItem:
        selectedConceptsMenuItem && selectedConceptsMenuItem.menuItem,
      searchable: true,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { mainArray, menuItems, selectedConceptsMenuItem } = nextProps
    this.setState({
      mainArray,
      menuItems,
      selectedConceptsMenuItem,
      selectedMenuItem:
        selectedConceptsMenuItem && selectedConceptsMenuItem.menuItem,
    })
  }

  renderOption = (option) => {
    if (!option) {
      return null
    }
    const {
      name,
      description,
      mealType,
      tagsDietaryPreferenceList,
      tagsIngredientList,
      marketType,
      tagsGroupOrderMealTypeList,
    } = option

    return (
      <div>
        <h4>{name}</h4>
        <p style={{ 'font-size': '80%', color: '#999999' }}>
          {description}
          <br />
          <b>Meal Type: </b>
          {mealType}
          <br />
          {marketType === 'Group Order' && (
            <Fragment>
              <b>Group Order Meal Types: </b>
              {tagsGroupOrderMealTypeList}
              <br />
            </Fragment>
          )}
          <b>Dietary Preferences: </b>
          {tagsDietaryPreferenceList}
          <br />
          <b>Ingredients: </b>
          {tagsIngredientList}
        </p>
      </div>
    )
  }

  renderValue = (option) => {
    if (!option) {
      return option
    }
    const { name, tagsDietaryPreferenceList } = option

    return (
      <div style={{ color: '#999999' }}>
        <h3>
          <b>
            {name}{' '}
            {tagsDietaryPreferenceList
              ? `- ${tagsDietaryPreferenceList.split(',').join(', ')}`
              : ''}
          </b>
        </h3>
      </div>
    )
  }

  onChange = (value) => {
    if (value) {
      this.setState({ selectedMenuItem: value })
      if (this.props.onChange) {
        this.props.onChange(value)
      }
    }
  }

  updateArray = (value) => {
    if (this.props.updateArray) {
      this.props.updateArray(value)
    }
  }

  makePrimaryImage = (index) => {
    const { selectedConceptsMenuItem } = this.state
    const newPrimaryImage = selectedConceptsMenuItem.images.splice(index, 1)[0]
    selectedConceptsMenuItem.images.unshift(newPrimaryImage)
    this.setState({ selectedConceptsMenuItem })
  }

  deleteImage = (index) => {
    const { selectedConceptsMenuItem } = this.state
    const images = selectedConceptsMenuItem && selectedConceptsMenuItem.images
    if (images && images[index]) {
      images[index].destroy = !images[index].destroy
    }
    this.setState({ selectedConceptsMenuItem })
  }

  setDirection = (i, direction) => {
    const { mainArray } = this.state
    const newArray = [...mainArray]
    const target = newArray[i]

    let swapIdx = i + (direction === 'up' ? -1 : 1)
    if (swapIdx < 0) {
      swapIdx = mainArray.length - 1
    }
    if (swapIdx >= mainArray.length) {
      swapIdx = 0
    }

    const swapPosition = newArray[swapIdx].position
    newArray[swapIdx].position = target.position
    target.position = swapPosition

    this.setState({ mainArray: newArray })
    this.updateArray(newArray)

    return newArray
  }

  render() {
    const index = this.props.index !== undefined ? `${this.props.index}` : ''
    const { menuItems, selectedConceptsMenuItem, selectedMenuItem } = this.state

    return (
      <div className={'section sortable-table concept-menu-item-select-input'}>
        <div className="sortable-arrows">
          <div
            className="arrow"
            onClick={() => this.setDirection(Number(index), 'up')}
          >
            ▲
          </div>
          <div
            className="arrow"
            onClick={() => this.setDirection(Number(index), 'down')}
          >
            ▼
          </div>
        </div>

        <div className="sortable-table__inner">
          <Select
            options={menuItems}
            optionRenderer={this.renderOption}
            value={selectedMenuItem}
            valueRenderer={this.renderValue}
            labelKey="name"
            clearable={true}
            resetValue={{ id: null, menuItem: {} }}
            onChange={this.onChange}
            placeholder={'Select Menu Item'}
          />

          {selectedConceptsMenuItem && selectedMenuItem && (
            <div className="selected-item-container">
              <div className="selected-item-left">
                {selectedMenuItem.mealType && (
                  <div className="sortable-details">
                    <p>
                      Meal Type:
                      <span className="badge badge-meal-type">
                        {selectedMenuItem.mealType}
                      </span>
                    </p>
                  </div>
                )}
                {selectedMenuItem.marketType && (
                  <div className="sortable-details">
                    <p>
                      Market Type:{' '}
                      <span className="badge badge-meal-type">
                        {selectedMenuItem.marketType}
                      </span>
                    </p>
                  </div>
                )}
                {selectedMenuItem.marketType === 'Group Order' &&
                  selectedMenuItem.tagsGroupOrderMealTypeList && (
                    <div className="sortable-details">
                      <p>
                        Group Order Meal Types:
                        {selectedMenuItem.tagsGroupOrderMealTypeList
                          .split(',')
                          .map((tag, i) => (
                            <span
                              key={i}
                              className="badge badge-group-order-meal-type"
                            >
                              {tag}
                            </span>
                          ))}
                      </p>
                    </div>
                  )}
                {selectedMenuItem.tagsMealPeriodList && (
                  <div className="sortable-details">
                    <p>
                      Meal Period:
                      {selectedMenuItem.tagsMealPeriodList
                        .split(',')
                        .map((mealPeriod, i) => (
                          <span key={i} className="badge badge-meal-period">
                            {mealPeriod}
                          </span>
                        ))}
                    </p>
                  </div>
                )}
                {selectedMenuItem.foodGroups && (
                  <div className="sortable-details">
                    <p>
                      Food Groups:
                      {selectedMenuItem.foodGroups.map((foodGroup, i) => (
                        <span key={i} className="badge badge-food-type">
                          {foodGroup}
                        </span>
                      ))}
                    </p>
                  </div>
                )}
                {selectedMenuItem.tagsCuisineList && (
                  <div className="sortable-details">
                    <p>
                      Cuisine:
                      {selectedMenuItem.tagsCuisineList
                        .split(',')
                        .map((cuisine, i) => (
                          <span key={i} className="badge badge-cuisine">
                            {cuisine}
                          </span>
                        ))}
                    </p>
                  </div>
                )}
                {selectedMenuItem.description && (
                  <div className="sortable-details">
                    <p>Description: {selectedMenuItem.description}</p>
                  </div>
                )}
                {selectedMenuItem.name && (
                  <FlexContainer
                    flexDirection="row"
                    justifyContent="flex-start"
                  >
                    <div className="sortable-details">
                      <p>
                        Chef Price: $
                        {selectedMenuItem.cost
                          ? Number(selectedMenuItem.cost).toFixed(2)
                          : Number(selectedMenuItem.chefPrice).toFixed(2)}{' '}
                      </p>
                    </div>
                    <XSpacing width="10px" />
                    <p>|</p>
                    <XSpacing width="10px" />
                    <div className="sortable-details">
                      <p>
                        Market Price: $
                        {selectedMenuItem.price
                          ? Number(selectedMenuItem.price).toFixed(2)
                          : Number(selectedMenuItem.marketPrice).toFixed(2)}
                      </p>
                      {selectedMenuItem.avgPricePerPerson !==
                        selectedMenuItem.price && (
                        <p>
                          (
                          {Number(selectedMenuItem.avgPricePerPerson).toFixed(
                            2,
                          )}{' '}
                          Avg Price Per Person)
                        </p>
                      )}
                    </div>
                    <XSpacing width="10px" />
                    <p>|</p>
                    <XSpacing width="10px" />
                    <div className="sortable-details">
                      <p>
                        Margin: {this.props.pMenuItemMargin(selectedMenuItem)}%
                      </p>
                    </div>
                  </FlexContainer>
                )}
                {selectedMenuItem && (
                  <FlexContainer
                    flexDirection="row"
                    justifyContent="flex-start"
                  >
                    <div className="sortable-details">
                      <p>Serving Size: {selectedMenuItem.servingSize}</p>
                    </div>
                    <XSpacing width="10px" />
                    {selectedMenuItem.packaging && (
                      <div className="sortable-details">
                        <p> | Packaging: {selectedMenuItem.packaging.name} </p>
                      </div>
                    )}
                  </FlexContainer>
                )}
              </div>
              <div className="selected-item-right">
                {selectedMenuItem.marketType === 'Group Order' && (
                  <Fragment>
                    {getMenuItemImageUrl(selectedMenuItem) ? (
                      <div className="item-image-container">
                        <img src={getMenuItemImageUrl(selectedMenuItem)} />
                      </div>
                    ) : (
                      <div className="item-image-placeholder-container">
                        <div className="no-image-copy">
                          <p className="title">No Photo Uploaded</p>
                          <p className="description">{noImageCopy}</p>
                        </div>
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

ConceptMenuItemSelectInput.defaultProps = {
  mainArray: [],
  menuItems: [],
  selectedConceptsMenuItem: {},
  selectedMenuItem: {},
  searchable: true,
}

ConceptMenuItemSelectInput.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object),
  selectedConceptsMenuItem: PropTypes.object,
  mainArray: PropTypes.arrayOf(PropTypes.object),
  index: PropTypes.number,

  pMenuItemMargin: PropTypes.func,
  onChange: PropTypes.func,
  updateArray: PropTypes.func,
}

export default ConceptMenuItemSelectInput
