import { connect } from 'react-redux'

import AccountsReceivableTab from '@components/dashboard/AccountsReceivableTab'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { ARRolesRails } from '@constants'
import { GetAllowedHqs } from '~/utils'

import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { pProcessDashboardParams } = presenters
  const { accountsReceivableDashboardPage, router, headquarter, user } = state

  const { accountsReceivable } = state.accountsReceivable
  const { isLoading } = accountsReceivableDashboardPage
  const { accountName, hqs, from, to } = pProcessDashboardParams(
    router.location.search,
  )
  const { userHeadquarters } = headquarter
  const allowedHqs = GetAllowedHqs({
    headquarters: userHeadquarters,
    user,
    allowedRoles: ARRolesRails,
  })

  return {
    accountsReceivable,
    email: user.email,
    isLoading,
    accountName,
    from,
    to,
    headquarters: allowedHqs,
    filteredHqs: hqs,
  }
}

const mapDispatchToProps = () => {
  const { AccountsReceivableService, RestService, RouterService, UIService } =
    services
  const {
    pFormatCommaSeparatedCurrency,
    pRequestARParams,
    pResponseAccountsReceivable,
    pAccountsReceivableCSV,
    pGroupARByClient,
    pGroupARByRep,
  } = presenters.Api
  const {
    GetUnpaidAccounts,
    LoadOrder,
    MarkOrderPaid,
    UpsertAccountingOrder,
    UpdateARAfterMarkingPaid,
  } = coordinators
  const { setSortAccounts, setSortSalesReps } = AccountsReceivableService

  const getUnpaidAccounts = GetUnpaidAccounts({
    AccountsReceivableService,
    RestService,
    UIService,
    pRequestARParams,
    pResponseAccountsReceivable,
    pGroupARByClient,
    pGroupARByRep,
    HandleError,
  })
  const loadOrder = LoadOrder({ RouterService })
  const markOrderPaid = MarkOrderPaid({ RestService, UIService, HandleError })
  const updateARAfterMarkingPaid = UpdateARAfterMarkingPaid({
    AccountsReceivableService,
  })
  const upsertAccountingOrder = UpsertAccountingOrder({ RestService })

  return {
    getUnpaidAccounts,
    loadOrder,
    markOrderPaid,
    updateARAfterMarkingPaid,
    upsertAccountingOrder,
    pFormatCommaSeparatedCurrency,
    setSortAccounts,
    setSortSalesReps,
    pAccountsReceivableCSV,
    UIService,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountsReceivableTab)
