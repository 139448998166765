import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { ProposalDashboard } from '@containers/proposal'

export class ProposalMasterDetail extends Component {
  onNew = (e) => {
    e.preventDefault()

    const { onNew } = this.props
    onNew && onNew()
  }

  render() {
    const { onNew } = this.props

    return (
      <div className="children-container__inner">
        <div className="p-5">
          <ProposalDashboard onNew={onNew} />
        </div>
      </div>
    )
  }
}

ProposalMasterDetail.propTypes = {
  onNew: PropTypes.func,
}

export default ProposalMasterDetail
