import { connect } from 'react-redux'
import UserSearchPage from '@components/userSearch/UserSearchPage'
import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { user } = state
  const { lastLoggedInHq: headquarterId } = user || {}

  return {
    headquarterId,
  }
}

const mapDispatchToProps = () => {
  const { RestService } = services
  const { SearchUsers } = coordinators
  const { pRequestUsersFilter, pResponseUsers } = presenters.Api

  const searchUsers = SearchUsers({
    RestService,
    pRequestUsersFilter,
    pResponseUsers,
    HandleError,
  })

  return {
    searchUsers,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchPage)
