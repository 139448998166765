import React from 'react'
import PropTypes from 'prop-types'

const LinkText = (props) => {
  const {
    isLink,
    link,
    label,
    color,
    onClick,
    fontSize,
    margin,
    testId,
    disabled,
  } = props

  const className = props.className
    ? `link-text ${props.className}`
    : 'link-text'

  return isLink ? (
    <a
      id={testId}
      className={className}
      style={{ color, fontSize, margin }}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {label}
    </a>
  ) : (
    <button
      id={testId}
      className={className}
      style={{ color, fontSize, margin }}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

LinkText.propTypes = {
  testId: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  link: PropTypes.string,
  fontSize: PropTypes.string,
  isLink: PropTypes.bool,
  margin: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,

  onClick: PropTypes.func,
}

export default LinkText
