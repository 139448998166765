import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class MarketService extends BaseService {
  clearEditHQ = () => this.dispatch(actions.clearEditHQ())

  clearMarket = () => this.dispatch(actions.clearMarket())

  setEditDeliveryZone = (dZone) =>
    this.dispatch(actions.setEditDeliveryZone(dZone))

  setEditHQ = (hq) => this.dispatch(actions.setEditHQ(hq))

  setEditServiceDepot = (sD) => this.dispatch(actions.setEditServiceDepot(sD))

  setHQ = (hq) => this.dispatch(actions.setHQDetails(hq))

  setDeliveryZone = (deliveryZone) =>
    this.dispatch(actions.setDeliveryZone(deliveryZone))

  setDeliveryZones = (deliveryZones) =>
    this.dispatch(actions.setDeliveryZones(deliveryZones))

  setServiceDepot = (serviceDepot) =>
    this.dispatch(actions.setServiceDepot(serviceDepot))

  setServiceDepots = (serviceDepots) =>
    this.dispatch(actions.setServiceDepots(serviceDepots))

  setMarkets = (markets) => this.dispatch(actions.setMarkets(markets))

  setNewHQ = (hq) => {
    const { markets } = this.getState()
    markets.push(hq)
    this.dispatch(actions.setDeliveryZones([]))
    this.dispatch(actions.setServiceDepots([]))
    this.dispatch(actions.setMarkets(markets))
    this.dispatch(actions.setHQDetails(hq))
  }

  updateEditMarket = (market) => this.dispatch(actions.updateEditMarket(market))

  updateDeliveryZone = (deliveryZone) => {
    const { deliveryZones } = this.getState()
    const newDZones = deliveryZones.filter((d) => d.id !== deliveryZone.id)
    newDZones.push(deliveryZone)

    this.dispatch(actions.setDeliveryZones(newDZones))
  }

  updateServiceDepot = (serviceDepot) => {
    const { serviceDepots } = this.getState()
    const newDepots = serviceDepots.filter((s) => s.id !== serviceDepot.id)
    newDepots.push(serviceDepot)

    this.dispatch(actions.setServiceDepots(newDepots))
  }

  deleteDeliveryZone = (id) => {
    const { deliveryZones } = this.getState()
    const remainingZones = deliveryZones.filter((d) => d.id !== id)

    this.dispatch(actions.setDeliveryZones(remainingZones))
  }

  deleteServiceDepot = (id) => {
    const { serviceDepots } = this.getState()
    const remainingDepots = serviceDepots.filter((s) => s.id !== id)

    this.dispatch(actions.setServiceDepots(remainingDepots))
  }

  changeDefault = (serviceDepot) => {
    this.setEditServiceDepot(serviceDepot)
    this.setServiceDepot(serviceDepot)
    this.updateServiceDepot(serviceDepot)
  }
}
