import * as customersCoordinators from './customers'
import * as discountsCoordinators from './discounts'
import * as emailDistributionsCoordinators from './emailDistributions'
import * as ordersCoordinators from './orders'
import * as popUpsCoordinators from './popUps'
import * as recurringPopUpsCoordinators from './recurringPopUps'
import * as notificationsCoordinators from './notifications'

export default Object.freeze({
  ...customersCoordinators,
  ...discountsCoordinators,
  ...emailDistributionsCoordinators,
  ...ordersCoordinators,
  ...popUpsCoordinators,
  ...recurringPopUpsCoordinators,
  ...notificationsCoordinators,
})
